import React, { Children, ReactElement, ReactNode } from "react";
import * as css from "./styles/css.module.scss";

interface HeaderColumnProps {
    children: ReactNode[] | ReactNode
}

interface WorkflowHeaderProps {
    children: ReactElement[] | ReactElement,
    className?: string,
}

function LeftCol(props: HeaderColumnProps): ReactElement{
    return (
            <span className={css.leftCol}>
                {props.children}
            </span>
    );
}

function RightCol(props: HeaderColumnProps): ReactElement{
    return (
            <span className={css.rightCol}>
                {props.children}
            </span>
    );
}

// TODO: Deprecate in favor of components/layout/page-header

export default function WorkflowHeader(props: WorkflowHeaderProps):ReactElement{
    let leftCol = <></>;
    let rightCol = <></>;

    Children.forEach(props.children, child => {
        if(child.type === LeftCol) {
            leftCol = child;
        }
        if(child.type === RightCol) {
            rightCol = child;
        }
    });

    return (
            <div className={props.className ? `${css.headerRow} ${props.className}` : `${css.headerRow}`}>
                {leftCol}
                {rightCol}
            </div>
    );
}

WorkflowHeader.LeftCol = LeftCol;
WorkflowHeader.RightCol = RightCol;