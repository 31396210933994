import { StyleSheet } from '@react-pdf/renderer';
import { COLORS } from '../../../constants/Colors';


export default StyleSheet.create({
    page: {
        flexDirection: "column",
        fontFamily: "Inter",
        padding: 32,
    },

    // CHARTS
    chartsPageWrapper: {
        display: "flex",
        flexDirection: "row",
        alignContent: "center",
        justifyContent: "space-between",
        flexWrap: "wrap",
    },
    chartBodyHeader: {
        display: "flex",
        flexDirection: "row",
        alignContent: "center",
        justifyContent: "center",
    },
    chartBodyMetric: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        marginLeft: 8,
        marginRight: 8,
    },
    chartBodyMetricLabel: {
        fontSize: 7,
        color: COLORS.GREY_500,
    },
    chartBodyMetricValue: {
        fontSize: 9,
        fontWeight: 600,
    },

    // COMMON COMPONENT STYLES
    pageTitleWrapper: {
        display: "flex",
        flexDirection: "row",
        alignItems: "baseline",
        justifyContent: "space-between",
        // borderBottom: `1 solid ${COLORS.GREY_500}`,
        paddingBottom: 8,
        marginBottom: 22,
    },
    pageTitle: {
        fontSize: 16,
        fontWeight: 700,
        fontFamily: "Inter",
        color: COLORS.PRIMARY_500,
    },
    pageTitlePropertyName: {
        fontSize: 16,
        marginLeft: "auto",
        fontWeight: 600,
        color: COLORS.GREY_400,
    },
    title: {
        fontSize: 16,
        fontWeight: 600,
        fontFamily: "Inter",
        borderBottom: `1 solid ${COLORS.GREY_500}`,
        paddingBottom: 8,
        marginBottom: 22,
        color: COLORS.GREY_800,
    },
    titleSmall: {
        fontSize: 12,
        marginBottom: 12,
    },
    paragraph: {
        fontSize: 12,
        fontWeight: 400,
        color: COLORS.GREY_800,
        lineHeight: 1.4,
    },
    pageFooter: {
        position: "absolute",
        bottom: 25,
        left: 32,
        width: "calc(100% - 64)",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
    },
    pageFooterLogo: {
        width: 36,
    },
    pageFooterNumber: {
        marginLeft: "auto",
        textAlign: "right",
        fontSize: 10,
        color: COLORS.GREY_600,
    },

    // CARDS
    cardWrapper: {
        width: "49%",
        borderRadius: 4,
        border: `1 solid ${COLORS.GREY_100}`,
        marginBottom: 10,
    },
    cardWrapperFull: {
        width: "100%",
    },
    cardHeader: {
        height: 40,
        padding: 8,
        borderBottom: `1 solid ${COLORS.GREY_100}`,
    },
    cardHeaderTitle: {
        fontSize: 9,
        fontWeight: 500,
        marginBottom: 2,
        textTransform: "uppercase",
        color: COLORS.GREY_600,
    },
    cardHeaderSubtitle: {
        fontSize: 7,
        color: COLORS.GREY_500,
    },
    cardBody: {
        padding: 8,
    },
    cardBodyFlush: {
        padding: 0,
    },

    // METRIC BLOCKS
    metricBlockRow: {
        display: "flex",
        flexDirection: "row",
        alignItems: "flex-start",
        justifyContent: "space-between",
        marginBottom: 32,
    },
    metricBlock: {
        width: "48%",
    },
    metricBlockTitle: {
        fontSize: 9,
        color: COLORS.GREY_600,
        textTransform: "uppercase",
        marginBottom: 8,
        borderColor: COLORS.GREY_200,
    },
    metricBlockValues: {
        display: "flex",
        flexDirection: "row",
        alignItems: "flex-start",
        justifyContent: "flex-start",
    },
    metricBlockValueWrapper: {
        width: "48%",
    },
    metricBlockValue: {
        fontSize: 17,
        fontWeight: 500,
    },
    metricBlockTopLabel: {
        marginRight: 8,
        marginBottom: 4,
        fontSize: 9,
        color: COLORS.GREY_500,
    },
    metricBlockBottomLabel: {
        marginTop: 8,
        fontSize: 9,
        color: COLORS.GREY_500,
    },
    metricBlockText: {
        marginTop: 8,
        fontSize: 10,
        color: COLORS.GREY_800,
    },

    // TABLES
    tableRow: {
        width: "100%",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        alignContent: "center",
        justifyContent: "flex-start",
    },
    tableCellWrapper: {
        height: 22,
        borderRight: `1 solid ${COLORS.GREY_100}`,
        borderBottom: `1 solid ${COLORS.GREY_100}`,
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        alignContent: "center",
        justifyContent: "flex-start",
    },
    tableCell: {
        fontSize: 8,
    },
    reportTableNameCol: {
        paddingLeft: 6,
        flex: 1,
        width: 300,
    },
    reportTableDataCol: {
        paddingRight: 6,
        justifyContent: "flex-end",
        width: 70,
    },
    tableCellSmall: {
        fontSize: 5,
    },
    reportTableDataColSmall: {
        paddingRight: 3,
        width: 38,
    },
    headerRow: {
        fontWeight: 600,
    },
    finalCol: {
        borderRight: 0,
    },
    finalRow: {
        borderBottom: 0,
    },

    // FINANCIALS TABLE
    financialsTableHeaderRow: {
        fontWeight: 600,
        height: 22,
        alignItems: "flex-end",
        alignContent: "flex-end",
        paddingBottom: 3,
    },
    financialsTableGLCol: {
        height: 16,
        width: 34,
        justifyContent: "center",
    },
    financialsTableNameCol: {
        flex: 1,
        height: 16,
        padding: 3,
        width: 300,
    },
    financialsTableDataCol: {
        height: 16,
        paddingRight: 3,
        width: 38,
        justifyContent: "flex-end",
        textAlign: "right",
    },
    financialsTableCell: {
        fontSize: 5,
    },
    financialsTableThickBorderRight: {
        borderRight: `2 solid ${COLORS.GREY_200}`,
    },
    financialsTableSummaryRow: {
        backgroundColor: COLORS.GREY_100,
    },

    // REVENUE TABLE
    revenueTableDataCol: {
        height: 16,
        paddingRight: 3,
        width: 50,
        justifyContent: "flex-end",
        textAlign: "right",
    },
    revenueTableCell: {
        fontSize: 7,
    },

    // PAYROLL TABLE
    payrollTableDataCol: {
        width: 150,
        justifyContent: "flex-end",
        textAlign: "right",
        paddingRight: 6,
    },

    // COVER PAGE
    coverPage: {
        position: "relative",
    },
    coverPageBanner: {
        position: "absolute",
        width: "100%",
        height: "38%",
        top: "24%",
    },
    propertyName: {
        fontFamily: "Inter",
        fontSize: 40,
        textAlign: "center",
        color: "#ffffff",
        fontWeight: 800,
        marginTop: 95,
        marginBottom: 20,
    },
    presentedBy: {
        fontFamily: "Inter",
        fontSize: 16,
        fontWeight: 500,
        marginTop: 4,
        textAlign: "center",
        color: "#ffffff",
    },
    backgroundImage: {
        position: "absolute",
        width: "100%",
        height: "100%",
        objectFit: "cover",
    },
    colorOverlay: {
        position: "absolute",
        width: "100%",
        height: "100%",
        opacity: .35,
    },
    overlayContainer: {
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    brandColorBand: {
        overflow: "hidden",
        width: "100%",
        height: "6px",
        fontSize: 0,
        position: "absolute",
        top: 0,
    },
    colorBar: {
        width: "36%",
        height: "100%",
        position: "absolute",
    },
    colorBarBlue: {
        left: 0,
        backgroundColor: "#3498db",
    },
    colorBarGreen: {
        left: "33%",
        backgroundColor: "#2ecc71",
    },
    colorBarOrange: {
        left: "66%",
        backgroundColor: "#e67e22",
    },
    coverFooterBranding: {
        position: "absolute",
        bottom: 32,
        width: "100%",
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
    },
    footerVizLogo: {
        width: 68,
        bottom: -1,
    },
    footerBrandJoinIcon: {
        width: 12,
        marginLeft: 16,
        marginRight: 16,
    },
    footerPartnerLogoAssetLiving: {
        width: 80,
    },

    // TABLE OF CONTENTS
    tocItem: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        marginBottom: 16,
        textDecoration: "none",
    },
    tocTitle: {
        fontSize: 12,
        fontWeight: 500,
        color: COLORS.GREY_600,
    },
    tocPageNumber: {
        fontSize: 12,
        fontWeight: 500,
        color: COLORS.GREY_600,
        marginLeft: "auto",
    }
});

export const interFontDefinitions = {
    family: 'Inter',
    fonts: [
        {
            src: 'https://fonts.gstatic.com/s/inter/v12/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuLyeMZhrib2Bg-4.ttf',
            fontWeight: 100,
        },
        {
            src: 'https://fonts.gstatic.com/s/inter/v12/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuDyfMZhrib2Bg-4.ttf',
            fontWeight: 200,
        },
        {
            src: 'https://fonts.gstatic.com/s/inter/v12/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuOKfMZhrib2Bg-4.ttf',
            fontWeight: 300,
        },
        {
            src: 'https://fonts.gstatic.com/s/inter/v12/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuLyfMZhrib2Bg-4.ttf',
            fontWeight: 400,
        },
        {
            src: 'https://fonts.gstatic.com/s/inter/v12/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuI6fMZhrib2Bg-4.ttf',
            fontWeight: 500,
        },
        {
            src: 'https://fonts.gstatic.com/s/inter/v12/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuGKYMZhrib2Bg-4.ttf',
            fontWeight: 600,
        },
        {
            src: 'https://fonts.gstatic.com/s/inter/v12/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuFuYMZhrib2Bg-4.ttf',
            fontWeight: 700,
        },
        {
            src: 'https://fonts.gstatic.com/s/inter/v12/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuDyYMZhrib2Bg-4.ttf',
            fontWeight: 800,
        },
        {
            src: 'https://fonts.gstatic.com/s/inter/v12/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuBWYMZhrib2Bg-4.ttf',
            fontWeight: 900,
        },
    ],
}