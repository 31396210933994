import {ReactElement} from 'react';
import {Link} from 'react-router-dom';
import './MenuCards.css';


interface MenuCard {
    title: string;
    img: string;
    url: string;
    alt: string;
}

interface Props {
    title: string;
    cards: MenuCard[];
}

export default function MenuCards(props: Props): ReactElement {
  return (
    <div className="container">
      <div className="my-5 ms-3 fs-4 text-darkliver"> {props.title} </div>
      <div className="d-flex justify-content-start row row-cols-auto">
        {props.cards.map((card, index) => (
          <Link key={index} to={card.url}>
            <figure className="figure figure-card mx-3" style={{width: '12rem', height: '12rem'}}>
              <div className="d-flex justify-content-center mt-4">
                <img src={card.img}
                  className="svg-alternate-img"
                  style={{width: '5.2rem'}}
                />
              </div>
              <figcaption className="figure-caption text-center mt-3">{card.title}</figcaption>
            </figure>
          </Link>
        ))}
      </div>
    </div>
  );
}
