import {HotTable} from "@handsontable/react";
import {ReactElement, useEffect, useRef, useState} from "react";
import {Property} from "../../../contexts/properties/PropertiesContext";
import * as tableCSS from "../../../components/account-table/styles/accountTable.module.scss";
import {LICENSES} from "../../../constants/Licenses";
import Handsontable from "handsontable";
import {GetSimplifiedRevenueSummaryQuery} from "../../../__generated__/generated_types";
import {MONTHS} from "../../../constants/Months";
import {numRange, sumOfArray} from "../logic/HelperFunctions";

export interface ILeaseExpirationScheduleTableProps {
    property: Property,
    data: GetSimplifiedRevenueSummaryQuery | undefined,
    triggerDownload: boolean,
    onDownloadComplete: () => void,
}

export default function LeaseExpirationScheduleTable({property, data, triggerDownload, onDownloadComplete}: ILeaseExpirationScheduleTableProps): ReactElement {
    const hotRef = useRef<HotTable>(null);

    // type pulled from the Handsontable row settings, sorry for the 'any'
    const [tableData, setTableData] = useState<any[][]>([]);

    const rfcstTotalColIndex = 13;
    const bdgtTotalColIndex = 26;
    const secondRfcstTotalColIndex = 27;
    const varianceColIndex = 28;
    const actColRange = numRange(1, property.reforecastStartMonthIndex + 1);
    const rfcstColRange = numRange(property.reforecastStartMonthIndex + 1, 13);
    const bdgtColRange = numRange(rfcstTotalColIndex + 1, bdgtTotalColIndex);

    useEffect(() => {
        if (!data?.simplifiedRevenueModel || !data.simplifiedRevenueModel.unitTypeRenewals) {
            return;
        }

        const expirys = data.simplifiedRevenueModel.unitTypeRenewals.expirationCount;
        const tempData: (string | number | null)[][] = [];

        for (const unit of expirys) {
            const actVals = unit.actuals.slice(0, property.reforecastStartMonthIndex);
            const rfcstVals = unit.reforecast.slice(property.reforecastStartMonthIndex, 12);
            const rfcstTotal = sumOfArray(actVals.concat(rfcstVals));
            const bdgtTotal = sumOfArray(unit.budget);

            tempData.push([
                unit.unitTypeName,
                ...actVals,
                ...rfcstVals,
                rfcstTotal,
                ...unit.budget,
                bdgtTotal,
                rfcstTotal,
                bdgtTotal - rfcstTotal,
            ]);
        }

        const getColumnTotals = () => {
            const columns = new Array(29).fill(0);
            const childSums: (number | null)[] = [];

            columns.forEach((_e, i) => {
                if (i > 0) {
                    let sum = 0;
                    let nullCount = 0;

                    tempData.forEach((c) => {
                        const curr = c[i];
                        if (curr !== null && curr !== undefined) {
                            sum += typeof curr === "string" ? parseInt(curr) : curr;
                        } else {
                            nullCount++;
                        }
                    });

                    // If all cells in the column are `null`, then keep their sum `null` instead of 0
                    childSums.push(nullCount === tempData.length ? null : sum);
                }
            });

            return childSums;
        };

        tempData.push([
            "Total",
            ...getColumnTotals(),
        ]);

        setTableData(tempData);
    }, [data]);

    useEffect(() => {
        if (triggerDownload && hotRef.current?.hotInstance) {
            const exportPlugin = hotRef.current.hotInstance.getPlugin("exportFile");
            exportPlugin.downloadFile("csv", {filename: `${property.name}_${property.budgetYear} Lease Expiration Schedule_${new Date().toLocaleDateString('en-US')}`, columnHeaders: true,});
            onDownloadComplete();
        }
    }, [triggerDownload]);

    const columns: string[] = [
        "",
        ...MONTHS,
        "Total",
        ...MONTHS,
        "Total",
        "Total",
        "Difference",
    ];

    if (!tableData || tableData.length == 0) {
        return <></>;
    }

    const settings: Handsontable.GridSettings = {
        data: tableData,
        licenseKey: LICENSES.HandsOnTable,
        columns(index) {
            return {type: index == 0 ? "text" : "numeric"};
        },
        colHeaders: columns,
        selectionMode: "range",
        rowHeaders: false,
        width: "auto",
        height: "auto",
        readOnly: true,
        fixedColumnsLeft: 1,
        fixedRowsBottom: 1,
        manualRowResize: false,
        manualColumnResize: false,
        autoColumnSize: false,
        autoRowSize: false,
        disableVisualSelection: ["header"],
        colWidths(index) {
            if (index == 0) {
                return 220;
            } else if (index == rfcstTotalColIndex || index == bdgtTotalColIndex || index == secondRfcstTotalColIndex || index == varianceColIndex) {
                return 120;
            } else {
                return 90;
            }
        },
        afterGetColHeader: (_column, th) => {
            if (!hotRef.current?.hotInstance || rfcstColRange[0] == undefined) {
                return;
            }

            th.className += ` ${tableCSS.cellBase} ${tableCSS.headerWithLabels}`;

            if (_column < rfcstColRange[0] || _column == rfcstTotalColIndex || _column == bdgtTotalColIndex || _column == secondRfcstTotalColIndex || _column == varianceColIndex) {
                th.className += ` ${tableCSS.cellBgGrey}`;
            }

            if (_column == rfcstColRange[0] || _column == rfcstTotalColIndex) {
                th.className += ` ${tableCSS.verticalThickBorderLeft}`;
            }

            if (_column == 1) {
                th.innerHTML = `
                    <div class="${tableCSS.tableHeaderLightLabel}">
                        <span>ACTUAL</span>
                        <span>${property.reforecastYear}</span>
                        <span>${MONTHS[_column - 1]}</span>
                    </div>
                `;
            } else if (actColRange.includes(_column)) {
                th.innerHTML = `
                    <div class="${tableCSS.tableHeaderLightLabel}">
                        <span>ACTUAL</span>
                        <span>&nbsp;</span>
                        <span>${MONTHS[_column - 1]}</span>
                    </div>
                `;
            } else if (rfcstColRange.includes(_column)) {
                th.innerHTML = `
                    <div class="${tableCSS.tableHeaderLightLabel}">
                        <span>RFCST</span>
                        <span>&nbsp;</span>
                        <span>${MONTHS[_column - 1]}</span>
                    </div>
                `;
            } else if (_column == rfcstTotalColIndex) {
                th.innerHTML = `
                    <div class="${tableCSS.tableHeaderLightLabel}">
                        <span>REFORECAST</span>
                        <span>${property.reforecastYear}</span>
                        <span>Total</span>
                    </div>
                `;
            } else if (_column == rfcstTotalColIndex + 1) {
                th.innerHTML = `
                    <div class="${tableCSS.tableHeaderLightLabel}">
                        <span>BUDGET</span>
                        <span>${property.budgetYear}</span>
                        <span>${MONTHS[(_column - rfcstTotalColIndex) - 1]}</span>
                    </div>
                `;
            } else if (bdgtColRange.includes(_column)) {
                th.innerHTML = `
                    <div class="${tableCSS.tableHeaderLightLabel}">
                        <span>BUDGET</span>
                        <span>&nbsp;</span>
                        <span>${MONTHS[(_column - rfcstTotalColIndex) - 1]}</span>
                    </div>
                `;
            } else if (_column == bdgtTotalColIndex) {
                th.innerHTML = `
                    <div class="${tableCSS.tableHeaderLightLabel}">
                        <span>BUDGET</span>
                        <span>${property.budgetYear}</span>
                        <span>Total</span>
                    </div>
                `;
            } else if (_column == secondRfcstTotalColIndex) {
                th.innerHTML = `
                    <div class="${tableCSS.tableHeaderLightLabel}">
                        <span>REFORECAST</span>
                        <span>${property.reforecastYear}</span>
                        <span>Total</span>
                    </div>
                `;
            } else if (_column == varianceColIndex) {
                th.innerHTML = `
                    <div class="${tableCSS.tableHeaderLightLabel}">
                        <span>&nbsp;</span>
                        <span>&nbsp;</span>
                        <span>Difference</span>
                    </div>
                `;
            } else {
                // sure there must be better ways to handle HoT col size calculations, but this will do it for now
                th.innerHTML = `
                    <div class="${tableCSS.tableHeaderLightLabel}">
                        <span>&nbsp;</span>
                        <span>&nbsp;</span>
                        <span>&nbsp;</span>
                    </div>
                `;
            }
        },
        cells(_row, _column) {
            this.className += ` ${tableCSS.cellBase}`;

            if (_column == 0) {
                this.className += ` ${tableCSS.rowHeader}`;
            }

            if (_column == rfcstColRange[0] || _column == rfcstTotalColIndex) {
                this.className += ` ${tableCSS.verticalThickBorderLeft}`;
            }

            if (_column == rfcstTotalColIndex || _column == bdgtTotalColIndex || _column == secondRfcstTotalColIndex || _column == varianceColIndex) {
                this.className += ` ${tableCSS.cellBgGrey}`;
            }

            if (_row == (tableData.length - 1)) {
                this.className += ` ${tableCSS.firstVersionRow}`;
            }

            return this;
        },
    };

    return (
        <div className={tableCSS.accountTableWrapper}>
            <HotTable
                ref={hotRef}
                settings={settings}
                className={tableCSS.accountTable}
            />
        </div>
    );
}
