import { ThemeProvider } from "@zendeskgarden/react-theming";
import { ReactElement, useEffect, useState } from "react";
import { ViziblyTheme } from "../analyst/ViziblyZDGTheme";
import AdminHeader from "../admin/AdminHeader";
import * as css from "./styles/executiveSummaryReportManagement.module.scss";
import useAppStore from "../../hooks/useAppStore";

import { Button } from "@zendeskgarden/react-buttons";

import "native-injects";
import Card from "../simplified-revenue/components/Card";
import { GetReportTablesQuery, useAddReportTableMutation, useDeleteReportTableMutation, useGetReportTablesLazyQuery, useListBudgetYearsQuery, useMoveReportTableMutation, useSetReportTableAccountsMutation, useUpdateReportTableMutation } from "../../__generated__/generated_types";
import { ReactComponent as MoveUpIcon } from '@zendeskgarden/svg-icons/src/16/chevron-up-stroke.svg';
import { ReactComponent as MoveDownIcon } from '@zendeskgarden/svg-icons/src/16/chevron-down-stroke.svg';
import { ReactComponent as PencilIcon } from '@zendeskgarden/svg-icons/src/16/pencil-stroke.svg';
import { ReactComponent as XIcon } from '@zendeskgarden/svg-icons/src/16/x-stroke.svg';
import { ReactComponent as PlusIcon } from '@zendeskgarden/svg-icons/src/16/plus-stroke.svg';
import { EditReportTable } from "./components/EditReportTable";
import DeleteReportTableModal from "./components/DeleteReportTableModal";
import { useChartOfAccounts } from "../../contexts/chartofaccounts/ChartOfAccountsContext";
import AccountsSelector from "./components/AccountsSelector";

export default function ExecutiveSummaryReportManagement(): ReactElement {
    const appStore = useAppStore();

    const [getGetReportTables, {data: reportTablesData}] = useGetReportTablesLazyQuery({fetchPolicy: "no-cache"});
    const {data: budgetYearsListData, loading: budgetYearsListLoading} = useListBudgetYearsQuery({fetchPolicy: "no-cache"});
    const [deleteReportTable] = useDeleteReportTableMutation({notifyOnNetworkStatusChange: true});
    const [updateReportTable] = useUpdateReportTableMutation({notifyOnNetworkStatusChange: true});
    const [moveReportTable] = useMoveReportTableMutation({notifyOnNetworkStatusChange: true});
    const [addReportTable] = useAddReportTableMutation({notifyOnNetworkStatusChange: true});
    const [setReportTableAccounts] = useSetReportTableAccountsMutation({notifyOnNetworkStatusChange: true});
    const coa = useChartOfAccounts();


    // const [selectedProperty, setSelectedProperty] = useState<Property>();
    const [reportTables, setReportTables] = useState<GetReportTablesQuery["getReportTables"]>([]);
    const [budgetYear, setBudgetYear] = useState<number>();
    const [updateReportTableData, setUpdateReportTableData] = useState<{id: string, name: string}>();
    const [deleteReportTableData, setDeleteReportTableData] = useState<{id: string, name: string}>();
    const [addReportTableData, setAddReportTableData] = useState(false);

    function fetchReportTablesData() {
        if (budgetYear !== undefined) {
            getGetReportTables({variables: {budgetYear: budgetYear}});
        }
    }

    function handleUpdateReportTable(
        reportTableId: string,
        name: string | undefined
    ) {
        if (budgetYear === undefined) {
            return;
        }
        const promises = [];
        if (name !== undefined) {
            promises.push(new Promise((resolve, reject) => {
                updateReportTable({
                    variables: {
                        reportTableId: reportTableId,
                        budgetYear: budgetYear,
                        newName: name
                    }
                })
                .then(() => resolve(true), () => reject())
                .catch(() => reject());
            }));
        }
        if (promises.length > 0) {
            Promise.all(promises)
            .then(
                () => {
                    setUpdateReportTableData(undefined);
                    fetchReportTablesData();
                }
            );
        }
    }

    function handleDeleteReportTable(reportTableId: string) {
        if (budgetYear === undefined) {
            return;
        }
        deleteReportTable({
            variables: {
                budgetYear: budgetYear,
                reportTableId: reportTableId
            }
        })
        .then(
            () => {
                setDeleteReportTableData(undefined);
                fetchReportTablesData();
            }
        );
    }

    function handleAddReportTable(name: string | undefined) {
        if (!name || budgetYear === undefined) {
            return;
        }
        addReportTable({
            variables: {
                budgetYear: budgetYear,
                reportTableName: name
            }
        })
        .then(
            () => {
                setAddReportTableData(false);
                fetchReportTablesData();
            }
        );
    }

    function handleMoveTable(reportTableId: string, direction: "up" | "down") {
        if (!budgetYear) {
            return;
        }
        let afterTableId: string | null = null;
        const tblIndex = reportTables.findIndex(tbl => tbl.id === reportTableId);
        if (direction === "up") {
            if (tblIndex - 2 > -1) {
                afterTableId = reportTables[tblIndex - 2]?.id ?? null;
            }
        }
        else if (direction === "down") {
            if (tblIndex + 1 < reportTables.length) {
                afterTableId = reportTables[tblIndex + 1]?.id ?? null;
            }
        }
        moveReportTable({
            variables: {
                budgetYear: budgetYear,
                reportTableId: reportTableId,
                afterTableId: afterTableId
            }
        })
        .then(
            () => {
                fetchReportTablesData();
            }
        );
    }

    function handleSetTableAccounts(reportTableId: string, accountIds: string[]) {
        if (budgetYear === undefined) {
            return;
        }
        setReportTableAccounts({
            variables: {
                budgetYear: budgetYear,
                reportTableId: reportTableId,
                accountIds: accountIds ?? []
            }
        });
    }

    useEffect(
        () => {
            appStore.set({ isLoading: false });
            return () => undefined;
        },
        []
    );

    useEffect(() => {
        if (budgetYearsListData && !budgetYearsListLoading) {
            setBudgetYear(budgetYearsListData.listBudgetYears.activeBudgetYear);
        }
    }, [budgetYearsListData, budgetYearsListLoading]);

    useEffect(() => {
        if (budgetYear !== undefined) {
            fetchReportTablesData();
        }
    }, [budgetYear]);

    useEffect(() => {
        if (reportTablesData) {
            setReportTables([...reportTablesData.getReportTables].sortBy("order"));
        }
    }, [reportTablesData]);

    return (
        <ThemeProvider theme={ViziblyTheme}>
            <div className={css.wrapper}>
                <AdminHeader
                    title={"Executive Summary Report Management"}
                    subtitle={"Manage report tables for financial summary section of executive summary report"}
                />
                <Card
                    title={"Report tables"}
                    actions={
                        <>
                            <Button
                                isBasic
                                onClick={() => setAddReportTableData(true)}
                            >
                                <PlusIcon />
                            </Button>
                        </>
                    }
                >
                    <div className={css.reportTablesTableWrapper}>
                        <table className={css.reportTablesTable}>
                            <tbody>
                                {reportTables.map((tbl, index) => {
                                    return (
                                        <tr key={tbl.id}>
                                            <td className={`${css.cell}`}>
                                                <div className={css.content}>
                                                    { tbl.name }
                                                    <div className={css.controls}>
                                                        <Button
                                                            isBasic
                                                            onClick={() => setUpdateReportTableData(tbl)}
                                                        >
                                                            <PencilIcon />
                                                        </Button>
                                                        <Button
                                                            isBasic
                                                            isDanger
                                                            onClick={() => setDeleteReportTableData(tbl)}
                                                        >
                                                            <XIcon />
                                                        </Button>
                                                        <Button
                                                            isBasic
                                                            disabled={index === 0}
                                                            onClick={() => handleMoveTable(tbl.id, "up")}
                                                        >
                                                            <MoveUpIcon />
                                                        </Button>
                                                        <Button
                                                            isBasic
                                                            disabled={index === reportTables.length - 1}
                                                            onClick={() => handleMoveTable(tbl.id, "down")}
                                                        >
                                                            <MoveDownIcon />
                                                        </Button>
                                                    </div>
                                                </div>
                                            </td>
                                            <td className={css.cell}>
                                                {coa.chartOfAccountsFlat &&
                                                <AccountsSelector
                                                    allAccounts={coa.chartOfAccountsFlat}
                                                    selectedAccountIds={tbl.accounts.map(acc => acc.accountId)}
                                                    onChangeCallback={(accountIds) => handleSetTableAccounts(tbl.id, accountIds)}
                                                />
                                                }
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    </div>
                </Card>
            </div>
            {
            updateReportTableData &&
            <EditReportTable
                existingReportTables={reportTablesData?.getReportTables ?? []}
                initialName={updateReportTableData.name}
                reportTableId={updateReportTableData.id}
                title="Edit Report Table"
                onCancel={() => setUpdateReportTableData(undefined)}
                onSubmit={(name) => handleUpdateReportTable(updateReportTableData.id, name)}
            />
            }
            {
            addReportTableData &&
            <EditReportTable
                existingReportTables={reportTablesData?.getReportTables ?? []}
                title="Add Report Table"
                onCancel={() => setAddReportTableData(false)}
                onSubmit={(name: string | undefined) => handleAddReportTable(name)}
            />
            }
            {deleteReportTableData && budgetYear !== undefined && (
            <DeleteReportTableModal
                reportTableId={deleteReportTableData.id}
                reportTableName={deleteReportTableData.name}
                budgetYear={budgetYear}
                onClose={() => {
                    setDeleteReportTableData(undefined);
                }}
                onConfirm={() => {
                    handleDeleteReportTable(deleteReportTableData.id);
                }}
            />
            )}
        </ThemeProvider>
    );
}