/* eslint-disable @typescript-eslint/no-empty-function */
import MenuCards from '../../../components/menu-cards/MenuCards';
import Renovations from '../../../assets/icons/renovations/renovations.svg';
import Ico from '../../../assets/icons/icone/icon.svg';
import {Button} from 'react-bootstrap';
import {AuthContext} from '../../../contexts/AuthContext';
import React, {useContext} from 'react';
import useAppStore from '../../../hooks/useAppStore';

export interface AdminSettingsProps {
    loggedOut?: () => void,
}

const AdminSettings: React.FC<AdminSettingsProps> = (params: AdminSettingsProps) => {

    const authContext = useContext(AuthContext);
    const appStore = useAppStore();

    /**
     * logout clicked event handler
     */
    async function logoutClicked() {
      await authContext.signOut();
      params.loggedOut?.();
    }

    React.useEffect(()=> {
      appStore.set({isLoading: false});
      return ()=> {};
  }, []);


    return (
      <>
        <Button variant="primary" onClick={logoutClicked}>Logout</Button>
        <MenuCards title="Admistrative Settings"
          cards={[
            {
              title: 'User Management',
              img: Ico,
              url: '',
              alt: '',
            },
            {
              title: 'Employee Positions',
              img: Ico,
              url: '',
              alt: '',
            },
            {
              title: 'Chart of Accounts',
              img: Ico,
              url: '/admin/setup/basic',
              alt: '',
            },
            {
              title: 'Model Assumptions',
              img: Ico,
              url: '/admin/template',
              alt: '',
            },
            {
              title: 'Manage Assumptions',
              img: Ico,
              url: '/admin/manageassumptions',
              alt: '',
            },
            {
              title: 'Summary report generator',
              img: Ico,
              url: '/analyst',
              alt: '',
            },
            {
              title: 'Experimental',
              img: Ico,
              url: '/admin/setup/experimental',
              alt: '',
            },
            {
              title: 'Experimental Multi',
              img: Ico,
              url: '/admin/setup/experimental-multi',
              alt: '',
            },
            {
              title: 'Account Datasource',
              img: Ico,
              url: '/admin/setup/account-datasource',
              alt: '',
            },
            {
              title: 'Account Settings',
              img: Ico,
              url: '',
              alt: '',
            },
            {
              title: 'Renovations',
              img: Renovations,
              url: '',
              alt: 'renovations-img',
            },

          ]}
        />
      </>

    );
};

export default AdminSettings;
