import {ColumnSettings} from "handsontable/settings";
import {Property} from "../../../contexts/properties/PropertiesContext";
import {
    DriverType,
    GetSinglePropertyDriversAndWorksheetItemsQuery,
    GrowthCalcMethod,
    VersionType
} from "../../../__generated__/generated_types";
import {IAccountSummaryData} from "../../../pages/workflows/account/AccountSummaryContext";
import {max} from "lodash";

export enum TAccountTableSummaryFunction {
    SUM = "SUM",
    AVG = "AVG",
}

export type TAccountTableRowData = {
    label: string,
    rowType: "driver" | "versionType" | "versionSummary" | "divider" | "historical" | "generic",
    parentRowId?: string,
    id?: string,
    editable?: boolean,
    hideable?: boolean,
    contributesToSummary?: boolean,
    dataFormat?: "percent" | "currency" | "count",
    driverCalculation?: boolean,
    driverType?: DriverType | "customDriver",
    [key: number]: number | null | undefined,
    rfcstStartMonth?: number,
    summaryFunction?: TAccountTableSummaryFunction,
    context?: Record<string, {[key: number]: number | null | undefined}>;
    contextCustomDriver?: Record<string, string>;
}

export type TAccountTableState = {
    data: TAccountTableRowData[],
    columns: ColumnSettings[],
    property: Property,
    isDriven: boolean,
}

type TDriverAssumptionsAndValues = {
    amount: number | null,
    percentage: number | null,
    lookbackPeriod: number | null,
    metricValue: number | null,
}

type TDriverSourceModel = {
    metricName?: string,
    assumptionsAndValues: TDriverAssumptionsAndValues[],
    sourceMetricId?: string,
    lookbackPeriod: number | null,
    locked?: boolean,
}

type TDriverSourceModelParsedValues = {
    amounts: {
        [key: number]: number | null,
    },
    percentages: {
        [key: number]: number | null,
    },
    counts: {
        [key: number]: number | null,
    },
    results: {
        [key: number]: number | null,
    }
}

export type TDriverSourceMetricCalcValues = {
    amount: number | null,
    percentage: number | null,
    value: number | null,
}
/**
 * Be aware that setting type to numeric triggers the numeric validator to effectively kill
 * browser main thread on a big number of rows.
 * How to reproduce:
 * 1. Set type: "numberic" for all columns below.
 * 2. Add % of Account driver to some arbitrary account in the account view
 * 3. Make sure you have selected at least 200 source accounts for that sake.
 * 4. Try clikging between reforecast/budget and then observe in-responsiveness of the browser
 */
export const placeholderColumns = [
    {data: "label", type: "text", readOnly: true, disableVisualSelection: true},
    {data: 0},
    {data: 1},
    {data: 2},
    {data: 3},
    {data: 4},
    {data: 5},
    {data: 6},
    {data: 7},
    {data: 8},
    {data: 9},
    {data: 10},
    {data: 11},
    {data: "total", readOnly: true},
];

type THistorical = {
    year: number,
    label: string,
    values: number[],
}

export function parseAccountTableData(rawData: GetSinglePropertyDriversAndWorksheetItemsQuery, financialYearValues: IAccountSummaryData, rfcstStartMonth: number, isDriven: boolean): TAccountTableRowData[] {
    const {
        accountPercentageDriver,
        operationalMetricDriver,
        worksheetDriver,
        accountValues,
        overrideValues,
        year,
        versionType,
        growthDriver,
        payrollBreakdown,
        originalValuesRaw,
        customDriver
    } = rawData.singlePropertyAccount;

    const parsedData: TAccountTableRowData[] = [];

    if (customDriver && customDriver.isDriven) {
        for (const item of customDriver.items) {
            const sourceMetric: TDriverSourceModel = {
                metricName: item.itemName,
                lookbackPeriod: 0,
                assumptionsAndValues: item.assumptions.map(x => ({
                    amount: x.amount ?? null,
                    percentage: x.percentOf ?? null,
                    lookbackPeriod: null,
                    metricValue: x.count ?? null,
                })),
                sourceMetricId: item.id,
                locked: false,
            };

            const parsedMetrics =
                parseDriverSourceMetric(
                    sourceMetric.assumptionsAndValues,
                    rfcstStartMonth,
                    DriverType.Operational /* Re-use operational logic here so call it Operational */,
                    versionType);

            const titleLabel = `Custom Driver (${sourceMetric.metricName})`;
            const feeLabel = "Fee Amount";
            const pctLabel = `% of ${sourceMetric.metricName}`;
            const countLabel = `# of ${sourceMetric.metricName}`;

            parsedData.push({
                label: titleLabel,
                id: sourceMetric.sourceMetricId,
                hideable: true,
                rowType: "driver",
                driverType: "customDriver",
                contributesToSummary: true,
                dataFormat: "currency",
                driverCalculation: true,
                rfcstStartMonth,
                summaryFunction: TAccountTableSummaryFunction.SUM,
                ...parsedMetrics.results
            });

            parsedData.push({
                label: feeLabel,
                parentRowId: sourceMetric.sourceMetricId,
                rowType: "driver",
                driverType: "customDriver",
                dataFormat: "currency",
                editable: true,
                rfcstStartMonth,
                summaryFunction: TAccountTableSummaryFunction.AVG,
                ...parsedMetrics.amounts,
                contextCustomDriver: {"customDriverValueType": "amounts"}
            });

            parsedData.push({
                label: pctLabel,
                parentRowId: sourceMetric.sourceMetricId,
                rowType: "driver",
                driverType: "customDriver",
                dataFormat: "percent",
                editable: true,
                rfcstStartMonth,
                summaryFunction: TAccountTableSummaryFunction.AVG,
                ...parsedMetrics.percentages,
                contextCustomDriver: {"customDriverValueType": "percentages"}
            });

            parsedData.push({
                label: countLabel,
                parentRowId: sourceMetric.sourceMetricId,
                rowType: "driver",
                driverType: "customDriver",
                dataFormat: "count",
                editable: true,
                rfcstStartMonth,
                summaryFunction: TAccountTableSummaryFunction.AVG,
                ...parsedMetrics.counts,
                contextCustomDriver: {"customDriverValueType": "counts"}
            });
        }
    }

    if (operationalMetricDriver && operationalMetricDriver.isDriven) {
        for (const metric of operationalMetricDriver.sourceMetrics) {
            if (metric.assumptionsAndValues == undefined) {
                continue;
            }

            const sourceMetric: TDriverSourceModel = {
                metricName: metric.metricName,
                lookbackPeriod: metric.assumptionsAndValues[0]?.lookbackPeriod ?? 0,
                assumptionsAndValues: metric.assumptionsAndValues.map(x => ({
                    amount: x.amount ?? null,
                    percentage: x.percentage ?? null,
                    lookbackPeriod: x.lookbackPeriod ?? null,
                    metricValue: x.metricValue ?? null,
                })),
                sourceMetricId: metric.sourceMetricId,
                locked: metric.locked ?? false,
            };

            const parsedMetrics = parseDriverSourceMetric(sourceMetric.assumptionsAndValues, rfcstStartMonth, DriverType.Operational, versionType);

            let titleLabel = `Op Driver (${sourceMetric.metricName}s)`;
            const feeLabel = "Fee Amount";
            let pctLabel = `% of ${sourceMetric.metricName}s`;
            let countLabel = `# of ${sourceMetric.metricName}s`;

            switch (sourceMetric.metricName) {
                case "Renewal": {
                    pctLabel = "% per Renewal";
                    break;
                }
                case "Occupied": {
                    titleLabel = `Op Driver (Occupied Units)`;
                    pctLabel = "% of Occupied Units";
                    countLabel = `# of Occupied Units`;
                    break;
                }
            }

            parsedData.push({
                label: titleLabel,
                id: sourceMetric.sourceMetricId,
                hideable: true,
                rowType: "driver",
                driverType: DriverType.Operational,
                contributesToSummary: true,
                dataFormat: "currency",
                driverCalculation: true,
                rfcstStartMonth,
                summaryFunction: TAccountTableSummaryFunction.SUM,
                ...parsedMetrics.results,
            });

            parsedData.push({
                label: feeLabel,
                parentRowId: sourceMetric.sourceMetricId,
                rowType: "driver",
                driverType: DriverType.Operational,
                dataFormat: "currency",
                editable: true,
                rfcstStartMonth,
                summaryFunction: TAccountTableSummaryFunction.AVG,
                ...parsedMetrics.amounts,
            });

            parsedData.push({
                label: pctLabel,
                parentRowId: sourceMetric.sourceMetricId,
                rowType: "driver",
                driverType: DriverType.Operational,
                dataFormat: "percent",
                editable: true,
                rfcstStartMonth,
                summaryFunction: TAccountTableSummaryFunction.AVG,
                ...parsedMetrics.percentages,
            });

            parsedData.push({
                label: countLabel,
                parentRowId: sourceMetric.sourceMetricId,
                rowType: "driver",
                driverType: DriverType.Operational,
                dataFormat: "count",
                rfcstStartMonth,
                summaryFunction: TAccountTableSummaryFunction.AVG,
                ...parsedMetrics.counts,
            });
        }
    }

    if (accountPercentageDriver && accountPercentageDriver.isDriven) {
        if (accountPercentageDriver.sourceAccounts.length > 1) {
            const headRow: TAccountTableRowData = {
                label: `% of Multiple Accounts`,
                id: "multi_pct_of_account",
                rowType: "driver",
                hideable: true,
                editable: true,
                driverType: DriverType.AccPercentage,
                // contributesToSummary: true,
                dataFormat: "percent",
                // driverCalculation: true,
                rfcstStartMonth,
                // summaryFunction: TAccountTableSummaryFunction.SUM,
                0: 0,
                1: 0,
                2: 0,
                3: 0,
                4: 0,
                5: 0,
                6: 0,
                7: 0,
                8: 0,
                9: 0,
                10: 0,
                11: 0,
            };
            parsedData.push(headRow);
            const avgs = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
            let count = 0;
            for (const metric of accountPercentageDriver.sourceAccounts) {
                if (metric.assumptionsAndValues) {
                    const sourceMetric: TDriverSourceModel = {
                        metricName: metric.glName,
                        lookbackPeriod: metric.assumptionsAndValues[0]?.lookbackPeriod ?? 0,
                        assumptionsAndValues: metric.assumptionsAndValues.map(x => ({
                            amount: x.accountValue ?? null,
                            percentage: x.percentage ?? null,
                            lookbackPeriod: x.lookbackPeriod ?? 0,
                            metricValue: x.accountValue ?? null,
                        })),
                        sourceMetricId: metric.accountId,
                        locked: metric.locked ?? false,
                    };
                    const parsedMetrics = parseDriverSourceMetric(sourceMetric.assumptionsAndValues, rfcstStartMonth, DriverType.AccPercentage, versionType);
                    parsedData.push({
                        label: sourceMetric.metricName ?? "",
                        id: sourceMetric.sourceMetricId,
                        parentRowId: `multi_pct_of_account`,
                        rowType: "driver",
                        driverType: DriverType.AccPercentage,
                        contributesToSummary: true,
                        dataFormat: "percent",
                        editable: true,
                        rfcstStartMonth,
                        summaryFunction: TAccountTableSummaryFunction.AVG,
                        ...parsedMetrics.percentages,
                        context: {
                            "amounts": parsedMetrics.amounts,
                        }
                    });
                    for (const k of Object.keys(avgs)) {
                        let percent = parsedMetrics.percentages[parseInt(k)];
                        if (!percent) {
                            percent = 0;
                        }
                        avgs[+k] = (avgs[+k] ?? 0) + percent;

                    }
                    count++;
                }
            }
            if (count > 0) {
                Object.entries(avgs).forEach(e => {
                    headRow[parseInt(e[0])] = +(e[1] / count).toFixed(1);
                });
            }
        }
        else
        {
            const augments = accountPercentageDriver.augments;
            const minValue = augments.minValue !== null ? parseFloat(augments.minValue) : null;
            const maxValue = augments.maxValue !== null ? parseFloat(augments.maxValue) : null;
            for (const metric of accountPercentageDriver.sourceAccounts) {
                if (metric.assumptionsAndValues == undefined) {
                    continue;
                }

                const sourceMetric: TDriverSourceModel = {
                    metricName: metric.glName,
                    lookbackPeriod: metric.assumptionsAndValues[0]?.lookbackPeriod ?? 0,
                    assumptionsAndValues: metric.assumptionsAndValues.map(x => ({
                        amount: x.accountValue ?? null,
                        percentage: x.percentage ?? null,
                        lookbackPeriod: x.lookbackPeriod ?? 0,
                        metricValue: x.accountValue ?? null,
                    })),
                    sourceMetricId: metric.accountId,
                    locked: metric.locked ?? false,
                };

                const parsedMetrics = parseDriverSourceMetric(sourceMetric.assumptionsAndValues, rfcstStartMonth, DriverType.AccPercentage, versionType);

                const minMaxResults: {[k: number]: number | null} = {};
                for (let [key, val] of Object.entries(parsedMetrics.results)) {
                    if(val !== null) {
                        if(minValue !== null && val < minValue) {
                            val = minValue;
                        }
                        if(maxValue !== null && val > maxValue) {
                            val = maxValue;
                        }
                    }
                    minMaxResults[+key] = val;
                }

                parsedData.push({
                    label: `% of ${sourceMetric.metricName}`,
                    id: sourceMetric.sourceMetricId,
                    rowType: "driver",
                    hideable: true,
                    driverType: DriverType.AccPercentage,
                    contributesToSummary: true,
                    dataFormat: "currency",
                    driverCalculation: true,
                    rfcstStartMonth,
                    summaryFunction: TAccountTableSummaryFunction.SUM,
                    ...minMaxResults,
                });

                parsedData.push({
                    label: `% of`,
                    parentRowId: sourceMetric.sourceMetricId,
                    rowType: "driver",
                    driverType: DriverType.AccPercentage,
                    dataFormat: "percent",
                    editable: true,
                    rfcstStartMonth,
                    summaryFunction: TAccountTableSummaryFunction.AVG,
                    ...parsedMetrics.percentages,
                });

                parsedData.push({
                    label: `${sourceMetric.metricName} (from ${sourceMetric.lookbackPeriod} ${sourceMetric.lookbackPeriod == 1 ? "month" : "months"} ago)`,
                    parentRowId: sourceMetric.sourceMetricId,
                    rowType: "driver",
                    driverType: DriverType.AccPercentage,
                    dataFormat: "currency",
                    rfcstStartMonth,
                    summaryFunction: TAccountTableSummaryFunction.SUM,
                    ...parsedMetrics.amounts,
                });
            }
        }
    }

    if (payrollBreakdown && payrollBreakdown.some(x => x != 0)) {
        let payrollValues;

        if (versionType == VersionType.Reforecast) {
            payrollValues = payrollBreakdown.slice(0, 12);
            // Zero out values before the reforecast month,
            // to prevent table yearly row summary from summing
            // invisible values.
            for(let i = 0; i < rfcstStartMonth; i++) {
                payrollValues[i] = 0.0;
            }
        } else {
            payrollValues = payrollBreakdown.slice(12);
        }

        parsedData.push({
            label: "Payroll",
            rowType: "generic",
            dataFormat: "currency",
            summaryFunction: TAccountTableSummaryFunction.SUM,
            0: payrollValues[0] ?? 0,
            1: payrollValues[1] ?? 0,
            2: payrollValues[2] ?? 0,
            3: payrollValues[3] ?? 0,
            4: payrollValues[4] ?? 0,
            5: payrollValues[5] ?? 0,
            6: payrollValues[6] ?? 0,
            7: payrollValues[7] ?? 0,
            8: payrollValues[8] ?? 0,
            9: payrollValues[9] ?? 0,
            10: payrollValues[10] ?? 0,
            11: payrollValues[11] ?? 0,
        });
    }

    if (worksheetDriver && worksheetDriver.isDriven) {
        parsedData.push({
            label: "Line Items",
            rowType: "divider",
        });

        for (const line of worksheetDriver.worksheetLines) {

            if(versionType == VersionType.Reforecast) {
                // Zero out values before the reforecast month,
                // to prevent table yearly row summary from summing
                // invisible values.
                for(let i = 0; i < rfcstStartMonth; i++) {
                    line.values[i] = null;
                }
            }

            const lineValues = {
                0: line.values[0] ?? 0,
                1: line.values[1] ?? 0,
                2: line.values[2] ?? 0,
                3: line.values[3] ?? 0,
                4: line.values[4] ?? 0,
                5: line.values[5] ?? 0,
                6: line.values[6] ?? 0,
                7: line.values[7] ?? 0,
                8: line.values[8] ?? 0,
                9: line.values[9] ?? 0,
                10: line.values[10] ?? 0,
                11: line.values[11] ?? 0,
            };

            parsedData.push({
                label: line.description,
                id: line.lineId,
                rowType: "driver",
                editable: true,
                contributesToSummary: true,
                driverType: DriverType.Worksheet,
                dataFormat: "currency",
                rfcstStartMonth,
                summaryFunction: TAccountTableSummaryFunction.SUM,
                ...lineValues,
            });
        }
    }

    if (growthDriver && growthDriver.length > 0) {
        const growthDriverItem = growthDriver[0];

        if (growthDriverItem) {
            let growthDriverName;
            switch (growthDriverItem.growthCalcMethod) {
                case GrowthCalcMethod.AnnualTargetValue: {
                    growthDriverName = "Annual Target Amount";
                    break;
                }
                case GrowthCalcMethod.MonthlyAverage: {
                    growthDriverName = "Monthly Average";
                    break;
                }
                case GrowthCalcMethod.PercentGrowth: {
                    growthDriverName = "% Growth";
                    break;
                }
            }

            const annualValues = [];
            for (let i = 0; i < 12; i++) {
                if (versionType == VersionType.Reforecast) {
                    annualValues.push(i < rfcstStartMonth ? 0 : originalValuesRaw[i]);
                } else {
                    annualValues.push(originalValuesRaw[i]);
                }
            }

            parsedData.push({
                label: growthDriverName,
                rowType: "driver",
                dataFormat: "currency",
                rfcstStartMonth,
                summaryFunction: TAccountTableSummaryFunction.SUM,
                ...annualValues,
            });
        }
    }

    const versionSummaryValues: number[] = [];
    for (let i = 0; i < 12; i++) {
        const overrideValue = overrideValues[i];
        const accountValue = accountValues[i];

        if ((versionType == VersionType.Budget
            || versionType == VersionType.Reforecast
                && i >= rfcstStartMonth)
            && overrideValue != null && overrideValue != undefined && isDriven) {
            versionSummaryValues.push(Math.round(overrideValue));
        } else if (accountValue != null && accountValue != undefined) {
            versionSummaryValues.push(Math.round(accountValue));
        } else {
            versionSummaryValues.push(0);
        }
    }

    parsedData.push({
        label: `${year} ${versionType == 'REFORECAST' ? 'Reforecast' : 'Budget'}`,
        rowType: "versionSummary",
        dataFormat: "currency",
        rfcstStartMonth,
        summaryFunction: TAccountTableSummaryFunction.SUM,
        0: versionSummaryValues[0],
        1: versionSummaryValues[1],
        2: versionSummaryValues[2],
        3: versionSummaryValues[3],
        4: versionSummaryValues[4],
        5: versionSummaryValues[5],
        6: versionSummaryValues[6],
        7: versionSummaryValues[7],
        8: versionSummaryValues[8],
        9: versionSummaryValues[9],
        10: versionSummaryValues[10],
        11: versionSummaryValues[11],
    });

    let previousYearValues;

    if (versionType == "REFORECAST") {
        previousYearValues = financialYearValues.years[year]?.bdgt;
    } else {
        const actVals = financialYearValues.years[year - 1]?.act.slice(0, rfcstStartMonth);
        const fcstVals = financialYearValues.years[year - 1]?.fcst.slice(rfcstStartMonth, 12);
        if (actVals && fcstVals) {
            previousYearValues = actVals.concat(fcstVals);
        }
    }

    parsedData.push({
        label: versionType == "REFORECAST" ? `${year} Budget` : `${year - 1} Reforecast`,
        rowType: "versionType",
        dataFormat: "currency",
        rfcstStartMonth,
        summaryFunction: TAccountTableSummaryFunction.SUM,
        0: previousYearValues?.[0] ?? 0,
        1: previousYearValues?.[1] ?? 0,
        2: previousYearValues?.[2] ?? 0,
        3: previousYearValues?.[3] ?? 0,
        4: previousYearValues?.[4] ?? 0,
        5: previousYearValues?.[5] ?? 0,
        6: previousYearValues?.[6] ?? 0,
        7: previousYearValues?.[7] ?? 0,
        8: previousYearValues?.[8] ?? 0,
        9: previousYearValues?.[9] ?? 0,
        10: previousYearValues?.[10] ?? 0,
        11: previousYearValues?.[11] ?? 0,
    });

    const historicalEntries: THistorical[] = [];
    for (const [historicalYear, values] of Object.entries(financialYearValues.years)) {
        if (values.act.every(x => !x)) {
            continue;
        }

        if (parseInt(historicalYear) < (versionType == VersionType.Reforecast ? year : (year - 1))) {
            historicalEntries.push({
                year: parseInt(historicalYear),
                label: `${historicalYear} Actuals`,
                values: values.act.map(x => x),
            });
        }
    }
    historicalEntries.sort((x, y) => x.year < y.year ? 1 : -1);

    historicalEntries.forEach(historical => {
        parsedData.push({
            label: `${historical.year} Actuals`,
            rowType: "historical",
            dataFormat: "currency",
            summaryFunction: TAccountTableSummaryFunction.SUM,
            0: historical.values[0] ?? 0,
            1: historical.values[1] ?? 0,
            2: historical.values[2] ?? 0,
            3: historical.values[3] ?? 0,
            4: historical.values[4] ?? 0,
            5: historical.values[5] ?? 0,
            6: historical.values[6] ?? 0,
            7: historical.values[7] ?? 0,
            8: historical.values[8] ?? 0,
            9: historical.values[9] ?? 0,
            10: historical.values[10] ?? 0,
            11: historical.values[11] ?? 0,
        });
    });

    return parsedData;
}

function parseDriverSourceMetric(data: TDriverAssumptionsAndValues[], rfcstStartMonth: number, driverType: DriverType, versionType: VersionType): TDriverSourceModelParsedValues {
    const parsedValues: TDriverSourceModelParsedValues = {
        amounts: {},
        percentages: {},
        counts: {},
        results: {},
    };

    // Never include values before the reforecast month,
    // to prevent table yearly row summary from including
    // invisible values.
    const startMonthIndex = versionType == VersionType.Reforecast ? rfcstStartMonth : 0;

    for (let i = startMonthIndex; i < data.length; i++) {
        const monthData = data[i];

        if (monthData != undefined) {
            const parsedAmount = monthData.amount == null ? null : +monthData.amount;
            const parsedPercentage = monthData.percentage == null ? null : +monthData.percentage;
            const parsedCount = monthData.metricValue == null ? null : +monthData.metricValue;
            let parsedResult = null;

            if (parsedAmount != null && parsedPercentage != null && parsedCount != null) {
                if (driverType == DriverType.Operational) {
                    parsedResult = +(parsedAmount * (parsedPercentage / 100) * parsedCount).toFixed();
                } else if (driverType == DriverType.AccPercentage) {
                    parsedResult = +(parsedAmount * (parsedPercentage / 100)).toFixed();
                }
            }

            parsedValues.amounts[i] = parsedAmount == null ? null : parsedAmount;
            parsedValues.percentages[i] = parsedPercentage;
            parsedValues.counts[i] = parsedCount == null ? null : parsedCount;
            parsedValues.results[i] = parsedResult;
        }
    }

    return parsedValues;
}
