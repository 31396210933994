import {
    BudgetComponentType,
    PerformanceMetricModel,
    PropertyPerformanceModel,
    TopCardTagType,
    VersionType
} from "../../../../__generated__/generated_types";
import { TOverviewCardProps } from "../../components/overview-card/OverviewCard";
import { TFocusYear, TValueFormat } from "../../../../global/types";
import { IRentsDataParsed, parseRentsData } from "./rentsData";

interface IPlanningDashboardData {
    overviewCards: TOverviewCardProps[];
    rents: IRentsDataParsed;
}

export function parseDashboardData(data: PropertyPerformanceModel, focusYear: TFocusYear, configuredTopCardTags: TopCardTagType[]): IPlanningDashboardData {

    // Overview Cards _________________________________
    const {
        gross_potential_rent,
        total_revenue,
        total_expenses,
        net_operating_income,
        occupancy,
    } = data.financial_summary;

    const overviewCards:TOverviewCardProps[] = [];

    const parsedGPR = parseAccountOverview(
        'Gross Potential Rent',
        gross_potential_rent,
        focusYear,
        BudgetComponentType.Income,
        'CURRENCY',
        configuredTopCardTags.includes(TopCardTagType.GrossPotentialRent),
    );
    if(parsedGPR){
        overviewCards.push(parsedGPR);
    }

    const parsedRevenue = parseAccountOverview(
        'Total Revenue',
        total_revenue,
        focusYear,
        BudgetComponentType.Income,
        'CURRENCY',
        configuredTopCardTags.includes(TopCardTagType.TotalRevenue),
    );
    if(parsedRevenue){
        overviewCards.push(parsedRevenue);
    }

    const parsedExpenses = parseAccountOverview(
        'Total Expenses',
        total_expenses,
        focusYear,
        BudgetComponentType.Expense,
        'CURRENCY',
        configuredTopCardTags.includes(TopCardTagType.TotalExpenses),
    );
    if(parsedExpenses){
        overviewCards.push(parsedExpenses);
    }

    const parsedNetOpIncom = parseAccountOverview(
        'Net Operating Income',
        net_operating_income,
        focusYear,
        BudgetComponentType.Income,
        'CURRENCY',
        configuredTopCardTags.includes(TopCardTagType.NetOperatingIncome),
    );
    if(parsedNetOpIncom){
        overviewCards.push(parsedNetOpIncom);
    }

    const parsedOccupancy = parseAccountOverview(
        'Occupancy',
        occupancy,
        focusYear,
        BudgetComponentType.Undefined,
        'PERCENT',
        true,
    );
    if(parsedOccupancy){
        overviewCards.push(parsedOccupancy);
    }

    // Rents __________________________________________
    const rents = parseRentsData(data.rent);

    return {
        overviewCards,
        rents,
    };
}

export const parseAccountOverview = (
    label: string,
    metricData: PerformanceMetricModel[],
    focusYear: TFocusYear,
    budgetComponentType: BudgetComponentType,
    valueFormat: TValueFormat,
    topCardTagConfigured: boolean,
): TOverviewCardProps|undefined => {

    const focusYearData = metricData.find(year => year.type === focusYear.type);

    const compareYearType = focusYear.type === VersionType.Budget ? VersionType.Reforecast : VersionType.Budget;
    const compareYearData = metricData.find(year => year.type === compareYearType);

    if(!focusYearData || !compareYearData){
        return;
    }

    return {
        label,
        value: focusYearData.year_total,
        year: focusYearData.year,
        valueFormat,
        budgetComponentType: budgetComponentType,
        topCardTagConfigured,
        compare: {
            year: compareYearData.year,
            value: compareYearData.year_total,
            versionType: compareYearType,
        }
    };
};
