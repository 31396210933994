import React from 'react';
import "./NumberCells.scss";
import {VarianceType} from "../../VarianceType";
import {LineMonthData} from './Line';

interface NumberCellsProps {
    monthNumbers: LineMonthData[];
    total: number;
    referenceTotal: number;
    variance: number;
    variancePercent: number | null;
    isPositiveGood: boolean;
    varianceSelectedValue?: string;
    isReforecast: boolean;
    rowName?: string;
}


export const NumberCells: React.VFC<NumberCellsProps> = (props) => {

    const formatNumber = (value: number) => {
        if (value !== undefined ) {
            if (value > 9999 || value < -9999) {
                return new Intl.NumberFormat().format(Math.round(value));
            } else if (Number.isNaN(value)) {
                return 0;
            } else {
                return Math.round(value);
            }
        }
    };

    return (
        <>
            {props.monthNumbers.map((mn, index) =>
                <td id={`${props.rowName}-${index + 1}`} key={index} className={`month-values ${props.rowName}`}>
                    {formatNumber(mn.value)}
                </td>
            )}
            <td id={`${props.rowName}-total`} className="as-total"> {formatNumber(props.total)} </td>

            {/* TODO: need to pass data to be able to toggle ACTUALS */}
            <td className="actuals"> {formatNumber(props.referenceTotal)} </td>

            {props.varianceSelectedValue === VarianceType.VARIANCE_AMOUNT ?
                <td className={"variance " + ((!props.isPositiveGood && props.variance < 0) || (props.variance >= 0 && props.isPositiveGood) ? "text-eucalyptus" : "text-roman")}>
                    {formatNumber(props.variance)}
                </td>
                :
                <td className={"variance " + (props.variancePercent === null || (!props.isPositiveGood && props.variancePercent < 0) || (props.isPositiveGood && props.variancePercent >= 0) ? "text-eucalyptus" : "text-roman" )}>
                    {`${props.variancePercent === null ? 'n/a' : Math.abs(props.variancePercent as number) ?? 0}`} %
                </td>
            }
        </>
    );
};


