import { useGetAccountNoteQuery, VersionType } from "../../../../../../../../__generated__/generated_types";

export interface UseBudgetNoteProps {
    propertyId: string;
    accountId: string;
    year: number;
}

export type UseBudgetNoteData = {
    text: string;
}

export function useBudgetNoteData(props: UseBudgetNoteProps): UseBudgetNoteData {
    const { data } = useGetAccountNoteQuery({
        variables: {
            propertyId: props.propertyId,
            accountId: props.accountId,
            versionType: VersionType.Budget,
            year: props.year
        },
        fetchPolicy: "network-only"
    });

    return {
        text: data?.queryAccountNote?.text ?? ""
    };
}