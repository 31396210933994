import Handsontable from "handsontable";
import {LICENSES} from "../../../constants/Licenses";
import {MONTHS} from "../../../constants/Months";
import {ColumnSettings} from "handsontable/settings";
import * as css from "../styles/accountTable.module.scss";
import {TAccountTableRowData} from "../data/AccountTableData";
import {VersionType} from "../../../__generated__/generated_types";
import {round} from "../../../utils/math";

export function buildHandsonTableSettings(
    data: TAccountTableRowData[],
    overrides:{[key: number]: (number | null)},
    columns: ColumnSettings[],
    rfcstStartMonth: number,
    versionType: VersionType,
    visibleParentRows: Set<number>,
    isDriven: boolean,
    isLocked: boolean,
): Handsontable.GridSettings {
    return {
        data,
        licenseKey: LICENSES.HandsOnTable,
        colHeaders: ["", ...MONTHS, "Total"],
        columns,
        selectionMode: "range",
        className: "htCenter",
        rowHeaders: false,
        colWidths: [200, 86.4, 86.4, 86.4, 86.4, 86.4, 86.4, 86.4, 86.4, 86.4, 86.4, 86.4, 86.4, 90],
        rowHeights: 50,
        width: "100%",
        height: "500",
        manualRowResize: false,
        manualColumnResize: false,
        stretchH: "none",
        autoColumnSize: false,
        autoRowSize: false,
        viewportRowRenderingOffset: 3,
        disableVisualSelection: ["header"],
        hiddenRows: true,
        copyPaste: {
            pasteMode: "overwrite",
            copyColumnHeaders: false,
            copyColumnGroupHeaders: false,
            copyColumnHeadersOnly: false
        },
        afterGetColHeader: (_, th) => {
            th.className += ` ${css.cellBase}`;
        },
        cells(row, col, prop) {
            // grab the row data and pivot to determine correct cell renderer to apply
            const rowData = data[row];

            if (rowData) {
                if (rowData.rowType == "divider") {
                    this.renderer = "Divider";
                } else if (versionType == VersionType.Budget && prop != "label" && prop != "total" && rowData.rowType == "versionSummary") {
                    this.renderer = "ForecastMonthCalculationOverrideable";
                } else if (versionType != VersionType.Budget && col < rfcstStartMonth + 1 && prop != "label") {
                    this.renderer = "Actuals";
                } else if (col >= rfcstStartMonth + 1 && prop != "total" && rowData.rowType == "versionSummary") {
                    if (isDriven) {
                        this.renderer = "ForecastMonthCalculationOverrideable";
                    } else {
                        this.renderer = "ForecastMonthStandard";
                    }
                } else if ((versionType == VersionType.Reforecast && col >= rfcstStartMonth + 1 || versionType == VersionType.Budget) && prop != "total" && prop != "label" && rowData.rowType == "driver" && rowData.driverCalculation) {
                    this.renderer = "ForecastMonthDriverCalculation";
                } else if ((versionType == VersionType.Reforecast && col >= rfcstStartMonth + 1 || versionType == VersionType.Budget) && rowData.rowType == "driver" && prop != "total" && prop != "label" && rowData.editable) {
                    this.renderer = "ForecastMonthEditableDriver";
                } else if (prop == "total") {
                    this.renderer = "Totals";
                } else {
                    this.renderer = "ViziblyBaseCell";
                }
            } else {
                // by default, still apply our general styling for look/feel consistency
                this.renderer = "ViziblyBaseCell";
            }

            return this;
        },
        // this logic used to be in their respective renderers, but the getSourceData built in method for HoT is too expensive to call per-renderer
        afterRenderer(td, row, col, prop, value, cellProperties) {
            const rowData = data[row];
            const baseClassName = td.className;

            if (rowData) {

                switch (rowData.dataFormat) {
                    case "currency": {
                        let decimalPoints = 0;
                        let pattern = "$0,0";
                        if(rowData.rowType === "driver" && rowData.editable) {
                            pattern = "$0,0.00";
                            decimalPoints = 2;
                        }

                        cellProperties.numericFormat = {
                            pattern: pattern,
                            culture: "en-US"
                        };
                        if (prop != "label" && value != null) {
                            value = round(parseFloat(value), decimalPoints).toFixed(decimalPoints);
                        }
                        Handsontable.renderers.NumericRenderer.apply(cellProperties, [cellProperties.instance, td, row, col, prop, value, cellProperties]);
                        break;
                    }
                    case "percent": {
                        if (prop != "label") {
                            if(rowData.rowType === "driver"){
                                cellProperties.numericFormat = {
                                    pattern: "0,0.0",
                                    culture: cellProperties.numericFormat?.culture ?? "en-US"
                                };
                                Handsontable.renderers.NumericRenderer.apply(cellProperties, [cellProperties.instance, td, row, col, prop, value, cellProperties]);
                            }
                            td.innerHTML += "%";
                        }
                        break;
                    }
                    case "count": {
                        if(prop != "label" && rowData.rowType === "driver") {
                            cellProperties.numericFormat = {
                                pattern: "0,0.00",
                                culture: cellProperties.numericFormat?.culture ?? "en-US"
                            };
                            Handsontable.renderers.NumericRenderer.apply(cellProperties, [cellProperties.instance, td, row, col, prop, value, cellProperties]);
                        }
                        break;
                    }
                }

                if (prop == "label") {
                    td.className += ` ${css.rowHeader}`;

                    if (rowData.hideable) {
                        td.className += ` ${css.parentRow}`;

                        if (visibleParentRows.has(row)) {
                            td.className += ` ${css.parentRowVisible}`;
                        }
                    } else if (rowData.parentRowId) {
                        td.className += ` ${css.childRow}`;
                    }
                }

                switch(cellProperties.renderer) {
                    case "Divider": {
                        switch (rowData.rowType) {
                            case "driver": {
                                td.innerHTML = "";
                                td.className += ` ${css.cellBgGrey}`;
                                cellProperties.disableVisualSelection = true;
                                break;
                            }
                        }
                        break;
                    }
                    case "Actuals": {
                        switch (rowData.rowType) {
                            case "driver": {
                                td.innerHTML = "";
                                td.className += ` ${css.cellBgGrey}`;
                                cellProperties.disableVisualSelection = true;
                                break;
                            }
                        }
                        break;
                    }
                    case "Totals": {
                        if (rowData.summaryFunction == "AVG") {
                            td.className += ` ${css.cellAverage}`;
                        }
                        break;
                    }
                    case "ForecastMonthCalculationOverrideable": {
                        const overridenValue = overrides[col - 1];

                        if (overridenValue != undefined && overridenValue != null && isDriven) {
                            td.className += ` ${css.cellColorMagenta}`;
                        } else {
                            if (isDriven) {
                                td.className += ` ${css.cellColorGreen}`;
                            } else {
                                td.className += ` ${css.cellColorBlue}`;
                            }
                        }
                        break;
                    }
                    case "ForecastMonthEditableDriver":
                    case "ForecastMonthStandard": {
                        if (!isLocked) {
                            td.className += ` ${css.cellColorBlue}`;
                        }
                        break;
                    }
                }
            }

            cellProperties.readOnly = isLocked || cellProperties["forceReadonly"] === true;

            if ((isLocked || cellProperties["forceReadonly"] === true) && prop != "label" && prop != "total") {
                td.className = baseClassName;
            }

            if (rowData?.rowType == "versionSummary" && isDriven) {
                td.className += ` ${css.firstVersionRow}`;
            }
        },
    };
}
