import {
    Maybe,
    PayrollBonusFrequency, PayrollBonusType, PayrollCompensationItemCalculationType,
    PayrollCompensationItemType,
    PayrollCompensationType,
    PayrollEmployeeClassification,
    PayrollModeledEmployeeType,
    PayrollPositionModel,
    PayrollRaiseFrequency, PayrollRaiseType,
    Property,
} from "../../../__generated__/generated_types";

export enum PayrollDrawerSections {
    staffDetails = "staffDetails",
    positionDetails = "positionDetails",
    earnings = "earnings",
    benefits = "benefits",
    taxesAndInsurance = "taxesAndInsurance"
}

// TODO merge it with the enum PayrollDrawerSections and keep a consolidated type
export enum PayrollDrawerSectionsDisplayName {
    staffDetails = "Staff Details",
    positionDetails = "Position Details",
    earnings = "Earnings",
    benefits = "Benefits",
    taxesAndInsurance = "Taxes & Insurance"
}

export interface DropDownOption {
    value: string,
    label: string
}

export const AllPayrollModeledEmployeeTypes: DropDownOption[] = [{
    value: PayrollModeledEmployeeType.Actual,
    label: "Active"
}, {
    value: PayrollModeledEmployeeType.Expected,
    label: "Forecasted"
}];

export const AllEmployeeClassifications: DropDownOption[] = [{
    value: PayrollEmployeeClassification.Employee,
    label: "Full-Time"
}, {
    value: PayrollEmployeeClassification.Contractor,
    label: "Contractor"
}];

export const AllPayrollCompensationType: DropDownOption[] = [{
    value: PayrollCompensationType.Annual,
    label: "Salary"
}, {
    value: PayrollCompensationType.Hourly,
    label: "Hourly"
}];

export const AllPayrollRaiseFrequencyAndType: DropDownOption[] = [{
    value: JSON.stringify([PayrollRaiseFrequency.Annually, PayrollRaiseType.FixedAmount]),
    label: "Annual Recurring ($)"
}, {
    value: JSON.stringify([PayrollRaiseFrequency.Annually, PayrollRaiseType.PercentOfSalary]),
    label: "Annual Recurring (%)"
}, {
    value: JSON.stringify([PayrollRaiseFrequency.Once, PayrollRaiseType.FixedAmount]),
    label: "One-Time ($)"
}, {
    value: JSON.stringify([PayrollRaiseFrequency.Once, PayrollRaiseType.PercentOfSalary]),
    label: "One-Time (%)"
}];

export const AllPayrollBonusFrequencyAndType: DropDownOption[] = [{
    value: JSON.stringify([PayrollBonusFrequency.Annually, PayrollBonusType.FixedAmount]),
    label: "Annual Recurring ($)"
}, {
    value: JSON.stringify([PayrollBonusFrequency.Annually, PayrollBonusType.PercentOfSalary]),
    label: "Annual Recurring (%)"
}, {
    value: JSON.stringify([PayrollBonusFrequency.Once, PayrollBonusType.FixedAmount]),
    label: "One-Time ($)"
}, {
    value: JSON.stringify([PayrollBonusFrequency.Once, PayrollBonusType.PercentOfSalary]),
    label: "One-Time (%)"
}];

export const AllMonths: DropDownOption[] = (() => {
    const _allMonths: DropDownOption[] = [];
    for (let i = 1; i < 13; i++) {
        _allMonths.push({
            value: (i - 1).toString(),
            label: i.toString()
        });
    }
    return _allMonths;
})();

/*export const AllDates: DropDownOption[] = (() => {
    const _allDates: DropDownOption[] = [];
    for (let i = 1; i < 31; i++) {
        _allDates.push({
            value: i.toString(),
            label: i.toString()
        });
    }
    return _allDates;
})();*/

export const AllFebDates: DropDownOption[] = (() => {
    const _allDates: DropDownOption[] = [];
    for (let i = 1; i <= 29; i++) {
        _allDates.push({
            value: i.toString(),
            label: i.toString()
        });
    }
    return _allDates;
})();

export const All30Dates: DropDownOption[] = (() => {
    const _allDates: DropDownOption[] = [];
    for (let i = 1; i <= 30; i++) {
        _allDates.push({
            value: i.toString(),
            label: i.toString()
        });
    }
    return _allDates;
})();

export const All31Dates: DropDownOption[] = (() => {
    const _allDates: DropDownOption[] = [];
    for (let i = 1; i <= 31; i++) {
        _allDates.push({
            value: i.toString(),
            label: i.toString()
        });
    }
    return _allDates;
})();

export const DEFAULT_NEW_EMPLOYEE_ID = "New#Package#UUID";




export enum LocalPayrollEmployeeModelKeys {
    firstName = "firstName",
    lastName = "lastName",
    externalSystemEmployeeId = "externalSystemEmployeeId",
    attemptTabChangeFromPositionDetails = "attemptTabChangeFromPositionDetails",
    type = "type",
    position = "position",
    classification = "classification",
    startDate = "startDate",
    terminationDate = "terminationDate",
    properties = "properties",
    baseCompensationType = "baseCompensationType",
    baseCompensationAnnualizedComponent = "baseCompensationAnnualizedComponent",
    baseCompensationHourlyCompensation = "baseCompensationHourlyCompensation",
    baseCompensationExpectedHoursPerWeek = "baseCompensationExpectedHoursPerWeek",
    raises = "raises",
    raiseFrequencyAndType = "raiseFrequencyAndType",
    raiseAmount = "raiseAmount",
    raiseMonth = "raiseMonth",
    raiseDate = "raiseDate",
    raiseEffectiveStartDate = "raiseEffectiveStartDate",
    onetimeRaiseDate = "onetimeRaiseDate",
    /*clearRaise = "clearRaise",*/
    removeRaise = "removeRaise",
    bonuses = "bonuses",
    bonusFrequencyAndType = "bonusFrequencyAndType",
    bonusAmount = "bonusAmount",
    bonusMonth = "bonusMonth",
    bonusDate = "bonusDate",
    bonusEffectiveStartDate = "bonusEffectiveStartDate",
    onetimeBonusDate = "onetimeBonusDate",
    /*clearBonus = "clearBonus",*/
    removeBonus = "removeBonus",
    overtimes = "overtimes",
    overtimesHourlyRate = "overtimesHourlyRate",
    overtimeHoursPerWeek = "overtimeHoursPerWeek",
    overtimeStartDate = "overtimeStartDate",
    overtimeEndDate = "overtimeEndDate",
    /*clearOvertime = "clearOvertime",*/
    removeOvertime = "removeOvertime",
    compItemsAmount = "compItemsAmount",
    compItemsEffectiveDate = "compItemsEffectiveDate",
    compItemsCompensationCap = "compItemsCompensationCap",
    removeCompItem = "removeCompItem",
    medicalCompItems = "medicalCompItems",
    dentalCompItems = "dentalCompItems",
    visionCompItems = "visionCompItems",
    retirement401KCompItems = "retirement401KCompItems",
    medicareCompItems = "medicareCompItems",
    socialSecurityTax = "socialSecurityTax",
    federalUnemploymentTax = "federalUnemploymentTax",
    stateUnemploymentTax = "stateUnemploymentTax",
    workersCompensation = "workersCompensation",
}

export interface LocalPayrollEmployeeBaseCompModel {
    compensationType: PayrollCompensationType | undefined;
    annualCompensation: string;
    expectedHoursPerWeek: string;
    hourlyCompensation: string;
}

export interface LocalPayrollEmployeeRaiseModel {
    amount: string;
    frequency: PayrollRaiseFrequency | null;
    raiseType: PayrollRaiseType | null;
    raiseDay: string;
    raiseMonth: string;
    effectiveStartDate: string | undefined;
    onetimeRaiseDate: string | undefined;
}

export interface LocalPayrollEmployeeBonusModel {
    amount: string;
    frequency: PayrollBonusFrequency | null;
    bonusType: PayrollBonusType | null;
    bonusDay: string;
    bonusMonth: string;
    effectiveStartDate: string | undefined;
    onetimeBonusDate: string | undefined;
}

export interface LocalPayrollEmployeeOvertimeModel {
    effectiveEndDate: string | undefined;
    effectiveStartDate: string | undefined;
    expectedHoursPerWeek: string;
    hourlyCompensation: string;
}

export interface LocalPayrollCompensationItemModel {
    customName: string;
    id: string;
    type: PayrollCompensationItemType;
}

export interface LocalPayrollEmployeeCompItemModel {
    id: string;
    compensationItem: LocalPayrollCompensationItemModel;
    annualCostCap: string;
    costPerPayPeriod: string;
    effectiveStartDate: string | undefined;
    calculationType: PayrollCompensationItemCalculationType;
}

export interface LocalPayrollEmployeeModel {
    id: string,
    firstName: string,
    firstNameFieldTouched: boolean,
    lastName: string,
    externalSystemEmployeeId: Maybe<string> | undefined,
    type: PayrollModeledEmployeeType | undefined,
    typeTouched: boolean,
    position: PayrollPositionModel | undefined,
    positionTouched: boolean,
    classification: PayrollEmployeeClassification | undefined,
    startDate: string | undefined,
    terminationDate: string | undefined,
    properties: {
        propertyId: string,
        attributionPercentage: string | null | undefined
    }[],
    propertiesTouched: boolean,
    baseComp: LocalPayrollEmployeeBaseCompModel,
    raises: Array<LocalPayrollEmployeeRaiseModel>,
    bonuses: Array<LocalPayrollEmployeeBonusModel>,
    overtimes: Array<LocalPayrollEmployeeOvertimeModel>,
    compItems: Record<PayrollCompensationItemType, Array<LocalPayrollEmployeeCompItemModel>>
}
