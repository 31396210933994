import React, { useState, useContext } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

import { useValidEmail, useValidPassword, useValidUsername } from '../../hooks/UseAuthHooks';

import { AuthContext } from '../../contexts/AuthContext';
import { Email, Password, Username } from './AuthComponents';
import { FormContainer } from './FormContainer';
import { Form, Button } from 'react-bootstrap';
import LoginDesign from './LoginDesign';

const useStyles = makeStyles({
    root: {
        height: '100vh',
    },
});

export interface SignUpProps {
    cancelled?: () => void,
    needVerifyAccount?: () => void
}

const SignUp: React.FC<SignUpProps> = (params: SignUpProps) => {
    const classes = useStyles();

    const { email, setEmail, emailIsValid } = useValidEmail('');
    const { password, setPassword, passwordIsValid } = useValidPassword('');
    const { username, setUsername, usernameIsValid } = useValidUsername('');
    const [error, setError] = useState('');
    const [created, setCreated] = useState(false);
    const [showPassword, setShowPassword] = useState(false);

    const {
        password: passwordConfirm,
        setPassword: setPasswordConfirm,
        passwordIsValid: passwordConfirmIsValid,

    } = useValidPassword('');

    const isValid =
        !emailIsValid ||
        email.length === 0 ||
        !usernameIsValid ||
        username.length === 0 ||
        !passwordIsValid ||
        password.length === 0 ||
        !passwordConfirmIsValid ||
        passwordConfirm.length === 0;

    const authContext = useContext(AuthContext);

    const signUpClicked = async () => {
        try {
            await authContext.signUpWithEmail(username, email, password);
            setCreated(true);
        } catch (err) {
            setError(err.message);
        }
    };

    const signUp = (
        <>
            <Form.Label className="mb-3">
                <span className="welcome-back-text"> Sign Up </span>
            </Form.Label>
            <Email emailIsValid={emailIsValid} setEmail={setEmail} email={email}/>
            <Username usernameIsValid={usernameIsValid} setUsername={setUsername} username={username} />
            <Password label="Password" setPassword={setPassword} password={password} />
            <Password label="Confirm Password" setPassword={setPasswordConfirm} password={password} />

            <div className="d-flex justify-content-between mt-4">
                <Button className="ms-0" variant="primary btn-block" onClick={() => params.cancelled?.()}>
                    Cancel
                </Button>
                <Button
                    type="submit"
                    variant="primary"
                    disabled={isValid}
                    onClick={signUpClicked}
                >
                    Sign Up
                </Button>
            </div>
        </>
    );

    const accountCreated = (
        <>
            <Typography variant="h5">{`Created ${username} account`}</Typography>
            <Typography variant="h6">{`Verfiy Code sent to ${email}`}</Typography>

            <Box m={4}>
                <Button onClick={() => params.needVerifyAccount?.()} color="primary" variant="contained">
                    Send Code
                </Button>
            </Box>
        </>
    );

    return (
        <FormContainer>
            <div className="left-side">
                <div className="left-side-container">
                    <Form >
                        {!created ? signUp : accountCreated}
                    </Form>
                </div>
            </div>
            <LoginDesign />
        </FormContainer>

    );
};

export default SignUp;
