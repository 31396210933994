import { useContext, useEffect, useState } from "react";
import { AuthContext, AuthUser } from "../../../contexts/AuthContext";
import { GuestAuthenticationContext, GuestUser } from "../../../contexts/guest-auth-context/GuestAuthContext";


export function useUserOrGuestContext(): AuthUser | GuestUser | undefined {
    const [user, setUser] = useState<AuthUser | GuestUser | undefined>(undefined);
    const { user: authUser } = useContext(AuthContext);
    const { user: guestUser} = useContext(GuestAuthenticationContext);

    useEffect(() => {
        if(guestUser) {
            setUser(guestUser);
        } else if(authUser) {
            setUser(authUser);
        }
    }, [authUser, guestUser]);

    return user;
}