import { ReactElement, useEffect, useRef, useState } from "react";

import { RevenueType } from "../../../../../../__generated__/generated_types";
import { REVENUE_TYPE_DESCRIPTIONS } from "../../../../../../components/template/types";

import { deleteNodeKeys, TFormulaNodeProps } from "./logic/formulaNode";
import * as css from "./styles/css.module.scss";


type TRevenueFormulaNodeProps = TFormulaNodeProps&{
    revenueType: {
        revenueTypeKey: RevenueType,
        id: string,
    },
}

export function RevenueDriverFxNode(props: TRevenueFormulaNodeProps): ReactElement {
    const [isFocused, setIsFocused] = useState<boolean>(false);

    const wrapperRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (isFocused && wrapperRef.current) {
            wrapperRef.current.focus();
        }
    }, [isFocused]);

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (
                wrapperRef.current &&
                (wrapperRef.current === event.target ||
                wrapperRef.current.contains(event.target as Node))
            ) {
                return;
            }
            setIsFocused(false);
        }

        // Clicking outside the current node should remove focus
        document.addEventListener("mousedown", handleClickOutside);

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        }
    }, []);

    const handleClick = () => {
        setIsFocused(!isFocused);
    };

    const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
        if (
            isFocused && deleteNodeKeys.includes(e.key)
        ) {
                props.fbUpdates.removeRevenueDriver({
                revenueType: props.revenueType.revenueTypeKey,
            });
        }
    };

    const driverName = REVENUE_TYPE_DESCRIPTIONS[props.revenueType.revenueTypeKey];

    // let driverNameRender: string|ReactElement = driverName;
    let nodeClassName = css.fxNodeLocked;

    if(!props.locked){
        // driverNameRender = <a>{driverName}</a>;
        nodeClassName = css.fxNode;
    }

    const className = `${nodeClassName} ${props.className ? props.className : ''} ${css.deletableNode} ${isFocused ? css.focusedNode : ''}`;

    return (
        <div
            className={className}
            onClick={handleClick}
            onKeyDown={handleKeyDown}
            ref={wrapperRef}
            tabIndex={0}
        >
            (Revenue Calculation: {driverName})
        </div>
    );
}
