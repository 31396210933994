import { Button } from "@zendeskgarden/react-buttons";
import { Body, Close, Footer, FooterItem, Header, Modal } from "@zendeskgarden/react-modals";
import { ReactElement } from "react";
import { Inline } from "@zendeskgarden/react-loaders";

export interface IBudgetYearIsAutoUnitTypeUpdateEnabledModal {
    budgetSeason: string,
    targetIsAutoUnitTypeUpdateEnabled: boolean,
    loading: boolean,
    onClose: () => void,
    onConfirm: () => void,
}

export default function BudgetYearProtectionModal(props: IBudgetYearIsAutoUnitTypeUpdateEnabledModal): ReactElement {

    return (
        <Modal onClose={props.onClose}>
            <Header isDanger>
                Confirm {props.targetIsAutoUnitTypeUpdateEnabled ? "enabling" : "disabling"} of Unit Type Auto Loading of Budget Season
            </Header>
            <Body>
                Are you sure you want to {props.targetIsAutoUnitTypeUpdateEnabled ? "enable" : "disable"} the Unit Type Auto Loading for {props.budgetSeason} Budget Season?
                {
                    " When Enabled the new Unit Types from Property Management system will be loaded automatically in a background periodic process. If you don't want to see new unit types appear automatically - keep this setting disabled."
                }
                <br/>
                <br/>
                <b>It is recommended to keep setting disabled for past budget seasons</b>
            </Body>
            <Footer>
                <FooterItem>
                    <Button onClick={props.onClose} isBasic disabled={props.loading}>
                        Cancel
                    </Button>
                </FooterItem>
                <FooterItem>
                    {
                        <Button isPrimary onClick={props.onConfirm} disabled={props.loading}>
                            {
                                props.loading
                                    ? <Inline size={24} aria-label="loading"/>
                                    : <span>Confirm</span>
                            }
                        </Button>
                    }
                </FooterItem>
            </Footer>
            <Close aria-label="Close modal" />
        </Modal>
    );
}