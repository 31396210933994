import { Well } from "@zendeskgarden/react-notifications";
import { Children, ReactElement, ReactNode } from "react";
import * as css from './styles/styles.module.scss';

interface MetricCardProps {
    children?: ReactElement[] | ReactElement,
    wide?: boolean,
}

interface MetricCardTitleProps {
    children: ReactNode[] | ReactNode
}

interface MetricCardBodyProps {
    children: ReactNode[] | ReactNode,
    className?: string,
}

function Title(props: MetricCardTitleProps): ReactElement {
    return (
        <div className={css.metricCardTitle}>
            {props.children}
        </div>
    );
}

function Body(props: MetricCardBodyProps): ReactElement {
    return (
        <div className={`${css.metricCardBody} ${props.className}`}>
            {props.children}
        </div>
    );
}

export function MetricCard(props: MetricCardProps): ReactElement {
    let title = <></>;
    let body = <></>;

    if (props.children) {
        Children.forEach(props.children, child => {
            if (child.type === Title) {
                title = child;
            }
            if (child.type === Body) {
                body = child;
            }
        });
    }

    return (
        <div className={css.metricCardWrapper}>
            <Well className={css.metricCard}>
                {title}
                {body}
            </Well>
        </div>
    );
}

MetricCard.Title = Title;
MetricCard.Body = Body;