import GC from '@grapecity/spread-sheets';
import { FinancialEntityCell } from "../../../components/spreadsheet/spreadjs-custom-cell-types/FinancialEntityCell";
import { AnalystCfg } from "../AnalystCfg";
import { RowEntityType } from "../enums";

export const isExpandableCategoryCell = (row: number, col: number, sheet: GC.Spread.Sheets.Worksheet): boolean => {
    const thisCell = sheet.getCell(row, col);
    const cellType = thisCell.cellType();
    return cellType && cellType.typeName === 'ExpandableCategoryCell';
};

export const isFinancialEntityCell = (row: number, col: number, sheet: GC.Spread.Sheets.Worksheet): boolean => {
    const thisCell = sheet.getCell(row, col);
    const cellType = thisCell.cellType();
    return cellType && cellType.typeName === 'FinancialEntityCell';
};

export const isToggleIconCell = (row: number, col: number, sheet: GC.Spread.Sheets.Worksheet): boolean => {
    const thisCell = sheet.getCell(row, col);
    const cellType = thisCell.cellType();
    return cellType && cellType.typeName === 'ToggleIconCell';
};

export const isRenovationPackageCell = (row: number, sheet: GC.Spread.Sheets.Worksheet): boolean => {
    if(isFinancialEntityCell(row, AnalystCfg.FINANCIAL_ENTITY_COL, sheet)){
        const labelCell = sheet.getCell(row, AnalystCfg.FINANCIAL_ENTITY_COL);
        const { rowEntityType } = (labelCell.cellType() as FinancialEntityCell).getCellVars();
        return rowEntityType == RowEntityType.RENOVATION_PACKAGE;
    } else {
        return false;
    }
};
