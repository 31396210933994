import { useEffect, useState } from 'react';
import { FinancialEntity, useChartOfAccounts } from "../../../contexts/chartofaccounts/ChartOfAccountsContext";
import { Property, useProperties } from "../../../contexts/properties/PropertiesContext";
import { useSettings } from "../../../contexts/settings/SettingsContext";
import { useVersions } from "../../../contexts/versions/VersionsContext";
import useGetMonths from "../../../hooks/UseGetMonths";
import { DriverType, useGetAnalystDriversLazyQuery, VersionType } from '../../../__generated__/generated_types';

export type DriverInfo = {
    key: string; // <account id>#<property id>
    reforecastDrivers: DriverType[];
    budgetDrivers: DriverType[];
}

export type Config = {
    year: number;
    startReforecastMonthIndex: number;
    months: string[];
    isReady: boolean;
    actualVersionId: string;
    reforecastVersionId: string;
    nextYearBudgetVersionId: string;
    properties: Property[];
    chartOfAccounts: FinancialEntity[];
    chartOfAccountsId: string;
    chartOfAccountsFlat: FinancialEntity[];
    drivers: Record<string, DriverInfo>; // keyed by DriverInfo.key
    currentProperty?: Property;
}

const DEFAULT_CONFIG: Config = {
    year: 0,
    startReforecastMonthIndex: 0,
    months: [],
    isReady: false,
    actualVersionId: '',
    reforecastVersionId: '',
    nextYearBudgetVersionId: '',
    properties: [],
    chartOfAccounts: [],
    chartOfAccountsId: '',
    chartOfAccountsFlat: [],
    drivers: {},
};

export function useConfig() {

    const [config, setConfig] = useState<Config>(DEFAULT_CONFIG);

    const { year, startReforecastMonthIndex, isDefaults: isDefaultSettings } = useSettings();
    const months = useGetMonths();
    const {
        chartOfAccountsId,
        chartOfAccounts,
        loading,
        chartOfAccountsFlat,
        isReady: isChartOfAccountsReady
    } = useChartOfAccounts();
    const { properties, currentProperty } = useProperties();
    const { getModelVersions, versions } = useVersions();

    // TODO: if you ever going to restore planning hub financials - move this to the financials code
    // const [getAnalystDrivers, {
    //     data: driversData,
    //     loading: driversLoading,
    //     client: driversClient
    // }] = useGetAnalystDriversLazyQuery();

    const isConfigReady =
        isChartOfAccountsReady &&
        chartOfAccounts &&
        chartOfAccountsFlat &&
        !loading &&
        properties &&
        versions &&
        months &&
        !isDefaultSettings;
        // &&
        // !driversLoading &&
        // driversData;

    // useEffect(
    //     () => {
    //         if(!properties){
    //             return;
    //         }
    //         getAnalystDrivers({
    //             variables: {
    //                 propertyIds: properties.map(p => p.id)
    //             }
    //         });
    //     },
    //     [properties]
    // );

    useEffect(
        () => {
            // && properties  && chartOfAccounts && chartOfAccountsFlat - to not make compiler complain about properties being possibly undefined
            if(isConfigReady && properties && chartOfAccounts && chartOfAccountsId && chartOfAccountsFlat
                // && driversData
            ){
                const { actualVersionId, reforecastVersionId, nextYearBudgetVersionId } = getModelVersions(year);

                const drivers: Record<string, DriverInfo> = {};

                // const groupAccountProperty = driversData.analystDrivers.groupBy(d => `${d.accountV2Id}#${d.propertyId}`);
                // const byVersionType = Object.entries(groupAccountProperty).toIdMap(([key]) => key, ([, value]) => value.groupBy("versionType"));
                // Object.entries(byVersionType).forEach(
                //     ([key, value]) => {
                //         drivers[key] = {
                //             key,
                //             reforecastDrivers: value[VersionType.Reforecast]?.map(row => row.driverType) ?? [],
                //             budgetDrivers: value[VersionType.Budget]?.map(row => row.driverType) ?? []
                //         };
                //     }
                // );


                setConfig({
                    year,
                    startReforecastMonthIndex,
                    months,
                    isReady: true,
                    actualVersionId,
                    reforecastVersionId,
                    nextYearBudgetVersionId,
                    properties,
                    chartOfAccounts,
                    chartOfAccountsId,
                    chartOfAccountsFlat,
                    drivers,
                    currentProperty,
                });
            }
        },
        [isConfigReady]
    );

    return config;
}
