import { ReactElement, useEffect, useState } from "react";
import { Dropdown, Multiselect, Field, Menu, Item } from '@zendeskgarden/react-dropdowns';
import Downshift from "downshift";
import { Button } from "@zendeskgarden/react-buttons";
import * as css from "../styles/bulkUpdate.module.scss";
import { Tag } from "@zendeskgarden/react-tags";
import { TAccountDropdownItem } from "./AccountLockBulkUpdater";

export interface IAccountDropdownProps {
    selectedAccounts: string[];
    accounts: TAccountDropdownItem[];
    onAccountSelect: (selected: string[]) => void;
}

export default function AccountDropdown({ selectedAccounts, accounts, onAccountSelect }: IAccountDropdownProps): ReactElement {
    const [inputValue, setInputValue] = useState("");
    const [flattenedAccounts, setFlattenedAccounts] = useState<TAccountDropdownItem[]>([]);
    const [filteredAccounts, setFilteredAccounts] = useState<TAccountDropdownItem[]>(flattenedAccounts);

    useEffect(() => {
        setFlattenedAccounts(flattenAccounts(accounts));
    }, [accounts]);

    useEffect(() => {
        setFilteredAccounts(flattenedAccounts);
    }, [flattenedAccounts]);

    useEffect(() => {
        setFilteredAccounts(flattenedAccounts.filter(a => a.name.toLowerCase().includes(inputValue.trim().toLowerCase())))
    }, [inputValue]);

    const stateReducer = (state: any, changes: any) => {
        switch (changes.type) {
            case Downshift.stateChangeTypes.keyDownEnter:
            case Downshift.stateChangeTypes.clickItem:
                updateSelections(changes.selectedItem);

                return {
                    ...changes,
                    highlightedIndex: state.highlightedIndex,
                    isOpen: true,
                    inputValue: '',
                };
            default:
                return changes;
        }
    };

    function updateSelections(id: string) {
        const childIds = getChildAccountIds(id);

        if (selectedAccounts.includes(id)) {
            onAccountSelect(selectedAccounts.filter(a => ![...childIds, id].includes(a)));
        } else {
            onAccountSelect([...selectedAccounts, ...childIds, id].dedupe());
        }
    }

    function getChildAccountIds(accountId: string | undefined): string[] {
        if (!accountId) {
            return [];
        }

        const childIds: string[] = [];

        function findChildren(currentParentId: string) {
            const children = flattenedAccounts.filter(a => a.parentId === currentParentId);

            for (const child of children) {
                childIds.push(child.id);
                findChildren(child.id);
            }
        }

        findChildren(accountId);

        return childIds;
    }

    function flattenAccounts(arr: TAccountDropdownItem[]) {
        return arr.reduce<TAccountDropdownItem[]>((flatArray, item) => {
            if (Array.isArray(item.children) && item.children.length > 0) {
                flatArray.push(item, ...flattenAccounts(item.children));
            } else {
                flatArray.push(item);
            }
            return flatArray;
        }, []);
    }

    return (
        <Dropdown
            inputValue={inputValue}
            selectedItems={selectedAccounts}
            downshiftProps={{ defaultHighlightedIndex: 0, stateReducer }}
            onInputValueChange={value => setInputValue(value)}
        >
            <Field>
                <Multiselect
                    placeholder="Select Accounts"
                    renderItem={({ value }: any) => (
                        <Tag>
                            <span>{flattenedAccounts.find(a => a.id == value)?.name}</span>
                            <Tag.Close onClick={() => {
                                updateSelections(value);
                            }} />
                        </Tag>
                    )}
                />
            </Field>
            <Menu>
                {inputValue == "" &&
                    <Button
                        className={css.propertyDropdownSelectAll}
                        isBasic
                        onClick={() => onAccountSelect(
                            selectedAccounts.length === flattenedAccounts.length ? [] : flattenedAccounts.map(a => a.id)
                        )}
                    >
                        {selectedAccounts.length === flattenedAccounts.length ? "Deselect All" : "Select All"}
                    </Button>
                }

                {(inputValue != "" && filteredAccounts.length == 0) &&
                    <Item className={css.propertyDropdownItem} disabled>No items found</Item>
                }

                {filteredAccounts.map(a => (
                    <Item key={a.id + a.parentId} value={a.id} className={css.propertyDropdownItem} style={{ paddingLeft: `${a.depth * 20 + 36}px` }}>
                        <span>{a.name}</span>
                    </Item>
                ))}
            </Menu>
        </Dropdown>
    );
}
