import { ChangeEvent, ReactElement, useEffect, useState } from "react";
import { Input } from "@zendeskgarden/react-forms";
import { IconButton } from "@zendeskgarden/react-buttons";
import * as css from "./styles/customItems.module.scss";
import { toast } from "react-toastify";
import { ThemeProvider } from "@zendeskgarden/react-theming";
import AdminHeader from "../admin/AdminHeader";
import { ViziblyTheme } from "../analyst/ViziblyZDGTheme";
import Card from "../simplified-revenue/components/Card";
import useAppStore from "../../hooks/useAppStore";
import {ReactComponent as RevertIcon} from '@zendeskgarden/svg-icons/src/16/edit-undo-stroke.svg';
import {ReactComponent as ConfirmIcon} from '@zendeskgarden/svg-icons/src/16/check-lg-stroke.svg';
import {ReactComponent as PlusIcon} from '@zendeskgarden/svg-icons/src/16/plus-fill.svg';
import {ReactComponent as XIcon} from '@zendeskgarden/svg-icons/src/16/x-stroke.svg';
import { useAddCustomItemMutation, useDeleteCustomItemMutation, useQueryCustomItemsLazyQuery, useUpdateCustomItemMutation } from "../../__generated__/generated_types";

export type ItemData = {
    id: string
    name: string
}

type ItemProps = {
    item?: ItemData,
    style?: React.CSSProperties,
    isAdd?: boolean,
    disabled?: boolean,
    handleSave: (name: string) => void,
    handleDelete?: () => void,
    handleFocus?: () => void,
    handleBlur?: () => void
}

function Item({
    item,
    style,
    isAdd,
    disabled,
    handleSave,
    handleDelete,
    handleFocus,
    handleBlur
}: ItemProps): ReactElement {
    const [name, setName] = useState(item?.name);
    const [isFocused, setIsFocused] = useState(false);
    const saveDisabled = disabled || !name || name.trim().length < 2;

    function _handleSave() {
        if (name) {
            handleSave(name);
            if (isAdd) {
                setName(undefined);
            }
        }
    }

    function _handleDelete() {
        handleDelete?.();
    }

    function _handleBlur() {
        handleBlur?.();
        setIsFocused(false);
    }

    function _handleFocus() {
        handleFocus?.();
        setIsFocused(true);
    }

    function _handleRevert() {
        setName(item?.name);
    }

    const handleNameSubmit = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.code === "Enter") {
            if (!saveDisabled) {
                e.preventDefault();
                e.stopPropagation();
                _handleSave();
            }
        } else if (e.code === "Escape") {
            e.preventDefault();
            e.stopPropagation();
            _handleRevert();
        }
    };

    useEffect(() => {
          setName(item?.name);
        },
        [item]
    );

    return (
        <div
            style={style}
            className={css.item}
        >
            {isAdd &&
            <IconButton size="small" isPrimary onClick={() => _handleSave()} disabled={saveDisabled}>
                <PlusIcon/>
            </IconButton>
            }
            <Input
                isBare
                placeholder="Add Item"
                value={name ?? ""}
                disabled={disabled}
                onChange={(e: ChangeEvent<HTMLInputElement>) => setName(e.target.value)}
                onFocus={() => _handleFocus()}
                onBlur={() => _handleBlur()}
                onKeyDown={(e) => handleNameSubmit(e)}
            />
            <IconButton size="small" onClick={() => _handleRevert()} disabled={saveDisabled || name == item?.name}>
                <RevertIcon/>
            </IconButton>
            {!isAdd &&
            <IconButton isPrimary={isFocused} onClick={() => _handleSave()} disabled={saveDisabled || name == item?.name}>
                <ConfirmIcon/>
            </IconButton>
            }
            {!isAdd &&
            <IconButton isDanger onClick={() => _handleDelete()}>
                <XIcon/>
            </IconButton>
            }
        </div>
    );
}


export function CustomItems(): ReactElement {
    const appStore = useAppStore();

    const [items, setItems] = useState<ItemData[]>([]);
    const [focusedId, setFocusedId] = useState<string>();

    const [queryItems, {data: itemsData, loading: itemsLoading}] = useQueryCustomItemsLazyQuery({
        fetchPolicy: "no-cache"
    });

    const [addCustomItem] = useAddCustomItemMutation({
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        onCompleted(data) {
            if (data.addCustomItem) {
                toast.success(`Item added`);
            }
            queryItems();
        }
    });

    const [deleteCustomItem] = useDeleteCustomItemMutation({
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        onCompleted(data) {
            if (data.deleteCustomItem) {
                toast.success(`Item deleted`);
            }
            queryItems();
        }
    });

    const [updateCustomItem] = useUpdateCustomItemMutation({
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        onCompleted(data) {
            if (data.updateCustomItem.success) {
                toast.success(`Item updated`);
            }
            else if (data.updateCustomItem.errorCode == "ERROR_ITEM_ALREADY_EXISTS") {
                toast.error(`Failed. Item${data.updateCustomItem.name ? ` ${data.updateCustomItem.name}` : " "} already exists`);
            }
            queryItems();
        }
    });


    useEffect(
        () => {
            queryItems();
            appStore.set({ isLoading: false });
            return () => undefined;
        },
        []
    );

    useEffect(() => {
        if (itemsData && !itemsLoading) {
            const items: ItemData[] = [];
            for (const item of itemsData.queryCustomItems) {
                items.push({
                    id: item.id,
                    name: item.name
                });
            }
            setItems(items.sortBy("name"));
        }
    }, [itemsData, itemsLoading]);

    function handleSave(item: ItemData) {
        updateCustomItem({variables: {id: item.id, name: item.name}});
    }

    function handleAdd(name: string) {
        addCustomItem({variables: {name}});
    }


    function handleDelete(id: string) {
        deleteCustomItem({variables: {id}});
    }

    return (
        <ThemeProvider theme={ViziblyTheme}>
            <div className={css.wrapper}>
                <AdminHeader
                    title={"Custom Item Management"}
                    subtitle={"Add/Remove/Edit custom items that are prsented for Custom Driver creation"}
                />
                <Card
                    title={"Items"}
                >
                    <>
                        <Item
                            isAdd
                            handleSave={(name: string) => {
                                handleAdd(name);
                            } }
                        />
                        {items.map(item =>
                            <Item
                                key={item.id}
                                item={item}
                                handleSave={(name: string) => {
                                    handleSave({...item, name: name});
                                } }
                                handleDelete={() => handleDelete(item.id)}
                                handleFocus={() => setFocusedId(item.id)}
                                handleBlur={() => setFocusedId(undefined)}
                                disabled={Boolean(focusedId) && item.id != focusedId}
                            />
                        )}
                    </>
                </Card>
            </div>
        </ThemeProvider>
    );
}