import React, {useEffect, useState} from 'react';
import {
    EntityType,
    GetNoteDocument,
    useGetNoteQuery,
    useUpsertNoteMutation,
    VersionType,
} from '../../__generated__/generated_types';
import ModalWindow from '../modal/ModalWindow';
import './AccountNote.module.scss';
import {IconButton} from "@zendeskgarden/react-buttons";

import {ReactComponent as NotesStroke} from '@zendeskgarden/svg-icons/src/16/notes-stroke.svg';
import {ReactComponent as NotesFill} from '@zendeskgarden/svg-icons/src/16/notes-fill.svg';
import {Tooltip} from "@zendeskgarden/react-tooltips";

import * as css from "./AccountNote.module.scss";

interface NoteProps {
    propertyId: string | null | undefined;
    year: number;
    versionType: VersionType;
    accountId: string;
}

export const AccountNote: React.VFC<NoteProps> = (props) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [noteTextValue, setNoteTextValue] = useState<string>("");

    const { data: note } = useGetNoteQuery({
        variables: {
            propertyId: props.propertyId ?? "",
            year: props.year,
            versionType: props.versionType,
            entityId: props.accountId,
            entityType: EntityType.Account
        },
        fetchPolicy: "cache-first" // first fetch the note from cache, if it doesn't exist, fetch from server
    });

    const [upsertNote] = useUpsertNoteMutation({
        awaitRefetchQueries: true,
        refetchQueries: [{
            query: GetNoteDocument, variables: {
                propertyId: props.propertyId ?? "",
                year: props.year,
                versionType: props.versionType,
                entityId: props.accountId,
                entityType: EntityType.Account
            }
        }]
    });

    useEffect(
        () => {
            if (note && note.queryNote) {
                setNoteTextValue(note.queryNote.text ?? "");
            } else {
                setNoteTextValue("");
            }
        }
        ,[note]
    );

    const handleCloseModal = () => {
        setIsModalOpen(false);
    };

    const handleSaveNote = async () => {
        if(props.propertyId) {
            await upsertNote({
                variables: {
                    text: noteTextValue,
                    entityId: props.accountId,
                    entityType: EntityType.Account,
                    versionType: props.versionType,
                    propertyId: props.propertyId,
                    year: props.year
                },
            });
            setNoteTextValue(noteTextValue);
            handleCloseModal();
        }
    };

    return (
        <div className="justify-content-center pe-2">
            <Tooltip content={note?.queryNote?.text ? 'Read Note' : 'Add Note'}>
                <IconButton
                    className={note?.queryNote?.text ? css.accountViewNoteButton : ''}
                    onClick={() => setIsModalOpen(true)}
                    aria-label="Create Note"
                >
                    {note?.queryNote?.text ? <NotesFill />: <NotesStroke />}
                </IconButton>
            </Tooltip>
             <ModalWindow
                isOpen={isModalOpen}
                primaryText="Update"
                title="Note"
                onClose={handleCloseModal}
                onSave={handleSaveNote}
                canCancel={false}
                canConfirm={true}
                hasBorders={false}
            >
                <textarea
                    className="accountNoteTextArea ps-2 pt-2"
                    cols={30}
                    rows={7}
                    defaultValue={noteTextValue}
                    placeholder="Add a note..."
                    onChange={event => setNoteTextValue(event.target.value)}
                >
                </textarea>
            </ModalWindow>
        </div>
    );
};
