/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-empty-function */
import React, {ReactElement, useContext, useEffect, useMemo} from 'react';
import {Redirect, Route, useLocation, useParams} from 'react-router-dom';
import Account from '../../../pages/workflows/account/Account';
import {useRoutingUtils} from '../../RoutingUtils';
import {useChartOfAccounts} from '../../../contexts/chartofaccounts/ChartOfAccountsContext';
import AverageRent from '../../../pages/workflows/operational/average-rent/AverageRent';
import EarlyTerminations from '../../../pages/workflows/operational/early-terminations/EarlyTerminations';
import LeaseExpirations from '../../../pages/workflows/operational/lease-expiration/LeaseExpiration';
import MonthToMonthMoveOuts from '../../../pages/workflows/operational/month-to-month-move-outs/MonthToMonthMoveOuts';
import MoveOutRates from '../../../pages/workflows/operational/move-outs/MoveOutRates';
import OccupancyRates from '../../../pages/workflows/operational/occupancy-rates/OccupancyRates';
import RenewalRates from '../../../pages/workflows/operational/renewal-rates/RenewalRates';
import RenewalRatios from '../../../pages/workflows/operational/renewal-ratios/RenewalRatios';
import Error from '../../../components/error/Error';
import {REVENUE_SOURCE_DESCRIPTIONS, RevenueSource} from '../../../constants/RevenueWorkflow';
import useAppStore from '../../../hooks/useAppStore';
import Skeleton from '../../../components/dashboard';
import "native-injects";
import AccountRenovations from "../../../pages/workflows/renovations/AccountRenovations";
import AccountPayroll from "../../../pages/workflows/payroll/AccountPayroll";
import {propertyHasRevenueModel, useProperties} from "../../../contexts/properties/PropertiesContext";
import {AuthorizationContext} from "../../../authorization/AuthorizationContext";
import {Authorizations} from "../../../authorization/Authorizations";
import ReadOnlyHeader from "../../../components/navbar/ReadOnlyHeader";
import {RenderIfReadOnly} from "../../../authorization/RenderIfAuthorized";

import * as navStyles from "../../../components/navbar/styles.module.scss";
import {useRenovationsWorkflow} from "../../../hooks/useRenovationsWorkflow";
import {IWorkflowPageProps} from "../../../pages/workflows/logic/workflows";
import LeaseExpirationSchedule from "../../../pages/simplified-revenue/LeaseExpirationSchedule";
import MarketRent from "../../../pages/simplified-revenue/MarketRent";
import Summary from "../../../pages/simplified-revenue/Summary";
import {RevenueModelType} from "../../../__generated__/generated_types";
import {SIMPLIFIED_REVENUE_ROUTES} from "./AccountRouteConstants";
import RenewalTradeOut from "../../../pages/simplified-revenue/RenewalTradeOut";
import RenewalRatio from "../../../pages/simplified-revenue/RenewalRatio";
import Renovations from '../../../pages/simplified-revenue/Renovations';
import VacancyRate from '../../../pages/simplified-revenue/VacancyRate';
import { RevenueSummaryV2 } from "../../../pages/workflows/operational/summary/RevenueSummaryV2";

interface AccountRouteProps {
    parentPath: AccountRouteParentComponents,
}

export enum AccountRouteParentComponents {
    OPERATIONAL = "/operational",
    RENOVATIONS = "/renovations",
    PAYROLL = "/payroll",
    ACCOUNT = "/account",
    // This must be kept in sync with the value in AccountRouteConstants.ts
    SIMPLIFIED_REVENUE = "/simplified-revenue"
}



const OPERATIONAL_COMPONENTS = {
    [RevenueSource.AVG_MARKET_RENTS]: AverageRent,
    [RevenueSource.EXPIRATIONS]: LeaseExpirations,
    [RevenueSource.RENEWALS_RATIO]: RenewalRatios,
    [RevenueSource.RENEWALS_RATE]: RenewalRates,
    [RevenueSource.MOVE_OUTS]: MoveOutRates,
    [RevenueSource.EARLY_TERMS]: EarlyTerminations,
    [RevenueSource.MONTH_TO_MONTH]: MonthToMonthMoveOuts,
    [RevenueSource.OCCUPANCY]: OccupancyRates,
    [RevenueSource.SUMMARY]: RevenueSummaryV2,
};

const PAGE_TO_KEY = Object.entries(REVENUE_SOURCE_DESCRIPTIONS)
    .toIdMap(([_, row]) => row.path, ([key]) => key as RevenueSource);


interface OperationalProps extends IWorkflowPageProps{
    page: string;
}

function OperationalRoute(props: OperationalProps): ReactElement {
    const key = PAGE_TO_KEY[props.page];
    const Component = key ? OPERATIONAL_COMPONENTS[key] : undefined;

    const memoizedComponent = useMemo(() => {
        if(Component){
            return <Component onNextClick={props.onNextClick} onPreviousClick={props.onPreviousClick} key={Component?.name}/>;
        }
    }, []);

    if(!Component || !memoizedComponent){
        return <Error message="Page not found"/>;
    }


    return memoizedComponent;
}

export const AccountRoute: React.FC<AccountRouteProps> = ({parentPath}) => {
    const appStore = useAppStore();

    const {
        id: currentAccountId,
        page: routeParamPage,
        type: routeParamType
    } = useParams() as {
        id: string;
        page: string;
        type: string | undefined
    };
    const {pathname} = useLocation();

    const categoryType = routeParamType || appStore.categoryType;

    const {hasAuthorization} = useContext(AuthorizationContext);
    const {accounts, getPrevAccountId, getNextAccountId} = useChartOfAccounts();
    const {goto} = useRoutingUtils();

    const {currentProperty} = useProperties();
    const {renovationsWorkflow} = useRenovationsWorkflow({categoryType});

    React.useEffect(() => {
        appStore.set({isLoading: false});
        return () => {
        };
    }, []);

    useEffect(() => {
        if(!currentProperty) {
            return;
        }

        const path = pathname ?? "";
        if(parentPath === AccountRouteParentComponents.OPERATIONAL ||
           parentPath === AccountRouteParentComponents.RENOVATIONS){
            if(
                propertyHasRevenueModel(currentProperty, RevenueModelType.UnitLevel) &&
                !path.startsWith(AccountRouteParentComponents.ACCOUNT)
            ) {
                const firstAccountId = accounts?.[0]?.id;
                goto(`${AccountRouteParentComponents.ACCOUNT}/${firstAccountId}/${categoryType}`)();
            } else if(
                propertyHasRevenueModel(currentProperty, RevenueModelType.Simplified) &&
                !path.startsWith(AccountRouteParentComponents.SIMPLIFIED_REVENUE)
            ) {
                goto(`${AccountRouteParentComponents.SIMPLIFIED_REVENUE}/${SIMPLIFIED_REVENUE_ROUTES[0]}`)();
            }
        }
        if(parentPath === AccountRouteParentComponents.SIMPLIFIED_REVENUE) {
            if(
                propertyHasRevenueModel(currentProperty, RevenueModelType.UnitLevel) &&
                !path.startsWith(AccountRouteParentComponents.ACCOUNT)
            ) {
                const firstAccountId = accounts?.[0]?.id;
                goto(`${AccountRouteParentComponents.ACCOUNT}/${firstAccountId}/${categoryType}`)();
            } else if(
                propertyHasRevenueModel(currentProperty, RevenueModelType.Original) &&
                !path.startsWith(AccountRouteParentComponents.OPERATIONAL)
            ) {
                const current = REVENUE_SOURCE_DESCRIPTIONS[RevenueSource.AVG_MARKET_RENTS];
                goto(`${AccountRouteParentComponents.OPERATIONAL}/${current.path}/${categoryType}`)();
            }
        }
    }, [parentPath, currentProperty, pathname]);

    function goBackRequested() {
        const isPayrollAvailableForCurrentUser = hasAuthorization(Authorizations.PlanningHubPayroll);
        if(parentPath === AccountRouteParentComponents.ACCOUNT){
            const prevAccountId = getPrevAccountId(currentAccountId);
            if(prevAccountId){
                return goto(`/account/${prevAccountId}/${categoryType}`)();
            }
            if(currentProperty?.unitLevelModelingEnabled){
                const lastAccountId = accounts?.[accounts?.length - 1]?.id;
                return goto(`${AccountRouteParentComponents.ACCOUNT}/${lastAccountId}/${categoryType}`)();
            }
            if(isPayrollAvailableForCurrentUser){
                return goto(AccountRouteParentComponents.PAYROLL)();
            }
        }
        if(parentPath === AccountRouteParentComponents.ACCOUNT ||
            parentPath === AccountRouteParentComponents.PAYROLL ||
            parentPath === AccountRouteParentComponents.RENOVATIONS){
            // Renovations
            if(renovationsWorkflow && renovationsWorkflow.length > 0){
                const unitTypes = renovationsWorkflow[0]?.children;
                if(unitTypes && unitTypes.length > 0){
                    let prevUnitTypeIndex = -1;
                    if(parentPath === AccountRouteParentComponents.ACCOUNT ||
                        parentPath === AccountRouteParentComponents.PAYROLL){
                        // Pick last unit type
                        prevUnitTypeIndex = unitTypes.length - 1;
                    } else {
                        // decide prev unit type if available
                        const currentUnitType = routeParamPage;
                        const currentUnitTypeIndex = unitTypes.findIndex(el => el.title === currentUnitType);
                        prevUnitTypeIndex = currentUnitTypeIndex - 1;
                    }
                    const unitType = unitTypes[prevUnitTypeIndex];
                    if(unitType && unitType?.url){
                        return goto(`${unitType.url}/${categoryType}`)();
                    }
                }
            }
        }

        // Reason for adding payroll check as well is:
        // There was no renovation unit type and hence the renovation url never encountered
        // so going back to operational last tab directly from payroll
        if(parentPath === AccountRouteParentComponents.ACCOUNT ||
            parentPath === AccountRouteParentComponents.PAYROLL ||
            parentPath === AccountRouteParentComponents.RENOVATIONS){
            if(propertyHasRevenueModel(currentProperty, RevenueModelType.Simplified)) {
                const lastIndex = SIMPLIFIED_REVENUE_ROUTES.length - 1;
                return goto(`${AccountRouteParentComponents.SIMPLIFIED_REVENUE}/${SIMPLIFIED_REVENUE_ROUTES[lastIndex]}`)();
            }
            const summary = REVENUE_SOURCE_DESCRIPTIONS[RevenueSource.SUMMARY];
            return goto(`${AccountRouteParentComponents.OPERATIONAL}/${summary.path}/${categoryType}`)();
        }

        if(parentPath === AccountRouteParentComponents.OPERATIONAL){
            const source = PAGE_TO_KEY[routeParamPage] ?? RevenueSource.AVG_MARKET_RENTS;
            const current = REVENUE_SOURCE_DESCRIPTIONS[source];
            if(current.previous){
                const previous = REVENUE_SOURCE_DESCRIPTIONS[current.previous];
                return goto(`${parentPath}/${previous.path}/${categoryType}`)();
            } else {
                const lastAccountId = accounts?.[accounts?.length - 1]?.id;
                return goto(`${AccountRouteParentComponents.ACCOUNT}/${lastAccountId}/${categoryType}`)();
            }
        }

        if(parentPath === AccountRouteParentComponents.SIMPLIFIED_REVENUE) {
            const index = SIMPLIFIED_REVENUE_ROUTES.indexOf(routeParamPage);
            if(index <= 0) {
                const lastAccountId = accounts?.[accounts?.length - 1]?.id;
                return goto(`${AccountRouteParentComponents.ACCOUNT}/${lastAccountId}/${categoryType}`)();
            }

            return goto(`${AccountRouteParentComponents.SIMPLIFIED_REVENUE}/${SIMPLIFIED_REVENUE_ROUTES[index - 1]}`)();
        }
    }

    function goNextRequested() {
        const isPayrollAvailableForCurrentUser = hasAuthorization(Authorizations.PlanningHubPayroll);
        if(parentPath === AccountRouteParentComponents.OPERATIONAL &&
            propertyHasRevenueModel(currentProperty, RevenueModelType.Original)){
            const source = PAGE_TO_KEY[routeParamPage] ?? RevenueSource.AVG_MARKET_RENTS;
            const current = REVENUE_SOURCE_DESCRIPTIONS[source];
            if(current.next){
                const next = REVENUE_SOURCE_DESCRIPTIONS[current.next];
                return goto(`${parentPath}/${next.path}/${categoryType}`)();
            }
        }

        if(parentPath === AccountRouteParentComponents.SIMPLIFIED_REVENUE &&
            propertyHasRevenueModel(currentProperty, RevenueModelType.Simplified)) {
            const lastIndex = SIMPLIFIED_REVENUE_ROUTES.length - 1;
            const index = SIMPLIFIED_REVENUE_ROUTES.indexOf(routeParamPage);

            if(index !== lastIndex) {
                return goto(`${AccountRouteParentComponents.SIMPLIFIED_REVENUE}/${SIMPLIFIED_REVENUE_ROUTES[index + 1]}`)();
            }
        }

        if((parentPath === AccountRouteParentComponents.OPERATIONAL ||
            parentPath === AccountRouteParentComponents.RENOVATIONS) &&
            propertyHasRevenueModel(currentProperty, RevenueModelType.Original)){
            // Renovations
            if(renovationsWorkflow && renovationsWorkflow.length > 0){
                const unitTypes = renovationsWorkflow[0]?.children;
                if(unitTypes && unitTypes.length > 0){
                    let nextUnitTypeIndex = -1;
                    if(parentPath === AccountRouteParentComponents.OPERATIONAL){
                        // Pick first unit type
                        nextUnitTypeIndex = 0;
                    } else {
                        // decide next unit type if available
                        const currentUnitType = routeParamPage;
                        const currentUnitTypeIndex = unitTypes.findIndex(el => el.title === currentUnitType);
                        nextUnitTypeIndex = currentUnitTypeIndex + 1;
                    }
                    const unitType = unitTypes[nextUnitTypeIndex];
                    if(unitType && unitType?.url){
                        return goto(`${unitType.url}/${categoryType}`)();
                    }
                }
            }
        }
        if((parentPath === AccountRouteParentComponents.OPERATIONAL ||
            parentPath === AccountRouteParentComponents.SIMPLIFIED_REVENUE ||
            parentPath === AccountRouteParentComponents.RENOVATIONS) &&
            !propertyHasRevenueModel(currentProperty, RevenueModelType.UnitLevel)){
            // Payroll
            if(isPayrollAvailableForCurrentUser){
                return goto(AccountRouteParentComponents.PAYROLL)();
            }
        }
        // Accounts __________________
        const nextAccountId = getNextAccountId(currentAccountId);

        if(nextAccountId){
            return goto(`${AccountRouteParentComponents.ACCOUNT}/${nextAccountId}/${categoryType}`)();
        } else if(propertyHasRevenueModel(currentProperty, RevenueModelType.UnitLevel)){
            const firstAccountId = accounts?.[0]?.id;
            return goto(`${AccountRouteParentComponents.ACCOUNT}/${firstAccountId}/${categoryType}`)();
        } else if(propertyHasRevenueModel(currentProperty, RevenueModelType.Simplified)) {
            return goto(`${AccountRouteParentComponents.SIMPLIFIED_REVENUE}/${SIMPLIFIED_REVENUE_ROUTES[0]}`)();
        } else {
            const avgMarketRents = REVENUE_SOURCE_DESCRIPTIONS[RevenueSource.AVG_MARKET_RENTS];
            return goto(`${AccountRouteParentComponents.OPERATIONAL}/${avgMarketRents.path}/${categoryType}`)();
        }
    }

    // NOTE: This was used as a hack to keep the pages from re-rendering
    // when the goNextRequested/goBackRequested functions were changed :/.
    const memoizedOperationalRoute = useMemo(() => () => (
            <OperationalRoute
                    onNextClick={goNextRequested}
                    onPreviousClick={goBackRequested}
                    key="operational-route"
                    page={routeParamPage}/>
    ), [routeParamPage]);
    return (
        <Skeleton
            accounts={accounts}
            renovationsWorkflow={renovationsWorkflow}
            isPayrollAvailableForCurrentUser={hasAuthorization(Authorizations.PlanningHubPayroll)}
            onNextClick={goNextRequested}
            onPreviousClick={goBackRequested}
            hideFooter={true}
        >
            <RenderIfReadOnly>
                <ReadOnlyHeader className={navStyles.readOnlyHeaderFixed}/>
            </RenderIfReadOnly>
            <Route exact path="/operational/:page/:type" component={memoizedOperationalRoute}/>
            <Route exact path="/renovations/:unitTypeId/:type">
                <AccountRenovations onNextClick={goNextRequested} onPreviousClick={goBackRequested} key="renos-node"/>
            </Route>
            <Route exact path="/account/:id/:type">
                <Account onNextClick={goNextRequested} onPreviousClick={goBackRequested} key="account-node"/>
            </Route>
            <Route exact path={"/payroll"}>
                <AccountPayroll onNextClick={goNextRequested} onPreviousClick={goBackRequested} key="payroll-node" />
            </Route>
            <Route path={"/payroll/:type"}>
                <Redirect to={"/payroll"} />
            </Route>

            {/* BEGIN: Routes for Simplified Revenue */}
            {/* You MUST put the route in the SIMPLIFIED_REVENUE_ROUTES list in AccountRouteConstants.ts if you want previous/next to work correctly. */}
            <Route exact path="/simplified-revenue/lease-expiration-schedule">
                <LeaseExpirationSchedule onNextClick={goNextRequested} onPreviousClick={goBackRequested} />
            </Route>
            <Route exact path="/simplified-revenue/market-rent">
                <MarketRent onNextClick={goNextRequested} onPreviousClick={goBackRequested} />
            </Route>
            <Route exact path="/simplified-revenue/summary">
                <Summary onNextClick={goNextRequested} onPreviousClick={goBackRequested} />
            </Route>
            <Route exact path="/simplified-revenue/renewal-trade-out">
                <RenewalTradeOut onNextClick={goNextRequested} onPreviousClick={goBackRequested} />
            </Route>
            <Route exact path="/simplified-revenue/renewal-ratios">
                <RenewalRatio onNextClick={goNextRequested} onPreviousClick={goBackRequested} />
            </Route>
            <Route exact path="/simplified-revenue/renovations">
                <Renovations onNextClick={goNextRequested} onPreviousClick={goBackRequested} />
            </Route>
            <Route exact path="/simplified-revenue/vacancy-rates">
                <VacancyRate onNextClick={goNextRequested} onPreviousClick={goBackRequested} />
            </Route>
            {/* END: Routes for Simplified Revenue */}
        </Skeleton>
    );
};
