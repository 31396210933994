import {ReactElement, useEffect, useState} from "react";
import {Tab, TabList, TabPanel, Tabs} from "@zendeskgarden/react-tabs";
import {UserList} from "./UserList";
import * as css from "./styles/userStyles.module.scss";
import {ViziblyTheme} from "../../analyst/ViziblyZDGTheme";
import {ThemeProvider} from "@zendeskgarden/react-theming";
import {GroupList} from "./GroupList";
import {useListAuthorizationsLazyQuery} from "../../../__generated__/generated_types";
import useAppStore from "../../../hooks/useAppStore";

export function Users(): ReactElement {
    const appStore = useAppStore();

    const [getAuthList, {loading: authLoading, data: authQueryData}] = useListAuthorizationsLazyQuery({
        fetchPolicy: "network-only"
    });
    const [authData, setAuthData] = useState<string[]>([]);
    const [selectedTab, setSelectedTab] = useState<string>("users");

    useEffect(() => {
        appStore.set({isLoading: false});
        getAuthList();
    }, []);

    useEffect(() => {
        if(authLoading || !authQueryData) {
            return;
        }

        setAuthData(authQueryData.listAuthorizations);
    }, [authLoading, authQueryData]);

    return <div className={css.userPageWrapper}>
        <ThemeProvider theme={ViziblyTheme}>
            <Tabs onChange={item => setSelectedTab(item)}>
                <TabList>
                    <Tab item="users">Users</Tab>
                    <Tab item="groups">Groups</Tab>
                </TabList>
                <TabPanel item="users">
                    {selectedTab === "users" && <UserList authData={authData} />}
                </TabPanel>
                <TabPanel item="groups">
                    {selectedTab === "groups" && <GroupList authData={authData} />}
                </TabPanel>
            </Tabs>
        </ThemeProvider>
    </div>;
}