import React, { ReactElement } from "react";

import * as css from "./styles/css.module.scss";

export interface IPageLabelProps {
    label: string,
    sublabel?: string
    className?: string
}

export default function PageLabel(props:IPageLabelProps): ReactElement{
    const label = props.label;
    const sublabel = props.sublabel ? `(${props.sublabel})` : undefined;
    return (
            <span className={`${props.className ? props.className : ''}`}>
                <span data-testid='main_label' key='main_label' className={css.label}>{label}</span>
                {sublabel &&
                        <span data-testid='sub_label' key='sub_label' className={css.subLabel}>{sublabel}</span>
                }
            </span>
    );
}
