// noinspection DuplicatedCode
import GC from "@grapecity/spread-sheets";
import { ICustomCellInfo, ICustomCellToggleHitInfo } from "./types";
import Style = GC.Spread.Sheets.Style;
import { CellDecoratorSpec } from "./icons/CellDecoratorSpecs";
import { DriverType } from "../../../__generated__/generated_types";
import {
    DRIVER_PILL_PADDING, GRADIENT_OVERLAY_W, OP_DRIVER_ICON_W, renderGradientOverlay,
    renderOpDriverIcon, renderPercentDriverIcon, renderRevenueDriverIcon,
    renderWorksheetDriverIcon, REVENUE_DRIVER_ICON_W,
    WORKSHEET_DRIVER_ICON_W
} from "./icon-renderers/draw_pills";

export interface DriverPillsCellInfo extends ICustomCellInfo {
    reforecastDrivers: DriverType[],
    budgetDrivers: DriverType[],
    style?: Style,
    padding?: number,
}

export class DriverPillsCell extends GC.Spread.Sheets.CellTypes.Text {
    constructor(private cellInfo: DriverPillsCellInfo) {
        super();
        this.typeName = "DriverPillsCell";
        this.cellInfo = cellInfo;
    }

    showPointer = false;

    override paint(ctx: CanvasRenderingContext2D, value: any, x: number, y: number, w: number, h: number, style: GC.Spread.Sheets.Style, context?: any): void {

        if (this.cellInfo.style){
            style = this.cellInfo.style;
        }
        super.paint(ctx, value, x, y, w, h + (this.cellInfo.padding || 0), style, context);
    }

    override paintContent(ctx: CanvasRenderingContext2D, value: any, x: number, y: number, w: number, h: number, style: GC.Spread.Sheets.Style, context?: any): void {
        // const togglePadW = this.cellInfo.baseToggleSpaceWidth + (this.cellInfo.subLevelXOffset * this.cellInfo.level);
        // const leftIconXOffset = (this.cellInfo.leftIconSpecs?.x || 0) + (this.cellInfo.subLevelXOffset * this.cellInfo.level);

        if (this.cellInfo.style){
            style = this.cellInfo.style;
        }

        super.paintContent(ctx, value, x, y, w, h, style, context);

        const combined = this.cellInfo.reforecastDrivers.concat(this.cellInfo.budgetDrivers).dedupe();
        if(combined.length > 0){
            const hasOpDriver = combined.includes(DriverType.Operational);
            const hasWorksheetDriver = combined.includes(DriverType.Worksheet);
            const hasRevenueDriver = combined.includes(DriverType.Revenue);
            const hasPercentDriver = combined.includes(DriverType.AccPercentage);

            let x_offset = x + 10;

            if(hasOpDriver){
                renderOpDriverIcon(ctx, x_offset, y + 11);
                x_offset = x_offset + OP_DRIVER_ICON_W + DRIVER_PILL_PADDING;
            }
            if(hasWorksheetDriver){
                renderWorksheetDriverIcon(ctx, x_offset, y + 11);
                x_offset = x_offset + WORKSHEET_DRIVER_ICON_W + DRIVER_PILL_PADDING;
            }
            if(hasRevenueDriver){
                renderRevenueDriverIcon(ctx, x_offset, y + 11);
                x_offset = x_offset + REVENUE_DRIVER_ICON_W + DRIVER_PILL_PADDING;
            }
            if(hasPercentDriver){
                renderPercentDriverIcon(ctx, x_offset, y + 11);
                // x_offset = REVENUE_DRIVER_ICON_W + DRIVER_PILL_PADDING;
            }

            renderGradientOverlay(ctx, x + w - GRADIENT_OVERLAY_W, y);

        }

        // if(this.cellInfo.hideToggle != true && this.cellInfo.canOpen){
        //     if (this.cellInfo.renderChevron) {
        //         renderChevronToggle(ctx, x + (this.cellInfo.rightIconSpecs?.x || 0), y + (this.cellInfo.rightIconSpecs?.y || 0), 10, 10, this.cellInfo.isOpen);
        //     } else {
        //         renderPlusMinusToggle(ctx, x + leftIconXOffset, y + (this.cellInfo.leftIconSpecs?.y || 0), 10, 10, this.cellInfo.isOpen);
        //     }
        // }
    }

    // override getHitInfo(x: number, y: number, _cellStyle: GC.Spread.Sheets.Style, cellRect: GC.Spread.Sheets.Rect, context?: any): GC.Spread.Sheets.IHitTestCellTypeHitInfo {
    //     const c = cellRect; // alias
    //     const [row, col] = [context.row as number, context.col as number];
    //     const info: ICustomCellToggleHitInfo = {
    //         x,
    //         y,
    //         row,
    //         col,
    //         cellRect,
    //         sheetArea: context.sheetArea,
    //         isReservedLocation: false,
    //     };
    //     return info;
    // }
}
