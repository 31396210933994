// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace SJSCfg {
    export const MAIN_SHEET_NAME = 'Sheet1';

    /**
     * First visible row and column, 0-based index.
     *
     * This system expects the SpreadJS *.json file to have
     * 2 empty columns and 2 empty rows that are hidden on initialization. This allows for the 0,0 cell
     * to be selected on load, which is out of user view. It also prevents an issue where interacting with
     * the SJS cell editor will emit cellHovered events, which break hovered row treatments, and prevent
     * input.
     */
    export const FIRST_VISIBLE_ROW = 2;
    export const FIRST_VISIBLE_COL = 2;

    export const FIRST_MONTH_COL = 3;
    export const FIRST_DATA_ROW = 5;
    export const TOTALS_COL = 15;

    export const FINANCIAL_ENTITY_COL_W = 284;
    export const MONTH_COL_W = 94;
    export const TOTALS_COL_W = 127;

    export const MINUMUM_ROW_HEIGHT = 40;
}
