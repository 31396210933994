import React, { useState } from 'react';
import { BsEye, BsEyeSlash } from "react-icons/bs";

import TextField from '@material-ui/core/TextField';
import { useValidPassword, useValidEmail } from '../../hooks/UseAuthHooks';

export const Email: React.FunctionComponent<{
	emailIsValid: boolean;
	setEmail: (_: string) => void;
	email?: string;
}> = ({
	// emailIsValid,
	setEmail,
	email
}) => {
	return (
		<div className="signup-wrapper mt-4">
			<div className={"signup-box " + (email && email.length > 0 ? "focused-border" : "default-border")}>
				<label className={"signup-email " + (email && email.length > 0 ? "focused-text" : "default-text")}> Email </label>
				<div className="signup-input-wrapper">
					<input
						className="signup-input"
						type="email"
						required
						onChange={(evt) => {setEmail(evt.target.value)}}
					/>
				</div>
			</div>
		</div>


	);
};

export const Password: React.FunctionComponent<{
	label: string;
	passwordIsValid?: boolean;
	setPassword: (_: string) => void;
	password?: string;

}> = ({ label, setPassword, password }) => {

    const [showPassword, setShowPassword] = useState(false);

	return (
		<div className="signup-wrapper mt-4">
			<div className={"signup-box " + (password && password.length > 0 ? "focused-border" : "default-border")}>
				{label === "Confirm Password" ?
					<label className={"signup-password signup-width " +
						(password && password.length > 0 ? "focused-text" : "default-text")}>
						Password
					</label>
					:
					<label className={"signup-password confirm-password " +
						(password && password.length > 0 ? "focused-text" : "default-text")}>
						Confirm Password
					</label>
				}
				<span className="signup-input-wrapper">
					<input
						className={"signup-input "}
						type={showPassword ? "text" : "password"}
						onChange={(value) => setPassword(value.target.value)}
					/>
					{password && password.length > 0 &&
						<div className="eye-icon-wrapper d-flex align-items-center">
							{!showPassword ?
								<BsEye onClick={() => setShowPassword(true)} />
								:
								<BsEyeSlash onClick={() => setShowPassword(false)} />
							}
						</div>
					}
				</span>
			</div>
		</div>
	);
};

export const Username: React.FunctionComponent<{username?: string; usernameIsValid: boolean; setUsername: (_: string) => void }> = ({
	usernameIsValid,
	setUsername,
	username,
}) => {
	return (
		<div className="signup-wrapper mt-4">
			<div className={"signup-box " + (username !== "" ? "focused-border" : "default-border")}>
				<label className={"signup-username " + (username !== "" ? "focused-text" : "default-text")}> Username </label>
				<div className="signup-input-wrapper">
					<input
						className="signup-input"
						type={usernameIsValid ? 'Username' : 'Minimum 8 characters'}
						// error={!usernameIsValid}
						required
						onChange={(evt) => { setUsername(evt.target.value)}}
					/>
				</div>
			</div>
		</div>
	);
};

export const Code: React.FunctionComponent<{ codeIsValid: boolean; setCode: (_: string) => void }> = ({
	codeIsValid,
	setCode,
}) => {
	return (
		<TextField
			fullWidth
			variant="outlined"
			label={codeIsValid ? 'Code' : 'Minimum 6 characters'}
			error={!codeIsValid}
			onChange={(evt: React.ChangeEvent<HTMLTextAreaElement>) => {
				setCode(evt.target.value);
			}}
		/>
	);
};
