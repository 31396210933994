import { ReactElement } from "react";
import { ITreeNode, ZDTreeContainer } from "./components/ZDTreeContainer";
import { AssumptionRowModel } from "../../data/models/AssumptionRowModel";
import { AssumptionRow } from "../assumption-row/AssumptionRow";
import { formatValue } from "./logic/formatValue";

export interface AssumptionTreeProps {
    assumptions: AssumptionRowModel[]
}

function buildTree(assumptions: AssumptionRowModel[]): ITreeNode[] {
    const tree: ITreeNode[] = [];

    for (const assumption of assumptions) {
        const formattedValue = formatValue(assumption.value, assumption.valueType);
        let children: ITreeNode[] | undefined = undefined;
        if (assumption.children.length > 0) {
            children = buildTree(assumption.children);
        }

        const node: ITreeNode = {
            content: <AssumptionRow
                label={assumption.label}
                subLabel={assumption.subLabel}
                value={formattedValue}
            />,
            children: children
        };
        tree.push(node);
    }

    return tree;
}

export function AssumptionsTree(props: AssumptionTreeProps): ReactElement {

    const tree = buildTree(props.assumptions);

    return (
        <ZDTreeContainer
            aria-label="assumptions"
            tree={tree}
        />
    );
}