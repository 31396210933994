import { ApexOptions } from "apexcharts";
import { COLORS } from "../../constants/Colors";
import merge from "lodash/merge";
import { formatterDollarUSNoDecimal } from "../../utils/formatters";

export interface IViziblyApexChartProps {
    type?: "line" | "area" | "bar" | "histogram" | "pie" | "donut" | "radialBar" | "scatter" | "bubble" | "heatmap" | "treemap" | "boxPlot" | "candlestick" | "radar" | "polarArea" | "rangeBar" | undefined,
    series?: ApexAxisChartSeries,
    options?: ApexOptions,
    width?: number,
    height?: number
}

export function buildApexChartConfig(props: IViziblyApexChartProps): IViziblyApexChartProps {
    let configOptions = props.options;

    if (configOptions != undefined) {
        configOptions = buildOptions(props);
    }

    return {
        ...props,
        options: configOptions,
    };
}

function buildOptions(props: IViziblyApexChartProps): ApexOptions {
    const builtOptions: ApexOptions = {
        colors: [COLORS.LAVENDER_300, COLORS.PRIMARY_200, COLORS.PRIMARY_200],
        markers: {
            size: 3,
            strokeWidth: 0
        },
        fill: {
            opacity: 1,
        },
        states: {
            hover: {
                filter: {
                    type: 'none',
                }
            },
        },
        dataLabels: {
            enabled: false,
        },
        legend: {
            show: false,
        },
        chart: {
            toolbar: {
                show: false,
            },
            selection: {
                enabled: false,
            },
            zoom: {
                enabled: false,
            },
            parentHeightOffset: 0,
        },
        xaxis: {
            crosshairs: {
                show: false,
            },
            axisTicks: {
                show: false,
            },
            tooltip: {
                enabled: false
            },
        },
        yaxis: {
            title: {
                text: '',
            },
            forceNiceScale: true,
            labels: {
                formatter: yAxisLabelFormatter,
            },
            axisBorder: {
                show: true,
            },
            tickAmount: 3,
        },
        tooltip: {
            y: {
                formatter: (val) => formatterDollarUSNoDecimal.format(val),
            },
            shared: false,
            fixed: {
                enabled: false,
            }
        }
    };

    if (!props?.options?.chart?.stacked && props?.type == 'bar') {
        builtOptions.plotOptions = {
            bar: {
                distributed: true,
            }
        };
    }

    if (props.series !== undefined && props.options?.chart?.type == 'line') {
        const stroke: number[] = [];

        props.series.forEach(x => {
            stroke.push(x.type == 'line' ? 2 : 0);
        });

        builtOptions.stroke = {};
        builtOptions.stroke.width = stroke;
    }

    return merge(builtOptions, props.options);
}

export interface ITooltipRow {
    label: string | number,
    value: string | number
}

// TODO investigate using Intl library instead for formatting - bowman APP-1043
export const yAxisLabelFormatter = (val: number): string => {
    if (val == 0) return '0';

    let label = val < 0 ? '-$' : '$';

    if (Math.abs(val) >= 1000000) {
        return label += (Math.abs(val) / 1000000) + 'm';
    } else if (Math.abs(val) >= 1000) {
        return label += (Math.abs(val) / 1000) + 'k';
    } else {
        return label += parseFloat(Math.abs(val).toFixed(1));
    }
};