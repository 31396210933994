import {ReactElement, useState} from "react";
import {useProperties} from "../../../contexts/properties/PropertiesContext";
import {Field, Hint, Input, Label} from "@zendeskgarden/react-forms";
import PropertyDropdown from "../../bulk-update/components/PropertyDropdown";
import {Button} from "@zendeskgarden/react-buttons";
import {PropertyManagementSystem, useCreateSendBudgetJobMutation} from "../../../__generated__/generated_types";
import * as css from "./styles/SendBudgetForm.module.scss";

export interface SendBudgetFormProps {
    submitted: () => void;
}

export function SendBudgetForm(props: SendBudgetFormProps): ReactElement {
    const [submitJob, {loading}] = useCreateSendBudgetJobMutation({
        fetchPolicy: "no-cache"
    });

    const {properties} = useProperties();

    const [propertyIds, setPropertyIds] = useState<string[]>([]);
    const [budgetNameTemplate, setBudgetNameTemplate] = useState<string>("");

    function clear(): void {
        setPropertyIds([]);
        setBudgetNameTemplate("");
    }

    function submit(): void {
        submitJob({
            variables: {
                propertyIds: propertyIds,
                budgetNameTemplate: budgetNameTemplate
            }
        }).then(() => {
            clear();
            props.submitted();
        });
    }

    return <form>
        <div className={css.field}>
            <PropertyDropdown
                    properties={properties?.filter(p => p.propertyManagementSystem === PropertyManagementSystem.Entrata) ?? []}
                    onPropertySelect={selected => setPropertyIds(selected)}
                    selectedProperties={propertyIds}
                    lockedProperties={{reforecastLocked: [], budgetLocked: []}}
                    label={"Properties"}
            />
        </div>
        <div className={css.field}>
        <Field>
            <Label>Budget Name Template</Label>
            <Hint>{"Use {property} and {budgetYear} to auto-fill property and budget year."}</Hint>
            <Input
                    type={"text"}
                    placeholder={"Budget_{property}_{budgetYear}"}
                    value={budgetNameTemplate}
                    onChange={(e) => setBudgetNameTemplate(e.target.value)}
            />
        </Field>
        </div>
        <div className={css.buttons}>
            <Button isBasic onClick={clear} disabled={loading}>Clear</Button>
            <Button isPrimary onClick={submit} disabled={propertyIds.length < 1 || budgetNameTemplate === ""}>Submit</Button>
        </div>
    </form>;
}