import { DEFAULT_THEME, IGardenTheme } from "@zendeskgarden/react-theming";
import { COLORS } from "../../constants/Colors";

/**
 * Default theme: https://garden.zendesk.com/components/theme-object
 */
export const ViziblyTheme:IGardenTheme = {
    ...DEFAULT_THEME,
    ...{
        colors:{
            ...DEFAULT_THEME.colors,
            ...{
                primaryHue: COLORS.PRIMARY_400,
            }
        },
        iconSizes:{
            sm: "16px",
            md: "16px",
            lg: "16px"
        },
        lineHeights:{
            sm: "16px",
            md: "16px",
            lg: "16px",
            xl: "16px",
            xxl: "16px",
            xxxl: "16px"
        },
        components:{
            ['loaders.progress_background']: 'margin: 0',
            // ['forms.checkbox']: `background-color: red`, // WIP
        }
    }
};
