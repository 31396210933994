/* eslint-disable @typescript-eslint/no-empty-function */
import React, { ReactElement, useContext, useEffect, useState } from "react";

import { useHistory, useLocation } from "react-router-dom";
import useAppStore from "../../hooks/useAppStore";

import { ViziblyTheme } from "../analyst/ViziblyZDGTheme";
import { ThemeProvider } from "styled-components";

import { AuthorizationContext } from "../../authorization/AuthorizationContext";

import LeaseActivity from "./lease-activity/LeaseActivity";
import Summary from "./summary/Summary";
import MarketRents from "./market-rents/MarketRents";

import DashboardLayout from "../../components/layout/dashboard/DashboardLayout";
import {
    REVENUE_DASHBOARD_DEFAULT_SECTION, REVENUE_DASHBOARD_PATH,
    REVENUE_DASHBOARD_SECTION,
    RevenueDashboardSections
} from "./helpers/structs";

import { DashboardHeaderSection } from "../../components/layout/dashboard/components/DashboardHeader";

import * as dashboardStyles from '../../components/layout/dashboard/helpers/styles.module.scss';
import DashboardHeaderButton from "../../components/layout/dashboard/components/DashboardHeaderTab";
import { ReactComponent as AccountMenu } from "../../assets/icons/icon_account_menu_icon.svg";

import { useFoqalAction } from "../../hooks/useFoqal";

import { Authorizations } from "../../authorization/Authorizations";
import SideBar from "../../components/sidebar/SideBar";
import { useRenovationsWorkflow } from "../../hooks/useRenovationsWorkflow";
import { BudgetingType } from "../../BudgetingType";
import { useChartOfAccounts } from "../../contexts/chartofaccounts/ChartOfAccountsContext";

function UnitLevelModeling(): ReactElement {
    const appStore = useAppStore();
    const history = useHistory();
    const location = useLocation();

    const { show: showFoqal, hide: hideFoqal } = useFoqalAction();

    const { hasAuthorization } = useContext(AuthorizationContext);
    const isPayrollAvailable: boolean = hasAuthorization(Authorizations.PlanningHubPayroll);

    const { renovationsWorkflow } = useRenovationsWorkflow({ categoryType: BudgetingType.BUDGET });
    const { accounts } = useChartOfAccounts();

    const [activeSectionInfo, setActiveSectionInfo] = useState<DashboardHeaderSection|undefined>();

    const [loadProgress, setLoadProgress] = useState<number>(0);

    const getSectionInfoByPath = (path: string): DashboardHeaderSection|undefined => {
        const thisSection = RevenueDashboardSections.find(section => section.navLocation === path);
        if(thisSection != undefined){
            return thisSection;
        }
        return;
    };

    const toggleSidebar = () => {
        if(appStore.isSideBarVisible){
            showFoqal();
        } else {
            hideFoqal();
        }
        appStore.set({ isSideBarVisible: !appStore.isSideBarVisible });
    };

    /**
     * Called when location path changes
     */
    useEffect(
        () => {
            if(!location.pathname){
                return;
            }

            if(location.pathname === REVENUE_DASHBOARD_PATH || location.pathname === `${REVENUE_DASHBOARD_PATH}/`){
                const sectionInfo = RevenueDashboardSections[REVENUE_DASHBOARD_DEFAULT_SECTION];
                if(sectionInfo != undefined){
                    history.replace(sectionInfo.navLocation);
                }
            } else {
                setActiveSectionInfo(getSectionInfoByPath(location.pathname));
            }
        },
        [location.pathname]
    );

    // Initial load, dispells the "Vizibly is starting" graphic
    useEffect(
        () => {
            appStore.set({ isLoading: false });
            return () => {
            };
        },
        []);

    const prependNav: ReactElement = <DashboardHeaderButton
        className={dashboardStyles.tabNavIconButton}
        onClick={() => {
            toggleSidebar();
        }}
        label={'Open the Account Workflow menu'}
        hideLabel={true}
        icon={AccountMenu}
    />;

    return (
        <ThemeProvider theme={ViziblyTheme}>
            <DashboardLayout
                title={"Revenue"}
                sections={RevenueDashboardSections}
                showingSection={REVENUE_DASHBOARD_SECTION.MARKET_RENTS}
                loadProgress={loadProgress}
                headerConfig={{ prependNav }}
            >
                {accounts && renovationsWorkflow &&
                    <SideBar
                        renovationsWorkflow={renovationsWorkflow}
                        isPayrollAvailableForCurrentUser={isPayrollAvailable}/>
                }
                <section className={dashboardStyles.section}>
                    {
                        activeSectionInfo?.sectionId === REVENUE_DASHBOARD_SECTION.MARKET_RENTS
                            ? <MarketRents setLoadProgress={setLoadProgress}/>
                            : <></>
                    }
                    {
                        activeSectionInfo?.sectionId === REVENUE_DASHBOARD_SECTION.LEASE_ACTIVITY
                            ? <LeaseActivity/>
                            : <></>
                    }
                    {
                        activeSectionInfo?.sectionId === REVENUE_DASHBOARD_SECTION.SUMMARY
                            ? <Summary setLoadProgress={setLoadProgress}/>
                            : <></>
                    }
                </section>
            </DashboardLayout>
        </ThemeProvider>);
}

export default UnitLevelModeling;
