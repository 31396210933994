import { ReactElement, useState } from "react";
import { toast } from "react-toastify";
import { Button } from "@zendeskgarden/react-buttons";
import { Body, Close, Footer, Header, Modal } from "@zendeskgarden/react-modals";
import { ThemeProvider } from "@zendeskgarden/react-theming";

import { ViziblyTheme } from "../../../styles/zendesk-garden/ViziblyZDGTheme";
import * as css from "./styles/copy-drivers-btn/css.module.scss";
import { useProperties } from "../../../contexts/properties/PropertiesContext";
import { DriversCopyPeriodInput, useCopyPropertyDriversMutation, VersionType } from "../../../__generated__/generated_types";
import { SelectableFinancialEntity } from "../PropertyDrivers";
import CopyDriversConfirmationModal from "./CopyDriversConfirmationModal";
import { Tooltip } from "@zendeskgarden/react-tooltips";


interface ICopyDriversBtn {
    allAccountsCount: number;
    selectedAccounts: SelectableFinancialEntity[];
    versionType: VersionType;
    isCopyPeriodLocked: boolean;
}

export default function CopyDriversToPeriod({
    allAccountsCount,
    selectedAccounts,
    versionType,
    isCopyPeriodLocked,
}: ICopyDriversBtn): ReactElement {
    const [isConfirmModalOpen, setIsConfirmModalOpen] = useState<boolean>(false);
    const [isCopyPDModalOpen, setIsCopyPDModalOpen] = useState<boolean>(false);
    const [isCopyDriversExecuting, setIsCopyDriversExecuting] = useState<boolean>(false);

    const { currentProperty, properties } = useProperties();
    const [copyDrivers] = useCopyPropertyDriversMutation();

    const handleOpenCopyPDModal = () => setIsCopyPDModalOpen(true);

    const destinationVersionType = versionType === VersionType.Budget ? VersionType.Reforecast : VersionType.Budget;

    const resetState = () => {
        setIsConfirmModalOpen(false);
        setIsCopyPDModalOpen(false);
        setIsCopyDriversExecuting(false);
    };

    const doCopyDrivers = (
        accountIds: string[],
        budgetYear: number,
        currentPropertyId: string,
        period: DriversCopyPeriodInput,
    ): Promise<any> => {
        return copyDrivers({
            variables: {
                ...(accountIds.length > 0 ? {accountIds} : {}),
                budgetYear: budgetYear,
                sourcePropertyId: currentPropertyId,
                destinationPropertyId: currentPropertyId,
                period: period,
            }
        });
    };

    const handleCopyDrivers = () => {
        const budgetYear = currentProperty?.budgetYear;

        if (!budgetYear || !currentProperty) {
            return;
        }

        const accountIds = allAccountsCount === selectedAccounts.length ? [] : selectedAccounts.map((e) => e.id);
        const period: DriversCopyPeriodInput = {
            destinationVersionType: destinationVersionType,
            sourceVersionType: versionType,
        };

        setIsCopyDriversExecuting(true);

        doCopyDrivers(accountIds, budgetYear, currentProperty.id, period)
            .then(({data}) => {
                if (data !== null) {
                    toast.success("Modeling methods copied successfully");
                }
                resetState();
            })
            .catch(() => {
                resetState();
            });
    };

    if (!properties || !currentProperty) {
        return <></>;
    }

    const copyFromYear = versionType === VersionType.Budget ? currentProperty.budgetYear : currentProperty.budgetYear - 1;
    const copyToYear = versionType === VersionType.Budget ? currentProperty.budgetYear - 1 : currentProperty.budgetYear;
    const copyTitle = `Copy Modeling Methods to ${copyToYear} ${destinationVersionType.toLowerCase().capitalize()}`;
    const copyLockedTooltip = `${copyToYear} ${destinationVersionType.toLowerCase().capitalize()} is locked. No edits permitted.`;

    return (
        <ThemeProvider theme={ViziblyTheme}>
            <div className={css.copyDriversBtnWrapper}>
                {isCopyPeriodLocked ?
                    <Tooltip content={copyLockedTooltip}>
                        <span>
                            <Button disabled>
                                {copyTitle}
                            </Button>
                        </span>
                    </Tooltip>
                    :
                    <Button
                        disabled={selectedAccounts.length === 0}
                        onClick={handleOpenCopyPDModal}
                    >
                        {copyTitle}
                    </Button>

                }
                {isCopyPDModalOpen && (
                    <Modal onClose={resetState}>
                        <Header>
                            {copyTitle}
                        </Header>
                        <Body className={css.copyDriversBody}>
                            <div className={`${css.row} ${css.copyToPeriodTitle}`}>
                                You're about to copy {selectedAccounts.length} modeling method{selectedAccounts.length > 1 ? 's' : ''} from {copyFromYear} {versionType.toLowerCase().capitalize()} to {copyToYear} {destinationVersionType.toLowerCase().capitalize()}.
                            </div>
                        </Body>
                        <Footer>
                            <div className={`${css.row} ${css.actionsRow}`}>
                                <div className={css.column}>
                                    <Button
                                        isBasic
                                        onClick={resetState}
                                    >
                                        Cancel
                                    </Button>
                                </div>
                                <div className={css.column}>
                                    <Button
                                        isPrimary
                                        onClick={() => setIsConfirmModalOpen(true)}
                                    >
                                        Copy
                                    </Button>
                                </div>
                            </div>
                        </Footer>
                        <Close aria-label="Close modal" />
                    </Modal>
                )}
            </div>
            {isConfirmModalOpen && (
                <CopyDriversConfirmationModal
                    canConfirm={!isCopyDriversExecuting}
                    count={selectedAccounts.length}
                    onClose={() => setIsConfirmModalOpen(false)}
                    onConfirm={handleCopyDrivers}
                />
            )}
        </ThemeProvider>
    );
}
