import { IFormulaBarUpdates } from "../../../../../../../contexts/account/data/useFormulaBarUpdates";
import { DriverType } from "../../../../../../../__generated__/generated_types";

export type TFormulaNodeProps = {
    nodeId: string,
    locked: boolean,
    isLastDriverType: boolean,
    nodeType?: DriverType,
    selected?: boolean,
    className?: string,
    fbUpdates: IFormulaBarUpdates,
}

// Keys that trigger deletion of a highlighted node in the formula bar
export const deleteNodeKeys = ["Delete", "Backspace"];
