// eslint-disable @typescript-eslint/ban-types
import GC from '@grapecity/spread-sheets';
import { NewSpreadsheetAPI } from "../../../components/spreadsheet/NewSpreadsheetTypes";
import { SJSCfg } from "./SJSConfig";
import { SJSSheetStyles as SJSS } from "./SJSSheetStyles";

export interface SJSLayoutHelperConfig {
    name: string,
    ssapi: NewSpreadsheetAPI,
    // eslint-disable-next-line @typescript-eslint/ban-types
    template: Object,
}

export interface SJSInitReturn {
    spread: GC.Spread.Sheets.Workbook,
    sheet: GC.Spread.Sheets.Worksheet,
}

// noinspection JSUnusedGlobalSymbols
export class SJSLayoutHelper {
    protected _name: string;
    protected _ssapi: NewSpreadsheetAPI;
    // eslint-disable-next-line @typescript-eslint/ban-types
    protected _template: Object;

    protected _cellHighlightBorderSpecs: CellHighlightBorder[] = [];

    public initialized = false;

    public constructor({ name, ssapi, template }: SJSLayoutHelperConfig) {
        this._name = name;
        this._ssapi = ssapi;
        this._template = template;
    }

    // SHEET INITIALIZATION ____________________________________________________________________________________________

    /**
     * Performs standard initilization on a SpreadJS spreadsheet. Returns an SJSInitReturn object,
     * which has references to the Workbook and Worksheet objects that are associated with the
     * SJSLayoutHelper as properties of an object named spread, and sheet. These references are
     * returned to enable overriding these default options by making assignments to the `spread.options`
     * and `sheet.options` objects.
     *
     * @see IWorkbookOptions
     * @see GC.Spread.Sheets.IWorksheetOptions
     */
    public initSheet(): SJSInitReturn {
        // Initialize an empty workbook and worksheet that'll be replaced by what directAccess uses
        let returnSpread: GC.Spread.Sheets.Workbook = new GC.Spread.Sheets.Workbook();
        let sheet: GC.Spread.Sheets.Worksheet = returnSpread.getSheetFromName(SJSCfg.MAIN_SHEET_NAME);

        // Set template
        this._ssapi.setTemplate({ template: this._template });

        // Perform initialization
        this._ssapi.directAccess(spread => {
            returnSpread = spread;
            sheet = spread.getSheetFromName(SJSCfg.MAIN_SHEET_NAME);

            spread.setActiveSheet(SJSCfg.MAIN_SHEET_NAME);

            // Spreadsheet Options
            spread.options.showVerticalScrollbar = true;
            spread.options.showHorizontalScrollbar = false;
            spread.options.allowUserZoom = false;
            spread.options.tabStripVisible = false;
            spread.options.allowUserDragDrop = false;
            spread.options.allowContextMenu = false;
            spread.options.showDragFillSmartTag = false;

            sheet.defaults.rowHeight = 40;

            sheet.options.colHeaderVisible = false;
            sheet.options.rowHeaderVisible = false;

            sheet.showRowOutline(false);

            // TODO: Create a ticket to perform this inititialization, but only once latest cell border treatment mech is in place
            // sheet.options.selectionBorderColor = 'transparent';
        });

        // Return references so preferences can be overwritten
        return {
            spread: returnSpread,
            sheet,
        };
    }

    // LAYOUT/STYLE HELPERS __________________________________________________________________________________________________

    /**
     * Intended to be called when the sheet is initialized, before data is loaded. This includes applying header
     * styling, setting the number of frozen rows and columns (this will vary by application). This could also be where
     * row labels are defined if we're in a scenario where that's possible, like when viewing Account Renovations
     * (see: vizibly-frontend/app/src/pages/renovations/helpers/AcctRenovationsLayout.ts)
     * @param sheet The SJS sheet this layout helper is associated with
     * @protected
     */
    protected applyPreloadFormatting(sheet: GC.Spread.Sheets.Worksheet): GC.Spread.Sheets.Worksheet {
        return this.setupColumns(sheet);
    }

    /**
     * Every layout will have a particular column layout, with many sharing configurations.
     * @param sheet
     * @protected
     */
    protected setupColumns(sheet: GC.Spread.Sheets.Worksheet): GC.Spread.Sheets.Worksheet {
        return sheet;
    }

    public applyRowHoverStyle(row: number): void {
        // eslint-disable-next-line no-console
        console.log(`SJSLayoutHelper.ts > applyRowHoverStyle(${row})`);
    }

    public applyRowDefaultStyle(row: number): void {
        // eslint-disable-next-line no-console
        console.log(`SJSLayoutHelper.ts > applyRowDefaultStyle(${row})`);
    }

    /**
     * Applies a "bridged" set of styles to a span of row cells, including a left cap style to the leftmost cell,
     * a right cap style to the rightmost cell, and a style that visually bridges the two to any inbetween.
     * @param row
     * @param firstCol
     * @param lastCol
     * @param styles
     * @protected
     */
    protected applyCappedRowSpanStyle(
        row: number,
        firstCol: number,
        lastCol: number,
        styles: CellSpanStyles,
    ) {

        this._ssapi.directAccess(spread => {
            const sheet = spread.getSheetFromName(SJSCfg.MAIN_SHEET_NAME);

            sheet.getCell(
                row, firstCol
            ).setStyle(styles.leftCap);

            sheet.getRange(
                row,
                firstCol + 1,
                1,
                lastCol - firstCol,
            ).setStyle(styles.centerSpan);

            sheet.getCell(
                row, lastCol
            ).setStyle(styles.rightCap);
        });
    }

    /**
     * Rolls over a set of rows, setting them to autofit their contents. This is necessary to achieve
     * expected results when long cell contents wrap.
     * @param sheetName         The name of the sheet that the process should be applied to.
     * @param firstRow          The first row that should be processed.
     * @param lastRow           The last row that should be processed
     * @param fallbackHeight    The height to be used instead of SSJS.DATA_ROW_HEIGHT
     */
    public applyAutofitRows(sheetName: string, firstRow: number, lastRow: number, fallbackHeight?: number): void {
        this._ssapi.directAccess(spread => {
            const sheet = spread.getSheetFromName(sheetName);

            let row = firstRow - 1;
            while (++row < lastRow + 1) {
                sheet.autoFitRow(row);
                if(sheet.getRowHeight(row) < (fallbackHeight ?? SJSS.DATA_ROW_HEIGHT)){
                    sheet.setRowHeight(row, fallbackHeight ?? SJSS.DATA_ROW_HEIGHT);
                }
            }
        });
    }

    /**
     * Toggles a row's collapsed (or closed) state, and returns the updated state, oriented towards an 'open' state.
     * @param row The row whose state should be toggled
     */
    public toggleGroupOpenState(row: number): boolean {
        let newCollapsedState = false;
        this._ssapi.directAccess(spread => {
            const sheet = spread.getSheetFromName(SJSCfg.MAIN_SHEET_NAME);
            const isCollapsed = sheet.rowOutlines.getCollapsed(row);

            newCollapsedState = !isCollapsed;
            sheet.rowOutlines.setCollapsed(row + 1, newCollapsedState);
        });
        return !newCollapsedState;
    }

    public setGroupOpenState(row: number, openState: boolean): void {
        this._ssapi.directAccess(spread => {
            const sheet = spread.getSheetFromName(SJSCfg.MAIN_SHEET_NAME);
            sheet.rowOutlines.setCollapsed(row + 1, !openState);
        });
    }

    // Year-Based SS support ___________________________________________________________________________________________
    /**
     * Applies column headers for a year of months, and a summary (Totals, Averages, etc.) column. It is
     * assumed that 3 rows are available to represent the header, and that the first 3 cells in the first
     * column are merged. Headers for more than one year may be applied.
     *
     * Don't forget this can be cached directly to a ssjson file to save a little client-side styling by
     * making a call to exportSJS()
     *
     * @param itemsColumnTitle      The title of the items column
     * @param years                 An array of one or more years to be displayed
     * @param totalColumnLabels     An array of one or more labels for each year's summary column
     * @param firstMonthColumn             The row and column of the item title cell
     * @param sheetName             The name of the sheet w/ the title row
     * @param firstReforecastMonth  The first month that reforecasting begins
     */
    public applyYearHeaderTreatment(
        years: number[],
        totalColumnLabels: string[],
        firstMonthColumn: { row: number, col: number },
        sheetName: string,
        firstReforecastMonth = 13
    ): void {
        this._ssapi.directAccess(spread => {
            const sheet = spread.getSheetFromName(sheetName);

            sheet.setRowHeight(SJSCfg.FIRST_VISIBLE_ROW, 26);
            sheet.setRowHeight(SJSCfg.FIRST_VISIBLE_ROW + 1, 20);
            sheet.setRowHeight(SJSCfg.FIRST_VISIBLE_ROW + 2, 36);

            years.forEach((year, yearIdx) => {

                const col = firstMonthColumn.col + 1 + (yearIdx * 13);

                // Populate header cell contents ________________________________________________
                const yearLabels: string[] = new Array(13).fill('');
                yearLabels[0] = year.toString();
                yearLabels[12] = year.toString();

                let reforecastBudgetLabels: string[] = new Array(13).fill('');
                reforecastBudgetLabels = reforecastBudgetLabels.map((_, monthIdx) => {
                    if(yearIdx > 0){
                        return 'BUDGET';
                    }
                    else if(monthIdx < firstReforecastMonth){
                        return 'ACTUAL';
                    }
                    else {
                        return 'REFORECAST';
                    }
                });

                const monthLabels = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec', totalColumnLabels[yearIdx]];
                sheet.setArray(firstMonthColumn.row, col, [reforecastBudgetLabels, yearLabels, monthLabels]);

                // Set Column Widths ____________________________________________________________
                for (let monthIdx = 0; monthIdx < 12; monthIdx++) {
                    sheet.setColumnWidth(col + monthIdx, SJSCfg.MONTH_COL_W);
                }
                sheet.setColumnWidth(col + 12, SJSCfg.TOTALS_COL_W);

                // Apply default styles across all years ________________________________________
                sheet.getRange(SJSCfg.FIRST_VISIBLE_ROW, col, 1, 26)
                    .setStyle(SJSS.YEAR_REFORECAST_HEADER_ROW_1);

                // Apply default styles across all years
                sheet.getRange(SJSCfg.FIRST_VISIBLE_ROW + 1, col, 1, 13 * (years.length + 1))
                    .setStyle(SJSS.YEAR_REFORECAST_HEADER_ROW_2);

                sheet.getRange(SJSCfg.FIRST_VISIBLE_ROW + 2, col, 1, 13 * (years.length + 1))
                    .setStyle(SJSS.YEAR_REFORECAST_HEADER_ROW_3);

                // Apply totals column header styling
                const applyTotalsColHeader = (col: number): void => {
                    sheet.setStyle(SJSCfg.FIRST_VISIBLE_ROW, col, SJSS.YEAR_TOTALS_HEADER_ROW_1);
                    sheet.setStyle(SJSCfg.FIRST_VISIBLE_ROW + 1, col, SJSS.YEAR_TOTALS_HEADER_ROW_2);
                    sheet.setStyle(SJSCfg.FIRST_VISIBLE_ROW + 2, col, SJSS.YEAR_TOTALS_HEADER_ROW_3);
                };
                applyTotalsColHeader(col + 13 + (13 * yearIdx));

                // Apply actual header styling for the reforecast year
                if(yearIdx == 0){
                    sheet.getRange(SJSCfg.FIRST_VISIBLE_ROW, col, 1, firstReforecastMonth - 1)
                        .setStyle(SJSS.YEAR_ACTUAL_HEADER_ROW_1);

                    sheet.getRange(SJSCfg.FIRST_VISIBLE_ROW + 1, col, 1, firstReforecastMonth - 1)
                        .setStyle(SJSS.YEAR_ACTUAL_HEADER_ROW_2);

                    sheet.getRange(SJSCfg.FIRST_VISIBLE_ROW + 2, col, 1, firstReforecastMonth - 1)
                        .setStyle(SJSS.YEAR_ACTUAL_HEADER_ROW_3);

                    // Apply last actual month header styling
                    sheet.setStyle(SJSCfg.FIRST_VISIBLE_ROW, col + firstReforecastMonth - 1, SJSS.YEAR_LAST_ACTUAL_HEADER_ROW_1);
                    sheet.setStyle(SJSCfg.FIRST_VISIBLE_ROW + 1, col + firstReforecastMonth - 1, SJSS.YEAR_LAST_ACTUAL_HEADER_ROW_2);
                    sheet.setStyle(SJSCfg.FIRST_VISIBLE_ROW + 2, col + firstReforecastMonth - 1, SJSS.YEAR_LAST_ACTUAL_HEADER_ROW_3);
                }
            });

            years.forEach((_, yearIdx) => {
                const applyTotalsColHeader = (col: number): void => {
                    sheet.setStyle(SJSCfg.FIRST_VISIBLE_ROW, col, SJSS.YEAR_TOTALS_HEADER_ROW_1);
                    sheet.setStyle(SJSCfg.FIRST_VISIBLE_ROW + 1, col, SJSS.YEAR_TOTALS_HEADER_ROW_2);
                    sheet.setStyle(SJSCfg.FIRST_VISIBLE_ROW + 2, col, SJSS.YEAR_TOTALS_HEADER_ROW_3);
                };
                applyTotalsColHeader(firstMonthColumn.col + 13 + (13 * yearIdx));
            });
        });
    }

    public getRowInfo(row: number, metaField: number): number|string|boolean|undefined {
        return this._ssapi.getValue({ row, col: metaField });
    }

    protected dataColOffset = (colId: number): number => {
        return colId;
    };

    // Cell Highlight Border Support ___________________________________________________________________________________
    public setCellHighlightBorderSpec(spec: CellHighlightBorder): void {
        this._cellHighlightBorderSpecs.push(spec);
    }

    protected getCellHighlightBorderColor(col: number): string {
        let color = 'blue';
        this._cellHighlightBorderSpecs.forEach(spec => {
            if(col >= spec.startCol && col <= spec.endCol){
                color = spec.color;
            }
        });
        return color;
    }

    /**
     * Currently used to update the selected cell border treatment.
     * Note: The row argument is currently unused, but is expected for future expansion.
     * @param _row
     * @param col
     * @param sheetName
     */
    public onCellSelect(_row: number, col: number, sheetName: string): void {
        this._ssapi.directAccess(spread => {
            const sheet = spread.getSheetFromName(sheetName);
            sheet.options.selectionBorderColor = this.getCellHighlightBorderColor(col);
        });
    }

    // Utility _________________________________________________________________________________________________________
    /**
     * Rolls over visible rows and columns evaluating dimensions to understand the visual width and height of the
     * spreadsheet. These values are then used to resize the SJS host container in the page.
     */
    public refreshSheetHostDimensions(): void {
        this._ssapi.directAccess(spread => {
            const sheet = spread.getSheetFromName(SJSCfg.MAIN_SHEET_NAME);

            // Find the visible width of the spreadsheet
            const totalCols = sheet.getColumnCount();
            let ssWidth = 0;
            for (let col = SJSCfg.FIRST_VISIBLE_COL; col < totalCols; col++) {
                if(sheet.getColumnVisible(col)){
                    ssWidth += sheet.getColumnWidth(col);
                }
            }

            // Find the visible height of the spreadsheet
            const totalRows = sheet.getRowCount();
            let ssHeight = 0;
            for (let row = SJSCfg.FIRST_VISIBLE_ROW; row < totalRows; row++) {
                if(sheet.getRowVisible(row)){
                    ssHeight += sheet.getRowHeight(row);
                }
            }

            spread.getHost().style.width = `${ssWidth}px`;
            spread.getHost().style.height = `${ssHeight}px`;
            spread.refresh();
        });
    }

    public fitHostWidthToSheet(pad?: number): void {
        this._ssapi.directAccess(spread => {
            const sheet = spread.getSheetFromName(SJSCfg.MAIN_SHEET_NAME);

            // Find the visible width of the spreadsheet
            const totalCols = sheet.getColumnCount();
            let ssWidth = 0;
            for (let col = SJSCfg.FIRST_VISIBLE_COL; col < totalCols; col++) {
                if(sheet.getColumnVisible(col)){
                    ssWidth += sheet.getColumnWidth(col);
                }
            }

            spread.getHost().style.width = `${ssWidth + (pad ?? 0)}px`;
            spread.refresh();
        });
    }

    public fitHostHeightToSheet(): void {
        this._ssapi.directAccess(spread => {
            const sheet = spread.getSheetFromName(SJSCfg.MAIN_SHEET_NAME);

            // Find the visible height of the spreadsheet
            const totalRows = sheet.getRowCount();
            let ssHeight = 0;
            for (let row = SJSCfg.FIRST_VISIBLE_ROW; row < totalRows; row++) {
                if(sheet.getRowVisible(row)){
                    ssHeight += sheet.getRowHeight(row);
                }
            }
            spread.getHost().style.height = `${ssHeight}px`;
            spread.refresh();
        });
    }

    public setHostHeight(ssHeight: number): void {
        this._ssapi.directAccess(spread => {
            spread.getHost().style.height = `${ssHeight}px`;
            spread.refresh();
        });
    }

    public setHostWidth(ssWidth: number): void {
        this._ssapi.directAccess(spread => {
            spread.getHost().style.width = `${ssWidth}px`;
            spread.refresh();
        });
    }

    public setHostDimensions(ssHeight: number, ssWidth: number): void {
        this.setHostHeight(ssHeight);
        this.setHostWidth(ssWidth);
    }

    public exportSJS(): void {
        this._ssapi.directAccess(spread => {

            const ssData = spread.toJSON();

            const a = document.createElement('a');
            const file = new Blob([JSON.stringify(ssData)], { type: 'text/plain' });

            a.href = URL.createObjectURL(file);
            a.download = 'ssExport.ssjson';
            a.click();

            URL.revokeObjectURL(a.href);
        });
    }
}

export type CellHighlightBorder = {
    startCol: number,
    endCol: number,
    color: string,
}

export type CellSpanStyles = {
    leftCap: GC.Spread.Sheets.Style,
    centerSpan: GC.Spread.Sheets.Style,
    rightCap: GC.Spread.Sheets.Style,
}
