import { ReactElement } from "react";
import { IAccountVendor } from "../logic/accountVarianceInsights";
import { formatterPercent } from "../../../../../../../../utils/formatters";
import { formatterDollarUSMax2Decimal } from "../../../../../../../../utils/fotmatters";

interface IInsightsTopVendors {
    vendorData: IAccountVendor[],
    accountTotal: number,
}
export default function InsightsTopVendors(props: IInsightsTopVendors):ReactElement{

    return (
            <ul>
                {props.vendorData.map(
                        vendor => {
                            const amtContrib = formatterDollarUSMax2Decimal.format(vendor.amount);
                            if(props.accountTotal > 0){
                                const contribPerc = formatterPercent.format(vendor.amount/props.accountTotal);
                                return <li>{vendor.name} accounted for <strong>{contribPerc} ({amtContrib})</strong> of total costs</li>;
                            }
                            else {
                                /**
                                 * Note: An account total of 0 is unlikely if there are transactions, but given +/-
                                 * interactions, transactions could be present for an account with a total of 0.
                                  */
                                return <li>{vendor.name} accounted for <strong>({amtContrib})</strong> of total costs</li>;
                            }
                        }
                )}
            </ul>
    );
}
