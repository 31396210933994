import { formatterDollarUSMax2Decimal, formatterDecimalUSMax2Decimal } from "../../../../../../../../../../utils/fotmatters";
import { AssumptionRowValueType } from "../../../data/enums/AssumptionRowValueTypeEnum";

export function formatValue(value: number, valueType: AssumptionRowValueType): string {
    let formattedValue = value.toString();
    if (valueType == AssumptionRowValueType.DOLLAR) {
        formattedValue = formatterDollarUSMax2Decimal.format(value);
    }
    else if (valueType == AssumptionRowValueType.PERCENTAGE) {
        formattedValue = `${formatterDecimalUSMax2Decimal.format(value)}%`;
    }
    else if (valueType == AssumptionRowValueType.NUMBER) {
        formattedValue = formatterDecimalUSMax2Decimal.format(value);
    }

    // default - unknown format
    return formattedValue;
}