import {createContext, ReactElement, ReactNode, useContext, useEffect, useState} from "react";
import {Authorizations} from "./Authorizations";
import {AuthContext, AuthUser} from "../contexts/AuthContext";
import {checkAuthorization, checkOnlyAuthorization} from "./AuthorizationCheckers";

export interface IAuthorizationContext {
    hasAuthorization(authorization: Authorizations): boolean;
    hasOnlyAuthorization(authorization: Authorizations): boolean;
}

const defaultAuthorizationContext: IAuthorizationContext = {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    hasAuthorization(_authorization: Authorizations): boolean {
        return false;
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    hasOnlyAuthorization(_authorization: Authorizations): boolean {
        return false;
    }
};

export const AuthorizationContext = createContext(defaultAuthorizationContext);

export function AuthorizationContextProvider(props: {children: ReactNode | ReactNode[]}): ReactElement {
    const {user} = useContext(AuthContext);
    const [context, setContext] = useState<IAuthorizationContext>({
        hasAuthorization(authorization: Authorizations): boolean {
            return checkAuthorization(user, authorization);
        },
        hasOnlyAuthorization(authorization: Authorizations): boolean {
            return checkOnlyAuthorization(user, authorization);
        }
    });

    useEffect(() => {
        function createContext(user: AuthUser | null): IAuthorizationContext {
            return {
                hasAuthorization(authorization: Authorizations): boolean {
                    return checkAuthorization(user, authorization);
                },
                hasOnlyAuthorization(authorization: Authorizations): boolean {
                    return checkOnlyAuthorization(user, authorization);
                }
            };
        }
        setContext(createContext(user));
    }, [user]);

    return (
        <AuthorizationContext.Provider value={context}>
            {props.children}
        </AuthorizationContext.Provider>
    );
}
