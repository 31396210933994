import { useContext } from "react";
import { Redirect, Route, RouteProps } from "react-router-dom";
import { GuestAuthenticationContext } from "../../contexts/guest-auth-context/GuestAuthContext";

const GuestPrivateRoute:React.FC<RouteProps<string>> = ({children, ...rest}) => {
    const guestAuthContext = useContext(GuestAuthenticationContext);
    return (
        <Route
            {...rest}
            render={() => {
                if (guestAuthContext.user !== null) {
                    return children;
                }
                else {
                    return <Redirect to="/guest/auth"/>;
                }
            }}
        />
    );
};

export default GuestPrivateRoute;