import GC from '@grapecity/spread-sheets';
import { colCode } from "../../../../utils/excel";
import { NewSpreadsheetAPI } from "../../../../components/spreadsheet/NewSpreadsheetTypes";
import { AcctRenovationsCfg } from "./AcctRenovationsCfg";
import {
    AcctRenovationPackagesQuery, GetAcctRenoMoveOutRatesQuery, GetMoveOutRatesQuery,
    RenovationNumberModel,
    RenovationNumbersModel
} from "../../../../__generated__/generated_types";
import { WorksheetStyles } from "../../../../components/analyst/worksheet/WorksheetStyles";
import { AnalystStyles } from "../../../analyst/AnalystStyles";
import { AcctRenovationsSheetStyles } from "./AcctRenovationsSheetStyles";
import { AcctRenosRowId } from "./enums";
import { AcctRenoRowSpecs } from "./constants";
import { AnalystRenovationsSheetStyles as renoStyles } from "../../../analyst/tab-renovations/AnalystRenovationsSheetStyles";

export interface AcctRenovationsLayoutCfg {
    name: string,
    ssapi: NewSpreadsheetAPI,
}

export class AcctRenovationsLayout{
    protected _name: string;
    protected _ssapi: NewSpreadsheetAPI;
    protected _packageRowsRendered = false;
    protected _initialized = false;

    public numPackages = 1; // Initialize to 1 since the ss template has 1 package row by default
    public pauseChangeReports = false;

    public packageIdsByRow:Record<number,string> = {};

    public constructor(config:AcctRenovationsLayoutCfg){
        this._name = config.name;
        this._ssapi = config.ssapi;
    }

    public initSheet():void{
        if(this._initialized){
            return;
        }
        this._initialized = true;

        this._ssapi.directAccess(spread => {
            const sheet = spread.getSheetFromName(AcctRenovationsCfg.MAIN_SHEET_NAME);

            // Spreadsheet Options
            spread.options.showVerticalScrollbar = false;
            spread.options.showHorizontalScrollbar = false;
            spread.options.allowUserZoom = false;
            spread.options.tabStripVisible = false;

            sheet.options.colHeaderVisible = false;
            sheet.options.rowHeaderVisible = false;

            this.setupColumns(sheet);
            this.applyPreloadFormatting(sheet);

            // Hide rows and columns outside the visible area
            let thisRow = -1;
            while (++thisRow < AcctRenovationsCfg.FIRST_VISIBLE_ROW) {
                sheet.setRowVisible(thisRow, false);
            }

            let thisCol = -1;
            while (++thisCol < AcctRenovationsCfg.FIRST_VISIBLE_COL) {
                sheet.setColumnVisible(thisCol, false);
            }
        });
    }

    public refreshSheetHostDimensions():void{
        this._ssapi.directAccess( spread => {
            const sheet = spread.getSheetFromName(AcctRenovationsCfg.MAIN_SHEET_NAME);

            // Find the visible width of the spreadsheet
            const totalCols = sheet.getColumnCount();
            let ssWidth = 0;
            for(let col = AcctRenovationsCfg.FIRST_VISIBLE_COL; col < totalCols; col++){
                if(sheet.getColumnVisible(col)){
                    ssWidth += sheet.getColumnWidth(col);
                }
            }

            // Find the visible height of the spreadsheet
            const totalRows = sheet.getRowCount();
            let ssHeight = 0;
            for(let row = AcctRenovationsCfg.FIRST_VISIBLE_ROW; row < totalRows; row++){
                if(sheet.getRowVisible(row)){
                    ssHeight += sheet.getRowHeight(row);
                }
            }

            spread.getHost().style.width = `${ssWidth}px`;
            spread.getHost().style.height = `${ssHeight}px`;
            spread.refresh();
        });
    }

    protected initializePackageGrid():void{
        this._ssapi.directAccess(spread => {
            const sheet = spread.getSheetFromName(AcctRenovationsCfg.MAIN_SHEET_NAME);
            this.pauseChangeReports = true;
            const firstPackageRow = AcctRenovationsCfg.FIRST_VISIBLE_ROW + AcctRenosRowId.PACKAGE_ROW;
            for(
                let row = firstPackageRow;
                row < firstPackageRow + this.numPackages;
                row++
            ){
                let col = AcctRenovationsCfg.FIRST_DATA_COL - 1;
                while(++col < AcctRenovationsCfg.TOTALS_COL){
                    sheet.setValue(row, col, null);
                }
            }
            this.pauseChangeReports = false;
        });
    }

    public renderAcctRenovationData(unitTypeId:string, year:number, data: AcctRenovationPackagesQuery):void{
        this._ssapi.directAccess(spread => {
            const sheet = spread.getSheetFromName(AcctRenovationsCfg.MAIN_SHEET_NAME);

            sheet.suspendPaint();

            let firstNewRow:number = AcctRenovationsCfg.FIRST_VISIBLE_ROW + AcctRenosRowId.TOTAL_RENOS_PERC_DEFAULT_ROW;

            // Get the packages for this unit type ID
            const unitPackages = data.queryRenovationPackages.filter(
                pkg => pkg.unitTypeId == unitTypeId,
            );

            // Determine if we need to remove package rows (if the previous unit type ID had more packages)
            let removeRows = 0;
            if(unitPackages.length != this.numPackages && unitPackages.length < this.numPackages){
                removeRows = this.numPackages - unitPackages.length;
            }

            // Create new rows based on the number of packages if they haven't been rendered yet
            if(unitPackages.length > this.numPackages){
                let newRowCount = unitPackages.length - this.numPackages;
                sheet.addRows(
                    firstNewRow,
                    newRowCount,
                );

                for(let row = -1; row < newRowCount; row++){
                    this.initPackageRow(row + firstNewRow, sheet);
                }
            }

            this.numPackages = unitPackages.length;

            // Remove rows if we determined we need to
            if(removeRows > 0){
                sheet.deleteRows(
                    AcctRenovationsCfg.FIRST_VISIBLE_ROW + AcctRenosRowId.TOTAL_RENOS_PERC_DEFAULT_ROW + this.numPackages - 1,
                    removeRows,
                );
            }

            this.initializePackageGrid();

            // Reset package ID by row Record
            this.packageIdsByRow = {};

            // A cell is automatically selected when adding rows, so reset the selection to a cell outside visual range
            sheet.setSelection(0,0, 1, 1);

            const packageNames = unitPackages.map(pkg => pkg.name);
            sheet.setArray(AcctRenovationsCfg.FIRST_VISIBLE_ROW + AcctRenosRowId.PACKAGE_ROW, AcctRenovationsCfg.ROW_LABEL_COLUMN, packageNames);

            // Get package data for this unit type ID (there may be none)
            const packageData = unitPackages.filter(
                pkg => pkg.unitTypeId == unitTypeId
            );

            // Populate package data
            packageData.forEach(
                (thisPkg, idx) => {
                    let pkgRow:(string|number|null|undefined)[] = [];

                    if(thisPkg.renovationNumbers.length > 0){
                        // Note: Assuming year instances are unique, get the info for this year
                        const thisYear = thisPkg.renovationNumbers.filter(pkg => pkg.year == year)[0];
                        const pkgMonthValues = thisYear?.expected.map(month => month.value) ?? [];

                        pkgRow = pkgRow.concat(pkgMonthValues);
                    }

                    const thisRow = firstNewRow + idx;
                    sheet.setArray(thisRow - 1, AcctRenovationsCfg.FIRST_DATA_COL, [pkgRow]);

                    this.packageIdsByRow[thisRow - 1] = thisPkg.id;

                    // Row Total Cell
                    const firstDataColCode = colCode(AcctRenovationsCfg.FIRST_DATA_COL);
                    const rowTotalColCode = colCode(AcctRenovationsCfg.TOTALS_COL - 1);
                    sheet.setFormula(
                        thisRow - 1,
                        AcctRenovationsCfg.TOTALS_COL,
                        `=SUM(${firstDataColCode}${thisRow}:${rowTotalColCode}${thisRow})`
                    );
                }
            );

            // Add the totals row formulas
            const totalsRow = AcctRenosRowId.TOTAL_RENOS_PERC_DEFAULT_ROW + this.numPackages + 1;

            let totalsFormulas:string[] = new Array(13).fill(null).map(
                (_, idx) => {
                    const thisCol = colCode(idx + AcctRenovationsCfg.FIRST_DATA_COL);
                    const firstDataRow = AcctRenovationsCfg.FIRST_DATA_ROW + 2;
                    const lastDataRow = firstDataRow + this.numPackages - 1;
                    return `=SUM(${thisCol}${firstDataRow}:${thisCol}${lastDataRow})`;
                }
            );

            sheet.setArray(
                totalsRow, AcctRenovationsCfg.FIRST_DATA_ROW - 1, [totalsFormulas], true
            );

            sheet.resumePaint();
        });

        this.refreshSheetHostDimensions();
    }

    public renderExpiringLeaseMoveOuts(year:number, data:GetAcctRenoMoveOutRatesQuery):void{
        this._ssapi.directAccess( spread => {
            const sheet = spread.getSheetFromName(AcctRenovationsCfg.MAIN_SHEET_NAME);

            let hasExpiringLeaseMoveoutInfo = false;

            sheet.suspendPaint();

            // Get actual and reforecast values for the relevant year
            const thisYearActuals = (data.actuals ?? []).find(entry => entry.year == year) ?? { unitTypeScheduleValues: [] };
            const thisYearReforecast = (data.reforecast ?? []).find(entry => entry.year == year) ?? { unitTypeScheduleValues: [] };

            const renoMoveoutRates = new Array(12).fill(null);
            const moveouts = new Array(12).fill(null);

            // Reset totals rows in case there's data there from before
            for(let col = AcctRenovationsCfg.FIRST_DATA_COL; col <= AcctRenovationsCfg.TOTALS_COL; col++){
                sheet.setValue(AcctRenovationsCfg.FIRST_VISIBLE_ROW + AcctRenosRowId.MOVE_OUTS_SUMMARY, col, null);
                sheet.setValue(AcctRenovationsCfg.FIRST_VISIBLE_ROW + AcctRenosRowId.RENOS_MOVE_OUT_PERC_DEFAULT_ROW + this.numPackages - 1, col, null);
            }

            // Roll over actuals values
            thisYearActuals.unitTypeScheduleValues.forEach(entry => {
                // Get the reforecast value for this monthIndex
                const reforecast = thisYearReforecast.unitTypeScheduleValues.find(refoEntry => refoEntry.monthIndex == entry.monthIndex);

                // If we found a reforecast value for this monthIndex, use it instead of the actuals value
                if(reforecast){
                    renoMoveoutRates[entry.monthIndex] = Number(reforecast.moveOutRate);
                    moveouts[reforecast.monthIndex] = Number(renoMoveoutRates[entry.monthIndex] * reforecast.leaseExpirationCount);
                    if(reforecast.moveOutRate){
                        hasExpiringLeaseMoveoutInfo = true;
                    }
                } else {
                    renoMoveoutRates[entry.monthIndex] = Number(entry.moveOutRate);
                    moveouts[entry.monthIndex] = Number(renoMoveoutRates[entry.monthIndex] * entry.leaseExpirationCount);
                    if(entry.moveOutRate){
                        hasExpiringLeaseMoveoutInfo = true;
                    }
                }
            });

            const firstDataCol = AcctRenovationsCfg.FIRST_DATA_COL;
            const lastDataCol = AcctRenovationsCfg.FIRST_DATA_COL + 11;
            const moveOutsSummaryRow = AcctRenovationsCfg.FIRST_VISIBLE_ROW + AcctRenosRowId.MOVE_OUTS_SUMMARY + 1;
            const renoMoveoutPercRow = AcctRenovationsCfg.FIRST_VISIBLE_ROW + AcctRenosRowId.RENOS_MOVE_OUT_PERC_DEFAULT_ROW + this.numPackages - 1;

            // Only render the summary cell if there are values
            if(hasExpiringLeaseMoveoutInfo){
                // Set Expiring Lease Move Outs row formulas and total column formula
                sheet.setArray(
                    AcctRenovationsCfg.FIRST_VISIBLE_ROW + AcctRenosRowId.MOVE_OUTS_SUMMARY,
                    AcctRenovationsCfg.FIRST_DATA_COL,
                    [moveouts]
                );

                sheet.setFormula(
                    AcctRenovationsCfg.FIRST_VISIBLE_ROW + AcctRenosRowId.MOVE_OUTS_SUMMARY,
                    AcctRenovationsCfg.TOTALS_COL,
                    `=SUM(${colCode(firstDataCol)}${moveOutsSummaryRow}:${colCode(lastDataCol)}${moveOutsSummaryRow})`,
                );

                const renosOverMoveouts = renoMoveoutRates.map( (renoMoveoutRate, idx) => {
                    const totalMoveoutsThisMonth = sheet.getValue(renoMoveoutPercRow - 1, firstDataCol + idx);
                    if(totalMoveoutsThisMonth > 0){
                        return (totalMoveoutsThisMonth/renoMoveoutRate)/100;
                    } else {
                        return null;
                    }
                });

                // TODO: Have Briant/Tiffanie double check these numbers. For now, hiding row.
                // Write Reno/Move Out % Row values
                // sheet.setArray(
                //     renoMoveoutPercRow,
                //     AcctRenovationsCfg.FIRST_DATA_COL,
                //     [renosOverMoveouts]
                // );
                sheet.setRowVisible(renoMoveoutPercRow, false);

                // TODO: Come back and determine why this cell isn't behaving as expected
                // const avgMoveoutPercs = `=AVERAGE(${colCode(firstDataCol)}${renoMoveoutPercRow + 1}:${colCode(lastDataCol)}${renoMoveoutPercRow + 1})`;
                // sheet.setFormula(
                //     renoMoveoutPercRow,
                //     AcctRenovationsCfg.TOTALS_COL,
                //     avgMoveoutPercs,
                // );
            } else {
                sheet.setValue(
                    AcctRenovationsCfg.FIRST_VISIBLE_ROW + AcctRenosRowId.MOVE_OUTS_SUMMARY,
                    AcctRenovationsCfg.TOTALS_COL,
                    null,
                );
                // sheet.setValue(
                //     renoMoveoutPercRow,
                //     AcctRenovationsCfg.TOTALS_COL,
                //     null,
                // );
            }

            sheet.resumePaint();

            this.refreshSheetHostDimensions();
        });
    }

    protected initPackageRow(row: number, sheet: GC.Spread.Sheets.Worksheet):void{
        const rowSpec = AcctRenoRowSpecs[AcctRenosRowId.PACKAGE_ROW];

        // Set Row Height
        sheet.setRowHeight(row, AcctRenovationsCfg.DATA_ROW_H);

        // Process Row Label
        sheet.setStyle(row, AcctRenovationsCfg.FIRST_VISIBLE_COL, rowSpec.style.labelCell.clone());

        for (let colIdx = AcctRenovationsCfg.FIRST_VISIBLE_COL + 1; colIdx < AcctRenovationsCfg.FIRST_VISIBLE_COL + 14; colIdx++) {
            if(rowSpec.style.dataCell){
                // Apply Data Cell styling
                sheet.setStyle(row, colIdx, rowSpec.style.dataCell.clone());
            }
        }
    }

    // Layout Helpers _______________________________________________________________________________________
    private setupColumns(sheet: GC.Spread.Sheets.Worksheet){
        sheet.setColumnWidth(AcctRenovationsCfg.FIRST_VISIBLE_COL, AcctRenovationsCfg.ROW_LABEL_COL_W);

        let thisCol = AcctRenovationsCfg.FIRST_DATA_COL - 1;
        while(++thisCol < AcctRenovationsCfg.FIRST_DATA_COL + 12){
            sheet.setColumnWidth(thisCol, AcctRenovationsCfg.MONTH_COL_W);
        }

        sheet.setColumnWidth(thisCol, AcctRenovationsCfg.TOTALS_COL_W);
    }

    private applyPreloadFormatting(sheet:GC.Spread.Sheets.Worksheet):void{
        const allCols = Object.keys(AcctRenosRowId);
        const numCols = allCols.length / 2;

        for (
            let rowIdx = AcctRenovationsCfg.FIRST_VISIBLE_ROW, idx = 0;
            rowIdx < AcctRenovationsCfg.FIRST_VISIBLE_ROW + AcctRenosRowId.RENOS_MOVE_OUT_PERC_DEFAULT_ROW + 1;
            rowIdx++, idx++
        ) {
            const rowSpec = AcctRenoRowSpecs[idx as AcctRenosRowId];

            // Set Row Height
            sheet.setRowHeight(rowIdx, AcctRenovationsCfg.DATA_ROW_H);

            // Process Row Label
            sheet.setStyle(rowIdx, AcctRenovationsCfg.FIRST_VISIBLE_COL, rowSpec.style.labelCell.clone());
            sheet.setValue(rowIdx, AcctRenovationsCfg.FIRST_VISIBLE_COL, rowSpec.label);

            for (let colIdx = AcctRenovationsCfg.FIRST_VISIBLE_COL + 1; colIdx < AcctRenovationsCfg.FIRST_VISIBLE_COL + 14; colIdx++) {
                if(rowSpec.style.dataCell){
                    // Apply Data Cell styling
                    sheet.setStyle(rowIdx, colIdx, rowSpec.style.dataCell.clone());
                }
            }
        }

        this.refreshSheetHostDimensions();
    }
}
