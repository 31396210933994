import React, {ReactElement, useContext, useEffect, useState} from "react";
import {useNewSpreadsheetAPI} from "../../../../components/spreadsheet/NewSpreadsheetHooks";
import {NewSpreadsheet} from "../../../../components/spreadsheet/NewSpreadsheet";
import viewTemplate from "../account_renovations_view.json";
import {AcctRenovationsLayout, AcctRenovationsLayoutCfg} from "../helpers/AcctRenovationsLayout";
import {
    AcctRenovationPackagesQuery,
    useAcctRenovationPackagesLazyQuery,
    useGetAcctRenoMoveOutRatesLazyQuery,
    useUpdateRenovationPackageCountMutation,
    VersionType,
} from "../../../../__generated__/generated_types";
import {BudgetingType} from "../../../../BudgetingType";
import {AcctRenovationsCfg} from "../helpers/AcctRenovationsCfg";
import {userIsReadOnly} from "../../../../authorization/AuthorizationCheckers";
import {AuthContext} from "../../../../contexts/AuthContext";
import {Property} from "../../../../contexts/properties/PropertiesContext";

const FIRST_VISIBLE_ROW = 2;

export interface AcctRenovationsSSProps {
    year: number,
    currentVersionYear: number,
    months: string[],
    property: Property | undefined,
    unitName: string,
    unitTypeId: string,
    type: string,
    budgetingType: string,
    readOnly?: boolean,
    accountDataLoading?: (isLoading:boolean) => void
}

export default function AcctRenovationsSpread(props: AcctRenovationsSSProps): ReactElement {

    const { property, type, unitTypeId, year, currentVersionYear, budgetingType, readOnly = false } = props;
    const { user } = useContext(AuthContext);

    const [layout, setLayout] = useState<AcctRenovationsLayout|undefined>(undefined);
    const [lastHoveredRow, setHoveredRow] = useState<number>(-1);

    const [propertyPackageData, setPropertyPackageData] = useState<AcctRenovationPackagesQuery|undefined>();
    const [dataReady, setDataReady] = useState<{
        renovationDataReady:boolean,
        moveOutRatesDataReady:boolean,
    }>({
        renovationDataReady:false,
        moveOutRatesDataReady:false,
    });

    const [
        getAcctRenovationPackages,
        { data: renovationData, loading: renovationDataLoading, error: renoDataError }
    ] = useAcctRenovationPackagesLazyQuery({
        fetchPolicy: "network-only",
    });

    const [
        getMoveOutRates,
        { data: moveOutRatesData, loading: moveOutRatesLoading, error: moveOutRatesDataError }
    ] = useGetAcctRenoMoveOutRatesLazyQuery();

    const [updateRenovationPackageCountMutation, { data, loading, error }] = useUpdateRenovationPackageCountMutation();

    const {
        handlers: sshandlers,
        api: ssapi,
        isSpreadsheetReady,
        // cellTargetClicked,
        // cellClicked,
        cellHovered,
        cellsChanged,
    } = useNewSpreadsheetAPI();

    /**
     * useEffect(): unitName
     * Called when a new unit has been selected so information for that unit may be retrieved
     */
    useEffect(
        ()=>{
            if(!dataReady.renovationDataReady || !dataReady.moveOutRatesDataReady || !layout || !propertyPackageData){
                return;
            }

            // Call for renovation package information
            layout.renderAcctRenovationData(unitTypeId, currentVersionYear, propertyPackageData);
        },
        [unitTypeId, layout, propertyPackageData]
    );

    /**
     * useEffect():
     */
    useEffect(
        ()=>{
            if(!type || !property){
                return;
            }

            // Call for renovation package information
            getAcctRenovationPackages({
                variables: {
                    propertyIds: [property.id],
                    yearAndType: {
                        versionType: type === BudgetingType.BUDGET ? VersionType.Budget : VersionType.Reforecast,
                        year: type === BudgetingType.BUDGET ? property.budgetYear : property.reforecastYear
                    }
                },
            });
        },
        [type, property]
    );

    /**
     * useEffect(): isSpreadsheetReady, config.isReady
     * Main initializer for spreadsheet interaction, instantiates the layout helper once the config
     * and spreadsheet are both ready
     */
    useEffect(
        () => {
            if(
                isSpreadsheetReady
            ){
                ssapi.suspend();

                const layoutConfig: AcctRenovationsLayoutCfg = {
                    name: 'Account Renovations',
                    ssapi,
                };

                ssapi.setTemplate({ template: viewTemplate });
                setLayout(new AcctRenovationsLayout(layoutConfig));

                ssapi.resume();
            }
        },
        [isSpreadsheetReady]);

    /**
     * useEffect(): isSpreadsheetReady, layout, renovationData, renovationDataLoading
     * Sets the spreadsheet template, then iniatializes the sheet via the layout helper, and finally
     * sents the loaded renovation data to be rendered by the layout helper
     */
    useEffect(
        () => {
            if(isSpreadsheetReady && renovationData && !renovationDataLoading && property && layout){
                ssapi.suspend();
                layout.initSheet();
                setPropertyPackageData(renovationData);
                layout.renderAcctRenovationData(unitTypeId, currentVersionYear, renovationData);
                ssapi.resume();

                setDataReady(previous => {
                    return {
                        ...previous,
                        ...{renovationDataReady: true}
                    };
                });

                // Package data is loaded, so dynamic rows are in place. Call for move out rates
                getMoveOutRates({
                    variables: {
                        propertyId: property.id,
                        year,
                        budgetYear: type == BudgetingType.BUDGET ? year + 1 : year,
                        unitTypeId,
                        getReforecast: true,
                    }
                });
            }
        },
        [isSpreadsheetReady, layout, renovationData, renovationDataLoading, property]
    );

    /**
     * useEffect(): isSpreadsheetReady, layout, moveOutRatesData, moveOutRatesLoading
     */
    useEffect(
        ()=>{
            if(isSpreadsheetReady && moveOutRatesData && !moveOutRatesLoading && layout){
                ssapi.suspend();

                setDataReady(previous => {
                    return {
                        ...previous,
                        ...{moveOutRatesDataReady: true}
                    };
                });

                layout.renderExpiringLeaseMoveOuts(currentVersionYear, moveOutRatesData);

                if(userIsReadOnly(user) || readOnly === true){
                    ssapi.lockAllCells();
                }

                ssapi.resume();

                if(props.accountDataLoading){
                    props.accountDataLoading(false);
                }
            }
        },
        [isSpreadsheetReady, layout, moveOutRatesData, moveOutRatesLoading, readOnly]
    );

    useEffect(
            () => {
                // An account or property has changed... set data loading to true
                if(props.accountDataLoading){
                    props.accountDataLoading(true);
                }
            },
            [props.unitTypeId, props.property]
    );

    /**
     * useEffect(): cellHovered?.row
     */
    useEffect(
        () => {
            if(!cellHovered || !layout || cellHovered.row < FIRST_VISIBLE_ROW || lastHoveredRow == cellHovered.row){
                return;
            }

        },
        [cellHovered?.row]
    );

    function isChangeInEditableArea(row:number, col:number){
        return col >= AcctRenovationsCfg.FIRST_DATA_COL
            && col <= AcctRenovationsCfg.FIRST_DATA_COL + 11
            && row >= AcctRenovationsCfg.FIRST_DATA_ROW
            && row <= AcctRenovationsCfg.FIRST_DATA_ROW + (layout?.numPackages ?? 0);
    }

    /**
     * useEffect(): cellsChanged, layout
     * Called when cells have changed. Checks to see if the changes cells are part of the editable range and
     * performs the call to the mutation query to store the updated values.
     */
    useEffect(
        ()=>{
            if(!cellsChanged || cellsChanged.length == 0 || !layout || layout.pauseChangeReports){
                return;
            }

            cellsChanged.forEach(
                cell => {
                    if(isChangeInEditableArea(cell.row, cell.col)){
                        // Don't allow entry of non-numeric values
                        if(cell.value != null && isNaN(cell.value)){
                            ssapi.directAccess( spread => {
                                const sheet = spread.getSheetFromName(AcctRenovationsCfg.MAIN_SHEET_NAME);
                                sheet.setValue(cell.row, cell.col, null);
                                return;
                            });
                        }
                        const packageId = layout.packageIdsByRow[cell.row];
                        if(packageId && property){
                            updateRenovationPackageCountMutation({
                                variables: {
                                    renovationPackageId: packageId,
                                    yearAndType: {
                                        versionType: type === BudgetingType.BUDGET ? VersionType.Budget : VersionType.Reforecast,
                                        year: type === BudgetingType.BUDGET ? property.budgetYear : property.reforecastYear
                                    },
                                    expected: [
                                        {
                                            monthIndex: cell.col - AcctRenovationsCfg.FIRST_VISIBLE_COL - 1,
                                            value: cell.value
                                        }
                                    ],
                                }
                            });
                        }
                    }
                }
            );

        },
        [cellsChanged, layout]
    );

    return (
        <React.Fragment>
            <div>
                <NewSpreadsheet
                    startRow={0}
                    startCol={0}
                    rows={9}
                    cols={16}
                    handlers={sshandlers}
                    allowVerticalScroll={true}
                    allowHorizontalScroll={true}
                    subscribeToMouse={true}
                    safeAreaFirstRow={3}
                    safeAreaFirstColumn={3}
                    style={{
                        maxWidth: "calc(100vw - 100px)",
                        maxHeight: "calc(100vh - 206px)",
                        padding: "0 0", ...(
                            (!dataReady.renovationDataReady || !dataReady.moveOutRatesDataReady)
                                ? { display: "none" } : {}
                        )
                    }}
                    allowZoom={false}
                    readOnly={readOnly}
                />
            </div>
        </React.Fragment>
    );
}
