import TwoYearsLayout, { AddedFinancialEntityTypes } from "../../../../sjs/layout/two-years/TwoYearsLayout";
import { SJSCfg } from "../../../../sjs/layout/shared/SJSConfig";
import { SJSSheetStyles as SJSS } from "../../../../sjs/layout/shared/SJSSheetStyles";

import { FinancialsConfig as CFG } from "../FinancialsConfig";

import { TwoYearsConfig as TWO_YR_CFG } from "../../../../sjs/layout/two-years/helpers/TwoYearsConfig";
import { TwoYearsColId } from "../../../../sjs/layout/two-years/helpers/enums";

import {
    ToggleIconCell,
} from "../../../../components/spreadsheet/spreadjs-custom-cell-types/ToggleIconCell";

import { FinancialEntityType } from "../../../../__generated__/generated_types";
import { Config, DriverInfo } from "../../../analyst/config/useConfig";
import { NewSpreadsheetAPI } from "../../../../components/spreadsheet/NewSpreadsheetTypes";
import {
    setupButton,
    setupButtonHover
} from "../../../../components/spreadsheet/spreadjs-custom-cell-types/icons/IconResources";
import { FinancialEntityRequestInfo, FinancialsData } from "../data/logic/financialsData";
import { FinancialEntityMetaData } from "../data/logic/financialEntityMetaData";
import { AnalystCfg } from "../../../analyst/AnalystCfg";
import { FinancialsLoadPhases } from "../FinancialsV2PlanningHubV1Shim";
import { financialsToggleIconConfig } from "./logic/financialsLayout";

export interface IFinancialsLayoutConfig {
    name: string,
    ssapi: NewSpreadsheetAPI,
    // eslint-disable-next-line @typescript-eslint/ban-types
    template: Object,
    onRenderDone: () => void;
    config: Config;
    reportLoadProgress?: (progressAmt: number, phaseId?: FinancialsLoadPhases) => void,
    registerTimingEvent?: (event: string, eventSource?: string) => void,
}

export enum StyleModifier {
    HasReforecastDrivers = 'HAS_REFO_DRIVERS',
    HasBudgetDrivers = 'HAS_BUDGET_DRIVERS',
}

export default class FinancialsLayout extends TwoYearsLayout {
    private readonly _onRenderDone: () => void;
    private _config: Config;
    private _registerTimingEvent: (event: string, eventSource?: string) => void;

    constructor(props: IFinancialsLayoutConfig) {
        super({
            name: props.name,
            ssapi: props.ssapi,
            template: props.template,
            year: props.config.year,
            firstReforecastMonth: props.config.startReforecastMonthIndex,
            reportLoadProgress: props.reportLoadProgress,
        });

        this._onRenderDone = props.onRenderDone;
        this._config = props.config;

        if(props.registerTimingEvent){
            this._registerTimingEvent = props.registerTimingEvent;
        }
        else {
            // eslint-disable-next-line @typescript-eslint/no-empty-function
            this._registerTimingEvent = (_event: string, _eventSource?: string): void => {
            };
        }

        props.ssapi.directAccess(spread => {
            const sheet = spread.getSheetFromName(TWO_YR_CFG.MAIN_TAB_NAME);

            sheet.getCell(TWO_YR_CFG.HEADER_ROW, TWO_YR_CFG.LABEL_COL)
                .value('Accounts')
                .setStyleName('SJSS.ENTITY_COL_LABEL');

            sheet.options.selectionBorderColor = 'transparent';

            spread.options.showHorizontalScrollbar = true;

            // sheet.options.rowHeaderVisible = true;
        });
    }

    public onMouseOverPropertyRow(row: number): void {
        console.log(`onMouseOverPropertyRow() > entered property row ${row}`);
    }

    public onMouseOverAccountRow(row: number): void {
        this._ssapi.directAccess(spread => {
            const sheet = spread.getSheetFromName(TWO_YR_CFG.MAIN_TAB_NAME);

            const thisCell = sheet.getCell(row, TWO_YR_CFG.LABEL_COL);
            const cellType = thisCell.cellType();

            if(cellType && cellType.typeName == 'ToggleIconCell'){
                const cellRef = (cellType as ToggleIconCell);
                cellRef.updateCellInfo({
                    ...cellRef.getCellInfo(),
                    ...{ showButton: true }
                });
            }
        });
    }

    public onMouseOutAccountRow(row: number): void {
        this._ssapi.directAccess(spread => {
            const sheet = spread.getSheetFromName(TWO_YR_CFG.MAIN_TAB_NAME);

            const thisCell = sheet.getCell(row, TWO_YR_CFG.LABEL_COL);
            const cellType = thisCell.cellType();

            if(cellType && cellType.typeName == 'ToggleIconCell'){
                const cellRef = (cellType as ToggleIconCell);
                cellRef.updateCellInfo({
                    ...cellRef.getCellInfo(),
                    ...{ showButton: false }
                });
            }
        });
    }

    override applyRowHoverStyle(row: number): void {
        console.log(`applyRowHoverStyle(${row})`);

    }

    override applyRowDefaultStyle(sheetRow: number): void {
        console.log(`applyRowDefaultStyle(${sheetRow})`);
    }

    public applyEntityDirectChildStyle(entityChild: FinancialEntityMetaData): boolean {
        switch (entityChild.type) {
            case FinancialEntityType.Category: {
                this.applyCategoryRowStyle(entityChild.sheetRow, entityChild.depth, entityChild.isExpanded);
                return true;
            }
            case FinancialEntityType.Account: {
                this.applyAccountRowStyle(entityChild.sheetRow, entityChild);
                return true;
            }
            default: {
                console.log(`DEV: Need to apply style to entity...`, entityChild);
                return false;
            }
        }
    }

    protected resetPropertyRowStyles(row: number, rowCount = 1, styleModifiers?: StyleModifier[]):void {
        this._ssapi.directAccess(spread => {
            const sheet = spread.getActiveSheet();

            const actualsRange = sheet.getRange(row, TWO_YR_CFG.FIRST_MONTH_COL, rowCount, this._firstReforecastMonth - 1);
            const lastActualColumn = sheet.getRange(row, TWO_YR_CFG.FIRST_MONTH_COL + this._firstReforecastMonth - 1, rowCount, 1);
            const refoRange = sheet.getRange(row, TWO_YR_CFG.FIRST_MONTH_COL + this._firstReforecastMonth, rowCount, 12 - this._firstReforecastMonth);
            const thisYearSummaryColumn = sheet.getRange(row, TWO_YR_CFG.TOTALS_COL, rowCount, 1);
            const budgetRange = sheet.getRange(row, TWO_YR_CFG.FIRST_BUDGET_MONTH_COL, rowCount, 12);
            const budgetYearSummaryColumn = sheet.getRange(row, TWO_YR_CFG.BUDGET_TOTALS_COL, rowCount, 1);

            // actualsRange.setStyle(SJSS.ACTUAL_CELL);
            // lastActualColumn.setStyle(SJSS.LAST_ACTUAL_CELL);
            actualsRange.setStyleName('SJSS.ACTUAL_CELL');
            lastActualColumn.setStyleName('SJSS.LAST_ACTUAL_CELL');

            let refoRangeStyleName = 'SJSS.EDITABLE_CELL_BLUE';
            if(styleModifiers?.includes(StyleModifier.HasReforecastDrivers)){
                refoRangeStyleName = 'SJSS.REFORECAST_DRIVER_CELL';
            }
            refoRange.setStyleName(refoRangeStyleName);

            thisYearSummaryColumn.setStyleName('SJSS.TOTALS_DATA');

            let budgetRangeStyleName = 'SJSS.EDITABLE_CELL_BLUE';
            if(styleModifiers?.includes(StyleModifier.HasBudgetDrivers)){
                budgetRangeStyleName = 'SJSS.BUDGET_DRIVER_CELL';
            }
            budgetRange.setStyleName(budgetRangeStyleName);

            budgetYearSummaryColumn.setStyleName('SJSS.TOTALS_DATA');

        });
    }

    public autofitRows(rows: number[]): void {
        this._ssapi.directAccess(spread => {
            const sheet = spread.getSheetFromName(TWO_YR_CFG.MAIN_TAB_NAME);
            rows.forEach(row => {
                sheet.getCell(row, TWO_YR_CFG.LABEL_COL).wordWrap(true);
                sheet.autoFitRow(row);
            });
        });
        // TODO: Figure out why some rows are returned with a height of 0 when there's content
    }

    public requestRowOpenByMeta(
        rowsMetaData: FinancialEntityMetaData[],
        childrenMetaData: FinancialEntityMetaData[]|undefined,
    ): {
        metaRowUpdatesRecord: Record<FinancialEntityMetaData['id'], Partial<FinancialEntityMetaData>>,
        unloadedEntities: FinancialEntityRequestInfo[],
    } {
        // this._registerTimingEvent('requestRowOpenByMeta()...', 'FinancialsLayout');
        const sheet = this._ssapi.getSpread()?.getSheetFromName(TWO_YR_CFG.MAIN_TAB_NAME);

        const unloadedEntities: FinancialEntityRequestInfo[] = [];
        const gatheredAccountIds: string[] = [];
        const gatheredCategoryIds: string[] = [];
        const styledRows: number[] = [];

        const metaRowUpdatesRecord: Record<string, Partial<FinancialEntityMetaData>> = {};

        const applyUpdateToUpdatedMetaRowsRecord = (rowEntityId: string, rowMetaUpdate: Partial<FinancialEntityMetaData>): Partial<FinancialEntityMetaData> => {
            if(metaRowUpdatesRecord[rowEntityId] == undefined){
                metaRowUpdatesRecord[rowEntityId] = rowMetaUpdate as Partial<FinancialEntityMetaData>;
            }
            else {
                const currentMetaUpdateRecord = metaRowUpdatesRecord[rowEntityId] as FinancialEntityMetaData;
                metaRowUpdatesRecord[rowEntityId] = Object.assign(currentMetaUpdateRecord, rowMetaUpdate);
            }
            return metaRowUpdatesRecord[rowEntityId] as Partial<FinancialEntityMetaData>;
        };

        // Rows is plural to support opening multiple rows in a single request. Most uses will be a single row.
        rowsMetaData.forEach(rowMeta => {
            // this._registerTimingEvent('Processing row...', 'FinancialsLayout');
            // this.setCellOpenState(rowMeta.sheetRow, true);

            // More needs to happen (at least once) when a row transitions from a closed to open state... loading children, styling, etc
            if(!rowMeta.isExpanded){
                if(rowMeta.type == FinancialEntityType.Account){
                    const acctPropertiesInfo: AccountPropertiesInfo[] = rowMeta.directChildRows.map(
                        childRow => {
                            return {
                                driverInfo: this._config.drivers[childRow.id],
                                dataRow: childRow.dataRow,
                                sheetRow: childRow.sheetRow,
                            };
                        }
                    );

                    if(!rowMeta.hasBeenExpanded){
                        this.applyAccountPropertiesStyles(
                            rowMeta.sheetRow + 1,
                            rowMeta,
                            acctPropertiesInfo,
                        );
                    }
                }
                else { // Any non-Account row entry

                    // Confirm each child row is styled and loaded...
                    childrenMetaData?.forEach(childRowMeta => {

                        let rowMetaUpdate: Partial<FinancialEntityMetaData> = {};

                        if(!childRowMeta.isStyled){
                            const styleWasApplied = this.applyEntityDirectChildStyle(childRowMeta);
                            if(styleWasApplied){
                                rowMetaUpdate = {
                                    isStyled: styleWasApplied,
                                };
                                styledRows.push(childRowMeta.sheetRow);
                            }

                            if(rowMetaUpdate.id != undefined){
                                applyUpdateToUpdatedMetaRowsRecord(
                                    rowMetaUpdate.id,
                                    { isStyled: styleWasApplied }
                                );
                            }
                        }

                        if(childRowMeta.loadState == 'UNLOADED'){
                            switch (childRowMeta.type) {
                                case FinancialEntityType.Account: {
                                    gatheredAccountIds.push(childRowMeta.id);
                                    break;
                                }
                                case FinancialEntityType.Category: {
                                    gatheredCategoryIds.push(childRowMeta.id);
                                }
                            }
                        }
                    });
                }

                const summarySheetRow = rowMeta?.summarySheetRow;
                if(summarySheetRow != undefined){
                    this._ssapi.directAccess(spread => {
                        const sheet = spread.getSheetFromName(TWO_YR_CFG.MAIN_TAB_NAME);

                        if(rowMeta){
                            // Style the summary row if it hasn't been styled yet...
                            if(!rowMeta.isStyled){
                                switch (rowMeta.type) {
                                    case FinancialEntityType.Category:
                                    case FinancialEntityType.Account: {
                                        this.applyCategorySummaryRowStyle(summarySheetRow, sheet, rowMeta?.depth);
                                        break;
                                    }
                                    case FinancialEntityType.Component: {
                                        this.applyComponentSummaryRowStyle(summarySheetRow, sheet);
                                        break;
                                    }
                                }
                                styledRows.push(summarySheetRow);

                                if(rowMeta.id != undefined){
                                    applyUpdateToUpdatedMetaRowsRecord(rowMeta.id, { isStyled: true });
                                }
                            }
                        }
                    });
                }

                if(gatheredCategoryIds.length > 0){
                    unloadedEntities.push({
                        type: FinancialEntityType.Category,
                        entityIds: gatheredCategoryIds
                    });
                }
                if(gatheredAccountIds.length > 0){
                    unloadedEntities.push({
                        type: FinancialEntityType.Account,
                        entityIds: gatheredAccountIds
                    });
                }
            }

            // if(styledRows.length > 0){
            //     this.autofitRows(styledRows);
            // }

            // styledRows.forEach( row => {
            //     sheet?.setRowHeight(row, SJSCfg.MINUMUM_ROW_HEIGHT);
            // });


            if(rowMeta.id != undefined){
                applyUpdateToUpdatedMetaRowsRecord(rowMeta.id, { isExpanded: true, hasBeenExpanded: true, });
            }

            this.setCellOpenState(rowMeta.sheetRow, true);
            this.setGroupOpenState(rowMeta.sheetRow, true);

            switch (rowMeta.type) {
                case FinancialEntityType.Category: {
                    this.applyCategoryRowStyle(rowMeta.sheetRow, rowMeta.depth, true);
                    break;
                }
                case FinancialEntityType.Account: {
                    this.applyAccountRowStyle(rowMeta.sheetRow, {
                        ...rowMeta,
                        isExpanded: true
                    });
                    break;
                }
            }
        });

        return {
            metaRowUpdatesRecord,
            unloadedEntities,
        };
    }

    public closeSheetRowByMeta = (rowData: FinancialEntityMetaData[]): FinancialEntityMetaData[] => {
        return rowData.map(entry => {
            const updatedEntry = {
                ...entry,
                isExpanded: false,
            };

            this.setCellOpenState(entry.sheetRow, false);
            this.setGroupOpenState(entry.sheetRow, false);

            this.applyCategoryRowStyle(entry.sheetRow, entry.depth, false);

            return updatedEntry;
        });
    };

    public closeSheetRow(sheetRow: number, financialsData: FinancialsData): { dataRowIdx: number, updatedRowMetaData: FinancialEntityMetaData|undefined } {
        const dataRowIdx = sheetRow - TWO_YR_CFG.FIRST_DATA_ROW;
        let rowMeta = financialsData.rowMetaData[sheetRow - TWO_YR_CFG.FIRST_DATA_ROW];

        if(rowMeta){
            rowMeta = {
                ...rowMeta,
                isExpanded: false,
            };

            this.setCellOpenState(sheetRow, false);
            this.setGroupOpenState(sheetRow, false);
        }
        else {
            console.warn(`Unable to access row ${sheetRow - TWO_YR_CFG.FIRST_DATA_ROW} metadata`);
        }

        return {
            dataRowIdx,
            updatedRowMetaData: rowMeta
        };
    }

    public setCellOpenState = (sheetRow: number, isOpen: boolean): void => {
        this._ssapi.directAccess(spread => {
            const sheet = spread.getSheetFromName(TWO_YR_CFG.MAIN_TAB_NAME);

            const thisCell = sheet.getCell(sheetRow, TWO_YR_CFG.LABEL_COL);
            const cellRef: ToggleIconCell = (thisCell.cellType() as ToggleIconCell);
            if(cellRef){
                cellRef.updateCellInfo({
                    ...cellRef.getCellInfo(),
                    ...{ isOpen }
                });
            }
        });
    };

    public applyCategoryRowStyle(
        sheetRow: number,
        depth: FinancialEntityMetaData['depth'],
        isExpanded: FinancialEntityMetaData['isExpanded']
    ): void {
        this._ssapi.directAccess(spread => {
            const sheet = spread.getSheetFromName(TWO_YR_CFG.MAIN_TAB_NAME);

            const ctgyStyle = this.getEntityLabelStyle(FinancialEntityType.Category, depth);

            const labelCell = sheet.getCell(sheetRow, TWO_YR_CFG.LABEL_COL);
            const labelCellType = labelCell.cellType() as ToggleIconCell;

            if(!labelCellType){
                // First application of custom cell type
                labelCell.setStyle(ctgyStyle);
                labelCell.cellType(
                    new ToggleIconCell({
                        entityType: FinancialEntityType.Category,
                        isOpen: isExpanded,
                        showIcon: true,
                        toggleIconSpecs: financialsToggleIconConfig,
                        hotspotSpecs: {
                            visible: false,
                            x: -25,
                            y: 1,
                            width: 20,
                            height: 20,
                        }
                    })
                );
            }

            if(isExpanded){
                // Apply standard styles to the Actual/Reforecast months
                // 3 styles - left end w/ 3 sides, middle span w/ top and bottom, right end w/ 3 sides
                this.applyCappedRowSpanStyle(
                    sheetRow,
                    TWO_YR_CFG.FIRST_MONTH_COL,
                    TWO_YR_CFG.FIRST_MONTH_COL + this._firstReforecastMonth - 1,
                    {
                        leftCap: SJSS.SECTION_VALUE_OPEN_FIRST_CELL_LABEL,
                        centerSpan: SJSS.SECTION_VALUE_OPEN_MID_CELL_LABEL,
                        rightCap: SJSS.SECTION_VALUE_OPEN_MID_CELL_LABEL,
                    },
                );

                sheet.setStyleName(
                    sheetRow,
                    TWO_YR_CFG.FIRST_MONTH_COL + this._firstReforecastMonth - 1,
                    'SJSS.SECTION_VALUE_OPEN_LAST_ACTUAL_CELL'
                );

                // TODO: Add support for 1 reforecast month (December only)
                this.applyCappedRowSpanStyle(
                    sheetRow,
                    TWO_YR_CFG.FIRST_MONTH_COL + this._firstReforecastMonth,
                    TWO_YR_CFG.FIRST_MONTH_COL + this._firstReforecastMonth + (11 - this._firstReforecastMonth),
                    {
                        leftCap: SJSS.SECTION_VALUE_OPEN_FIRST_CELL_LABEL,
                        centerSpan: SJSS.SECTION_VALUE_OPEN_MID_CELL_LABEL,
                        rightCap: SJSS.SECTION_VALUE_OPEN_MID_CELL_LABEL,
                    }
                );

                // This Year totals column
                sheet.getCell(
                    sheetRow, TWO_YR_CFG.TOTALS_COL
                ).setStyleName('SJSS.TOTALS_COLUMN_DATA_CELL_OPEN');

                // Apply standard styles to the Budget months
                // 3 styles - left end w/ 3 sides, middle span w/ top and bottom, right end w/ 3 sides
                this.applyCappedRowSpanStyle(
                    sheetRow,
                    TWO_YR_CFG.FIRST_BUDGET_MONTH_COL,
                    TWO_YR_CFG.FIRST_BUDGET_MONTH_COL + 11,
                    {
                        leftCap: SJSS.SECTION_VALUE_OPEN_FIRST_CELL_LABEL,
                        centerSpan: SJSS.SECTION_VALUE_OPEN_MID_CELL_LABEL,
                        rightCap: SJSS.SECTION_VALUE_OPEN_LAST_CELL_LABEL,
                    }
                );

                // Budget totals column
                sheet.getCell(
                    sheetRow, AnalystCfg.BUDGET_TOTALS_COL
                ).setStyleName('SJSS.TOTALS_COLUMN_DATA_CELL_OPEN');
            }
            else {
                this.applyDataCellStylesToRow(sheetRow, sheet);
            }
        });
    }

    public applyAccountRowStyle(sheetRow: number, rowMeta: FinancialEntityMetaData): void {

        this._ssapi.directAccess(spread => {
            const sheet = spread.getSheetFromName(TWO_YR_CFG.MAIN_TAB_NAME);
            const accountStyle = this.getEntityLabelStyle(FinancialEntityType.Account, rowMeta.depth);

            const labelCell = sheet.getCell(sheetRow, TWO_YR_CFG.LABEL_COL);
            const labelCellType = labelCell.cellType() as ToggleIconCell;

            if(!labelCellType && !rowMeta.isStyled){
                labelCell.setStyle(accountStyle);
                labelCell.cellType(
                    new ToggleIconCell({
                        entityType: FinancialEntityType.Account,
                        showIcon: true,
                        toggleIconSpecs: financialsToggleIconConfig,
                        isOpen: CFG.ROWS_OPEN_ON_LOAD,
                        hotspotSpecs: {
                            visible: false,
                            x: -25,
                            y: 8,
                            width: 20,
                            height: 20,
                        },
                        buttonSpecs: {
                            visible: false,
                            x: 196,
                            y: 9,
                            xPadOffset: TWO_YR_CFG.LABEL_BASE_INDENT + (TWO_YR_CFG.LABEL_LEVEL_INDENT * rowMeta.depth),
                            buttonImg: setupButton,
                            buttonHoverImg: setupButtonHover,
                            width: 78,
                            height: 24,
                        },
                    })
                );
            }
            if(rowMeta.isExpanded){
                this.applyCategoryRowStyle(rowMeta.sheetRow, rowMeta.depth, true);
            } else {
                this.applyDataCellStylesToRow(rowMeta.sheetRow, sheet);
            }
        });
    }

    public applyAccountPropertiesStyles(firstPropertySheetRow: number, rowMeta: FinancialEntityMetaData, acctPropertiesInfo: AccountPropertiesInfo[]): void {
        // this._registerTimingEvent('applyAccountPropertiesStyles()', 'FinancialsLayout');
        // this._ssapi.directAccess(spread => {
        const sheet = this._ssapi.getSpread()?.getSheetFromName(SJSCfg.MAIN_SHEET_NAME);
        if(!sheet){
            return;
        }

        acctPropertiesInfo.forEach((acctPropinfo, propIdx) => {
            let styleModifiers: StyleModifier[] = [];
            if(acctPropinfo.driverInfo?.reforecastDrivers){
                styleModifiers.push(StyleModifier.HasReforecastDrivers);
            }
            if(acctPropinfo.driverInfo?.budgetDrivers){
                styleModifiers.push(StyleModifier.HasBudgetDrivers);
            }
            this.resetPropertyRowStyles(firstPropertySheetRow + propIdx, 1, styleModifiers);

            const ctgyStyle = this.getEntityLabelStyle(AddedFinancialEntityTypes.Property, rowMeta.depth + 1);
            sheet.setStyle(acctPropinfo.sheetRow, TWO_YR_CFG.LABEL_COL, ctgyStyle);
        });
        // });

        // acctPropertiesInfo.map(property => property.sheetRow).forEach( row => {
        //     sheet.setRowHeight(row, SJSCfg.MINUMUM_ROW_HEIGHT);
        // });

        // this.autofitRows(acctPropertiesInfo.map(property => property.sheetRow));
        // this._registerTimingEvent('applyAccountPropertiesStyles() DONE', 'FinancialsLayout');
    }

    public renderInitialViewTableRows(financialsData: FinancialsData, _setDataReady: React.Dispatch<React.SetStateAction<boolean>>): void {
        const { tableData, rowMetaData } = financialsData;

        const rowCount = tableData.length + SJSCfg.FIRST_DATA_ROW;

        this._ssapi.suspend();

        /**
         * Called when all row processing is complete
         */
        const reportViewReady = () => {
            this.reportLoadProgress(100);
            this.initialized = true;
            this._ssapi.resume();

            this._onRenderDone();
        };

        const sheet = this._ssapi.getSpread()?.getSheetFromName(TWO_YR_CFG.MAIN_TAB_NAME);
        if(sheet){
            sheet.setRowCount(rowCount);
            sheet.setColumnCount(Object.keys(TwoYearsColId).filter((v) => isNaN(Number(v))).length + SJSCfg.FIRST_VISIBLE_COL);

            sheet.setArray(
                TWO_YR_CFG.FIRST_DATA_ROW,
                TWO_YR_CFG.LABEL_COL,
                tableData,
            );

            const sliceSize = Math.floor(tableData.length / 100);
            const totalSlices = tableData.length / sliceSize;

            const applyStylesToSlice = (sliceIdx: number) => {
                // Get slice...
                const sliceStart = sliceIdx * sliceSize;
                const sliceEnd = sliceStart + sliceSize;

                const dataSlice = tableData.slice(sliceStart, sliceEnd);

                dataSlice.forEach((_, rowIdx) => {
                    const tableRowIdx = rowIdx + sliceStart;
                    const rowMeta = rowMetaData[tableRowIdx];

                    if(rowMeta){
                        // console.log(`applyStylesToSlice() > slice ${sliceIdx}:tableRowIdx ${tableRowIdx}`);
                        const sheetRow = rowMeta.sheetRow;
                        // Create outline groups regardless of load visibility
                        if(rowMeta.totalOutlineGroupRows && rowMeta.totalOutlineGroupRows > 0){
                            sheet.rowOutlines.group(sheetRow + 1, rowMeta.totalOutlineGroupRows);
                            sheet.rowOutlines.setCollapsed(sheetRow + 1, !CFG.ROWS_OPEN_ON_LOAD);
                        }

                        // Don't style any row that's above the STYLE_TO_DEPTH_ON_LOAD threshold
                        if(rowMeta?.depth > CFG.STYLE_TO_DEPTH_ON_LOAD){
                            return;
                        }

                        switch (rowMeta.type) {
                            case FinancialEntityType.Category: {
                                this.applyCategoryRowStyle(sheetRow, rowMeta.depth, rowMeta.isExpanded);
                                // this.applyDefaultCellStyleToRow(sheetRow, sheet);
                                break;
                            }

                            case FinancialEntityType.Account: {
                                this.applyAccountRowStyle(sheetRow, rowMeta);
                                // this.applyDefaultCellStyleToRow(sheetRow, sheet);
                                break;
                            }

                            case AddedFinancialEntityTypes.CategorySummary:
                            case AddedFinancialEntityTypes.AccountSummary: {
                                this.applyCategorySummaryRowStyle(sheetRow, sheet, rowMeta.depth);
                                break;
                            }

                            case AddedFinancialEntityTypes.ComponentSummary: {
                                this.applyComponentSummaryRowStyle(sheetRow, sheet);
                                break;
                            }
                        }

                        // Apply autoFitRow to all visible rows
                        // sheet.autoFitRow(sheetRow);
                        // if(sheet.getRowHeight(sheetRow) < SJSCfg.MINUMUM_ROW_HEIGHT){
                        //     sheet.setRowHeight(sheetRow, SJSCfg.MINUMUM_ROW_HEIGHT);
                        // }
                    }
                });
            };

            const procLoopFunc = (sliceIdx: number) => {
                if(sliceIdx > totalSlices){
                    reportViewReady();
                    return;
                }

                // Call applyStyles
                applyStylesToSlice(sliceIdx);

                // Call UI update
                this.reportLoadProgress(sliceIdx);

                setTimeout(() => {
                    procLoopFunc(sliceIdx + 1);
                }, 0);
            };

            procLoopFunc(0);
        }
    }

    public applyFinancialsDataUpdate(financialsData: FinancialsData): void {
        this._ssapi.directAccess(spread => {
            const sheet = spread.getSheetFromName(SJSCfg.MAIN_SHEET_NAME);
            sheet.setArray(
                TWO_YR_CFG.FIRST_DATA_ROW,
                TWO_YR_CFG.LABEL_COL,
                financialsData.tableData,
            );
        });
    }
}

export type AccountPropertiesInfo = {
    driverInfo: (DriverInfo|undefined);
    dataRow: number,
    sheetRow: number,
}
