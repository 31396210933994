import { useEffect, useReducer, useRef, useState } from "react";
import { toast } from "react-toastify";
import { TDriverAccountMetric, TDriverCustomItem, TDriverMetricsYear, TDriverOperationalMetric, TPendingDriverMetricsYear } from "../../../contexts/account/data/logic/driversAndWorksheetData";
import { compItemsCustomNamesMap, IPayrollCompensationItem, IPayrollDriver, TAllAvailableDrivers, TListPayrollPositions } from "../../../contexts/account/data/utils";
import { Property, useProperties } from "../../../contexts/properties/PropertiesContext";
import { GrowthCalcMethod, MonthlyAverageBasePeriod, PayrollPrimaryCompensationItem, PendingUpdates, PercentGrowthBasePeriod, TForecastPeriodLocks, useGetPayrollCompensationItemsLazyQuery, useGetPayRolPositionsLazyQuery, useGetPropertyForecastLocksLazyQuery, useQueryCustomItemsLazyQuery, useSetPropertyDriversMutation, VersionType, WorksheetDriverLineUpdateByDescriptionInput } from "../../../__generated__/generated_types";
import { SelectableFinancialEntity, TVersionTypeDropdownItem } from "../PropertyDrivers";
import { IDataByDriverType } from "./useEditAssumptions";
import * as css from "../styles/propertyDrivers.module.scss";


export enum ActiveView {
    selectAccounts = "SELECT_ACCOUNTS",
    editFormulas = "EDIT_FORMULAS",
    editAssumptions = "EDIT_ASSUMPTIONS",
}

interface ParsedFormDriversByAccountId {
    [accountId: string]: TDriverMetricsYear,
}

interface PendingUpdatesByAccountId {
    [accountId: string]: PendingUpdates,
}

export interface IStashFxBarDirtyStateByAccountId {
    accountId: string,
    dirtyDrivers: TDriverMetricsYear,
    originalDrivers: TDriverMetricsYear,
    isWorksheetDeleted: boolean,
}

export interface ILineItemsMenuOptionsByAccountId {
    [accountId: string]: string[],
}

export interface ISetLineItemsMenuOptionsByAccountId {
    accountId: string;
    worksheetTypeAhead: string[];
}

export interface IUpdateAccountLocksByAccountId {
    accountId: string;
    isLocked: boolean;
}

export type TAccountLocks = Map<string, boolean>;

type State = {
    accountLocks: TAccountLocks,
    accountLocksDraft: TAccountLocks,
    activeView: ActiveView,
    budgetYear: number | undefined,
    isConfigureModelingMethodsModalOpen: boolean,
    parsedFormDriversByAccountId: ParsedFormDriversByAccountId,
    parsedOriginalDriversByAccountId: ParsedFormDriversByAccountId,
    lineItemsMenuOptionsByAccountId: ILineItemsMenuOptionsByAccountId,
    pendingUpdatesByAccountId: PendingUpdatesByAccountId,
    reforecastStartMonth: number,
    selectedAccounts: SelectableFinancialEntity[],
    versionType: TVersionTypeDropdownItem,
    worksheetDeletedFor: string[],
    year: number,
    propertyForecastLocks: TForecastPeriodLocks | undefined,
}

const date = new Date();
const defaultYear = date.getFullYear();

const DEFAULT_PROPERTY_FORECAST_LOCKS: TForecastPeriodLocks = {
    isBudgetLocked: true,
    isReforecastLocked: true,
};

const INITIAL_STATE: State = {
    accountLocks: new Map(),
    accountLocksDraft: new Map(),
    activeView: ActiveView.selectAccounts,
    budgetYear: undefined,
    isConfigureModelingMethodsModalOpen: false,
    parsedFormDriversByAccountId: {},
    parsedOriginalDriversByAccountId: {},
    lineItemsMenuOptionsByAccountId: {},
    pendingUpdatesByAccountId: {},
    reforecastStartMonth: 0,
    selectedAccounts: [],
    versionType: VersionType.Budget,
    worksheetDeletedFor: [],
    year: defaultYear,
    propertyForecastLocks: DEFAULT_PROPERTY_FORECAST_LOCKS,
};

interface AddPendingUpdatesByAccountId {
    type: "ADD_PENDING_UPDATES_BY_ACCOUNT_ID",
    accountId: string,
    isWorksheetDeleted: boolean,
    parsedFormDrivers: TDriverMetricsYear,
    parsedOriginalDrivers: TDriverMetricsYear,
}

interface ResetPropertyDrivers {
    type: "RESET_PROPERTY_DRIVERS",
}

interface BulkUpdateAccountLocksDraft {
    type: "BULK_UPDATE_ACCOUNT_LOCKS_DRAFT",
    isLocked: boolean,
}

interface UpdateAccountLocksByAccountId {
    type: "UPDATE_ACCOUNT_LOCKS_BY_ACCOUNT_ID",
    accountId: string,
    isLocked: boolean,
}

interface UpdateAccountLocksDraftByAccountId {
    type: "UPDATE_ACCOUNT_LOCKS_DRAFT_BY_ACCOUNT_ID",
    accountId: string,
    isLocked: boolean,
}

interface SetActiveView {
    type: "SET_ACTIVE_VIEW",
    viewToActivate: ActiveView,
}

interface SetBudgetYear {
    type: "SET_BUDGET_YEAR",
    yearToSet: number,
}

interface SetIsConfigureModelingMethodModalOpen {
    type: "SET_IS_CONFIGURE_MODELING_METHOD_MODAL_OPEN",
    isOpen: boolean,
}

interface SetLineItemsMenuOptionsByAccountId extends ISetLineItemsMenuOptionsByAccountId{
    type: "SET_LINE_ITEMS_MENU_OPTIONS_BY_ACCOUNT_ID",
}

interface SetReforecastStartMonth {
    type: "SET_REFORECAST_START_MONTH",
    reforecastStartMonth: number,
}

interface SetSelectedAccounts {
    type: "SET_SELECTED_ACCOUNTS",
    accounts: SelectableFinancialEntity[],
}

interface SetVersionType {
    type: "SET_VERSION_TYPE",
    versionTypeToSet: TVersionTypeDropdownItem,
}

interface SetYear {
    type: "SET_YEAR",
    yearToSet: number,
}

type Action = AddPendingUpdatesByAccountId
    | BulkUpdateAccountLocksDraft
    | ResetPropertyDrivers
    | SetActiveView
    | SetBudgetYear
    | SetIsConfigureModelingMethodModalOpen
    | SetLineItemsMenuOptionsByAccountId
    | SetReforecastStartMonth
    | SetSelectedAccounts
    | SetVersionType
    | SetYear
    | UpdateAccountLocksByAccountId
    | UpdateAccountLocksDraftByAccountId;

export interface IUsePropertyDriversReturn {
    accountLocks: TAccountLocks;
    accountLocksDraft: TAccountLocks;
    bulkUpdateAccountLocksDraft: (isLocked: boolean) => void;
    updateAccountLocksByAccountId: (args: IUpdateAccountLocksByAccountId) => void;
    updateAccountLocksDraftByAccountId: (args: IUpdateAccountLocksByAccountId) => void;
    isPropertyLocked: boolean;

    activeView: ActiveView;
    budgetYear: number | undefined;
    currentProperty: Property | undefined;
    propertyId: string;
    reforecastStartMonth: number;
    selectedAccounts: SelectableFinancialEntity[];
    versionType: TVersionTypeDropdownItem;
    year: number;

    propertyForecastLocks: TForecastPeriodLocks;

    addPendingUpdatesByAccountId: (args: IStashFxBarDirtyStateByAccountId) => void;

    isConfigureModelingMethodsModalOpen: boolean;
    setIsConfigureModelingMethodsModalOpen: (isOpen: boolean) => void;

    parsedFormDriversByAccountId: ParsedFormDriversByAccountId;
    parsedOriginalDriversByAccountId: ParsedFormDriversByAccountId;
    pendingUpdatesByAccountId: PendingUpdatesByAccountId;
    setActiveView: (viewToActivate: ActiveView) => void;
    setSelectedAccounts: (accounts: SelectableFinancialEntity[]) => void;
    setVersionType: (versionTypeToSet: TVersionTypeDropdownItem) => void;

    shouldFxBarStashChanges: boolean;
    setShouldFxBarStashChanges: React.Dispatch<React.SetStateAction<boolean>>;

    stashFxBarDirtyStateByAccountId: (args: IStashFxBarDirtyStateByAccountId) => void;

    willBulkUpdate: boolean;
    setWillBulkUpdate: React.Dispatch<React.SetStateAction<boolean>>;
    saveBulkUpdateState: (drivers: TDriverMetricsYear) => void;
    bulkUpdateState: TDriverMetricsYear | undefined;
    shouldApplyBulkUpdatesToFormulaBars: boolean;

    saveFxBarsInBulk: (originalAssumptionsData: IDataByDriverType, assumptionsData: IDataByDriverType) => void;
    isSaving: boolean;

    refetchDWD: boolean;
    setRefetchDWD: React.Dispatch<React.SetStateAction<boolean>>;

    isNoChangesModalOpen: boolean;
    setIsNoChangesModalOpen: React.Dispatch<React.SetStateAction<boolean>>;

    accounts: SelectableFinancialEntity[];
    setAccounts: React.Dispatch<React.SetStateAction<SelectableFinancialEntity[]>>;

    allAvailableDrivers: TAllAvailableDrivers;

    lineItemsMenuOptionsByAccountId: ILineItemsMenuOptionsByAccountId;
    setLineItemsMenuOptionsByAccountId: (args: ISetLineItemsMenuOptionsByAccountId) => void;
}


export default function usePropertyDrivers (): IUsePropertyDriversReturn {
    const [state, dispatch] = useReducer(propertyDriversReducer, INITIAL_STATE);
    const { currentProperty, propertyId } = useProperties();
    const prevPropertyIdRef = useRef<string>();
    const prevVersionTypeRef = useRef<VersionType>();

    // TODO (Sahil): Move all these to the common state and use reducers
    const [shouldFxBarStashChanges, setShouldFxBarStashChanges] = useState<boolean>(false);
    const [stashed, setStashed] = useState<TDriverMetricsYear[]>([]);

    const [willBulkUpdate, setWillBulkUpdate] = useState<boolean>(false);
    const [bulkUpdateState, setBulkUpdateState] = useState<TDriverMetricsYear>();

    const [accounts, setAccounts] = useState<SelectableFinancialEntity[]>([]);

    const [propertyForecastLocks, setPropertyForecastLocks] = useState<TForecastPeriodLocks>(DEFAULT_PROPERTY_FORECAST_LOCKS);

    const [isSaving, setIsSaving] = useState<boolean>(false);
    const [isNoChangesModalOpen, setIsNoChangesModalOpen] = useState<boolean>(false);
    const [refetchDWD, setRefetchDWD] = useState<boolean>(false);

    const [isPropertyLocked, setPropertyLocked] = useState<boolean>(false);

    const [payrollDriversDb, setPayrollDriversDb] = useState<IPayrollDriver[]>();
    const [customItemsDb, setCustomItemsDb] = useState<string[]>([]);
    const [localPayrollPositionsData, setLocalPayrollPositionsData] = useState<TListPayrollPositions>();
    const [localPayrollCompensationItems, setLocalPayrollCompensationItems] = useState<IPayrollCompensationItem[]>();

    const [getPayrollPositionsData, {data: payrollPositionsResult, loading: payrollPositionsDataLoading, error: payrollPositionsDataError}] = useGetPayRolPositionsLazyQuery({
        fetchPolicy: "no-cache"
    });

    const [getPayrollCompensationItemsData, {data: payrollCompensationItemsResult, loading: payrollCompensationItemsDataLoading, error: payrollCompensationItemsDataError}] = useGetPayrollCompensationItemsLazyQuery({
        fetchPolicy: "no-cache"
    });

    const [getPropertyForecastPeriodLocks, {data: propertyForecastLocksResult, loading: propertyForecastLocksLoading}] = useGetPropertyForecastLocksLazyQuery({
        fetchPolicy: "no-cache",
    });

    const [getCustomItemsData, {data: customItemsResult, loading: customItemsLoading}] = useQueryCustomItemsLazyQuery({
        fetchPolicy: "no-cache"
    });

    /**
     * Re-populate the select accounts table if the user switches to another property
     */
    useEffect(() => {
        if (prevPropertyIdRef.current && prevVersionTypeRef.current) {
            dispatch({ type: "RESET_PROPERTY_DRIVERS" });
            setRefetchDWD(true);
        }
        prevPropertyIdRef.current = propertyId;
        prevVersionTypeRef.current = state.versionType;
    }, [propertyId, state.versionType]);

    useEffect(() => {
        if (state.budgetYear) {
            getPropertyForecastPeriodLocks({
                variables: {
                    propertyId: propertyId,
                    budgetYear: state.budgetYear,
                },
            });
        }
    }, [state.budgetYear, propertyId]);

    useEffect(() => {
        if (propertyForecastLocksLoading || !propertyForecastLocksResult || propertyForecastLocksResult.propertyForecastLocks === null || propertyForecastLocksResult.propertyForecastLocks === undefined) {
            return;
        }

        const isForecastPeriodLockedForProperty = (state.versionType === VersionType.Reforecast && propertyForecastLocksResult.propertyForecastLocks.isReforecastLocked)
            || (state.versionType === VersionType.Budget && propertyForecastLocksResult.propertyForecastLocks.isBudgetLocked);

            setPropertyLocked(isForecastPeriodLockedForProperty);
    }, [propertyForecastLocksResult, propertyForecastLocksLoading, state.versionType]);

    useEffect(() => {
        getPayrollPositionsData();
        getPayrollCompensationItemsData();
        getCustomItemsData();
    }, []);

    useEffect(() => {
        if (payrollPositionsDataLoading && !payrollPositionsResult?.listPayrollPositions && payrollPositionsDataError) {
            return;
        }

        setLocalPayrollPositionsData(payrollPositionsResult?.listPayrollPositions);
    }, [payrollPositionsDataLoading, payrollPositionsResult, payrollPositionsDataError]);

    useEffect(() => {
        if (customItemsResult && !customItemsLoading) {
            setCustomItemsDb(customItemsResult.queryCustomItems.map(it => it.name));
        }
    }, [customItemsResult, customItemsLoading]);

    useEffect(() => {
        if (payrollCompensationItemsDataLoading || !payrollCompensationItemsResult || payrollCompensationItemsDataError) {
            return;
        }

        const staticPayrollCompensationItems: IPayrollCompensationItem[] = [
            { customName: null, id: PayrollPrimaryCompensationItem.Bonus, type: PayrollPrimaryCompensationItem.Bonus },
            { customName: null, id: PayrollPrimaryCompensationItem.Compensation, type: PayrollPrimaryCompensationItem.Compensation },
            { customName: null, id: PayrollPrimaryCompensationItem.Overtime, type: PayrollPrimaryCompensationItem.Overtime },
        ];

        let allPayrollCompItems: IPayrollCompensationItem[] = [
            ...staticPayrollCompensationItems,
            ...payrollCompensationItemsResult.listCompensationItems,
        ];

        allPayrollCompItems = allPayrollCompItems.map((payrollCompItem: IPayrollCompensationItem) => ({
            ...payrollCompItem,
            customName: compItemsCustomNamesMap[payrollCompItem.type],
        }));

        setLocalPayrollCompensationItems(allPayrollCompItems);
    }, [payrollCompensationItemsDataLoading, payrollCompensationItemsResult, payrollCompensationItemsDataError]);

    // Set payroll drivers when both positions and compensations are ready
    useEffect(() => {
        if (localPayrollCompensationItems && localPayrollPositionsData) {
            const tempPayrollDrivers: IPayrollDriver[] = localPayrollCompensationItems.map((compItem) => ({
                ...compItem,
                positions: localPayrollPositionsData,
            }));

            setPayrollDriversDb(tempPayrollDrivers);
        }
    }, [localPayrollCompensationItems, localPayrollPositionsData]);

    useEffect(() => {
        if (propertyId && state.budgetYear) {
            getPropertyForecastPeriodLocks({
                variables: {
                    propertyId,
                    budgetYear: state.budgetYear,
                }
            });
        }
    },
    // Depends on version type just so it can re-fetch when the user switches between budget and reforecast
    [propertyId, state.versionType, state.year]);

    useEffect(() => {
        if (propertyForecastLocksLoading || !propertyForecastLocksResult || !propertyForecastLocksResult.propertyForecastLocks) {
            return;
        }

        setPropertyForecastLocks(propertyForecastLocksResult.propertyForecastLocks);
    }, [propertyForecastLocksLoading, propertyForecastLocksResult]);

    const [SetPropertyDrivers, {
        data: setPropertyDriversData,
        loading: setPropertyDriversLoading,
    }] = useSetPropertyDriversMutation({
        notifyOnNetworkStatusChange: true
    });

    useEffect(() => {
        if (isSaving && setPropertyDriversData && !setPropertyDriversLoading) {
            toast.success("Property drivers saved successfully!", {
                className: css.savePdToast,
            });
            dispatch({ type: "RESET_PROPERTY_DRIVERS" });
            setIsSaving(false);
        }
    }, [isSaving, setPropertyDriversData, setPropertyDriversLoading]);

    /**
     * Clicking 'cancel' or 'save' or clicking outside the modal to close it will all trigger this effect
     * Always reset the dirty state and re-fetch data from the db whenever this happens
    */
    useEffect(() => {
        if (state.isConfigureModelingMethodsModalOpen === false) {
            dispatch({ type: "RESET_PROPERTY_DRIVERS" });
            setRefetchDWD(true);
        }
    }, [state.isConfigureModelingMethodsModalOpen]);

    const [shouldApplyBulkUpdatesToFormulaBars, setApplyBulkUpdatesToFormulaBars] = useState<boolean>(false);

    const stashFxBarDirtyStateByAccountId = ({
        accountId,
        dirtyDrivers,
        originalDrivers,
        isWorksheetDeleted,
    }: IStashFxBarDirtyStateByAccountId) => {
        setStashed([ ...stashed, dirtyDrivers ]);
        dispatch({
            type: "ADD_PENDING_UPDATES_BY_ACCOUNT_ID",
            accountId,
            isWorksheetDeleted,
            parsedFormDrivers: dirtyDrivers,
            parsedOriginalDrivers: originalDrivers,
        });
        setShouldFxBarStashChanges(false);
    };

    useEffect(() => {
        if (currentProperty) {
            // A budget year is always reforecast year + 1
            setYear(state.versionType === VersionType.Reforecast ? currentProperty.reforecastYear : currentProperty.reforecastYear + 1);
            setBudgetYear(currentProperty.budgetYear);
            setReforecastStartMonth(currentProperty.reforecastStartMonthIndex);
        }
    }, [currentProperty, state.versionType]);

    useEffect(() => {
        if (willBulkUpdate && bulkUpdateState !== undefined) {
            setWillBulkUpdate(false);
            setApplyBulkUpdatesToFormulaBars(true);
        }
    }, [bulkUpdateState, setApplyBulkUpdatesToFormulaBars, willBulkUpdate]);

    const bulkUpdateAccountLocksDraft = (isLocked: boolean) => {
        dispatch({
            type: "BULK_UPDATE_ACCOUNT_LOCKS_DRAFT",
            isLocked,
        });
    };

    const updateAccountLocksByAccountId = ({accountId, isLocked}: IUpdateAccountLocksByAccountId) => {
        dispatch({
            type: "UPDATE_ACCOUNT_LOCKS_BY_ACCOUNT_ID",
            accountId,
            isLocked,
        });
    };

    const updateAccountLocksDraftByAccountId = ({accountId, isLocked}: IUpdateAccountLocksByAccountId) => {
        dispatch({
            type: "UPDATE_ACCOUNT_LOCKS_DRAFT_BY_ACCOUNT_ID",
            accountId,
            isLocked,
        });
    };

    const setIsConfigureModelingMethodsModalOpen = (isOpen: boolean) => {
        dispatch({
            type: "SET_IS_CONFIGURE_MODELING_METHOD_MODAL_OPEN",
            isOpen,
        });
    };

    const setLineItemsMenuOptionsByAccountId = ({accountId, worksheetTypeAhead}: ISetLineItemsMenuOptionsByAccountId) => {
        dispatch({
            type: "SET_LINE_ITEMS_MENU_OPTIONS_BY_ACCOUNT_ID",
            accountId,
            worksheetTypeAhead,
        });
    };

    const saveBulkUpdateState = (drivers: TDriverMetricsYear) => {
        setBulkUpdateState(drivers);
    };

    const addPendingUpdatesByAccountId = ({
        accountId,
        dirtyDrivers,
        originalDrivers,
        isWorksheetDeleted,
    }: IStashFxBarDirtyStateByAccountId) => {
        dispatch({
            type: "ADD_PENDING_UPDATES_BY_ACCOUNT_ID",
            accountId,
            isWorksheetDeleted,
            parsedFormDrivers: dirtyDrivers,
            parsedOriginalDrivers: originalDrivers,
        });
    };

    const saveFxBarsInBulk = (originalAssumptionsData: IDataByDriverType, assumptionsData: IDataByDriverType) => {
        /**
         * Compare account locks to see if they changed
        */
        const pendingAccountLocks: {accountId: string, isLocked: boolean}[] = [];

        state.accountLocksDraft.forEach((isLocked, accountId) => {
            if (state.accountLocks.get(accountId) !== isLocked) {
                pendingAccountLocks.push({
                    accountId,
                    isLocked,
                });
            }
        });

        /**
         * Simply compares the original drivers with the dirty state and determines what `pendingUpdates` should
         * look like for each selected GL account
         */
        const generatePendingUpdatesByAccountId = () => {
            const pendingUpdatesByAccountId: PendingUpdatesByAccountId = {};

            for (const [accountId, allDrivers] of Object.entries(state.parsedFormDriversByAccountId)) {

                const PendingUpdatesAdd: TPendingDriverMetricsYear = {
                    operational: [],
                    revenue: [],
                    worksheet: [],
                    account: [],
                    accountPercentageAugment: null,
                    payroll: [],
                    monthlyAverage: [],
                    percentGrowth: [],
                    annualTargetValue: [],
                    customItems: []

                };
                const PendingUpdatesRemove: TPendingDriverMetricsYear = {
                    operational: [],
                    revenue: [],
                    worksheet: [],
                    account: [],
                    accountPercentageAugment: null,
                    payroll: [],
                    monthlyAverage: [],
                    percentGrowth: [],
                    annualTargetValue: [],
                    customItems: []
                };
                const PendingUpdatesUpdate: TPendingDriverMetricsYear = {
                    operational: [],
                    revenue: [],
                    worksheet: [],
                    account: [],
                    accountPercentageAugment: null,
                    payroll: [],
                    monthlyAverage: [],
                    percentGrowth: [],
                    annualTargetValue: [],
                    customItems: []
                };

                const pendingUpdates = {
                    add: PendingUpdatesAdd,
                    remove: PendingUpdatesRemove,
                    update: PendingUpdatesUpdate,
                };
                const allOriginalDrivers = state.parsedOriginalDriversByAccountId[accountId];

                if (allOriginalDrivers) {
                    const opDrivers = allDrivers["operational"];
                    allOriginalDrivers["operational"].forEach((each) => {
                        const matchingDriver = opDrivers.find((eachOpDriver) => eachOpDriver.type === each.type);
                        if (matchingDriver === undefined) {
                            const { amount, locked, percentage, values, ...newEach } = { ...each };
                            pendingUpdates["remove"]["operational"].push(newEach);
                        } else {
                            if (matchingDriver && matchingDriver.lookbackPeriod !== each.lookbackPeriod) {
                                pendingUpdates["update"]["operational"].push({
                                    driverMetricTypeId: each.driverMetricTypeId,
                                    sourceMetricId: each.sourceMetricId,
                                    type: each.type,
                                    lookbackPeriod: matchingDriver.lookbackPeriod || 0,
                                });
                            }
                        }
                    });
                    opDrivers.forEach((each) => {
                        if (allOriginalDrivers["operational"].find((eachOriginalOpDriver) => eachOriginalOpDriver.type === each.type) === undefined) {
                            const { amount, locked, percentage, values, ...newEach } = { ...each };
                            pendingUpdates["add"]["operational"].push(newEach);
                        }
                    });

                    const revenueDrivers = allDrivers["revenue"];
                    allOriginalDrivers["revenue"].forEach((each) => {
                        if (revenueDrivers.find((eachRevDriver) => eachRevDriver.revenueType === each.revenueType) === undefined) {
                            pendingUpdates["remove"]["revenue"].push({ sourceId: each.sourceId, revenueType: each.revenueType });
                        }
                    });
                    revenueDrivers.forEach((each) => {
                        if (allOriginalDrivers["revenue"].find((eachOriginalRevDriver) => eachOriginalRevDriver.revenueType === each.revenueType) === undefined) {
                            pendingUpdates["add"]["revenue"].push({ sourceId: each.sourceId, revenueType: each.revenueType });
                        }
                    });

                    const accDrivers = allDrivers["account"];
                    allOriginalDrivers["account"].forEach((each) => {
                        const matchingDriver = accDrivers.find((eachAccDriver) => eachAccDriver.accountId === each.accountId);
                        if (matchingDriver === undefined) {
                            pendingUpdates["remove"]["account"].push({
                                accountId: each.accountId,
                                lookbackPeriod: each.lookbackPeriod ? each.lookbackPeriod : 0,
                            });
                        } else {
                            if (matchingDriver && matchingDriver.lookbackPeriod !== each.lookbackPeriod) {
                                pendingUpdates["update"]["account"].push({
                                    accountId: each.accountId,
                                    lookbackPeriod: matchingDriver.lookbackPeriod || 0,
                                });
                            }
                        }
                    });
                    accDrivers.forEach((each) => {
                        if (allOriginalDrivers["account"].find((eachOriginalAccDriver) => eachOriginalAccDriver.accountId === each.accountId) === undefined) {
                            pendingUpdates["add"]["account"].push({
                                accountId: each.accountId,
                                lookbackPeriod: each.lookbackPeriod ? each.lookbackPeriod : 0,
                            });
                        }
                    });

                    const {minValue: originalMinValue, maxValue: originalMaxValue} = allOriginalDrivers.accountPercentageAugment;
                    const {minValue, maxValue} = allDrivers.accountPercentageAugment;
                    if(originalMinValue === null && originalMaxValue === null) {
                        if(minValue !== null || maxValue !== null) {
                            // Adding
                            pendingUpdates["add"]["accountPercentageAugment"] = {
                                minValue: minValue,
                                maxValue: maxValue
                            };
                        }
                    } else {
                        if(minValue === null && maxValue === null) {
                            // Removing
                            pendingUpdates["remove"]["accountPercentageAugment"] = {
                                minValue: null,
                                maxValue: null
                            };
                        } else if(originalMinValue !== minValue || originalMaxValue !== maxValue) {
                            // Updating
                            pendingUpdates["update"]["accountPercentageAugment"] = {
                                minValue: minValue,
                                maxValue: maxValue
                            };
                        }
                        // Do nothing as nothing has changed.
                    }

                    const percentGrowthDriver = allDrivers["percentGrowth"][0];
                    const originalPercentGrowthDriver = allOriginalDrivers["percentGrowth"][0];

                    if (percentGrowthDriver !== undefined || originalPercentGrowthDriver !== undefined) {
                        if (percentGrowthDriver === undefined && originalPercentGrowthDriver !== undefined) {
                            // Driver was deleted
                            pendingUpdates["remove"]["percentGrowth"].push(originalPercentGrowthDriver);
                        } else if (percentGrowthDriver !== undefined && originalPercentGrowthDriver === undefined) {
                            // Driver was added
                            pendingUpdates["add"]["percentGrowth"].push(percentGrowthDriver);
                        } else if (percentGrowthDriver !== undefined && originalPercentGrowthDriver !== undefined) {
                            const hasDrivenBeenUpdated = percentGrowthDriver.percentGrowthBasePeriod !== originalPercentGrowthDriver.percentGrowthBasePeriod
                                || percentGrowthDriver.percentGrowthAdjustmentValue !== originalPercentGrowthDriver.percentGrowthAdjustmentValue
                                || ((percentGrowthDriver.percentGrowthBasePeriod !== PercentGrowthBasePeriod.SameMonthLastYear && percentGrowthDriver.percentGrowthBasePeriod !== PercentGrowthBasePeriod.BudgetSameMonthCurrentYear)
                                    && (originalPercentGrowthDriver.percentGrowthBasePeriod !== PercentGrowthBasePeriod.SameMonthLastYear && originalPercentGrowthDriver.percentGrowthBasePeriod !== PercentGrowthBasePeriod.BudgetSameMonthCurrentYear)
                                    && percentGrowthDriver.distributionMethod !== originalPercentGrowthDriver.distributionMethod);
                            if (hasDrivenBeenUpdated) {
                                pendingUpdates["update"]["percentGrowth"].push(percentGrowthDriver);
                            }
                        }
                    }

                    const percentGrowthDriverFromAssumptions = assumptionsData.percentGrowth.find((each) => each.accountId === accountId)?.drivers[0];
                    if (percentGrowthDriverFromAssumptions) {
                        if (originalPercentGrowthDriver) {
                            // Changes were made to an existing % growth driver
                            if (originalPercentGrowthDriver.growthCalcMethod === GrowthCalcMethod.PercentGrowth && percentGrowthDriverFromAssumptions.growthCalcMethod === GrowthCalcMethod.PercentGrowth) {
                                const currentPendingUpdateObj = pendingUpdates["update"]["percentGrowth"][0];
                                if (currentPendingUpdateObj) {
                                    if (originalPercentGrowthDriver.percentGrowthAdjustmentValue !== percentGrowthDriverFromAssumptions.percentGrowthAdjustmentValue) {
                                        pendingUpdates["update"]["percentGrowth"] = [{
                                            ...currentPendingUpdateObj,
                                            percentGrowthAdjustmentValue: percentGrowthDriverFromAssumptions.percentGrowthAdjustmentValue,
                                        }];
                                    }
                                } else {
                                    if (originalPercentGrowthDriver.percentGrowthAdjustmentValue !== percentGrowthDriverFromAssumptions.percentGrowthAdjustmentValue) {
                                        pendingUpdates["update"]["percentGrowth"] = [percentGrowthDriverFromAssumptions];
                                    }
                                }
                            }
                        } else {
                            // Changes were made to a newly added % growth driver
                            const currentPendingAddObj = pendingUpdates["add"]["percentGrowth"][0];
                            if (currentPendingAddObj) {
                                pendingUpdates["add"]["percentGrowth"] = [{
                                    ...currentPendingAddObj,
                                    percentGrowthAdjustmentValue: percentGrowthDriverFromAssumptions.percentGrowthAdjustmentValue,
                                }];
                            }
                        }
                    }

                    const monthlyAverageGrowthDriver = allDrivers["monthlyAverage"][0];
                    const originalMonthlyAverageGrowthDriver = allOriginalDrivers["monthlyAverage"][0];

                    if (monthlyAverageGrowthDriver !== undefined || originalMonthlyAverageGrowthDriver !== undefined) {
                        if (monthlyAverageGrowthDriver === undefined && originalMonthlyAverageGrowthDriver !== undefined) {
                            // Driver was deleted
                            pendingUpdates["remove"]["monthlyAverage"].push(originalMonthlyAverageGrowthDriver);
                        } else if (monthlyAverageGrowthDriver !== undefined && originalMonthlyAverageGrowthDriver === undefined) {
                            // Driver was added
                            pendingUpdates["add"]["monthlyAverage"].push(monthlyAverageGrowthDriver);
                        } else if (monthlyAverageGrowthDriver !== undefined && originalMonthlyAverageGrowthDriver !== undefined) {
                            const hasDrivenBeenUpdated = monthlyAverageGrowthDriver.monthlyAverageBasePeriod !== originalMonthlyAverageGrowthDriver.monthlyAverageBasePeriod
                                || (monthlyAverageGrowthDriver.monthlyAverageBasePeriod !== MonthlyAverageBasePeriod.CustomLookbackPeriod
                                    && originalMonthlyAverageGrowthDriver.monthlyAverageBasePeriod !== MonthlyAverageBasePeriod.CustomLookbackPeriod
                                    && monthlyAverageGrowthDriver.lookbackPeriodStart !== originalMonthlyAverageGrowthDriver.lookbackPeriodStart
                                    && monthlyAverageGrowthDriver.lookbackPeriodEnd !== originalMonthlyAverageGrowthDriver.lookbackPeriodEnd)
                                || monthlyAverageGrowthDriver.monthlyAdjustmentType !== originalMonthlyAverageGrowthDriver.monthlyAdjustmentType
                                || monthlyAverageGrowthDriver.monthlyAdjustmentValue !== originalMonthlyAverageGrowthDriver.monthlyAdjustmentValue;
                            if (hasDrivenBeenUpdated) {
                                pendingUpdates["update"]["monthlyAverage"].push(monthlyAverageGrowthDriver);
                            }
                        }
                    }

                    const annualTargetValueDriver = allDrivers["annualTargetValue"][0];
                    const originalAnnualTargetValueDriver = allOriginalDrivers["annualTargetValue"][0];

                    if (annualTargetValueDriver !== undefined || originalAnnualTargetValueDriver !== undefined) {
                        if (annualTargetValueDriver === undefined && originalAnnualTargetValueDriver !== undefined) {
                            // Driver was deleted
                            pendingUpdates["remove"]["annualTargetValue"].push(originalAnnualTargetValueDriver);
                        } else if (annualTargetValueDriver !== undefined && originalAnnualTargetValueDriver === undefined) {
                            // Driver was added
                            pendingUpdates["add"]["annualTargetValue"].push(annualTargetValueDriver);
                        } else if (annualTargetValueDriver !== undefined && originalAnnualTargetValueDriver !== undefined) {
                            const hasDrivenBeenUpdated = annualTargetValueDriver.annualTargetValueManualEntry !== originalAnnualTargetValueDriver.annualTargetValueManualEntry
                                || annualTargetValueDriver.distributionMethod !== originalAnnualTargetValueDriver.distributionMethod;
                            if (hasDrivenBeenUpdated) {
                                pendingUpdates["update"]["annualTargetValue"].push(annualTargetValueDriver);
                            }
                        }
                    }

                    const annualTargetValueDriverFromAssumptions = assumptionsData.annualTargetValue.find((each) => each.accountId === accountId)?.drivers[0];
                    if (annualTargetValueDriverFromAssumptions) {
                        if (originalAnnualTargetValueDriver) {
                            // Changes were made to an existing annual target value growth driver
                            if (originalAnnualTargetValueDriver.growthCalcMethod === GrowthCalcMethod.AnnualTargetValue && annualTargetValueDriverFromAssumptions.growthCalcMethod === GrowthCalcMethod.AnnualTargetValue) {
                                const currentPendingUpdateObj = pendingUpdates["update"]["annualTargetValue"][0];
                                if (currentPendingUpdateObj) {
                                    if (originalAnnualTargetValueDriver.annualTargetValueManualEntry !== annualTargetValueDriverFromAssumptions.annualTargetValueManualEntry) {
                                        pendingUpdates["update"]["annualTargetValue"] = [{
                                            ...currentPendingUpdateObj,
                                            annualTargetValueManualEntry: annualTargetValueDriverFromAssumptions.annualTargetValueManualEntry,
                                        }];
                                    }
                                } else {
                                    if (originalAnnualTargetValueDriver.annualTargetValueManualEntry !== annualTargetValueDriverFromAssumptions.annualTargetValueManualEntry) {
                                        pendingUpdates["update"]["annualTargetValue"] = [annualTargetValueDriverFromAssumptions];
                                    }
                                }
                            }
                        } else {
                            // Changes were made to a newly added annual target value growth driver
                            const currentPendingAddObj = pendingUpdates["add"]["annualTargetValue"][0];
                            if (currentPendingAddObj) {
                                pendingUpdates["add"]["annualTargetValue"] = [{
                                    ...currentPendingAddObj,
                                    annualTargetValueManualEntry: annualTargetValueDriverFromAssumptions.annualTargetValueManualEntry,
                                }];
                            }
                        }
                    }

                    const wsDrivers = allDrivers["worksheet"];
                    allOriginalDrivers["worksheet"].forEach((each) => {
                        if (wsDrivers.find((eachWsDriver) => eachWsDriver.description === each.description) === undefined) {
                            pendingUpdates["remove"]["worksheet"].push({description: each.description});
                        }
                    });
                    wsDrivers.forEach((each) => {
                        if (allOriginalDrivers["worksheet"].find((eachOriginalWsDriver) => eachOriginalWsDriver.description === each.description) === undefined) {
                            pendingUpdates["add"]["worksheet"].push({description: each.description});
                        }
                    });

                    const payrollDrivers = allDrivers["payroll"];
                    allOriginalDrivers["payroll"].forEach((each) => {
                        if (payrollDrivers.find((eachPayrollDriver) => eachPayrollDriver.itemType === each.itemType) === undefined) {
                            pendingUpdates["remove"]["payroll"].push({
                                itemType: each.itemType,
                                positions: each.positions.map((ep) => ({
                                    id: ep.id,
                                    name: ep.name,
                                })),
                                compId: payrollDriversDb?.find((eachPayrollItem) => eachPayrollItem.type === each.itemType)?.id || '',
                            });
                        }
                    });
                    payrollDrivers.forEach((each) => {
                        const matchingCompItemInOriginalState = allOriginalDrivers["payroll"].find((eachPayrollDriver) => eachPayrollDriver.itemType === each.itemType);
                        if (matchingCompItemInOriginalState) {
                            if (each.positions.length === matchingCompItemInOriginalState.positions.length) {
                                if (!each.positions.every((p) => matchingCompItemInOriginalState.positions.find((op) => op.id === p.id) !== undefined)) {
                                    pendingUpdates["update"]["payroll"].push({
                                        itemType: each.itemType,
                                        positions: each.positions.map((ep) => ({
                                            id: ep.id,
                                            name: ep.name,
                                        })),
                                        compId: payrollDriversDb?.find((eachPayrollItem) => eachPayrollItem.type === each.itemType)?.id || '',
                                    });
                                }
                            } else {
                                payrollDrivers.forEach((ep) => {
                                    pendingUpdates["update"]["payroll"].push({
                                        itemType: ep.itemType,
                                        positions: ep.positions.map((epp) => ({
                                            id: epp.id,
                                            name: epp.name,
                                        })),
                                        compId: payrollDriversDb?.find((eachPayrollItem) => eachPayrollItem.type === ep.itemType)?.id || '',
                                    });
                                });
                            }
                        }
                    });
                    payrollDrivers.forEach((each) => {
                        if (allOriginalDrivers["payroll"].find((eachOriginalPayrollDriver) => eachOriginalPayrollDriver.itemType === each.itemType) === undefined) {
                            pendingUpdates["add"]["payroll"].push({
                                itemType: each.itemType,
                                positions: each.positions.map((ep) => ({
                                    id: ep.id,
                                    name: ep.name,
                                })),
                                compId: payrollDriversDb?.find((eachPayrollItem) => eachPayrollItem.type === each.itemType)?.id || '',
                            });
                        }
                    });

                    const customDrivers = allDrivers["customDriver"];
                    allOriginalDrivers["customDriver"].forEach((each) => {
                        const matchingDriver = customDrivers.find((eachCustomDriver) => eachCustomDriver.itemName === each.itemName);
                        if (matchingDriver === undefined) {
                            const {itemName} = each;
                            pendingUpdates["remove"]["customItems"].push(itemName);
                        }
                    });
                    customDrivers.forEach((each) => {
                        if (allOriginalDrivers["customDriver"].find((eachOriginalCustomDriver) => eachOriginalCustomDriver.itemName === each.itemName) === undefined) {
                            const {itemName} = each;
                            pendingUpdates["add"]["customItems"].push(itemName);
                        }
                    });

                }

                pendingUpdatesByAccountId[accountId] = pendingUpdates;
            }

            const allPackedItems = [];

            for (const [accountId, pendingUpdates] of Object.entries(pendingUpdatesByAccountId)) {
                const getAssumptionsForOpDriver = (opDriverType: string, matchingOriginal: TDriverOperationalMetric | undefined) => {
                    const opDriver = assumptionsData.operational.find((each) => each.accountId === accountId)?.drivers.find((eachDriver) => eachDriver.type === opDriverType);

                    if (opDriver === undefined || matchingOriginal === undefined) return undefined;

                    let hasDollarChanges = false;
                    let hasPercentageChanges = false;

                    const originalValues = matchingOriginal.values;
                    for (let i = 0, n = originalValues.length; i < n; i++) {
                        // != on purpose to compare strings w/ numbers
                        if (originalValues[i] != opDriver.values[i]) {
                            hasDollarChanges = true;
                            break;
                        }
                    }

                    const originalPercentages = matchingOriginal.percentage;
                    for (let i = 0, n = originalPercentages.length; i < n; i++) {
                        // != on purpose to compare strings w/ numbers
                        if (originalPercentages[i] != opDriver.percentage[i]) {
                            hasPercentageChanges = true;
                            break;
                        }
                    }

                    if (hasDollarChanges === false && hasPercentageChanges === false) {
                        return undefined;
                    }

                    const assumptionsCount = new Array(12).fill(0);
                    const result: { [key: string]: string | number | null } = {};
                    if (hasDollarChanges) {
                        assumptionsCount.forEach((_each, i) => {
                            const value = opDriver.values[i];
                            result[`amountMonth${i}`] = typeof value === "string" ? parseFloat(value) : (value || 0);
                        });
                    }
                    if (hasPercentageChanges) {
                        assumptionsCount.forEach((_each, i) => {
                            const value = opDriver.percentage[i];
                            result[`percentMonth${i}`] = typeof value === "string" ? parseFloat(value) : (value || 0);
                        });
                    }

                    return result;
                };

                const getAssumptionsForCustomDriver = (itemName: string, matchingOriginal: TDriverCustomItem | undefined) => {
                    const customDriver = assumptionsData.customDriver.find((each) => each.accountId === accountId)?.drivers.find((eachDriver) => eachDriver.itemName === itemName);

                    if (customDriver === undefined || matchingOriginal === undefined) return undefined;

                    let hasDollarChanges = false;
                    let hasPercentageChanges = false;
                    let hasCountChanges = false;

                    const originalValues = matchingOriginal.amount;
                    for (let i = 0, n = originalValues.length; i < n; i++) {
                        // != on purpose to compare strings w/ numbers
                        if (originalValues[i] != customDriver.amount[i]) {
                            hasDollarChanges = true;
                            break;
                        }
                    }

                    const originalPercentages = matchingOriginal.percentage;
                    for (let i = 0, n = originalPercentages.length; i < n; i++) {
                        // != on purpose to compare strings w/ numbers
                        if (originalPercentages[i] != customDriver.percentage[i]) {
                            hasPercentageChanges = true;
                            break;
                        }
                    }

                    const originalCounts = matchingOriginal.count;
                    for (let i = 0, n = originalPercentages.length; i < n; i++) {
                        // != on purpose to compare strings w/ numbers
                        if (originalCounts[i] != customDriver.count[i]) {
                            hasCountChanges = true;
                            break;
                        }
                    }

                    if (hasDollarChanges === false && hasPercentageChanges === false && hasCountChanges === false) {
                        return undefined;
                    }

                    const assumptionsCount = new Array(12).fill(0);
                    const result: { [key: string]: string | number | null } = {};
                    if (hasDollarChanges) {
                        assumptionsCount.forEach((_each, i) => {
                            const value = customDriver.amount[i];
                            result[`amountMonth${i}`] = typeof value === "string" ? parseFloat(value) : (value || 0);
                        });
                    }
                    if (hasPercentageChanges) {
                        assumptionsCount.forEach((_each, i) => {
                            const value = customDriver.percentage[i];
                            result[`percentMonth${i}`] = typeof value === "string" ? parseFloat(value) : (value || 0);
                        });
                    }
                    if (hasCountChanges) {
                        assumptionsCount.forEach((_each, i) => {
                            const value = customDriver.count[i];
                            result[`countMonth${i}`] = typeof value === "string" ? parseFloat(value) : (value || 0);
                        });
                    }

                    return result;
                };

                const getAssumptionsForAccDriver = (targetAccountId: string, matchingOriginal: TDriverAccountMetric | undefined) => {
                    const accDriver = assumptionsData.account.find((each) => each.accountId === accountId)?.drivers.find((eachDriver) => eachDriver.accountId === targetAccountId);

                    if (accDriver === undefined || matchingOriginal === undefined) return accDriver;

                    let hasChanges = false;
                    const originalValues = matchingOriginal.percentage;
                    for (let i = 0, n = originalValues.length; i < n; i++) {
                        // != on purpose to compare strings w/ numbers
                        if (originalValues[i] != accDriver.percentage[i]) {
                            hasChanges = true;
                            break;
                        }
                    }

                    if (!hasChanges) {
                        return undefined;
                    }

                    const assumptionsCount = new Array(12).fill(0);
                    const result: { [key: string]: string | number | null } = {};

                    assumptionsCount.forEach((_each, i) => {
                        const value = accDriver.percentage[i];
                        result[`percentMonth${i}`] = typeof value === "string" ? parseFloat(value) : (value || 0);
                    });

                    return result;
                };

                const parsedOpItems = assumptionsData.operational.find((each) => each.accountId === accountId)?.drivers.map((eachDriver) => {
                    const matchingOriginal = originalAssumptionsData.operational.find((o) => o.accountId === accountId)?.drivers.find((d) => d.type === eachDriver.type);
                    const assumptionsByOpDriver = getAssumptionsForOpDriver(eachDriver.type, matchingOriginal);

                    // Don't pack the assumptions obj if there are no pending updates to save
                    if (assumptionsByOpDriver) {
                        return ({
                            propertyId,
                            year: state.year,
                            versionType: state.versionType,
                            destinationAccountId: accountId,
                            sourceMetricId: eachDriver.sourceMetricId,
                            ...assumptionsByOpDriver,
                        });
                    }

                    return null;
                })
                    // Typeguard to let TS know there really aren't any null values left here
                    .filter((item): item is any => item !== null);

                const parsedCustomDriverItems = assumptionsData.customDriver.find((each) => each.accountId === accountId)?.drivers.map((eachDriver) => {
                    const matchingOriginal = originalAssumptionsData.customDriver.find((o) => o.accountId === accountId)?.drivers.find((d) => d.itemName === eachDriver.itemName);
                    const assumptionsByCustomItemDriver = getAssumptionsForCustomDriver(eachDriver.itemName, matchingOriginal);

                    // Don't pack the assumptions obj if there are no pending updates to save
                    if (assumptionsByCustomItemDriver) {
                        return ({
                            propertyId,
                            year: state.year,
                            versionType: state.versionType,
                            destinationAccountId: accountId,
                            itemName: eachDriver.itemName,
                            ...assumptionsByCustomItemDriver,
                        });
                    }

                    return null;
                })
                    // Typeguard to let TS know there really aren't any null values left here
                    .filter((item): item is any => item !== null);

                const parsedAccItems = assumptionsData.account.find((each) => each.accountId === accountId)?.drivers.map((eachDriver) => {
                    const matchingOriginal = originalAssumptionsData.account.find((a) => a.accountId === accountId)?.drivers.find((d) => d.accountId === eachDriver.accountId);
                    const assumptionsByAccDriver = getAssumptionsForAccDriver(eachDriver.accountId, matchingOriginal);

                    // Don't pack the assumptions obj if there are no pending updates to save
                    if (assumptionsByAccDriver) {
                        return ({
                            propertyId,
                            year: state.year,
                            versionType: state.versionType,
                            destinationAccountId: accountId,
                            sourceAccountId: eachDriver.accountId,
                            ...assumptionsByAccDriver,
                        });
                    }

                    return null;
                })
                    // Typeguard to let TS know there really aren't any null values left here
                    .filter((item): item is any => item !== null);

                const appliedLineItems = state.parsedFormDriversByAccountId[accountId]?.worksheet.map((each) => each.description);
                const parsedWsItems: WorksheetDriverLineUpdateByDescriptionInput[] = [];

                assumptionsData.worksheet.forEach((each) => {

                    if (appliedLineItems?.includes(each.lineItem)) {
                        const valuesForCurrentLineItem = assumptionsData.worksheet.find((w) => w.lineItem === each.lineItem)?.drivers.find((d) => d.accountId === accountId)?.values;
                        const originalValues = originalAssumptionsData.worksheet.find((w) => w.lineItem === each.lineItem)?.drivers.find((d) => d.accountId === accountId)?.values;

                        if (originalValues && valuesForCurrentLineItem) {
                            let hasChanges = false;
                            for (let i = 0, n = originalValues.length; i < n; i++) {
                                // != on purpose to compare strings w/ numbers
                                if (originalValues[i] != valuesForCurrentLineItem[i]) {
                                    hasChanges = true;
                                    break;
                                }
                            }

                            if (hasChanges) {
                                parsedWsItems.push({
                                    destinationAccountId: accountId,
                                    propertyId: propertyId,
                                    year: state.year,
                                    versionType: state.versionType,
                                    lineName: each.lineItem,
                                    lineValues: valuesForCurrentLineItem.map((v, i) => ({
                                        monthIndex: i,
                                        value: typeof v === "string" ? v : (v ? v.toString() : "0"),
                                    })),
                                });
                            }
                        }
                    }
                });

                let hasPendingUpdates = false;
                let verifiedPendingUpdates: PendingUpdates | undefined = pendingUpdates;
                for (const pendingUpdateType of Object.values(pendingUpdates)) {
                    for (const driverUpdates of Object.values(pendingUpdateType)) {
                        if(
                            (driverUpdates instanceof Array && driverUpdates.length > 0)
                            || typeof driverUpdates === "object"
                        ) {
                            hasPendingUpdates = true;
                            break;
                        }
                    }
                }

                if (!hasPendingUpdates) {
                    verifiedPendingUpdates = undefined;
                }

                if (hasPendingUpdates
                    || (parsedOpItems && parsedOpItems.length > 0)
                    || (parsedCustomDriverItems && parsedCustomDriverItems.length > 0)
                    || (parsedAccItems && parsedAccItems.length > 0)
                    || (parsedWsItems && parsedWsItems.length > 0)) {
                        const packedItems = {
                            accountId,
                            isUserRequestingWorksheetDeletion: state.worksheetDeletedFor.includes(accountId),
                            pendingUpdates: verifiedPendingUpdates,
                            opItems: parsedOpItems || [],
                            accItems: parsedAccItems || [],
                            wsItems: parsedWsItems || [],
                            customDriverItems: parsedCustomDriverItems || []
                        };

                        allPackedItems.push(packedItems);
                    }
            }

            if (allPackedItems.length || pendingAccountLocks.length) {
                setIsSaving(true);
                SetPropertyDrivers({
                    variables: {
                        propertyId,
                        year: state.year,
                        versionType: state.versionType,
                        items: allPackedItems,
                        accountLocks: pendingAccountLocks,
                    }
                });
            } else {
                setIsNoChangesModalOpen(true);
            }

        };

        generatePendingUpdatesByAccountId();
    };

    const setActiveView = (viewToActivate: ActiveView) => {
        dispatch({
            type: "SET_ACTIVE_VIEW",
            viewToActivate,
        });
    };

    const setBudgetYear = (yearToSet: number) => {
        dispatch({
            type: "SET_BUDGET_YEAR",
            yearToSet,
        });
    };

    const setReforecastStartMonth = (monthIndex: number) => {
        dispatch({
            type: "SET_REFORECAST_START_MONTH",
            reforecastStartMonth: monthIndex,
        });
    };

    const setSelectedAccounts = (accounts: SelectableFinancialEntity[]) => {
        dispatch({
            type: "SET_SELECTED_ACCOUNTS",
            accounts,
        });
    };

    const setVersionType = (versionTypeToSet: TVersionTypeDropdownItem) => {
        dispatch({
            type: "SET_VERSION_TYPE",
            versionTypeToSet,
        });
    };

    const setYear = (yearToSet: number) => {
        dispatch({
            type: "SET_YEAR",
            yearToSet,
        });
    };

    return {
        accountLocks: state.accountLocks,
        accountLocksDraft: state.accountLocksDraft,
        bulkUpdateAccountLocksDraft,
        updateAccountLocksByAccountId,
        updateAccountLocksDraftByAccountId,

        isPropertyLocked,

        activeView: state.activeView,
        setActiveView,

        parsedFormDriversByAccountId: state.parsedFormDriversByAccountId,
        parsedOriginalDriversByAccountId: state.parsedOriginalDriversByAccountId,

        addPendingUpdatesByAccountId,
        pendingUpdatesByAccountId: state.pendingUpdatesByAccountId,

        currentProperty,
        propertyId,

        isConfigureModelingMethodsModalOpen: state.isConfigureModelingMethodsModalOpen,
        setIsConfigureModelingMethodsModalOpen,

        reforecastStartMonth: state.reforecastStartMonth,

        versionType: state.versionType,
        setVersionType,

        budgetYear: state.budgetYear,
        year: state.year,

        selectedAccounts: state.selectedAccounts,
        setSelectedAccounts,

        shouldFxBarStashChanges,
        setShouldFxBarStashChanges,
        stashFxBarDirtyStateByAccountId,

        willBulkUpdate,
        setWillBulkUpdate,
        saveBulkUpdateState,
        bulkUpdateState,
        shouldApplyBulkUpdatesToFormulaBars,

        saveFxBarsInBulk,
        isSaving,

        refetchDWD,
        setRefetchDWD,

        isNoChangesModalOpen,
        setIsNoChangesModalOpen,

        accounts,
        setAccounts,

        allAvailableDrivers: {payroll: payrollDriversDb, customItems: customItemsDb},

        lineItemsMenuOptionsByAccountId: state.lineItemsMenuOptionsByAccountId,
        setLineItemsMenuOptionsByAccountId,

        propertyForecastLocks,
    };
}

function propertyDriversReducer(state: State, action: Action): State {
    switch (action.type) {
        case "ADD_PENDING_UPDATES_BY_ACCOUNT_ID": {
            const { accountId, parsedFormDrivers, parsedOriginalDrivers, isWorksheetDeleted } = action;

            // TODO (Sahil): Actually use the real drivers to derive `pendingUpdates` later
            const constructPendingUpdates = (_parsedFormDrivers: TDriverMetricsYear, _parsedOriginalDrivers: TDriverMetricsYear): PendingUpdates => {

                const pendingUpdates: PendingUpdates = {
                    add: {
                        account: [],
                        accountPercentageAugment: null,
                        operational: [],
                        revenue: [],
                        annualTargetValue: [],
                        monthlyAverage: [],
                        percentGrowth: [],
                        worksheet: [],
                        payroll: []
                    },
                    update: {
                        account: [],
                        accountPercentageAugment: null,
                        operational: [],
                        revenue: [],
                        annualTargetValue: [],
                        monthlyAverage: [],
                        percentGrowth: [],
                        worksheet: [],
                        payroll: []
                    },
                    remove: {
                        account: [],
                        accountPercentageAugment: null,
                        operational: [],
                        revenue: [],
                        annualTargetValue: [],
                        monthlyAverage: [],
                        percentGrowth: [],
                        worksheet: [],
                        payroll: []
                    },
                };

                return {
                    add: pendingUpdates.add,
                    update: pendingUpdates.update,
                    remove: pendingUpdates.remove
                };
            };

            return {
                ...state,
                worksheetDeletedFor: [...state.worksheetDeletedFor, ...(isWorksheetDeleted ? [accountId] : [])],
                parsedFormDriversByAccountId: {
                    ...state.parsedFormDriversByAccountId,
                    [accountId]: parsedFormDrivers,
                },
                parsedOriginalDriversByAccountId: {
                    ...state.parsedOriginalDriversByAccountId,
                    [accountId]: parsedOriginalDrivers,
                },
                pendingUpdatesByAccountId: {
                    ...state.pendingUpdatesByAccountId,
                    [accountId]: constructPendingUpdates(parsedFormDrivers, parsedOriginalDrivers),
                },
            };
        }

        case "BULK_UPDATE_ACCOUNT_LOCKS_DRAFT": {
            const { isLocked } = action;

            const selectedAccountIds = state.selectedAccounts.map((each) => each.id);

            const accountLocksCopy = new Map(state.accountLocksDraft);
            accountLocksCopy.forEach((_, accountId) => {
                if (selectedAccountIds.includes(accountId)) {
                    accountLocksCopy.set(accountId, isLocked);
                }
            });

            return {
                ...state,
                accountLocksDraft: accountLocksCopy,
            };
        }

        case "UPDATE_ACCOUNT_LOCKS_BY_ACCOUNT_ID": {
            const { accountId, isLocked } = action;

            const accountLocksCopy = new Map(state.accountLocks);
            accountLocksCopy.set(accountId, isLocked);

            return {
                ...state,
                accountLocks: accountLocksCopy,
                accountLocksDraft: accountLocksCopy,
            };
        }

        case "UPDATE_ACCOUNT_LOCKS_DRAFT_BY_ACCOUNT_ID": {
            const { accountId, isLocked } = action;

            const accountLocksDraftCopy = new Map(state.accountLocksDraft);
            accountLocksDraftCopy.set(accountId, isLocked);

            return {
                ...state,
                accountLocksDraft: accountLocksDraftCopy,
            };
        }

        case "SET_ACTIVE_VIEW": {
            const { viewToActivate } = action;

            return {
                ...state,
                activeView: viewToActivate,
            };
        }

        case "SET_LINE_ITEMS_MENU_OPTIONS_BY_ACCOUNT_ID": {
            const { accountId, worksheetTypeAhead } = action;

            return {
                ...state,
                lineItemsMenuOptionsByAccountId: {
                    ...state.lineItemsMenuOptionsByAccountId,
                    [accountId]: worksheetTypeAhead,
                },
            };
        }

        case "SET_REFORECAST_START_MONTH": {
            const { reforecastStartMonth } = action;

            return {
                ...state,
                reforecastStartMonth,
            };
        }

        case "SET_SELECTED_ACCOUNTS": {
            const { accounts } = action;

            return {
                ...state,
                selectedAccounts: accounts,
            };
        }

        case "SET_VERSION_TYPE": {
            const { versionTypeToSet } = action;

            return {
                ...state,
                versionType: versionTypeToSet,
            };
        }

        case "SET_BUDGET_YEAR": {
            const { yearToSet } = action;

            return {
                ...state,
                budgetYear: yearToSet,
            };
        }

        case "SET_YEAR": {
            const { yearToSet } = action;

            return {
                ...state,
                year: yearToSet,
            };
        }

        case "SET_IS_CONFIGURE_MODELING_METHOD_MODAL_OPEN": {
            const { isOpen } = action;

            return {
                ...state,
                isConfigureModelingMethodsModalOpen: isOpen,
            };
        }

        case "RESET_PROPERTY_DRIVERS": {
            return {
                ...state,
                isConfigureModelingMethodsModalOpen: false,
                parsedFormDriversByAccountId: {},
                parsedOriginalDriversByAccountId: {},
                lineItemsMenuOptionsByAccountId: {},
                pendingUpdatesByAccountId: {},
                selectedAccounts: [],
                worksheetDeletedFor: [],
            };
        }

        default:
            return state;
    }
}
