import arrowExpand from "../icons/arrow-expand.svg";
import arrowCollapse from "../icons/arrow-collapse.svg";

const imageExpand = new Image();
imageExpand.src = arrowExpand;

const imageCollapse = new Image();
imageCollapse.src = arrowCollapse;

const ICON_SIZE = 12;
const ICON_CLICKABLE_SIZE = 24;
const ICON_PADDING = 12;

export function drawExpandCollapse(ctx: CanvasRenderingContext2D, x: number, y: number, w: number, h: number, isExpand: boolean): void {
    const image = isExpand ? imageExpand : imageCollapse;
    ctx.drawImage(image, x + w - ICON_PADDING - ICON_SIZE, y + h / 2 - ICON_SIZE / 2, ICON_SIZE, ICON_SIZE);
}

export function isInsideExpandCollapse(tx: number, ty: number, x: number, y: number, w: number, h: number): boolean {
    const [iconCenterX, iconCenterY] = [x + w - ICON_PADDING - ICON_SIZE / 2, y + h / 2];
    return iconCenterX - ICON_CLICKABLE_SIZE / 2 <= tx && tx <= iconCenterX + ICON_CLICKABLE_SIZE / 2 &&
           iconCenterY - ICON_CLICKABLE_SIZE / 2 <= ty && ty <= iconCenterY + ICON_CLICKABLE_SIZE / 2;
}
