type TButtonStates = {
    isBasic: boolean,
    isDefault: boolean,
    disabled: boolean,
    isPrimary: boolean,
}

/**
 * Returns what display state a month button should be in. We make no assumptions about how the parameters are provided
 * from external users of the YearMonthSelector The properties are necessary for comparison, but may come from transient
 * locations, like query params or a React state. We don't want to assume that a default value will be present, but
 * want to be able to render anyway.
 *
 * @param monthIdx          The index of the month of the year for the button we're processing
 * @param displayedYear     The year being displayed by the selector control
 * @param selectedYear      The year value at the moment the selector was opened
 * @param selectedMonthIdx  The currently selected month index (e.g. March = 2)
 * @param maxYear           (optional) The highest year that months can be selected from
 * @param minYear           (optional) The lowest year that months can be selected from
 */
export const getButtonState = (
    monthIdx: number,
    displayedYear: number|undefined,
    selectedYear: number|undefined,
    selectedMonthIdx: number,
    maxYear?: number,
    minYear?: number,
): Partial<TButtonStates> => {

    if(
        (selectedYear === undefined || displayedYear === undefined)
        || (maxYear !== undefined && selectedYear > maxYear)
        || (maxYear !== undefined && displayedYear > maxYear)
        || (minYear !== undefined && selectedYear < minYear)
        || (minYear !== undefined && displayedYear < minYear)
    ){
        return { disabled: true };
    }
    else if(selectedYear === displayedYear && selectedMonthIdx === monthIdx){
        return { isPrimary: true };
    }
    else {
        return { isBasic: true };
    }
};

export enum YearMonthSelectorLimit {
    NO_LIMIT = 'NO_LIMIT',
    PREVENT_SELECTING_FUTURE_MONTHS = 'PREVENT_SELECTING_FUTURE_MONTHS',
    ONLY_ALLOW_SELECTING_PAST_MONTHS = 'ONLY_ALLOW_SELECTING_PAST_MONTHS',
}
