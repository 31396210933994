import React from 'react';
import {Link} from 'react-router-dom';
import {BudgetingType} from '../../BudgetingType';
import './AccountHeader.scss';
import StarredAccount from "../starred-account/StarredAccount";
import {VersionType} from "../../__generated__/generated_types";
import {useSettings} from "../../contexts/settings/SettingsContext";
import {useVersions} from "../../contexts/versions/VersionsContext";

interface AccountHeaderProps {
    name        : string,
    level       : number,
    accountId   : string | undefined,
    isReforecast: boolean,
    rowName?: string
}

export const AccountHeader: React.VFC<AccountHeaderProps> = (props) => {

    const {year: currentYear} = useSettings();
    const {versions} = useVersions();
    const versionIds = versions?.valueVersions.filter(
        vv => (vv.type === VersionType.Reforecast && vv.year === currentYear) || (vv.type === VersionType.Budget && vv.year === currentYear + 1)
    )?.map(version => version.id);
    const currentVersionId = versions?.valueVersions.find(vv => {
       if (props.isReforecast) {
           return vv.type === VersionType.Reforecast && vv.year === currentYear;
       }
       return vv.type === VersionType.Budget && (vv.year === currentYear + 1);
    })?.id;

    return (
        <>
            <td className={"d-flex justify-content-between align-items-center border-0 p-0"}>
                <div className="tree-line border-start-1 d-flex h-100 border-whisper border-start">
                    <Link target="_blank" className="account-link text-dodger-blue" to={`/account/${props.accountId}/${props.isReforecast ? BudgetingType.REFORECAST : BudgetingType.BUDGET}?row=${props.rowName}`}>
                        {props.name}
                    </Link>
                    {
                        props.accountId && versionIds && versionIds.length > 0 &&
                        <StarredAccount
                            accountId={props.accountId}
                            versionIds={versionIds}
                            currentVersionId={currentVersionId ?? ""}
                        />
                    }
                </div>
            </td>
        </>
    );
};
