import { useEffect, useState } from 'react';
import { getChartOptions } from '../logic/overviewWidget';
import { PointOptionsObject, Options } from 'highcharts';
import { MONTHS } from '../../../../../../../../constants/Months';
import { useMonthlyVarianceReportContext } from '../../../../../context/MonthlyVarianceReportContext';

export type IOverviewDataChartMonth = {
    categories: string[],
    chartData: Array<(number | [(number | string), (number | null)] | null | PointOptionsObject)>,
};

export type IOverviewDataChartYTD = {
    categories: string[],
    chartData: {
        actual: Array<(number | [(number | string), (number | null)] | null | PointOptionsObject)>,
        budget: Array<(number | [(number | string), (number | null)] | null | PointOptionsObject)>,
    }
};

export type IOverviewDataChart = {
    month: IOverviewDataChartMonth,
    ytd: IOverviewDataChartYTD,
};

interface IOverviewData {
    isPositiveGood: boolean,
    actualValue: number,
    budgetValue: number,
    variancePercent: number,
    varianceValue: number,
    chart: IOverviewDataChart,
    chartOptions: Options | undefined,
}

interface IOverviewDataReturn {
    isOverviewDataReady: boolean,
    overviewData?: IOverviewData,
}

interface IUserOverviewDataProps {
    isPositiveGood: boolean,
    accountId: string,
    month: number,
    year: number,
    viewMode: string,
}

export function useOverviewData(props: IUserOverviewDataProps): IOverviewDataReturn {
    const mvrContext = useMonthlyVarianceReportContext();
    const [overviewData, setOverviewData] = useState<IOverviewData>();
    const [isOverviewDataReady, setIsOverviewDataReady] = useState<boolean>(false);

    useEffect(
        () => {
            if (!mvrContext.isMvrContextReady || mvrContext.mvrEntityDataMap == undefined) return;

            const chartDataMap = mvrContext.mvrEntityDataMap[props.accountId];

            if (!chartDataMap || !chartDataMap.values) return;

            const chart = {
                month: {
                    categories: ['Actual', 'Budget'],
                    chartData: [
                        chartDataMap.values.actual[props.month] ?? 0,
                        chartDataMap.values.budget[props.month] ?? 0,
                    ]
                },
                ytd: {
                    categories: MONTHS.slice(0, props.month + 1),
                    chartData: {
                        actual: chartDataMap.values.actual.slice(0, props.month + 1),
                        budget: chartDataMap.values.budget.slice(0, props.month + 1)
                    }
                }
            };

            const baseData = props.viewMode == 'ytd' ? chartDataMap?.ytd : chartDataMap;

            setOverviewData({
                isPositiveGood: props.isPositiveGood,
                actualValue: baseData?.values?.actual[props.month] ?? 0,
                budgetValue: baseData?.values?.budget[props.month] ?? 0,
                variancePercent: baseData?.variance?.percent[props.month] ?? 0,
                varianceValue: baseData?.variance?.value[props.month] ?? 0,
                chart: chart,
                chartOptions: getChartOptions(props.viewMode, chart)
            });
        },
        [mvrContext.isMvrContextReady, props.viewMode, props.accountId]
    );

    useEffect(
        () => {
            if (!overviewData) return;

            setIsOverviewDataReady(true);
        },
        [overviewData]
    );

    return { overviewData, isOverviewDataReady };
}