import { BudgetingType } from "../../../BudgetingType";
import {
    WORKFLOW_YEAR_TAB_BUDGET,
    WORKFLOW_YEAR_TAB_REFORECAST
} from "../../../components/workflows/workflow-year-tabs/WorkflowYearTabs";

export interface IWorkflowPageProps {
    onPreviousClick: () => void
    onNextClick: () => void
}

export const shouldUpdateWorkflowTab = (type:string, selectedTab:string):boolean|string => {
    if(type == BudgetingType.REFORECAST && selectedTab != WORKFLOW_YEAR_TAB_REFORECAST){
        return WORKFLOW_YEAR_TAB_REFORECAST;
    }
    if(type == BudgetingType.BUDGET &&  selectedTab != WORKFLOW_YEAR_TAB_BUDGET){
        return WORKFLOW_YEAR_TAB_BUDGET;
    }
    return false;
};
