import { PillType } from "../components/modeling-method-pill/ModelingMethodPill";
import { ModelingMethodType } from "../data/enums/ModelingMethodTypeEnum";

export function mapModelingMethodTypeToPillType(modelingMethodType: ModelingMethodType): PillType {
    let pillType = PillType.MANUAL_ENTRY;

    switch (modelingMethodType) {
        case ModelingMethodType.LINE_ITEMS:
            pillType = PillType.LINE_ITEMS;
            break;
        case ModelingMethodType.MANUAL_ENTRY:
            pillType = PillType.MANUAL_ENTRY;
            break;
        case ModelingMethodType.MIXED_METHODS:
            pillType = PillType.MIXED_METHODS;
            break;
        case ModelingMethodType.OP_DRIVER:
            pillType = PillType.OP_DRIVER;
            break;
        case ModelingMethodType.OVERRIDE:
            pillType = PillType.OVERRIDE;
            break;
        case ModelingMethodType.PAYROLL:
            pillType = PillType.PAYROLL;
            break;
        case ModelingMethodType.PCT_OF_ACCOUNT:
            pillType = PillType.PCT_OF_ACCOUNT;
            break;
        case ModelingMethodType.RENOVATION_COSTS:
            pillType = PillType.RENOVATION_COSTS;
            break;
        case ModelingMethodType.REVENUE:
            pillType = PillType.REVENUE;
            break;
    }

    return pillType;
}