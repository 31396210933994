import { useState } from "react";
import { SingleRowSelectionColorAdvisor } from "./NewSpreadsheet";
import {
    NewSpreadsheetAPIResult,
    SpreadsheetHandle,
    NewSpreadsheetHandlers,
    NewSpreadsheetAPI,
    ChangedCell,
    CellHitInfo,
    CustomCellType,
    CellCoords,
} from "./NewSpreadsheetTypes";
import GC from '@grapecity/spread-sheets';
import { ICustomCellInfo } from "./spreadjs-custom-cell-types/types";

export function useNewSpreadsheetAPI(): NewSpreadsheetAPIResult {
    const [cellHovered, setCellHovered] = useState<CellHitInfo | undefined>();
    const [cellContentHovered, setCellContentHovered] = useState<CellHitInfo | undefined>();
    const [cellsChanged, setCellsChanged] = useState<ChangedCell[]>();
    const [cellClicked, setCellClicked] = useState<CellHitInfo | undefined>();
    const [cellContentClicked, setCellContentClicked] = useState<CellHitInfo | undefined>();
    const [cellTargetClicked, setCellTargetClicked] = useState<CellHitInfo | undefined>();
    const [selectionIsChanging, setSelectionIsChanging] = useState<CellCoords | undefined>();
    const [spreadsheet, setSpreadsheet] = useState<SpreadsheetHandle | undefined>();

    const handlers: NewSpreadsheetHandlers = {
        cellsChanged: function (cells: ChangedCell[]): void {
            setCellsChanged(cells);
        },

        cellHovered: function (hoverInfo: CellHitInfo): void {
            setCellHovered((prev) => {
                let prevInfo: CellHitInfo | undefined = undefined;
                if (prev) {
                    prevInfo = {
                        row: prev.row,
                        col: prev.col,
                        cellRect: prev.cellRect,
                        offsetLeft: prev.offsetLeft,
                        offsetTop: prev.offsetTop,

                        // ATTN! - do not copy prev.prev to avoid chain. let garbage collector work
                    };
                }
                const info: CellHitInfo = {
                    row: hoverInfo.row,
                    col: hoverInfo.col,
                    cellRect: hoverInfo.cellRect,
                    offsetLeft: hoverInfo.offsetLeft,
                    offsetTop: hoverInfo.offsetTop,

                    prev: prevInfo
                };

                return info;
            });
        },

        cellClicked: function (cell: CellHitInfo | undefined): void {
            setCellClicked(cell);
        },

        cellContentHovered: function (cell: CellHitInfo): void {
            setCellContentHovered(cell);
        },

        cellContentClicked: function (cell: CellHitInfo | undefined): void {
            setCellContentClicked(cell);
        },

        cellTargetClicked: function (cell: CellHitInfo | undefined): void {
            setCellTargetClicked(cell);
        },

        selectionIsChanging: function (cellCoords: CellCoords | undefined):void{
            setSelectionIsChanging(cellCoords);
        },

        onInit: function (handle: SpreadsheetHandle): void {
            setSpreadsheet(handle);
        },

        onDeinit: function (): void {
            setSpreadsheet(undefined);
        },

    };


    const api: NewSpreadsheetAPI = {
        download: function (): string {
            return spreadsheet?.download() ?? "";
        },

        showRows: function (params: { startRow: number; rows: number; sheetName?: string; }): void {
            spreadsheet?.showRows(params);
        },
        // eslint-disable-next-line @typescript-eslint/ban-types
        setTemplate: function (params: { template: Object; sheetName?: string; rows?: number; }): void {
            spreadsheet?.setTemplate(params);
        },
        subscribe: function (): void {
            spreadsheet?.subscribe();
        },
        setArrayFormula: function (params: { row: number, col: number, rows: number, cols: number, formula: string, sheetName?: string }): void {
            spreadsheet?.setArrayFormula(params);
        },
        setArray: function (params: { row: number; col: number; array: any[]; setFormula?: boolean | undefined; sheetName?: string; notify?: boolean }): void {
            spreadsheet?.setArray(params);
        },
        setValue: function (params: { row: number; col: number; value: number | string | undefined; sheetName?: string; }): void {
            spreadsheet?.setValue(params);
        },
        getColumnLabel: function (params: { col: number; sheetName?: string; }): string {
            return spreadsheet?.getColumnLabel(params) ?? "A";
        },
        setCellLocked: function (params: { row: number; col: number; locked: boolean; sheetName?: string; }): void {
            spreadsheet?.setCellLocked(params);
        },
        setCellsLocked: function (params: { row: number; col: number; rowCount: number; colCount: number; locked: boolean; sheetName?: string; }): void {
            spreadsheet?.setCellsLocked(params);
        },
        getValue: function (params: { row: number; col: number; sheetName?: string; }): (number | string) {
            return spreadsheet?.getValue(params) ?? "";
        },
        setColumnWidth: function (params: { col: number, width: number }): void {
            spreadsheet?.setColumnWidth(params);
        },
        setRowHeight: function (params: { row: number, height: number }): void {
            spreadsheet?.setRowHeight(params);
        },
        setStyle: function (params: { row: number, col: number, style: GC.Spread.Sheets.Style }): void {
            spreadsheet?.setStyle(params);
        },
        addSpan: function (params: { row: number, col: number, rowCount: number, colCount: number }): void {
            spreadsheet?.addSpan(params);
        },
        recalculate: function (): void {
            spreadsheet?.recalculate();
        },
        suspend: function (): void {
            spreadsheet?.suspend();
        },
        resume: function (): void {
            spreadsheet?.resume();
        },
        updateCustomCellType: function (params: { row: number, col: number, cellType: CustomCellType, sheetName?: string, customCellInfo?: ICustomCellInfo }): void {
            spreadsheet?.updateCustomCellType(params);
        },
        applyCustomCellType: function (params: { row: number; col: number; sheetName?: string; cellType: CustomCellType; customCellInfo?: ICustomCellInfo; }): void {
            spreadsheet?.applyCustomCellType(params);
        },
        removeCustomCellType: function (params: { row: number; col: number; sheetName?: string; }): void {
            spreadsheet?.removeCustomCellType(params);
        },
        getCell: function (params: { row: number; col: number; sheetName?: string; }): GC.Spread.Sheets.CellRange | undefined {
            return spreadsheet?.getCell?.(params);
        },
        toJSON: function (): string {
            return spreadsheet?.toJSON?.() ?? "";
        },
        setCellSelection: function (params: { row: number; col: number; editMode: boolean; }): void {
            spreadsheet?.setCellSelection(params);
        },
        setSingleRowColorAdvisor: function (advisor: SingleRowSelectionColorAdvisor): void {
            spreadsheet?.setSingleRowColorAdvisor(advisor);
        },
        startCollectingCellUpdates: function (): void {
            spreadsheet?.startCollectingCellUpdates();
        },
        setRowCount: function (params: { rows: number; sheetName?: string | undefined; }): void {
            spreadsheet?.setRowCount(params);
        },
        setColumnCount: function (params: { cols: number; sheetName?: string | undefined; }): void {
            spreadsheet?.setColumnCount(params);
        },
        createOutlineColumn: function (params: { columnIdx: number, sheetName?: string }): void {
            spreadsheet?.createOutlineColumn(params);
        },
        showRowOutline: function (params: { show: boolean, sheetName?: string }): void {
            spreadsheet?.showRowOutline(params);
        },
        frozenRowCount: function (params: { rowCount: number, sheetName?: string }): void {
            spreadsheet?.frozenRowCount(params);
        },
        frozenColumnCount: function (params: { colCount: number, sheetName?: string }): void {
            spreadsheet?.frozenColumnCount(params);
        },
        setFrozenLineColor: function (params: { color: string, sheetName?: string }): void {
            spreadsheet?.setFrozenLineColor(params);
        },
        setRowVisible: function (params: { rowIdx: number, isVisible: boolean, sheetName?: string }): void {
            spreadsheet?.setRowVisible(params);
        },
        setColumnVisible: function (params: { colIdx: number, isVisible: boolean, sheetName?: string }): void {
            spreadsheet?.setColumnVisible(params);
        },
        getColumnCount: function (params: { sheetName?: string }): number | undefined {
            return spreadsheet?.getColumnCount(params);
        },
        getRowCount: function (params: { sheetName?: string }): number | undefined {
            return spreadsheet?.getRowCount(params);
        },
        setBorder: function (params: { row: number, col: number, border: GC.Spread.Sheets.LineBorder, options: GC.Spread.Sheets.ISetBorderOptions, rowCount?: number, colCount?: number, sheetName?: string }): void {
            spreadsheet?.setBorder(params);
        },
        setRowOutlineGroups: function (params: { firstRow: number, rowCount: number, sheetName?: string }): void {
            spreadsheet?.setRowOutlineGroups(params);
        },
        setOutlineExpand: function (params: { level: number, isExpanded: boolean, sheetName?: string }): void {
            spreadsheet?.setOutlineExpand(params);
        },
        setOutlineGroupExpand: function (params: { groupRow: number, isExpanded: boolean, level: number, sheetName?: string }): void {
            spreadsheet?.setOutlineGroupExpand(params);
        },
        getOutlineMaxLevel: function (params: { sheetName?: string }): number | undefined {
            return spreadsheet?.getOutlineMaxLevel(params);
        },
        suspendPaint: function (): void {
            spreadsheet?.suspendPaint();
        },
        resumePaint: function (): void {
            spreadsheet?.resumePaint();
        },
        setFormat: function (params: { row: number, col: number, format: string, rowCount?: number, colCount?: number, sheetName?: string }): void {
            spreadsheet?.setFormat(params);
        },
        addRows: function (params: { row: number, count: number, sheetName?: string }): void {
            spreadsheet?.addRows(params);
        },
        setActiveSheetIndex: function (index: number): void {
            spreadsheet?.setActiveSheetIndex(index);
        },
        getActiveSheetIndex: function (): number {
            return spreadsheet?.getActiveSheetIndex() || 0;
        },
        getSheetIndex: function (sheetName: string): number {
            return spreadsheet?.getSheetIndex(sheetName) || 0;
        },
        updateSpreadsheetViewPort: function (params: { startRow: number, startCol: number, rows: number, cols: number, sheetName?: string }): void {
            spreadsheet?.updateSpreadsheetViewPort(params);
        },
        setCellBgImageBase64: function (params: { row: number, col: number, b64Image: string, sheetName?: string }): void {
            spreadsheet?.setCellBgImageBase64(params);
        },
        directAccess: function (cb: (spread: GC.Spread.Sheets.Workbook) => void): void {
            spreadsheet?.directAccess(cb);
        },
        getSpread: function ():GC.Spread.Sheets.Workbook|undefined {
            return spreadsheet?.getSpread();
        },
        addCellStateRange: function (params: { row: number, col: number, rows: number, cols: number, state: string, style: GC.Spread.Sheets.Style, sheetName?: string }): void {
            spreadsheet?.addCellStateRange(params);
        },
        clearSelection: function (): void {
            spreadsheet?.clearSelection();
        },
        lockAllCells: function (params?: {sheetName?: string}):void {
            spreadsheet?.lockAllCells(params);
        },
    };

    const result: NewSpreadsheetAPIResult = {
        handlers,
        api,
        cellsChanged: cellsChanged ?? [],
        cellHovered,
        cellContentHovered,
        isSpreadsheetReady: spreadsheet != undefined,
        cellClicked,
        cellContentClicked,
        cellTargetClicked,
        selectionIsChanging,
    };

    return result;
}
