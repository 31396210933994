import { useEffect, useState } from "react";
import { useGetAccountVarianceNoteQuery, VersionType, useSetAccountVarianceNoteMutation } from "../../../../../../../../__generated__/generated_types";

export interface UseVarianceNoteDataProps {
    propertyId: string;
    accountId: string;
    year: number;
    monthIndex: number;
}

type UseVarianceNoteData = {
    isSaving: boolean;
    persistedValue: string;
    save: (text: string) => void
}

export function useVarianceNoteData(props: UseVarianceNoteDataProps): UseVarianceNoteData {
    const [isSaving, setIsSaving] = useState(false);
    const [persistedValue, setPersistedValue] = useState<string>("");

    const { data, loading } = useGetAccountVarianceNoteQuery({
        variables: {
            propertyId: props.propertyId,
            accountId: props.accountId,
            year: props.year,
            monthIndex: props.monthIndex,
            versionType: VersionType.Budget
        },
        fetchPolicy: "network-only"
    });

    const [saveToBackend] = useSetAccountVarianceNoteMutation({
        onCompleted: data => {
            setPersistedValue(data.upsertAccountVarianceNote.text);
            setIsSaving(false);
        },
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        onError: _error => {
            setIsSaving(false);
        }
    });

    const save = (text: string) => {
        setIsSaving(true);
        saveToBackend({
            variables: {
                propertyId: props.propertyId,
                accountId: props.accountId,
                year: props.year,
                monthIndex: props.monthIndex,
                versionType: VersionType.Budget,
                text: text
            }
        })
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            .catch(_error => setIsSaving(false));
    };

    useEffect(
        () => {
            if (data && !loading) {
                setPersistedValue(data.queryAccountVarianceNotes.firstElement?.text ?? "");
            }
        },
        [data, loading]
    );


    return {
        isSaving: isSaving,
        persistedValue: persistedValue,
        save: save
    };
}