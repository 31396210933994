import {ThemeProvider} from "@zendeskgarden/react-theming";
import {ReactElement, useEffect, useState} from "react";
import {ViziblyTheme} from "../analyst/ViziblyZDGTheme";
import useAppStore from "../../hooks/useAppStore";
import * as css from "./styles/OpDriverModeling.module.scss";
import * as workflowCSS from "../../styles/workflows/workflowCSS.module.scss";
import Header from "./components/Header";
import Card from "./components/Card";
import {Col, Grid, Row} from "@zendeskgarden/react-grid";
import DropdownSelectTable, {TDropdownSelectTableData, TDropdownSelectTableOption} from "./components/DropdownSelectTable";
import {MONTHS_LONG} from "../../constants/Months";
import {useProperties} from "../../contexts/properties/PropertiesContext";
import {ForecastLocks, useGetForecastLocksLazyQuery, useGetSimplifiedRevenueLazyQuery, useGetSimplifiedRevenueSummaryLazyQuery, useUpdateActiveRentRollMutation, useUpdateRentRollMutation} from "../../__generated__/generated_types";
import {toast} from "react-toastify";
import {WorkflowStepNavProps} from "../../components/workflows/workflow-step-nav/WorkflowStepNav";
import {Body, Close, Footer, FooterItem, Header as ZenHeader, Modal} from "@zendeskgarden/react-modals";
import {Button, IconButton} from "@zendeskgarden/react-buttons";
import LeaseExpirationScheduleTable from "./components/LeaseExpirationScheduleTable";
import {RiDownloadLine} from "react-icons/ri";
import LockBanner from "../workflows/account/LockBanner";

type TRentRoll = {
    id: string,
    isActive: boolean,
    date: string,
}

export default function LeaseExpirationSchedule(props: WorkflowStepNavProps): ReactElement {
    const appStore = useAppStore();
    const property = useProperties();

    const [triggerDownload, setTriggerDownload] = useState<boolean>(false);
    const [rentRollOptions, setRentRollOptions] = useState<TRentRoll[]>();
    const [rentRollDateOptions, setRentRollDateOptions] = useState<string[]>();
    const [expiryOptions, setExpiryOptions] = useState<TDropdownSelectTableOption[]>();
    const [selectedRentRoll, setSelectedRentRoll] = useState<string>();
    const [tempSelectedRentRoll, setTempSelectedRentRoll] = useState<string>();
    const [rentRoll, setRentRoll] = useState<TDropdownSelectTableData[]>([]);
    const [confirming, setConfirming] = useState<boolean>(false);
    const [forecastLocks, setForecastLocks] = useState<ForecastLocks>();
    const [getSimplifiedRevenue, {data, loading}] = useGetSimplifiedRevenueLazyQuery({
        // Don't use "network-only, you may accidentally get multiple fetches :(
        fetchPolicy: "no-cache",
    });
    const [getSimplifiedRevenueSummary, {data: summaryData}] = useGetSimplifiedRevenueSummaryLazyQuery({
        fetchPolicy: "no-cache",
    });
    const [getForecastLocks, {data: dataForecastLocks, loading: lockDataLoading}] = useGetForecastLocksLazyQuery({
        fetchPolicy: "no-cache",
    });
    const [updateRentRoll, {data: updateRentRollData, loading: updateRentRollLoading, error: updateRentRollError}] = useUpdateRentRollMutation();
    const [updateActiveRentRoll, {data: activeRentRollData, loading: activeRentRollLoading, error: activeRentRollError}] = useUpdateActiveRentRollMutation();

    useEffect(() => {
        appStore.set({isLoading: false});
        return () => undefined;
    }, []);

    useEffect(() => {
        let timeout: ReturnType<typeof setTimeout>;
        const currentProperty = property.currentProperty;
        if(currentProperty && !updateRentRollLoading && (updateRentRollData || updateRentRollError)) {
            timeout = setTimeout(() => {
                getSimplifiedRevenueSummary({
                    variables: {
                        budgetYear: currentProperty.budgetYear,
                        propertyId: currentProperty.id
                    }
                });
            }, 300);
        }
        return () => clearTimeout(timeout);
    }, [updateRentRollData, updateRentRollLoading, updateRentRollError]);

    useEffect(() => {
        let timeout: ReturnType<typeof setTimeout>;
        const currentProperty = property.currentProperty;
        if(currentProperty && !activeRentRollLoading && (activeRentRollData || activeRentRollError)) {
            timeout = setTimeout(() => {
                getSimplifiedRevenue({
                    variables: {
                        budgetYear: currentProperty.budgetYear,
                        propertyId: currentProperty.id
                    }
                });

                getSimplifiedRevenueSummary({
                    variables: {
                        budgetYear: currentProperty.budgetYear,
                        propertyId: currentProperty.id
                    }
                });
            }, 200);
        }
        return () => clearTimeout(timeout);
    }, [activeRentRollLoading, activeRentRollData, activeRentRollError]);

    useEffect(() => {
        if (!property.currentProperty) {
            return;
        }

        const tempRentRollOptions: TDropdownSelectTableOption[] = [];
        const currentYear = property.currentProperty.reforecastYear;
        const currentMonth = property.currentProperty.reforecastStartMonthIndex;

        for (let m = currentMonth; m < 12; m++) {
            const monthDate: Date = new Date(Date.UTC(currentYear, m, 1));
            tempRentRollOptions.push({label: `${MONTHS_LONG[m]} ${currentYear % 100}`, value: monthDate.toISOString().split('T')[0]});
        }

        for (let m = 0; m < 12; m++) {
            const monthDate: Date = new Date(Date.UTC(currentYear + 1, m, 1));
            tempRentRollOptions.push({label: `${MONTHS_LONG[m]} ${(currentYear + 1) % 100}`, value: monthDate.toISOString().split('T')[0]});
        }

        setExpiryOptions(tempRentRollOptions);
        getSimplifiedRevenue({
            variables: {
                propertyId: property.currentProperty.id,
                budgetYear: property.currentProperty.budgetYear,
            }
        });

        getSimplifiedRevenueSummary({
            variables: {
                propertyId: property.currentProperty.id,
                budgetYear: property.currentProperty.budgetYear,
            }
        });

        getForecastLocks({
            variables: {
                propertyId: property.currentProperty.id,
                budgetYear: property.currentProperty.budgetYear,
            }
        });
    }, [property.currentProperty]);

    useEffect(() => {
        if (!data || !expiryOptions || loading) {
            return;
        }

        const rentRollRaw = data.simplifiedRevenueModel?.rentRoll;
        const availableRentRolls = data.simplifiedRevenueModel?.availableRentRolls;

        const propertyRentRolls = availableRentRolls?.filter(x => x.propertyId == property.currentProperty?.id);

        const activeRentRoll = propertyRentRolls?.find(x => x.isActive);

        setRentRollOptions(propertyRentRolls);
        setRentRollDateOptions(propertyRentRolls?.map(x => x.date).sort());
        setSelectedRentRoll(activeRentRoll?.date);

        let tempRentRoll: TDropdownSelectTableData[] | undefined = undefined;
        if (rentRollRaw) {
            const expiringUnits = rentRollRaw.expiringUnits;
            tempRentRoll = [
                {
                    titleDivider: "Vacant Units",
                    items: expiringUnits.filter(x => x.reason == "Vacant").map(x => ({
                        id: x.unitId, colLeft: x.unitNumber, colRightSelection: x.conversionMonth, colRightOptions: expiryOptions
                    }))
                },
                {
                    titleDivider: "Units with MTM Leases",
                    items: expiringUnits.filter(x => x.reason == "MonthToMonth").map(x => ({
                        id: x.unitId, colLeft: x.unitNumber, colRightSelection: x.conversionMonth, colRightOptions: expiryOptions
                    }))
                }
            ];
        }

        setRentRoll(tempRentRoll ?? []);
    }, [data, loading, expiryOptions]);

    useEffect(() => {
        if (lockDataLoading || !dataForecastLocks?.forecastLocks) {
            setForecastLocks(undefined);
            return;
        }

        setForecastLocks(dataForecastLocks.forecastLocks.property);
    }, [dataForecastLocks, lockDataLoading]);

    return (
        <ThemeProvider theme={ViziblyTheme}>
            <div className={`${css.opDriverModelingWrapper} ${workflowCSS.workflowContainer}`}>
                <div className={workflowCSS.workflowContainerInner}>
                    <Header
                        title={"Lease Expiration Schedule"}
                        options={rentRollDateOptions}
                        selectedOption={selectedRentRoll}
                        nextAction={props.onNextClick}
                        prevAction={props.onPreviousClick}
                        onSelectOption={(roll: string) => {
                            setTempSelectedRentRoll(roll);
                            setConfirming(true);
                        }}
                    />

                    <Grid className={css.bodyContent} gutters={"lg"}>
                        <Row>
                            {property.currentProperty && forecastLocks && forecastLocks.reforecastLocked && forecastLocks.budgetLocked &&
                                <LockBanner text={`${property.currentProperty.budgetYear - 1} Reforecast and ${property.currentProperty.budgetYear} Budget years have been locked.`} />
                            }

                            {forecastLocks && rentRoll && selectedRentRoll &&
                                <Col>
                                    <Card
                                        title={"Select the month that Vacants and MTMs convert to Term Leases"}
                                    >
                                            <DropdownSelectTable
                                                colLeft="Unit #"
                                                colRight="Conversion Month"
                                                data={rentRoll}
                                                forecastLocks={forecastLocks}
                                                onDataChange={(unitId, conversionDate) => {
                                                    if (!property.currentProperty) {
                                                        return;
                                                    }

                                                    updateRentRoll({
                                                        variables: {
                                                            budgetYear: property.currentProperty.budgetYear,
                                                            propertyId: property.currentProperty.id,
                                                            updates: [
                                                                {
                                                                    unitId: unitId as string,
                                                                    conversionDate,
                                                                }
                                                            ]
                                                        }
                                                    }).then(data => {
                                                        if(data?.data?.updateSimplifiedRevenueExpiringUnits) {
                                                            toast.success("Saved", {autoClose: 2500, hideProgressBar: true});
                                                        }
                                                    });
                                                }}
                                            />
                                    </Card>
                                </Col>
                            }
                        </Row>

                        <Row>
                            <Col>
                                <Card
                                    title={"Lease Expiration Schedule"}
                                    actions={
                                        <IconButton onClick={() => {
                                            setTriggerDownload(true);
                                        }}>
                                            <RiDownloadLine />
                                        </IconButton>
                                    }
                                >
                                    {property.currentProperty &&
                                        <LeaseExpirationScheduleTable
                                            property={property.currentProperty}
                                            data={summaryData}
                                            triggerDownload={triggerDownload}
                                            onDownloadComplete={() => setTriggerDownload(false)}
                                        />
                                    }
                                </Card>
                            </Col>
                        </Row>
                    </Grid>
                </div>
            </div>

            {confirming && (
                <Modal
                    onClose={() => setConfirming(false)}>
                    <ZenHeader>Update Rent Roll</ZenHeader>
                    <Body>
                        <p>Updating the rent roll will automatically update your financial forecast and require you to manually update the conversion month of your MTMs and vacant units.</p>
                    </Body>
                    <Footer>
                        <FooterItem>
                            <Button
                                isBasic
                                onClick={() => setConfirming(false)}>
                                Cancel
                            </Button>
                        </FooterItem>
                        <FooterItem>
                            <Button isPrimary onClick={() => {
                                const rentRollId = rentRollOptions?.find(x => x.date == tempSelectedRentRoll)?.id;

                                if (!property.currentProperty || !rentRollId) {
                                    return;
                                }

                                updateActiveRentRoll({
                                    variables: {
                                        budgetYear: property.currentProperty.budgetYear,
                                        propertyId: property.currentProperty.id,
                                        rentRollId,
                                    }
                                }).then(resp => {
                                    if (resp?.data?.setActiveRentRoll) {
                                        toast.success("Active Rent Roll Changed", {autoClose: 2500, hideProgressBar: true});
                                    }
                                    setConfirming(false);
                                }, () => {
                                    toast.error("Failed to set active rent roll.");
                                    setConfirming(false);
                                });
                            }}>
                                Update
                            </Button>
                        </FooterItem>
                    </Footer>
                    <Close aria-label="Close modal" />
                </Modal>
            )}
        </ThemeProvider>
    );
}