/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React, {ReactElement, useEffect, useState} from 'react';

import useAppStore from "../../../hooks/useAppStore";
import useGetMonths from "../../../hooks/UseGetMonths";

import {UnitType, useProperties} from "../../../contexts/properties/PropertiesContext";
import {useSettings} from "../../../contexts/settings/SettingsContext";

import {useParams} from "react-router-dom";
import AcctRenovationsSpread from "./components/AcctRenovationsSpread";
import {BudgetingType} from "../../../BudgetingType";

import {RenderIfReadOnly} from "../../../authorization/RenderIfAuthorized";
import ReadOnlyHeader from "../../../components/navbar/ReadOnlyHeader";

import * as Style from './helpers/styles.module.scss';
import * as navStyles from "../../../components/navbar/styles.module.scss";

import {ThemeProvider} from "styled-components";
import {ViziblyTheme} from "../../../styles/zendesk-garden/ViziblyZDGTheme";

import {TabPanel, Tabs} from "@zendeskgarden/react-tabs";

import * as workflowCSS from "../../../styles/workflows/workflowCSS.module.scss";
import * as workflowHeaderCSS from "../../../components/workflows/workflow-header/styles/css.module.scss";

import WorkflowHeader from "../../../components/workflows/workflow-header/WorkflowHeader";
import WorkflowLoadingOverlay from "../../../components/workflows/workflow-loading-overlay/WorkflowLoadingOverlay";
import WorkflowPageLabel from "../../../components/workflows/workflow-page-label/WorkflowPageLabel";
import WorkflowStepNav from "../../../components/workflows/workflow-step-nav/WorkflowStepNav";

import WorkflowYearTabs, {
    WORKFLOW_YEAR_TAB_BUDGET,
    WORKFLOW_YEAR_TAB_REFORECAST
} from "../../../components/workflows/workflow-year-tabs/WorkflowYearTabs";
import {IWorkflowPageProps, shouldUpdateWorkflowTab,} from "../logic/workflows";
import WorkflowNavToggle from "../../../components/workflows/workflow-nav-toggle/WorkflowNavToggle";
import { useGetForecastLocksLazyQuery } from '../../../__generated__/generated_types';

function AccountRenovations(props: IWorkflowPageProps): ReactElement {

    const { unitTypeId, type } = useParams() as { unitTypeId: string; type: string };
    const { currentProperty: property, unitTypes } = useProperties();

    const { year } = useSettings();
    const currentVersionYear = type == BudgetingType.BUDGET ? year + 1 : year;
    const months = useGetMonths();

    const thisUnitType: UnitType = unitTypes.find(unit => unit.id == unitTypeId) ?? {
        id: unitTypeId,
        type: "",
    };

    const [selectedTab, setSelectedTab] = useState(WORKFLOW_YEAR_TAB_BUDGET);
    const [accountDataLoading, setAccountDataLoading] = useState<boolean>(true);

    const [getForecastLocks, {data: dataForecastLocks, loading: lockDataLoading}] = useGetForecastLocksLazyQuery({
        fetchPolicy: "no-cache",
    });

    useEffect(() => {
        if (!property?.id) {
            return;
        }

        getForecastLocks({
            variables: {
                propertyId: property.id,
                budgetYear: year + 1,
            }
        });
    }, [property]);

    /**
     * Update selected tab, which updates display, on type change
     */
    useEffect(
            () => {
                const updateWorkflowTab = shouldUpdateWorkflowTab(type, selectedTab);
                if(updateWorkflowTab != false){
                    setSelectedTab(updateWorkflowTab as string);
                }
            },
            [type]
    );

    return (
            <ThemeProvider theme={ViziblyTheme}>
                <WorkflowLoadingOverlay loading={accountDataLoading} />
                <RenderIfReadOnly>
                    <ReadOnlyHeader className={navStyles.readOnlyHeaderFixed} />
                </RenderIfReadOnly>
                <div className={workflowCSS.workflowContainer}>
                    <WorkflowHeader>
                        <WorkflowHeader.LeftCol>
                            <div className={workflowHeaderCSS.rowItemsContainer}>
                                <WorkflowNavToggle/>
                                <WorkflowPageLabel label={thisUnitType.type} />
                            </div>
                        </WorkflowHeader.LeftCol>
                        <WorkflowHeader.RightCol>
                            <WorkflowStepNav onPreviousClick={props.onPreviousClick} onNextClick={props.onNextClick}/>
                        </WorkflowHeader.RightCol>
                    </WorkflowHeader>
                    <div className={workflowCSS.bodyContainer}>
                        <Tabs selectedItem={selectedTab} onChange={setSelectedTab} className={workflowCSS.accountYearTabNav}>
                            <WorkflowYearTabs year={year}
                                selectedTab={selectedTab}
                                rawForecastLocks={dataForecastLocks}
                            />

                            {(!lockDataLoading && dataForecastLocks?.forecastLocks?.property) &&
                                <div className={Style.renoTable}>
                                    {selectedTab === WORKFLOW_YEAR_TAB_REFORECAST ?
                                            <AcctRenovationsSpread
                                                year={year}
                                                months={months}
                                                property={property}
                                                type={type}
                                                unitTypeId={unitTypeId}
                                                unitName={thisUnitType.type}
                                                currentVersionYear={currentVersionYear}
                                                budgetingType={type}
                                                accountDataLoading={setAccountDataLoading}
                                                readOnly={dataForecastLocks.forecastLocks.property.reforecastLocked}
                                            />
                                            :
                                            <AcctRenovationsSpread
                                                year={year + 1}
                                                months={months}
                                                property={property}
                                                type={type}
                                                unitTypeId={unitTypeId}
                                                unitName={thisUnitType.type}
                                                currentVersionYear={currentVersionYear}
                                                budgetingType={type}
                                                accountDataLoading={setAccountDataLoading}
                                                readOnly={dataForecastLocks.forecastLocks.property.budgetLocked}
                                            />

                                    }
                                </div>
                            }
                        </Tabs>
                    </div>
                </div>
            </ThemeProvider>
    );
}

export default React.memo(AccountRenovations);
