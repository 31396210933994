import { Button } from "@zendeskgarden/react-buttons";
import { Body, Close, Footer, FooterItem, Header, Modal } from "@zendeskgarden/react-modals";
import { ReactElement, useState } from "react";
import { Inline } from "@zendeskgarden/react-loaders";
import { useGetUnitTypeDependenciesQuery } from "../../../__generated__/generated_types";

export interface IBudgetYearProtectingModal {
    unitTypeId: string,
    unitTypeName: string,
    budgetYear: number,
    onClose: () => void,
    onConfirm: () => void,
}

export default function DeleteUnitTypeModal(props: IBudgetYearProtectingModal): ReactElement {

    const {data: unitTypeDepsData, loading: unitTypeDepsLoading} = useGetUnitTypeDependenciesQuery({
        fetchPolicy: "no-cache",
        variables: {
            unitTypeId: props.unitTypeId,
            budgetYear: props.budgetYear
        }
    });
    const [deleteClicked, setDeleteClicked] = useState(false);
    const deleteButtonDisabled = deleteClicked || unitTypeDepsLoading || !unitTypeDepsData;

    return (
        <Modal onClose={props.onClose}>
            <Header isDanger>
                Confirm Delete Unit Type
            </Header>
            <Body>
                Are you sure you want to delete the unit type <b>{props.unitTypeName}</b>?
                {unitTypeDepsData && !unitTypeDepsLoading && (unitTypeDepsData.renovationPackagesByUnitType.length > 0) &&
                <>
                    <br/>
                    <br/>
                    <b>Following renovation packages will be removed:</b> <br/>
                    <ul>
                        {unitTypeDepsData.renovationPackagesByUnitType.map((pkg, index) => (
                            <li key={index}>{pkg.name}</li>
                        ))}
                    </ul>
                </>
                }

            </Body>
            <Footer>
                <FooterItem>
                    <Button onClick={props.onClose} isBasic>
                        Cancel
                    </Button>
                </FooterItem>
                <FooterItem>
                    {
                        <Button isPrimary onClick={() => {setDeleteClicked(true); props.onConfirm();}} disabled={deleteButtonDisabled}>
                            {
                                deleteClicked
                                    ? <Inline size={24} aria-label="loading"/>
                                    : <span>Delete</span>
                            }
                        </Button>
                    }
                </FooterItem>
            </Footer>
            <Close aria-label="Close modal" />
        </Modal>
    );
}