import {
    CreateUnitEventMutationVariables,
    EnrichPossibleUnitEventQuery,
    UnitEventType,
    useEnrichPossibleUnitEventLazyQuery
} from "../../../../../../__generated__/generated_types";
import { ApolloError } from "@apollo/client";

export type enrichPossibleUnitEventsReturn = {
    requestEnrichedPossibleUnitEvent: (
        variables: CreateUnitEventMutationVariables
    ) => void,
    enrichedPossibleUnitEventData: EnrichPossibleUnitEventQuery|undefined,
    enrichedPossibleUnitEventLoading: boolean,
    enrichedPossibleUnitEventError: ApolloError|undefined,
}

export default function useEnrichPossibleUnitEvent(): enrichPossibleUnitEventsReturn {
    const [enrichPossibleUnitEvents, {
        data: enrichedPossibleUnitEventData,
        loading: enrichedPossibleUnitEventLoading,
        error: enrichedPossibleUnitEventError,
    }] = useEnrichPossibleUnitEventLazyQuery({
        fetchPolicy: "no-cache",
    });

    const requestEnrichedPossibleUnitEvent = (
        variables: CreateUnitEventMutationVariables
    ): void => {
        enrichPossibleUnitEvents({
            variables
        });
    };

    return {
        requestEnrichedPossibleUnitEvent,
        enrichedPossibleUnitEventData,
        enrichedPossibleUnitEventLoading,
        enrichedPossibleUnitEventError,
    };
}