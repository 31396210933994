import React, { ReactElement, useEffect } from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";

import useAppStore from "../../hooks/useAppStore";

import MonthlyVarianceReport from "./monthly-variance-report/MonthlyVarianceReport";

import * as reportsCSS from './styles/reportsCSS.module.scss';
import { ThemeProvider } from "styled-components";
import { ViziblyTheme } from "../../styles/zendesk-garden/ViziblyZDGTheme";
import { MonthlyVarianceReportProvider } from "./monthly-variance-report/context/MonthlyVarianceReportContext";
import { LineItemsReport } from "./line-items-report/LineItemsReport";

export default function ReportsRoute():ReactElement{

    const appStore = useAppStore();

    const { path } = useRouteMatch();

    useEffect(
            () => {
                appStore.set({ isLoading: false });
                // eslint-disable-next-line @typescript-eslint/no-empty-function
                return () => {};
            },
            []
    );

    return (
            <ThemeProvider theme={ViziblyTheme}>
                <div className={reportsCSS.reportsContainer}>
                    <Switch>
                        {/* Monthly Variance Report: path = `/reports/mvr` */}
                        <Route exact path={`${path}/:year?/:month?`}>
                            <MonthlyVarianceReportProvider>
                                <MonthlyVarianceReport/>
                            </MonthlyVarianceReportProvider>
                        </Route>
                    </Switch>
                </div>
            </ThemeProvider>
    );
}