import { ReactElement, useMemo, useState } from "react";
import { Body, Close, Footer, FooterItem, Header, Modal } from "@zendeskgarden/react-modals";
import { Field as FormField, Label, Input } from "@zendeskgarden/react-forms";
import { Datepicker } from "@zendeskgarden/react-datepickers";
import { Button } from "@zendeskgarden/react-buttons";
import { Inline } from "@zendeskgarden/react-loaders";
import { DateFormatter } from "../../../components/analyst-payroll-drawer/helpers/utils";
import { ensureDateInLocale, ensureDateInUtc } from "../../../utils/helper-funcs";

export type BulkUpdateOvertimeProps = {
    onClose: () => void;
    onConfirm: (
        startDate: Date,
        endDate: Date | undefined,
        hourlyRate: number,
        hoursWeek: number
    ) => void;
    loading: boolean;
    isCreate?: boolean;
}

export function BulkUpdateOvertime(props: BulkUpdateOvertimeProps): ReactElement {
    const [startDate, setStartDate] = useState<Date>();
    const [endDate, setEndDate] = useState<Date>();
    const [hourlyRate, setHourlyRate] = useState<number>();
    const [hoursWeek, setHoursWeek] = useState<number>();

    function handleConfirm() {
        if (startDate != undefined && hourlyRate != undefined && hoursWeek != undefined) {
            props.onConfirm(startDate, endDate, hourlyRate, hoursWeek);
        }
    }

    const canConfirm = useMemo(
        () => startDate != undefined && hourlyRate != undefined && hoursWeek != undefined
        ,
        [startDate, hourlyRate, hoursWeek]
    );

    return (
    <Modal onClose={() => props.onClose()} isLarge style={{overflow: "unset"}}>
        <Header>
            Bulk {props.isCreate == undefined || !props.isCreate? "Update" : "Create"} Overtime
        </Header>
        <Body>
            <div style={{flexDirection: "column", display: "flex", height: "500px"}}>
                <div style={{display: "flex", flexDirection: "row", marginTop: "1rem"}}>
                    <FormField style={{width: "20rem"}}>
                        <Label>Hourly Rate</Label>
                        <Input
                            placeholder="$"
                            value={hourlyRate}
                            type={"number"}
                            onChange={(evt) => {
                                const val = Number.parseFloat(evt.target.value);
                                if (Number.isNaN(val)) {
                                    setHourlyRate(undefined);
                                }
                                else {
                                    setHourlyRate(val);
                                }
                            }}
                        />
                    </FormField>
                    <FormField style={{marginLeft: "auto", width: "20rem"}}>
                        <Label>Hours/Week</Label>
                        <Input
                            value={hoursWeek}
                            type={"number"}
                            onChange={(evt) => {
                                const val = Number.parseFloat(evt.target.value);
                                if (Number.isNaN(val)) {
                                    setHoursWeek(undefined);
                                }
                                else {
                                    setHoursWeek(val);
                                }
                            }}
                        />
                    </FormField>
                </div>
                <div style={{display: "flex", flexDirection: "row", marginTop: "1rem"}}>
                    <FormField style={{width: "20rem"}}>
                        <Label>Start Date</Label>
                        <Datepicker
                            value={ensureDateInLocale(startDate)}
                            onChange={(date) => setStartDate(ensureDateInUtc(date))}
                            formatDate={date => {
                                return date ? DateFormatter.format(ensureDateInLocale(date)) : "";
                            }}
                        >

                            <Input placeholder="Select Date"/>
                        </Datepicker>
                    </FormField>
                    <FormField style={{marginLeft: "auto", width: "20rem"}}>
                        <Label>End Date</Label>
                        <Datepicker
                            value={ensureDateInLocale(endDate)}
                            onChange={(date) => setEndDate(new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate())))}
                            formatDate={date => {
                                return date ? DateFormatter.format(ensureDateInLocale(date)) : "";
                            }}
                        >

                            <Input placeholder="Select Date"/>
                        </Datepicker>
                    </FormField>
                </div>
            </div>
        </Body>
        <Footer>
            <FooterItem>
                <Button onClick={() => props.onClose()} isBasic disabled={false}>
                    Cancel
                </Button>
            </FooterItem>
            <FooterItem>
                {
                    <Button
                        isPrimary
                        onClick={() => handleConfirm()}
                        disabled={props.loading || !canConfirm}
                    >
                        {
                            props.loading
                                ? <Inline size={24} aria-label="loading"/>
                                :
                                 <span>Confirm</span>
                        }
                    </Button>
                }
            </FooterItem>
        </Footer>
        <Close aria-label="Close modal" />
    </Modal>

    );
}