import { BudgetingType } from "../../../../../../BudgetingType";
import { GetCappedRenewalIncreaseQuery } from "../../../../../../__generated__/generated_types";

export interface Props {
    budgetingType: BudgetingType;
    propertyId: string;
    year: number;
    startReforecastMonthIndex: number;
    data: GetCappedRenewalIncreaseQuery["property"];
    canRender: boolean;
    readOnly?: boolean;
}

export enum SheetNames {
    PROPERTY_LEVEL = "PropertyLevel",
    UNIT_TYPE_LEVEL = "UnitTypeLevel"
}

export const SheetsIndexMap:Record<SheetNames, number> = {
    [SheetNames.PROPERTY_LEVEL]: 0,
    [SheetNames.UNIT_TYPE_LEVEL]: 1
};

export const FIRST_VALUE_ROW = 4;
export const FIRST_VALUE_COLUMN = 4;
export const PROPERTY_LEVEL_TOTAL_ROWS_COUNT = 1 + 3 + 4 * 3; // header, current_year * 3, 4 * 3 past years
export const TOTAL_COLS_COUNT = 15; // year names, tradeout, 12 months, average

export const SJS_TEXT_PERCENT = "%";
export const SJS_TEXT_TRADEOUT_DOLLAR = "Tradeout $";
export const SJS_TEXT_TRADEOUT_PERCENTAGE = `Tradeout ${SJS_TEXT_PERCENT}`;
export const SJS_TEXT_IN_PLACE_RENT = `In Place Rent`;
export const SJS_TEXT_REFORECAST = "Reforecast";
export const SJS_TEXT_BUDGET = "Budget";
