import {ReactElement, useEffect, useState} from "react";
import {Button} from "@zendeskgarden/react-buttons";
import {Body, Footer, FooterItem, Header, Modal} from "@zendeskgarden/react-modals";
import {Body as TableBody, Cell, HeaderCell, HeaderRow, Row, Table} from "@zendeskgarden/react-tables";
import {Input} from "@zendeskgarden/react-forms";
import {
    useCalculateCurveExpirationsLazyQuery,
    useGetExpirationCurveQuery,
    useMassUpdateLeaseExpirationCountsForPropertyAndPeriodMutation,
    useSetExpirationCurveMutation,
    VersionType
} from "../../../../../__generated__/generated_types";
import {
    curveCountTableHeader,
    curveCountTableNumber,
    curveCountTableUnitType,
    curveInput,
    curveModal,
    curveModalBody,
    curveSmallHeader,
    curveTableCell,
    curveTableHeader
} from "./leaseExpirationCurveModal.module.scss";

interface UnitTypeExpirations {
    unitTypeId: string;
    unitTypeName: string;
    reforecastExpirations: number[];
    budgetExpirations: number[];
}

export interface LeaseExpirationCurveModalProps {
    propertyId: string;
    budgetYear: number;
    handleCancel: () => void;
    handleConfirm: () => void;
}

export function LeaseExpirationCurveModal(props: LeaseExpirationCurveModalProps): ReactElement {

    const [expirationCurve, setExpirationCurve] = useState<Array<string | undefined>>(new Array(12).fill(undefined));
    const [goodExpirationCurve, setGoodExpirationCurve] = useState<string[] | null>(null);
    const [curveErrors, setCurveErrors] = useState<boolean[]>(new Array(12).fill(false));
    const [is100Percent, setIs100Percent] = useState<boolean>(false);
    const [currentTotal, setCurrentTotal] = useState<number>(0);
    const [expirationCounts, setExpirationCounts] = useState<UnitTypeExpirations[] | null>(null);

    const {data: curveData, loading: curveLoading} = useGetExpirationCurveQuery({
        fetchPolicy: "no-cache",
        variables: {
            propertyId: props.propertyId,
            budgetYear: props.budgetYear,
        }
    });

    const [calculateExpirations, {data: expirationsData, loading: expirationsLoading}] = useCalculateCurveExpirationsLazyQuery({
        fetchPolicy: "no-cache"
    });

    const [saveExpirationCurve, {data: curveUpdate, loading: curveUpdateLoading}] = useSetExpirationCurveMutation();
    const [saveExpirationCounts, {data: countsUpdate, loading: countsLoading}] = useMassUpdateLeaseExpirationCountsForPropertyAndPeriodMutation();

    useEffect(() => {
        if(curveLoading && !(curveData?.expirationCurve)) {
            return;
        }


        const curve = curveData?.expirationCurve;
        if(curve) {
            setExpirationCurve(curve.curve);
        }
    }, [curveData, curveLoading]);

    useEffect(() => {
        const floatCurve = new Array<number>(12).fill(0);
        const goodCurve = new Array<string>(12).fill("0");
        for(let i = 0; i < expirationCurve.length; i++) {
            let val = 0;
            try {
                const rawVal = expirationCurve[i];
                if(rawVal) {
                    val = parseFloat(rawVal);
                    goodCurve[i] = rawVal;
                }
            } catch(err) {
                // Do nothing treat the value as 0.
            }
            floatCurve[i] = val;
        }

        const sum = floatCurve.sum();
        if(Math.abs(100 - sum) < 0.000001) {
            setIs100Percent(true);
            setGoodExpirationCurve(goodCurve);
        } else {
            setIs100Percent(false);
        }
        setCurrentTotal(sum);
    }, [expirationCurve]);

    useEffect(() => {
        if(!is100Percent || !goodExpirationCurve) {
            return;
        }

        calculateExpirations({
            variables: {
                propertyId: props.propertyId,
                budgetYear: props.budgetYear,
                curve: goodExpirationCurve
            }
        });
    }, [is100Percent, goodExpirationCurve]);

    useEffect(() => {
        if(expirationsLoading && !(expirationsData?.calculateExpirationCurveExpirations)) {
            return;
        }

        if(expirationsData?.calculateExpirationCurveExpirations) {
            const result: UnitTypeExpirations[] = [];
            for (const utData of expirationsData.calculateExpirationCurveExpirations) {
                result.push({
                    unitTypeId: utData.unitTypeId,
                    unitTypeName: utData.unitTypeName,
                    reforecastExpirations: utData.reforecastExpirations,
                    budgetExpirations: utData.budgetExpirations
                });
            }
            setExpirationCounts(result);
        }
    }, [expirationsData, expirationsLoading]);

    function setCurveValue(idx: number, curve: (string | undefined)[], errors: boolean[], value: string): void {
        function setError(idx: number, errors: boolean[], value: boolean): void {
            const newErrors = [...errors];
            newErrors[idx] = value;
            setCurveErrors(newErrors);
        }

        let actualVal: string | undefined = undefined;
        if(value !== "") {
            actualVal = value;

            setError(idx, errors, !/^\d+(\.\d+)?$/.test(actualVal));
        } else {
            setError(idx, errors, false);
        }
        const newCurve = [...curve];
        newCurve[idx] = actualVal;
        setExpirationCurve(newCurve);
    }

    function saveCurve(curveToSave: string[]): void {
        saveExpirationCurve({
            variables: {
                propertyId: props.propertyId,
                budgetYear: props.budgetYear,
                curve: curveToSave
            }
        });
    }

    function saveAll(curveToSave: string[] | null, countsToSave: UnitTypeExpirations[] | null): void {
        if(!curveToSave || !countsToSave) {
            return;
        }

        const curvePromise = saveExpirationCurve({
            variables: {
                propertyId: props.propertyId,
                budgetYear: props.budgetYear,
                curve: curveToSave
            }
        });
        const countsPromise = saveExpirationCounts({
            variables: {
                propertyId: props.propertyId,
                versionType: VersionType.Budget,
                updates: countsToSave.map(c => ({
                    unitTypeId: c.unitTypeId,
                    values: c.budgetExpirations
                }))
            }
        });

        Promise.all([curvePromise, countsPromise]).then(() => {
            props.handleConfirm();
        });
    }

    function renderExpirationCountsTable(expirationCounts: UnitTypeExpirations[]): ReactElement {

        const sortedCounts = expirationCounts.sortBy(c => c.unitTypeName);
        const budgetLastTwoDigits = props.budgetYear - (Math.floor(props.budgetYear / 100) * 100);

        const rows:ReactElement[] = [];

        for(const counts of sortedCounts) {
            const row = <Row>
                <Cell colSpan={2} className={curveCountTableUnitType}>{counts.unitTypeName}</Cell>
                <Cell className={curveCountTableNumber}>{counts.budgetExpirations[0]}</Cell>
                <Cell className={curveCountTableNumber}>{counts.budgetExpirations[1]}</Cell>
                <Cell className={curveCountTableNumber}>{counts.budgetExpirations[2]}</Cell>
                <Cell className={curveCountTableNumber}>{counts.budgetExpirations[3]}</Cell>
                <Cell className={curveCountTableNumber}>{counts.budgetExpirations[4]}</Cell>
                <Cell className={curveCountTableNumber}>{counts.budgetExpirations[5]}</Cell>
                <Cell className={curveCountTableNumber}>{counts.budgetExpirations[6]}</Cell>
                <Cell className={curveCountTableNumber}>{counts.budgetExpirations[7]}</Cell>
                <Cell className={curveCountTableNumber}>{counts.budgetExpirations[8]}</Cell>
                <Cell className={curveCountTableNumber}>{counts.budgetExpirations[9]}</Cell>
                <Cell className={curveCountTableNumber}>{counts.budgetExpirations[10]}</Cell>
                <Cell className={curveCountTableNumber}>{counts.budgetExpirations[11]}</Cell>
                <Cell className={curveCountTableNumber}>100%</Cell>
            </Row>;
            rows.push(row);
        }

        return <Table>
            <HeaderRow>
                <HeaderCell className={curveCountTableUnitType}>Unit Type</HeaderCell>
                <HeaderCell></HeaderCell>
                <HeaderCell className={curveCountTableHeader}>Jan '{budgetLastTwoDigits}</HeaderCell>
                <HeaderCell className={curveCountTableHeader}>Feb '{budgetLastTwoDigits}</HeaderCell>
                <HeaderCell className={curveCountTableHeader}>Mar '{budgetLastTwoDigits}</HeaderCell>
                <HeaderCell className={curveCountTableHeader}>Apr '{budgetLastTwoDigits}</HeaderCell>
                <HeaderCell className={curveCountTableHeader}>May '{budgetLastTwoDigits}</HeaderCell>
                <HeaderCell className={curveCountTableHeader}>Jun '{budgetLastTwoDigits}</HeaderCell>
                <HeaderCell className={curveCountTableHeader}>Jul '{budgetLastTwoDigits}</HeaderCell>
                <HeaderCell className={curveCountTableHeader}>Aug '{budgetLastTwoDigits}</HeaderCell>
                <HeaderCell className={curveCountTableHeader}>Sep '{budgetLastTwoDigits}</HeaderCell>
                <HeaderCell className={curveCountTableHeader}>Oct '{budgetLastTwoDigits}</HeaderCell>
                <HeaderCell className={curveCountTableHeader}>Nov '{budgetLastTwoDigits}</HeaderCell>
                <HeaderCell className={curveCountTableHeader}>Dec '{budgetLastTwoDigits}</HeaderCell>
                <HeaderCell className={curveCountTableHeader}>Unit Percentage</HeaderCell>
            </HeaderRow>
            <TableBody>
                {rows}
            </TableBody>
        </Table>;
    }

    return <Modal isLarge className={curveModal}>
        <Header>Set Expirations via Curve</Header>
        <Body className={curveModalBody}>
            <h5 className={curveSmallHeader}>Expiration Curve</h5>
            <Table>
                <HeaderRow>
                    <HeaderCell className={curveTableHeader}>Jan</HeaderCell>
                    <HeaderCell className={curveTableHeader}>Feb</HeaderCell>
                    <HeaderCell className={curveTableHeader}>Mar</HeaderCell>
                    <HeaderCell className={curveTableHeader}>Apr</HeaderCell>
                    <HeaderCell className={curveTableHeader}>May</HeaderCell>
                    <HeaderCell className={curveTableHeader}>Jun</HeaderCell>
                    <HeaderCell className={curveTableHeader}>Jul</HeaderCell>
                    <HeaderCell className={curveTableHeader}>Aug</HeaderCell>
                    <HeaderCell className={curveTableHeader}>Sep</HeaderCell>
                    <HeaderCell className={curveTableHeader}>Oct</HeaderCell>
                    <HeaderCell className={curveTableHeader}>Nov</HeaderCell>
                    <HeaderCell className={curveTableHeader}>Dec</HeaderCell>
                </HeaderRow>
                <TableBody>
                    <Row>
                        <Cell className={curveTableCell}>
                            <Input
                                    className={curveInput}
                                    value={expirationCurve[0]}
                                    onChange={evt => setCurveValue(0, expirationCurve, curveErrors, evt.target.value)}
                                    validation={curveErrors[0] ? "error" : undefined} />
                        </Cell>
                        <Cell className={curveTableCell}>
                            <Input
                                    className={curveInput}
                                    value={expirationCurve[1]}
                                    onChange={evt => setCurveValue(1, expirationCurve, curveErrors, evt.target.value)}
                                    validation={curveErrors[1] ? "error" : undefined} />
                        </Cell>
                        <Cell className={curveTableCell}>
                            <Input
                                    className={curveInput}
                                    value={expirationCurve[2]}
                                    onChange={evt => setCurveValue(2, expirationCurve, curveErrors, evt.target.value)}
                                    validation={curveErrors[2] ? "error" : undefined} />
                        </Cell>
                        <Cell className={curveTableCell}>
                            <Input
                                    className={curveInput}
                                    value={expirationCurve[3]}
                                    onChange={evt => setCurveValue(3, expirationCurve, curveErrors, evt.target.value)}
                                    validation={curveErrors[3] ? "error" : undefined} />
                        </Cell>
                        <Cell className={curveTableCell}>
                            <Input
                                    className={curveInput}
                                    value={expirationCurve[4]}
                                    onChange={evt => setCurveValue(4, expirationCurve, curveErrors, evt.target.value)}
                                    validation={curveErrors[4] ? "error" : undefined} />
                        </Cell>
                        <Cell className={curveTableCell}>
                            <Input
                                    className={curveInput}
                                    value={expirationCurve[5]}
                                    onChange={evt => setCurveValue(5, expirationCurve, curveErrors, evt.target.value)}
                                    validation={curveErrors[5] ? "error" : undefined} />
                        </Cell>
                        <Cell className={curveTableCell}>
                            <Input
                                    className={curveInput}
                                    value={expirationCurve[6]}
                                    onChange={evt => setCurveValue(6, expirationCurve, curveErrors, evt.target.value)}
                                    validation={curveErrors[6] ? "error" : undefined} />
                        </Cell>
                        <Cell className={curveTableCell}>
                            <Input
                                    className={curveInput}
                                    value={expirationCurve[7]}
                                    onChange={evt => setCurveValue(7, expirationCurve, curveErrors, evt.target.value)}
                                    validation={curveErrors[7] ? "error" : undefined} />
                        </Cell>
                        <Cell className={curveTableCell}>
                            <Input
                                    className={curveInput}
                                    value={expirationCurve[8]}
                                    onChange={evt => setCurveValue(8, expirationCurve, curveErrors, evt.target.value)}
                                    validation={curveErrors[8] ? "error" : undefined} />
                        </Cell>
                        <Cell className={curveTableCell}>
                            <Input
                                    className={curveInput}
                                    value={expirationCurve[9]}
                                    onChange={evt => setCurveValue(9, expirationCurve, curveErrors, evt.target.value)}
                                    validation={curveErrors[9] ? "error" : undefined} />
                        </Cell>
                        <Cell className={curveTableCell}>
                            <Input
                                    className={curveInput}
                                    value={expirationCurve[10]}
                                    onChange={evt => setCurveValue(10, expirationCurve, curveErrors, evt.target.value)}
                                    validation={curveErrors[10] ? "error" : undefined} />
                        </Cell>
                        <Cell className={curveTableCell}>
                            <Input
                                    className={curveInput}
                                    value={expirationCurve[11]}
                                    onChange={evt => setCurveValue(11, expirationCurve, curveErrors, evt.target.value)}
                                    validation={curveErrors[11] ? "error" : undefined} />
                        </Cell>
                    </Row>
                </TableBody>
            </Table>
            {!is100Percent &&
                    <div>Curve must total to 100%. Current Total {currentTotal.toFixed(4)}%.</div>
            }

            <h5 className={curveSmallHeader}>Expiration Counts for Curve</h5>
            {!is100Percent ?
                <div>Values will calculate when curve is complete.</div> :
                !expirationCounts ?
                        <div>Unable to calculate expirations</div> :
                        renderExpirationCountsTable(expirationCounts)
            }
        </Body>
        <Footer>
            <FooterItem>
                <Button isBasic onClick={props.handleCancel}>Cancel</Button>
            </FooterItem>
            <FooterItem>
                <Button
                        isNeutral
                        disabled={!is100Percent || !curveErrors.every(v => !v) || !goodExpirationCurve || curveUpdateLoading || countsLoading}
                        onClick={() => saveCurve(goodExpirationCurve as string[])}>Set Curve</Button>
            </FooterItem>
            <FooterItem>
                <Button
                        isPrimary
                        disabled={!is100Percent || !curveErrors.every(v => !v) || curveUpdateLoading || countsLoading}
                        onClick={() => saveAll(goodExpirationCurve, expirationCounts)}>Apply</Button>
            </FooterItem>
        </Footer>
    </Modal>;
}