import GC from "@grapecity/spread-sheets";
import {ICustomCellInfo} from "./spreadjs-custom-cell-types/types";
import { SingleRowSelectionColorAdvisor } from "./NewSpreadsheet";

export interface CellRect {
    x: number;
    y: number;
    height: number;
    width: number;
}

export interface CellCoords {
    row: number;
    col: number;
}

export interface CellHitInfo {
    row: number;
    col: number;
    cellRect: CellRect;
    offsetLeft: number;
    offsetTop: number;
    prev?: CellHitInfo;
    gcuiElement?: string;
}

export interface CellClickedInfo {
    row: number;
    col: number;
}

export interface NewSpreadsheetHandlers {
    cellsChanged: (cells: ChangedCell[]) => void;
    cellHovered: (hoverInfo: CellHitInfo) => void;
    cellContentHovered: (hoverInfo: CellHitInfo) => void;
    cellClicked: (clickInfo: CellHitInfo) => void;
    cellContentClicked: (clickInfo: CellHitInfo | undefined) => void;
    cellTargetClicked: (clickInfo: CellHitInfo | undefined) => void;
    selectionIsChanging: (cellCoords: CellCoords | undefined) => void;
    onInit: (handle: SpreadsheetHandle) => void;
    onDeinit: () => void;
}




export enum CustomCellType {
    CUSTOM_LINK = 1,
    OVERRIDE_MODE_CELL = 2,
    CUSTOM_LINK_WITH_TRIANGLE = 3,
    OVERRIDE_MODE_CELL_WITH_TRIANGLE = 4,
    EXPANDABLE_CATEGORY_CELL = 5,
    ROLLUP_TOTALS = 6,
    EXPAND_ROW_CELL = 7,
    COLLAPSE_ROW_CELL = 8,
    LIGHTNING_CELL = 9,
    DRIVER_PILLS_CELL = 10,
    TYPEAHEAD_DROPDOWN_CELL = 11,
    CHECKBOX_CELL = 12,
    BLANK_DASH = 13
}

export interface NewSpreadsheetAPI {
    download: () => string;
    showRows: (params: {startRow: number, rows: number, sheetName?: string}) => void;
    // eslint-disable-next-line @typescript-eslint/ban-types
    setTemplate: (params: {template: Object, sheetName?: string, rows?: number}) => void;
    subscribe: () => void;
    suspend: () => void;
    resume: () => void;
    setArrayFormula: (params: { row: number, col: number, rows: number, cols: number, formula: string, sheetName?: string }) => void;
    setArray: (params: {row: number, col: number, array: any[], setFormula?: boolean, sheetName?: string, notify?: boolean}) => void;
    setValue: (params: {row: number, col: number, value: number|string|undefined, sheetName?: string}) => void;
    getValue: (params: {row: number, col: number, sheetName?: string}) => (number | string);
    getColumnLabel: (params: {col: number, sheetName?: string}) => string;
    setCellLocked: (params: {row: number, col: number, locked: boolean, sheetName?: string}) => void;
    setCellsLocked: (params: {row: number, col: number, rowCount: number, colCount: number, locked: boolean, sheetName?: string}) => void;
    recalculate: () => void;
    updateCustomCellType: (params: { row: number, col: number, cellType: CustomCellType, sheetName?: string, customCellInfo?: ICustomCellInfo }) => void;
    applyCustomCellType: (params: { row: number; col: number; sheetName?: string; cellType: CustomCellType, customCellInfo?: ICustomCellInfo }) => void;
    removeCustomCellType: (params: { row: number; col: number; sheetName?: string }) => void;
    getCell?: (params: { row: number; col: number; sheetName?: string, value?: string }) => GC.Spread.Sheets.CellRange | undefined;
    toJSON?: () => string;
    setCellSelection: (params: {row: number, col: number, editMode: boolean}) => void;
    setSingleRowColorAdvisor: (advisor: SingleRowSelectionColorAdvisor) => void;
    startCollectingCellUpdates: () => void;
    setRowCount: (params: { rows: number, sheetName?: string }) => void;
    setColumnCount: (params: { cols: number, sheetName?: string }) => void;
    setColumnWidth: (params: { col: number, width:number }) => void;
    setRowHeight: (params: { row: number, height:number }) => void;
    setStyle: (params: { row: number, col:number, style:GC.Spread.Sheets.Style }) => void;
    addSpan: (params: { row: number, col:number, rowCount:number, colCount:number }) => void;
    createOutlineColumn: (params: { columnIdx: number, sheetName?: string }) => void;
    showRowOutline: (params: { show: boolean, sheetName?: string }) => void;
    frozenRowCount: (params: { rowCount: number, sheetName?: string }) => void;
    frozenColumnCount: (params: { colCount: number, sheetName?: string }) => void;
    setFrozenLineColor: (params: { color: string, sheetName?: string }) => void;
    setRowVisible: (params: { rowIdx: number, isVisible:boolean, sheetName?: string }) => void;
    setColumnVisible: (params: { colIdx: number, isVisible:boolean, sheetName?: string }) => void;
    getColumnCount: (params: { sheetName?: string }) => number|undefined;
    getRowCount: (params: { sheetName?: string }) => number|undefined;
    setBorder: (params: {row:number, col:number, border:GC.Spread.Sheets.LineBorder, options:GC.Spread.Sheets.ISetBorderOptions, rowCount?:number, colCount?:number, sheetName?: string}) => void;
    setRowOutlineGroups: (params: {firstRow:number, rowCount:number, sheetName?: string}) => void;
    setOutlineExpand: (params: { level: number, isExpanded: boolean, sheetName?: string }) => void;
    setOutlineGroupExpand: (params: {groupRow: number, isExpanded:boolean, level: number, sheetName?: string}) => void;
    getOutlineMaxLevel: (params: { sheetName?: string }) => number|undefined;
    suspendPaint: () => void;
    resumePaint: () => void;
    setFormat: (params: { row: number, col: number, format: string, rowCount?: number, colCount?: number, sheetName?: string }) => void;
    addRows:(params:{row:number, count:number, sheetName?:string}) => void;
    getSheetIndex: (name:string) => number;
    setActiveSheetIndex: (index:number) => void;
    getActiveSheetIndex: () => number;
    updateSpreadsheetViewPort: (params: { startRow: number, startCol: number, rows: number, cols: number, sheetName?: string }) => void;
    setCellBgImageBase64:(params:{row:number, col:number, b64Image:string, sheetName?:string}) => void;
    directAccess: (cb: (spread: GC.Spread.Sheets.Workbook) => void) => void;
    getSpread: () => GC.Spread.Sheets.Workbook|undefined,
    addCellStateRange: (params:{row: number, col: number, rows: number, cols: number, state:string, style:GC.Spread.Sheets.Style, sheetName?: string}) => void;
    clearSelection: () => void;
    lockAllCells: (params?: {sheetName?: string}) => void;
}

//TODO: Add string as a possible type for value. This is a build-time check, but at runtime it may be a string.
export interface ChangedCell {
    row: number;
    col: number;
    value: number | null;
    sheetName: string;
    sheetArea?: GC.Spread.Sheets.SheetArea;
    propertyName?: string,
    oldValue?:any,
    isUndo?: boolean;
}

export interface NewSpreadsheetAPIResult {
    handlers: NewSpreadsheetHandlers;
    api: NewSpreadsheetAPI;
    isSpreadsheetReady: boolean;
    cellsChanged: ChangedCell[];
    cellHovered: CellHitInfo | undefined;
    cellContentHovered: CellHitInfo | undefined;
    cellClicked: CellHitInfo | undefined;
    cellContentClicked: CellHitInfo | undefined;
    cellTargetClicked: CellHitInfo | undefined;
    selectionIsChanging: CellCoords | undefined;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface SpreadsheetHandle extends NewSpreadsheetAPI{
}
