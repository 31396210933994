import GC from "@grapecity/spread-sheets";
import {NewSpreadsheetAPI} from "../../spreadsheet/NewSpreadsheetTypes";
import {
    RenovationPackageDrawerStyles
} from "./RenovationPackageDrawerStyles";
import {RenovationPackageDrawerConfigs} from "./RenovationPackageDrawerConfig";
import {GetRenovationCostCategoriesQuery} from "../../../__generated__/generated_types";
import {
    AddRenovationSetupDrawerRowColMapEntryAction,
    RenovationSetupDrawerRowColMapAction,
    RenovationSetupDrawerRowColMapEntry, RenovationSetupDrawerSheetRowColMapActionTypes
} from "./RenovationSetupDrawerSheetState";
import {LocalRenovationPackage, RenovationSetupDrawerSheetEntityType} from "./types";
import { colCode } from "../../../utils/excel";
import { ToggleIconCell } from "../../../components/spreadsheet/spreadjs-custom-cell-types/ToggleIconCell";
import * as Icons from "../../spreadsheet/spreadjs-custom-cell-types/icons/IconResources";
export const initializeSheet = (
    ssapi: NewSpreadsheetAPI,
    renovationCostCategories: GetRenovationCostCategoriesQuery | undefined,
    renovationPackages: LocalRenovationPackage[],
    dispatch: React.Dispatch<RenovationSetupDrawerRowColMapAction>,
    simplifiedRevenueMode: boolean
): void => {
    const costCategoriesCount = renovationCostCategories?.queryRenovationCostCategories.length || 10;
    const totalRowsCount = RenovationPackageDrawerConfigs.FIRST_COST_CATEGORY_VALUE_ROW_INDEX +
        costCategoriesCount + 1 /*total_row*/ + 1 /*premium_row*/;
    /*const height = RenovationPackageDrawerStyles.HEADER_ROW_HEIGHT +
        RenovationPackageDrawerStyles.SUB_HEADER_ROW_HEIGHT +
        costCategoriesCount * RenovationPackageDrawerStyles.DATA_ROW_HEIGHT +
        RenovationPackageDrawerStyles.TOTAL_LABEL_ROW_HEIGHT +
        RenovationPackageDrawerStyles.PREMIUM_LABEL_ROW_HEIGHT;*/
    const packagesCount = renovationPackages?.length || 1;
    const totalColumnsCount = RenovationPackageDrawerConfigs.FIRST_PACKAGE_NAME_VALUE_COL_INDEX + (packagesCount * 2) + 4; // Some buffer for 2 more packages and scrolling
    ssapi.directAccess(spread => {
        const sheet = spread.getActiveSheet();
        sheet.options.gridline = {
            showHorizontalGridline: false,
            showVerticalGridline: false
        };
        sheet.options.rowHeaderVisible = false;
        sheet.options.colHeaderVisible = false;
        spread.options.showVerticalScrollbar = true;
        spread.options.showHorizontalScrollbar = true;
        spread.options.allowUserZoom = false;
        spread.options.tabStripVisible = false;
        sheet.options.isProtected = true;
        sheet.options.frozenlineColor = RenovationPackageDrawerStyles.CELL_BORDER_COLOR;
        // ssapi.frozenRowCount({ rowCount: 2 });
        ssapi.frozenColumnCount({ colCount: 2 });

        ssapi.clearSelection();

        ssapi.suspend();
        ssapi.setRowCount({
            rows: totalRowsCount
        });
        ssapi.setColumnCount({
            cols: totalColumnsCount
        });
        // spread.getHost().style.height = (height + 50).toString() + "px";  // Some buffer for scrolling
        initializeCategoriesColumn(ssapi, renovationCostCategories, simplifiedRevenueMode);
        const anyVisiblePackage = renovationPackages.some(renovationPackage => !renovationPackage.markedForDeletion);
        // console.info(`in initializeSheet, renovationPackages: ${JSON.stringify(renovationPackages)}, anyVisiblePackage: ${anyVisiblePackage}`);
        initializePackageData(ssapi, sheet, renovationCostCategories, renovationPackages, dispatch, simplifiedRevenueMode);
        if (renovationPackages.length === 0 || !anyVisiblePackage) {
            // console.info("Going to initialize the dummy packages");
            initializeDummyPackageData(ssapi, renovationCostCategories, renovationPackages, simplifiedRevenueMode);
        }
        ssapi.directAccess( spread => {
            const sheet = spread.getActiveSheet();
            let row = RenovationPackageDrawerConfigs.FIRST_COST_CATEGORY_VALUE_ROW_INDEX;
            while(++row < costCategoriesCount + RenovationPackageDrawerConfigs.FIRST_COST_CATEGORY_VALUE_ROW_INDEX){
                sheet.autoFitRow(row);
                if(sheet.getRowHeight(row) < RenovationPackageDrawerStyles.DATA_ROW_HEIGHT){
                    sheet.setRowHeight(row, RenovationPackageDrawerStyles.DATA_ROW_HEIGHT);
                }
            }
        });
        spread.refresh();
        ssapi.resume();
    });
};

function initializeCategoriesColumn(
    ssapi: NewSpreadsheetAPI,
    renovationCostCategories: GetRenovationCostCategoriesQuery | undefined,
    simplifiedRevenueMode: boolean
) {
    ssapi.setColumnWidth({
        col: RenovationPackageDrawerConfigs.COST_CATEGORY_LABEL_COL_INDEX,
        width: RenovationPackageDrawerStyles.COST_CATEGORY_COL_WIDTH
    });
    ssapi.setColumnWidth({
        col: RenovationPackageDrawerConfigs.GL_LABEL_COL_INDEX,
        width: RenovationPackageDrawerStyles.GL_COL_WIDTH
    });
    ssapi.setRowHeight({
        row: RenovationPackageDrawerConfigs.RENOVATION_PACKAGE_LABEL_ROW_INDEX,
        height: RenovationPackageDrawerStyles.HEADER_ROW_HEIGHT
    });
    ssapi.addSpan({
        row: RenovationPackageDrawerConfigs.RENOVATION_PACKAGE_LABEL_ROW_INDEX,
        col: RenovationPackageDrawerConfigs.RENOVATION_PACKAGE_LABEL_COL_INDEX,
        rowCount: 1,
        colCount: 2
    });
    ssapi.setValue({
        row: RenovationPackageDrawerConfigs.RENOVATION_PACKAGE_LABEL_ROW_INDEX,
        col: RenovationPackageDrawerConfigs.RENOVATION_PACKAGE_LABEL_COL_INDEX,
        value: RenovationPackageDrawerConfigs.RENOVATION_PACKAGE_LABEL
    });
    ssapi.setStyle({
        row: RenovationPackageDrawerConfigs.RENOVATION_PACKAGE_LABEL_ROW_INDEX,
        col: RenovationPackageDrawerConfigs.RENOVATION_PACKAGE_LABEL_COL_INDEX,
        style: RenovationPackageDrawerStyles.RENOVATION_PACKAGE_LABEL_CELL_STYLE
    });
    ssapi.setRowHeight({
        row: RenovationPackageDrawerConfigs.COST_CATEGORY_LABEL_ROW_INDEX,
        height: RenovationPackageDrawerStyles.SUB_HEADER_ROW_HEIGHT
    });
    ssapi.setValue({
        row: RenovationPackageDrawerConfigs.COST_CATEGORY_LABEL_ROW_INDEX,
        col: RenovationPackageDrawerConfigs.COST_CATEGORY_LABEL_COL_INDEX,
        value: RenovationPackageDrawerConfigs.COST_CATEGORY_LABEL
    });
    ssapi.setStyle({
        row: RenovationPackageDrawerConfigs.COST_CATEGORY_LABEL_ROW_INDEX,
        col: RenovationPackageDrawerConfigs.COST_CATEGORY_LABEL_COL_INDEX,
        style: RenovationPackageDrawerStyles.COST_CATEGORY_LABEL_CELL_STYLE
    });
    ssapi.setValue({
        row: RenovationPackageDrawerConfigs.GL_LABEL_ROW_INDEX,
        col: RenovationPackageDrawerConfigs.GL_LABEL_COL_INDEX,
        value: RenovationPackageDrawerConfigs.GL_LABEL
    });
    ssapi.setStyle({
        row: RenovationPackageDrawerConfigs.GL_LABEL_ROW_INDEX,
        col: RenovationPackageDrawerConfigs.GL_LABEL_COL_INDEX,
        style: RenovationPackageDrawerStyles.GL_LABEL_CELL_STYLE
    });
    let currentRowIndex = RenovationPackageDrawerConfigs.FIRST_COST_CATEGORY_VALUE_ROW_INDEX;
    renovationCostCategories?.queryRenovationCostCategories.forEach(categoryMeta => {
        ssapi.setRowHeight({row: currentRowIndex, height: RenovationPackageDrawerStyles.DATA_ROW_HEIGHT});
        ssapi.setStyle({
            row: currentRowIndex,
            col: RenovationPackageDrawerConfigs.COST_CATEGORY_LABEL_COL_INDEX,
            style: RenovationPackageDrawerStyles.COST_CATEGORY_VALUE_CELL_STYLE
        });
        ssapi.setStyle({
            row: currentRowIndex,
            col: RenovationPackageDrawerConfigs.GL_LABEL_COL_INDEX,
            style: RenovationPackageDrawerStyles.GL_VALUE_CELL_STYLE
        });
        ssapi.setValue({
            row: currentRowIndex,
            col: RenovationPackageDrawerConfigs.COST_CATEGORY_LABEL_COL_INDEX,
            value: categoryMeta.name
        });
        ssapi.setValue({
            row: currentRowIndex,
            col: RenovationPackageDrawerConfigs.GL_LABEL_COL_INDEX,
            value: categoryMeta.account.glName
        });
        currentRowIndex++;
    });
    ssapi.setRowHeight({
        row: currentRowIndex,
        height: RenovationPackageDrawerStyles.TOTAL_LABEL_ROW_HEIGHT
    });
    ssapi.addSpan({
        row: currentRowIndex,
        col: RenovationPackageDrawerConfigs.TOTAL_LABEL_COL_INDEX,
        rowCount: 1,
        colCount: 2
    });
    ssapi.setValue({
        row: currentRowIndex,
        col: RenovationPackageDrawerConfigs.TOTAL_LABEL_COL_INDEX,
        value: RenovationPackageDrawerConfigs.TOTAL_LABEL
    });
    ssapi.setStyle({
        row: currentRowIndex,
        col: RenovationPackageDrawerConfigs.TOTAL_LABEL_COL_INDEX,
        style: RenovationPackageDrawerStyles.TOTAL_LABEL_CELL_STYLE
    });
    if (!simplifiedRevenueMode) {
        currentRowIndex++;
        ssapi.setRowHeight({
            row: currentRowIndex,
            height: RenovationPackageDrawerStyles.PREMIUM_LABEL_ROW_HEIGHT
        });
        ssapi.addSpan({
            row: currentRowIndex,
            col: RenovationPackageDrawerConfigs.PREMIUM_LABEL_COL_INDEX,
            rowCount: 1,
            colCount: 2
        });
        ssapi.setValue({
            row: currentRowIndex,
            col: RenovationPackageDrawerConfigs.PREMIUM_LABEL_COL_INDEX,
            value: RenovationPackageDrawerConfigs.PREMIUM_LABEL
        });
        ssapi.setStyle({
            row: currentRowIndex,
            col: RenovationPackageDrawerConfigs.PREMIUM_LABEL_COL_INDEX,
            style: RenovationPackageDrawerStyles.PREMIUM_LABEL_CELL_STYLE
        });
        ssapi.setStyle({
            row: currentRowIndex,
            col: RenovationPackageDrawerConfigs.PREMIUM_LABEL_COL_INDEX + 1,
            style: RenovationPackageDrawerStyles.PREMIUM_LABEL_CELL_STYLE
        });
    }
}

function initializeDummyPackageData(
    ssapi: NewSpreadsheetAPI,
    renovationCostCategories: GetRenovationCostCategoriesQuery | undefined,
    renovationPackages: LocalRenovationPackage[],
    simplifiedRevenueMode: boolean
) {
    const offsetColumns = renovationPackages.length * 2;
    // console.info("in initializeDummyPackageData", offsetColumns);
    ssapi.addSpan({
        row: RenovationPackageDrawerConfigs.PACKAGE_NAME_VALUE_ROW_INDEX,
        col: RenovationPackageDrawerConfigs.FIRST_PACKAGE_NAME_VALUE_COL_INDEX + offsetColumns,
        rowCount: 1,
        colCount: 2
    });
    ssapi.setColumnWidth({
        col: RenovationPackageDrawerConfigs.FIRST_AMOUNT_VALUE_COL_INDEX + offsetColumns,
        width: RenovationPackageDrawerStyles.AMOUNT_COL_WIDTH
    });
    ssapi.setColumnWidth({
        col: RenovationPackageDrawerConfigs.FIRST_DELAY_RECOGNITION_VALUE_COL_INDEX + offsetColumns,
        width: RenovationPackageDrawerStyles.DELAY_RECOGNITION_COL_WIDTH
    });
    ssapi.setStyle({
        row: RenovationPackageDrawerConfigs.PACKAGE_NAME_VALUE_ROW_INDEX,
        col: RenovationPackageDrawerConfigs.FIRST_PACKAGE_NAME_VALUE_COL_INDEX + offsetColumns,
        style: RenovationPackageDrawerStyles.PACKAGE_NAME_VALUE_DUMMY_CELL_STYLE
    });
    ssapi.setValue({
        row: RenovationPackageDrawerConfigs.PACKAGE_NAME_VALUE_ROW_INDEX,
        col: RenovationPackageDrawerConfigs.FIRST_PACKAGE_NAME_VALUE_COL_INDEX + offsetColumns,
        value: RenovationPackageDrawerConfigs.BLANK_CELL_VALUE
    });
    ssapi.setStyle({
        row: RenovationPackageDrawerConfigs.PACKAGE_NAME_VALUE_ROW_INDEX,
        col: RenovationPackageDrawerConfigs.FIRST_PACKAGE_NAME_VALUE_COL_INDEX + 1 + offsetColumns,
        style: RenovationPackageDrawerStyles.PACKAGE_REMOVE_ACTION_DUMMY_CELL_STYLE
    });
    ssapi.setValue({
        row: RenovationPackageDrawerConfigs.PACKAGE_NAME_VALUE_ROW_INDEX,
        col: RenovationPackageDrawerConfigs.FIRST_PACKAGE_NAME_VALUE_COL_INDEX + 1 + offsetColumns,
        value: RenovationPackageDrawerConfigs.BLANK_CELL_VALUE
    });
    ssapi.setStyle({
        row: RenovationPackageDrawerConfigs.AMOUNT_LABEL_ROW_INDEX,
        col: RenovationPackageDrawerConfigs.FIRST_AMOUNT_VALUE_COL_INDEX + offsetColumns,
        style: RenovationPackageDrawerStyles.AMOUNT_LABEL_DUMMY_CELL_STYLE
    });
    ssapi.setValue({
        row: RenovationPackageDrawerConfigs.AMOUNT_LABEL_ROW_INDEX,
        col: RenovationPackageDrawerConfigs.FIRST_AMOUNT_VALUE_COL_INDEX + offsetColumns,
        value: RenovationPackageDrawerConfigs.BLANK_CELL_VALUE
    });
    ssapi.setStyle({
        row: RenovationPackageDrawerConfigs.DELAY_RECOGNITION_LABEL_ROW_INDEX,
        col: RenovationPackageDrawerConfigs.FIRST_DELAY_RECOGNITION_VALUE_COL_INDEX + offsetColumns,
        style: RenovationPackageDrawerStyles.DELAY_RECOGNITION_LABEL_DUMMY_CELL_STYLE
    });
    ssapi.setValue({
        row: RenovationPackageDrawerConfigs.DELAY_RECOGNITION_LABEL_ROW_INDEX,
        col: RenovationPackageDrawerConfigs.FIRST_DELAY_RECOGNITION_VALUE_COL_INDEX + offsetColumns,
        value: RenovationPackageDrawerConfigs.BLANK_CELL_VALUE
    });
    let currentRowIndex = RenovationPackageDrawerConfigs.FIRST_COST_CATEGORY_VALUE_ROW_INDEX;
    renovationCostCategories?.queryRenovationCostCategories.forEach(_ => {
        ssapi.setStyle({
            row: currentRowIndex,
            col: RenovationPackageDrawerConfigs.FIRST_AMOUNT_VALUE_COL_INDEX + offsetColumns,
            style: RenovationPackageDrawerStyles.AMOUNT_VALUE_DUMMY_CELL_STYLE
        });
        ssapi.setValue({
            row: currentRowIndex,
            col: RenovationPackageDrawerConfigs.FIRST_AMOUNT_VALUE_COL_INDEX + offsetColumns,
            value: RenovationPackageDrawerConfigs.BLANK_CELL_VALUE
        });
        ssapi.setStyle({
            row: currentRowIndex,
            col: RenovationPackageDrawerConfigs.FIRST_DELAY_RECOGNITION_VALUE_COL_INDEX + offsetColumns,
            style: RenovationPackageDrawerStyles.DELAY_RECOGNITION_VALUE_DUMMY_CELL_STYLE
        });
        ssapi.setValue({
            row: currentRowIndex,
            col: RenovationPackageDrawerConfigs.FIRST_DELAY_RECOGNITION_VALUE_COL_INDEX + offsetColumns,
            value: RenovationPackageDrawerConfigs.BLANK_CELL_VALUE
        });
        currentRowIndex++;
    });
    ssapi.setStyle({
        row: currentRowIndex,
        col: RenovationPackageDrawerConfigs.FIRST_AMOUNT_VALUE_COL_INDEX + offsetColumns,
        style: RenovationPackageDrawerStyles.TOTAL_AMOUNT_VALUE_DUMMY_CELL_STYLE
    });
    ssapi.setValue({
        row: currentRowIndex,
        col: RenovationPackageDrawerConfigs.FIRST_AMOUNT_VALUE_COL_INDEX + offsetColumns,
        value: RenovationPackageDrawerConfigs.BLANK_CELL_VALUE
    });
    ssapi.setStyle({
        row: currentRowIndex,
        col: RenovationPackageDrawerConfigs.FIRST_DELAY_RECOGNITION_VALUE_COL_INDEX + offsetColumns,
        style: RenovationPackageDrawerStyles.TOTAL_DELAY_RECOGNITION_VALUE_DUMMY_CELL_STYLE
    });
    ssapi.setValue({
        row: currentRowIndex,
        col: RenovationPackageDrawerConfigs.FIRST_DELAY_RECOGNITION_VALUE_COL_INDEX + offsetColumns,
        value: RenovationPackageDrawerConfigs.BLANK_CELL_VALUE
    });
    if (!simplifiedRevenueMode) {
        currentRowIndex++;
        ssapi.setStyle({
            row: currentRowIndex,
            col: RenovationPackageDrawerConfigs.FIRST_AMOUNT_VALUE_COL_INDEX + offsetColumns,
            style: RenovationPackageDrawerStyles.PREMIUM_VALUE_DUMMY_CELL_STYLE
        });
        ssapi.setValue({
            row: currentRowIndex,
            col: RenovationPackageDrawerConfigs.FIRST_AMOUNT_VALUE_COL_INDEX + offsetColumns,
            value: RenovationPackageDrawerConfigs.BLANK_CELL_VALUE
        });
        ssapi.setStyle({
            row: currentRowIndex,
            col: RenovationPackageDrawerConfigs.FIRST_DELAY_RECOGNITION_VALUE_COL_INDEX + offsetColumns,
            style: RenovationPackageDrawerStyles.PREMIUM_DELAY_RECOGNITION_VALUE_DUMMY_CELL_STYLE
        });
        ssapi.setValue({
            row: currentRowIndex,
            col: RenovationPackageDrawerConfigs.FIRST_DELAY_RECOGNITION_VALUE_COL_INDEX + offsetColumns,
            value: RenovationPackageDrawerConfigs.BLANK_CELL_VALUE
        });
    }
}

function initializePackageData(
    ssapi: NewSpreadsheetAPI,
    sheet: GC.Spread.Sheets.Worksheet,
    renovationCostCategories: GetRenovationCostCategoriesQuery | undefined,
    renovationPackages: LocalRenovationPackage[],
    dispatch: React.Dispatch<RenovationSetupDrawerRowColMapAction>,
    simplifiedRevenueMode: boolean
) {
    if(renovationPackages.length === 0) {
        return;
    }

    let currentAmountColIndex = RenovationPackageDrawerConfigs.FIRST_AMOUNT_VALUE_COL_INDEX;
    let currentDelayRecognitionColIndex = RenovationPackageDrawerConfigs.FIRST_DELAY_RECOGNITION_VALUE_COL_INDEX;
    const entriesInRenovationSetupDrawerSheetState: RenovationSetupDrawerRowColMapEntry[] = [];
    renovationPackages.forEach(renovationPackage => {
        if(renovationPackage.markedForDeletion) {
            ssapi.setColumnVisible({
                colIdx: currentAmountColIndex,
                isVisible: false
            });
            ssapi.setColumnVisible({
                colIdx: currentDelayRecognitionColIndex,
                isVisible: false
            });
        }

        // Package Name Cell
        ssapi.addSpan({
            row: RenovationPackageDrawerConfigs.PACKAGE_NAME_VALUE_ROW_INDEX,
            col: currentAmountColIndex,
            rowCount: 1,
            colCount: 2
        });
        ssapi.setColumnWidth({
            col: currentAmountColIndex,
            width: RenovationPackageDrawerStyles.AMOUNT_COL_WIDTH
        });

        // Set Package label to a custom cell w/ an X icon
        sheet.getCell(
            RenovationPackageDrawerConfigs.PACKAGE_NAME_VALUE_ROW_INDEX,
            currentAmountColIndex,
        ).cellType(
            new ToggleIconCell({
                style: RenovationPackageDrawerStyles.PACKAGE_NAME_VALUE_CELL_STYLE,
                showIcon: true,
                toggleIconSpecs: {
                    isOpen: false,
                    openIconImg: Icons.simpleX,
                    openIconSpecs: {
                        x: 256,
                        y: 18,
                        width: 12,
                        height: 12,
                    },
                    openIconHoverImg: Icons.simpleX,
                    openIconHoverSpecs: {
                        x: 256,
                        y: 18,
                        width: 12,
                        height: 12,
                    },
                    closedIconImg: Icons.simpleX,
                    closedIconSpecs: {
                        x: 256,
                        y: 18,
                        width: 12,
                        height: 12,
                    },
                    closedIconHoverImg: Icons.simpleX,
                    closedIconHoverSpecs: {
                        x: 256,
                        y: 18,
                        width: 12,
                        height: 12,
                    },
                },
                hotspotSpecs:{
                    x: 252,
                    y: 14,
                    width: 20,
                    height: 20,
                }
            })
        );
        sheet.getCell(
            RenovationPackageDrawerConfigs.PACKAGE_NAME_VALUE_ROW_INDEX,
            currentAmountColIndex,
        ).locked(false);

        ssapi.setColumnWidth({
            col: currentDelayRecognitionColIndex,
            width: RenovationPackageDrawerStyles.DELAY_RECOGNITION_COL_WIDTH
        });
        ssapi.setValue({
            row: RenovationPackageDrawerConfigs.PACKAGE_NAME_VALUE_ROW_INDEX,
            col: currentAmountColIndex,
            value: renovationPackage.name
        });
        entriesInRenovationSetupDrawerSheetState.push({
            idx: `${RenovationPackageDrawerConfigs.PACKAGE_NAME_VALUE_ROW_INDEX}#${currentAmountColIndex}`,
            packageId: renovationPackage.id,
            categoryId: RenovationSetupDrawerSheetEntityType.CATEGORY_NA,
            value: renovationPackage.name || RenovationPackageDrawerConfigs.DEFAULT_AMOUNT,
            entityType: RenovationSetupDrawerSheetEntityType.PACKAGE_NAME,
        });
        ssapi.setStyle({
            row: RenovationPackageDrawerConfigs.PACKAGE_NAME_VALUE_ROW_INDEX,
            col: currentDelayRecognitionColIndex,
            style: RenovationPackageDrawerStyles.PACKAGE_REMOVE_ACTION_CELL_STYLE
        });
        ssapi.setStyle({
            row: RenovationPackageDrawerConfigs.AMOUNT_LABEL_ROW_INDEX,
            col: currentAmountColIndex,
            style: RenovationPackageDrawerStyles.AMOUNT_LABEL_CELL_STYLE
        });
        ssapi.setStyle({
            row: RenovationPackageDrawerConfigs.DELAY_RECOGNITION_LABEL_ROW_INDEX,
            col: currentDelayRecognitionColIndex,
            style: RenovationPackageDrawerStyles.DELAY_RECOGNITION_LABEL_CELL_STYLE
        });
        ssapi.setValue({
            row: RenovationPackageDrawerConfigs.AMOUNT_LABEL_ROW_INDEX,
            col: currentAmountColIndex,
            value: RenovationPackageDrawerConfigs.AMOUNT_LABEL
        });
        ssapi.setValue({
            row: RenovationPackageDrawerConfigs.DELAY_RECOGNITION_LABEL_ROW_INDEX,
            col: currentDelayRecognitionColIndex,
            value: RenovationPackageDrawerConfigs.DELAY_RECOGNITION_LABEL
        });
        let currentRowIndex = RenovationPackageDrawerConfigs.FIRST_COST_CATEGORY_VALUE_ROW_INDEX;
        renovationCostCategories?.queryRenovationCostCategories.forEach(category => {
            const costCategory = renovationPackage.costCategoryMappings.find(costCategoryMapping => {
                return costCategoryMapping.costCategoryId === category.id;
            });
            ssapi.setStyle({
                row: currentRowIndex,
                col: currentAmountColIndex,
                style: RenovationPackageDrawerStyles.AMOUNT_VALUE_CELL_STYLE
            });
            ssapi.setValue({
                row: currentRowIndex,
                col: currentAmountColIndex,
                value: costCategory?.amount || RenovationPackageDrawerConfigs.DEFAULT_AMOUNT
            });
            const currentColCode = colCode(currentDelayRecognitionColIndex);
            const currentDelayRecognitionCellStyle = RenovationPackageDrawerStyles.DELAY_RECOGNITION_VALUE_CELL_STYLE.clone();
            const currentCellFormatter = `{{=${currentColCode}${currentRowIndex+1}}}    month(s)`;
            currentDelayRecognitionCellStyle.formatter = currentCellFormatter;
            const delayRecognition = costCategory?.delayRecognition == undefined ? 
                RenovationPackageDrawerConfigs.DEFAULT_DELAY_RECOGNITION 
                : costCategory.delayRecognition;
            ssapi.setStyle({
                row: currentRowIndex,
                col: currentDelayRecognitionColIndex,
                style: currentDelayRecognitionCellStyle
            });
            ssapi.setValue({
                row: currentRowIndex,
                col: currentDelayRecognitionColIndex,
                value: delayRecognition
            });
            entriesInRenovationSetupDrawerSheetState.push({
                idx: `${currentRowIndex}#${currentAmountColIndex}`,
                packageId: renovationPackage.id,
                categoryId: category.id,
                value: costCategory?.amount || RenovationPackageDrawerConfigs.DEFAULT_AMOUNT,
                entityType: RenovationSetupDrawerSheetEntityType.AMOUNT,
            });
            entriesInRenovationSetupDrawerSheetState.push({
                idx: `${currentRowIndex}#${currentDelayRecognitionColIndex}`,
                packageId: renovationPackage.id,
                categoryId: category.id,
                value: delayRecognition,
                entityType: RenovationSetupDrawerSheetEntityType.DELAY_RECOGNITION,
            });
            currentRowIndex++;
        });
        let currentColCode = colCode(currentAmountColIndex);
        sheet.setFormula(
            currentRowIndex,
            currentAmountColIndex,
            `=SUM(${currentColCode}${RenovationPackageDrawerConfigs.FIRST_COST_CATEGORY_VALUE_ROW_INDEX+1}:${currentColCode}${currentRowIndex})`
        );
        ssapi.setStyle({
            row: currentRowIndex,
            col: currentAmountColIndex,
            style: RenovationPackageDrawerStyles.TOTAL_AMOUNT_VALUE_CELL_STYLE
        });
        ssapi.setStyle({
            row: currentRowIndex,
            col: currentDelayRecognitionColIndex,
            style: RenovationPackageDrawerStyles.TOTAL_DELAY_RECOGNITION_VALUE_CELL_STYLE
        });
        if (!simplifiedRevenueMode) {
            currentRowIndex++;
            ssapi.setStyle({
                row: currentRowIndex,
                col: currentAmountColIndex,
                style: RenovationPackageDrawerStyles.PREMIUM_VALUE_CELL_STYLE
            });
            ssapi.setValue({
                row: currentRowIndex,
                col: currentAmountColIndex,
                value: renovationPackage.premium
            });
            entriesInRenovationSetupDrawerSheetState.push({
                idx: `${currentRowIndex}#${currentAmountColIndex}`,
                packageId: renovationPackage.id,
                categoryId: RenovationSetupDrawerSheetEntityType.CATEGORY_NA,
                value: renovationPackage.premium,
                entityType: RenovationSetupDrawerSheetEntityType.PREMIUM,
            });
            currentColCode = colCode(currentDelayRecognitionColIndex);
            const currentDelayRecognitionCellStyle = RenovationPackageDrawerStyles.PREMIUM_DELAY_RECOGNITION_VALUE_CELL_STYLE.clone();
            const currentCellFormatter = `{{=${currentColCode}${currentRowIndex+1}}}    month(s)`;
            currentDelayRecognitionCellStyle.formatter = currentCellFormatter;
            ssapi.setStyle({
                row: currentRowIndex,
                col: currentDelayRecognitionColIndex,
                style: currentDelayRecognitionCellStyle
            });
            ssapi.setValue({
                row: currentRowIndex,
                col: currentDelayRecognitionColIndex,
                value: renovationPackage.premiumDelayRecognition
            });
            entriesInRenovationSetupDrawerSheetState.push({
                idx: `${currentRowIndex}#${currentDelayRecognitionColIndex}`,
                packageId: renovationPackage.id,
                categoryId: RenovationSetupDrawerSheetEntityType.CATEGORY_NA,
                value: renovationPackage.premiumDelayRecognition,
                entityType: RenovationSetupDrawerSheetEntityType.PREMIUM_DELAY_RECOGNITION,
            });
        }
        currentAmountColIndex = currentAmountColIndex + 2;
        currentDelayRecognitionColIndex = currentDelayRecognitionColIndex + 2;
    });
    dispatch({
        kind: RenovationSetupDrawerSheetRowColMapActionTypes.AddRenovationSetupDrawerRowColMapEntryAction,
        entries: entriesInRenovationSetupDrawerSheetState
    } as AddRenovationSetupDrawerRowColMapEntryAction);
}
