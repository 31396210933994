import {createContext, ReactElement, ReactNode, useContext, useEffect, useState} from 'react';
import {BudgetingType} from '../../../BudgetingType';

export type IAccountSummaryDataYears = {
    act: number[],
    fcst: number[],
    bdgt: number[],
}

export type IAccountSummaryData = {
    years: {
        [key: number]: IAccountSummaryDataYears,
    }
}

export type IAccountSummary = {
    budgetType: BudgetingType,
    isIncome: boolean | undefined,
    isReady: boolean,
    startReforecastMonthIndex: number,
    currentYear: number,
    data: IAccountSummaryData,
    setData: (data: IAccountSummaryData) => void,
    setIsIncome: (isIncome: boolean | undefined) => void,
    setBudgetType: (budgetType: BudgetingType) => void,
    setCurrentYear: (year: number) => void,
    setIsReady: (isReady: boolean) => void,
    setStartReforecastMonthIndex: (month: number) => void,
}

export type IAccountSummaryStateProps = {
    children: ReactNode[] | ReactNode
}

export const AccountSummaryContext = createContext<IAccountSummary | undefined>(undefined);

function AccountSummaryContextState(): IAccountSummary {
    const [isIncome, setIsIncome] = useState<boolean | undefined>(undefined);
    const [data, _setData] = useState<IAccountSummaryData>({years: {}});
    const [budgetType, setBudgetType] = useState<BudgetingType>(BudgetingType.REFORECAST);
    const [currentYear, setCurrentYear] = useState<number>(0);
    const [startReforecastMonthIndex, setStartReforecastMonthIndex] = useState<number>(0);
    const [isReady, setIsReady] = useState<boolean>(false);

    function setData(data: IAccountSummaryData): void {
        const blendedYearData: { [key: number]: IAccountSummaryDataYears } = {};

        for (const year in data.years) {
            const yearData = data.years[year];

            if (!yearData || parseInt(year) != currentYear) {
                continue;
            }

            blendedYearData[year] = {
                act: yearData.act.slice(0, startReforecastMonthIndex).concat(Array(12 - startReforecastMonthIndex).fill(0)),
                fcst: Array(startReforecastMonthIndex).fill(0).concat(yearData.fcst.slice(startReforecastMonthIndex, 12)),
                bdgt: yearData.bdgt,
            };
        }

        const cleanedData = Object.assign(data, {years: {...data.years, ...blendedYearData}});

        _setData(cleanedData);
    }

    useEffect(
        () => {
            if (
                isIncome == undefined || data == undefined) return;


            setIsReady(Object.keys(data.years).length > 0);
        },
        [isIncome, data]
    );

    return {
        budgetType,
        isIncome,
        isReady,
        startReforecastMonthIndex,
        currentYear,
        data,
        setIsIncome,
        setData,
        setBudgetType,
        setCurrentYear,
        setIsReady,
        setStartReforecastMonthIndex,
    };
}

export function AccountSummaryContextProvider(props: IAccountSummaryStateProps): ReactElement {
    return (
        <AccountSummaryContext.Provider value={{...AccountSummaryContextState()}}>
            {props.children}
        </AccountSummaryContext.Provider>
    );
}

export function useAccountSummaryContext(): IAccountSummary {
    const ctx = useContext(AccountSummaryContext);

    if (ctx === undefined) {
        throw new Error('useAccountSummaryContext must be used within a <AccountSummaryContextProvider>');
    }

    return ctx;
}