import { ReactElement, useEffect, useMemo, useRef, useState } from "react";
import CreatableSelect from "react-select/creatable";
import { MultiValue, components, ActionMeta } from "react-select";
import { TooltipModal } from "@zendeskgarden/react-modals";

import { COLORS } from "../../../../../../constants/Colors";
import CheckmarkIcon from "../../../../../../assets/icons/icon_checkmark.svg";
import SearchIcon from "../../../../../../assets/icons/icon_search.svg";
import { IFormulaBarUpdates } from "../../../../../../contexts/account/data/useFormulaBarUpdates";

import * as css from "./styles/css.module.scss";
import { getPopperModifiers, getTooltipPlacementProps } from "../../logic/formulaBarMenu";


export type TLineItemsOption = {
    label: string;
    value: string;
}

interface ILineItemsMenu {
    accountId: string;
    budgetYear: number;
    fbUpdates: IFormulaBarUpdates;
    triggerNode?: ReactElement;
    worksheetTypeAhead?: string[] | undefined;
    isBulkUpdateBar?: boolean,
    isPropertyDriversUI?: boolean,
}


export const LineItemsMenu = ({ fbUpdates, triggerNode, worksheetTypeAhead, isBulkUpdateBar, isPropertyDriversUI }: ILineItemsMenu): ReactElement => {

    const triggerRef = useRef<HTMLDivElement>(null);
    const [refElement, setRefElement] = useState<HTMLDivElement|null>(null);
    const [createdOptions, setCreatedOptions] = useState<string[]>([]);
    /**
     * Reconstruct `fbUpdates.options` and `fbUpdates.selectedOptions` when:
     *  1. typeaheadData changes
     *  2. pendingUpdates.add is reset i.e. any newly added line items are removed
     *     2.a. this is how this component knows if the 'Reset' button was clicked
     *     2.b. newly created items' presence indicates that they're selected, so they must be emptied
     *          whenever the formula bar is reset
    */
    useEffect(() => {
        const doesDirtyStateExist = (fbUpdates.pendingUpdates.add.worksheet === undefined || fbUpdates.pendingUpdates.add.worksheet.length === 0)
            && (fbUpdates.pendingUpdates.update.worksheet === undefined || fbUpdates.pendingUpdates.update.worksheet.length === 0);

        if (worksheetTypeAhead && doesDirtyStateExist) {
        fbUpdates.setOptions(worksheetTypeAhead.map(o => ({label: o, value: o.trim().toLowerCase()})) as MultiValue<TLineItemsOption>);
        const preSelectedOptions = fbUpdates.parsedFormDrivers.worksheet.map((item) => ({
            label: item.description,
            value: item.description.trim().toLowerCase(),
        })) || [];
        fbUpdates.setSelectedOptions(preSelectedOptions);
        }
    }, [worksheetTypeAhead, fbUpdates.pendingUpdates.add.worksheet]);

    const fxBarWorksheetDriverNames = fbUpdates.parsedFormDrivers.worksheet.map((driver) => driver.description) || [];

    const customStyles = useMemo(() => ({
        control: (base:any, _state:any) => ({
            ...base,
            boxShadow: "none",
            border: `1px solid #DDDDDD`,
            fontSize: "12px",
            fontWeight: 500,
            fontFamily: "Inter",
            margin: "0 12px",
            hover:{
                border: `1px solid ${ COLORS.PRIMARY_400 }`,
            }
        }),
        menu: (base:any, _state:any) => ({
            ...base,
            boxShadow: "none",
            font: `500 12px Inter`,
            position: "relative",
        }),
        container: (base:any, _state:any) => ({
            ...base,
            backgroundColor: "none",
            border: "none",
            boxShadow: "0 20px 30px 0 rgba(23,73,77,0.15)",
            padding: "12px 0",
            width: "301px",
        }),
        group: (base:any, _state:any) => ({
            ...base,
            backgroundColor: 'red',
        }),
        input: (base:any, _state:any) => ({
            ...base,
            display: "inline-flex",
            'input': {
                caretColor: "var(--grey-800)",
            },
            '&:after': {
                display: "none",
            },
        }),
        option: (base:any, _state:any) => ({
            ...base,
            backgroundColor: "#FFFFFF",
            color: "initial",
            display: "flex",
            fontSize: "14px",
            fontWeight: 400,
            gap: "8px",
            justifyContents: "space-between",
            '&:hover': {
                backgroundColor: "#F3F7F8",
            },
        }),
        valueContainer: (base:any, _state:any) => ({
            ...base,
            display: "flex",
            flexDirection: "row",
            gap: "8px",
        })
    }), []);

    const CustomOption = (optionProps: any) => {
        const { innerProps, innerRef } = optionProps;
        const isSelected = fxBarWorksheetDriverNames.includes(optionProps.data.label);

        return (
          <components.Option {...optionProps} innerProps={innerProps} innerRef={innerRef}>
            {/* Show a spacer div when a checkmark doesn't exist. */}
            {isSelected
                ? <img src={CheckmarkIcon} alt="check" />
                : <div className={css.emptyCheckmarkSpacer}></div>
            }
            {optionProps.data.label}
          </components.Option>
        );
    };

    const CustomValueContainer = (props: any) => {
        return (
            <components.ValueContainer {...props}>
                <div className={css.valContainerIcon}>
                    <img src={SearchIcon} alt="search" />
                </div>
                <div className={css.valContainerInputWrapper}>{props.children}</div>
            </components.ValueContainer>
        );
    };

    const handleChange = (values: TLineItemsOption[], meta: ActionMeta<any>) => {
        const updatedValue: string = meta.option.label;

        if (meta.action === "create-option") {
            setCreatedOptions([ ...createdOptions, meta.option.label]);
            const newOptions = [...(fbUpdates.options || []), meta.option]
                .sort((a: TLineItemsOption, b: TLineItemsOption) => {
                    if (a.label < b.label) {
                        return -1;
                    }
                    if (a.label > b.label) {
                        return 1;
                    }
                    return 0;
                });

            fbUpdates.setOptions(newOptions);
        }

        /**
         * If a newly created option was unselected, then `handleChange` receives empty `values`
         * We don't want to send empty values to `updateLineItems()` because:
         * `updatedValues` should always be - all line items currently applied to the formula bar
        */
        if (meta.action === "deselect-option" && createdOptions.includes(meta.option.label)) {
            const newOptions = fbUpdates.options?.filter((option) => option.label !== meta.option.label) || [];

            fbUpdates.setOptions(newOptions);
            fbUpdates.setSelectedOptions(fbUpdates.selectedOptions.filter((option) => option.label !== meta.option.label));

            fbUpdates.updateLineItems({
                action: meta.action,
                updatedValue,
                updatedValues: fbUpdates.selectedOptions.filter((option) => option.label !== meta.option.label),
            });
        } else {
            fbUpdates.setSelectedOptions(values);

            fbUpdates.updateLineItems({
                action: meta.action,
                updatedValue,
                updatedValues: values,
            });
        }
    };

    const handleClick = () => {
        setRefElement(triggerRef.current);
    };

    const handleClose = () => {
        setRefElement(null);
    };

    const customFilter = (option: TLineItemsOption, searchText: string) =>
        option.value.toLowerCase().includes(searchText.toLowerCase());

    const triggerNodeClassNames = refElement
        ? `${css.lineItemsTriggerNode} ${css.focusedNode}`
        : css.lineItemsTriggerNode;

    const TypeAheadElement = (
        <>
            <div className={css.lineItemsMenuTitle}>
                Line Items
            </div>
            <CreatableSelect
                autoFocus
                escapeClearsValue
                menuIsOpen
                isMulti
                backspaceRemovesValue={false}
                controlShouldRenderValue={false}
                hideSelectedOptions={false}
                isClearable={false}
                components={{ DropdownIndicator: null, Option: CustomOption, ValueContainer: CustomValueContainer }}
                filterOption={customFilter}
                onChange={(values:any, meta) => handleChange(values, meta)}
                options={fbUpdates.options}
                placeholder={""}
                styles={customStyles}
                value={fbUpdates.selectedOptions}
            />
        </>
    );

    const defaultPopperModifiers = [
        {
          name: "preventOverflow",
          options: {
            boundariesElement: "window",
          },
        },
    ];

    const renderLineItemsMenu = () => {
        if (triggerNode) {
            return (
                <>
                    <div
                        className={triggerNodeClassNames}
                        ref={triggerRef}
                        onClick={handleClick}
                    >
                        {triggerNode}
                    </div>
                    <TooltipModal
                        className={css.tooltipModal}
                        hasArrow={false}
                        referenceElement={refElement}
                        onClose={handleClose}
                        placement="bottom"
                        isAnimated
                        {...getTooltipPlacementProps(isPropertyDriversUI, isBulkUpdateBar)}
                        popperModifiers={getPopperModifiers(isPropertyDriversUI, isBulkUpdateBar, defaultPopperModifiers)}
                    >
                        <TooltipModal.Body className={css.tooltipModalBody}>
                            {TypeAheadElement}
                        </TooltipModal.Body>
                    </TooltipModal>
                </>
            );
        }
        return TypeAheadElement;
    };

    return renderLineItemsMenu();
};
