import {
  DistributionMethod,
  PercentGrowthBasePeriod,
  VersionType,
  UserRole
} from "../../../../../__generated__/generated_types";

export interface BaseToGrow {
  value: PercentGrowthBasePeriod;
  label: string;
}


export const stringToFixedFloat = (str: string, decimals: number, validatePercent: boolean): number => {
    let floatValue = parseFloat(parseFloat(str).toFixed(decimals));

    if (validatePercent) {
        floatValue = floatValue >= 0 && floatValue <= 100 ? floatValue : 0;
    }

    return floatValue;
};

export const distributionOptions = [
    { name: DistributionMethod.Flat, value: "even distribution" },
    { name: DistributionMethod.Seasonal, value: "seasonal distribution" },
];

export type ModelingMethodName = "account" | "operational" | "revenue" | "worksheet" | "renovations" | "payroll" | "percentGrowth" | "monthlyAverage" | "annualTargetValue" | "customDriver";

export interface AccountViewFxBarUserPermissionsMap {
    [UserRole.Admin]: ModelingMethodName[];
    [UserRole.Manager]: ModelingMethodName[];
}

export const accountViewFxBarUserPermissionsMap: AccountViewFxBarUserPermissionsMap = {
    [UserRole.Admin]: ["account", "operational", "revenue", "worksheet", "renovations", "payroll", "percentGrowth", "monthlyAverage", "annualTargetValue", "customDriver"],
    [UserRole.Manager]: ["worksheet", "percentGrowth", "monthlyAverage", "annualTargetValue"],
};

export const canAddDriversToEmptyFxBar: boolean = (() => {
    let canAdd = true;

    /**
     * If all user roles are allowed to edit at least 1 modeling method, then canAddDriversToEmptyFxBar = true
    */
    Object.values(accountViewFxBarUserPermissionsMap).forEach((editableModelingMethods) => {
        if (editableModelingMethods.length === 0) {
            canAdd = false;
        }
    });

    return canAdd;
})();

// Ref - https://www.notion.so/vizibly/Growth-Drivers-30142e24d53b4e3ab864088359bcab73?pvs=4#d6b44e4601e04dc883eea7591e2da804
export const getBaseToGrowOptions = (
  versionType: VersionType.Budget | VersionType.Reforecast,
  reforecastYear: number
): BaseToGrow[] => {
  if (versionType === VersionType.Budget) {
    return [
      {
        value: PercentGrowthBasePeriod.SameMonthLastYear,
        label: "Same month last year",
      },
      {
        value: PercentGrowthBasePeriod.BudgetSameMonthCurrentYear,
        label: `Same month ${reforecastYear} budget amount`,
      },
      {
        value: PercentGrowthBasePeriod.LastYearBudget,
        label: `${reforecastYear} Budget Total`,
      },
      {
        value: PercentGrowthBasePeriod.LastYearActuals,
        label: `${reforecastYear} Reforecast Total`,
      },
      {
        value: PercentGrowthBasePeriod.TwoYearsAgoActuals,
        label: `${reforecastYear - 1} Actuals Total`,
      },
    ];
  }

  return [
    {
      value: PercentGrowthBasePeriod.SameMonthLastYear,
      label: "Same month last year",
    },
    {
      value: PercentGrowthBasePeriod.BudgetSameMonthCurrentYear,
      label: `Same month ${reforecastYear} budget amount`,
    },
    {
      value: PercentGrowthBasePeriod.CurrentYearBudget,
      label: `${reforecastYear} Budget Total`,
    },
    {
      value: PercentGrowthBasePeriod.LastYearActuals,
      label: `${reforecastYear - 1} Actuals Total`,
    },
    {
      value: PercentGrowthBasePeriod.TwoYearsAgoActuals,
      label: `${reforecastYear - 2} Actuals Total`,
    },
  ];
};
