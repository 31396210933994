import { Autocomplete, Dropdown, Field, Item, Menu } from '@zendeskgarden/react-dropdowns';
import SearchIconSrc from '@zendeskgarden/svg-icons/src/16/search-stroke.svg';
import Downshift from "downshift";
import { useEffect, useRef, useState } from 'react';
import "./CustomZDDropdown.scss";

function SearchIcon(props: { show: boolean }) {
    return props.show ? <img src={SearchIconSrc} style={{ paddingRight: "7px" }} /> : null;
}

export interface CustomZDDDropdownProps {
    applySelectedItems: (items: any) => void;
    openDropdownPlaceholder: string;
    closedDropdownPlaceholder: string | undefined;
    options: ReadonlyArray<{ value: string, label: string, disabled?: boolean }>;
    isError: boolean | undefined;
    onBlur?: () => void;
    isMulti?: boolean;
    disabled?: boolean;
    widthSpec?: string;
    allOption?: boolean; // TODO: implement the "all" option which when clicked automatically selects all options
    initialSelectedItems?: ReadonlyArray<string>
    noSearchIcon?: boolean
}

export function CustomZDDropdown(props: CustomZDDDropdownProps) {
    const [inputValue, setInputValue] = useState("");
    const [selectedItems, setSelectedItems] = useState<string[]>([]);
    const [showSearchIcon, setShowSearchIcon] = useState(false);
    const driverInputRef = useRef<HTMLInputElement>(null);

    const stateReducer = (state: any, changes: any) => {
        if (!props.isMulti) {
            return changes;
        }
        switch (changes.type) {
            case Downshift.stateChangeTypes.keyDownEnter:
            case Downshift.stateChangeTypes.clickItem:
                return {
                    ...changes,
                    highlightedIndex: state.highlightedIndex,
                    isOpen: true,
                    inputValue: '',
                };
            default:
                return changes;
        }
    };

    useEffect(
        () => {
            if (driverInputRef.current != null) {
                driverInputRef.current.placeholder = props.openDropdownPlaceholder;
            }
        },
        [driverInputRef.current]
    );

    useEffect(
        () => {
            setSelectedItems(props.initialSelectedItems?.map(i => i) ?? []);
        },
        [props.initialSelectedItems]
    );

    const applySelectedDriverItems = (items: any) => {
        if (items) {
            if (props.isMulti) {
                if (
                    props.options.length == items.length - (props.allOption ? 2 : 0 ) // 2 - two fake items in case of allOption
                    || Array.isArray(items) && items.some(v => v == "all")
                ) {
                    const allItems = props.options.map(o => o.value);
                    setSelectedItems(allItems);
                    props.applySelectedItems(allItems);
                }
                else if (Array.isArray(items) && items.some(v => v == "clear")) {
                    setSelectedItems([]);
                    props.applySelectedItems([]);
                }
                else {
                    setSelectedItems(items);
                    props.applySelectedItems(items);
                }
            }
            else {
                setSelectedItems(Array.isArray(items) ? items : [items]);
                props.applySelectedItems(Array.isArray(items) ? items : [items]);
            }
        }
    };

    const renderAutocompleteValue = () => {
        if (selectedItems.length > 0) {
            return (
                <div style={{ display: "flex", flexDirection: "column" }}>
                    {props.closedDropdownPlaceholder &&
                    <span style={{ color: "#66708580", fontSize: "10px", fontFamily: "Inter", lineHeight: "16px", fontWeight: 500 }}>
                        {props.closedDropdownPlaceholder}
                    </span>
                    }
                    <span style={{ color: "#333842", fontSize: "12px", fontFamily: "Inter", lineHeight: "16px", fontWeight: 500, textOverflow: "ellipsis", overflow: "hidden" }}>
                        {selectedItems.length == props.options.length && props.options.length > 1 ? "All" : props.options.filter(o => selectedItems.includes(o.value)).map(o => o.label).join(", ")}
                    </span>
                </div>
            );
        }
        else {
            return <span style={{ color: "#66708580", fontSize: "12px", fontFamily: "Inter", lineHeight: "16px", fontWeight: 500 }}>{props.closedDropdownPlaceholder}</span>;
        }
    };

    const renderOptions = () => {
        if (props.options.length === 0) {
            return <Item disabled>No matches found</Item>;
        }

        return [
            ...(props.allOption ? [{ value: "all", label: "Select All" }, { value: "clear", label: "Clear All" }] : []),
            ...props.options.filter(({ label }) => label.trim().toLowerCase().includes(inputValue.toLowerCase()))
        ].
            map((option, index) => (
                <Item key={option.value} value={option.value} disabled={option.disabled} style={{opacity: option.disabled ? ".4" : "1"}}>
                    <span style={{ color: "#333842", fontSize: "12px", fontFamily: "Inter", lineHeight: "16px", fontWeight: props.allOption && index < 2 ? 900 : 500 }}>{option.label}</span>
                </Item>
            ));
    };

    const onBlur = () => {
        setShowSearchIcon(false);
        props.onBlur?.();
    };

    return (
        <Dropdown
            inputValue={inputValue}
            selectedItems={props.isMulti ? selectedItems : undefined}
            selectedItem={props.isMulti ? undefined : (selectedItems.firstElement ?? undefined)}
            onSelect={param => applySelectedDriverItems(param)}
            downshiftProps={{
                defaultHighlightedIndex: 0,
                stateReducer: stateReducer
            }}
            onInputValueChange={value => setInputValue(value)}
            onStateChange={(all) => { if (all.isOpen && !props.noSearchIcon) setShowSearchIcon(true); }}
        >
            <Field
                className="custom-zd-dropdown-field"
                style={props.widthSpec ? { width: props.widthSpec } : undefined}
            >
                <Autocomplete
                    start={<SearchIcon show={showSearchIcon} />}
                    className="custom-zd-dropdown-autocomplete"
                    style={props.widthSpec ? { width: props.widthSpec, minWidth: props.widthSpec } : undefined}
                    inputRef={driverInputRef}
                    onBlur={() => onBlur()}
                    validation={props.isError ? "error" : undefined}
                    disabled={props.disabled}
                >
                    {renderAutocompleteValue()}
                </Autocomplete>
            </Field>
            <Menu
                style={{ width: `calc(${props.widthSpec ?? "241px"} + 30px)` }}
            >
                {renderOptions()}
            </Menu>
        </Dropdown>

    );
}