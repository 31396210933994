import {ReactElement, useEffect, useState} from "react";
import Intercom from "@intercom/messenger-js-sdk";
import {INTERCOM_APP_ID} from "../constants/Intercom";

type IntercomUser = {
    id: string,
    clientId: string,
    clientName: string,
    firstName: string,
    lastName: string,
    email: string,
    custom_tag?: string
}

export function IntercomWrapper(props: {children: ReactElement | ReactElement[], user: IntercomUser | null}): ReactElement {
    const [intercomInitialized, setIntercomInitialized] = useState<boolean>(false);

    useEffect(() => {
        Intercom({
            app_id: INTERCOM_APP_ID
        });
        setIntercomInitialized(true);
    }, []);

    useEffect(() => {
        if(!intercomInitialized) {
            return;
        }

        const user = props.user;
        if (window.Intercom !== undefined) {
            if (user === null) {
                window.Intercom("shutdown");
                window.Intercom("boot", {
                    app_id: INTERCOM_APP_ID
                });
            }
            else {
                // We have to use the direct `window.Intercom` here. The "wrapped methods" they provide
                // keep a ref that holds the wrong object when reloading the page. This causes Intercom
                // to not properly reset.
                // I don't know why it happens, but it does.
                window.Intercom("shutdown");
                window.Intercom("boot", {
                    app_id: INTERCOM_APP_ID,
                    user_id: user.id,
                    name: `${user.firstName} ${user.lastName}`,
                    company: {
                        id: user.clientId,
                        name: user.clientName,
                    },
                    email: user.email,
                    clientId: user.clientId,
                    companyName: user.clientName,
                    custom_tag: user.custom_tag
                });
            }
            return () => {
                if(window.Intercom !== undefined) {
                    window.Intercom("shutdown");
                    window.Intercom("boot", {
                        app_id: INTERCOM_APP_ID
                    });
                }
            };
        }
    }, [intercomInitialized, props.user]);


    return <>{props.children}</>;
}