import { ThemeProvider } from "@zendeskgarden/react-theming";
import { ReactElement, useEffect, useState } from "react";
import { ViziblyTheme } from "../analyst/ViziblyZDGTheme";
import AdminHeader from "../admin/AdminHeader";
import * as css from "./styles/unitTypeManagement.module.scss";
import useAppStore from "../../hooks/useAppStore";

import { Field, MediaInput } from '@zendeskgarden/react-forms';
import { Button } from "@zendeskgarden/react-buttons";

import "native-injects";
import Card from "../simplified-revenue/components/Card";
import { useProperties } from "../../contexts/properties/PropertiesContext";
import { ListUnitTypesQuery, UnitTypeForPmsActualOverridesUpdateInput, UnitTypePmsActualOverrideMonthModel, useAddUnitTypeMutation, useDeleteUnitTypeMutation, useListUnitTypesLazyQuery, useSetUnitTypePmsOverridesMutation, useUpdateUnitTypeMutation } from "../../__generated__/generated_types";
import { ReactComponent as SearchIcon } from '@zendeskgarden/svg-icons/src/16/search-stroke.svg';
import { ReactComponent as CheckIcon } from '@zendeskgarden/svg-icons/src/16/check-lg-fill.svg';
import { ReactComponent as PencilIcon } from '@zendeskgarden/svg-icons/src/16/pencil-stroke.svg';
import { ReactComponent as XIcon } from '@zendeskgarden/svg-icons/src/16/x-stroke.svg';
import { ReactComponent as PlusIcon } from '@zendeskgarden/svg-icons/src/16/plus-stroke.svg';
import { EditUnitType } from "./components/EditUnitType";
import { toast } from "react-toastify";
import DeleteUnitTypeModal from "./components/DeleteUnitTypeModal";

type MetricMapValue = {original: number | null, override: number | null};
type MetricMap = Map<string, MetricMapValue>;

function dataValueToMapValue(
    dataValue: Pick<UnitTypePmsActualOverrideMonthModel,
                    'unitTypeId' | 'unitTypeName' | 'original' | 'override'>
): MetricMapValue {
    return {original: dataValue.original ?? null, override: dataValue.override ?? null};
}

export default function UnitTypeManagement(): ReactElement {
    const appStore = useAppStore();
    const {currentProperty} = useProperties();

    const [getUnitTypes, {data: unitTypesData}] = useListUnitTypesLazyQuery({fetchPolicy: "no-cache"});
    const [deleteUnitType] = useDeleteUnitTypeMutation({notifyOnNetworkStatusChange: true});
    const [updateUnitType] = useUpdateUnitTypeMutation({notifyOnNetworkStatusChange: true});
    const [updateUnitTypeOverrides] = useSetUnitTypePmsOverridesMutation();
    const [addUnitType] = useAddUnitTypeMutation({notifyOnNetworkStatusChange: true});


    // const [selectedProperty, setSelectedProperty] = useState<Property>();
    const [unitTypes, setUnitTypes] = useState<ListUnitTypesQuery["listUnitTypes"]>([]);
    const [metricValues, setMetricValues] = useState<{
        unitCount: MetricMap,
        inPlaceRent: MetricMap,
        occupancy: MetricMap
    }>({
        unitCount: new Map(),
        inPlaceRent: new Map(),
        occupancy: new Map()
    });
    const [updateUnitTypeData, setUpdateUnitTypeData] = useState<{id: string, name: string}>();
    const [deleteUnitTypeData, setDeleteUnitTypeData] = useState<{id: string, name: string}>();
    const [addUnitTypeData, setAddUnitTypeData] = useState(false);

    function fetchUnitTypesData() {
        if (currentProperty) {
            let lastActualMonthIndex = currentProperty.reforecastStartMonthIndex - 1;
            if (lastActualMonthIndex < 0) {
                lastActualMonthIndex = 11;
            }
            getUnitTypes({variables: {propertyId: currentProperty.id, monthIndex: lastActualMonthIndex}});
        }
    }

    function handleUpdateUnitType(
        unitTypeId: string,
        name: string | undefined,
        unitCountOverrides: Record<number, number | null>,
        occupancyOverrides: Record<number, number | null>,
        inPlaceRentOverrides: Record<number, number | null>
    ) {
        const promises = [];
        if (name !== undefined) {
            promises.push(new Promise((resolve, reject) => {
                updateUnitType({
                    variables: {
                        unitTypeId: unitTypeId,
                        name: name
                    }
                })
                .then(() => resolve(true), () => reject())
                .catch(() => reject());
            }));
        }
        if (
            currentProperty && (
            Object.entries(unitCountOverrides).length > 0 ||
            Object.entries(occupancyOverrides).length > 0 ||
            Object.entries(inPlaceRentOverrides).length > 0
        )) {
            const unitCountUpdates: UnitTypeForPmsActualOverridesUpdateInput[] = [];
            const occupancyUpdates: UnitTypeForPmsActualOverridesUpdateInput[] = [];
            const inPlaceRentUpdates: UnitTypeForPmsActualOverridesUpdateInput[] = [];
            for (const [monthIndex, val] of Object.entries(unitCountOverrides)) {
                unitCountUpdates.push({
                    unitTypeId: unitTypeId,
                    [`valueM${monthIndex}`]: val
                });
            }
            for (const [monthIndex, val] of Object.entries(occupancyOverrides)) {
                occupancyUpdates.push({
                    unitTypeId: unitTypeId,
                    [`valueM${monthIndex}`]: val
                });
            }
            for (const [monthIndex, val] of Object.entries(inPlaceRentOverrides)) {
                inPlaceRentUpdates.push({
                    unitTypeId: unitTypeId,
                    [`valueM${monthIndex}`]: val
                });
            }
            promises.push(new Promise((resolve, reject) => {
                updateUnitTypeOverrides({
                    variables: {
                        propertyId: currentProperty.id,
                        unitCount: unitCountUpdates,
                        occupancy: occupancyUpdates,
                        inPlaceRent: inPlaceRentUpdates
                    }
                })
                .then(() => resolve(true), () => reject())
                .catch(() => reject());
            }));
        }
        if (promises.length > 0) {
            Promise.all(promises)
            .then(
                () => {
                    setUpdateUnitTypeData(undefined);
                    toast.success(`Unit Type updated complete`);
                    fetchUnitTypesData();
                }
            );
        }
    }

    function handleDeleteUnitType(unitTypeId: string) {
        deleteUnitType({
            variables: {
                unitTypeId: unitTypeId
            }
        })
        .then(
            (data) => {
                setDeleteUnitTypeData(undefined);
                if (data.data?.deleteUnitType) {
                    toast.success(`Unit Type deleted successfully`);
                }
                fetchUnitTypesData();
            }
        );
    }

    function handleAddUnitType(propertyId: string, name: string | undefined) {
        if (!name) {
            return;
        }
        addUnitType({
            variables: {
                propertyId: propertyId,
                name: name
            }
        })
        .then(
            (data) => {
                setAddUnitTypeData(false);
                if (data.data?.addUnitType) {
                    toast.success(`Unit Type added successfully`);
                }
                fetchUnitTypesData();
            }
        );
    }


    useEffect(
        () => {
            appStore.set({ isLoading: false });
            return () => undefined;
        },
        []
    );

    useEffect(() => {
        fetchUnitTypesData();
    }, [currentProperty]);

    useEffect(() => {
        if (unitTypesData) {
            setUnitTypes([...unitTypesData.listUnitTypes]);
            if (unitTypesData.queryPropertyPMSActualsWithOverridesMonth) {
                const unitCountValues: MetricMap = new Map();
                const inPlaceRentValues: MetricMap = new Map();
                const occupancyValues: MetricMap = new Map();
                for (const value of unitTypesData.queryPropertyPMSActualsWithOverridesMonth.unitCount) {
                    unitCountValues.set(value.unitTypeId, dataValueToMapValue(value));
                }
                for (const value of unitTypesData.queryPropertyPMSActualsWithOverridesMonth.inPlaceRent) {
                    inPlaceRentValues.set(value.unitTypeId, dataValueToMapValue(value));
                }
                for (const value of unitTypesData.queryPropertyPMSActualsWithOverridesMonth.occupancy) {
                    occupancyValues.set(value.unitTypeId, dataValueToMapValue(value));
                }
                setMetricValues({
                    unitCount: unitCountValues,
                    inPlaceRent: inPlaceRentValues,
                    occupancy: occupancyValues
                });
            }
        }
    }, [unitTypesData]);

    return (
        <ThemeProvider theme={ViziblyTheme}>
            <div className={css.wrapper}>
                <AdminHeader
                    title={"Unit Types Management"}
                    subtitle={"Add/Rename/Delete Unit Types and override historical metric values"}
                />
                <Card
                    title={"Unit Types For Property"}
                    actions={
                        <>
                            <Field className={css.inputBox}>
                                <MediaInput start={<SearchIcon />} placeholder="Search" onChange={e => {
                                    if (!unitTypesData) {
                                        return;
                                    }
                                    setUnitTypes(unitTypesData.listUnitTypes.filter(p => p.name.toLowerCase().includes(e.target.value.trim().toLowerCase())));
                                }} />
                            </Field>
                            <Button
                                isBasic
                                onClick={() => setAddUnitTypeData(true)}
                            >
                                <PlusIcon />
                            </Button>
                        </>
                    }
                >
                    <div className={css.unitTypesTableWrapper}>
                        <table className={css.unitTypesTable}>
                            <thead>
                                <tr className={css.headerCell}>
                                    <th className={`${css.cell} ${css.nameCell}`}>Name</th>
                                    <th className={`${css.cell} ${css.metricCell}`}>Unit Count</th>
                                    <th className={`${css.cell} ${css.metricCell}`}>Occupancy %</th>
                                    <th className={`${css.cell} ${css.metricCell}`}>In Place Rent</th>
                                    <th className={`${css.cell} ${css.inUseCell}`}>Is In Use</th>
                                    <th className={`${css.cell} ${css.periodCell}`}>Added On</th>
                                </tr>
                            </thead>

                            <tbody>
                                {unitTypes.sortBy("name").map(unitType => {
                                    const unitCount = metricValues.unitCount.get(unitType.id);
                                    const occupancy = metricValues.occupancy.get(unitType.id);
                                    const inPlaceRent = metricValues.inPlaceRent.get(unitType.id);
                                    let unitCountOverriden = false;
                                    let occupancyOverriden = false;
                                    let inPlaceRentOverriden = false;
                                    let unitCountValue = null;
                                    let occupancyValue = null;
                                    let inPlaceRentValue = null;
                                    if (unitCount) {
                                        unitCountOverriden = unitCount.override !== null;
                                        unitCountValue = unitCount.override === null ? unitCount.original : unitCount.override;
                                    }
                                    if (occupancy) {
                                        occupancyOverriden = occupancy.override !== null;
                                        occupancyValue = occupancy.override === null ? occupancy.original : occupancy.override;
                                    }
                                    if (occupancyValue !== null) {
                                        occupancyValue *= 100;
                                    }
                                    if (inPlaceRent) {
                                        inPlaceRentOverriden = inPlaceRent.override !== null;
                                        inPlaceRentValue = inPlaceRent.override === null ? inPlaceRent.original : inPlaceRent.override;
                                    }
                                    return (
                                        <tr key={unitType.id}>
                                            <td className={`${css.cell}`}>
                                                <div className={css.content}>
                                                    { unitType.name }
                                                    <div className={css.controls}>
                                                        <Button
                                                            isBasic
                                                            onClick={() => setUpdateUnitTypeData(unitType)}
                                                        >
                                                            <PencilIcon />
                                                        </Button>
                                                        <Button
                                                            isBasic
                                                            isDanger
                                                            onClick={() => setDeleteUnitTypeData(unitType)}
                                                        >
                                                            <XIcon />
                                                        </Button>
                                                    </div>
                                                </div>
                                            </td>
                                            <td className={`${css.cell} ${css.metricCell} ${unitCountOverriden ? css.isOverride : ""}`}>
                                                { unitCountValue !== null ? unitCountValue : "" }
                                            </td>
                                            <td className={`${css.cell} ${css.metricCell} ${occupancyOverriden ? css.isOverride : ""}`}>
                                                { occupancyValue !== null ? occupancyValue : "" }
                                            </td>
                                            <td className={`${css.cell} ${css.metricCell} ${inPlaceRentOverriden ? css.isOverride : ""}`}>
                                                { inPlaceRentValue !== null ? inPlaceRentValue : "" }
                                            </td>
                                            <td className={`${css.cell} ${css.inUseCell}`}>{ unitType.inUse ? <CheckIcon/> : "" }</td>
                                            <td className={`${css.cell} ${css.periodCell}`}>{ unitType.createdAt }</td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    </div>
                </Card>
            </div>
            {
            updateUnitTypeData && currentProperty &&
            <EditUnitType
                existingUnitTypes={unitTypesData?.listUnitTypes ?? []}
                initialName={updateUnitTypeData.name}
                property={currentProperty}
                unitTypeId={updateUnitTypeData.id}
                title="Edit Unit Type"
                onCancel={() => setUpdateUnitTypeData(undefined)}
                onSubmit={(name, unitCountOverrides, occupancyOverrides, inPlaceRentOverrides) => handleUpdateUnitType(updateUnitTypeData.id, name, unitCountOverrides, occupancyOverrides, inPlaceRentOverrides)}
            />
            }
            {
            addUnitTypeData && currentProperty &&
            <EditUnitType
                existingUnitTypes={unitTypesData?.listUnitTypes ?? []}
                property={currentProperty}
                title="Add Unit Type"
                onCancel={() => setAddUnitTypeData(false)}
                onSubmit={(name: string | undefined, ..._rest) => currentProperty && handleAddUnitType(currentProperty.id, name)}
            />
            }
            {deleteUnitTypeData && currentProperty && (
            <DeleteUnitTypeModal
                unitTypeId={deleteUnitTypeData.id}
                unitTypeName={deleteUnitTypeData.name}
                budgetYear={currentProperty.budgetYear}
                onClose={() => {
                    setDeleteUnitTypeData(undefined);
                }}
                onConfirm={() => {
                    handleDeleteUnitType(deleteUnitTypeData.id);
                }}
            />
        )}
        </ThemeProvider>
    );
}