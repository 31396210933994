import Handsontable from "handsontable";
import {GetSimplifiedRevenueQuery, SimplifiedRevenueMarketRentIncreaseInput} from "../../../__generated__/generated_types";
import { TSimplifiedUnitType } from "../Renovations";

// given the state of the table, determine the increases payload for market growth update
export function buildManualEntryIncreases(
    rfcstColRange: number[],
    bdgtColRange: number[],
    rfcstAverageColIndex: number,
    bdgtAverageColIndex: number,
    rfcstAnnualGrowth: string,
    bdgtAnnualGrowth: string,
    isRfcstConfigured: boolean,
    isBdgtConfigured: boolean,
    hotInstance: Handsontable,
): SimplifiedRevenueMarketRentIncreaseInput {
        // TODO some typing inconsistencies are present with the backend for when null is allowed for month values and when it isnt, so this could be clened up
        const increases: SimplifiedRevenueMarketRentIncreaseInput = {
            reforecastYearlyIncrease: (rfcstAnnualGrowth == "" || rfcstAnnualGrowth == null) ? null : parseFloat(rfcstAnnualGrowth),
            reforecastMonthlyValues: [],
            budgetYearlyIncrease: (bdgtAnnualGrowth == "" || bdgtAnnualGrowth == null) ? null : parseFloat(bdgtAnnualGrowth),
            budgetMonthlyValues: [],
        };

        const growthValues = hotInstance.getDataAtRow(1);

        if (!isRfcstConfigured) {
            let rfcstGVals = growthValues.slice(rfcstColRange[0], rfcstAverageColIndex);
            rfcstGVals = Array(12 - rfcstColRange.length).fill(0).concat(rfcstGVals);
            const revMonthlyInput = rfcstGVals.map((x, m) => ({
                month: m,
                value: (x == "" || x == null) ? 0 : parseFloat(x),
            }));

            increases.reforecastMonthlyValues = revMonthlyInput;
            increases.reforecastYearlyIncrease = null;
        }

        if (!isBdgtConfigured) {
            const bdgtGVals = growthValues.slice(bdgtColRange[0], bdgtAverageColIndex);
            const revMonthlyInput = bdgtGVals.map((x, m) => ({
                month: m,
                value: (x == "" || x == null) ? 0 : parseFloat(x),
            }));

            increases.budgetMonthlyValues = revMonthlyInput;
            increases.budgetYearlyIncrease = null;
        }

        return increases;
}

export function sumOfArray(values: (number | null)[]): number {
    const nonNulls = values.filter((value): value is number => value !== null);
    const sum = nonNulls.reduce((a, b) => a + b, 0);
    return sum;
}

export function averageOfArray(values: (number | null)[]): number {
    const nonNulls = values.filter((value): value is number => value !== null);
    const sum = nonNulls.reduce((a, b) => a + b, 0);
    const average = nonNulls.length > 0 ? sum / nonNulls.length : 0;
    return average;
}

export function numRange(start: number, end: number): number[] {
    return Array.from({length: end - start}, (_x, k) => k + start);
}

export function buildSimplifiedUnitTypes(data: GetSimplifiedRevenueQuery): TSimplifiedUnitType[] {
    const unitTypes: TSimplifiedUnitType[] = [];

    if (data.simplifiedRevenueModel?.fakeUnitTypeMarketRents) {
        data.simplifiedRevenueModel.fakeUnitTypeMarketRents.forEach(fakeUnit => {
            unitTypes.push({
                id: fakeUnit.name,
                type: fakeUnit.name,
                startingMarketRent: fakeUnit.startingMarketRent,
                count: 0,
                isCustom: true,
            });
        });
    }

    if (data.simplifiedRevenueModel?.unitTypeMarketRents) {
        data.simplifiedRevenueModel.unitTypeMarketRents.forEach(unit => {
            unitTypes.push({
                id: unit.unitTypeId,
                type: unit.unitTypeName,
                startingMarketRent: unit.rentRollStartingMarketRent,
                overrideMarketRent: unit.overrideStartingMarketRent,
                count: 0,
                isCustom: false,
            });
        });
    }

    return unitTypes;
}