import GC from "@grapecity/spread-sheets";
import ReactDOM from "react-dom";
import { SelectInstance } from "react-select";
import { drawLock } from "../icon-renderers/draw-lock";
import { ICustomCellInfo } from "../types";
import { TypeaheadDropdown } from "./TypeaheadDropdown";

export interface SelectNotifiable {
    callback: (value: string | null, meta: any) => void;
    setRef: (selectRef: SelectInstance | null) => void;
}

export interface ICustomCellTypeaheadDropdown extends ICustomCellInfo {
    options?: string[];
    width: number;
    leftPadding?: number;
    placeholder?: string;
    initialValue?: string;
    getTypeaheadOptions?: () => string[];
    withIcon?: boolean;
}

export class TypeaheadDropdownCell extends GC.Spread.Sheets.CellTypes.Base implements SelectNotifiable {
    constructor(private info: ICustomCellTypeaheadDropdown) {
        super();
        this.typeName = "TypeaheadDropdownCell";
        this.showIcon = info.withIcon ?? false;
    }

    selectedOption = "";
    selectRef: SelectInstance|null = null;
    isEditing = false;
    editorContext = null;
    showIcon = false;

    callback = (value: string|null, _meta: any): void => {
        this.selectedOption = value ?? "";
    }

    setRef(selectRef: SelectInstance|null): void {
        this.selectRef = selectRef;
        this.selectRef?.focus();
    }

    displayIcon():void {
        this.showIcon = true;
    }

    hideIcon():void {
        this.showIcon = false;
    }

    override createEditorElement(context?: any): HTMLElement {
        const editor = document.createElement("div");
        const cellValue = context.sheet.getValue(context.row, context.col);
        editor.setAttribute("gcUIElement", "gcEditingInput");

        let typeaheadOptions: string[] = [];
        if(this.info.getTypeaheadOptions){
            typeaheadOptions = this.info.getTypeaheadOptions();
        }
        else if(this.info.options){
            typeaheadOptions = [...this.info.options];
        }

        ReactDOM.render(<TypeaheadDropdown
            holder={this}
            options={typeaheadOptions}
            placeholder={this.info.placeholder}
            initialValue={cellValue}
            width={this.info.width}
        />, editor);

        return editor;
    }

    override paintContent(ctx: CanvasRenderingContext2D, value: any, x: number, y: number, w: number, h: number, style: GC.Spread.Sheets.Style, context?: any): void {
        super.paintContent(ctx, value, x, y, w, h, style, context);
        if (this.showIcon) {
            drawLock(ctx, x, y, w, h);
        }
    }

    override activateEditor(editorContext: HTMLElement, _cellStyle: GC.Spread.Sheets.Style, _cellRect: GC.Spread.Sheets.Rect, _context?: any): void {
        if(editorContext?.parentElement?.parentElement?.style){
            editorContext.parentElement.parentElement.style.overflow = "visible";

            const containerBoxStyle = editorContext?.parentElement?.parentElement?.style;

            if(containerBoxStyle){
                containerBoxStyle.border = "none";
                containerBoxStyle.boxShadow = "none";
                containerBoxStyle.backgroundColor = "none";
                containerBoxStyle.padding = "1px 2px 3px 4px";
            }

            const controlContainerStyle = editorContext?.parentElement?.style;

            if(controlContainerStyle){
                controlContainerStyle.top = "7px";
                if(this.info.leftPadding != undefined){
                    controlContainerStyle.left = `${this.info.leftPadding}px`;
                }
            }
            this.isEditing = true;
        }
    }

    override getEditorValue(_editorContext: HTMLElement, _context ?: any): string {
        this.isEditing = false;
        return this.selectedOption;
    }

    override isReservedKey(_e: KeyboardEvent, _context ?: any): boolean {
        return this.isEditing;
    }
}
