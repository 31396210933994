export function parseNumString(entry:string):number|null{
        const strNum = parseFloat(entry);
        if(!isNaN(strNum)){
            return strNum;
        }
        return null;
}

export function sumYearValues(values:(number|string|null|undefined)[]):number {
    const reduced = values
        .map( entry => {
            if(typeof entry === 'string'){
                return parseNumString(entry);
            }
            else {
                return entry;
            }
        })
        .reduce((acc:number, curr) => {
        if(curr === null || curr === undefined){
            return acc;
        }
        return acc + curr;
    }, 0);

    return reduced;
}

export function flatAverageValues(values:(number|string|null|undefined)[]):number {
    // Return a valid number even if the values array is empty
    if(values.length === 0){
        return 0;
    }

    const reduced = values
        .map( entry => {
            if(typeof entry === 'string'){
                return parseNumString(entry);
            }
            else {
                return entry;
            }
        })
        .reduce((acc:number, curr) => {
        if(curr === null || curr === undefined){
            return acc;
        }
        return acc + curr;
    }, 0);

    return reduced/values.length;
}
