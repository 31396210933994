
import { useEffect, useState } from "react";
import {
    GetPropertyAccountDriverStatusQuery,
    GetPropertyExecutiveSummaryDataQuery,
    TopCardTagType,
    useGetPropertyAccountDriverStatusLazyQuery,
    useGetPropertyExecutiveSummaryDataLazyQuery
} from "../../../__generated__/generated_types";
import {Property} from "../../../contexts/properties/PropertiesContext";
import {buildBudgetedAvgMarketRentStat, buildCommentsPreviewData, buildSummaryDataAverageMarketRent,
        buildSummaryDataAverageOccupancy,
        buildSummaryDataExpiringCounts,
        buildSummaryDataFinancialMetric,
        buildSummaryDataTradeOuts,
        CommentPreviewData,
        TBudgetedAvgMarketRentStat,
        TSummaryDataAverageOccupancy,
} from "./logic";
import { buildGraphSeriesDataExpirations, buildGraphSeriesDataFinancialMetric, buildGraphSeriesDataMoveOutRatios, buildGraphSeriesDataMoveOuts, buildGraphSeriesDataOccupancy, buildGraphSeriesDataRenewalRatios, buildGraphSeriesDataRenewals, ExecutiveSummaryGraphData } from "./seriesGraphDataLogic";
import { buildFinancialsTableData, buildReportTablesData, ReportTableData } from "./reportTablesLogic";
import { FinancialEntity, useChartOfAccounts } from "../../../contexts/chartofaccounts/ChartOfAccountsContext";
import { ExecutiveSummaryUserInfo } from "../PropertyExecutiveSummary";
import { RevenueSummaryUnitType } from "../../workflows/operational/summary/RevenueSummaryTable";
import { IFinancialsCOATableRow } from "../components/helpers";
import "native-injects";


export enum SERIES_DATA_OPTION {
    EXPIRATIONS,
    OCCUPANCY,
    RENEWALS,
    RENEWAL_RATIOS,
    RENEWAL_TRADEOUTS,
    MOVE_OUTS,
    MOVE_OUTS_RATIOS,
    NEW_LEASE_TRADEOUTS,
    NOI,
    RENTAL_INCOME,
    TOTAL_EXPENSE,
    BUDGETED_MARKET_RENT,
}


export interface EmployeeTotals {
    employeeId: string;
    positionName: string;
    basePayTotal: number;
    raiseTotal: number;
}

export type PropertyExecutiveSummaryData = {
    rawData: GetPropertyExecutiveSummaryDataQuery | undefined,
    rawDataLoading: boolean,
    property: Property,
    user: ExecutiveSummaryUserInfo,
    unitTypes: RevenueSummaryUnitType[],

    financialMetricsSetupRequired: boolean,

    noiDelta: number,
    noiDeltaPercent: number,

    rentalIncomeDelta: number,
    rentalIncomeDeltaPercent: number,

    totalExpenseDelta: number,
    totalExpenseDeltaPercent: number,

    avgMarketRentReforecast: number | null,
    avgMarketRentBudget: number | null,
    avgMarketRentDeltaPercent: number | null,

    occupancyStat: TSummaryDataAverageOccupancy,

    budgetedAvgMarketRent: TBudgetedAvgMarketRentStat,
    marketRentGrowthPercent: number | null,

    budgetExpirations: number | null,
    budgetExpiringMoveOuts: number | null,
    budgetExpiringRenewals: number | null,
    budgetExpiringMoveOutRatio: number | null,
    budgetExpiringRenewalRatio: number | null,

    newLeaseTradeOut: number | null,
    newLeaseTradeOutPercent: number | null,
    renewalTradeOut: number | null,
    renewalTradeOutPercent: number | null,

    graphSeriesData: Map<SERIES_DATA_OPTION, ExecutiveSummaryGraphData>,
    reportTables: ReportTableData[],
    financialsTable: IFinancialsCOATableRow[],
    commentsMap: Map<string, CommentPreviewData>,

    employeeTotals: EmployeeTotals[]
}

export function usePropertyExecutiveSummaryData(property: Property | undefined, user: ExecutiveSummaryUserInfo): PropertyExecutiveSummaryData | undefined {
    const [loadData, { data, loading }] = useGetPropertyExecutiveSummaryDataLazyQuery({ fetchPolicy: "no-cache" });
    const [loadAccountDriverStatus, { data: accountDriverStatusData, loading: accountDriverStatusLoading }] = useGetPropertyAccountDriverStatusLazyQuery({ fetchPolicy: "no-cache" });
    const coa = useChartOfAccounts();
    const [executiveSummaryData, setExecutiveSummaryData] = useState<PropertyExecutiveSummaryData>();
    useEffect(
        () => {
            if (!property) {
                return;
            }
            loadData({
                variables: {
                    propertyId: property.id,
                    budgetYear: property.budgetYear,
                    topCardTagTypes: []
                }
            });
            loadAccountDriverStatus({
                variables: {
                    propertyId: property.id,
                    budgetYear: property.budgetYear
                }
            });
        },
        [property]
    );

    useEffect(
        () => {
            if (property && data && !loading && user && coa.isReady && coa.chartOfAccountsFlat && coa.chartOfAccounts && accountDriverStatusData && !accountDriverStatusLoading) {
                const executiveSummaryData = buildSummaryData(property, user, data, loading, coa.chartOfAccounts, accountDriverStatusData.queryPropertyAccountDriverStatus);
                setExecutiveSummaryData(executiveSummaryData);
            }
        },
        [data, loading, user, coa.isReady, coa.chartOfAccountsFlat, accountDriverStatusData, accountDriverStatusLoading]
    );
    return executiveSummaryData;
}

function buildUnitTypes(rawData: GetPropertyExecutiveSummaryDataQuery): RevenueSummaryUnitType[] {
    let ret:RevenueSummaryUnitType[] = [];
    if (rawData.originalRevenueModel) {
        ret = [{ unitTypeId: "all", unitTypeName: "All Unit Types" },
            ...Object.entries(rawData
                .originalRevenueModel
                .unitTypeUnitCount
                .toIdMap("unitTypeId", "unitTypeName")
            )
                .map(([unitTypeId, unitTypeName]) => ({ unitTypeId, unitTypeName }))
                .sortBy("unitTypeName")
        ]
    }

    return ret;
}

function addChildren(parent: FinancialEntity, result: FinancialEntity[]) {
    for (const child of parent.children.sortBy("order")) {
        result.push(child);
        addChildren(child, result);
    }
}
function buildSummaryData(property: Property,
                          user: ExecutiveSummaryUserInfo,
                          rawData: GetPropertyExecutiveSummaryDataQuery,
                          rawDataLoading: boolean,
                          chartOfAccounts: FinancialEntity[],
                          accountDriverStatusData: GetPropertyAccountDriverStatusQuery["queryPropertyAccountDriverStatus"]): PropertyExecutiveSummaryData {
    const chartOfAccountsFlat: FinancialEntity[] = [];
    for (const record of chartOfAccounts.sortBy("order")) {
        addChildren(record, chartOfAccountsFlat);
        chartOfAccountsFlat.push(record);
    }
    const noiStat = buildSummaryDataFinancialMetric(property, rawData, TopCardTagType.NetOperatingIncome);
    const avgMarketRentStat = buildSummaryDataAverageMarketRent(rawData);
    const occupancyStat = buildSummaryDataAverageOccupancy(property, rawData);
    const rentalIncomeStat = buildSummaryDataFinancialMetric(property, rawData, TopCardTagType.RentalIncome);
    const totalExpenseStat = buildSummaryDataFinancialMetric(property, rawData, TopCardTagType.TotalExpenses);
    const budgetedMarketRentStat = buildSummaryDataFinancialMetric(property, rawData, TopCardTagType.MarketRent);
    const budgetAvgMarketRentStat = buildBudgetedAvgMarketRentStat(budgetedMarketRentStat, occupancyStat);
    const expiringCountsStat = buildSummaryDataExpiringCounts(property, rawData);
    const tradeOutsStat = buildSummaryDataTradeOuts(rawData);
    const reportTables = buildReportTablesData(property, rawData, chartOfAccountsFlat, occupancyStat.budgetUnitCountMonthly.average());
    const unitTypes = buildUnitTypes(rawData);
    const financialsTable = buildFinancialsTableData(property, rawData, chartOfAccountsFlat, accountDriverStatusData);
    const commentPreviews = buildCommentsPreviewData(rawData);
    const executiveSummaryData:PropertyExecutiveSummaryData = {
        rawData: rawData,
        rawDataLoading: rawDataLoading,
        property: property,
        user: user,
        unitTypes,

        financialMetricsSetupRequired: false,

        noiDelta: noiStat.varianceAmount == null ? 0 : noiStat.varianceAmount,
        noiDeltaPercent: noiStat.variancePercent == null ? 0 : noiStat.variancePercent,
        rentalIncomeDelta: rentalIncomeStat.varianceAmount == null ? 0 : rentalIncomeStat.varianceAmount,
        rentalIncomeDeltaPercent: rentalIncomeStat.variancePercent == null ? 0 : rentalIncomeStat.variancePercent,
        totalExpenseDelta: totalExpenseStat.varianceAmount == null ? 0 : totalExpenseStat.varianceAmount,
        totalExpenseDeltaPercent: totalExpenseStat.variancePercent == null ? 0 : totalExpenseStat.variancePercent,
        avgMarketRentReforecast: avgMarketRentStat.reforecastValue,
        avgMarketRentBudget: avgMarketRentStat.budgetValue,
        avgMarketRentDeltaPercent: avgMarketRentStat.deltaPercent,
        occupancyStat: occupancyStat,

        budgetedAvgMarketRent: budgetAvgMarketRentStat,
        marketRentGrowthPercent: budgetedMarketRentStat.variancePercent,

        budgetExpirations: expiringCountsStat.budgetExpirations,
        budgetExpiringMoveOuts: expiringCountsStat.budgetExpiringMoveOuts,
        budgetExpiringRenewals: expiringCountsStat.budgetExpiringRenewals,
        budgetExpiringMoveOutRatio: expiringCountsStat.budgetExpiringMoveOutRatio,
        budgetExpiringRenewalRatio: expiringCountsStat.budgetExpiringRenewalRatio,

        newLeaseTradeOut: tradeOutsStat.newLeaseTradeOut,
        newLeaseTradeOutPercent: tradeOutsStat.newLeaseTradeOutPercent,
        renewalTradeOut: tradeOutsStat.renewalTradeOut,
        renewalTradeOutPercent: tradeOutsStat.renewalTradeOutPercent,

        graphSeriesData: new Map(),

        reportTables: reportTables,
        financialsTable,
        commentsMap: commentPreviews,

        employeeTotals: rawData.employeeTotals
    };

    executiveSummaryData.graphSeriesData.set(SERIES_DATA_OPTION.OCCUPANCY, buildGraphSeriesDataOccupancy(occupancyStat, property));
    executiveSummaryData.graphSeriesData.set(SERIES_DATA_OPTION.EXPIRATIONS, buildGraphSeriesDataExpirations(expiringCountsStat, property));
    executiveSummaryData.graphSeriesData.set(SERIES_DATA_OPTION.RENEWALS, buildGraphSeriesDataRenewals(expiringCountsStat, property));
    executiveSummaryData.graphSeriesData.set(SERIES_DATA_OPTION.RENEWAL_RATIOS, buildGraphSeriesDataRenewalRatios(expiringCountsStat, property));
    executiveSummaryData.graphSeriesData.set(SERIES_DATA_OPTION.MOVE_OUTS, buildGraphSeriesDataMoveOuts(expiringCountsStat, property));
    executiveSummaryData.graphSeriesData.set(SERIES_DATA_OPTION.MOVE_OUTS_RATIOS, buildGraphSeriesDataMoveOutRatios(expiringCountsStat, property));
    executiveSummaryData.graphSeriesData.set(SERIES_DATA_OPTION.NOI, buildGraphSeriesDataFinancialMetric(noiStat, property));
    executiveSummaryData.graphSeriesData.set(SERIES_DATA_OPTION.RENTAL_INCOME, buildGraphSeriesDataFinancialMetric(rentalIncomeStat, property));
    executiveSummaryData.graphSeriesData.set(SERIES_DATA_OPTION.TOTAL_EXPENSE, buildGraphSeriesDataFinancialMetric(totalExpenseStat, property));
    executiveSummaryData.graphSeriesData.set(SERIES_DATA_OPTION.BUDGETED_MARKET_RENT, buildGraphSeriesDataFinancialMetric(budgetedMarketRentStat, property));

    return executiveSummaryData;
}
