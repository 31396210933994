import { BudgetComponentType } from "../__generated__/generated_types";
import { COLORS } from "../constants/Colors";
import { isPositiveGood } from "./account";

export const getVarianceColor = (value:number, budgetComponentType: BudgetComponentType, negatedAtComponent = false):string => {
    const isPosGood = isPositiveGood(budgetComponentType, negatedAtComponent);
    if (value == 0) {
        return COLORS.GREY_800;
    }

    if(
        (isPosGood && value > 0)
        || (!isPosGood && value < 0)
    ){
        return COLORS.POSITIVE_COLOR;
    } else {
        return COLORS.NEGATIVE_COLOR;
    }
};

export const calculateVariancePercent = (actual: number, budgetValue: number):number => {
    if(budgetValue === 0){
        /**
         * Avoid DIV0 errors.
         * Why +/-100? We already have this type of value representation in the app, so return +/-100, or 0 if there's
         * no budgetValue AND no actual value.
         */
        return actual > 0
            ? 100 : actual < 0
                ? -100 : 0;
    }
    return (actual - budgetValue)/Math.abs(budgetValue);
};

/**
 * If the current date is past the 15th of the month, return the index for last month, otherwise return index for
 * previous month.
 * @returns An integer number, between 0 and 11, representing the month in the given date according to local time.
 * 0 corresponds to January, 1 to February, and so on.
 */
export const getDefaultMVRMonth = ():number => {
    const now = new Date();
    const date = now.getDate();
    const month = now.getMonth();

    return (date > 15) ? month : month - 1;
};
