import React from "react";

const Terms: React.VFC = () => {
    // window.open("/Vizibly_Terms_of_Service_v2.pdf", "_blank");
    const terms = String.raw`
        Vizibly Terms of Service

THESE TERMS OF SERVICE (“AGREEMENT”) GOVERN THE LICENSING, INSTALLATION AND USE OF VIZIBLY’S SERVICES. BY ACCESSING THE SERVICES (A) YOU ARE INDICATING THAT YOU HAVE READ AND UNDERSTAND THIS AGREEMENT, AND AGREE TO BE LEGALLY BOUND BY IT ON BEHALF OF THE COMPANY, GOVERNMENT, OR OTHER ENTITY FOR WHICH YOU ARE ACTING (FOR EXAMPLE, AS AN EMPLOYEE OR GOVERNMENT OFFICIAL) OR, IF THERE IS NO COMPANY, GOVERNMENT OR OTHER ENTITY FOR WHICH YOU ARE ACTING, ON BEHALF OF YOURSELF AS AN INDIVIDUAL; AND (B) YOU REPRESENT AND WARRANT THAT YOU HAVE THE AUTHORITY TO ACT ON BEHALF OF AND BIND SUCH COMPANY, GOVERNMENT OR OTHER ENTITY (IF ANY). WITHOUT LIMITING THE FOREGOING, YOU (AND YOUR ENTITY, IF ANY) ACKNOWLEDGE THAT BY SUBMITTING AN ORDER FOR THE SERVICES, YOU (AND YOUR ENTITY (IF ANY)) HAVE AGREED TO BE BOUND BY THIS AGREEMENT.

As used in this Agreement, “Vizibly,” refers to Vizibly, Inc., a Delaware corporation, with its principal place of business in San Francisco, California and “Customer” refers to the company, government, or other entity on whose behalf you have entered into this Agreement or, if there is no such entity, you as an individual.


1.	Services

Vizibly, Inc. (“Vizibly”) will provide you with the “Services” as detailed in the “Order Form” subject to the terms and conditions of this Agreement. 

2.	Customer Obligations

2.1	Registration.  To register to use the Services, you must reset the password of the administrator account provided to you by Vizibly, which will be created by Vizibly based on the information provided in the Order Form. You will provide complete and accurate information during the registration process and will update your information to ensure that it remains accurate.

2.2	Representations and Warranties.  You represent and warrant that (i) you have the full power to enter into this Agreement and to carry out your obligations under this Agreement; (ii) you will use the Services in compliance with this Agreement and all applicable laws and regulations; and (iii) to the best of your knowledge, there are no claims, actions, or proceedings against you that would impair your ability to honor this Agreement. 

2.3	Security.  You will ensure that your data, and your use of it, complies with this Agreement and all applicable laws. You are responsible for properly configuring and using the Services and taking your own steps to maintain appropriate security, protection, and backup of your data. You will obtain and maintain any equipment and ancillary services needed to connect to, access, or otherwise use the Services, including, without limitation, modems, hardware, servers, software, operating systems, networking, web servers and the like (collectively, “Equipment”). You are responsible for maintaining the security of the Equipment and your administrator and user accounts (together, your “Accounts”) (including your passwords and data), and for all uses of the Equipment and your Accounts with or without your knowledge or consent. You will not disclose any of your Account credentials to any unauthorized person. You are responsible for all activities in your Accounts, regardless of whether undertaken by you, your employees, or a third party (including contractors and agents). Vizibly is not responsible for unauthorized access to your Accounts. You will contact Vizibly immediately if you believe unauthorized activity has occurred in any of your Accounts or if any of your Account information is lost or stolen.

2.4	Restrictions.  You will not copy or use the Services except as expressly permitted in this Agreement. You will not (i) directly or indirectly, reverse engineer, decompile, disassemble, or otherwise attempt to discover the source code, object code, or underlying structure, ideas, know-how, or algorithms relevant to the Services, or any documentation, deliverables, reports, analysis, dashboards, work product, or data related to or produced in connection with the Services; (ii) modify, translate, or create derivative works based on the Services (except to the extent expressly permitted by Vizibly or to the extent authorized within the Services); (iii) knowingly disclose results of any benchmark or other performance tests to any third party without Vizibly’s prior written consent; or (d) distribute, sell, sublicense, rent, lease or use the Services (or any portion thereof) for time sharing, hosting, service provider or similar purposes; or (iv) remove any trademarks, logos, copyrights, proprietary notices, symbols, or labels.. 

2.5	Availability of Services.  Vizibly will use commercially reasonable efforts to provide 95% uptime with respect to the Services during each calendar quarter of the Term. You acknowledge that the Services may be temporarily unavailable for scheduled maintenance or for unscheduled emergency maintenance, either by Vizibly or by third-party providers, or because of other causes beyond Vizibly’s control. Vizibly will use commercially reasonable efforts to provide you with notice of any scheduled disruption to the email address associated with your administrator account. 

2.6	Indemnification.  You will defend, indemnify, and hold harmless Vizibly and its employees, officers, directors, and representatives from and against any claims, damages, losses, liabilities, settlements, and expenses (including, without limitation, costs and legal fees) arising out of or relating to any claim or action concerning: (i) your use of the Services, including any activities under your Accounts and use by your employees, contractors, and personnel; (ii) your breach of this Agreement or violation of applicable law; or (iii) your data or the combination of your data with other applications, content, or processes, including any claim involving alleged infringement or misappropriation of Intellectual Property Rights. If Vizibly is obligated to respond to a third party subpoena or other compulsory legal order or process seeking information related to your data or use of the Services, you will reimburse Vizibly for legal fees as well as Vizibly’s employees’ and contractors’ time and materials spend responding at Vizibly’s then-current hourly rates. Vizibly will promptly notify you of any claim subject to this Section, but Vizibly’s failure to promptly notify you will only affect your obligations to the extent that it materially harms your ability to defend the claim. Subject to Vizibly’s prior written consent, you may (i) use counsel of your own choosing and (ii) settle the claim. 

3.	Payment and Taxes 

3.1	Payment.  You will pay Vizibly the fees described in the Order Form within 30 days in accordance with the terms in the Order Form (the “Fees”). If you believe that Vizibly has billed you incorrectly, you must contact support@vizibly.io within 60 days. After 60 days Vizibly will have no obligation to credit any incorrectly billed amounts. If your use of the Services exceeds the service capacity stated in the Order Form or otherwise requires payment of additional fees, Vizibly will bill you accordingly and you agree to pay the additional fees within 30 days. Vizibly reserves the right to change the Fees or applicable charges upon 30 days’ notice to the email address associated with your administrator account. Vizibly may charge you interest at the rate of 1% per month or the highest rate permitted by law on any late payment. Vizibly reserves the right to terminate or suspend your access to the Services in its sole discretion for failure to pay Fees. You are responsible for all expenses associated with collections, including legal fees.

3.2	Taxes.  You are responsible for all taxes associated with the Services other than U.S. taxes based on Vizibly’s income. All Fees are exclusive of taxes and duties. If any deduction or withholding is required by law, you will notify Vizibly and will pay Vizibly any additional amounts necessary to ensure that the net amount that Vizibly receives, after any deduction or withholding, equals the amount that Vizibly would have received if  no deduction or withholding had been required. Additionally, you will provide Vizibly with documentation showing that the deducted or withheld amounts have been paid to the relevant taxing authority.

4.	Term and Termination

4.1	Term.  The term of this Agreement begins when you create an administrator account and will remain in effect until terminated in accordance with this Agreement. The “Service Term” is as stated in the Order Form and will automatically renew unless you provide notice to support@vizibly.io of your intent to terminate at least 60 days prior to the end of the then-current Service Term.

4.2	Termination and Suspension.  Vizibly may immediately terminate or suspend your access to the Services, any of your Accounts, and/or this Agreement completely if: (i) Vizibly changes the way it provides or discontinues the Services; (ii) you are late in payment; (iii) you breach this Agreement; (iv) Vizibly determines that your use of the Services poses a risk to the availability, functionality, or security of the Services; (v) Vizibly determines that your use of the Services may be unlawful; or (vi) you have ceased to operate in the ordinary course, made an assignment for the benefit of creditors or a similar disposition of your assets, or you have become the subject of any bankruptcy, reorganization, liquidation, dissolution, or similar proceeding. If Vizibly suspends your access to the Services, you remain responsible for all Fees and charges you incur during the suspension and you will not be entitled to any credit or refund. Vizibly will use commercially reasonable efforts to restore your access to the Services promptly following resolution of the cause of your suspension. In addition to any other remedies it may have, Vizibly may terminate for any reason by providing you with 30 days’ notice to the email address associated with your administrator account.

4.3	Effect of Termination.  Upon termination, (i) all of your rights under this Agreement immediately terminate and (ii) you remain responsible for all Fees and charges you incur up to and including the date of termination, as well as all expenses associated with collections, including legal fees. Vizibly has no obligation to continue to store any of your data after termination. 


5.	Confidentiality

Each party acknowledges that it may receive or have access to Confidential Information of the other party. Each party agrees to (i) take reasonable precautions to maintain the confidentiality of the other party’s Confidential Information and (ii) not use the other party’s Confidential Information except in performing its obligations under this Agreement. Each party may disclose the Confidential Information of the other party to those of its employees, representatives or agents having a need to know for Customer’s use of the Services, provided that any such employee, representative or agent is aware of the restrictions on use and disclosure contained herein and agree to be bound hereby.

“Confidential Information” means any business, technical, or financial information that is designated as “confidential” or “proprietary” at the time of disclosure or that, due to its nature or under the circumstances of disclosure, the receiving party knows or has reason to know should be treated as confidential. Vizibly’s Confidential Information includes, but is not limited to, non-public information regarding features, functionality, and performance of the Service. Confidential Information does not include anything that: (i) is or becomes publicly known and made generally available through no breach of this Agreement; (ii) was in the receiving party’s possession prior to receipt from the disclosing party; (iii) was rightfully disclosed to the receiving party without restriction by a third party; (iv) was independently developed without use of any Confidential Information of the disclosing party; or (v) is required to be disclosed by law. 

6.	Intellectual Property Rights and Ownership

6.1	Intellectual Property Rights.  You own all right, title, and interest in and to your data. Vizibly owns all right, title, and interest in and to (i) the Services, including all improvements, enhancements, and modifications to the Services; (ii) any anonymized data that is based on or derived from your data or data provided to you as part of the Services; (iii) any suggestions or other feedback that you provide to Vizibly about the Services or otherwise; and (iv) all Intellectual Property Rights related to (i) through (iii). 

“Intellectual Property Rights” means any and all tangible and intangible copyrights and other rights associated with works of authorship throughout the world; trademark and trade name rights and similar rights; trade secret rights, patents, designs, algorithms, utility models, and other industrial property rights, and all respective improvements; all other intellectual property rights whether arising by operation of law, contract, or otherwise; and all registrations, applications, renewals, extensions, continuations, divisions, or reissues now or in the future in force.

6.2	Data Use.  Vizibly has the right to collect and analyze your data and other information relating to the provision, use, and performance of the Services and related systems and technologies to (i) improve and enhance the Services and for other development or offerings, and (ii) disclose such data in an aggregate or other de-identified form in connection with Vizibly’s business.  The foregoing is not applicable to any personally identifiable data that Vizibly may collect incidentally in connection with your access to the Services.

7.	Limitation of Liability

Vizibly will not be liable to you for any indirect, incidental, special, consequential or exemplary damages (including damages for loss of profit, goodwill, use, or data). Vizibly will not be responsible for any compensation, reimbursement, or direct damages arising in connection with (i) your inability to use the Services; (ii) the cost of procurement of substitute goods or services; (iii) any investments, expenditures, or commitments by you in connection with this Agreement or your use of or access to the Services; or (iv) any unauthorized access to, alteration of, or deletion, destruction, damage, loss, or failure to store any of your content or other data. Vizibly’s aggregate liability under this Agreement will be limited to the amount you actually pay to Vizibly under this Agreement for the Services that gave rise to the claim during the 12 months immediately preceding the claim.

8.	No Warranty

Vizibly does not warrant that the Services will be uninterrupted or error free. Vizibly does not make any warranty as to the results that may be obtained from your use of the Services. The Services are provided “AS IS” and “AS AVAILABLE” and with no representation or warranty of any kind. Except to the extent prohibited by law, Vizibly disclaims all implied and statutory warranties, including any implied warranty of merchantability, fitness for a particular purpose, non-infringement, and any warranty arising out of any course of dealing or usage of trade.

9.	General

9.1	Entire Agreement.  This Agreement comprises the entire understanding between you and Vizibly relating to the subject matter of this Agreement. This Agreement supersede all prior or contemporaneous representations, understandings, agreements, or communications between you and Vizibly, whether written or verbal, regarding the subject matter of this Agreement. 

9.2	Choice of Law; Dispute Resolution.  This Agreement and any related action will be governed under the laws of the State of California and the United States, without regard to any conflicts of laws provisions. You consent to the exclusive jurisdiction and venue of state courts of the State of California for the County of Santa Clara and the United States District Court for the Northern District of California. The parties will first attempt to resolve any dispute arising out of or relating to this Agreement or the relationship created by this Agreement, through mandatory, non-binding mediation in Santa Clara County, California, using JAMS and conducted in accordance with the JAMS mediation procedures then in effect. In any action or proceeding to enforce rights under this Agreement, the prevailing party will be entitled to recover costs and legal fees. You acknowledge and agree that Vizibly has the right to seek preliminary injunctive relief without waiving or eliminating the mandatory arbitrated dispute resolution procedure described in this Section to protect its Intellectual Property Rights, preserve the status quo, or prevent irreparable injury.

9.3	Severability.  If any court of competent jurisdiction holds that any provision of this Agreement is unenforceable or invalid, that provision will be limited or eliminated to the minimum extent necessary so that this Agreement will otherwise remain in full force and effect. 

9.4	Assignment.  You will not assign this Agreement or delegate, transfer, or sublicense any of your rights under this Agreement without Vizibly’s prior written consent. Vizibly may transfer and assign any of its rights and obligations under this Agreement without consent.  

9.5	Waiver.  Vizibly’s failure to enforce any provision of this Agreement will not constitute a present or future waiver of that provision nor limit Vizibly’s right to enforce that provision at a later time.

9.6	Relationship of the Parties. No agency, partnership, joint venture, or employment is created as a result of this Agreement. You do not have any authority of any kind to bind Vizibly in any respect whatsoever. This Agreement does not create any third-party beneficiary rights in any individual or entity that is not a party to this Agreement. 

9.7	Publicity.  Vizibly may use your name and logo in promoting Vizibly’s services as a part of its website and in marketing material. 

9.8	Force Majeure.   Vizibly is not liable for any delay or failure to perform any obligation under this Agreement where the delay or failure results from any cause beyond Vizibly’s reasonable control, including acts of God, labor disputes or other industrial disturbances, systemic electrical, telecommunications, or other utility failures, earthquake, storms, or other elements of nature, blockages, embargoes, riots, acts or orders of government, and acts of terrorism or war.

9.9	Notice.  All communications and notices must be in English. To give Vizibly notice you must email support@vizibly.io. Vizibly may provide any notice to you by sending a message to the email address associated with your administrator account, which you will be deemed to have received when Vizibly sends the email, whether or not you actually receive the email. 

9.10	Amendments.  Vizibly may amend this Agreement at any time by posting a revised version on its website or by otherwise notifying you at the email address associated with your administrator account. Amendments become effective upon posting or as stated in the notice. By continuing to use the Services as of the effective date of any amendment to this Agreement, you agree to be bound by the amended terms of service. Vizibly last amended this Agreement on the date listed at the beginning of this Agreement.

9.11	Survival.  All sections of this Agreement which by their nature should survive termination will survive termination, including, without limitation, Sections 3 (Payment and Taxes), 4.3 (Effect of Termination), 5 (Confidentiality), 6 (Intellectual Property Rights and Ownership) 7 (Limitation of Liability), 8 (No Warranty), and 9.7 (Publicity).
`;
    return <pre style={{whiteSpace: "pre-wrap"}}>{terms}</pre>;
};

export default Terms;