import GC from '@grapecity/spread-sheets';
import DeleteButton from '../assets/icons/trash.svg';
import LockButton from '../assets/icons/lock-on/lock-on.svg';
import LockOffButton from '../assets/icons/lock-off/lock-off.svg';

import { VZTDStyle, VZTRStyle } from '../components/vztable/reconciler/ReconcilerSpreadJS';
export const WHITE_BORDER = new GC.Spread.Sheets.LineBorder('#FFFFFF', GC.Spread.Sheets.LineStyle.thin);
export const GRAY_BORDER = new GC.Spread.Sheets.LineBorder('#F2F2F2', GC.Spread.Sheets.LineStyle.thin);
export const GREEN_BORDER = new GC.Spread.Sheets.LineBorder('#78BCBC', GC.Spread.Sheets.LineStyle.thin);
export const DARK_GRAY_BORDER = new GC.Spread.Sheets.LineBorder('#DDDDDD', GC.Spread.Sheets.LineStyle.medium);
export const DARK_GRAY_BORDER_MED = new GC.Spread.Sheets.LineBorder('#9C9C9C', GC.Spread.Sheets.LineStyle.medium);

export const HALIGN_LEFT: VZTDStyle = {
    hAlign: GC.Spread.Sheets.HorizontalAlign.left,
};

export const HALIGN_CENTER: VZTDStyle = {
    hAlign: GC.Spread.Sheets.HorizontalAlign.center,
};

export const HALIGN_RIGHT: VZTDStyle = {
    hAlign: GC.Spread.Sheets.HorizontalAlign.right,
};

export const VALIGN_TOP: VZTDStyle = {
    vAlign: GC.Spread.Sheets.VerticalAlign.top,
};

export const VALIGN_CENTER: VZTDStyle = {
    vAlign: GC.Spread.Sheets.VerticalAlign.center,
};

export const VALIGN_BOTTOM: VZTDStyle = {
    vAlign: GC.Spread.Sheets.VerticalAlign.bottom,
};

export const BORDER_TOP_WHITE: VZTDStyle = {
    borderTop: WHITE_BORDER
};

export const BORDER_BOTTOM_WHITE: VZTDStyle = {
    borderBottom: WHITE_BORDER
};

export const BORDER_LEFT_WHITE: VZTDStyle = {
    borderLeft: WHITE_BORDER
};

export const BORDER_RIGHT_WHITE: VZTDStyle = {
    borderRight: WHITE_BORDER
};

export const BORDER_TOP_GRAY: VZTDStyle = {
    borderTop: GRAY_BORDER
};

export const BORDER_BOTTOM_GRAY: VZTDStyle = {
    borderBottom: GRAY_BORDER
};

export const BORDER_LEFT_GRAY: VZTDStyle = {
    borderLeft: GRAY_BORDER
};

export const BORDER_RIGHT_GRAY: VZTDStyle = {
    borderRight: GRAY_BORDER
};

// header style
export const HEADER_FIRST_COL_STYLE: VZTDStyle = {
    font: '500 12px Inter',
    hAlign: GC.Spread.Sheets.HorizontalAlign.left,
    vAlign: GC.Spread.Sheets.VerticalAlign.center,
    width: 125,
    cellPadding: '0 0 0 12',
    borderRight: WHITE_BORDER,
    borderLeft: WHITE_BORDER,
};

export const HEADER_STYLE: VZTDStyle = {
    font: '400 12px Inter',
    hAlign: GC.Spread.Sheets.HorizontalAlign.center,
    vAlign: GC.Spread.Sheets.VerticalAlign.center,
    width: 70,
    borderRight: WHITE_BORDER,
    borderLeft: WHITE_BORDER,
};

export const HEADER_TOTAL_COL_STYLE = {
    font: '500 12px Inter',
    hAlign: GC.Spread.Sheets.HorizontalAlign.center,
    vAlign: GC.Spread.Sheets.VerticalAlign.center,
    // width: 180,
    width: 136,
    borderRight: WHITE_BORDER,
    borderLeft: WHITE_BORDER,
}

// Account view styles (may want to re-use it in revenue workflow)
export const DATA_CELL_DEFAULTS: VZTDStyle = {
    cellPadding: '0 0 0 4',
    hAlign: GC.Spread.Sheets.HorizontalAlign.center,
    vAlign: GC.Spread.Sheets.VerticalAlign.center,
    formatter: '###,###,###'

};

export const FIRST_ROW_FIRST_COL_STYLE: VZTDStyle = {
    font: '500 12px Inter',
    cellPadding: '0 0 0 12',
    hAlign: GC.Spread.Sheets.HorizontalAlign.left,
    vAlign: GC.Spread.Sheets.VerticalAlign.center,
    borderTop: GRAY_BORDER,
    // borderTop: PINK_BORDER,
    borderBottom: GRAY_BORDER,
};


export const FIRST_COL_STYLE: VZTDStyle = {
    font: '300 12px Inter',
    foreColor: '#807A7A',
    cellPadding: '0 0 0 12',
    hAlign: GC.Spread.Sheets.HorizontalAlign.left,
    vAlign: GC.Spread.Sheets.VerticalAlign.center,
    borderTop: GRAY_BORDER,
    borderBottom: GRAY_BORDER,
    borderRight: GRAY_BORDER,
    borderLeft: GRAY_BORDER,
};

export const ROW_HEIGHT = 47;

export const DATA_ROW_STYLE: VZTRStyle = {
    height: ROW_HEIGHT,
};

export const DATA_CELL_STYLE: VZTDStyle = {
    ...DATA_CELL_DEFAULTS,
    font: '300 12px Inter',
    foreColor: '#807A7A',
    borderBottom: GRAY_BORDER,
    borderRight: GRAY_BORDER,
    borderLeft: GRAY_BORDER,
    formatter: '###,###,###',
};

export const DATA_CELL_PERCENT_FORMATTER: VZTDStyle = {
    formatter: "0.0%"
};


export const DATA_CELL_TOTAL_STYLE: VZTDStyle = {
    ...DATA_CELL_DEFAULTS,
    font: '500 12px Inter',
    foreColor: 'black',
    hAlign: GC.Spread.Sheets.HorizontalAlign.center,
    vAlign: GC.Spread.Sheets.VerticalAlign.center,
    borderTop: GRAY_BORDER,
    borderBottom: GRAY_BORDER,
    borderRight: GRAY_BORDER,
    formatter: '###,###,###',
};

export const DATA_CELL_EMPTY_STYLE: VZTDStyle = {
    // font: '300 12px Inter',
    font: '500 12px Inter',
    foreColor: '#107F88',
    hAlign: GC.Spread.Sheets.HorizontalAlign.center,
    vAlign: GC.Spread.Sheets.VerticalAlign.center,
    borderTop: GREEN_BORDER,
    borderBottom: GREEN_BORDER,
    borderRight: GREEN_BORDER,
    borderLeft: GREEN_BORDER,
    formatter: '###,###,###',
    locked: false,
};

export const DATA_CELL_ENTERED_STYLE: VZTDStyle = {
    ...DATA_CELL_DEFAULTS,
    // font: '300 12px Inter',
    font: '500 12px Inter',
    foreColor: '#107F88',
    borderTop: GRAY_BORDER,
    borderBottom: GRAY_BORDER,
    borderRight: GRAY_BORDER,
    borderLeft: GRAY_BORDER,
    formatter: '###,###,###',
    locked: false,
};

export const ADD_ITEM_BUTTON_STYLE: VZTDStyle = {
    foreColor: '#107F88',
    font: '400 14px Inter',
    cellPadding: '0 0 0 12',
    vAlign: GC.Spread.Sheets.VerticalAlign.center,
};

export const ADD_ITEM_LINE_CELL_STYLE: VZTDStyle = {
    borderLeft: WHITE_BORDER,
    borderRight: WHITE_BORDER,
    // borderBottom: DARK_GRAY_BORDER,
};



export const DATA_CELL_ACTUAL_STYLE: VZTDStyle = {
    ...DATA_CELL_DEFAULTS,
    // font: '300 12px Inter',
    font: '500 12px Inter',
    foreColor: 'black',
    borderTop: GRAY_BORDER,
    borderBottom: GRAY_BORDER,
    borderLeft: GRAY_BORDER,
    formatter: '###,###,###',
};

export const DATA_CELL_EMPTY_DRIVER_STYLE: VZTDStyle = {
    ...DATA_CELL_DEFAULTS,
    font: '500 12px Inter',
    foreColor: 'black',
    backColor: '#F8F8F8',
    borderTop: DARK_GRAY_BORDER,
    borderBottom: DARK_GRAY_BORDER,
    borderLeft: DARK_GRAY_BORDER,
    borderRight: DARK_GRAY_BORDER,
    formatter: '###,###,###',
    locked: true,
};

// worksheet assumptions styles - shared between account view and assumptions management
// for styling worksheet
export const DESCRIPTION_STYLE: VZTDStyle = {
    ...DATA_CELL_DEFAULTS,
    font: '500 12px Inter',
    borderTop: GRAY_BORDER,
    borderBottom: GRAY_BORDER,
    borderRight: WHITE_BORDER,
};

export const WHITE_BORDERS: VZTDStyle = {
    borderTop: WHITE_BORDER,
    borderBottom: WHITE_BORDER,
    borderLeft: WHITE_BORDER,
    borderRight: WHITE_BORDER,
}

export const DELETE_BUTTON_STYLE: VZTDStyle = {
    ...DATA_CELL_DEFAULTS,
    font: '500 12px Inter',
    borderTop: GRAY_BORDER,
    borderBottom: GRAY_BORDER,
    borderRight: GRAY_BORDER,
    backgroundImage: DeleteButton,
};

export const LOCKED_BUTTON_STYLE: VZTDStyle = {
    ...DATA_CELL_DEFAULTS,
    font: '500 12px Inter',
    borderTop: GRAY_BORDER,
    borderBottom: GRAY_BORDER,
    borderRight: GRAY_BORDER,
    backgroundImage: LockButton,
};

export const UNLOCKED_BUTTON_STYLE: VZTDStyle = {
    ...DATA_CELL_DEFAULTS,
    font: '500 12px Inter',
    borderTop: GRAY_BORDER,
    borderBottom: GRAY_BORDER,
    borderRight: GRAY_BORDER,
    backgroundImage: LockOffButton,
};

export const NO_DATA_AVAILABLE_STYLE: VZTDStyle = {
    font: '900 20px Inter',
    foreColor: '#ECECEC',
    hAlign: GC.Spread.Sheets.HorizontalAlign.center,
    vAlign: GC.Spread.Sheets.VerticalAlign.center,
    borderTop: GRAY_BORDER,
    borderBottom: GRAY_BORDER,
    borderLeft: GRAY_BORDER,
    locked: true,
};

export const TOTAL_EMPTY_CELL_STYLE: VZTDStyle = {
    borderTop: WHITE_BORDER,
    borderBottom: WHITE_BORDER,
    borderRight: WHITE_BORDER,
};

export const DATA_EMPTY_CELL_STYLE: VZTDStyle = {
    ...DATA_CELL_DEFAULTS,
    foreColor: '#107F88',
    font: '300 12px Inter',
    borderTop: GREEN_BORDER,
    borderBottom: GREEN_BORDER,
    borderLeft: GREEN_BORDER,
    borderRight: GREEN_BORDER,
    locked: false,
};

export const DATA_ENTERED_EDITABLE_CELL_STYLE: VZTDStyle = {
    ...DATA_CELL_DEFAULTS,
    foreColor: '#107F88',
    font: '400 12px Inter',
    borderTop: GRAY_BORDER,
    borderBottom: GRAY_BORDER,
    borderLeft: GRAY_BORDER,
    borderRight: GRAY_BORDER,
    locked: false,
};

export const DATA_ENTERED_LOCKED_CELL_STYLE: VZTDStyle = {
    ...DATA_CELL_DEFAULTS,
    foreColor: '#9C9C9C',
    font: '300 12px Inter',
    backColor: '#F8F8F8',
    borderTop: GRAY_BORDER,
    borderBottom: GRAY_BORDER,
    borderLeft: GRAY_BORDER,
    borderRight: GRAY_BORDER,
    locked: true,
};

// BORDER WITH WORKSHEET STYLE:
export const FIRST_ROW_FIRST_COL_STYLE_WS: VZTDStyle = {
    font: '500 12px Inter',
    cellPadding: '0 0 0 12',
    hAlign: GC.Spread.Sheets.HorizontalAlign.left,
    vAlign: GC.Spread.Sheets.VerticalAlign.center,
    borderTop: DARK_GRAY_BORDER_MED,
    borderBottom: GRAY_BORDER,
};

export const DATA_CELL_EMPTY_STYLE_WS: VZTDStyle = {
    font: '500 12px Inter',
    foreColor: '#107F88',
    hAlign: GC.Spread.Sheets.HorizontalAlign.center,
    vAlign: GC.Spread.Sheets.VerticalAlign.center,
    borderTop: DARK_GRAY_BORDER_MED,
    borderBottom: GREEN_BORDER,
    borderRight: GREEN_BORDER,
    borderLeft: GREEN_BORDER,
    formatter: '###,###,###',
    locked: false,
};

export const DATA_CELL_ENTERED_STYLE_WS: VZTDStyle = {
    ...DATA_CELL_DEFAULTS,
    font: '500 12px Inter',
    foreColor: '#107F88',
    borderTop: DARK_GRAY_BORDER_MED,
    borderBottom: GRAY_BORDER,
    borderRight: GRAY_BORDER,
    borderLeft: GRAY_BORDER,
    formatter: '###,###,###',
    locked: false,
};

export const DATA_CELL_ACTUAL_STYLE_WS: VZTDStyle = {
    ...DATA_CELL_DEFAULTS,
    font: '500 12px Inter',
    foreColor: 'black',
    borderTop: DARK_GRAY_BORDER_MED,
    borderBottom: GRAY_BORDER,
    borderLeft: GRAY_BORDER,
    formatter: '###,###,###',
};

export const DATA_CELL_EMPTY_DRIVER_STYLE_WS: VZTDStyle = {
    ...DATA_CELL_DEFAULTS,
    font: '500 12px Inter',
    foreColor: 'black',
    backColor: '#F8F8F8',
    borderTop: DARK_GRAY_BORDER_MED,
    borderBottom: DARK_GRAY_BORDER,
    borderLeft: DARK_GRAY_BORDER,
    borderRight: DARK_GRAY_BORDER,
    formatter: '###,###,###',
    locked: true,
};

export const DATA_CELL_TOTAL_STYLE_WS: VZTDStyle = {
    ...DATA_CELL_DEFAULTS,
    font: '500 12px Inter',
    foreColor: 'black',
    hAlign: GC.Spread.Sheets.HorizontalAlign.center,
    vAlign: GC.Spread.Sheets.VerticalAlign.center,
    borderTop: DARK_GRAY_BORDER_MED,
    borderBottom: GRAY_BORDER,
    borderRight: GRAY_BORDER,
    formatter: '###,###,###',
};
// END OF WORKSHEET STYLES
