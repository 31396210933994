import { useEffect, useState } from "react";
import { TreeNode } from "../components/tree/Tree";
import {
    AcctRenovationPackagesQuery,
    RevenueModelType,
    useAcctRenovationPackagesLazyQuery,
    VersionType
} from "../__generated__/generated_types";
import { BudgetingType } from "../BudgetingType";
import { propertyHasRevenueModel, UnitType, useProperties } from "../contexts/properties/PropertiesContext";

export type UseRenovationsWorkflowProps = {
    categoryType?:string,
}

export type UseRenovationsWorkflowReturn = {
    renovationsWorkflow: TreeNode[],
}

export function useRenovationsWorkflow(props: UseRenovationsWorkflowProps):UseRenovationsWorkflowReturn{

    const { unitTypes, currentProperty, loaded: propertiesLoaded } = useProperties();

    const [renovationsWorkflow, setRenovationsWorkflow] = useState<Array<TreeNode>>([]);

    const [getRenovationsData, {data: renovationsData, loading: renovationsDataLoading}] = useAcctRenovationPackagesLazyQuery({
        fetchPolicy: "network-only"
    });
    const [renovationData, setRenovationData] = useState<AcctRenovationPackagesQuery | undefined>(undefined);

    useEffect(() => {
        if(!currentProperty || !propertyHasRevenueModel(currentProperty, RevenueModelType.Original)) {
           setRenovationsWorkflow([]);
        } else {
            getRenovationsData({
                variables: {
                    propertyIds: [currentProperty.id],
                    yearAndType: {
                        versionType: props.categoryType === BudgetingType.BUDGET ? VersionType.Budget : VersionType.Reforecast,
                        year: (props.categoryType === BudgetingType.BUDGET ? currentProperty?.budgetYear : currentProperty?.reforecastYear) ?? 0
                    }
                }
            });
        }
    }, [currentProperty]);

    useEffect(() => {
        if(renovationsData && !renovationsDataLoading) {
            setRenovationData(renovationsData);
        }
    }, [renovationsData, renovationsDataLoading]);

    const getRenovationWorkflow = (renoData: AcctRenovationPackagesQuery):TreeNode[] => {
        // Gather the list of units with packages
        const unitsWithPackages = new Set(renoData.queryRenovationPackages.map(pkg => pkg.unitTypeId));//Array.from();

        const treeNodeChildren:TreeNode[] = unitTypes.sort(
            (a:UnitType, b:UnitType)=>{ return a.type.localeCompare(b.type);}
        ).reduce(function(result:TreeNode[], unitType) {
            if (unitsWithPackages.has(unitType.id)) {
                result.push({
                    title: unitType.type,
                    url: `/renovations/${unitType.id}`,
                });
            }
            return result;
        }, []);

        // Renovation Schedule section has Unit Types as children
        return [{
            title: 'Renovations',
            children: treeNodeChildren,
        }];
    };

    useEffect(
        () => {
            if(!renovationData || !propertiesLoaded){
                return;
            }

            if(currentProperty?.unitLevelModelingEnabled) {
                setRenovationsWorkflow([]);
                return;
            }

            const renovationsWorkflow = getRenovationWorkflow(renovationData);
            setRenovationsWorkflow(renovationsWorkflow);
        },
        [renovationData, currentProperty, propertiesLoaded]
    );

    return {
        renovationsWorkflow,
    };
}
