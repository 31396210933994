import {useEffect, useState} from "react";
import {useListRenovationPackagesLazyQuery} from "../../../../__generated__/generated_types";
import {Property} from "../../../../contexts/properties/PropertiesContext";
import {TClassicToRenovatedAssociationsData, TSimplifiedRevenueUnitType, mapToClassicToRenovatedAssociations} from "./classicToRenovatedAssociationsLogic";

export interface IClassicToRenovatedAssociationsDataProps {
    property: Property | undefined,
    unitTypes: TSimplifiedRevenueUnitType[]
}

export function useClassicToRenovatedAssociationsData(
    {property, unitTypes}: IClassicToRenovatedAssociationsDataProps): TClassicToRenovatedAssociationsData & {refetch: () => void} {
    const [getRenovationPackages, {data}] = useListRenovationPackagesLazyQuery({
        fetchPolicy: "network-only",
        notifyOnNetworkStatusChange: true,
});

    const [state, setState] = useState<TClassicToRenovatedAssociationsData>({rows: [], unmappedUnitTypes: [], unrenovatedUnitTypes: []});

    function refetch(): void {
        if (!property) {
            return;
        }

        getRenovationPackages({
            variables: {
                propertyIds: [property.id],
                budgetYear: property.budgetYear
            }
        });
    }

    useEffect(
        () => {
            if (property == undefined) {
                return;
            }

            getRenovationPackages({
                variables: {
                    propertyIds: [property.id],
                    budgetYear: property.budgetYear
                }
            });
        },
        [property?.id]
    );

    useEffect(
        () => {
            if (data == undefined) {
                return;
            }

            const mapResult = mapToClassicToRenovatedAssociations(data, unitTypes);
            setState(mapResult);
        },
        [data]
    );


    return {
        ...state,
        refetch,
    };
}