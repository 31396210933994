import {ReactElement, useEffect, useState} from 'react';
import {Col, Row} from '@zendeskgarden/react-grid';
import spStyles from './StartPlanning.module.scss';
import {useChartOfAccounts} from '../../contexts/chartofaccounts/ChartOfAccountsContext';
import Loading from '../../components/loading/Loadiing';
import Accounts from "../../assets/icons/account/sp_accounts.svg";
import Revenue from "../../assets/icons/revenue/sp_revenue.svg";
import Payroll from "../../assets/icons/payroll/sp_payroll.svg";
import {Link} from 'react-router-dom';
import {useRoutingUtils} from "../../routes/RoutingUtils";

import {Authorizations} from "../../authorization/Authorizations";
import {RenderIfAuthorized} from "../../authorization/RenderIfAuthorized";
import {useProperties} from "../../contexts/properties/PropertiesContext";
import {useConfig} from '../../hooks/useConfig';
import {VersionType} from '../../__generated__/generated_types';

export default function StartPlanningCards(): ReactElement {
    const {accounts, loading} = useChartOfAccounts();
    const config = useConfig();
    const {goto} = useRoutingUtils();
    const {currentProperty} = useProperties();
    const [versionTypeString, setVersionTypeString] = useState<string | undefined>();
    let firstAccountId;

    if (!loading && accounts) {
        firstAccountId = accounts[0]?.id;
    }

    useEffect(() => {
        if (!config.isReady) {
            return;
        }

        setVersionTypeString(config.versions.selectedVersionType == VersionType.Reforecast ? "reforecast" : "budget");
    }, [config.isReady]);

    if (!config.isReady || !versionTypeString) {
        return <></>;
    }

    return (
        <>
            {loading && <Loading />}
            {accounts &&
                <div className={spStyles.spContainer}>
                    <Row justifyContent="center">
                        <Col textAlign={"center"} >
                            {currentProperty?.unitLevelModelingEnabled ?
                                <div className={`${spStyles.startPlanningCard} ${spStyles.revenue}`}
                                        onClick={(goto(`/revenue`))}>
                                    <div>
                                        <img width={150} src={Revenue} alt="revenue-icon"/>
                                    </div>
                                    <Link to="/revenue">
                                        <button className={spStyles.spBtn}>Revenue</button>
                                    </Link>
                                </div>
                                :
                                <div className={`${spStyles.startPlanningCard} ${spStyles.revenue}`}
                                        onClick={(goto(`/operational/average-rent/${versionTypeString}`))}>
                                    <div>
                                        <img width={150} src={Revenue} alt="revenue-icon"/>
                                    </div>
                                    <Link to={`/operational/average-rent/${versionTypeString}`}>
                                        <button className={spStyles.spBtn}>Revenue</button>
                                    </Link>
                                </div>
                            }
                        </Col>
                        <RenderIfAuthorized required={Authorizations.PlanningHubPayroll}>
                            <Col textAlign={"center"} >
                                <div className={`${spStyles.startPlanningCard} ${spStyles.payroll}`}
                                        onClick={(goto('/payroll'))}>
                                    <div>
                                        <img width={150} src={Payroll} alt="payroll-icon" />
                                    </div>
                                    <Link to={`/payroll`}>
                                        <button className={spStyles.spBtn}>Payroll</button>
                                    </Link>
                                </div>
                            </Col>
                        </RenderIfAuthorized>
                        <Col textAlign={"center"}>
                            <div className={`${spStyles.startPlanningCard} ${spStyles.accounts}`}
                                    onClick={(goto(`/account/${firstAccountId}/${versionTypeString}`))}>
                                <div>
                                    <img width={150} src={Accounts} alt="account-icon" />
                                </div>
                                <Link to={`/account/${firstAccountId}/${versionTypeString}`}>
                                    <button className={spStyles.spBtn}>Accounts</button>
                                </Link>
                            </div>
                        </Col>
                    </Row>
                </div>
            }
        </>
    );
}
