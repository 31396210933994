export enum AnalystRenovationEntityType {
    PROPERTY = 'PROPERTY',
    UNIT_TYPE = 'UNIT_TYPE',
    PACKAGE = 'PACKAGE'
}

export type RenovationSetupDrawerMetadata = {
    propertyId: string;
    unitTypeId: string;
}
