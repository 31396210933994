import { useState, useEffect, useRef } from 'react';

import * as yup from 'yup';
import YupPassword from 'yup-password';
import { useGetCaseSensitiveEmailLazyQuery, useGetWelcomeUnauthorizedUserLazyQuery } from '../__generated__/generated_types';
YupPassword(yup);

export const useValidEmail = (initialValue: string) => {
    const [email, setEmail] = useState(initialValue);
    const [emailIsValid, setEmailIsValid] = useState(true);

    useEffect(() => {
        const emailSchema = yup.object().shape({
            email: yup.string().email().required(),
        });

        if (email.length === 0) {
            setEmailIsValid(true);
            return;
        }

        const isValid = emailSchema.isValidSync({ email });

        setEmailIsValid(isValid);
    }, [email]);

    return { email, setEmail, emailIsValid };
};

export const useValidPassword = (initialValue: string) => {
    const [password, setPassword] = useState(initialValue);
    const [passwordIsValid, setPasswordIsValid] = useState(true);

    useEffect(() => {
        const passwordSchema = yup.object().shape({
            password: yup.string().min(8).required(),
            // password: yup.string().min(8).minSymbols(1, "Must include a special character").minNumbers(1, "Must include a number"),

        });

        if (password.length === 0) {
            setPasswordIsValid(true);
            return;
        }
        const isValid = passwordSchema.isValidSync({ password });

        setPasswordIsValid(isValid);
    }, [password]);

    return { password, setPassword, passwordIsValid };
};

export const useValidUsername = (initialValue: string,) => {
    const [username, setUsernameOut] = useState(initialValue);
    const [usernameIn, setUsername] = useState(initialValue);
    const [usernameIsValid, setUsernameIsValid] = useState(true);
    const [getEmail, { data, loading, error }] = useGetCaseSensitiveEmailLazyQuery();

    useEffect(() => {
        setUsernameOut(usernameIn);

        const emailDebounce = setTimeout(() => {
            const usernameSchema = yup.object().shape({
                usernameIn: yup.string().min(8).required(),
            });

            if (usernameIn.length === 0) {
                setUsernameIsValid(true);
                setUsernameOut("");
                return;
            }

            const isValid = usernameSchema.isValidSync({ usernameIn });

            if (!isValid) {
                setUsernameIsValid(false);
                setUsernameOut(usernameIn);
                return;
            }

            getEmail({
                variables: {
                    email: usernameIn,
                }
            });
        }, 500);

        return () => {
            if (emailDebounce) {
                clearTimeout(emailDebounce);
            }
        };
    }, [usernameIn]);

    useEffect(
        () => {
            if (!data || loading) {
                return;
            }

            if (error) { // user does not exist
                setUsernameIsValid(true);
                setUsernameOut(usernameIn);
                return;
            }
            setUsernameOut(data.caseSensitiveEmail);
            setUsernameIsValid(true);
        },
        [data, loading]
    );


    return { username, setUsername, usernameIsValid };
};



export const useValidCode = (initialValue: string) => {
    const [code, setCode] = useState(initialValue);
    const [codeIsValid, setCodeIsValid] = useState(true);

    useEffect(() => {
        const codeSchema = yup.object().shape({
            code: yup.string().min(6).required(),
        });

        if (code.length === 0) {
            setCodeIsValid(true);
            return;
        }

        const isValid = codeSchema.isValidSync({ code });

        setCodeIsValid(isValid);
    }, [code]);

    return { code, setCode, codeIsValid };
};

export const useWelcomeUser = (userId: string | undefined) => {
    const [username, setUsername] = useState<string>();
    const [fullName, setFullName] = useState<string>();

    const [loadUser, { data, loading }] = useGetWelcomeUnauthorizedUserLazyQuery();
    useEffect(
        () => {
            if (!data || loading) {
                return;
            }
            const user = data.welcomeUnauthorizedUser;
            setUsername(user.email);
            setFullName(user.firstName + " " + user.lastName);
        },
        [data, loading]
    );

    useEffect(
        () => {
            if (userId) {
                loadUser({
                    variables: {
                        userId: userId
                    }
                });
            }
        },
        [userId]
    );

    return { username, fullName };
};

