import { ReactElement } from "react";
import loginCartoon from "../../assets/icons/login/loginCartoon.svg";


export default function LoginDesign(): ReactElement {
    return (
        <>
            <div className="right-side right-side-login right-side-image-login">
                <div className="login-image-wrapper">
                    <img className="login-image" src={loginCartoon} alt="assestsPerformance"/>
                    <div className="optimize-text">Optimize the performance of <br/> your assets.</div>
                </div>
            </div>
        </>
    );
}

