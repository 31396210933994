import FileSaver from "file-saver";
import { AppConfig } from "../../../hooks/useConfig";
import { ExportPropertyBudgetT12ReportQuery } from "../../../__generated__/generated_types";
import {
    formatForExcelT12Report,
} from "./formatters";

export function useExportT12ReportHook() {
    const exportExcel = (
        data: ExportPropertyBudgetT12ReportQuery,
        config: AppConfig
    ) => {
        let formattedDataPromise;
        formattedDataPromise = formatForExcelT12Report({
            data: data,
            config: config
        });

        if (formattedDataPromise) {
            formattedDataPromise.then(saveData => {
                const fileName = `${config.year + 1} Budget - ${config.properties.currentProperty?.name ?? ""}`;
                FileSaver.saveAs(saveData, `${fileName}.xlsx`);
            });
        }
    };

    return exportExcel;
}