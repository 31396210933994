import React, { Children, ReactElement, ReactNode } from "react";
import * as css from "./styles/css.module.scss";

interface HeaderColumnProps {
    children: ReactNode[] | ReactNode
    className?:string
}

interface PageHeaderProps {
    children: ReactElement[] | ReactElement,
    className?: string,
}

function LeftCol(props: HeaderColumnProps): ReactElement{
    return (
            <span className={props.className ? `${css.leftCol} ${props.className}` : css.leftCol}>
                {props.children}
            </span>
    );
}

function RightCol(props: HeaderColumnProps): ReactElement{
    return (
            <span className={props.className ? `${css.rightCol} ${props.className}` : css.rightCol}>
                {props.children}
            </span>
    );
}

export default function PageHeader(props: PageHeaderProps):ReactElement{
    let leftCol = <></>;
    let rightCol = <></>;

    Children.forEach(props.children, child => {
        if(child.type === LeftCol) {
            leftCol = child;
        }
        if(child.type === RightCol) {
            rightCol = child;
        }
    });

    return (
            <div className={props.className ? `${css.headerRow} ${props.className}` : `${css.headerRow}`}>
                {leftCol}
                {rightCol}
            </div>
    );
}

PageHeader.LeftCol = LeftCol;
PageHeader.RightCol = RightCol;