// TODO: DEPRECATED. KILL SOON


import { AnalystSections } from "../../../components/analyst/section-tabs/AnalystSectionTabs";
import { AnalystDashboardProperties } from "../../analyst/AnalystDashboardProperties";
import React, { ReactElement, useEffect, useState } from "react";
import Financials from "./Financials";

import * as phubV1ShimStyles from "./styles/phubV1Shim.module.scss";

export type FinancialsV1ShimProps = {
    reportLoadProgress: (progressAmt: number) => void;
    style: React.CSSProperties;
    setSectionReady: (sectionId: AnalystSections, isReady: boolean) => void,
    propertiesFilter?: AnalystDashboardProperties,
}

export enum FinancialsLoadPhases {
    FINANCIALS_MOUNT = 'FINANCIALS_MOUNT',
    BUILD_FINANCIALS_DATA = 'BUILD_FINANCIALS_DATA',
    FETCH_ENTITY_YEAR_VALUES = 'FETCH_ENTITY_YEAR_VALUES',
    OPENING_ROW = 'OPENING_ROW',
}

export default function FinancialsV2PlanningHubV1Shim(props: FinancialsV1ShimProps):ReactElement{

    const { reportLoadProgress, style, setSectionReady } = props;

    const reportLoadProgressShim = (progress:number|string):void => {
        let useProgressAmt = 0;

        if(typeof progress == 'number'){
            useProgressAmt = progress;
        } else {
            switch(progress){
                case FinancialsLoadPhases.FINANCIALS_MOUNT:{
                    useProgressAmt = 5;
                    break;
                }
                case FinancialsLoadPhases.BUILD_FINANCIALS_DATA:{
                    useProgressAmt = 10;
                    break;
                }
                case FinancialsLoadPhases.FETCH_ENTITY_YEAR_VALUES:{
                    useProgressAmt = 20;
                    break;
                }
                case FinancialsLoadPhases.OPENING_ROW:{
                    useProgressAmt = 75;
                    break;
                }
            }
        }

        if(useProgressAmt > 0 && useProgressAmt < 100){
            showLoadingOverlay(true);
        } else if(useProgressAmt == 100){
            if(!shimSectionReady){
                setShimSectionReady(true);
            }
            showLoadingOverlay(false);
        }

        reportLoadProgress(useProgressAmt);
    };

    const [shimSectionReady, setShimSectionReady] = useState<boolean>(false);
    const [loadingOverlay, showLoadingOverlay] = useState<boolean>(false);

    useEffect(
            ()=>{
                // Start perceived load at 10%
                // reportLoadProgressShim(FinancialsLoadPhases.FINANCIALS_MOUNT);

                // Note: v2 pages handle their own shimmer, so can report as ready to v1
                setSectionReady(AnalystSections.Financials, true);
            },
            []
    );
    return (
            <div className={phubV1ShimStyles.tabBody}>
                {(shimSectionReady && loadingOverlay)
                        ? <div className={phubV1ShimStyles.loadingOverlay}/>
                        : <></>
                }
                <Financials
                        reportLoadProgress={reportLoadProgressShim}
                        style={style}
                />
            </div>
    );
}