import GC from "@grapecity/spread-sheets";
import { drawTriangle, isInsideSquare } from "./icon-renderers/draw-triangle";
import { ICustomCellTargetHitInfo } from "./types";

const PADDING = 3;

export class CustomLink extends GC.Spread.Sheets.CellTypes.Base {
    constructor(private isOverride = false) {
        super();
        this.typeName = "CustomLink";
    }

    drawUnderline = false;
    showPointer = false;

    override paint(ctx: CanvasRenderingContext2D, value: any, x: number, y: number, w: number, h: number, style: GC.Spread.Sheets.Style, context?: any): void {
        if (this.isOverride) {
            style.backColor = "#F3E9EF"; // update background before letting parent class to use it
        }
        super.paint(ctx, value, x, y, w, h, style, context);

        if (this.showPointer) {
            const spread = context.sheet.getParent();
            let canvasEl: any = spread
                .getHost()
                .querySelector('[gcuielement="gcWorksheetCanvas"]');
            if (canvasEl && canvasEl.style.cursor === "default") {
                canvasEl.style.cursor = "pointer";
            }
        }
    }


    override paintContent(ctx: CanvasRenderingContext2D, value: any, x: number, y: number, w: number, h: number, style: GC.Spread.Sheets.Style, context?: any): void {
        const txt = this.format(value, "", undefined, context);

        ctx.font = style.font ?? "";
        if (style.foreColor) {
            ctx.fillStyle = style.foreColor;
        }
        ctx.textAlign = "center";
        ctx.textBaseline = "middle";

        const measure = ctx.measureText(txt);
        const [width, height] = [measure.width, measure.actualBoundingBoxAscent + measure.actualBoundingBoxDescent];

        const textCenterX = x + w / 2;
        const textCenterY = y + h / 2;
        const textLeftX = textCenterX - width / 2;
        const textBottomY = textCenterY + height / 2;
        ctx.fillText(txt, textCenterX, textCenterY);

        if (this.drawUnderline) {
            ctx.beginPath();
            ctx.strokeStyle = "black";
            ctx.moveTo(textLeftX, textBottomY + PADDING);
            ctx.lineTo(textLeftX + width, textBottomY + PADDING);
            ctx.stroke();
        }

        if (this.isOverride) {
            drawTriangle(ctx, x, y, w, h);
        }

    }

    override format(value: any, format: string | GC.Spread.Formatter.GeneralFormatter, formattedData?: GC.Spread.Sheets.FormattedData, context?: any): string {
        const sheet = context.sheet as GC.Spread.Sheets.Worksheet;
        const [row, col] = [context.row as number, context.col as number];
        const formatter = sheet.getCell(row, col).formatter();
        if (formatter) {
            const formatted = super.format(value, formatter);

            return formatted;
        }

        return super.format(value, format, formattedData, context);
    }

    override getHitInfo(x: number, y: number, cellStyle: GC.Spread.Sheets.Style, cellRect: GC.Spread.Sheets.Rect, context?: any): GC.Spread.Sheets.IHitTestCellTypeHitInfo {
        let canvasEl: any = context.sheet.getParent()
            .getHost()
            .querySelector('[gcuielement="gcWorksheetCanvas"]');
        const ctx = canvasEl.getContext("2d");
        const sheet = context.sheet as GC.Spread.Sheets.Worksheet;

        const [row, col] = [context.row as number, context.col as number];
        const value = sheet.getCell(row, col).value();
        const txt = this.format(value, "", undefined, context);

        ctx.font = cellStyle.font ?? "";
        ctx.textAlign = "center";
        ctx.textBaseline = "middle";

        const measure = ctx.measureText(txt);
        const [width, height] = [measure.width, measure.actualBoundingBoxAscent + measure.actualBoundingBoxDescent];

        const textCenterX = cellRect.x + cellRect.width / 2;
        const textCenterY = cellRect.y + cellRect.height / 2;
        const textLeftX = textCenterX - width / 2;
        const textBottomY = textCenterY + height / 2;
        const c = cellRect; // alias
        const isTriangleHit = isInsideSquare(x, y, c.x, c.y, c.width, c.height) && this.isOverride;
        const info: ICustomCellTargetHitInfo = {
            x,
            y,
            row,
            col,
            cellRect,
            sheetArea: context.sheetArea,
            isReservedLocation: textLeftX <= x && x <= textLeftX + width && textBottomY >= y && y >= textBottomY - height,
            isTargetHit: isTriangleHit
        };
        return info;
    }

    override processMouseMove(hitInfo: GC.Spread.Sheets.IHitTestCellTypeHitInfo): boolean {
        const hi = hitInfo as ICustomCellTargetHitInfo;
        this.drawUnderline = (hi.isReservedLocation && !hi.isTargetHit) ?? false;
        this.showPointer = hi.isReservedLocation || hi.isTargetHit;
        return true;
    }

    override processMouseLeave(_hitInfo: GC.Spread.Sheets.IHitTestCellTypeHitInfo): boolean {
        this.drawUnderline = false;
        this.showPointer = false;
        return true;
    }
}
