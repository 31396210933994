import React, {ReactElement, useEffect, useState} from "react";
import styles from '../AnalystPayrollDrawer.module.scss';
import {Field, Input, Label} from '@zendeskgarden/react-forms';
import {Col, Row} from '@zendeskgarden/react-grid';
import {Datepicker} from '@zendeskgarden/react-datepickers';

import {
    AllEmployeeClassifications,
    AllPayrollModeledEmployeeTypes,
    LocalPayrollEmployeeModel,
    LocalPayrollEmployeeModelKeys
} from "../helpers/types";
import {CustomZDDropdown} from "../../../atoms/custom-zd-dropdown/CustomZDDropdown";
import {Property} from "../../../contexts/properties/PropertiesContext";
import {DateFormatter} from "../helpers/utils";
import {ensureDateInLocale} from "../date-helpers";
import {TListPayrollPositions} from "../../../contexts/account/data/utils";
import {TLockedProperties} from "../../../pages/bulk-update/BulkUpdate";
import {Button} from "@zendeskgarden/react-buttons";


interface FormPropertyAttribution {
    propertyId: string | undefined;
    attributionPercentage: string | undefined;
}

interface PositionalDetailsProps {
    propertiesData: Property[]
    employeeData: LocalPayrollEmployeeModel,
    payrollPositionsData: TListPayrollPositions,
    updateFormState: (key: LocalPayrollEmployeeModelKeys, value: any) => void,
    lockedProperties: TLockedProperties,
}

export default function TabPositionDetails(
    {
        propertiesData,
        employeeData,
        payrollPositionsData,
        updateFormState,
        lockedProperties,
    }: PositionalDetailsProps): ReactElement {
    const [propertyAttributions, setPropertyAttributions] = useState<FormPropertyAttribution[]>(employeeData?.properties ? employeeData.properties.map(p => ({
        propertyId: p.propertyId,
        attributionPercentage: p.attributionPercentage !== null ? p.attributionPercentage : undefined
    })) : [{
        propertyId: undefined,
        attributionPercentage: undefined
    }]);

    if (!employeeData || !payrollPositionsData) {
        return <></>;
    }

    function updateGlobalStateWithValid(newAllocations: FormPropertyAttribution[]): void {
        const valid = newAllocations.filter(pa => !!pa.propertyId);
        updateFormState(LocalPayrollEmployeeModelKeys.properties, valid);
    }

    function addPropertyAllocation(): void {
        setPropertyAttributions([
            ...propertyAttributions,
            {
                propertyId: undefined,
                attributionPercentage: undefined
            }
        ]);
    }

    function removePropertyAllocation(idx: number): void {
        const remaining = [
            ...propertyAttributions.slice(0, idx),
            ...propertyAttributions.slice(idx + 1)
        ];
        // We do this to ensure 100% allocation when only 1 property is selected.
        if(remaining.length === 1 && remaining[0]) {
            remaining[0].attributionPercentage = undefined;
        }
        setPropertyAttributions(remaining);
        updateGlobalStateWithValid(remaining);
    }

    function setPropertyId(idx: number, id: string): void {
        const newArr = [...propertyAttributions];
        const selected = newArr[idx];
        if(selected) {
            selected.propertyId = id;
        }
        setPropertyAttributions(newArr);
        updateGlobalStateWithValid(newArr);
    }

    function setAllocationPercentage(idx: number, percentage: string): void {
        const newArr = [...propertyAttributions];
        const selected = newArr[idx];
        if(selected) {
            selected.attributionPercentage = percentage !== "" ? percentage : undefined;
        }
        setPropertyAttributions(newArr);
        updateGlobalStateWithValid(newArr);
    }

    return (
        <div className={styles.positionDetailsContainer}>
            <div style={{maxWidth: "400px"}}>
                <h4 className={styles.positionDetailsHeader}>Position Details</h4>
                <Field className={styles.field}>
                    <Label className={styles.label}>Status</Label>
                    <CustomZDDropdown
                        applySelectedItems={(items) => {
                            updateFormState(LocalPayrollEmployeeModelKeys.type, Array.isArray(items) ? items[0] : items);
                        }}
                        initialSelectedItems={employeeData.type ? [employeeData.type] : undefined}
                        openDropdownPlaceholder="Select Status"
                        closedDropdownPlaceholder=""
                        options={AllPayrollModeledEmployeeTypes.map(opt => ({value: opt.value, label: opt.label}))}
                        isError={employeeData.typeTouched && employeeData.type === undefined ? true : undefined}
                        onBlur={() => {
                            return undefined;
                        }}
                        noSearchIcon
                    />
                </Field>

                <Field className={styles.field}>
                    <Label className={styles.label}>Position Type</Label>
                    <CustomZDDropdown
                        applySelectedItems={(items) => {
                            const newPositionId: string = Array.isArray(items) ? items[0] : items;
                            updateFormState(LocalPayrollEmployeeModelKeys.position, payrollPositionsData.find(p => p.id === newPositionId));
                        }}
                        initialSelectedItems={employeeData.position ? [employeeData.position.id] : undefined}
                        openDropdownPlaceholder="Select Position"
                        closedDropdownPlaceholder=""
                        options={payrollPositionsData.map(opt => ({value: opt.id, label: opt.name}))}
                        isError={employeeData.positionTouched && employeeData.position === undefined ? true : undefined}
                        onBlur={() => {
                            return undefined;
                        }}
                        noSearchIcon
                    />
                </Field>

                <Field className={styles.field}>
                    <Label className={styles.label}>Work Classification</Label>
                    <CustomZDDropdown
                        applySelectedItems={(items) => {
                            updateFormState(LocalPayrollEmployeeModelKeys.classification, Array.isArray(items) ? items[0] : items);
                        }}
                        initialSelectedItems={employeeData.classification ? [employeeData.classification] : undefined}
                        openDropdownPlaceholder="Select Work Classification"
                        closedDropdownPlaceholder=""
                        options={AllEmployeeClassifications.map(opt => ({value: opt.value, label: opt.label}))}
                        isError={false}
                        onBlur={() => {
                            return undefined;
                        }}
                        noSearchIcon
                    />
                </Field>

                <Row justifyContent="between">
                    <Col size={6}>
                        <Field className={styles.field}>
                            <Label className={styles.label}>Start Date</Label>
                            <Datepicker
                                value={employeeData.startDate ? ensureDateInLocale(employeeData.startDate) : undefined}
                                onChange={(selectedStartDate) => {
                                    updateFormState(LocalPayrollEmployeeModelKeys.startDate, selectedStartDate.toISOString());
                                }}
                                formatDate={date => {
                                    return date ? DateFormatter.format(date) : "";
                                }}
                            >
                                <Input/>
                            </Datepicker>
                        </Field>
                    </Col>
                    <Col size={6}>
                        <Field className={styles.field}>
                            <Label className={styles.label}>End Date</Label>
                            <Datepicker
                                minValue={employeeData.startDate ? ensureDateInLocale(employeeData.startDate) : undefined}
                                value={employeeData.terminationDate ? ensureDateInLocale(employeeData.terminationDate) : undefined}
                                onChange={(selectedTerminationDate) => {
                                    updateFormState(LocalPayrollEmployeeModelKeys.terminationDate, selectedTerminationDate.toISOString());
                                }}
                                formatDate={date => {
                                    return date ? DateFormatter.format(date) : "";
                                }}
                            >
                                <Input disabled={employeeData.startDate === undefined} />
                            </Datepicker>
                        </Field>
                    </Col>
                </Row>

                <h5>Properties (for cost allocation)</h5>
                <div>
                    {propertyAttributions.map((pa, idx) => {
                        return <div key={idx} className={styles.propertyRow}>
                            <div className={styles.propertyInputs}>
                                <Field className={styles.propertyInputSelect}>
                                    <Label hidden>Property 1 (for cost allocation)</Label>
                                    <CustomZDDropdown
                                        applySelectedItems={items => setPropertyId(idx, items[0])}
                                        initialSelectedItems={pa.propertyId ? [pa.propertyId] : undefined}
                                        openDropdownPlaceholder="Select Property"
                                        closedDropdownPlaceholder=""
                                        disabled={pa.propertyId ? lockedProperties.reforecastLocked.includes(pa.propertyId) && lockedProperties.budgetLocked.includes(pa.propertyId) : false}
                                        options={propertiesData.map((opt => ({value: opt.id, label: opt.name, disabled: lockedProperties.reforecastLocked.includes(opt.id) && lockedProperties.budgetLocked.includes(opt.id)})))}
                                        isError={false}
                                        isMulti={false}
                                    />
                                </Field>
                                {propertyAttributions.length > 1 &&
                                <Field className={styles.propertyInputAllocation}>
                                    <Label hidden>Allocation Percentage</Label>
                                    <Input
                                        type={"number"}
                                        value={pa.attributionPercentage}
                                        placeholder={"Allocation %"}
                                        onChange={evt => setAllocationPercentage(idx, evt.target.value)}
                                        disabled={pa.propertyId ? lockedProperties.reforecastLocked.includes(pa.propertyId) && lockedProperties.budgetLocked.includes(pa.propertyId) : false}
                                    />
                                </Field>
                                }
                            </div>
                            {idx > 0 ?
                                <div className={styles.buttonRow}>
                                    <Button isBasic isDanger onClick={() => removePropertyAllocation(idx)}>Remove</Button>
                                </div>:
                                <></>
                            }
                        </div>;
                    })}
                </div>
                <div>
                    <Button isBasic className={styles.addButton} onClick={() => addPropertyAllocation()}>Add</Button>
                </div>
            </div>
        </div>
    );
}
