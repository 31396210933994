import React, { MouseEvent, ReactElement, useEffect, useRef, useState } from 'react';
import { Dropdown, Field, Item, Menu, Multiselect, Separator } from "@zendeskgarden/react-dropdowns";
import { TooltipModal } from '@zendeskgarden/react-modals';
import { StateChangeOptions } from 'downshift';

import { TDriverMetricsYear } from '../../../../../../contexts/account/data/logic/driversAndWorksheetData';
import useFormulaBarMenu, { DriverMetricsMapObject } from '../../logic/useFormulaBarMenu';

import * as css from "./styles/css.module.scss";
import { IFormulaBarUpdates } from '../../../../../../contexts/account/data/useFormulaBarUpdates';
import { compItemsCustomNamesMap, TAllAvailableDrivers } from '../../../../../../contexts/account/data/utils';
import { getPopperModifiers, getTooltipPlacementProps } from '../../logic/formulaBarMenu';


interface MenuState {
    isOpen: boolean,
    selectedDriverMetric: keyof TDriverMetricsYear | undefined;
    selectedItem: string | undefined;
}

const INITIAL_MENU_STATE = {
    isOpen: false,
    selectedDriverMetric: undefined,
    selectedItem: undefined,
};

interface IPayrollMenu {
    allDrivers: TAllAvailableDrivers | undefined;
    fbUpdates: IFormulaBarUpdates;
    level: number;
    selectedCompItemType?: string;
    triggerNode?: ReactElement;
    isBulkUpdateBar?: boolean,
    isPropertyDriversUI?: boolean,
}

export const PayrollMenu = ({
    allDrivers,
    fbUpdates,
    level,
    selectedCompItemType,
    triggerNode,
    isBulkUpdateBar,
    isPropertyDriversUI,
}: IPayrollMenu): ReactElement => {

    const fxMenu = useFormulaBarMenu({ allDrivers, fbUpdates });

    const allPayrollDrivers = allDrivers?.payroll || [];

    const [menuState, setMenuState] = useState<MenuState>(INITIAL_MENU_STATE);
    const triggerRef = useRef<HTMLDivElement>(null);
    const [refElement, setRefElement] = useState<HTMLDivElement|null>(null);
    const [inputValue, setInputValue] = useState('');
    const [selectedItems, setSelectedItems] = useState<string[]>([]);
    const [matchingOptions, setMatchingOptions] = useState<DriverMetricsMapObject[]>();
    const [levelState, setLevelState] = useState<number>(level);
    const [selectedCompItem, setSelectedCompItem] = useState<string>('');

    useEffect(() => {
        const compItemId = allPayrollDrivers?.find((driver) => driver.type === selectedCompItemType)?.id || '';

        setSelectedCompItem(compItemId);
    }, [selectedCompItemType]);

    useEffect(() => {
        if (inputValue === "" && (selectedCompItem || menuState.selectedItem)) {
            const compComparator = selectedCompItem || menuState.selectedItem;
            const selectedCompItemObj = allPayrollDrivers?.find((driver) => driver.id === compComparator);
            const positions = selectedCompItemObj?.positions;
            if (positions) {
                setMatchingOptions(positions.map((position) => ({
                    depth: 0,
                    name: position.id,
                    value: position.name,
                    isAvailable: true,
                    disabled: false,
                    disabledMessage: '',
                    isStatic: false,
                    type: "interactive",
                    children: null,
                })));
            }
        } else {
            const filteredOptions = matchingOptions?.filter((option) => option.value.toLowerCase().includes(inputValue.toLowerCase()));
            setMatchingOptions(filteredOptions);
        }
    }, [inputValue, menuState.selectedItem]);

    useEffect(() => {
        const positionsInFxBar = fbUpdates.parsedFormDrivers.payroll.find((driver) => driver.itemType === selectedCompItemType)?.positions;
        if (positionsInFxBar) {
            setSelectedItems(positionsInFxBar.map((position) => position.id));
        }

        if (levelState === 1 && (selectedCompItem || menuState.selectedItem)) {
            const compComparator = selectedCompItem || menuState.selectedItem;
            const selectedCompItemObj = allPayrollDrivers?.find((driver) => driver.id === compComparator);
            const positions = selectedCompItemObj?.positions;
            if (positions) {
                setMatchingOptions(positions.map((position) => ({
                    depth: 0,
                    name: position.id,
                    value: position.name,
                    isAvailable: true,
                    disabled: false,
                    disabledMessage: '',
                    isStatic: false,
                    type: "interactive",
                    children: null,
                })));
            }
        } else {
            setMatchingOptions(fxMenu.getPayrollMenuItems());
        }
    }, [allPayrollDrivers, levelState, selectedCompItem, fbUpdates, menuState.selectedItem]);

    const handleStateChange = (changes: StateChangeOptions<any>) => {
        const updatedState = {} as MenuState;
        const keysInChanges = Object.keys(changes);

        if (keysInChanges.includes('selectedItem')) {
            // If a menu item was selected, then handle that
            // This Zendesk component checks which menu item is currently selected, and renders
            // menu items based on that. So when the junction node is clicked, there's nothing selected,
            // and hence we render the plus menu
            updatedState.selectedItem = changes.selectedItem;

            const selectedCompItemObj = allPayrollDrivers?.find((driver) => driver.id === updatedState.selectedItem);
            if (selectedCompItemObj) {
                const positions = selectedCompItemObj.positions;
                if (positions) {
                    setMatchingOptions(positions?.map((position) => ({
                        depth: 0,
                        name: position.id,
                        value: position.name,
                        isAvailable: true,
                        disabled: false,
                        disabledMessage: '',
                        isStatic: false,
                        type: "interactive",
                        children: null,
                    })));
                }
            }

            setLevelState(1);
        }

        if (Object.keys(updatedState).length > 0) {
            setMenuState(updatedState);
        }
    };

    const handleClick = (event: MouseEvent<HTMLDivElement>) => {
        event.stopPropagation();
        setRefElement(triggerRef.current);
    };

    const handleClose = () => {
        setMenuState(INITIAL_MENU_STATE);
        setRefElement(null);
        setInputValue("");
    };

    // The header is a non-clickable, static text separated from the menu body
    const renderMenuHeader = () => {
        let headerTitle = '';

        if (levelState === 0) {
            headerTitle = "Payroll Items";
        }

        if (levelState === 1) {
            if (menuState.selectedItem) {
                headerTitle = allPayrollDrivers?.find((driver) => driver.id === menuState.selectedItem)?.customName || '';
            } else {
                headerTitle = (selectedCompItemType ? compItemsCustomNamesMap[selectedCompItemType] : '') || '';
            }
        }
        return (
            <>
                <div
                    style={inlineStyles.header}
                >
                    {headerTitle}
                </div>
                <Separator style={inlineStyles.separator}/>
            </>
        );
    };

    const renderOptions = () => {
        if (matchingOptions?.length === 0) {
          return <Item disabled>No matches found</Item>;
        }

        const renderMenuItemValue = (
            value: string,
            disabled: boolean,
            disabledMessage: string,
        ): ReactElement | string => {
            if (disabled) {
                return (
                    <div className={css.disabledItem}>
                        <div className={css.truncatedItem}>
                            {value}
                        </div>
                        <div className={css.alreadyAppliedText}>
                            {disabledMessage}
                        </div>
                    </div>
                );
            }
            return value;
        };

        return matchingOptions?.map(each => {
            if (levelState === 0) {
                return (
                    <Item
                        value={each.name}
                        key={each.name}
                        disabled={each.disabled}
                        className={`${css.truncatedItem} ${css.menuItem}`}
                        style={{display: "flex", alignItems: "center"}}
                    >
                        {renderMenuItemValue(each.value, each.disabled, each.disabledMessage)}
                    </Item>
                );
            }
            if (levelState === 1) {
                const isSelected = selectedItems.includes(each.name);
                return (
                    <Item
                        value={each.name}
                        key={each.name}
                        disabled={each.disabled}
                        className={`${css.truncatedItem} ${css.menuItem}`}
                        style={{display: "flex", alignItems: "center", gap: "20px", height: "36px", paddingLeft: isSelected ? "0px" : "36px"}}
                    >
                        {renderMenuItemValue(each.value, each.disabled, each.disabledMessage)}
                    </Item>
                );
            }
            return <></>;
        });
    };

    const handleMultiSelect = (items: any) => {
        if (menuState.selectedItem || selectedCompItem) {
            fbUpdates.updatePayrollDrivers({
                allPayrollDrivers,
                selectedCompItem:  menuState.selectedItem || selectedCompItem,
                positionIds: items
            });
        }

        setSelectedItems(items);
    };

    const renderRoot = () => {
        // if levelState === 0, then render the compensation items menu
        if (levelState === 0) {
            return (
                <>
                    <Dropdown
                        isOpen
                        onSelect={item => {
                            setMenuState({...menuState, selectedItem: item});
                        }}
                        onStateChange={(changes) => handleStateChange(changes)}
                    >
                        <div className={css.menuWrapper}>
                            {renderMenuHeader()}
                            <Menu placement="bottom" className={css.menu} style={inlineStyles.menu}>
                                {renderOptions()}
                            </Menu>
                        </div>
                    </Dropdown>
                </>
            );
        }

        // if levelState === 1, then render the positions menu
        // if a triggerNode was passed, then wrap the dropdown inside that
        if (levelState === 1) {
            if (triggerNode) {
                return (
                    <>
                        <div
                            className={css.triggerNode}
                            ref={triggerRef}
                            onClick={(event) => handleClick(event)}
                        >
                            {triggerNode}
                        </div>
                        <TooltipModal
                            className={css.tooltipModal}
                            hasArrow={false}
                            referenceElement={refElement}
                            onClose={handleClose}
                            {...getTooltipPlacementProps(isPropertyDriversUI, isBulkUpdateBar)}
                            popperModifiers={getPopperModifiers(isPropertyDriversUI, isBulkUpdateBar)}
                            isAnimated
                        >
                            <TooltipModal.Body className={css.tooltipModalBody}>
                                <Dropdown
                                    isOpen
                                    inputValue={inputValue}
                                    selectedItems={selectedItems}
                                    onSelect={handleMultiSelect}
                                    downshiftProps={{ defaultHighlightedIndex: 0 }}
                                    onInputValueChange={value => setInputValue(value)}
                                    >
                                    <div className={css.menuWrapper}>
                                        <Field>
                                            {renderMenuHeader()}
                                            <Multiselect
                                                className={css.autoComplete}
                                                onKeyDown={() => null}
                                                style={inlineStyles.autocomplete}
                                                renderItem={() => <div onClick={() => null} onKeyDown={() => null}></div>}
                                                renderShowMore={() => ''}
                                            />
                                        </Field>
                                        <Menu placement="bottom" className={css.menu} style={inlineStyles.menu}>{renderOptions()}</Menu>
                                    </div>
                                </Dropdown>
                            </TooltipModal.Body>
                        </TooltipModal>
                    </>
                );
            } else {
                return (
                    <Dropdown
                        isOpen
                        inputValue={inputValue}
                        selectedItems={selectedItems}
                        onSelect={handleMultiSelect}
                        downshiftProps={{ defaultHighlightedIndex: 0 }}
                        onInputValueChange={value => setInputValue(value)}
                        >
                        <div className={css.menuWrapper}>
                            <Field>
                                {renderMenuHeader()}
                                <Multiselect
                                    className={css.autoComplete}
                                    onKeyDown={() => null}
                                    style={inlineStyles.autocomplete}
                                    renderItem={() => <div onClick={() => null} onKeyDown={() => null}></div>}
                                    renderShowMore={() => ''}
                                />
                            </Field>
                            <Menu placement="bottom" className={css.menu} style={inlineStyles.menu}>{renderOptions()}</Menu>
                        </div>
                    </Dropdown>
                );
            }
        }

        return <></>;
    };

    return renderRoot();
};

const inlineStyles = ({
    autocomplete: {boxShadow: "none", margin: "12px", width: "calc(100% - 24px)"},
    header: {color: "#2f3941", fontSize: "0.9rem", fontWeight: 500, margin: "0", padding: "12px"},
    item: (depth: number) => ({paddingLeft: `${36 + depth * 24}px`}),
    menu: {marginTop: "30px", position: "relative" as 'relative', transform: "none", width: "auto"},
    separator: {margin: "0"},
});
