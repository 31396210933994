import { Button } from "@zendeskgarden/react-buttons";
import { Body, Close, Footer, FooterItem, Header, Modal } from "@zendeskgarden/react-modals";
import { ReactElement } from "react";
import { Inline } from "@zendeskgarden/react-loaders";

export interface IActivateBudgetYearModal {
    budgetSeason: string,
    loading: boolean,
    onClose: () => void,
    onConfirm: () => void,
}

export default function ActivateBudgetYearModal(props: IActivateBudgetYearModal): ReactElement {

    return (
        <Modal onClose={props.onClose}>
            <Header isDanger>
                Confirm change of Budget Season
            </Header>
            <Body>
                Are you sure you want to change Budget Season to {props.budgetSeason}? This affects all users' budgets.
            </Body>
            <Footer>
                <FooterItem>
                    <Button onClick={props.onClose} isBasic disabled={props.loading}>
                        Cancel
                    </Button>
                </FooterItem>
                <FooterItem>
                    {
                        <Button isPrimary onClick={props.onConfirm} disabled={props.loading}>
                            {
                                props.loading
                                    ? <Inline size={24} aria-label="loading"/>
                                    : <span>Confirm</span>
                            }
                        </Button>
                    }
                </FooterItem>
            </Footer>
            <Close aria-label="Close modal" />
        </Modal>
    );
}