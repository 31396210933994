import { memo, ReactElement, useEffect, useState } from "react";

import { MONTHS_LONG } from "../../constants/Months";

import { Button } from '@zendeskgarden/react-buttons';

import { ReactComponent as ChevronIconOpen } from '@zendeskgarden/svg-icons/src/16/chevron-right-stroke.svg';
import { ReactComponent as ChevronIconClosed } from '@zendeskgarden/svg-icons/src/16/chevron-down-stroke.svg';
import { ReactComponent as CalendarIcon } from '@zendeskgarden/svg-icons/src/16/calendar-stroke.svg';

import * as css from "./styles/css.module.scss";
import CalendarPane from "./components/CalendarPane";

export interface IYearMonthSelectorProps {
    year: number|undefined;
    month: number|undefined;
    onClick?: (year: number, month: number) => void;
    className?: string;
    id?: string;
    maxYear?: number;
    minYear?: number;
    showCalendarIcon?: boolean;
    isBasic?: boolean;
    calendarClassName?: string;
    disabledBackLabel?: string;
    disabledForwardLabel?: string;
}

function YearMonthSelector(props: IYearMonthSelectorProps): ReactElement {

    const [pickerOpen, setPickerOpen] = useState<boolean>(false);

    const [displayedYear, setDisplayedYear] = useState<number|undefined>(props.year);
    useEffect(
            () => {
                if(props.year == undefined){
                    return;
                }
                setDisplayedYear(props.year);
            },
            [props.year]
    );

    const selectionMade = (year: number, month: number):void => {
        if(props.onClick){
            props.onClick(year, month);
        }
        togglePicker(false);
    };

    const togglePicker = (forceState?: boolean): void => {
        if(forceState != undefined){
            setPickerOpen(forceState);
        } else {
            setPickerOpen(!pickerOpen);
        }
    };

    const getNextYearSelectDisabled = ():boolean => {
        return (
                displayedYear === undefined
                || (props.maxYear !== undefined && displayedYear > props.maxYear - 1));
    };

    const getPrevYearSelectDisabled = ():boolean => {
        return (
                displayedYear === undefined
                || (props.minYear !== undefined && displayedYear < props.minYear + 1));
    };

    return (
            <div className={props.className ? `${css.yearMonthSelector} ${props.className}` : `${css.yearMonthSelector}`}>
                <Button
                        data-testid={props.id ? `btn_current_year_month_${props.id}` : 'btn_current_year_month'}
                        onClick={() => {
                            togglePicker();
                        }}
                        isNeutral={!pickerOpen}
                        focusInset={pickerOpen}
                        className={css.currentYearMonthBtn}
                        disabled={props.year === undefined || props.month === undefined}
                        isBasic={props.isBasic ?? false}
                >
                    {
                        props.showCalendarIcon
                                ? <Button.StartIcon><CalendarIcon/></Button.StartIcon>
                                : <></>
                    }
                    {props.month != undefined ? MONTHS_LONG[props.month] : ''} {props.year}
                    <Button.EndIcon>
                        {pickerOpen ? <ChevronIconOpen/> : <ChevronIconClosed/>}
                    </Button.EndIcon>
                </Button>

                <CalendarPane
                        year={props.year}
                        month={props.month}
                        displayedYear={displayedYear}
                        setDisplayedYear={setDisplayedYear}
                        pickerOpen={pickerOpen}
                        selectionMade={selectionMade}
                        maxYear={props.maxYear}
                        minYear={props.minYear}
                        disabledBackLabel={props.disabledBackLabel}
                        disabledForwardLabel={props.disabledForwardLabel}
                        className={props.calendarClassName}
                        onClickOutside={() => {
                            togglePicker(false);
                        }}
                />
            </div>
    );
}

export default memo(YearMonthSelector);