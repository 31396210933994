import { Button } from "@zendeskgarden/react-buttons";
import { ReactElement } from "react";

import * as css from "./styles/userSelectionBar.module.scss";

export interface ISelectionBar {
    selected: string[];
    onEdit: () => void;
    onDelete: () => void;
    name: string;
    namePlural: string;
    enableBulk?: boolean;
}

export default function SelectionBar(props: ISelectionBar): ReactElement {
    return (

        <div className={`${css.userSelectionBar} ${props.selected.length ? css.userSelectionBarVisible : ''}`}>
            <div className={css.userSelectionBarCount}><span>{props.selected.length}</span></div>
            <h4>{props.selected.length == 1 ? props.name : props.namePlural} selected</h4>

            {/* until bulk editing is ready, hide the edit button for bulk operations */}
            <Button className={`${css.userSelectionButton} ${props.selected.length > 1 && !props.enableBulk ? css.userSelectionButtonHidden : ''}`} onClick={props.onEdit} disabled={props.selected.length > 1 && !props.enableBulk}>
                {props.selected.length == 1 ? `Edit ${props.name}` : "Bulk Edit"}
            </Button>

            <Button className={css.userSelectionButton} onClick={props.onDelete}>
                {props.selected.length == 1 ? `Delete ${props.name}` : "Bulk Delete"}
            </Button>
        </div>
    );
}