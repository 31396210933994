import React, { ReactElement, useEffect, useState, useRef, useLayoutEffect } from "react";

import { useConfig } from "../../../analyst/config/useConfig";

import { NewSpreadsheet } from "../../../../components/spreadsheet/NewSpreadsheet";
import { useNewSpreadsheetAPI } from "../../../../components/spreadsheet/NewSpreadsheetHooks";
import viewTemplate from "../../../../sjs/templates/two_years_sjs_view.json";

import * as styles from "../helpers/styles.module.scss";

import TwoYearsLayout from "../../../../sjs/layout/two-years/TwoYearsLayout";
import {
    createEmptyYearSummaryData,
    SummaryRentAccountData,
    useSummaryData,
    YearSummaryMetricData
} from "../data/useSummaryData";
import {TwoYearsTableData} from "../../../../sjs/layout/two-years/helpers/types";
import SummaryLayout, {SummaryLayoutData} from "./SummaryLayout";

export type SummarySJSSpreadProps = {
    setViewReady: React.Dispatch<React.SetStateAction<boolean>>,
    setDataReady: React.Dispatch<React.SetStateAction<boolean>>,
    setLoadProgress: React.Dispatch<React.SetStateAction<number>>,
}

const SummarySpread = ({
                              setViewReady,
                              setDataReady,
                              setLoadProgress
                          }: SummarySJSSpreadProps): ReactElement => {
    const ref = useRef<HTMLDivElement>(null);

    const config = useConfig();

    const {loaded, metricData, accountData} = useSummaryData();

    const [layout, setLayout] = useState<SummaryLayout|undefined>();

    const {
        handlers: sshandlers,
        api: ssapi,
        isSpreadsheetReady
    } = useNewSpreadsheetAPI();

    // GENERAL EFFECTS _______________________________________________________________________________________________
    useEffect(
        () => {
            setLoadProgress(4);
        },
        []);

    // COMPONENT EFFECTS _______________________________________________________________________________________________
    /**
     * Called when the div the spreadsheet is in changes height.
     * Tells spreadsheet layout helper to update the host height to the new container height.
     */
    useLayoutEffect(
        () => {
            if(layout && ref.current && ref.current.id == 'SummarySJSSpreadCell'){
                const containerHeight = ref.current.offsetHeight;
                const containerWidth = ref.current.offsetWidth;
                if(containerHeight && containerWidth){
                    layout.setHostDimensions(containerHeight, containerWidth);
                }
            }
        });

    // DATA EFFECTS ----------------------------------------------------------------------------------------------------
    const mapForSpreadsheet = (metricData: YearSummaryMetricData[], accountData: SummaryRentAccountData[]): Omit<SummaryLayoutData, "setDataReady"> => {
        let reforecastData = metricData.filter(d => d.valuesType === "REFORECAST").firstElement;
        if(!reforecastData) {
            reforecastData = createEmptyYearSummaryData("REFORECAST");
        }
        let budgetData = metricData.filter(d => d.valuesType === "BUDGET").firstElement;
        if(!budgetData) {
            budgetData = createEmptyYearSummaryData("BUDGET");
        }

        const mapPercentageForSjs = (vals: (number | null)[]): (number | null)[] => {
            return vals.map(v => v ? v / 100 : v);
        };

        const opMetrics = [
            ["Avg Market Rent", ...reforecastData.averageMarketRent, null, ...budgetData.averageMarketRent, null],
            ["Expirations", ...reforecastData.expirations, null, ...budgetData.expirations, null],
            ["Renewal Ratio", ...mapPercentageForSjs(reforecastData.renewalRatio), null, ...mapPercentageForSjs(budgetData.renewalRatio), null],
            ["Renewals", ...reforecastData.renewals, null, ...budgetData.renewals, null],
            ["Renewal Increase", ...reforecastData.renewalIncrease, null, ...budgetData.renewalIncrease, null],
            ["Move Out %", ...mapPercentageForSjs(reforecastData.moveOutPercentage), null, ...mapPercentageForSjs(budgetData.moveOutPercentage), null],
            ["Total Move Outs", ...reforecastData.totalMoveOuts, null, ...budgetData.totalMoveOuts, null],
            ["Occupancy", ...mapPercentageForSjs(reforecastData.occupancy), null, ...mapPercentageForSjs(budgetData.occupancy), null],
            ["Occupied Units", ...reforecastData.occupiedUnits, null, ...budgetData.occupiedUnits, null]
        ];

        const accounts: TwoYearsTableData = [];
        for(const account of accountData) {
            accounts.push([account.accountName.trim(), ...account.reforecastValues, null, ...account.budgetValues, null]);
        }

        return {
            opMetricHeader: ["Operational Metrics", ...new Array(26).fill(null)],
            opMetrics: opMetrics,
            financialsHeader: ["Financials", ...new Array(26).fill(null)],
            financials: accounts
        };
    };

    useEffect(
        () => {
            if(!loaded || !layout){
                setDataReady(false);
                setLoadProgress(4);
                return;
            }

            const mappedData = mapForSpreadsheet(metricData, accountData);
            layout.renderDataCustom({
                ...mappedData,
                setDataReady: setDataReady
            });
            setLoadProgress(100);
        },
        [loaded, metricData, accountData, layout],
    );

// SPREADSHEET EFFECTS _____________________________________________________________________________________________
    /**
     * Initializes the spreadsheet layout helper when the spreadsheet and environment data is ready
     */
    useEffect(
        () => {
            if(!isSpreadsheetReady || !config.isReady){
                return;
            }

            setLayout(new SummaryLayout({
                name: 'Description',
                ssapi,
                template: viewTemplate,
                year: config.year,
                firstReforecastMonth: config.startReforecastMonthIndex,
            }));

            setViewReady(true);
        },
        [isSpreadsheetReady, config.isReady]
    );

    return (
        <div
            id={'SummarySJSSpreadCell'}
            ref={ref}
            className={styles.sheetContainer}>
            <NewSpreadsheet
                startRow={0}
                startCol={0}
                rows={9}
                cols={16}
                handlers={sshandlers}
                allowVerticalScroll={true}
                allowHorizontalScroll={true}
                subscribeToMouse={true}
                safeAreaFirstRow={3}
                safeAreaFirstColumn={2}
                style={{
                    maxWidth: "100%",
                    padding: "0 0",
                }}
                allowZoom={false}
            />
        </div>
    );
};

export default SummarySpread;
