// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace TwoYearsConfig {
    export const MAIN_TAB_NAME = 'Sheet1';

    export const ROW_INFO_TAB_NAME = 'RowInfo';
    export const ROW_INFO_UUID_COL = 0;

    export const LABEL_BASE_INDENT = 26;
    export const LABEL_LEVEL_INDENT = 10;

    /**
     * Row and column values are 0 based
     */
    export const LABEL_COL = 2;
    export const HEADER_ROW = 2;

    export const TOTALS_COL = 15;
    export const BUDGET_TOTALS_COL = 28;

    export const FIRST_MONTH_COL = 3;
    export const FIRST_BUDGET_MONTH_COL = 16;
    export const FIRST_DATA_ROW = 5;

    export const HEADER_ROW_HEIGHT = 72;
}
