/* eslint-disable @typescript-eslint/no-empty-function */
import React, {ReactElement, useEffect, useState} from "react";
import useAppStore from "../../../hooks/useAppStore";

import {useAnalystDashboardProperties} from "../../analyst/AnalystDashboardProperties";
import {AnalystSections} from "../../../components/analyst/section-tabs/AnalystSectionTabs";
import {useProperties} from "../../../contexts/properties/PropertiesContext";
import {Payroll} from "../../payroll/Payroll";

import {ThemeProvider} from "styled-components";
import {ViziblyTheme} from "../../../styles/zendesk-garden/ViziblyZDGTheme";

import WorkflowHeader from "../../../components/workflows/workflow-header/WorkflowHeader";
import WorkflowPageLabel from "../../../components/workflows/workflow-page-label/WorkflowPageLabel";
import WorkflowStepNav from "../../../components/workflows/workflow-step-nav/WorkflowStepNav";

import ShimmerRenderer, {ShimmerPageKey} from "../../../components/shimmer/ShimmerRenderer";

import * as workflowCSS from "../../../styles/workflows/workflowCSS.module.scss";
import * as workflowHeaderCSS from "../../../components/workflows/workflow-header/styles/css.module.scss";
import {IWorkflowPageProps} from "../logic/workflows";
import WorkflowNavToggle from "../../../components/workflows/workflow-nav-toggle/WorkflowNavToggle";
import { useGetForecastLocksLazyQuery, useGetUserPropertiesForecastLocksLazyQuery } from "../../../__generated__/generated_types";
import { useSettings } from "../../../contexts/settings/SettingsContext";
import LockBanner from "../account/LockBanner";
import { TLockedProperties } from "../../bulk-update/BulkUpdate";

export default function AccountPayroll(props:IWorkflowPageProps):ReactElement{
    const appStore = useAppStore();
    const { year } = useSettings();

    const { currentProperty: property } = useProperties();

    const [showShimmer, setShowShimmer] = useState<boolean>(true);
    const [sectionReady, setSectionReady] = useState<Record<AnalystSections, boolean>>({
        [AnalystSections.Payroll]: false,
        [AnalystSections.Renovations]: true,
        [AnalystSections.Financials]: false,
    });
    const [lockedProperties, setLockedProperties] = useState<TLockedProperties>({reforecastLocked: [], budgetLocked: []});

    const [getForecastLocks, {data: dataForecastLocks, loading: lockDataLoading}] = useGetForecastLocksLazyQuery({
        fetchPolicy: "no-cache",
    });
    const [getPropertyLocks, {data: propertyLocks, loading: propertyLocksLoading}] = useGetUserPropertiesForecastLocksLazyQuery({
        fetchPolicy: "no-cache",
    });

    // Initial load, dispells the "Vizibly is starting" graphic
    useEffect(
        () => {
            appStore.set({ isLoading: false });
            return () => {
            };
        },
    []);

    useEffect(() => {
        if (!property?.id) {
            return;
        }

        getForecastLocks({
            variables: {
                propertyId: property.id,
                budgetYear: year + 1,
            }
        });
        getPropertyLocks({
            variables: {
                budgetYear: year + 1,
            }
        });
    }, [property]);

    useEffect(() => {
        if (propertyLocksLoading || !propertyLocks?.userPropertiesForecastLocks) {
            return;
        }

        const {budgetLocked, reforecastLocked} = propertyLocks.userPropertiesForecastLocks;
        setLockedProperties({budgetLocked, reforecastLocked});
    }, [propertyLocksLoading, propertyLocks?.userPropertiesForecastLocks]);

    // Updated when a section is loaded
    useEffect(
        () => {
            if(sectionReady[AnalystSections.Payroll]){
                setShowShimmer(false);
            } else {
                setShowShimmer(true);
            }
        },
        [sectionReady]);

    function onSectionReadyReport(sectionId:AnalystSections, isReady:boolean):void{
        setSectionReady(previous => {
            return {
                ...previous,
                ...{[sectionId]: isReady }
            };
        });
    }

    return(
            <ThemeProvider theme={ViziblyTheme}>
                {showShimmer ? <ShimmerRenderer modal={true} {...{
                    pageKey: ShimmerPageKey.ACCOUNT_VIEW
                }} /> : null}
                <div className={workflowCSS.workflowContainer}>
                    <WorkflowHeader>
                        <WorkflowHeader.LeftCol>
                            <div className={workflowHeaderCSS.rowItemsContainer}>
                                <WorkflowNavToggle/>
                                <WorkflowPageLabel label="Payroll"/>
                            </div>
                        </WorkflowHeader.LeftCol>
                        <WorkflowHeader.RightCol>
                            <div className={workflowHeaderCSS.columnItemsContainer}>
                                <WorkflowStepNav onPreviousClick={props.onPreviousClick} onNextClick={props.onNextClick}/>
                            </div>
                        </WorkflowHeader.RightCol>
                    </WorkflowHeader>
                        {property?.id && (!lockDataLoading && dataForecastLocks?.forecastLocks?.property) && property?.budgetYear != undefined &&
                            <div className={workflowCSS.bodyContainer}>
                                {(dataForecastLocks.forecastLocks.property.reforecastLocked && dataForecastLocks.forecastLocks.property.budgetLocked) &&
                                    <LockBanner fixed text={`${year - 1} Reforecast and ${year} Budget years have been locked.`} />
                                }
                                <Payroll
                                    setSectionReady={onSectionReadyReport}
                                    budgetYear={property.budgetYear}
                                    lockedProperties={lockedProperties}
                                    showSingleProperty={property.id}
                                    filterPropertyIds={[property.id]}
                                />
                            </div>
                        }
                </div>
            </ThemeProvider>
    );
}
