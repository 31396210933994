import { useEffect, useState } from "react";
import { useProperties } from "../../../contexts/properties/PropertiesContext";
import { useSettings } from "../../../contexts/settings/SettingsContext";
import {
    PropertyPerformanceModel,
    useGetPropertyPerformanceLazyQuery,
    VersionType
} from "../../../__generated__/generated_types";
import { TFocusYear } from "../../../global/types";
import { RentsDataConfig } from "./logic/RentsDataConfig";

interface IPlanningDashboardData {
    data: PropertyPerformanceModel|undefined,
    loading: boolean,
    property: {
        name: string|undefined,
        id: string|undefined,
        reforecastStartMonthIndex: number|undefined,
    },
    focusYear: TFocusYear|undefined,
    setFocusYear: (focusYear: TFocusYear) => void,

    rentsPrefs: IRentPrefs,
    setRentsPrefs: (prefs:IRentPrefs) => void,

    fetch: () => void,
}

export default function usePlanningDashboardData(): IPlanningDashboardData {

    const settings = useSettings();
    const properties = useProperties();

    const [_fetch, { data, loading }] = useGetPropertyPerformanceLazyQuery({
        fetchPolicy: "network-only",
    });

    useEffect(
        () => {
            if(settings.isDefaults || !settings.year || !properties.currentProperty){
                return;
            }
            // Default to the account's Budget year
            _setFocusYear({
                year: settings.year + 1,
                type: VersionType.Budget,
            });

            _fetch({
                variables: {
                    propertyId: properties.currentProperty.id,
                    budgetYear: settings.year + 1,
                }
            });
        },
        [settings.isDefaults, settings.year, properties.currentProperty]
    );

    const [focusYear, _setFocusYear] = useState<TFocusYear>();
    const setFocusYear = (focusYear: TFocusYear): void => {
        _setFocusYear(focusYear);
    };

    /**
     * Preferences for the Rents Widget.
     * lookbackYears    How many years back the user would like to see in the Rents widget.
     */
    const [rentsPrefs, _setRentsPrefs] = useState<IRentPrefs>({
        lookbackYears: RentsDataConfig.maxLookbackYears,
    });

    const setRentsPrefs = (prefs:IRentPrefs):void => {
        const lookbackYears = prefs.lookbackYears > RentsDataConfig.maxLookbackYears
            ? RentsDataConfig.maxLookbackYears
            : prefs.lookbackYears;

        _setRentsPrefs({
            lookbackYears
        });
    };

    // Note: This is here to facilitate development. Remove when page is ready.
    const fetch = ():void => {
        if(settings.isDefaults || !settings.year || !properties.currentProperty){
            return;
        }
        _fetch({
            variables: {
                propertyId: properties.currentProperty.id,
                budgetYear: settings.year + 1,
            }
        });
    };

    // Note: loading represents the ready state of all dependencies, including performance data
    return {
        data: data?.getPropertyPerformance,
        loading: loading || settings.isDefaults || !settings.year || !properties.currentProperty,
        property: {
            name: properties.currentProperty?.name,
            id: properties.currentProperty?.id,
            reforecastStartMonthIndex: properties.currentProperty?.reforecastStartMonthIndex,
        },
        focusYear,
        setFocusYear,

        rentsPrefs,
        setRentsPrefs,

        fetch,
    };
}

export interface IRentPrefs {
    lookbackYears: number,
}
