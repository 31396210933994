import { Button } from "@zendeskgarden/react-buttons";
import { Dropdown, Item, Menu, NextItem, PreviousItem, Trigger } from "@zendeskgarden/react-dropdowns";
import { ThemeProvider } from "@zendeskgarden/react-theming";
import { ReactElement, useContext, useState } from "react";
import { ViziblyTheme } from "../../../../styles/zendesk-garden/ViziblyZDGTheme";
import { ReactComponent as ClipboardListStrokeIcon } from '@zendeskgarden/svg-icons/src/16/clipboard-list-stroke.svg';
import * as css from "./styles/css.module.scss";
import { useNavigation } from "./navigation/useNavigation";
import { parseNavigationDestination } from "./logic/parseNavigationDestination";
import { FeatureFlagContext } from "../../../../feature-flag/FeatureFlagContextProvider";
import { Feature } from "../../../../feature-flag/Feature";


enum ReportsNavMenuItem {
    OVERVIEW = "overview",
    LINE_ITEMS = "line_items",
    MONTHLY_VARIANCE_REPORT = "monthly_variance_report",
    BULK_EXPORT = "bulk_export",
    BACK_FROM_BULK_EXPORT = "back_from_bulk_export",
    FINANCIAL_REPORTS = "financial_reports",
    OPERATIONAL_REPORTS = "operational_reports",
}

interface IReportsNavMenuState {
    isOpen: boolean;
    tempSelectedItem: ReportsNavMenuItem | undefined;
}

export function ReportsNavMenu(): ReactElement {
    const navigation = useNavigation();
    const { hasFeature } = useContext(FeatureFlagContext);

    const [state, setState] = useState<IReportsNavMenuState>({
        isOpen: false,
        tempSelectedItem: undefined
    });

    const handleItemSelected = (item: ReportsNavMenuItem) => {
        if (item !== ReportsNavMenuItem.BULK_EXPORT && item !== ReportsNavMenuItem.BACK_FROM_BULK_EXPORT) {
            const parsedDestination = parseNavigationDestination(item);
            navigation.goto(parsedDestination);
        }
    };

    const handleStateChange = (changes: any, stateAndHelpers: any) => {
        const updatedState: any = {};

        if (Object.prototype.hasOwnProperty.call(changes, 'isOpen')) {
            updatedState.isOpen =
            changes.selectedItem === ReportsNavMenuItem.BULK_EXPORT ||
            changes.selectedItem === ReportsNavMenuItem.BACK_FROM_BULK_EXPORT ||
            changes.isOpen;
        }

        if (Object.prototype.hasOwnProperty.call(changes, 'selectedItem')) {
            updatedState.tempSelectedItem = changes.selectedItem;
            stateAndHelpers.setHighlightedIndex(1);
        }

        if (Object.keys(updatedState).length > 0) {
            setState(updatedState);
        }
    };

    const buttonClass = navigation.currentDestination ? css.focusedButton : undefined;

    return (
        <ThemeProvider theme={ViziblyTheme}>
            <Dropdown
                isOpen={state.isOpen}
                onSelect={item => handleItemSelected(item)}
                onStateChange={handleStateChange}
            >
                <Trigger>
                    <Button isBasic className={buttonClass}>
                        <Button.StartIcon>
                            <ClipboardListStrokeIcon />
                        </Button.StartIcon>
                        <label className={css.reportsLabel}>Reports</label>
                    </Button>
                </Trigger>
                <Menu>
                    {state.tempSelectedItem === ReportsNavMenuItem.BULK_EXPORT ? (
                        <>
                            <PreviousItem value="back_from_bulk_export">Bulk Export</PreviousItem>
                            <Item value="financial_reports">Financial Reports</Item>
                            <Item value="operational_reports">Operational Reports</Item>
                        </>
                    ) : (
                        <>
                            <Item value="overview">Overview</Item>
                            <Item value="property_executive_summary">Executive Summary</Item>
                            <Item value="line_items">Line Items</Item>
                            {hasFeature(Feature.MVR) &&
                                <Item value="monthly_variance_report">Monthly Variance Report</Item>
                            }
                            <NextItem value="bulk_export">Bulk Export</NextItem>
                        </>
                    )}
                </Menu>
            </Dropdown>
        </ThemeProvider>
    );
}
