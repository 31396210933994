import {useEffect, useState} from "react";
import {useGetUnitLevelPropertyMetricsLazyQuery} from "../../../../__generated__/generated_types";
import {useProperties} from "../../../../contexts/properties/PropertiesContext";
import {useChartOfAccounts} from "../../../../contexts/chartofaccounts/ChartOfAccountsContext";

export interface SummaryRentAccountData {
    accountName: string;
    order: number;
    reforecastValues: (number | null)[];
    budgetValues: (number | null)[];
}

export interface YearSummaryMetricData {
    valuesType: "BUDGET" | "REFORECAST";
    averageMarketRent: (number | null)[];
    expirations: (number | null)[];
    renewalRatio: (number | null)[];
    renewals: (number | null)[];
    renewalIncrease: (number | null)[];
    moveOutPercentage: (number | null)[];
    moveOuts: (number | null)[];
    monthToMonthMoveOuts: (number | null)[];
    totalMoveOuts: (number | null)[];
    occupancy: (number | null)[];
    occupiedUnits: (number | null)[];
}

export function createEmptyYearSummaryData(valuesType: YearSummaryMetricData["valuesType"]): YearSummaryMetricData {
    return {
        valuesType: valuesType,
        averageMarketRent: new Array(12).fill(null),
        expirations: new Array(12).fill(null),
        renewalRatio: new Array(12).fill(null),
        renewals: new Array(12).fill(null),
        renewalIncrease: new Array(12).fill(null),
        moveOutPercentage: new Array(12).fill(null),
        moveOuts: new Array(12).fill(null),
        monthToMonthMoveOuts: new Array(12).fill(null),
        totalMoveOuts: new Array(12).fill(null),
        occupancy: new Array(12).fill(null),
        occupiedUnits: new Array(12).fill(null)
    };
}


export interface SummaryData {
    metricData: YearSummaryMetricData[];
    accountData: SummaryRentAccountData[];
    loaded: boolean;
}

export function useSummaryData(): SummaryData {
    const [summaryMetricData, setSummaryMetricData] = useState<YearSummaryMetricData[]>([]);
    const [accountMetricData, setAccountMetricData] = useState<SummaryRentAccountData[]>([]);
    const [summaryLoaded, setSummaryLoaded] = useState<boolean>(false);
    const {currentProperty} = useProperties();
    const {chartOfAccountsId} = useChartOfAccounts();
    const [getUnitLevelMetrics, {loading: unitLevelMetricsLoading, data: unitLevelMetrics, error: unitLevelMetricsError}] = useGetUnitLevelPropertyMetricsLazyQuery({
        fetchPolicy: "network-only",
        notifyOnNetworkStatusChange: true
    });

    useEffect(() => {
        if(chartOfAccountsId && currentProperty) {
            setSummaryLoaded(false);
            setSummaryMetricData([]);
            setAccountMetricData([]);
            getUnitLevelMetrics({
                variables: {
                    propertyId: currentProperty.id,
                    chartOfAccountsId: chartOfAccountsId
                }
            });
        }
    }, [currentProperty, chartOfAccountsId]);

    useEffect(() => {
        if(!unitLevelMetricsLoading) {
            if(unitLevelMetrics) {
                if(unitLevelMetrics.unitLevelPropertyFinancialMetrics.length > 0) {
                    const accounts = [...unitLevelMetrics.unitLevelPropertyFinancialMetrics];
                    accounts.sort((one, two) => one.order - two.order);
                    setAccountMetricData(accounts);
                }
                if(unitLevelMetrics.unitLevelPropertyMetrics.length > 0) {
                    const summaryData: YearSummaryMetricData[] = unitLevelMetrics.unitLevelPropertyMetrics.map(m => {
                        return {
                            ...m,
                            valuesType: m.valuesType as YearSummaryMetricData["valuesType"],
                        };
                    });
                    setSummaryMetricData(summaryData);
                }
            }
            setSummaryLoaded(true);
        }
    }, [unitLevelMetricsLoading]);

    return {
        metricData: summaryMetricData,
        accountData: accountMetricData,
        loaded: summaryLoaded
    };
}
