import { FinancialTotalValueQueryInput, FinancialValueQueryInput } from "../../__generated__/generated_types";
import { VersionsState } from "../versions/VersionsContext";
import { FinancialEntity } from "./ChartOfAccountsContext";

export function createRequestVariables(financialEntities: FinancialEntity[], propertyId: string, startReforecastMonthIndex: number, currentYear: number, getModelVersions: (year: number) => ReturnType<VersionsState["getModelVersions"]>) {

    const entities = financialEntities.flatMap(row => [{ id: row.id, type: row.type }]).groupBy("type");
    const { actualVersionId, reforecastVersionId, nextYearBudgetVersionId, currentYearBudgetVersionId, currentYearTotalVersionId, yearM1ActualVersionId, yearM2ActualVersionId } = getModelVersions(currentYear);

    let financialValueSelectors = [] as FinancialValueQueryInput[];
    let financialTotalValueSelectorsProjections = [] as FinancialTotalValueQueryInput[];
    let financialTotalValueSelectorsHistorical = [] as FinancialTotalValueQueryInput[];

    for (const group of Object.values(entities)) {
        const entityIds = group.map(row => row.id);
        financialValueSelectors = financialValueSelectors.concat([
            { // actuals
                entityIds,
                entityType: group.firstElement.type,
                monthIndexes: new Array(startReforecastMonthIndex).fill(0).map((_, i) => i),
                propertyIds: [propertyId],
                versionIds: [actualVersionId]
            },
            { // reforecast
                entityIds,
                entityType: group.firstElement.type,
                monthIndexes: new Array(12 - startReforecastMonthIndex).fill(0).map((_, i) => i + startReforecastMonthIndex),
                propertyIds: [propertyId],
                versionIds: [reforecastVersionId]
            },
            { // budgets
                entityIds,
                entityType: group.firstElement.type,
                monthIndexes: new Array(12).fill(0).map((_, i) => i),
                propertyIds: [propertyId],
                versionIds: [nextYearBudgetVersionId]
            },
        ]);
        financialTotalValueSelectorsProjections = financialTotalValueSelectorsProjections.concat([
            {
                entityIds,
                entityType: group.firstElement.type,
                propertyIds: [propertyId],
                versionIds: [currentYearTotalVersionId, nextYearBudgetVersionId]
            }
        ]);
        financialTotalValueSelectorsHistorical = financialTotalValueSelectorsHistorical.concat([
            {
                entityIds,
                entityType: group.firstElement.type,
                propertyIds: [propertyId],
                versionIds: [currentYearBudgetVersionId, yearM1ActualVersionId, yearM2ActualVersionId]
            }
        ]);
    }

    return { financialValueSelectors, financialTotalValueSelectorsProjections, financialTotalValueSelectorsHistorical };
}
