/* eslint-disable @typescript-eslint/no-empty-function */
import React, {useState} from "react";
import {GetVersionsQuery, useGetVersionsQuery, ValueVersion, VersionType} from "../../__generated__/generated_types";
import {reactLocalStorage} from 'reactjs-localstorage';
import {localUserPrefs} from "../../hooks/useConfig";

export interface VersionsState {
    versions: GetVersionsQuery | undefined;
    versionsById: Record<string, Pick<ValueVersion, "id" | "type" | "year">>;
    selectedVersionType: VersionType,
    setSelectedVersionType: (type: VersionType) => void,
    getModelVersions: (currentYear: number) => {
        actualVersionId: string,
        reforecastVersionId: string,
        yearM1ActualVersionId: string,
        yearM2ActualVersionId: string,
        yearM3ActualVersionId: string,
        currentYearTotalVersionId: string,
        currentYearBudgetVersionId: string,
        nextYearBudgetVersionId: string
    };
}

const VersionsStateContext = React.createContext<VersionsState | undefined>(undefined);


export const VersionsProvider: React.FunctionComponent = ({children}) => {
    const {data: versions, loading} = useGetVersionsQuery({
        fetchPolicy: 'network-only'
    });

    const localStorageUserPrefs: localUserPrefs = {
        selectedVersionType: VersionType.Budget,
        ...reactLocalStorage.getObject("userPrefs", {selectedVersionType: VersionType.Reforecast}, true)
    };
    const [selectedVersionType, _setSelectedVersionType] = useState<VersionType>(localStorageUserPrefs.selectedVersionType);

    if (loading) return null;

    const getModelVersions = (currentYear: number): ReturnType<VersionsState["getModelVersions"]> => {

        const actualVersionId = versions?.valueVersions?.find(version => version.type == VersionType.Actuals && version.year == currentYear)?.id ?? "UNKNOWN ACTUALS VERSION ID";
        const reforecastVersionId = versions?.valueVersions?.find(version => version.type == VersionType.Reforecast && version.year == currentYear)?.id ?? "UNKNOWN REFORECAST VERSION ID";
        const yearM1ActualVersionId = versions?.valueVersions?.find(version => version.type == VersionType.Actuals && version.year == currentYear - 1)?.id ?? "UNKNOWN YEAR-1 TOTAL VERSION ID";
        const yearM2ActualVersionId = versions?.valueVersions?.find(version => version.type == VersionType.Actuals && version.year == currentYear - 2)?.id ?? "UNKNOWN YEAR-2 TOTAL VERSION ID";
        const yearM3ActualVersionId = versions?.valueVersions?.find(version => version.type == VersionType.Actuals && version.year == currentYear - 3)?.id ?? "UNKNOWN YEAR-3 TOTAL VERSION ID";
        const currentYearTotalVersionId = versions?.valueVersions?.find(version => version.type == VersionType.YearTotal && version.year == currentYear)?.id ?? "UNKNOWN YEAR TOTAL VERSION ID";
        const currentYearBudgetVersionId = versions?.valueVersions?.find(version => version.type == VersionType.Budget && version.year == currentYear)?.id ?? "UNKNOWN CURRENT YEAR BUDGET VERSION ID";
        const nextYearBudgetVersionId = versions?.valueVersions?.find(version => version.type == VersionType.Budget && version.year == currentYear + 1)?.id ?? "UNKNOWN NEXT YEAR BUDGET VERSION ID";

        return {
            actualVersionId,
            reforecastVersionId,
            yearM1ActualVersionId,
            yearM2ActualVersionId,
            yearM3ActualVersionId,
            currentYearTotalVersionId,
            currentYearBudgetVersionId,
            nextYearBudgetVersionId
        };
    };

    const versionsById = versions ? versions.valueVersions.map(row => row as Pick<ValueVersion, "id" | "type" | "year">).toIdMap("id") : {};

    function setSelectedVersionType(type: VersionType): void {
        _setSelectedVersionType(type);

        const localStorageUserPrefs: localUserPrefs = {
            selectedVersionType: VersionType.Budget,
            ...reactLocalStorage.getObject("userPrefs", {selectedVersionType: VersionType.Reforecast}, true)
        };

        reactLocalStorage.setObject("userPrefs", {
            ...localStorageUserPrefs,
            selectedVersionType: type,
        });
    }

    const value: VersionsState = {
        versions,
        versionsById,
        selectedVersionType,
        setSelectedVersionType,
        getModelVersions
    };

    return (
        <VersionsStateContext.Provider value={value}>
            {children}
        </VersionsStateContext.Provider>
    );
};

export function useVersions(): VersionsState {
    const context = React.useContext(VersionsStateContext);

    if (context === undefined) {
        throw new Error('useVersions must be used within a VersionsProvider');
    }

    return context;
}
