import React, {ReactElement} from "react";
import {
    LocalPayrollEmployeeCompItemModel,
    LocalPayrollEmployeeModel,
    LocalPayrollEmployeeModelKeys,
} from "../helpers/types";
import {PayrollCompensationItemModel, PayrollCompensationItemType} from "../../../__generated__/generated_types";
import styles from "../AnalystPayrollDrawer.module.scss";
import BenefitEntryWithCompensationCap from "../components/BenefitEntryWithCompensationCap";
import {getDefaultLocalPayrollEmployeeCompItemModel} from "../helpers/utils";
import {Button} from "@zendeskgarden/react-buttons";

interface TaxesAndInsuranceProps {
    employeeData: LocalPayrollEmployeeModel;
    compensationItems:  Pick<PayrollCompensationItemModel, "id" | "type" | "customName">[];
    updateFormState: (key: LocalPayrollEmployeeModelKeys, value: any) => void;
}

export default function TabTaxesAndInsurance({employeeData, compensationItems, updateFormState}: TaxesAndInsuranceProps): ReactElement {

    const allCompItemsWithCompensationCap = [{
        label: "Medicare",
        items: employeeData.compItems[PayrollCompensationItemType.MedicareTax],
        updateKey: LocalPayrollEmployeeModelKeys.medicareCompItems,
        compensationItem: compensationItems.filter(ci => ci.type === PayrollCompensationItemType.MedicareTax).firstElement
    }, {
        label: "Social Security Tax",
        items: employeeData.compItems[PayrollCompensationItemType.SocialSecurityTax],
        updateKey: LocalPayrollEmployeeModelKeys.socialSecurityTax,
        compensationItem: compensationItems.filter(ci => ci.type === PayrollCompensationItemType.SocialSecurityTax).firstElement
    }, {
        label: "Federal Unemployment Tax",
        items: employeeData.compItems[PayrollCompensationItemType.FederalUnemploymentTax],
        updateKey: LocalPayrollEmployeeModelKeys.federalUnemploymentTax,
        compensationItem: compensationItems.filter(ci => ci.type === PayrollCompensationItemType.FederalUnemploymentTax).firstElement
    }, {
        label: "State Unemployment Tax",
        items: employeeData.compItems[PayrollCompensationItemType.StateUnemploymentTax],
        updateKey: LocalPayrollEmployeeModelKeys.stateUnemploymentTax,
        compensationItem: compensationItems.filter(ci => ci.type === PayrollCompensationItemType.StateUnemploymentTax).firstElement
    }, {
        label: "Workers' Compensation",
        items: employeeData.compItems[PayrollCompensationItemType.WorkersCompensation],
        updateKey: LocalPayrollEmployeeModelKeys.workersCompensation,
        compensationItem: compensationItems.filter(ci => ci.type === PayrollCompensationItemType.WorkersCompensation).firstElement
    }];

    return (
        <div className={styles.benefitsContainer}>
            {
                allCompItemsWithCompensationCap.map(currentCompItem => {
                    return (<div className={styles.benefitsSections} key={currentCompItem.label}>
                        <div style={{width: "400px"}}>
                            <div>
                                <h4 className={styles.benefitsHeaders}>{currentCompItem.label}</h4>
                            </div>
                            {
                                currentCompItem.items?.map((compItem, index) => {
                                    return <BenefitEntryWithCompensationCap
                                        key={index}
                                        index={index}
                                        subHeaderLabel={"Payment"}
                                        compItem={compItem}
                                        allowRemove={currentCompItem.items.length > 0}
                                        updateFormState={(key: LocalPayrollEmployeeModelKeys, value: any) => {
                                            const newCompItems: Array<LocalPayrollEmployeeCompItemModel> =
                                                JSON.parse(JSON.stringify(currentCompItem.items));
                                            const correspondingNewEntry: LocalPayrollEmployeeCompItemModel | undefined = newCompItems[index];
                                            if (correspondingNewEntry) {
                                                switch (key) {
                                                    case LocalPayrollEmployeeModelKeys.compItemsAmount:
                                                        correspondingNewEntry.costPerPayPeriod = value;
                                                        break;
                                                    case LocalPayrollEmployeeModelKeys.compItemsCompensationCap:
                                                        correspondingNewEntry.annualCostCap = value;
                                                        break;
                                                    case LocalPayrollEmployeeModelKeys.compItemsEffectiveDate:
                                                        correspondingNewEntry.effectiveStartDate = value;
                                                        break;
                                                    case LocalPayrollEmployeeModelKeys.removeCompItem:
                                                        newCompItems.splice(index, 1);
                                                        break;
                                                    default:
                                                        console.info(`Unhandled key: ${key} with value: ${JSON.stringify(value)}`);
                                                }
                                            }
                                            updateFormState(currentCompItem.updateKey, newCompItems);
                                        }}
                                    />;
                                })
                            }
                            <Button isBasic className={styles.addButton} onClick={() => {
                                let newCompItems: Array<LocalPayrollEmployeeCompItemModel> =
                                    currentCompItem.items ? [...currentCompItem.items] : [];
                                if(currentCompItem.compensationItem) {
                                    const defaultItem = getDefaultLocalPayrollEmployeeCompItemModel(currentCompItem.compensationItem);
                                    if(defaultItem) {
                                        newCompItems.push(defaultItem);
                                    }
                                }
                                updateFormState(currentCompItem.updateKey, newCompItems);
                            }}>Add</Button>
                        </div>
                    </div>);
                })
            }
        </div>
    );
}
