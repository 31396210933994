import React, {forwardRef, ForwardRefRenderFunction, Ref, useEffect, useImperativeHandle, useState} from 'react';
import {
    AccountsTableViewModel,
    useGetTableViewForStarredAccountsByPropertyIdAndVersionIdsLazyQuery,
    VersionType
} from "../../../__generated__/generated_types";

import {AccountsTableView} from "../accounts-table-view/AccountsTableView";
import {useAccountsTableViewData} from "../accounts-table-view/hooks/useAccountsTableViewData";

interface StarredAccountsTableViewProps {
    onDataUpdate: () => void
}

export interface StarredAccountsTableViewRefObject {
    imperativeRefreshTableData: () => void
}

const StarredAccountsTableViewRenderer:
    ForwardRefRenderFunction<StarredAccountsTableViewRefObject, StarredAccountsTableViewProps> = (
    {onDataUpdate},
    ref: Ref<StarredAccountsTableViewRefObject>
) => {
    const {
        isReady, propertyId, property, year,
        reforecastYearShortForm, budgetYearShortForm,
        starringReforcastVersionId, starringBudgetVersionId,
        finTotalReforcastVersionId, finTotalBudgetVersionId
    } = useAccountsTableViewData();
    const [data, setData] = useState<AccountsTableViewModel[] | undefined>(undefined);

    const [
        fetchTableData,
        {data: tableData, loading: tableDataLoading, error: tableDataLoadingError}
    ] = useGetTableViewForStarredAccountsByPropertyIdAndVersionIdsLazyQuery({fetchPolicy: "network-only"});

    const refreshTableData = () => {
        if (isReady && propertyId && year &&
            starringReforcastVersionId && starringBudgetVersionId &&
            finTotalReforcastVersionId && finTotalBudgetVersionId) {
            fetchTableData({
                variables: {
                    propertyId: propertyId,
                    versionIds: [starringReforcastVersionId, starringBudgetVersionId],
                    financialTotalValueArgInput: [
                        {
                            versionId: finTotalReforcastVersionId,
                            year: year,
                            type: VersionType.YearTotal
                        },
                        {
                            versionId: finTotalBudgetVersionId,
                            year: year + 1,
                            type: VersionType.Budget
                        }
                    ]
                }
            });
        }
    };

    useImperativeHandle(
        ref,
        () => ({
            imperativeRefreshTableData() {
                refreshTableData();
            }
        }),
        [isReady, propertyId, year,
            starringReforcastVersionId, starringBudgetVersionId,
            finTotalReforcastVersionId, finTotalBudgetVersionId],
    );

    useEffect(() => {
        refreshTableData();
    }, [isReady, propertyId, year,
        starringReforcastVersionId, starringBudgetVersionId,
        finTotalReforcastVersionId, finTotalBudgetVersionId]);

    useEffect(
        () => {
            if (tableDataLoading || tableDataLoadingError || !tableData) {
                return;
            }
            if (tableData.getTableViewForStarredAccountsByPropertyIdAndVersionIds) {
                setData(tableData.getTableViewForStarredAccountsByPropertyIdAndVersionIds);
            } else {
                setData([]);
            }
        }, [tableData, tableDataLoading, tableDataLoadingError]
    );

    return (
        <AccountsTableView
            headerText={"Starred Accounts"}
            propertyId={propertyId}
            reforecastYear={property?.reforecastYear ?? 0}
            tableData={data}
            reforecastYearShortForm={reforecastYearShortForm ?? ""}
            budgetYearShortForm={budgetYearShortForm ?? ""}
            onStarringUpdate={() => {
                refreshTableData();
                onDataUpdate();
            }}
            onNoteUpdate={() => {
                // refreshTableData();
                onDataUpdate();
            }}
        />
    );
};

const StarredAccountsTableView = forwardRef(StarredAccountsTableViewRenderer);
export default StarredAccountsTableView;
