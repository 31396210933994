import { ReactElement } from "react";
import * as css from '../../styles/css.module.scss';
import * as widgetCSS from '../graph-table-widget/styles/css.module.scss';
import { HotColumn, HotTable } from "@handsontable/react";
import { registerAllModules } from 'handsontable/registry';
import { LICENSES } from "../../../../constants/Licenses";
import { CellMeta, CellProperties } from "handsontable/settings";
import { TWidgetTableConfig } from "../../data/useOperationalData";

registerAllModules();

interface IOperationalTableProps {
    tableData: TWidgetTableConfig,
}

export default function OperationalTable(props: IOperationalTableProps): ReactElement {
    const valueColumnConfig = {
        type: 'numeric',
        readOnly: true,
        numericFormat: {
            pattern: '0,0.00',
            culture: 'en-US'
          }
    };

    return (
        <div style={{ 'height': '100%' }}>
            <HotTable
                id="operational"
                width="100%"
                height="100%"
                data={props.tableData.data}
                afterGetColHeader={(_column: number, th: HTMLTableHeaderCellElement): void => {
                    if (_column == 0) {
                        th.classList.add(widgetCSS.widgetTableHeader);
                    } else {
                        th.classList.add(widgetCSS.widgetTableHeaderAlignRight);
                    }
                }}
                stretchH="all"
                className={css.widgetTable}
                licenseKey={LICENSES.HandsOnTable}
                fixedRowsBottom={1}
                cells={function (this: CellProperties, row: number, col: number): CellMeta {
                    if (row == props.tableData.data.length - 1) {
                        if (col == 0) {
                            return {
                                className: `${css.summaryRow} ${css.leftAlignCell}`,
                            };
                        } else {
                            return {
                                className: css.summaryRow,
                            };
                        }
                    }

                    return this;
                }}
            >
                {
                    props.tableData.colHeaders.map((column, index) => {
                        if (index == 0) {
                            return <HotColumn key={index} className={`${css.leftAlignCell} ${css.valueColumnNarrow}`} title={column} {...valueColumnConfig} />;
                        } else {
                            return <HotColumn key={index} className={css.valueColumnNarrow} title={column} {...valueColumnConfig} />;
                        }
                    })
                }
            </HotTable>
        </div>
    );
}