import {ThemeProvider} from "@zendeskgarden/react-theming";
import {ReactElement, useEffect, useState} from "react";
import {ViziblyTheme} from "../analyst/ViziblyZDGTheme";
import useAppStore from "../../hooks/useAppStore";
import * as css from "./styles/OpDriverModeling.module.scss";
import * as workflowCSS from "../../styles/workflows/workflowCSS.module.scss";
import Header from "./components/Header";
import {Col, Grid, Row} from "@zendeskgarden/react-grid";
import {useProperties} from "../../contexts/properties/PropertiesContext";
import Card from "./components/Card";
import MarketRentDefaultsTable from "./components/MarketRentDefaultsTable";
import {ForecastLocks, useGetForecastLocksLazyQuery, useGetSimplifiedRevenueLazyQuery} from "../../__generated__/generated_types";
import {Button, IconButton} from "@zendeskgarden/react-buttons";
import {RiDownloadLine} from "react-icons/ri";
import {WorkflowStepNavProps} from "../../components/workflows/workflow-step-nav/WorkflowStepNav";
import { TSimplifiedUnitType } from "./Renovations";
import MarketRentOverridesTable from "./components/MarketRentOverridesTable";
import { buildSimplifiedUnitTypes } from "./logic/HelperFunctions";
import LockStatus from "./components/LockStatus";

export default function MarketRent(props: WorkflowStepNavProps): ReactElement {
    const appStore = useAppStore();
    const property = useProperties();

    const [configVisible, setConfigVisible] = useState<boolean>(false);
    const [triggerDownload, setTriggerDownload] = useState<boolean>(false);
    const [unitTypes, setUnitTypes] = useState<TSimplifiedUnitType[]>([]);
    const [forecastLocks, setForecastLocks] = useState<ForecastLocks>();
    const [getSimplifiedRevenue, {data, loading, refetch}] = useGetSimplifiedRevenueLazyQuery({
        fetchPolicy: "no-cache",
        notifyOnNetworkStatusChange: true,
    });
    const [getForecastLocks, {data: dataForecastLocks, loading: lockDataLoading}] = useGetForecastLocksLazyQuery({
        fetchPolicy: "no-cache",
    });

    useEffect(() => {
        appStore.set({isLoading: false});
        return () => undefined;
    }, []);

    useEffect(() => {
        if (!property.currentProperty) {
            return;
        }

        getSimplifiedRevenue({
            variables: {
                propertyId: property.currentProperty.id,
                budgetYear: property.currentProperty.budgetYear,
            }
        });

        getForecastLocks({
            variables: {
                propertyId: property.currentProperty.id,
                budgetYear: property.currentProperty.budgetYear,
            }
        });
    }, [property.currentProperty]);

    useEffect(() => {
        if (!data || loading) {
            return;
        }

        const unitTypes = buildSimplifiedUnitTypes(data);

        setUnitTypes(unitTypes);
    }, [data, loading]);

    useEffect(() => {
        if (lockDataLoading || !dataForecastLocks?.forecastLocks) {
            setForecastLocks(undefined);
            return;
        }

        setForecastLocks(dataForecastLocks.forecastLocks.property);
    }, [dataForecastLocks, lockDataLoading]);

    return (
        <ThemeProvider theme={ViziblyTheme}>
            <div className={`${css.opDriverModelingWrapper} ${workflowCSS.workflowContainer}`}>
                <div className={workflowCSS.workflowContainerInner}>
                    <Header
                        title={"Market Rent"}
                        prevAction={props.onPreviousClick}
                        nextAction={props.onNextClick}
                    />

                    <Grid className={css.bodyContent} gutters={"lg"}>
                        <Row>
                            <Col>
                                {forecastLocks && property.currentProperty && data && refetch &&
                                    <Card
                                        title="Defaults"
                                        actions={
                                            <>
                                                <LockStatus
                                                    budgetYear={property.currentProperty.budgetYear}
                                                    reforecastLocked={forecastLocks.reforecastLocked}
                                                    budgetLocked={forecastLocks.budgetLocked}
                                                />
                                                <div className={css.buttonGroup}>
                                                    <Button disabled={forecastLocks?.reforecastLocked && forecastLocks?.budgetLocked} isPrimary onClick={() => setConfigVisible(true)}>Configure Rules</Button>
                                                    <IconButton onClick={() => {
                                                        setTriggerDownload(true);
                                                    }}>
                                                        <RiDownloadLine />
                                                    </IconButton>
                                                </div>
                                            </>
                                        }
                                    >
                                            <MarketRentDefaultsTable
                                                property={property.currentProperty}
                                                data={data}
                                                configModalVisible={configVisible}
                                                triggerDownload={triggerDownload}
                                                forecastLocks={forecastLocks}
                                                onDataUpdate={refetch}
                                                onDownloadComplete={() => setTriggerDownload(false)}
                                                onModalClose={() => {
                                                    setConfigVisible(false);
                                                    refetch();
                                                }}
                                            />
                                    </Card>
                                }
                            </Col>
                        </Row>

                        <Row>
                            <Col>
                                <Card title="Overrides" className={css.marketRentsOverrideCard}>
                                    {forecastLocks && property.currentProperty && unitTypes.length > 0 && refetch ?
                                        <MarketRentOverridesTable
                                            property={property.currentProperty}
                                            unitTypes={unitTypes}
                                            forecastLocks={forecastLocks}
                                        />
                                        : <></>
                                    }
                                </Card>
                            </Col>
                        </Row>
                    </Grid>
                </div>
            </div>
        </ThemeProvider>
    );
}