/**
 * Ensures that the given date is properly within the
 * users locale so that if formatting things don't appear
 * a day behind for users behind GMT.
 *
 * @param d The date to adjust.
 */
export function ensureDateInLocale(d: Date): Date {
    // If all 3 of these are 0, assume the date is UTC and needs to be modified to
    // the locale.
    if(d.getUTCHours() === 0 && d.getUTCMinutes() === 0 && d.getUTCSeconds() === 0) {
        return new Date(d.getUTCFullYear(), d.getUTCMonth(), d.getUTCDate(), 0, 0, 0);
    }
    return d;
}

/**
 * Given a date ensures that's in the user's proper time such that when
 * formatted to the given locale.
 *
 * @param d The date to format.
 * @param locale The locale whose format is output. Default is 'en-us'.
 */
export function formatDateInLocale(d: Date, locale = "en-us"): string {
    const localeDate = ensureDateInLocale(d);
    return localeDate.toLocaleDateString(locale, {
        year: "2-digit",
        month: "numeric",
        day: "numeric"
    });
}

/**
 * Given a number representing a year, return the last two digits of the year number (e.g. 2024 returns 24)
 * @param year
 */
export function getAbbreviatedYear(year:number):string{
    return year.toString().slice(-2);
}
