import { ReactElement, useContext, useEffect, useMemo, useState } from "react";
import * as css from "./styles/css.module.scss";
import { SERIES_DATA_OPTION, usePropertyExecutiveSummaryData } from "./logic/usePropertyExecutiveSummaryData";
import { Property, useProperties } from "../../contexts/properties/PropertiesContext";
import { OperationalGraph } from "../planning-dashboard/components/operational-graph/OperationalGraph";
import GraphTableWidget from "../planning-dashboard/components/graph-table-widget/GraphTableWidget";
import MenuTitle from "../../components/menu-title/MenuTitle";
import { IMenuTitleOption } from "../../components/menu-title/logic/menuTitle";
import * as widgetCss from "./styles/widget.module.scss";
import * as statCSS from "../../components/account-summary/styles/styles.module.scss";
import { Col, Grid, Row } from "@zendeskgarden/react-grid";
import WidgetLoading from "../planning-dashboard/components/widget-loading/WidgetLoading";
import { ThemeProvider } from "styled-components";
import {ViziblyTheme} from "../../styles/zendesk-garden/ViziblyZDGTheme";
import OperationalTable from "../planning-dashboard/components/operational-table/OperationalTable";
import { ExecutiveSummaryGraphData } from "./logic/seriesGraphDataLogic";
import useAppStore from "../../hooks/useAppStore";
import { FinancialReportTable } from "./components/FinancialSummaryTable";
import { DownloadButton } from "./pdf/DownloadButton";
import { buildExecutiveSummaryStatementNOI, buildExecutiveSummaryStatementRents, buildExecutiveSummaryStatementRentalIncome, buildExecutiveSummaryStatementTotalExpense, buildRentsStatementMarketRent, buildRentsStatementNewLeaseTradeOut, buildRentsStatementRenewalTradeOut, buildRentsStatementOccupancy, buildRentsStatementRenewalRatio, buildRentsStatementMoveOutRatio, buildRentsStatementSummary, buildRentsStatementResidentsTurnover, buildRentsStatementTradeouts, formatAsJSX} from "./logic/summaryStringBuilders";
import { loadClientBrandAsset, OPERATIONAL_MENU_OPTIONS } from "./constants";
import { TabList, TabPanel, Tabs, Tab } from "@zendeskgarden/react-tabs";
import { Anchor, Button, ToggleButton } from "@zendeskgarden/react-buttons";
import cn from "classnames";
import MetricBlock from "./components/MetricBlock";
import brand from "../../assets/icons/brand/brand.png";
import { Favorite, Share, ShareRounded } from "@material-ui/icons";
import { AddRounded } from "@material-ui/icons";
import { ShareModal } from "./components/ShareModal";
import { RevenueSummaryTable } from "../workflows/operational/summary/RevenueSummaryTable";
import { AuthContext } from "../../contexts/AuthContext";
import { displayModalIcon } from '../workflows/account/Account.scss';
import FinancialsCOATable from "./components/FinancialsCOATable";
import { formatterDollarUSNoDecimal, formatterPercentAutoSign, formatterPercentWithSign } from "../../utils/formatters";
import {EmployeeTable} from "./components/EmployeeTable";
import { useGetBudgetApprovalStatusLazyQuery, useSetBudgetApprovalMutation } from "../../__generated__/generated_types";


export enum ExecutiveSummaryTabs {
    summary = "summary",
    financials = "financials",
    revenue = "revenue",
    payroll = "payroll",
}

export type ExecutiveSummaryUserInfo = {
    clientId: string,
    clientName: string,
    clientDisplayName: string,
    isGuest: boolean
}

export interface IPropertyExecutiveSummaryProps {
    property: Property,
    user: ExecutiveSummaryUserInfo
}

export function PropertyExecutiveSummary(props: IPropertyExecutiveSummaryProps): ReactElement {
    const data = usePropertyExecutiveSummaryData(props.property, props.user);
    const [fetchBudgetApproval, {data: approvalData, loading: approvalDataLoading}] = useGetBudgetApprovalStatusLazyQuery({
        fetchPolicy: "no-cache"
    });
    const [setBudgetApproval, {loading: approvalUpdating}] = useSetBudgetApprovalMutation({
        onCompleted: () => {
            fetchBudgetApproval({
                variables: {
                    propertyId: props.property.id,
                    budgetYear: props.property.budgetYear
                }
            });
        }
    });
    const [selectedGraphMetric, setSelectedGraphMetric] = useState<SERIES_DATA_OPTION>(SERIES_DATA_OPTION.OCCUPANCY);
    const appStore = useAppStore();
    const [selectedTab, setSelectedTab] = useState<ExecutiveSummaryTabs>(ExecutiveSummaryTabs.summary);
    const [isAtTop, setIsAtTop] = useState(true);
    const [sectionInViewport, setSectionInViewport] = useState<string | null>("executive-summary");
    const clientBranding = loadClientBrandAsset(props.user.clientId);
    const [showShareModal, setShowShareModal] = useState(false);

    useEffect(() => {
        appStore.set({ isLoading: false });

        const handleScroll = () => {
            if (window.scrollY === 0) {
                setIsAtTop(true);
            } else {
                setIsAtTop(false);
            }
        };

        window.addEventListener("scroll", handleScroll);

        fetchBudgetApproval({
            variables: {
                propertyId: props.property.id,
                budgetYear: props.property.budgetYear
            }
        });

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    const chartData:ExecutiveSummaryGraphData|undefined = useMemo(
        () => {
            if (!data) {
                return undefined;
            }
            return data.graphSeriesData.get(selectedGraphMetric);
        },
        [data, selectedGraphMetric]
    );

    const metricData = {
        mktRent: {
            title: "avg budgeted market rents",
            isSmallTitle: true,
            values: [
                {
                    value: data && data.budgetedAvgMarketRent.budgetedAvgBudgetMarketRent !== null ? formatterDollarUSNoDecimal.format(data.budgetedAvgMarketRent.budgetedAvgBudgetMarketRent) : "",
                    bottomLabel: <div><span>{data && data.budgetedAvgMarketRent.variancePercent !== null ? formatterPercentWithSign.format(data.budgetedAvgMarketRent.variancePercent) : ""}</span> YOY</div>,
                }
            ],
            text: <div>{formatAsJSX(buildRentsStatementMarketRent(data))}</div>,
        },
        occupancy: {
            title: "occupancy",
            isSmallTitle: true,
            values: [
                {
                    value: data && data.occupancyStat.budgetAvgOccupancy !== null ? formatterPercentAutoSign.format(data.occupancyStat.budgetAvgOccupancy) : "",
                    bottomLabel: data && data.occupancyStat.budgetAvgOccupancy !== null
                                  && data.occupancyStat.reforecastDecember !== null
                                  ?
                                  `${formatterPercentWithSign.format(data.occupancyStat.budgetAvgOccupancy - data.occupancyStat.reforecastDecember)} vs '${data.property.reforecastYear % 100} Reforecast`
                                  : "",
                }
            ],
            text: formatAsJSX(buildRentsStatementOccupancy(data)),
        },
        resTurn: {
            title: "resident turnover",
            isSmallTitle: true,
            values: [
                {
                    topLabel: "Renewal Ratio",
                    value: buildRentsStatementRenewalRatio(data),
                },
                {
                    topLabel: "Move Out Ratio",
                    value: buildRentsStatementMoveOutRatio(data),
                }
            ],
            text: buildRentsStatementResidentsTurnover(data),
        },
        tradeouts: {
            title: "tradeouts",
            isSmallTitle: true,
            values: [
                {
                    topLabel: "New Lease Tradeout",
                    value: data && data.newLeaseTradeOutPercent !== null ?
                            formatterPercentWithSign.format(data.newLeaseTradeOutPercent)
                            :
                            "",
                },
                {
                    topLabel: "Renewal Tradeout",
                    value: data && data.renewalTradeOutPercent !== null ?
                            formatterPercentWithSign.format(data.renewalTradeOutPercent)
                            :
                            "",
                }
            ],
            text: buildRentsStatementTradeouts(data),
        }
    }

    function handleBudgetApproval() {
        setBudgetApproval({
            variables: {
                propertyId: props.property.id,
                budgetYear: props.property.budgetYear,
                approved: !(approvalData?.getBudgetApprovalStatus?.approved)
            }
        })
    }

    return (
        <ThemeProvider theme={ViziblyTheme}>
            <div id="graph_tmp_anchor" style={{position: "absolute", visibility: "hidden", width: "500px", height: "300px"}}></div>
            <div className={cn(css.wrapper, (props.user.isGuest && css.isGuestUser))}>
                <div className={css.header}>
                    <div className={css.headerActions}>
                        <ToggleButton
                            isBasic
                            isPressed={selectedTab == ExecutiveSummaryTabs.summary}
                            onClick={() => setSelectedTab(ExecutiveSummaryTabs.summary)}
                        >
                            Analysis
                        </ToggleButton>
                        <ToggleButton
                            isBasic
                            isPressed={selectedTab == ExecutiveSummaryTabs.financials}
                            onClick={() => setSelectedTab(ExecutiveSummaryTabs.financials)}
                        >
                            Financials
                        </ToggleButton>
                        <ToggleButton
                            isBasic
                            isPressed={selectedTab == ExecutiveSummaryTabs.revenue}
                            onClick={() => setSelectedTab(ExecutiveSummaryTabs.revenue)}
                        >
                            Revenue
                        </ToggleButton>
                        <ToggleButton
                            isBasic
                            isPressed={selectedTab == ExecutiveSummaryTabs.payroll}
                            onClick={() => setSelectedTab(ExecutiveSummaryTabs.payroll)}
                        >
                            Payroll
                        </ToggleButton>

                        <div style={
                            {
                                marginLeft: "auto",
                                display: "flex",
                                flexDirection: "row",
                                gap: "1rem"
                            }
                        }>
                            {
                                data?.graphSeriesData &&
                                    <DownloadButton data={data} rawChartData={data.graphSeriesData} />
                            }
                            {!props.user.isGuest && <Button onClick={() => setShowShareModal(true)} style={{marginLeft: "auto"}}>
                                Share
                                <Button.EndIcon><ShareRounded /></Button.EndIcon>
                            </Button>}
                            <Button isDanger={approvalData?.getBudgetApprovalStatus?.approved} onClick={() => handleBudgetApproval()}>
                                {(approvalData?.getBudgetApprovalStatus?.approved)
                                ? "Revoke Approval"
                                : "Approve"}
                            </Button>
                        </div>
                    </div>
                    <div className={css.brandColorBand} style={{backgroundColor: clientBranding.colorSecondary}}>
                        {/* TODO:bowman this is a switch on our special tri-color asset living branding, but should be revisited after deadline */}
                        {
                            props.user.clientId == "6de1678f-59af-49b1-accd-4b76b612a160" || props.user.clientId == "0b1f3821-0616-4fed-9ca6-bfa9ecfd8f3c" ?
                            <>
                                <div className={cn(css.colorBar, css.colorBarBlue)}></div>
                                <div className={cn(css.colorBar, css.colorBarGreen)}></div>
                                <div className={cn(css.colorBar, css.colorBarOrange)}></div>
                            </>
                            : <></>
                        }
                    </div>

                    {
                        selectedTab == ExecutiveSummaryTabs.summary ?
                            <div
                                className={cn(css.banner, (!isAtTop && css.bannerSmall))}
                                style={{
                                    backgroundImage: `url('/${clientBranding.banner}')`
                                }}
                            >
                                <div className={css.bannerColorOverlay} style={{backgroundColor: clientBranding.colorPrimary}}></div>
                                <div className={css.bannerInner}>
                                    <h1>{data ? data.property.name : ""}</h1>
                                    <span>{data ? data.property.budgetYear : ""} Operating Budget - Presented by {data ? data.user.clientDisplayName : ""}</span>
                                    {
                                        clientBranding.logo &&
                                            <img src={clientBranding.logo} className={css.bannerLogo} />
                                    }
                                </div>
                            </div>
                    : <></>
                    }
                </div>

                <Tabs selectedItem={selectedTab} onChange={setSelectedTab} style={{overflow: "visible"}}>
                    <TabPanel item={ExecutiveSummaryTabs.summary}>
                        <div className={css.summaryTabWrapper}>

                            <div className={css.summaryContent}>
                                <section className={css.executiveSummary} id="executive-summary">
                                    <MetricBlock
                                        title="Executive Summary"
                                        text={
                                            <div>
                                                {formatAsJSX(buildExecutiveSummaryStatementNOI(data))}
                                                {formatAsJSX(buildExecutiveSummaryStatementRents(data))}
                                                {formatAsJSX(buildExecutiveSummaryStatementRentalIncome(data))}
                                                {formatAsJSX(buildExecutiveSummaryStatementTotalExpense(data))}
                                            </div>
                                        }
                                    />
                                </section>

                                <section className={css.rentSummary} id="rents">
                                    <MetricBlock
                                        title="Rent Summary"
                                        text={formatAsJSX(buildRentsStatementSummary(data))}
                                    />

                                    <div style={{display: "grid", gridTemplateColumns: "repeat(2, 1fr)", gap: "1rem"}}>
                                        <MetricBlock
                                            title={metricData.mktRent.title}
                                            isSmallTitle={metricData.mktRent.isSmallTitle}
                                            values={metricData.mktRent.values}
                                            text={metricData.mktRent.text}
                                        />
                                        <MetricBlock
                                            title={metricData.occupancy.title}
                                            isSmallTitle={metricData.occupancy.isSmallTitle}
                                            values={metricData.occupancy.values}
                                            text={metricData.occupancy.text}
                                        />
                                        <MetricBlock
                                            title={metricData.resTurn.title}
                                            isSmallTitle={metricData.resTurn.isSmallTitle}
                                            values={metricData.resTurn.values}
                                            text={metricData.resTurn.text}
                                        />
                                        <MetricBlock
                                            title={metricData.tradeouts.title}
                                            isSmallTitle={metricData.tradeouts.isSmallTitle}
                                            values={metricData.tradeouts.values}
                                            text={metricData.tradeouts.text}
                                        />
                                    </div>

                                    <div style={{marginBottom: "2rem"}}>
                                        {
                                            data ?
                                            <GraphTableWidget>
                                                <GraphTableWidget.Label>
                                                    <div className={widgetCss.widgetLabel}>
                                                        <MenuTitle
                                                            options={OPERATIONAL_MENU_OPTIONS}
                                                            onChange={(newVal) => setSelectedGraphMetric(newVal)}
                                                            noUnderline
                                                        />
                                                    </div>
                                                    <div className={widgetCss.widgetSublabel}>
                                                        <MenuTitle
                                                            options={[
                                                                {
                                                                    label: `${data.property.reforecastYear % 100} RFCST`,
                                                                    value: 0,
                                                                }
                                                            ]}
                                                            small
                                                            plain
                                                        />
                                                        <span className={widgetCss.widgetSublabelMetricDivider}>vs</span>
                                                        <MenuTitle
                                                            options={[
                                                                {
                                                                    label: `${data.property.budgetYear % 100} BDGT`,
                                                                    value: 0,
                                                                }
                                                            ]}
                                                            small
                                                            plain
                                                        />
                                                    </div>
                                                </GraphTableWidget.Label>
                                                <GraphTableWidget.Graph>
                                                    <Grid>
                                                        <Row justifyContent="center">
                                                            <Col textAlign="center">
                                                                <h5 className={statCSS.statLabel}>{chartData?.summary.comparisonItemOne.label ?? "-"}</h5>
                                                                <h5 className={statCSS.statValue}>{chartData?.summary.comparisonItemOne.value ?? "-"}</h5>
                                                            </Col>

                                                            <Col textAlign="center">
                                                            <h5 className={statCSS.statLabel}>{chartData?.summary.comparisonItemTwo.label ?? "-"}</h5>
                                                                <h5 className={statCSS.statValue}>{chartData?.summary.comparisonItemTwo.value ?? "-"}</h5>
                                                            </Col>

                                                            <Col textAlign="center">
                                                                <h5 className={statCSS.statLabel}>{chartData?.summary.variancePercent.label}</h5>
                                                                <h5 className={statCSS.statValue}>{chartData?.summary.variancePercent.value}</h5>
                                                            </Col>

                                                            <Col textAlign="center">
                                                                <h5 className={statCSS.statLabel}>{chartData?.summary.varianceAmount.label}</h5>
                                                                <h5 className={statCSS.statValue}>{chartData?.summary.varianceAmount.value}</h5>
                                                            </Col>
                                                        </Row>

                                                        <Row>
                                                            <Col>
                                                                {chartData ?
                                                                <OperationalGraph
                                                                    chartData={{varianceCounts: chartData.varianceCounts, chartSeriesData: chartData.chartSeriesData}}
                                                                    dataType={chartData.dataType}
                                                                />
                                                                : <WidgetLoading />
                                                                }
                                                            </Col>
                                                        </Row>
                                                    </Grid>
                                                </GraphTableWidget.Graph>
                                                <GraphTableWidget.Table>
                                                    {
                                                        chartData?.tableData !== undefined ?
                                                            <OperationalTable tableData={chartData.tableData} />
                                                            : <WidgetLoading />
                                                    }
                                                </GraphTableWidget.Table>
                                            </GraphTableWidget>
                                            : <WidgetLoading />
                                        }
                                    </div>
                                </section>

                                <section className={css.financialSummary} id="financial-summary">
                                    <MetricBlock
                                        title="Financial Summary"
                                        text={formatAsJSX(buildExecutiveSummaryStatementNOI(data))}
                                    />

                                    {data &&
                                        <div>
                                            {data.reportTables.map((reportTable, i) =>
                                                <div key={i} style={{marginBottom: "2rem"}}>
                                                    <FinancialReportTable
                                                        reforecastYear={data.property.reforecastYear}
                                                        budgetYear={data.property.budgetYear}
                                                        data={reportTable}
                                                    />
                                                </div>
                                            )}
                                        </div>
                                    }
                                </section>

                            </div>
                        </div>

                        <div className={css.footer}>
                            <div>
                                <img src={brand} alt="vizibly-brand" className={css.footerVizLogo} />
                                {
                                    clientBranding.logoMini && <>
                                        <div className={css.footerPlusIcon}>
                                            <AddRounded className={css.footerIconAdd} />
                                            <Favorite className={css.footerIconHeart} />
                                        </div>
                                        <img src={`/${clientBranding.logoMini}`} className={css.footerPartnerLogo} />
                                    </>
                                }
                            </div>
                        </div>
                    </TabPanel>

                    <TabPanel item={ExecutiveSummaryTabs.financials}>
                        <div style={{padding: "1rem 1rem 0 1rem"}}>
                            {
                                data && data.rawData && data.financialsTable && !data.rawDataLoading &&
                                approvalData && !approvalDataLoading &&
                                    <FinancialsCOATable
                                        key={props.property.id}
                                        data={data.financialsTable}
                                        commentsMap={data.commentsMap}
                                        property={props.property}
                                        budgetApproved={Boolean(approvalData.getBudgetApprovalStatus?.approved)}
                                    />
                            }
                        </div>
                    </TabPanel>

                    <TabPanel item={ExecutiveSummaryTabs.revenue}>
                        <div style={{padding: "1rem 1rem 0 1rem"}}>
                            {data && data.rawData && data.rawData.originalRevenueModel &&
                                <RevenueSummaryTable
                                    unitTypeData={data.rawData.originalRevenueModel}
                                    property={props.property}
                                    unitTypes={data.unitTypes}
                                    showLabel
                                />
                            }
                        </div>
                    </TabPanel>

                    <TabPanel item={ExecutiveSummaryTabs.payroll}>
                        <div style={{padding: "1rem 1rem 0 1rem"}}>
                            {data && data.employeeTotals &&
                                <EmployeeTable property={props.property} employeeTotals={data.employeeTotals} />
                            }
                        </div>
                    </TabPanel>
                </Tabs>
            </div>
            {showShareModal && data && <ShareModal property={data.property} onClose={() => setShowShareModal(false)}/>}
        </ThemeProvider>
    );
}
