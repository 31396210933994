/* eslint-disable @typescript-eslint/no-empty-function */
import React from 'react';
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';
import SetPassword from '../../components/auth/SetPassword';
import RequestCode from '../../components/auth/RequestCode';
import SignIn from '../../components/auth/SignIn';
import SignUp from '../../components/auth/SignUp';
import VerifyCode from '../../components/auth/Verify';
import { useRoutingUtils } from '../RoutingUtils';
import { PasswordFlow } from '../../components/auth/types';
import Terms from '../../pages/terms/Terms';
import useAppStore from '../../hooks/useAppStore';
import { useFoqalRouteTrack } from '../../hooks/useFoqal';

export interface SignInRouteProps {
    signedIn?: () => void
}
export const SignInRoute: React.FC<SignInRouteProps> =
    (params: SignInRouteProps) =>
        <Router>
            <Contents {...params}></Contents>
        </Router>;

// https://github.com/ReactTraining/react-router/issues/7015
// You can't use any of the hooks (useHistory, @rackuka)
// from within the same component that puts the Router into the tree.
// You need to move your BrowserRouter out of that component.
// It can go in the ReactDOM.render() call, for instance.
const Contents: React.FC<SignInRouteProps> = () => {
    useFoqalRouteTrack();

    const { goto, goback } = useRoutingUtils();
    const appStore = useAppStore();

    React.useEffect(()=> {
        appStore.set({isLoading: false});
        return ()=> {};
    }, []);


    return (
        <Switch>
            <Route exact path="/signup" component={() =>
                <SignUp
                    needVerifyAccount={goto('verify')}
                    cancelled={goback} />
            } />
            <Route path="/onboard/:userId" component={() =>
                <SetPassword
                    needSignIn={goto('/setup/start')}
                    flow={PasswordFlow.CREATE}

                />
            }
            />
            <Route exact path="/login" component={() =>
                <SignIn
                    signedIn={goto('/')}
                    // needConfirmUser={goto('/verify')}
                    provideResetPasswordRoute={() => '/login/forgot'}
                />
            }
            />
            <Route path="/login/forgot" component={() =>
                <RequestCode
                    cancelled={goback}
                    needResetPassword={goto('/login/enter-password')}

                />
            }/>
             <Route exact path="/login/enter-password" component={() =>
                <SetPassword
                    toLogin={goto("/login")}
                    needSignIn={goto('/')}
                    flow={PasswordFlow.RESET}
                />
            }/>
            <Route path="/terms" component={Terms}/>
            <Route path="/">
                <Redirect to="/login" />
            </Route>
        </Switch>
    );
};

