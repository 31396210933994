// eslint-disable-next-line @typescript-eslint/no-namespace
import { CellDecoratorSpec } from "../../components/spreadsheet/spreadjs-custom-cell-types/icons/CellDecoratorSpecs";
import GC from "@grapecity/spread-sheets";
import { AnalystStyles } from "./AnalystStyles";
import { colCode } from "../../utils/excel";

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace AnalystCfg{
    export const DEFAULT_FINANCIAL_CELLS_OPEN = false;

    export const FIRST_VISIBLE_SS_ROW = 1;
    export const FIRST_VISIBLE_SS_COL = 4;

    export const TOTAL_VISIBLE_COLUMNS = 30;

    export const FIRST_COL_HEADER_ROW = 2;
    export const FIRST_DATA_ROW = 5;
    export const FIRST_DATA_COL = 4;
    export const DATA_CELL_HEIGHT = 40;
    export const DATA_CELL_WIDTH = 97;

    export const BASE_TOGGLE_SPACE_WIDTH = 36;
    export const SUBLEVEL_X_OFFSET = 12;

    export const FINANCIAL_ENTITY_COL = 2;
    export const FINANCIAL_ENTITY_LABEL_ROW = 2;
    export const FINANCIAL_ENTITY_COL_W = 284;
    export const FINANCIAL_ENTITY_CELL_PADDING = 0;

    export const DRIVER_COLUMN = 3;
    export const DRIVER_COLUMN_W = 156;

    export const THIS_YEAR_TOTALS_COL = 16;
    export const FIRST_BUDGET_DATA_COL = 17;
    export const BUDGET_TOTALS_COL = 29;

    export const MAIN_TAB_OCC_RATE_COL = 30;

    export const TOTALS_COL_W = 127;

    export const BLOCK_SIZE = 20;

    export const MAIN_TAB_NAME = "Sheet1";
    export const HELPER_FINANCIAL_TAB_NAME = "Sheet2";
    export const UTIL_TAB_NAME = "Util";
    export const PROPERTY_UTIL_TAB_NAME = "PropertyUtil";

    export const UTIL_TAB_FIRST_DATA_ROW = 0;
    export const UTIL_TAB_UNIT_ID_COL = 0;

    export const UTIL_TAB_OCC_RATE_INPUT_COL = 1;
    export const UTIL_TAB_OCC_RATE_INPUT_COL_CODE = colCode(UTIL_TAB_OCC_RATE_INPUT_COL);
    export const UTIL_TAB_OCC_RATE_OUTPUT_COL = 2;

    export const OPDRIVERS_UTIL_TAB_HIDE_COL = 0;
    export const OPDRIVERS_UTIL_TAB_HIDE_COL_CODE = colCode(OPDRIVERS_UTIL_TAB_HIDE_COL);

    export const FINANCIALS_UTIL_TAB_HIDE_COL = 0;

    // Used for the ExpandableCategoryCells on Financials
    export const leftIconSpecs: CellDecoratorSpec = {
        x: 8,
        y: 7,
    };

    export const rightIconSpecs: CellDecoratorSpec = {
        x: 254,
        y: 6,
    };

    export const levelStyles: GC.Spread.Sheets.Style[] = [
        AnalystStyles.LEVEL_0_ROW_LABEL,
        AnalystStyles.LEVEL_1_ROW_LABEL,
        AnalystStyles.LEVEL_2_ROW_LABEL,
        AnalystStyles.LEVEL_3_ROW_LABEL,
        AnalystStyles.LEVEL_4_ROW_LABEL,
    ];

    export const loadedLevelStyles: GC.Spread.Sheets.Style[] = [
        AnalystStyles.LEVEL_0_ROW_LABEL_LOADED,
        AnalystStyles.LEVEL_1_ROW_LABEL_LOADED,
        AnalystStyles.LEVEL_2_ROW_LABEL,
        AnalystStyles.LEVEL_3_ROW_LABEL,
    ];

    export const rollupLevelStyles: GC.Spread.Sheets.Style[] = [
        AnalystStyles.LEVEL_0_ROLLUP_ROW_LABEL_V1,
        AnalystStyles.LEVEL_1_ROLLUP_ROW_LABEL_V1,
        AnalystStyles.LEVEL_2_ROLLUP_ROW_LABEL_V1,
        AnalystStyles.LEVEL_3_ROW_LABEL,
    ];
}


