import React, { useEffect, useState } from "react";

import { useConfig } from "../../../analyst/config/useConfig";

import { TwoYearsTableData } from "../../../../sjs/layout/two-years/helpers/types";
import {
    useListUnitsAndMarketRentsLazyQuery, useListUnitsAndMarketRentsQuery, useSetMarketRentMutation, VersionType
} from "../../../../__generated__/generated_types";
import { useProperties } from "../../../../contexts/properties/PropertiesContext";
import {
    averageTwoYearsArrays,
    composeReforecastYearArray,
    getYearFlatAverage,
    isYearArrayPopulated,
    parseYearArrayValues,
    YearArray
} from "../../helpers/utils";

export type MarketRentsDataReturn = {
    unitAndMarketRentsTableData: TwoYearsTableData,
    setUnitAndMarketRentsTableData: React.Dispatch<React.SetStateAction<TwoYearsTableData>>
    unitsAndMarketRentsLoading: boolean,
    dataReady: boolean,
    setMarketRent: (unitId:string, year:number, month:number, type:VersionType, marketRent:number) => void,
}

export interface UseMarketRentsDataProps {
    setLoadProgress?: React.Dispatch<React.SetStateAction<number>>;
}

export default function useMarketRentsData({ setLoadProgress }: UseMarketRentsDataProps): MarketRentsDataReturn {
    const { year, startReforecastMonthIndex } = useConfig();
    const { currentProperty, properties, propertyId } = useProperties();

    const [unitAndMarketRentsTableData, setUnitAndMarketRentsTableData] = useState<TwoYearsTableData>([]);

    const {
        data: unitsAndMarketRentsData,
        loading: unitsAndMarketRentsLoading
    } = useListUnitsAndMarketRentsQuery({
        variables: {
            propertyId: currentProperty?.id as string,
            page: 1,
            take: 1000,
            reforecastYear: year,
            budgetYear: year + 1,
        },
        fetchPolicy: "no-cache"
    });

    const [
        setMarketRentMutatation,
        {
            data: setMarketRentData,
            loading: setMarketRentDataLoading,
        }
    ] = useSetMarketRentMutation({
        notifyOnNetworkStatusChange: true
    });

    const [dataReady, setDataReady] = useState<boolean>(false);

    const reportLoadProgress = (progressAmt: number): void => {
        if(setLoadProgress){
            setLoadProgress(progressAmt);
        }
    };

    useEffect(
        () => {
            if(!unitsAndMarketRentsData || unitsAndMarketRentsLoading){
                return;
            }

            const { items: units } = unitsAndMarketRentsData.listUnits;

            const tableData: TwoYearsTableData = [];

            const avgMarketRents: YearArray[] = [];
            const avgAdjustedMarketRents:YearArray[] = [];

            units.forEach((unit) => {
                const hasRenoAdjust = isYearArrayPopulated(unit.reforecastMarketRents?.proratedRenovationPremiums)
                    || isYearArrayPopulated(unit.budgetMarketRents?.proratedRenovationPremiums);
                const reforecastYearArr = composeReforecastYearArray(
                    unit.reforecastMarketRents?.rawMarketRentsActuals,
                    unit.reforecastMarketRents?.rawMarketRentsReforecast,
                    startReforecastMonthIndex,
                );
                const budgetYearArr = parseYearArrayValues(unit.budgetMarketRents?.rawMarketRentsBudget);

                const twoYearsAvgMarketRentsArr = [
                    ...reforecastYearArr,
                    ...[getYearFlatAverage(reforecastYearArr, Math.round)],
                    ...budgetYearArr,
                    ...[getYearFlatAverage(budgetYearArr, Math.round)]
                ];

                const avgMarketRentRow = [
                    ...[`Unit ${unit.number}`, 'Market Rent'],
                    ...twoYearsAvgMarketRentsArr,
                    ...[unit.id, 'avg_mkt_rent', !hasRenoAdjust ? 'no_reno_adjust' : null],
                ];

                avgMarketRents.push(twoYearsAvgMarketRentsArr);
                if(!hasRenoAdjust){
                    avgAdjustedMarketRents.push(twoYearsAvgMarketRentsArr);
                }

                tableData.push(avgMarketRentRow);

                if(hasRenoAdjust){
                    const refoYearRenoPremiumData = parseYearArrayValues(unit.reforecastMarketRents?.proratedRenovationPremiums);
                    const budgetYearRenoPremiumData = parseYearArrayValues(unit.budgetMarketRents?.proratedRenovationPremiums);

                    tableData.push([
                        ...[null, 'Renovation Premium'],
                        ...refoYearRenoPremiumData,
                        ...[getYearFlatAverage(refoYearRenoPremiumData, Math.round)],
                        ...budgetYearRenoPremiumData,
                        ...[getYearFlatAverage(budgetYearRenoPremiumData, Math.round)],
                    ]);

                    const adjustedReforecastYearArr = composeReforecastYearArray(
                        unit.reforecastMarketRents?.adjustedMarketRentsActuals,
                        unit.reforecastMarketRents?.adjustedMarketRentsReforecast,
                        startReforecastMonthIndex,
                    );
                    const adjustBudgetYearArr = parseYearArrayValues(unit.budgetMarketRents?.adjustedMarketRentsBudget);

                    const twoYearsAdjBudgetYearsArr = [
                        ...adjustedReforecastYearArr,
                        ...[getYearFlatAverage(adjustedReforecastYearArr, Math.round)],
                        ...adjustBudgetYearArr,
                        ...[getYearFlatAverage(adjustBudgetYearArr, Math.round)]
                    ];
                    tableData.push([
                        ...['span', 'Adjusted Market Rent'],
                        ...twoYearsAdjBudgetYearsArr,
                        ...['adjusted_mkt_rent', null],
                    ]);

                    avgAdjustedMarketRents.push(twoYearsAdjBudgetYearsArr);
                }
            });

            tableData.push([
                '',
                'Avg Market Rent',
                ...averageTwoYearsArrays(avgMarketRents)
            ]);

            tableData.push([
                '',
                'Avg Adjusted Market Rent',
                ...averageTwoYearsArrays(avgAdjustedMarketRents)
            ]);

            setUnitAndMarketRentsTableData(tableData);
        },
        [unitsAndMarketRentsData, unitsAndMarketRentsLoading]);

    useEffect(
        () => {
            if(!unitAndMarketRentsTableData){
                return;
            }

            setDataReady(true);
        },
        [unitAndMarketRentsTableData],
    );

    const setMarketRent = (unitId:string, whichYear:number, month:number, type:VersionType, marketRent:number):void => {
        console.log(`setMarketRent(${unitId}, ${whichYear}, ${month}, ${type}, ${marketRent}), typeof marketRent: ${typeof marketRent}`);
        setMarketRentMutatation({
            variables:{
                unitId,
                year: whichYear,
                month,
                type,
                marketRent: marketRent.toString(),
            }
        });
    };

    return {
        unitAndMarketRentsTableData,
        setUnitAndMarketRentsTableData,
        unitsAndMarketRentsLoading,
        dataReady,
        setMarketRent,
    };
}
