const GUEST_ID_TOKEN_KEY = "vizibly-guest-id-token";
const GUEST_ACCESS_TOKEN_KEY = "vizibly-guest-access-token";
const GUEST_ACCESS_TOKEN_URL_KEY = "token";

let useGuestIdTokenForServerRequests = false;

export function getGuestIdTokenFromStore():string|null {
    return localStorage.getItem(GUEST_ID_TOKEN_KEY);
}

export function getGuestAccessTokenFromURL():string|null {
    const currentAccessToken = new URL(window.location.href).searchParams.get(GUEST_ACCESS_TOKEN_URL_KEY);
    return currentAccessToken;
}

export function getGuestAccessTokenFromStore():string|null {
    return localStorage.getItem(GUEST_ACCESS_TOKEN_KEY);
}

export function saveGuestAccessTokenToStore(token: string):void {
    localStorage.setItem(GUEST_ACCESS_TOKEN_KEY, token);
}

export function saveGuestIdTokenToStore(token: string):void {
    localStorage.setItem(GUEST_ID_TOKEN_KEY, token);
}

export function removeGuestIdTokenFromStore():void {
    localStorage.removeItem(GUEST_ID_TOKEN_KEY);
}

export function setUseGuestIdTokenForServerRequests(val:boolean):void {
    useGuestIdTokenForServerRequests = val;
}

export function getUseGuestIdTokenForServerRequests():boolean {
    return useGuestIdTokenForServerRequests;
}