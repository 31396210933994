import { Button } from "@zendeskgarden/react-buttons";
import { Body, Close, Footer, FooterItem, Header, Modal } from "@zendeskgarden/react-modals";
import { ReactElement, useEffect, useState } from "react";
import { ItemData } from "../types";
import { AccountStatusCard } from "./AccountStatusCard";
import { FinancialEntityType } from "../../../__generated__/generated_types";
import { MAX_DELETED_ACCOUNTS } from "../utilities";

export type IDeleteConfirmModal = {
    item: ItemData,
    allItems: ItemData[],
    deletedExistingItems: ItemData[],
    onClose: () => void,
    onConfirm: () => void,
}

export default function DeleteConfirmModal(props: IDeleteConfirmModal): ReactElement {
    const [existingItemsToDelete, setExistingItemsToDelete] = useState<ItemData[]>();
    useEffect(() => {
        const existingItemsToDelete: ItemData[] = [];
        if (props.item.type != FinancialEntityType.Account) {
            // check that deleting all sub accounts will not exceed the MAX_DELETED_ACCOUNTS threshold
            for (const item of props.allItems.filter(item => item.parentIds.includes(props.item.id))) {
                if (!item.isNew && item.type == FinancialEntityType.Account) {
                    existingItemsToDelete.push(item);
                }
            }
        }
        setExistingItemsToDelete(existingItemsToDelete);
    }, []);

    const existingItemsToDeleteCount = existingItemsToDelete?.length ?? 0;

    return (
        <Modal onClose={props.onClose} isLarge>
            <Header>
                Are you sure you want to delete {(props.item.glNumber ? props.item.glNumber + " - " : "") + props.item.glName}?
            </Header>
            <Body>
                Record Status:
                {props.item.isNew ?
                <>New Account<br/></>
                :
                <AccountStatusCard {...props}/>
                }
                {(props.deletedExistingItems.length + existingItemsToDeleteCount) > MAX_DELETED_ACCOUNTS ?
                "Deleting this category deletes too many accounts"
                :
                 existingItemsToDeleteCount > 0 ?
                <>
                Following existing subaccounts will be deleted:
                <ul>
                {existingItemsToDelete?.map(item => (
                    <li>
                    {(item.glNumber ? `${item.glNumber} - ` : "")}{item.glName}:
                    <AccountStatusCard item={item}/>
                    </li>
                ))}
                </ul>
                </>
                :
                null
                }

            </Body>
            <Footer>
                <FooterItem>
                    <Button onClick={props.onClose} isBasic>
                        Cancel
                    </Button>
                </FooterItem>
                <FooterItem>
                    <Button isPrimary isDanger onClick={props.onConfirm}>
                        Delete
                    </Button>
                </FooterItem>
            </Footer>
            <Close aria-label="Close modal" />
        </Modal>
    );
}