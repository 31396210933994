import { ApolloError } from "@apollo/client";
import { ReactElement } from "react";

interface Props {
	error?: ApolloError;
	title?: string;
	message?: string;
}
export default function Error({error, title, message}: Props): ReactElement {
    return (
		<div className="w-100 h-100 d-flex align-items-center justify-content-center">
			<div className="border p-4 shadow p-3 mb-5 bg-body rounded">
				<h2>{title ?? error?.name ?? "Error"}</h2>
				<h4 className="mt-3">{message ?? error?.message ?? "Unknown error occured"}</h4>
				{error?.graphQLErrors?.map(error => (
					<div className="border-top mt-2 pt-2">
						<strong>{error.message}</strong>
						<pre>
							<code>
								{error.stack}
							</code>
						</pre>
					</div>
				))}
			</div>

		</div>

    );
}


export const NoDataError = (): ReactElement => <Error title="No Data" message="No data could be found" />;


export const ErrorOrNoData = ({error}: {error?: ApolloError}): ReactElement => (
	error ? <Error error={error} title="Error fetching" /> :<Error title="No Data" message="No data could be found" />
);
