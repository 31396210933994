import {
    GetSinglePropertyDriversAndWorksheetItemsQuery,
    Maybe,
    PayrollDriverCompensationItemPositionsModel,
    VersionType,
    RevenueType,
    GetSinglePropertyDriversAndWorksheetItemsNoCalcEngineQuery,
    GrowthCalcMethod,
    PercentGrowthBasePeriod,
    DistributionMethod,
    MonthlyAverageBasePeriod,
    MonthlyAdjustmentType,
} from "../../../../__generated__/generated_types";

export function hydrateDriverAssumptions (data: GetSinglePropertyDriversAndWorksheetItemsQuery): TDriverMetrics {
    const {
        operationalMetricDriver,
        revenueDriver,
        worksheetDriver,
        growthDriver,
        accountPercentageDriver,
        payrollDriver,
        renovationsDriver,
        customDriver,
        year,
        versionType,
    } = data.singlePropertyAccount;

    // determine if this account actually contains any drivers
    const isDriven = (operationalMetricDriver?.isDriven ?? false)
        || (revenueDriver?.isDriven ?? false)
        || (worksheetDriver?.isDriven ?? false)
        || (growthDriver && growthDriver.length > 0)
        || (accountPercentageDriver?.isDriven ?? false)
        || (payrollDriver?.isDriven ?? false)
        || (renovationsDriver?.isDriven ?? false)
        || (customDriver?.isDriven ?? false);

    const drivers = {
        operational: [],
        revenue: [],
        worksheet: [],
        monthlyAverage: [],
        percentGrowth: [],
        annualTargetValue: [],
        account: [],
        accountPercentageAugment: {
            minValue: null,
            maxValue: null
        },
        payroll: [],
        renovations: [],
        customDriver: []
    } as TDriverMetricsYear;

    // only look for drivers to build if the account is driven
    if (isDriven) {
        // hydrate operational drivers
        operationalMetricDriver?.sourceMetrics.forEach(metric => {
            drivers.operational.push({
                type: metric.metricName,
                amount: metric.assumptionsAndValues[0]?.amount,
                sourceMetricId: metric.sourceMetricId,
                driverMetricTypeId: metric.driverMetricTypeId,
                locked: metric.locked == undefined || metric.locked == null ? false : metric.locked,
                lookbackPeriod: metric.assumptionsAndValues[0]?.lookbackPeriod,
                percentage: metric.assumptionsAndValues.map(x => x.percentage),
                values: metric.assumptionsAndValues.map(x => x.amount),
            });
        });
        drivers.operational.sort(
            (a, b) =>
                a.type > b.type ? 1 : a.type < b.type ? -1 : 0
        );

        // hydrate custom drivers
        customDriver?.items.forEach(item => {
            drivers.customDriver.push({
                id: item.id,
                itemName: item.itemName,
                amount: item.assumptions.map(x => x.amount),
                percentage: item.assumptions.map(x => x.percentOf),
                count: item.assumptions.map(x => x.count),
            });
        });
        drivers.customDriver.sort(
            (a, b) =>
                a.itemName > b.itemName ? 1 : a.itemName < b.itemName ? -1 : 0
        );

        // hydrate account percentage drivers
        accountPercentageDriver?.sourceAccounts.forEach(account => {
            drivers.account.push({
                accountId: account.accountId,
                glNumber: account.glNumber,
                glName: account.glName,
                locked: account.locked == undefined || account.locked == null ? false : account.locked,
                assumptionId: account.assumptionId ?? '',
                acctPercentageDriverAccountId: account.acctPercentageDriverAccountId,
                lookbackPeriod: account.assumptionsAndValues[0]?.lookbackPeriod,
                percentage: account.assumptionsAndValues.map(x => x.percentage),
                values: account.assumptionsAndValues.map(x => x.accountValue),
            });
        });
        drivers.account.sort(
            (a, b) =>
                a.glName > b.glName ? 1 : a.glName < b.glName ? -1 : 0
        );

        // hydrate the account percentage augments
        if(accountPercentageDriver?.augments) {
            drivers.accountPercentageAugment = {
                minValue: accountPercentageDriver.augments.minValue,
                maxValue: accountPercentageDriver.augments.maxValue
            };
        }

        // hydrate worksheet drivers
        worksheetDriver?.worksheetLines.forEach(line => {
            drivers.worksheet.push({
                description: line.description,
                values: line.values,
            });
        });

        drivers.worksheet.sort(
            (a, b) =>
                a.description > b.description ? 1 : a.description < b.description ? -1 : 0
        );

        const appliedGrowthDriver = growthDriver[0];
        if (appliedGrowthDriver) {
            const { __typename, ...appliedGrowthDriverWOTypeName } = appliedGrowthDriver;
            if (appliedGrowthDriverWOTypeName.growthCalcMethod === GrowthCalcMethod.MonthlyAverage) {
                drivers.monthlyAverage.push(appliedGrowthDriverWOTypeName);
            } else if (appliedGrowthDriverWOTypeName.growthCalcMethod === GrowthCalcMethod.PercentGrowth) {
                drivers.percentGrowth.push(appliedGrowthDriverWOTypeName);
            } else if (appliedGrowthDriverWOTypeName.growthCalcMethod === GrowthCalcMethod.AnnualTargetValue) {
                drivers.annualTargetValue.push(appliedGrowthDriverWOTypeName);
            }
        }

        // hydrate remaining drivers
        // Note: this array destructuring was introduced because these sort operations began failing and crashing the
        // frontend if values are present in either the sourceRevenueMetrics, compensationItems, or costCategories arrays
        drivers.revenue = [...revenueDriver?.sourceRevenueMetrics ?? []];
        drivers.revenue.sort(
            (a, b) => a.revenueType > b.revenueType ? 1 : a.revenueType < b.revenueType ? -1 : 0
        );

        drivers.payroll = [...payrollDriver?.compensationItems ?? []];
        drivers.payroll.sort(
            (a, b) => a.itemType > b.itemType ? 1 : a.itemType < b.itemType ? -1 : 0
        );

        drivers.renovations = [...renovationsDriver?.costCategories ?? []];
        drivers.renovations.sort(
            (a, b) => a.name > b.name ? 1 : a.name < b.name ? -1 : 0
        );
    }

    return {
        year,
        versionType,
        isDriven,
        drivers,
    };
}

export function hydrateDriverAssumptionsNoCalcEngine (data: GetSinglePropertyDriversAndWorksheetItemsNoCalcEngineQuery): TDriverMetrics {
    const {
        operationalMetricDriver,
        revenueDriver,
        worksheetDriver,
        accountPercentageDriver,
        growthDriver,
        payrollDriver,
        renovationsDriver,
        customDriver,
        year,
        versionType,
    } = data.singlePropertyAccount;

    // determine if this account actually contains any drivers
    const isDriven = (operationalMetricDriver?.isDriven ?? false)
        || (revenueDriver?.isDriven ?? false)
        || (worksheetDriver?.isDriven ?? false)
        || (growthDriver && growthDriver.length > 0)
        || (accountPercentageDriver?.isDriven ?? false)
        || (payrollDriver?.isDriven ?? false)
        || (renovationsDriver?.isDriven ?? false)
        || (customDriver?.isDriven ?? false);

    const drivers = {
        operational: [],
        revenue: [],
        worksheet: [],
        account: [],
        accountPercentageAugment: {
            minValue: null,
            maxValue: null
        },
        payroll: [],
        monthlyAverage: [],
        percentGrowth: [],
        annualTargetValue: [],
        renovations: [],
        customDriver: []
    } as TDriverMetricsYear;

    // only look for drivers to build if the account is driven
    if (isDriven) {
        // hydrate operational drivers
        operationalMetricDriver?.sourceMetricAssumptions.forEach(metric => {
            drivers.operational.push({
                type: metric.metricName,
                amount: metric.assumptions[0]?.amount,
                sourceMetricId: metric.sourceMetricId,
                driverMetricTypeId: metric.driverMetricTypeId,
                locked: metric.locked == undefined || metric.locked == null ? false : metric.locked,
                lookbackPeriod: metric.assumptions[0]?.lookbackPeriod,
                percentage: metric.assumptions.map(x => x.percentage),
                values: metric.assumptions.map(x => x.amount),
            });
        });
        drivers.operational.sort(
            (a, b) =>
                a.type > b.type ? 1 : a.type < b.type ? -1 : 0
        );

        // hydrate custom drivers
        customDriver?.items.forEach(item => {
            drivers.customDriver.push({
                id: item.id,
                itemName: item.itemName,
                amount: item.assumptions.map(x => x.amount),
                percentage: item.assumptions.map(x => x.percentOf),
                count: item.assumptions.map(x => x.count),
            });
        });
        drivers.customDriver.sort(
            (a, b) =>
                a.itemName > b.itemName ? 1 : a.itemName < b.itemName ? -1 : 0
        );

        // hydrate account percentage drivers
        accountPercentageDriver?.sourceAccountAssumptions.forEach(account => {
            drivers.account.push({
                accountId: account.accountId,
                glNumber: account.glNumber,
                glName: account.glName,
                locked: account.locked == undefined || account.locked == null ? false : account.locked,
                assumptionId: account.assumptionId ?? '',
                acctPercentageDriverAccountId: account.acctPercentageDriverAccountId,
                lookbackPeriod: account.assumptions[0]?.lookbackPeriod,
                percentage: account.assumptions.map(x => x.percentage),
                values: [],
            });
        });
        drivers.account.sort(
            (a, b) =>
                a.glName > b.glName ? 1 : a.glName < b.glName ? -1 : 0
        );

        // hydrate the account percentage augments
        if(accountPercentageDriver?.augments) {
            drivers.accountPercentageAugment = {
                minValue: accountPercentageDriver.augments.minValue,
                maxValue: accountPercentageDriver.augments.maxValue
            };
        }

        // hydrate worksheet drivers
        worksheetDriver?.worksheetLines.forEach(line => {
            drivers.worksheet.push({
                description: line.description,
                values: line.values,
            });
        });

        drivers.worksheet.sort(
            (a, b) =>
                a.description > b.description ? 1 : a.description < b.description ? -1 : 0
        );

        const appliedGrowthDriver = growthDriver[0];
        if (appliedGrowthDriver) {
            const { __typename, ...appliedGrowthDriverWOTypeName } = appliedGrowthDriver;
            if (appliedGrowthDriverWOTypeName.growthCalcMethod === GrowthCalcMethod.MonthlyAverage) {
                drivers.monthlyAverage.push(appliedGrowthDriverWOTypeName);
            } else if (appliedGrowthDriverWOTypeName.growthCalcMethod === GrowthCalcMethod.PercentGrowth) {
                drivers.percentGrowth.push(appliedGrowthDriverWOTypeName);
            } else if (appliedGrowthDriverWOTypeName.growthCalcMethod === GrowthCalcMethod.AnnualTargetValue) {
                drivers.annualTargetValue.push(appliedGrowthDriverWOTypeName);
            }
        }

        // hydrate remaining drivers
        // Note: this array destructuring was introduced because these sort operations began failing and crashing the
        // frontend if values are present in either the sourceRevenueMetrics, compensationItems, or costCategories arrays
        drivers.revenue = [...revenueDriver?.sourceRevenueMetrics ?? []];
        drivers.revenue.sort(
            (a, b) => a.revenueType > b.revenueType ? 1 : a.revenueType < b.revenueType ? -1 : 0
        );

        drivers.payroll = [...payrollDriver?.compensationItems ?? []];
        drivers.payroll.sort(
            (a, b) => a.itemType > b.itemType ? 1 : a.itemType < b.itemType ? -1 : 0
        );

        drivers.renovations = [...renovationsDriver?.costCategories ?? []];
        drivers.renovations.sort(
            (a, b) => a.name > b.name ? 1 : a.name < b.name ? -1 : 0
        );
    }

    return {
        year,
        versionType,
        isDriven,
        drivers,
    };
}

export type TDriverMetrics = {
    year: number,
    versionType: VersionType,
    isDriven: boolean,
    drivers: TDriverMetricsYear,
}

export type TDriverMetricsYear = {
    operational: TDriverOperationalMetric[],
    revenue: TDriverRevenueMetric[],
    worksheet: TDriverWorksheetMetric[],
    monthlyAverage: TDriverGrowthMetric[],
    percentGrowth: TDriverGrowthMetric[],
    annualTargetValue: TDriverGrowthMetric[],
    account: TDriverAccountMetric[],
    accountPercentageAugment: TDriverAccountAugments,
    payroll: TDriverPayrollMetric[],
    renovations: TDriverRenovationsMetric[],
    customDriver: TDriverCustomItem[]
}

export type TPendingDriverMetricsYear = {
    operational: TPendingDriverOperationalMetric[],
    customItems: TPendingDriverCustomItem[],
    revenue: TDriverRevenueMetric[],
    worksheet: TPendingDriverWorksheetMetric[],
    monthlyAverage: TPendingDriverGrowthMetric[],
    percentGrowth: TPendingDriverGrowthMetric[],
    annualTargetValue: TPendingDriverGrowthMetric[],
    account: TPendingDriverAccountMetric[],
    accountPercentageAugment: TDriverAccountAugments | null,
    payroll: TPendingDriverPayrollMetric[],
}

export type TPendingDriverAccountMetric = {
    accountId: string;
    lookbackPeriod: number;
}

export type TPendingDriverOperationalMetric = {
    type: string,
    sourceMetricId: string,
    driverMetricTypeId: string,
    lookbackPeriod: number | undefined | null,
}

export type TPendingDriverCustomItem = string;

export type TPendingDriverPayrollMetric = {
    compId: string;
    itemType: string;
    positions: PayrollDriverCompensationItemPositionsModel[];
}

export type TPendingDriverWorksheetMetric = {
    description: string,
}

export type TPendingDriverGrowthMetric = {
    id: string,
    growthCalcMethod: GrowthCalcMethod,
    percentGrowthBasePeriod?: PercentGrowthBasePeriod | null | undefined,
    percentGrowthAdjustmentValue?: number | null | undefined,
    distributionMethod?: DistributionMethod | null | undefined,
    monthlyAverageBasePeriod?: MonthlyAverageBasePeriod | null,
    monthlyAdjustmentType?: MonthlyAdjustmentType | null,
    monthlyAdjustmentValue?: number | null,
    lookbackPeriodStart?: string | null,
    lookbackPeriodEnd?: string | null,
    annualTargetValueManualEntry?: number | null,
}

export type TDriverOperationalMetric = {
    type: string,
    amount: number | undefined,
    sourceMetricId: string,
    driverMetricTypeId: string,
    lookbackPeriod: number | undefined | null,
    percentage: (number | null)[],
    values: (number | null | string)[],
    locked: boolean,
}

export type TDriverCustomItem = {
    id: string,
    itemName: string,
    amount: (number | null)[],
    percentage: (number | null)[],
    count: (number | null)[],
}

export type TDriverRevenueMetric = {
    sourceId: string,
    revenueType: RevenueType,
}

export type TDriverWorksheetMetric = {
    description: string,
    values: (number | null | string)[],
}

export type TDriverGrowthMetric = {
    id: string,
    growthCalcMethod: GrowthCalcMethod,
    percentGrowthBasePeriod?: PercentGrowthBasePeriod | null | undefined,
    percentGrowthAdjustmentValue?: number | null | undefined,
    distributionMethod?: DistributionMethod | null | undefined,
    monthlyAverageBasePeriod?: MonthlyAverageBasePeriod | null,
    monthlyAdjustmentType?: MonthlyAdjustmentType | null,
    monthlyAdjustmentValue?: number | null,
    lookbackPeriodStart?: string | null,
    lookbackPeriodEnd?: string | null,
    annualTargetValueManualEntry?: number | null,
}

export type TDriverAccountMetric = {
    accountId: string,
    assumptionId: string | undefined,
    acctPercentageDriverAccountId: string,
    glNumber?: string|null,
    glName: string,
    percentage: number[],
    lookbackPeriod?: number | null | undefined,
    values: (number | null | string)[],
    locked: boolean,
}

export type TDriverAccountAugments = {
    // The values are decimals and are numeric strings or null.
    minValue: string | null;
    maxValue: string | null;
}

export type TDriverPayrollMetric = {
    itemType: string;
    positions: PayrollDriverCompensationItemPositionsModel[];
}

export type TDriverRenovationsMetric = {
    id: string,
    name: string,
}
