import Handsontable from "handsontable";
import { TAccountTableRowData } from "../../../components/account-table/data/AccountTableData";
import * as tableCSS from "../../../components/account-table/styles/accountTable.module.scss";

export interface UnitData {
    actuals: (number | null)[];
    reforecast: (number | null)[],
    budget: (number | null)[],
    unitTypeId: string;
    unitTypeName: string;
    unitCounts?: IUnitTypeCounts;
}

export interface IUnitTypeCounts {
    actuals: (number | null)[];
    reforecast: (number | null)[];
    budget: (number | null)[];
}

export interface UnitTypeOccupancy {
    moveIns: UnitData[],
    moveOuts: UnitData[],
    occupancyPercentage: UnitData[],
    occupiedUnitCount: UnitData[],
    vacancyPercentage: UnitData[],
    vacantCount: UnitData[],
}

export interface UnitTypeRenewals {
    expirationCount: UnitData[],
    expirationPercentage: UnitData[],
    renewalCount: UnitData[],
    renewalPercentage: UnitData[],
}

export interface UnitTypeRenovations {
    renovatedExpirationCount: UnitData[],
    renovatedRenewalRatio: UnitData[],
    renovatedUnitCount: UnitData[],
    renovationCount: UnitData[],
    unrenovatedExpirationCount: UnitData[],
    unrenovatedRenewalRatio: UnitData[],
    unrenovatedUnitCount: UnitData[],
}

export interface SimplifiedRevenueModel {
    budgetYear: number,
    propertyId: string,
    unitTypeAvgMarketRents: UnitData[],
    unitTypeBlendedInPlaceRent: UnitData[],
    unitTypeNewLeaseInPlaceRent: UnitData[],
    unitTypeOccupancy: UnitTypeOccupancy,
    unitTypeRenewalInPlaceRent: UnitData[],
    unitTypeRenewalTradeOutAmount: UnitData[],
    unitTypeRenewals: UnitTypeRenewals,
    unitTypeRenovations: UnitTypeRenovations,
    unitTypeUnitCounts: UnitData[],
}

export const enum SummaryTableRentRowNames {
    marketRent = 'marketRent',
    blendedInPlaceRent = 'blendedInPlaceRent',
    renewalInPlaceRent = 'renewalInPlaceRent',
    newLeaseInPlaceRent = 'newLeaseInPlaceRent',
    renewalTradeout = 'renewalTradeout',
}

export const enum SummaryTableRevenueRowNames {
    unitCounts = 'unitCounts',
    expirationPercentage = 'expirationPercentage',
    expirationCount = 'expirationCount',
    renewalCount = 'renewalCount',
    renewalPercentage = 'renewalPercentage',
}

export const enum SummaryTableOccupancyRowNames {
    unitCounts = 'unitCounts',
    moveIns = 'moveIns',
    moveOuts = 'moveOuts',
    occupiedUnitCount = 'occupiedUnitCount',
    vacantCount = 'vacantCount',
    occupancyPercentage = 'occupancyPercentage',
    vacancyPercentage = 'vacancyPercentage',
}

export const enum SummaryTableRenovationsRowNames {
    unrenovatedUnitCount = 'unrenovatedUnitCount',
    renovatedUnitCount = 'renovatedUnitCount',
    unrenovatedExpirationCount = 'unrenovatedExpirationCount',
    renovatedExpirationCount = 'renovatedExpirationCount',
    renovationCount = 'renovationCount',
    unrenovatedRenewalRatio = 'unrenovatedRenewalRatio',
    renovatedRenewalRatio = 'renovatedRenewalRatio',
}

export const summaryTableRentRowMap = [
    { label: 'Market Rent', value: SummaryTableRentRowNames.marketRent, rawDataValue: 'unitTypeAvgMarketRents', dataFormat: 'currency' },
    { label: 'Blended In Place Rent', value: SummaryTableRentRowNames.blendedInPlaceRent, rawDataValue: 'unitTypeBlendedInPlaceRent', dataFormat: 'currency' },
    { label: 'Renewal In Place Rent', value: SummaryTableRentRowNames.renewalInPlaceRent, rawDataValue: 'unitTypeRenewalInPlaceRent', dataFormat: 'currency' },
    { label: 'New Lease In Place Rent', value: SummaryTableRentRowNames.newLeaseInPlaceRent, rawDataValue: 'unitTypeNewLeaseInPlaceRent', dataFormat: 'currency' },
    { label: 'Renewal Tradeout $', value: SummaryTableRentRowNames.renewalTradeout, rawDataValue: 'unitTypeRenewalTradeOutAmount', dataFormat: 'currency' },
];

export const summaryTableRenewalRowMap = [
    { label: 'Total Units', value: SummaryTableRevenueRowNames.unitCounts, rawDataValue: 'unitTypeUnitCounts', dataFormat: 'count' },
    { label: '% Expiring', value: SummaryTableRevenueRowNames.expirationPercentage, rawDataValue: 'expirationPercentage', dataFormat: 'percentage' },
    { label: 'Expiring Leases', value: SummaryTableRevenueRowNames.expirationCount, rawDataValue: 'expirationCount', dataFormat: 'count' },
    { label: 'Renewals', value: SummaryTableRevenueRowNames.renewalCount, rawDataValue: 'renewalCount', dataFormat: 'count' },
    { label: 'Renewal Ratio', value: SummaryTableRevenueRowNames.renewalPercentage, rawDataValue: 'renewalPercentage', dataFormat: 'percentage' },
];

export const summaryTableOccupancyRowMap = [
    { label: 'Total Units', value: SummaryTableOccupancyRowNames.unitCounts, rawDataValue: 'unitTypeUnitCounts', dataFormat: 'count' },
    { label: 'Move Ins', value: SummaryTableOccupancyRowNames.moveIns, rawDataValue: 'moveIns', dataFormat: 'count' },
    { label: 'Move Outs', value: SummaryTableOccupancyRowNames.moveOuts, rawDataValue: 'moveOuts', dataFormat: 'count' },
    { label: 'Occupied Units', value: SummaryTableOccupancyRowNames.occupiedUnitCount, rawDataValue: 'occupiedUnitCount', dataFormat: 'count' },
    { label: 'Vacant Units', value: SummaryTableOccupancyRowNames.vacantCount, rawDataValue: 'vacantCount', dataFormat: 'count' },
    { label: 'Occupancy Rate', value: SummaryTableOccupancyRowNames.occupancyPercentage, rawDataValue: 'occupancyPercentage', dataFormat: 'percentage' },
    { label: 'Vacancy Rate', value: SummaryTableOccupancyRowNames.vacancyPercentage, rawDataValue: 'vacancyPercentage', dataFormat: 'percentage' },
];

export const summaryTableRenovationsRowMap = [
    { label: 'Unit Count (Classic)', value: SummaryTableRenovationsRowNames.unrenovatedUnitCount, rawDataValue: 'unrenovatedUnitCount', dataFormat: 'count' },
    { label: 'Unit Count (Renovated)', value: SummaryTableRenovationsRowNames.renovatedUnitCount, rawDataValue: 'renovatedUnitCount', dataFormat: 'count' },
    { label: 'Expiring Leases (Classic)', value: SummaryTableRenovationsRowNames.unrenovatedExpirationCount, rawDataValue: 'unrenovatedExpirationCount', dataFormat: 'count' },
    { label: 'Expiring Leases (Renovated)', value: SummaryTableRenovationsRowNames.renovatedExpirationCount, rawDataValue: 'renovatedExpirationCount', dataFormat: 'count' },
    { label: 'Renovations', value: SummaryTableRenovationsRowNames.renovationCount, rawDataValue: 'renovationCount', dataFormat: 'count' },
    { label: 'Renewal Ratio (Classic)', value: SummaryTableRenovationsRowNames.unrenovatedRenewalRatio, rawDataValue: 'unrenovatedRenewalRatio', dataFormat: 'percentage' },
    { label: 'Renewal Ratio (Renovated)', value: SummaryTableRenovationsRowNames.renovatedRenewalRatio, rawDataValue: 'renovatedRenewalRatio', dataFormat: 'percentage' },
];

export interface SummaryTableGenericRowValues {
    dataFormat: string,
    decimalPoints: number,
    label: string,
    value: string,
    actuals: (number | null)[],
    budget: (number | null)[],
    reforecast: (number | null)[],
    cellValues: (number | null)[],
    unitCounts?: {
        actuals: (number | null)[],
        budget: (number | null)[],
        reforecast: (number | null)[],
    }
}

export interface ITableDataByTabAndUnitType {
    id: string;
    dataFormat: string;
    decimalPoints: number;
    parentId: string;
    col0: string | number | null;
    col1: string | number | null;
    col2: string | number | null;
    col3: string | number | null;
    col4: string | number | null;
    col5: string | number | null;
    col6: string | number | null;
    col7: string | number | null;
    col8: string | number | null;
    col9: string | number | null;
    col10: string | number | null;
    col12: string | number | null;
    col13: string | number | null;
    col14: string | number | null;
    col15: string | number | null;
    col16: string | number | null;
    col17: string | number | null;
    col18: string | number | null;
    col19: string | number | null;
    col20: string | number | null;
    col21: string | number | null;
    col22: string | number | null;
    col23: string | number | null;
    col24: string | number | null;
    col25: string | number | null;
    col26: string | number | null;
    col27: string | number | null;
}

export interface SummaryTableParentRow extends Omit<SummaryTableGenericRowValues, 'value' | 'cellValues'> {
    label: string,
    value: SummaryTableRentRowNames | SummaryTableRevenueRowNames | SummaryTableOccupancyRowNames | SummaryTableRenovationsRowNames,
    rows: SummaryTableGenericRowValues[],
}

export const enum TabNames {
    rent = 'rent',
    renewalRatio = 'renewalRatio',
    occupancy = 'occupancy',
    renovations = 'renovations',
}

export interface SummaryTableData {
    [TabNames.rent]: SummaryTableParentRow[]
    [TabNames.renewalRatio]: SummaryTableParentRow[]
    [TabNames.occupancy]: SummaryTableParentRow[]
    [TabNames.renovations]: SummaryTableParentRow[]
}

export interface TAccountTableChildRowData extends TAccountTableRowData {
    defaultMonthlyValues: (number | null)[];
}

export const ALL_UNIT_TYPES = "All Unit Types";

export const buildSummaryTableData = (rawData: SimplifiedRevenueModel, reforecastStartMonthIndex: number): SummaryTableData => {
    const tableData: SummaryTableData = {
        [TabNames.rent]: [],
        [TabNames.renewalRatio]: [],
        [TabNames.occupancy]: [],
        [TabNames.renovations]: [],
    };

    const getChildRowByUnit = (unit: UnitData, decimalPoints = 0) => ({
        label: unit.unitTypeName,
        value: unit.unitTypeId,
        decimalPoints: decimalPoints,
        actuals: unit.actuals,
        budget: unit.budget,
        reforecast: unit.reforecast,
        unitCounts: getUnitCounts(rawData, unit.unitTypeId),
        cellValues: [
            ...unit.actuals.slice(0, reforecastStartMonthIndex),
            ...unit.reforecast.slice(reforecastStartMonthIndex),
            ...unit.budget,
        ],
    });

    tableData[TabNames.rent] = summaryTableRentRowMap.map((each): SummaryTableParentRow => {
        const getChildRows = () => {
            if (each.value === SummaryTableRentRowNames.marketRent) {
                return rawData.unitTypeAvgMarketRents.map((eachUnit) => getChildRowByUnit(eachUnit));
            }
            if (each.value === SummaryTableRentRowNames.blendedInPlaceRent) {
                return rawData.unitTypeBlendedInPlaceRent.map((eachUnit) => getChildRowByUnit(eachUnit));
            }
            if (each.value === SummaryTableRentRowNames.renewalInPlaceRent) {
                return rawData.unitTypeRenewalInPlaceRent.map((eachUnit) => getChildRowByUnit(eachUnit));
            }
            if (each.value === SummaryTableRentRowNames.newLeaseInPlaceRent) {
                return rawData.unitTypeNewLeaseInPlaceRent.map((eachUnit) => getChildRowByUnit(eachUnit));
            }
            return rawData.unitTypeRenewalTradeOutAmount.map((eachUnit) => getChildRowByUnit(eachUnit));
        };

        return {
            dataFormat: each.dataFormat,
            decimalPoints: 0,
            label: each.label,
            value: each.value,
            actuals: new Array(12).fill(0),
            budget: new Array(12).fill(0),
            reforecast: new Array(12).fill(0),
            rows: getChildRows().map((e) => ({ ...e, dataFormat: each.dataFormat })),
        };
    });

    tableData[TabNames.renewalRatio] = summaryTableRenewalRowMap.map((each): SummaryTableParentRow => {
        const getChildRows = () => {
            if (each.value === SummaryTableRevenueRowNames.unitCounts) {
                return rawData.unitTypeUnitCounts.map((eachUnit) => getChildRowByUnit(eachUnit));
            }
            if (each.value === SummaryTableRevenueRowNames.expirationPercentage) {
                return rawData.unitTypeRenewals.expirationPercentage.map((eachUnit) => getChildRowByUnit(eachUnit));
            }
            if (each.value === SummaryTableRevenueRowNames.expirationCount) {
                return rawData.unitTypeRenewals.expirationCount.map((eachUnit) => getChildRowByUnit(eachUnit, 2));
            }
            if (each.value === SummaryTableRevenueRowNames.renewalCount) {
                return rawData.unitTypeRenewals.renewalCount.map((eachUnit) => getChildRowByUnit(eachUnit, 2));
            }
            return rawData.unitTypeRenewals.renewalPercentage.map((eachUnit) => getChildRowByUnit(eachUnit));
        };

        let decimalPoints = 0;
        if(each.value === SummaryTableRevenueRowNames.expirationCount ||
           each.value === SummaryTableRevenueRowNames.renewalCount) {
            decimalPoints = 2;
        }

        return {
            dataFormat: each.dataFormat,
            decimalPoints: decimalPoints,
            label: each.label,
            value: each.value,
            actuals: new Array(12).fill(0),
            budget: new Array(12).fill(0),
            reforecast: new Array(12).fill(0),
            rows: getChildRows().map((e) => ({ ...e, dataFormat: each.dataFormat })),
        };
    });

    tableData[TabNames.occupancy] = summaryTableOccupancyRowMap.map((each): SummaryTableParentRow => {
        const getChildRows = () => {
            if (each.value === SummaryTableOccupancyRowNames.unitCounts) {
                return rawData.unitTypeUnitCounts.map((eachUnit) => getChildRowByUnit(eachUnit, 2));
            }
            if (each.value === SummaryTableOccupancyRowNames.moveIns) {
                return rawData.unitTypeOccupancy.moveIns.map((eachUnit) => getChildRowByUnit(eachUnit, 2));
            }
            if (each.value === SummaryTableOccupancyRowNames.moveOuts) {
                return rawData.unitTypeOccupancy.moveOuts.map((eachUnit) => getChildRowByUnit(eachUnit, 2));
            }
            if (each.value === SummaryTableOccupancyRowNames.occupiedUnitCount) {
                return rawData.unitTypeOccupancy.occupiedUnitCount.map((eachUnit) => getChildRowByUnit(eachUnit, 2));
            }
            if (each.value === SummaryTableOccupancyRowNames.vacantCount) {
                return rawData.unitTypeOccupancy.vacantCount.map((eachUnit) => getChildRowByUnit(eachUnit, 2));
            }
            if (each.value === SummaryTableOccupancyRowNames.occupancyPercentage) {
                return rawData.unitTypeOccupancy.occupancyPercentage.map((eachUnit) => getChildRowByUnit(eachUnit));
            }
            return rawData.unitTypeOccupancy.vacancyPercentage.map((eachUnit) => getChildRowByUnit(eachUnit));
        };

        let decimalPoints = 0;
        if(each.value === SummaryTableOccupancyRowNames.moveIns ||
           each.value === SummaryTableOccupancyRowNames.moveOuts ||
           each.value === SummaryTableOccupancyRowNames.occupiedUnitCount ||
           each.value === SummaryTableOccupancyRowNames.vacantCount ||
           each.value === SummaryTableOccupancyRowNames.unitCounts) {
            decimalPoints = 2;
        }

        return {
            dataFormat: each.dataFormat,
            decimalPoints: decimalPoints,
            label: each.label,
            value: each.value,
            actuals: new Array(12).fill(0),
            budget: new Array(12).fill(0),
            reforecast: new Array(12).fill(0),
            rows: getChildRows().map((e) => ({ ...e, dataFormat: each.dataFormat })),
        };
    });

    tableData[TabNames.renovations] = summaryTableRenovationsRowMap.map((each): SummaryTableParentRow => {
        const getChildRows = () => {
            if (each.value === SummaryTableRenovationsRowNames.unrenovatedUnitCount) {
                return rawData.unitTypeRenovations.unrenovatedUnitCount.map((eachUnit) => getChildRowByUnit(eachUnit, 2));
            }
            if (each.value === SummaryTableRenovationsRowNames.renovatedUnitCount) {
                return rawData.unitTypeRenovations.renovatedUnitCount.map((eachUnit) => getChildRowByUnit(eachUnit, 2));
            }
            if (each.value === SummaryTableRenovationsRowNames.unrenovatedExpirationCount) {
                return rawData.unitTypeRenovations.unrenovatedExpirationCount.map((eachUnit) => getChildRowByUnit(eachUnit, 2));
            }
            if (each.value === SummaryTableRenovationsRowNames.renovatedExpirationCount) {
                return rawData.unitTypeRenovations.renovatedExpirationCount.map((eachUnit) => getChildRowByUnit(eachUnit, 2));
            }
            if (each.value === SummaryTableRenovationsRowNames.renovationCount) {
                return rawData.unitTypeRenovations.renovationCount.map((eachUnit) => getChildRowByUnit(eachUnit, 2));
            }
            if (each.value === SummaryTableRenovationsRowNames.unrenovatedRenewalRatio) {
                return rawData.unitTypeRenovations.unrenovatedRenewalRatio.map((eachUnit) => getChildRowByUnit(eachUnit, ));
            }
            return rawData.unitTypeRenovations.renovatedRenewalRatio.map((eachUnit) => getChildRowByUnit(eachUnit, ));
        };

        let decimalPoints = 0;
        if(each.value === SummaryTableRenovationsRowNames.renovationCount ||
           each.value === SummaryTableRenovationsRowNames.unrenovatedUnitCount ||
           each.value === SummaryTableRenovationsRowNames.renovatedUnitCount ||
           each.value === SummaryTableRenovationsRowNames.unrenovatedExpirationCount ||
           each.value === SummaryTableRenovationsRowNames.renovatedExpirationCount) {
            decimalPoints = 2;
        }

        return {
            dataFormat: each.dataFormat,
            decimalPoints: decimalPoints,
            label: each.label,
            value: each.value,
            actuals: new Array(12).fill(0),
            budget: new Array(12).fill(0),
            reforecast: new Array(12).fill(0),
            rows: getChildRows().map((e) => ({ ...e, dataFormat: each.dataFormat })),
        };
    });

    return tableData;
};

export function hideAllChildRows(hotInstance: Handsontable, data: ITableDataByTabAndUnitType[]): void {
    const currentHiddenRows = hotInstance.getPlugin("hiddenRows");
    const childRowIndexes: number[] = [];
    const alreadyHiddenRows = currentHiddenRows.getHiddenRows();

    for (let i = 0; i < data.length; i++) {
        const rowData = data[i];

        if (rowData && rowData.parentId && !alreadyHiddenRows.includes(i)) {
            childRowIndexes.push(i);
            const cell = hotInstance.getCellMeta(i, 0);
            const currentClasses: string = cell.className as string;
            hotInstance.setCellMeta(i, 0, "className", currentClasses.replace(tableCSS.parentRowVisible, ""));
        }
    }

    currentHiddenRows.hideRows(childRowIndexes);
}

export function toggleHiddenRows(hotInstance: Handsontable, data: ITableDataByTabAndUnitType[], id: string, row: number, selectedTab: TabNames): boolean {
    const currentHiddenRows = hotInstance.getPlugin("hiddenRows");
    const childRowIndexes: number[] = [];
    const cell = hotInstance.getCellMeta(row, 0);
    let isVisible;

    for (let i = 0; i < data.length; i++) {
        const rowData = data[i];

        const getMappedId = () => {
            if (selectedTab === TabNames.rent) {
                return summaryTableRentRowMap.find((each) => each.value === id)?.label;
            }
            if (selectedTab === TabNames.renewalRatio) {
                return summaryTableRenewalRowMap.find((each) => each.value === id)?.label;
            }
            if (selectedTab === TabNames.occupancy) {
                return summaryTableOccupancyRowMap.find((each) => each.value === id)?.label;
            }
            return summaryTableRenovationsRowMap.find((each) => each.value === id)?.label;
        };

        const mappedId = getMappedId();

        if (rowData && rowData.parentId && mappedId && rowData.parentId == mappedId) {
            childRowIndexes.push(i);
        }
    }

    const alreadyHiddenRows = currentHiddenRows.getHiddenRows();
    const currentClasses: string = cell.className as string;

    if (childRowIndexes[0] && alreadyHiddenRows.includes(childRowIndexes[0])) {
        hotInstance.setCellMeta(row, 0, "className", `${cell.className} ${tableCSS.parentRowVisible}`);
        currentHiddenRows.showRows(childRowIndexes);
        isVisible = true;
    } else {
        hotInstance.setCellMeta(row, 0, "className", currentClasses.replace(tableCSS.parentRowVisible, ""));
        currentHiddenRows.hideRows(childRowIndexes);
        isVisible = false;
    }

    return isVisible;
}

export function getUnitCounts(rawData: SimplifiedRevenueModel, unitTypeId: string): {actuals: (number | null)[], reforecast: (number | null)[], budget: (number | null)[]} {
    const counts = rawData.unitTypeUnitCounts.find(x => x.unitTypeId == unitTypeId);

    return {
        actuals: counts?.actuals ?? Array(12).fill(null),
        reforecast: counts?.reforecast ??  Array(12).fill(null),
        budget: counts?.budget ??  Array(12).fill(null),
    }
}

interface IGetChildAverageReturn {
    [key: number]: number | null;
}

export const getChildAverages = (children: (number | null)[][]): IGetChildAverageReturn => {
    const columns = new Array(12).fill(0);
    const childAverages: {[key: number]: number | null } = {};

    columns.forEach((_e, i) => {
        let sum = 0;
        let nullCount = 0;

        children.forEach((c) => {
            const curr = c[i];
            if (curr === null || curr === undefined) {
                nullCount++;
            } else {
                sum += curr;
            }
        });

        childAverages[i] = nullCount === children.length ? null : Math.round(sum/(children.length - nullCount));
    });

    return childAverages;
};
