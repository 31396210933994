import {ForwardedRef, forwardRef, ReactElement, useEffect, useImperativeHandle, useState} from "react";
import {JobType, useListJobsLazyQuery} from "../../../__generated__/generated_types";
import {Body, Cell, Head, HeaderCell, HeaderRow, Row, Table} from "@zendeskgarden/react-tables";
import {Pagination} from "@zendeskgarden/react-pagination";

export interface ListJobsProps {
    jobType: JobType
}

export interface ListJobsRef {
    reload: () => void;
}

export const ListJobs = forwardRef(function ListJobs(props: ListJobsProps, ref: ForwardedRef<ListJobsRef>): ReactElement {
    const [listJobs, {data, loading}] = useListJobsLazyQuery({
        fetchPolicy: "no-cache",
        notifyOnNetworkStatusChange: true
    });
    const [page, setPage] = useState<number>(1);

    useImperativeHandle(ref, () => {
        return {
            reload: () => {
                if(page === 1) {
                    listJobs({
                        variables: {
                            page: 1,
                            take: 10
                        }
                    });
                } else {
                    setPage(1);
                }
            }
        };
    }, [page]);

    useEffect(() => {
        listJobs({
            variables: {
                page: page,
                take: 10
            }
        });
    }, [props.jobType, page])

    function formatDateTime(value: Date | string): string {
        if(typeof value === "string") {
            value = new Date(value);
        }

        const formatter = Intl.DateTimeFormat("en-US", {
            dateStyle: "short",
            timeStyle: "short"
        });
        return formatter.format(value);
    }

    let body: JSX.Element | JSX.Element[] = <Row><Cell colSpan={7}>Loading...</Cell></Row>;
    if(!loading) {
        if(!data?.listJobs || data.listJobs.items.length == 0) {
            body = <Row><Cell colSpan={7}>No Jobs Yet</Cell></Row>;
        } else {
            body = data.listJobs.items.map(i => {
                return <Row key={i.id}>
                    <Cell style={{whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis"}}>{i.id}</Cell>
                    <Cell>{i.jobType}</Cell>
                    <Cell>{formatDateTime(i.createdAt)}</Cell>
                    <Cell>{i.started ? formatDateTime(i.started) : ""}</Cell>
                    <Cell>{i.ended ? formatDateTime(i.ended) : ""}</Cell>
                    <Cell>{i.status}</Cell>
                    <Cell>{i.errorMessage ? i.errorMessage : ""}</Cell>
                </Row>;
            });
        }
    }

    return <div>
        <Table style={{marginBottom: "1em"}}>
            <Head>
                <HeaderRow>
                    <HeaderCell>Job Id</HeaderCell>
                    <HeaderCell>Job Type</HeaderCell>
                    <HeaderCell>Created</HeaderCell>
                    <HeaderCell>Started</HeaderCell>
                    <HeaderCell>Ended</HeaderCell>
                    <HeaderCell>Status</HeaderCell>
                    <HeaderCell>Error Message</HeaderCell>
                </HeaderRow>
            </Head>
            <Body>
                {body}
            </Body>
        </Table>
        {data?.listJobs &&
            <Pagination
                    currentPage={data.listJobs.page}
                    totalPages={data.listJobs.maxPage === 0 ? data.listJobs.page : data.listJobs.maxPage}
                    onChange={page => setPage(page)}
            />
        }
    </div>;
});