export function colCode(colNum: number): string {
    const m = colNum % 26;
    const c = String.fromCharCode(65 + m);
    const r = colNum - m;
    return r > 0
        ? `${colCode((r - 1) / 26)}${c}`
        : c
}

export const nanSafeParseFloat = (value: string) => {
    const parsed = parseFloat(value);
    return isNaN(parsed) ? 0 : parsed;
};

export const nanSafeParseFloatNull = (value: string) => {
    const parsed = parseFloat(value);
    return isNaN(parsed) ? null : parsed;
};
