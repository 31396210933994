import { COLORS } from "../../../../constants/Colors";
import { RentSummaryMetricModel, RentSummaryModel, VersionType } from "../../../../__generated__/generated_types";
import { IRentsTableRow } from "../../components/rents-table/logic/rentsTable";


export function parseRentsData(data: RentSummaryModel): IRentsDataParsed {

    const inPlaceRent= parseSingleRentData(data.in_place_rent);
    // Note: This series info is mirrored within each metric.
    const seriesInfo = getRentSeriesInfo(inPlaceRent);

    return {
        seriesInfo,
        inPlaceRent,
        marketRents: parseSingleRentData(data.market_rents),
        newLeaseRent: parseSingleRentData(data.new_lease_rent),
        renewalRent: parseSingleRentData(data.renewal_rent),
    };
}

export function getDatapointLabel(year: number, type: string): string {
    let typeLabel = type;

    if(type === VersionType.Budget){
        typeLabel = 'BDGT';
    }
    else if(type === VersionType.Reforecast){
        typeLabel = 'RFCST';
    }
    else if(type === VersionType.Actuals){
        typeLabel = 'ACT';
    }

    return `${year} ${typeLabel}`;
}

function getRentSeriesInfo(data:TRentsData):IRentsSeriesInfo{
    return {
        labels: [...data.labels],
        types: [...data.types],
        years: [...data.years],
    };
}
function parseSingleRentData(rentData: RentSummaryMetricModel[]): TRentsData {
    const result: TRentsData = {
        data: [],
        labels: [],
        types: [],
        years: [],
    };

    const sorted = [...rentData].sort((a, b) => a.year - b.year);

    sorted.forEach(entry => {
        result.data.push(entry.year_total);
        result.labels.push(getDatapointLabel(entry.year, entry.type));
        result.types.push(entry.type);
        result.years.push(entry.year);
    });

    return result;
}

export type TRentsData = {
    data: number[],
    labels: string[],
    years: number[],
    types: string[],
}

export type IRentsSeriesInfo = Omit<TRentsData, 'data'>;

export interface IRentsDataParsed {
    seriesInfo: IRentsSeriesInfo;
    inPlaceRent: TRentsData;
    marketRents: TRentsData;
    newLeaseRent: TRentsData;
    renewalRent: TRentsData;
}
