import type { dateOrNull, LocalUnitEventModel } from "../types";
import { DateTime, Interval } from "luxon";
import { LeaseTermCustomToken } from "../structs";
import { UnitEventType } from "../../../../../../__generated__/generated_types";

export function setDaysVacant(localUnitEventModel: LocalUnitEventModel): boolean {
    /*
        If there is previous event then we calculate/update the days vacant
        based on the new start date selected by user
    */
    const localPrevEndDate = localUnitEventModel.prevEndDate;
    const localCurrStartDate = localUnitEventModel.currStartDate;
    if(localPrevEndDate && localCurrStartDate){
        /*
            Interval is inclusive of startDate and exclusive of endDate
            So, for calculating the vacant days,
            the start date needs to be shifted to a day later after the lease event ended
        */
        localUnitEventModel.daysVacant = Math.floor(Interval.fromDateTimes(
            localPrevEndDate.plus({ days: 1 }),
            localCurrStartDate
        ).length("days"));
    }
    return true;
}

export function setStartDate(localUnitEventModel: LocalUnitEventModel): boolean {
    /*
        If the daysVacant value in the input field is non-empty valid value
        The startDate is updated accordingly based on previous event end date
    */
    const localPrevEndDate = localUnitEventModel.prevEndDate;
    const localDaysVacant = localUnitEventModel.daysVacant;
    if(localPrevEndDate){
        if(localDaysVacant !== null){
            localUnitEventModel.currStartDate = localPrevEndDate.plus({
                days: localDaysVacant + 1
            });
        } else {
            localUnitEventModel.currStartDate = null;
        }
    }
    return true;
}

export function setLeaseTerm(localUnitEventModel: LocalUnitEventModel): boolean {
    const localCurrStartDate = localUnitEventModel.currStartDate;
    const localCurrEndDate = localUnitEventModel.currEndDate;

    if(localCurrStartDate && localCurrEndDate){
        /*
            Interval is inclusive of startDate and exclusive of endDate
            So, for calculating the total lease duration,
            the end date needs to be shifted to a day later after the lease event actually ended
        */
        const intervalInMonths = Interval.fromDateTimes(
            localCurrStartDate,
            localCurrEndDate.plus({ days: 1 })
        ).length("months");
        const intervalInMonthsInt = parseInt(intervalInMonths.toString(), 10);
        /*
            If the selected end date for the current event is such that
            It is exactly 1-24 months away from the start date
            then we update the lease term dropdown value with such months count
            Otherwise in case of fraction of months or a lease term of more than 24 months
            we show the custom value selected in the lease term dropdown
         */
        if(intervalInMonthsInt === intervalInMonths && intervalInMonthsInt <= 24){
            localUnitEventModel.currLeaseTerm = intervalInMonthsInt.toString();
        } else {
            localUnitEventModel.currLeaseTerm = LeaseTermCustomToken;
        }
    }
    return true;
}

export function setDefaultLeaseTermForNewEvents(localUnitEventModel: LocalUnitEventModel): boolean {
    if(localUnitEventModel.currEventId !== null){
        /*
        ref: https://vizibly.atlassian.net/browse/APP-682
        Do nothing, as this is an existing event,
        and should ideally have an end date with it except for MTM where it is optional
        And hence should have a lease term driven from the start date and end date
         */
        return false;
    }

    if(localUnitEventModel.currLeaseTerm !== null){
        /*
        Do nothing, as the user has already set some lease term or end date for this new event
         */
        return false;
    }

    /*
        The default lease term needs to be set only for the TermLease, Renewal and MTM
        ref: https://vizibly.atlassian.net/browse/APP-682
     */
    if(localUnitEventModel.currEventType === UnitEventType.TermLease ||
        localUnitEventModel.currEventType === UnitEventType.Renewal ||
        localUnitEventModel.currEventType === UnitEventType.MonthToMonth){
        const localCurrStartDate = localUnitEventModel.currStartDate;
        const localCurrEndDate = localUnitEventModel.currEndDate;
        const localMaxLeaseTerm = localUnitEventModel.currMaxLeaseTerm;
        /*
        If the max lease term is not set, in case there is no next event
        Or if the max lease term is set to something more than 11
        then let's default the lease term to 12
        so that user does not have to select the lease term every time
        they are creating a new event
        ref: https://vizibly.atlassian.net/browse/APP-682
         */
        if(localCurrStartDate && !localCurrEndDate &&
            (localMaxLeaseTerm === null || localMaxLeaseTerm >= 12)){
            localUnitEventModel.currLeaseTerm = "12";
            setEndDateByLeaseTerm(localUnitEventModel);
        }
        return true;
    }
    return false;
}

export function setRenovationDuration(localUnitEventModel: LocalUnitEventModel): boolean {
    const localCurrStartDate = localUnitEventModel.currStartDate;
    const localCurrEndDate = localUnitEventModel.currEndDate;

    if(localCurrStartDate && localCurrEndDate){
        /*
            Interval is inclusive of startDate and exclusive of endDate
            So, for calculating the total renovation duration,
            the end date needs to be shifted to a day later after the lease event actually ended
        */
        localUnitEventModel.currRenovationDuration = Math.floor(
            Interval.fromDateTimes(
                localCurrStartDate,
                localCurrEndDate.plus({ days: 1 })
            ).length("days")
        );
    }
    return true;
}

export function setEndDateByLeaseTerm(localUnitEventModel: LocalUnitEventModel): boolean {
    /*
        If the value selected for leaseTerm is not custom i.e the whole number of months
        The current event end date is calculated and updated
        by adding lease term months count to the current event start date
    */
    const localLeaseTerm = localUnitEventModel.currLeaseTerm;
    const localCurrStartDate = localUnitEventModel.currStartDate;
    if(localLeaseTerm && localLeaseTerm !== LeaseTermCustomToken && localCurrStartDate){
        /*
            Interval is inclusive of startDate and exclusive of endDate
            So, for calculating the end date we first go n months past the start date
            and then we substract 1 day from it to make it exact n months of stay
        */
        localUnitEventModel.currEndDate = localCurrStartDate.plus({
            months: parseInt(localLeaseTerm, 10)
        }).minus({ days: 1 });
    }
    return true;
}

export function setEndDateByRenovationDays(localUnitEventModel: LocalUnitEventModel): boolean {
    /*
        If the value selected for renovation duration is non empty and valid number
        The current event end date is calculated and updated
        by adding renovation duration days count to the current event start date
    */
    const localRenovationDuration = localUnitEventModel.currRenovationDuration;
    const localCurrStartDate = localUnitEventModel.currStartDate;
    if(localRenovationDuration !== null && localRenovationDuration > 0 && localCurrStartDate){
        /*
            Interval is inclusive of startDate and exclusive of endDate
            So, for calculating the end date we first go n days past the start date
            and then we substract 1 day from it to make it exact n months of stay
        */
        localUnitEventModel.currEndDate = localCurrStartDate.plus({
            days: localRenovationDuration
        }).minus({ days: 1 });
    }
    return true;
}

export function setMaxVacantDaysAndMaxStartDate(localUnitEventModel: LocalUnitEventModel): boolean {
    const localPrevEndDate = localUnitEventModel.prevEndDate;
    const localCurrEndDate = localUnitEventModel.currEndDate;
    const localNextStartDate = localUnitEventModel.nextStartDate;
    if(localCurrEndDate){
        /*
        If the end date for an event is present, which means the start date of an event can not go beyond it
         */
        localUnitEventModel.currMaxStartDate = DateTime.fromISO(localCurrEndDate.toISO(), { setZone: true });
    } else if(localNextStartDate){
        /*
        If the end date for an event is not set,
        which means the start date of an event can not go beyond the next event start date, if present
         */
        localUnitEventModel.currMaxStartDate = localNextStartDate.minus({ days: 1 });
    }

    if(localPrevEndDate && localUnitEventModel.currMaxStartDate){
        /*
        If the current event has a previous event and
        the start date's max value is also set
        based on either current event's end date or next event's start date
        then we can calculate the maximum days the unit can stay vacant between the prev and next event
         */
        localUnitEventModel.maxDaysVacant = Interval.fromDateTimes(
            localPrevEndDate.plus({ days: 1 }),
            localUnitEventModel.currMaxStartDate
        ).length("days");
    }
    return true;
}

export function setMaxLeaseTerm(localUnitEventModel: LocalUnitEventModel): boolean {
    const localPrevEndDate = localUnitEventModel.prevEndDate;
    const localCurrStartDate = localUnitEventModel.currStartDate;
    const localNextStartDate = localUnitEventModel.nextStartDate;

    let minimumPossibleStartDate: dateOrNull = null;
    let maximumPossibleEndDate: dateOrNull = null;

    /*
    The current event can only start after the previous event ends
    or if the current event has a start date configured
     */
    if(localCurrStartDate){
        minimumPossibleStartDate = localCurrStartDate;
    } else if(localPrevEndDate){
        minimumPossibleStartDate = localPrevEndDate.plus({ days: 1 });
    }

    /*
    The current event has to end before the start date of the next event, if any
     */
    if(localNextStartDate){
        maximumPossibleEndDate = localNextStartDate.minus({ days: 1 });
    }

    if(minimumPossibleStartDate && maximumPossibleEndDate){
        /*
        Now that we know what are the possible minimum possible start date for current event
        And we know the maximum possible end date for the current event
        So, we can calculate the maximum possible lease term for the event
         */
        localUnitEventModel.currMaxLeaseTerm = Math.floor(Interval.fromDateTimes(
            minimumPossibleStartDate,
            maximumPossibleEndDate.plus({ days: 1 })
        ).length("months"));

        /*
        Updating the lease term meta data, for any lease term which is not applicable for the current event
        based on the prev event end date, current event start date, and next event start date
        We first mark all options as good and clickable
        And then we check if the option is really within the maxLeaseTerm range, it is okay else mark it disabled
        Also in case of marking it disabled the reason of disabled is also added to it
         */
        Object.values(localUnitEventModel.currAllLeaseTermDdOptions).forEach(v => {
            v.isDisabled = false;
            v.disabledReason = null;
            if(v.value !== LeaseTermCustomToken && localUnitEventModel.currMaxLeaseTerm !== null){
                const currentLeaseTermInt = parseInt(v.value, 10);
                if(currentLeaseTermInt > localUnitEventModel.currMaxLeaseTerm){
                    v.isDisabled = true;
                    v.disabledReason = "Lease term must end before next event starts";
                }
            }
        });
    }
    return true;
}

export function setEndDateDefault(localUnitEventModel: LocalUnitEventModel): boolean {
    if(localUnitEventModel.currEndDate === null){
        const localCurrStartDate = localUnitEventModel.currStartDate;
        let localCurrMaxLeaseTerm = localUnitEventModel.currMaxLeaseTerm;
        if(localCurrStartDate){
            if(localCurrMaxLeaseTerm !== null){
                if(localUnitEventModel.currEventType !== UnitEventType.Renovation) {
                    /*
                        For non renovation events
                        Assuming the 12 months of lease term as default, let's try assign the end date
                        which is 12 months away from the start date,
                        if permissible within the range of lease term options
                     */
                    localCurrMaxLeaseTerm = localCurrMaxLeaseTerm >= 12 ? 12 : localCurrMaxLeaseTerm;
                } else {
                    /*
                        For renovation events
                        Assuming the 1 month of renovation as default, let's try assign the end date
                        which is 1 month away from the start date,
                        if permissible within the range of lease term options
                     */
                    localCurrMaxLeaseTerm = localCurrMaxLeaseTerm >= 1 ? 1 : localCurrMaxLeaseTerm;
                }

                /*
                    Interval is inclusive of startDate and exclusive of endDate
                    So, for calculating the end date we first go n months past the start date
                    and then we substract 1 day from it to make it exact n months of stay
                */
                localUnitEventModel.currEndDate = localCurrStartDate.plus({
                    months: localCurrMaxLeaseTerm
                }).minus({ days: 1 });
            } else {
                /*
                    If there was no maximum lease term set,
                    then let's default the end date picker to go to the start date of the event
                    which is the very first valid end date, with a lease term of 1 days
                 */
                localUnitEventModel.currEndDate = DateTime.fromISO(localCurrStartDate.toISO(), { setZone: true });
            }
        }
    }
    return true;
}

export function validateForm(localUnitEventModel: LocalUnitEventModel): boolean {

    let isFormValid = true;

    /*
    Keeping some values locally for re-referencing and reusing
     */
    const localCurrEventType = localUnitEventModel.currEventType;
    const localPrevEndDate = localUnitEventModel.prevEndDate;
    const localDaysVacant = localUnitEventModel.daysVacant;
    const maxPossibleDaysVacantVal = localUnitEventModel.maxDaysVacant;
    const localCurrStartDate = localUnitEventModel.currStartDate;
    const localCurrEndDate = localUnitEventModel.currEndDate;
    const localLeaseTerm = localUnitEventModel.currLeaseTerm;
    const localRenovationDuration = localUnitEventModel.currRenovationDuration;
    // const maxPossibleLeaseTerm = localUnitEventModel.maxLeaseTerm;
    const localNextStartDate = localUnitEventModel.nextStartDate;

    /*
    The local days vacant can not be negative
        - would mean two different tenants/leases/events are occurring simultaneously at some moment in time
    The local days vacant can have some maximum value if there is some next event is lined up
     */
    if(localDaysVacant !== null && localDaysVacant < 0){
        localUnitEventModel.daysVacantErrMsg = "Days Vacant cannot be negative";
        isFormValid = false;
    } else if(localDaysVacant !== null && maxPossibleDaysVacantVal !== null && localDaysVacant > maxPossibleDaysVacantVal){
        localUnitEventModel.daysVacantErrMsg = `Days Vacant cannot be more than ${maxPossibleDaysVacantVal} days`;
        isFormValid = false;
    } else {
        localUnitEventModel.daysVacantErrMsg = null;
    }

    /*
    The current event can start only after the previous event ends
    Also the start date for current event can not be beyond the current event end date
    And off course the current event can not start after the next event kicks in
    In the end the start date must be set for any event to be a valid event
     */
    if(localCurrStartDate && localPrevEndDate && !(localCurrStartDate > localPrevEndDate)){
        localUnitEventModel.currStartDateErrMsg = "Start Date must come after prior event's End Date";
        isFormValid = false;
    } else if(localCurrStartDate && localCurrEndDate && localCurrStartDate > localCurrEndDate){
        localUnitEventModel.currStartDateErrMsg = "Start Date must come before End Date";
        isFormValid = false;
    } else if(localCurrStartDate && localNextStartDate && localCurrStartDate >= localNextStartDate){
        localUnitEventModel.currStartDateErrMsg = "Start Date must come before next event's Start Date";
        isFormValid = false;
    } else if(!localCurrStartDate){
        localUnitEventModel.currStartDateErrMsg = "Start Date is required";
        isFormValid = false;
    } else {
        localUnitEventModel.currStartDateErrMsg = null;
    }

    /*
    The lease term can not be beyond such value so that it starts interfering the next event's start date
    Also the lease term must be manually/automatically(by setting the end date) set/calculated
     */
    /*if(localLeaseTerm !== null && localLeaseTerm !== LeaseTermCustomToken &&
        maxPossibleLeaseTerm !== null && parseInt(localLeaseTerm, 10) > maxPossibleLeaseTerm) {
        localUnitEventModel.leaseTermErrMsg = `Lease Term cannot be more than ${maxPossibleLeaseTerm} months or please set it to custom`;
        isFormValid = false;
    } else*/
    if(!localLeaseTerm &&
        localCurrEventType !== UnitEventType.Renovation
    ){
        localUnitEventModel.currLeaseTermErrMsg = `Lease Term is required`;
        isFormValid = false;
    } else {
        localUnitEventModel.currLeaseTermErrMsg = null;
    }

    /*
    For renovation type events, the duration of renovation is a must.
     */
    if((localRenovationDuration === null || localRenovationDuration < 1) &&
        localCurrEventType === UnitEventType.Renovation){
        localUnitEventModel.currRenovationDurationErrMsg = `Duration is required`;
        isFormValid = false;
    } else {
        localUnitEventModel.currRenovationDurationErrMsg = null;
    }

    /*
    The current event's end date can not be prior to the same/current event's start date
    Also the end date can not interfere with the next event start date as the current event must end before the next event kicks in
    If the event is not MTM, then the user expected to specify the event's end date
     */
    if(localCurrStartDate && localCurrEndDate && localCurrStartDate > localCurrEndDate){
        localUnitEventModel.currEndDateErrMsg = "End Date must come after Start Date";
        isFormValid = false;
    } else if(localCurrEndDate && localNextStartDate && !(localNextStartDate > localCurrEndDate)){
        localUnitEventModel.currEndDateErrMsg = "End Date must come before next event's Start Date";
        isFormValid = false;
    } else if(!localCurrEndDate){
        localUnitEventModel.currEndDateErrMsg = `End Date is required`;
        isFormValid = false;
    } else {
        localUnitEventModel.currEndDateErrMsg = null;
    }

    /*
    For the events being renewed, there should be a valid increase or decrease percentage of rent
     */
    if(localCurrEventType === UnitEventType.Renewal &&
        localUnitEventModel.currRentIncreaseDecreasePercentage === null){
        localUnitEventModel.currRentIncreaseDecreasePercentageErrMsg = `Rent Increase/Decrease % is required`;
        isFormValid = false;
    } else {
        localUnitEventModel.currRentIncreaseDecreasePercentageErrMsg = null;
    }

    /*
    For the renovation events, there should be a valid premium and cost associated with it
     */
    if(localCurrEventType === UnitEventType.Renovation &&
        localUnitEventModel.currRenovationPremium === null){
        localUnitEventModel.currRenovationPremiumErrMsg = `Renovation Premium is required`;
        isFormValid = false;
    } else {
        localUnitEventModel.currRenovationPremiumErrMsg = null;
    }
    if(localCurrEventType === UnitEventType.Renovation &&
        localUnitEventModel.currRenovationCost === null){
        localUnitEventModel.currRenovationCostErrMsg = `Renovation Cost is required`;
        isFormValid = false;
    } else {
        localUnitEventModel.currRenovationCostErrMsg = null;
    }

    return isFormValid;
}
