import { getVarianceForSet } from "../../../../components/account-summary/logic/AccountSummaryLogic";
import { IMenuTitleOption } from "../../../../components/menu-title/logic/menuTitle";
import { COLORS } from "../../../../constants/Colors";
import { MONTHS } from "../../../../constants/Months";
import { formatterDollarUSNoDecimal, formatterPercentAutoSign } from "../../../../utils/formatters";
import { flatAverageValues, sumYearValues } from "../../../../utils/yearValues";
import {
    OperationalMetricPerformanceItemModel,
    OperationalMetricPerformanceModel,
    VersionType
} from "../../../../__generated__/generated_types";
import { OpDriverMetricType } from "../../../analyst/tab-op-drivers/enums";
import { CellValue } from "handsontable/common";
import {
    TOperationalChartDataBundle,
    TOperationalDataMetricData,
    TOperationalDataSummary,
    TOperationalParsedData,
    TOperationalParsedDataItem,
    TWidgetTableConfig
} from "../useOperationalData";

export const operationalMenuOptions: IMenuTitleOption[] = [
    { label: 'Occupancy Rate', value: OpDriverMetricType.OCCUPANCY_RATES },
    { label: 'Move Out Rate', value: OpDriverMetricType.MOVE_OUT_RATES },
    { label: 'Renewal Rate', value: OpDriverMetricType.RENEWAL_RATIO },
    { label: 'Expirations', value: OpDriverMetricType.EXPIRATIONS },
];

export type TVarianceFormatReturn = {
    valOneStringValue: string | number,
    valTwoStringValue: string | number,
    variancePercent: string | number,
    varianceCount: number | string | null | undefined,
}

export function parseRawOperationalData(
    data: OperationalMetricPerformanceModel | undefined,
    startMonthIndex: number | undefined,
    focusYear: number,
): TOperationalParsedData {
    if (data == undefined || startMonthIndex == undefined) {
        return {};
    }

    const res: TOperationalParsedData = {
        [OpDriverMetricType.OCCUPANCY_RATES]: {
            menuOptions: [],
            seriesData: [],
        },
        [OpDriverMetricType.MOVE_OUT_RATES]: {
            menuOptions: [],
            seriesData: [],
        },
        [OpDriverMetricType.EXPIRATIONS]: {
            menuOptions: [],
            seriesData: [],
        },
        [OpDriverMetricType.RENEWAL_RATIO]: {
            menuOptions: [],
            seriesData: [],
        },
    };

    for (const metric in data) {
        let dataSet: OperationalMetricPerformanceItemModel[] = [];
        let resMetric: TOperationalParsedDataItem = {
            menuOptions: [],
            seriesData: [],
        };

        switch (metric) {
            case 'move_out_rates': {
                resMetric = res[OpDriverMetricType.MOVE_OUT_RATES] ?? {
                    menuOptions: [],
                    seriesData: [],
                };
                dataSet = data.move_out_rates ?? [];
                break;
            }
            case 'expirations': {
                resMetric = res[OpDriverMetricType.EXPIRATIONS] ?? {
                    menuOptions: [],
                    seriesData: [],
                };
                dataSet = data.expirations ?? [];
                break;
            }
            case 'occupancy_rates': {
                resMetric = res[OpDriverMetricType.OCCUPANCY_RATES] ?? {
                    menuOptions: [],
                    seriesData: [],
                };
                dataSet = data.occupancy_rates ?? [];
                break;
            }
            case 'renewal_ratio': {
                resMetric = res[OpDriverMetricType.RENEWAL_RATIO] ?? {
                    menuOptions: [],
                    seriesData: [],
                };
                dataSet = data.renewal_ratio ?? [];
                break;
            }
        }

        if (dataSet == undefined || resMetric == undefined) {
            return {};
        }

        let years: number[] = [];
        dataSet.map(x => x.year).forEach(year => {
            if (!years.includes(year)) {
                years.push(year);
            }
        });

        years = years.sort();

        years.forEach((year, index) => {
            const dataForYear = dataSet.filter(x => x.year == year);
            const budgetData = dataForYear.find(x => x.versionType == VersionType.Budget);
            const reforecastData = dataForYear.find(x => x.versionType == VersionType.Reforecast);
            const actualsData = dataForYear.find(x => x.versionType == VersionType.Actuals);

            if (year == focusYear) {
                resMetric.menuOptions.unshift({
                    label: `${year % 100} RFCST`,
                    value: index,
                });
            } else if (year < focusYear) {
                resMetric.menuOptions.unshift({
                    label: `${year % 100} ACT`,
                    value: index,
                });
            }

            if (budgetData) {
                resMetric.seriesData.push({
                    ...budgetData,
                    name: `${year % 100} BUDGET`,
                    color: COLORS.PRIMARY_400,
                    type: 'line',
                    averageValue: flatAverageValues(budgetData.values),
                    averageCount: flatAverageValues(budgetData.counts),
                    totalValue: sumYearValues(budgetData.values),
                    totalCount: sumYearValues(budgetData.counts),
                });
            }

            if (reforecastData) {
                const reforecastValues = new Array(12).fill(0);
                const reforecastCounts = new Array(12).fill(0);

                for(let i = startMonthIndex; i < 12; i++) {
                    reforecastValues[i] = reforecastData.values[i];
                    reforecastCounts[i] = reforecastData.counts[i];
                }

                if(actualsData) {
                    const actualValuesWithNulls = new Array(12).fill(null);
                    for(let i = 0; i < startMonthIndex; i++) {
                        reforecastValues[i] = actualsData.values[i];
                        reforecastCounts[i] = actualsData.counts[i];
                        actualValuesWithNulls[i] = actualsData.values[i];
                    }

                    resMetric.seriesData.push({
                        ...actualsData,
                        name: `${year % 100} REFORECAST - ACTUAL`,
                        color: COLORS.LAVENDER_400,
                        type: 'bar',
                        values: actualValuesWithNulls,
                        counts: reforecastCounts,
                        averageValue: flatAverageValues(reforecastValues),
                        averageCount: flatAverageValues(reforecastCounts),
                        totalValue: sumYearValues(reforecastValues),
                        totalCount: sumYearValues(reforecastCounts),
                    });
                }



                resMetric.seriesData.push({
                    ...reforecastData,
                    name: `${year % 100} REFORECAST - FORECAST`,
                    color: COLORS.LAVENDER_200,
                    type: 'bar',
                    values: new Array(startMonthIndex).fill(null).concat(reforecastData.values.slice(startMonthIndex)),
                    counts: reforecastCounts,
                    averageValue: flatAverageValues(reforecastValues),
                    averageCount: flatAverageValues(reforecastCounts),
                    totalValue: sumYearValues(reforecastValues),
                    totalCount: sumYearValues(reforecastCounts),
                });
            } else if (actualsData) {
                resMetric.seriesData.push({
                    ...actualsData,
                    name: `${year % 100} ACTUAL`,
                    color: COLORS.LAVENDER_400,
                    type: 'bar',
                    averageValue: flatAverageValues(actualsData.values),
                    averageCount: flatAverageValues(actualsData.counts),
                    totalValue: sumYearValues(actualsData.values),
                    totalCount: sumYearValues(actualsData.counts),
                });
            }
        });
    }

    return res;
}

export function getApexSeriesFromParsedData(
    data: TOperationalDataMetricData[],
    comparisonIndex: number,
    focusYear: number
): TOperationalChartDataBundle | undefined {
    const res = [];
    const budgetData: TOperationalDataMetricData | undefined = data.find(d => d.versionType === VersionType.Budget);
    const comparingData = data[comparisonIndex];
    const varianceCounts: (number | null)[] = [];
    let actualsData: TOperationalDataMetricData | undefined;
    let reforecastData: TOperationalDataMetricData | undefined;
    let comparisonCounts: number[] = [];

    if (comparingData == undefined) {
        return;
    }

    if (comparingData.year >= focusYear) {
        reforecastData = data.find(d => d.versionType === VersionType.Reforecast);
        actualsData = undefined;
        if(reforecastData !== undefined) {
            actualsData = data.find(d => d.versionType === VersionType.Actuals && d.year === reforecastData?.year);
        }

        if (actualsData) {
            comparisonCounts = actualsData.counts;
        } else if (reforecastData) {
            comparisonCounts = reforecastData.counts;
        }
    } else {
        actualsData = data[comparisonIndex];

        if (actualsData) {
            comparisonCounts = actualsData.counts;
        }
    }

    if (actualsData) {
        res.push(actualsData);
    }

    if (reforecastData) {
        res.push(reforecastData);
    }

    if (budgetData) {
        res.push(budgetData);
    }

    for (let i = 0; i < 12; i++) {
        const comparisonCount = comparisonCounts[i];
        const bdgtCount = budgetData?.counts[i];

        if (comparisonCount != undefined && bdgtCount != undefined ) {
            if (actualsData?.counts)
            varianceCounts.push(Math.abs(comparisonCount - bdgtCount));
        } else {
            varianceCounts.push(null);
        }
    }

    const chartSeriesData = res.map(item => ({
        ...item,
        name: item.name,
        data: item.values,
        type: item.type,
        color: item.color,
    }));

    return {
        varianceCounts,
        chartSeriesData,
    };

}

export function getHotTableDataFromParsedSeriesData(
    chartBundle: TOperationalChartDataBundle | undefined,
    summaryData: TOperationalDataSummary | undefined,
    dataType: 'dollar' | 'percent' | 'count',
): TWidgetTableConfig | undefined {
    if (!chartBundle || !summaryData) {
        return;
    }

    const data: CellValue[][] = [];
    const leftColName = summaryData.comparisonItemOne.label;
    const rightColName = summaryData.comparisonItemTwo.label;
    const varPctColName = summaryData.variancePercent.label;
    const varAmtColName = summaryData.varianceAmount.label;
    const colHeaders = ["Month", leftColName, rightColName, varPctColName, varAmtColName];

    const actualsData = chartBundle.chartSeriesData.find(x => x.versionType == VersionType.Actuals);
    const reforecastData = chartBundle.chartSeriesData.find(x => x.versionType == VersionType.Reforecast);
    const budgetData = chartBundle.chartSeriesData.find(x => x.versionType == VersionType.Budget);

    if (!(reforecastData || actualsData) || !budgetData) {
        return;
    }

    const summaryRow: (string | number)[] = [dataType == 'percent' ? 'Avg' : 'Total'];

    MONTHS.forEach((month, i) => {
        let leftColValue, leftColCount;

        const monthData: (string | number)[] = [month];
        const budgetValue = budgetData.values[i] ?? 0;
        const budgetCount = budgetData.counts[i] ?? 0;
        const actualsValue = actualsData?.values[i];
        const actualsCount = actualsData?.counts[i];
        const forecastValue = reforecastData?.values[i];
        const forecastCount = reforecastData?.counts[i];

        if (actualsValue != undefined) {
            leftColValue = actualsValue ?? 0;
            leftColCount = actualsCount ?? 0;
        } else {
            leftColValue = forecastValue ?? 0;
            leftColCount = forecastCount ?? 0;
        }

        // data for left column - either the actual if it exists, or reforecast otherwise
        // and data for the budget column
        monthData.push(
            formatValueForDataType(leftColValue, dataType),
            formatValueForDataType(budgetValue, dataType)
        );

        const {
            variancePercent,
            varianceCount,
        } = getFormattedVariances(leftColValue ?? 0, budgetValue ?? 0, budgetCount - leftColCount, dataType);


        // data for the change columns - percent and count/dollar representation
        monthData.push(
            variancePercent,
            varianceCount ?? '-',
        );

        // add row data for this month into the full table data array
        data.push(monthData);
    });

    summaryRow.push(
        summaryData.comparisonItemOne.value,
        summaryData.comparisonItemTwo.value,
        summaryData.variancePercent.value,
        summaryData.varianceAmount.value,
    );

    data.push(summaryRow);

    return {
        colHeaders,
        data,
    };
}

export function formatValueForDataType(value: string | number | null | undefined, dataType: 'dollar' | 'percent' | 'count'): string {
    if (value == undefined || value == null) {
        return '-';
    }

    switch (dataType) {
        case 'dollar': {
            if (typeof value == 'string') {
                value = parseInt(value);
            }

            return formatterDollarUSNoDecimal.format(value);
        }
        case 'percent': {
            if (typeof value == 'string') {
                value = parseInt(value);
            }

            return formatterPercentAutoSign.format(value / 100); // backend sends percent data that is already * 100
        }
        case 'count': {
            if (typeof value == 'string') {
                value = Math.round(parseInt(value));
            } else {
                value = Math.round(value);
            }

            return String(value);
        }
    }
}

export function buildSummaryData(
    parsedDataForMetricType: TOperationalParsedDataItem,
    selectedIndex: number,
    dataType: 'dollar' | 'percent' | 'count'
): TOperationalDataSummary | undefined {
    const leftData = parsedDataForMetricType.seriesData[selectedIndex];
    const budgetData = parsedDataForMetricType.seriesData.find(x => x.versionType == VersionType.Budget);

    if (!budgetData || !leftData) {
        return;
    }

    const leftTotalValue = leftData?.totalValue ?? 0;
    const leftTotalCount = leftData?.totalCount ?? 0;
    const leftAverageValue = leftData?.averageValue ?? 0;
    const budgetTotalValue = budgetData?.totalValue ?? 0;
    const budgetTotalCount = budgetData?.totalCount ?? 0;
    const budgetAverageValue = budgetData?.averageValue ?? 0;

    let leftValueDisplay, budgetValueDisplay, variancePercentValue, varianceAmountLabel, varianceAmountValue;

    if (dataType == 'percent') {
        leftValueDisplay = formatValueForDataType(leftAverageValue, dataType);
        budgetValueDisplay = formatValueForDataType(budgetAverageValue, dataType);
        variancePercentValue = formatValueForDataType(budgetAverageValue - leftAverageValue, dataType);
        varianceAmountValue = formatValueForDataType((budgetTotalCount - leftTotalCount) / 12, 'count');
    } else {
        const variance = getVarianceForSet(budgetTotalValue, leftTotalValue);

        leftValueDisplay = formatValueForDataType(leftTotalValue, dataType);
        budgetValueDisplay = formatValueForDataType(budgetTotalValue, dataType);
        variancePercentValue = formatValueForDataType(variance.varPercentage ?? 0, 'percent');
        varianceAmountValue = variance.varValue;
    }

    if (dataType == 'dollar') {
        varianceAmountLabel = '$ Change';
    } else {
        varianceAmountLabel = '# Change';
    }

    return {
        comparisonItemOne: {
            label: parsedDataForMetricType.menuOptions.find(x => x.value == selectedIndex)?.label ?? '-',
            value: leftValueDisplay ?? 0,
        },
        comparisonItemTwo: {
            label: `${budgetData.year % 100} BDGT`,
            value: budgetValueDisplay ?? '-',
        },
        variancePercent: {
            label: "% Change",
            value: variancePercentValue ?? '-',
        },
        varianceAmount: {
            label: varianceAmountLabel,
            value: varianceAmountValue ?? '-',
        },
    };
}

export function getFormattedVariances(
    valOne: number,
    valTwo: number,
    varianceCount: number | string | null | undefined,
    dataType: 'dollar' | 'percent' | 'count'
): TVarianceFormatReturn {
    let valOneStringValue, valTwoStringValue;
    let variancePercent: number | string | undefined = undefined;
    const variance = getVarianceForSet(valTwo, valOne);

    switch (dataType) {
        case 'dollar': {
            valOneStringValue = formatValueForDataType(valOne, dataType);
            valTwoStringValue = formatValueForDataType(valTwo, dataType);
            variancePercent = formatValueForDataType(variance.varPercentage, 'percent');
            varianceCount = variance.varValue;
            break;
        }
        case 'percent': {
            valOneStringValue = formatValueForDataType(valOne, dataType);
            valTwoStringValue = formatValueForDataType(valTwo, dataType);
            variancePercent = formatValueForDataType(valTwo - valOne, dataType);
            break;
        }
        case 'count': {
            valOneStringValue = valOne;
            valTwoStringValue = valTwo;
            variancePercent = formatValueForDataType(variance.varPercentage, 'percent');
            varianceCount = variance.varValue;
            break;
        }
    }

    return {
        valOneStringValue,
        valTwoStringValue,
        variancePercent,
        varianceCount,
    };
}