import {lazy, Suspense, ReactElement} from "react";
import {Property} from "../../../contexts/properties/PropertiesContext";

const PropertyChangeReadonlyE2eTestUtil = lazy(() => import('./PropertyChangeReadonlyE2eTestUtil'));

type PropertyChangeReadonlyE2eTestWrapperProps = {
    properties: Property[] | undefined;
    chooseProperty: (propertyId: string) => void;
}

export default function PropertyChangeE2eTestWrapper({
                                                         properties,
                                                         chooseProperty
                                                     }: PropertyChangeReadonlyE2eTestWrapperProps): ReactElement {
    const urlParams = new URLSearchParams(window.location.search);
    const isE2EMode = urlParams.get('e2e-mode');
    if (properties && properties.length > 0 && chooseProperty && typeof chooseProperty === 'function') {
        if (isE2EMode === "true" || process.env['REACT_APP_E2E_MODE'] === "true") {
            return (
                <Suspense fallback={<></>}>
                    <PropertyChangeReadonlyE2eTestUtil
                        properties={properties}
                        chooseProperty={chooseProperty}
                    />
                </Suspense>);
        }
    }
    return <></>;
}
