import { ReactElement, useState, useContext, useEffect } from "react";
import { getResetPasswordTypeFromAWSErrorCode, PasswordFlow, PasswordFormPartProps, ResetPasswordError, ResetPasswordErrorType } from "./types";
import { useValidPassword } from '../../hooks/UseAuthHooks';

import { AuthContext } from '../../contexts/AuthContext';
import { Form, Button } from 'react-bootstrap';
import { BsEye, BsEyeSlash } from "react-icons/bs";
import OTPInput from "./OTPInput";
import { Link } from "react-router-dom";
import logo from "../../assets/icons/brand/logo.svg";

export default function PasswordFormPart(params: PasswordFormPartProps): ReactElement {
    const { password, setPassword } = useValidPassword('');
    const [code, setCode] = useState<string>();
    const [error, setError] = useState<ResetPasswordError>();
    const [showPassword, setShowPassword] = useState(false);
    const [created, setCreated] = useState(false);
    const [hasNumber, setHasNumber] = useState<boolean>();
    const [hasSpecialCharacter, setHasSpecialCharacter] = useState<boolean>();
    const [hasMinEightCharacters, setHasMinEightCharacters] = useState<boolean>();

    const validateNumber = /[0-9]+/;
    const validateSpecialCharacter = /[!@#$%^&*(),.?":{}|<>]/;

    const authContext = useContext(AuthContext);

    let errorBorder;
    let errorText;
    if (password.length === 0) {
        errorBorder = "default-border";
        errorText = "default-text";
    }
    else if ((hasSpecialCharacter !== undefined && !hasSpecialCharacter) || (hasNumber !== undefined && !hasNumber)) {
        errorBorder = "error-border";
        errorText = "error-text";
    } else if (password.length > 0) {
        errorBorder = "focused-border";
        errorText = "focused-text";
    }

    useEffect(
        () => {
            validatePassword(password);
        }
        , [password]
    );

    const validatePassword = (value: string) => {
        if (password.length === 0) {
            reset();
            return;
        }
        const passwordHasNumber = validateNumber.test(value);
        const passwordHasSpecialCharacter = validateSpecialCharacter.test(value);

        if (passwordHasNumber !== undefined && passwordHasNumber) {
            setHasNumber(true);
        } else {
            setHasNumber(false);
        }
        if (passwordHasSpecialCharacter !== undefined && passwordHasSpecialCharacter) {
            setHasSpecialCharacter(true);
        } else {
            setHasSpecialCharacter(false);
        }
        if (value.length >= 8) {
            setHasMinEightCharacters(true);
        } else {
            setHasMinEightCharacters(false);
        }
    };

    const resetPassword = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        event.preventDefault();
        validatePassword(password);

        if (!hasSpecialCharacter || !hasNumber || !code || !params.username) {
            return;
        }

        try {
            await authContext.setPasswordAndLogin(params.username, code, password);
            params.needSignIn?.();
        } catch (err: unknown) {
            const typedErr = err as {code: string, message: string};
            setError({ type: getResetPasswordTypeFromAWSErrorCode(typedErr.code), message: typedErr.message });
        }
    };

    const reset = () => {
        setPassword("");
        errorBorder = "default-border";
        errorText = "default-text";
        setHasNumber(undefined);
        setHasSpecialCharacter(undefined);
        setHasMinEightCharacters(undefined);
        setError(undefined);
    };

    const createdAndSignInClicked = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        event.preventDefault();
        validatePassword(password);

        try {
            await authContext.signInWithPasswordReset?.(
                params.username,
                "fake",
                password
            );
            setCreated(true);
            params.needSignIn?.();
        } catch (err) {
            setError({ type: ResetPasswordErrorType.PASSWORD_PROBLEM, message: (err as unknown as { message: string }).message });
        }
        reset();
    };


    return (
        <>
            <div className="left-side-container reset-pw d-flex flex-column">
                <Form>
                    {params.flow === PasswordFlow.RESET &&
                        <OTPInput
                            codeError={error}
                            username={params.username}
                            setCode={(code: string) => setCode(code)}
                        />
                    }

                    {params.flow === PasswordFlow.CREATE && (
                        <>
                            <Form.Label className="mb-3">
                                <span className="welcome-back-text"> Welcome to <br /> </span>
                                <img className="mt-2" src={logo} alt="vizibly-logo"/>
                            </Form.Label>
                            <div className="login-text mb-3">{`Hi ${params.fullName}, your account is almost ready.`}<br /> Please create a password. </div>
                        </>
                    )}

                    <div className="login-wrapper reset-password-wrapper">
                        <div className={"login-box " + `${errorBorder}`}>
                            <label className={"login-password " + `${errorText}` + (params.flow === PasswordFlow.RESET ? " login-new-password-width" : "")}> {params.flow === PasswordFlow.RESET ? "New Password" : "Password"}</label>
                            <span className="login-input-wrapper">
                                <input
                                    className={"login-input " + (((hasSpecialCharacter !== undefined && !hasSpecialCharacter) || (hasNumber !== undefined && !hasNumber)) ?
                                        "error-text" : ""
                                    )}
                                    type={showPassword ? "text" : "password"}
                                    required
                                    value={password || ""}
                                    onChange={(value) => setPassword(value.target.value)}
                                />
                                {password.length > 0 &&
                                    <span className="eye-icon-wrapper">
                                        {!showPassword ?
                                            <BsEye onClick={() => setShowPassword(true)} />
                                            :
                                            <BsEyeSlash onClick={() => setShowPassword(false)} />
                                        }
                                    </span>
                                }
                            </span>
                        </div>
                    </div>
                    {hasSpecialCharacter !== undefined && !hasSpecialCharacter &&
                        <div className="error-text password-error"> Your password must include a special character. </div>
                    }
                    {hasMinEightCharacters !== undefined && !hasMinEightCharacters &&
                        <div className="error-text password-error"> Your password must include a minimum of 8 characters. </div>
                    }
                    {hasNumber !== undefined && !hasNumber &&
                        <div className="error-text password-error"> Your password must include a number. </div>
                    }

                    <div className="d-flex justify-content-start">
                        <div className="back-to-login">By signing in, you agree to Vizibly's <Link className="back-to-login" to={"/terms"}>Terms of Service</Link>.</div>
                    </div>

                    <div className="d-flex justify-content-end mt-4">
                        {params.flow === PasswordFlow.CREATE ? (
                            <Button
                                className="bt-block"
                                variant="primary"
                                onClick={createdAndSignInClicked}
                                disabled={!password.length || !hasSpecialCharacter || !hasMinEightCharacters || !hasNumber}
                            >
                                Create & Sign In
                            </Button>
                        ) : (

                            <Button
                                className="reset-password-button"
                                variant="primary"
                                disabled={!password.length || !code || !code.length || !hasSpecialCharacter || !hasMinEightCharacters || !hasNumber}
                                onClick={resetPassword}
                            >
                                Reset & Log In
                            </Button>

                        )}
                    </div>
                </Form>
                {params.flow === PasswordFlow.RESET && <div className="back-to-login mt-auto mb-0" onClick={params.toLogin}> &lt; Back to log in </div>}
            </div>
        </>
    );
}