import { ReactElement, useEffect, useState } from "react";
import { Dropdown, Field, Item, Menu, Select } from "@zendeskgarden/react-dropdowns";

type LookbackPeriodSelectorProps = {
    initialValue: number;
    onSelect: (selected: number) => void,
}

export function LookbackPeriodSelector(props: LookbackPeriodSelectorProps): ReactElement {
    const [selectedItem, setSelectedItem] = useState(props.initialValue);

    function handleSelectedItem(v: number) {
        props.onSelect(v);
    }

    function formatSelectedItem(v: number): string {
        let formatted = "same month";

        if (v == -1) {
            formatted = "Mixed";
        } else if(v == 1){
            formatted = '1 month ago';
        } else if (v > 1) {
            formatted = `${v} months ago`;
        }

        return formatted;
    }

    useEffect(() => {
      setSelectedItem(props.initialValue);
    }, [props.initialValue]);

    return (
        <Dropdown
            selectedItem={selectedItem}
            onSelect={(v) => {setSelectedItem(v); handleSelectedItem(v)}}
        >
            <Field>
                <Select>
                    {formatSelectedItem(selectedItem)}
                </Select>
            </Field>
            <Menu>
                {
                [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map(v =>
                    <Item key={v} value={v}>{`${formatSelectedItem(v)}`}</Item>
                )
                }
            </Menu>
        </Dropdown>

    );
}