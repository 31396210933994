import { ReactElement, useState } from "react";
import { Button } from '@zendeskgarden/react-buttons';
import { ReactComponent as Download } from '@zendeskgarden/svg-icons/src/16/download-stroke.svg';
import { ThemeProvider } from "@zendeskgarden/react-theming";
import { ViziblyTheme } from "../../pages/analyst/ViziblyZDGTheme";
import useBudgetExportData, { BudgetExportFormat } from "./BudgetExportData";
import { BudgetingType } from "../../BudgetingType";
import { Dropdown, Item, Menu, Trigger } from "@zendeskgarden/react-dropdowns";
import { ReactComponent as ChevronIcon } from '@zendeskgarden/svg-icons/src/16/chevron-down-stroke.svg';
import { ReactComponent as InfoIcon } from '@zendeskgarden/svg-icons/src/16/info-fill.svg';
import { Paragraph, Title, Tooltip } from "@zendeskgarden/react-tooltips";

export default function BudgetExportButton(params: { tab: BudgetingType }): ReactElement {
    const exportData = useBudgetExportData(params.tab);
    const [rotated, setRotated] = useState<boolean | undefined>();

    return (
        <>
            <ThemeProvider theme={ViziblyTheme}>
                <Dropdown
                    onSelect={item => exportData.exportBudget(item)}
                    onStateChange={options => setRotated(options?.isOpen)}
                >
                    <Trigger>
                        <Button>
                            <Button.StartIcon>
                                <Download />
                            </Button.StartIcon>
                            Export
                            <Button.EndIcon isRotated={rotated}>
                                <ChevronIcon />
                            </Button.EndIcon>
                        </Button>
                    </Trigger>
                    <Menu style={{overflow: "visible"}}>
                        <Item value={BudgetExportFormat.CSV_REPORT}>12 Month Income Statement (csv)</Item>
                        <Item value={BudgetExportFormat.EXCEL_FULL_NO_ZEROS}>12 Month Income Statement (xlsx)</Item>
                        <Item value={BudgetExportFormat.EXCEL_FULL_NO_ZEROS_WITH_THIS_YEAR_BUDGET}>12 Month Income Statement - All Comparisons (xlsx)</Item>
                        <Item value={BudgetExportFormat.EXCEL_FULL_NO_ZEROS_WITH_METRICS}>12 Month Income Statement with Metrics (xlsx)</Item>
                        <Item value={BudgetExportFormat.EXCEL_WITHOUT_RAW_ACCOUNTS_NO_ZEROS}>Annualized - Categories Only (xlsx)</Item>
                        <Item value={BudgetExportFormat.CSV_APPFOLIO}>Appfolio export</Item>
                        <Item value={BudgetExportFormat.CSV_YARDI}>YARDI export</Item>
                        <Item value={BudgetExportFormat.CSV_YARDI_1_cent}>
                            YARDI $0.01
                            <Tooltip
                                type="light"
                                size="large"
                                placement="top-end"
                                content={
                                <>
                                    <Title>0.01 for zero accounts</Title>
                                    <Paragraph>
                                        Zeros are replaced with $0.01 for Accounts with all zeros
                                    </Paragraph>
                                </>
                                }
                            >
                                <InfoIcon/>
                            </Tooltip>
                            {" export"}
                        </Item>
                        <Item value={BudgetExportFormat.EXCEL_T12_REPORT}>12 Month Income Statement - T12 Annualized (xlsx)</Item>
                    </Menu>
                </Dropdown>
            </ThemeProvider>
        </>
    );
}