export function yearToSeason(budgetYear: number): string {
    return `${budgetYear - 1}-${ budgetYear }`;
}

export function stringYearToSeason(budgetYearStr: string): string {
    const budgetYear = Number.parseInt(budgetYearStr);

    if (Number.isNaN(budgetYear)) {
        return "";
    }

    return yearToSeason(budgetYear);
}
