import {useEffect, useState} from "react";
import {
    GetPayrollDefaultsQuery,
    PayrollCompensationItemType,
    PayrollCompensationType,
    PayrollDefaultBaseCompModel,
    PayrollDefaultBonusModel,
    PayrollDefaultCompItemModel,
    PayrollDefaultOvertimeModel,
    PayrollDefaultRaiseModel,
    useGetPayrollDefaultsLazyQuery
} from "../../../__generated__/generated_types";
import {
    LocalPayrollEmployeeBaseCompModel,
    LocalPayrollEmployeeBonusModel,
    LocalPayrollEmployeeCompItemModel,
    LocalPayrollEmployeeModel,
    LocalPayrollEmployeeModelKeys,
    LocalPayrollEmployeeOvertimeModel,
    LocalPayrollEmployeeRaiseModel
} from "../helpers/types";


type IUsePayrollDefaultsProps = {
    shouldLoadDefaults: boolean,
    propertyIds: string[],
    positionId: string | undefined
}

/**
 * 1. Property selection
 *
 *      The payroll defaults API provides defaults for combination of position and property
 *      The hook facilitates use case of choosing one of multiple property ids to pull defaults
 *
 *      When this matters: in our data we have some employees with multiple properties assigned
 *
 *      For such use case we want to be deterministic for which property id is used for defaults.
 *      Implemented by taking first property id from the alphabetically sorted ids.
 *
 *      We should be able to change the logic of picking property id later if we want to.
 *
 * 2. Ability to apply defaults selectively
 *
 *      The usePayrollDefaults hook allows to specify list of defaults (identified with LocalPayrollEmployeeModelKeys)
 *      to be skipped in applying the defaults. `disableDefaultsForKey` is for that.
 *
 * 3. Small optimisation
 *
 *      `shouldLoadDefaults` parameter allows to load defaults only when they are needed
 */
export function usePayrollDefaults(props: IUsePayrollDefaultsProps) {
    const [fetch, {data, loading}] = useGetPayrollDefaultsLazyQuery({fetchPolicy: "no-cache"});
    const [keysToNotApplyDefaults, setKeysToNotApplyDefaults] = useState<Set<string>>(new Set());

    const propertyId = props.propertyIds.sort().firstElement;

    useEffect(
        () => {
            if (!props.shouldLoadDefaults) {
                return;
            }
            if (!propertyId || !props.positionId) {
                return;
            }
            fetch({
                variables: {
                    propertyId: propertyId,
                    positionId: props.positionId
                }
            });
        },
        [props.shouldLoadDefaults, propertyId, props.positionId]
    );

    const applyDefaults = (to: LocalPayrollEmployeeModel): LocalPayrollEmployeeModel => {
        if (!data) {
            return to;
        }

        return doApplyDefaults(to, data.payrollDefaults, keysToNotApplyDefaults);
    };

    const disableDefaultsForKey = (keyIn: string) => {
        let key = keyIn;
        if (keyIn == LocalPayrollEmployeeModelKeys.baseCompensationType ||
            keyIn == LocalPayrollEmployeeModelKeys.baseCompensationAnnualizedComponent ||
            keyIn == LocalPayrollEmployeeModelKeys.baseCompensationHourlyCompensation ||
            keyIn == LocalPayrollEmployeeModelKeys.baseCompensationExpectedHoursPerWeek) {
            key = BASE_COMPENSATION_KEY;
        }
        setKeysToNotApplyDefaults(prev => {
            const newSet = new Set(prev);
            newSet.add(key);
            return newSet;
        });
    };

    return {
        isReady: data && !loading, // this will trigger upstream every time data loads
        applyDefaults,
        disableDefaultsForKey
    };
}

function doApplyDefaults(to: LocalPayrollEmployeeModel,
    defaults: GetPayrollDefaultsQuery["payrollDefaults"],
    keysToNotApplyDefaults: Set<string>): LocalPayrollEmployeeModel {
    const updated = {...to};

    if (!keysToNotApplyDefaults.has(BASE_COMPENSATION_KEY)) {
        updated.baseComp = mapBaseComp(defaults.baseComp);
    }

    if (!keysToNotApplyDefaults.has(LocalPayrollEmployeeModelKeys.raises)) {
        updated.raises = mapRaises(defaults.raises);
    }

    if (!keysToNotApplyDefaults.has(LocalPayrollEmployeeModelKeys.bonuses)) {
        updated.bonuses = mapBonuses(defaults.bonuses);
    }

    if (!keysToNotApplyDefaults.has(LocalPayrollEmployeeModelKeys.overtimes)) {
        updated.overtimes = mapOvertime(defaults.overtime);
    }

    for (const [key, compensationItemType] of Object.entries(COMP_ITEM_KEY_TO_TYPE_MAP)) {
        if (!keysToNotApplyDefaults.has(key)) {
            updated.compItems[compensationItemType] =
                mapCompItems(defaults.compItems.filter(i => i.compensationItem.type == compensationItemType));
        }
    }

    return updated;
}

const BASE_COMPENSATION_KEY = "base_compensation";
const COMP_ITEM_KEY_TO_TYPE_MAP = {
    [LocalPayrollEmployeeModelKeys.medicalCompItems]: PayrollCompensationItemType.Medical,
    [LocalPayrollEmployeeModelKeys.dentalCompItems]: PayrollCompensationItemType.Dental,
    [LocalPayrollEmployeeModelKeys.visionCompItems]: PayrollCompensationItemType.Vision,
    [LocalPayrollEmployeeModelKeys.retirement401KCompItems]: PayrollCompensationItemType.Retirement_401KMatch,
    [LocalPayrollEmployeeModelKeys.medicareCompItems]: PayrollCompensationItemType.MedicareTax,
    [LocalPayrollEmployeeModelKeys.socialSecurityTax]: PayrollCompensationItemType.SocialSecurityTax,
    [LocalPayrollEmployeeModelKeys.federalUnemploymentTax]: PayrollCompensationItemType.FederalUnemploymentTax,
    [LocalPayrollEmployeeModelKeys.stateUnemploymentTax]: PayrollCompensationItemType.StateUnemploymentTax,
    [LocalPayrollEmployeeModelKeys.workersCompensation]: PayrollCompensationItemType.WorkersCompensation
};

function mapBaseComp(baseComp: PayrollDefaultBaseCompModel | undefined | null): LocalPayrollEmployeeBaseCompModel {
    if (!baseComp) {
        return {
            annualCompensation: "",
            compensationType: PayrollCompensationType.Annual,
            hourlyCompensation: "",
            expectedHoursPerWeek: ""
        };
    }
    return {
        annualCompensation: baseComp.annualCompensation,
        compensationType: baseComp.compensationType,
        hourlyCompensation: baseComp.hourlyCompensation,
        expectedHoursPerWeek: baseComp.expectedHoursPerWeek
    };
}

function mapRaises(raises: PayrollDefaultRaiseModel[]): LocalPayrollEmployeeRaiseModel[] {
    return raises.map(raise => {
        return {
            amount: raise.amount,
            frequency: raise.frequency,
            raiseType: raise.raiseType,
            raiseDay: raise.raiseDay?.toString() ?? "",
            raiseMonth: raise.raiseMonth?.toString() ?? "",
            effectiveStartDate: raise.effectiveStartDate ?? undefined,
            onetimeRaiseDate: raise.onetimeRaiseDate ?? undefined,
        };
    });
}

function mapBonuses(bonuses: PayrollDefaultBonusModel[]): LocalPayrollEmployeeBonusModel[] {
    return bonuses.map(bonus => {
        return {
            amount: bonus.amount,
            frequency: bonus.frequency,
            bonusType: bonus.bonusType,
            bonusDay: bonus.bonusDay?.toString() ?? "",
            bonusMonth: bonus.bonusMonth?.toString() ?? "",
            effectiveStartDate: bonus.effectiveStartDate ?? undefined,
            onetimeBonusDate: bonus.onetimeBonusDate ?? undefined,
        };
    });
}

function mapOvertime(overtimeItems: PayrollDefaultOvertimeModel[]): LocalPayrollEmployeeOvertimeModel[] {
    return overtimeItems.map(ot => {
        return {
            effectiveStartDate: ot.effectiveStartDate ?? undefined,
            effectiveEndDate: ot.effectiveEndDate ?? undefined,
            expectedHoursPerWeek: ot.expectedHoursPerWeek ?? "",
            hourlyCompensation: ot.hourlyCompensation ?? ""
        };
    });
}

function mapCompItems(items: PayrollDefaultCompItemModel[]): LocalPayrollEmployeeCompItemModel[] {
    return items.map(compItem => ({
        id: "",
        compensationItem: {
            id: compItem.compensationItem.id,
            type: compItem.compensationItem.type,
            customName: compItem.compensationItem.customName ?? ""
        },
        annualCostCap: compItem.annualCostCap,
        costPerPayPeriod: compItem.costPerPayPeriod,
        effectiveStartDate: compItem.effectiveStartDate,
        calculationType: compItem.calculationType
    }));
}
