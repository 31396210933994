import { FinancialEntityMetaData } from "../../data/logic/financialEntityMetaData";
import * as Icons from "../../../../../components/spreadsheet/spreadjs-custom-cell-types/icons/IconResources";
import { ToggleOpenIconSpec } from "../../../../../components/spreadsheet/spreadjs-custom-cell-types/icons/CellDecoratorSpecs";

export const applyRowMetaUpdateToRecord = (
    rowMetaUpdate: Partial<FinancialEntityMetaData>,
    rowMetaCurrent: Partial<FinancialEntityMetaData>,
): Partial<FinancialEntityMetaData> => {

    let updatedRecordEntry:Partial<FinancialEntityMetaData>;

    if(rowMetaCurrent == undefined){
        updatedRecordEntry = rowMetaUpdate as Partial<FinancialEntityMetaData>;
    }
    else {
        updatedRecordEntry = Object.assign(rowMetaCurrent, rowMetaUpdate);
    }

    return updatedRecordEntry;
};

export const financialsToggleIconConfig:ToggleOpenIconSpec = {
    isOpen: false,
    openIconImg: Icons.plusMinusOpen,
    openIconSpecs: {
        x: -18,
        y: 15,
        width: 8,
        height: 10,
    },
    openIconHoverImg: Icons.plusMinusOpen,
    openIconHoverSpecs: {
        x: -18,
        y: 15,
        width: 8,
        height: 10,
    },
    closedIconImg: Icons.plusMinusClosed,
    closedIconSpecs: {
        x: -19,
        y: 15,
        width: 10,
        height: 10,
    },
    closedIconHoverImg: Icons.plusMinusClosed,
    closedIconHoverSpecs: {
        x: -19,
        y: 15,
        width: 10,
        height: 10,
    },
};
