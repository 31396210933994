/**
 * CellDecoratorSpecs represents the dimensional specifications of an icon.
 */
export interface CellDecoratorSpec {
    x: number,
    y: number,
    id?: number,
    width?: number,
    height?: number,
    visible?: boolean,
    xPadOffset?: number,
}

export interface SingleCellIconSpec {
    iconImg: HTMLImageElement,
    iconSpecs: CellDecoratorSpec,
    iconHoverImg: HTMLImageElement,
    iconHoverSpecs: CellDecoratorSpec,
}

/**
 * ToggleOpenIconSpecs represents a set of icons that facilitate an "open" and "closed" state.
 * Hover icons can be optionally defined for each state.
 */
export interface ToggleOpenIconSpec {
    isOpen: boolean,
    openIconImg: HTMLImageElement,
    openIconSpecs: CellDecoratorSpec,
    openIconHoverImg?: HTMLImageElement,
    openIconHoverSpecs?: CellDecoratorSpec,
    closedIconImg: HTMLImageElement,
    closedIconSpecs: CellDecoratorSpec,
    closedIconHoverImg?: HTMLImageElement,
    closedIconHoverSpecs?: CellDecoratorSpec,
}

export const DEFAULT_ICON_W = 12;
export const DEFAULT_ICON_H = 12;

/**
 * A Hotspot is a rectangular region of a cell that's used to determine targetClicked event status, as opposed
 * to cellClicked, which is fired when a cell is clicked away from the hotspot. The width and height props are
 * required for a Hotspot.
 */
export interface HotspotSpec extends CellDecoratorSpec {
    width: number,
    height: number,
}

export interface ButtonSpec extends CellDecoratorSpec {
    buttonImg: HTMLImageElement,
    buttonHoverImg: HTMLImageElement,
}

export const DEFAULT_BUTTON_W = 150;
export const DEFAULT_BUTTON_H = 48;
