import {ThemeProvider} from "@zendeskgarden/react-theming";
import {ReactElement, useEffect, useRef, useState} from "react";
import {ViziblyTheme} from "../analyst/ViziblyZDGTheme";
import useAppStore from "../../hooks/useAppStore";
import * as css from "./styles/OpDriverModeling.module.scss";
import * as workflowCSS from "../../styles/workflows/workflowCSS.module.scss";
import * as tableCSS from "../../components/account-table/styles/accountTable.module.scss";
import Header from "./components/Header";
import Card from "./components/Card";
import {Col, Grid, Row} from "@zendeskgarden/react-grid";
import {useProperties} from "../../contexts/properties/PropertiesContext";
import {
    ForecastLocks,
    SimplifiedRevenueRenewalPeriodConfiguration,
    useGetForecastLocksLazyQuery,
    useGetSimplifiedRevenueLazyQuery,
    useUpdateRenewalConfigurationMutation
} from "../../__generated__/generated_types";
import {toast} from "react-toastify";
import {HotTable} from "@handsontable/react";
import Handsontable from "handsontable";
import {LICENSES} from "../../constants/Licenses";
import {hotValidUserActions, sanitizeTableInput} from "../../components/account-table/logic/AccountTableHelpers";
import {WorkflowStepNavProps} from "../../components/workflows/workflow-step-nav/WorkflowStepNav";
import LockBanner from "../workflows/account/LockBanner";

export default function RenewalTradeOut(props: WorkflowStepNavProps): ReactElement {
    const appStore = useAppStore();
    const property = useProperties();

    const saveDebounce = useRef<(ReturnType<typeof setTimeout> | null)>(null);
    const hotRef = useRef<HotTable>(null);

    const [initialized, setInitialized] = useState<boolean>(false);
    const [tradeOuts, setTradeOuts] = useState<SimplifiedRevenueRenewalPeriodConfiguration>();
    const [forecastLocks, setForecastLocks] = useState<ForecastLocks>();
    const [getSimplifiedRevenue, {data, loading}] = useGetSimplifiedRevenueLazyQuery({
        fetchPolicy: "network-only",
    });
    const [getForecastLocks, {data: dataForecastLocks, loading: lockDataLoading}] = useGetForecastLocksLazyQuery({
        fetchPolicy: "no-cache",
    });
    const [updateRenewalConfiguration] = useUpdateRenewalConfigurationMutation({
        onCompleted: data => {
            if (data.updateSimplifiedRevenueRenewalTradeOutConfiguration) {
                toast.success("Saved", {autoClose: 2500, hideProgressBar: true});
            }
        },
        onError: () => {
            toast.error("Error Saving", {autoClose: 2500, hideProgressBar: true});
        },
    });

    useEffect(() => {
        appStore.set({isLoading: false});
        return () => undefined;
    }, []);

    useEffect(() => {
        if (!property.currentProperty) {
            return;
        }

        getSimplifiedRevenue({
            variables: {
                propertyId: property.currentProperty.id,
                budgetYear: property.currentProperty.budgetYear,
            }
        });

        getForecastLocks({
            variables: {
                propertyId: property.currentProperty.id,
                budgetYear: property.currentProperty.budgetYear,
            }
        });
    }, [property.currentProperty]);

    useEffect(() => {
        if (!data || loading || !data.simplifiedRevenueModel || !data.simplifiedRevenueModel.renewalConfiguration) {
            return;
        }

        const {
            maxIncreaseAmount,
            maxIncreasePercentage,
            maxPercentOfMarketRent,
            lossToLeaseCaptureRate,
            increaseInPlaceGreaterThanLossToLease,
        } = data.simplifiedRevenueModel.renewalConfiguration.reforecast;

        setTradeOuts({
            maxIncreaseAmount,
            maxIncreasePercentage,
            maxPercentOfMarketRent,
            lossToLeaseCaptureRate,
            increaseInPlaceGreaterThanLossToLease,
        });
    }, [data, loading]);

    useEffect(() => {
        if (!tradeOuts) {
            return;
        }

        if (saveDebounce.current) {
            clearTimeout(saveDebounce.current);
        }

        if (isValid()) {
            saveDebounce.current = setTimeout(save, 1000);
        }
    }, [tradeOuts]);

    useEffect(() => {
        if (lockDataLoading || !dataForecastLocks?.forecastLocks) {
            setForecastLocks(undefined);
            return;
        }

        setForecastLocks(dataForecastLocks.forecastLocks.property);
    }, [dataForecastLocks, lockDataLoading]);

    function isValid(): boolean {
        if (!tradeOuts) {
            return false;
        }

        for (let value of Object.values(tradeOuts)) {
            if (value == undefined || value == null) {
                return false;
            }

            if (typeof value == "string") {
                value = parseFloat(value);

                if (isNaN(value)) {
                    return false;
                }
            }

            if (typeof value !== "number") {
                return false;
            }
        }

        return true;
    }

    function save() {
        if (isValid() && property.currentProperty && tradeOuts && initialized) {
            updateRenewalConfiguration({
                variables: {
                    propertyId: property.currentProperty.id,
                    budgetYear: property.currentProperty.budgetYear,
                    configuration: {
                        reforecast: tradeOuts,
                        budget: tradeOuts,
                    }
                }
            });
        }
    }

    if (!tradeOuts || !forecastLocks) {
        return <></>;
    }

    const settings: Handsontable.GridSettings = {
        data: [
            ["Max $ Increase", tradeOuts.maxIncreaseAmount],
            ["Max % Increase", tradeOuts.maxIncreasePercentage],
            ["Max % of Market", tradeOuts.maxPercentOfMarketRent],
            ["LTL Capture Rate", tradeOuts.lossToLeaseCaptureRate],
            ["% Increase if In-Place Rate > LTL", tradeOuts.increaseInPlaceGreaterThanLossToLease],
        ],
        licenseKey: LICENSES.HandsOnTable,
        colHeaders: [
            "Rule",
            "Reforecast & Budget"
        ],
        selectionMode: "range",
        rowHeaders: false,
        stretchH: "all",
        width: "auto",
        height: "auto",
        readOnly: true,
        manualRowResize: false,
        manualColumnResize: false,
        autoColumnSize: false,
        autoRowSize: false,
        disableVisualSelection: ["header"],
        afterGetColHeader: (_column, th) => {
            if (!hotRef.current?.hotInstance) {
                return;
            }

            th.className += ` ${tableCSS.cellBase} ${tableCSS.cellBgGrey}`;

            if (_column == 0) {
                th.className += ` ${tableCSS.alignLeft}`;
            }
        },
        cells(_row, _column) {
            this.className += ` ${tableCSS.cellBase}`;

            if (_column == 1) {
                if (!(forecastLocks?.reforecastLocked && forecastLocks?.budgetLocked)) {
                    this.className += ` ${tableCSS.cellColorBlue} ${tableCSS.cellBorderBottom}`;
                    this.readOnly = false;
                }

                if (_row == 0) {
                    this.renderer = function (instance, td, row, col, prop, value, cellProperties) {
                        cellProperties.type = "numeric";
                        cellProperties.numericFormat = {
                            pattern: '$0,0',
                            culture: 'en-US'
                        };

                        if (value !== null && value !== undefined) {
                            td.innerText = String(Math.round(value));
                        }

                        Handsontable.renderers.NumericRenderer.apply(this, [instance, td, row, col, prop, value, cellProperties]);
                    };
                } else {
                    this.renderer = function (instance, td, row, col, prop, value, cellProperties) {
                        Handsontable.renderers.NumericRenderer.apply(this, [instance, td, row, col, prop, value, cellProperties]);

                        if (value !== null && value !== undefined) {
                            value = parseFloat(value);
                            td.innerText = value.toFixed(1) + '%';
                        }
                    };
                }
            } else {
                this.className += ` ${tableCSS.alignLeft}`;
            }

            return this;
        },
        beforeChange: (changes) => {
            for (let i = 0; i < changes.length; i++) {
                const change = changes[i];

                if (!change) {
                    return;
                }

                change[3] = sanitizeTableInput(change[3]);
            }
        },
        afterChange: (changes, _source) => {
            const source = _source as string;

            if (!changes) {
                return;
            }

            for (let i = 0; i < changes.length; i++) {
                const change = changes[i];

                if (!change) {
                    continue;
                }

                const row = change[0];
                const col = change[1];
                const oldVal = change[2];
                const newVal = change[3];

                if (row == undefined || col == undefined || oldVal === undefined || newVal == undefined || String(oldVal) === String(newVal)) {
                    continue;
                }

                const tempTradeOuts = Object.assign({}, tradeOuts);

                if (hotValidUserActions.includes(source)) {
                    if (!initialized) {
                        setInitialized(true);
                    }

                    const parsedVal = parseFloat(newVal);
                    if (isNaN(parsedVal)) {
                        return;
                    }

                    switch (row) {
                        case 0: {
                            tempTradeOuts.maxIncreaseAmount = parsedVal;
                            break;
                        }
                        case 1: {
                            tempTradeOuts.maxIncreasePercentage = parsedVal;
                            break;
                        }
                        case 2: {
                            tempTradeOuts.maxPercentOfMarketRent = parsedVal;
                            break;
                        }
                        case 3: {
                            tempTradeOuts.lossToLeaseCaptureRate = parsedVal;
                            break;
                        }
                        case 4: {
                            tempTradeOuts.increaseInPlaceGreaterThanLossToLease = parsedVal;
                            break;
                        }
                    }

                    setTradeOuts(tempTradeOuts);
                }
            }
        },
        beforeUndoStackChange(_doneActions, source) {
            if (source && !hotValidUserActions.includes(source)) {
                return false;
            }
        },
    };

    return (
        <ThemeProvider theme={ViziblyTheme}>
            <div className={`${css.opDriverModelingWrapper} ${workflowCSS.workflowContainer}`}>
                <div className={workflowCSS.workflowContainerInner}>
                    <Header
                        title={"Renewal Trade Out"}
                        prevAction={props.onPreviousClick}
                        nextAction={props.onNextClick}
                    />

                    <Grid className={css.bodyContent} gutters={"lg"}>
                        <Row>
                            <Col textAlign="center">
                                {property.currentProperty && forecastLocks && forecastLocks.reforecastLocked && forecastLocks.budgetLocked &&
                                    <LockBanner text={`${property.currentProperty.budgetYear - 1} Reforecast and ${property.currentProperty.budgetYear} Budget years have been locked.`} />
                                }

                                <Card className={css.limitedCardWidth}>
                                    <div className={tableCSS.accountTableWrapper}>
                                        <HotTable
                                            ref={hotRef}
                                            settings={settings}
                                            className={tableCSS.accountTable}
                                        />
                                    </div>
                                </Card>
                            </Col>
                        </Row>
                    </Grid>
                </div>
            </div>
        </ThemeProvider>
    );
}