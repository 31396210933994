import { ReactElement, useEffect, useState } from "react";
import { GetRevenueSummaryUnitTypeQuery, OriginalRevenueModel } from "../../../../__generated__/generated_types";
import { Property } from "../../../../contexts/properties/PropertiesContext";
import { Body, Cell, Head, HeaderRow, Row, Table } from "@zendeskgarden/react-tables";
import { buildCells, buildCellsCsv, buildHeaderCells, buildHeaderCellsCsv, buildNumbers, buildPropertyNumbersSum, buildPropertyNumbersWeightedAverage, buildRatio } from "./content_builders";
import * as css from "./revenueSummary.module.scss";
import { Dropdown, Field, Item, Menu, Select } from "@zendeskgarden/react-dropdowns";
import { Button } from "@zendeskgarden/react-buttons";
import FileSaver from "file-saver";
import { formatterIntNoGrouping, formatterPercentNoGrouping, formatterDecimalNoGrouping, formatterInt, formatterPercent, formatterDollarUSNoDecimal } from "../../../../utils/formatters";
import tableCSS from "../../../property-executive-summary/styles/table.module.scss";
import cn from "classnames";

export type RevenueSummaryUnitType = { unitTypeId: string, unitTypeName: string };
export type IRevenueSummaryTableProps = {
    unitTypeData: GetRevenueSummaryUnitTypeQuery["originalRevenueModel"],
    property: Property,
    unitTypes: RevenueSummaryUnitType[] | undefined,
    showLabel?: boolean
}

export type UnitTypeFieldKey = keyof Pick<OriginalRevenueModel,
    "unitTypeAvgMarketRent" |
    "unitTypeExpirationCount" |
    "unitTypeInPlaceRent" |
    "unitTypeMoveInCount" |
    "unitTypeMoveOutCount" |
    "unitTypeNewLeaseTradeOut" |
    "unitTypeOccupiedCount" |
    "unitTypeRenewalCount" |
    "unitTypeRenewalTradeOut" |
    "unitTypeUnitCountMonthly" |
    "unitTypeVacancyCount"
>;


export function RevenueSummaryTable(props: IRevenueSummaryTableProps): ReactElement {

    const [expanded, setExpanded] = useState<Record<UnitTypeFieldKey, boolean>>({
        unitTypeAvgMarketRent: false,
        unitTypeExpirationCount: false,
        unitTypeInPlaceRent: false,
        unitTypeMoveInCount: false,
        unitTypeMoveOutCount: false,
        unitTypeNewLeaseTradeOut: false,
        unitTypeOccupiedCount: false,
        unitTypeRenewalCount: false,
        unitTypeRenewalTradeOut: false,
        unitTypeUnitCountMonthly: false,
        unitTypeVacancyCount: false,
    });
    const [selectedUnitType, setSelectedUnitType] = useState<string>("all");

    useEffect(() => {
        if (!props.property) {
            return;
        }

        setSelectedUnitType("all");
    }, [props.property]);

    function handleExpand(key: UnitTypeFieldKey) {
        setExpanded(prev => {
            const prevVal = prev[key];
            return {
                ...prev,
                [key]: !prevVal
            };
        });
    }

    function TableBlockRowExpandable({
            label,
            unitTypeField,
            formatter,
            propertyAggregate = "sum",
            unitTypeFieldWeights
        }:
        {
            label: string,
            unitTypeField: UnitTypeFieldKey,
            formatter: Intl.NumberFormat,
            propertyAggregate?: "sum" | "wavg" | "ratio",
            unitTypeFieldWeights?: UnitTypeFieldKey
        }
    ): ReactElement {
        const revenueData = props.unitTypeData;
        if (!revenueData) {
            return <></>;
        }

        let propertyNumbers: number[] = [];
        if (propertyAggregate == "sum") {
            propertyNumbers = buildPropertyNumbersSum(
                    props.property.reforecastStartMonthIndex,
                    revenueData[unitTypeField],
                    selectedUnitType);
        }
        else if (propertyAggregate == "wavg" && unitTypeFieldWeights) {
            propertyNumbers = buildPropertyNumbersWeightedAverage(
                    props.property.reforecastStartMonthIndex,
                    revenueData[unitTypeField],
                    revenueData[unitTypeFieldWeights],
                    selectedUnitType);
        }
        else if (propertyAggregate == "ratio" && unitTypeFieldWeights) {
            const numeratorPropertyNumbers = buildPropertyNumbersSum(
                    props.property.reforecastStartMonthIndex,
                    revenueData[unitTypeField],
                    selectedUnitType);
            const denominatorPropertyNumbers = buildPropertyNumbersSum(
                    props.property.reforecastStartMonthIndex,
                    revenueData[unitTypeFieldWeights],
                    selectedUnitType);
            propertyNumbers = buildRatio(numeratorPropertyNumbers, denominatorPropertyNumbers);
        }
        else {
            return <></>;
        }
        return (
            <>
                <Row>
                    <Cell onClick={() => handleExpand(unitTypeField)} className={cn(tableCSS.fixedCol, tableCSS.firstCol)}>
                        {expanded[unitTypeField] ? "-" : "+"} {label}
                    </Cell>
                    {buildCells(
                        props.property.reforecastStartMonthIndex,
                        propertyNumbers,
                        formatter
                    )}
                </Row>
                {
                    expanded[unitTypeField] &&
                    revenueData[unitTypeField]
                        .filter(utData => selectedUnitType == "all" || utData.unitTypeId == selectedUnitType)
                        .sortBy("unitTypeName").map((utData, i) =>
                            <Row key={i}>
                                <Cell className={cn(css.unitTypeName, tableCSS.fixedCol, tableCSS.firstCol)}> {utData.unitTypeName}</Cell>
                                {buildCells(props.property.reforecastStartMonthIndex, buildNumbers(props.property.reforecastStartMonthIndex, utData), formatter)}
                            </Row>
                        )
                }
            </>
        );
    }


    function tableBlockRowRatioCsv({
            label,
            unitTypeNumeratorField,
            formatter,
            unitTypeDenominatorField
        }: {
            label: string,
            unitTypeNumeratorField: UnitTypeFieldKey,
            formatter: Intl.NumberFormat,
            unitTypeDenominatorField: UnitTypeFieldKey
    }): string[][] {
        const revenueData = props.unitTypeData;
        if (!props.property || !revenueData) {
            return [];
        }

        let propertyNumbers: number[] = [];
        const numeratorPropertyNumbers = buildPropertyNumbersSum(
                props.property.reforecastStartMonthIndex,
                revenueData[unitTypeNumeratorField],
                selectedUnitType);
        const denominatorPropertyNumbers = buildPropertyNumbersSum(
                props.property.reforecastStartMonthIndex,
                revenueData[unitTypeDenominatorField],
                selectedUnitType);
        propertyNumbers = buildRatio(numeratorPropertyNumbers, denominatorPropertyNumbers);
        return (
                [
                    [label,
                        ...buildCellsCsv(
                            props.property.reforecastStartMonthIndex,
                            propertyNumbers,
                            formatter
                        )
                    ]
                ]
        );
    }


    function TableBlockRowRatio({
            label,
            unitTypeNumeratorField,
            formatter,
            unitTypeDenominatorField
    }:
    {
        label: string,
        unitTypeNumeratorField: UnitTypeFieldKey,
        formatter: Intl.NumberFormat,
        unitTypeDenominatorField: UnitTypeFieldKey
    }
    ): ReactElement {
        const revenueData = props.unitTypeData;
        if (!props.property || !revenueData) {
            return <></>;
        }

        let propertyNumbers: number[] = [];
        const numeratorPropertyNumbers = buildPropertyNumbersSum(
                props.property.reforecastStartMonthIndex,
                revenueData[unitTypeNumeratorField],
                selectedUnitType);
        const denominatorPropertyNumbers = buildPropertyNumbersSum(
                props.property.reforecastStartMonthIndex,
                revenueData[unitTypeDenominatorField],
                selectedUnitType);
        propertyNumbers = buildRatio(numeratorPropertyNumbers, denominatorPropertyNumbers);
        return (
                <Row>
                    <Cell className={cn(tableCSS.fixedCol, tableCSS.firstCol)}>{label}</Cell>
                    {buildCells(
                        props.property.reforecastStartMonthIndex,
                        propertyNumbers,
                        formatter
                    )}
                </Row>
        );
    }

    function tableBlockRowExpandableCsv({
            label,
            unitTypeField,
            formatter,
            propertyAggregate = "sum",
            unitTypeFieldWeights
    }:
    {
        label: string,
        unitTypeField: UnitTypeFieldKey,
        formatter: Intl.NumberFormat,
        propertyAggregate?: "sum" | "wavg" | "ratio",
        unitTypeFieldWeights?: UnitTypeFieldKey
    }
    ): string[][] {
        const revenueData = props.unitTypeData;
        if (!props.property || !revenueData) {
            return [];
        }

        let propertyNumbers: number[] = [];
        if (propertyAggregate == "sum") {
            propertyNumbers = buildPropertyNumbersSum(
                    props.property.reforecastStartMonthIndex,
                    revenueData[unitTypeField],
                    selectedUnitType);
        }
        else if (propertyAggregate == "wavg" && unitTypeFieldWeights) {
            propertyNumbers = buildPropertyNumbersWeightedAverage(
                    props.property.reforecastStartMonthIndex,
                    revenueData[unitTypeField],
                    revenueData[unitTypeFieldWeights],
                    selectedUnitType);
        }
        else if (propertyAggregate == "ratio" && unitTypeFieldWeights) {
            const numeratorPropertyNumbers = buildPropertyNumbersSum(
                    props.property.reforecastStartMonthIndex,
                    revenueData[unitTypeField],
                    selectedUnitType);
            const denominatorPropertyNumbers = buildPropertyNumbersSum(
                    props.property.reforecastStartMonthIndex,
                    revenueData[unitTypeFieldWeights],
                    selectedUnitType);
            propertyNumbers = buildRatio(numeratorPropertyNumbers, denominatorPropertyNumbers);
        }
        else {
            return [];
        }
        return (
            [
                [
                    label,
                    ...buildCellsCsv(
                        props.property.reforecastStartMonthIndex,
                        propertyNumbers,
                        formatter
                    )
                ],
                ...revenueData[unitTypeField]
                    .filter(utData => selectedUnitType == "all" || utData.unitTypeId == selectedUnitType)
                    .sortBy("unitTypeName").map(utData =>
                        [
                            ` ${utData.unitTypeName}`,
                            ...buildCellsCsv(props.property.reforecastStartMonthIndex, buildNumbers(props.property.reforecastStartMonthIndex, utData), formatter)
                        ]
                    )
            ]
        );
    }

    function SubHead(args: {title: string}): ReactElement {
        if (!props.property) {
            return <></>;
        }
        return (
            <Row style={{backgroundColor: "var(--grey-200)"}}>
                <Cell
                    // colSpan={24 - props.property.reforecastStartMonthIndex + 2}
                    style={{
                        position: "sticky",
                        left: 0,
                        backgroundColor: "var(--grey-200)",
                    }}> {args.title} </Cell>
                <Cell
                    colSpan={23 - props.property.reforecastStartMonthIndex + 2}
                    style={{
                        backgroundColor: "var(--grey-200)",
                    }}></Cell>
            </Row>
        );
    }

    function exportToCsv(): void {
        if (!props.property || !props.unitTypeData) {
            return;
        }
        const csvRows = [
            buildHeaderCellsCsv(props.property.reforecastStartMonthIndex, props.property.budgetYear).join(",")
        ];

        function toString(arr: string[]): string {
            return arr.join(",");
        }
        csvRows.push("METRICS");
        csvRows.push(...tableBlockRowExpandableCsv({label: "Unit Count", unitTypeField: "unitTypeUnitCountMonthly", formatter: formatterIntNoGrouping}).map(v => toString(v)));
        csvRows.push(...tableBlockRowExpandableCsv({label: "Expiration Count", unitTypeField: "unitTypeExpirationCount", formatter: formatterIntNoGrouping}).map(v => toString(v)));
        csvRows.push(...tableBlockRowRatioCsv({label: "Occupied %", unitTypeNumeratorField: "unitTypeOccupiedCount", unitTypeDenominatorField: "unitTypeUnitCountMonthly", formatter: formatterPercentNoGrouping}).map(v => toString(v)));
        csvRows.push(...tableBlockRowExpandableCsv({label: "Occupied Count", unitTypeField: "unitTypeOccupiedCount", formatter: formatterIntNoGrouping}).map(v => toString(v)));
        csvRows.push(...tableBlockRowRatioCsv({label: "Renewal %", unitTypeNumeratorField: "unitTypeRenewalCount", unitTypeDenominatorField: "unitTypeExpirationCount", formatter: formatterPercentNoGrouping}).map(v => toString(v)));
        csvRows.push(...tableBlockRowExpandableCsv({label: "Renewal Count", unitTypeField: "unitTypeRenewalCount", formatter: formatterIntNoGrouping}).map(v => toString(v)));
        csvRows.push(...tableBlockRowExpandableCsv({label: "Move In Count", unitTypeField: "unitTypeMoveInCount", formatter: formatterDecimalNoGrouping}).map(v => toString(v)));
        csvRows.push(["Total Move In Count", ...buildCellsCsv(props.property.reforecastStartMonthIndex, buildNumbers( props.property.reforecastStartMonthIndex, props.unitTypeData.totalMoveInCount), formatterDecimalNoGrouping)].join(","));
        csvRows.push(...tableBlockRowRatioCsv({label: "Move Out %", unitTypeNumeratorField: "unitTypeMoveOutCount", unitTypeDenominatorField: "unitTypeExpirationCount", formatter: formatterPercentNoGrouping}).map(v => toString(v)));
        csvRows.push(...tableBlockRowExpandableCsv({label: "Move Out Count", unitTypeField: "unitTypeMoveOutCount", formatter: formatterDecimalNoGrouping}).map(v => toString(v)));
        csvRows.push(["Early Term Count", ...buildCellsCsv(props.property.reforecastStartMonthIndex, buildNumbers( props.property.reforecastStartMonthIndex, props.unitTypeData.earlyTerminationCount), formatterIntNoGrouping)].join(","));
        csvRows.push(["MTM Move Out Count",...buildCellsCsv(props.property.reforecastStartMonthIndex, buildNumbers(props.property.reforecastStartMonthIndex, props.unitTypeData.m2mMoveOutCount),formatterIntNoGrouping)].join(","));
        csvRows.push(["Total Move Out Count", ...buildCellsCsv(props.property.reforecastStartMonthIndex, buildNumbers( props.property.reforecastStartMonthIndex, props.unitTypeData.totalMoveOutCount), formatterDecimalNoGrouping)].join(","));
        csvRows.push("AMOUNTS ($)");
        csvRows.push(...tableBlockRowExpandableCsv({label: "Avg Market Rent", unitTypeField: "unitTypeAvgMarketRent", formatter: formatterIntNoGrouping, propertyAggregate: "wavg", unitTypeFieldWeights: "unitTypeUnitCountMonthly"}).map(v => toString(v)));
        csvRows.push(...tableBlockRowExpandableCsv({label: "In Place Rent", unitTypeField: "unitTypeInPlaceRent", formatter: formatterIntNoGrouping, propertyAggregate: "wavg", unitTypeFieldWeights: "unitTypeUnitCountMonthly"}).map(v => toString(v)));
        csvRows.push(...tableBlockRowExpandableCsv({label: "New Lease Tradeout", unitTypeField: "unitTypeNewLeaseTradeOut", formatter: formatterIntNoGrouping, propertyAggregate: "wavg", unitTypeFieldWeights: "unitTypeMoveInCount"}).map(v => toString(v)));
        csvRows.push(...tableBlockRowExpandableCsv({label: "Renewal Tradeout", unitTypeField: "unitTypeRenewalTradeOut", formatter: formatterIntNoGrouping, propertyAggregate: "wavg", unitTypeFieldWeights: "unitTypeRenewalCount"}).map(v => toString(v)));
        csvRows.push("FINANCIALS ($)");
        csvRows.push(["Market Rent",...buildCellsCsv(props.property.reforecastStartMonthIndex, buildNumbers(props.property.reforecastStartMonthIndex, props.unitTypeData.rent),formatterIntNoGrouping)].join(","));
        csvRows.push(["Loss To Lease",...buildCellsCsv(props.property.reforecastStartMonthIndex, buildNumbers(props.property.reforecastStartMonthIndex, props.unitTypeData.lossToLease),formatterIntNoGrouping)].join(","));
        csvRows.push(["Gross Potential Rent",...buildCellsCsv(props.property.reforecastStartMonthIndex, buildNumbers(props.property.reforecastStartMonthIndex, props.unitTypeData.grossPotentialRent),formatterIntNoGrouping)].join(","));
        csvRows.push(["GPR Change",...buildCellsCsv(props.property.reforecastStartMonthIndex, buildNumbers(props.property.reforecastStartMonthIndex, props.unitTypeData.momLossToLeaseChange),formatterIntNoGrouping)].join(","));

        const saveData = new Blob([csvRows.join("\n")], {type: "text/csv;charset=utf-8"});
        FileSaver.saveAs(saveData, `${props.property.budgetYear} - ${props.property.name}.csv`);
    }

    return (
        !(props.unitTypeData && props.property && props.unitTypes) ?
        <></>
        :   <div className={tableCSS.wrapper}>
                <div className={tableCSS.topHeader}>
                    {props.showLabel && <h4 className={css.label}>{props.property.name} | Revenue Summary</h4>}
                    <div className={tableCSS.headerActions}>
                        <div className={css.unitTypeSelector}>
                            <Dropdown
                                selectedItem={selectedUnitType}
                                onSelect={(v) => setSelectedUnitType(v)}
                            >
                                <Field>
                                    <Select>
                                        {props.unitTypes.find(ut => ut.unitTypeId == selectedUnitType)?.unitTypeName ?? "N/A"}
                                    </Select>
                                </Field>
                                <Menu>
                                    {props.unitTypes.map(({ unitTypeId, unitTypeName }) => (
                                        <Item key={unitTypeId} value={unitTypeId}>
                                            {unitTypeName}
                                        </Item>
                                    ))}
                                </Menu>
                            </Dropdown>
                        </div>
                        <Button onClick={() => exportToCsv()} style={{marginLeft: "1rem"}}>Export</Button>
                    </div>
                </div>
                <div className={tableCSS.tableWrapper}>
                    <Table className={tableCSS.table}>
                        <Head isSticky style={{zIndex: 3}}>
                            <HeaderRow className={tableCSS.headerRow}>
                                {buildHeaderCells(props.property.reforecastStartMonthIndex, props.property.budgetYear)}
                            </HeaderRow>
                        </Head>
                        <Body>
                            <SubHead title="Metrics" />
                            <TableBlockRowExpandable
                                key={"Unit Count"}
                                label={"Unit Count"}
                                unitTypeField={"unitTypeUnitCountMonthly"}
                                formatter={formatterInt}
                            />
                            <TableBlockRowExpandable
                                key={"Expiration Count"}
                                label={"Expiration Count"}
                                unitTypeField={"unitTypeExpirationCount"}
                                formatter={formatterInt}
                            />
                            <TableBlockRowRatio
                                key="Occupied %"
                                label="Occupied %"
                                unitTypeNumeratorField="unitTypeOccupiedCount"
                                unitTypeDenominatorField="unitTypeUnitCountMonthly"
                                formatter={formatterPercent}
                            />
                            <TableBlockRowExpandable
                                key={"Occupied Count"}
                                label={"Occupied Count"}
                                unitTypeField={"unitTypeOccupiedCount"}
                                formatter={formatterInt}
                            />
                            <TableBlockRowRatio
                                key="Renewal %"
                                label="Renewal %"
                                unitTypeNumeratorField="unitTypeRenewalCount"
                                unitTypeDenominatorField="unitTypeExpirationCount"
                                formatter={formatterPercent}
                            />
                            <TableBlockRowExpandable
                                key={"Renewal Count"}
                                label={"Renewal Count"}
                                unitTypeField={"unitTypeRenewalCount"}
                                formatter={formatterInt}
                            />
                            <TableBlockRowExpandable
                                key={"Move In Count"}
                                label={"Move In Count"}
                                unitTypeField={"unitTypeMoveInCount"}
                                formatter={formatterInt}
                            />
                            <Row className={selectedUnitType !== "all" ? css.increasedRowOpacity : undefined}>
                                <Cell className={cn(tableCSS.fixedCol, tableCSS.firstCol)}>Total Move In Count</Cell>
                                {buildCells(props.property.reforecastStartMonthIndex,
                                            buildNumbers(
                                                    props.property.reforecastStartMonthIndex,
                                                    props.unitTypeData.totalMoveInCount),
                                            formatterInt
                                            )
                                }
                            </Row>
                            <TableBlockRowRatio
                                key="Move Out %"
                                label="Move Out %"
                                unitTypeNumeratorField="unitTypeMoveOutCount"
                                unitTypeDenominatorField="unitTypeExpirationCount"
                                formatter={formatterPercent}
                            />
                            <TableBlockRowExpandable
                                key={"Move Out Count"}
                                label={"Move Out Count"}
                                unitTypeField={"unitTypeMoveOutCount"}
                                formatter={formatterInt}
                            />
                            <Row className={selectedUnitType !== "all" ? css.increasedRowOpacity : undefined}>
                                <Cell className={cn(tableCSS.fixedCol, tableCSS.firstCol)}>Early Term Count</Cell>
                                {buildCells(props.property.reforecastStartMonthIndex,
                                            buildNumbers(
                                                    props.property.reforecastStartMonthIndex,
                                                    props.unitTypeData.earlyTerminationCount),
                                            formatterInt
                                            )
                                }
                            </Row>
                            <Row className={selectedUnitType !== "all" ? css.increasedRowOpacity : undefined}>
                                <Cell className={cn(tableCSS.fixedCol, tableCSS.firstCol)}>MTM Move Out Count</Cell>
                                {buildCells(props.property.reforecastStartMonthIndex,
                                            buildNumbers(
                                                    props.property.reforecastStartMonthIndex,
                                                    props.unitTypeData.m2mMoveOutCount),
                                            formatterInt
                                            )
                                }
                            </Row>
                            <Row className={selectedUnitType !== "all" ? css.increasedRowOpacity : undefined}>
                                <Cell className={cn(tableCSS.fixedCol, tableCSS.firstCol)}>Total Move Out Count</Cell>
                                {buildCells(props.property.reforecastStartMonthIndex,
                                            buildNumbers(
                                                    props.property.reforecastStartMonthIndex,
                                                    props.unitTypeData.totalMoveOutCount),
                                            formatterInt
                                            )
                                }
                            </Row>

                            <SubHead title="AMOUNTS"/>

                            <TableBlockRowExpandable
                                key={"Avg Market Rent"}
                                label={"Avg Market Rent"}
                                unitTypeField={"unitTypeAvgMarketRent"}
                                formatter={formatterDollarUSNoDecimal}
                                propertyAggregate="wavg"
                                unitTypeFieldWeights={"unitTypeUnitCountMonthly"}
                            />
                            <TableBlockRowExpandable
                                key={"In Place Rent"}
                                label={"In Place Rent"}
                                unitTypeField={"unitTypeInPlaceRent"}
                                formatter={formatterDollarUSNoDecimal}
                                propertyAggregate="wavg"
                                unitTypeFieldWeights={"unitTypeUnitCountMonthly"}
                            />
                            <TableBlockRowExpandable
                                key={"New Lease Tradeout"}
                                label={"New Lease Tradeout"}
                                unitTypeField={"unitTypeNewLeaseTradeOut"}
                                formatter={formatterDollarUSNoDecimal}
                                propertyAggregate="wavg"
                                unitTypeFieldWeights={"unitTypeMoveInCount"}
                            />
                            <TableBlockRowExpandable
                                key={"Renewal Tradeout"}
                                label={"Renewal Tradeout"}
                                unitTypeField={"unitTypeRenewalTradeOut"}
                                formatter={formatterDollarUSNoDecimal}
                                propertyAggregate="wavg"
                                unitTypeFieldWeights={"unitTypeRenewalCount"}
                            />

                            <SubHead title="Financials"/>

                            <Row>
                                <Cell className={cn(tableCSS.fixedCol, tableCSS.firstCol)}>Market Rent</Cell>
                                {buildCells(props.property.reforecastStartMonthIndex, buildNumbers(props.property.reforecastStartMonthIndex, props.unitTypeData.rent), formatterDollarUSNoDecimal)}
                            </Row>
                            <Row>
                                <Cell className={cn(tableCSS.fixedCol, tableCSS.firstCol)}>Loss To Lease</Cell>
                                {buildCells(props.property.reforecastStartMonthIndex, buildNumbers(props.property.reforecastStartMonthIndex, props.unitTypeData.lossToLease), formatterDollarUSNoDecimal)}
                            </Row>
                            <Row>
                                <Cell className={cn(tableCSS.fixedCol, tableCSS.firstCol)}>Gross Potential Rent</Cell>
                                {buildCells(props.property.reforecastStartMonthIndex, buildNumbers(props.property.reforecastStartMonthIndex, props.unitTypeData.grossPotentialRent), formatterDollarUSNoDecimal)}
                            </Row>
                            <Row style={{fontStyle: "italic"}}>
                                <Cell className={cn(tableCSS.fixedCol, tableCSS.firstCol)}>GPR Change</Cell>
                                {buildCells(props.property.reforecastStartMonthIndex, buildNumbers(props.property.reforecastStartMonthIndex, props.unitTypeData.momLossToLeaseChange), formatterDollarUSNoDecimal)}
                            </Row>
                        </Body>
                    </Table>
                </div>
            </div>
    )
}