import React, { ReactElement, useContext } from "react";

import * as css from "./styles/css.module.scss";
import { formatterDollarUSNoDecimal, formatterPercent, formatterPercentWithSign } from "../../../../utils/formatters";
import { BudgetComponentType, UserRole, VersionType } from "../../../../__generated__/generated_types";
import { isPositiveGood } from "../../../../utils/account";
import { getAbbreviatedYear } from "../../../../utils/date-helpers";
import { calculateVariancePercent } from "../../../../utils/variance";
import { TValueFormat } from "../../../../global/types";
import { AuthContext } from "../../../../contexts/AuthContext";
import { Anchor } from "@zendeskgarden/react-buttons";

export default function OverviewCard(props: TOverviewCardProps): ReactElement {

    const valueStr = props.valueFormat === 'CURRENCY'
            ? formatterDollarUSNoDecimal.format(props.value)
            : formatterPercent.format(props.value);

    const { user } = useContext(AuthContext);
    /**
     * Since we're comparing two dates, an account's BudgetComponentType's isPositiveGood will negate when comparing
     * a reforecast year to a budget year. For example, if the account is an INCOME account, a positive change is
     * normally considered good, however, if we're comparing the reforecast year and the value is greater than what was
     * budgeted, then an increase in value between the two is bad.
     */
    const isPositiveGoodResult = isPositiveGood(
            props.budgetComponentType,
            props.year < props.compare.year
    );

    let bgClass:string = css.positiveChange;

    if(
            (isPositiveGoodResult && props.value < props.compare.value)
            || (!isPositiveGoodResult && props.value > props.compare.value)
    ){
        bgClass = css.negativeChange;
    }

    let variance: number;
    if(props.valueFormat === 'PERCENT') {
        variance = props.value - props.compare.value;
    } else {
        variance = calculateVariancePercent(props.value, props.compare.value);
    }

    const percentChange = formatterPercentWithSign.format(variance);
    const compareYearLabel = `${getAbbreviatedYear(props.compare.year)}`;
    const compareBudgetTypeLabel = props.compare.versionType === VersionType.Reforecast
            ? 'Reforecast'
            : 'Budget';

    return (
            <div className={`${css.overviewCard} ${bgClass}`}>
                <div className={css.label}>{props.label}</div>
                <div className={css.value}>{props.topCardTagConfigured ? valueStr : "N/A"}</div>
                <div className={css.compareLine}>
                    {
                        props.topCardTagConfigured
                        ? <><span className={css.percentChange}>{percentChange}</span> vs {compareYearLabel} {compareBudgetTypeLabel}</>
                        : (user && user.role == UserRole.Admin)
                            ? <Anchor isExternal href="/settings/financial-metrics-management" target="_blank">Configure in Settings</Anchor>
                            : "-"
                    }
                </div>
            </div>
    );
}

export type TOverviewCardProps = {
    label: string,
    value: number,
    valueFormat: TValueFormat,
    budgetComponentType: BudgetComponentType,
    topCardTagConfigured: boolean,
    year: number,
    compare: {
        value: number,
        year: number,
        versionType: VersionType,
    }
}
