import {Fragment, ReactElement, useEffect, useState} from "react";
import * as css from "../styles/OpDriverModeling.module.scss";
import * as tableCSS from "../../../components/account-table/styles/accountTable.module.scss";
import {Dropdown, Field, Item, Label, Menu, Select} from "@zendeskgarden/react-dropdowns";
import { ForecastLocks } from "../../../__generated__/generated_types";

export type TDropdownSelectTableOption = {
    label: string,
    value: string | number | Date | null | undefined,
}

export type TDropdownSelectTableItem = {
    id: string,
    colLeft: string | number,
    colRightSelection: string | number | Date | null | undefined,
    colRightOptions: TDropdownSelectTableOption[],
}

export type TDropdownSelectTableData = {
    titleDivider: string,
    items: TDropdownSelectTableItem[],
}

export interface IDropdownSelectTableProps {
    colLeft: string,
    colRight: string,
    data: TDropdownSelectTableData[],
    forecastLocks: ForecastLocks,
    onDataChange: (unitNumber: string | number, item: any) => void,
}

interface IDropdownProps {
    id: string,
    colRightSelection: string | number | Date | null | undefined,
    colRightOptions: TDropdownSelectTableOption[],
    isDisabled: boolean,
    onDataChange: (unitNumber: string | number, item: any) => void,
}

function DropdownMenu({id, colRightSelection, colRightOptions, isDisabled = false, onDataChange}: IDropdownProps): ReactElement {
    const [selection, setSelection] = useState<string | number | Date | null | undefined>(colRightSelection ?? "Select Month");
    const [label, setLabel] = useState<string>();

    useEffect(() => {
        setLabel(colRightOptions.find(x => String(x.value) == String(selection))?.label ?? "Select Month");
    }, [selection]);

    return (
        <Dropdown
            selectedItem={selection}
            onSelect={item => {
                setSelection(item);
                onDataChange(id, item);
            }}
        >
            <Field>
                <Label hidden>Lease Expiration Month</Label>
                <Select className={css.bareSelect} disabled={isDisabled}>
                    {
                        label == "Select Month" ?
                          <span style={{color: "#aaaaaa"}}>{label}</span>
                        : label
                    }
                </Select>
            </Field>
            <Menu>
                <Item disabled value={"Select Month"}>
                    Select Month
                </Item>
                {colRightOptions.map(option => (
                    <Item key={String(option.value)} value={option.value}>
                        {option.label}
                    </Item>
                ))}
            </Menu>
        </Dropdown>
    );
}

export default function DropdownSelectTable({colLeft, colRight, data, forecastLocks, onDataChange}: IDropdownSelectTableProps): ReactElement {
    return (
        <div style={{maxHeight: "450px", overflow: "auto"}}>
            <table className={tableCSS.accountTable} style={{borderCollapse: "collapse", zIndex: 1}}>
                <thead>
                    <tr style={{"position": "sticky", top: 0, zIndex: 10, boxShadow: "0 0 1px #dddddd"}}>
                        <th colSpan={2} className={`${tableCSS.cellBase} ${tableCSS.cellBgGrey} ${tableCSS.tallCell}`}>{colLeft}</th>
                        <th colSpan={2} className={`${tableCSS.cellBase} ${tableCSS.cellBgGrey} ${tableCSS.alignLeft} ${tableCSS.tallCell}`}>{colRight}</th>
                    </tr>
                </thead>
                <tbody>
                    {data.map((section, index) => (
                        <Fragment key={index}>
                            <tr style={{"position": "sticky", top: "65px", zIndex: 10, boxShadow: "0 0 1px #dddddd"}}>
                                <td className={`${tableCSS.cellBase} ${tableCSS.rowHeader} ${tableCSS.alignLeft}`} colSpan={4}>{section.titleDivider}</td>
                            </tr>

                            {section.items.map(item => (
                                <tr key={item.id}>
                                    <td className={`${tableCSS.cellBase} ${css.dropdownTableWidthLimitedCell}`}></td>
                                    <td className={`${tableCSS.cellBase} ${css.dropdownTableWidthLimitedCell}`}>{item.colLeft}</td>
                                    <td className={`${tableCSS.cellBase} ${tableCSS.alignLeft} ${css.dropdownTableWidthLimitedCell}`}>
                                        <DropdownMenu
                                            id={item.id}
                                            colRightSelection={item.colRightSelection}
                                            colRightOptions={item.colRightOptions}
                                            isDisabled={forecastLocks.reforecastLocked && forecastLocks.budgetLocked}
                                            onDataChange={onDataChange}
                                        />
                                    </td>
                                    <td className={`${tableCSS.cellBase}`}></td>
                                </tr>
                            ))}
                        </Fragment>
                    ))}
                </tbody>
            </table>
        </div>
    );
}