import React, { ReactElement } from "react";

import { Button, IconButton } from "@zendeskgarden/react-buttons";

export interface DashboardHeaderButtonProps {
    className: string,
    onClick: () => void,
    label: string,
    hideLabel?: boolean,
    icon?:  React.FC<React.SVGProps<SVGSVGElement>>,
}

function DashboardHeaderButton(props:DashboardHeaderButtonProps):ReactElement{
    return props.hideLabel ?
        (<IconButton
            isBasic
            className={props.className}
            onClick={props.onClick}
            aria-label={props.label}
        >{props.icon && <props.icon/>}</IconButton>)
        :
        (<Button
            isBasic
            className={props.className}
            onClick={props.onClick}
            aria-label={props.label}
        >{props.icon && <Button.StartIcon>
            <props.icon/>
        </Button.StartIcon>}{props.label}</Button>);
}

export default DashboardHeaderButton;
