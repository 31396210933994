import { Button } from "@zendeskgarden/react-buttons";
import { Body, Close, Footer, FooterItem, Header, Modal } from "@zendeskgarden/react-modals";
import { ReactElement, useState } from "react";
import { Inline } from "@zendeskgarden/react-loaders";

export interface IDeleteReportTableModal {
    reportTableId: string,
    reportTableName: string,
    budgetYear: number,
    onClose: () => void,
    onConfirm: () => void,
}

export default function DeleteReportTableModal(props: IDeleteReportTableModal): ReactElement {

    const [deleteClicked, setDeleteClicked] = useState(false);

    return (
        <Modal onClose={props.onClose}>
            <Header isDanger>
                Confirm Delete Report Table
            </Header>
            <Body>
                Are you sure you want to delete report table <b>{props.reportTableName}</b>?
            </Body>
            <Footer>
                <FooterItem>
                    <Button onClick={props.onClose} isBasic>
                        Cancel
                    </Button>
                </FooterItem>
                <FooterItem>
                    {
                        <Button isPrimary onClick={() => {setDeleteClicked(true); props.onConfirm();}} disabled={deleteClicked}>
                            {
                                deleteClicked
                                    ? <Inline size={24} aria-label="loading"/>
                                    : <span>Delete</span>
                            }
                        </Button>
                    }
                </FooterItem>
            </Footer>
            <Close aria-label="Close modal" />
        </Modal>
    );
}