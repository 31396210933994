import { ReactElement, useEffect, useState } from "react";
import { getAbbreviatedYear } from "../../../../utils/date-helpers";
import { VersionType } from "../../../../__generated__/generated_types";

export default function CurrentFocusedYear(props: ICurrentFocusedYearProps): ReactElement {

    const [yearAbbreviated, setYearAbbreviated] = useState<string>('');
    useEffect(
            () => {
                if(!props.year){
                    return;
                }
                setYearAbbreviated(getAbbreviatedYear(props.year));
            },
            [props.year]
    );

    const [yearLabel, setYearLabel] = useState<string>('');
    useEffect(
            () => {
                if(props.yearType === undefined){
                    return;
                }
                const label = props.yearType === VersionType.Budget
                        ? 'Budget' : props.yearType === VersionType.Actuals ? 'Actuals' : 'Reforecast';
                setYearLabel(label);
            },
            [props.yearType]
    );

    return (
            <span>{yearLabel} {yearAbbreviated}</span>
    );
}

interface ICurrentFocusedYearProps {
    year: number|undefined,
    yearType: VersionType|undefined,
}
