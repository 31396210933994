import { ReactElement, useEffect, useState } from "react";
import { IAccountVarianceTrends, IVarianceAccount } from "../logic/accountVarianceInsights";
import { BudgetComponentType } from "../../../../../../../../__generated__/generated_types";

import * as css from '../styles/insights.module.scss';
import AccountVarianceMetric from "./AccountVarianceMetric";
import AnnualBudgetRemaining, { IAnnualBudgetRemaining } from "./AnnualBudgetRemaining";
import { getAccountType } from "../../../../../../../../utils/account";

interface IAccountVarianceTrendsArgs {
    account: IVarianceAccount|undefined,
    trends: IAccountVarianceTrends|undefined,
    annualBudgetRemaining: IAnnualBudgetRemaining|undefined;
}

export function AccountVarianceTrends(props: IAccountVarianceTrendsArgs): ReactElement {

    const [accountType, setAccountType] = useState<BudgetComponentType>(BudgetComponentType.Income);

    useEffect(
            () => {
                if(!props.account){
                    return;
                }

                setAccountType(
                        getAccountType(props.account.budgetComponentType, props.account.negate)
                );
            },
            [props.account]
    );

    return (<>
        <ul className={css.insightsList}>
            {
                props.account && props.trends?.previousMonth
                        ? <li>
                            <AccountVarianceMetric
                                    varianceInfo={props.trends?.previousMonth}
                                    accountType={accountType}
                            />
                        </li>
                        : <></>
            }
            {
                props.account && props.trends?.previousYear
                        ? <li>
                            <AccountVarianceMetric
                                    varianceInfo={props.trends?.previousYear}
                                    accountType={accountType}
                            />
                        </li>
                        : <></>
            }
            {
                props.annualBudgetRemaining
                        ? <li>
                            <AnnualBudgetRemaining budgetRemaining={props.annualBudgetRemaining}/>
                        </li>
                        : <></>
            }
        </ul>
    </>);
}
