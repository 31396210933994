import { useGetMvrVarianceNotesLazyQuery } from "../../../../../__generated__/generated_types";
import { useEffect, useState } from "react";

interface IUseVarianceNoteDataReturn {
    fetch: (propertyId: string, year: number, monthIdx: number) => void;
    isLoaded: boolean;
    getAccountNoteById: (accountId:string) => string|undefined;
    byAccountId: Record<string, string>;
}

export default function useVarianceNoteData(): IUseVarianceNoteDataReturn {

    const [varianceNotesByAccountId, setVarianceNotesByAccountId] = useState<Record<string, string>>({});

    /**
     * fetchMvrVarianceNotes will request the monthly variance notes for one month of a year
     */
    const [
        _fetchVarianceNotes,
        {
            loading: varianceNotesLoading,
            data: rawVarianceNotes,
        }
    ] = useGetMvrVarianceNotesLazyQuery({
        fetchPolicy: "network-only",
    });

    useEffect(
        () => {
            if(varianceNotesLoading || !rawVarianceNotes){
                return;
            }
            const notesById = rawVarianceNotes?.queryAccountVarianceNotes.toIdMap('accountId', entry => entry.text);
            setVarianceNotesByAccountId(notesById);
        },
        [varianceNotesLoading, rawVarianceNotes]
    );

    const fetch = (propertyId: string, year: number, monthIdx: number): void => {
        _fetchVarianceNotes({
            variables: {
                propertyId: propertyId,
                year: year,
                varianceNoteMonthIndex: monthIdx,
            }
        });
    };

    const getAccountNoteById = (accountId:string):string|undefined => {
        return varianceNotesByAccountId[accountId];
    };

    return {
        fetch,
        isLoaded: !varianceNotesLoading,
        getAccountNoteById,
        byAccountId: varianceNotesByAccountId,
    };
}