import GC from '@grapecity/spread-sheets';
import VerticalAlign = GC.Spread.Sheets.VerticalAlign;
import HorizontalAlign = GC.Spread.Sheets.HorizontalAlign;

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace AcctRenovationsSheetStyles {

    /**
     * Borders, Colors, etc.
     */
    export const ACCT_RENO_BORDER = new GC.Spread.Sheets.LineBorder(
        '#D7DCDE', GC.Spread.Sheets.LineStyle.thin
    );

    /**
     * Cell Styles
     */
    export const MONTH_HEADER = new GC.Spread.Sheets.Style();
    MONTH_HEADER.hAlign = HorizontalAlign.right;
    MONTH_HEADER.vAlign = VerticalAlign.bottom;
    MONTH_HEADER.foreColor = '#333842';
    MONTH_HEADER.font = '600 12px Inter';
    MONTH_HEADER.cellPadding = '10 10 10 10';
    MONTH_HEADER.locked = true;

    export const ROW_LABEL = new GC.Spread.Sheets.Style();
    ROW_LABEL.foreColor = '#333842';
    ROW_LABEL.font = '600 12px Inter';
    ROW_LABEL.wordWrap = true;
    ROW_LABEL.vAlign = VerticalAlign.center;
    ROW_LABEL.textIndent = 1.5;
    ROW_LABEL.locked = true;

    export const RENO_COUNT_HDR = ROW_LABEL.clone();
    RENO_COUNT_HDR.borderTop = ACCT_RENO_BORDER;
    RENO_COUNT_HDR.borderBottom = ACCT_RENO_BORDER;

    export const RENO_SUMMARY_ROW = RENO_COUNT_HDR.clone();
    RENO_SUMMARY_ROW.backColor = '#F7F8F9';
    RENO_SUMMARY_ROW.formatter = '0.0';

    export const TOTAL_RENOS_ROW = RENO_SUMMARY_ROW.clone();
    TOTAL_RENOS_ROW.borderTop = ACCT_RENO_BORDER;

    export const TOTAL_RENOS_DATA = TOTAL_RENOS_ROW.clone();
    TOTAL_RENOS_DATA.formatter = '#';

    export const RENO_MOVEOUT_PERC_LABEL = ROW_LABEL.clone();
    RENO_MOVEOUT_PERC_LABEL.borderTop = ACCT_RENO_BORDER;
    RENO_MOVEOUT_PERC_LABEL.backColor = '#F7F8F9';
    RENO_MOVEOUT_PERC_LABEL.hAlign = HorizontalAlign.left;

    export const RENO_MOVEOUT_PERC_DATA = RENO_MOVEOUT_PERC_LABEL.clone();
    RENO_MOVEOUT_PERC_DATA.formatter = '0.0%';
    RENO_MOVEOUT_PERC_DATA.hAlign = HorizontalAlign.right;

    export const RENO_DATA_LABEL = ROW_LABEL.clone();
    RENO_DATA_LABEL.textIndent = 3.2;
    RENO_DATA_LABEL.foreColor = '#333842';
    RENO_DATA_LABEL.font = '500 12px Inter';
    RENO_DATA_LABEL.hAlign = HorizontalAlign.left;
    RENO_DATA_LABEL.borderBottom = ACCT_RENO_BORDER;

    export const RENO_DATA = new GC.Spread.Sheets.Style();
    RENO_DATA.foreColor = '#0D666D';
    RENO_DATA.font = '500 12px Inter';
    RENO_DATA.wordWrap = true;
    RENO_DATA.hAlign = HorizontalAlign.right;
    RENO_DATA.vAlign = VerticalAlign.center;
    RENO_DATA.cellPadding = '6px';
    RENO_DATA.borderTop = ACCT_RENO_BORDER;
    RENO_DATA.borderLeft = ACCT_RENO_BORDER;
    RENO_DATA.borderBottom = ACCT_RENO_BORDER;
    RENO_DATA.formatter = '#';
    RENO_DATA.locked = false;

    export const TEST_STYLE = RENO_DATA.clone();
    TEST_STYLE.backColor = '#CAE6E8';
    TEST_STYLE.foreColor = '#00666C';

    export const TEST_STYLE_2 = RENO_DATA.clone();
    TEST_STYLE.backColor = '#00666C';
    TEST_STYLE.foreColor = '#CAE6E8';
}

