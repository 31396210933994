import { ReactElement, useContext, useState } from "react";
import { Form } from "react-bootstrap";
import email from "../../assets/icons/email/email.svg";
import { AuthContext } from "../../contexts/AuthContext";
import { useValidCode } from "../../hooks/UseAuthHooks";
import { OTPInputProps, ResetPasswordErrorType } from "./types";

export default function OTPInput(params: OTPInputProps): ReactElement {
    const { code, setCode } = useValidCode('');
    const authContext = useContext(AuthContext);

    return (
        <>
            <Form.Group className="mb-3">
                <Form.Label className="mb-2 text-darkliver">
                    <div className="d-flex align-items-center">
                        <img className="email-icon" src={email} alt="email" />
                        <div> Enter the 6-digit verification code sent to <span className="text-blueberry"> {params.username} </span> </div>
                    </div>
                </Form.Label>
            </Form.Group>
            <div className="login-wrapper forgot-password-wrapper mt-4">
                <div className={"login-box " + (params.codeError ? "error-border" : code.length > 0 ? "focused-border" : "default-border")}>
                    <label className={"login-email " + (params.codeError ? "error-text" : code.length > 0 ? "focused-text" : "default-text")}> Code </label>
                    <span className="login-input-wrapper">
                        <input
                            className="login-input"
                            type="text"
                            required
                            minLength={6}
                            onChange={(value) => {
                                setCode(value.target.value);
                                params.setCode(value.target.value);
                            }}
                        />
                    </span>
                </div>
            </div>
            {params.codeError && params.codeError.type === ResetPasswordErrorType.CODE_PROBLEM &&
                <p className="error-message code-error-message"> That's not the right code. Please try again. </p>
            }
            {params.codeError && params.codeError.type === ResetPasswordErrorType.CODE_PROBLEM_TOO_MANY &&
                <p className="error-message code-error-message"> You’ve entered too many incorrect codes. Contact <a href="mailto:founders@vizibly.io">Vizibly support</a> for help. </p>
            }
            <p className="resend-code-paragraph">
                <span className="reset-code-text"> Didn’t receive your code? Check your spam folder </span>
                 {/* or <span className="resend-code" onClick={() => sendNewCode()}>request new code.</span> */}
            </p>
        </>
    );
}