import { ReactElement, useMemo, useState } from "react";
import { Dropdown, Field, HeaderItem, Item, Menu, Select, Separator } from "@zendeskgarden/react-dropdowns";
import { DateFormatter } from "../../../components/analyst-payroll-drawer/helpers/utils";
import { ensureDateInLocale } from "../../../utils/helper-funcs";

type DateSelectorProps = {
    once: Date[],
    recurring: {month: number, day: number}[],
    onSelectOnce: (d: Date) => void,
    onSelectRecurring: (month: number, day: number) => void,
    onSelectAny: () => void
}

const d2 = (n: number): string => `0${n}`.slice(-2);

export function DateSelector(props: DateSelectorProps): ReactElement {
    const [selectedItem, setSelectedItem] = useState("Any");
    const onceDates = useMemo(
        () => {
            return new Map<string, Date>(Array.from(props.once.map(row => [row.getTime().toString(), row])));
        },
        [props.once]
    );

    const recurringDates = useMemo(
        () => {
            return new Map<string, {month: number, day: number}>(Array.from(props.recurring.map(row => [`${d2(row.month)}${d2(row.day)}`, row])));
        },
        [props.recurring]
    );

    function handleSelectedItem(v: string) {
        if (v == "Any") {
            props.onSelectAny();
        }
        else if (v.length == 4) {
            const ret = recurringDates.get(v);
            if (ret) {
                props.onSelectRecurring(ret.month, ret.day);
            }
        }
        else {
            const ret = onceDates.get(v);
            if (ret) {
                props.onSelectOnce(ret);
            }
        }
    }

    function formatSelectedItem(v: string): string {
        let formatted = "Any";
        if (v == "Any") {
            formatted = "Any";
        }
        else if (v.length == 4) {
            const ret = recurringDates.get(v);
            if (ret) {
                formatted = `${d2(ret.month + 1)}/${d2(ret.day)}`;
            }
        }
        else {
            const ret = onceDates.get(v);
            if (ret) {
                formatted = DateFormatter.format(ensureDateInLocale(ret));
            }
        }

        return formatted;
    }

    return (
        <Dropdown
            selectedItem={selectedItem}
            onSelect={(v) => {setSelectedItem(v); handleSelectedItem(v)}}
        >
            <Field>
                <Select>
                    {formatSelectedItem(selectedItem)}
                </Select>
            </Field>
            <Menu style={{zIndex: 100}}>
                <Item key="any" value="Any">Any</Item>
                {(props.recurring.length > 0) &&
                <>
                    <HeaderItem>
                        Recurring
                    </HeaderItem>
                    <Separator/>
                    {
                    props.recurring.map(row => {
                        const v = d2(row.month) + d2(row.day);
                        return <Item key={v} value={v}>{`${d2(row.month + 1)}/${d2(row.day)}`}</Item>;
                    })
                    }
                </>
                }
                {
                <>
                    <HeaderItem>
                        Once
                    </HeaderItem>
                    <Separator/>
                    {
                    props.once.sortBy(d => d.getTime()).map(row =>
                        <Item key={row.getTime()} value={row.getTime().toString()}>{DateFormatter.format(ensureDateInLocale(row))}</Item>
                    )
                    }
                </>
                }
            </Menu>
        </Dropdown>

    );
}