import {ReactElement, useEffect, useState} from "react";
import {useDeleteUsersMutation, useListUsersLazyQuery, UserRole} from "../../../__generated__/generated_types";
import {User} from "../types";
import {Body, Cell, Head, HeaderCell, HeaderRow, Row, Table} from "@zendeskgarden/react-tables";
import {Checkbox, Field, Label, MediaInput} from '@zendeskgarden/react-forms';
import {ViziblyTheme} from "../../analyst/ViziblyZDGTheme";
import {ThemeProvider} from "@zendeskgarden/react-theming";
import {formatterEnumToTitle} from "../../../utils/formatters";
import {CheckRounded, SearchRounded} from "@material-ui/icons";
import {Button} from "@zendeskgarden/react-buttons";
import {UserCreateModal} from "./UserCreateModal";
import {UserEditModal} from "./UserEditModal";

import * as css from "./styles/userStyles.module.scss";
import AdminHeader from "../AdminHeader";
import SelectionBar from "./components/SelectionBar";
import UserDeleteConfirmation from "./components/UserDeleteConfirmation";
import {Inline} from "@zendeskgarden/react-loaders";
import {COLORS} from "../../../constants/Colors";
import {toast} from "react-toastify";
import {authorizationTitleRemapping} from "./logic/authorizationRenamer";
import {UserBulkEditModal} from "./UserBulkEditModal";

export function UserList(props: {authData: string[]}): ReactElement {
    const [getUsers, { loading, data }] = useListUsersLazyQuery({
        fetchPolicy: "network-only"
    });
    const [users, setUsers] = useState<User[]>([]);
    const [search, setSearch] = useState<string | undefined>(undefined);
    const [filteredUsers, setFilteredUsers] = useState<User[]>([]);
    const [deleteUsers, { loading: loadingDeleteUsers }] = useDeleteUsersMutation({
        notifyOnNetworkStatusChange: true,
    });
    const [selectedUsers, setSelectedUsers] = useState<string[]>([]);
    const [createFormVisible, setCreateFormVisible] = useState<boolean>(false);
    const [editFormVisible, setEditFormVisible] = useState<boolean>(false);
    const [bulkEditVisible, setBulkEditVisible] = useState<boolean>(false);
    const [confirmDeleteVisible, setConfirmDeleteVisible] = useState<boolean>(false);

    function selectAll(): void {
        setSelectedUsers(users.map(x => x.id));
    }

    function deselectAll(): void {
        setSelectedUsers([]);
    }

    function selectUser(user: string): void {
        setSelectedUsers([...selectedUsers, user]);
    }

    function deselectUser(user: string): void {
        const newSelection = [...selectedUsers];
        newSelection.splice(newSelection.indexOf(user), 1);
        setSelectedUsers(newSelection);
    }

    function deleteSelectedUsers(): void {
        deleteUsers({
            variables: {
                ids: selectedUsers,
            }
        })
            .then(() => {
                setConfirmDeleteVisible(false);
                deselectAll();
                toast.success('Deleted successfully');
                getUsers();
            })
            .catch(() => { /* Do Nothing */ });
    }

    useEffect(() => {
        getUsers();

        const handleEsc = (evt: KeyboardEvent) => {
            if (evt.key === 'Escape') {
                deselectAll();
            }
        };

        window.addEventListener('keydown', handleEsc);

        return () => {
            window.removeEventListener('keydown', handleEsc);
        };
    }, []);

    useEffect(() => {
        if (!loading && data?.listUsers) {
            setUsers(data.listUsers);
        }
    }, [loading, data]);

    useEffect(() => {
        if (users.length === 0) {
            return;
        }
        if (!search) {
            setFilteredUsers(users);
            return;
        }

        const filtered: User[] = [];
        for (const user of users) {
            if (user.firstName.toLowerCase().includes(search)
                || user.lastName.toLowerCase().includes(search)
                || user.email.toLowerCase().includes(search)
            ) {
                filtered.push(user);
            }
        }

        setFilteredUsers(filtered);
    }, [search, users]);

    return (
        <ThemeProvider theme={ViziblyTheme}>
            <div className={css.userPageInner}>
                <AdminHeader
                    title={"User Management"}
                    subtitle={"Add new users, delete users, and configure permissions."}
                />

                <div className={css.tableHeaderActions}>
                    <h5 className={css.tableHeaderActionsLeft}>
                        Users
                        {
                            loading && <Inline size={24} color={COLORS.PRIMARY_500} aria-label="loading"/>
                        }
                    </h5>
                    <div className={css.tableHeaderActionsRight}>
                        <Field>
                            <MediaInput
                                className={css.tableHeaderActionsSearch}
                                placeholder="Search"
                                isCompact
                                onChange={evt => setSearch(evt.target.value.toLowerCase())}
                                start={
                                    <SearchRounded />
                                }
                            />
                        </Field>
                        <Button isPrimary className={css.tableHeaderActionsButton} onClick={() => setCreateFormVisible(true)}>Add User</Button>
                    </div>
                </div>

                <div className={css.userTableWrapper}>
                    <Table className={css.userTable} style={{width: "auto"}} size={"large"} >
                        <Head isSticky>
                            <HeaderRow className={css.headerRowAbove}>
                                <HeaderCell className={css.checkboxCell} />
                                <HeaderCell className={`${css.nameCell} ${css.firstNameCell}`} />
                                <HeaderCell className={`${css.nameCell} ${css.lastNameCell}`} />
                                <HeaderCell />
                                <HeaderCell />
                                <HeaderCell />
                                <HeaderCell />
                                <HeaderCell colSpan={props.authData.length + 1} className={css.permissionsHeader}>PERMISSIONS</HeaderCell>
                            </HeaderRow>

                            <HeaderRow className={css.headerRow}>
                                <HeaderCell isMinimum className={css.checkboxCell}>
                                    <Field>
                                        <Checkbox
                                            className={css.userTableCheckbox}
                                            checked={!loading && selectedUsers.length == users.length}
                                            onChange={() => {
                                                users.length == selectedUsers.length ? deselectAll() : selectAll();
                                            }}
                                            indeterminate={selectedUsers.length != 0 && selectedUsers.length != users.length}
                                        >
                                            <Label className={css.userTableCheckbox} />
                                        </Checkbox>
                                    </Field>
                                </HeaderCell>
                                <HeaderCell className={`${css.nameCell} ${css.firstNameCell}`}>First Name</HeaderCell>
                                <HeaderCell className={`${css.nameCell} ${css.lastNameCell}`}>Last Name</HeaderCell>
                                <HeaderCell>Email</HeaderCell>
                                <HeaderCell>Role</HeaderCell>
                                <HeaderCell>Group(s)</HeaderCell>
                                <HeaderCell>Properties</HeaderCell>
                                <HeaderCell className={css.headerAuthCell}>Read Only</HeaderCell>
                                {props.authData.map((auth, i) => (
                                    <HeaderCell key={i} className={css.headerAuthCell}>{formatterEnumToTitle(authorizationTitleRemapping(auth))}</HeaderCell>
                                ))}
                            </HeaderRow>
                        </Head>

                        <Body>
                            {filteredUsers.map(u => (
                                <Row key={u.id} className={css.bodyRow}>
                                    <Cell className={css.checkboxCell}>
                                        <Field>
                                            <Checkbox
                                                className={css.userTableCheckbox}
                                                checked={selectedUsers.includes(u.id)}
                                                onChange={() => {
                                                    selectedUsers.includes(u.id) ? deselectUser(u.id) : selectUser(u.id);
                                                }}
                                            >
                                                <Label className={css.userTableCheckbox} />
                                            </Checkbox>
                                        </Field>
                                    </Cell>
                                    <Cell className={`${css.nameCell} ${css.firstNameCell}`}>{u.firstName}</Cell>
                                    <Cell className={`${css.nameCell} ${css.lastNameCell}`}>{u.lastName}</Cell>
                                    <Cell>{u.email}</Cell>
                                    <Cell>{formatterEnumToTitle(u.role)}</Cell>
                                    <Cell>
                                        {u.groups.map(g => g.name).join(",")}
                                    </Cell>
                                    <Cell className={css.propertiesCell}>
                                        {u.role == UserRole.Admin ? "All" : u.properties.map(x => x.name).join(", ")}
                                    </Cell>
                                    <Cell className={css.authCell}>{u.isReadOnly ? <CheckRounded /> : <></>}</Cell>
                                    {props.authData.map((auth, i) => (
                                        <Cell key={i} className={css.authCell}>
                                            {
                                                u.authorizations.includes(auth) || u.role == UserRole.Admin
                                                    ? <CheckRounded />
                                                    : <></>
                                            }
                                        </Cell>
                                    ))}
                                </Row>
                            ))}
                        </Body>
                    </Table>
                </div>
            </div>

            <SelectionBar
                selected={selectedUsers}
                onEdit={() => {
                    if(selectedUsers.length > 1) {
                        setBulkEditVisible(true);
                    } else {
                        setEditFormVisible(true);
                    }
                }}
                onDelete={() => setConfirmDeleteVisible(true)}
                name="User"
                namePlural="Users"
                enableBulk
            />
            <UserCreateModal
                formVisible={createFormVisible}
                onModalClose={() => setCreateFormVisible(false)}
                onCreate={getUsers}
            />
            <UserEditModal
                userId={selectedUsers[0]}
                formVisible={editFormVisible}
                onModalClose={() => setEditFormVisible(false)}
                onUpdate={getUsers}
            />
            {bulkEditVisible &&
                <UserBulkEditModal
                        closed={edited => {
                            setBulkEditVisible(false);
                            if(edited) {
                                getUsers();
                            }
                        }}
                        userIds={selectedUsers}
                />
            }

            {confirmDeleteVisible && (
                <UserDeleteConfirmation
                    users={users}
                    selectedUsers={selectedUsers}
                    loading={loadingDeleteUsers}
                    onClose={() => setConfirmDeleteVisible(false)}
                    onConfirm={deleteSelectedUsers}
                />
            )}
        </ThemeProvider>
    );
}
