import React, { ReactElement, useRef, useState } from "react";
import StarredAccountsTableView, {
    StarredAccountsTableViewRefObject
} from "../../../start-planning/starred-accounts-table-view/StarredAccountsTableView";
import OverriddenAccountsTableView, {
    OverriddenAccountsTableViewRefObject
} from "../../../start-planning/overridden-accounts-table-view/OverriddenAccountsTableView";

interface IAccountOverviewTablesProps {
    className?: string,
}

export default function AccountOverviewTables(_props: IAccountOverviewTablesProps): ReactElement {

    // From StartPlanning.tsx
    const starredAccountsTableViewRef = useRef<StarredAccountsTableViewRefObject>(null);
    // const emptyAccountsTableViewRef = useRef<EmptyAccountsTableViewRefObject>(null);
    const overriddenAccountsTableViewRef = useRef<OverriddenAccountsTableViewRefObject>(null);
    // END: From StartPlanning.tsx

    // TODO: Migrate to <Widget> wrapper (in components/) when tables and their data services have been refactored
    return (
        <>
            <div>
                <StarredAccountsTableView
                    ref={starredAccountsTableViewRef}
                    onDataUpdate={() => {
                        // if (emptyAccountsTableViewRef && emptyAccountsTableViewRef.current &&
                        //     typeof emptyAccountsTableViewRef.current.imperativeRefreshTableData === "function") {
                        //     emptyAccountsTableViewRef?.current?.imperativeRefreshTableData();
                        // }
                        if (overriddenAccountsTableViewRef && overriddenAccountsTableViewRef.current &&
                            typeof overriddenAccountsTableViewRef.current.imperativeRefreshTableData === "function") {
                            overriddenAccountsTableViewRef?.current?.imperativeRefreshTableData();
                        }
                    }}
                />
            </div>
            {/* Hiding this table for now to improve performance until this can be switched to handsontable */}
            {/* <div>
                    <EmptyAccountsTableView
                            ref={emptyAccountsTableViewRef}
                            onDataUpdate={() => {
                                if(starredAccountsTableViewRef && starredAccountsTableViewRef.current &&
                                        typeof starredAccountsTableViewRef.current.imperativeRefreshTableData === "function"){
                                    starredAccountsTableViewRef?.current?.imperativeRefreshTableData();
                                }
                                if(overriddenAccountsTableViewRef && overriddenAccountsTableViewRef.current &&
                                        typeof overriddenAccountsTableViewRef.current.imperativeRefreshTableData === "function"){
                                    overriddenAccountsTableViewRef?.current?.imperativeRefreshTableData();
                                }
                            }}
                    />
                </div> */}
            <div>
                <OverriddenAccountsTableView
                    ref={overriddenAccountsTableViewRef}
                    onDataUpdate={() => {
                        if (starredAccountsTableViewRef && starredAccountsTableViewRef.current &&
                            typeof starredAccountsTableViewRef.current.imperativeRefreshTableData === "function") {
                            starredAccountsTableViewRef?.current?.imperativeRefreshTableData();
                        }
                        // if (emptyAccountsTableViewRef && emptyAccountsTableViewRef.current &&
                        //     typeof emptyAccountsTableViewRef.current.imperativeRefreshTableData === "function") {
                        //     emptyAccountsTableViewRef?.current?.imperativeRefreshTableData();
                        // }
                    }}
                />
            </div>
        </>
    );
}
