// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace ReportsNavMenuConfig{

    export const OVERVIEW_PATH = "dashboard";
    export const LINE_ITEMS_PATH = "line_items";
    export const PROPERTY_EXECUTIVE_SUMMARY = "property_executive_summary";
    export const MVR_PATH = `reports/mvr`;
    export const FINANCIAL_REPORTS_PATH = `bulk-export/financial-reports`;
    export const OPERATIONAL_REPORTS_PATH = `bulk-export/operational-reports`;

}
