import React, { Children, ReactElement } from "react";

import * as css from "./styles/infoBlock.module.scss";

import Title from "./Title";
import Content from "./Content";

interface IInfoBlockProps {
    children: ReactElement[]|ReactElement,
    className?: string,
}

export default function InfoBlock(props: IInfoBlockProps): ReactElement {
    let title = <></>;
    let content = <></>;

    Children.forEach(props.children, child => {
        if(child.type === Title){
            title = child;
        }
        if(child.type === Content){
            content = child;
        }
    });

    return (
            <div className={props.className ? `${css.infoBlock} ${props.className}` : `${css.infoBlock}`}>
                {title}
                {content}
            </div>
    );
}
