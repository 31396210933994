import { EmployeeTotals } from "../logic/usePropertyExecutiveSummaryData";
import { formatterDollarUSNoDecimal } from '../../../utils/formatters';
import { chunkArray } from "./pdfExportHelpers";

export function payrollTableDataToRows(tableData: EmployeeTotals[]): string[][][] {
    const columnHeaders = [
        "Employee",
        "Base Pay",
        "Raise(s)",
        "Total",
    ];

    const rows = tableData.map(x => [
        x.positionName,
        formatterDollarUSNoDecimal.format(x.basePayTotal),
        formatterDollarUSNoDecimal.format(x.raiseTotal),
        formatterDollarUSNoDecimal.format(x.basePayTotal + x.raiseTotal),
    ]);

    const chunkedData = chunkArray(rows, 14).map(chunk => [columnHeaders, ...chunk]);

    return chunkedData;
}
