import { ReactElement } from "react";
import Select from "react-select";
import useYearDefinitions from "../annualsummary/YearDefinitions";
import './YearSelector.scss';

interface YearSelectorProps {
    variant: "standard" | "react-select";
}
export default function YearSelector(props: YearSelectorProps): ReactElement {
    const { definitions, setCurrentDefinition, currentDefinition } = useYearDefinitions();

    function yearDefinitionSelected(index: string) {
        const iIndex = Number.parseInt(index);
        const newYearDefinition = definitions[iIndex];
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        setCurrentDefinition(newYearDefinition!);
    }

    return (
        <>
            {props.variant === "standard" &&
                <select className="year-selector-select" value={currentDefinition.index} onChange={event => {
                    event.preventDefault();
                    yearDefinitionSelected(event.target.value);
                }}>
                    {
                        definitions.map((def, index) => (
                            <option key={index} value={index}>{def.label}</option>
                        ))
                    }
                </select>
            }
            {props.variant === "react-select" &&
                <Select
                    className="year-selector-select"
                    options={definitions.map(def => ({value: def.index, label: def.label}))}
                    onChange={event => {
                        if (event !== null) {
                            yearDefinitionSelected(event.value.toString());
                        }
                    }}
                />
            }
        </>
    );
}