import * as css from "./styles/css.module.scss";
import { ReactElement } from "react";
import {
    ModelingMethodPill
} from "./components/modeling-method-pill/ModelingMethodPill";
import { AssumptionsTree } from "./components/assumptions-tree/AssumptionsTree";
import { useBudgetAssumptionsData } from "./data/useBudgetAssumptionsData";
import { mapModelingMethodTypeToPillType } from "./logic/mapModelingMethodTypeToPilltype";
import { buildTotalAndOverride } from "./logic/buildTotalAndOverride";

export interface BudgetAssumptionsProps {
    propertyId: string;
    accountId: string;
    year: number;
    monthIndex: number;
    accountValue: number;
}

export function BudgetAssumptions(props: BudgetAssumptionsProps): ReactElement {
    const data = useBudgetAssumptionsData({
        propertyId: props.propertyId,
        accountId: props.accountId,
        year: props.year,
        monthIndex: props.monthIndex
    });

    // we could have a state for only re-evaluating the pillType when the modelingMethodType
    // changes. but cpu wise there is not surely a win with the state approach.
    // same time the code will become more complicated with the state approach.
    // to trade off here is to do the map on every render
    //
    // for the same reason the mapModelingMeethodTypeToPillType not wrapped into
    // as useCallback hook as the mapModelingMeethodTypeToPillType is so light
    const pillType = mapModelingMethodTypeToPillType(data.data.modelingMethodType);

    const totalAndOverride = buildTotalAndOverride(props.accountValue, data.data.originalValue);

    return (
        <div className={css.container}>
            <div className={css.header}>
                <span className={css.headerCaption}>Budget assumptions</span>
                <div className={css.headerModelingMethod}>
                    <ModelingMethodPill pillType={pillType}/>
                </div>
            </div>
            <div className={totalAndOverride.override == null ? css.content : css.contentWithOverride}>
                <AssumptionsTree assumptions={data.data.assumptionRows}/>
            </div>
            <div className={totalAndOverride.override == null ? css.total : css.totalWithOverride}>
                <div className={css.label}>Total</div><div className={css.value}>{totalAndOverride.total}</div>
            </div>
            {
                totalAndOverride.override == null ?
                <></>
                :
                <div className={css.override}>
                    <div className={css.label}>Override</div><div className={css.value}>{totalAndOverride.override}</div>
                </div>
            }
        </div>
    );
}