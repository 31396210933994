import { WorksheetLinesReportLine } from './../types';
import { FinancialEntity } from './../../../../../../../contexts/chartofaccounts/ChartOfAccountsContext';
import { Property } from "../../../../../../../contexts/properties/PropertiesContext";
import { GetWorksheetLinesForWorksheetLinesReportQuery, VersionType } from "../../../../../../../__generated__/generated_types";

const zeroes = new Array(12).fill(null);

export function buildWorksheetLines(
    data: GetWorksheetLinesForWorksheetLinesReportQuery,
    allProperties: Property[],
    allAccounts: FinancialEntity[]
): WorksheetLinesReportLine[] {

    const propertiesDictionary = new Map(allProperties.map(property => [property.id, property]));
    const accountsDictionary = new Map(allAccounts.map(account => [account.id, account]));

    const lineGroups = data.worksheetLines.groupBy(row => row.propertyId + row.accountId + row.description);

    const result = [] as WorksheetLinesReportLine[];
    for (const lineGroup of Object.values(lineGroups)) {
        const {propertyId, accountId, description} = lineGroup.firstElement;
        const property = propertiesDictionary.get(propertyId);
        const account = accountsDictionary.get(accountId);

        const lineGroupByVersionType = lineGroup.groupBy("versionType");
        const reforecastLines = lineGroupByVersionType[VersionType.Reforecast] ?? [];
        const budgetLines = lineGroupByVersionType[VersionType.Budget] ?? [];

        // it is possible to get non-balanced lines for reforecast and budget periods
        // i.e. "Google" worksheet item may be defined for reforecast period and not defined for 
        // budget period
        // the logic below accounts for that case
        // missing reforecast (or budget)
        // is populated with zeroes so that
        // result of function execution produces line with both reforecast and budget
        // arrays
        const maxLines = Math.max(reforecastLines.length, budgetLines.length);
        for (let i = 0; i < maxLines; i++) {
            const worksheetLinesReportLine: WorksheetLinesReportLine = {
                propertyId: propertyId,
                propertyName: property?.name ?? "unknown",
                accountId: accountId,
                accountName: account?.name ?? "unknown",
                accountNumber: account?.number,
                description: description,
                reforecastValues: [],
                budgetValues: []
            };
            if (i < reforecastLines.length) {
                const line = reforecastLines[i];
                worksheetLinesReportLine.reforecastValues = (line?.values ?? zeroes).copy();
            }
            else {
                worksheetLinesReportLine.reforecastValues = zeroes.copy();
            }

            if (i < budgetLines.length) {
                const line = budgetLines[i];
                worksheetLinesReportLine.budgetValues = (line?.values ?? zeroes).copy();
            }
            else {
                worksheetLinesReportLine.budgetValues = zeroes.copy();
            }

            result.push(worksheetLinesReportLine);
        }
    }

    return result;
}