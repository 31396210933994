import { ReactElement, useState } from "react";
import { OriginalRevenueRenewalIncreaseType } from "../../../../__generated__/generated_types";
import { Dropdown, Field, Item, Menu, Select } from "@zendeskgarden/react-dropdowns";
import { Input, Field as FormField } from "@zendeskgarden/react-forms";
import { Button } from "@zendeskgarden/react-buttons";
import * as css from "./renewalIncrease.module.scss"

export interface RenewalIncreaseConfigurationProps {
    disabled?: boolean,
    type: OriginalRevenueRenewalIncreaseType | null,
    percent: number | null,
    onSave: (type: OriginalRevenueRenewalIncreaseType | null, percent: number | null) => void,
}

function toString(val: OriginalRevenueRenewalIncreaseType | "manual-entry"): string {
    let ret = "Manual Entry";
    if (val === OriginalRevenueRenewalIncreaseType.MarketOrInPlaceRent) {
        ret = "Grow In Place Rent";
    }
    else if (val === OriginalRevenueRenewalIncreaseType.MarketRentDiscount) {
        ret = "Discount From Market";
    }
    return ret;
}

export function RenewalIncreaseConfiguration(props: RenewalIncreaseConfigurationProps): ReactElement {
    const [type, setType] = useState<OriginalRevenueRenewalIncreaseType | null>(props.type);
    const [percent, setPercent] = useState<number | null>(props.percent);

    function handleSelectType(newVal: OriginalRevenueRenewalIncreaseType | "manual-entry") {
        if (newVal === "manual-entry") {
            setType(null);
        }
        else {
            setType(newVal);
        }
        setPercent(null);
    }

    function handleChangePercent(evt: any) {
        const sanitized = evt.target.value === undefined || evt.target.value == null || evt.target.value === "" ? null : +evt.target.value;
        if (!Number.isNaN(sanitized)) {
            setPercent(sanitized);
        }
        else {
            setPercent(null);
        }
    }

    function handleSave() {
        let percentSave = null;
        if (type !== null) {
            percentSave = percent;
        }
        props.onSave(type, percentSave);
    }

    function handleCancel() {
        setType(props.type);
        setPercent(props.percent);
    }

    return (
        <div className={css.wrapper}>
            <Dropdown
                selectedItem={type}
                onSelect={handleSelectType}
            >
                <Field className={css.dropdown}>
                    <Select disabled={props.disabled}>
                        {toString(type ?? "manual-entry")}
                    </Select>
                </Field>
                <Menu>
                    <Item key={OriginalRevenueRenewalIncreaseType.MarketOrInPlaceRent} value={OriginalRevenueRenewalIncreaseType.MarketOrInPlaceRent}>
                        {toString(OriginalRevenueRenewalIncreaseType.MarketOrInPlaceRent)}
                    </Item>
                    <Item key={OriginalRevenueRenewalIncreaseType.MarketRentDiscount} value={OriginalRevenueRenewalIncreaseType.MarketRentDiscount}>
                        {toString(OriginalRevenueRenewalIncreaseType.MarketRentDiscount)}
                    </Item>
                    <Item key={"key-manual"} value={"manual-entry"}>
                        {toString("manual-entry")}
                    </Item>
                </Menu>
            </Dropdown>
            {type !== null &&
            <FormField className={css.input}>
                <Input
                    disabled={props.disabled}
                    value={percent ?? ""}
                    type="number"
                    onChange={handleChangePercent}
                    placeholder={"Type % amount"}
                />
            </FormField>
            }
            {(type !== props.type || percent !== props.percent) &&
            <div>
                <Button
                    isPrimary
                    disabled={(type === props.type && percent === props.percent) || (type !== null && percent == null)}
                    onClick={handleSave}
                >
                    Save
                </Button>
                <Button
                    disabled={type === props.type && percent === props.percent}
                    onClick={handleCancel}
                >
                    Cancel
                </Button>
            </div>
            }
        </div>
    );
}