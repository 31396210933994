import React, { useContext } from 'react';
import {CustomZDDropdown} from '../../../atoms/custom-zd-dropdown/CustomZDDropdown';
import {AnalystDashboardProperties} from '../../../pages/analyst/AnalystDashboardProperties';

import styles from "./styles.module.scss";
import {RenderIfAuthorized} from "../../../authorization/RenderIfAuthorized";
import {Authorizations} from "../../../authorization/Authorizations";
import { useHistory } from "react-router-dom";
import { AuthContext } from "../../../contexts/AuthContext";

export enum AnalystSections {
    Financials,
    Renovations,
    Payroll,
}

const AnalystSectionTabs = (props: {
    onTabSelect: React.Dispatch<React.SetStateAction<AnalystSections>>,
    showingSection:AnalystSections,
    propertiesFilter: AnalystDashboardProperties,
    hasUnitLevelModeling: boolean
}):JSX.Element => {

    const {user} = useContext(AuthContext);
    const history = useHistory();

    const activeSection:Record<AnalystSections, JSX.Element> = {
        [AnalystSections.Financials]: <div className={`${styles.financials} ${styles.financialsImg}`}>Financials</div>,
        [AnalystSections.Renovations]: <div className={`${styles.renovations} ${styles.renovationsImg}`}>Operational Drivers</div>,
        [AnalystSections.Payroll]: <div className={`${styles.payroll} ${styles.payrollImg}`}>Operational Drivers</div>,
    };

    const inactiveSection:Record<AnalystSections, JSX.Element> = {
        [AnalystSections.Financials]:
            <button type={"button"}
                    className={`${styles.financials} ${styles.financialsBtn}`}
                    onClick={() => {
                        history.push('/hub/financials');
                        props.onTabSelect(AnalystSections.Financials);
                    }}>
                Financials
            </button>,
        [AnalystSections.Renovations]:
                <button type={"button"}
                        className={`${styles.renovations} ${styles.renovationsBtn}`}
                        onClick={() => {
                            history.push('/hub/renovations');
                            props.onTabSelect(AnalystSections.Renovations);
                        }}>
                    Renovations
                </button>,
        [AnalystSections.Payroll]:
                <button type={"button"}
                        className={`${styles.payroll} ${styles.payrollBtn}`}
                        onClick={() => {
                            history.push('/hub/payroll');
                            props.onTabSelect(AnalystSections.Payroll);
                        }}>
                    Payroll
                </button>,
    };

    function getTab(tab:AnalystSections):JSX.Element {
        if(tab == props.showingSection){
            return activeSection[tab];
        } else {
            return inactiveSection[tab];
        }
    }

    return (
        <React.Fragment>
            <div className={styles.sectionNav}>
                <RenderIfAuthorized required={Authorizations.PlanningHubPayroll}>
                    {getTab(AnalystSections.Payroll)}
                </RenderIfAuthorized>
                {!props.hasUnitLevelModeling &&
                    getTab(AnalystSections.Renovations)
                }
                {user?.clientId === "70279817-eaaa-462c-bbeb-6b8489028c6e" && getTab(AnalystSections.Financials)}
                {props.showingSection == AnalystSections.Payroll
                        && <div className={styles.propertiesSelector}>
                        <CustomZDDropdown
                            initialSelectedItems={props.propertiesFilter.initialSelectedProperties}
                            applySelectedItems={function (items: any): void {
                                props.propertiesFilter.applyPropertiesSelection(items);
                            } }
                            openDropdownPlaceholder={"Properties"}
                            closedDropdownPlaceholder={"Properties"}
                            options={props.propertiesFilter.isReady ? props.propertiesFilter.propertyOptions : []}
                            isError={undefined}
                            isMulti
                            allOption
                        />
                </div>}
            </div>
        </React.Fragment>
    );
};

export default AnalystSectionTabs;
