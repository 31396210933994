// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace FinancialsConfig {
    // export const ROWS_OPEN_ON_LOAD = true;
    export const ROWS_OPEN_ON_LOAD = false;
    export const SJS_CELL_ID = 'FinancialsSJSSpreadCell';

    /**
     * STYLE_TO_DEPTH_ON_LOAD defines how many levels in a Financials spreadsheet heirarchy should be
     * styled on load. Spreadsheet styling should only be applied to rows that are visible to the user,
     * as the time it takes to apply spreadsheet styles grows as the number of rows increases.
     */
    export const STYLE_TO_DEPTH_ON_LOAD = 1;
}