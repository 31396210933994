import {ReactElement} from "react";
import * as css from "../styles/OpDriverModeling.module.scss";
import {Col, Grid, Row} from "@zendeskgarden/react-grid";

export interface ICardProps {
    title?: string,
    actions?: ReactElement,
    children?: ReactElement[] | ReactElement,
    className?: string,
    titleClassName?: string,
}

export default function Card({title, actions, children, className, titleClassName}: ICardProps): ReactElement {
    return (
        <Grid className={`${css.card} ${className}`} gutters={false}>
            {title &&
                <Row className={`${css.cardTitle} ${titleClassName}`} alignItems="center">
                    <Col>
                        <span>{title}</span>
                    </Col>

                    {actions &&
                        <Col textAlign="end" className={css.cardActionsCol}>
                            {actions}
                        </Col>
                    }
                </Row>
            }

            {children &&
                <Row>
                    <Col>
                        {children}
                    </Col>
                </Row>
            }
        </Grid>
    );
}