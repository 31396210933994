export class HandledGQLErrors {
    private static instance: HandledGQLErrors;

    public handledGQLErrors: Record<string, boolean> = {};

    // eslint-disable-next-line @typescript-eslint/no-empty-function
    private constructor() {
    }

    private getErrorKey = (
        exceptionName: string,
        errorCode: string,
    ): string => {
        return `${exceptionName}_${errorCode}`;
    };

    public static getInstance():HandledGQLErrors {
        if(HandledGQLErrors.instance === undefined){
            HandledGQLErrors.instance = new HandledGQLErrors();
        }
        return HandledGQLErrors.instance;
    }

    public registerHandledGQLError = (
        exceptionName: string,
        errorCode: string,
    ): void => {
        const errorKey = this.getErrorKey(exceptionName, errorCode);
        this.handledGQLErrors[errorKey] = true;
    };

    public unregisterHandledGQLError = (
        exceptionName: string,
        errorCode: string,
    ): void => {
        const errorKey = this.getErrorKey(exceptionName, errorCode);
        const possibleHandledError = this.handledGQLErrors[errorKey];

        if(possibleHandledError != undefined){
            delete this.handledGQLErrors[errorKey];
        }
    };

    public isGQLErrorHandled = (
        exceptionName: string,
        errorCode: string,
    ): boolean => {
        return this.handledGQLErrors[this.getErrorKey(exceptionName, errorCode)] ?? false;
    }
}