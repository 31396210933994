import Shimmer from './Shimmer';
import YOYImage from '../../assets/images/shimmers/yoy.jpg';
import AccountViewImage from '../../assets/images/shimmers/account-view.jpg';
import AnnualImage from '../../assets/images/shimmers/annual-dashboard.jpg';
import AnalystViewImage from "../../assets/images/shimmers/analyst-view-v2.svg";
import AnalystDrawerOpPct from "../../assets/images/shimmers/analyst_drawer_op_pct.svg";
import AnalystDrawerWorksheet from "../../assets/images/shimmers/analyst_drawer_worksheet.svg";

import styles from './styles.module.scss';

export enum ShimmerPageKey {
    ACCOUNT_VIEW,
    YoY_VIEW,
    ANNUAL_VIEW,
    ANALYST_VIEW,
    ANALYST_DRAWER_OP_PCT,
    ANALYST_DRAWER_WORKSHEET,
    MONTHLY_VARIANCE_REPORT,
}


interface Props {
    pageKey?: ShimmerPageKey;
    modal?: boolean;
}



// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
function ShimmerRenderer({ pageKey, modal }: Props) {
    let image = null;
    let className = `${styles.shimmer} ${styles.shimmerGeneral}`;

    switch (pageKey) {
        case ShimmerPageKey.YoY_VIEW:
            image = YOYImage;
            break;
        case ShimmerPageKey.ACCOUNT_VIEW:
            image = AccountViewImage;
            break;
        case ShimmerPageKey.ANNUAL_VIEW:
            image = AnnualImage;
            break;
        case ShimmerPageKey.ANALYST_VIEW:
            image = AnalystViewImage;
            className = `${styles.shimmer} ${styles.shimmerAnalyst}`;
            break;
        case ShimmerPageKey.ANALYST_DRAWER_OP_PCT:
            image = AnalystDrawerOpPct;
            break;
        case ShimmerPageKey.ANALYST_DRAWER_WORKSHEET:
            image = AnalystDrawerWorksheet;
            break;
        case ShimmerPageKey.MONTHLY_VARIANCE_REPORT:
            // TODO: Come up with a more general name for AnalystViewImage. It's really just a shorter shimmer.
            image = AnalystViewImage;
            className = `${styles.shimmer} ${styles.shimmerReport}`;
            break;
        default:
            break;
    }
    const Shim = image ?
        <Shimmer className={className}>
            <img src={image} alt="" />
        </Shimmer>
        : <></>;

    return (
        modal ?
            <div style={{ background: "whitesmoke", top: 75, zIndex: 8 }} className='_modal'>
                {Shim}
            </div>
            :
            Shim
    );
}

export default ShimmerRenderer;
