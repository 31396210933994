import React, { ReactElement } from "react";
import * as css from "./styles/infoBlock.module.scss";

interface ITitleProps {
    children: ReactElement[]|ReactElement|string,
    className?: string,
}

export default function Title(props: ITitleProps): ReactElement {
    return (
            <div className={props.className ? `${css.title} ${props.className}` : css.title}>
                {props.children}
            </div>
    );
}