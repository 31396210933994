/* eslint-disable @typescript-eslint/no-empty-function */
import React, { ReactElement, useEffect, useRef, useState } from 'react';

import { useProperties } from '../../../contexts/properties/PropertiesContext';
import { Pagination } from '@zendeskgarden/react-pagination';
import { zdgPaginationTransformPageProps } from '../../../styles/zendesk-garden/ZDPaginationTransformPageProps';

import HighchartsReact from 'highcharts-react-official';
import gantt from 'highcharts/highcharts-gantt';

import { UlmEventModal } from "./ulm-edit-modal/UlmEventModal";
import {UlmEventModalProps, UnitModelSelected} from "./ulm-edit-modal/helpers/types";

import { useUnitEventData } from './data/hooks/useUnitEventData';

import {EnrichedUnitEventModel} from "../../../__generated__/generated_types";
import type {HCGanttSeries} from "./helpers/types";
import { ChartBackgroundClickInfo } from "./helpers/types";
import {DateTime} from "luxon";

function LeaseActivity(): ReactElement {

    const { currentProperty } = useProperties();

    const chartRef = useRef<HighchartsReact.RefObject>(null);

    const onUnitEventClick = (evt:any) => {
        // console.info(`onUnitEventClick`);
        const clickedPointMetadata:HCGanttSeries = evt.point;
        handleEventModalOpen(
            clickedPointMetadata.custom.unit,
            null,
            clickedPointMetadata.custom.prevEvent,
            clickedPointMetadata.custom.currentEvent,
            clickedPointMetadata.custom.nextEvent
        );
    };

    const onEmptyAreaClick = (info: (HCGanttSeries|ChartBackgroundClickInfo|undefined) ) => {
        // console.info(`onEmptyAreaClick, ${UndefinedSafeStringify(info)}`);
        if(info && 'prevEvent' in info) {
            handleEventModalOpen(
                info.unit,
                DateTime.fromSeconds(info.clickedTs / 1000).toISO(),
                info.prevEvent,
                null,
                info.nextEvent
            );
        }
    };

    const {
        fetchUnitEvents,
        hcUnitEventsData,
        totalPages,
        currentPage,
        totalUnits,
        setCurrentPage,
    } = useUnitEventData({
        onUnitEventClick,
        onEmptyAreaClick,
    });

    const [showUlmEventModal, setShowUlmEventModal] = useState<boolean>(false);
    const [ulmEventModalProps, setUlmEventModalProps] = useState<UlmEventModalProps|undefined>(undefined);

    useEffect(
        () => {
            if(!currentProperty){
                return;
            }
            fetchUnitEvents(currentProperty.id, currentProperty.budgetYear, 1);
        },
        [currentProperty],
    );

    const onPaginationUpdate = (nextPage:number):void => {
        setCurrentPage(nextPage);
    };

    const handleEventModalOpen = (
        unit:UnitModelSelected,
        clickedDate: (string|null),
        prevEvent: (EnrichedUnitEventModel|null),
        clickedEvent:(EnrichedUnitEventModel|null),
        nextEvent: (EnrichedUnitEventModel|null)
    ) => {
        // console.info(`handleEventModalOpen: ${clickedDate}, ${clickedEvent}`);
        // A place to decide if user has clicked an empty space or the existing event on the main view
        // Based of this, we decide on the required props attribute and share it with event modal
        // We need to pass at least one of clickedDate or currentEvent based on the click in empty space or existing event
        if(clickedDate){
            // Case of a new event leading to the add event mode of the modal
            const newEventModalProps: UlmEventModalProps = {
                propertyId: currentProperty?.id ?? "",
                budgetYear: currentProperty?.budgetYear ?? 0,
                unit: unit,
                clickedDate: clickedDate,
                prevEvent: prevEvent,
                nextEvent: nextEvent,
                onClose: (showModal, wasThereAnyUpdatesPersistedOnBe) => {
                    handleEventModalClose(showModal, wasThereAnyUpdatesPersistedOnBe);
                }
            };
            setUlmEventModalProps(newEventModalProps);
            setShowUlmEventModal(true);
        } else if(clickedEvent) {
            // Case of an existing event leading to edit event mode of the modal
            const existingEventModalProps: UlmEventModalProps = {
                propertyId: currentProperty?.id ?? "",
                budgetYear: currentProperty?.budgetYear ?? 0,
                unit: unit,
                prevEvent: prevEvent,
                currentEvent: clickedEvent,
                nextEvent: nextEvent,
                onClose: (showModal, wasThereAnyUpdatesPersistedOnBe) => {
                    handleEventModalClose(showModal, wasThereAnyUpdatesPersistedOnBe);
                }
            };
            setUlmEventModalProps(existingEventModalProps);
            setShowUlmEventModal(true);
        }
    };

    const handleEventModalClose = (showModal: boolean, wasThereAnyUpdatesPersistedOnBe: boolean) => {
        if(wasThereAnyUpdatesPersistedOnBe && currentProperty) {
            fetchUnitEvents(currentProperty.id, currentProperty.budgetYear, currentPage);
        }
        setUlmEventModalProps(undefined);
        setShowUlmEventModal(showModal);
    };

    return (<>
        <div>
            <HighchartsReact
                ref={chartRef}
                constructorType="ganttChart"
                highcharts={gantt}
                options={hcUnitEventsData}
            />
        </div>
        {totalUnits > 10 &&
            <div>
                <Pagination
                    currentPage={currentPage}
                    totalPages={totalPages}
                    onChange={onPaginationUpdate}
                    transformPageProps={zdgPaginationTransformPageProps}
                ></Pagination>
            </div>
        }
        {
            showUlmEventModal && ulmEventModalProps &&
            <UlmEventModal
                {...ulmEventModalProps}
            />
        }
    </>);
}

export default LeaseActivity;
