import { ReactElement } from "react";
import { ItemData } from "../types";
import { useGetDriverStatusForAccountQuery } from "../../../__generated__/generated_types";
import Card from "../../simplified-revenue/components/Card";
import "native-injects";
import { Body, Cell, Head, HeaderCell, HeaderRow, Row, Table } from "@zendeskgarden/react-tables";
import { Link } from "react-router-dom";

export type AccountStatusCardProps = {
    item: ItemData
}

export function AccountStatusCard({item}: AccountStatusCardProps): ReactElement {
    const {data: accountStatusData} = useGetDriverStatusForAccountQuery({
        variables: {accountId: item.id},
        notifyOnNetworkStatusChange: true,
        fetchPolicy: "no-cache",
    });
    return (
        <Card>
            <Table>
                <Head>
                    <HeaderRow>
                        <HeaderCell>
                            Used in
                        </HeaderCell>
                        <HeaderCell>
                            Is Used
                        </HeaderCell>
                        <HeaderCell>
                            Details
                        </HeaderCell>
                    </HeaderRow>
                </Head>
                <Body>
                    <Row>
                        <Cell>
                            Modelling Methods
                        </Cell>
                        <Cell>
                            {accountStatusData?.queryDriverStatusForAccount?.hasModellingMethods ? "Yes" : "No"}
                        </Cell>
                        <Cell>
                            {accountStatusData?.queryDriverStatusForAccount?.hasModellingMethods &&
                            <Link target="_blank" to={`/account/${item.id}/budget`}>
                                See Account
                            </Link>
                            }
                        </Cell>
                    </Row>
                    <Row>
                        <Cell>
                            Renovation Cost Categories
                        </Cell>
                        <Cell>
                            {(accountStatusData?.queryDriverStatusForAccount?.renovationCostCategories?.length ?? 0) > 0 ? "Yes" : "No"}
                        </Cell>
                        <Cell>
                            {Boolean(accountStatusData?.queryDriverStatusForAccount?.renovationCostCategories?.length ?? 0) &&
                            <>
                                <ul>
                                {accountStatusData?.queryDriverStatusForAccount?.renovationCostCategories?.map(row => <li>{row}</li>)}
                                </ul>
                                <Link target="_blank" to={`/account/${item.id}/budget`}>
                                    See Account
                                </Link>
                            </>
                            }
                        </Cell>
                    </Row>
                    <Row>
                        <Cell>
                            Financial Metrics
                        </Cell>
                        <Cell>
                            {(accountStatusData?.queryDriverStatusForAccount?.financialMetrics?.length ?? 0) > 0 ? "Yes" : "No"}
                        </Cell>
                        <Cell>
                            {Boolean(accountStatusData?.queryDriverStatusForAccount?.financialMetrics?.length ?? 0) &&
                            <>
                                <ul>
                                {accountStatusData?.queryDriverStatusForAccount?.financialMetrics?.map(row => <li>{row}</li>)}
                                </ul>
                                <Link target="_blank" to={`/setup/start`}>
                                    Go To Plan
                                </Link>
                            </>
                            }
                        </Cell>
                    </Row>
                    {((accountStatusData?.queryDriverStatusForAccount?.accPercentageSourceForAccounts?.length ?? 0) > 0) &&
                    <Row>
                        <Cell>
                            Accounts including <b>{(item.glNumber ? item.glNumber + " - " : "") + item.glName}</b> in % of Account
                        </Cell>
                        <Cell>
                            {(accountStatusData?.queryDriverStatusForAccount?.accPercentageSourceForAccounts?.length ?? 0) > 0 ? "Yes" : "No"}
                        </Cell>
                        <Cell>
                            {Boolean(accountStatusData?.queryDriverStatusForAccount?.accPercentageSourceForAccounts?.length ?? 0) &&
                            <>
                                <ul>
                                {accountStatusData?.queryDriverStatusForAccount?.accPercentageSourceForAccounts?.map(row => (
                                    <li>
                                        <Link target="_blank" to={`/account/${row.id}/budget`}>
                                            {(row.glNumber ? `${row.glNumber} - ` : "")}{row.glName}
                                        </Link>
                                    </li>
                                ))}
                                </ul>
                            </>
                            }
                        </Cell>
                    </Row>
                    }
                </Body>
            </Table>
        </Card>
    )
}