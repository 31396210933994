import React, { ReactElement, useContext, useEffect, useRef, useState } from "react";

import { Modal, Close, Header, Body, Footer, FooterItem } from '@zendeskgarden/react-modals';

import * as css from "./styles/css.module.scss";
import useMonthlyVarianceModalData, {
    IMonthlyVarianceModalDataProps,
} from "./data/useMonthlyVarianceModalData";
import { Col, Grid, Row } from "@zendeskgarden/react-grid";
import { MVRTransactionsTable } from "./components/mvr-transactions-table/MVRTransactionsTable";
import { ReactComponent as ChevronUp } from '@zendeskgarden/svg-icons/src/16/chevron-up-stroke.svg';
import { ReactComponent as ChevronDown } from '@zendeskgarden/svg-icons/src/16/chevron-down-stroke.svg';
import { Button, IconButton } from '@zendeskgarden/react-buttons';
import { Tab, TabList, TabPanel, Tabs } from "@zendeskgarden/react-tabs";
import { OverviewWidget } from "./components/overview-widget/OverviewWidget";
import { InsightsWidget } from "./components/insights-widget/InsightsWidget";
import { VarianceNote } from "./components/variance-note/VarianceNote";
import { BudgetNote } from "./components/budget-note/BudgetNote";
import { BudgetAssumptions } from "./components/budget-assumptions/BudgetAssumptions";
import { useMonthlyVarianceReportContext } from "../../context/MonthlyVarianceReportContext";
import YearMonthSelector from "../../../../../components/year-month-selector/YearMonthSelector";

import { NotePane } from "./components/note-pane/NotePane";
import { getVarianceNotesHeaderLabel } from "./logic/actualVsBudgetNotePane";
import useVarianceNoteData from "../../context/data/useVarianceNoteData";
import { useMonthlyVarianceModalFinancials } from "./data/useMonthlyVarianceModalFinancials";
import { FeatureFlagContext } from "../../../../../feature-flag/FeatureFlagContextProvider";
import { Feature } from "../../../../../feature-flag/Feature";

type IMonthlyVarianceModalProps = {
    isVisible: boolean
    closeRequested: () => void
    modalConfig: IMonthlyVarianceModalDataProps,
}

function MonthlyVarianceModal(props: IMonthlyVarianceModalProps): ReactElement {

    const ctx = useMonthlyVarianceReportContext();
    const { hasFeature } = useContext(FeatureFlagContext);
    const [monthIndex, setMonthIndex] = useState<number>(props.modalConfig.month);
    const [year, setYear] = useState<number>(props.modalConfig.year);

    const mvrData = useMonthlyVarianceModalData({
        accountId: props.modalConfig.accountId,
        accounts: props.modalConfig.accounts,
        propertyId: props.modalConfig.propertyId,
        year: props.modalConfig.year,
        month: props.modalConfig.month,
    });
    const accountId = mvrData.currentAccount.id;

    const mvrModalFinancials = useMonthlyVarianceModalFinancials({
        propertyId: props.modalConfig.propertyId,
        accountId: mvrData.currentAccount.id,
        year: year,
        monthIndex: monthIndex
    });

    const varianceNotes = useVarianceNoteData();

    const [isDirty, setIsDirty] = useState(false);
    const [isConfirmClose, setIsConfirmClose] = useState(false);
    const mvrModalRef = useRef<HTMLDivElement>(null);

    const closeModalIfClean = (isDirty: boolean) => {
        if (isDirty) {
            setIsConfirmClose(true);
        }
        else {
            props.closeRequested();
        }
    };

    const forceCloseModal = () => {
        setIsConfirmClose(false);
        props.closeRequested();
    };

    /**
     * When the modal becomes visible, set the account, when it's closed, reset the year and month.
     */
    useEffect(
        () => {
            if (props.isVisible) {
                mvrData.selectAccount(props.modalConfig.accountId);
            }
            else if (ctx.mvrReportYear && ctx.mvrReportMonth != undefined) {
                setYear(ctx.mvrReportYear);
                setMonthIndex(ctx.mvrReportMonth);
            }
        },
        [props.isVisible]
    );

    const accountLabel = mvrData.currentAccount.glNumber ?
        `(${mvrData.currentAccount.glNumber}) ${mvrData.currentAccount.glName}`
        : mvrData.currentAccount.glName;

    const propertyId = props.modalConfig.propertyId;

    const modalRoot: Element | undefined = (mvrModalRef.current == null) ? undefined : mvrModalRef.current;

    /**
     * The modal has awareness of the month and year of the main report that it was opened from. If the date that's
     * selected in the Actual vs. Budget area isn't the same as the main report's date, and there's a variance note,
     * the variance note is shown.
     */
    const [showActualVsBudgetVarianceNote, setShowActualVsBudgetVarianceNote] = useState<boolean>(false);

    /**
     * Handles updates from the Actual vs. Budget date picker.
     *
     * @param year
     * @param month
     */
    const onActualVsBudgetDateSelect = (year: number, month: number) => {
        setYear(year);
        setMonthIndex(month);

        varianceNotes.fetch(propertyId, year, monthIndex);
    };

    /**
     * Update the year and month state for the Actual vs. Budget area if they're updated externally.
     */
    useEffect(
        () => {
            if (props.modalConfig.month === undefined || !props.modalConfig.year) {
                return;
            }

            setYear(props.modalConfig.year);
            setMonthIndex(props.modalConfig.month);
        },
        [props.modalConfig.month, props.modalConfig.year]
    );

    /**
     * Whenever the year or month changes, fetch the account variance notes for the new year/month
     */
    useEffect(
        () => {
            varianceNotes.fetch(propertyId, year, monthIndex);
        },
        [year, monthIndex]
    );

    useEffect(
        () => {
            if (!varianceNotes.isLoaded || !varianceNotes.byAccountId || !accountId) {
                return;
            }
            const note = varianceNotes.byAccountId[accountId];

            const isDifferentYearOrMonth = year != ctx.mvrReportYear || monthIndex != ctx.mvrReportMonth;
            const noteExists = note != undefined && note.length > 0;

            setShowActualVsBudgetVarianceNote(isDifferentYearOrMonth && noteExists);
        },
        [varianceNotes.isLoaded, varianceNotes.byAccountId, accountId]
    );

    return (
        <>{
            props.isVisible ?
                <Modal
                    onClose={() => {
                        closeModalIfClean(isDirty);
                    }}
                    className={css.modalPane}
                    ref={mvrModalRef}
                >
                    <Close aria-label="Close Modal" className={css.modalCloseButton} />
                    <Grid columns={12} className={css.fitParentHeight}>
                        <Row className={css.modalHeader}>
                            <Col className={css.modalHeaderContent}>
                                <IconButton size="small" onClick={() => mvrData.prevAccount()}>
                                    <ChevronUp />
                                </IconButton>
                                <IconButton size="small" onClick={() => mvrData.nextAccount()}>
                                    <ChevronDown />
                                </IconButton>
                                {accountLabel}
                            </Col>
                        </Row>
                        <Row className={css.modalBody}>
                            <Col size={4} className={css.modalPaneLeftCol}>
                                <div className={`${css.modalInnerCell} ${css.widgetsPane}`}>
                                    <Tabs className={css.widgetTabs}>
                                        <TabList className={css.overviewTab}>
                                            <Tab item="overview">Overview</Tab>
                                            <Tab item="insights">Insights</Tab>
                                        </TabList>
                                        <TabPanel item="overview" className={css.widgetTabPanel}>
                                            {ctx && ctx.mvrEntityDataMap
                                                ? <OverviewWidget accountId={mvrData.currentAccount.id}
                                                    month={props.modalConfig.month}
                                                    year={props.modalConfig.year} />
                                                : <></>
                                            }
                                        </TabPanel>
                                        <TabPanel item="insights" className={css.widgetTabPanel}>
                                            <InsightsWidget
                                                    propertyId={propertyId}
                                                    accountId={mvrData.currentAccount.id}
                                                    year={year}
                                                    monthIndex={monthIndex}
                                                    accountValue={mvrModalFinancials.budgetValue}
                                            />
                                        </TabPanel>
                                    </Tabs>
                                </div>
                                <div className={`${css.modalInnerCell} ${css.varianceNotePane}`}>
                                    <VarianceNote
                                        propertyId={propertyId}
                                        accountId={accountId}
                                        year={ctx.mvrReportYear ?? year}
                                        monthIndex={ctx.mvrReportMonth ?? monthIndex}
                                        onDirtyChange={isDirty => setIsDirty(isDirty)}
                                    >
                                        <VarianceNote.Header>
                                            {/* // Note: Consider showing date here since it's so small at the top of the column */}
                                            Variance Note
                                        </VarianceNote.Header>
                                    </VarianceNote>
                                </div>
                            </Col>
                            <Col size={8} className={`${css.fitParentHeight} ${css.modalPaneRightCol}`}>
                                <div className={`${css.fitParentHeight} ${css.modalInnerCell}`}>
                                    <div className={css.rightColHeader}>
                                        <div className={css.rightColHeaderLabel}>Actual vs. Budget</div>
                                        <YearMonthSelector
                                            year={year}
                                            month={monthIndex}
                                            onClick={onActualVsBudgetDateSelect}
                                            isBasic={true}
                                            showCalendarIcon={true}
                                            calendarClassName={css.modalDatePickerCalendar}
                                            minYear={ctx.minVersionYear}
                                            maxYear={ctx.maxVersionYear}
                                        />
                                    </div>
                                    <div className={css.rightColBody}>
                                        <div className={css.fitParentHeight}>
                                            <div>
                                                <div className={css.modalTransactionsCaption}>Transactions</div>
                                            </div>
                                            <div className={
                                                showActualVsBudgetVarianceNote
                                                    ? css.modalTransactionsTableCompact
                                                    : css.modalTransactionsTable
                                            }>
                                                <div className={css.fitParentHeight}>
                                                    <MVRTransactionsTable
                                                        propertyId={propertyId}
                                                        accountId={mvrData.currentAccount.id}
                                                        year={year}
                                                        monthIndex={monthIndex}
                                                        totalValue={mvrModalFinancials.actualValue}
                                                    />
                                                </div>
                                            </div>
                                            <div className={css.actualVsBudgetNotePane} style={!showActualVsBudgetVarianceNote ? { display: "none" } : {}}>
                                                <NotePane isVisible={showActualVsBudgetVarianceNote}>
                                                    <NotePane.Header>
                                                        <NotePane.Label>{getVarianceNotesHeaderLabel({ monthIdx: monthIndex })}</NotePane.Label>
                                                    </NotePane.Header>
                                                    <NotePane.Note>
                                                        {varianceNotes.byAccountId[accountId]}
                                                    </NotePane.Note>
                                                </NotePane>
                                            </div>
                                        </div>
                                        <div className={css.budgetCol}>
                                            <div className={css.budgetAssumptionsPane}>
                                                <BudgetAssumptions
                                                    propertyId={propertyId}
                                                    accountId={mvrData.currentAccount.id}
                                                    year={year}
                                                    monthIndex={monthIndex}
                                                    accountValue={mvrModalFinancials.budgetValue}
                                                />
                                            </div>
                                            <div className={css.budgetNotePane}>
                                                <BudgetNote
                                                    accountId={accountId}
                                                    propertyId={propertyId}
                                                    year={year}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Grid>
                </Modal>
                : <></>
        }
            {isConfirmClose && (
                <Modal
                    onClose={() => setIsConfirmClose(false)}
                    appendToNode={modalRoot}
                    backdropProps={{
                        style: {
                            backgroundColor: "#33384233",
                            marginTop: "75px"
                        }
                    }}
                    focusOnMount={true}
                    isAnimated={false}
                >
                    <Header isDanger>Confirm Exit</Header>
                    <Body>
                        Are you sure you want to exit? Your unsaved work will be lost.
                    </Body>
                    <Footer>
                        <FooterItem>
                            <Button onClick={() => setIsConfirmClose(false)} isBasic>
                                Cancel
                            </Button>
                        </FooterItem>
                        <FooterItem>
                            <Button isPrimary isDanger onClick={() => forceCloseModal()}>
                                Confirm
                            </Button>
                        </FooterItem>
                    </Footer>
                    <Close aria-label="Close modal" />
                </Modal>
            )}
        </>
    );
}

export default MonthlyVarianceModal;