import React, {useContext, useEffect, useState} from 'react';
import {Dropdown} from 'react-bootstrap';
import {GoTriangleDown} from 'react-icons/go';
import {CategoryDescriptor, useChartOfAccounts} from '../../contexts/chartofaccounts/ChartOfAccountsContext';
import {useProperties} from '../../contexts/properties/PropertiesContext';
import {useSettings} from '../../contexts/settings/SettingsContext';
import {useVersions} from '../../contexts/versions/VersionsContext';
import useGetMonths from '../../hooks/UseGetMonths';
import {
    BudgetCategory,
    BudgetComponentV2,
    useGetFinancialValuesLazyQuery,
    GetFinancialValuesQueryVariables,
    FinancialEntityType, FinancialValueQueryInput
} from '../../__generated__/generated_types';
import './FinancialMetrics.scss';
import {AuthContext} from "../../contexts/AuthContext";
import {isNaN} from "lodash";
import {IAccountSummaryData} from '../../pages/workflows/account/AccountSummaryContext';


interface MetricsData {
    firstlineValues: (number | null)[],
    secondlineValues: (number | null)[]
    firstlineTotal: (number | null),
    secondlineTotal: (number | null),
    variance: (number | null)[],
    varianceTotal: (number | null),
    varianceAmount: (number | null)[],
    varianceAmountTotal: (number | null)
}

function varianceInPercent(secondLine: number | null, varianceAmount: number | null): number | null {
    if (secondLine === null && varianceAmount === null) {
        return 0;
    }

    if (secondLine === null && varianceAmount !== null) {
        return null;
    }

    if (secondLine !== null && varianceAmount === null) {
        return 100;
    }

    return ((varianceAmount as number) / Math.abs((secondLine as number))) * 100;
}

function varianceAmount(firstLine?: number | null, secondLine?: number | null): number | null {
    if (firstLine === null && secondLine === null) {
        return null;
    }
    return (firstLine ?? 0) - (secondLine ?? 0);
}

function createEmptyMetricsData(): MetricsData {
    const ret: MetricsData = {
        firstlineValues: Array<number | null>(12).fill(null),
        secondlineValues: Array<number | null>(12).fill(null),
        firstlineTotal: null,
        secondlineTotal: null,
        variance: Array<number | null>(12).fill(null),
        varianceTotal: null,
        varianceAmount: Array<number | null>(12).fill(null),
        varianceAmountTotal: null
    };

    return ret;
}

const defaultDisplayData: MetricsData = createEmptyMetricsData();


export interface FinancialMetricsProps {
    parentCategories?: CategoryDescriptor[];
    //not sure this is the best way to do this. hack for now
    budgetComponentName?: string;
    year: number;
    // true means - show current year (props.year) actuals
    // false means - show next year budget
    showActuals: boolean;
    accountId: string;
    firstRowAdjustments?: number[]; // TODO: this is a hack for now to demo renovations
    tableOptions?: {
        className?: string;
        noWrap?: boolean;
    },
    tableHeadOptions?: {
        className?: string;
        bold?: boolean;
    }
    className?: string,
    financialYearValues?: IAccountSummaryData,
}

export type TypedBudgetComponent = {
    id: BudgetComponentV2["id"],
    budgetComponentType: BudgetComponentV2["budgetComponentType"],
    name: BudgetComponentV2["name"],
    __typename?: 'BudgetComponent'
}

export type TypedBudgetCategory = {
    name: BudgetCategory["name"],
    id: BudgetCategory["id"],
    __typename?: "BudgetCategory",
    parentCategory?: TypedBudgetCategory | null,
    budgetComponent?: TypedBudgetComponent | null
}

export const FinancialMetrics: React.FC<FinancialMetricsProps> = (props: FinancialMetricsProps) => {
    const {currentProperty: property} = useProperties();
    const [displayData, setDisplayData] = useState<MetricsData>(defaultDisplayData);
    const {year: reforecastYear, startReforecastMonthIndex} = useSettings();
    const months = useGetMonths();
    const {getModelVersions} = useVersions();
    const {user} = useContext(AuthContext);
    const {operationalFinancialMetricsAggregateId, getParentCategories} = useChartOfAccounts();

    ////////////////////////////////////////////////////////////////////////
    // positiveIsGood semantics: (according to MVP-221)
    //   Reforecast tab -

    // For items within the Income Component, the variance % should appear red
    // if reforecast value < budget value and green if reforecast value > budget value;
    // applicable to the negative amounts of any of values as in standard math (< > semantics)

    // For items within NOT Income Component (such as those within
    //  Expense Component or Components created by the Admin during set up),
    // the variance % should appear red if reforecast value > budget value and
    // green if reforecast value < budget value;
    // applicable to the negative amounts of any of values as in standard math (< > semantics)

    // Budget tab -

    // For items within the Income Component, the variance % should appear red
    // if Current Yr + 1 (e.g. 2022) value < Current Year Reforecast (e.g. 2021) value
    // and green if (Current Yr + 1) value > than Current Year Reforecast value.
    // applicable to the negative amounts of any of values as in standard math (< > semantics)

    // For items within NOT within Income Component (such as those within Expense
    // Component or Components created by the Admin during set up), the variance % should
    // appear red if (Current Year + 1)  value > Current Year Reforecast value and green
    // if (Current Year + 1)  value < Current Year Reforecast value;
    // applicable to the negative amounts of any of values as in standard math (< > semantics)

    // variance in amount = abs(first line - second line)
    // variance in % = abs(variance in amount / second line) * 100

    const [positiveIsGood, setPositiveIsGood] = useState(true);
    const [selectedAggregate, setSelectedAggregate] = useState(0);
    const [aggregates, setAggregates] = useState<CategoryDescriptor[]>([]);

    const [getFinancialMetrics, {loading, data}] = useGetFinancialValuesLazyQuery({
        fetchPolicy: 'network-only'
    });

    const {actualVersionId, reforecastVersionId, currentYearBudgetVersionId, nextYearBudgetVersionId} = getModelVersions(reforecastYear);

    const refetchFinancialMetrics = () => {
        const propertyId = property?.id;
        if (!aggregates || !propertyId) {
            return;
        }

        const types = aggregates.groupBy(a => a.type);
        const selectors: FinancialValueQueryInput[] = [];
        for (const entry of Object.entries(types)) {
            const descriptors = entry[1];
            if (descriptors[0]) {
                const aggregateType = descriptors[0].type;
                const descriptorIds = descriptors.map(d => d.id);
                // Actuals
                selectors.push({
                    entityIds: descriptorIds,
                    entityType: aggregateType,
                    monthIndexes: new Array(startReforecastMonthIndex + 1).fill(0).map((_, i) => i),
                    propertyIds: [propertyId],
                    versionIds: [actualVersionId]
                });
                // Reforecast
                selectors.push({
                    entityIds: descriptorIds,
                    entityType: aggregateType,
                    monthIndexes: new Array(12 - startReforecastMonthIndex).fill(0).map((_, i) => i + startReforecastMonthIndex),
                    propertyIds: [propertyId],
                    versionIds: [reforecastVersionId]
                });
                // Budget
                selectors.push({
                    entityIds: descriptorIds,
                    entityType: aggregateType,
                    monthIndexes: new Array(12).fill(0).map((_, i) => i),
                    propertyIds: [propertyId],
                    versionIds: [currentYearBudgetVersionId, nextYearBudgetVersionId]
                });
            }
        }

        const variables: GetFinancialValuesQueryVariables = {
            financialValueSelectors: selectors,
            financialTotalValueSelectorsProjections: [],
            financialTotalValueSelectorsHistorical: []
        };
        getFinancialMetrics({variables});
    };

    useEffect(() => {
        refetchFinancialMetrics();

        // financialYearValues being passed in indicates that we are tracking financial values, so only use interval if we aren't tracking values
        if (!props.financialYearValues) {
            const interval = setInterval(() => {
                refetchFinancialMetrics();
            }, 4000);
            return () => clearInterval(interval);
        }
    }, [aggregates, property, props.financialYearValues]);

    useEffect(() => {
        const componentAggregate = aggregates.find((aggregate) => aggregate.type === FinancialEntityType.Component);
        if (componentAggregate) {
            setPositiveIsGood(componentAggregate.budgetComponentType === 'INCOME');
        }
    }, [aggregates, selectedAggregate]);

    function loadAggregates() {
        if (props.parentCategories) {
            setAggregates(props.parentCategories);
        } else if (props.budgetComponentName) {
            const aggregates = getParentCategories(operationalFinancialMetricsAggregateId ?? "");
            setAggregates(aggregates);
        } else {
            throw Error("No aggreagates were found");
        }

    }

    function formatNumber(val: number | null): string {
        if (val !== undefined && val !== null) {
            return new Intl.NumberFormat().format(Math.round(val));
        }
        return '';
    }

    function handleSelect(eventKey: string) {
        const selected = parseInt(eventKey);
        setSelectedAggregate(isNaN(selected) ? 0 : selected);
    }

    function convertData(
        firstLineValues: number[],
        secondLineValues: number[],
        firstLineTotal: number,
        secondLineTotal: number,
    ) {
        const roundOrNull = (value: number | null) => {
            if (value !== null) {
                return (value * 10) / 10;
            }
            return null;
        };

        const newDisplayData = createEmptyMetricsData();
        newDisplayData.firstlineValues = firstLineValues.map(val => roundOrNull(val ?? null));
        newDisplayData.secondlineValues = secondLineValues.map(val => roundOrNull(val ?? null));

        for (let i = 0; i < 12; i++) {
            newDisplayData.varianceAmount[i] = roundOrNull(varianceAmount(
                newDisplayData.firstlineValues[i] ?? null,
                newDisplayData.secondlineValues[i] ?? null
            ));

            newDisplayData.variance[i] = roundOrNull(varianceInPercent(
                newDisplayData.secondlineValues[i] ?? null,
                newDisplayData.varianceAmount[i] ?? null
            ));
        }


        newDisplayData.firstlineTotal = roundOrNull(firstLineTotal ?? null);

        newDisplayData.secondlineTotal = roundOrNull(secondLineTotal ?? null);

        newDisplayData.varianceAmountTotal = roundOrNull(varianceAmount(
            newDisplayData.firstlineTotal,
            newDisplayData.secondlineTotal
        ));

        newDisplayData.varianceTotal = roundOrNull(varianceInPercent(
            newDisplayData.secondlineTotal ?? null,
            newDisplayData.varianceAmountTotal
        ));

        return newDisplayData;
    }

    useEffect(() => {
        loadAggregates();
    }, [props.parentCategories, property, selectedAggregate, operationalFinancialMetricsAggregateId]);

    useEffect(() => {
        if (!loading && data && aggregates) {
            const aggregateIdx = selectedAggregate ?? 0;
            const aggregate = aggregates[aggregateIdx];
            if (data.financialValues !== undefined && aggregate !== undefined) {
                const aggregateValues = data.financialValues.financialValues.filter(fv => fv.entityId === aggregate.id);
                const valuesByVersionId = aggregateValues.groupBy("versionId");
                const firstLineValues = [];
                for (let i = 0; i < 12; i++) {
                    const versionValues = props.showActuals ?
                        (i < startReforecastMonthIndex ?
                            valuesByVersionId[actualVersionId]
                            :
                            valuesByVersionId[reforecastVersionId]
                        )
                        : valuesByVersionId[nextYearBudgetVersionId];
                    firstLineValues[i] = versionValues?.find(row => row.monthIndex == i)?.value;
                }

                const secondLineValues = [];
                for (let i = 0; i < 12; i++) {
                    const versionValues = props.showActuals ?
                        valuesByVersionId[currentYearBudgetVersionId]
                        :
                        (i < startReforecastMonthIndex ?
                            valuesByVersionId[actualVersionId]
                            :
                            valuesByVersionId[reforecastVersionId]
                        );
                    secondLineValues[i] = versionValues?.find(row => row.monthIndex == i)?.value;
                }

                const firstLineTotal = firstLineValues.reduce((acc, val) => {
                    val = parseFloat(val);
                    if (isNaN(val)) {
                        val = 0;
                    }
                    return acc + val;
                }, 0);
                const secondLineTotal = secondLineValues.reduce((acc, val) => {
                    val = parseFloat(val);
                    if (isNaN(val)) {
                        val = 0;
                    }
                    return acc + val;
                }, 0);
                const newDisplayData = convertData(
                    firstLineValues,
                    secondLineValues,
                    firstLineTotal,
                    secondLineTotal
                );
                setDisplayData(newDisplayData);
            }
        }
    }, [data, loading, aggregates, selectedAggregate, props.showActuals]);


    return (
        <>
            {/* <div className="metrics-header d-flex justify-content-start" key='fin-metrics-tabs'>
                <div className='metrics financial-metrics fm-tab active'>
                    <AiOutlineDollarCircle
                        className="financial-metrics-icon"
                    /> Financial Metrics
                </div>
                MVP-385
                <div className='metrics fm-tab'>
                    <FiBarChart2
                        className="operational-metrics-icon"
                    /> Operational Metrics
                </div>
            </div> */}
            <div className={`table-wrapper w-100 ${props.tableOptions?.noWrap ? "" : " bg-white"} ${props.className ? props.className : ''}`} style={{overflowX: "auto"}}>
                <table className={"fm-table header-table " + props.tableOptions?.className} key='fin-metrics-table'>
                    <thead className="thead-container">
                        <tr className="fm-header-title">
                            <th className={"fm-th " + props.tableHeadOptions?.className} >
                                <Dropdown>
                                    <Dropdown.Toggle variant="fm-dropdown" className="btn-fm-dropdown">
                                        <div className="d-flex align-items-center">
                                            <div>{aggregates[selectedAggregate]?.name ?? 'no data'}</div>
                                            <GoTriangleDown className="ms-1" />
                                        </div>
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu>
                                        {aggregates.map((aggregate, index) => {
                                            return (
                                                <Dropdown.Item
                                                    className="fm-dropdown-items"
                                                    key={index}
                                                    eventKey={index}
                                                    active={selectedAggregate === index}
                                                    onSelect={(eventKey) => (eventKey !== null) && handleSelect(eventKey)}
                                                >
                                                    {aggregate.name}
                                                </Dropdown.Item>
                                            );
                                        })}
                                    </Dropdown.Menu>
                                </Dropdown>
                            </th>
                            {months.map(month => (
                                <th className={`fm-months ${!props.tableHeadOptions?.bold ? "fw-normal" : ""}`} key={month}> {month} </th>
                            ))}
                            <th className='fm-total'> Total </th>
                        </tr>
                    </thead>
                    <tbody className="fm-body">
                        <tr>
                            <td className='fm-td td-title' key="-1">{props.showActuals ? `${props.year} Reforecast` : `${props.year + 1} Budget`}</td>
                            {displayData.firstlineValues.map((val, index) => {
                                return (
                                    <td className='fm-td' key={index}>{formatNumber(val)}</td>
                                );
                            })}
                            <td className='fm-td key="12" fm-total'>{formatNumber(displayData.firstlineTotal)}</td>
                        </tr>
                        <tr>
                            <td className='fm-td td-title' key="-1">{props.showActuals ? `${props.year} Budget` : `${props.year} Reforecast`}</td>
                            {displayData.secondlineValues.map((val, index) => {
                                return (
                                    <td className='fm-td' key={index}>{formatNumber(val)}</td>
                                );
                            })}
                            <td className='fm-td' key="12">{formatNumber(displayData.secondlineTotal)}</td>
                        </tr>
                        <tr>
                            <td className='fm-td td-title' key="-1">Variance %</td>
                            {displayData.variance.map((val, index) => {
                                if (val) {

                                    return (
                                        <td
                                            className={`fm-td
                                                ${(((val ?? 0) > 0) && positiveIsGood || (val ?? 0) < 0 && !positiveIsGood) ?
                                                    'text-eucalyptus' : 'text-roman'}`
                                            }
                                            key={index}
                                        >
                                            {`${formatNumber(Math.abs(val))}%`}
                                        </td>
                                    );

                                }
                                else {

                                    return (
                                        <td
                                            className='fm-td text-eucalyptus'
                                            key={index}
                                        >
                                            n/a
                                        </td>
                                    );

                                }
                            })}
                            <td
                                className={`fm-td  key="12"
                                    ${(((displayData.varianceTotal ?? 0) > 0) && positiveIsGood || ((displayData.varianceTotal ?? 0 < 0) && !positiveIsGood)) ?
                                        'text-eucalyptus' : 'text-roman'}`
                                }
                            >
                                {displayData.varianceTotal ? `${formatNumber(Math.abs(displayData.varianceTotal))}%` : ''}
                            </td>
                        </tr>
                        <tr>
                            <td className='fm-td td-title' key="-1">Variance $</td>
                            {displayData.varianceAmount.map((val, index) => {

                                if (val !== null) {
                                    return (
                                        <td
                                            className={`fm-td
                                                ${(((val ?? 0) > 0) && positiveIsGood || (val ?? 0) < 0 && !positiveIsGood) ?
                                                    'text-eucalyptus' : 'text-roman'}`
                                            }
                                            key={index}
                                        >
                                            {`${formatNumber(Math.abs(val))}`}
                                        </td>
                                    );
                                }
                                else {
                                    return (
                                        <td
                                            className='fm-td text-eucalyptus'
                                            key={index}
                                        >
                                            0
                                        </td>
                                    );
                                }
                            })}
                            <td
                                className={`fm-td key="12"
                                    ${(((displayData.varianceAmountTotal ?? 0) > 0) && positiveIsGood || ((displayData.varianceAmountTotal ?? 0 < 0) && !positiveIsGood)) ?
                                        'text-eucalyptus' : 'text-roman'}`
                                }
                            >
                                {displayData.varianceAmountTotal ? `${formatNumber(displayData.varianceAmountTotal)}` : ''}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </>
    );
};
