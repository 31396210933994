import React, {ReactElement, useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import {BudgetingType} from '../../../../BudgetingType';
import {useProperties} from '../../../../contexts/properties/PropertiesContext';
import {useSettings} from '../../../../contexts/settings/SettingsContext';
import {UnitTypeScheduleValue, useGetForecastLocksLazyQuery, useGetMoveOutRatesQuery, VersionType} from '../../../../__generated__/generated_types';
import UnitTypeLevelMetricTable from '../UnitTypeLevelMetricTable';
import useOperationLoadingState from '../hooks/useOperationLoadingState';
import {WeightFn} from '../base';

import {ThemeProvider} from "styled-components";
import {ViziblyTheme} from "../../../../styles/zendesk-garden/ViziblyZDGTheme";

import {Tabs} from "@zendeskgarden/react-tabs";

import * as workflowCSS from "../../../../styles/workflows/workflowCSS.module.scss";
import * as workflowHeaderCSS from "../../../../components/workflows/workflow-header/styles/css.module.scss";

import WorkflowHeader from "../../../../components/workflows/workflow-header/WorkflowHeader";
import WorkflowPageLabel from "../../../../components/workflows/workflow-page-label/WorkflowPageLabel";
import WorkflowStepNav from "../../../../components/workflows/workflow-step-nav/WorkflowStepNav";

import WorkflowYearTabs, {
    WORKFLOW_YEAR_TAB_BUDGET
} from "../../../../components/workflows/workflow-year-tabs/WorkflowYearTabs";
import {IWorkflowPageProps, shouldUpdateWorkflowTab} from "../../logic/workflows";
import WorkflowNavToggle from "../../../../components/workflows/workflow-nav-toggle/WorkflowNavToggle";
import ShimmerRenderer, {ShimmerPageKey} from "../../../../components/shimmer/ShimmerRenderer";

export const weightFnMoveOutRate: WeightFn<"moveOutRate"> = (value) => {
    const val = value as UnitTypeScheduleValue;
    return val["leaseExpirationCount"] || 0;
};

export default function MoveOutRates(props:IWorkflowPageProps): ReactElement {
    const { type } = useParams() as { type: BudgetingType; propertyId: string };
    const { propertyId, loaded: propertyContextLoaded } = useProperties();
    const { year } = useSettings();

    const currentVersionYear = type == BudgetingType.BUDGET ? year + 1 : year;

    const [getForecastLocks, {data: dataForecastLocks, loading: lockDataLoading}] = useGetForecastLocksLazyQuery({
        fetchPolicy: "no-cache",
    });

    useEffect(() => {
        getForecastLocks({
            variables: {
                propertyId,
                budgetYear: year + 1,
            }
        });
    }, []);

    const budgetYear = year + 1;
    const data = useGetMoveOutRatesQuery({
        variables: {
            propertyId,
            versionType: type == BudgetingType.BUDGET ? VersionType.Budget : VersionType.Reforecast,
            budgetYear: budgetYear,
            versionYear: currentVersionYear,
            year
        },
        fetchPolicy: "network-only"
    });

    const { tableHasRendered } = useOperationLoadingState(data);

    const canRender = tableHasRendered && propertyContextLoaded && !lockDataLoading;

    /**
     * Update selected tab, which updates display, on type change
     */
    useEffect(
            () => {
                const updateWorkflowTab = shouldUpdateWorkflowTab(type, selectedTab);
                if(updateWorkflowTab != false){
                    setSelectedTab(updateWorkflowTab as string);
                }
            },
            [type]
    );

    const [selectedTab, setSelectedTab] = useState(WORKFLOW_YEAR_TAB_BUDGET);

    return (
            <ThemeProvider theme={ViziblyTheme}>
                {/* TODO: Update to <WorkflowLoadingOverlay loading={accountDataLoading}/> */}
                {!canRender ? <ShimmerRenderer modal={true} pageKey={ShimmerPageKey.ACCOUNT_VIEW}  /> : null}
                <div className={workflowCSS.workflowContainer}>
                    <WorkflowHeader>
                        <WorkflowHeader.LeftCol>
                            <div className={workflowHeaderCSS.rowItemsContainer}>
                                <WorkflowNavToggle/>
                                <WorkflowPageLabel label="Move Out Rates"/>
                            </div>
                        </WorkflowHeader.LeftCol>
                        <WorkflowHeader.RightCol>
                            <WorkflowStepNav onPreviousClick={props.onPreviousClick} onNextClick={props.onNextClick}/>
                        </WorkflowHeader.RightCol>
                    </WorkflowHeader>
                    <div className={workflowCSS.bodyContainer}>
                        <Tabs selectedItem={selectedTab} onChange={setSelectedTab} className={workflowCSS.accountYearTabNav}>
                            <WorkflowYearTabs year={year}
                                selectedTab={selectedTab}
                                rawForecastLocks={dataForecastLocks}
                            />

                            {/*
                                        TODO: Improve data service, load both tabs and financials at once.
                                        Then, update content and replace w/ Tab panels (e.g. workflows/account/Account.tsx)
                                        https://vizibly.atlassian.net/browse/APP-936
                                    */}
                            <div className={workflowCSS.tabContent}>
                                <div className={workflowCSS.dataColumn}>
                                    <div className={`${workflowCSS.accountTableContainer}`}>
                                        {(!lockDataLoading && dataForecastLocks?.forecastLocks?.property) &&
                                            <UnitTypeLevelMetricTable
                                                    key="move-out-rate"
                                                    currentVersionYear={currentVersionYear}
                                                    field="moveOutRate"
                                                    propertyId={propertyId}
                                                    canRender={canRender}
                                                    type={type}
                                                    data={data}
                                                    parseValue={value => parseFloat(value ?? "0").toString()}
                                                    formatPercentage={true}
                                                    parseFromRemote={(value: string | number) => typeof value === "string" ? parseFloat(value) : value}
                                                    weightFn={weightFnMoveOutRate}
                                                    aggregator="AVERAGE"
                                                    columnAggregator="WEIGHTED_AVERAGE"
                                                    readOnly={
                                                        type === BudgetingType.REFORECAST ? dataForecastLocks.forecastLocks.property.reforecastLocked : dataForecastLocks.forecastLocks.property.budgetLocked
                                                    }
                                            />
                                        }
                                    </div>
                                </div>
                            </div>
                        </Tabs>
                    </div>
                </div>
            </ThemeProvider>
    );
}

