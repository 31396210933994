import { GetMvrBudgetAssumptionsForPropertyAndAccountQuery } from "../../../../../../../../../__generated__/generated_types";
import { ModelingMethodType } from "../enums/ModelingMethodTypeEnum";
import { AssumptionsModel } from "../models/AssumptionsModel";
import { mapAccPercentageAssumptions } from "./mapAccPercentageAssumptions";
import { mapOperationalMetricAssumptions } from "./mapOperationalMetricAssumptions";
import { mapWorksheetLines } from "./mapWorksheetAssumptions";

export function mapRawDataToAssumptionsModel(rawData: GetMvrBudgetAssumptionsForPropertyAndAccountQuery, monthIndex: number): AssumptionsModel {
    const model: AssumptionsModel = {
        modelingMethodType: ModelingMethodType.MANUAL_ENTRY,
        assumptionRows: [],
        originalValue: null
    };

    const originalValue = rawData.singlePropertyAccount.originalValues[monthIndex];
    if (originalValue != undefined) {
        model.originalValue = originalValue;
    }

    const hasOverride = model.originalValue != null;

    const { modelingMethodType, hasMultipleModelingMethods } = resolveModelingMethodType(rawData, hasOverride);
    model.modelingMethodType = modelingMethodType;


    const worksheetLineRows = mapWorksheetLines(rawData.singlePropertyAccount.worksheetDriver, monthIndex, !hasMultipleModelingMethods);
    model.assumptionRows.push(...worksheetLineRows);
    const accPercentageRows = mapAccPercentageAssumptions(rawData.singlePropertyAccount.accountPercentageDriver, monthIndex);
    model.assumptionRows.push(...accPercentageRows);
    const operationalMetricRows = mapOperationalMetricAssumptions(rawData.singlePropertyAccount.operationalMetricDriver, monthIndex);
    model.assumptionRows.push(...operationalMetricRows);

    return model;
}

function resolveModelingMethodType(rawData: GetMvrBudgetAssumptionsForPropertyAndAccountQuery, hasOverride: boolean): {
    modelingMethodType: ModelingMethodType,
    hasMultipleModelingMethods: boolean
} {
    let modelingMethodType: ModelingMethodType = ModelingMethodType.MANUAL_ENTRY;
    let enabledModelingMethodsCount = 0;

    if (rawData.singlePropertyAccount.accountPercentageDriver?.isDriven) {
        modelingMethodType = ModelingMethodType.PCT_OF_ACCOUNT;
        enabledModelingMethodsCount++;
    }

    if (rawData.singlePropertyAccount.operationalMetricDriver?.isDriven) {
        modelingMethodType = ModelingMethodType.OP_DRIVER;
        enabledModelingMethodsCount++;
    }

    if (rawData.singlePropertyAccount.payrollDriver?.isDriven) {
        modelingMethodType = ModelingMethodType.PAYROLL;
        enabledModelingMethodsCount++;
    }

    if (rawData.singlePropertyAccount.renovationsDriver?.isDriven) {
        modelingMethodType = ModelingMethodType.RENOVATION_COSTS;
        enabledModelingMethodsCount++;
    }

    if (rawData.singlePropertyAccount.revenueDriver?.isDriven) {
        modelingMethodType = ModelingMethodType.REVENUE;
        enabledModelingMethodsCount++;
    }

    if (rawData.singlePropertyAccount.worksheetDriver?.isDriven) {
        modelingMethodType = ModelingMethodType.LINE_ITEMS;
        enabledModelingMethodsCount++;
    }

    if (enabledModelingMethodsCount > 1) {
        modelingMethodType = ModelingMethodType.MIXED_METHODS;
    }

    if (hasOverride) {
        modelingMethodType = ModelingMethodType.OVERRIDE;
    }

    return {
        modelingMethodType: modelingMethodType,
        hasMultipleModelingMethods: enabledModelingMethodsCount > 1
    };
}

