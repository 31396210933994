import * as Icons from "../icons/IconResources";
import { ButtonSpec, DEFAULT_BUTTON_H, DEFAULT_BUTTON_W, DEFAULT_ICON_H, DEFAULT_ICON_W, ToggleOpenIconSpec } from "../icons/CellDecoratorSpecs";

export function renderPlusMinusToggle(ctx: CanvasRenderingContext2D, x: number, y: number, isOpen:boolean):void{
    const icon = isOpen ? Icons.plusMinusOpen : Icons.plusMinusClosed;
    const iconW = isOpen ? Icons.plusMinusOpenW : Icons.plusMinusClosedW;
    const iconH = isOpen ? Icons.plusMinusOpenH : Icons.plusMinusClosedH;
    ctx.drawImage(icon, x, y, iconW, iconH);
}

export function renderPlusMinusLoading(ctx: CanvasRenderingContext2D, x: number, y: number):void{
    ctx.drawImage(Icons.plusMinusLoading, x, y, Icons.plusMinusLoadingW, Icons.plusMinusLoadingH);
}

export function renderChevronToggle(ctx: CanvasRenderingContext2D, x: number, y: number, isOpen:boolean):void{
    const icon = isOpen ? Icons.chevronOpen : Icons.chevronClosed;
    const iconW = isOpen ? Icons.chevronOpenW : Icons.chevronClosedW;
    const iconH = isOpen ? Icons.chevronOpenH : Icons.chevronClosedH;
    ctx.drawImage(icon, x, y, iconW, iconH);
}

export function renderSetupBtn(ctx: CanvasRenderingContext2D, x: number, y: number, width: number, height: number, hover: boolean):void{
    ctx.drawImage(hover ? Icons.setupButtonHover : Icons.setupButton, x, y, width, height);
}

/**
 * A generic icon renderer for icons that have a toggled state, and may have a hover state for each
 * @param ctx       CanvasRenderingContext2D The cell's canvas context
 * @param iconSpecs ToggleOpenIconSpecs Defines resources and dimensions for the states
 * @param isOpen    boolean true if the "open" state of the toggle should be showing
 * @param isHover   boolean true if the hovered state should be shown
 */
export function renderToggleIcon(ctx:CanvasRenderingContext2D, cellX:number, cellY:number, iconSpecs:ToggleOpenIconSpec, isOpen:boolean, isHover?:boolean):void{
    if(isOpen){
        if(!isHover){
            ctx.drawImage(
                iconSpecs.openIconImg,
                iconSpecs.openIconSpecs.x + cellX,
                iconSpecs.openIconSpecs.y + cellY,
                iconSpecs.openIconSpecs?.width ?? DEFAULT_ICON_W,
                iconSpecs.openIconSpecs?.height ?? DEFAULT_ICON_H
            );
        } else {
            ctx.drawImage(
                iconSpecs.openIconHoverImg as HTMLImageElement,
                (iconSpecs.openIconHoverSpecs?.x ?? 0) + cellX,
                (iconSpecs.openIconHoverSpecs?.y ?? 0) + cellY,
                iconSpecs.openIconHoverSpecs?.width ?? 0,
                iconSpecs.openIconHoverSpecs?.height ?? 0
            );
        }
    } else {
        if(!isHover){
            ctx.drawImage(
                iconSpecs.closedIconImg,
                iconSpecs.closedIconSpecs.x + cellX,
                iconSpecs.closedIconSpecs.y + cellY,
                iconSpecs.closedIconSpecs?.width ?? DEFAULT_ICON_W,
                iconSpecs.closedIconSpecs?.height ?? DEFAULT_ICON_H
            );
        } else {
            ctx.drawImage(
                iconSpecs.closedIconHoverImg as HTMLImageElement,
                (iconSpecs.closedIconHoverSpecs?.x ?? 0) + cellX,
                (iconSpecs.closedIconHoverSpecs?.y ?? 0) + cellY,
                iconSpecs.closedIconHoverSpecs?.width ?? 0,
                iconSpecs.closedIconHoverSpecs?.height ?? 0
            );
        }
    }
}

/**
 * A generic button renderer for buttons that appear in a cell, and may have a hover state for each
 * @param ctx       CanvasRenderingContext2D The cell's canvas context
 * @param buttonSpecs ToggleOpenIconSpecs Defines resources and dimensions for the states
 * @param isOpen    boolean true if the "open" state of the toggle should be showing
 * @param isHover   boolean true if the hovered state should be shown
 */
export function renderButton(ctx:CanvasRenderingContext2D, cellX:number, cellY:number, buttonSpecs:ButtonSpec, isHover?:boolean):void{
    if(!isHover){
        ctx.drawImage(
            buttonSpecs.buttonImg,
            buttonSpecs.x + cellX - (buttonSpecs.xPadOffset ?? 0),
            buttonSpecs.y + cellY,
            buttonSpecs.width ?? DEFAULT_BUTTON_W,
            buttonSpecs.height ?? DEFAULT_BUTTON_H,
        );
    } else {
        ctx.drawImage(
            buttonSpecs.buttonHoverImg,
            buttonSpecs.x + cellX - (buttonSpecs.xPadOffset ?? 0),
            buttonSpecs.y + cellY,
            buttonSpecs.width ?? DEFAULT_BUTTON_W,
            buttonSpecs.height ?? DEFAULT_BUTTON_H,
        );
    }
}

// TODO: Make "draw_expand_toggle" file less specific to the expand/collapse, instead make generic
