import { ReactElement, useState, useEffect } from "react";
import { Col, Grid, Row } from '@zendeskgarden/react-grid';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import * as css from "./styles/css.module.scss";
import { getVarianceColor, OverviewOption } from "./logic/overviewWidget";
import { ReactComponent as ChevronDownIcon } from '@zendeskgarden/svg-icons/src/16/chevron-down-stroke.svg';
import { Dropdown, Field, Menu, Item, Select } from '@zendeskgarden/react-dropdowns';
import { useOverviewData } from "./data/useOverviewData";
import { MONTHS } from "../../../../../../../constants/Months";
import { useChartOfAccounts } from "../../../../../../../contexts/chartofaccounts/ChartOfAccountsContext";
import { formatterDollarUSNoDecimal } from "../../../../../../../utils/fotmatters";
import { isPositiveGood } from "../../../../../../../utils/account";

type OverviewWidgetProps = {
    month: number,
    year: number,
    accountId: string,
}

export function OverviewWidget(props: OverviewWidgetProps): ReactElement {
    // TODO remove coa here when ispositivegood method is updated @smith
    const { chartOfAccountsFlat, isReady: isCoaReady, getFinancialEntityById } = useChartOfAccounts();
    const [_isPositiveGood, setIsPositiveGood] = useState<boolean>(true);
    const [overviewViewMode, setOverviewViewMode] = useState<OverviewOption>();

    useEffect(
        () => {
            if (!isCoaReady || chartOfAccountsFlat == undefined) return;

            const entityInfo = getFinancialEntityById(props.accountId);
            if (!entityInfo) {
                return;
            }
            const isPositiveGoodResult = isPositiveGood(entityInfo.budgetComponentType);

            if (isPositiveGoodResult == undefined) return;

            setIsPositiveGood(isPositiveGoodResult);
        },
        [isCoaReady, chartOfAccountsFlat]
    );

    const overviewMenuOptions: OverviewOption[] = [
        { label: `${MONTHS[props.month]} ${props.year}`, value: 'month' },
        { label: `YTD ${props.year} (Jan - ${MONTHS[props.month]})`, value: 'ytd' },
    ];

    useEffect(
        () => {
            setOverviewViewMode(overviewMenuOptions[0]);
        },
        []
    );

    const { overviewData, isOverviewDataReady } = useOverviewData({
        isPositiveGood: _isPositiveGood,
        accountId: props.accountId,
        month: props.month,
        year: props.year,
        viewMode: overviewViewMode?.value ?? 'month',
    });

    return (
        !isOverviewDataReady || !overviewData
            ? <></>
            : <div>
                <Grid>
                    <Row className={css.datePickerWrapper}>
                        <Col>
                            <Dropdown
                                selectedItem={overviewViewMode}
                                onSelect={setOverviewViewMode}
                                downshiftProps={{ itemToString: (item: OverviewOption) => item && item.label }}
                            >
                                <Field>
                                    <Select isCompact isBare>
                                        <span className={css.datePicker}>
                                            {overviewViewMode?.label} <ChevronDownIcon />
                                        </span>
                                    </Select>
                                </Field>
                                <Menu>
                                    {overviewMenuOptions.map(option => (
                                        <Item key={option.value} value={option}>
                                            {option.label}
                                        </Item>
                                    ))}
                                </Menu>
                            </Dropdown>
                        </Col>
                    </Row>
                    <Row justifyContent="center">
                        <Col size={4} textAlign="center">
                            <h5 className={css.title}>ACTUAL</h5>
                            <h5 className={`${css.value} ${getVarianceColor(overviewData?.variancePercent, _isPositiveGood)}`}>
                                {formatterDollarUSNoDecimal.format(overviewData.actualValue)}
                            </h5>
                            <h5 className={css.title}>VAR %</h5>
                            <h5 className={`${css.value} ${css.valueSmall} ${getVarianceColor(overviewData.variancePercent, _isPositiveGood)}`}>
                                {(Math.abs(overviewData.variancePercent * 100)).toFixed(2)}
                            </h5>
                        </Col>

                        <Col size={4} textAlign="center">
                            <h5 className={css.title}>BUDGET</h5>
                            <h5 className={`${css.value}`}>
                                {formatterDollarUSNoDecimal.format(overviewData.budgetValue)}
                            </h5>
                            <h5 className={css.title}>VAR $</h5>
                            <h5 className={`${css.value} ${css.valueSmall} ${getVarianceColor(overviewData.variancePercent, _isPositiveGood)}`}>
                                {formatterDollarUSNoDecimal.format(Math.abs(overviewData.varianceValue))}
                            </h5>
                        </Col>
                    </Row>
                </Grid>

                <HighchartsReact highcharts={Highcharts} options={overviewData.chartOptions} immutable={true} />
            </div>
    );
}