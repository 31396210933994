import { createContext, ReactElement, ReactNode, useContext, useEffect, useState } from "react";
import { useProperties } from "../../../../contexts/properties/PropertiesContext";
import { useRouteMatch } from "react-router-dom";
import { FinancialEntity } from "../../../../contexts/chartofaccounts/ChartOfAccountsContext";
import { ApolloError } from "@apollo/client";

import useMonthlyVarianceReportData, {
    IUseMonthlyVarianceReportDataReturn,
    IVarianceNoteUpdateArgs
} from "./data/useMonthlyVarianceReportData";
import { IFinancialEntityYear, MvrTableRow } from "./data/logic/monthlyVarianceReportData";
import { useSettings } from "../../../../contexts/settings/SettingsContext";

import { MonthlyVarianceReportContextConfig as CFG } from "./MonthlyVarianceReportContextConfig";
import { useVersions } from "../../../../contexts/versions/VersionsContext";
import { getDefaultMVRMonth } from "../../../../utils/variance";
import { ensureDateInLocale } from "../../../../utils/date-helpers";
import useAccountVarianceInsights, { IUseAccountVarianceInsightsReturn } from "./data/useAccountVarianceInsights";

export interface IMvrContextState {
    isMvrContextReady: boolean;
    isMvrDataLoaded: boolean;

    getUpdatedPath: (year: number, month: number) => string;

    setMvrReportYear?: (year: number) => void;
    mvrReportYear?: number;
    /**
     * Expects a 0-based representation of a month (0-11)
     * @param month
     */
    setMvrReportMonth?: (month: number) => void;

    maxReportYear: number;
    minReportYear: number;
    getValidReportYear: (year:number|string) => [number, boolean];
    getValidReportMonth: (month:number|string) => [number, boolean];

    mvrReportMonth?: number;

    mvrTableData?: MvrTableRow[];
    mvrEntityDataMap?: Record<string, Partial<IFinancialEntityYear>>;

    reportPropertyId?: string;
    mvrAccountsReady: boolean;
    mvrAccounts: FinancialEntity[];
    mvrDataErrors?: ApolloError[]|undefined;
    setVarianceNote: (updateArgs: IVarianceNoteUpdateArgs) => void;

    minVersionYear?: number;
    maxVersionYear?: number;

    mvrData:  IUseMonthlyVarianceReportDataReturn;
    accountVarianceInsights: IUseAccountVarianceInsightsReturn;
}

const useMvrContextState = (): IMvrContextState => {

    const routeMatch = useRouteMatch();
    const versions = useVersions();

    const { year:settingsYear, isDefaults:isSettingsLoading } = useSettings();

    const yearNow = new Date().getFullYear();

    // Note: min and max year bounds are based on year from useSettings context and is updated when isSettingsLoading is
    // no longer true. This temporarily sets the default range to the present moment.
    const [maxReportYear, setMaxReportYear] = useState<number>(yearNow + CFG.MVR_LOOK_FWD_YEARS);
    const [minReportYear, setMinReportYear] = useState<number>(yearNow - CFG.MVR_LOOK_BACK_YEARS);

    const [minVersionYear, setMinVersionYear] = useState<number>();
    const [maxVersionYear, setMaxVersionYear] = useState<number>();

    const [reportPropertyId, setReportPropertyId] = useState<string>();
    const { currentProperty, loaded: propertiesLoaded } = useProperties();
    useEffect(
            () => {
                if(!propertiesLoaded || !currentProperty){
                    return;
                }
                setReportPropertyId(currentProperty.id);
            },
            [propertiesLoaded, currentProperty]
    );

    useEffect(
            () => {
                if(!versions || !versions.versions || !versions.versions.valueVersions){
                    return;
                }
                const years = Array.from(new Set(versions.versions.valueVersions.map( v => v.year)));
                setMinVersionYear(Math.min(...years));
                setMaxVersionYear(Math.max(...years));
            },
            [versions, versions.versions]
    );

    const mvrData = useMonthlyVarianceReportData();
    const accountVarianceInsights = useAccountVarianceInsights();

    /**
     * Dependencies loaded
     */
    const [isMvrContextReady, setIsMvrContextReady] = useState<boolean>(false);
    useEffect(
            () => {
                if(!mvrData.mvrDataServiceReady || !reportPropertyId || isSettingsLoading || !minVersionYear || !maxVersionYear){
                    return;
                }

                if(!maxReportYear || !minReportYear){
                    setMaxReportYear(settingsYear + CFG.MVR_LOOK_FWD_YEARS);
                    setMinReportYear(settingsYear - CFG.MVR_LOOK_BACK_YEARS);
                } else {
                    // All dependencies are loaded, min and max year range established
                    setIsMvrContextReady(true);
                }

            },
            [mvrData.mvrDataServiceReady, reportPropertyId, isSettingsLoading, maxReportYear, minReportYear, minVersionYear, maxVersionYear]
    );

    // TODO: Move this to a logic file; write tests
    const getValidReportYear = (year:number|string|undefined):[number, boolean] => {
        let useYear:number;
        let parseOK = false;

        const yearNow = ensureDateInLocale(new Date()).getFullYear();
        const defaultYear = Math.min(Math.max(yearNow, settingsYear), settingsYear + 1);

        if(typeof year === 'string'){
            const parsedYear = parseInt(year);
            if(!isNaN(parsedYear)){
                useYear = parsedYear;
            } else {
                useYear = defaultYear;
            }
        }
        else if(year === undefined){
            useYear = defaultYear;
        }
        else {
            useYear = year;
        }

        if(useYear < minReportYear){
            useYear = minReportYear;
        }
        else if(useYear > maxReportYear){
            useYear = maxReportYear;
        }
        else {
            parseOK = true;
        }

        return [useYear, parseOK];
    };

    const getValidReportMonth = (monthIdx:string|number):[number, boolean] => {

        let useMonth:number;
        let parseOK = false;

        const parsedMonthStr = typeof monthIdx == 'string' ? parseInt(monthIdx) : undefined;

        if(parsedMonthStr != undefined && !isNaN(parsedMonthStr)){
            useMonth = parsedMonthStr;
        }
        else if(typeof monthIdx === 'number' && monthIdx >= 0 && monthIdx <= 11){
            useMonth = monthIdx;
            parseOK = true;
        }
        else {
            useMonth = getDefaultMVRMonth();
        }

        return [useMonth, parseOK];
    };

    const getUpdatedPath = (year: number, month: number): string => {
        return routeMatch.path
                .replace(':year?', year.toString())
                .replace(':month?', (month + 1).toString() ?? (getDefaultMVRMonth() + 1));
    };

    return {
        isMvrContextReady,
        isMvrDataLoaded: mvrData.mvrDataLoaded,

        mvrReportYear: mvrData.mvrReportYear,
        maxReportYear,
        minReportYear,
        getValidReportYear,
        getValidReportMonth,

        mvrReportMonth: mvrData.mvrReportMonth,

        mvrTableData: mvrData.mvrTableData,
        mvrEntityDataMap: mvrData.mvrEntityDataMap,

        getUpdatedPath,

        reportPropertyId,

        mvrAccountsReady: mvrData.mvrAccountsReady,
        mvrAccounts: mvrData.mvrAccounts,

        mvrDataErrors: mvrData.mvrDataErrors,

        setVarianceNote: mvrData.setVarianceNote,

        minVersionYear,
        maxVersionYear,

        mvrData,
        // TODO: Migrate to using mvrData instead of flattening its API
        // TODO: Remove redundant naming (e.g. mvrData.mvrReportYear becomes mvrData.reportYear)

        accountVarianceInsights,
    };
};

export const MonthlyVarianceReportContext = createContext<IMvrContextState|undefined>(undefined);

export function MonthlyVarianceReportProvider(props: { children: ReactNode|ReactNode[] }): ReactElement {

    return (<MonthlyVarianceReportContext.Provider value={{ ...useMvrContextState() }}>
        {props.children}
    </MonthlyVarianceReportContext.Provider>);
}

export function useMonthlyVarianceReportContext(): IMvrContextState {

    const context = useContext(MonthlyVarianceReportContext);

    if(context === undefined){
        throw new Error('useMvrContext must be used within a MonthlyVarianceReportProvider');
    }

    return context;
}
