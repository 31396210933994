import {RenovationSetupDrawerSheetEntityType} from "./types";

export enum RenovationSetupDrawerSheetRowColMapActionTypes {
    AddRenovationSetupDrawerRowColMapEntryAction = "AddRenovationSetupDrawerRowColMapEntryAction"
}

export type RenovationSetupDrawerRowColMapEntry = {
    idx: string, // row#col
    packageId: string,
    categoryId: string,
    entityType: RenovationSetupDrawerSheetEntityType,
    value: number | string | null,
}

type RenovationSetupDrawerInternalState = {
    rowColToEntityMap: Record<string, RenovationSetupDrawerRowColMapEntry>;
}

export interface RenovationSetupDrawerRowColMapAction {
    kind: RenovationSetupDrawerSheetRowColMapActionTypes;
}

export interface AddRenovationSetupDrawerRowColMapEntryAction extends RenovationSetupDrawerRowColMapAction {
    kind: RenovationSetupDrawerSheetRowColMapActionTypes.AddRenovationSetupDrawerRowColMapEntryAction;
    entries: RenovationSetupDrawerRowColMapEntry[];
}

export function renovationSetupDrawerSheetStateReducer(
    state: RenovationSetupDrawerInternalState,
    action: RenovationSetupDrawerRowColMapAction
): RenovationSetupDrawerInternalState {
    switch (action.kind) {
        case RenovationSetupDrawerSheetRowColMapActionTypes.AddRenovationSetupDrawerRowColMapEntryAction: {
            const act = action as AddRenovationSetupDrawerRowColMapEntryAction;
            const newState = {
                ...state,
                ...{
                    rowColToEntityMap: {
                        ...state.rowColToEntityMap,
                        ...act.entries.toIdMap("idx"),
                    },
                }
            };
            return newState;
        }
    }
    return state;
}

export type RenovationSetupDrawerSheetUpdates = {
    [idx: string]: {
        idx: string, // row#col
        packageId: string,
        categoryId: string,
        entityType: RenovationSetupDrawerSheetEntityType,
        newValue: number | string | null,
    }
}
