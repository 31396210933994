import React, {useContext, useEffect, useState} from "react";
import { useProperties } from "../properties/PropertiesContext";
import {AuthContext} from "../AuthContext";


interface Settings {
    year: number;
    startReforecastMonthIndex: number;
    fiscalYearStartMonthIndex: number;
    isDefaults: boolean;
    hasReports: boolean;
}

const SETTINGS_DEFAULT: Settings = {
    year: new Date().getFullYear(),
    startReforecastMonthIndex: new Date().getMonth() + 1,
    fiscalYearStartMonthIndex: 0,
    isDefaults: true,
    hasReports: false,
};

export const SettingsContext = React.createContext<Settings>(SETTINGS_DEFAULT);

export const SettingsProvider: React.FunctionComponent = ({ children }) => {
    const { user } = useContext(AuthContext);
    const { currentProperty } = useProperties();
    const [settings, setSettings] = useState<Settings>();

    useEffect(
        () => {
            const hasReports = user?.hasPowerBi ?? false;

            if (currentProperty) {
                setSettings({
                    year: currentProperty.reforecastYear,
                    fiscalYearStartMonthIndex: currentProperty.fiscalYearStartMonthIndex,
                    startReforecastMonthIndex: currentProperty.reforecastStartMonthIndex,
                    isDefaults: false,
                    hasReports: hasReports
                });
            }
            else { // property is not assigned to any model
                setSettings(undefined);
            }
        },
        [currentProperty, user]
    );

    return (
        <SettingsContext.Provider value={settings ?? SETTINGS_DEFAULT}>
            {children}
        </SettingsContext.Provider>
    );
};


export function useSettings(): Settings {
    const context = React.useContext(SettingsContext);

    if (context === undefined) {
        throw new Error('useSettings must be used within a SettingsProvider');
    }

    return context;
}