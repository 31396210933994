import lightningGreyIcon from "../icons/lightning-grey.svg";
import lightningHightlightIcon from "../icons/lightning-highlight.svg";

const lightningGrey = new Image();
lightningGrey.src = lightningGreyIcon;

const lightningHightlight = new Image();
lightningHightlight.src = lightningHightlightIcon;

export function renderLightning(ctx: CanvasRenderingContext2D, x: number, y: number, width: number, height: number, isHighlighted:boolean):void{
    const icon = isHighlighted ? lightningHightlight : lightningGrey;
    ctx.drawImage(icon, x + width - 24, y + height / 2 - 6, 12, 12);
}
