import React from 'react';
import styles from './styles.module.scss';

interface Props {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    children?: any;
    className?: string;
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
function Shimmer({ children, className }: Props) {
    return (
        <div className={className}>{children}</div>
    );
}

export default Shimmer;
