import GC from "@grapecity/spread-sheets";
import { drawTriangle, isInsideSquare } from "./icon-renderers/draw-triangle";
import { ICustomCellTargetHitInfo } from "./types";

export class OverrideModeCell extends GC.Spread.Sheets.CellTypes.Text {
    constructor(private isOverride = false) {
        super();
        this.typeName = "OverrideModeCell";
    }

    showPointer = false;
    isSaveTriggered = false;

    override paint(ctx: CanvasRenderingContext2D, value: any, x: number, y: number, w: number, h: number, style: GC.Spread.Sheets.Style, context?: any): void {
        if (this.isOverride) {
            style.backColor = "#F3E9EF"; // update background before letting parent class to use it
        }

        super.paint(ctx, value, x, y, w, h, style, context);

        if (this.showPointer) {
            const spread = context.sheet.getParent();
            let canvasEl: any = spread
                .getHost()
                .querySelector('[gcuielement="gcWorksheetCanvas"]');
            if (canvasEl && canvasEl.style.cursor === "default") {
                canvasEl.style.cursor = "pointer";
            }
        }
    }

    override paintContent(ctx: CanvasRenderingContext2D, value: any, x: number, y: number, w: number, h: number, style: GC.Spread.Sheets.Style, context?: any): void {
        super.paintContent(ctx, value, x, y, w, h, style, context);
        if (this.isOverride) {
            drawTriangle(ctx, x, y, w, h);
        }
    }

    override getHitInfo(x: number, y: number, _cellStyle: GC.Spread.Sheets.Style, cellRect: GC.Spread.Sheets.Rect, context?: any): GC.Spread.Sheets.IHitTestCellTypeHitInfo {
        const c = cellRect; // alias
        const isTriangleHit = this.isOverride && isInsideSquare(x, y, c.x, c.y, c.width, c.height);
        const [row, col] = [context.row as number, context.col as number];
        const info: ICustomCellTargetHitInfo = {
            x,
            y,
            row,
            col,
            cellRect,
            sheetArea: context.sheetArea,
            isReservedLocation: false,
            isTargetHit: isTriangleHit
        };
        return info;
    }

    override processMouseMove(hitInfo: GC.Spread.Sheets.IHitTestCellTypeHitInfo): boolean {
        const hi = hitInfo as ICustomCellTargetHitInfo;
        this.showPointer = hi.isTargetHit;
        return true;
    }

    override processMouseLeave(_hitInfo: GC.Spread.Sheets.IHitTestCellTypeHitInfo): boolean {
        this.showPointer = false;
        return true;
    }

    override createEditorElement(context?: any): HTMLElement {
        const div = super.createEditorElement(context);
        const parentStyleSheet = this.getParentStyleSheet();

        if (parentStyleSheet) {
            const ruleInfo = this.getRuleFromStyleSheet(parentStyleSheet);
            if (ruleInfo) {
                parentStyleSheet.deleteRule(ruleInfo.index);
            }
            if (this.isOverride) {
                parentStyleSheet.insertRule(".gcsj-func-color-text::selection {background-color: #E6D3DF; caret-color: #9C4D81}", parentStyleSheet.cssRules.length);
                div.style.background = "#F3E9EF";
            }
            else {
                parentStyleSheet.insertRule(".gcsj-func-color-text::selection {background-color: #F3E9EF; caret-color: #9C4D81}", parentStyleSheet.cssRules.length);
            }
        }
        return div;
    }

    override activateEditor(editorContext: HTMLElement, cellStyle: GC.Spread.Sheets.Style, cellRect: GC.Spread.Sheets.Rect, context?: any): void {
        super.activateEditor(editorContext, cellStyle, cellRect, context);
        const style = editorContext?.parentElement?.parentElement?.style;

        if (style) {
            style.border = "1px solid #9C4D81";
            style.width = "70px";
            style.height = "47px";
            if (this.isOverride) {
                style.backgroundColor = "#F3E9EF";
            }

        }
    }

    override isReservedKey(e: KeyboardEvent, context?: any): boolean {
        this.isSaveTriggered = this.isOverride == false && [GC.Spread.Commands.Key.enter, GC.Spread.Commands.Key.tab].includes(e.keyCode);
        const ret = super.isReservedKey(e, context);
        return ret;
    }

    override deactivateEditor(editorContext: HTMLElement, context?: any): void {
        super.deactivateEditor(editorContext, context);
        const parentStyleSheet = this.getParentStyleSheet();

        if (parentStyleSheet) {
            const ruleInfo = this.getRuleFromStyleSheet(parentStyleSheet);
            if (ruleInfo) {
                parentStyleSheet.deleteRule(ruleInfo.index);
            }
        }
    }

    getParentStyleSheet(cssSelector = ".marker-do-not-remove"): CSSStyleSheet | null {
        const styleSheets = document.styleSheets;
        for (let i = 0; i < styleSheets.length; i++) {
            try {
                const rules = styleSheets[i]?.cssRules;
                if (rules == undefined) {
                    continue;
                }
                for (let j = 0; j < (rules?.length ?? 0); j++) {
                    const rule = rules[j];

                    if (rule && rule.cssText.startsWith(cssSelector)) {
                        return rule.parentStyleSheet;
                    }
                }
            }
            catch (e) {
                // nop
            }
        }

        return null;
    }

    getRuleFromStyleSheet(parentStyleSheet: CSSStyleSheet, cssSelector = ".gcsj-func-color-text::selection"): { rule: CSSStyleRule, index: number } | undefined {
        try {
            const rules = parentStyleSheet.cssRules;
            if (rules == undefined) {
                return undefined;
            }
            for (let j = 0; j < (rules?.length ?? 0); j++) {
                const rule = rules[j];

                if (rule && rule.cssText.startsWith(cssSelector) && rule.type == rule.STYLE_RULE) {
                    return { rule: rule as CSSStyleRule, index: j };
                }
            }
        }
        catch (e) {
            // nop
        }

        return undefined;
    }

    getGCJSFuncColorTextRule(): { rule: CSSStyleRule, index: number } | undefined {
        const styleSheets = document.styleSheets;
        for (let i = 0; i < styleSheets.length; i++) {
            try {
                const rules = styleSheets[i]?.cssRules;
                if (rules == undefined) {
                    continue;
                }
                for (let j = 0; j < (rules?.length ?? 0); j++) {
                    const rule = rules[j];

                    if (rule && rule.cssText.startsWith(".gcsj-func-color-text::selection") && rule.type == rule.STYLE_RULE) {
                        return { rule: rule as CSSStyleRule, index: j };
                    }
                }
            }
            catch (e) {
                // nop
            }
        }

        return undefined;
    }
}
