const MinPasswordLength = 8;
const SpecialChars = new Set(["^","$","*",".","[","]","{","}","(",")","?","-","\"","!","@","#","%","&","/","\\",",",">","<","'",":",";","|","_","~","`","+","="]);
const Digits = new Set(["0", "1", "2", "3", "4", "5" , "6", "7", "8", "9"]);

export enum PasswordError {
    PASSWORD_LENGTH,
    MISSING_DIGIT,
    MISSING_SPECIAL,
    MISSING_UPPER ,
    MISSING_LOWER
}

export interface PasswordValidationResult {
    valid: boolean;
    errors: PasswordError[];
}

export function validatePassword(password: string): PasswordValidationResult {
    const chars = password.split("");
    let upperCount = 0;
    let lowerCount = 0;
    let specialCount = 0;
    let digitCount = 0;
    for(const ch of chars) {
        // Digit & Special Char checks need to come first.
        // No Alpha Chars are upper & lower case in JS :(
        if(Digits.has(ch)) {
            digitCount++;
        } else if(SpecialChars.has(ch)) {
            specialCount++;
        } else if(ch === ch.toUpperCase()) {
            upperCount++;
        } else if(ch === ch.toLowerCase()) {
            lowerCount++;
        }
    }

    const errors = [];
    if(password.length < MinPasswordLength) {
        errors.push(PasswordError.PASSWORD_LENGTH);
    }
    if(upperCount < 1) {
        errors.push(PasswordError.MISSING_UPPER);
    }
    if(lowerCount < 1) {
        errors.push(PasswordError.MISSING_LOWER);
    }
    if(specialCount < 1) {
        errors.push(PasswordError.MISSING_SPECIAL);
    }
    if(digitCount < 1) {
        errors.push(PasswordError.MISSING_DIGIT);
    }
    return {
        valid: errors.length === 0,
        errors: errors
    };
}