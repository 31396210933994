export const DRIVER_PILL_PADDING = 4;

// Driver Pill: Operational Driver _______________________
import operationalDriverImg from "../icons/DriverPills/driver_operational_85x20.svg";

const operationalDriverIcon = new Image();
operationalDriverIcon.src = operationalDriverImg;

export const OP_DRIVER_ICON_W = 85;
export const OP_DRIVER_ICON_H = 20;

export function renderOpDriverIcon(ctx: CanvasRenderingContext2D, x: number, y: number):void{
    ctx.drawImage(operationalDriverIcon, x, y, OP_DRIVER_ICON_W, OP_DRIVER_ICON_H);
}

// Driver Pill: Worksheet Driver _______________________
import worksheetDriverImg from "../icons/DriverPills/driver_worksheet_80x20.svg";

const worksheetDriverIcon = new Image();
worksheetDriverIcon.src = worksheetDriverImg;

export const WORKSHEET_DRIVER_ICON_W = 80;
export const WORKSHEET_DRIVER_ICON_H = 20;

export function renderWorksheetDriverIcon(ctx: CanvasRenderingContext2D, x: number, y: number):void{
    ctx.drawImage(worksheetDriverIcon, x, y, WORKSHEET_DRIVER_ICON_W, WORKSHEET_DRIVER_ICON_H);
}

// Driver Pill: Percentage of Income Driver _______________________
import percentDriverImg from "../icons/DriverPills/driver_percent_of_income_93x20.svg";

const percentDriverIcon = new Image();
percentDriverIcon.src = percentDriverImg;

export const PERCENT_DRIVER_ICON_W = 93;
export const PERCENT_DRIVER_ICON_H = 20;

export function renderPercentDriverIcon(ctx: CanvasRenderingContext2D, x: number, y: number):void{
    ctx.drawImage(percentDriverIcon, x, y, PERCENT_DRIVER_ICON_W, PERCENT_DRIVER_ICON_H);
}

// Driver Pill: Revenue Driver _______________________
import revenueDriverImg from "../icons/DriverPills/driver_revenue_67x20.svg";

const revenueDriverIcon = new Image();
revenueDriverIcon.src = revenueDriverImg;

export const REVENUE_DRIVER_ICON_W = 67;
export const REVENUE_DRIVER_ICON_H = 20;

export function renderRevenueDriverIcon(ctx: CanvasRenderingContext2D, x: number, y: number):void{
    ctx.drawImage(revenueDriverIcon, x, y, REVENUE_DRIVER_ICON_W, REVENUE_DRIVER_ICON_H);
}

// Gradient Overlay _______________________
import gradientOverlayImg from "../icons/DriverPills/gradient_overlay_56x35.svg";

const gradientOverlay = new Image();
gradientOverlay.src = gradientOverlayImg;

export const GRADIENT_OVERLAY_W = 56;
export const GRADIENT_OVERLAY_H = 35;

export function renderGradientOverlay(ctx: CanvasRenderingContext2D, x: number, y: number):void{
    ctx.drawImage(gradientOverlay, x, y, GRADIENT_OVERLAY_W, GRADIENT_OVERLAY_H);
}
