import {
    BudgetComponentType,
    FinancialEntityType,
    GetAccountVarianceInsightsQuery
} from "../../../../../../../../__generated__/generated_types";
import { IAnnualBudgetRemaining } from "../components/AnnualBudgetRemaining";

export function parseAccountVarianceInsights(data: GetAccountVarianceInsightsQuery): IAccountVarianceInsights {
    const { account, insights } = data.getAccountVarianceInsights;

    const previousMonth = insights.trends.previousMonth ? {
        year: insights.trends.previousMonth.year,
        monthIndex: insights.trends.previousMonth.monthIndex,
        varianceAmount: insights.trends.previousMonth.varianceAmount,
        variancePercentage: insights.trends.previousMonth.variancePercentage,
        value: insights.trends.previousMonth.value,
    } : null;

    const previousYear = insights.trends.previousYear ? {
        year: insights.trends.previousYear.year,
        monthIndex: insights.trends.previousYear.monthIndex,
        varianceAmount: insights.trends.previousYear.varianceAmount,
        variancePercentage: insights.trends.previousYear.variancePercentage,
        value: insights.trends.previousYear.value,
    } : null;

    return {
        account: {
            budgetComponentType: account.budgetComponentType,
            negate: account.negate,
        },
        insights: {
            trends: {
                currentValue: insights.trends.currentValue,
                previousMonth,
                previousYear
            },
            glContributions: insights.glContributions.map(
                contribution => ({
                    id: contribution.id,
                    name: contribution.name,
                    glNumber: contribution.glNumber,
                    type: contribution.type,
                    percentContributed: contribution.percentContributed,
                    parentTotal: contribution.parentTotal,
                })
            ),
            annualBudgetRemaining: {
                ...insights.annualBudgetRemaining
            },
            topVendors: insights.topVendors.map(
                vendor => ({
                    name: vendor.vendorName,
                    amount: vendor.amount,
                })
            ),
        }
    };
}

export interface IAccountVarianceInsights {
    account: IVarianceAccount,
    insights: IInsights,
}

export interface IVarianceAccount {
    budgetComponentType: BudgetComponentType,
    negate: boolean,
}

export interface IInsights {
    trends: IAccountVarianceTrends,
    glContributions: IGlContributions,
    annualBudgetRemaining: IAnnualBudgetRemaining,
    topVendors: IAccountVendor[],
}

export interface IAccountVarianceTrends {
    currentValue: number
    previousMonth: IVarianceMetric|null,
    previousYear: IVarianceMetric|null,
}

export interface IVarianceMetric {
    year: number,
    monthIndex: number,
    varianceAmount: number,
    variancePercentage: number,
    value: number,
}

export interface IAccountVendor {
    name: string,
    amount: number,
}

export type IGlContributions = IGlContribution[];

export interface IGlContribution {
    id: string,
    name: string,
    glNumber: string|undefined|null,
    type: FinancialEntityType,
    percentContributed: number,
    parentTotal: number,
}
