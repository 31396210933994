export interface ForgotPasswordProps {
    // cancelled?: () => void;
    needSignIn?: () => void;
    flow: PasswordFlow;

    // needVerifyAccount?: () => void
    signedIn?: () => void;
    toLogin?: () => void;
}

export interface PasswordFormPartProps extends ForgotPasswordProps {
    username: string;
    fullName: string;
}

export enum PasswordFlow {
    RESET = "RESET",
    CREATE = "CREATE"
}

export interface OTPInputProps {
    username: string;
    codeError?: ResetPasswordError;
    setCode: (code: string) => void;
}

export enum ResetPasswordErrorType {
    CODE_PROBLEM = "CODE_PROBLEM",
    CODE_PROBLEM_TOO_MANY = "CODE_PROBLEM_TOO_MANY",
    PASSWORD_PROBLEM = "PASSWORD_PROBLEM"
}

export type ResetPasswordError = {
    type: ResetPasswordErrorType;
    message: string;
}

export function getResetPasswordTypeFromAWSErrorCode(awsErrorCode: string): ResetPasswordErrorType {
    if (["LimitExceededException"].includes(awsErrorCode)) {
        return ResetPasswordErrorType.CODE_PROBLEM_TOO_MANY; // TODO: for now. need to: 1) have specific place on page to show non-code errors, 2) recognize different error types into code vs password problem, 3) may be introduce another generic type
    }
    // TODO: yes PASSWORD_PROBLEM not returned - see above
    return ResetPasswordErrorType.CODE_PROBLEM;
}