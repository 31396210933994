import GC from '@grapecity/spread-sheets';
import { COLORS } from "../../../constants/Colors";

import ActualsHeaderCellBG from '../../../assets/images/sjs_cell_bg_actuals_header.png';
import ReforecastHeaderCellBG from '../../../assets/images/sjs_cell_bg_reforecast_header.png';
import TotalsHeaderCellBG from '../../../assets/images/sjs_cell_bg_totals_header.png';
import HorizontalAlign = GC.Spread.Sheets.HorizontalAlign;
import VerticalAlign = GC.Spread.Sheets.VerticalAlign;

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace SJSSheetStyles {

    // Header Component Colors ______________________________________________________________
    export const ACTUAL_TOP_BORDER_COLOR = COLORS.PRIMARY_150;
    export const REFORECAST_TOP_BORDER_COLOR = COLORS.PRIMARY_400;

    export const HEADER_TEXT_COLOR_1 = COLORS.GREY_500;
    export const HEADER_BACK_COLOR_1 = COLORS.GREY_100;

    // Cell Component Colors & Fonts ________________________________________________________
    export const BORDER_COLOR = COLORS.GREY_200;
    export const VERTICAL_DIVIDER_COLOR = COLORS.GREY_300;
    export const HOVER_BORDER_COLOR = COLORS.PRIMARY_150;

    export const SELECTED_STATIC_BORDER_COLOR = COLORS.GREY_300;
    export const SELECTED_EDITABLE_BORDER_COLOR = COLORS.PRIMARY_400;

    export const DATA_DEFAULT_COLOR = COLORS.PRIMARY_800;
    export const DATA_DEFAULT_FONT = '500 12px Inter';

    export const COMP_SUMMARY_BG_COLOR = COLORS.GREY_200;
    export const CTGY_SUMMARY_BG_COLOR = COLORS.GREY_100;
    export const SUMMARY_FORE_COLOR = COLORS.GREY_500;

    // Layout Specs
    export const DATA_ROW_HEIGHT = 40;
    export const WORKSHEET_ROW_HEIGHT = 48;

    // Border Styles ________________________________________________________________________
    export const CELL_BORDER = new GC.Spread.Sheets.LineBorder(
        BORDER_COLOR,
        GC.Spread.Sheets.LineStyle.thin
    );
    export const HOVER_CELL_BORDER = new GC.Spread.Sheets.LineBorder(
        HOVER_BORDER_COLOR,
        GC.Spread.Sheets.LineStyle.medium
    );
    export const HOVER_CELL_BORDER_BLUE = new GC.Spread.Sheets.LineBorder(
        COLORS.ROYAL_300,
        GC.Spread.Sheets.LineStyle.medium
    );

    export const MEDIUM_VERTICAL_DIVIDER = new GC.Spread.Sheets.LineBorder(
        VERTICAL_DIVIDER_COLOR,
        GC.Spread.Sheets.LineStyle.medium
    );
    export const ACTUAL_HEADER_TOP_BORDER = new GC.Spread.Sheets.LineBorder(
        ACTUAL_TOP_BORDER_COLOR,
        GC.Spread.Sheets.LineStyle.thick
    );
    export const REFORECAST_HEADER_TOP_BORDER = new GC.Spread.Sheets.LineBorder(
        REFORECAST_TOP_BORDER_COLOR,
        GC.Spread.Sheets.LineStyle.medium
    );
    export const NO_CELL_BORDER = new GC.Spread.Sheets.LineBorder(
        REFORECAST_TOP_BORDER_COLOR,
        GC.Spread.Sheets.LineStyle.empty
    );
    export const TOTAL_DATA_BOTTOM_BORDER = new GC.Spread.Sheets.LineBorder(
        VERTICAL_DIVIDER_COLOR,
        GC.Spread.Sheets.LineStyle.thin
    );
    export const SUMMARY_BORDER = new GC.Spread.Sheets.LineBorder(
        COLORS.GREY_300,
        GC.Spread.Sheets.LineStyle.thin,
    );

    // Cell Styles __________________________________________________________________________
    export const BASE = new GC.Spread.Sheets.Style();
    BASE.borderBottom = CELL_BORDER;
    BASE.borderRight = CELL_BORDER;
    BASE.font = DATA_DEFAULT_FONT;
    BASE.foreColor = DATA_DEFAULT_COLOR;
    BASE.vAlign = VerticalAlign.center;
    BASE.wordWrap = true;
    BASE.locked = true;

    // Header Cell Styles ___________________________________________________________________
    export const DATA_HEADER = BASE.clone();
    DATA_HEADER.cellPadding = '8';
    DATA_HEADER.font = '700 12px Inter';
    DATA_HEADER.foreColor = HEADER_TEXT_COLOR_1;
    DATA_HEADER.vAlign = VerticalAlign.bottom;
    DATA_HEADER.hAlign = HorizontalAlign.right;
    DATA_HEADER.borderRight = NO_CELL_BORDER;

    export const ACTUAL_DATA_HEADER = DATA_HEADER.clone();
    ACTUAL_DATA_HEADER.borderTop = ACTUAL_HEADER_TOP_BORDER;

    export const LAST_ACTUAL_DATA_HEADER = ACTUAL_DATA_HEADER.clone();
    LAST_ACTUAL_DATA_HEADER.borderRight = MEDIUM_VERTICAL_DIVIDER;

    export const REFORECAST_DATA_HEADER = DATA_HEADER.clone();
    REFORECAST_DATA_HEADER.borderTop = REFORECAST_HEADER_TOP_BORDER;
    REFORECAST_DATA_HEADER.backColor = COLORS.PRIMARY_50;

    export const LAST_REFORECAST_DATA_HEADER = REFORECAST_DATA_HEADER.clone();
    LAST_REFORECAST_DATA_HEADER.borderRight = MEDIUM_VERTICAL_DIVIDER;

    export const LAST_DATA_HEADER = DATA_HEADER.clone();
    LAST_DATA_HEADER.borderRight = MEDIUM_VERTICAL_DIVIDER;

    // Year Header Styles
    export const ENTITY_COL_LABEL = BASE.clone();
    ENTITY_COL_LABEL.name = 'SJSS.ENTITY_COL_LABEL';
    ENTITY_COL_LABEL.cellPadding = '20 16 16 20';
    ENTITY_COL_LABEL.font = '600 13px Inter';
    ENTITY_COL_LABEL.foreColor = COLORS.GREY_800;
    ENTITY_COL_LABEL.vAlign = VerticalAlign.center;
    ENTITY_COL_LABEL.hAlign = HorizontalAlign.left;
    ENTITY_COL_LABEL.locked = true;

    export const YEAR_HEADER_BASE = new GC.Spread.Sheets.Style();
    YEAR_HEADER_BASE.cellPadding = '0 5 0 0';
    YEAR_HEADER_BASE.foreColor = COLORS.GREY_500;
    YEAR_HEADER_BASE.font = '400 10px Inter';
    YEAR_HEADER_BASE.vAlign = VerticalAlign.bottom;
    YEAR_HEADER_BASE.hAlign = HorizontalAlign.right;
    YEAR_HEADER_BASE.locked = true;

    export const YEAR_ACTUAL_HEADER_ROW_1 = YEAR_HEADER_BASE.clone();
    YEAR_ACTUAL_HEADER_ROW_1.name = 'SJSS.YEAR_ACTUAL_HEADER_ROW_1';
    YEAR_ACTUAL_HEADER_ROW_1.backgroundImage = ActualsHeaderCellBG;

    export const YEAR_LAST_ACTUAL_HEADER_ROW_1 = YEAR_ACTUAL_HEADER_ROW_1.clone();
    YEAR_LAST_ACTUAL_HEADER_ROW_1.name = 'SJSS.YEAR_LAST_ACTUAL_HEADER_ROW_1';
    YEAR_LAST_ACTUAL_HEADER_ROW_1.borderRight = MEDIUM_VERTICAL_DIVIDER;

    export const YEAR_ACTUAL_HEADER_ROW_2 = YEAR_HEADER_BASE.clone();
    YEAR_ACTUAL_HEADER_ROW_2.name = 'SJSS.YEAR_ACTUAL_HEADER_ROW_2';
    YEAR_ACTUAL_HEADER_ROW_2.font = '500 12px Inter';
    YEAR_ACTUAL_HEADER_ROW_2.vAlign = VerticalAlign.top;

    export const YEAR_LAST_ACTUAL_HEADER_ROW_2 = YEAR_ACTUAL_HEADER_ROW_2.clone();
    YEAR_LAST_ACTUAL_HEADER_ROW_2.name = 'SJSS.YEAR_LAST_ACTUAL_HEADER_ROW_2';
    YEAR_LAST_ACTUAL_HEADER_ROW_2.borderRight = MEDIUM_VERTICAL_DIVIDER;

    export const YEAR_ACTUAL_HEADER_ROW_3 = YEAR_HEADER_BASE.clone();
    YEAR_ACTUAL_HEADER_ROW_3.name = 'SJSS.YEAR_ACTUAL_HEADER_ROW_3';
    YEAR_ACTUAL_HEADER_ROW_3.cellPadding = '0 5 6 0';
    YEAR_ACTUAL_HEADER_ROW_3.font = '600 12px Inter';
    YEAR_ACTUAL_HEADER_ROW_3.foreColor = COLORS.GREY_800;
    YEAR_ACTUAL_HEADER_ROW_3.vAlign = VerticalAlign.center;

    export const YEAR_LAST_ACTUAL_HEADER_ROW_3 = YEAR_ACTUAL_HEADER_ROW_3.clone();
    YEAR_LAST_ACTUAL_HEADER_ROW_3.name = 'SJSS.YEAR_LAST_ACTUAL_HEADER_ROW_3';
    YEAR_LAST_ACTUAL_HEADER_ROW_3.borderRight = MEDIUM_VERTICAL_DIVIDER;

    export const YEAR_REFORECAST_HEADER_ROW_1 = YEAR_ACTUAL_HEADER_ROW_1.clone();
    YEAR_REFORECAST_HEADER_ROW_1.name = "SJSS.YEAR_REFORECAST_HEADER_ROW_1";
    YEAR_REFORECAST_HEADER_ROW_1.backgroundImage = ReforecastHeaderCellBG;

    export const YEAR_REFORECAST_HEADER_ROW_2 = YEAR_ACTUAL_HEADER_ROW_2.clone();
    YEAR_REFORECAST_HEADER_ROW_2.name = "SJSS.YEAR_REFORECAST_HEADER_ROW_2";
    YEAR_REFORECAST_HEADER_ROW_2.backColor = COLORS.PRIMARY_50;

    export const YEAR_REFORECAST_HEADER_ROW_3 = YEAR_ACTUAL_HEADER_ROW_3.clone();
    YEAR_REFORECAST_HEADER_ROW_3.name = "SJSS.YEAR_REFORECAST_HEADER_ROW_3";
    YEAR_REFORECAST_HEADER_ROW_3.backColor = COLORS.PRIMARY_50;

    export const YEAR_TOTALS_HEADER_ROW_1 = YEAR_ACTUAL_HEADER_ROW_1.clone();
    YEAR_TOTALS_HEADER_ROW_1.name = 'SJSS.YEAR_TOTALS_HEADER_ROW_1';
    YEAR_TOTALS_HEADER_ROW_1.backgroundImage = TotalsHeaderCellBG;
    YEAR_TOTALS_HEADER_ROW_1.borderRight = MEDIUM_VERTICAL_DIVIDER;

    export const YEAR_TOTALS_HEADER_ROW_2 = YEAR_ACTUAL_HEADER_ROW_2.clone();
    YEAR_TOTALS_HEADER_ROW_2.name = 'SJSS.YEAR_TOTALS_HEADER_ROW_2';
    YEAR_TOTALS_HEADER_ROW_2.backColor = COLORS.GREY_75;
    YEAR_TOTALS_HEADER_ROW_2.borderRight = MEDIUM_VERTICAL_DIVIDER;

    export const YEAR_TOTALS_HEADER_ROW_3 = YEAR_ACTUAL_HEADER_ROW_3.clone();
    YEAR_TOTALS_HEADER_ROW_3.name = 'SJSS.YEAR_TOTALS_HEADER_ROW_3';
    YEAR_TOTALS_HEADER_ROW_3.backColor = COLORS.GREY_75;
    YEAR_TOTALS_HEADER_ROW_3.borderRight = MEDIUM_VERTICAL_DIVIDER;

    // Data Cell Styles _____________________________________________________________________
    export const EDITABLE_CELL = BASE.clone();
    EDITABLE_CELL.borderBottom = HOVER_CELL_BORDER;
    EDITABLE_CELL.cellPadding = '6px';
    EDITABLE_CELL.font = '400 12px Inter';
    EDITABLE_CELL.foreColor = COLORS.PRIMARY_500;
    EDITABLE_CELL.formatter = '#,#';
    EDITABLE_CELL.hAlign = HorizontalAlign.right;
    EDITABLE_CELL.vAlign = VerticalAlign.center;
    EDITABLE_CELL.locked = false;

    export const REFORECAST_DRIVER_CELL = EDITABLE_CELL.clone();
    REFORECAST_DRIVER_CELL.name = "SJSS.REFORECAST_DRIVER_CELL";
    REFORECAST_DRIVER_CELL.locked = true;

    export const BUDGET_DRIVER_CELL = EDITABLE_CELL.clone();
    BUDGET_DRIVER_CELL.name = "SJSS.BUDGET_DRIVER_CELL";
    BUDGET_DRIVER_CELL.locked = true;

    export const EDITABLE_CELL_BLUE = EDITABLE_CELL.clone();
    EDITABLE_CELL_BLUE.name = "SJSS.EDITABLE_CELL_BLUE";
    EDITABLE_CELL_BLUE.borderBottom = HOVER_CELL_BORDER_BLUE;
    EDITABLE_CELL_BLUE.foreColor = COLORS.ROYAL_300;

    export const EDITABLE_PERCENT = EDITABLE_CELL.clone();
    EDITABLE_PERCENT.formatter = '0.0%';

    export const DATA_CELL_HOVER = EDITABLE_CELL.clone();

    export const LAST_DATA_CELL = EDITABLE_CELL.clone();
    LAST_DATA_CELL.borderRight = MEDIUM_VERTICAL_DIVIDER;

    export const ACTUAL_CELL = EDITABLE_CELL.clone();
    ACTUAL_CELL.name = "SJSS.ACTUAL_CELL";
    ACTUAL_CELL.foreColor = "#667085";
    ACTUAL_CELL.locked = true;
    ACTUAL_CELL.borderBottom = CELL_BORDER;
    ACTUAL_CELL.borderRight = CELL_BORDER;

    export const LAST_ACTUAL_CELL = ACTUAL_CELL.clone();
    LAST_ACTUAL_CELL.name = "SJSS.LAST_ACTUAL_CELL";
    LAST_ACTUAL_CELL.borderRight = MEDIUM_VERTICAL_DIVIDER;

    export const ACTUAL_CELL_GREY = EDITABLE_CELL.clone();
    ACTUAL_CELL_GREY.foreColor = COLORS.GREY_400;
    ACTUAL_CELL_GREY.borderBottom = CELL_BORDER;

    export const LAST_ACTUAL_GREY_CELL = ACTUAL_CELL_GREY.clone();
    LAST_ACTUAL_GREY_CELL.foreColor = COLORS.GREY_400;
    LAST_ACTUAL_GREY_CELL.borderRight = MEDIUM_VERTICAL_DIVIDER;

    export const ADMIN_LOCKED_CELL = EDITABLE_CELL.clone();

    export const DATA_LABEL = EDITABLE_CELL.clone();
    DATA_LABEL.textIndent = 2;
    DATA_LABEL.foreColor = COLORS.PRIMARY_400;

    export const FINANCIAL_ENTITY_LABEL = new GC.Spread.Sheets.Style();
    FINANCIAL_ENTITY_LABEL.foreColor = '#2F3941';
    FINANCIAL_ENTITY_LABEL.font = '500 12px Inter';
    FINANCIAL_ENTITY_LABEL.vAlign = VerticalAlign.center;
    FINANCIAL_ENTITY_LABEL.cellPadding = '8 8 8 26';
    FINANCIAL_ENTITY_LABEL.borderTop = CELL_BORDER;
    FINANCIAL_ENTITY_LABEL.borderBottom = CELL_BORDER;
    FINANCIAL_ENTITY_LABEL.wordWrap = true;

    export const FINANCIAL_ENTITY_LABEL_LVL1 = FINANCIAL_ENTITY_LABEL.clone();
    FINANCIAL_ENTITY_LABEL_LVL1.cellPadding = '8 8 8 34';

    export const FINANCIAL_ENTITY_LABEL_LVL2 = FINANCIAL_ENTITY_LABEL.clone();
    FINANCIAL_ENTITY_LABEL_LVL2.cellPadding = '8 8 8 42';

    export const FINANCIAL_ENTITY_LABEL_LVL3 = FINANCIAL_ENTITY_LABEL.clone();
    FINANCIAL_ENTITY_LABEL_LVL3.cellPadding = '8 8 8 50';

    export const FINANCIAL_ENTITY_LABEL_LVL4 = FINANCIAL_ENTITY_LABEL.clone();
    FINANCIAL_ENTITY_LABEL_LVL4.cellPadding = '8 8 8 58';

    export const PROPERTY_LABEL = FINANCIAL_ENTITY_LABEL.clone();
    PROPERTY_LABEL.foreColor = COLORS.PRIMARY_300;

    export const PROPERTY_LABEL_LVL1 = FINANCIAL_ENTITY_LABEL_LVL1.clone();
    PROPERTY_LABEL_LVL1.foreColor = COLORS.PRIMARY_300;

    export const PROPERTY_LABEL_LVL2 = FINANCIAL_ENTITY_LABEL_LVL2.clone();
    PROPERTY_LABEL_LVL2.foreColor = COLORS.PRIMARY_300;

    export const PROPERTY_LABEL_LVL3 = FINANCIAL_ENTITY_LABEL_LVL3.clone();
    PROPERTY_LABEL_LVL3.foreColor = COLORS.PRIMARY_300;

    export const PROPERTY_LABEL_LVL4 = FINANCIAL_ENTITY_LABEL_LVL4.clone();
    PROPERTY_LABEL_LVL4.foreColor = COLORS.PRIMARY_300;

    export const ADMIN_LOCKED_DATA_LABEL = DATA_LABEL.clone();
    ADMIN_LOCKED_DATA_LABEL.foreColor = COLORS.GREY_300;

    // Summary Cell Styles __________________________________________________________________
    export const COMP_SUMMARY_LABEL = FINANCIAL_ENTITY_LABEL.clone();
    COMP_SUMMARY_LABEL.name = 'SJSS.COMP_SUMMARY_LABEL';
    COMP_SUMMARY_LABEL.backColor = COMP_SUMMARY_BG_COLOR;
    COMP_SUMMARY_LABEL.foreColor = COLORS.GREY_600;
    COMP_SUMMARY_LABEL.cellPadding = '8 8 8 18';
    COMP_SUMMARY_LABEL.font = '500 12px Inter';
    COMP_SUMMARY_LABEL.borderTop = SUMMARY_BORDER;
    COMP_SUMMARY_LABEL.borderRight = SUMMARY_BORDER;
    COMP_SUMMARY_LABEL.borderBottom = SUMMARY_BORDER;

    export const COMP_SUMMARY_ACTUAL = ACTUAL_CELL.clone();
    COMP_SUMMARY_ACTUAL.name = 'SJSS.COMP_SUMMARY_ACTUAL';
    COMP_SUMMARY_ACTUAL.backColor = COMP_SUMMARY_BG_COLOR;
    COMP_SUMMARY_ACTUAL.foreColor = COLORS.GREY_500;
    COMP_SUMMARY_ACTUAL.borderTop = SUMMARY_BORDER;
    COMP_SUMMARY_ACTUAL.borderRight = SUMMARY_BORDER;
    COMP_SUMMARY_ACTUAL.borderBottom = SUMMARY_BORDER;

    export const COMP_SUMMARY_LAST_ACTUAL = COMP_SUMMARY_ACTUAL.clone();
    COMP_SUMMARY_LAST_ACTUAL.name = 'SJSS.COMP_SUMMARY_LAST_ACTUAL';
    COMP_SUMMARY_LAST_ACTUAL.borderRight = MEDIUM_VERTICAL_DIVIDER;

    export const COMP_SUMMARY_REFORECAST = COMP_SUMMARY_ACTUAL.clone();
    COMP_SUMMARY_REFORECAST.borderBottom = TOTAL_DATA_BOTTOM_BORDER;

    export const COMP_SUMMARY_REFORECAST_TOTAL = COMP_SUMMARY_REFORECAST.clone();
    COMP_SUMMARY_REFORECAST_TOTAL.name = 'SJSS.COMP_SUMMARY_REFORECAST_TOTAL';
    COMP_SUMMARY_REFORECAST_TOTAL.borderRight = MEDIUM_VERTICAL_DIVIDER;
    COMP_SUMMARY_REFORECAST_TOTAL.formatter = "#,#";

    export const CTGY_SUMMARY_LABEL = COMP_SUMMARY_LABEL.clone();
    CTGY_SUMMARY_LABEL.backColor = CTGY_SUMMARY_BG_COLOR;
    CTGY_SUMMARY_LABEL.formatter = "#,#";

    export const CTGY_SUMMARY_LABEL_LVL1 = CTGY_SUMMARY_LABEL.clone();
    CTGY_SUMMARY_LABEL_LVL1.cellPadding = '8 8 8 34';

    export const CTGY_SUMMARY_LABEL_LVL2 = CTGY_SUMMARY_LABEL.clone();
    CTGY_SUMMARY_LABEL_LVL2.cellPadding = '8 8 8 42';

    export const CTGY_SUMMARY_LABEL_LVL3 = CTGY_SUMMARY_LABEL.clone();
    CTGY_SUMMARY_LABEL_LVL3.cellPadding = '8 8 8 50';

    export const CTGY_SUMMARY_LABEL_LVL4 = CTGY_SUMMARY_LABEL.clone();
    CTGY_SUMMARY_LABEL_LVL4.cellPadding = '8 8 8 58';

    export const CTGY_SUMMARY_ACTUAL = COMP_SUMMARY_ACTUAL.clone();
    CTGY_SUMMARY_ACTUAL.name = 'SJSS.CTGY_SUMMARY_ACTUAL';
    CTGY_SUMMARY_ACTUAL.backColor = CTGY_SUMMARY_BG_COLOR;

    export const CTGY_SUMMARY_LAST_ACTUAL = CTGY_SUMMARY_ACTUAL.clone();
    CTGY_SUMMARY_LAST_ACTUAL.name = 'SJSS.CTGY_SUMMARY_LAST_ACTUAL';
    CTGY_SUMMARY_LAST_ACTUAL.borderRight = MEDIUM_VERTICAL_DIVIDER;

    export const CTGY_SUMMARY_REFORECAST = CTGY_SUMMARY_LABEL.clone();
    CTGY_SUMMARY_REFORECAST.borderBottom = TOTAL_DATA_BOTTOM_BORDER;

    export const CTGY_SUMMARY_REFORECAST_TOTAL = CTGY_SUMMARY_REFORECAST.clone();
    CTGY_SUMMARY_REFORECAST_TOTAL.name = 'SJSS.CTGY_SUMMARY_REFORECAST_TOTAL';
    CTGY_SUMMARY_REFORECAST_TOTAL.borderRight = MEDIUM_VERTICAL_DIVIDER;
    CTGY_SUMMARY_REFORECAST_TOTAL.formatter = "#,#";
    CTGY_SUMMARY_REFORECAST_TOTAL.font = '400 12px Inter';

    // Totals Cell Styles ___________________________________________________________________
    export const TOTALS_COLUMN_BASE = BASE.clone();
    TOTALS_COLUMN_BASE.backColor = COLORS.GREY_75;
    TOTALS_COLUMN_BASE.borderBottom = CELL_BORDER;
    TOTALS_COLUMN_BASE.cellPadding = '6px';
    TOTALS_COLUMN_BASE.foreColor = COLORS.GREY_500;
    TOTALS_COLUMN_BASE.hAlign = HorizontalAlign.right;

    export const TOTALS_HEADER = TOTALS_COLUMN_BASE.clone();
    TOTALS_HEADER.font = '600 12px Inter';
    TOTALS_HEADER.vAlign = VerticalAlign.bottom;

    export const TOTALS_DATA = TOTALS_COLUMN_BASE.clone();
    TOTALS_DATA.name = 'SJSS.TOTALS_DATA';
    TOTALS_DATA.font = '400 12px Inter';
    TOTALS_DATA.formatter = '#,#';
    TOTALS_DATA.locked = true;
    TOTALS_DATA.borderRight = MEDIUM_VERTICAL_DIVIDER;


    // Capped Rowspan Styles ____________________________________________________________________
    const CAPPED_ROWSPAN_CELL_BASE = new GC.Spread.Sheets.Style();
    CAPPED_ROWSPAN_CELL_BASE.backColor = 'white';
    CAPPED_ROWSPAN_CELL_BASE.foreColor = 'white';
    CAPPED_ROWSPAN_CELL_BASE.borderTop = CELL_BORDER;
    CAPPED_ROWSPAN_CELL_BASE.borderBottom = CELL_BORDER;
    CAPPED_ROWSPAN_CELL_BASE.locked = true;

    export const SECTION_VALUE_OPEN_FIRST_CELL_LABEL = CAPPED_ROWSPAN_CELL_BASE.clone();
    SECTION_VALUE_OPEN_FIRST_CELL_LABEL.borderLeft = CELL_BORDER;

    export const SECTION_VALUE_OPEN_MID_CELL_LABEL = CAPPED_ROWSPAN_CELL_BASE.clone();

    export const SECTION_VALUE_OPEN_LAST_CELL_LABEL = CAPPED_ROWSPAN_CELL_BASE.clone();
    SECTION_VALUE_OPEN_LAST_CELL_LABEL.borderRight = CELL_BORDER;

    export const SECTION_VALUE_OPEN_LAST_ACTUAL_CELL = SECTION_VALUE_OPEN_MID_CELL_LABEL.clone();
    SECTION_VALUE_OPEN_LAST_ACTUAL_CELL.name = 'SJSS.SECTION_VALUE_OPEN_LAST_ACTUAL_CELL';
    SECTION_VALUE_OPEN_LAST_ACTUAL_CELL.borderRight = new GC.Spread.Sheets.LineBorder('#D2D5DB', GC.Spread.Sheets.LineStyle.medium);

    export const TOTALS_COLUMN_DATA_CELL_OPEN = TOTALS_COLUMN_BASE.clone();
    TOTALS_COLUMN_DATA_CELL_OPEN.name = 'SJSS.TOTALS_COLUMN_DATA_CELL_OPEN';
    TOTALS_COLUMN_DATA_CELL_OPEN.foreColor = COLORS.GREY_75;
    TOTALS_COLUMN_DATA_CELL_OPEN.borderRight = MEDIUM_VERTICAL_DIVIDER;

    // Test Styles __________________________________________________________________________
    export const TEST_STYLE_1 = BASE.clone();
    TEST_STYLE_1.backColor = '#FF00FF';

    export const TEST_STYLE_2 = BASE.clone();
    TEST_STYLE_2.backColor = '#FFFF00';

    export const TEST_STYLE_3 = BASE.clone();
    TEST_STYLE_3.backColor = '#00FFFF';
}
