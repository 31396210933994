import React, {useContext} from "react";
import { Route, RouteProps } from "react-router-dom";
import {AuthContext, Role} from "../contexts/AuthContext";

interface AdminRouteProps extends RouteProps {
    component: any;
}

export const AdminRoute = (props: AdminRouteProps) => {
    const { component: Component, ...rest } = props;
    const { role } = useContext(AuthContext);

    return (
    <Route
        {...rest}
        render={(routeProps) =>
            role === Role.Admin ?  (
                <Component {...routeProps} />
            ) : <>Protected</>
        }
    />);
};

