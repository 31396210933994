import { useEffect, useState } from "react";
import { useVarianceNoteData } from "../data/useVarianceNoteData";

export enum SavePhase {
    READY = 0,
    SAVING = 1,
    SAVED = 2
}

export type VarianceNoteState = {
    canSave: boolean;
    isDirty: boolean;
    savePhase: SavePhase;
    text: string;
    setText: (text: string) => void;
    save: () => void;
}

export interface VarianceNoteStateProps {
    propertyId: string;
    accountId: string;
    year: number;
    monthIndex: number;
}

export function useVarianceNoteState(props: VarianceNoteStateProps): VarianceNoteState {
    const varianceNoteData = useVarianceNoteData({
        propertyId: props.propertyId,
        accountId: props.accountId,
        year: props.year,
        monthIndex: props.monthIndex
    });

    const [isDirty, setIsDirty] = useState(false);
    const [savePhase, setSavePhase] = useState(SavePhase.READY);
    const [textValue, setTextValue] = useState<string>("");

    const setText = (text: string) => {
        setTextValue(text);
    };


    const save = () => {
        if (savePhase !== SavePhase.READY) {
            return;
        }

        setSavePhase(SavePhase.SAVING);
        varianceNoteData.save(textValue);
    };

    useEffect(
        () => {
            if (savePhase === SavePhase.SAVING && !varianceNoteData.isSaving) {
                setSavePhase(SavePhase.SAVED);
                setTimeout(() => {
                    setSavePhase(SavePhase.READY);
                }, 1000);
            }
        },
        [varianceNoteData.isSaving]
    );

    useEffect(
        () => {
            setTextValue(varianceNoteData.persistedValue);
        },
        [varianceNoteData.persistedValue]
    );

    useEffect(
        () => {
            setIsDirty(textValue.trim() !== (varianceNoteData.persistedValue).trim());
        },
        [textValue, varianceNoteData.persistedValue]
    );

    return {
        canSave: isDirty && (savePhase === SavePhase.READY),
        isDirty: isDirty,
        savePhase: savePhase,
        text: textValue,
        setText: setText,
        save: save
    };
}