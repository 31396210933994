import Handsontable from "handsontable";
import { useEffect, useReducer } from "react";
import { TDriverAccountMetric, TDriverOperationalMetric, TDriverGrowthMetric, TDriverWorksheetMetric, TDriverCustomItem } from "../../../contexts/account/data/logic/driversAndWorksheetData";
import { GrowthCalcMethod, VersionType } from "../../../__generated__/generated_types";
import { IUsePropertyDriversReturn } from "./usePropertyDrivers";


const COLUMN_TITLES = [
    "Destination GL Accounts", "Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec", "Average",
];

export const SELECTED_DRIVER_LABELS = {
    opDriver: "OpDriver",
    customDriver: "Custom Driver",
    lineItems: "Line Items",
    accDriver: "Account",
    percentGrowth: "% Growth",
    annualTargetValue: "Annual Target Amount",
};

export const GROWTH_DRIVER_LABELS = [SELECTED_DRIVER_LABELS.percentGrowth, SELECTED_DRIVER_LABELS.annualTargetValue];

export interface IColumnConfig {
    title: string;
    readOnly: boolean;
}

interface IAllRowsData {
    modelingMethod: string,
    rows: {
        rowTitle: string,
        values: Array<string | number | null>,
    }[],
}

interface IDropdownItem {
    dropdownTitle: string,
    rows: {
        displayName: string,
        values: Array<string | number | null>,
    }[],
}

export interface IDataByDriverType {
    account: { accountId: string, accountName: string, drivers: TDriverAccountMetric[] }[],
    annualTargetValue: { accountId: string, accountName: string, drivers: TDriverGrowthMetric[] }[],
    operational: { accountId: string, accountName: string, drivers: TDriverOperationalMetric[] }[],
    percentGrowth: { accountId: string, accountName: string, drivers: TDriverGrowthMetric[] }[],
    customDriver: { accountId: string, accountName: string, drivers: TDriverCustomItem[] }[]
    worksheet: {
        lineItem: TDriverWorksheetMetric["description"],
        drivers: {
            accountId: string,
            accountName: string,
            values: TDriverWorksheetMetric["values"],
        }[],
    }[],
}

interface IApplyBulkUpdate {
    selectedDriver: string,
    selectedLineItem: string | undefined,
    selectedOpDriverUnit: OpDriverUnit,
    selectedCustomDriverUnit: CustomDriverUnit,
    tableData: (string | number | null)[][],
}

export interface IUpdateGLAccountData {
    row: number,
    column: number,
    newValue: number,
}

export const enum OpDriverUnit {
    DOLLAR = "$",
    PERCENTAGE = "%",
}

export const enum CustomDriverUnit {
    DOLLAR = "$",
    PERCENTAGE = "%",
    COUNT = "#"
}

interface SetAllRowsData {
    type: "SET_ALL_ROWS_DATA",
    allRowsData: IAllRowsData[],
}

interface ApplyBulkUpdate extends IApplyBulkUpdate {
    reforecastStartMonth: number,
    versionType: VersionType,
    type: "APPLY_BULK_UPDATE",
}

interface SetBulkUpdateData {
    type: "SET_BULK_UPDATE_DATA",
    bulkUpdateData: Array<number | string | null>,
}

interface SetDataByDriverType {
    type: "SET_DATA_BY_DRIVER_TYPE",
    dataByDriverType: IDataByDriverType,
    dataByDriverTypeDraft: IDataByDriverType,
}

interface SetDropdownItems {
    type: "SET_DROPDOWN_ITEMS",
}

interface SetSelectedLineItem {
    type: "SET_SELECTED_LINE_ITEM",
    selectedLineItem: string | undefined,
}

interface SetSelectedModelingMethod {
    type: "SET_SELECTED_MODELING_METHOD",
    selectedModelingMethod: string,
}

interface SetSelectedOpDriverUnit {
    type: "SET_SELECTED_OP_DRIVER_UNIT",
    selectedOpDriverUnit: OpDriverUnit,
}

interface SetSelectedCustomDriverUnit {
    type: "SET_SELECTED_CUSTOM_DRIVER_UNIT",
    selectedCustomDriverUnit: CustomDriverUnit,
}

interface UpdateGLAccountData {
    type: "UPDATE_GL_ACCOUNT_DATA",
    data: IUpdateGLAccountData[],
    selectedDriver: string | undefined,
}

interface State {
    allRowsData: IAllRowsData[],
    bulkUpdateData: Array<number | string | null>,
    dataByDriverType: IDataByDriverType | undefined,
    dataByDriverTypeDraft: IDataByDriverType | undefined,
    dropdownItems: IDropdownItem[],
    selectedLineItem: string | undefined,
    selectedModelingMethod: string,
    selectedOpDriverUnit: OpDriverUnit,
    selectedCustomDriverUnit: CustomDriverUnit,
}

const INITIAL_STATE: State = {
    allRowsData: [],
    bulkUpdateData: [],
    dataByDriverType: undefined,
    dataByDriverTypeDraft: undefined,
    dropdownItems: [],
    selectedLineItem: undefined,
    selectedModelingMethod: '',
    selectedOpDriverUnit: OpDriverUnit.DOLLAR,
    selectedCustomDriverUnit: CustomDriverUnit.DOLLAR
};

type Action =
    ApplyBulkUpdate
    | SetAllRowsData
    | SetBulkUpdateData
    | SetDataByDriverType
    | SetDropdownItems
    | SetSelectedLineItem
    | SetSelectedModelingMethod
    | SetSelectedOpDriverUnit
    | SetSelectedCustomDriverUnit
    | UpdateGLAccountData;

interface IUseEditAssumptionsProps {
    pd: IUsePropertyDriversReturn,
}

interface IUseEditAssumptionsReturn {
    // local variables
    getColumnsConfig: (selectedDriver: string | undefined) => IColumnConfig[],

    // event handlers
    handleCellChange: (changes: Handsontable.CellChange[] | null) => void,

    // state variables and actions
    allRowsData: IAllRowsData[],
    setAllRowsData: (allRowsData: IAllRowsData[]) => void,
    applyBulkUpdate: (args: IApplyBulkUpdate) => void,
    bulkUpdateData: Array<number | string | null>,
    setBulkUpdateData: (bulkUpdateData: Array<number | string | null>) => void,
    dataByDriverType: IDataByDriverType | undefined,
    dataByDriverTypeDraft: IDataByDriverType | undefined,
    dropdownItems: IDropdownItem[],
    setDropdownItems: () => void,
    selectedLineItem: string | undefined,
    setSelectedLineItem: (selectedLineItem: string | undefined) => void,
    selectedModelingMethod: string,
    setSelectedModelingMethod: (modelingMethod: string) => void,
    selectedOpDriverUnit: OpDriverUnit,
    setSelectedOpDriverUnit: (selectedOpDriverUnit: OpDriverUnit) => void,
    selectedCustomDriverUnit: CustomDriverUnit,
    setSelectedCustomDriverUnit: (selectedCustomDriverUnit: CustomDriverUnit) => void,
    updateGLAccountData: (data: IUpdateGLAccountData[], selectedDriver: string | undefined) => void,
}

export default function useEditAssumptions ({
    pd,
}: IUseEditAssumptionsProps): IUseEditAssumptionsReturn {
    const [state, dispatch] = useReducer(editAssumptionsDriversReducer, INITIAL_STATE);

    useEffect(() => {
        const dataByDriverType: IDataByDriverType = { account: [], operational: [], percentGrowth: [], annualTargetValue: [], worksheet: [], customDriver: [] };
        /**
         * ToDo (post-release)
         *  Write or use a deepclone here instead of updating two objects every time in this effect
         */
        const dataByDriverTypeDraft: IDataByDriverType = { account: [], operational: [], percentGrowth: [], annualTargetValue: [], worksheet: [], customDriver: [] };

        for (const [accountId, parsedFormDrivers] of Object.entries(pd.parsedFormDriversByAccountId)) {
            const accountName = pd.selectedAccounts.find((selectedAccount) => selectedAccount.id === accountId)?.name;
            if (accountName) {
                if (parsedFormDrivers.account.length > 0) {
                    dataByDriverType.account.push({ accountId, accountName, drivers: parsedFormDrivers.account });
                    dataByDriverTypeDraft.account.push({ accountId, accountName, drivers: parsedFormDrivers.account });
                }
                if (parsedFormDrivers.percentGrowth.length > 0) {
                    dataByDriverType.percentGrowth.push({ accountId, accountName, drivers: parsedFormDrivers.percentGrowth });
                    dataByDriverTypeDraft.percentGrowth.push({ accountId, accountName, drivers: parsedFormDrivers.percentGrowth });
                }
                if (parsedFormDrivers.annualTargetValue.length > 0) {
                    dataByDriverType.annualTargetValue.push({ accountId, accountName, drivers: parsedFormDrivers.annualTargetValue });
                    dataByDriverTypeDraft.annualTargetValue.push({ accountId, accountName, drivers: parsedFormDrivers.annualTargetValue });
                }
                if (parsedFormDrivers.operational.length > 0) {
                    dataByDriverType.operational.push({ accountId, accountName, drivers: parsedFormDrivers.operational });
                    dataByDriverTypeDraft.operational.push({ accountId, accountName, drivers: parsedFormDrivers.operational });
                }
                if (parsedFormDrivers.worksheet.length > 0) {
                    for (const drivers of parsedFormDrivers.worksheet) {
                        if (dataByDriverType.worksheet.find((each) => each.lineItem === drivers.description)) {
                            dataByDriverType.worksheet = dataByDriverType.worksheet.map((each) => {
                                if (each.lineItem === drivers.description) {
                                    const newDrivers = [...each.drivers];
                                    newDrivers.push({
                                        accountId,
                                        accountName,
                                        values: drivers.values.map((each) => each === null ? 0 : each),
                                    });
                                    return {
                                        ...each,
                                        drivers: newDrivers,
                                    };
                                }
                                return each;
                            });
                            dataByDriverTypeDraft.worksheet = dataByDriverType.worksheet.slice();
                        } else {
                            dataByDriverType.worksheet.push({
                                lineItem: drivers.description,
                                drivers: [{
                                    accountId,
                                    accountName,
                                    values: drivers.values.map((each) => each === null ? 0 : each),
                                }],
                            });
                            dataByDriverTypeDraft.worksheet.push({
                                lineItem: drivers.description,
                                drivers: [{
                                    accountId,
                                    accountName,
                                    values: drivers.values.map((each) => each === null ? 0 : each),
                                }],
                            });
                        }
                    }
                }
                if (parsedFormDrivers.customDriver.length > 0) {
                    dataByDriverType.customDriver.push({ accountId, accountName, drivers: parsedFormDrivers.customDriver });
                    dataByDriverTypeDraft.customDriver.push({ accountId, accountName, drivers: parsedFormDrivers.customDriver });
                }
            }
        }

        dispatch({
            type: "SET_DATA_BY_DRIVER_TYPE",
            dataByDriverType,
            dataByDriverTypeDraft,
        });
    }, [pd.parsedFormDriversByAccountId, pd.selectedAccounts]);

    /**
     * Re-initialize the bulk update cells when the reforecast start month changes
     *  or when the user chooses a new item from the modeling method or line items dropdowns
     */
    useEffect(() => {
        const initialBulkUpdateData = new Array(12).fill(0)
            .map((each: number | null, index) => {
                if (pd.versionType === VersionType.Reforecast && (index < pd.reforecastStartMonth)) {
                    return null;
                }
                return each;
            });
        setBulkUpdateData(initialBulkUpdateData);
    }, [pd.reforecastStartMonth, pd.versionType, state.selectedLineItem, state.selectedModelingMethod]);

    useEffect(() => {
        setDropdownItems();
    }, [state.dataByDriverTypeDraft, state.selectedOpDriverUnit, state.selectedCustomDriverUnit]);

    const getColumnsConfig = (selectedDriver: string | undefined): IColumnConfig[] => {
        if (selectedDriver) {
            if (GROWTH_DRIVER_LABELS.includes(selectedDriver)) {
                return [
                    { title: "Destination GL Accounts", readOnly: true },
                    { title: selectedDriver, readOnly: false },
                ];
            } else {
                return COLUMN_TITLES
                    .map((each, index) => {
                        if (index === 0 || (index === COLUMN_TITLES.length - 1)) {
                            return {
                                title: each,
                                readOnly: true,
                            };
                        }
                        return {
                            title: each,
                            readOnly: pd.versionType === VersionType.Reforecast && ((index - 1) < pd.reforecastStartMonth),
                        };
                    });
            }
        }
        return [];
    };

    const setAllRowsData = (allRowsData: IAllRowsData[]) => {
        dispatch({
            type: "SET_ALL_ROWS_DATA",
            allRowsData,
        });
    };

    const applyBulkUpdate = ({
        selectedDriver,
        selectedLineItem,
        selectedOpDriverUnit,
        selectedCustomDriverUnit,
        tableData,
    }: IApplyBulkUpdate) => {
        dispatch({
            type: "APPLY_BULK_UPDATE",
            reforecastStartMonth: pd.reforecastStartMonth,
            selectedDriver,
            selectedLineItem,
            selectedOpDriverUnit,
            selectedCustomDriverUnit,
            tableData,
            versionType: pd.versionType,
        });
    };

    const setBulkUpdateData = (bulkUpdateData: Array<number | string | null>) => {
        dispatch({
            type: "SET_BULK_UPDATE_DATA",
            bulkUpdateData,
        });
    };

    const setDropdownItems = () => {
        dispatch({
            type: "SET_DROPDOWN_ITEMS",
        });
    };

    const setSelectedLineItem = (selectedLineItem: string | undefined) => {
        dispatch({
            type: "SET_SELECTED_LINE_ITEM",
            selectedLineItem,
        });
    };

    const setSelectedModelingMethod = (selectedModelingMethod: string) => {
        dispatch({
            type: "SET_SELECTED_MODELING_METHOD",
            selectedModelingMethod,
        });
    };

    const setSelectedOpDriverUnit = (selectedOpDriverUnit: OpDriverUnit) => {
        dispatch({
            type: "SET_SELECTED_OP_DRIVER_UNIT",
            selectedOpDriverUnit,
        });
    };

    const setSelectedCustomDriverUnit = (selectedCustomDriverUnit: CustomDriverUnit) => {
        dispatch({
            type: "SET_SELECTED_CUSTOM_DRIVER_UNIT",
            selectedCustomDriverUnit,
        });
    };

    const updateGLAccountData = (data: IUpdateGLAccountData[], selectedDriver: string | undefined) => {
        dispatch({
            type: "UPDATE_GL_ACCOUNT_DATA",
            data,
            selectedDriver,
        });
    };

    const handleCellChange = (changes: Handsontable.CellChange[] | null) => {
        if (changes) {
            const bulkUpdateDataCopy = [...state.bulkUpdateData];

            for (const change of changes) {
                const [_row, column, _oldValue, newValue] = change;

                const index = typeof column === "number" ? column : parseInt(column);

                bulkUpdateDataCopy[index] = newValue;
            }

            setBulkUpdateData(bulkUpdateDataCopy);
        }
    };

    return {
        // local variables
        getColumnsConfig,

        // event handlers
        handleCellChange,

        // state variables and reducers
        allRowsData: state.allRowsData,
        setAllRowsData,

        applyBulkUpdate,

        bulkUpdateData: state.bulkUpdateData,
        setBulkUpdateData,

        dataByDriverType: state.dataByDriverType,
        dataByDriverTypeDraft: state.dataByDriverTypeDraft,

        dropdownItems: state.dropdownItems,
        setDropdownItems,

        selectedLineItem: state.selectedLineItem,
        setSelectedLineItem,

        selectedModelingMethod: state.selectedModelingMethod,
        setSelectedModelingMethod,

        selectedOpDriverUnit: state.selectedOpDriverUnit,
        setSelectedOpDriverUnit,

        selectedCustomDriverUnit: state.selectedCustomDriverUnit,
        setSelectedCustomDriverUnit,

        updateGLAccountData,
    };
}

function editAssumptionsDriversReducer(state: State, action: Action): State {
    switch (action.type) {
        case "SET_ALL_ROWS_DATA": {
            const { allRowsData } = action;

            return {
                ...state,
                allRowsData,
            };
        }

        case "APPLY_BULK_UPDATE": {
            const { reforecastStartMonth, selectedDriver, selectedLineItem, selectedOpDriverUnit, selectedCustomDriverUnit, versionType } = action;

            const getUpdatedDataByDriverTypeDraft = (dataByDriverTypeDraft: IDataByDriverType | undefined) => {
                if (dataByDriverTypeDraft === undefined) return dataByDriverTypeDraft;

                const getUpdatedValues = (existingValues: (string | number | null)[], bulkUpdateValues: (string | number | null)[]): number[] => {
                    let result = [];
                    if (versionType === VersionType.Reforecast) {
                        result = [
                            ...existingValues.slice(0, reforecastStartMonth),
                            ...bulkUpdateValues.slice(reforecastStartMonth),
                        ];
                    } else {
                        result = bulkUpdateValues;
                    }
                    return result.map((each) => each === null ? 0 : (typeof each === "string" ? parseFloat(each) : each));
                };

                switch (true) {
                    case selectedDriver.includes(SELECTED_DRIVER_LABELS.accDriver): {
                        // Extract account name from "% Account (<account_name>)"
                        const selectedAccount = selectedDriver.match(/\((.*?)\)/)?.[1];

                        if (selectedAccount) {
                            return {
                                ...dataByDriverTypeDraft,
                                account: dataByDriverTypeDraft.account.map((eachAccount) => ({
                                    ...eachAccount,
                                    drivers: eachAccount.drivers.map((eachDriver) => {
                                        if (eachDriver.glName === selectedAccount) {
                                            return {
                                                ...eachDriver,
                                                percentage: getUpdatedValues(eachDriver.percentage, state.bulkUpdateData),
                                            };
                                        }
                                        return eachDriver;
                                    }),
                                })),
                            };
                        }

                        return dataByDriverTypeDraft;
                    }
                    case selectedDriver.includes(SELECTED_DRIVER_LABELS.opDriver): {
                        // Extract op driver name from "Op Driver (<op_driver_name>)"
                        const selectedOpDriver = selectedDriver.match(/\((.*?)\)/)?.[1];

                        if (selectedOpDriver) {
                            return {
                                ...dataByDriverTypeDraft,
                                operational: dataByDriverTypeDraft.operational.map((eachOp) => ({
                                    ...eachOp,
                                    drivers: eachOp.drivers.map((eachDriver) => {
                                        if (eachDriver.type === selectedOpDriver) {
                                            return ({
                                                ...eachDriver,
                                                ...(selectedOpDriverUnit === OpDriverUnit.DOLLAR && {
                                                    values: getUpdatedValues(eachDriver.values, state.bulkUpdateData),
                                                }),
                                                ...(selectedOpDriverUnit === OpDriverUnit.PERCENTAGE && {
                                                    percentage: getUpdatedValues(eachDriver.percentage, state.bulkUpdateData),
                                                }),
                                            });
                                        }
                                        return eachDriver;
                                    }),
                                })),
                            };
                        }

                        return dataByDriverTypeDraft;
                    }
                    case selectedDriver.includes(SELECTED_DRIVER_LABELS.customDriver): {
                        // Extract custom driver name from "Custom Driver (<item name>)"
                        const selectedCustomDriver = selectedDriver.match(/\((.*?)\)/)?.[1];

                        if (selectedCustomDriver) {
                            return {
                                ...dataByDriverTypeDraft,
                                customDriver: dataByDriverTypeDraft.customDriver.map((eachCustom) => ({
                                    ...eachCustom,
                                    drivers: eachCustom.drivers.map((eachDriver) => {
                                        if (eachDriver.itemName === selectedCustomDriver) {
                                            return ({
                                                ...eachDriver,
                                                ...(selectedCustomDriverUnit === CustomDriverUnit.DOLLAR && {
                                                    amount: getUpdatedValues(eachDriver.amount, state.bulkUpdateData),
                                                }),
                                                ...(selectedCustomDriverUnit === CustomDriverUnit.PERCENTAGE && {
                                                    percentage: getUpdatedValues(eachDriver.percentage, state.bulkUpdateData),
                                                }),
                                                ...(selectedCustomDriverUnit === CustomDriverUnit.COUNT && {
                                                    count: getUpdatedValues(eachDriver.count, state.bulkUpdateData),
                                                }),
                                            });
                                        }
                                        return eachDriver;
                                    }),
                                })),
                            };
                        }

                        return dataByDriverTypeDraft;
                    }
                    case selectedDriver === SELECTED_DRIVER_LABELS.percentGrowth: {
                        return {
                            ...dataByDriverTypeDraft,
                            percentGrowth: dataByDriverTypeDraft.percentGrowth.map((eachPG) => {
                                const driver = eachPG.drivers[0];
                                let bulkPercentGrowth = state.bulkUpdateData[0];
                                bulkPercentGrowth = typeof bulkPercentGrowth === "string" ? parseFloat(bulkPercentGrowth) : bulkPercentGrowth;
                                bulkPercentGrowth = bulkPercentGrowth === undefined || bulkPercentGrowth === null || (bulkPercentGrowth >= -100 && bulkPercentGrowth <= 100) ? bulkPercentGrowth : 0;

                                if (driver) {
                                    return {
                                        ...eachPG,
                                        drivers: [{
                                            ...driver,
                                            percentGrowthAdjustmentValue: bulkPercentGrowth,
                                        }],
                                    };
                                }

                                return eachPG;
                            })
                        };
                    }
                    case selectedDriver === SELECTED_DRIVER_LABELS.annualTargetValue: {
                        return {
                            ...dataByDriverTypeDraft,
                            annualTargetValue: dataByDriverTypeDraft.annualTargetValue.map((each) => {
                                const driver = each.drivers[0];
                                let bulkAnnualTargetValue = state.bulkUpdateData[0];
                                bulkAnnualTargetValue = typeof bulkAnnualTargetValue === "string" ? parseFloat(bulkAnnualTargetValue) : bulkAnnualTargetValue;
                                bulkAnnualTargetValue = typeof bulkAnnualTargetValue === "number" ? bulkAnnualTargetValue : null;

                                if (driver) {
                                    return {
                                        ...each,
                                        drivers: [{
                                            ...driver,
                                            annualTargetValueManualEntry: bulkAnnualTargetValue,
                                        }],
                                    };
                                }

                                return each;
                            })
                        };
                    }
                    case selectedDriver.includes(SELECTED_DRIVER_LABELS.lineItems): {
                        return {
                            ...dataByDriverTypeDraft,
                            worksheet: dataByDriverTypeDraft.worksheet.map((eachWs) => {
                                if (eachWs.lineItem === selectedLineItem) {
                                    return {
                                        ...eachWs,
                                        drivers: eachWs.drivers.map((eachDriver) => ({
                                            ...eachDriver,
                                            values: getUpdatedValues(eachDriver.values, state.bulkUpdateData),
                                        })),
                                    };
                                }
                                return eachWs;
                            }),
                        };
                    }
                    default:
                        // Unlikely
                        return dataByDriverTypeDraft;
                }
            };

            return {
                ...state,
                dataByDriverTypeDraft: getUpdatedDataByDriverTypeDraft(state.dataByDriverTypeDraft),
            };
        }

        case "SET_BULK_UPDATE_DATA": {
            const { bulkUpdateData } = action;

            return {
                ...state,
                bulkUpdateData,
            };
        }

        case "SET_DATA_BY_DRIVER_TYPE": {
            const { dataByDriverType, dataByDriverTypeDraft } = action;

            return {
                ...state,
                dataByDriverType,
                dataByDriverTypeDraft: dataByDriverTypeDraft,
            };
        }

        case "SET_DROPDOWN_ITEMS": {
            const dropdownItems: Record<string, IDropdownItem> = {};

            if (state.dataByDriverTypeDraft) {
                for (const account of state.dataByDriverTypeDraft.account) {
                    for (const accountDriver of account.drivers) {
                        const values = accountDriver.percentage;
                        const dropdownTitle = `Percentage of Account (${accountDriver.glName})`;

                        let dropdownItem = dropdownItems[dropdownTitle];
                        if (dropdownItem === undefined) {
                            dropdownItem = {dropdownTitle: dropdownTitle, rows: []};
                        }

                        dropdownItem.rows.push({ displayName: account.accountName, values });

                        dropdownItems[dropdownTitle] = dropdownItem;
                    }
                }

                for (const account of state.dataByDriverTypeDraft.operational) {
                    for (const opDriver of account.drivers) {
                        const values = state.selectedOpDriverUnit === OpDriverUnit.DOLLAR
                            ? opDriver.values
                            : opDriver.percentage;
                        const dropdownTitle = `OpDriver (${opDriver.type})`;

                        let dropdownItem = dropdownItems[dropdownTitle];
                        if (dropdownItem === undefined) {
                            dropdownItem = {dropdownTitle: dropdownTitle, rows: []};
                        }

                        dropdownItem.rows.push({ displayName: account.accountName, values });

                        dropdownItems[dropdownTitle] = dropdownItem;
                    }
                }

                for (const account of state.dataByDriverTypeDraft.customDriver) {
                    for (const customDriver of account.drivers) {
                        const values = state.selectedCustomDriverUnit === CustomDriverUnit.DOLLAR
                            ? customDriver.amount
                            : state.selectedCustomDriverUnit === CustomDriverUnit.PERCENTAGE
                            ? customDriver.percentage
                            : customDriver.count;
                        const dropdownTitle = `Custom Driver (${customDriver.itemName})`;

                        let dropdownItem = dropdownItems[dropdownTitle];
                        if (dropdownItem === undefined) {
                            dropdownItem = {dropdownTitle: dropdownTitle, rows: []};
                        }

                        dropdownItem.rows.push({ displayName: account.accountName, values });

                        dropdownItems[dropdownTitle] = dropdownItem;
                    }
                }

                for (const account of state.dataByDriverTypeDraft.percentGrowth) {
                    const growthDriver = account.drivers[0];

                    if (growthDriver && growthDriver.growthCalcMethod === GrowthCalcMethod.PercentGrowth) {
                        const values: (string | number | null)[] = [growthDriver.percentGrowthAdjustmentValue === undefined ? null : growthDriver.percentGrowthAdjustmentValue];
                        const dropdownTitle = SELECTED_DRIVER_LABELS.percentGrowth;

                        let dropdownItem = dropdownItems[dropdownTitle];
                        if (dropdownItem === undefined) {
                            dropdownItem = {dropdownTitle: dropdownTitle, rows: []};
                        }

                        dropdownItem.rows.push({ displayName: account.accountName, values });

                        dropdownItems[dropdownTitle] = dropdownItem;
                    }
                }

                for (const account of state.dataByDriverTypeDraft.annualTargetValue) {
                    const growthDriver = account.drivers[0];

                    if (growthDriver && growthDriver.growthCalcMethod === GrowthCalcMethod.AnnualTargetValue) {
                        const values: (string | number | null)[] = [growthDriver.annualTargetValueManualEntry === undefined ? null : growthDriver.annualTargetValueManualEntry];
                        const dropdownTitle = SELECTED_DRIVER_LABELS.annualTargetValue;

                        let dropdownItem = dropdownItems[dropdownTitle];
                        if (dropdownItem === undefined) {
                            dropdownItem = {dropdownTitle: dropdownTitle, rows: []};
                        }

                        dropdownItem.rows.push({ displayName: account.accountName, values });

                        dropdownItems[dropdownTitle] = dropdownItem;
                    }
                }

                if (state.dataByDriverTypeDraft.worksheet.length > 0) {
                    dropdownItems[SELECTED_DRIVER_LABELS.lineItems] = {
                        dropdownTitle: SELECTED_DRIVER_LABELS.lineItems,
                        rows: state.dataByDriverTypeDraft.worksheet.map(() => ({
                            displayName: '',
                            values: [],
                        })),
                    };
                }
            }

            const updatedDropdownItems = Object.values(dropdownItems);

            return {
                ...state,
                dropdownItems: updatedDropdownItems,
            };
        }

        case "SET_SELECTED_LINE_ITEM": {
            const { selectedLineItem } = action;

            return {
                ...state,
                selectedLineItem,
            };
        }

        case "SET_SELECTED_MODELING_METHOD": {
            const { selectedModelingMethod } = action;

            return {
                ...state,
                selectedModelingMethod,
            };
        }

        case "SET_SELECTED_OP_DRIVER_UNIT": {
            const { selectedOpDriverUnit } = action;

            return {
                ...state,
                selectedOpDriverUnit,
            };
        }

        case "SET_SELECTED_CUSTOM_DRIVER_UNIT": {
            const { selectedCustomDriverUnit } = action;

            return {
                ...state,
                selectedCustomDriverUnit,
            };
        }

        case "UPDATE_GL_ACCOUNT_DATA": {
            const { data, selectedDriver } = action;

            const getUpdatedDataByDriverTypeDraft = (dataByDriverTypeDraft: IDataByDriverType | undefined) => {
                if (dataByDriverTypeDraft === undefined || selectedDriver === undefined) return dataByDriverTypeDraft;

                const getUpdateAccDriverValues = (currentAccDriverValues: IDataByDriverType["account"], selectedAccDriver: string) => {
                    // The row number in the assumptions HoT !== the index of the op driver in the list of op drivers, so we first fetch the matching driver values
                    const tempCurrentAccDriverValues = currentAccDriverValues.filter((c) => c.drivers.find((d) => selectedAccDriver === d.glName) !== undefined);

                    for (const eachUpdate of data) {
                        const { row, column, newValue } = eachUpdate;

                        const tempRow = tempCurrentAccDriverValues[row];

                        if (tempRow) {
                            tempCurrentAccDriverValues[row] = {
                                ...tempRow,
                                drivers: tempRow.drivers.map((eachDriver) => {
                                    if (eachDriver.glName === selectedAccDriver) {
                                        return {
                                            ...eachDriver,
                                            ...(state.selectedOpDriverUnit === OpDriverUnit.DOLLAR && {
                                                // slicing at column - 1 because the first column is the account name
                                                percentage: [
                                                    ...eachDriver.percentage.slice(0, column - 1),
                                                    newValue,
                                                    ...eachDriver.percentage.slice(column),
                                                ],
                                            }),
                                        };
                                    }
                                    return eachDriver;
                                }),
                            };
                        }
                    }

                    return currentAccDriverValues.map((c) => {
                        const matchingAccDriver = tempCurrentAccDriverValues.find((t) => t.accountId === c.accountId);
                        if (matchingAccDriver) {
                            return matchingAccDriver;
                        }
                        return c;
                    });
                };

                const getUpdateOpDriverValues = (currentOpDriverValues: IDataByDriverType["operational"], selectedOpDriver: string) => {
                    // The row number in the assumptions HoT !== the index of the op driver in the list of op drivers, so we first fetch the matching driver values
                    const tempCurrentOpDriverValues = currentOpDriverValues.filter((c) => c.drivers.find((d) => selectedOpDriver === d.type) !== undefined);

                    for (const eachUpdate of data) {
                        const { row, column, newValue } = eachUpdate;

                        const tempRow = tempCurrentOpDriverValues[row];

                        if (tempRow) {
                            tempCurrentOpDriverValues[row] = {
                                ...tempRow,
                                drivers: tempRow.drivers.map((eachDriver) => {
                                    if (eachDriver.type === selectedOpDriver) {
                                        return {
                                            ...eachDriver,
                                            ...(state.selectedOpDriverUnit === OpDriverUnit.DOLLAR && {
                                                // slicing at column - 1 because the first column is the account name
                                                values: [
                                                    ...eachDriver.values.slice(0, column - 1),
                                                    newValue,
                                                    ...eachDriver.values.slice(column),
                                                ],
                                            }),
                                            ...(state.selectedOpDriverUnit === OpDriverUnit.PERCENTAGE && {
                                                // slicing at column - 1 because the first column is the account name
                                                percentage: [
                                                    ...eachDriver.percentage.slice(0, column - 1),
                                                    newValue,
                                                    ...eachDriver.percentage.slice(column),
                                                ],
                                            }),
                                        };
                                    }
                                    return eachDriver;
                                }),
                            };
                        }
                    }

                    return currentOpDriverValues.map((c) => {
                        const matchingOpDriver = tempCurrentOpDriverValues.find((t) => t.accountId === c.accountId);
                        if (matchingOpDriver) {
                            return matchingOpDriver;
                        }
                        return c;
                    });
                };

                const getUpdateCustomDriverValues = (currentCustomDriverValues: IDataByDriverType["customDriver"], selectedCustomDriver: string) => {
                    // The row number in the assumptions HoT !== the index of the custom driver in the list of custom drivers, so we first fetch the matching driver values
                    const tempCurrentCustomDriverValues = currentCustomDriverValues.filter((c) => c.drivers.find((d) => selectedCustomDriver === d.itemName) !== undefined);

                    for (const eachUpdate of data) {
                        const { row, column, newValue } = eachUpdate;

                        const tempRow = tempCurrentCustomDriverValues[row];

                        if (tempRow) {
                            tempCurrentCustomDriverValues[row] = {
                                ...tempRow,
                                drivers: tempRow.drivers.map((eachDriver) => {
                                    if (eachDriver.itemName === selectedCustomDriver) {
                                        return {
                                            ...eachDriver,
                                            ...(state.selectedCustomDriverUnit === CustomDriverUnit.DOLLAR && {
                                                // slicing at column - 1 because the first column is the account name
                                                amount: [
                                                    ...eachDriver.amount.slice(0, column - 1),
                                                    newValue,
                                                    ...eachDriver.amount.slice(column),
                                                ],
                                            }),
                                            ...(state.selectedCustomDriverUnit === CustomDriverUnit.PERCENTAGE && {
                                                // slicing at column - 1 because the first column is the account name
                                                percentage: [
                                                    ...eachDriver.percentage.slice(0, column - 1),
                                                    newValue,
                                                    ...eachDriver.percentage.slice(column),
                                                ],
                                            }),
                                            ...(state.selectedCustomDriverUnit === CustomDriverUnit.COUNT && {
                                                // slicing at column - 1 because the first column is the account name
                                                count: [
                                                    ...eachDriver.count.slice(0, column - 1),
                                                    newValue,
                                                    ...eachDriver.count.slice(column),
                                                ],
                                            }),
                                        };
                                    }
                                    return eachDriver;
                                }),
                            };
                        }
                    }

                    return currentCustomDriverValues.map((c) => {
                        const matchingOpDriver = tempCurrentCustomDriverValues.find((t) => t.accountId === c.accountId);
                        if (matchingOpDriver) {
                            return matchingOpDriver;
                        }
                        return c;
                    });
                };

                const getUpdatedPercentGrowthValues = (currentPercentGrowthDriverValues: IDataByDriverType["percentGrowth"]) => {
                    let tempCurrentPercentGrowthDriversValues = currentPercentGrowthDriverValues;
                    for (const eachUpdate of data) {
                        const { row, newValue } = eachUpdate;

                        const parsedNewValue = ((newValue >= -100) && (newValue <= 100)) ? newValue : 0;

                        const tempRow = currentPercentGrowthDriverValues[row];

                        if (tempRow) {
                            tempCurrentPercentGrowthDriversValues = tempCurrentPercentGrowthDriversValues.map((each, index) => {
                                if (index === row) {
                                    const driver = each.drivers[0];

                                    if (driver) {
                                        return {
                                            ...each,
                                            drivers: [{
                                                ...driver,
                                                percentGrowthAdjustmentValue: parsedNewValue,
                                            }]
                                        };
                                    }
                                }
                                return each;
                            });
                        }
                    }

                    return tempCurrentPercentGrowthDriversValues;
                };

                const getUpdatedAnnualTargetGrowthValues = (currentAnnualTargetGrowthDriverValues: IDataByDriverType["annualTargetValue"]) => {
                    let tempCurrentAnnualTargetGrowthDriverValues = currentAnnualTargetGrowthDriverValues;
                    for (const eachUpdate of data) {
                        const { row, newValue } = eachUpdate;

                        const tempRow = currentAnnualTargetGrowthDriverValues[row];

                        if (tempRow) {
                            tempCurrentAnnualTargetGrowthDriverValues = tempCurrentAnnualTargetGrowthDriverValues.map((each, index) => {
                                if (index === row) {
                                    const driver = each.drivers[0];

                                    if (driver) {
                                        return {
                                            ...each,
                                            drivers: [{
                                                ...driver,
                                                annualTargetValueManualEntry: newValue,
                                            }]
                                        };
                                    }
                                }
                                return each;
                            });
                        }
                    }

                    return tempCurrentAnnualTargetGrowthDriverValues;
                };

                const getUpdateWSDriverValues = (currentWSDriverValues: IDataByDriverType["worksheet"], selectedLineItem: string) => {
                    let tempCurrentWSDriverValues = currentWSDriverValues.find((each) => each.lineItem === selectedLineItem);

                    for (const eachUpdate of data) {
                        const { row, column, newValue } = eachUpdate;

                        const tempRow = tempCurrentWSDriverValues?.drivers[row];

                        if (tempRow && tempCurrentWSDriverValues) {
                            tempCurrentWSDriverValues = {
                                ...tempCurrentWSDriverValues,
                                drivers: tempCurrentWSDriverValues.drivers.map((each, index) => {
                                    if (index === row) {
                                        return {
                                            ...each,
                                            values: [
                                                ...each.values.slice(0, column - 1),
                                                newValue,
                                                ...each.values.slice(column),
                                            ],
                                        };
                                    } else {
                                        return each;
                                    }
                                }),
                            };
                        }
                    }

                    return currentWSDriverValues.map((each) => {
                        if (each.lineItem === selectedLineItem && tempCurrentWSDriverValues) {
                            return tempCurrentWSDriverValues;
                        }
                        return each;
                    });
                };

                switch(true) {
                    case selectedDriver.includes(SELECTED_DRIVER_LABELS.accDriver): {
                        // Extract account name from "Account (<acc_name>)"
                        const selectedAccDriver = selectedDriver.match(/\((.*?)\)/)?.[1];

                        if (selectedAccDriver) {
                            return {
                                ...dataByDriverTypeDraft,
                                account: getUpdateAccDriverValues(dataByDriverTypeDraft.account, selectedAccDriver),
                            };
                        }

                        return dataByDriverTypeDraft;
                    }
                    case selectedDriver.includes(SELECTED_DRIVER_LABELS.opDriver): {
                        // Extract op driver name from "Op Driver (<op_driver_name>)"
                        const selectedOpDriver = selectedDriver.match(/\((.*?)\)/)?.[1];

                        if (selectedOpDriver) {
                            return {
                                ...dataByDriverTypeDraft,
                                operational: getUpdateOpDriverValues(dataByDriverTypeDraft.operational, selectedOpDriver),
                            };
                        }

                        return dataByDriverTypeDraft;
                    }
                    case selectedDriver.includes(SELECTED_DRIVER_LABELS.customDriver): {
                        // Extract custom driver name from "Custom Driver (<item_name>)"
                        const selectedCustomDriver = selectedDriver.match(/\((.*?)\)/)?.[1];

                        if (selectedCustomDriver) {
                            return {
                                ...dataByDriverTypeDraft,
                                customDriver: getUpdateCustomDriverValues(dataByDriverTypeDraft.customDriver, selectedCustomDriver),
                            };
                        }

                        return dataByDriverTypeDraft;
                    }
                    case selectedDriver === SELECTED_DRIVER_LABELS.percentGrowth: {
                        return {
                            ...dataByDriverTypeDraft,
                            percentGrowth: getUpdatedPercentGrowthValues(dataByDriverTypeDraft.percentGrowth),
                        };
                    }
                    case selectedDriver === SELECTED_DRIVER_LABELS.annualTargetValue: {
                        return {
                            ...dataByDriverTypeDraft,
                            annualTargetValue: getUpdatedAnnualTargetGrowthValues(dataByDriverTypeDraft.annualTargetValue),
                        };
                    }
                    case selectedDriver === SELECTED_DRIVER_LABELS.lineItems: {
                        if (state.selectedLineItem) {
                            return {
                                ...dataByDriverTypeDraft,
                                worksheet: getUpdateWSDriverValues(dataByDriverTypeDraft.worksheet, state.selectedLineItem),
                            };
                        }

                        return dataByDriverTypeDraft;
                    }
                    default:
                        // Unlikely
                        return dataByDriverTypeDraft;
                }
            };

            return {
                ...state,
                dataByDriverTypeDraft: getUpdatedDataByDriverTypeDraft(state.dataByDriverTypeDraft),
            };
        }
    }
}
