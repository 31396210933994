import { ReactElement } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import GuestPrivateRoute from "./GuestPrivateRoute";
import { GuestOtp } from "../../pages/auth/GuestOtp";
import { PropertyExecutiveSummaryGuestWrapper } from "../../pages/property-executive-summary/PropertyExecutiveSummaryGuestWrapper";
import { ChartOfAccountsProvider } from "../../contexts/chartofaccounts/ChartOfAccountsContext";
import { MixPanelRouteWatcher } from "../../mixpanel/MixPanelRouteWatcher";

export function GuestRoute(): ReactElement {
    return (
        <>
            <MixPanelRouteWatcher />
            <Switch>
                <Route exact path="/guest/auth" component={() => <GuestOtp/>} />
                <GuestPrivateRoute exact path="/guest/report">
                    <ChartOfAccountsProvider>
                        <PropertyExecutiveSummaryGuestWrapper/>
                    </ChartOfAccountsProvider>
                </GuestPrivateRoute>
                <Route path="/guest">
                    <Redirect to="/guest/report"/>
                </Route>
                <Route path="*">
                    <Redirect to="/"/>
                </Route>
            </Switch>
        </>
    );
}