import React, {CSSProperties} from 'react';
import {
    StarredAccountMutationArgs,
    useUpsertStarredAccountsMutation
} from '../../__generated__/generated_types';
import {ReactComponent as StarStroke} from '@zendeskgarden/svg-icons/src/16/star-stroke.svg';
import {ReactComponent as StarFill} from '@zendeskgarden/svg-icons/src/16/star-fill.svg';
import styles from "./StarredAccount.module.scss";
import {useConfig} from "../../hooks/useConfig";

interface StarredAccountForAccountsTableViewProps {
    accountId: string
    reforecastVersionId: string
    budgetVersionId: string
    style?: CSSProperties
    onUpdate?: () => void
    isStarred: boolean
}

export const StarredAccountForAccountsTableView: React.VFC<StarredAccountForAccountsTableViewProps> = (props) => {

    const config = useConfig();

    const [upsertStarredAccounts] = useUpsertStarredAccountsMutation({
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        onCompleted: () => {
            props.onUpdate?.();
        },
    });

    const onStarAccountToggle = (toStar: boolean) => {
        const payload: StarredAccountMutationArgs[] = [
            props.reforecastVersionId, props.budgetVersionId
        ].map(e => {
            const commonPayload: StarredAccountMutationArgs = {
                propertyId: config.properties.currentProperty?.id ?? "",
                accountId: props.accountId,
                versionId: e ?? "",
                isStarred: toStar
            };
            return commonPayload;
        });
        upsertStarredAccounts({
            variables: {
                data: payload
            }
        });
    };

    return (
        <div style={props.style} className={styles.starIconContainer}>
            {
                props.isStarred ?
                    <StarFill onClick={() => {
                        onStarAccountToggle(false);
                    }}/>
                    :
                    <StarStroke onClick={() => {
                        onStarAccountToggle(true);
                    }}/>
            }
        </div>
    );
};
