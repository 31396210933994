/* eslint-disable @typescript-eslint/no-empty-function */
import React, {useContext, useEffect, useReducer, useState} from "react";
import {NewSpreadsheet} from "../../../components/spreadsheet/NewSpreadsheet";
import {useNewSpreadsheetAPI} from "../../../components/spreadsheet/NewSpreadsheetHooks";

import {useConfig} from "../config/useConfig";
import {analystRenovationStateReducer} from "./AnalystRenovationState";

import {AnalystRenovationEntityType, RenovationSetupDrawerMetadata,} from "./types";

import {AnalystCfg} from "../AnalystCfg";
import {AnalystTimeBasedLayoutConfig} from "../helpers/AnalystTimeBasedLayout";
import AnalystRenovationDrawer from "../../../components/analyst-renovations-drawers/AnalystRenovationDrawer";

import {AnalystSections} from "../../../components/analyst/section-tabs/AnalystSectionTabs";
import {useSettings} from "../../../contexts/settings/SettingsContext";
import useGetMonths from "../../../hooks/UseGetMonths";
import {
    GetAnalystRenovationsQuery,
    useGetAnalystRenovationsLazyQuery,
    useUpdateRenovationPackageCountMutation,
    VersionType
} from "../../../__generated__/generated_types";
import {AnalystRenovationsLayout} from "./AnalystRenovationsLayout";
import viewTemplate from "./analyst_renovations_view.json";
import {FinancialEntityCell} from "../../../components/spreadsheet/spreadjs-custom-cell-types/FinancialEntityCell";
import {RowEntityType} from "../enums";
import {isFinancialEntityCell, isRenovationPackageCell} from "../helpers/CustomCellHelpers";
import {
    PropertiesAndUnitTypes,
    useGetPropertiesAndUnitTypes
} from "../../../components/analyst-renovations-drawers/data/UseGetPropertiesAndUnitTypes";
import {AnalystDashboardProperties} from "../AnalystDashboardProperties";
import {AuthContext} from "../../../contexts/AuthContext";
import {userIsReadOnly} from "../../../authorization/AuthorizationCheckers";
import { useProperties } from "../../../contexts/properties/PropertiesContext";

type RenovationPackagesQueryData = GetAnalystRenovationsQuery["queryRenovationPackagesPaginated"]["items"];

export type RenovationData = {
    propertyData: PropertiesAndUnitTypes,
    renovationPackageData: RenovationPackagesQueryData|undefined,
    months: string[],
    year: number,
    startReforecastMonthIndex: number,
}

interface DataState {
    isDataReady: boolean;
    getRenovationData: () => RenovationData;
}


export function useAnalystRenovationsData(setLoadingProgress: React.Dispatch<React.SetStateAction<number>>, propertyId: string | undefined): DataState {
    const [isDataReady, setDataReady] = useState<boolean>(false);
    const [data, setData] = useState<RenovationPackagesQueryData | undefined>(undefined);
    const [partialData, setPartialData] = useState<RenovationPackagesQueryData>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const { year, startReforecastMonthIndex, isDefaults } = useSettings();
    // TODO - migrate to common place from renovation drawer
    const {isDataReady: isPropertyAndUnitTypesDataReady, data: propertiesAndUnitTypes} = useGetPropertiesAndUnitTypes();
    const months = useGetMonths();

    const [
        getRenovationPackages,
        { data: renovationPackageData, loading: renovationPackagesLoading, error: renovationPackagesError }
    ] = useGetAnalystRenovationsLazyQuery({
        // This fixes what looks like a bug in the Apollo cache system.
        // For whatever reason, with "network-only", this query can get into
        // a refresh loop without any subsequent calls to "getRenovationPackages"
        // in the useEffect.
        // Repo:
        //   1. Set this to "network-only"
        //   2. Navigate to Planning Hub > Financials
        //   3. Navigate to Planning Hub > Renovations
        //   4. Navigate to Reports > Overview
        //   5. Navigate to Planning Hub > Financials
        //   6. Navigate to Planning Hub > Renovations (watch infinite requests start happening on a schedule).
        fetchPolicy: "no-cache"
    });

    useEffect(() => {
        if(!renovationPackagesLoading && renovationPackageData && propertyId) {
            if(renovationPackageData.queryRenovationPackagesPaginated.hasMore && renovationPackageData.queryRenovationPackagesPaginated.cursor) {
                const propIds:string[] = [propertyId];
                const newPartialData = [...partialData, ...renovationPackageData.queryRenovationPackagesPaginated.items];
                setLoadingProgress(newPartialData.length / renovationPackageData.queryRenovationPackagesPaginated.total * 100.0);
                setPartialData(newPartialData);
                getRenovationPackages({
                    variables: {
                        cursor: renovationPackageData.queryRenovationPackagesPaginated.cursor,
                        propertyIds: propIds,
                        yearAndTypes: [
                            {
                                year: year,
                                versionType: VersionType.Reforecast
                            },
                            {
                                year: year + 1,
                                versionType: VersionType.Budget
                            }
                        ]
                    }
                });
            } else {
                setData([...partialData, ...renovationPackageData.queryRenovationPackagesPaginated.items]);
                setLoading(false);
                setLoadingProgress(100);
                setPartialData([]);
                setDataReady(true);
            }
        }
    }, [renovationPackagesLoading, renovationPackageData]);

    useEffect(() => {
            if(isDefaults || !isPropertyAndUnitTypesDataReady || !propertiesAndUnitTypes || !propertyId){
                return;
            }
            // We're going to assume all properties have the same budget and reforecast years.
            // That is safe assumption at this point and won't change.
            const propIds:string[] = [propertyId];
            setLoadingProgress(0);
            setData(undefined);
            setDataReady(false);
            getRenovationPackages({
                variables: {
                    propertyIds: propIds,
                    yearAndTypes: [
                        {
                            year: year,
                            versionType: VersionType.Reforecast
                        },
                        {
                            year: year + 1,
                            versionType: VersionType.Budget
                        }
                    ]
                }
            });
        },
        [isDefaults, isPropertyAndUnitTypesDataReady, propertiesAndUnitTypes, year, propertyId]);

    useEffect(
        () => {
            if(!isPropertyAndUnitTypesDataReady || !propertiesAndUnitTypes || !data || loading){
                setDataReady(false);
                return;
            }
            // setDataReady(true);
        },
        [isPropertyAndUnitTypesDataReady, propertiesAndUnitTypes, data, loading, renovationPackagesError]
    );

    const getRenovationData = (): RenovationData => {
        return {
            propertyData: propertiesAndUnitTypes.filter(proeprty => proeprty.id == propertyId),
            renovationPackageData: data,
            months,
            year,
            startReforecastMonthIndex
        };
    };

    return {
        isDataReady: isDataReady,
        getRenovationData: getRenovationData,
    };
}

const AnalystRenovations = (props: {
    setSectionReady: (sectionId: AnalystSections, isReady: boolean) => void,
    setLoadProgress: React.Dispatch<React.SetStateAction<number>>,
    hideSpreadsheet?: boolean,
    propertiesFilter: AnalystDashboardProperties,
    lockedProperties: {
        reforecastLocked: string[],
        budgetLocked: string[],
    },
}): JSX.Element => {

    const {
        setSectionReady,
        hideSpreadsheet,
        lockedProperties,
    } = props;

    const {
        handlers: sshandlers,
        api: ssapi,
        isSpreadsheetReady,
        cellTargetClicked,
        cellClicked,
        cellHovered,
        cellsChanged,
    } = useNewSpreadsheetAPI();

    const [state, dispatch] = useReducer(analystRenovationStateReducer, { rowToEntityMap: {}, totalRows: 0 });

    const [showRenovationSetupDrawer, setShowRenovationSetupDrawer] = useState<boolean>(false);
    const [renovationSetupDrawerMetadata, setRenovationSetupDrawerMetadata] = useState<RenovationSetupDrawerMetadata>({propertyId: "", unitTypeId: ""});
    const [layout, setLayout] = useState<AnalystRenovationsLayout|undefined>(undefined);
    const [year, setYear] = useState<number | undefined>(undefined);
    const [lastHoveredRow, setHoveredRow] = useState<number>(-1);

    const {currentProperty} = useProperties();
    const config = useConfig();
    const { isDataReady:renovationDataReady, getRenovationData } = useAnalystRenovationsData(props.setLoadProgress, currentProperty?.id);
    const [updateRenovationPackageCountMutation] = useUpdateRenovationPackageCountMutation();
    const { user } = useContext(AuthContext);

    useEffect(
        () => {
            if(!isSpreadsheetReady || !layout || !renovationDataReady){
                return;
            }
            ssapi.suspend();
            ssapi.setTemplate({ template: viewTemplate });
            ssapi.showRowOutline({ show: false });
            const renovationData = getRenovationData();
            if(renovationData){
                layout.initSheet('TOTAL');
                layout.renderRenovationMetrics(renovationData, renovationData.year, dispatch);
                setYear(renovationData.year);
            }

            if(userIsReadOnly(user)){
                ssapi.lockAllCells();
            }

            ssapi.resume();

            setSectionReady(AnalystSections.Renovations, true);
        },
        [isSpreadsheetReady, renovationDataReady, layout]
    );

    /**
     * Detect and process mouseover of Property/Account row
     */
    useEffect(
        () => {
            if(!cellHovered
                || !layout
                || cellHovered.row < AnalystCfg.FIRST_DATA_ROW
                || lastHoveredRow == cellHovered.row
                || !renovationDataReady
                ||  userIsReadOnly(user)
            ){
                return;
            }

            // Query the cell to see if a button or toggle was clicked
            layout.ssapi.directAccess(spread => {
                const sheet = spread.getSheetFromName(AnalystCfg.MAIN_TAB_NAME);

                if(isFinancialEntityCell(cellHovered.row, AnalystCfg.FINANCIAL_ENTITY_COL, sheet)){
                    const thisCell = sheet.getCell(cellHovered.row, AnalystCfg.FINANCIAL_ENTITY_COL);
                    const cellRef: FinancialEntityCell = (thisCell.cellType() as FinancialEntityCell);
                    const { rowEntityType } = cellRef.getCellVars();

                    if(rowEntityType == RowEntityType.UNIT_TYPE){
                        const cellInfo = cellRef.getCellInfo();
                        const parentId = (state.rowToEntityMap[cellHovered.row]?.parent.id ?? "") as string;

                        // if a property is locked for both forecast periods, dont show the setup button
                        if (lockedProperties.reforecastLocked.includes(parentId) && lockedProperties.budgetLocked.includes(parentId)) {
                            return;
                        }

                        cellRef.updateCellInfo({
                            ...cellInfo,
                            ...{ isButtonVisible: true }
                        });
                    }
                }

                const lastCell = sheet.getCell(lastHoveredRow, AnalystCfg.FINANCIAL_ENTITY_COL);
                if(isFinancialEntityCell(lastHoveredRow, AnalystCfg.FINANCIAL_ENTITY_COL, sheet)){
                    const cellRef: FinancialEntityCell = (lastCell.cellType() as FinancialEntityCell);
                    const { rowEntityType } = cellRef.getCellVars();

                    if(rowEntityType == RowEntityType.UNIT_TYPE){
                        const cellInfo = cellRef.getCellInfo();
                        cellRef.updateCellInfo({
                            ...cellInfo,
                            ...{ isButtonVisible: false }
                        });
                    }
                }
            });

            setHoveredRow(cellHovered.row);
        },
        [cellHovered?.row, layout]);

    const openPackageSetupDrawerIfNeeded = (row: number) => {
        if(userIsReadOnly(user)){
            return;
        }

        const rowInfo = state.rowToEntityMap[row];
        if(rowInfo?.entityType === AnalystRenovationEntityType.UNIT_TYPE){
            const id = (state.rowToEntityMap[row]?.entityID ?? "") as string;
            const parentId = (state.rowToEntityMap[row]?.parent.id ?? "") as string;

            // if a property is locked for both forecast periods, prevent opening of setup drawer
            if (lockedProperties.reforecastLocked.includes(parentId) && lockedProperties.budgetLocked.includes(parentId)) {
                return;
            }

            setRenovationSetupDrawerMetadata({ propertyId: parentId, unitTypeId: id });
            setShowRenovationSetupDrawer(true);
        }
    };

    useEffect(
        () => {
            if(!isSpreadsheetReady || !cellTargetClicked || !layout){
                return;
            }

            switch (cellTargetClicked?.col) {
                case AnalystCfg.FINANCIAL_ENTITY_COL: {

                    // Query the cell to see if a button or toggle was clicked
                    layout.ssapi.directAccess(spread => {
                        const sheet = spread.getSheetFromName(AnalystCfg.MAIN_TAB_NAME);
                        const { row, col } = cellTargetClicked;
                        const thisCell = sheet.getCell(row, col);
                        if(!isFinancialEntityCell(row, AnalystCfg.FINANCIAL_ENTITY_COL, sheet)){
                            return;
                        }
                        const cellRef: FinancialEntityCell = (thisCell.cellType() as FinancialEntityCell);
                        const { isButtonHovered, isToggleHovered } = cellRef.getCellVars();

                        if(isButtonHovered){
                            openPackageSetupDrawerIfNeeded(cellTargetClicked.row);
                        }

                        if(isToggleHovered){
                            layout.toggleSectionOpenState(cellTargetClicked.row);
                        }
                    });
                }
            }
        },
        [cellTargetClicked, layout]
    );

    useEffect(
        () => {
            if (isSpreadsheetReady && config.isReady && layout){
                const selectedPropertiesIds = props.propertiesFilter.selectedProperties.map(prop => prop.id);
                ssapi.suspend();
                Object.values(state.rowToEntityMap).
                filter(entity => entity.entityType === "PROPERTY").
                forEach(entity => {
                    const visible = selectedPropertiesIds.includes(entity.entityID as string);
                    ssapi.directAccess(spread => {
                        const sheet = spread.getSheetFromName(AnalystCfg.MAIN_TAB_NAME);
                        const thisCell = sheet.getCell(entity.idx, AnalystCfg.FINANCIAL_ENTITY_COL);
                        if(!isFinancialEntityCell(entity.idx, AnalystCfg.FINANCIAL_ENTITY_COL, sheet)) {
                            return;
                        }
                        const cellRef: FinancialEntityCell = (thisCell.cellType() as FinancialEntityCell);

                        const { level } = cellRef.getCellVars();

                        const rgi = sheet.rowOutlines.find(entity.idx + 1, level ?? 0);

                        if (rgi != null) {
                            for (let idx = entity.idx; idx <= rgi.end; idx++) {
                                ssapi.setRowVisible({rowIdx: idx, isVisible: visible, sheetName: AnalystCfg.MAIN_TAB_NAME});
                            }
                        }
                    });

                });
                ssapi.resume();

            }
        },
        [props.propertiesFilter.selectedProperties]
    );

    useEffect(
        () => {
            if(!isSpreadsheetReady || !cellClicked || !layout){
                return;
            }

            switch (cellClicked?.col) {
                case AnalystCfg.FINANCIAL_ENTITY_COL: {

                    // Query the cell to see if a button or toggle was clicked
                    layout.ssapi.directAccess(spread => {
                        const sheet = spread.getSheetFromName(AnalystCfg.MAIN_TAB_NAME);
                        const { row } = cellClicked;
                        if(!isFinancialEntityCell(row, AnalystCfg.FINANCIAL_ENTITY_COL, sheet)){
                            return;
                        }
                        layout.toggleSectionOpenState(cellClicked.row);

                    });
                }
            }
        },
        [cellClicked, layout]
    );

    const isChangedCellReforecastYear = (cell:{ row: number, col: number, value: number | null, sheetName?: string }) => {
        return cell.col > AnalystCfg.FIRST_DATA_COL - 1 && cell.col < AnalystCfg.THIS_YEAR_TOTALS_COL;
    };

    const isChangedCellBudgetYear = (cell:{ row: number, col: number, value: number | null, sheetName?: string }) => {
        return cell.col > AnalystCfg.FIRST_BUDGET_DATA_COL - 1 && cell.col < AnalystCfg.BUDGET_TOTALS_COL;
    };

    useEffect(
        () => {
            if(!isSpreadsheetReady || !layout || !renovationDataReady || (cellsChanged && cellsChanged.length == 0)){
                return;
            }

            layout.ssapi.directAccess(spread => {
                const sheet = spread.getSheetFromName(AnalystCfg.MAIN_TAB_NAME);

                sheet.suspendPaint();
                cellsChanged.forEach( update => {
                    if(update.value != null && isNaN(update.value)){
                        sheet.setValue(update.row, update.col, null);
                        // TODO: Reset value in cell editor for this cell
                        sheet.resumePaint();
                        return;
                    }
                });

                // Filter out cells that aren't in editable spaces
                const cells = cellsChanged.filter(
                    cell => (isChangedCellReforecastYear(cell) || isChangedCellBudgetYear(cell))
                );

                cells.forEach( cell => {
                    // Confirm that the cell that was edited was a renovation package (semi-redundant check)
                    if(isRenovationPackageCell(cell.row, sheet) && year){
                        const infoCell = sheet.getCell(cell.row, AnalystCfg.FINANCIAL_ENTITY_COL);
                        const cellRef = (infoCell.cellType() as FinancialEntityCell);
                        const { renovationPackageId } = cellRef.getCellInfo();
                        if(renovationPackageId === undefined) {
                            return;
                        }

                        let budgetType: VersionType;
                        let updateYear: number;
                        let monthIndex:number;
                        if(isChangedCellReforecastYear(cell)){
                            budgetType = VersionType.Reforecast;
                            updateYear = year;
                            monthIndex = cell.col - AnalystCfg.FIRST_DATA_COL;
                        } else {
                            budgetType = VersionType.Budget;
                            updateYear = year + 1;
                            monthIndex = cell.col - AnalystCfg.FIRST_BUDGET_DATA_COL;
                        }

                        updateRenovationPackageCountMutation({
                            variables: {
                                renovationPackageId: renovationPackageId,
                                yearAndType: {
                                    year: updateYear,
                                    versionType: budgetType
                                },
                                expected: [
                                    {
                                        monthIndex: monthIndex,
                                        value: cell.value
                                    }
                                ],
                            }
                        });
                    }
                });

                sheet.resumePaint();
            });
        },
        [cellsChanged]);

    useEffect(() => {
            if(isSpreadsheetReady && config.isReady){
                const { months, year, startReforecastMonthIndex } = config;
                ssapi.suspend();

                const layoutConfig: AnalystTimeBasedLayoutConfig = {
                    name: 'Renovations',
                    ssapi,
                    firstReforecastMonth: startReforecastMonthIndex,
                    months,
                    year,
                    financialEntityColLabel: "Renovations",
                    hideColumns: [AnalystCfg.DRIVER_COLUMN],
                    // Hardcode to false as this code is dead.
                    canRenderSetup: false,
                    lockedProperties,
                };
                setLayout(new AnalystRenovationsLayout(layoutConfig));
                ssapi.resume();
            }
        },
        [isSpreadsheetReady, config.isReady]);

    return (
        <React.Fragment>
            <div>
                <NewSpreadsheet
                    startRow={0}
                    startCol={0}
                    rows={1000}
                    cols={30}
                    handlers={sshandlers}
                    allowVerticalScroll={true}
                    allowHorizontalScroll={true}
                    subscribeToMouse={true}
                    safeAreaFirstRow={5}
                    safeAreaFirstColumn={4}
                    style={{
                        maxWidth: "calc(100vw - 80px)",
                        maxHeight: "calc(100vh - 208px - 26px)", // 208: header, 26: spreadsheet horiz. scrollbar
                        padding: "0 0", ...(hideSpreadsheet ? { display: "none" } : {})
                    }}
                    allowZoom={false}
                />
            </div>
            <div>
                {showRenovationSetupDrawer &&
                    <AnalystRenovationDrawer
                        show={showRenovationSetupDrawer}
                        propertyId={renovationSetupDrawerMetadata?.propertyId ?? ""}
                        unitTypeId={renovationSetupDrawerMetadata?.unitTypeId ?? ""}
                        handleClose={(_wasAnyUpdatesPersistedOnBE:boolean) => {
                            setShowRenovationSetupDrawer(false);
                        }}/>}
            </div>
        </React.Fragment>
    );
};

export default AnalystRenovations;
