import {CellValue} from "handsontable/common";
import {useEffect, useState} from "react";
import {useChartOfAccounts} from "../../../contexts/chartofaccounts/ChartOfAccountsContext";
import {useProperties} from "../../../contexts/properties/PropertiesContext";
import {getChartOfAccountsWithDepths, IFinancialEntityWithDepth} from "../../../utils/account";
import {useGetFinancialValuesForYearLazyQuery, VersionType, FinancialValuesYearModel} from "../../../__generated__/generated_types";

export type TGLAccountData = {
    data: FinancialValuesYearModel[] | undefined,
    coaWithDepths: IFinancialEntityWithDepth[] | undefined,
    startMonthIndex: number,
    focusYear: number,
    isReady: boolean,
    fetch: (accountId: string) => void,
}

export type TGLAccountsTableConfig = {
    colHeaders: string[],
    data: CellValue[][],
}

export default function useGLAccountData(): TGLAccountData {

    const properties = useProperties();

    // this the easiest way to deal with this for now,
    // but would be good to remove from the data service to another level above
    const coa = useChartOfAccounts();

    const [startMonthIndex, setStartMonthIndex] = useState<number>(0);
    const [focusYear, setFocusYear] = useState<number>(0);
    const [isReady, setIsReady] = useState<boolean>(false);
    const [coaWithDepths, setCOAWithDepths] = useState<IFinancialEntityWithDepth[] | undefined>();
    const [_fetch, {data, loading}] = useGetFinancialValuesForYearLazyQuery({
        fetchPolicy: "network-only"
    });

    useEffect(
        () => {
            if (!coa.isReady || !coa.chartOfAccounts || !properties.currentProperty) {
                return;
            }

            // not supposed to include top level components of depth 0, like Income
            const accountsWithDepth = getChartOfAccountsWithDepths(coa.chartOfAccounts);
            setCOAWithDepths(accountsWithDepth.filter(x => x.depth != 0).map(x => ({
                ...x,
                depth: x.depth - 1,
            })));

            const account = accountsWithDepth[0];
            if (!account) {
                return;
            }

            fetch(account.id);
        },
        [coa.isReady, properties.currentProperty]
    );

    useEffect(
        () => {
            if (!data || loading || !properties.currentProperty || !coaWithDepths) {
                setIsReady(false);
                return;
            }

            setStartMonthIndex(properties.currentProperty.reforecastStartMonthIndex);
            setFocusYear(properties.currentProperty.reforecastYear);
            setIsReady(true);
        },
        [data, loading, properties.currentProperty, coaWithDepths]
    );

    const fetch = (accountId: string): void => {
        if (!properties.currentProperty) {
            return;
        }

        _fetch({
            variables: {
                propertyIds: [properties.currentProperty.id],
                entityIds: [accountId],
                years: [
                    {year: properties.currentProperty.reforecastYear + 1, versionType: VersionType.Budget},
                    {year: properties.currentProperty.reforecastYear, versionType: VersionType.Reforecast},
                    {year: properties.currentProperty.reforecastYear, versionType: VersionType.Actuals},
                    {year: properties.currentProperty.reforecastYear - 1, versionType: VersionType.Actuals},
                    {year: properties.currentProperty.reforecastYear - 2, versionType: VersionType.Actuals},
                    {year: properties.currentProperty.reforecastYear - 3, versionType: VersionType.Actuals},
                ],
            }
        });
    };

    return {
        data: data?.financialValuesForYear.map(x => ({
            entityId: x.entityId,
            entityType: x.entityType,
            propertyId: x.propertyId,
            values: x.values,
            versionType: x.versionType,
            year: x.year,
        })),
        coaWithDepths,
        isReady,
        startMonthIndex,
        focusYear,
        fetch,
    };
}