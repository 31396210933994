import React, { ReactElement } from "react";
import * as css from "./styles/infoBlock.module.scss";

interface IContentProps {
    children: ReactElement[]|ReactElement|string|undefined,
    className?: string,
}

export default function Content(props: IContentProps): ReactElement {
    return (
            <div className={props.className ? `${css.content} ${props.className}` : css.content}>
                {
                    props.children != undefined
                            ? props.children
                            : <div className={css.noDataAvailable}>No data available</div>
                }
            </div>
    );
}