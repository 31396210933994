import React, { useEffect, useState } from 'react';

import { Body, Close, Footer, FooterItem, Header, Modal } from '@zendeskgarden/react-modals';
import { Dropdown, Field as FieldDD, Item, Label as LabelDD, Menu, Select } from '@zendeskgarden/react-dropdowns';
import { Col, Grid, Row } from '@zendeskgarden/react-grid';
import { Button } from '@zendeskgarden/react-buttons';
import { Tag } from '@zendeskgarden/react-tags';
import styles from "./UnitEventModal.module.scss";
import { CreateUnitEventMutationVariables, UnitEventType } from "../../../../__generated__/generated_types";
import { Field, Input, Label, MediaInput, Message } from "@zendeskgarden/react-forms";
import { Datepicker } from "@zendeskgarden/react-datepickers";
import { AllUnitEventTypes, AllUnitEventTypesShortLabel, LocalUnitEventModelChangeKeys } from "./helpers/structs";
import {
    ensureDateInLocale,
    ensureDateInUtc,
    getPrecisedFloatFromNumber,
    getPrecisedFloatFromString,
    getPrecisedStringFromString,
    UndefinedSafeStringify
} from "../../../../utils/helper-funcs";
import type { DropDownOption, LocalUnitEventModel, UlmEventModalProps } from "./helpers/types";
import { DateTime } from "luxon";
import { validateForm } from "./helpers/utils/fields-validations";
import {
    getEventTypeTagClassName,
    getEventTypeThemeClassName,
    getNextLocalStateFromPrevLocalState,
    initLocalState
} from "./helpers/utils/local-state";
import useCreateUpdateDeleteUnitEvent from "./data/hooks/UseCreateUpdateDeleteUnitEvent";
import { ReactComponent as LinkStroke } from '@zendeskgarden/svg-icons/src/16/link-stroke.svg';

import useEnrichPossibleUnitEvent from "./data/hooks/useEnrichPossibleUnitEvent";

export const UlmEventModal: React.VFC<UlmEventModalProps> = (props: UlmEventModalProps) => {

    const [localUnitEventModel, setLocalUnitEventModel] = useState<LocalUnitEventModel | undefined>(undefined);
    const [hasUnsavedData, setHasUnsavedData] = useState<boolean>(false);
    const [showDiscardChangesAlert, setShowDiscardChangesAlert] = useState(false);
    const [isFormValid, setIsFormValid] = useState<boolean>(true);
    const unitEventCrudData = useCreateUpdateDeleteUnitEvent();
    const [showDeleteUnitEventAlert, setShowDeleteUnitEventAlert] = useState(false);
    const [wasAnyUpdatesPersistedOnBE, setWasAnyUpdatesPersistedOnBE] = useState<boolean>(false);

    useEffect(() => {
        if (!props.unit) {
            return;
        }
        // Either of clickedDate or currentEvent must be present on the props
        if (!('clickedDate' in props || 'currentEvent' in props)) {
            return;
        }
        if ('currentEvent' in props && !props.currentEvent) {
            // console.warn(`The props has invalid currentEvent.`);
            return;
        }
        if ('clickedDate' in props && (!props.clickedDate || props.clickedDate.length < 1)) {
            // console.warn(`The props has invalid clickedDate.`);
            return;
        }
        const newLocalUnitEventModel = initLocalState(props);
        setLocalUnitEventModel(newLocalUnitEventModel);
    }, [props]);

    useEffect(() => {
        if ((unitEventCrudData.createApiStatus === "INIT" || unitEventCrudData.createApiStatus === "COMPLETED") &&
            (unitEventCrudData.updateApiStatus === "INIT" || unitEventCrudData.updateApiStatus === "COMPLETED") &&
            (unitEventCrudData.deleteApiStatus === "INIT" || unitEventCrudData.deleteApiStatus === "COMPLETED")) {
            setHasUnsavedData(false);
        }
    }, [unitEventCrudData.createApiStatus, unitEventCrudData.updateApiStatus, unitEventCrudData.deleteApiStatus]);

    useEffect(() => {
        if (
            unitEventCrudData.createApiStatus === "COMPLETED" ||
            unitEventCrudData.updateApiStatus === "COMPLETED" ||
            unitEventCrudData.deleteApiStatus === "COMPLETED"
        ) {
            /*
            Once the create/update/delete api call returns successfully
            Let's close the modal
             */
            handleMainModalClose(true);
        }
    }, [unitEventCrudData.createApiStatus, unitEventCrudData.updateApiStatus, unitEventCrudData.deleteApiStatus]);

    /*
        This function receives the updates from different form fields on the modal pop up
        Based on the respective form field new values the next/new state of the modal pop up is calculated
        Generally the new value will be
            string for text inputs/dropdown
            number for number type inputs
            date object for DatePicker
     */
    const updateLocalUnitEventModel = (key: LocalUnitEventModelChangeKeys, value: any) => {
        setHasUnsavedData(true);
        setUserUpdatedValue(true);
        setLocalUnitEventModel((prevState: LocalUnitEventModel | undefined): LocalUnitEventModel => {
            const nextState = getNextLocalStateFromPrevLocalState(props, prevState, key, value);
            setIsFormValid(validateForm(nextState));
            return nextState;
        });
    };

    const clearStates = () => {
        setShowDiscardChangesAlert(false);
        setShowDeleteUnitEventAlert(false);
        setLocalUnitEventModel(undefined);
        setHasUnsavedData(false);
        setWasAnyUpdatesPersistedOnBE(false);
    };

    // Modal functions
    const handleMainModalClose = (force = false) => {
        // console.info(`handleMainModalClose, force: ${force}, hasUnsavedData: ${hasUnsavedData}`);
        if (force) {
            setShowDiscardChangesAlert(false);
            clearStates();
            props.onClose(false, wasAnyUpdatesPersistedOnBE);
        } else {
            if (hasUnsavedData) {
                setShowDiscardChangesAlert(true);
            } else {
                setShowDiscardChangesAlert(false);
                clearStates();
                props.onClose(false, wasAnyUpdatesPersistedOnBE);
            }
        }
    };
    const handleDeleteUnitEvent = (force = false) => {
        // console.info(`handleDeleteUnitEvent, force: ${force}`);
        if (force) {
            setWasAnyUpdatesPersistedOnBE(true);
            /*
            After invoking the api, we wait for the api's return response
            If it returns successfully we close the modal else if there was some network/server error
            the user still has a chance to interact with the modal pop up
             */
            unitEventCrudData.deleteUnitEvent({
                id: localUnitEventModel?.currEventId ?? ""
            });
            setShowDeleteUnitEventAlert(false);
        }
    };
    const handleUnitEventDeleteAttempt = () => {
        setShowDeleteUnitEventAlert(true);
    };

    const buildCommonPayload = (localUnitEventModel:LocalUnitEventModel):Omit<CreateUnitEventMutationVariables, 'propertyId' | 'budgetYear' | 'unitId'>|undefined => {
        return localUnitEventModel.currStartDate ? {
            eventType: localUnitEventModel.currEventType,
            eventStart: localUnitEventModel.currStartDate.toISO(),
            eventEnd: localUnitEventModel.currEndDate ? localUnitEventModel.currEndDate.toISO() : undefined,
            inPlaceRent: localUnitEventModel.currMtmRent ? localUnitEventModel.currMtmRent.toString() : undefined,
            concessionMonths: localUnitEventModel.currLeaseConcession ? localUnitEventModel.currLeaseConcession.toString() : "0",
            renewalAdjustmentPercentage: localUnitEventModel.currRentIncreaseDecreasePercentage ? localUnitEventModel.currRentIncreaseDecreasePercentage.toString() : "0",
            shortTermEffectiveAvgDailyRent: localUnitEventModel.currEffectiveAverageDailyRate ? localUnitEventModel.currEffectiveAverageDailyRate.toString() : "0",
            renovationCost: localUnitEventModel.currRenovationCost ? localUnitEventModel.currRenovationCost.toString() : "0",
            renovationPremium: localUnitEventModel.currRenovationPremium ? localUnitEventModel.currRenovationPremium.toString() : "0"
        } : undefined;
    };

    const handleUnitEventSave = () => {
        if (localUnitEventModel &&
            localUnitEventModel.currEventType &&
            localUnitEventModel.currStartDate) {

            const commonPayload = buildCommonPayload(localUnitEventModel);
            if(!commonPayload){
                console.warn('Unable to build common event payload. Check localUnitEventModel.currStartDate');
                return;
            }

            setWasAnyUpdatesPersistedOnBE(true);

            /*
            After invoking the api, we wait for the api's return response
            If it returns successfully we close the modal else if there was some network/server error
            the user still has a chance to interact with the modal pop up
             */
            if (localUnitEventModel.currEventId === null) {
                unitEventCrudData.createUnitEvent({
                    ...commonPayload,
                    propertyId: props.propertyId,
                    budgetYear: props.budgetYear,
                    unitId: props.unit.id
                });
            } else if (localUnitEventModel.currEventId.length > 0) {
                unitEventCrudData.updateUnitEvent({
                    ...commonPayload,
                    id: localUnitEventModel.currEventId,
                });
            }
        } else {
            console.warn(`============== The localUnitEventData: ${UndefinedSafeStringify(localUnitEventModel)} is not valid.`);
        }
    };

    // Support for updating displayed event specs w/ possible calculated values ________________________________________
    const {
        requestEnrichedPossibleUnitEvent,
        enrichedPossibleUnitEventData,
        enrichedPossibleUnitEventLoading,
    } = useEnrichPossibleUnitEvent();

    useEffect(
            ()=>{
                if(enrichedPossibleUnitEventLoading || !enrichedPossibleUnitEventData){
                    return;
                }

                const evt = enrichedPossibleUnitEventData.enrichPossibleUnitEvent;
                const evtUpdate:Partial<LocalUnitEventModel> = {
                    currEventStartingCalculatedInPlaceRent: getPrecisedFloatFromString(evt.startingCalculatedInPlaceRent),
                    currEventEndingCalculatedInPlaceRent: getPrecisedFloatFromString(evt.endingCalculatedInPlaceRent),
                    currEventStartingLossToLease: getPrecisedFloatFromString(evt.startingLossToLease),
                    currEventEndingLossToLease: getPrecisedFloatFromString(evt.endingLossToLease)
                };

                setLocalUnitEventModel(((prevState: LocalUnitEventModel | undefined): LocalUnitEventModel => {
                    if(prevState){
                        return {
                            ...prevState,
                            ...evtUpdate,
                        };
                    } else {
                        return evtUpdate as LocalUnitEventModel;
                    }
                }));
            },
            [enrichedPossibleUnitEventData, enrichedPossibleUnitEventLoading]
    );

    const requiredPossibleEvtValuesPresent = () => {
        if(!localUnitEventModel){
            return false;
        }
        const evtType = localUnitEventModel.currEventType;

        if(!localUnitEventModel.currStartDate
                || !localUnitEventModel.currEndDate
                || (evtType == UnitEventType.MonthToMonth && localUnitEventModel.currMtmRent == null)
                || (evtType == UnitEventType.Renewal && localUnitEventModel.currRentIncreaseDecreasePercentage == null)
        ){
            return false;
        }

        return true;
    };

    const [userUpdatedValue, setUserUpdatedValue] = useState<boolean>(false);

    useEffect(
            ()=>{
                if(!localUnitEventModel || !userUpdatedValue || !requiredPossibleEvtValuesPresent()){
                    return;
                }

                setUserUpdatedValue(false);

                const commonPayload = buildCommonPayload(localUnitEventModel);
                const possibleEventVars = {
                    ...commonPayload,
                    propertyId: props.propertyId,
                    budgetYear: props.budgetYear,
                    unitId: props.unit.id,
                    eventType: localUnitEventModel.currEventType,
                    eventStart: localUnitEventModel.currStartDate,
                    eventEnd: localUnitEventModel.currEndDate,
                    renewalAdjustmentPercentage: localUnitEventModel.currRentIncreaseDecreasePercentage,
                    currMtmRent: localUnitEventModel.currMtmRent,
                };
                requestEnrichedPossibleUnitEvent(possibleEventVars);
            },
            [localUnitEventModel, userUpdatedValue]
    );

    const modalRootProbe = document.getElementById("modal-alert-root");
    const modalRoot = modalRootProbe == null ? undefined : modalRootProbe;

    if (!localUnitEventModel) {
        return <></>;
    }

    return (
        <>
            {showDiscardChangesAlert && (
                <Modal
                    onClose={() => setShowDiscardChangesAlert(false)}
                    appendToNode={modalRoot}
                    backdropProps={{
                        style: {
                            backgroundColor: "#33384233",
                            marginTop: "75px"
                        }
                    }}
                    focusOnMount={true}
                    isAnimated={false}
                >
                    <Header isDanger>Discard unsaved changes?</Header>
                    <Body>
                        Your unsaved changes will be lost.
                    </Body>
                    <Footer>
                        <FooterItem>
                            <Button onClick={() => setShowDiscardChangesAlert(false)} isBasic>
                                Cancel
                            </Button>
                        </FooterItem>
                        <FooterItem>
                            <Button isPrimary isDanger onClick={() => handleMainModalClose(true)}>
                                Discard
                            </Button>
                        </FooterItem>
                    </Footer>
                    <Close aria-label="Close modal"/>
                </Modal>
            )}
            {showDeleteUnitEventAlert && (
                <Modal
                    onClose={() => setShowDeleteUnitEventAlert(false)}
                    appendToNode={modalRoot}
                    backdropProps={{
                        style: {
                            backgroundColor: "#33384233",
                            marginTop: "75px"
                        }
                    }}
                    focusOnMount={true}
                    isAnimated={false}
                >
                    <Header isDanger>Confirm Delete</Header>
                    <Body>
                        Are you sure you want to delete this lease event? This can not be undone.
                    </Body>
                    <Footer>
                        <FooterItem>
                            <Button onClick={() => setShowDeleteUnitEventAlert(false)} isBasic>
                                Cancel
                            </Button>
                        </FooterItem>
                        <FooterItem>
                            <Button isPrimary isDanger onClick={() => handleDeleteUnitEvent(true)}>
                                Delete
                            </Button>
                        </FooterItem>
                    </Footer>
                    <Close aria-label="Close modal"/>
                </Modal>
            )}
            <Modal onClose={() => handleMainModalClose(false)}>
                <Header>{
                    /*
                        The non emtpy and valid value of currEventId show two possiblities
                            - An existing event was opened up from the main view
                            - The new event has been saved within the pop up and hence
                                the next operations are going to be editing this new event recently created
                                within the same life span since the launch of the pop up without closing it
                    */
                    localUnitEventModel.currEventId ? 'Edit' : 'Add'
                } Lease Event</Header>
                <Body>
                    <div className={styles.bodyContainer}>
                        <div className={styles.summaryContainer}>
                            <Grid>
                                <Row className={styles.rowBorderHeight + " " + styles.headingRow}>
                                    <Col className={styles.colBorder}>
                                        <Tag className={styles.unitNumberContainer}>
                                            <span>
                                                {
                                                    `Unit ${props.unit.number}`
                                                }
                                            </span>
                                        </Tag>
                                        <div className={styles.tenantNameContainer}>
                                            {
                                                localUnitEventModel.currEventTenantName
                                            }
                                        </div>
                                    </Col>
                                    <Col className={styles.colBorder}>
                                        <Row className={styles.rowBorderHeight}>
                                            <Col></Col>
                                            <Col size={7}>
                                                <div className={styles.middle}>
                                                    PRIOR EVENT
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row className={styles.rowBorderHeight}>
                                            <Col></Col>
                                            <Col size={5}>
                                                <div className={styles.middle}>
                                                    End Date
                                                    <br/>
                                                    {
                                                        localUnitEventModel.prevEndDate ?
                                                            localUnitEventModel.prevEndDate.toFormat('MM/dd/yyyy')
                                                            :
                                                            "-"
                                                    }
                                                </div>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col className={styles.colBorder + " " + getEventTypeThemeClassName(localUnitEventModel.currEventType)}>
                                        <Row className={styles.rowBorderHeight}>
                                            <Col>
                                                <div className={styles.middleCenter}>
                                                    <Tag className={getEventTypeTagClassName(localUnitEventModel.currEventType)}>
                                                        <span>
                                                            {
                                                                AllUnitEventTypesShortLabel[localUnitEventModel.currEventType]
                                                            }
                                                        </span>
                                                    </Tag>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row className={styles.rowHeight}>
                                            <Col size={5}>
                                                <div className={styles.middle}>
                                                    Start Date
                                                    <br/>
                                                    {
                                                        localUnitEventModel.currStartDate ?
                                                            localUnitEventModel.currStartDate.toFormat('MM/dd/yyyy')
                                                            :
                                                            "-"
                                                    }
                                                </div>
                                            </Col>
                                            <Col size={2}>
                                                <div className={styles.middle}>
                                                    {
                                                        "➔"
                                                    }
                                                </div>
                                            </Col>
                                            <Col size={5}>
                                                <div className={styles.middle}>
                                                    End Date
                                                    <br/>
                                                    {
                                                        localUnitEventModel.currEndDate ?
                                                            localUnitEventModel.currEndDate.toFormat('MM/dd/yyyy')
                                                            :
                                                            "-"
                                                    }
                                                </div>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>

                                {/* Market Rent row ________________________________________________________________ */}
                                <Row className={styles.rowBorderHeight}>
                                    <Col className={styles.colBorder}>
                                        <div className={styles.middle}>
                                            Market Rent
                                        </div>
                                    </Col>
                                    <Col className={styles.colBorder}>
                                        <Row className={styles.rowHeight}>
                                            <Col>

                                            </Col>
                                            <Col size={5}>
                                                <div className={styles.middle}>
                                                    {
                                                        props.prevEvent?.endingMarketRent
                                                                ? '$' + getPrecisedStringFromString(props.prevEvent?.endingMarketRent)
                                                                : 'n/a'
                                                    }
                                                </div>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col className={styles.colBorder + " " + getEventTypeThemeClassName(localUnitEventModel.currEventType)}>
                                        <Row className={styles.rowHeight}>
                                            <Col size={5}>
                                                <div className={styles.middle}>
                                                    {
                                                        localUnitEventModel.currEventStartingMarketRent
                                                                ? '$' + localUnitEventModel.currEventStartingMarketRent
                                                                : 'n/a'
                                                    }
                                                </div>
                                            </Col>
                                            <Col size={2}>

                                            </Col>
                                            <Col size={5}>
                                                <div className={styles.middle}>
                                                    {
                                                        localUnitEventModel.currEventEndingMarketRent
                                                                ? '$' + localUnitEventModel.currEventEndingMarketRent
                                                                : 'n/a'
                                                    }
                                                </div>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>

                                {/* In Place Rent row ______________________________________________________________ */}
                                <Row className={styles.rowBorderHeight}>
                                    <Col className={styles.colBorder}>
                                        <div className={styles.middle}>
                                            In Place Rent
                                        </div>
                                    </Col>
                                    <Col className={styles.colBorder}>
                                        <Row className={styles.rowHeight}>
                                            <Col>

                                            </Col>
                                            <Col size={5}>
                                                <div className={styles.middle}>
                                                    {
                                                        props.prevEvent?.endingCalculatedInPlaceRent
                                                                ? '$' + getPrecisedStringFromString(props.prevEvent?.endingCalculatedInPlaceRent)
                                                                : 'n/a'
                                                    }
                                                </div>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col className={styles.colBorder + " " + getEventTypeThemeClassName(localUnitEventModel.currEventType)}>
                                        <Row className={styles.rowHeight}>
                                            <Col size={5}>
                                                <div className={styles.middle}>
                                                    {
                                                        localUnitEventModel.currEventStartingCalculatedInPlaceRent
                                                                ? '$' + localUnitEventModel.currEventStartingCalculatedInPlaceRent
                                                                : 'n/a'
                                                    }
                                                </div>
                                            </Col>
                                            <Col size={2}>

                                            </Col>
                                            <Col size={5}>
                                                <div className={styles.middle}>
                                                    {
                                                        localUnitEventModel.currEventEndingCalculatedInPlaceRent
                                                                ? '$' + localUnitEventModel.currEventEndingCalculatedInPlaceRent
                                                                : 'n/a'
                                                    }
                                                </div>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>

                                {/* Lease to Loss Ratio row ________________________________________________________ */}
                                <Row className={styles.rowBorderHeight}>
                                    <Col className={styles.colBorder}>
                                        <div className={styles.middle}>
                                            Loss to Lease Ratio
                                        </div>
                                    </Col>
                                    <Col className={styles.colBorder}>
                                        <Row className={styles.rowHeight}>
                                            <Col>

                                            </Col>
                                            <Col size={5}>
                                                <div className={styles.middle}>
                                                    {
                                                        props.prevEvent?.endingLossToLease != null
                                                                ? getPrecisedFloatFromString(props.prevEvent?.endingLossToLease, 1) + '%'
                                                                : 'n/a'
                                                    }
                                                </div>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col className={styles.colBorder + " " + getEventTypeThemeClassName(localUnitEventModel.currEventType)}>
                                        <Row className={styles.rowHeight}>
                                            <Col size={5}>
                                                <div className={styles.middle}>
                                                    {
                                                        localUnitEventModel.currEventStartingLossToLease != null
                                                                ? getPrecisedFloatFromNumber(localUnitEventModel.currEventStartingLossToLease, 1) + '%'
                                                                : 'n/a'
                                                    }
                                                </div>
                                            </Col>
                                            <Col size={2}>
                                            </Col>
                                            <Col size={5}>
                                                <div className={styles.middle}>
                                                    {
                                                        localUnitEventModel.currEventEndingLossToLease != null
                                                                ? getPrecisedFloatFromNumber(localUnitEventModel.currEventEndingLossToLease, 1) + '%'
                                                                : 'n/a'
                                                    }
                                                </div>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Grid>
                        </div>
                        <div className={styles.fieldsContainer}>
                            <Grid>
                                <Row className={styles.rowResetPadding}>
                                    <Col className={styles.col}>
                                        <Field className={styles.field}>
                                            <Dropdown
                                                selectedItem={AllUnitEventTypes[localUnitEventModel.currEventType]}
                                                onSelect={(item: DropDownOption) => {
                                                    updateLocalUnitEventModel(
                                                        LocalUnitEventModelChangeKeys.eventType,
                                                        item.value
                                                    );
                                                }}
                                                downshiftProps={{itemToString: (item: DropDownOption) => item && item.label}}
                                            >
                                                <FieldDD>
                                                    <LabelDD className={styles.label}>Event</LabelDD>
                                                    <Select>{AllUnitEventTypes[localUnitEventModel.currEventType].label}</Select>
                                                </FieldDD>
                                                <Menu>
                                                    {Object.values(AllUnitEventTypes).map(option => (
                                                        <Item key={option.value} value={option}>
                                                            {option.label}
                                                        </Item>
                                                    ))}
                                                </Menu>
                                            </Dropdown>
                                        </Field>
                                    </Col>
                                </Row>
                                <Row className={styles.rowResetPadding}>
                                    <Col className={styles.col} size={5}>
                                        <Field className={styles.field}>
                                            <Label className={styles.label}>Days Vacant</Label>
                                            <MediaInput
                                                end={<><div className={styles.inputSuffixDays}>days</div></>}
                                                value={localUnitEventModel.daysVacant ?? ''}
                                                type={"number"}
                                                min={0}
                                                disabled={!props.prevEvent || !props.prevEvent.eventEnd}
                                                onChange={(evt) => {
                                                    updateLocalUnitEventModel(
                                                        LocalUnitEventModelChangeKeys.daysVacant,
                                                        evt.target.value
                                                    );
                                                }
                                            }/>
                                            <Message validation="error"
                                                     hidden={localUnitEventModel.daysVacantErrMsg === null}>
                                                {localUnitEventModel.daysVacantErrMsg}
                                            </Message>
                                        </Field>
                                    </Col>
                                    <Col className={styles.col} size={2}>
                                        <div className={styles.middleCenter}>
                                            <LinkStroke className={styles.linkIcon}/>
                                        </div>
                                    </Col>
                                    <Col className={styles.col} size={5}>
                                        <Field className={styles.field}>
                                            <Label className={styles.label}>Start Date</Label>
                                            <Datepicker
                                                value={localUnitEventModel.currStartDate ? ensureDateInLocale(localUnitEventModel.currStartDate.toISO()) : undefined}
                                                minValue={localUnitEventModel.prevEndDate ? ensureDateInLocale(localUnitEventModel.prevEndDate.plus({days: 1}).toISO()) : undefined}
                                                maxValue={localUnitEventModel.currMaxStartDate ? ensureDateInLocale(localUnitEventModel.currMaxStartDate.toISO()) : undefined}
                                                onChange={(selectedDate) => {
                                                    const newSelectedDateStr = ensureDateInUtc(selectedDate)?.toISOString();
                                                    if (newSelectedDateStr) {
                                                        setUserUpdatedValue(true);
                                                        updateLocalUnitEventModel(
                                                            LocalUnitEventModelChangeKeys.startDate,
                                                            DateTime.fromISO(newSelectedDateStr, {setZone: true})
                                                        );
                                                    }
                                                }}
                                                formatDate={selectedDate => {
                                                    const newSelectedDateStr = ensureDateInUtc(selectedDate)?.toISOString();
                                                    if (newSelectedDateStr) {
                                                        return DateTime.fromISO(
                                                            newSelectedDateStr, {setZone: true}
                                                        ).toFormat('MM/dd/yyyy');
                                                    }
                                                    return "";
                                                }}
                                            >
                                                <Input/>
                                            </Datepicker>
                                            <Message validation="error"
                                                     hidden={localUnitEventModel.currStartDateErrMsg === null}>
                                                {localUnitEventModel.currStartDateErrMsg}
                                            </Message>
                                        </Field>
                                    </Col>
                                </Row>
                                <Row className={styles.rowResetPadding}>
                                    <Col className={styles.col} size={5}>
                                        {
                                            localUnitEventModel.currEventType !== UnitEventType.Renovation &&
                                            <Field className={styles.field}>
                                                <Dropdown
                                                    selectedItem={localUnitEventModel.currLeaseTerm && localUnitEventModel.currAllLeaseTermDdOptions[localUnitEventModel.currLeaseTerm]}
                                                    onSelect={(item: DropDownOption) => {
                                                        updateLocalUnitEventModel(
                                                            LocalUnitEventModelChangeKeys.leaseTerm,
                                                            item.value
                                                        );
                                                    }}
                                                    downshiftProps={{itemToString: (item: DropDownOption) => item && item.label}}
                                                >
                                                    <FieldDD>
                                                        <LabelDD className={styles.label}>
                                                            {
                                                                localUnitEventModel.currEventType === UnitEventType.ShortTermRental ?
                                                                    "Duration" : "Lease Term"
                                                            }
                                                        </LabelDD>
                                                        <Select>{(localUnitEventModel.currLeaseTerm && localUnitEventModel.currAllLeaseTermDdOptions[localUnitEventModel.currLeaseTerm]?.label) ?? ""}</Select>
                                                    </FieldDD>
                                                    <Menu>
                                                        {Object.values(localUnitEventModel.currAllLeaseTermDdOptions).map(option => (
                                                            <Item key={option.value} value={option}
                                                                  disabled={option.isDisabled === true}
                                                                  className={styles.optionItem}>
                                                                <div>
                                                                    {option.label}
                                                                </div>
                                                                {
                                                                    option.disabledReason &&
                                                                    <div className={styles.disabledReason}>
                                                                        {
                                                                            option.disabledReason
                                                                        }
                                                                    </div>
                                                                }
                                                            </Item>
                                                        ))}
                                                    </Menu>
                                                </Dropdown>
                                                <Message validation="error"
                                                         hidden={localUnitEventModel.currLeaseTermErrMsg === null}>
                                                    {localUnitEventModel.currLeaseTermErrMsg}
                                                </Message>
                                            </Field>
                                        }
                                        {
                                            localUnitEventModel.currEventType === UnitEventType.Renovation &&
                                            <Field className={styles.field}>
                                                <Label className={styles.label}>Renovation Duration</Label>
                                                <MediaInput
                                                    end={<><div className={styles.inputSuffixDays}>days</div></>}
                                                    value={localUnitEventModel.currRenovationDuration ?? ''}
                                                    type={"number"}
                                                    min={1}
                                                    onChange={(evt) => {
                                                        updateLocalUnitEventModel(
                                                            LocalUnitEventModelChangeKeys.renovationDuration,
                                                            evt.target.value
                                                        );
                                                    }}/>
                                                <Message validation="error"
                                                         hidden={localUnitEventModel.currRenovationDurationErrMsg === null}>
                                                    {localUnitEventModel.currRenovationDurationErrMsg}
                                                </Message>
                                            </Field>
                                        }
                                    </Col>
                                    <Col className={styles.col} size={2}>
                                        <div className={styles.middleCenter}>
                                            <LinkStroke className={styles.linkIcon}/>
                                        </div>
                                    </Col>
                                    <Col className={styles.col} size={5}>
                                        <Field className={styles.field}>
                                            <Label className={styles.label}>End Date</Label>
                                            <Datepicker
                                                value={localUnitEventModel.currEndDate ? ensureDateInLocale(localUnitEventModel.currEndDate.toISO()) : undefined}
                                                minValue={localUnitEventModel.currStartDate ? ensureDateInLocale(localUnitEventModel.currStartDate.toISO()) : undefined}
                                                maxValue={localUnitEventModel.nextStartDate ? ensureDateInLocale(localUnitEventModel.nextStartDate.minus({days: 1}).toISO()) : undefined}
                                                onChange={(selectedDate) => {
                                                    const newSelectedDateStr = ensureDateInUtc(selectedDate)?.toISOString();
                                                    if (newSelectedDateStr) {
                                                        setUserUpdatedValue(true);
                                                        updateLocalUnitEventModel(
                                                            LocalUnitEventModelChangeKeys.endDate,
                                                            DateTime.fromISO(newSelectedDateStr, {setZone: true})
                                                        );
                                                    }
                                                }}
                                                formatDate={selectedDate => {
                                                    const newSelectedDateStr = ensureDateInUtc(selectedDate)?.toISOString();
                                                    if (newSelectedDateStr) {
                                                        return DateTime.fromISO(
                                                            newSelectedDateStr, {setZone: true}
                                                        ).toFormat('MM/dd/yyyy');
                                                    }
                                                    return "";
                                                }}
                                            >
                                                <Input onClick={() => {
                                                    if(localUnitEventModel && localUnitEventModel.currEndDate === null) {
                                                        updateLocalUnitEventModel(
                                                            LocalUnitEventModelChangeKeys.endDateClicked,
                                                            null
                                                        );
                                                    }
                                                }}/>
                                            </Datepicker>
                                            <Message validation="error"
                                                     hidden={localUnitEventModel.currEndDateErrMsg === null}>
                                                {localUnitEventModel.currEndDateErrMsg}
                                            </Message>
                                        </Field>
                                    </Col>
                                </Row>
                                <Row className={styles.rowResetPadding}>
                                    <Col className={styles.col}>
                                        {
                                            localUnitEventModel.currEventType === UnitEventType.Renewal &&
                                            <Field className={styles.field}>
                                                <Label className={styles.label}>Rent Increase/Decrease %</Label>
                                                <MediaInput
                                                    end={<><div className={styles.inputSuffixPercentage}>%</div></>}
                                                    value={localUnitEventModel.currRentIncreaseDecreasePercentage ?? ''}
                                                    type={"number"}
                                                    onChange={(evt) => {
                                                        updateLocalUnitEventModel(
                                                            LocalUnitEventModelChangeKeys.rentIncreaseDecreasePercentage,
                                                            evt.target.value
                                                        );
                                                    }
                                                }/>
                                                <Message validation="error"
                                                         hidden={localUnitEventModel.currRentIncreaseDecreasePercentageErrMsg === null}>
                                                    {localUnitEventModel.currRentIncreaseDecreasePercentageErrMsg}
                                                </Message>
                                            </Field>
                                        }
                                        {
                                            (localUnitEventModel.currEventType === UnitEventType.TermLease ||
                                             localUnitEventModel.currEventType === UnitEventType.Renewal) &&
                                            <Field className={styles.field}>
                                                <Label className={styles.label}>Lease Concession</Label>
                                                <MediaInput
                                                    end={<><div className={styles.inputSuffixMonths}>months</div></>}
                                                    value={localUnitEventModel.currLeaseConcession ?? ''}
                                                    type={"number"}
                                                    onChange={(evt) => {
                                                        updateLocalUnitEventModel(
                                                            LocalUnitEventModelChangeKeys.leaseConcession,
                                                            evt.target.value
                                                        );
                                                    }}/>
                                            </Field>
                                        }
                                        {
                                            localUnitEventModel.currEventType === UnitEventType.ShortTermRental &&
                                            <Field className={styles.field}>
                                                <Label className={styles.label}>Effective Average Daily Rate</Label>
                                                <MediaInput
                                                    start={<><div className={styles.inputPrefixDollor}>$</div></>}
                                                    value={localUnitEventModel.currEffectiveAverageDailyRate ?? ''}
                                                    type={"number"}
                                                    onChange={(evt) => {
                                                        updateLocalUnitEventModel(
                                                            LocalUnitEventModelChangeKeys.effectiveAverageDailyRate,
                                                            evt.target.value
                                                        );
                                                    }}/>
                                            </Field>
                                        }
                                        {
                                            localUnitEventModel.currEventType === UnitEventType.MonthToMonth &&
                                            <Field className={styles.field}>
                                                <Label className={styles.label}>MTM Rent</Label>
                                                <MediaInput
                                                    start={<><div className={styles.inputPrefixDollor}>$</div></>}
                                                    value={localUnitEventModel.currMtmRent ?? ''}
                                                    type={"number"}
                                                    onChange={(evt) => {
                                                        updateLocalUnitEventModel(
                                                                LocalUnitEventModelChangeKeys.mtmRent,
                                                                evt.target.value
                                                        );
                                                    }}/>
                                            </Field>
                                        }
                                        {
                                            localUnitEventModel.currEventType === UnitEventType.Renovation &&
                                            <>
                                                <Field className={styles.field}>
                                                    <Label className={styles.label}>Renovation Premium</Label>
                                                    <MediaInput
                                                        start={<><div className={styles.inputPrefixDollor}>$</div></>}
                                                        value={localUnitEventModel.currRenovationPremium ?? ''}
                                                        type={"number"}
                                                        onChange={(evt) => {
                                                            updateLocalUnitEventModel(
                                                                LocalUnitEventModelChangeKeys.renovationPremium,
                                                                evt.target.value
                                                            );
                                                        }}/>
                                                    <Message validation="error"
                                                             hidden={localUnitEventModel.currRenovationPremiumErrMsg === null}>
                                                        {localUnitEventModel.currRenovationPremiumErrMsg}
                                                    </Message>
                                                </Field>
                                                <Field className={styles.field}>
                                                    <Label className={styles.label}>Renovation Cost</Label>
                                                    <MediaInput
                                                        start={<><div className={styles.inputPrefixDollor}>$</div></>}
                                                        value={localUnitEventModel.currRenovationCost ?? ''}
                                                        type={"number"}
                                                        onChange={(evt) => {
                                                            updateLocalUnitEventModel(
                                                                LocalUnitEventModelChangeKeys.renovationCost,
                                                                evt.target.value
                                                            );
                                                        }}/>
                                                    <Message validation="error"
                                                             hidden={localUnitEventModel.currRenovationCostErrMsg === null}>
                                                        {localUnitEventModel.currRenovationCostErrMsg}
                                                    </Message>
                                                </Field>
                                            </>
                                        }
                                    </Col>
                                </Row>
                            </Grid>
                        </div>
                    </div>
                </Body>
                <Footer className={styles.footer}>
                    <FooterItem>
                        <Button isDanger
                                isBasic
                                onClick={() => handleUnitEventDeleteAttempt()}>
                            Delete
                        </Button>
                    </FooterItem>
                    <div className={styles.right}>
                        <FooterItem>
                            <Button isBasic
                                    onClick={() => handleMainModalClose(false)}>
                                Cancel
                            </Button>
                        </FooterItem>
                        <FooterItem>
                            <Button isPrimary
                                    disabled={!isFormValid || !hasUnsavedData}
                                    onClick={() => handleUnitEventSave()}>
                                {
                                    localUnitEventModel.currEventId ? 'Update' : 'Add'
                                }
                            </Button>
                        </FooterItem>
                    </div>
                </Footer>
                <Close aria-label="Close modal"/>
            </Modal>
        </>
    );
};
