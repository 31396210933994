import { formatterDollarUSNoDecimal, formatterDollarUSNoDecimalSignAlways, formatterPercent, formatterPercentAutoSign, formatterPercentWithSign } from "../../../utils/formatters";
import { PropertyExecutiveSummaryData } from "./usePropertyExecutiveSummaryData";
import css from "../styles/css.module.scss";
import { Fragment } from "react";
import { COLORS } from "../../../constants/Colors";
import { Text } from "@react-pdf/renderer";

export interface ISummaryStringOutput {
    asString: string;
    asJSX: JSX.Element;
    asPDF: JSX.Element;
}

export function formatAsJSX(input: string): JSX.Element {
    const formattingString = /___FORMATTING_SPAN___/g;
    const splitParts = input.split(formattingString);

    if (splitParts.length % 2 === 0) {
        return <></>;
    }

    const output = splitParts.map((part, index) => {
        if (index % 2 === 0) {
            return part; // only odd indexes are our token replacements, so on even just return text
        }

        let className = "";

        if (part.includes("+")) {
            className = css.textPositive;
        } else if (part.includes("-")) {
            className = css.textNegative;
        }

        return (
            <span key={index} className={className}>
                {part}
            </span>
        );
    });

    return <>{output}</>;
}

export function formatAsPDF(input: string): JSX.Element {
    const formattingString = /___FORMATTING_SPAN___/g;
    const splitParts = input.split(formattingString);

    if (splitParts.length % 2 === 0) {
        return <></>;
    }

    const output = splitParts.map((part, index) => {
        if (index % 2 === 0) {
            return part; // only odd indexes are our token replacements, so on even just return text
        }

        let style = {};

        if (part.includes("+")) {
            style = {color: COLORS.GREEN_500, fontWeight: 500};
        } else if (part.includes("-")) {
            style = {color: COLORS.RED_500, fontWeight: 500};
        }

        return (
            <Text key={index} style={style}>
                {part}
            </Text>
        );
    });

    return <>{output}</>;
}

export function buildExecutiveSummaryStatementNOI(data: PropertyExecutiveSummaryData | undefined): string {
    if (!data) {
        return "";
    }

    let ret = `${data.property.name} is projected to`;
    if (data.noiDelta > 0) {
        ret = ret.concat(
            ` increase NOI in ${data.property.budgetYear} by ___FORMATTING_SPAN___${formatterDollarUSNoDecimal.format(data.noiDelta)}`);
        if (data.noiDeltaPercent != 0) {
            ret = ret.concat(` (${formatterPercentWithSign.format(data.noiDeltaPercent)})`);
        }
        ret = ret.concat('___FORMATTING_SPAN___');
    }
    else if (data.noiDelta < 0) {
        ret = ret.concat(
            ` decrease NOI in ${data.property.budgetYear} by ___FORMATTING_SPAN___${formatterDollarUSNoDecimal.format(-data.noiDelta)}`);
        if (data.noiDeltaPercent != 0) {
            ret = ret.concat(` (${formatterPercentWithSign.format(data.noiDeltaPercent)})`);
        }
        ret = ret.concat('___FORMATTING_SPAN___');
    }
    else {
        ret = ret.concat(
            ` have no changes in NOI in ${data.property.budgetYear} compared to ${data.property.reforecastYear}`
        );
    }
    ret += ".";

    return ret;
}

export function buildExecutiveSummaryStatementRents(data: PropertyExecutiveSummaryData | undefined): string {
    if (!data || data.avgMarketRentBudget == null || data.avgMarketRentReforecast == null) {
        return "";
    }
    let ret = " Rents are forecasted to";
    if (data.avgMarketRentDeltaPercent == 0 || data.avgMarketRentDeltaPercent == null) {
        ret = ret.concat(
            ` be at ___FORMATTING_SPAN___${formatterDollarUSNoDecimal.format(data.avgMarketRentBudget)}___FORMATTING_SPAN___ by December ${data.property.budgetYear}`
        );
    }
    else {
        ret = ret.concat(
            ` ${data.avgMarketRentDeltaPercent < 0 ? "decrease" : "increase"} from`,
            ` ___FORMATTING_SPAN___${formatterDollarUSNoDecimal.format(data.avgMarketRentReforecast)}`,
            ` to ${formatterDollarUSNoDecimal.format(data.avgMarketRentBudget)}`,
            ` (${formatterPercentWithSign.format(data.avgMarketRentDeltaPercent)})___FORMATTING_SPAN___`,
            ` by December ${data.property.budgetYear}`
        );
    }
    if (data.occupancyStat.budgetAvgOccupancy != null) {
        ret = ret.concat(
            ` at an average occupancy of ___FORMATTING_SPAN___${formatterPercent.format(data.occupancyStat.budgetAvgOccupancy)}___FORMATTING_SPAN___`
        );
    }
    ret += ".";

    return ret;
}

export function buildExecutiveSummaryStatementRentalIncome(data: PropertyExecutiveSummaryData | undefined): string {
    if (!data) {
        return "";
    }

    let ret = "";
    if (data.rentalIncomeDelta != 0) {
        ret = ` Overall change in rental income is expected at ___FORMATTING_SPAN___${formatterDollarUSNoDecimalSignAlways.format(data.rentalIncomeDelta)}`;
        if (data.rentalIncomeDeltaPercent != 0) {
            ret = ret.concat(` (${formatterPercentWithSign.format(data.rentalIncomeDeltaPercent)})___FORMATTING_SPAN___`);
        }
        ret = ret.concat(".");
    }
    else {
        ret = " No change is expected in rental income.";
    }

    return ret;
}

export function buildExecutiveSummaryStatementTotalExpense(data: PropertyExecutiveSummaryData | undefined): string {
    if (!data) {
        return "";
    }
    let ret = " Total Operating Expense growth is projected to";
    if (data.totalExpenseDelta > 0) {
        ret = ret.concat(
            ` increase by ___FORMATTING_SPAN___${formatterDollarUSNoDecimal.format(data.totalExpenseDelta)}`);
        if (data.totalExpenseDeltaPercent != 0) {
            ret = ret.concat(` (${formatterPercentWithSign.format(data.totalExpenseDeltaPercent)})___FORMATTING_SPAN___`);
        }
    }
    else if (data.totalExpenseDelta < 0) {
        ret = ret.concat(
            ` decrease by ___FORMATTING_SPAN___${formatterDollarUSNoDecimal.format(-data.totalExpenseDelta)}`);
        if (data.totalExpenseDeltaPercent != 0) {
            ret = ret.concat(` (${formatterPercentWithSign.format(data.totalExpenseDeltaPercent)})___FORMATTING_SPAN___`);
        }
    }
    else {
        ret = ret.concat(
            ` have have no changes`
        );
    }
    ret += ".";

    return ret;
}

export function buildRentsStatementMarketRent(data: PropertyExecutiveSummaryData | undefined): string {
    if (!data
        || data.budgetedAvgMarketRent.budgetedAvgBudgetMarketRent == null
        || data.budgetedAvgMarketRent.budgetedAvgReforecastMarketRent == null
    ) {
        return "";
    }

    let ret = "Rents";

    if (data.budgetedAvgMarketRent.varianceAmount !== null) {
        if (data.budgetedAvgMarketRent.varianceAmount > 0) {
            ret = ret.concat(
                ` are increasing`
            );
        }
        else {
            ret = ret.concat(
                ` are decreasing`
            );
        }
        ret = ret.concat(
            ` from ${formatterDollarUSNoDecimal.format(data.budgetedAvgMarketRent.budgetedAvgReforecastMarketRent)}`,
            ` to ${formatterDollarUSNoDecimal.format(data.budgetedAvgMarketRent.budgetedAvgBudgetMarketRent)}`,
        );

        ret += ".";
    }

    if (data.budgetedAvgMarketRent.variancePercent !== null) {
        ret = ret.concat(" A projected");
        if (data.budgetedAvgMarketRent.variancePercent > 0) {
            ret += " increase";
        }
        else {
            ret += " decrease";
        }
        ret = ret.concat(
            ` of ${formatterPercentWithSign.format(data.budgetedAvgMarketRent.variancePercent)}`,
            ` by December ${data.property.budgetYear}`
        );
    }

    // Rents are increasing from $1,134 to $1,169. A projected increase of +3% by December 2025.

    return ret;
}

export function buildRentsStatementNewLeaseTradeOut(data: PropertyExecutiveSummaryData | undefined): string {
    if (!data) {
        return "";
    }

    let ret = "";
    if (data.newLeaseTradeOut !== null) {
        ret = formatterDollarUSNoDecimal.format(data.newLeaseTradeOut);
        if (data.newLeaseTradeOutPercent !== null) {
            ret = ret.concat(` (${formatterPercent.format(data.newLeaseTradeOutPercent)})`);
        }
        ret = ret.concat(" New Lease Trade Out");
    }

    return ret;
}

export function buildRentsStatementRenewalTradeOut(data: PropertyExecutiveSummaryData | undefined): string {
    if (!data) {
        return "";
    }

    let ret = "";
    if (data.renewalTradeOut !== null) {
        ret = formatterDollarUSNoDecimal.format(data.renewalTradeOut);
        if (data.renewalTradeOutPercent !== null) {
            ret = ret.concat(` (${formatterPercent.format(data.renewalTradeOutPercent)})`);
        }
        ret = ret.concat(" Renewal Trade Out");
    }

    return ret;
}

export function buildRentsStatementTradeouts(data: PropertyExecutiveSummaryData | undefined): string {
    if (!data) {
        return "";
    }

    // "New Lease Trade Out projected at +$35 (3.1%). Renewal Trade Out projected at +$11 (1.0%)."
    let ret = "";
    if (data.newLeaseTradeOut !== null) {
        ret = ret.concat(
            `New Lease Trade Out projected at ${formatterDollarUSNoDecimal.format(data.newLeaseTradeOut)}`
        );
        if (data.newLeaseTradeOutPercent !== null) {
            ret = ret.concat(` (${formatterPercent.format(data.newLeaseTradeOutPercent)})`);
        }
        ret += ".";
    }

    if (data.renewalTradeOut !== null) {
        ret = ret.concat(
            ` Renewal Trade Out projected at ${formatterDollarUSNoDecimal.format(data.renewalTradeOut)}`
        );
        if (data.renewalTradeOutPercent !== null) {
            ret = ret.concat(` (${formatterPercent.format(data.renewalTradeOutPercent)})`);
        }
        ret += ".";
    }

    return ret;
}

export function buildRentsStatementOccupancy(data: PropertyExecutiveSummaryData | undefined): string {
    if (!data || data.occupancyStat.budgetAvgOccupancy === null) {
        return "";
    }
    // "The Average Occupancy is 94.1%. A projected increase of +$1.0% from December 2024."
    let ret = `The Average Occupancy is ${formatterPercentAutoSign.format(data.occupancyStat.budgetAvgOccupancy)}.`;
    if (data.occupancyStat.reforecastDecember !== null) {
        const delta = data.occupancyStat.budgetAvgOccupancy - data.occupancyStat.reforecastDecember;
        ret = ret.concat(
            ` A projected ${delta > 0 ? "increase" : "decrease"} of ${formatterPercentWithSign.format(delta)} from December ${data.property.reforecastYear}.`
        );
    }
    return ret;
}

export function buildRentsStatementSummary(data: PropertyExecutiveSummaryData | undefined): string {
    if (!data) {
        return "";
    }
    let ret = "";
    if (data.marketRentGrowthPercent !== null) {
        ret = ret.concat(`The projected rent growth for ${data.property.budgetYear} is ___FORMATTING_SPAN___${formatterPercentWithSign.format(data.marketRentGrowthPercent)}___FORMATTING_SPAN___.`);
    }
    let newLeaseTradeOutPart: string | null = null;
    let renewalTradeOutPart: string | null = null;
    if (data.newLeaseTradeOut !== null) {
        newLeaseTradeOutPart = formatterDollarUSNoDecimal.format(data.newLeaseTradeOut);
        if (data.newLeaseTradeOutPercent !== null) {
            newLeaseTradeOutPart = newLeaseTradeOutPart.concat(` ___FORMATTING_SPAN___(${formatterPercent.format(data.newLeaseTradeOutPercent)})___FORMATTING_SPAN___`);
        }
    }
    if (data.renewalTradeOut !== null) {
        renewalTradeOutPart = formatterDollarUSNoDecimal.format(data.renewalTradeOut);
        if (data.renewalTradeOutPercent !== null) {
            renewalTradeOutPart = renewalTradeOutPart.concat(` ___FORMATTING_SPAN___(${formatterPercent.format(data.renewalTradeOutPercent)})___FORMATTING_SPAN___`);
        }
    }

    if (newLeaseTradeOutPart && renewalTradeOutPart) {
        ret = ret.concat(` This will result in ___FORMATTING_SPAN___${newLeaseTradeOutPart}___FORMATTING_SPAN___ New Lease Trade Outs and ___FORMATTING_SPAN___${renewalTradeOutPart}___FORMATTING_SPAN___ Renewal Trade Outs.`);
    }
    else if (newLeaseTradeOutPart) {
        ret = ret.concat(` This will result in ___FORMATTING_SPAN___${newLeaseTradeOutPart}___FORMATTING_SPAN___ New Lease Trade Outs.`);
    }
    else if (renewalTradeOutPart) {
        ret = ret.concat(` This will result in ___FORMATTING_SPAN___${renewalTradeOutPart}___FORMATTING_SPAN___ Renewal Trade Outs.`);
    }
    if (data.occupancyStat.budgetAvgOccupancy !== null) {
        ret = ret.concat(` ${data.property.name} is expected to operate at an average ${formatterPercent.format(data.occupancyStat.budgetAvgOccupancy)}
         occupancy, resulting in `);
        if (data.rentalIncomeDelta != 0) {
            ret = ret.concat(` a ___FORMATTING_SPAN___${formatterDollarUSNoDecimal.format(data.rentalIncomeDelta * Math.sign(data.rentalIncomeDelta))}`);
            if (data.rentalIncomeDeltaPercent != 0) {
                ret = ret.concat(` (${formatterPercentWithSign.format(data.rentalIncomeDeltaPercent)})`);
            }

            ret = ret.concat('___FORMATTING_SPAN___');

            if (data.rentalIncomeDelta > 0) {
                ret = ret.concat(" increase");
            }
            else {
                ret = ret.concat(" decrease");
            }
            ret = ret.concat(` in rental income versus ${data.property.reforecastYear}.`);
        }
        else {
            ret = `No change in rental income versus ${data.property.reforecastYear}.`;
        }
    }

    return ret;
}

export function buildRentsStatementRenewalRatio(data: PropertyExecutiveSummaryData | undefined): string {
    if (!data || data.budgetExpiringRenewalRatio == null) {
        return "";
    }

    return formatterPercent.format(data.budgetExpiringRenewalRatio);
}

export function buildRentsStatementMoveOutRatio(data: PropertyExecutiveSummaryData | undefined): string {
    if (!data || data.budgetExpiringMoveOutRatio == null) {
        return "";
    }

    return formatterPercent.format(data.budgetExpiringMoveOutRatio);
}


export function buildRentsStatementResidentsTurnover(data: PropertyExecutiveSummaryData | undefined): string {
    if (!data) {
        return "";
    }

    // "The renewal ratio is 47.1% (98 renewals). The move out ratio is 43.2% (90 move outs).",
    let ret = "";
    if (data.budgetExpiringRenewalRatio !== null && data.budgetExpiringRenewals !== null) {
        ret = ret.concat(
            `The renewal ratio is ${formatterPercentAutoSign.format(data.budgetExpiringRenewalRatio)} (${Math.round(data.budgetExpiringRenewals)} renewals).`
        );
    }
    if (data.budgetExpiringMoveOutRatio !== null && data.budgetExpiringMoveOuts !== null) {
        ret = ret.concat(
            ` The move out ratio is ${formatterPercentAutoSign.format(data.budgetExpiringMoveOutRatio)} (${Math.round(data.budgetExpiringMoveOuts)} move outs).`
        )
    }

    return ret;
}
