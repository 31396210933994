import { ReactElement, useEffect, useState } from "react";
import { MetricCard } from "../../metric-card/MetricCard";
import Chart from 'react-apexcharts';
import MenuTitle from "../../menu-title/MenuTitle";
import { IMenuTitleOption } from "../../menu-title/logic/menuTitle";
import * as css from "../styles/styles.module.scss";
import { Col, Grid, Row } from "@zendeskgarden/react-grid";
import { ApexOptions } from "apexcharts";
import { COLORS } from "../../../constants/Colors";
import { useAccountSummaryContext } from "../../../pages/workflows/account/AccountSummaryContext";
import { AccountChangeColorMap, AccountChangeDirection, getAccountChangeDirection, getTotalsForYear, getVarianceForSet } from "../logic/AccountSummaryLogic";
import { buildApexChartConfig, yAxisLabelFormatter } from "../../../styles/apex-charts/ViziblyChartDefaultConfig";
import { formatterDollarUSNoDecimal } from "../../../utils/formatters";
import { ReactComponent as ArrowLeft } from '@zendeskgarden/svg-icons/src/16/arrow-left-sm-fill.svg';
import { BudgetingType } from "../../../BudgetingType";

interface IGrowthCardProps {
    wide?: boolean
}

export function GrowthCard(props: IGrowthCardProps): ReactElement {
    const ctxAcctSummary = useAccountSummaryContext();

    const [chartSeries, setChartSeries] = useState<ApexAxisChartSeries>([]);
    const [chartCategories, setChartCategories] = useState<string[] | number[]>([]);
    const [growthMin, setGrowthMin] = useState<number>(-3);
    const [growthMax, setGrowthMax] = useState<number>(3);
    const [changeValue, setChangeValue] = useState<number>(0);
    const [changePercentage, setChangePercentage] = useState<number | string | undefined>(0);
    const [accountChangeDirection, setAccountChangeDirection] = useState<AccountChangeDirection>(AccountChangeDirection.NEITHER);
    const [leftComparator, setLeftComparator] = useState<IMenuTitleOption>({ label: '', value: 0 });
    const [rightComparator, setRightComparator] = useState<IMenuTitleOption>({ label: '', value: 0 });

    const options: IMenuTitleOption[] = [
        {
            label: 'Growth',
            value: 0
        }
    ];

    const chartOptions: ApexOptions = {
        chart: {
            type: 'line',
            stacked: true,
        },
        tooltip: {
            y: [
                // TODO investigate using Intl library instead for formatting - bowman APP-1043
                { formatter: (val) => formatterDollarUSNoDecimal.format(val) },
                { formatter: (val) => formatterDollarUSNoDecimal.format(val) },
                { formatter: (val) => parseFloat((val).toPrecision(2)) + '%' },
                { formatter: (val) => formatterDollarUSNoDecimal.format(val) },
            ],
        },
        xaxis: {
            categories: chartCategories,
        },
        yaxis: [
            {
                seriesName: 'Actual',
                title: {
                    text: ""
                },
                forceNiceScale: true,
                labels: {
                    formatter: yAxisLabelFormatter,
                },
                axisBorder: {
                    show: true,
                },
                tickAmount: 3,
            },
            {
                seriesName: 'Actual',
                show: false,
            },
            {
                seriesName: 'Growth',
                min: growthMin,
                max: growthMax,
                opposite: true,
                title: {
                    text: ""
                },
                forceNiceScale: true,
                labels: {
                    formatter: function (val): string {
                        if (val == 0) return "0";

                        return parseFloat(Math.round(val).toFixed(1)) + "%";
                    }
                },
                axisBorder: {
                    show: true,
                },
                tickAmount: 4,
            },
            {
                seriesName: 'Actual',
                show: false,
            },
        ],
        forecastDataPoints: {
            count: 1,
            fillOpacity: 1,
        },
    };

    useEffect(
        () => {
            if (!ctxAcctSummary.data) return;

            const localCurrentYearTruncated = ctxAcctSummary.currentYear % 100;
            const seriesAct = [] as number[];
            const seriesFcst = [] as number[];
            const seriesBdgt = [] as number[];
            const seriesGrowthValue = [] as number[];
            const seriesGrowthPercentage = [] as (number | null)[];
            let startingYear: number;
            let previousTotal = 0;

            if (ctxAcctSummary.budgetType == BudgetingType.REFORECAST) {
                startingYear = ctxAcctSummary.currentYear - 3;
                setChartCategories([
                    `${localCurrentYearTruncated - 3} ACT`,
                    `${localCurrentYearTruncated - 2} ACT`,
                    `${localCurrentYearTruncated - 1} ACT`,
                    `${localCurrentYearTruncated} RFCST`,
                ]);
                setLeftComparator({ label: `${localCurrentYearTruncated - 1} ACT`, value: 0 });
                setRightComparator({ label: `${localCurrentYearTruncated} RFCST`, value: 0 });
            } else {
                startingYear = ctxAcctSummary.currentYear - 2;
                setChartCategories([
                    `${localCurrentYearTruncated - 2} ACT`,
                    `${localCurrentYearTruncated - 1} ACT`,
                    `${localCurrentYearTruncated} RFCST`,
                    `${localCurrentYearTruncated + 1} BDGT`,
                ]);
                setLeftComparator({ label: `${localCurrentYearTruncated} RFCST`, value: 0 });
                setRightComparator({ label: `${localCurrentYearTruncated + 1} BDGT`, value: 0 });
            }

            // loop through each year needed for the growth chart to build the data series
            for (let i = 0; i < 4; i++) {
                const {
                    act,
                    fcst,
                    rfcst,
                    bdgt,
                } = getTotalsForYear(startingYear + i, ctxAcctSummary);

                seriesAct.push(act);
                seriesFcst.push(fcst);
                seriesBdgt.push(bdgt);

                if (i === 0) {
                    seriesGrowthValue.push(0);
                    seriesGrowthPercentage.push(null);
                } else {
                    let newValueToCompare = rfcst;

                    if (i === 3 && ctxAcctSummary.budgetType == BudgetingType.BUDGET) {
                        newValueToCompare = bdgt;
                    }

                    const {
                        varValue,
                        varPercentage,
                    } = getVarianceForSet(newValueToCompare, previousTotal);
                    seriesGrowthValue.push(varValue);
                    seriesGrowthPercentage.push(varPercentage ?? null);

                    if (i === 3) {
                        setChangeValue(varValue);
                        setChangePercentage(varPercentage != undefined ? varPercentage + '%' : '-');
                        setAccountChangeDirection(getAccountChangeDirection(newValueToCompare, previousTotal, ctxAcctSummary.isIncome));
                    }
                }

                previousTotal = rfcst;
            }

            const updatedChartSeries = [
                {
                    name: 'Actual',
                    type: 'bar',
                    data: seriesAct,
                },
                {
                    name: 'Forecast',
                    type: 'bar',
                    data: seriesFcst,
                },
                {
                    name: 'Growth',
                    type: 'line',
                    data: seriesGrowthPercentage,
                    color: COLORS.PRIMARY_600,
                }
            ];

            if (ctxAcctSummary.budgetType == BudgetingType.BUDGET) {
                updatedChartSeries.push({
                    name: 'Budget',
                    type: 'bar',
                    data: seriesBdgt,
                });
            }

            // to center the zero on the growth y axis vertically, we need to know the highest absolute
            // growth value and apply it to both the min and max values with a little padding
            if (chartOptions?.yaxis && Array.isArray(chartOptions?.yaxis)) {
                const maxAbsGrowth = Math.max(...seriesGrowthPercentage.flatMap(x => x ? [Math.abs(x)] : [])) * 1.05;
                setGrowthMin(parseFloat(maxAbsGrowth.toFixed(1)) * -1);
                setGrowthMax(parseFloat(maxAbsGrowth.toFixed(1)));
            }

            setChartSeries(updatedChartSeries);
        },
        [ctxAcctSummary.data]
    );

    const chartConfig = buildApexChartConfig({ options: chartOptions, series: chartSeries });

    return (
        <MetricCard wide={props.wide}>
            <MetricCard.Title>
                <div className={css.accountSummaryTitle}>
                    <MenuTitle options={options} />
                </div>

                <div className={css.accountSummarySubtitle}>
                    <MenuTitle options={[leftComparator]} plain />
                    <span className={`${css.accountSummarySubtitleSeparator} ${css.accountSummarySubtitleSeparatorIcon}`}><ArrowLeft /></span>
                    <MenuTitle options={[rightComparator]} plain />
                </div>
            </MetricCard.Title>

            <MetricCard.Body className={props.wide ? css.accountSummaryBodyWide : ''}>
                <Grid className={props.wide ? css.accountSummaryBodyWideLeftWrapper : ''}>
                    <Row justifyContent="center" className={props.wide ? css.accountSummaryBodyWideLeft : ''}>
                        <Col size={props.wide ? 12 : 5} textAlign="center">
                            <h5 className={css.statLabel}>% CHANGE</h5>
                            <h5 className={css.statValue}>
                                {changePercentage}
                            </h5>
                        </Col>

                        <Col size={props.wide ? 12 : 5} textAlign="center">
                            <h5 className={css.statLabel}>$ CHANGE</h5>
                            <h5 className={`${css.statValue} ${ctxAcctSummary.budgetType == BudgetingType.BUDGET ? AccountChangeColorMap[accountChangeDirection] : ''}`}>
                                {formatterDollarUSNoDecimal.format(changeValue)}
                            </h5>
                        </Col>
                    </Row>
                </Grid>

                <div className={props.wide ? css.accountSummaryBodyWideRight : ''}>
                    <Chart
                        options={chartConfig.options}
                        series={chartConfig.series}
                        height={props.wide ? 235 : 190}
                    />
                </div>
            </MetricCard.Body>
        </MetricCard>
    );
}