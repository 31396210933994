import { ReactElement, useEffect, useState } from "react";
import { useBudgetAssumptionsData } from "../budget-assumptions/data/useBudgetAssumptionsData";
import { InsightsReferenceAccounts } from "./components/InsightsReferenceAccounts";
import { ModelingMethodType } from "../budget-assumptions/data/enums/ModelingMethodTypeEnum";
import useAccountVarianceInsights from "../../../../context/data/useAccountVarianceInsights";
import {
    IAccountVarianceInsights,
    parseAccountVarianceInsights
} from "./logic/accountVarianceInsights";
import { AccountVarianceTrends } from "./components/AccountVarianceTrends";
import InsightsSection from "./components/InsightsSection";
import GlContributions from "./components/GlContributions";

import * as css from "./styles/insights.module.scss";
import InsightsTopVendors from "./components/InsightsTopVendors";

export interface IInsightsWidgetProps {
    propertyId: string;
    accountId: string;
    year: number;
    monthIndex: number;
    accountValue: number;
}

export function InsightsWidget(props: IInsightsWidgetProps): ReactElement {

    const insightsData = useAccountVarianceInsights();

    const budgetAssumptions = useBudgetAssumptionsData({
        propertyId: props.propertyId,
        accountId: props.accountId,
        year: props.year,
        monthIndex: props.monthIndex
    });

    /**
     * Requests updates from data services on input prop change
     */
    useEffect(
            () => {
                if(
                        props.propertyId === undefined
                        || props.accountId === undefined
                        || props.year === undefined
                        || props.monthIndex === undefined
                ){
                    return;
                }

                insightsData.fetch(props.accountId, props.propertyId, props.year, props.monthIndex);
            },
            [props.propertyId, props.accountId, props.year, props.monthIndex]
    );

    const [
        parsedInsights,
        setParsedInsights
    ] = useState<IAccountVarianceInsights>();

    useEffect(
            () => {
                if(insightsData.loading || !insightsData.data){
                    return;
                }
                setParsedInsights(
                        parseAccountVarianceInsights(insightsData.data)
                );
            },
            [insightsData.loading, insightsData.data]
    );

    const referenceAccountRows = budgetAssumptions.data.assumptionRows.filter(x => x.modelingType == ModelingMethodType.PCT_OF_ACCOUNT);

    return (
            <div className={css.insightsTab}>
                <InsightsSection>
                    <InsightsSection.Title>Trends</InsightsSection.Title>
                    <InsightsSection.Body>
                        <AccountVarianceTrends
                                account={parsedInsights?.account}
                                trends={parsedInsights?.insights.trends}
                                annualBudgetRemaining={parsedInsights?.insights.annualBudgetRemaining}
                        />
                    </InsightsSection.Body>
                </InsightsSection>
                {referenceAccountRows.length > 0
                        ? <InsightsSection>
                            <InsightsSection.Title>Reference Accounts</InsightsSection.Title>
                            <InsightsSection.Body>
                                <InsightsReferenceAccounts referenceAccounts={referenceAccountRows}/>
                            </InsightsSection.Body>
                        </InsightsSection>
                        : <></>
                }
                {parsedInsights && parsedInsights.insights.topVendors.length > 0
                        ? <InsightsSection>
                            <InsightsSection.Title>Top 3 Vendors</InsightsSection.Title>
                            <InsightsSection.Body>
                                <InsightsTopVendors
                                        vendorData={parsedInsights.insights.topVendors}
                                        accountTotal={props.accountValue}
                                />
                            </InsightsSection.Body>
                        </InsightsSection>
                        : <></>
                }
                <InsightsSection>
                    <InsightsSection.Title>GL Contribution</InsightsSection.Title>
                    <InsightsSection.Body>
                        <GlContributions
                                account={parsedInsights?.account}
                                contributions={parsedInsights?.insights.glContributions}/>
                    </InsightsSection.Body>
                </InsightsSection>
            </div>
    );
}