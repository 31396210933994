import React, {useState, useEffect, ReactElement} from 'react';
import {DEFAULT_THEME, ThemeProvider} from '@zendeskgarden/react-theming';
import {PropertiesAndUnitTypesFilterDataType} from "./helpers/types";
import {useGetPropertiesAndUnitTypes} from "./data/UseGetPropertiesAndUnitTypes";
import {CustomZDDropdown} from "../../atoms/custom-zd-dropdown/CustomZDDropdown";

interface PropertiesAndUnitTypesFilterProps {
    initialPropertyId: string,
    initialUnitTypeId: string,
    checkPropertyAndUnitTypeChangeAllowed: () => Promise<boolean>
    onSelectChange: (propertyId: string|undefined, unitTypeId: string|undefined) => void
    disabled?: boolean
}

export default function PropertiesAndUnitTypesFilter(props: PropertiesAndUnitTypesFilterProps): ReactElement {
    const [allOptions, setAllOptions] = useState<PropertiesAndUnitTypesFilterDataType[]>([]);
    const [selectedOption, setSelectedOption] = useState<PropertiesAndUnitTypesFilterDataType|undefined>(undefined);
    const {isDataReady: isPropertyAndUnitTypesDataReady, data: propertiesAndUnitTypes} = useGetPropertiesAndUnitTypes();

    useEffect(() => {
        /*console.info(`useEffect
        props.initialPropertyId: ${props.initialPropertyId}
        props.initialUnitTypeId: ${props.initialUnitTypeId}
        isPropertyAndUnitTypesDataReady: ${isPropertyAndUnitTypesDataReady}`);*/
        if(!props.initialPropertyId || !props.initialUnitTypeId || !isPropertyAndUnitTypesDataReady || !propertiesAndUnitTypes) {
            return;
        }
        if(!propertiesAndUnitTypes || !propertiesAndUnitTypes.length) {
            console.warn(`No property available on the DB for this client`);
            return;
        }

        const allProperties = propertiesAndUnitTypes;
        let selectedProperty = allProperties.find(property => property.id === props.initialPropertyId);
        if (!selectedProperty) {
            console.warn(`property meta could not be found for the supplied propertyId: ${props.initialPropertyId}. So, picking the first available property`);
            selectedProperty = allProperties[0];
        }
        if(!selectedProperty?.unitTypesCustom || !selectedProperty?.unitTypesCustom.length) {
            console.warn(`No unit types available on the DB for this client and propertyId: ${selectedProperty?.id}`);
            return;
        }
        const allUnitTypes = selectedProperty.unitTypesCustom;
        let selectedUnitType = selectedProperty?.unitTypesCustom.find(unitType => unitType.id === props.initialUnitTypeId);
        if (!selectedUnitType) {
            console.warn(`unitType meta could not be found for the supplied unitTypeId: ${props.initialUnitTypeId}. So, picking the first available unit type`);
            selectedUnitType = allUnitTypes[0];
        }
        // console.info("AnalystRenovationDrawerProps useEffect, selectedProperty, selectedUnitType: ", selectedProperty, selectedUnitType);
        const options:PropertiesAndUnitTypesFilterDataType[] = [];
        allProperties.map(p => p).sort((a, b) => {
            return a.name.localeCompare(b.name);
        }).forEach(property => {
            property.unitTypesCustom.map(ut => ut).sort((a, b) => {
                return a.name.localeCompare(b.name);
            }).forEach(unitType => {
                options.push({
                    key: `${property.id}#${unitType.id}`,
                    propertyId: property.id,
                    unitTypeId: unitType.id,
                    propertyName: property.name,
                    unitTypeName: unitType.name
                });
            });
        });
        setAllOptions(options);
        const currentSelection = options.find(option => option.key === `${selectedProperty?.id}#${selectedUnitType?.id}`);
        (async() => {
            const allowProceed = await props.checkPropertyAndUnitTypeChangeAllowed();
            if(allowProceed) {
                setSelectedOption(currentSelection);
                props.onSelectChange(currentSelection?.propertyId, currentSelection?.unitTypeId);
            }
        })();
    }, [props.initialPropertyId, props.initialUnitTypeId, isPropertyAndUnitTypesDataReady]);

    const onSelect = (item:PropertiesAndUnitTypesFilterDataType) => {
        (async() => {
            // console.info(`OnSelect Going to ask if a change is allowed`);
            const allowProceed = await props.checkPropertyAndUnitTypeChangeAllowed();
            // console.info(`OnSelect allowProceed: ${allowProceed}`);
            if(allowProceed) {
                setSelectedOption(item);
                props.onSelectChange(item.propertyId, item.unitTypeId);
            }
        })();
    };

    // console.info(`before render PropertiesAndUnitTypesFilter selectedOption: ${Stringify(selectedOption)}`);

    return (
        <ThemeProvider focusVisibleRef={null} theme={DEFAULT_THEME}>
            <CustomZDDropdown
                applySelectedItems={(items) => {
                    const selectedOptions = allOptions.filter(opt => items.indexOf(opt.key) !== -1);
                    if (selectedOptions && selectedOptions.length > 0 && selectedOptions[0]) {
                        onSelect(selectedOptions[0]);
                    }
                }}
                initialSelectedItems={ selectedOption && selectedOption.key ? [selectedOption.key] : undefined}
                openDropdownPlaceholder="Select Property/Unit Type"
                closedDropdownPlaceholder=""
                options={
                    allOptions.map(opt => ({value: opt.key, label: `${opt.propertyName} | ${opt.unitTypeName}`}))
                }
                isError={false}
                onBlur={() => {
                    console.info(`On Blur`);
                }}
                isMulti={false}
                widthSpec={"275px"}
                noSearchIcon
                disabled={props.disabled}
            />
        </ThemeProvider>
    );
}
