import { ReactElement, useEffect, useState } from "react";
import { ListBudgetYearsQuery, PropertyDriverStatusViewModel, VersionType, useCopyDriversClientLevelMutation, useQueryDriverStatusLazyQuery } from "../../../__generated__/generated_types";
import Card from "../../simplified-revenue/components/Card";
import * as css from "../styles/budgetSeasonManagement.module.scss";
import { Field as FormField, Label as FormLabel, Radio, MediaInput } from '@zendeskgarden/react-forms';
import { Dropdown, Field, Item, Menu, Select } from "@zendeskgarden/react-dropdowns";
import { yearToSeason } from "../util";
import { Button } from "@zendeskgarden/react-buttons";
import { toast } from "react-toastify";
import { ReactComponent as SearchIcon } from '@zendeskgarden/svg-icons/src/16/search-stroke.svg';
import { Body, Close, Footer, FooterItem, Header, Modal } from "@zendeskgarden/react-modals";
import { Inline } from "@zendeskgarden/react-loaders";

export type IModelingMethodsTab = {
    budgetYearsListData: ListBudgetYearsQuery | undefined,
}

type TPropertyDriverStatus =
    Pick<PropertyDriverStatusViewModel,
        "propertyId" |
        "propertyName" |
        "revenue" |
        "accPercentage" |
        "operational" |
        "worksheet" |
        "growth" |
        "payroll" |
        "customDriver" |
        "year" |
        "versionType"
    >

export default function ModelingMethodsTab({budgetYearsListData}: IModelingMethodsTab): ReactElement {

    const [sourcePeriod, setSourcePeriod] = useState<VersionType.Reforecast | VersionType.Budget>(VersionType.Budget);
    const [destPeriod, setDestPeriod] = useState<VersionType.Reforecast | VersionType.Budget>(VersionType.Reforecast);
    const [sourceBudgetYear, setSourceBudgetYear] = useState<number>();
    const [destBudgetYear, setDestBudgetYear] = useState<number>();
    const [saving, setSaving] = useState(false);
    const [showConfirmCopy, setShowConfirmCopy] = useState(false);

    const [propertyDriverStatus, setPropertyDriverStatus] = useState<TPropertyDriverStatus[]>([]);
    const [propertyDriverStatusBudgetYear, setPropertyDriverStatusBudgetYear] = useState<number>();
    const [propertyDriverStatusPeriod, setPropertyDriverStatusPeriod] = useState<VersionType.Reforecast | VersionType.Budget>(VersionType.Budget);
    const [filteredPropertyDriverStatus, setFilteredPropertyDriverStatus] = useState<TPropertyDriverStatus[]>([]);

    const [copyDriversClientLevel] = useCopyDriversClientLevelMutation();
    const [getDriverStatus, {data: propertyDriverStatusData}] = useQueryDriverStatusLazyQuery({
        fetchPolicy: "no-cache"
    });

    function resetForm() {
        setSourceBudgetYear(undefined);
        setDestBudgetYear(undefined);
        setSourcePeriod(VersionType.Budget);
        setDestPeriod(VersionType.Reforecast);
    }

    function reloadDriverStatus() {
        if (!propertyDriverStatusBudgetYear || !propertyDriverStatusPeriod) {
            return;
        }
        getDriverStatus({
            variables: {
                budgetYear: propertyDriverStatusBudgetYear,
                versionType: propertyDriverStatusPeriod
            }
        });
    }

    function handleCopy(sourceBudgetYear: number,
                        sourcePeriod: VersionType.Reforecast | VersionType.Budget,
                        destBudgetYear: number,
                        destPeriod: VersionType.Reforecast | VersionType.Budget) {
        setSaving(true);
        copyDriversClientLevel({
            variables: {
                sourceBudgetYear: sourceBudgetYear,
                sourceVersionType: sourcePeriod,
                destinationBudgetYear: destBudgetYear,
                destinationVersionType: destPeriod
            }
        })
        .then(
            (ret) => {
                resetForm();
                if (ret.data?.copyDriversClientLevel.result) {
                    toast.success(`Modeling methods copied successfully`);
                }
                else {
                    toast.error(ret.data?.copyDriversClientLevel.resultString ?? "Modeling methods copy failed");
                }
                reloadDriverStatus();
                setSaving(false);
            }
        )
        .finally(() => {
            setShowConfirmCopy(false);
        });
    }

    useEffect(
        () => {
            reloadDriverStatus();
        },
        [propertyDriverStatusBudgetYear, propertyDriverStatusPeriod]
    );

    useEffect(
        () => {
            if (budgetYearsListData?.listBudgetYears?.activeBudgetYear != undefined) {
                setPropertyDriverStatusBudgetYear(budgetYearsListData?.listBudgetYears?.activeBudgetYear);
            }
        },
        [budgetYearsListData?.listBudgetYears?.activeBudgetYear]
    );

    useEffect(
        () => {
            if (!propertyDriverStatusData) {
                return;
            }
            const propertyDriverStatus: TPropertyDriverStatus[] = [];
            for (const row of propertyDriverStatusData.queryDriverStatus) {
                propertyDriverStatus.push({
                    propertyId: row.propertyId,
                    propertyName: row.propertyName,
                    revenue: row.revenue,
                    accPercentage: row.accPercentage,
                    operational: row.operational,
                    worksheet: row.worksheet,
                    growth: row.growth,
                    payroll: row.payroll,
                    customDriver: row.customDriver,
                    year: row.year,
                    versionType: row.versionType
                });
            }
            setPropertyDriverStatus(propertyDriverStatus);
            setFilteredPropertyDriverStatus(propertyDriverStatus);
        },
        [propertyDriverStatusData]
    );

    const possibleSourceBudgetYears = Array.from(budgetYearsListData?.listBudgetYears?.budgetYears?.map(y => y.year) ?? ([] as number[])).sort();
    const possibleDestBudgetYears = budgetYearsListData?.listBudgetYears?.budgetYears?.filter(y => y.year != sourceBudgetYear && !y.protected)?.map(y => y.year)?.sort() ?? [];

    return (
        <>
            <Card
                className={css.updaterControlsCard}
                titleClassName={css.updaterControlsTitle}
                title={"Carry Over Modeling Methods Across Budgeting Seasons"}
                actions={
                    <div className={css.updaterControlsButtons}>
                        {(sourceBudgetYear || destBudgetYear) &&
                            <Button
                                className={css.button}
                                isBasic
                                isDanger
                                onClick={resetForm}
                            >
                                Cancel
                            </Button>
                        }

                        <Button
                            className={css.button}
                            isPrimary
                            disabled={saving 
                                || sourcePeriod == undefined
                                || sourceBudgetYear == undefined
                                || destBudgetYear == undefined
                                || destPeriod == undefined
                            }
                            onClick={() => {
                                setShowConfirmCopy(true);
                            }}
                        >
                            Copy
                        </Button>
                    </div>
                }
            >
                <ol className={css.updaterControlsSteps}>
                    <li>
                        Select Originating Season
                        <Dropdown
                            selectedItem={sourceBudgetYear?.toString()}
                            onSelect={(v) => {setSourceBudgetYear(v); setDestBudgetYear(undefined);}}
                        >
                            <Field>
                                <Select>
                                    {sourceBudgetYear ? yearToSeason(sourceBudgetYear): ""}
                                </Select>
                            </Field>
                            <Menu>
                                {possibleSourceBudgetYears.map(option => (
                                    <Item key={option} value={option}>
                                        {yearToSeason(option)}
                                    </Item>
                                ))}
                            </Menu>
                        </Dropdown>
                    </li>
                    <li>
                        Select Originating Forecast Period
                        <FormField>
                            <Radio
                                disabled={sourceBudgetYear == undefined}
                                value={"sourcePeriodReforecast"}
                                checked={sourcePeriod == VersionType.Reforecast}
                                onChange={() => setSourcePeriod(VersionType.Reforecast)}
                            >
                                <FormLabel>{sourceBudgetYear ? sourceBudgetYear - 1 : ""} Reforecast</FormLabel>
                            </Radio>
                        </FormField>
                        <FormField>
                            <Radio
                                disabled={sourceBudgetYear == undefined}
                                value={"sourcePeriodBudget"}
                                checked={sourcePeriod == VersionType.Budget}
                                onChange={() => setSourcePeriod(VersionType.Budget)}
                            >
                                <FormLabel>{sourceBudgetYear ?? ""} Budget</FormLabel>
                            </Radio>
                        </FormField>
                    </li>
                    <li>
                        Select Destination Season
                        <Dropdown
                            selectedItem={destBudgetYear?.toString()}
                            onSelect={(v) => setDestBudgetYear(v)}
                        >
                            <Field>
                                <Select>
                                    {destBudgetYear ? yearToSeason(destBudgetYear): ""}
                                </Select>
                            </Field>
                            <Menu>
                                {possibleDestBudgetYears.map(option => (
                                    <Item key={option} value={option}>
                                        {yearToSeason(option)}
                                    </Item>
                                ))}
                            </Menu>
                        </Dropdown>
                    </li>
                    <li>
                        Select Destination Forecast Period
                        <FormField>
                            <Radio
                                disabled={destBudgetYear == undefined}
                                value={"destPeriodReforecast"}
                                checked={destPeriod == VersionType.Reforecast}
                                onChange={() => setDestPeriod(VersionType.Reforecast)}
                            >
                                <FormLabel>{destBudgetYear ? destBudgetYear - 1 : ""} Reforecast</FormLabel>
                            </Radio>
                        </FormField>
                        <FormField>
                            <Radio
                                disabled={destBudgetYear == undefined}
                                value={"destPeriodBudget"}
                                checked={destPeriod == VersionType.Budget}
                                onChange={() => setDestPeriod(VersionType.Budget)}
                            >
                                <FormLabel>{destBudgetYear ?? ""} Budget</FormLabel>
                            </Radio>
                        </FormField>
                    </li>
                </ol>

            </Card>
            <Card
                title={"Number of Accounts Assigned a Modelling Method"}
                actions={
                    <>
                        <Dropdown
                            selectedItem={propertyDriverStatusBudgetYear?.toString()}
                            onSelect={(v) => setPropertyDriverStatusBudgetYear(v)}
                        >
                            <Field>
                                <Select style={{width: "15rem"}}>
                                    {propertyDriverStatusBudgetYear ? yearToSeason(propertyDriverStatusBudgetYear): ""}
                                </Select>
                            </Field>
                            <Menu>
                                {
                                    budgetYearsListData?.listBudgetYears?.budgetYears ?
                                        budgetYearsListData?.listBudgetYears?.budgetYears?.map(option => (
                                            <Item key={option.year} value={option.year}>
                                                {yearToSeason(option.year)}
                                            </Item>
                                        ))
                                        :
                                        <></>
                                }
                            </Menu>
                        </Dropdown>
                        <Dropdown
                            selectedItem={propertyDriverStatusPeriod}
                            onSelect={(v) => setPropertyDriverStatusPeriod(v)}
                        >
                            <Field>
                                <Select style={{width: "10rem"}}>
                                    {propertyDriverStatusPeriod ? propertyDriverStatusPeriod.toPascalCase(): ""}
                                </Select>
                            </Field>
                            <Menu>
                                {[VersionType.Reforecast, VersionType.Budget].map(option => (
                                    <Item key={option} value={option}>
                                        {option.toPascalCase()}
                                    </Item>
                                ))}
                            </Menu>
                        </Dropdown>
                        <FormField className={css.propertySearch}>
                            <MediaInput start={<SearchIcon />} placeholder="Search" onChange={e => {
                                setFilteredPropertyDriverStatus(
                                    propertyDriverStatus
                                    .filter(p => p.propertyName.toLowerCase().includes(e.target.value.trim().toLowerCase())));
                            }} />
                        </FormField>
                    </>
                }
            >
                <div className={css.propertyTableWrapper}>
                    <table className={css.propertyTable}>
                        <thead>
                            <tr className={css.headerCell}>
                                <th className={`${css.cell} ${css.nameCell}`}>Property</th>
                                <th className={`${css.cell} ${css.periodCell}`}>Revenue</th>
                                <th className={`${css.cell} ${css.periodCell}`}>Acc %</th>
                                <th className={`${css.cell} ${css.periodCell}`}>Operational</th>
                                <th className={`${css.cell} ${css.periodCell}`}>Line Items</th>
                                <th className={`${css.cell} ${css.periodCell}`}>Growth</th>
                                <th className={`${css.cell} ${css.periodCell}`}>Payroll</th>
                                <th className={`${css.cell} ${css.periodCell}`}>Custom Driver</th>
                            </tr>
                        </thead>

                        <tbody>
                            {filteredPropertyDriverStatus.map(property => (
                                    <tr key={property.propertyId}>
                                        <td className={`${css.cell}`}>{ property.propertyName }</td>
                                        <td className={`${css.cell} ${css.periodCell}`}>{property.revenue}</td>
                                        <td className={`${css.cell} ${css.periodCell}`}>{property.accPercentage}</td>
                                        <td className={`${css.cell} ${css.periodCell}`}>{property.operational}</td>
                                        <td className={`${css.cell} ${css.periodCell}`}>{property.worksheet}</td>
                                        <td className={`${css.cell} ${css.periodCell}`}>{property.growth}</td>
                                        <td className={`${css.cell} ${css.periodCell}`}>{property.payroll}</td>
                                        <td className={`${css.cell} ${css.periodCell}`}>{property.customDriver}</td>
                                    </tr>
                                ))}
                        </tbody>
                    </table>
                </div>
            </Card>
            {showConfirmCopy && sourceBudgetYear && destBudgetYear &&
            <Modal onClose={() => setShowConfirmCopy(false)}>
                <Header isDanger>
                    Confirm copy modelling methods
                </Header>
                <Body>
                    Are you sure you want to copy modelling methods to {yearToSeason(destBudgetYear)} {destPeriod.toPascalCase()}?
                    This will erase modelling methods in {yearToSeason(destBudgetYear)}  {destPeriod.toPascalCase()} if they exist.
                </Body>
                <Footer>
                    <FooterItem>
                        <Button
                            onClick={() => setShowConfirmCopy(false)}
                            isBasic
                            disabled={saving
                                || sourcePeriod == undefined
                                || sourceBudgetYear == undefined
                                || destBudgetYear == undefined
                                || destPeriod == undefined
                            }
                        >
                            Cancel
                        </Button>
                    </FooterItem>
                    <FooterItem>
                        {
                            <Button
                                isPrimary
                                onClick={() => handleCopy(sourceBudgetYear, sourcePeriod, destBudgetYear, destPeriod)}
                                disabled={saving
                                    || sourcePeriod == undefined
                                    || sourceBudgetYear == undefined
                                    || destBudgetYear == undefined
                                    || destPeriod == undefined
                                }
                            >
                                {
                                    saving
                                        ? <Inline size={24} aria-label="loading"/>
                                        : <span>Confirm</span>
                                }
                            </Button>
                        }
                    </FooterItem>
                </Footer>
                <Close aria-label="Close modal" />
            </Modal>}
         </>
    );
}