import {useEffect, useState} from "react";
import {
    GetEmployeeQuery,
    useGetEmployeeLazyQuery
} from "../../../__generated__/generated_types";

export interface EmployeeDataState {
    isDataReady: boolean;
    refreshEmployeeData: (employeeId: string) => void;
    getData: () => GetEmployeeQuery["getEmployee"]|null;
}

export function useGetEmployee(): EmployeeDataState {
    const [isDataReady, setDataReady] = useState<boolean>(false);
    const [data, setData] = useState<GetEmployeeQuery["getEmployee"]|null>(null);

    const [
        getEmployee,
        { data: employeeData, loading: employeeDataLoading, error: employeeDataError }
    ] = useGetEmployeeLazyQuery({ fetchPolicy: "network-only" });

    useEffect(
        () => {
            /*console.info(`useEffect
                isDataReady: ${isDataReady}
                employeeId: ${employeeId}
                employeeDataLoading: ${employeeDataLoading}
                employeeData: ${JSON.stringify(employeeData)}
                employeeDataError: ${employeeDataError}`);*/
            if(employeeData === undefined || employeeDataLoading){
                return;
            }
            setData(employeeData.getEmployee);
            setDataReady(true);
        },
        [employeeData, employeeDataLoading, employeeDataError]
    );

    const refreshEmployeeData = (employeeId: string) => {
        console.info(`refreshEmployeeData called for ${employeeId}`);
        if (employeeId) {
            setDataReady(false);
            getEmployee({
                variables: {
                    employeeId: employeeId
                }
            });
        }
    };

    const getData = () => {
        return data;
    };

    return {
        isDataReady,
        refreshEmployeeData,
        getData,
    };
}
