import React, { ChangeEvent, ReactElement, useEffect, useRef, useState } from "react";
import { Dropdown, Item, Menu, Separator } from "@zendeskgarden/react-dropdowns";
import {Field as FormField, Input} from "@zendeskgarden/react-forms";

import { IFormulaBarUpdates } from "../../../../../../contexts/account/data/useFormulaBarUpdates";

import * as css from "./styles/css.module.scss";
import { DistributionMethod } from "../../../../../../__generated__/generated_types";
import { TooltipModal } from "@zendeskgarden/react-modals";
import { distributionOptions, stringToFixedFloat } from "../../logic/utils";


interface IAnnualTargetValueMenuProps {
    fbUpdates: IFormulaBarUpdates,
    handleCloseParent?: () => void,
    level: number,
    triggerNode?: ReactElement,
}

export default function AnnualTargetValueMenu({
    fbUpdates,
    handleCloseParent,
    level,
    triggerNode,
}: IAnnualTargetValueMenuProps): React.ReactElement {
    const [inputAnnualTargetValue, setInputAnnualTargetValue] = useState<string>("0");

    const triggerRef = useRef<HTMLDivElement>(null);
    const [refElement, setRefElement] = useState<HTMLDivElement|null>(null);

    useEffect(() => {
        const valueFromFbUpdates = fbUpdates.parsedFormDrivers.annualTargetValue[0]?.annualTargetValueManualEntry;
        if (valueFromFbUpdates) {
            setInputAnnualTargetValue(valueFromFbUpdates.toString());
        }
    }, [fbUpdates.parsedFormDrivers.annualTargetValue]);

    const handleClick = () => {
        setRefElement(triggerRef.current);
    };

    const handleClose = () => {
        setRefElement(null);
        if (handleCloseParent) handleCloseParent();
    };

    const handleCloseAnnualTargetValueManualEntryNode = () => {
        if (inputAnnualTargetValue === '-' || inputAnnualTargetValue.length === 0) {
            fbUpdates.updateAnnualTargetValueDriverManualEntry(0);
        }
        fbUpdates.updateAnnualTargetValueDriverManualEntry(stringToFixedFloat(inputAnnualTargetValue, 2, false));
        handleClose();
    };

    const handleSelectDistribution = (item: DistributionMethod) => {
        fbUpdates.updateAnnualTargetValueDriverDistribution(item);
        handleClose();
    };

    const handleAnnualTargetValueChange = (event: ChangeEvent<HTMLInputElement>) => {
        setInputAnnualTargetValue(event.target.value);
    };

    const handleAnnualTargetValueSubmit = (event: React.KeyboardEvent<HTMLDivElement>) => {
        if (event.key === 'Enter' && level === 0) {
            handleCloseAnnualTargetValueManualEntryNode();
        }
    };

    // The header is a non-clickable, static text separated from the menu body
    const menuHeader = (
        <>
            <div
                style={inlineStyles.header}
            >
                Input annual target amount
            </div>
            <Separator style={inlineStyles.separator}/>
        </>
    );

    const annualTargetValueManualEntryNode = (
        <div className={css.menuWrapper}>
            {menuHeader}
            <div className={`${css.inputWrapper} ${css.pseudoDollar}`}>
                <FormField>
                    <Input
                        value={inputAnnualTargetValue}
                        type="number"
                        onChange={handleAnnualTargetValueChange}
                        onKeyDown={handleAnnualTargetValueSubmit}
                        style={inlineStyles.input}
                    />
                </FormField>
            </div>
        </div>
    );

    const distributionNode = (
        <Dropdown
            isOpen
            onSelect={(item) => handleSelectDistribution(item)}
            downshiftProps={{ defaultHighlightedIndex: 0 }}
        >
            <div className={css.menuWrapper}>
                <Menu placement="bottom" className={css.menu} style={inlineStyles.menu}>
                    {distributionOptions.map((each) => (
                        <Item
                            value={each.name}
                            key={each.name}
                            className={css.menuItem}
                        >
                            {each.value}
                        </Item>
                    ))}
                </Menu>
            </div>
        </Dropdown>
    );

    if (level === 0) {
        if (triggerNode) {
            return (
                <>
                    <div
                        className={css.triggerNode}
                        ref={triggerRef}
                        onClick={handleClick}
                    >
                        {triggerNode}
                    </div>
                    <TooltipModal
                        className={css.tooltipModal}
                        hasArrow={false}
                        referenceElement={refElement}
                        onClose={handleCloseAnnualTargetValueManualEntryNode}
                        placement="bottom"
                        isAnimated
                    >
                        <TooltipModal.Body className={css.tooltipModalBody}>
                            {annualTargetValueManualEntryNode}
                        </TooltipModal.Body>
                    </TooltipModal>
                </>
            );
        }
        return annualTargetValueManualEntryNode;
    }

    if (level === 1) {
        if (triggerNode) {
            return (
                <>
                    <div
                        className={css.triggerNode}
                        ref={triggerRef}
                        onClick={handleClick}
                    >
                        {triggerNode}
                    </div>
                    <TooltipModal
                        className={css.tooltipModal}
                        hasArrow={false}
                        referenceElement={refElement}
                        onClose={handleClose}
                        placement="bottom"
                        isAnimated
                    >
                        <TooltipModal.Body className={css.tooltipModalBody}>
                            {distributionNode}
                        </TooltipModal.Body>
                    </TooltipModal>
                </>
            );
        }
        return distributionNode;
    }

    return <></>;
}

/**
 * To override ZDG styles and avoid using !important all the time
 */
const inlineStyles = ({
    header: {color: "#2f3941", fontWeight: 500, margin: "0", padding: "12px"},
    input: {margin: "16px", minHeight: "39px", paddingLeft: "26px", width: "150px"},
    menu: {marginTop: "30px", position: "relative" as 'relative', transform: "none", width: "auto"},
    separator: {margin: "0"},
});
