import { ReactElement, useEffect, useRef, useState } from "react";

import { registerAllModules } from 'handsontable/registry';
import { registerRenderer } from 'handsontable/renderers';

registerAllModules();
registerRenderer('varianceCellRenderer', varianceCellRenderer);
registerRenderer('financialEntityCellRenderer', financialEntityCellRenderer);
registerRenderer('valueCellRenderer', valueCellRenderer);
registerRenderer('varianceNotesCellRenderer', varianceNotesCellRenderer);

import { HotTable } from '@handsontable/react';

import {
    buildMvrNestedHeader,
    buildMvrSheetSettings,
} from "./logic/mvrSpreadsheet";

import * as mvrCSS from "./styles/css.module.scss";
import { MvrTableRow } from "../context/data/logic/monthlyVarianceReportData";

import Handsontable from "handsontable";
import { Events } from "handsontable/pluginHooks";
import {
    financialEntityCellRenderer,
    valueCellRenderer,
    varianceCellRenderer,
    varianceNotesCellRenderer
} from "./logic/cellRenderers";
import { GridSettings } from "handsontable/settings";
import { useMonthlyVarianceReportContext } from "../context/MonthlyVarianceReportContext";
import { onCellChange } from "./logic/cellChangeHandler";
import {
    afterGetColumnHeader
} from "../../line-items-report/components/line-items-spreadsheet/logic/lineItemsSpreadsheet";

interface IMVRSpreadsheetProps {
    month?: number;
    year?: number;
    data?: MvrTableRow[];
    isVisible?: boolean;
    requestModalOpen: (accountId: string) => void;
}

function MVRSpreadsheet(props: IMVRSpreadsheetProps): ReactElement {

    const hotTableComponent = useRef<HotTable>(null);
    const [hotRef, setHotRef] = useState<Handsontable>();

    const mvrContext = useMonthlyVarianceReportContext();

    const [sheetSettings, setSheetSettings] = useState<Partial<GridSettings>>(buildMvrSheetSettings(props.month, props.year));

    useEffect(
            () => {
                if(props.month === undefined || !props.year){
                    return;
                }

                setSheetSettings(prev => {
                    return {
                        ...prev,
                        nestedHeaders: buildMvrNestedHeader(props.month, props.year),
                    };
                });
            },
            [props.month, props.year]
    );

    useEffect(
            () => {
                if(hotTableComponent?.current?.hotInstance){
                    setHotRef(hotTableComponent?.current?.hotInstance);
                }
            },
            []
    );

    useEffect(
            () => {
                if(!hotRef){
                    return;
                }

                hotRef.addHook('financialEntityDetails' as keyof Events, (accountId: string) => {
                    props.requestModalOpen(accountId);
                });
            },
            [hotRef]
    );

    return (
            <div className={props.isVisible != false ? mvrCSS.sheetContainer : mvrCSS.sheetContainerHidden}>
                <HotTable
                        id="hot"
                        ref={hotTableComponent}
                        settings={sheetSettings}
                        data={props.data}
                        afterChange={(changes: Handsontable.CellChange[]|null, source: Handsontable.ChangeSource) => {
                            if(!hotRef || !mvrContext || !changes || source == 'loadData'){
                                return;
                            }
                            onCellChange(changes, source, hotRef, mvrContext);
                        }}
                />
            </div>
    );
}

export default MVRSpreadsheet;