import { ReactElement, useMemo, useState } from "react";
import CreatableSelect from "react-select/creatable";
import { components, ActionMeta } from "react-select";
import { Body, Close, Footer, FooterItem, Header, Modal } from "@zendeskgarden/react-modals";

import CheckmarkIcon from "../../../assets/icons/icon_checkmark.svg";
import SearchIcon from "../../../assets/icons/icon_search.svg";

import * as css from "../styles/coaTags.module.scss";
import { COLORS } from "../../../constants/Colors";
import { Button } from "@zendeskgarden/react-buttons";


export type TagOption = {
    label: string;
    value: string;
}

interface TagSelectorProps {
    title: string,
    options: TagOption[],
    selected: TagOption[],
    canAdd: boolean,
    onConfirm: (tags: TagOption[]) => void,
    onClose: () => void
}


export const TagSelector = (props: TagSelectorProps): ReactElement => {
    const [options, setOptions] = useState<TagOption[]>(props.options);
    const [selectedOptions, setSelectedOptions] = useState<TagOption[]>(props.selected);

    const customStyles = useMemo(() => ({
        control: (base:any, _state:any) => ({
            ...base,
            boxShadow: "none",
            border: `1px solid #DDDDDD`,
            fontSize: "12px",
            fontWeight: 500,
            fontFamily: "Inter",
            margin: "0 12px",
            hover:{
                border: `1px solid ${ COLORS.PRIMARY_400 }`,
            }
        }),
        menu: (base:any, _state:any) => ({
            ...base,
            boxShadow: "none",
            font: `500 12px Inter`,
            position: "relative",
        }),
        container: (base:any, _state:any) => ({
            ...base,
            backgroundColor: "none",
            border: "none",
            padding: "12px 0",
            width: "100%"
        }),
        group: (base:any, _state:any) => ({
            ...base,
            backgroundColor: 'red',
        }),
        input: (base:any, _state:any) => ({
            ...base,
            display: "inline-flex",
            'input': {
                caretColor: "var(--grey-800)",
            },
            '&:after': {
                display: "none",
            },
        }),
        option: (base:any, _state:any) => ({
            ...base,
            backgroundColor: "#FFFFFF",
            color: "initial",
            display: "flex",
            fontSize: "14px",
            fontWeight: 400,
            gap: "8px",
            justifyContents: "space-between",
            '&:hover': {
                backgroundColor: "#F3F7F8",
            },
        }),
        valueContainer: (base:any, _state:any) => ({
            ...base,
            display: "flex",
            flexDirection: "row",
            gap: "8px",
        })
    }), []);

    const CustomOption = (optionProps: any) => {
        const { innerProps, innerRef } = optionProps;
        const isSelected = Boolean(selectedOptions.find(s => s.value == optionProps.data.value));

        return (
          <components.Option {...optionProps} innerProps={innerProps} innerRef={innerRef}>
            {/* Show a spacer div when a checkmark doesn't exist. */}
            {isSelected
                ? <img src={CheckmarkIcon} alt="check" />
                : <div className={css.emptyCheckmarkSpacer}></div>
            }
            {optionProps.data.label}
          </components.Option>
        );
    };

    const CustomValueContainer = (props: any) => {
        return (
            <components.ValueContainer {...props}>
                <div className={css.valContainerIcon}>
                    <img src={SearchIcon} alt="search" />
                </div>
                <div className={css.valContainerInputWrapper}>{props.children}</div>
            </components.ValueContainer>
        );
    };

    const handleChange = (values: TagOption[], meta: ActionMeta<any>) => {
        if (meta.action === "create-option") {
            setOptions(prev =>
                [...prev, meta.option]
                .sort((a: TagOption, b: TagOption) => a.value.toLowerCase() < b.value.toLowerCase() ? -1 : 1)
            );
            setSelectedOptions(prev => 
                [...prev, meta.option]
            );
        }

        if (meta.action === "deselect-option") {
            if (!props.options.find(o => o.value == meta.option.value)) {
                // newly created tag got unselected. delete it so it never gets persisted
                const optionIndex = options.findIndex(o => o.value == meta.option.value);
                if (optionIndex > -1) { // paranoiya
                    setOptions(prev => {
                        const updated = [...prev];
                        updated.splice(optionIndex, 1);
                        return updated;
                    });
                }
            }
            setSelectedOptions(values);
        }
        if (meta.action === "select-option") {
            setSelectedOptions(values);
        }
    };

    function handleClose() {
        props.onClose();
    }

    const handleConfirm = () => {
        props.onConfirm(selectedOptions);
    };

    const customFilter = (option: TagOption, searchText: string) =>
        option.value.toLowerCase().includes(searchText.toLowerCase());

    return (
        <Modal
            onClose={handleClose}
            isAnimated
        >
            <Header>
                {props.title}
            </Header>
            <Body>
                <CreatableSelect
                    autoFocus
                    escapeClearsValue
                    menuIsOpen
                    isMulti
                    backspaceRemovesValue={false}
                    controlShouldRenderValue={false}
                    hideSelectedOptions={false}
                    isClearable={false}
                    components={{ DropdownIndicator: null, Option: CustomOption, ValueContainer: CustomValueContainer }}
                    filterOption={customFilter}
                    onChange={(values:any, meta) => handleChange(values, meta)}
                    options={options}
                    isValidNewOption={(val) => props.canAdd && val.length > 0}
                    placeholder={""}
                    styles={customStyles}
                    value={selectedOptions}
                />
            </Body>
            <Footer>
                <FooterItem>
                    <Button onClick={handleClose} isBasic>
                        Cancel
                    </Button>
                </FooterItem>
                <FooterItem>
                    <Button isPrimary onClick={() => handleConfirm()}>
                        Confirm
                    </Button>
                </FooterItem>
            </Footer>
            <Close aria-label="Close modal" />
        </Modal>
    );
};
