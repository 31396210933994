import { ReactElement } from "react";
import { BudgetingType } from "../../BudgetingType";
import useAppStore from "../../hooks/useAppStore";
import "./Toggle.css";

export interface SidebarTab {
    tabName: BudgetingType;
    year: number;
    onToggle?: (type: BudgetingType) => void;
}

export default function ToggleCategory(props: SidebarTab): ReactElement {

    const reforecastYear = props.year.toString().substr(-2);
    const budgetYear = `${props.year + 1}`.toString().substr(-2);

    const appStore = useAppStore();

    const onClick = (event:React.MouseEvent, type: BudgetingType)=> {
        event?.preventDefault();
        props.onToggle?.(type);
        appStore.set({categoryType: type});
    };

    return (
        <div className="sidebar-components toggle-container d-flex justify-content-around bg-whisper">
            <a href="#" className={"toggle text-decoration-none py-1 px-3 " +
                (props.tabName === BudgetingType.REFORECAST ? "bg-white " : "bg-whisper text-grey")}
                onClick={(event) => onClick(event, BudgetingType.REFORECAST)}> '{reforecastYear} Reforecast
            </a>
            <a href="#" className={"toggle text-decoration-none py-1 px-3 " +
                (props.tabName === BudgetingType.BUDGET ? "bg-white " : "bg-whisper text-grey")}
                onClick={(event) => onClick(event, BudgetingType.BUDGET)}> '{budgetYear} Budget
            </a>
        </div>
    );
}
