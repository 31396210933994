import React, { Children, ReactElement, useState } from "react";
import * as css from "./styles/css.module.scss";

import { ToggleIconButton } from '@zendeskgarden/react-buttons';
import { ReactComponent as GraphIcon } from '@zendeskgarden/svg-icons/src/16/bar-chart-stroke.svg';
import { ReactComponent as TableIcon } from '@zendeskgarden/svg-icons/src/16/table-stroke.svg';

interface IGraphTableLabel {
    children: ReactElement[]|ReactElement|string,
    className?: string,
}

function Label(props: IGraphTableLabel): ReactElement {
    return (
            <div className={props.className ? `${css.widgetLabelContainer} ${props.className}` : css.widgetLabelContainer}>
                {props.children}
            </div>
    );
}

interface IGraphTableDataPane {
    children: ReactElement[]|ReactElement,
    className?: string,
}

function Graph(props: IGraphTableDataPane): ReactElement {
    return (
            <div className={props.className ? `${css.graphPane} ${props.className}` : css.graphPane}>
                {props.children}
            </div>
    );
}

function Table(props: IGraphTableDataPane): ReactElement {
    return (
            <div className={props.className ? `${css.tablePane} ${props.className}` : css.tablePane}>
                <div className={css.inner}>
                    {props.children}
                </div>
            </div>
    );
}

interface IGraphTableWidgetProps {
    children: ReactElement[]|ReactElement,
    displyMode?: 'GRAPH'|'TABLE',
    className?: string,
}

export default function GraphTableWidget(props: IGraphTableWidgetProps): ReactElement {

    const [displayMode, setDisplayMode] = useState<'GRAPH'|'TABLE'>(props.displyMode ?? 'GRAPH');

    let label = <></>;
    let graph = <></>;
    let table = <></>;

    Children.forEach(props.children, child => {
        if(child.type === Label){
            label = child;
        }
        if(child.type === Graph){
            graph = child;
        }
        if(child.type === Table){
            table = child;
        }
    });

    return (
            <div className={props.className ? `${css.widget} ${props.className}` : css.widget}>
                <div className={css.widgetHeader}>
                    <div>{label}</div>
                    <div className={css.widgetModeActions}>
                        <ToggleIconButton
                                aria-label="square bold"
                                isBasic={false}
                                isPill={false}
                                isPressed={displayMode === 'GRAPH'}
                                onClick={() => setDisplayMode('GRAPH')}
                                className={css.modeToggleButton}
                        >
                            <GraphIcon/>
                        </ToggleIconButton>
                        <ToggleIconButton
                                aria-label="square bold"
                                isBasic={false}
                                isPill={false}
                                isPressed={displayMode === 'TABLE'}
                                onClick={() => setDisplayMode('TABLE')}
                                className={css.modeToggleButton}
                        >
                            <TableIcon/>
                        </ToggleIconButton>
                    </div>
                </div>
                <div style={displayMode !== 'GRAPH' ? { display: 'none' } : {}}>
                    {graph}
                </div>
                <div style={displayMode !== 'TABLE' ? { display: 'none' } : {}}>
                    {table}
                </div>
            </div>
    );
}

GraphTableWidget.Label = Label;
GraphTableWidget.Graph = Graph;
GraphTableWidget.Table = Table;
