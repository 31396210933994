import {ReactElement, useEffect, useRef, useState} from "react";
import Select, { components } from "react-select";

import Checkmark from "../../../assets/icons/icon_checkmark.svg";
import { COLORS } from "../../../constants/Colors";
import { Property } from "../../../contexts/properties/PropertiesContext";

import { Option } from "./CopyDriversToProperties";
import * as css from "./styles/copy-drivers-btn/css.module.scss";
import { GetUserPropertiesForecastLocksQuery, VersionType } from "../../../__generated__/generated_types";

export interface IPropertySelectorProps {
    properties: Property[];
    selectedOptions: Option[];
    year: number,
    versionType: VersionType,
    lockedProperties: GetUserPropertiesForecastLocksQuery | undefined;
    setSelectedOptions: (propertyOptions: Option[]) => void;
}

export default function PropertiesSelector(props: IPropertySelectorProps): ReactElement {
    const [inputValue, setInputValue] = useState("");
    const [matchingOptions, setMatchingOptions] = useState<Option[]>(props.properties.map((e) => ({value: e.id, label: e.name})));

    const inputRef = useRef<HTMLInputElement>(null);
    const periodText = `${props.year} ${props.versionType == VersionType.Reforecast ? "Reforecast" : "Budget"}`;

    useEffect(
        () => {
            const matchedOptions = props.properties.filter(property => {
                return property.name.trim().toLowerCase().indexOf(inputValue.trim().toLowerCase()) !== -1;
            }).map((e) => ({ label: e.name, value: e.id}));

            setMatchingOptions(matchedOptions);
        }, [inputValue]
    );

    useEffect(
        () => {
            if (inputRef.current != null) {
                inputRef.current.placeholder = "Choose property";
            }

        }, [inputRef.current]
    );

    const handleChange = (values: Option[]) => {
        props.setSelectedOptions(values);
    };

    const customStyles = {
        control: (base:any, _state:any) => ({
            ...base,
            boxShadow: `0 0 0 0 ${ COLORS.PRIMARY_400 }`,
            border: `1px solid ${ COLORS.PRIMARY_400 }`,
            fontSize: "12px",
            fontWeight: 500,
            fontFamily: "Inter",
            width: "100%",
            hover:{
                border: `1px solid ${ COLORS.PRIMARY_400 }`,
            }
        }),
        menu: (base:any, _state:any) => ({
            ...base,
            font: `500 12px Inter`,
        }),
        multiValue: (base:any, _state:any) => ({
            ...base,
            padding: "2px 4px",
            '> *': {
                fontSize: "100% !important",
                "&:hover, &:focus": {
                    // override conditional background and font color to always be the original
                    backgroundColor: "initial !important",
                    color: "initial !important",
                },
            },
        }),
        container: (base:any, _state:any) => ({
            ...base,
            backgroundColor: 'none',
            width: "100%",
        }),
        group: (base:any, _state:any) => ({
            ...base,
            backgroundColor: 'red',
        }),
        option: (base:any, _state:any) => ({
            ...base,
            // override conditional background and font color to always be the original
            backgroundColor: "initial",
            color: "initial",
            fontWeight: 400,
            "&:hover, &:focus": {
                backgroundColor: COLORS.PRIMARY_70,
            },
        }),
    };

    function isLocked(propertyId: string): boolean {
        if (props.versionType == VersionType.Reforecast) {
            return props.lockedProperties?.userPropertiesForecastLocks?.reforecastLocked.includes(propertyId) ?? false;
        } else {
            return props.lockedProperties?.userPropertiesForecastLocks?.budgetLocked.includes(propertyId) ?? false;
        }
    }

    const Option = (props: any) => (
        <components.Option {...props}>
            <div className={css.dropdownOption} style={{opacity: props.isDisabled ? .4 : 1}}>
                <img src={Checkmark} alt="check" className={!props.isSelected ? css.hidden : ""}/>
                {props.label}
                {props.isDisabled &&
                    <span className={css.dropdownMetaText}>
                        {periodText} is locked. No edits are permitted.
                    </span>
                }
            </div>
        </components.Option>
    );

    return (
        <Select
            isMulti
            options={matchingOptions}
            closeMenuOnSelect={false}
            backspaceRemovesValue={false}
            hideSelectedOptions={false}
            isClearable={false}
            onChange={(values:any, _meta) => handleChange(values)}
            onInputChange={value => setInputValue(value)}
            value={props.selectedOptions}
            styles={customStyles}
            components={{IndicatorSeparator: () => null, Option}}
            isOptionDisabled={option => isLocked(option.value)}
        />
    );
}
