import {ReactElement, useEffect} from "react";
import {ThemeProvider} from "@zendeskgarden/react-theming";
import {ViziblyTheme} from "../analyst/ViziblyZDGTheme";

import Header, { DropdownPlacement } from "./components/Header";
import useAppStore from "../../hooks/useAppStore";
import useSummaryHook from "./logic/useSummary";

import * as css from "./styles/OpDriverModeling.module.scss";
import * as workflowCSS from "../../styles/workflows/workflowCSS.module.scss";
import SummaryTableTabs from "./components/Tabs";
import {WorkflowStepNavProps} from "../../components/workflows/workflow-step-nav/WorkflowStepNav";
import SummaryTable from "./components/SummaryTable";
import { useProperties } from "../../contexts/properties/PropertiesContext";


export default function Summary(props: WorkflowStepNavProps): ReactElement {
    const appStore = useAppStore();
    const { currentProperty } = useProperties();
    const useSummary = useSummaryHook();

    const handleSelectOption = (option: string) => {
        useSummary.setSelectedUnitType({ selectedUnitType: option });
    };

    useEffect(() => {
        appStore.set({ isLoading: false });

        return () => undefined;
    }, []);

    return (
        <ThemeProvider theme={ViziblyTheme}>
            <div className={`${css.opDriverModelingWrapper} ${workflowCSS.workflowContainer} ${css.summaryContainer}`}>
                <div className={workflowCSS.workflowContainerInner}>
                    <Header
                        title="Summary"
                        options={useSummary.unitTypes}
                        selectedOption={useSummary.selectedUnitType}
                        onSelectOption={handleSelectOption}
                        dropdownPlacement={DropdownPlacement.RIGHT}
                        nextAction={props.onNextClick}
                        prevAction={props.onPreviousClick}
                    />
                    <SummaryTableTabs
                        selectedTab={useSummary.selectedTab}
                        setSelectedTab={useSummary.setSelectedTab}
                    />
                    {currentProperty && (
                            <SummaryTable
                                currentProperty={currentProperty}
                                selectedTab={useSummary.selectedTab}
                                selectedUnitType={useSummary.selectedUnitType}
                                tableDataByTabAndUnitType={useSummary.tableDataByTabAndUnitType}
                            />
                        )
                    }
                </div>
            </div>
        </ThemeProvider>
    );
}
