import { useEffect, useState } from "react";
import { useGetMvrBudgetAssumptionsForPropertyAndAccountLazyQuery } from "../../../../../../../../__generated__/generated_types";
import { ModelingMethodType } from "./enums/ModelingMethodTypeEnum";
import { mapRawDataToAssumptionsModel } from "./logic/mapRawDataToAssumptionsModel";
import { AssumptionsModel } from "./models/AssumptionsModel";

export interface UseBudgetAssumptionsDataProps {
    propertyId: string;
    accountId: string;
    year: number;
    monthIndex: number;
}

export type UseBudgetAssumptionsData = {
    data: AssumptionsModel;
}

export function useBudgetAssumptionsData(props: UseBudgetAssumptionsDataProps): UseBudgetAssumptionsData {
    const [fetchBudgetAssumptions, {data, loading}] = useGetMvrBudgetAssumptionsForPropertyAndAccountLazyQuery({
        fetchPolicy: "network-only"
    });

    const [budgetAssumptionsModel, setBudgetAssumptionsModel] = useState<AssumptionsModel>({
        modelingMethodType: ModelingMethodType.MANUAL_ENTRY,
        assumptionRows: [],
        originalValue: null
    });

    useEffect(
        () => {
            fetchBudgetAssumptions({
                variables: {
                    propertyId: props.propertyId,
                    accountId: props.accountId,
                    year: props.year
                }
            });
        },
        [props.propertyId, props.accountId, props.year]
    );

    useEffect(
        () => {
            if (data && !loading) {
                const model = mapRawDataToAssumptionsModel(data, props.monthIndex);
                setBudgetAssumptionsModel(model);
            }
        },
        [data, loading, props.monthIndex]
    );

    return {
        data: budgetAssumptionsModel
    };
}