import { ReactElement, useEffect, useState } from "react";
import { MetricCard } from "../../metric-card/MetricCard";
import Chart from "react-apexcharts";
import MenuTitle from "../../menu-title/MenuTitle";
import { IMenuTitleOption } from "../../menu-title/logic/menuTitle";
import { Col, Grid, Row } from "@zendeskgarden/react-grid";
import * as css from "../styles/styles.module.scss";
import { ApexOptions } from "apexcharts";
import { COLORS } from "../../../constants/Colors";
import { useAccountSummaryContext } from "../../../pages/workflows/account/AccountSummaryContext";
import { AccountChangeColorMap, AccountChangeDirection, getTotalsForYear } from "../logic/AccountSummaryLogic";
import { formatterDollarUSNoDecimal } from "../../../utils/formatters";
import { buildApexChartConfig } from "../../../styles/apex-charts/ViziblyChartDefaultConfig";

export function BudgetRemainingCard(): ReactElement {
    const ctxAcctSummary = useAccountSummaryContext();

    const [chartSeries, setChartSeries] = useState<ApexAxisChartSeries>([]);
    const [titleText, setTitleText] = useState<string>('');
    const [bdgtRemaining, setBdgtRemaining] = useState<number>(0);
    const [yMin, setYMin] = useState<number>(-10);
    const [yMax, setYMax] = useState<number>(10);
    const [bdgtRemainingStatus, setBdgtRemainingStatus] = useState<AccountChangeDirection>(AccountChangeDirection.NEITHER);
    const [bdgtRemainingText, setBdgtRemainingText] = useState<string>('');

    const leftOptions: IMenuTitleOption[] = [
        { label: titleText, value: 0 }, // 'bdgt_remains'
    ];

    const chartOptions: ApexOptions = {
        chart: {
            stacked: true,
        },
        plotOptions: {
            bar: {
                columnWidth: '25%',
            }
        },
        xaxis: {
            categories: [ctxAcctSummary.currentYear.toString()],
        },
        yaxis: {
            min: yMin,
            max: yMax,
        }
    };

    useEffect(
        () => {
            if (!ctxAcctSummary.data) return;

            setTitleText(ctxAcctSummary.isIncome ? 'Revenue Target' : 'Budget Remaining');

            const {
                act,
                fcst,
                rfcst,
                bdgt,
            } = getTotalsForYear(ctxAcctSummary.currentYear, ctxAcctSummary);

            let localBudgetRemaining;
            if (ctxAcctSummary.isIncome) {
                localBudgetRemaining = rfcst - bdgt;
            } else {
                localBudgetRemaining = bdgt - rfcst;
            }

            setBdgtRemaining(Math.abs(localBudgetRemaining));

            if (localBudgetRemaining > 0) {
                setBdgtRemainingStatus(AccountChangeDirection.GOOD);
                setBdgtRemainingText(ctxAcctSummary.isIncome ? 'over' : 'under');
            } else if (localBudgetRemaining < 0) {
                setBdgtRemainingStatus(AccountChangeDirection.BAD);
                setBdgtRemainingText(ctxAcctSummary.isIncome ? 'under' : 'over');
            } else {
                setBdgtRemainingStatus(AccountChangeDirection.NEITHER);
                setBdgtRemainingText('even with');
            }

            // it didnt look like apexcharts accounts for the value of the goal marker when computing
            // min and max for y axis values, so thats what were doing here
            const highestAbsolute = Math.max(Math.abs(rfcst ?? -10), Math.abs(bdgt ?? -10));
            if (rfcst >= 0 && bdgt >= 0) {
                setYMin(0);
                setYMax(highestAbsolute);
            } else if (rfcst <= 0 && bdgt <= 0) {
                setYMax(0);
                setYMin(highestAbsolute * -1);
            } else {
                setYMin(Math.min(rfcst ?? -10, bdgt ?? -10));
                setYMax(Math.max(rfcst ?? 10, bdgt ?? 10));
            }

            setChartSeries([
                {
                    name: 'Actual',
                    data: [{
                        x: ctxAcctSummary.currentYear.toString(),
                        y: act,
                    }],
                },
                {
                    name: 'Forecast',
                    data: [{
                        x: ctxAcctSummary.currentYear.toString(),
                        y: fcst,
                        goals: [
                            {
                                name: 'Budget',
                                value: bdgt,
                                strokeHeight: 5,
                                strokeColor: COLORS.PRIMARY_600
                            }
                        ]
                    }],
                }
            ]);
        },
        [ctxAcctSummary.data]
    );

    const chartConfig = buildApexChartConfig({ options: chartOptions, series: chartSeries });

    return (
        <MetricCard>
            <MetricCard.Title>
                <div className={css.accountSummaryTitle}>
                    <MenuTitle options={leftOptions} />
                </div>
            </MetricCard.Title>

            <MetricCard.Body>
                <Grid>
                    <Row>
                        <Col>
                            <h2 className={`${css.accountSummaryHeaderText} ${AccountChangeColorMap[bdgtRemainingStatus]}`}>
                                {formatterDollarUSNoDecimal.format(bdgtRemaining)}
                            </h2>
                            <h5>
                                <span className={AccountChangeColorMap[bdgtRemainingStatus]}>{bdgtRemainingText}</span> {ctxAcctSummary.currentYear} Budget
                            </h5>
                        </Col>
                    </Row>
                </Grid>

                <Chart
                    type="bar"
                    options={chartConfig.options}
                    series={chartConfig.series}
                    height={188}
                />
            </MetricCard.Body>
        </MetricCard>
    );
}