import { Button } from "@zendeskgarden/react-buttons";
import { Body, Close, Footer, FooterItem, Header, Modal } from "@zendeskgarden/react-modals";
import { ReactElement } from "react";
import { AccountStatusCard, AccountStatusCardProps } from "./AccountStatusCard";

export type AccountStatusModalProps = AccountStatusCardProps & {
    title: string,
    onClose: () => void,
}

export default function AccountStatusModal(props: AccountStatusModalProps): ReactElement {

    return (
        <Modal onClose={props.onClose} isLarge>
            <Header>
                {props.title}
            </Header>
            <Body>
                {props.item.isNew ?
                <>New Account</>
                :
                <AccountStatusCard {...props}/>
                }
            </Body>
            <Footer>
                <FooterItem>
                    <Button onClick={props.onClose} isBasic>
                        Ok
                    </Button>
                </FooterItem>
            </Footer>
            <Close aria-label="Close modal" />
        </Modal>
    );
}