import { ReactElement } from "react";
import { BsArrowRightShort } from "react-icons/bs";
import { Button } from 'react-bootstrap';


import './Footer.scss';

interface FooterProps {
    backText: string;
    nextText: string;
    debugContext?: boolean;
    needGoBack?: () => void
    needGoNext?: () => void
    markCompleted?: () => void;
}

export default function Footer(props: FooterProps): ReactElement {

    function backClicked() {
        props.needGoBack?.();
    }

    function nextClicked() {
        props.needGoNext?.();
    }

    return (
        <footer className="footer w-100 d-flex flex-row justify-content-end bg-white">
            <div className="py-3 pe-5">
                {props.markCompleted &&
                    <div className="form-check d-flex align-items-center pe-4">
                        <input type="checkbox" className="form-check-input" onClick={props.markCompleted} />
                        <label className="form-check-label ps-2 small" > Mark as Complete </label>
                    </div>
                }
                <Button variant="secondary" onClick={backClicked}>
                    {props.backText}
                </Button>
                <Button variant="primary" onClick={nextClicked} className="btn-primary">
                    {props.nextText}
                    <BsArrowRightShort className="next-btn-icon fs-2" />
                </Button>
            </div>
        </footer>
    );
}

