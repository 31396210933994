import React, { ReactElement, useEffect, useState } from "react";

import { useSettings } from "../../../contexts/settings/SettingsContext";

import PageLabel from "../../../components/layout/page-label/PageLabel";

import PageHeader from "../../../components/layout/page-header/PageHeader";
import { columnItemsContainer } from "../../../components/layout/page-header/styles/css.module.scss";

import YearMonthSelector from "../../../components/year-month-selector/YearMonthSelector";

import { useHistory, useParams } from "react-router-dom";

import { Button } from '@zendeskgarden/react-buttons';
import { Notification, Title, Close } from '@zendeskgarden/react-notifications';
import { ReactComponent as Download } from '@zendeskgarden/svg-icons/src/16/download-stroke.svg';
import { Spinner } from '@zendeskgarden/react-loaders';

import * as reportsCSS from "../styles/reportsCSS.module.scss";
import * as css from "./styles/css.module.scss";

import { useMonthlyVarianceReportContext } from "./context/MonthlyVarianceReportContext";
import {
    IMonthlyVarianceModalDataProps,
    MVRNavigationAccount
} from "./components/monthly-variance-modal/data/useMonthlyVarianceModalData";

import { parseMonthParam } from "./logic/monthlyVarianceReport";

import { buildMVRModalAccountList } from "./components/monthly-variance-modal/data/logic/buildMVRModalAccountList";
import ShimmerRenderer, { ShimmerPageKey } from "../../../components/shimmer/ShimmerRenderer";

import MVRSpreadsheet from "./spreadsheet/MVRSpreadsheet";
import MonthlyVarianceModal from "./components/monthly-variance-modal/MonthlyVarianceModal";
import useQuery from "../../../hooks/useQuery";

function MonthlyVarianceReport(): ReactElement {

    const history = useHistory();
    const query = useQuery();

    /**
     * Monthly VarianceReport Context
     */
    const ctx = useMonthlyVarianceReportContext();

    const { year: yearParam, month: monthParam } = useParams() as { year: string, month: string };
    useEffect(
            () => {
                if(!ctx.isMvrContextReady){
                    return;
                }
                const [validYear, yearParseOK] = ctx.getValidReportYear(yearParam);
                // Note: monthParams are 1-based, validMonths are 0-based
                const [validMonth, monthParseOK] = parseMonthParam(monthParam);

                // If either param wasn't able to be parsed to an expected/valid value, update the url with the
                // validated values
                if(!monthParseOK || !yearParseOK){
                    const newPath = ctx.getUpdatedPath(validYear, validMonth);
                    history.replace(newPath);
                }

                ctx.mvrData.setReportDate(validYear, validMonth);

                /**
                 * The =?expand=<accountId> query param expects a valid account ID, and if present, will open the
                 * MVR details modal on load. If present, the query param will be cleared and the modal will open either
                 * to the requested account, or the first account found if the requested account isn't valid.
                 */
                const expand = query.get('expand');
                if(expand != undefined){
                    // Set the modal to open to the requested account
                    setModalOpenToAccount(expand);

                    // ...and clear the query param
                    const newPath = ctx.getUpdatedPath(validYear, validMonth);
                    history.replace(newPath);
                }
            },
            [yearParam, monthParam, ctx.isMvrContextReady]
    );

    const [showDataErrorAlert, setShowDataErrorAlert] = useState<boolean>();
    useEffect(
            () => {
                setShowDataErrorAlert(ctx.mvrDataErrors != undefined && ctx.mvrDataErrors.length > 0);
            },
            [ctx.mvrDataErrors]
    );

    /**
     * Handles updates to the Date Picker component for the main report.
     * @param year  (number) Report year. Note: Business rules dictate that a user may get a report from 2 years back,
     * and 1 year forward (as defined by Briant)
     * @param month (number) A 0-based representation of a month
     */
    const onMainReportDatePickerUpdate = (year: number, month: number): void => {

        const newPath = ctx.getUpdatedPath(year, month);
        history.push(newPath);

    };

    /**
     * Modal presense state is defined here in the main report.
     */
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const [modalOpenToAccount, setModalOpenToAccount] = useState<string>();

    /**
     * modalConfig represents datapoints necessary to display the modal, namely accountId, report month, and report year
     */
    const [modalConfig, setModalConfig] = useState<IMonthlyVarianceModalDataProps>();

    const canModalOpen = ctx.isMvrContextReady
            && ctx.mvrAccountsReady;

    const [modalAccountList, setModalAccountList] = useState<MVRNavigationAccount[]>();
    useEffect(
            () => {
                if(!ctx.mvrAccountsReady || ctx.mvrAccounts.length == 0){
                    return;
                }
                setModalAccountList(buildMVRModalAccountList(ctx.mvrAccounts));
            },
            [ctx.mvrAccountsReady, ctx.mvrAccounts]
    );

    useEffect(
            () => {
                if(
                        !canModalOpen
                        || modalOpenToAccount === undefined
                        || !modalAccountList
                ){
                    return;

                }

                setModalConfig(prev => {
                    if(
                            !modalOpenToAccount
                            || !ctx.mvrReportYear
                            || ctx.mvrReportMonth == undefined
                            || !ctx.reportPropertyId
                    ){
                        return prev;
                    }

                    return {
                        accountId: modalOpenToAccount,
                        accounts: modalAccountList,
                        propertyId: ctx.reportPropertyId,
                        year: ctx.mvrReportYear,
                        month: ctx.mvrReportMonth,
                    };
                });



                setIsModalOpen(true);
            },
            [
                ctx.mvrAccountsReady,
                ctx.isMvrContextReady,
                ctx.reportPropertyId,
                ctx.mvrReportYear,
                ctx.mvrReportMonth,
                modalAccountList,
                canModalOpen,
                modalOpenToAccount
            ]
    );

    /**
     * Clears modalOpenToAccountState, which is necessary for the modal to be visible, to close the modal
     */
    useEffect(
            () => {
                if(isModalOpen){
                    return;
                }

                setModalOpenToAccount(undefined);
            },
            [isModalOpen]
    );

    const requestModalOpen = (accountId: string|undefined) => {
        setModalOpenToAccount(accountId);
    };

    return (
            <div className={reportsCSS.reportBody}>
                {
                    showDataErrorAlert
                            ? <div className={css.errorAlertContainer}>
                                <Notification type="error">
                                    <Title>Error</Title>
                                    We were unable to load the requested data.
                                    <Close
                                            aria-label="Close Error Alert"
                                            onClick={() => {
                                                setShowDataErrorAlert(false);
                                            }
                                            }/>
                                </Notification>
                            </div>
                            : <></>
                }
                <PageHeader>
                    <PageHeader.LeftCol className={columnItemsContainer}>
                        <PageLabel
                                label="Monthly Variance Report"
                                className={reportsCSS.reportLabelContainer}
                        />
                        <div className={css.datePickerContainer}>
                            <YearMonthSelector
                                    year={ctx.mvrReportYear}
                                    month={ctx.mvrReportMonth}
                                    maxYear={ctx.maxReportYear}
                                    minYear={ctx.minReportYear}
                                    onClick={onMainReportDatePickerUpdate}
                                    // disabledForwardLabel={"Budget Year"}
                            />
                            { ctx.mvrData.loading ? <Spinner size="large"/> : <></>}
                        </div>
                    </PageHeader.LeftCol>
                </PageHeader>
                {
                    !ctx.isMvrDataLoaded
                            ? <ShimmerRenderer pageKey={ShimmerPageKey.MONTHLY_VARIANCE_REPORT}/>
                            : <></>
                }
                <MVRSpreadsheet
                        year={ctx.mvrData.mvrReportYear}
                        month={ctx.mvrData.mvrReportMonth}
                        data={ctx.mvrTableData}
                        requestModalOpen={requestModalOpen}
                        isVisible={ctx.isMvrDataLoaded}
                />
                {
                    modalConfig != undefined
                            ? <MonthlyVarianceModal
                                    isVisible={isModalOpen}
                                    modalConfig={modalConfig}
                                    closeRequested={() => {
                                        setIsModalOpen(false);
                                    }}
                            />
                            : <></>
                }
            </div>
    );
}

export default MonthlyVarianceReport;