import { DashboardHeaderSection } from "../../../components/layout/dashboard/components/DashboardHeader";

import { ReactComponent as DollarsIcon } from "../../../assets/icons/icon_dollar_circle.svg";
import { ReactComponent as OpDriversIcon } from "../../../assets/icons/icon_adjust.svg";
import { ReactComponent as RenovationsIcon } from "../../../assets/icons/icon_building.svg";

export enum REVENUE_DASHBOARD_SECTION {
    MARKET_RENTS,
    LEASE_ACTIVITY,
    SUMMARY,
}

export const REVENUE_DASHBOARD_PATH = '/revenue';
export const REVENUE_DASHBOARD_DEFAULT_SECTION = REVENUE_DASHBOARD_SECTION.SUMMARY;

export const RevenueDashboardSections:DashboardHeaderSection[] = [
    {
        sectionId: REVENUE_DASHBOARD_SECTION.MARKET_RENTS,
        label: 'Market Rents',
        navLocation: `${REVENUE_DASHBOARD_PATH}/market-rents`,
    },
    {
        sectionId: REVENUE_DASHBOARD_SECTION.LEASE_ACTIVITY,
        label: 'Leasing Activity',
        navLocation: `${REVENUE_DASHBOARD_PATH}/leasing-activity`,
    },
    {
        sectionId: REVENUE_DASHBOARD_SECTION.SUMMARY,
        label: 'Summary',
        navLocation: `${REVENUE_DASHBOARD_PATH}/summary`,
    },
];
