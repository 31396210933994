/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { ReactElement, useState, useEffect } from "react";
import { NavLink, useParams } from "react-router-dom";
import { IoIosArrowForward, IoIosArrowDown } from "react-icons/io";
import "../sidebar/SideBar.css";
import * as styles from "./styles.module.scss";
import "./Tree.scss";
import useAppStore from "../../hooks/useAppStore";
import { activeLink } from "./styles.module.scss";


export interface TreeNode {
    title: string;
    subtitle?: string;
    children?: TreeNode[];
    isCompleted?: boolean;
    accountId?: string;
    url?: string;
    useRawUrl?:boolean;
    isWorkflow?: boolean;
}

export interface TreeNodeProps {
    node: TreeNode;
    type: string;
    depth: number;
}

export interface Props {
    nodes: TreeNode[];
    type: string;
    depth: number;
}

function TreeNode(props: TreeNodeProps): ReactElement {
    const appStore = useAppStore();
    const { id: accountId } = useParams() as { id: string };
    const { node, type, depth } = props;

    // expandableParents()
    // const isHistoryNavExpanded = appStore.sideBarItem?.parentkeys[] === props.node.title;

    const [isExpanded, setIsExpanded] = useState(false);
    const toggleExpand = () => setIsExpanded(!isExpanded);

    const expandableParents = () => {
        const arr = appStore.sideBarItem?.parentkeys;
        if(arr?.includes(node.title)) {
            setIsExpanded(true);
            return;
        }
        setIsExpanded(false);
    };

    useEffect(() => {
        expandableParents();
        return () => { };
    }, [appStore.sideBarItem]);

    const getNodeDepthStyle = (depth:number):string => {
        switch(depth){
            case 0:{
                return styles.nodeDepth0;
                break;
            }
            case 1:{
                return styles.nodeDepth1;
                break;
            }
            case 2:{
                return styles.nodeDepth2;
                break;
            }
            case 3:{
                return styles.nodeDepth3;
                break;
            }
            default: {
                return '';
            }
        }
    };

    return (
        <div className={`${getNodeDepthStyle(props.depth)}`} >

            <div>
                <div className="sidebar-icons">
                    {!node.children ? <div className={"sidebar-expanded-border "}></div>
                        : !isExpanded ? (
                            <IoIosArrowForward
                                className={`${styles.arrowForwardIcon} ` + (
                                    isExpanded ? "fw-bold text-primary category-expanded " : `${styles.leftNavFont}`
                                )}
                                onClick={() => toggleExpand()}
                            />
                        ) : (
                            <IoIosArrowDown
                                className={`${styles.arrowDownIcon} ` + (
                                    isExpanded ? "fw-bold text-primary category-expanded " : `${styles.leftNavFont}`
                                )}
                                onClick={() => toggleExpand()}
                            />
                        )
                    }
                </div>

                {node.url ? (
                    <NavLink
                        activeClassName={`${activeLink}`}
                        className={`text-decoration-none ${styles.leftNavFont} fw-normal`}
                        to={node.useRawUrl ? node.url : node.url + "/" + type}
                        onClick={() => {
                            appStore.set({ isSideBarVisible: false });
                        }}>
                        <div
                            className={`fw-normal ${styles.sidebarAccountDefault} ${styles.sidebarAccountLink} ${styles.accountSelected}`}>
                            <div> {node.title} </div>
                        </div>
                    </NavLink>

                ) : !node.accountId ? (
                    <div
                        title={node.title}
                        onClick={() => toggleExpand()}
                        key="node-title"
                        className={`${styles.treeCategoryTitle} ${styles.leftNavFont}`}
                    >
                        {node.title}
                    </div>

                ) : (
                    <NavLink
                            activeClassName={`${styles.activeLink}`}
                            className={`text-decoration-none ${styles.leftNavFont} fw-normal`}
                            to={"/account/" + node.accountId + "/" + type}
                            onClick={() => {
                                appStore.set({ isSideBarVisible: false });
                            }}
                    >
                        <div
                            className={`${styles.sidebarAccountDefault} ${styles.sidebarAccountLink} ` + (node.accountId == accountId && `${styles.activeLink} ${styles.accountSelected}`)}>
                            <div> {node.title} </div>
                        </div>
                    </NavLink>

                )}
            </div>
            <div className="sidebar-accounts">
                {isExpanded && node.children && <Tree nodes={node.children} type={type} depth={depth + 1} />}
            </div>
        </div>
    );
}

export default function Tree(props: Props): ReactElement {

    return (
        <div className={`${styles.sidebarCategoryTable}`}>
            {props.nodes.map((node, i) => (
                <TreeNode
                    key={i}
                    type={props.type}
                    node={node}
                    depth={props.depth}
                />
            ))}
        </div>
    );
}
