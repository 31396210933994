import React, { ReactElement } from "react";
import * as css from "./styles/css.module.scss";

export type IWorkflowLoadingOverlayProps = {
    loading: boolean
}

export default function WorkflowLoadingOverlay(props:IWorkflowLoadingOverlayProps):ReactElement {
    return (
            props.loading
                    ? <div className={css.workflowLoadingOverlay}/>
                    : <></>
    );
}
