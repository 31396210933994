import GC from "@grapecity/spread-sheets";
import Style = GC.Spread.Sheets.Style;
import { DEFAULT_ICON_W, ToggleOpenIconSpec, ButtonSpec, DEFAULT_BUTTON_W } from "./icons/CellDecoratorSpecs";
import { ICustomCellInfo, ICustomCellToggleHitInfo } from "./types";
import { renderButton, renderToggleIcon } from "./icon-renderers/draw_expand_toggle";
import { RowEntityType } from "../../../pages/analyst/enums";
import { OpDriverMetricType, OpDriverPropertyEditLevel } from "../../../pages/analyst/tab-op-drivers/enums";

export interface FinancialEntityCellInfo extends ICustomCellInfo {
    subLevelXOffset?: number,
    style?: Style,
    padding?: number,
    toggleIconSpecs?: ToggleOpenIconSpec,
    isLoaded?: boolean,
    isIconHovered?:boolean,
    isOpen?: boolean,
    level?:number,
    buttonSpecs?:ButtonSpec,
    isButtonVisible?:boolean,
    propertyId?:string,
    unitTypeId?:string,
    renovationPackageId?: string,
    metricType?:OpDriverMetricType,
    editLevel?:OpDriverPropertyEditLevel,
    unitsCount?:number,
    isRowDataReadonlyForMetricType?:boolean
}

export interface FinancialEntityCellVars {
    row: number
    canOpen: boolean
    isOpen: boolean
    level: number|undefined
    isButtonHovered: boolean,
    isToggleHovered: boolean,
    rowEntityType:RowEntityType,
}

export class FinancialEntityCell extends GC.Spread.Sheets.CellTypes.Text {
    private isOpen:boolean;
    private isButtonHovered:boolean;
    private isToggleHovered:boolean;

    constructor(private cellInfo: FinancialEntityCellInfo, private row:number, readonly canOpen:boolean, private rowEntityType:RowEntityType) {
        super();
        this.typeName = "FinancialEntityCell";
        this.cellInfo = cellInfo;
        this.row = row;
        this.isOpen = true;
        this.canOpen = canOpen;
        this.isButtonHovered = false;
        this.isToggleHovered = false;
        this.rowEntityType = rowEntityType;

        if(!this.cellInfo.isButtonVisible){
            this.cellInfo.isButtonVisible = false;
        }
    }

    showPointer = false;

    updateCellInfo(cellInfo: FinancialEntityCellInfo) {
        this.cellInfo = cellInfo;
    }

    public getCellInfo():FinancialEntityCellInfo{
        return this.cellInfo;
    }

    public getCellVars():FinancialEntityCellVars{
        return {
            row: this.row,
            isOpen: this.isOpen,
            canOpen: this.canOpen,
            level: this.cellInfo.level,
            isButtonHovered: this.isButtonHovered,
            isToggleHovered: this.isToggleHovered,
            rowEntityType: this.rowEntityType
        };
    }

    public getIsOpen():boolean{
        return this.isOpen;
    }

    public setIsOpen(b:boolean):void{
        this.isOpen = b;
    }

    public setRow(n: number):void{
        this.row = n;
    }

    override paint(ctx: CanvasRenderingContext2D, value: any, x: number, y: number, w: number, h: number, style: GC.Spread.Sheets.Style, context?: any): void {

        if (this.cellInfo.style){
            style = this.cellInfo.style;
        }
        super.paint(ctx, value, x, y, w, h + (this.cellInfo.padding || 0), style, context);
    }

    override paintContent(ctx: CanvasRenderingContext2D, value: any, x: number, y: number, w: number, h: number, style: GC.Spread.Sheets.Style, context?: any): void {

        if (this.cellInfo.style){
            style = this.cellInfo.style;
        }

        super.paintContent(ctx, value, x, y, w, h, style, context);

        if(this.cellInfo.toggleIconSpecs){
            renderToggleIcon(ctx, x, y, this.cellInfo.toggleIconSpecs, this.isOpen, this.cellInfo.isIconHovered);
        }

        if(this.cellInfo.buttonSpecs && this.cellInfo.isButtonVisible){
            renderButton(ctx, x, y, this.cellInfo.buttonSpecs, this.isButtonHovered);
        }
    }

    override getHitInfo(x: number, y: number, _cellStyle: GC.Spread.Sheets.Style, cellRect: GC.Spread.Sheets.Rect, context?: any): GC.Spread.Sheets.IHitTestCellTypeHitInfo {
        const c = cellRect; // alias
        const [row, col] = [context.row as number, context.col as number];

        this.isToggleHovered = this.getIsToggleIconHovered(x, y, cellRect);
        this.isButtonHovered = this.getIsButtonHovered(x, y, cellRect);

        return {
            x,
            y,
            row,
            col,
            cellRect,
            isOpen: this.isOpen,
            highlightToggle: this.isToggleHovered || this.isButtonHovered,
            isTargetHit: this.isToggleHovered || this.isButtonHovered,
        } as ICustomCellToggleHitInfo;
    }

    getIsToggleIconHovered(x:number, _y:number, c:GC.Spread.Sheets.Rect):boolean{
        if(this.cellInfo.toggleIconSpecs){
            const { openIconSpecs, closedIconSpecs } = this.cellInfo.toggleIconSpecs as ToggleOpenIconSpec;
            if(this.isOpen){
                if(x > c.x + openIconSpecs.x && x < c.x + openIconSpecs.x + (openIconSpecs?.width ?? DEFAULT_ICON_W)){
                    return true;
                }
            } else {
                if(x > c.x + closedIconSpecs.x && x < c.x + closedIconSpecs.x + (closedIconSpecs?.width ?? DEFAULT_ICON_W)){
                    return true;
                }
            }
        }

        return false;
    }

    getIsButtonHovered(x:number, _y:number, c:GC.Spread.Sheets.Rect):boolean{
        if(!this.cellInfo.isButtonVisible){
            return false;
        }

        if(this.cellInfo.buttonSpecs){
            const { x:btnX, width: btnWidth } = this.cellInfo.buttonSpecs as ButtonSpec;
            if(this.isOpen){
                if(x > c.x + btnX && x < c.x + btnX + (btnWidth ?? DEFAULT_BUTTON_W)){
                    return true;
                }
            } else {
                if(x > c.x + btnX && x < c.x + btnX + (btnWidth ?? DEFAULT_BUTTON_W)){
                    return true;
                }
            }
        }

        return false;
    }
}

