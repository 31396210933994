import { ReactElement } from "react";

import { IconButton } from "@zendeskgarden/react-buttons";

import { ReactComponent as ClosedIcon } from '@zendeskgarden/svg-icons/src/16/plus-stroke.svg';
import { ReactComponent as OpenIcon } from '@zendeskgarden/svg-icons/src/16/dash-stroke.svg';

interface IExpandDetailsToggleProps {
    isExpanded: boolean,
    onClick: (isExpanded: boolean) => void,
    size?: 'small'|'medium'|'large'|undefined,
}

export default function ExpandDetailsToggle(props: IExpandDetailsToggleProps): ReactElement {

    const _size = props.size ?? 'small';
    return (
            <IconButton
                    size={_size}
                    onClick={() => {
                        props.onClick(!props.isExpanded);
                    }}>
                {
                    props.isExpanded
                            ? <OpenIcon/>
                            : <ClosedIcon/>
                }
            </IconButton>
    );
}