import { Col, Row } from "@zendeskgarden/react-grid";
import * as css from "../../../styles/css.module.scss";
import { ReactElement } from "react";
import { buildReferenceAccountHTML } from "../logic/useInsightsWidget";
import { AssumptionRowModel } from "../../budget-assumptions/data/models/AssumptionRowModel";

export interface IInsightsReferenceAccountsProps {
    referenceAccounts: AssumptionRowModel[],
}

export function InsightsReferenceAccounts(props: IInsightsReferenceAccountsProps): ReactElement {

    return (
            <ul>
                {buildReferenceAccountHTML(props.referenceAccounts)}
            </ul>
    );
}