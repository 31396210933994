import { ReactElement, useEffect, useRef, useState } from "react";
import { MONTHS } from "../../../../../../constants/Months";

import { TDriverGrowthMetric } from "../../../../../../contexts/account/data/logic/driversAndWorksheetData";
import { MonthlyAdjustmentType, MonthlyAverageBasePeriod } from "../../../../../../__generated__/generated_types";
import MonthlyAverageMenu from "../formula-menu/MonthlyAverageMenu";

import { deleteNodeKeys, TFormulaNodeProps } from "./logic/formulaNode";
import * as css from "./styles/css.module.scss";


type TGrowthDriverFxNodeProps = TFormulaNodeProps&{
    driver: TDriverGrowthMetric,
}

export const MonthlyAverageBasePeriodInverseMap = {
    [MonthlyAverageBasePeriod.LastMonth]: 1,
    [MonthlyAverageBasePeriod.LastThreeMonthsActuals]: 3,
    [MonthlyAverageBasePeriod.LastSixMonthsActuals]: 6,
    [MonthlyAverageBasePeriod.LastTwelveMonthsActuals]: 12,
};

export default function(props: TGrowthDriverFxNodeProps): ReactElement {
    const [isFocused, setIsFocused] = useState<boolean>(false);
    const [childNodeHovered, setChildNodeHovered] = useState<boolean>(false);

    const wrapperRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (isFocused && wrapperRef.current) {
            wrapperRef.current.focus();
        }
    }, [isFocused]);

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (
                wrapperRef.current &&
                (wrapperRef.current === event.target ||
                wrapperRef.current.contains(event.target as Node))
            ) {
                return;
            }
            setIsFocused(false);
        };

        // Clicking outside the current node should remove focus
        document.addEventListener("mousedown", handleClickOutside);

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    const handleClick = () => {
        setIsFocused(!isFocused);
    };

    const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
        if (
            isFocused && deleteNodeKeys.includes(e.key) && !childNodeHovered
        ) {
            props.fbUpdates.removeMonthlyAverageGrowthDriver();
        }
    };

    const nodeClassName = props.locked ? css.fxNodeLocked : css.fxNode;
    const className = `${nodeClassName} ${props.className ? props.className : ''} ${css.deletableNode} ${isFocused ? css.focusedNode : ''} ${childNodeHovered ? css.disableHover : ''}`;

    const renderBasePeriod = (): string | JSX.Element => {
        let basePeriodNodeText = '';
        if (props.driver.monthlyAverageBasePeriod === MonthlyAverageBasePeriod.CustomLookbackPeriod) {
            if (props.driver.lookbackPeriodStart && props.driver.lookbackPeriodEnd) {
                const startMonth = props.driver.lookbackPeriodStart.split("-")[1];
                const endMonth = props.driver.lookbackPeriodEnd.split("-")[1];
                const startYear = props.driver.lookbackPeriodStart.split("-")[0];
                const endYear = props.driver.lookbackPeriodEnd.split("-")[0];
                if (startMonth && endMonth && startYear && endYear) {
                    const periodStart = `${MONTHS[parseInt(startMonth) - 1]} ${parseInt(startYear)}`;
                    const periodEnd = `${MONTHS[parseInt(endMonth) - 1]} ${parseInt(endYear)}`;
                    basePeriodNodeText = `${periodStart} to ${periodEnd}`;
                }
            }
        } else {
            if (props.driver.monthlyAverageBasePeriod) {
                basePeriodNodeText = `last ${MonthlyAverageBasePeriodInverseMap[props.driver.monthlyAverageBasePeriod]} months of actuals`;
            }
        }

        if (props.locked) {
            return <>&nbsp;{basePeriodNodeText}</>;
        }
        return (
            <MonthlyAverageMenu
                fbUpdates={props.fbUpdates}
                level={0}
                triggerNode={<a>{basePeriodNodeText}</a>}
            />
        );
    };

    const renderMonthlyAdjustment = (): string | JSX.Element => {
        let monthlyAdjustmentNodeText = '';
        // '!=' on purpose to check for null and undefined
        if (props.driver.monthlyAdjustmentType === MonthlyAdjustmentType.Dollar && props.driver.monthlyAdjustmentValue != null) {
            monthlyAdjustmentNodeText = `$${props.driver.monthlyAdjustmentValue} monthly adjustment`;
        }
        if (props.driver.monthlyAdjustmentType === MonthlyAdjustmentType.Percent && props.driver.monthlyAdjustmentValue != null) {
            monthlyAdjustmentNodeText = `${props.driver.monthlyAdjustmentValue}% monthly adjustment`;
        }

        if (props.locked) {
            return <>&nbsp;{monthlyAdjustmentNodeText}</>;
        }
        return (
            <MonthlyAverageMenu
                fbUpdates={props.fbUpdates}
                level={1}
                triggerNode={<a>{monthlyAdjustmentNodeText}</a>}
            />
        );
    };

    return (
        <div
            className={className}
            onClick={handleClick}
            onKeyDown={handleKeyDown}
            onMouseEnter={() => setChildNodeHovered(false)}
            ref={wrapperRef}
            tabIndex={0}
        >
            (Monthly Average:
            <div
                className={css.fxNode}
                onMouseEnter={() => setChildNodeHovered(true)}
                onMouseLeave={() => setChildNodeHovered(false)}
            >
                {renderBasePeriod()}
            </div>
            &nbsp;with&nbsp;
            <div
                className={css.fxNode}
                onMouseEnter={() => setChildNodeHovered(true)}
                onMouseLeave={() => setChildNodeHovered(false)}
            >
                {renderMonthlyAdjustment()}
            </div>)
        </div>
    );
}
