/* eslint-disable @typescript-eslint/no-namespace */
import GC from "@grapecity/spread-sheets";
import { AnalystStyles } from "../../../pages/analyst/AnalystStyles";
import { COLORS } from "../../../constants/Colors";

const VerticalAlign = GC.Spread.Sheets.VerticalAlign;
const HorizontalAlign = GC.Spread.Sheets.HorizontalAlign;
export namespace WorksheetStyles {

    // Upstream imports from Analyst View styles ____________________
    const TOTALS_RIGHT_BORDER_COLOR = AnalystStyles.TOTALS_BG_COLOR;

    const TOTALS_RT_BORDER = AnalystStyles.TOTALS_RT_BORDER;

    const CELL_BORDER = AnalystStyles.CELL_BORDER;
    export const CELL_BORDER_COLOR = AnalystStyles.CELL_BORDER_COLOR;

    // Row/Col Dimension props _______________________________________
    export const ITEM_COL_WIDTH = 191;
    export const DATA_COL_WIDTH = 56;
    export const DATA_TOTAL_COL_WIDTH = 78;
    export const HEADER_ROW_HEIGHT = 44;
    export const HEADER_SUBROW_HEIGHT = 22;
    export const DATA_ROW_HEIGHT = 48;


    // Worksheet Colors & Fonts ______________________________________________
    export const ITEM_LABEL_COLOR = COLORS.GREY_800;

    export const PROPERTY_LABEL_COLOR = COLORS.GREY_800;
    export const PROPERTY_DATA_COLOR = COLORS.PRIMARY_800;

    export const YEAR_FONT_COLOR = COLORS.GREY_600;

    export const TOTALS_HEADER_FONT_COLOR = COLORS.GREY_600;
    export const TOTALS_BG_COLOR = '#2F3941';

    // "EVEN" refers to even numbered items to support zebra stripe treatment
    export const EVEN_ITEM_BG_COLOR = COLORS.GREY_75;

    export const DATA_FONT_COLOR = COLORS.PRIMARY_800;

    export const ITEM_LABEL_FONT = '600 12px Inter';
    export const ITEM_PROPERTY_FONT = '500 12px Inter';
    export const ITEM_TOTAL_FONT = '500 12px Inter';

    // Header Styles ______________________________________________
    export const HEADER_BOTTOM_BORDER = CELL_BORDER;

    export const MONTH_ROW_LABEL = new GC.Spread.Sheets.Style();
    MONTH_ROW_LABEL.font = "700 12px Inter";
    MONTH_ROW_LABEL.foreColor = YEAR_FONT_COLOR;
    MONTH_ROW_LABEL.cellPadding = "0px";
    MONTH_ROW_LABEL.hAlign = GC.Spread.Sheets.HorizontalAlign.center;
    MONTH_ROW_LABEL.vAlign = VerticalAlign.top;
    MONTH_ROW_LABEL.locked = true;

    export const YEAR_HEADER_LABEL = new GC.Spread.Sheets.Style();
    YEAR_HEADER_LABEL.font = "600 8px Inter";
    YEAR_HEADER_LABEL.foreColor = COLORS.GREY_600;
    YEAR_HEADER_LABEL.cellPadding = "0px";
    YEAR_HEADER_LABEL.hAlign = GC.Spread.Sheets.HorizontalAlign.center;
    YEAR_HEADER_LABEL.vAlign = VerticalAlign.bottom;
    YEAR_HEADER_LABEL.locked = true;

    export const YEAR_RIGHT_BORDER = new GC.Spread.Sheets.LineBorder("#6670854D", GC.Spread.Sheets.LineStyle.medium);

    export const ITEM_COLUMN_HDR_LABEL = MONTH_ROW_LABEL.clone();
    ITEM_COLUMN_HDR_LABEL.foreColor = COLORS.GREY_500;
    ITEM_COLUMN_HDR_LABEL.vAlign = VerticalAlign.center;
    ITEM_COLUMN_HDR_LABEL.hAlign = GC.Spread.Sheets.HorizontalAlign.left;
    ITEM_COLUMN_HDR_LABEL.cellPadding = "0 0 0 56";

    export const TOTAL_COLUMN_HDR_LABEL = ITEM_COLUMN_HDR_LABEL.clone();
    TOTAL_COLUMN_HDR_LABEL.foreColor = COLORS.GREY_600;
    TOTAL_COLUMN_HDR_LABEL.backColor = COLORS.GREY_100;
    TOTAL_COLUMN_HDR_LABEL.vAlign = VerticalAlign.bottom;
    TOTAL_COLUMN_HDR_LABEL.hAlign = GC.Spread.Sheets.HorizontalAlign.center;
    TOTAL_COLUMN_HDR_LABEL.borderRight = YEAR_RIGHT_BORDER;
    TOTAL_COLUMN_HDR_LABEL.cellPadding = "3";


    // Totals Column Styles _______________________________________________________________________
    const TOTALS_COLUMN_BASE = new GC.Spread.Sheets.Style();
    TOTALS_COLUMN_BASE.vAlign = VerticalAlign.center;
    TOTALS_COLUMN_BASE.hAlign = HorizontalAlign.center;
    TOTALS_COLUMN_BASE.backColor = COLORS.GREY_100;
    TOTALS_COLUMN_BASE.foreColor = DATA_FONT_COLOR;
    TOTALS_COLUMN_BASE.borderRight = TOTALS_RT_BORDER;

    export const SPACER_TOTAL_COLUMN = TOTALS_COLUMN_BASE.clone();
    SPACER_TOTAL_COLUMN.borderRight = undefined;

    export const ITEM_TOTAL_DATA =  TOTALS_COLUMN_BASE.clone();
    ITEM_TOTAL_DATA.font = ITEM_LABEL_FONT;
    ITEM_TOTAL_DATA.wordWrap = true;
    ITEM_TOTAL_DATA.foreColor = COLORS.GREY_800;

    export const TOTAL_DATA_HOVERED = ITEM_TOTAL_DATA.clone();
    TOTAL_DATA_HOVERED.backColor = COLORS.GREY_100;
    TOTAL_DATA_HOVERED.borderRight = undefined;

    export const ODD_ITEM_TOTAL_DATA = ITEM_TOTAL_DATA.clone();
    ODD_ITEM_TOTAL_DATA.backColor = COLORS.GREY_75;

    // export const ITEM_TOTAL_DATA_HOVERED = ITEM_TOTAL_DATA.clone();
    // ITEM_TOTAL_DATAAnalystStyles.MANUAL_EDITABLE_CELL_BORDER

    // Item Styles _______________________________________________________________________
    const ITEM_LABEL_BASE = new GC.Spread.Sheets.Style();
    ITEM_LABEL_BASE.foreColor = ITEM_LABEL_COLOR;
    ITEM_LABEL_BASE.font = ITEM_PROPERTY_FONT;
    ITEM_LABEL_BASE.vAlign = VerticalAlign.center;
    ITEM_LABEL_BASE.wordWrap = true;
    ITEM_LABEL_BASE.locked = true;

    const ITEM_DATA_BASE = new GC.Spread.Sheets.Style();
    ITEM_DATA_BASE.foreColor = ITEM_LABEL_COLOR;
    ITEM_DATA_BASE.font = ITEM_PROPERTY_FONT;
    ITEM_DATA_BASE.vAlign = VerticalAlign.center;
    ITEM_DATA_BASE.locked = false;
    ITEM_DATA_BASE.formatter = "#,#;-#,#;0";

    // Item Entry Row
    export const EVEN_ITEM_NAME_LABEL = ITEM_LABEL_BASE.clone();
    EVEN_ITEM_NAME_LABEL.backColor = EVEN_ITEM_BG_COLOR;
    EVEN_ITEM_NAME_LABEL.font = ITEM_LABEL_FONT;
    EVEN_ITEM_NAME_LABEL.textIndent = 7;

    export const EVEN_ITEM_NAME_BLANK = EVEN_ITEM_NAME_LABEL.clone();

    export const EVEN_ITEM_NAME_BLANK_LOCKED_EDITABLE = EVEN_ITEM_NAME_BLANK.clone();
    EVEN_ITEM_NAME_BLANK_LOCKED_EDITABLE.backColor = {
        patternColor: COLORS.GREY_300,
        type: GC.Spread.Sheets.PatternType.lightUp
    } as GC.Spread.Sheets.IPatternFill;

    export const EVEN_ITEM_NAME_BLANK_LOCKED_NON_EDITABLE = EVEN_ITEM_NAME_BLANK_LOCKED_EDITABLE.clone();
    EVEN_ITEM_NAME_BLANK_LOCKED_NON_EDITABLE.locked = true;
    EVEN_ITEM_NAME_BLANK_LOCKED_NON_EDITABLE.foreColor = COLORS.GREY_400;

    export const EVEN_ITEM_LABEL = ITEM_LABEL_BASE.clone();
    EVEN_ITEM_LABEL.backColor = EVEN_ITEM_BG_COLOR;

    export const EVEN_ITEM_DATA_CELL = ITEM_DATA_BASE.clone();
    EVEN_ITEM_DATA_CELL.backColor = EVEN_ITEM_BG_COLOR;
    EVEN_ITEM_DATA_CELL.foreColor = PROPERTY_DATA_COLOR;
    EVEN_ITEM_DATA_CELL.hAlign = HorizontalAlign.center;

    export const EVEN_PROPERTY_LABEL = ITEM_LABEL_BASE.clone();
    EVEN_PROPERTY_LABEL.backColor = EVEN_ITEM_BG_COLOR;
    EVEN_PROPERTY_LABEL.font = ITEM_PROPERTY_FONT;
    EVEN_PROPERTY_LABEL.textIndent = 10;

    export const EVEN_PROPERTY_LABEL_LOCKED = EVEN_PROPERTY_LABEL.clone();
    EVEN_PROPERTY_LABEL_LOCKED.backColor = {
        patternColor: COLORS.GREY_300,
        type: GC.Spread.Sheets.PatternType.lightUp,
        backgroundColor: EVEN_ITEM_BG_COLOR
    } as GC.Spread.Sheets.IPatternFill;

    export const EVEN_PROPERTY_DATA_CELL = ITEM_DATA_BASE.clone();
    EVEN_PROPERTY_DATA_CELL.backColor = EVEN_ITEM_BG_COLOR;
    EVEN_PROPERTY_DATA_CELL.foreColor = PROPERTY_DATA_COLOR;
    EVEN_PROPERTY_DATA_CELL.font = ITEM_PROPERTY_FONT;
    EVEN_PROPERTY_DATA_CELL.hAlign = HorizontalAlign.center;

    export const EVEN_PROPERTY_DATA_CELL_LOCKED_EDITABLE = EVEN_PROPERTY_DATA_CELL.clone();
    EVEN_PROPERTY_DATA_CELL_LOCKED_EDITABLE.backColor = {
        patternColor: COLORS.GREY_300,
        type: GC.Spread.Sheets.PatternType.lightUp,
        backgroundColor: EVEN_ITEM_BG_COLOR
    } as GC.Spread.Sheets.IPatternFill;

    export const EVEN_PROPERTY_DATA_CELL_LOCKED_NON_EDITABLE = EVEN_PROPERTY_DATA_CELL_LOCKED_EDITABLE.clone();
    EVEN_PROPERTY_DATA_CELL_LOCKED_NON_EDITABLE.locked = true;
    EVEN_PROPERTY_DATA_CELL_LOCKED_NON_EDITABLE.foreColor = COLORS.GREY_400;

    export const EVEN_PROPERTY_DATA_CELL_HOVERED = EVEN_PROPERTY_DATA_CELL.clone();
    EVEN_PROPERTY_DATA_CELL_HOVERED.backColor = "#FFFFFF";
    EVEN_PROPERTY_DATA_CELL_HOVERED.borderTop = AnalystStyles.MANUAL_EDITABLE_CELL_BORDER;
    EVEN_PROPERTY_DATA_CELL_HOVERED.borderRight = AnalystStyles.MANUAL_EDITABLE_CELL_BORDER;
    EVEN_PROPERTY_DATA_CELL_HOVERED.borderBottom = AnalystStyles.MANUAL_EDITABLE_CELL_BORDER;
    EVEN_PROPERTY_DATA_CELL_HOVERED.borderLeft = AnalystStyles.MANUAL_EDITABLE_CELL_BORDER;

    export const EVEN_PROPERTY_DATA_CELL_HOVERED_LOCKED_EDITABLE = EVEN_PROPERTY_DATA_CELL_HOVERED.clone();
    EVEN_PROPERTY_DATA_CELL_HOVERED_LOCKED_EDITABLE.backColor = {
        patternColor: COLORS.GREY_300,
        type: GC.Spread.Sheets.PatternType.lightUp,
        backgroundColor: "#FFFFFF"
    } as GC.Spread.Sheets.IPatternFill;

    export const EVEN_PROPERTY_DATA_CELL_HOVERED_LOCKED_NON_EDITABLE = EVEN_PROPERTY_DATA_CELL_HOVERED_LOCKED_EDITABLE.clone();
    EVEN_PROPERTY_DATA_CELL_HOVERED_LOCKED_NON_EDITABLE.locked = true;
    EVEN_PROPERTY_DATA_CELL_HOVERED_LOCKED_NON_EDITABLE.foreColor = COLORS.GREY_400;

    export const EVEN_PROPERTY_DATA_CELL_SELECTED = EVEN_PROPERTY_DATA_CELL.clone();
    EVEN_PROPERTY_DATA_CELL_SELECTED.backColor = COLORS.PRIMARY_75;

    export const EVEN_PROPERTY_DATA_CELL_SELECTED_LOCKED_EDITABLE = EVEN_PROPERTY_DATA_CELL_SELECTED.clone();
    EVEN_PROPERTY_DATA_CELL_SELECTED_LOCKED_EDITABLE.backColor = {
        patternColor: COLORS.GREY_300,
        type: GC.Spread.Sheets.PatternType.lightUp,
        backgroundColor: COLORS.PRIMARY_75
    } as GC.Spread.Sheets.IPatternFill;

    export const EVEN_PROPERTY_DATA_CELL_SELECTED_LOCKED_NON_EDITABLE = EVEN_PROPERTY_DATA_CELL_SELECTED_LOCKED_EDITABLE.clone();
    EVEN_PROPERTY_DATA_CELL_SELECTED_LOCKED_NON_EDITABLE.locked = true;
    EVEN_PROPERTY_DATA_CELL_SELECTED_LOCKED_NON_EDITABLE.foreColor = COLORS.GREY_400;

    export const PROPERTY_TOTAL_DATA_SELECTED = ITEM_TOTAL_DATA.clone();
    PROPERTY_TOTAL_DATA_SELECTED.backColor = COLORS.PRIMARY_75;

    export const PROPERTY_LABEL_SELECTED = EVEN_PROPERTY_LABEL.clone();
    PROPERTY_LABEL_SELECTED.backColor = COLORS.PRIMARY_75;

    export const PROPERTY_LABEL_SELECTED_LOCKED = PROPERTY_LABEL_SELECTED.clone();
    PROPERTY_LABEL_SELECTED_LOCKED.backColor = {
        patternColor: COLORS.GREY_300,
        type: GC.Spread.Sheets.PatternType.lightUp,
        backgroundColor: COLORS.PRIMARY_75
    } as GC.Spread.Sheets.IPatternFill;

    // Even zebra stripe items
    export const ODD_ITEM_NAME_LABEL = EVEN_ITEM_NAME_LABEL.clone();
    ODD_ITEM_NAME_LABEL.backColor = 'white';

    export const ODD_ITEM_NAME_BLANK = ODD_ITEM_NAME_LABEL.clone();

    export const ODD_ITEM_NAME_BLANK_LOCKED_EDITABLE = ODD_ITEM_NAME_BLANK.clone();
    ODD_ITEM_NAME_BLANK_LOCKED_EDITABLE.backColor = {
        patternColor: COLORS.GREY_300,
        type: GC.Spread.Sheets.PatternType.lightUp
    } as GC.Spread.Sheets.IPatternFill;

    export const ODD_ITEM_NAME_BLANK_LOCKED_NON_EDITABLE = ODD_ITEM_NAME_BLANK_LOCKED_EDITABLE.clone();
    ODD_ITEM_NAME_BLANK_LOCKED_NON_EDITABLE.locked = true;
    ODD_ITEM_NAME_BLANK_LOCKED_NON_EDITABLE.foreColor = COLORS.GREY_400;

    export const ODD_ITEM_LABEL = EVEN_ITEM_LABEL.clone();
    ODD_ITEM_LABEL.backColor = 'white';

    export const ODD_ITEM_DATA_CELL = EVEN_ITEM_DATA_CELL.clone();
    ODD_ITEM_DATA_CELL.backColor = 'white';

    export const ODD_PROPERTY_LABEL = EVEN_PROPERTY_LABEL.clone();
    ODD_PROPERTY_LABEL.backColor = 'white'; //'white';

    export const ODD_PROPERTY_LABEL_LOCKED = ODD_PROPERTY_LABEL.clone();
    ODD_PROPERTY_LABEL_LOCKED.backColor = {
        patternColor: COLORS.GREY_300,
        type: GC.Spread.Sheets.PatternType.lightUp,
        backgroundColor: 'white'
    } as GC.Spread.Sheets.IPatternFill;

    export const ODD_PROPERTY_DATA_CELL = EVEN_PROPERTY_DATA_CELL.clone();
    ODD_PROPERTY_DATA_CELL.backColor = 'white';

    export const ODD_PROPERTY_DATA_CELL_HOVERED = ODD_PROPERTY_DATA_CELL.clone();
    ODD_PROPERTY_DATA_CELL_HOVERED.backColor = "#FFFFFF";
    ODD_PROPERTY_DATA_CELL_HOVERED.borderTop = AnalystStyles.MANUAL_EDITABLE_CELL_BORDER;
    ODD_PROPERTY_DATA_CELL_HOVERED.borderRight = AnalystStyles.MANUAL_EDITABLE_CELL_BORDER;
    ODD_PROPERTY_DATA_CELL_HOVERED.borderBottom = AnalystStyles.MANUAL_EDITABLE_CELL_BORDER;
    ODD_PROPERTY_DATA_CELL_HOVERED.borderLeft = AnalystStyles.MANUAL_EDITABLE_CELL_BORDER;

    export const ODD_PROPERTY_DATA_CELL_HOVERED_LOCKED_EDITABLE = ODD_PROPERTY_DATA_CELL_HOVERED.clone();
    ODD_PROPERTY_DATA_CELL_HOVERED_LOCKED_EDITABLE.backColor = {
        patternColor: COLORS.GREY_300,
        type: GC.Spread.Sheets.PatternType.lightUp,
        backgroundColor: 'white'
    } as GC.Spread.Sheets.IPatternFill;

    export const ODD_PROPERTY_DATA_CELL_HOVERED_LOCKED_NON_EDITABLE = ODD_PROPERTY_DATA_CELL_HOVERED_LOCKED_EDITABLE.clone();
    ODD_PROPERTY_DATA_CELL_HOVERED_LOCKED_NON_EDITABLE.locked = true;

    export const ODD_PROPERTY_DATA_CELL_LOCKED_EDITABLE = ODD_PROPERTY_DATA_CELL.clone();

    ODD_PROPERTY_DATA_CELL_LOCKED_EDITABLE.backColor = {
        patternColor: COLORS.GREY_300,
        type: GC.Spread.Sheets.PatternType.lightUp,
        backgroundColor: 'white'
    } as GC.Spread.Sheets.IPatternFill;

    export const ODD_PROPERTY_DATA_CELL_LOCKED_NON_EDITABLE = ODD_PROPERTY_DATA_CELL_LOCKED_EDITABLE.clone();
    ODD_PROPERTY_DATA_CELL_LOCKED_NON_EDITABLE.locked = true;
    ODD_PROPERTY_DATA_CELL_LOCKED_NON_EDITABLE.foreColor = COLORS.GREY_400;

    // Test/Dev cell styles _______________________________________________________________________
    export const TEST_STYLE = new GC.Spread.Sheets.Style();
    TEST_STYLE.foreColor = 'blue';
    TEST_STYLE.backColor = 'yellow';
    TEST_STYLE.font = '400 12px Inter';
    TEST_STYLE.vAlign = VerticalAlign.center;
    TEST_STYLE.cellPadding = '6px';
    TEST_STYLE.locked = false;

    export const TEST_STYLE_2 = TEST_STYLE.clone();
    TEST_STYLE_2.foreColor = 'yellow';
    TEST_STYLE_2.backColor = 'blue';

    export const TEST_STYLE_3 = TEST_STYLE.clone();
    TEST_STYLE_3.foreColor = 'white';
    TEST_STYLE_3.backColor = '#FF0000';
    TEST_STYLE_3.borderRight = new GC.Spread.Sheets.LineBorder("#0000FF", GC.Spread.Sheets.LineStyle.medium);


    // "Add Item" Styles _______________________________________________________________________
    export namespace AddItem {
        export const SPACER_ROW_HEIGHT = 6;
        export const SPACER_ROW_CELL_BASE = new GC.Spread.Sheets.Style();
        SPACER_ROW_CELL_BASE.backColor = COLORS.GREY_500;
        export const ITEM_NAME = SPACER_ROW_CELL_BASE.clone();

        export const DATA_ROW_HEIGHT = 48;

        export const DATA_CELL = AnalystStyles.MANUAL_STATIC_CELL_HOVERED.clone();
        DATA_CELL.borderTop = AnalystStyles.MANUAL_EDITABLE_CELL_BORDER;
        DATA_CELL.borderRight = AnalystStyles.MANUAL_EDITABLE_CELL_BORDER;
        DATA_CELL.borderBottom = AnalystStyles.MANUAL_EDITABLE_CELL_BORDER;
        DATA_CELL.borderLeft = AnalystStyles.MANUAL_EDITABLE_CELL_BORDER;
        DATA_CELL.formatter = "#,#0";
    }

}


