import { useEffect, useState } from "react";

import { useProperties } from "../../../../../contexts/properties/PropertiesContext";
import { useSettings } from "../../../../../contexts/settings/SettingsContext";

import { ListUnitEventsQuery, useListUnitEventsLazyQuery } from "../../../../../__generated__/generated_types";

import { LeaseActivityConfig as CFG } from "../../helpers/LeaseActivityConfig";

import { UnitEventDataToHCFormat } from "../highchartsGanttDataXform";

export type UnitEventsList = ListUnitEventsQuery['listUnits'];

export type PaginationInfo = {
    currentPage: number,
    totalPages: number,
}

export type useUnitEventDataReturn = {
    fetchUnitEvents: (propertyId: string, budgetYear: number, page: number) => void,
    fetchedUnitEventsData: ListUnitEventsQuery|undefined,
    hcUnitEventsData: any,
    totalUnits: number,
    totalPages: number,
    currentPage: number,
    setCurrentPage: (nextPage: number) => void,
}

export type UseUnitEventDataArgs = {
    onUnitEventClick: (evt: any) => void,
    onEmptyAreaClick: (evt: any) => void,
}

export function useUnitEventData({
                                     onUnitEventClick,
                                     onEmptyAreaClick,
                                 }: UseUnitEventDataArgs): useUnitEventDataReturn {

    const { currentProperty } = useProperties();
    const { year } = useSettings();

    const [unitEventsData, setUnitEventsData] = useState<UnitEventsList>();

    // Unit Event Pagination state
    const [paginationState, setPaginationState] = useState<PaginationInfo>({
        currentPage: 1,
        totalPages: 1,
    });
    const [lastPage, setLastPage] = useState<number>(1);
    const [totalUnits, setTotalUnits] = useState<number>(0);

    // Unit event data prepared for Highcharts consumption
    const [hcUnitEventsData, setHcUnitEventsData] = useState<any>();

    const [
        fetchUnitEventsData,
        {
            data: fetchedUnitEventsData,
            loading: unitEventDataLoading,
            // error: unitEventDataError
        }
    ] = useListUnitEventsLazyQuery({ fetchPolicy: "network-only" });

    /**
     * Initiates an update of the data being displayed in the Gantt chart
     * @param propertyId
     * @param page
     * @constructor
     */
    const FetchUnitEvents = (propertyId: string, budgetYear: number, page: number) => {
        fetchUnitEventsData({
            variables: {
                propertyId,
                budgetYear,
                page,
                take: CFG.UNITS_PER_PAGE,
            }
        });
    };

    const setCurrentPage = (nextPage: number): void => {
        setHcUnitEventsData((state: any) => {
            return {
            ...state,
                series: [],
            };
        });

        setLastPage(paginationState.currentPage);
        setPaginationState({
            ...paginationState,
            currentPage: nextPage,
        });
    };

    useEffect(
        () => {
            if(paginationState.currentPage == lastPage || !currentProperty){
                return;
            }
            FetchUnitEvents(currentProperty.id, currentProperty.budgetYear, paginationState.currentPage);
        },
        [paginationState.currentPage, lastPage]
    );

    useEffect(
        () => {
            if(unitEventDataLoading || !fetchedUnitEventsData){
                return;
            }

            setUnitEventsData(fetchedUnitEventsData.listUnits);
        },
        [fetchedUnitEventsData, unitEventDataLoading],
    );

    useEffect(
        () => {
            if(!unitEventsData){
                return;
            }

            setPaginationState({
                currentPage: unitEventsData.page,
                totalPages: unitEventsData.maxPage,
            });
            setTotalUnits(unitEventsData.total);

            const hcData = UnitEventDataToHCFormat(
                unitEventsData,
                year,
                onUnitEventClick,
                onEmptyAreaClick,
                currentProperty
            );
            setHcUnitEventsData(hcData);
        },
        [unitEventsData],
    );

    return {
        fetchUnitEvents: FetchUnitEvents,
        fetchedUnitEventsData,
        hcUnitEventsData,
        totalUnits,
        totalPages: paginationState.totalPages,
        currentPage: paginationState.currentPage,
        setCurrentPage,
    };
}
