import GC from '@grapecity/spread-sheets';

import { AnalystStyles } from "../../../analyst/AnalystStyles";
import {
    SJSSheetStyles,
    SJSSheetStyles as SJSS
} from "../../../../sjs/layout/shared/SJSSheetStyles";
import { COLORS } from "../../../../constants/Colors";

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace MarketRentSheetStyles {

    const HorizontalAlign = GC.Spread.Sheets.HorizontalAlign;
    const VerticalAlign = GC.Spread.Sheets.VerticalAlign;

    // Label Column _________________________________________________________________________
    export const MARKET_RENTS_LABEL = SJSS.FINANCIAL_ENTITY_LABEL.clone();
    MARKET_RENTS_LABEL.cellPadding = '8 8 8 16';
    MARKET_RENTS_LABEL.borderRight = SJSS.CELL_BORDER;
    MARKET_RENTS_LABEL.borderBottom = SJSS.CELL_BORDER;

    // Data Column __________________________________________________________________________
    export const ACTUAL_CELL        = SJSS.ACTUAL_CELL_GREY;
    ACTUAL_CELL.backColor           = COLORS.GREY_75;
    ACTUAL_CELL.foreColor           = COLORS.GREY_300;
    ACTUAL_CELL.locked              = true;

    export const LAST_ACTUAL_CELL   = ACTUAL_CELL.clone();
    LAST_ACTUAL_CELL.borderRight    = SJSS.MEDIUM_VERTICAL_DIVIDER;

    // Item Column __________________________________________________________________________
    export const ITEM_BASE = SJSS.DATA_LABEL.clone();
    ITEM_BASE.borderBottom = SJSS.CELL_BORDER;
    ITEM_BASE.borderRight = SJSS.CELL_BORDER;
    ITEM_BASE.foreColor = '#0D666D';

    export const ITEM_HEADER = ITEM_BASE.clone();
    ITEM_HEADER.textIndent = 2.7;
    ITEM_HEADER.hAlign = HorizontalAlign.left;
    ITEM_HEADER.foreColor = COLORS.GREY_800;
    ITEM_HEADER.font = '550 12px Inter';

    export const ITEM_LABEL = ITEM_BASE.clone();
    ITEM_LABEL.cellPadding = '8 26 8 13';
    ITEM_HEADER.textIndent = 2.7;
    ITEM_LABEL.locked = false;
    ITEM_LABEL.hAlign = HorizontalAlign.left;

    // Totals Column __________________________________________________________________________
    export const TOTALS_COLUMN_HDR = SJSS.TOTALS_COLUMN_BASE.clone();
    TOTALS_COLUMN_HDR.vAlign = VerticalAlign.bottom;
    TOTALS_COLUMN_HDR.hAlign = HorizontalAlign.right;
    TOTALS_COLUMN_HDR.font = '500 12px Inter';

    export const TOTALS_COLUMN_DATA_CELL = TOTALS_COLUMN_HDR.clone();
    TOTALS_COLUMN_DATA_CELL.font = '500 12px Inter';
    TOTALS_COLUMN_DATA_CELL.cellPadding = '6px';
    TOTALS_COLUMN_DATA_CELL.borderTop = AnalystStyles.CELL_BORDER;
    TOTALS_COLUMN_DATA_CELL.borderBottom = AnalystStyles.CELL_BORDER;
    TOTALS_COLUMN_DATA_CELL.vAlign = VerticalAlign.center;
    TOTALS_COLUMN_DATA_CELL.formatter = "#,#";
    TOTALS_COLUMN_DATA_CELL.locked = true;

    export const TOTALS_COLUMN_DATA_CELL_LOCKED = TOTALS_COLUMN_HDR.clone();
    TOTALS_COLUMN_DATA_CELL_LOCKED.font = '500 12px Inter';

    export const MKT_RENT_SUMMARY = new GC.Spread.Sheets.Style();
    MKT_RENT_SUMMARY.cellPadding = '8';
    MKT_RENT_SUMMARY.backColor = SJSS.CTGY_SUMMARY_BG_COLOR;
    MKT_RENT_SUMMARY.foreColor = SJSS.SUMMARY_FORE_COLOR;
    MKT_RENT_SUMMARY.font = '500 12px Inter';
    MKT_RENT_SUMMARY.borderTop = SJSS.SUMMARY_BORDER;
    MKT_RENT_SUMMARY.borderRight = SJSS.SUMMARY_BORDER;
    MKT_RENT_SUMMARY.borderBottom = SJSS.SUMMARY_BORDER;
    MKT_RENT_SUMMARY.vAlign = VerticalAlign.center;
    MKT_RENT_SUMMARY.formatter = "#,#";
}
