import * as css from "./styles/css.module.scss";
import { ReactElement } from "react";

export interface AssumptionRowProps {
    label: string;
    subLabel?: string;
    value: string;
}

export function AssumptionRow(props: AssumptionRowProps): ReactElement {
    return (
        <div className={css.row}>
            <div className={css.labelContainer}>
                <div className={css.labelMain} title={props.label}>
                    {props.label}
                </div>
                {props.subLabel ?
                    <div className={css.labelSub}>
                        {props.subLabel}
                    </div>
                    :
                    null
                }
            </div>
            <div className={css.value}>
                {props.value}
            </div>
        </div>
    );
}