import { getAbbreviatedYear } from "../../../../../../utils/helper-funcs";
import { MONTHS_LONG } from "../../../../../../constants/Months";

export const getVarianceNotesHeaderLabel = (params: {year?: number, monthIdx?:number}):string => {
    let monthStr = '';
    let yearStr = '';

    if(params.monthIdx != undefined){
        monthStr = `${MONTHS_LONG[params.monthIdx]} `;
    }
    if(params.year){
        const shortYear = getAbbreviatedYear(params.year);
        yearStr = `${shortYear} `;
    }
    return `${monthStr}${yearStr}Variance Note`;
};
