import React, {useEffect, useState} from 'react';
import {AccountHeader} from './AccountHeader';
import {CategoryHeader} from './CategoryHeader';
import {NumberCells} from './NumberCells';
import {ComponentHeader} from './ComponentHeader';
import {EntityType, FinancialEntityType, useGetFinancialValuesLazyQuery, VersionType} from '../../__generated__/generated_types';
import {fillLineData} from './Helpers';
import './Line.scss';
import {ReportNote} from '../note/ReportNote';
import {MdSubdirectoryArrowRight} from "react-icons/md";
import {useProperties} from '../../contexts/properties/PropertiesContext';
import {BudgetingType} from '../../BudgetingType';
import useYearDefinitions from './YearDefinitions';
import {useSettings} from '../../contexts/settings/SettingsContext';
import {useVersions} from '../../contexts/versions/VersionsContext';
import {FinancialEntity} from '../../contexts/chartofaccounts/ChartOfAccountsContext';
import {createRequestVariables} from '../../contexts/chartofaccounts/CreateFinancialValuesQueryVariables';

export interface LineMonthData {
    monthIndex: number;
    value: number;
}
export interface ChildLinesData {
    linesData: LineData[] | undefined;
    isDataLoading?: boolean;
    hasChildren: boolean;
}

export interface LineData {
    // lineName: string;
    monthNumbers: LineMonthData[];
    total: number;
    referenceTotal: number;
    variance: number;
    variancePercent: number | null;
    // componentId?: string;
    // categoryId?: string;
    financialEntity: FinancialEntity;
    // isComponent?: boolean;
    // accountId?: string;
}

export interface LineProps {
    data: LineData;
    isPositiveGood: boolean;
    level?: number;
    tab: BudgetingType;
    varianceType: string;
    refreshTriggered: boolean;
    refreshCompletedCallback: () => void;
}

export const Line: React.VFC<LineProps> = (propsIn) => {

    const props = {level: 0, ...propsIn}; // default level 0
    const [showChildren, setShowChildren] = useState(false);
    const {currentProperty: property} = useProperties();
    const [children, setChildren] = useState<LineData[] | undefined>();
    const {year: currentYear, startReforecastMonthIndex} = useSettings();
    const {currentDefinition: referenceYear} = useYearDefinitions();
    const {versions, getModelVersions} = useVersions();
    const [getFinancialValues, {called, loading, data, refetch}] = useGetFinancialValuesLazyQuery({
        notifyOnNetworkStatusChange: true,
        fetchPolicy: "no-cache",
        onCompleted: () => {
            if (!loading && props.refreshTriggered === true) {
                props.refreshCompletedCallback();
            }
        }
    });


    const hasChildren = props.data.financialEntity.children.length > 0;
    const isComponent = props.data.financialEntity.type == FinancialEntityType.Component;
    const isReforecast = props.tab === BudgetingType.REFORECAST;

    function needCollapse() {
        setShowChildren(false);
    }

    function needExpand() {
        if (!called && property) {
            getFinancialValues({
                variables: createRequestVariables(props.data.financialEntity.children, property.id, startReforecastMonthIndex, currentYear, getModelVersions)
            });
        }
        setShowChildren(true);
    }


    useEffect(() => {
        if (called && !loading && data) {
            const convertedData: LineData[] = [];

            const {actualVersionId, reforecastVersionId, nextYearBudgetVersionId, currentYearTotalVersionId} = getModelVersions(currentYear);
            const valuesByEntityAndVersion = data.financialValues.financialValues.groupBy(row => row.entityId + "#" + row.versionId);
            const totalValuesByEntityAndVersion = data.financialValues.financialTotalValues.groupBy(row => row.entityId + "#" + row.versionId);

            props.data.financialEntity.children.forEach(child => {
                const values = isReforecast ?
                    [
                        ...(valuesByEntityAndVersion[child.id + "#" + actualVersionId] ?? []),
                        ...(valuesByEntityAndVersion[child.id + "#" + reforecastVersionId] ?? [])
                    ]
                    :
                    valuesByEntityAndVersion[child.id + "#" + nextYearBudgetVersionId] ?? [];

                let previousTotal = totalValuesByEntityAndVersion[child.id + "#" + referenceYear.versionId]?.firstElement?.value ?? "0";
                if(referenceYear.versionId === currentYearTotalVersionId) {
                    const reforecastValues = [
                        ...(valuesByEntityAndVersion[child.id + "#" + actualVersionId] ?? []),
                        ...(valuesByEntityAndVersion[child.id + "#" + reforecastVersionId] ?? [])
                    ];
                    previousTotal = reforecastValues.map(rv => Number(rv.value ?? 0.0)).sum();
                }

                const total = values.map(v => Number(v.value ?? 0.0)).sum();

                const lineData = fillLineData(
                    values.map(row => ({monthIndex: row.monthIndex, value: Number(row.value)})),
                    total,
                    previousTotal,
                    child
                );

                convertedData.push(lineData);
            });

            setChildren(convertedData);
        }

    }, [data, loading, property, isReforecast, referenceYear.versionId]);

    useEffect(() => {
        if (props.refreshTriggered === true && refetch) {
            refetch();
        }
    }, [props.refreshTriggered]);

    const rowName = props.data.financialEntity.name?.replace(/\s/g, '');

    return (
        <>
            {/* line */}
            <tr key={0} className={`${isComponent ? 'line-component' : ''} ${showChildren && props.level === 0 ? "bg-azure" : ""} tree-indent-${props.level}`}>
                {isComponent ?
                    <ComponentHeader name={`${props.data.financialEntity.name}${props.data.financialEntity.number ? " (" + props.data.financialEntity.number + ")" : ""}`} />
                    : hasChildren ?
                        <CategoryHeader
                            needCollapse={needCollapse}
                            needExpand={needExpand}
                            categoryName={`${props.data.financialEntity.name}${props.data.financialEntity.number ? " (" + props.data.financialEntity.number + ")" : ""}`}
                            level={props.level}
                            data={props.data?.financialEntity}
                        />
                        :
                        <AccountHeader name={`${props.data.financialEntity.name}${props.data.financialEntity.number ? " (" + props.data.financialEntity.number + ")" : ""}`}
                            level={props.level}
                            accountId={props.data.financialEntity.id}
                            isReforecast={isReforecast}
                            rowName={rowName}
                        />
                }
                {/* line data */}
                {showChildren ?
                    <>
                        <td colSpan={12}></td>
                        <td className="as-total as-total-border-blue"></td>
                        <td colSpan={2}></td>
                    </>
                    :
                    <NumberCells monthNumbers={props.data.monthNumbers}
                        total={props.data.total}
                        referenceTotal={props.data.referenceTotal}
                        variance={props.data.variance}
                        variancePercent={props.data.variancePercent}
                        isPositiveGood={props.isPositiveGood}
                        varianceSelectedValue={props.varianceType}
                        isReforecast={isReforecast}
                        rowName={rowName}
                    />
                }
                <td>
                    {props.data.financialEntity.type == FinancialEntityType.Account &&
                        <ReportNote
                            propertyId={property?.id ?? ""}
                            year={isReforecast ? (property?.reforecastYear ?? 0) : ((property?.reforecastYear ?? 0) + 1)}
                            entityId={props.data.financialEntity.id}
                            entityType={
                                props.data.financialEntity.type == FinancialEntityType.Account ?
                                    EntityType.Account
                                    : props.data.financialEntity.type == FinancialEntityType.Category ?
                                        EntityType.BudgetCategory
                                        : EntityType.BudgetComponent
                            }
                            versionType={isReforecast ? VersionType.Reforecast : VersionType.Budget}
                        />
                    }
                </td>
            </tr>

            {/* children */}
            {showChildren && children && children.map((child, index) =>
                <Line key={index}
                    data={child}
                    isPositiveGood={props.isPositiveGood}
                    level={props.level + 1}
                    tab={props.tab}
                    varianceType={props.varianceType}
                    refreshTriggered={props.refreshTriggered}
                    refreshCompletedCallback={() => refetch && refetch()}
                />
            )}

            {/* line subtotal */}
            {showChildren &&
                <tr key={1} className={"fw-bold " + (showChildren ? "bg-light-azure" : "")}>
                    <td className={`fw-bold tree-indent-${props.level + 1} bg-light-azure totals-expand p-0`}>
                        <div className="tree-line border-start-1 d-flex h-100 border-whisper border-start">
                            <MdSubdirectoryArrowRight className="arrow-right-icon" />
                            <div className="totals d-flex">{`Total ${props.data.financialEntity.name.toLocaleLowerCase()}`}</div>
                        </div>


                    </td>
                    <NumberCells monthNumbers={props.data.monthNumbers}
                        total={props.data.total}
                        referenceTotal={props.data.referenceTotal}
                        variance={props.data.variance}
                        variancePercent={props.data.variancePercent}
                        isPositiveGood={props.isPositiveGood}
                        varianceSelectedValue={props.varianceType}
                        isReforecast={isReforecast}
                    />
                </tr>
            }
        </>
    );
};
