import { ReactElement, useEffect, useState } from "react";
import {
    BudgetYearModel,
    ListBudgetYearsQuery,
    useActivateBudgetYearMutation,
    useAddBudgetYearMutation,
    useUpdateBudgetYearIsAutoUnitTypeUpdateEnabledMutation,
    useUpdateBudgetYearProtectionMutation
} from "../../../__generated__/generated_types";
import { Property } from "../../../contexts/properties/PropertiesContext";
import { stringYearToSeason, yearToSeason } from "../util";
import { toast } from "react-toastify";
import Card from "../../simplified-revenue/components/Card";
import AddBudgetYearModal from "./AddBudgetYearModal";
import ActivateBudgetYearModal from "./ActivateBudgetYearModal";
import * as css from "../styles/budgetSeasonManagement.module.scss";
import PropertyReforecastStartBulkUpdater from "./PropertyReforecastStartBulkUpdater";
import { Field, MediaInput } from "@zendeskgarden/react-forms";
import { ReactComponent as SearchIcon } from '@zendeskgarden/svg-icons/src/16/search-stroke.svg';
import { ReactComponent as CheckIcon } from '@zendeskgarden/svg-icons/src/16/check-lg-fill.svg';
import { Button } from "@zendeskgarden/react-buttons";
import { MONTHS } from "../../../constants/Months";
import BudgetYearProtectionModal from "./BudgetYearProtectionModal";
import BudgetYearIsAutoUnitTypeUpdateEnabledModal from "./BudgetYearIsAutoUnitTypeUpdateEnabledModal";

export type IBudgetSeasonTabProps = {
    budgetYearsListData: ListBudgetYearsQuery | undefined,
    properties: Property[] | undefined,
    onReforecastStartMonthUpdateComplete: () => void;
    onActivateBudgetYearComplete: () => void;
    onBudgetYearLockingComplete: () => void;
    onBudgetYearSetIsAutoUnitTypeUpdateEnabledComplete: () => void;
    onAddBudgetYearComplete: () => void;
}

export default function BudgetSeasonsTab({
    budgetYearsListData,
    properties,
    onReforecastStartMonthUpdateComplete,
    onActivateBudgetYearComplete,
    onBudgetYearLockingComplete,
    onBudgetYearSetIsAutoUnitTypeUpdateEnabledComplete,
    onAddBudgetYearComplete
}: IBudgetSeasonTabProps): ReactElement {
    const [filteredProperties, setFilteredProperties] = useState<Property[]>([]);
    const [showAddBudgetYearModal, setShowAddBudgetYearModal] = useState(false);
    const [possibleBudgetYears, setPossibleBudgetYears] = useState<number[]>([]);
    const [existingBudgetYears, setExistingBudgetYears] = useState<BudgetYearModel[]>([]);
    const [activeBudgetYear, setActiveBudgetYear] = useState<number>();
    const [showActivateBudgetYearModal, setShowActivateBudgetYearModal] = useState<{show: boolean, budgetYear: number}>({show: false, budgetYear: 0});
    const [showBudgetYearProtectionModal, setShowBudgetYearProtectionModal] = useState<{budgetYear: number, targetProtectedState: boolean}>();
    const [showBudgetYearIsAutoUnitTypeUpdateEnabledModal, setShowBudgetYearIsAutoUnitTypeUpdateEnabledModal] = useState<{budgetYear: number, targetIsAutoUnitTypeUpdateEnabled: boolean}>();

    const [budgetYearToAdd, setBudgetYearToAdd] = useState<string>();

    const [addBudgetYear, { loading: addBudgetYearLoading }] = useAddBudgetYearMutation({
        notifyOnNetworkStatusChange: true
    });

    const [activateBudgetYear, { loading: activateBudgetYearLoading }] = useActivateBudgetYearMutation({
        notifyOnNetworkStatusChange: true
    });

    const [updateBudgetYearProtected, { loading: budgetYearProtectedLoading }] = useUpdateBudgetYearProtectionMutation({
        notifyOnNetworkStatusChange: true
    });

    const [updateBudgetYearIsAutoUnitTypeUpdateEnabled, { loading: budgetYearIsAutoUnitTypeUpdateEnabledLoading }] = useUpdateBudgetYearIsAutoUnitTypeUpdateEnabledMutation({
        notifyOnNetworkStatusChange: true
    });

    useEffect(() => {
        if (!properties) {
            return;
        }

        setFilteredProperties(properties);
    }, [properties]);

    useEffect(() => {
        if (!budgetYearsListData) {
            return;
        }

        const existingBudgetYears = budgetYearsListData.listBudgetYears?.budgetYears?.sortBy(row => row.year)

        const thisYear = new Date().getUTCFullYear();
        let possibleBudgetYears = [thisYear + 1];
        possibleBudgetYears = possibleBudgetYears.filter(year => !budgetYearsListData.listBudgetYears.budgetYears.map(y => y.year).includes(year)).sort();

        setPossibleBudgetYears(possibleBudgetYears);
        setExistingBudgetYears(existingBudgetYears);
        setActiveBudgetYear(budgetYearsListData.listBudgetYears.activeBudgetYear);

    }, [budgetYearsListData]);

    function handleAddBudgetYear(budgetYearToAdd: string) {
        const budgetYear = Number.parseInt(budgetYearToAdd);

        if (Number.isNaN(budgetYear)) {
            return;
        }

        addBudgetYear({
            variables: {
                budgetYear: budgetYear
            }
        })
        .then(
            () => {
                setShowAddBudgetYearModal(false);
                setBudgetYearToAdd(undefined);
                toast.success(`Budget Season ${yearToSeason(budgetYear)} added successfully`);
                onAddBudgetYearComplete();
            }
        );
    }

    function handleActivateBudgetYear(budgetYearToActivate: number) {
        activateBudgetYear({
            variables: {
                budgetYear: budgetYearToActivate
            }
        })
        .then(
            () => {
                setShowActivateBudgetYearModal({show: false, budgetYear: 0});
                toast.success(`Budget Season ${yearToSeason(budgetYearToActivate)} activated successfully`);
                onActivateBudgetYearComplete();
            }
        );
    }

    function handleBudgetYearProtected(budgetYear: number, targetProtectedState: boolean) {
        updateBudgetYearProtected({
            variables: {
                budgetYear: budgetYear,
                protected: targetProtectedState
            }
        })
        .then(
            () => {
                setShowBudgetYearProtectionModal(undefined);
                toast.success(`Budget Season ${yearToSeason(budgetYear)} ${targetProtectedState ? "locked" : "unlocked"} successfully`);
                onBudgetYearLockingComplete();
            }
        );
    }

    function handleBudgetYearIsAutoUnitTypeUpdateEnabled(budgetYear: number, targetIsAutoUnitTypeUpdateEnabled: boolean) {
        updateBudgetYearIsAutoUnitTypeUpdateEnabled({
            variables: {
                budgetYear: budgetYear,
                isAutoUnitTypeUpdateEnabled: targetIsAutoUnitTypeUpdateEnabled
            }
        })
        .then(
            () => {
                setShowBudgetYearIsAutoUnitTypeUpdateEnabledModal(undefined);
                toast.success(`Budget Season ${yearToSeason(budgetYear)} Unit Type Auto Loading ${targetIsAutoUnitTypeUpdateEnabled ? "enabled" : "disabled"} successfully`);
                onBudgetYearSetIsAutoUnitTypeUpdateEnabledComplete();
            }
        );
    }

    function handleReforecastStartMonthUpdateComplete() {
        toast.success(`Reforecast Start Month successfully updated`);
        onReforecastStartMonthUpdateComplete();
    }

    return (
        <>
            <Card
                className={css.budgetSeasonsCard}
                title="Manage Seasons"
                actions={
                    <Button
                        className={css.button}
                        isPrimary
                        disabled={possibleBudgetYears.length == 0}
                        onClick={() => setShowAddBudgetYearModal(true)}
                    >
                        {possibleBudgetYears.length == 0 ? "No seasons to add" : "Add Budget Season"}
                    </Button>
                }
            >
                <div className={css.propertyTableWrapper}>
                    <table className={css.propertyTable}>
                        <thead>
                            <tr className={css.headerCell}>
                                <th className={`${css.cell} ${css.nameCell}`}>Budget Season</th>
                                <th className={`${css.cell} ${css.periodCell}`}>Active</th>
                                <th className={`${css.cell} ${css.periodCell}`}>Lock Status</th>
                                <th className={`${css.cell} ${css.periodCell}`}>Unit Type Auto Loading</th>
                            </tr>
                        </thead>

                        <tbody>
                            {existingBudgetYears.map(year => (
                                    <tr key={year.year}>
                                        <td className={`${css.cell}`}>{ yearToSeason(year.year) }</td>
                                        <td className={`${css.cell} ${css.periodCell} ${year.year != activeBudgetYear ? css.withButton : ""}`}>
                                            { year.year == activeBudgetYear ? <CheckIcon />
                                                : <Button
                                                        size="small"
                                                        onClick={() => setShowActivateBudgetYearModal({show: true, budgetYear: year.year})}
                                                >
                                                    Activate
                                                </Button>}
                                        </td>
                                        <td className={`${css.cell} ${css.periodCell} ${css.withButton}`}>
                                            { year.protected ?
                                                <Button
                                                    size="small"
                                                    isDanger
                                                    onClick={() => setShowBudgetYearProtectionModal({budgetYear: year.year, targetProtectedState: false})}
                                                >
                                                    Unlock
                                                </Button>
                                                :
                                                <Button
                                                    size="small"
                                                    onClick={() => setShowBudgetYearProtectionModal({budgetYear: year.year, targetProtectedState: true})}
                                                >
                                                    Lock
                                                </Button>}
                                        </td>
                                        <td className={`${css.cell} ${css.periodCell} ${css.withButton}`}>
                                            { year.isAutoUnitTypeUpdateEnabled ?
                                                <Button
                                                    size="small"
                                                    isDanger
                                                    onClick={() => setShowBudgetYearIsAutoUnitTypeUpdateEnabledModal({budgetYear: year.year, targetIsAutoUnitTypeUpdateEnabled: false})}
                                                >
                                                    Disable
                                                </Button>
                                                :
                                                <Button
                                                    size="small"
                                                    onClick={() => setShowBudgetYearIsAutoUnitTypeUpdateEnabledModal({budgetYear: year.year, targetIsAutoUnitTypeUpdateEnabled: true})}
                                                >
                                                    Enable
                                                </Button>}
                                        </td>
                                    </tr>
                                ))}
                        </tbody>
                    </table>
                </div>
            </Card>

            <Card
                className={css.updaterControlsCard}
                titleClassName={css.updaterControlsTitle}
                title={"Bulk Update Property Reforecast Start"}
            >
                {properties ?
                        <PropertyReforecastStartBulkUpdater
                            properties={properties}
                            onUpdateCompleted={() => handleReforecastStartMonthUpdateComplete()}
                        />
                        :
                        <></>
                    }

            </Card>

            <Card
                title={"Property Reforecast Start"}
                actions={
                    <Field className={css.propertySearch}>
                        <MediaInput start={<SearchIcon />} placeholder="Search" onChange={e => {
                            if (!properties) {
                                return;
                            }
                            setFilteredProperties(properties.filter(p => p.name.toLowerCase().includes(e.target.value.trim().toLowerCase())));
                        }} />
                    </Field>
                }
            >
                <div className={css.propertyTableWrapper}>
                    <table className={css.propertyTable}>
                        <thead>
                            <tr className={css.headerCell}>
                                <th className={`${css.cell} ${css.nameCell}`}>Property</th>
                                <th className={`${css.cell} ${css.periodCell}`}>Reforecast Start</th>
                            </tr>
                        </thead>

                        <tbody>
                            {filteredProperties.map(property => (
                                    <tr key={property.id}>
                                        <td className={`${css.cell}`}>{ property.name }</td>
                                        <td className={`${css.cell} ${css.periodCell}`}>{ `${property.reforecastYear}-${MONTHS[property.reforecastStartMonthIndex]}` }</td>
                                    </tr>
                                ))}
                        </tbody>
                    </table>
                </div>
            </Card>
            {showAddBudgetYearModal && (
                <AddBudgetYearModal
                    loading={addBudgetYearLoading}
                    selectedOptionValue={budgetYearToAdd}
                    selectedOptionLabel={stringYearToSeason(budgetYearToAdd ?? "")}
                    options={possibleBudgetYears.map(y => ({value: y.toString(), label: yearToSeason(y)}))}
                    onSelectOption={option => setBudgetYearToAdd(option)}
                    onClose={() => {
                        setBudgetYearToAdd(undefined);
                        setShowAddBudgetYearModal(false);
                    }}
                    onConfirm={() => {
                        handleAddBudgetYear(budgetYearToAdd ?? "");
                    }}
                />
            )}

            {showActivateBudgetYearModal.show && (
                <ActivateBudgetYearModal
                    loading={activateBudgetYearLoading}
                    budgetSeason={yearToSeason(showActivateBudgetYearModal.budgetYear)}
                    onClose={() => {
                        setShowActivateBudgetYearModal({show: false, budgetYear: 0});
                    }}
                    onConfirm={() => {
                        handleActivateBudgetYear(showActivateBudgetYearModal.budgetYear);
                    }}
                />
            )}

            {showBudgetYearProtectionModal && (
                <BudgetYearProtectionModal
                    loading={budgetYearProtectedLoading}
                    budgetSeason={yearToSeason(showBudgetYearProtectionModal.budgetYear)}
                    targetProtectionState={showBudgetYearProtectionModal.targetProtectedState}
                    onClose={() => {
                        setShowBudgetYearProtectionModal(undefined);
                    }}
                    onConfirm={() => {
                        handleBudgetYearProtected(showBudgetYearProtectionModal.budgetYear, showBudgetYearProtectionModal.targetProtectedState);
                    }}
                />
            )}

            {showBudgetYearIsAutoUnitTypeUpdateEnabledModal && (
                <BudgetYearIsAutoUnitTypeUpdateEnabledModal
                    loading={budgetYearIsAutoUnitTypeUpdateEnabledLoading}
                    budgetSeason={yearToSeason(showBudgetYearIsAutoUnitTypeUpdateEnabledModal.budgetYear)}
                    targetIsAutoUnitTypeUpdateEnabled={showBudgetYearIsAutoUnitTypeUpdateEnabledModal.targetIsAutoUnitTypeUpdateEnabled}
                    onClose={() => {
                        setShowBudgetYearIsAutoUnitTypeUpdateEnabledModal(undefined);
                    }}
                    onConfirm={() => {
                        handleBudgetYearIsAutoUnitTypeUpdateEnabled(showBudgetYearIsAutoUnitTypeUpdateEnabledModal.budgetYear, showBudgetYearIsAutoUnitTypeUpdateEnabledModal.targetIsAutoUnitTypeUpdateEnabled);
                    }}
                />
            )}
        </>
    );
}