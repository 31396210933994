import {HotTable} from "@handsontable/react";
import {ReactElement, useEffect, useRef, useState} from "react";
import {Property} from "../../../contexts/properties/PropertiesContext";
import * as tableCSS from "../../../components/account-table/styles/accountTable.module.scss";
import {LICENSES} from "../../../constants/Licenses";
import Handsontable from "handsontable";
import {ForecastLocks, GetSimplifiedRevenueQuery, useUpdateDefaultRenovationRatioMutation} from "../../../__generated__/generated_types";
import {MONTHS} from "../../../constants/Months";
import {toast} from "react-toastify";
import {hotValidUserActions, sanitizeTableInput} from "../../../components/account-table/logic/AccountTableHelpers";
import {averageOfArray, numRange} from "../logic/HelperFunctions";

export interface IMarketRentDefaultsTableProps {
    property: Property,
    data: GetSimplifiedRevenueQuery,
    triggerDownload: boolean,
    forecastLocks: ForecastLocks,
    onDataUpdate: () => void,
    onDownloadComplete: () => void,
}

export default function RenovationsDefaultsTable({property, data, triggerDownload, forecastLocks, onDataUpdate, onDownloadComplete}: IMarketRentDefaultsTableProps): ReactElement {
    const hotRef = useRef<HotTable>(null);
    const manualEntrySaveDebounce = useRef<(ReturnType<typeof setTimeout> | null)>(null);

    const [rowData, setRowData] = useState<(string | number | null)[][]>([]);
    const refetchDebounce = useRef<(ReturnType<typeof setTimeout> | null)>(null);
    const [updateDefaultRenovationRatio] = useUpdateDefaultRenovationRatioMutation();

    const [rfcstRenovations, setRfcstRenovations] = useState<(number | null)[]>([]);

    useEffect(() => {
        if (!data.simplifiedRevenueModel) {
            return;
        }

        const rfcstRenovationsTemp = data.simplifiedRevenueModel.defaultRenovationRatio.reforecast;
        const bdgtRenovations = data.simplifiedRevenueModel.defaultRenovationRatio.budget;
        const rfcstVisibleRenovations = data.simplifiedRevenueModel.defaultRenovationRatio.reforecast.slice(property.reforecastStartMonthIndex, 13);

        const tempRowData = [
            [
                "% of Classic Move Outs that are renovated",
                ...rfcstVisibleRenovations.concat(averageOfArray(rfcstRenovationsTemp)).concat(bdgtRenovations).concat(averageOfArray(bdgtRenovations)),
            ],
        ];

        setRowData(tempRowData);
        setRfcstRenovations(rfcstRenovationsTemp);
    }, [data]);

    useEffect(() => {
        if (triggerDownload && hotRef.current?.hotInstance) {
            const exportPlugin = hotRef.current.hotInstance.getPlugin("exportFile");
            exportPlugin.downloadFile("csv", {filename: `${property.name}_${property.budgetYear} Renovations_${new Date().toLocaleDateString('en-US')}`, columnHeaders: true,});
            onDownloadComplete();
        }
    }, [triggerDownload]);

    const rfcstAverageColIndex = 13 - property.reforecastStartMonthIndex;
    const bdgtAverageColIndex = 13 - property.reforecastStartMonthIndex + 13;
    const rfcstColRange =  numRange(1, rfcstAverageColIndex);
    const bdgtColRange = numRange(rfcstAverageColIndex + 1, bdgtAverageColIndex);

    const columns: string[] = [
        "",
        ...MONTHS.slice(property.reforecastStartMonthIndex, 12),
        "Average",
        ...MONTHS,
        "Average",
    ];

    const settings: Handsontable.GridSettings = {
        data: rowData,
        licenseKey: LICENSES.HandsOnTable,
        columns(index) {
            return {type: index == 0 ? "text" : "numeric"};
        },
        colHeaders: columns,
        selectionMode: "range",
        rowHeaders: false,
        width: "auto",
        height: "auto",
        readOnly: true,
        fixedColumnsLeft: 1,
        manualRowResize: false,
        manualColumnResize: false,
        autoColumnSize: false,
        autoRowSize: false,
        disableVisualSelection: ["header"],
        colWidths(index) {
            if (index == 0) {
                return 300;
            } else {
                return 100;
            }
        },
        afterGetColHeader: (_column, th) => {
            if (!hotRef.current?.hotInstance) {
                return;
            }

            th.className += ` ${tableCSS.cellBase} ${tableCSS.headerWithLabels}`;

            if (_column == 0 || _column == rfcstAverageColIndex || _column == bdgtAverageColIndex) {
                th.className += ` ${tableCSS.cellBgGrey}`;
            }

            if (_column == rfcstAverageColIndex || _column == bdgtAverageColIndex) {
                th.className += ` ${tableCSS.verticalThickBorderLeft}`;
            }

            if (rfcstColRange.includes(_column)) {
                const validMonths = MONTHS.slice(property.reforecastStartMonthIndex, 12);
                // -1 offset is to account for the first column - the label column
                th.innerHTML = `
                    <div class="${tableCSS.tableHeaderLightLabel}">
                        <span>RFCST</span>
                        <span>&nbsp;</span>
                        <span>${ validMonths[_column - 1]}</span>
                    </div>
                `;
            } else if (rfcstAverageColIndex + 1 == _column) {
                const startingBdgtCol = rfcstColRange.length + 2;
                th.innerHTML = `
                    <div class="${tableCSS.tableHeaderLightLabel}">
                        <span>BDGT</span>
                        <span>${property.budgetYear}</span>
                        <span>${ MONTHS[_column - startingBdgtCol]}</span>
                    </div>
                `;
            } else if (bdgtColRange.includes(_column)) {
                const startingBdgtCol = rfcstColRange.length + 2;
                th.innerHTML = `
                    <div class="${tableCSS.tableHeaderLightLabel}">
                        <span>BDGT</span>
                        <span>&nbsp;</span>
                        <span>${ MONTHS[_column - startingBdgtCol]}</span>
                    </div>
                `;
            } else if (rfcstAverageColIndex == _column) {
                th.innerHTML = `
                    <div class="${tableCSS.tableHeaderLightLabel}">
                        <span>RFCST</span>
                        <span>${property.reforecastYear}</span>
                        <span>Average</span>
                    </div>
                `;
            } else if (bdgtAverageColIndex == _column) {
                th.innerHTML = `
                    <div class="${tableCSS.tableHeaderLightLabel}">
                        <span>BDGT</span>
                        <span>${property.budgetYear}</span>
                        <span>Average</span>
                    </div>
                `;
            } else {
                // sure there must be better ways to handle HoT col size calculations, but this will do it for now
                th.innerHTML = `
                    <div class="${tableCSS.tableHeaderLightLabel}">
                        <span> </span>
                        <span> </span>
                        <span> </span>
                    </div>
                `;
            }
        },
        cells(_row, _column) {
            this.className += ` ${tableCSS.cellBase}`;

            if (_column == 0) {
                this.className += ` ${tableCSS.rowHeader}`;
            }

            // if row is second one, with growth pct, and it's a non-label column
            if (_column > 0) {
                this.renderer = function (instance, td, row, col, prop, value, cellProperties) {
                    Handsontable.renderers.NumericRenderer.apply(this, [instance, td, row, col, prop, value, cellProperties]);

                    if (value !== null && value !== undefined) {
                        value = parseFloat(value);
                        td.innerText = value.toFixed(1) + '%';
                    }
                };

                if (rfcstColRange.includes(_column) && !forecastLocks.reforecastLocked) {
                    this.className += ` ${tableCSS.cellColorBlue} ${tableCSS.cellBorderBottom}`;
                    this.readOnly = false;
                }

                if (bdgtColRange.includes(_column) && !forecastLocks.budgetLocked) {
                    this.className += ` ${tableCSS.cellColorBlue} ${tableCSS.cellBorderBottom}`;
                    this.readOnly = false;
                }
            }

            // if the column is one of the average columns
            if (_column == rfcstAverageColIndex || _column == bdgtAverageColIndex) {
                this.className += ` ${tableCSS.verticalThickBorderLeft}`;
                this.className += ` ${tableCSS.cellBgGrey}`;
            }

            return this;
        },
        beforeChange: (changes) => {
            for (let i = 0; i < changes.length; i++) {
                const change = changes[i];

                if (!change) {
                    return;
                }

                change[3] = sanitizeTableInput(change[3]);
            }
        },
        afterChange: (changes, _source) => {
            const source = _source as string;

            if (!changes) {
                return;
            }

            for (let i = 0; i < changes.length; i++) {
                const change = changes[i];

                if (!change) {
                    continue;
                }

                const row = change[0];
                const col = change[1];
                const oldVal = change[2];
                const newVal = change[3];

                if (row == undefined || col == undefined || oldVal === undefined || newVal == undefined || String(oldVal) === String(newVal)) {
                    continue;
                }

                if (hotValidUserActions.includes(source)) {
                    if (manualEntrySaveDebounce.current) {
                        clearTimeout(manualEntrySaveDebounce.current);
                    }

                    manualEntrySaveDebounce.current = setTimeout(() => {
                        if (!hotRef.current?.hotInstance) {
                            return;
                        }

                        const rfcstRenos = rfcstRenovations.slice(0, property.reforecastStartMonthIndex).concat(hotRef.current.hotInstance.getDataAtRow(0).slice(1, rfcstAverageColIndex));
                        const bdgtRenos = hotRef.current.hotInstance.getDataAtRow(0).slice(rfcstAverageColIndex + 1, bdgtAverageColIndex);

                        updateDefaultRenovationRatio({
                            variables: {
                                propertyId: property.id,
                                budgetYear: property.budgetYear,
                                renovationRatios: {
                                    reforecastMonthlyValues: rfcstRenos.map((value, month) => ({value, month})),
                                    budgetMonthlyValues: bdgtRenos.map((value, month) => ({value, month})),
                                }
                            }
                        }).then(result => {
                            const data = result.data;
                            if (data && data.updateSimplifiedDefaultRevenueRenovationRatio) {
                                if (refetchDebounce.current) {
                                    clearTimeout(refetchDebounce.current);
                                }
                                toast.success("Saved", {autoClose: 2500, hideProgressBar: true});
                                refetchDebounce.current = setTimeout(() => {
                                    onDataUpdate();
                                }, 200);
                            }
                        },
                        () => {
                            toast.error("Error Saving", {autoClose: 2500, hideProgressBar: true});
                        });
                    }, 1500);
                }
            }
        },
        beforeUndoStackChange(_doneActions, source) {
            if (source && !hotValidUserActions.includes(source)) {
                return false;
            }
        },
    };

    return (
        <div className={tableCSS.accountTableWrapper}>
            <HotTable
                ref={hotRef}
                settings={settings}
                className={tableCSS.accountTable}
            />
        </div>
    );
}
