import {ThemeProvider} from "@zendeskgarden/react-theming";
import {ReactElement, useEffect, useState} from "react";
import {ViziblyTheme} from "../analyst/ViziblyZDGTheme";
import useAppStore from "../../hooks/useAppStore";
import * as css from "./styles/OpDriverModeling.module.scss";
import * as workflowCSS from "../../styles/workflows/workflowCSS.module.scss";
import Header from "./components/Header";
import {Col, Grid, Row} from "@zendeskgarden/react-grid";
import {useProperties} from "../../contexts/properties/PropertiesContext";
import Card from "./components/Card";
import {ForecastLocks, useGetForecastLocksLazyQuery, useGetRenewalRatioCountsActualsLazyQuery, useGetSimplifiedRevenueLazyQuery, useGetSimplifiedRevenueSummaryLazyQuery} from "../../__generated__/generated_types";
import {IconButton} from "@zendeskgarden/react-buttons";
import {RiDownloadLine} from "react-icons/ri";
import RenewalRatioDefaultsTable from "./components/RenewalRatioDefaultsTable";
import {WorkflowStepNavProps} from "../../components/workflows/workflow-step-nav/WorkflowStepNav";
import RenewalRatioCompareTable from "./components/RenewalRatioCompareTable";
import LockStatus from "./components/LockStatus";

export default function RenewalRatio({onPreviousClick, onNextClick}: WorkflowStepNavProps): ReactElement {
    const appStore = useAppStore();
    const property = useProperties();

    const [triggerDownload, setTriggerDownload] = useState<boolean>(false);
    const [forecastLocks, setForecastLocks] = useState<ForecastLocks>();
    const [getSimplifiedRevenue, {data, refetch}] = useGetSimplifiedRevenueLazyQuery({
        fetchPolicy: "network-only",
        notifyOnNetworkStatusChange: true,
    });
    const [getRenewalRatioCountsActuals, {data: renewalRatioCountActualsData}] = useGetRenewalRatioCountsActualsLazyQuery({
        fetchPolicy: "network-only",
    });
    const [getSimplifiedRevenueSummary, {data: simplifiedRevenueSummaryData}] = useGetSimplifiedRevenueSummaryLazyQuery({
        fetchPolicy: "network-only",
    });
    const [getForecastLocks, {data: dataForecastLocks, loading: lockDataLoading}] = useGetForecastLocksLazyQuery({
        fetchPolicy: "no-cache",
    });

    useEffect(() => {
        appStore.set({isLoading: false});
        return () => undefined;
    }, []);

    useEffect(() => {
        if (!property.currentProperty) {
            return;
        }

        getSimplifiedRevenue({
            variables: {
                propertyId: property.currentProperty.id,
                budgetYear: property.currentProperty.budgetYear,
            }
        });

        getRenewalRatioCountsActuals({
            variables: {
                propertyId: property.currentProperty.id,
                year: property.currentProperty.reforecastYear,
            }
        });

        getSimplifiedRevenueSummary({
            variables: {
                propertyId: property.currentProperty.id,
                budgetYear: property.currentProperty.budgetYear,
            }
        });

        getForecastLocks({
            variables: {
                propertyId: property.currentProperty.id,
                budgetYear: property.currentProperty.budgetYear,
            }
        });
    }, [property.currentProperty]);

    useEffect(() => {
        if (lockDataLoading || !dataForecastLocks?.forecastLocks) {
            setForecastLocks(undefined);
            return;
        }

        setForecastLocks(dataForecastLocks.forecastLocks.property);
    }, [dataForecastLocks, lockDataLoading]);

    return (
        <ThemeProvider theme={ViziblyTheme}>
            <div className={`${css.opDriverModelingWrapper} ${workflowCSS.workflowContainer}`}>
                <div className={workflowCSS.workflowContainerInner}>
                    <Header
                        title={"Renewal Ratio"}
                        prevAction={onPreviousClick}
                        nextAction={onNextClick}
                    />

                    <Grid className={css.bodyContent} gutters={"lg"}>
                        <Row>
                            <Col>
                                {forecastLocks && property.currentProperty && data && refetch &&
                                    <Card
                                        title="Defaults"
                                        actions={
                                            <>
                                                <LockStatus
                                                    budgetYear={property.currentProperty.budgetYear}
                                                    reforecastLocked={forecastLocks.reforecastLocked}
                                                    budgetLocked={forecastLocks.budgetLocked}
                                                />
                                                <IconButton onClick={() => {
                                                    setTriggerDownload(true);
                                                }}>
                                                    <RiDownloadLine />
                                                </IconButton>
                                            </>
                                        }
                                    >
                                            <RenewalRatioDefaultsTable
                                                property={property.currentProperty}
                                                data={data}
                                                triggerDownload={triggerDownload}
                                                forecastLocks={forecastLocks}
                                                onDataUpdate={refetch}
                                                onDownloadComplete={() => setTriggerDownload(false)}
                                            />
                                    </Card>
                                }
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Card
                                    title="Compare"
                                >
                                    {property.currentProperty && renewalRatioCountActualsData && simplifiedRevenueSummaryData &&
                                        <RenewalRatioCompareTable
                                            property={property.currentProperty}
                                            renewalRatioCountActualsData={renewalRatioCountActualsData}
                                            simplifiedRevenueSummaryData={simplifiedRevenueSummaryData}
                                        />
                                    }
                                </Card>
                            </Col>
                        </Row>
                    </Grid>
                </div>
            </div>
        </ThemeProvider>
    );
}
