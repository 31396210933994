import {UnitEventType} from "../../../../../__generated__/generated_types";
import type {DropDownOption} from "./types";

export const LeaseTermCustomToken = "custom";

/*
This is the list of all possible unit event type to show in the Event dropdown on add/edit modal popup
 */
export const AllUnitEventTypes: Record<UnitEventType, DropDownOption> = {
    [UnitEventType.TermLease]: {
        value: UnitEventType.TermLease,
        label: "Term Lease"
    },
    [UnitEventType.MonthToMonth]: {
        value: UnitEventType.MonthToMonth,
        label: "Month to Month"
    },
    [UnitEventType.Renewal]: {
        value: UnitEventType.Renewal,
        label: "Renewal"
    },
    [UnitEventType.Renovation]: {
        value: UnitEventType.Renovation,
        label: "Renovation"
    },
    [UnitEventType.ShortTermRental]: {
        value: UnitEventType.ShortTermRental,
        label: "Short Term Rental"
    }
};

/*
    These lables are used as a header/title in the top of the edit event modal
 */
export const AllUnitEventTypesShortLabel: Record<UnitEventType, string> = {
    [UnitEventType.TermLease]: "TERM LEASE",
    [UnitEventType.MonthToMonth]: "MTM",
    [UnitEventType.Renewal]: "RENEWAL",
    [UnitEventType.Renovation]: "RENOVATION",
    [UnitEventType.ShortTermRental]: "STR"
};

/*
A list of keys which indicate the different user actions
Based on these user actions the local state of the modal pop up is updated often
The local state of the modal pop up is something
which drives the data/entries into the popup along with its' dirty etc states
 */
export enum LocalUnitEventModelChangeKeys {
    eventId = "eventId",
    eventType = "eventType",
    daysVacant = "daysVacant",
    startDate = "startDate",
    leaseTerm = "leaseTerm",
    renovationDuration = "renovationDuration",
    endDate = "endDate",
    endDateClicked = "endDateClicked",
    leaseConcession = "leaseConcession",
    rentIncreaseDecreasePercentage = "rentIncreaseDecreasePercentage",
    effectiveAverageDailyRate = "effectiveAverageDailyRate",
    mtmRent = "mtmRent",
    renovationPremium = "renovationPremium",
    renovationCost = "renovationCost"
}
