import {BudgetingType} from '../../BudgetingType';
import {VersionType} from "../../__generated__/generated_types";
import React, {useState, useMemo, useEffect} from 'react';
import {useSettings} from '../../contexts/settings/SettingsContext';
import {useVersions} from '../../contexts/versions/VersionsContext';

export type YearDefinition = {
    year: number;
    type: VersionType;
    versionId: string;
    label: string;
    index: number;
}

function useYearDefinitionsState(tab: BudgetingType): { definitions: YearDefinition[], currentDefinition: YearDefinition, setCurrentDefinition: (definition: YearDefinition) => void } {
    const {year: currentYear} = useSettings();
    const {getModelVersions} = useVersions();
    const definitionsConfig = useMemo(() => {
        const {
            currentYearBudgetVersionId,
            currentYearTotalVersionId,
            yearM1ActualVersionId,
            yearM2ActualVersionId
        } = getModelVersions(currentYear);

        return {
            [BudgetingType.REFORECAST]: [
                {
                    year: currentYear,
                    type: VersionType.Budget,
                    versionId: currentYearBudgetVersionId,
                    label: "'" + currentYear.toString().slice(2) + " Budget",
                    index: 0,
                },
                {
                    year: currentYear - 1,
                    type: VersionType.Actuals,
                    versionId: yearM1ActualVersionId,
                    label: (currentYear - 1).toString() + " actuals",
                    index: 1,
                },
                {
                    year: currentYear - 2,
                    type: VersionType.Actuals,
                    versionId: yearM2ActualVersionId,
                    label: (currentYear - 2).toString() + " actuals",
                    index: 2,
                }
            ] as YearDefinition[],
            [BudgetingType.BUDGET]: [
                {
                    year: currentYear,
                    type: VersionType.YearTotal,
                    versionId: currentYearTotalVersionId,
                    label: currentYear.toString() + " Reforecast",
                    index: 0,
                },
                {
                    year: currentYear,
                    type: VersionType.Budget,
                    versionId: currentYearBudgetVersionId,
                    label: "'" + currentYear.toString().slice(2) + " Budget",
                    index: 1,
                },
                {
                    year: currentYear - 1,
                    type: VersionType.Actuals,
                    versionId: yearM1ActualVersionId,
                    label: (currentYear - 1).toString() + " actuals",
                    index: 2,
                },
                {
                    year: currentYear - 2,
                    type: VersionType.Actuals,
                    versionId: yearM2ActualVersionId,
                    label: (currentYear - 2).toString() + " actuals",
                    index: 3,
                }
            ] as YearDefinition[],
        };
    }
        , [currentYear, getModelVersions]);

    const [currentDefinition, setCurrentDefinitionInternal] = useState<
        {
            [BudgetingType.REFORECAST]: YearDefinition,
            [BudgetingType.BUDGET]: YearDefinition
        }
    >(
        {
            [BudgetingType.REFORECAST]: definitionsConfig[BudgetingType.REFORECAST][0]!,
            [BudgetingType.BUDGET]: definitionsConfig[BudgetingType.BUDGET][0]!

        }
    );

    const setCurrentDefinition = (definition: YearDefinition) => {
        setCurrentDefinitionInternal(prev => {
            return {
                ...prev,
                [tab]: definition
            };
        });
    };

    return {definitions: definitionsConfig[tab], currentDefinition: currentDefinition[tab], setCurrentDefinition: setCurrentDefinition};
}


interface YearDefinitionState {
    definitions: YearDefinition[];
    currentDefinition: YearDefinition;
    setCurrentDefinition: (val: YearDefinition) => void;
}
const DEFAULT_YEAR_DEFINITION_CONTEXT_VALUE: YearDefinitionState = {
    definitions: [],
    currentDefinition: {
        year: new Date().getUTCFullYear(),
        type: VersionType.YearTotal,
        versionId: "",
        label: "Current year total", // nvm - default is never used
        index: 0,

    },
    setCurrentDefinition: (_val: YearDefinition) => { /* noop */ }
};

export const YearDefinitionContext = React.createContext<YearDefinitionState>(DEFAULT_YEAR_DEFINITION_CONTEXT_VALUE);

export const YearDefinitionsProvider: React.FunctionComponent<{ tab: BudgetingType }> = ({tab, children}) => {

    const contextValue: YearDefinitionState = useYearDefinitionsState(tab);
    return (
        <YearDefinitionContext.Provider value={contextValue}>
            {children}
        </YearDefinitionContext.Provider>
    );
};

export default function useYearDefinitions(): YearDefinitionState {
    const context = React.useContext(YearDefinitionContext);

    if (context === undefined) {
        throw new Error('useYearDefinitions must be used within a YearDefinitionsProvider');
    }

    return context;
}
