import { ItemData } from "./types";

// maximum number of accounts (type == Account)
// that can be deleted at once
// reason for limitation:
//      deleting an account deletes all modelling methods assigned to it
//      if need be - restoring modelling methods for small amount of accounts is manageable by custmer
//          - restoring modelling methods for huge number of accounts will most likely involve
//             customer service work which is undesirable
// NOTE: only existing accounts are considered for limitation. The new ones created during COA editing session are exempt.
export const MAX_DELETED_ACCOUNTS = 5;


export function reparent(items: ItemData[], child: ItemData, newParent: ItemData): void {
    const grandChildren = items.filter(item => item.parentIds.includes(child.id));
    const cutParentsLength = child.parentIds.length;
    child.parentIds = newParent.parentIds.concat(newParent.id);
    for (const grandChild of grandChildren) {
        grandChild.parentIds.splice(0, cutParentsLength); // keep the child's id
        grandChild.parentIds = child.parentIds.concat(grandChild.parentIds);
    }
}

export function reorder(list: ItemData[], sourceIndex: number, destIndex: number, count = 0): ItemData[] {
    const result = Array.from(list);
    const removed = result.splice(sourceIndex, 1 + count);
    let destIndexAdjusted = destIndex;
    if (sourceIndex > destIndex) {
        destIndexAdjusted = destIndexAdjusted + 1;
    }
    else if (sourceIndex < destIndex) {
        destIndexAdjusted = destIndexAdjusted - count;
    }
    if (removed) {
        result.splice(destIndexAdjusted, 0, ...removed);
    }
    return result;
}
