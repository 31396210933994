import React, { ReactElement, useEffect, useState } from "react";
import { Dropdown, Field as DropdownField, Item, Menu, Select } from "@zendeskgarden/react-dropdowns";
import { Checkbox, Field, Input, Label } from "@zendeskgarden/react-forms";
import { Col, Grid, Row } from "@zendeskgarden/react-grid";
import { Head, HeaderCell, HeaderRow, Table } from "@zendeskgarden/react-tables";
import { ThemeProvider } from "@zendeskgarden/react-theming";
import Infinite from "react-infinite";

import { useGetPropertyForecastLocksLazyQuery, VersionType } from "../../../__generated__/generated_types";
import lockOnDisabled from "../../../assets/icons/lock/locked-disabled.svg";
import useAppStore from "../../../hooks/useAppStore";
import { useProperties } from "../../../contexts/properties/PropertiesContext";
import { SelectableFinancialEntity, TVersionTypeDropdownItem } from "../PropertyDrivers";
import { IUsePropertyDriversReturn } from "../logic/usePropertyDrivers";
import {
    areAllAccountsSelected,
    areAllFilteredAccountsSelected,
    checkSearchQueryMatch,
    getFilteredAccounts,
    isIndeterminate,
    isSelectAllSelected,
} from "../logic/utils";

import PropertyDriversFormulaBar from "./PropertyDriversFormulaBar";
import * as css from "./styles/css.module.scss";
import LockButton from "./LockButton";


const VERSION_TYPES = [
    VersionType.Budget,
    VersionType.Reforecast,
];

interface ISelectAccounts {
    pd: IUsePropertyDriversReturn,
}


function DelayRender(props: {timeoutMs: number, uniqueId: string, children: (canRender: boolean) => ReactElement | ReactElement[]}): ReactElement {
    const [canRender, setCanRender] = useState(false);
    useEffect(() => {
        setCanRender(false);
        const delayRender = setTimeout(() => {
            setCanRender(true);
        }, props.timeoutMs);

        return () => {
            clearTimeout(delayRender);
        };
    }, [props.uniqueId]);

    return <>
        {props.children(canRender)}
    </>;
}

export default function SelectAccounts({
    pd,
}: ISelectAccounts): ReactElement {

    const appStore = useAppStore();
    const { propertyId } = useProperties();

    const [filteredAccounts, setFilteredAccounts] = useState<SelectableFinancialEntity[]>([]);
    const [searchInput, setSearchInput] = useState<string>("");

    useEffect(
        () => {
            appStore.set({ isLoading: false });
        },
        []
    );

    /**
     * Every time the accounts selection updates, filteredAccounts should update too
    */
    useEffect(
        () => {
            setFilteredAccounts(getFilteredAccounts(pd.accounts, searchInput));
            pd.setSelectedAccounts(pd.accounts.filter((account) => account.isSelected));
        },
        [pd.accounts]
    );

    useEffect(
        () => {
            if (pd.selectedAccounts.length === 0) {
                pd.setAccounts(pd.accounts.map((each) => ({
                    ...each,
                    isSelected: false,
                })));
                setFilteredAccounts(filteredAccounts.map((each) => ({
                    ...each,
                    isSelected: false,
                })));
            }
        },
        [pd.selectedAccounts.length]
    );

    const handleVersionTypeSelect = (selectedVersionType: TVersionTypeDropdownItem) => {
        pd.setVersionType(selectedVersionType);
    };

    const handleSearchInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newSearchInput = event.currentTarget.value;

        setSearchInput(newSearchInput);
        setFilteredAccounts(getFilteredAccounts(pd.accounts, newSearchInput));
    };

    const handleSelect = (selectedAccount: SelectableFinancialEntity, isSelected: boolean) => {
        const updatedAccounts = pd.accounts.map((account: SelectableFinancialEntity) => {
            if (account.id === selectedAccount.id) {
                return {
                    ...account,
                    isSelected,
                };
            }
            return account;
        });

        pd.setAccounts(updatedAccounts);
    };

    const handleSelectAll = () => {
        if (searchInput.length > 0) {
            pd.setAccounts(pd.accounts.map((account: SelectableFinancialEntity) => {
                if (checkSearchQueryMatch(account.name, account.number, searchInput)) {
                    return { ...account, isSelected: !areAllFilteredAccountsSelected(filteredAccounts) };
                }
                return account;
            }));
        } else {
            pd.setAccounts(pd.accounts.map((account: SelectableFinancialEntity) => ({ ...account, isSelected: !areAllAccountsSelected(pd.accounts) })));
        }
    };

    const renderRow = (currentAccount: SelectableFinancialEntity, index: number) => {
        return (
            <DelayRender
                timeoutMs={1000}
                uniqueId={currentAccount.id}
                children={
                    canRender =>
                        <Row
                            key={currentAccount.id}
                            className={`${css.noMargin} ${css.bodyRow}`}
                            aria-rowindex={index + 1}
                        >
                            <Col
                                className={css.tableCell}
                                style={inlineStyles.lockBodyCell}
                            >
                                {pd.budgetYear && canRender &&
                                    <LockButton
                                        accountId={currentAccount.id}
                                        propertyId={propertyId}
                                        budgetYear={pd.budgetYear}
                                        versionType={pd.versionType}
                                        isConfigureModalOpen={pd.isConfigureModelingMethodsModalOpen}
                                        updateAccountLocksByAccountId={pd.updateAccountLocksByAccountId}
                                        updateAccountLocksDraftByAccountId={pd.updateAccountLocksDraftByAccountId}
                                        isLocked={pd.isPropertyLocked || pd.accountLocksDraft.get(currentAccount.id)}
                                        disabled
                                    />
                                }
                            </Col>
                            <Col className={css.tableCell}>
                                <Field>
                                    <Checkbox checked={currentAccount.isSelected} onChange={(event) => handleSelect(currentAccount, event.target.checked)}>
                                    <Label
                                        isRegular
                                        className={`${css.accountName} ${css.checkboxLabel}`}
                                    >
                                        {currentAccount.number} {currentAccount.name}
                                    </Label>
                                    </Checkbox>
                                </Field>
                            </Col>
                            <Col className={css.tableCell}>
                                <div className={css.formulaBarCell}>
                                    {canRender &&
                                    <PropertyDriversFormulaBar
                                        accountId={currentAccount.id || ''}
                                        propertyId={propertyId}
                                        isFxBarReadOnly
                                        pd={pd}
                                    />}
                                </div>
                            </Col>
                        </Row>
                }
            />
        );
    };

    return (
        <ThemeProvider>
            <Grid className={`${css.selectedAccountsGrid} ${css.noPadding}`}>
                <Row className={`${css.searchWrapper} ${css.noMargin}`}>
                    <Col className={`${css.searchWrapperCol} ${css.wrapperTitle}`}>
                        Configure GL accounts
                    </Col>
                    <Col className={css.searchWrapperCol}>
                        <form className={css.searchBarForm}>
                            <Field className={css.searchBarField}>
                                <Input
                                    placeholder="Search"
                                    value={searchInput}
                                    onChange={handleSearchInputChange}
                                    className={css.searchBarInput}
                                />
                            </Field>
                        </form>
                    </Col>
                    <Col className={css.searchWrapperCol}>
                        <Dropdown
                            selectedItem={pd.versionType}
                            onSelect={handleVersionTypeSelect}
                        >
                            <DropdownField className={css.dropdownField}>
                                <Select className={css.dropdownSelect}>
                                    <div className={css.dropdownLabel}>
                                        Forecast Period
                                    </div>
                                    <div className={css.selectedItem}>
                                        {pd.versionType} {pd.year}
                                    </div>
                                </Select>
                            </DropdownField>
                            <Menu>
                                {VERSION_TYPES.map(each => (
                                <Item key={each} value={each}>
                                    {each}
                                </Item>
                                ))}
                            </Menu>
                        </Dropdown>
                    </Col>
                </Row>
                <Row className={css.noMargin}>
                    <Col className={css.noPadding}>
                        <Table className={css.driversTable}>
                            <Head isSticky>
                                <HeaderRow className={css.headerRow}>
                                    <HeaderCell
                                        className={css.tableCell}
                                        style={inlineStyles.lockCell}
                                        width="50px"
                                    >
                                        <img src={lockOnDisabled} alt="lock" width="15px" height="15px" />
                                    </HeaderCell>
                                    <HeaderCell className={css.tableCell}>
                                        <Field>
                                            <Checkbox
                                                onChange={handleSelectAll}
                                                checked={isSelectAllSelected(pd.accounts, filteredAccounts, searchInput)}
                                                indeterminate={isIndeterminate(pd.accounts, filteredAccounts, searchInput)}
                                            >
                                                <Label className={css.checkboxLabel}>
                                                    GL Account
                                                </Label>
                                            </Checkbox>
                                        </Field>
                                    </HeaderCell>
                                    <HeaderCell className={css.tableCell}>
                                        Formula
                                    </HeaderCell>
                                </HeaderRow>
                            </Head>
                        </Table>
                        <Infinite containerHeight={500} elementHeight={56} preloadBatchSize={1}>
                            {filteredAccounts.map((currentAccount, index) => (
                                <div key={index}>
                                {renderRow(currentAccount, index)}
                                </div>
                            ))}
                        </Infinite>
                    </Col>
                </Row>
            </Grid>
        </ThemeProvider>
    );
}

const inlineStyles = ({
    lockCell: {
        paddingLeft: "16px",
    },
    lockBodyCell: {
        display: "flex",
        flex: "0 0 50px",
        paddingLeft: "16px",
    },
});
