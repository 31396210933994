import React, {ReactElement} from "react";
import css from "../styles/table.module.scss";
import {Body, Cell, Head, HeaderCell, HeaderRow, Row, Table} from "@zendeskgarden/react-tables";
import {EmployeeTotals} from "../logic/usePropertyExecutiveSummaryData";
import {Property} from "../../../contexts/properties/PropertiesContext";
import {Button} from "@zendeskgarden/react-buttons";
import {exportEmployeeTableToExcel} from "../logic/employeeTableExport";
import { formatterDollarUSNoDecimal } from '../../../utils/formatters';

interface EmployeeTableProps {
    property: Property;
    employeeTotals: EmployeeTotals[];
}

export function EmployeeTable(props: EmployeeTableProps): ReactElement {
    return <div className={css.wrapper}>
        <div className={css.topHeader}>
            <h4>{props.property.name} | Employee Overview</h4>
            <div className={css.headerActions}>
                <Button onClick={() => exportEmployeeTableToExcel(props.property, props.employeeTotals)}>Export</Button>
            </div>
        </div>
        <div className={css.tableWrapper}>
        <Table className={css.table}>
            <Head className={css.headerRow}>
                <HeaderRow>
                    <HeaderCell>
                        <div>Employee</div>
                    </HeaderCell>
                    <HeaderCell>
                        <div>Base Pay</div>
                    </HeaderCell>
                    <HeaderCell>
                        <div>Raise(s)</div>
                    </HeaderCell>
                    <HeaderCell>
                        <div>Total</div>
                    </HeaderCell>
                </HeaderRow>
            </Head>
            <Body>{props.employeeTotals.map(et => {
                return <Row>
                    <Cell>{et.positionName}</Cell>
                    <Cell>{formatterDollarUSNoDecimal.format(et.basePayTotal)}</Cell>
                    <Cell>{formatterDollarUSNoDecimal.format(et.raiseTotal)}</Cell>
                    <Cell>{formatterDollarUSNoDecimal.format((et.basePayTotal + et.raiseTotal))}</Cell>
                </Row>;
            })}</Body>
        </Table>
        </div>
    </div>;
}