import {AnalystRenovationEntityType} from "./types";

export enum RenovationRowMapActionTypes {
    AddRenovationRowMapEntryAction = "AddRenovationRowMapEntryAction",
    Clear = "Clear"
}

export type RenovationRowMapEntry = {
    idx: number,
    entityID: number|string,
    entityName: string,
    entityType: AnalystRenovationEntityType,
    level: number,
    parent:{
        id: number|string|null,
        name: string|null
    }
}

type AnalystRenovationInternalState = {
    rowToEntityMap: Record<number, RenovationRowMapEntry>;
    totalRows: number;
}

export interface RenovationRowMapAction {
    kind: RenovationRowMapActionTypes;
}

export interface AddRenovationRowMapEntryAction extends RenovationRowMapAction {
    kind: RenovationRowMapActionTypes.AddRenovationRowMapEntryAction;
    entries: RenovationRowMapEntry[];
}

export function analystRenovationStateReducer(state: AnalystRenovationInternalState, action: RenovationRowMapAction): AnalystRenovationInternalState {
    switch (action.kind) {
        case RenovationRowMapActionTypes.AddRenovationRowMapEntryAction: {
            const act = action as AddRenovationRowMapEntryAction;
            const newState = {
                ...state,
                ...{
                    rowToEntityMap: {
                        ...state.rowToEntityMap,
                        ...act.entries.toIdMap("idx"),
                    },
                }
            };
            return newState;
        }
        case RenovationRowMapActionTypes.Clear: {
            return { rowToEntityMap: {}, totalRows: 0 };
        }
    }
    return state;
}
