import { useEffect, useState } from "react";
import { useGetMvrFinancialsSingleAccountLazyQuery } from "../../../../../../__generated__/generated_types";
import { mapFinancialsSingleAccountQueryResult } from "./logic/mapFinancialsSingleAccountQueryResult";

export interface UseMonthlyVarianceModalFinancialsProps {
    propertyId: string;
    accountId: string;
    year: number;
    monthIndex: number;
}

type UseMonthlyVarianceModalFinancialsState = {
    actuals: number[], // 12 values array. defaulted to zeroes
    budget: number[], // 12 values array. defaulted to zeroes
}

const monthlyVarianceModalFinancialsDefaultState = {
    actuals: new Array<number>(12).fill(0),
    budget: new Array<number>(12).fill(0)
};

export type UseMonthlyVarianceModalFinancialsData = {
    actualValue: number;
    budgetValue: number;
}

/**
 * Responsible for fetching financial values for a year (actuals and budget)
 * Provides value for single month based on the monthIndex specified
 */
export function useMonthlyVarianceModalFinancials(
    props: UseMonthlyVarianceModalFinancialsProps
): UseMonthlyVarianceModalFinancialsData {
    // we want to use lazy query to properly react for props change (through useEffect)
    // and also be prepared for handling of a need for the parameters be not defined
    const [fetchFinancials, { data, loading }] = useGetMvrFinancialsSingleAccountLazyQuery({
        fetchPolicy: "network-only"
    });

    const [financials, setFinancials] = useState<UseMonthlyVarianceModalFinancialsState>(monthlyVarianceModalFinancialsDefaultState);

    useEffect(
        () => {
            fetchFinancials({
                variables: {
                    propertyId: props.propertyId,
                    accountId: props.accountId,
                    year: props.year
                }
            });
        },
        [props.propertyId, props.accountId, props.year]
    );

    useEffect(
        () => {
            if (data && !loading) {
                const {actuals, budget} = mapFinancialsSingleAccountQueryResult(data);
                setFinancials({
                    actuals: actuals,
                    budget: budget
                });
            }
        },
        [data, loading]
    );

    return {
        actualValue: financials.actuals[props.monthIndex] ?? 0, // I don't mind coalesce to turn 0 into 0
        budgetValue: financials.budget[props.monthIndex] ?? 0
    };
}