/* eslint-disable @typescript-eslint/no-empty-function */

import {ReactElement, useEffect, useState} from "react";
import {Field, Input, Label} from "@zendeskgarden/react-forms";
import {
    Dropdown,
    Field as DropdownField,
    Item,
    Label as DropdownLabel,
    Menu,
    Select
} from "@zendeskgarden/react-dropdowns";
import {
    FileUploadRequestCreateInput,
    FileUploadStatus,
    FileUploadType,
    useCreateFileUploadRequestMutation,
    useQueryFileUploadRequestLazyQuery,
} from "../../../__generated__/generated_types";
import {NavLink} from "react-router-dom";
import {ViziblyTheme} from "../../analyst/ViziblyZDGTheme";
import {ThemeProvider} from "@zendeskgarden/react-theming";
import {Col, Row} from "@zendeskgarden/react-grid";
import useAppStore from "../../../hooks/useAppStore";

export function FileUploadCreate(): ReactElement {
    const [
        createFileUploadRequest, 
        {data: createFileUploadRequestData, loading: createFileUploadRequestLoading, error: createFileUploadRequestError}
    ] = useCreateFileUploadRequestMutation({
        notifyOnNetworkStatusChange: true
    });

    const [
        queryFileUploadRequest,
        {data: queryFileUploadRequestData, loading: queryFileUploadRequestLoading, error: queryFileUploadRequestError}
    ] = useQueryFileUploadRequestLazyQuery({
        fetchPolicy: "no-cache"
    });

    const [createInput, setCreateInput] = useState<FileUploadRequestCreateInput>({
        type: FileUploadType.AccountValue, 
        s3Bucket: "",
        s3Key: "",
    });

    const [uploadResult, setUploadResult] = useState<string>("");

    function updateCreateData(update: Partial<FileUploadRequestCreateInput>): void {
        if (createInput) {
            setCreateInput({
                ...createInput,
                ...update,
            });
        }
    }

    const appStore = useAppStore();

    function doCreateFileUploadRequest(): void {
        if (createInput) {
            createFileUploadRequest({
                variables: {
                    input: createInput
                }
            }).catch(_err => { /* Do Nothing */ });
        }
    }

    useEffect(
        () => {
            appStore.set({ isLoading: false });
            return () => {};
        },
        []
    );

    const [queryResultTimeout, setQueryResultTimeout] = useState<NodeJS.Timeout | undefined>(undefined);
    const [clearQueryResultTimeout, setClearQueryResultTimeout] = useState<boolean>(false);
    const [queryResultAttempt, setQueryResultAttempt] = useState<number>(0);

    useEffect(() => {
        if (createFileUploadRequestLoading) {
            setUploadResult("Creating upload request.");
        } else if (createFileUploadRequestError) {
            setUploadResult(`Failed to create upload: ${JSON.stringify(createFileUploadRequestError)}.`);
        } else if (createFileUploadRequestData) {
            if (queryResultAttempt === 0) {
                setUploadResult(`Processing upload: ${createFileUploadRequestData.createFileUploadRequest.id}.`);
            }
            setQueryResultTimeout(setTimeout(() => {
                setQueryResultAttempt(queryResultAttempt + 1);
                queryFileUploadRequest({variables: {input: {id: createFileUploadRequestData.createFileUploadRequest.id}}});
            }, 5000));
        }
    }, [createFileUploadRequestData, createFileUploadRequestLoading, createFileUploadRequestError, queryResultAttempt]);

    useEffect(() => {
        if (queryFileUploadRequestError) {
            setUploadResult(`Failed to query status of file upload request ${createFileUploadRequestData?.createFileUploadRequest.id}.`);
        } else if (queryFileUploadRequestData) {
            if (!createFileUploadRequestData) {
                return;
            }

            var timeout: NodeJS.Timeout;
            switch (queryFileUploadRequestData.queryFileUploadRequests[0]?.status) {    
                case FileUploadStatus.Processing:
                    setUploadResult(
                        `Still processing file upload: ${createFileUploadRequestData.createFileUploadRequest.id}.
                         Check status attempt ${queryResultAttempt}.
                        `
                    );
                    break;
                    // return () => clearTimeout(timeout);
                case FileUploadStatus.Failed:
                    setClearQueryResultTimeout(true);
                    setUploadResult(
                        `File upload ${createFileUploadRequestData?.createFileUploadRequest.id} failed: 
                         ${JSON.stringify(queryFileUploadRequestData.queryFileUploadRequests[0]?.errors)}
                        `
                    );
                    break;
                case FileUploadStatus.Successful:
                    setClearQueryResultTimeout(true);
                    setUploadResult(
                        `File upload ${createFileUploadRequestData?.createFileUploadRequest.id} successfully completed.`
                    );
                    break;
                default:
                    setClearQueryResultTimeout(true);
                    setUploadResult(
                        `Unknown failure querying status of file upload ${createFileUploadRequestData?.createFileUploadRequest.id}.`
                    );
            }
        }
    }, [queryFileUploadRequestData, queryFileUploadRequestLoading, queryFileUploadRequestError]);

    useEffect(() => {
        if (clearQueryResultTimeout && queryResultTimeout) {
            clearInterval(queryResultTimeout);
            setQueryResultTimeout(undefined);
            setClearQueryResultTimeout(false);
        }
    }, [queryResultTimeout, clearQueryResultTimeout]);

    return (
        <div style={{marginTop: "75px"}}>
            <ThemeProvider theme={ViziblyTheme}>
                <form onSubmit={evt => {evt.preventDefault(); doCreateFileUploadRequest();}}>
                    <Row>
                        <Col md={6} offsetMd={3}>
                            <Field>
                                <Label>S3 Bucket</Label>
                                <Input value={createInput.s3Bucket} onChange={evt => updateCreateData({s3Bucket: evt.target.value})}/>
                            </Field>
                            <Field>
                                <Label>S3 Key</Label>
                                <Input value={createInput.s3Key} onChange={evt => updateCreateData({s3Key: evt.target.value})}/>
                            </Field>
                            <Dropdown inputValue={""} selectedItem={createInput.type} onSelect={item => updateCreateData({type: item})}>
                                <DropdownField>
                                    <DropdownLabel>File Type</DropdownLabel>
                                    <Select>{createInput.type}</Select>
                                </DropdownField>
                                <Menu>
                                    {Object.values(FileUploadType).map(r => (
                                        <Item key={r} value={r}>{r}</Item>
                                    ))}
                                </Menu>
                            </Dropdown>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={6} offsetMd={3}>
                            <NavLink to="/admin/start">Cancel</NavLink>
                            <button className="btn btn-primary" type="submit" disabled={createFileUploadRequestLoading}>Upload File</button>
                        </Col>
                    </Row>
                </form>
                <Row>
                    <Col md={6} offsetMd={3}>
                        <Label>Upload Result</Label>
                    </Col>
                </Row>
                <Row>
                    <Col md={6} offsetMd={3}>
                        <textarea value={uploadResult} readOnly style={{width: "100%"}}/>
                    </Col>
                </Row>
            </ThemeProvider>
        </div>
    );
}
