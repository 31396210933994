import { WorksheetLinesReportLine } from './../data/types';
export type SpreadsheetLine = any[];

export function buildSpreadsheetData(data: WorksheetLinesReportLine[]): SpreadsheetLine[] {
    const sortedData = data.sortBy(row => row.description + row.propertyName + row.accountNumber ?? "" + row.accountName);

    const result = [] as SpreadsheetLine[];
    for (const dataRow of sortedData) {
        const line: SpreadsheetLine = [];
        line.push(dataRow.description);
        line.push(dataRow.propertyName);
        line.push((dataRow.accountNumber ? dataRow.accountNumber + " - " : "") + dataRow.accountName);
        line.push(...dataRow.reforecastValues);
        line.push(dataRow.reforecastValues.sum());
        line.push(...dataRow.budgetValues);
        line.push(dataRow.budgetValues.sum());

        result.push(line);
    }

    return result;
}