import { useEffect, useReducer } from "react";
import { MultiValue } from "react-select";
import "native-injects";

import { lookbackPeriodMap } from "../../../pages/workflows/account/formula-bar/logic/useFormulaBarMenu";
import { TLineItemsOption } from "../../../pages/workflows/account/formula-bar/components/formula-menu/LineItemsMenu";
import { DistributionMethod, GrowthCalcMethod, MonthlyAdjustmentType, MonthlyAverageBasePeriod, PayrollDriverCompensationItemPositionsModel, PayrollPositionModel, PercentGrowthBasePeriod, RevenueType } from "../../../__generated__/generated_types";

import {
    TDriverAccountMetric,
    TDriverMetrics,
    TDriverMetricsYear,
    TDriverOperationalMetric,
    TDriverPayrollMetric,
    TDriverGrowthMetric,
    TDriverRevenueMetric,
    TDriverWorksheetMetric,
    TPendingDriverAccountMetric,
    TPendingDriverMetricsYear,
    TPendingDriverOperationalMetric,
    TPendingDriverPayrollMetric,
    TPendingDriverGrowthMetric, TDriverAccountAugments,
} from "./logic/driversAndWorksheetData";
import {IPayrollDriver} from "./utils";

import { useProperties } from "../../properties/PropertiesContext";


interface PendingUpdates {
    add: TPendingDriverMetricsYear,
    remove: TPendingDriverMetricsYear,
    update: TPendingDriverMetricsYear,
}

type TRemoveOperationalDriverPayload = {
    name: string;
    id: string;
    lookbackPeriod?: string | number | null;
}

type TRemoveAccountDriver = {
    payload: TRemoveOperationalDriverPayload;
}

type TRemoveOperationalDriver = {
    operationalDriverName: string;
}

type TRemoveCustomDriver = {
    itemName: string;
}

type TRemovePayrollDriver = {
    allPayrollDrivers: IPayrollDriver[],
    itemType: string;
}

type TRemoveRevenueDriver = {
    revenueType: RevenueType,
}

type TUpdateAccountDrivers = {
    updatePayload: {
        accountId: string;
        glName: string;
        glNumber: string;
    }
}

type TSetAccountManyDrivers = {
    payload: {
        accountId: string;
        glName: string;
        glNumber: string;
        lookbackPeriod: number;
    }[]
}

type TUpdateLineItems = {
    action: string,
    updatedValue: string,
    updatedValues: TLineItemsOption[],
}

type TUpdateAccountLookbackPeriodByAccountId = {
    lookbackPeriod: string,
    accountId: string,
}

type TUpdateOperationalLookbackPeriodByMetricType = {
    lookbackPeriod: string,
    operationalMetricType: string,
}

type TAddOperationalDriver = {
    createPayload: TDriverOperationalMetric,
}

type TAddCustomDriver = {
    itemName: string,
}

type TUpdatePayrollDrivers = {
    allPayrollDrivers: IPayrollDriver[],
    selectedCompItem: string,
    positionIds: string[],
}

type TUpdateRevenueDrivers = {
    updatePayload: TDriverRevenueMetric,
}

type State = {
    options: MultiValue<TLineItemsOption> | undefined,
    isUserRequestingWorksheetDeletion: boolean,
    parsedFormDrivers: TDriverMetricsYear,
    parsedOriginalFormDrivers: TDriverMetricsYear,
    pendingUpdates: PendingUpdates,
    selectedOptions: TLineItemsOption[],
    isReady: boolean,
}

const PendingUpdatesAllTypes = {
    operational: [],
    revenue: [],
    worksheet: [],
    monthlyAverage: [],
    percentGrowth: [],
    annualTargetValue: [],
    account: [],
    accountPercentageAugment: null,
    payroll: [],
    customItems: []
};

const INITIAL_STATE: State = {
    options: undefined,
    isUserRequestingWorksheetDeletion: false,
    parsedFormDrivers: {
        operational: [],
        revenue: [],
        worksheet: [],
        monthlyAverage: [],
        percentGrowth: [],
        annualTargetValue: [],
        account: [],
        accountPercentageAugment: {
            minValue: null,
            maxValue: null
        },
        payroll: [],
        renovations: [],
        customDriver: []
    },
    parsedOriginalFormDrivers: {
        operational: [],
        revenue: [],
        worksheet: [],
        monthlyAverage: [],
        percentGrowth: [],
        annualTargetValue: [],
        account: [],
        accountPercentageAugment: {
            minValue: null,
            maxValue: null
        },
        payroll: [],
        renovations: [],
        customDriver: []
    },
    pendingUpdates: {
        add: PendingUpdatesAllTypes,
        remove: PendingUpdatesAllTypes,
        update: PendingUpdatesAllTypes,
    },
    selectedOptions: [],
    isReady: false,
};

interface IApplyBulkUpdate {
    type: "APPLY_BULK_UPDATE",
    drivers: TDriverMetricsYear,
}

interface ISetParsedOriginalFormDriverMetricsAction {
    type: "SET_PARSED_ORIGINAL_FORM_DRIVER_METRICS",
    parsedOriginalFormDrivers: TDriverMetricsYear,
}

interface ISetOptionsAction {
    type: "SET_LINE_ITEMS_MENU_OPTIONS",
    updatedOptions: MultiValue<TLineItemsOption>,
}

interface ISetSelectedOptionsAction {
    type: "SET_LINE_ITEMS_MENU_SELECTED_OPTIONS",
    updatedOptions: TLineItemsOption[],
}

interface IUpdateAccountDriversAction {
    type: "UPDATE_ACCOUNT_DRIVERS",
    accountId: string,
    glName: string,
    glNumber: string,
}

interface ISetAccountManyDriversAction {
    type: "SET_ACCOUNT_MANY_DRIVERS",
    accounts: TSetAccountManyDrivers["payload"],
}

interface ISetAccountAugmentsAction {
    type: "SET_ACCOUNT_AUGMENTS",
    augments: TDriverAccountAugments
}

interface IAddOperationalDriverAction {
    type: "ADD_OPERATIONAL_DRIVER",
    createPayload: TDriverOperationalMetric,
}

interface IUpdateRevenueDriversAction {
    type: "UPDATE_REVENUE_DRIVERS",
    updatePayload: TDriverRevenueMetric,
}

interface IAddPayrollDriverByCompTypeAction {
    type: "ADD_PAYROLL_DRIVER_BY_COMP_TYPE",
    positions: PayrollDriverCompensationItemPositionsModel[],
    itemType: string,
}

interface IRemoveAccountDriverAction {
    type: "REMOVE_ACCOUNT_DRIVER",
    accountId: string,
}

interface IRemoveOperationalDriverAction {
    type: "REMOVE_OPERATIONAL_DRIVER",
    operationalDriverName: string,
}

interface IAddCustomDriverAction {
    type: "ADD_CUSTOM_DRIVER",
    itemName: string,
}

interface IRemoveCustomDriverAction {
    type: "REMOVE_CUSTOM_DRIVER",
    itemName: string,
}

interface IRemovePayrollDriverAction {
    type: "REMOVE_PAYROLL_DRIVER",
    compId: string;
    itemType: string;
}

interface IRemoveRevenueDriverAction {
    type: "REMOVE_REVENUE_DRIVER",
    revenueType: RevenueType,
}

interface IResetPendingUpdatesAction {
    type: "RESET_PENDING_UPDATES",
}

interface IResetParsedFormDriverMetricsAction {
    type: "RESET_PARSED_FORM_DRIVER_METRICS",
    parsedOriginalFormDrivers: TDriverMetricsYear,
}

interface IUpdateAccountLookbackPeriodByAccountIdAction {
    type: "UPDATE_ACCOUNT_LOOKBACK_PERIOD_BY_ACCOUNT_ID",
    accountId: string,
    lookbackPeriod: string,
}

interface IUpdateOperationalLookbackPeriodByMetricTypeAction {
    type: "UPDATE_OPERATIONAL_LOOKBACK_PERIOD_BY_METRIC_TYPE",
    operationalMetricType: string,
    lookbackPeriod: string,
}

interface IDeleteWorksheetAction {
    type: "DELETE_WORKSHEET",
    isWorksheetDriven: boolean,
}

interface IRemoveLineItemsAction {
    type: "REMOVE_LINE_ITEMS",
}

interface IRemoveMonthlyAverageGrowthDriverAction {
    type: "REMOVE_MONTHLY_AVERAGE_GROWTH_DRIVER",
}

interface IRemovePercentGrowthDriverAction {
    type: "REMOVE_PERCENT_GROWTH_DRIVER",
}

interface IUpdatePercentGrowthDriverBaseToGrowAction {
    type: "UPDATE_PERCENT_GROWTH_DRIVER_BASE_TO_GROW",
    baseToGrow: PercentGrowthBasePeriod,
}

interface IUpdatePercentGrowthDriverDistributionAction {
    type: "UPDATE_PERCENT_GROWTH_DRIVER_DISTRIBUTION",
    distributionMethod: DistributionMethod,
}

interface IUpdatePercentGrowthDriverRateAction {
    type: "UPDATE_PERCENT_GROWTH_DRIVER_RATE",
    growthRate: number,
}

interface IAddMonthlyAverageGrowthDriverAction {
    type: "ADD_MONTHLY_AVERAGE_GROWTH_DRIVER",
    reforecastStartMonthIndex: number,
    reforecastYear: number,
}

interface IUpdateMonthlyAverageStartMonthYearAction {
    type: "UPDATE_MONTHLY_AVERAGE_START_MONTH_YEAR",
    lookbackPeriodStart: string,
}

interface IUpdateMonthlyAverageEndMonthYearAction {
    type: "UPDATE_MONTHLY_AVERAGE_END_MONTH_YEAR",
    lookbackPeriodEnd: string,
}

interface IUpdateMonthlyAverageBasePeriod {
    type: "UPDATE_MONTHLY_AVERAGE_BASE_PERIOD",
    monthlyAverageBasePeriod: MonthlyAverageBasePeriod,
}

interface IUpdateMonthlyAdjustmentTypeAction {
    type: "UPDATE_MONTHLY_ADJUSTMENT_TYPE",
    monthlyAdjustmentType: MonthlyAdjustmentType,
}

interface IUpdateMonthlyAdjustmentValueAction {
    type: "UPDATE_MONTHLY_ADJUSTMENT_VALUE",
    monthlyAdjustmentValue: number | null,
}

interface IAddAnnualTargetValueGrowthDriverAction {
    type: "ADD_ANNUAL_TARGET_VALUE_GROWTH_DRIVER",
}

interface IUpdateAnnualTargetValueDriverManualEntryAction {
    type: "UPDATE_ANNUAL_TARGET_VALUE_DRIVER_MANUAL_ENTRY",
    annualTargetValue: number,
}

interface IUpdateAnnualTargetValueDistributionAction {
    type: "UPDATE_ANNUAL_TARGET_VALUE_DISTRIBUTION",
    distributionMethod: DistributionMethod,
}

interface IRemoveAnnualTargetValueDriverAction {
    type: "REMOVE_ANNUAL_TARGET_VALUE_DRIVER",
}

interface IUpdateLineItemsAction {
    type: "UPDATE_LINE_ITEMS",
    actionType: string,
    updatedValue: string,
    updatedValues: string[],
}

interface IUpdatePayrollDriversAction {
    type: "UPDATE_PAYROLL_DRIVERS",
    compId: string;
    itemType: string;
    positions: PayrollDriverCompensationItemPositionsModel[],
}

type Action = IApplyBulkUpdate
    | IAddMonthlyAverageGrowthDriverAction
    | IAddAnnualTargetValueGrowthDriverAction
    | IAddPayrollDriverByCompTypeAction
    | IRemoveAccountDriverAction
    | IRemoveAnnualTargetValueDriverAction
    | IRemoveOperationalDriverAction
    | IAddCustomDriverAction
    | IRemoveCustomDriverAction
    | IRemovePayrollDriverAction
    | IRemoveMonthlyAverageGrowthDriverAction
    | IRemovePercentGrowthDriverAction
    | IRemoveRevenueDriverAction
    | IResetPendingUpdatesAction
    | ISetOptionsAction
    | ISetParsedOriginalFormDriverMetricsAction
    | ISetSelectedOptionsAction
    | IResetParsedFormDriverMetricsAction
    | IDeleteWorksheetAction
    | IRemoveLineItemsAction
    | IUpdateAccountDriversAction
    | ISetAccountManyDriversAction
    | ISetAccountAugmentsAction
    | IUpdatePercentGrowthDriverBaseToGrowAction
    | IUpdatePercentGrowthDriverDistributionAction
    | IUpdateMonthlyAdjustmentTypeAction
    | IUpdateMonthlyAdjustmentValueAction
    | IUpdateMonthlyAverageStartMonthYearAction
    | IUpdateMonthlyAverageEndMonthYearAction
    | IUpdateMonthlyAverageBasePeriod
    | IUpdatePercentGrowthDriverRateAction
    | IUpdateAnnualTargetValueDistributionAction
    | IUpdateAnnualTargetValueDriverManualEntryAction
    | IUpdateLineItemsAction
    | IAddOperationalDriverAction
    | IUpdatePayrollDriversAction
    | IUpdateRevenueDriversAction
    | IUpdateAccountLookbackPeriodByAccountIdAction
    | IUpdateOperationalLookbackPeriodByMetricTypeAction;

export interface IFormulaBarUpdates {
    addAnnualTargetValueGrowthDriver: () => void;
    addMonthlyAverageGrowthDriver: () => void;
    applyBulkUpdate: (drivers: TDriverMetricsYear) => void;
    isUserRequestingWorksheetDeletion: boolean,
    save: (pendingUpdates: TDriverMetrics|undefined) => void,
    parsedFormDrivers: TDriverMetricsYear, // This is what will power the formula bar, and not parsedData from the D_A_W_D hook
    parsedOriginalFormDrivers: TDriverMetricsYear,
    removeAccountDriver: (args: TRemoveAccountDriver) => void,
    removeAnnualTargetValueDriver: () => void,
    removeLineItems: () => void,
    removeMonthlyAverageGrowthDriver: () => void,
    removeOperationalDriver: (args: TRemoveOperationalDriver) => void,
    removeCustomDriver: (args: TRemoveCustomDriver) => void,
    removePayrollDriver: (args: TRemovePayrollDriver) => void,
    removePercentGrowthDriver: () => void,
    removeRevenueDriver: (args: TRemoveRevenueDriver) => void,
    options: MultiValue<TLineItemsOption> | undefined,
    setOptions: (updatedOptions: MultiValue<TLineItemsOption>) => void,
    selectedOptions: TLineItemsOption[],
    setSelectedOptions: (updatedOptions: TLineItemsOption[]) => void,
    updateAccountDrivers: (args: TUpdateAccountDrivers) => void,
    setAccountManyDrivers: (args: TSetAccountManyDrivers) => void,
    setAccountAugments: (args: TDriverAccountAugments) => void,
    deleteWorksheet: (isWorksheetDriven: boolean) => void,
    updateAnnualTargetValueDriverManualEntry: (annualTargetValue: number) => void,
    updateAnnualTargetValueDriverDistribution: (distributionMethod: DistributionMethod) => void,
    updatePercentGrowthDriverBaseToGrow: (baseToGrow: PercentGrowthBasePeriod) => void,
    updatePercentGrowthDriverDistribution: (distributionMethod: DistributionMethod) => void,
    updatePercentGrowthDriverRate: (growthRate: number) => void,
    updateLineItems: (args: TUpdateLineItems) => void,
    updateAccountLookbackPeriodByAccountId: (args: TUpdateAccountLookbackPeriodByAccountId) => void,
    updateOperationalLookbackPeriodByMetricType: (args: TUpdateOperationalLookbackPeriodByMetricType) => void,
    updateMonthlyAdjustmentType: (monthlyAdjustmentType: MonthlyAdjustmentType) => void,
    updateMonthlyAdjustmentValue: (monthlyAdjustmentValue: number) => void,
    updateMonthlyAverageBasePeriod: (MonthlyAverageBasePeriod: MonthlyAverageBasePeriod) => void,
    updateMonthlyAverageStartMonthYear: (month: number, year: number) => void,
    updateMonthlyAverageEndMonthYear: (month: number, year: number) => void,
    addOperationalDriver: (args: TAddOperationalDriver) => void,
    updatePayrollDrivers: (args: TUpdatePayrollDrivers) => void,
    addCustomDriver: (args: TAddCustomDriver) => void,
    updateRevenueDrivers: (args: TUpdateRevenueDrivers) => void,
    pendingUpdates: PendingUpdates,
    registerParsedDrivers: (parsedOriginalFormDrivers: TDriverMetricsYear) => void,
    saveLoading: boolean,
    resetFormulaBar: () => void,
    isReady: boolean,
}

export default function useFormulaBarUpdates(): IFormulaBarUpdates {

    const [state, dispatch] = useReducer(formulaBarReducer, INITIAL_STATE);
    const { currentProperty } = useProperties();

    const registerParsedDrivers = (parsedOriginalFormDrivers: TDriverMetricsYear): void => {
        dispatch({
            type: "SET_PARSED_ORIGINAL_FORM_DRIVER_METRICS",
            parsedOriginalFormDrivers,
        } as ISetParsedOriginalFormDriverMetricsAction);
    };

    const setOptions = (updatedOptions: MultiValue<TLineItemsOption>) => {
        dispatch({
            type: "SET_LINE_ITEMS_MENU_OPTIONS",
            updatedOptions,
        });
    };

    const setSelectedOptions = (updatedOptions: TLineItemsOption[]) => {
        dispatch({
            type: "SET_LINE_ITEMS_MENU_SELECTED_OPTIONS",
            updatedOptions,
        });
    };

    const applyBulkUpdate = (drivers: TDriverMetricsYear) => {
        dispatch({
            type: "APPLY_BULK_UPDATE",
            drivers,
        });
    };

    // Placeholder initialization, this will track the API call
    const saveLoading = false;

    const setAccountManyDrivers = ({payload}: TSetAccountManyDrivers): void => {
        dispatch({
            type: "SET_ACCOUNT_MANY_DRIVERS",
            accounts: payload
        });
    };

    const setAccountAugments = (augments: TDriverAccountAugments): void => {
        dispatch({
            type: "SET_ACCOUNT_AUGMENTS",
            augments: augments
        });
    };

    const updateAccountDrivers = ({ updatePayload}: TUpdateAccountDrivers): void => {
        dispatch({
            type: "UPDATE_ACCOUNT_DRIVERS",
            accountId: updatePayload.accountId,
            glName: updatePayload.glName,
            glNumber: updatePayload.glNumber,
        });
    };

    const updateAccountLookbackPeriodByAccountId = ({
        accountId,
        lookbackPeriod,
    }: TUpdateAccountLookbackPeriodByAccountId): void => {

        dispatch({
            type: "UPDATE_ACCOUNT_LOOKBACK_PERIOD_BY_ACCOUNT_ID",
            accountId,
            lookbackPeriod,
        });

        return;
    };

    const updateOperationalLookbackPeriodByMetricType = ({
        operationalMetricType,
        lookbackPeriod,
    }: TUpdateOperationalLookbackPeriodByMetricType): void => {

        dispatch({
            type: "UPDATE_OPERATIONAL_LOOKBACK_PERIOD_BY_METRIC_TYPE",
            operationalMetricType,
            lookbackPeriod,
        });
    };

    const addAnnualTargetValueGrowthDriver = (): void => {
        dispatch({
            type: "ADD_ANNUAL_TARGET_VALUE_GROWTH_DRIVER",
        });
    };

    const removeAnnualTargetValueDriver = (): void => {
        dispatch({
            type: "REMOVE_ANNUAL_TARGET_VALUE_DRIVER",
        });
    };

    const updateAnnualTargetValueDriverDistribution = (distributionMethod: DistributionMethod): void => {
        dispatch({
            type: "UPDATE_ANNUAL_TARGET_VALUE_DISTRIBUTION",
            distributionMethod,
        });
    };

    const updateAnnualTargetValueDriverManualEntry = (annualTargetValue: number): void => {
        dispatch({
            type: "UPDATE_ANNUAL_TARGET_VALUE_DRIVER_MANUAL_ENTRY",
            annualTargetValue,
        });
    };

    const updatePercentGrowthDriverBaseToGrow = (baseToGrow: PercentGrowthBasePeriod): void => {
        dispatch({
            type: "UPDATE_PERCENT_GROWTH_DRIVER_BASE_TO_GROW",
            baseToGrow,
        });
    };

    const updatePercentGrowthDriverDistribution = (distributionMethod: DistributionMethod): void => {
        dispatch({
            type: "UPDATE_PERCENT_GROWTH_DRIVER_DISTRIBUTION",
            distributionMethod,
        });
    };

    const updatePercentGrowthDriverRate = (growthRate: number): void => {
        dispatch({
            type: "UPDATE_PERCENT_GROWTH_DRIVER_RATE",
            growthRate,
        });
    };

    const removeMonthlyAverageGrowthDriver = (): void => {
        dispatch({
            type: "REMOVE_MONTHLY_AVERAGE_GROWTH_DRIVER",
        });
    };

    const removePercentGrowthDriver = (): void => {
        dispatch({
            type: "REMOVE_PERCENT_GROWTH_DRIVER",
        });
    };

    const addMonthlyAverageGrowthDriver = (): void => {
        if (currentProperty) {
            dispatch({
                type: "ADD_MONTHLY_AVERAGE_GROWTH_DRIVER",
                reforecastStartMonthIndex: currentProperty.reforecastStartMonthIndex,
                reforecastYear: currentProperty.reforecastYear,
            });
        }
    };

    const updateMonthlyAdjustmentType = (monthlyAdjustmentType: MonthlyAdjustmentType): void => {
        dispatch({
            type: "UPDATE_MONTHLY_ADJUSTMENT_TYPE",
            monthlyAdjustmentType,
        });
    };

    const updateMonthlyAdjustmentValue = (monthlyAdjustmentValue: number): void => {
        dispatch({
            type: "UPDATE_MONTHLY_ADJUSTMENT_VALUE",
            monthlyAdjustmentValue: monthlyAdjustmentValue,
        });
    };

    const updateMonthlyAverageBasePeriod = (monthlyAverageBasePeriod: MonthlyAverageBasePeriod) => {
        dispatch({
            type: "UPDATE_MONTHLY_AVERAGE_BASE_PERIOD",
            monthlyAverageBasePeriod,
        });
    };

    const updateMonthlyAverageStartMonthYear = (month: number, year: number) => {
        const lookbackPeriodStart = `${year}-${(month + 1).toString().padStart(2, '0')}-01`;
        dispatch({
            type: "UPDATE_MONTHLY_AVERAGE_START_MONTH_YEAR",
            lookbackPeriodStart,
        });
    };

    const updateMonthlyAverageEndMonthYear = (month: number, year: number) => {
        const lookbackPeriodEnd = `${year}-${(month + 1).toString().padStart(2, '0')}-01`;
        dispatch({
            type: "UPDATE_MONTHLY_AVERAGE_END_MONTH_YEAR",
            lookbackPeriodEnd,
        });
    };

    const addOperationalDriver = ({
        createPayload,
    }: TAddOperationalDriver): void => {
        dispatch({
            type: "ADD_OPERATIONAL_DRIVER",
            createPayload,
        });
    };

    const addCustomDriver = ({
        itemName
    }: TAddCustomDriver): void => {
        dispatch({
            type: "ADD_CUSTOM_DRIVER",
            itemName
        })
    }

    const updateRevenueDrivers = ({
        updatePayload,
    }: TUpdateRevenueDrivers): void => {
        dispatch({
            type: "UPDATE_REVENUE_DRIVERS",
            updatePayload,
        });
    };

    const removeAccountDriver = ({ payload }: TRemoveAccountDriver): void => {
        dispatch({
            type: "REMOVE_ACCOUNT_DRIVER",
            accountId: payload.id,
        });
    };

    const removeLineItems = () => {
        dispatch({ type: "REMOVE_LINE_ITEMS" });
    };

    const deleteWorksheet = (isWorksheetDriven: boolean) => {
        // First remove all line items, then delete the worksheet
        removeLineItems();
        dispatch({
            type: "DELETE_WORKSHEET",
            isWorksheetDriven,
        });
    };

    const removeOperationalDriver = ({ operationalDriverName }: TRemoveOperationalDriver): void => {
        dispatch({
            type: "REMOVE_OPERATIONAL_DRIVER",
            operationalDriverName,
        });
    };

    const removeCustomDriver = ({ itemName }: TRemoveCustomDriver): void => {
        dispatch({
            type: "REMOVE_CUSTOM_DRIVER",
            itemName,
        });
    };

    const removeRevenueDriver = ({
        revenueType,
    }: TRemoveRevenueDriver) => {
        dispatch({
            type: "REMOVE_REVENUE_DRIVER",
            revenueType,
        });
    };

    const updateLineItems = ({
        action,
        updatedValue,
        updatedValues,
    }: TUpdateLineItems) => {
        dispatch({
            type: "UPDATE_LINE_ITEMS",
            actionType: action,
            updatedValue,
            updatedValues: updatedValues.map((updatedValue) => updatedValue.label),
        });
    };

    const removePayrollDriver = ({ allPayrollDrivers, itemType }: TRemovePayrollDriver) => {
        const compId = allPayrollDrivers.find((driver) => driver.type === itemType)?.id;

        if (compId) {
            dispatch({
                type: "REMOVE_PAYROLL_DRIVER",
                compId,
                itemType,
            });
        }
    };

    const updatePayrollDrivers = ({
        allPayrollDrivers,
        selectedCompItem,
        positionIds,
    }: TUpdatePayrollDrivers) => {
        const itemType = allPayrollDrivers.find((driver) => driver.id === selectedCompItem)?.type;
        const parsePositionsForFxBar: PayrollPositionModel[] = [];
        const allPositions = allPayrollDrivers.find((driver) => driver.type === itemType)?.positions;

        positionIds.forEach((positionId) => {
            const positionObj = allPositions?.find((position) => position.id === positionId);
            if (positionObj) {
                parsePositionsForFxBar.push(positionObj);
            }
        });

        // A work-around for type-casting
        const updatedPositions: PayrollDriverCompensationItemPositionsModel[] = parsePositionsForFxBar.map((position) => ({
            id: position.id,
            name: position.name,
        }));

        if (itemType) {
            dispatch({
                type: "UPDATE_PAYROLL_DRIVERS",
                compId: selectedCompItem,
                itemType,
                positions: updatedPositions,
            });
        }
    };

    // Make an API call to save the pending updates to the drivers and worksheet data
    const save = (): void => {
        if (state.pendingUpdates === undefined) {
            return;
        }

        // save API call goes here
    };

    // Reset the formula bar to its last saved state
    const resetFormulaBar = (): void => {
        dispatch({
            type: "RESET_PARSED_FORM_DRIVER_METRICS",
            parsedOriginalFormDrivers: state.parsedOriginalFormDrivers,
        });

        dispatch({
            type: "RESET_PENDING_UPDATES",
        });

    };

    return {
        isUserRequestingWorksheetDeletion: state.isUserRequestingWorksheetDeletion,

        registerParsedDrivers,

        parsedFormDrivers: state.parsedFormDrivers,
        parsedOriginalFormDrivers: state.parsedOriginalFormDrivers,
        pendingUpdates: state.pendingUpdates,

        resetFormulaBar,

        addAnnualTargetValueGrowthDriver,
        addMonthlyAverageGrowthDriver,
        deleteWorksheet,
        removeAccountDriver,
        removeAnnualTargetValueDriver,
        removeLineItems,
        removeMonthlyAverageGrowthDriver,
        removeOperationalDriver,
        removeCustomDriver,
        removePayrollDriver,
        removePercentGrowthDriver,
        removeRevenueDriver,
        setOptions,
        setSelectedOptions,
        updateAccountDrivers,
        setAccountManyDrivers,
        setAccountAugments,
        updateAnnualTargetValueDriverDistribution,
        updateAnnualTargetValueDriverManualEntry,
        updatePercentGrowthDriverBaseToGrow,
        updatePercentGrowthDriverDistribution,
        updatePercentGrowthDriverRate,
        updateLineItems,
        updateAccountLookbackPeriodByAccountId,
        updateOperationalLookbackPeriodByMetricType,
        updateMonthlyAdjustmentType,
        updateMonthlyAdjustmentValue,
        updateMonthlyAverageBasePeriod,
        updateMonthlyAverageStartMonthYear,
        updateMonthlyAverageEndMonthYear,
        addOperationalDriver,
        addCustomDriver,
        updatePayrollDrivers,
        updateRevenueDrivers,

        save,
        saveLoading,

        options: state.options,
        selectedOptions: state.selectedOptions,

        applyBulkUpdate,

        isReady: state.isReady,
    };
}

function formulaBarReducer(state: State, action: Action): State {
    switch (action.type) {
        case "APPLY_BULK_UPDATE": {
            const { drivers } = action;

            /**
             * When bulk update is applied, we need to explicitly mark `isUserRequestingWorksheetDeletion` as true
             * if the bulk update state removes it from the current formula bar
             */
            const doesBulkStateHaveWorksheet = drivers.worksheet.length > 0;
            const doesOriginalStateHaveWorksheet = state.parsedFormDrivers.worksheet.length > 0;

            return {
                ...state,
                parsedFormDrivers: drivers,
                isUserRequestingWorksheetDeletion: !doesBulkStateHaveWorksheet && doesOriginalStateHaveWorksheet,
            };
        }

        case "REMOVE_OPERATIONAL_DRIVER": {
            const { operationalDriverName } = action;

            /**
             * If a newly added driver was removed, then simply remove it from pendingUpdates.add
             */
            const getAddedDrivers = (operationalDrivers: TPendingDriverOperationalMetric[]) => {
                return operationalDrivers.filter((driver) => driver.type !== operationalDriverName);
            };

            const getRemovedDrivers = (operationalDrivers: TPendingDriverOperationalMetric[]) => {
                const getRemovedDriver = state.parsedFormDrivers.operational.find((driver) => driver.type === operationalDriverName);

                const originalOperationalDrivers = state.parsedOriginalFormDrivers.operational;

                const reconstructOperationalPayload = (inputPayload: TDriverOperationalMetric) => ({
                    type: inputPayload.type,
                    sourceMetricId: inputPayload.sourceMetricId,
                    driverMetricTypeId: inputPayload.driverMetricTypeId,
                    lookbackPeriod: inputPayload.lookbackPeriod,
                });

                if (originalOperationalDrivers.find((driver) => driver.type === operationalDriverName)) {
                    return [
                        ...operationalDrivers,
                        ...(getRemovedDriver ? [reconstructOperationalPayload(getRemovedDriver)] : []),
                    ];
                }

                return operationalDrivers;
            };

            /**
             * If an edited driver was removed, then simply remove it from pendingUpdates.update
             */
            const getUpdatedDrivers = (operationalDrivers: TPendingDriverOperationalMetric[]) => {
                return operationalDrivers.filter((driver) => driver.type !== operationalDriverName);
            };

            return {
                ...state,
                parsedFormDrivers: {
                    ...state.parsedFormDrivers,
                    operational: state.parsedFormDrivers.operational.filter((driver) => driver.type !== operationalDriverName),
                },
                pendingUpdates: {
                    ...state.pendingUpdates,
                    add: {
                        ...state.pendingUpdates.add,
                        operational: getAddedDrivers(state.pendingUpdates.add.operational),
                    },
                    remove: {
                        ...state.pendingUpdates.remove,
                        operational: getRemovedDrivers(state.pendingUpdates.remove.operational),
                    },
                    update: {
                        ...state.pendingUpdates.update,
                        operational: getUpdatedDrivers(state.pendingUpdates.update.operational),
                    }
                },
            };
        }

        case "REMOVE_CUSTOM_DRIVER": {
            const {itemName: removeItemName} = action;
            const removed = state.pendingUpdates.remove.customItems.filter(itemName => itemName != removeItemName);
            const added = state.pendingUpdates.add.customItems.filter(itemName => itemName != removeItemName);
            if (state.parsedOriginalFormDrivers.customDriver.find(row => row.itemName == removeItemName)) {
                removed.push(removeItemName);
            }

            return {
                ...state,
                parsedFormDrivers: {
                    ...state.parsedFormDrivers,
                    customDriver: state.parsedFormDrivers.customDriver.filter(row => row.itemName != removeItemName),
                },
                pendingUpdates: {
                    ...state.pendingUpdates,
                    remove: {
                        ...state.pendingUpdates.remove,
                        customItems: removed
                    },
                    add: {
                        ...state.pendingUpdates.add,
                        customItems: added
                    }
                },
            };
        }

        case "ADD_CUSTOM_DRIVER": {
            const {itemName: addItemName} = action;
            const removed = state.pendingUpdates.remove.customItems.filter(itemName => itemName != addItemName);
            const added = state.pendingUpdates.add.customItems.filter(itemName => itemName != addItemName);
            const existing = state.parsedOriginalFormDrivers.customDriver.find(row => row.itemName == addItemName);
            const updatedParsed = state.parsedFormDrivers.customDriver.filter(row => row.itemName != addItemName);
            if (existing == undefined) {
                added.push(addItemName);
                updatedParsed.push({
                    id: addItemName,
                    itemName: addItemName,
                    amount: new Array(12).fill(0),
                    count: new Array(12).fill(0),
                    percentage: new Array(12).fill(0)
                });
            }
            else {
                updatedParsed.push(existing);
            }

            return {
                ...state,
                parsedFormDrivers: {
                    ...state.parsedFormDrivers,
                    customDriver: updatedParsed,
                },
                pendingUpdates: {
                    ...state.pendingUpdates,
                    remove: {
                        ...state.pendingUpdates.remove,
                        customItems: removed
                    },
                    add: {
                        ...state.pendingUpdates.add,
                        customItems: added
                    }
                },
            };
        }

        case "ADD_OPERATIONAL_DRIVER": {
            const { createPayload } = action;

            const getOperationalDrivers = (operationalDrivers: TDriverOperationalMetric[]) => {
                const matchingOriginalDriver = state.parsedOriginalFormDrivers.operational.find((each) => each.type === createPayload.type);

                /**
                 * If a deleted driver is being added back, then preserve all of its original keys except for lookbackPeriod
                 * as lookbackPeriod for new op driver nodes should default to 0, which is its value in createPayload
                 * Else use createPayload as is
                 */
                const refinedUpdatePayload = matchingOriginalDriver ? {
                    ...matchingOriginalDriver,
                    lookbackPeriod: createPayload.lookbackPeriod,
                } : createPayload;

                return [
                    ...operationalDrivers,
                    refinedUpdatePayload,
                ];
            };

            const getAddedDrivers = (operationalDrivers: TPendingDriverOperationalMetric[]) => {
                const reconstructOperationalPayload = (inputPayload: TDriverOperationalMetric) => ({
                    type: inputPayload.type,
                    sourceMetricId: inputPayload.sourceMetricId,
                    driverMetricTypeId: inputPayload.driverMetricTypeId,
                    lookbackPeriod: inputPayload.lookbackPeriod,
                });

                if (state.parsedOriginalFormDrivers.operational.find((driver) => driver.type === createPayload.type)) {
                    return operationalDrivers;
                }
                return [
                    ...operationalDrivers,
                    reconstructOperationalPayload(createPayload),
                ];
            };

            const getRemovedDrivers = (operationalDrivers: TPendingDriverOperationalMetric[]) => {
                return operationalDrivers.filter((driver) => driver.type !== createPayload.type);
            };

            const getUpdatedDrivers = (operationalDrivers: TPendingDriverOperationalMetric[]) => {
                const matchingOriginalDriver = state.parsedOriginalFormDrivers.operational.find((driver) => driver.type === createPayload.type);

                if (matchingOriginalDriver) {
                    /**
                     * This reducer only runs when the user adds an operational driver to the formula bar
                     * We check if the createPayload.lookbackPeriod (always 0) is the same as the lookbackPeriod
                     * of this operational driver from the original data
                    */
                    if (matchingOriginalDriver.lookbackPeriod === createPayload.lookbackPeriod) {
                        return operationalDrivers;
                    } else {
                        /**
                         * If the lookbackPeriod-s are different, then we create an entry in pending.update
                         * if it doesn't already exist
                        */
                        if (operationalDrivers.find((each) => each.type === createPayload.type)) {
                            return operationalDrivers.map((each) => {
                                if (each.type === createPayload.type) {
                                    return {
                                        ...each,
                                        lookbackPeriod: createPayload.lookbackPeriod,
                                    };
                                }
                                return each;
                            });
                        }
                        return [
                            ...operationalDrivers,
                            {
                                type: createPayload.type,
                                sourceMetricId: createPayload.sourceMetricId,
                                driverMetricTypeId: createPayload.driverMetricTypeId,
                                lookbackPeriod: createPayload.lookbackPeriod,
                            },
                        ];
                    }
                }

                return operationalDrivers;
            };

            return {
                ...state,
                parsedFormDrivers: {
                    ...state.parsedFormDrivers,
                    operational: getOperationalDrivers(state.parsedFormDrivers.operational),
                },
                pendingUpdates: {
                    ...state.pendingUpdates,
                    add: {
                        ...state.pendingUpdates.add,
                        operational: getAddedDrivers(state.pendingUpdates.add.operational),
                    },
                    remove: {
                        ...state.pendingUpdates.remove,
                        operational: getRemovedDrivers(state.pendingUpdates.remove.operational),
                    },
                    update: {
                        ...state.pendingUpdates.update,
                        operational: getUpdatedDrivers(state.pendingUpdates.update.operational),
                    }
                },
            };
        }

        case "UPDATE_PERCENT_GROWTH_DRIVER_BASE_TO_GROW": {
            const { baseToGrow } = action;
            const parsedBaseToGrow = baseToGrow.replaceAll(' ', '_').toUpperCase() as PercentGrowthBasePeriod;

            const defaultPercentGrowthDriver: (TDriverGrowthMetric | TPendingDriverGrowthMetric)[] = [{
                id: GrowthCalcMethod.PercentGrowth,
                growthCalcMethod: GrowthCalcMethod.PercentGrowth,
                distributionMethod: DistributionMethod.Flat,
                percentGrowthBasePeriod: parsedBaseToGrow,
                percentGrowthAdjustmentValue: 0,
            }];

            const getPercentGrowthDriver = (drivers: TDriverGrowthMetric[]) => {
                const driver = drivers[0];
                if (driver?.id) {
                    return [{ ...driver, percentGrowthBasePeriod: parsedBaseToGrow }];
                }

                return defaultPercentGrowthDriver;
            };

            const getPercentGrowthDriverPendingAdd = () => {
                const originalDriver = state.parsedOriginalFormDrivers.percentGrowth[0];

                /**
                 * If the newly added % growth driver is the same as a default one,
                 */
                if (originalDriver) {
                    return [];
                }

                return defaultPercentGrowthDriver;
            };

            const getPercentGrowthDriverPendingRemove = (drivers: TPendingDriverGrowthMetric[]) => {
                const driver = drivers[0];

                /**
                 * If the driver exists in pending.remove, then remove it from there
                 */
                if (driver) {
                    return [];
                }

                return drivers;
            };

            const getPercentGrowthDriverPendingUpdate = (drivers: TPendingDriverGrowthMetric[]) => {
                /**
                 * To check if this is a no-op, we check if all 3 editable items of the % growth driver are the same
                 * because we don't want to rm the driver from pending.update if the other 2 items are in a dirty state
                 */
                const isNoOp = state.parsedOriginalFormDrivers.percentGrowth.length > 0
                    && state.parsedOriginalFormDrivers.percentGrowth[0]?.percentGrowthBasePeriod === parsedBaseToGrow
                    && state.parsedOriginalFormDrivers.percentGrowth[0]?.percentGrowthAdjustmentValue === state.parsedFormDrivers.percentGrowth[0]?.percentGrowthAdjustmentValue
                    && state.parsedOriginalFormDrivers.percentGrowth[0]?.distributionMethod === state.parsedFormDrivers.percentGrowth[0]?.distributionMethod;

                const isDriverInPendingAdd = state.pendingUpdates.add.percentGrowth.length > 0;

                if (isNoOp || isDriverInPendingAdd) {
                    return [];
                }

                /**
                 * If it's not a no-op and if the driver already exists in pending.update, then update it in there
                */
                const driver = drivers[0];
                if (driver) {
                    return [{
                        ...driver,
                        percentGrowthBasePeriod: parsedBaseToGrow,
                    }];
                }

                /**
                 * If it's not a no-op, and if a driver doesn't already exist in pending.update, we check if
                 * the growth base period has changed
                 */
                const originalDriver = state.parsedOriginalFormDrivers.percentGrowth[0];

                if (originalDriver && originalDriver.percentGrowthBasePeriod !== parsedBaseToGrow) {
                    return [{
                        ...originalDriver,
                        percentGrowthBasePeriod: parsedBaseToGrow,
                    }];
                }

                /**
                 * If nothing needs to be done here
                */
                return drivers;
            };

            return {
                ...state,
                parsedFormDrivers: {
                    ...state.parsedFormDrivers,
                    percentGrowth: getPercentGrowthDriver(state.parsedFormDrivers.percentGrowth),
                },
                pendingUpdates: {
                    ...state.pendingUpdates,
                    add: {
                        ...state.pendingUpdates.add,
                        percentGrowth: getPercentGrowthDriverPendingAdd(),
                    },
                    remove: {
                        ...state.pendingUpdates.remove,
                        percentGrowth: getPercentGrowthDriverPendingRemove(state.pendingUpdates.remove.percentGrowth),
                    },
                    update: {
                        ...state.pendingUpdates.update,
                        percentGrowth: getPercentGrowthDriverPendingUpdate(state.pendingUpdates.update.percentGrowth),
                    },
                },
            };
        }

        case "UPDATE_PERCENT_GROWTH_DRIVER_RATE": {
            const { growthRate: percentGrowthAdjustmentValue } = action;

            const getPercentGrowthDriver = (drivers: TDriverGrowthMetric[]) => {
                const driver = drivers[0];

                if (driver?.id) {
                    return [{
                        ...driver,
                        percentGrowthAdjustmentValue,
                    }];
                }

                return drivers;
            };

            const getPercentGrowthDriverPendingAdd = (drivers: TPendingDriverGrowthMetric[]) => {
                const driver = drivers[0];

                /**
                 * If a % growth driver already exists in pending.add, then the user's trying to update that
                */
                if (driver) {
                    return [{
                        ...driver,
                        percentGrowthAdjustmentValue,
                    }];
                }

                /**
                 * Else the user must be updating an existing driver, so skip
                */
                return drivers;
            };

            const getPercentGrowthDriverPendingUpdate = (drivers: TPendingDriverGrowthMetric[]) => {
                /**
                 * To check if this is a no-op, we check if all 3 editable items of the % growth driver are the same
                 * because we don't want to rm the driver from pending.update if the other 2 items are in a dirty state
                 */
                const isNoOp = state.parsedOriginalFormDrivers.percentGrowth.length > 0
                    && state.parsedOriginalFormDrivers.percentGrowth[0]?.percentGrowthBasePeriod === state.parsedFormDrivers.percentGrowth[0]?.percentGrowthBasePeriod
                    && state.parsedOriginalFormDrivers.percentGrowth[0]?.percentGrowthAdjustmentValue === percentGrowthAdjustmentValue
                    && state.parsedOriginalFormDrivers.percentGrowth[0]?.distributionMethod === state.parsedFormDrivers.percentGrowth[0]?.distributionMethod;

                const isDriverInPendingAdd = state.pendingUpdates.add.percentGrowth.length > 0;

                if (isNoOp || isDriverInPendingAdd) {
                    return [];
                }

                /**
                 * If it's not a no-op and if a % growth driver already exists in pending.update, then update its percentGrowthAdjustmentValue
                 * to preserve any other updates made elsewhere to the % growth driver
                 */
                const driver = drivers[0];
                if (driver) {
                    return [{
                        ...driver,
                        percentGrowthAdjustmentValue,
                    }];
                }

                /**
                 * If it's not a no-op, and if a driver doesn't already exist in pending.update, we check if
                 * the growth base period has changed
                 */
                const originalDriver = state.parsedOriginalFormDrivers.percentGrowth[0];

                if (originalDriver && originalDriver.percentGrowthAdjustmentValue !== percentGrowthAdjustmentValue) {
                    return [{
                        ...originalDriver,
                        percentGrowthAdjustmentValue,
                    }];
                }

                /**
                 * If nothing needs to be done here
                */
                return drivers;
            };

            return {
                ...state,
                parsedFormDrivers: {
                    ...state.parsedFormDrivers,
                    percentGrowth: getPercentGrowthDriver(state.parsedFormDrivers.percentGrowth),
                },
                pendingUpdates: {
                    ...state.pendingUpdates,
                    add: {
                        ...state.pendingUpdates.add,
                        percentGrowth: getPercentGrowthDriverPendingAdd(state.pendingUpdates.add.percentGrowth),
                    },
                    update: {
                        ...state.pendingUpdates.update,
                        percentGrowth: getPercentGrowthDriverPendingUpdate(state.pendingUpdates.update.percentGrowth),
                    },
                },
            };
        }

        case "REMOVE_MONTHLY_AVERAGE_GROWTH_DRIVER": {
            const getMonthlyAverageGrowthDriverRemove = (drivers: TPendingDriverGrowthMetric[]) => {
                const driver = drivers[0];

                /**
                 * If a driver already exists in pending.remove, then ignore other removals since they're probably coming from
                 * a newly added % growth driver - in that case this would be an overall no-op
                 */
                if (driver) {
                    return drivers;
                }

                /**
                 * If a % growth driver wasn't originally in the Fx bar, then the user must be removing a newly added % growth driver
                 * In that case this would be an overall no-op
                 */
                if (state.parsedOriginalFormDrivers.monthlyAverage.length === 0) {
                    return [];
                }

                /**
                 * Add whatever's in the original Fx bar to pending.remove
                 */
                return state.parsedOriginalFormDrivers.monthlyAverage;
            };

            return {
                ...state,
                parsedFormDrivers: {
                    ...state.parsedFormDrivers,
                    monthlyAverage: [],
                },
                pendingUpdates: {
                    ...state.pendingUpdates,
                    add: {
                        ...state.pendingUpdates.add,
                        monthlyAverage: [],
                    },
                    remove: {
                        ...state.pendingUpdates.remove,
                        monthlyAverage: getMonthlyAverageGrowthDriverRemove(state.pendingUpdates.remove.monthlyAverage),
                    },
                    update: {
                        ...state.pendingUpdates.update,
                        monthlyAverage: [],
                    },
                },
            };
        }

        case "REMOVE_PERCENT_GROWTH_DRIVER": {
            const getPercentGrowthDriverPendingRemove = (drivers: TPendingDriverGrowthMetric[]) => {
                const driver = drivers[0];

                /**
                 * If a driver already exists in pending.remove, then ignore other removals since they're probably coming from
                 * a newly added % growth driver - in that case this would be an overall no-op
                 */
                if (driver) {
                    return drivers;
                }

                /**
                 * If a % growth driver wasn't originally in the Fx bar, then the user must be removing a newly added % growth driver
                 * In that case this would be an overall no-op
                 */
                if (state.parsedOriginalFormDrivers.percentGrowth.length === 0) {
                    return [];
                }

                /**
                 * Add whatever's in the original Fx bar to pending.remove
                 */
                return state.parsedOriginalFormDrivers.percentGrowth;
            };

            return {
                ...state,
                parsedFormDrivers: {
                    ...state.parsedFormDrivers,
                    percentGrowth: [],
                },
                pendingUpdates: {
                    ...state.pendingUpdates,
                    add: {
                        ...state.pendingUpdates.add,
                        percentGrowth: [],
                    },
                    remove: {
                        ...state.pendingUpdates.remove,
                        percentGrowth: getPercentGrowthDriverPendingRemove(state.pendingUpdates.remove.percentGrowth),
                    },
                    update: {
                        ...state.pendingUpdates.update,
                        percentGrowth: [],
                    },
                },
            };
        }

        case "UPDATE_PERCENT_GROWTH_DRIVER_DISTRIBUTION": {
            const { distributionMethod } = action;

            const getPercentGrowthDriver = (drivers: TDriverGrowthMetric[]) => {
                const driver = drivers[0];

                if (driver?.id) {
                    return [{
                        ...driver,
                        distributionMethod: distributionMethod,
                    }];
                }

                return drivers;
            };

            const getPercentGrowthDriverAdd = (drivers: TPendingDriverGrowthMetric[]) => {
                const driver = drivers[0];

                /**
                 * If a % growth driver already exists in pending.add, then the user's trying to update that
                */
                if (driver) {
                    return [{
                        ...driver,
                        distributionMethod,
                    }];
                }

                /**
                 * Else the user must be updating an existing driver, so skip
                */
                return drivers;
            };

            const getPercentGrowthDriverUpdate = (drivers: TPendingDriverGrowthMetric[]) => {
                /**
                 * To check if this is a no-op, we check if all 3 editable items of the % growth driver are the same
                 * because we don't want to rm the driver from pending.update if the other 2 items are in a dirty state
                 */
                const isNoOp = state.parsedOriginalFormDrivers.percentGrowth.length > 0
                    && state.parsedOriginalFormDrivers.percentGrowth[0]?.percentGrowthBasePeriod === state.parsedFormDrivers.percentGrowth[0]?.percentGrowthBasePeriod
                    && state.parsedOriginalFormDrivers.percentGrowth[0]?.percentGrowthAdjustmentValue === state.parsedFormDrivers.percentGrowth[0]?.percentGrowthAdjustmentValue
                    && state.parsedOriginalFormDrivers.percentGrowth[0]?.distributionMethod === distributionMethod;

                const isDriverInPendingAdd = state.pendingUpdates.add.percentGrowth.length > 0;

                if (isNoOp || isDriverInPendingAdd) {
                    return [];
                }

                /**
                 * If it's not a no-op and if a % growth driver already exists in pending.update, then update its distributionMethod
                 * to preserve any other updates made elsewhere to the % growth driver
                 */
                const driver = drivers[0];
                if (driver) {
                    return [{
                        ...driver,
                        distributionMethod,
                    }];
                }

                /**
                 * If it's not a no-op, and if a driver doesn't already exist in pending.update, we check if
                 * the growth base period has changed
                 */
                const originalDriver = state.parsedOriginalFormDrivers.percentGrowth[0];

                if (originalDriver && originalDriver.distributionMethod !== distributionMethod) {
                    return [{
                        ...originalDriver,
                        distributionMethod,
                    }];
                }

                /**
                 * If nothing needs to be done here
                */
                return drivers;
            };

            return {
                ...state,
                parsedFormDrivers: {
                    ...state.parsedFormDrivers,
                    percentGrowth: getPercentGrowthDriver(state.parsedFormDrivers.percentGrowth),
                },
                pendingUpdates: {
                    ...state.pendingUpdates,
                    add: {
                        ...state.pendingUpdates.add,
                        percentGrowth: getPercentGrowthDriverAdd(state.pendingUpdates.add.percentGrowth),
                    },
                    update: {
                        ...state.pendingUpdates.update,
                        percentGrowth: getPercentGrowthDriverUpdate(state.pendingUpdates.update.percentGrowth),
                    },
                },
            };
        }

        case "ADD_ANNUAL_TARGET_VALUE_GROWTH_DRIVER": {
            /**
             * Create a new annual target amount driver.
             * These are the fields we need to define one, with their default values
             */
            const defaultAnnualTargetValueDriver: (TDriverGrowthMetric | TPendingDriverGrowthMetric)[] = [{
                id: GrowthCalcMethod.AnnualTargetValue,
                growthCalcMethod: GrowthCalcMethod.AnnualTargetValue,
                annualTargetValueManualEntry: 0,
                distributionMethod: DistributionMethod.Flat,
            }];

            const getAnnualTargetValueDriver = (): TDriverGrowthMetric[] => {
                const originalDriver = state.parsedOriginalFormDrivers.annualTargetValue[0];
                const dirtyDriver = state.parsedFormDrivers.annualTargetValue[0];

                /**
                 * If the newly added annual target driver is the same as a default one,
                 */
                if (originalDriver
                    && !dirtyDriver
                    && originalDriver.growthCalcMethod === GrowthCalcMethod.AnnualTargetValue
                    && originalDriver.annualTargetValueManualEntry === 0
                    && originalDriver.distributionMethod === DistributionMethod.Flat) {
                        return [originalDriver];
                }

                return defaultAnnualTargetValueDriver;
            };

            const getAnnualTargetValueDriverAdd = (): TPendingDriverGrowthMetric[] => {
                const originalDriver = state.parsedOriginalFormDrivers.annualTargetValue[0];

                /**
                 * If the newly added annual target driver is the same as a default one,
                 */
                if (originalDriver) {
                    return [];
                }

                return defaultAnnualTargetValueDriver;
            };

            const getAnnualTargetValueDriverRemove = (drivers: TPendingDriverGrowthMetric[]) => {
                const doesPendingRmHaveAnnualTrgtDriver = state.pendingUpdates.remove.annualTargetValue[0]?.growthCalcMethod === GrowthCalcMethod.AnnualTargetValue;

                if (doesPendingRmHaveAnnualTrgtDriver) {
                    return [];
                }

                return drivers;
            };

            const getAnnualTargetValueDriverUpdate = (drivers: TPendingDriverGrowthMetric[]) => {
                // If an existing driver was deleted, and if the user added the same driver type back, then this is an update if not a no-op
                const originalDriver = state.parsedOriginalFormDrivers.annualTargetValue[0];
                const deletedDriver = state.pendingUpdates.remove.annualTargetValue[0];

                const newDriver = defaultAnnualTargetValueDriver[0];

                const isNoOp = originalDriver
                    && deletedDriver
                    && originalDriver.annualTargetValueManualEntry === newDriver?.annualTargetValueManualEntry
                    && originalDriver.distributionMethod === newDriver?.distributionMethod;

                if (isNoOp) {
                    return [];
                }

                if (originalDriver && deletedDriver && newDriver) {
                    return [{
                        ...newDriver,
                        id: originalDriver.id,

                    }];
                }

                return drivers;
            };

            return {
                ...state,
                parsedFormDrivers: {
                    ...state.parsedFormDrivers,
                    annualTargetValue: getAnnualTargetValueDriver(),
                },
                pendingUpdates: {
                    ...state.pendingUpdates,
                    add: {
                        ...state.pendingUpdates.add,
                        annualTargetValue: getAnnualTargetValueDriverAdd(),
                    },
                    remove: {
                        ...state.pendingUpdates.remove,
                        annualTargetValue: getAnnualTargetValueDriverRemove(state.pendingUpdates.remove.annualTargetValue),
                    },
                    update: {
                        ...state.pendingUpdates.update,
                        annualTargetValue: getAnnualTargetValueDriverUpdate(state.pendingUpdates.update.annualTargetValue),
                    }
                }
            };
        }

        case "UPDATE_ANNUAL_TARGET_VALUE_DRIVER_MANUAL_ENTRY": {
            const { annualTargetValue: annualTargetValueManualEntry } = action;

            const getAnnualTargetValueDriver = (drivers: TDriverGrowthMetric[]) => {
                const driver = drivers[0];

                if (driver?.id) {
                    return [{
                        ...driver,
                        annualTargetValueManualEntry,
                    }];
                }

                return drivers;
            };

            const getAnnualTargetValuePendingAdd = (drivers: TPendingDriverGrowthMetric[]) => {
                const driver = drivers[0];

                /**
                 * If an annual target value growth driver already exists in pending.add, then the user's trying to update that
                */
                if (driver) {
                    /**
                     * To check if this is a no-op, we check if all 2 editable items of the annual target value growth driver are the same
                     * because we don't want to rm the driver from pending.update if the other item is in a dirty state
                     */
                    const isNoOp = state.parsedOriginalFormDrivers.annualTargetValue.length > 0
                        && state.parsedOriginalFormDrivers.annualTargetValue[0]?.annualTargetValueManualEntry === annualTargetValueManualEntry
                        && state.parsedOriginalFormDrivers.annualTargetValue[0]?.distributionMethod === state.parsedFormDrivers.annualTargetValue[0]?.distributionMethod;

                    if (!isNoOp) {
                        return [{
                            ...driver,
                            annualTargetValueManualEntry,
                        }];
                    }
                }

                /**
                 * Else the user must be updating an existing driver, so skip
                */
                return drivers;
            };

            const getAnnualTargetValuePendingUpdate = (drivers: TPendingDriverGrowthMetric[]) => {
                /**
                 * To check if this is a no-op, we check if all 2 editable items of the annual target value growth driver are the same
                 * because we don't want to rm the driver from pending.update if the other item is in a dirty state
                 */
                const isNoOp = state.parsedOriginalFormDrivers.annualTargetValue.length > 0
                    && state.parsedOriginalFormDrivers.annualTargetValue[0]?.annualTargetValueManualEntry === annualTargetValueManualEntry
                    && state.parsedOriginalFormDrivers.annualTargetValue[0]?.distributionMethod === state.parsedFormDrivers.annualTargetValue[0]?.distributionMethod;

                const isDriverInPendingAdd = state.pendingUpdates.add.annualTargetValue.length > 0;

                if (isNoOp || isDriverInPendingAdd) {
                    return [];
                }

                /**
                 * If an annual target value growth driver already exists in pending.update, then update its annualTargetValue
                 * to preserve any other updates made elsewhere to the annual target value growth driver
                 */
                const driver = drivers[0];
                if (driver) {
                    return [{
                        ...driver,
                        annualTargetValueManualEntry,
                    }];
                }

                /**
                 * If it's not a no-op, and if a driver doesn't already exist in pending.update, we check if
                 * the annualTargetValueManualEntry has changed
                 */
                const originalDriver = state.parsedOriginalFormDrivers.annualTargetValue[0];

                if (originalDriver && originalDriver.annualTargetValueManualEntry !== annualTargetValueManualEntry) {
                    return [{
                        ...originalDriver,
                        annualTargetValueManualEntry,
                    }];
                }

                /**
                 * If nothing needs to be done here
                */
                return drivers;
            };

            return {
                ...state,
                parsedFormDrivers: {
                    ...state.parsedFormDrivers,
                    annualTargetValue: getAnnualTargetValueDriver(state.parsedFormDrivers.annualTargetValue),
                },
                pendingUpdates: {
                    ...state.pendingUpdates,
                    add: {
                        ...state.pendingUpdates.add,
                        annualTargetValue: getAnnualTargetValuePendingAdd(state.pendingUpdates.add.annualTargetValue),
                    },
                    update: {
                        ...state.pendingUpdates.update,
                        annualTargetValue: getAnnualTargetValuePendingUpdate(state.pendingUpdates.update.annualTargetValue),
                    },
                },
            };
        }

        case "UPDATE_ANNUAL_TARGET_VALUE_DISTRIBUTION": {
            const { distributionMethod } = action;

            const getAnnualTargetValueDriver = (drivers: TDriverGrowthMetric[]) => {
                const driver = drivers[0];

                if (driver?.id) {
                    return [{
                        ...driver,
                        distributionMethod: distributionMethod,
                    }];
                }

                return drivers;
            };

            const getAnnualTargetValueDriverAdd = (drivers: TPendingDriverGrowthMetric[]) => {
                const driver = drivers[0];

                /**
                 * If an annual target value growth driver already exists in pending.add, then the user's trying to update that
                */
                if (driver) {
                    /**
                     * To check if this is a no-op, we check if all 2 editable items of the annual target value growth driver are the same
                     * because we don't want to rm the driver from pending.update if the other item is in a dirty state
                     */
                    const isNoOp = state.parsedOriginalFormDrivers.annualTargetValue.length > 0
                        && state.parsedOriginalFormDrivers.annualTargetValue[0]?.annualTargetValueManualEntry === state.parsedFormDrivers.annualTargetValue[0]?.annualTargetValueManualEntry
                        && state.parsedOriginalFormDrivers.annualTargetValue[0]?.distributionMethod === distributionMethod;

                    if (!isNoOp) {
                        return [{
                            ...driver,
                            distributionMethod,
                        }];
                    }
                }

                /**
                 * Else the user must be updating an existing driver, so skip
                */
                return drivers;
            };

            const getAnnualTargetValueDriverUpdate = (drivers: TPendingDriverGrowthMetric[]) => {
                /**
                 * To check if this is a no-op, we check if all 2 editable items of the annual target value growth driver are the same
                 * because we don't want to rm the driver from pending.update if the other item is in a dirty state
                 */
                const isNoOp = state.parsedOriginalFormDrivers.annualTargetValue.length > 0
                    && state.parsedOriginalFormDrivers.annualTargetValue[0]?.annualTargetValueManualEntry === state.parsedFormDrivers.annualTargetValue[0]?.annualTargetValueManualEntry
                    && state.parsedOriginalFormDrivers.annualTargetValue[0]?.distributionMethod === distributionMethod;

                const isDriverInPendingAdd = state.pendingUpdates.add.annualTargetValue.length > 0;

                if (isNoOp || isDriverInPendingAdd) {
                    return [];
                }

                /**
                 * If an annual target value growth driver already exists in pending.update, then update its distributionMethod
                 * to preserve any other updates made elsewhere to the annual target value growth driver
                 */
                const driver = drivers[0];
                if (driver) {
                    return [{
                        ...driver,
                        distributionMethod,
                    }];
                }

                /**
                 * If it's not a no-op, and if a driver doesn't already exist in pending.update, we check if
                 * the distribution method has changed
                 */
                const originalDriver = state.parsedOriginalFormDrivers.annualTargetValue[0];

                if (originalDriver && originalDriver.distributionMethod !== distributionMethod) {
                    return [{
                        ...originalDriver,
                        distributionMethod,
                    }];
                }

                /**
                 * If nothing needs to be done here
                */
                return drivers;
            };

            return {
                ...state,
                parsedFormDrivers: {
                    ...state.parsedFormDrivers,
                    annualTargetValue: getAnnualTargetValueDriver(state.parsedFormDrivers.annualTargetValue),
                },
                pendingUpdates: {
                    ...state.pendingUpdates,
                    add: {
                        ...state.pendingUpdates.add,
                        annualTargetValue: getAnnualTargetValueDriverAdd(state.pendingUpdates.add.annualTargetValue),
                    },
                    update: {
                        ...state.pendingUpdates.update,
                        annualTargetValue: getAnnualTargetValueDriverUpdate(state.pendingUpdates.update.annualTargetValue),
                    },
                },
            };
        }

        case "REMOVE_ANNUAL_TARGET_VALUE_DRIVER": {
            const getAnnualTargetAmountRemove = (drivers: TPendingDriverGrowthMetric[]) => {
                const driver = drivers[0];

                /**
                 * If a driver already exists in pending.remove, then ignore other removals since they're probably coming from
                 * a newly added annual target amount driver - in that case this would be an overall no-op
                 */
                if (driver) {
                    return drivers;
                }

                /**
                 * If an annual target amount growth driver wasn't originally in the Fx bar,
                 * then the user must be removing a newly added one.
                 * In that case this would be an overall no-op
                 */
                if (state.parsedOriginalFormDrivers.annualTargetValue.length === 0) {
                    return [];
                }

                /**
                 * Add whatever's in the original Fx bar to pending.remove
                 */
                return state.parsedOriginalFormDrivers.annualTargetValue;
            };

            return {
                ...state,
                parsedFormDrivers: {
                    ...state.parsedFormDrivers,
                    annualTargetValue: [],
                },
                pendingUpdates: {
                    ...state.pendingUpdates,
                    add: {
                        ...state.pendingUpdates.add,
                        annualTargetValue: [],
                    },
                    remove: {
                        ...state.pendingUpdates.remove,
                        annualTargetValue: getAnnualTargetAmountRemove(state.pendingUpdates.remove.annualTargetValue),
                    },
                    update: {
                        ...state.pendingUpdates.update,
                        annualTargetValue: [],
                    },
                },
            };
        }


        case "ADD_MONTHLY_AVERAGE_GROWTH_DRIVER": {
            const { reforecastStartMonthIndex, reforecastYear } = action;

            const getDefaultMonthlyAverageDriver = () => {
                let lookbackPeriodStart = '';
                let lookbackPeriodEnd = '';

                if (reforecastStartMonthIndex === 0) {
                    lookbackPeriodStart = `${reforecastYear - 1}-01-01`;
                    lookbackPeriodEnd = `${reforecastYear - 1}-12-01`;
                } else {
                    lookbackPeriodStart = `${reforecastYear}-01-01`;
                    /**
                     * reforecastStartMonthIndex is 0-indexed and corresponds to the last month of actuals in a 1-indexed system
                     * Hence we keep lookbackPeriodEnd (which is 1-indexed for months) the same as reforecastStartMonthIndex
                     */
                    lookbackPeriodEnd = `${reforecastYear}-${(reforecastStartMonthIndex).toString().padStart(2, '0')}-01`;
                }
                return [{
                    id: GrowthCalcMethod.MonthlyAverage,
                    growthCalcMethod: GrowthCalcMethod.MonthlyAverage,
                    monthlyAverageBasePeriod: MonthlyAverageBasePeriod.CustomLookbackPeriod,
                    monthlyAdjustmentType: MonthlyAdjustmentType.Percent,
                    monthlyAdjustmentValue: 0,
                    lookbackPeriodStart,
                    lookbackPeriodEnd,
                }];
            };

            const getMonthlyAverageDriver = () => {
                const originalDriver = state.parsedOriginalFormDrivers.monthlyAverage[0];

                /**
                 * If the newly added monthly average driver is the same as a default one,
                 */
                if (originalDriver) {
                    return [];
                }

                return getDefaultMonthlyAverageDriver();
            }

            const getMonthlyAverageDriverRemove = (drivers: TPendingDriverGrowthMetric[]) => {
                const doesPendingRmHaveMonthlyAvgDriver = state.pendingUpdates.remove.monthlyAverage[0]?.growthCalcMethod === GrowthCalcMethod.MonthlyAverage;

                if (doesPendingRmHaveMonthlyAvgDriver) {
                    return [];
                }

                return drivers;
            };

            const getMonthlyAverageDriverUpdate = (drivers: TPendingDriverGrowthMetric[]) => {
                // If an existing driver was deleted, and if the user added the same driver type back, then this is an update
                const originalDriver = state.parsedOriginalFormDrivers.monthlyAverage[0];
                const deletedDriver = state.pendingUpdates.remove.monthlyAverage[0];

                const newDriver = getDefaultMonthlyAverageDriver()[0];

                if (originalDriver && deletedDriver && newDriver) {
                    return [{
                        ...newDriver,
                        id: originalDriver.id,

                    }];
                }

                return drivers;
            };

            return {
                ...state,
                parsedFormDrivers: {
                    ...state.parsedFormDrivers,
                    monthlyAverage: getDefaultMonthlyAverageDriver(),
                },
                pendingUpdates: {
                    ...state.pendingUpdates,
                    add: {
                        ...state.pendingUpdates.add,
                        monthlyAverage: getMonthlyAverageDriver(),
                    },
                    remove: {
                        ...state.pendingUpdates.remove,
                        monthlyAverage: getMonthlyAverageDriverRemove(state.pendingUpdates.remove.monthlyAverage),
                    },
                    update: {
                        ...state.pendingUpdates.update,
                        monthlyAverage: getMonthlyAverageDriverUpdate(state.pendingUpdates.update.monthlyAverage),
                    }
                }
            };
        }

        case "UPDATE_MONTHLY_AVERAGE_BASE_PERIOD": {
            const { monthlyAverageBasePeriod } = action;

            const checkIFNoOp = (originalDriver: TDriverGrowthMetric, pendingDriver: TPendingDriverGrowthMetric) => {
                if (originalDriver.monthlyAverageBasePeriod === monthlyAverageBasePeriod
                    && originalDriver.lookbackPeriodStart === pendingDriver.lookbackPeriodStart
                    && originalDriver.lookbackPeriodEnd === pendingDriver.lookbackPeriodEnd
                    && originalDriver.monthlyAdjustmentType === pendingDriver.monthlyAdjustmentType
                    && originalDriver.monthlyAdjustmentValue === pendingDriver.monthlyAdjustmentValue) {
                        return true;
                    }
                    return false;
            };

            const getMonthlyAverageDriver = (drivers: TDriverGrowthMetric[]) => {
                const dirtyDriver = drivers[0];

                if (dirtyDriver) {
                    return [{
                        ...dirtyDriver,
                        monthlyAverageBasePeriod,
                    }];
                }

                // Unlikely
                return drivers;
            };

            const getMonthlyAverageDriverAdd = (drivers: TPendingDriverGrowthMetric[]) => {
                const originalDriver = state.parsedOriginalFormDrivers.monthlyAverage[0];
                const pendingDriver = drivers[0];

                 // If the driver was newly added, simply update the monthlyAverageBasePeriod
                if (originalDriver === undefined && pendingDriver) {
                    return [{
                        ...pendingDriver,
                        monthlyAverageBasePeriod,
                    }];
                }

                const wasDriverRemoved = originalDriver && state.pendingUpdates.remove.monthlyAverage.length > 0;
                if (originalDriver && pendingDriver && wasDriverRemoved) {
                    // Check if it's a no-op. If it is, then rm from add as we're simply restoring a deleted driver
                    if (checkIFNoOp(originalDriver, pendingDriver)) {
                            return [];
                        }
                }

                return drivers;
            };

            const getMonthlyAverageDriverUpdate = (drivers: TPendingDriverGrowthMetric[]) => {
                const originalDriver = state.parsedOriginalFormDrivers.monthlyAverage[0];
                const pendingDriver = drivers[0];

                // If the driver was newly added, simply return as is
                if (originalDriver === undefined) {
                    return drivers;
                }

                // If the driver doesn't already exist in pending.update then create a new entry
                if (pendingDriver === undefined) {
                    return [{
                        ...originalDriver,
                        monthlyAverageBasePeriod,
                    }];
                }

                // If it's a no-op, then remove the driver from pending.update
                if (checkIFNoOp(originalDriver, pendingDriver)) {
                        return [];
                    }

                // Finally, update the entry of the driver in pending.update
                return [{
                    ...pendingDriver,
                    monthlyAverageBasePeriod,
                }];
            };

            return {
                ...state,
                parsedFormDrivers: {
                    ...state.parsedFormDrivers,
                    monthlyAverage: getMonthlyAverageDriver(state.parsedFormDrivers.monthlyAverage),
                },
                pendingUpdates: {
                    ...state.pendingUpdates,
                    add: {
                        ...state.pendingUpdates.add,
                        monthlyAverage: getMonthlyAverageDriverAdd(state.pendingUpdates.add.monthlyAverage),
                    },
                    update: {
                        ...state.pendingUpdates.update,
                        monthlyAverage: getMonthlyAverageDriverUpdate(state.pendingUpdates.update.monthlyAverage),
                    },
                }
            };
        }

        case "UPDATE_MONTHLY_AVERAGE_START_MONTH_YEAR": {
            const { lookbackPeriodStart } = action;

            const checkIFNoOp = (originalDriver: TDriverGrowthMetric, pendingDriver: TPendingDriverGrowthMetric) => {
                if (originalDriver.monthlyAverageBasePeriod === pendingDriver.monthlyAverageBasePeriod
                    && originalDriver.lookbackPeriodStart === lookbackPeriodStart
                    && originalDriver.lookbackPeriodEnd === pendingDriver.lookbackPeriodEnd
                    && originalDriver.monthlyAdjustmentType === pendingDriver.monthlyAdjustmentType
                    && originalDriver.monthlyAdjustmentValue === pendingDriver.monthlyAdjustmentValue) {
                        return true;
                    }
                    return false;
            };

            const getMonthlyAverageDriver = (drivers: TDriverGrowthMetric[]) => {
                const dirtyDriver = drivers[0];

                if (dirtyDriver) {
                    return [{
                        ...dirtyDriver,
                        lookbackPeriodStart,
                    }];
                }

                // Unlikely
                return drivers;
            };

            const getMonthlyAverageDriverAdd = (drivers: TPendingDriverGrowthMetric[]) => {
                const originalDriver = state.parsedOriginalFormDrivers.monthlyAverage[0];
                const pendingDriver = drivers[0];

                 // If the driver was newly added, simply update the lookbackPeriodStart
                if (originalDriver === undefined && pendingDriver) {
                    return [{
                        ...pendingDriver,
                        lookbackPeriodStart,
                    }];
                }

                const wasDriverRemoved = originalDriver && state.pendingUpdates.remove.monthlyAverage.length > 0;
                if (originalDriver && pendingDriver && wasDriverRemoved) {
                    // Check if it's a no-op. If it is, then rm from add as we're simply restoring a deleted driver
                    if (checkIFNoOp(originalDriver, pendingDriver)) {
                            return [];
                        }
                }

                return drivers;
            };

            const getMonthlyAverageDriverUpdate = (drivers: TPendingDriverGrowthMetric[]) => {
                const originalDriver = state.parsedOriginalFormDrivers.monthlyAverage[0];
                const pendingDriver = drivers[0];

                // If the driver was newly added, simply return as is
                if (originalDriver === undefined) {
                    return drivers;
                }

                // If the driver doesn't already exist in pending.update then create a new entry
                if (pendingDriver === undefined) {
                    return [{
                        ...originalDriver,
                        lookbackPeriodStart,
                    }];
                }

                // If it's a no-op, then remove the driver from pending.update
                if (checkIFNoOp(originalDriver, pendingDriver)) {
                        return [];
                    }

                // Finally, update the entry of the driver in pending.update
                return [{
                    ...pendingDriver,
                    lookbackPeriodStart,
                }];
            };

            return {
                ...state,
                parsedFormDrivers: {
                    ...state.parsedFormDrivers,
                    monthlyAverage: getMonthlyAverageDriver(state.parsedFormDrivers.monthlyAverage),
                },
                pendingUpdates: {
                    ...state.pendingUpdates,
                    add: {
                        ...state.pendingUpdates.add,
                        monthlyAverage: getMonthlyAverageDriverAdd(state.pendingUpdates.add.monthlyAverage),
                    },
                    update: {
                        ...state.pendingUpdates.update,
                        monthlyAverage: getMonthlyAverageDriverUpdate(state.pendingUpdates.update.monthlyAverage),
                    },
                }
            };
        }

        case "UPDATE_MONTHLY_AVERAGE_END_MONTH_YEAR": {
            const { lookbackPeriodEnd } = action;

            const checkIFNoOp = (originalDriver: TDriverGrowthMetric, pendingDriver: TPendingDriverGrowthMetric) => {
                if (originalDriver.monthlyAverageBasePeriod === pendingDriver.monthlyAverageBasePeriod
                    && originalDriver.lookbackPeriodStart === pendingDriver.lookbackPeriodStart
                    && originalDriver.lookbackPeriodEnd === lookbackPeriodEnd
                    && originalDriver.monthlyAdjustmentType === pendingDriver.monthlyAdjustmentType
                    && originalDriver.monthlyAdjustmentValue === pendingDriver.monthlyAdjustmentValue) {
                        return true;
                    }
                    return false;
            };

            const getMonthlyAverageDriver = (drivers: TDriverGrowthMetric[]) => {
                const dirtyDriver = drivers[0];

                if (dirtyDriver) {
                    return [{
                        ...dirtyDriver,
                        lookbackPeriodEnd,
                    }];
                }

                // Unlikely
                return drivers;
            };

            const getMonthlyAverageDriverAdd = (drivers: TPendingDriverGrowthMetric[]) => {
                const originalDriver = state.parsedOriginalFormDrivers.monthlyAverage[0];
                const pendingDriver = drivers[0];

                 // If the driver was newly added, simply update the lookbackPeriodEnd
                if (originalDriver === undefined && pendingDriver) {
                    return [{
                        ...pendingDriver,
                        lookbackPeriodEnd,
                    }];
                }

                const wasDriverRemoved = originalDriver && state.pendingUpdates.remove.monthlyAverage.length > 0;
                if (originalDriver && pendingDriver && wasDriverRemoved) {
                    // Check if it's a no-op. If it is, then rm from add as we're simply restoring a deleted driver
                    if (checkIFNoOp(originalDriver, pendingDriver)) {
                            return [];
                        }
                }

                return drivers;
            };

            const getMonthlyAverageDriverUpdate = (drivers: TPendingDriverGrowthMetric[]) => {
                const originalDriver = state.parsedOriginalFormDrivers.monthlyAverage[0];
                const pendingDriver = drivers[0];

                // If the driver was newly added, simply return as is
                if (originalDriver === undefined) {
                    return drivers;
                }

                // If the driver doesn't already exist in pending.update then create a new entry
                if (pendingDriver === undefined) {
                    return [{
                        ...originalDriver,
                        lookbackPeriodEnd,
                    }];
                }

                // If it's a no-op, then remove the driver from pending.update
                if (checkIFNoOp(originalDriver, pendingDriver)) {
                        return [];
                    }

                // Finally, update the entry of the driver in pending.update
                return [{
                    ...pendingDriver,
                    lookbackPeriodEnd,
                }];
            };

            return {
                ...state,
                parsedFormDrivers: {
                    ...state.parsedFormDrivers,
                    monthlyAverage: getMonthlyAverageDriver(state.parsedFormDrivers.monthlyAverage),
                },
                pendingUpdates: {
                    ...state.pendingUpdates,
                    add: {
                        ...state.pendingUpdates.add,
                        monthlyAverage: getMonthlyAverageDriverAdd(state.pendingUpdates.add.monthlyAverage),
                    },
                    update: {
                        ...state.pendingUpdates.update,
                        monthlyAverage: getMonthlyAverageDriverUpdate(state.pendingUpdates.update.monthlyAverage),
                    },
                }
            };
        }

        case "UPDATE_MONTHLY_ADJUSTMENT_TYPE": {
            const { monthlyAdjustmentType } = action;

            const checkIFNoOp = (originalDriver: TDriverGrowthMetric, pendingDriver: TPendingDriverGrowthMetric) => {
                if (originalDriver.monthlyAverageBasePeriod === pendingDriver.monthlyAverageBasePeriod
                    && originalDriver.lookbackPeriodStart === pendingDriver.lookbackPeriodStart
                    && originalDriver.lookbackPeriodEnd === pendingDriver.lookbackPeriodEnd
                    && originalDriver.monthlyAdjustmentType === monthlyAdjustmentType
                    && originalDriver.monthlyAdjustmentValue === pendingDriver.monthlyAdjustmentValue) {
                        return true;
                    }
                    return false;
            };

            const getMonthlyAverageDriver = (drivers: TDriverGrowthMetric[]) => {
                const dirtyDriver = drivers[0];

                if (dirtyDriver) {
                    return [{
                        ...dirtyDriver,
                        monthlyAdjustmentType,
                    }];
                }

                // Unlikely
                return drivers;
            };

            const getMonthlyAverageDriverAdd = (drivers: TPendingDriverGrowthMetric[]) => {
                const originalDriver = state.parsedOriginalFormDrivers.monthlyAverage[0];
                const pendingDriver = drivers[0];

                 // If the driver was newly added, simply update the monthlyAdjustmentType
                if (originalDriver === undefined && pendingDriver) {
                    return [{
                        ...pendingDriver,
                        monthlyAdjustmentType,
                    }];
                }

                const wasDriverRemoved = originalDriver && state.pendingUpdates.remove.monthlyAverage.length > 0;
                if (originalDriver && pendingDriver && wasDriverRemoved) {
                    // Check if it's a no-op. If it is, then rm from add as we're simply restoring a deleted driver
                    if (checkIFNoOp(originalDriver, pendingDriver)) {
                            return [];
                        }
                }

                return drivers;
            };

            const getMonthlyAverageDriverUpdate = (drivers: TPendingDriverGrowthMetric[]) => {
                const originalDriver = state.parsedOriginalFormDrivers.monthlyAverage[0];
                const pendingDriver = drivers[0];

                // If the driver was newly added, simply return as is
                if (originalDriver === undefined) {
                    return drivers;
                }

                // If the driver doesn't already exist in pending.update then create a new entry
                if (pendingDriver === undefined) {
                    return [{
                        ...originalDriver,
                        monthlyAdjustmentType,
                    }];
                }

                // If it's a no-op, then remove the driver from pending.update
                if (checkIFNoOp(originalDriver, pendingDriver)) {
                        return [];
                    }

                // Finally, update the entry of the driver in pending.update
                return [{
                    ...pendingDriver,
                    monthlyAdjustmentType,
                }];
            };

            return {
                ...state,
                parsedFormDrivers: {
                    ...state.parsedFormDrivers,
                    monthlyAverage: getMonthlyAverageDriver(state.parsedFormDrivers.monthlyAverage),
                },
                pendingUpdates: {
                    ...state.pendingUpdates,
                    add: {
                        ...state.pendingUpdates.add,
                        monthlyAverage: getMonthlyAverageDriverAdd(state.pendingUpdates.add.monthlyAverage),
                    },
                    update: {
                        ...state.pendingUpdates.update,
                        monthlyAverage: getMonthlyAverageDriverUpdate(state.pendingUpdates.update.monthlyAverage),
                    },
                }
            };
        }

        case "UPDATE_MONTHLY_ADJUSTMENT_VALUE": {
            const { monthlyAdjustmentValue } = action;

            const checkIFNoOp = (originalDriver: TDriverGrowthMetric, pendingDriver: TPendingDriverGrowthMetric) => {
                if (originalDriver.monthlyAverageBasePeriod === pendingDriver.monthlyAverageBasePeriod
                    && originalDriver.lookbackPeriodStart === pendingDriver.lookbackPeriodStart
                    && originalDriver.lookbackPeriodEnd === pendingDriver.lookbackPeriodEnd
                    && originalDriver.monthlyAdjustmentType === pendingDriver.monthlyAdjustmentType
                    && originalDriver.monthlyAdjustmentValue === monthlyAdjustmentValue) {
                        return true;
                    }
                    return false;
            };

            const getMonthlyAverageDriver = (drivers: TDriverGrowthMetric[]) => {
                const dirtyDriver = drivers[0];

                if (dirtyDriver) {
                    return [{
                        ...dirtyDriver,
                        monthlyAdjustmentValue,
                    }];
                }

                // Unlikely
                return drivers;
            };

            const getMonthlyAverageDriverAdd = (drivers: TPendingDriverGrowthMetric[]) => {
                const originalDriver = state.parsedOriginalFormDrivers.monthlyAverage[0];
                const pendingDriver = drivers[0];

                 // If the driver was newly added, simply update the monthlyAdjustmentValue
                if (originalDriver === undefined && pendingDriver) {
                    return [{
                        ...pendingDriver,
                        monthlyAdjustmentValue,
                    }];
                }

                const wasDriverRemoved = originalDriver && state.pendingUpdates.remove.monthlyAverage.length > 0;
                if (originalDriver && pendingDriver && wasDriverRemoved) {
                    // Check if it's a no-op. If it is, then rm from add as we're simply restoring a deleted driver
                    if (checkIFNoOp(originalDriver, pendingDriver)) {
                            return [];
                        }
                }

                return drivers;
            };

            const getMonthlyAverageDriverUpdate = (drivers: TPendingDriverGrowthMetric[]) => {
                const originalDriver = state.parsedOriginalFormDrivers.monthlyAverage[0];
                const pendingDriver = drivers[0];

                // If the driver was newly added, simply return as is
                if (originalDriver === undefined) {
                    return drivers;
                }

                // If the driver doesn't already exist in pending.update then create a new entry
                if (pendingDriver === undefined) {
                    return [{
                        ...originalDriver,
                        monthlyAdjustmentValue,
                    }];
                }

                // If it's a no-op, then remove the driver from pending.update
                if (checkIFNoOp(originalDriver, pendingDriver)) {
                        return [];
                    }

                // Finally, update the entry of the driver in pending.update
                return [{
                    ...pendingDriver,
                    monthlyAdjustmentValue,
                }];
            };

            return {
                ...state,
                parsedFormDrivers: {
                    ...state.parsedFormDrivers,
                    monthlyAverage: getMonthlyAverageDriver(state.parsedFormDrivers.monthlyAverage),
                },
                pendingUpdates: {
                    ...state.pendingUpdates,
                    add: {
                        ...state.pendingUpdates.add,
                        monthlyAverage: getMonthlyAverageDriverAdd(state.pendingUpdates.add.monthlyAverage),
                    },
                    update: {
                        ...state.pendingUpdates.update,
                        monthlyAverage: getMonthlyAverageDriverUpdate(state.pendingUpdates.update.monthlyAverage),
                    },
                }
            };
        }

        case "REMOVE_REVENUE_DRIVER": {
            const { revenueType } = action;

            const getAddedDrivers = (revenueDrivers: TDriverRevenueMetric[]) => {
                if (revenueDrivers.find((driver) => driver.revenueType === revenueType)) {
                    return [];
                }
                return revenueDrivers;
            };

            const getRemovedDrivers = (revenueDrivers: TDriverRevenueMetric[]) => {
                const getRemovedDriver = state.parsedFormDrivers.revenue.find((driver) => driver.revenueType === revenueType);

                const reconstructRevenuePayload = (inputPayload: TDriverRevenueMetric) => ({
                    sourceId: inputPayload.sourceId,
                    revenueType: inputPayload.revenueType,
                });

                const originalRevenueDrivers = state.parsedOriginalFormDrivers.revenue;

                if (originalRevenueDrivers.find((driver) => driver.revenueType === revenueType)) {
                    return [...(getRemovedDriver ? [reconstructRevenuePayload(getRemovedDriver)] : [])];
                }

                return revenueDrivers;
            };

            return {
                ...state,
                parsedFormDrivers: {
                    ...state.parsedFormDrivers,
                    /**
                     * Only ONE revenue driver can exist in a formula bar,
                     * so "removing" a revenue driver is as good as emptying this array
                     */
                    revenue: [],
                },
                pendingUpdates: {
                    ...state.pendingUpdates,
                    add: {
                        ...state.pendingUpdates.add,
                        revenue: getAddedDrivers(state.pendingUpdates.add.revenue),
                    },
                    remove: {
                        ...state.pendingUpdates.remove,
                        revenue: getRemovedDrivers(state.pendingUpdates.remove.revenue),
                    },
                },
            };
        }

        case "UPDATE_REVENUE_DRIVERS": {
            const { updatePayload } = action;

            const getAddedDrivers = () => {
                const reconstructRevenuePayload = (inputPayload: TDriverRevenueMetric) => ({
                    sourceId: inputPayload.sourceId,
                    revenueType: inputPayload.revenueType,
                });

                if (state.parsedOriginalFormDrivers.revenue.find((driver) => driver.revenueType === updatePayload.revenueType)) {
                    return [];
                }
                return [reconstructRevenuePayload(updatePayload)];
            };

            const getRemovedDrivers = (revenueDrivers: TDriverRevenueMetric[]) => {
                if (revenueDrivers.find((driver) => driver.revenueType === updatePayload.revenueType)) {
                    return [];
                }
                return revenueDrivers;
            };

            return {
                ...state,
                parsedFormDrivers: {
                    ...state.parsedFormDrivers,
                    revenue: [updatePayload],
                },
                pendingUpdates: {
                    ...state.pendingUpdates,
                    add: {
                        ...state.pendingUpdates.add,
                        revenue: getAddedDrivers(),
                    },
                    remove: {
                        ...state.pendingUpdates.remove,
                        revenue: getRemovedDrivers(state.pendingUpdates.remove.revenue),
                    }
                },
            };
        }

        case "SET_PARSED_ORIGINAL_FORM_DRIVER_METRICS": {
            const { parsedOriginalFormDrivers } = action;
            return {
                ...state,
                isUserRequestingWorksheetDeletion: false,
                parsedOriginalFormDrivers,
                // Updating the original data will always update the data that drives the form
                parsedFormDrivers: parsedOriginalFormDrivers,
                pendingUpdates: INITIAL_STATE.pendingUpdates,
                isReady: true,
            };
        }

        case "SET_LINE_ITEMS_MENU_OPTIONS": {
            const { updatedOptions } = action;

            return {
                ...state,
                options: updatedOptions,
            };
        }

        case "SET_LINE_ITEMS_MENU_SELECTED_OPTIONS": {
            const { updatedOptions } = action;

            return {
                ...state,
                selectedOptions: updatedOptions,
            };
        }

        case "REMOVE_ACCOUNT_DRIVER": {
            const { accountId } = action;

            const getPendingRemove = (prevPendingRemoveDrivers: TPendingDriverAccountMetric[]) => {
                const accountInOriginalFxBar = state.parsedOriginalFormDrivers.account.find((driver) => driver.accountId === accountId);

                const getNewlyRemovedAccount = (driver: TDriverAccountMetric) => ({
                    accountId: driver.accountId,
                    lookbackPeriod: driver.lookbackPeriod || 0,
                });

                if (accountInOriginalFxBar) {
                    return [ ...prevPendingRemoveDrivers, getNewlyRemovedAccount(accountInOriginalFxBar)];
                } else {
                    return prevPendingRemoveDrivers;
                }
            };

            return {
                ...state,
                parsedFormDrivers: {
                    ...state.parsedFormDrivers,
                    account: state.parsedFormDrivers.account.filter((driver) => driver.accountId !== accountId),
                },
                pendingUpdates: {
                    add: {
                        ...state.pendingUpdates.add,
                        account: state.pendingUpdates.add.account.filter((driver) => driver.accountId !== accountId),
                    },
                    remove: {
                        ...state.pendingUpdates.remove,
                        account: getPendingRemove(state.pendingUpdates.remove.account),
                    },
                    update: {
                        ...state.pendingUpdates.update,
                        account: state.pendingUpdates.update.account.filter((driver) => driver.accountId !== accountId),
                    },
                },
            };
        }

        case "UPDATE_ACCOUNT_DRIVERS": {
            const { accountId, glName, glNumber } = action;

            const getNewlyAddedAccount = (): TDriverAccountMetric => ({
                accountId,
                assumptionId: undefined,
                acctPercentageDriverAccountId: "",
                glNumber,
                glName,
                percentage: new Array(12).fill(0),
                lookbackPeriod: 0,
                values: new Array(12).fill(0),
                locked: false,
            });

            const getUpdatedAccountDrivers = (prevAccountDrivers: TDriverAccountMetric[]) => {
                // If account was previously in the formula bar dirty state, then it means it was un-selected
                if (prevAccountDrivers.find((driver) => driver.accountId === accountId)) {
                    return prevAccountDrivers.filter((driver) => driver.accountId !== accountId);
                }

                const accountInOriginalFxBar = state.parsedOriginalFormDrivers.account.find((driver) => driver.accountId === accountId);

                if (accountInOriginalFxBar) {
                    return [ ...prevAccountDrivers, accountInOriginalFxBar];
                } else {
                    return [ ...prevAccountDrivers, getNewlyAddedAccount()];
                }
            };

            const getPendingAdd = (prevAccountDrivers: TDriverAccountMetric[]): TPendingDriverAccountMetric[] => {
                const updatedParsedDrivers = getUpdatedAccountDrivers(prevAccountDrivers);
                const originalParsedDrivers = state.parsedOriginalFormDrivers.account;

                return updatedParsedDrivers
                    .filter((updatedParsedDriver) => originalParsedDrivers.find((originalParsedDriver) => originalParsedDriver.accountId === updatedParsedDriver.accountId) === undefined)
                    .map((updatedParsedDriver) => ({
                        accountId: updatedParsedDriver.accountId,
                        lookbackPeriod: updatedParsedDriver.lookbackPeriod || 0,
                    }));
            };

            /**
             * If the account exists in the pendingUpdates.update, then it must've been un-selected, simply remove it from there
            */
            const getPendingUpdate = (prevPendingUpdateDrivers: TPendingDriverAccountMetric[]): TPendingDriverAccountMetric[] => {
                if (prevPendingUpdateDrivers) {
                    return prevPendingUpdateDrivers
                        .filter((driver) => driver.accountId !== accountId);
                }
                return prevPendingUpdateDrivers;
            };

            const getPendingRemove = (prevAccountDrivers: TDriverAccountMetric[]): TPendingDriverAccountMetric[] => {
                const updatedParsedDrivers = getUpdatedAccountDrivers(prevAccountDrivers);
                const originalParsedDrivers = state.parsedOriginalFormDrivers.account;

                return originalParsedDrivers
                    .filter((originalParsedDriver) => updatedParsedDrivers.find((updatedParsedDriver) => updatedParsedDriver.accountId === originalParsedDriver.accountId) === undefined)
                    .map((originalParsedDriver) => ({
                        accountId: originalParsedDriver.accountId,
                        lookbackPeriod: originalParsedDriver.lookbackPeriod || 0,
                    }));
            };

            return {
                ...state,
                parsedFormDrivers: {
                    ...state.parsedFormDrivers,
                    account: getUpdatedAccountDrivers(state.parsedFormDrivers.account),
                },
                pendingUpdates: {
                    ...state.pendingUpdates,
                    add: {
                        ...state.pendingUpdates.add,
                        account: getPendingAdd(state.parsedFormDrivers.account),
                    },
                    remove: {
                        ...state.pendingUpdates.remove,
                        account: getPendingRemove(state.parsedFormDrivers.account),
                    },
                    update: {
                        ...state.pendingUpdates.update,
                        account: getPendingUpdate(state.pendingUpdates.update.account),
                    }
                },
            };
        }

        case "SET_ACCOUNT_MANY_DRIVERS": {
            const {accounts} = action;
            const existingAccountsById = state.parsedOriginalFormDrivers.account.toIdMap("accountId");
            const removed = state.parsedOriginalFormDrivers.account
                .filter(it => !accounts.find(acc => acc.accountId == it.accountId))
                .map(it => ({
                    accountId: it.accountId,
                    lookbackPeriod: it.lookbackPeriod ?? 0 // no point in having this parameter in removes but have to follow data contract :/
                }));
            const added = accounts.filter(it => !existingAccountsById[it.accountId]).map(it => ({accountId: it.accountId, lookbackPeriod: it.lookbackPeriod}));
            const updated = accounts.filter(it => {
                const acc = existingAccountsById[it.accountId];
                if (!acc || acc.lookbackPeriod == it.lookbackPeriod) {
                    return false;
                }
                return true;
            }).map(it => ({accountId: it.accountId, lookbackPeriod: it.lookbackPeriod}));
            const parsedFormDriversAccount: TDriverAccountMetric[] = [];
            for (const account of accounts) {
                const existingAccount = existingAccountsById[account.accountId];
                if (existingAccount) {
                    parsedFormDriversAccount.push({
                        ...existingAccount,
                        lookbackPeriod: account.lookbackPeriod
                    });
                }
                else {
                    parsedFormDriversAccount.push({
                        accountId: account.accountId,
                        assumptionId: undefined,
                        acctPercentageDriverAccountId: "",
                        glNumber: account.glNumber,
                        glName: account.glName,
                        percentage: new Array(12).fill(0),
                        lookbackPeriod: account.lookbackPeriod,
                        values: new Array(12).fill(0),
                        locked: false,
                    });
                }
            }

            return {
                ...state,
                parsedFormDrivers: {
                    ...state.parsedFormDrivers,
                    account: parsedFormDriversAccount,
                },
                pendingUpdates: {
                    ...state.pendingUpdates,
                    add: {
                        ...state.pendingUpdates.add,
                        account: added,
                    },
                    remove: {
                        ...state.pendingUpdates.remove,
                        account: removed,
                    },
                    update: {
                        ...state.pendingUpdates.update,
                        account: updated,
                    }
                },
            };
        }

        case "SET_ACCOUNT_AUGMENTS": {
            const {minValue, maxValue} = action.augments;
            const {minValue: originalMin, maxValue: originalMax} = state.parsedOriginalFormDrivers.accountPercentageAugment;
            if(state.parsedOriginalFormDrivers.account.length > 0 && state.parsedFormDrivers.account.length === 0) {
                return {
                    ...state,
                    parsedFormDrivers: {
                        ...state.parsedFormDrivers,
                        accountPercentageAugment: {
                            minValue: null,
                            maxValue: null
                        }
                    },
                    pendingUpdates: {
                        ...state.pendingUpdates,
                        add: {
                            ...state.pendingUpdates.add,
                            accountPercentageAugment: null
                        },
                        update: {
                            ...state.pendingUpdates.update,
                            accountPercentageAugment: null
                        },
                        remove: {
                            ...state.pendingUpdates.remove,
                            accountPercentageAugment: {
                                minValue: null,
                                maxValue: null
                            }
                        }
                    }
                };
            } else if(minValue === originalMin && maxValue === originalMax) {
                // Remove any pending updates
                return {
                    ...state,
                    parsedFormDrivers: {
                        ...state.parsedFormDrivers,
                        accountPercentageAugment: {
                            minValue: originalMin,
                            maxValue: originalMax
                        }
                    },
                    pendingUpdates: {
                        ...state.pendingUpdates,
                        add: {
                            ...state.pendingUpdates.add,
                            accountPercentageAugment: null
                        },
                        update: {
                            ...state.pendingUpdates.update,
                            accountPercentageAugment: null
                        },
                        remove: {
                            ...state.pendingUpdates.remove,
                            accountPercentageAugment: null
                        }
                    }
                };
            } else if(minValue === null && maxValue === null) {
                // We are deleting the value.
                let removeValue = null;
                if(originalMin !== null || originalMax !== null) {
                    removeValue = {
                        minValue: null,
                        maxValue: null
                    };
                }
                return {
                    ...state,
                    parsedFormDrivers: {
                        ...state.parsedFormDrivers,
                        accountPercentageAugment: {
                            minValue: null,
                            maxValue: null
                        }
                    },
                    pendingUpdates: {
                        ...state.pendingUpdates,
                        add: {
                            ...state.pendingUpdates.add,
                            accountPercentageAugment: null
                        },
                        update: {
                            ...state.pendingUpdates.update,
                            accountPercentageAugment: null
                        },
                        remove: {
                            ...state.pendingUpdates.remove,
                            accountPercentageAugment: removeValue
                        }
                    }
                };
            } else {
                if(originalMin === null && originalMax === null) {
                    // We will be creating a value.
                    return {
                        ...state,
                        parsedFormDrivers: {
                            ...state.parsedFormDrivers,
                            accountPercentageAugment: {
                                minValue: minValue,
                                maxValue: maxValue
                            }
                        },
                        pendingUpdates: {
                            ...state.pendingUpdates,
                            add: {
                                ...state.pendingUpdates.add,
                                accountPercentageAugment: {
                                    minValue: minValue,
                                    maxValue: maxValue
                                }
                            },
                            update: {
                                ...state.pendingUpdates.update,
                                accountPercentageAugment: null
                            },
                            remove: {
                                ...state.pendingUpdates.remove,
                                accountPercentageAugment: null
                            }
                        }
                    };
                } else {
                    // We're updating an existing value.
                    return {
                        ...state,
                        parsedFormDrivers: {
                            ...state.parsedFormDrivers,
                            accountPercentageAugment: {
                                minValue: minValue,
                                maxValue: maxValue
                            }
                        },
                        pendingUpdates: {
                            ...state.pendingUpdates,
                            add: {
                                ...state.pendingUpdates.add,
                                accountPercentageAugment: null
                            },
                            update: {
                                ...state.pendingUpdates.update,
                                accountPercentageAugment: {
                                    minValue: minValue,
                                    maxValue: maxValue
                                }
                            },
                            remove: {
                                ...state.pendingUpdates.remove,
                                accountPercentageAugment: null
                            }
                        }
                    };
                }
            }
            break;
        }

        case "UPDATE_ACCOUNT_LOOKBACK_PERIOD_BY_ACCOUNT_ID": {
            const { accountId, lookbackPeriod } = action;

            const lookbackPeriodNumber = lookbackPeriodMap.indexOf(lookbackPeriod);

            const getUpdatedAccountDrivers = (accountDrivers: TDriverAccountMetric[]) => accountDrivers.map((accountDriver: TDriverAccountMetric) => {
                if (accountDriver.accountId === accountId) {
                    return {
                        ...accountDriver,
                        lookbackPeriod: lookbackPeriodNumber,
                    };
                }
                return accountDriver;
            });

            const getPendingAdd = (accountDrivers: TPendingDriverAccountMetric[]) => {
                if (accountDrivers) {
                    return accountDrivers.map((accountDriver) => {
                        if (accountDriver.accountId === accountId) {
                            return {
                                ...accountDriver,
                                lookbackPeriod: lookbackPeriodNumber,
                            };
                        }
                        return accountDriver;
                    });
                }
                return accountDrivers;
            };

            const getPendingUpdate = (accountDrivers: TPendingDriverAccountMetric[]) => {
                const originalLookbackPeriod = state.parsedOriginalFormDrivers.account.find((driver) => driver.accountId === accountId)?.lookbackPeriod;

                if (accountDrivers.find((driver) => driver.accountId === accountId)) {
                    /**
                     * If the newly select lookback period is the same as the original,
                     * then remove this account from pendingUpdates.update
                     */
                    if (originalLookbackPeriod === lookbackPeriodNumber) {
                        return accountDrivers.filter((driver) => driver.accountId !== accountId);
                    }

                    return accountDrivers.map((driver) => {
                        if (driver.accountId === accountId) {
                            return {
                                ...driver,
                                lookbackPeriod: lookbackPeriodNumber,
                            };
                        }
                        return driver;
                    });
                }

                return [
                    ...accountDrivers,
                    { accountId, lookbackPeriod: lookbackPeriodNumber },
                ];

            };

            return {
                ...state,
                parsedFormDrivers: {
                    ...state.parsedFormDrivers,
                    "account": getUpdatedAccountDrivers(state.parsedFormDrivers["account"]),
                },
                pendingUpdates: {
                    ...state.pendingUpdates,
                    add: {
                        ...state.pendingUpdates.add,
                        account: getPendingAdd(state.pendingUpdates.add.account),
                    },
                    update: {
                        ...state.pendingUpdates.update,
                        account: getPendingUpdate(state.pendingUpdates.update.account),
                    },
                },
            };
        }

        case "UPDATE_OPERATIONAL_LOOKBACK_PERIOD_BY_METRIC_TYPE": {
            const { operationalMetricType, lookbackPeriod } = action;

            // Convert the lookbackPeriod text from one of "[same month, ...n months]" to n
            const lookbackPeriodNumber = lookbackPeriodMap.indexOf(lookbackPeriod);

            const getUpdatedAccountDrivers = (opDrivers: TDriverOperationalMetric[]) => opDrivers.map((opDriver: TDriverOperationalMetric) => {
                // Simply update the lookbackPeriod for the op driver that's being edited
                if (opDriver.type === operationalMetricType) {
                    return {
                        ...opDriver,
                        lookbackPeriod: lookbackPeriodNumber,
                    };
                }
                return opDriver;
            });

            const getPendingAdd = (opDrivers: TPendingDriverOperationalMetric[]) => {
                if (opDrivers) {
                    return opDrivers.map((opDriver) => {
                        if (opDriver.type === operationalMetricType) {
                            /**
                             * If the op driver being edited is a newly added one i.e. it if it exists in opDrivers,
                             * then update it's lookbackPeriod in pendingUpdates.add
                            */
                            return {
                                ...opDriver,
                                lookbackPeriod: lookbackPeriodNumber,
                            };
                        }
                        return opDriver;
                    });
                }
                return opDrivers;
            };

            const getPendingUpdate = (opDrivers: TPendingDriverOperationalMetric[]) => {
                const originalOpDriver = state.parsedOriginalFormDrivers.operational.find((driver) => driver.type === operationalMetricType);

                if (originalOpDriver) {
                    const originalLookbackPeriod = originalOpDriver.lookbackPeriod;

                    if (opDrivers.find((driver) => driver.type === operationalMetricType)) {
                        /**
                         * If the newly selected lookback period is the same as the original,
                         * then remove this operational driver from pendingUpdates.update since this is a no-op
                         */
                        if (originalLookbackPeriod === lookbackPeriodNumber) {
                            return opDrivers.filter((driver) => driver.type !== operationalMetricType);
                        }

                        /**
                         * If the newly selected lookback period is different from the original,
                         * and if an entry for the current op driver already exists in pendingUpdates.update,
                         * then update that in pendingUpdates.update
                        */
                        return opDrivers.map((driver) => {
                            if (driver.type === operationalMetricType) {
                                return {
                                    ...driver,
                                    lookbackPeriod: lookbackPeriodNumber,
                                };
                            }
                            return driver;
                        });
                    }

                    /**
                     * If the current op driver doesn't exist in pendingUpdates.update, then create an entry for it
                    */
                    return [
                        ...opDrivers,
                        {
                            // These are the only 4 keys we need to update an op driver in the backend
                            driverMetricTypeId: originalOpDriver.driverMetricTypeId,
                            sourceMetricId: originalOpDriver.sourceMetricId,
                            type: operationalMetricType,
                            lookbackPeriod: lookbackPeriodNumber,
                        },
                    ];
                }

                return opDrivers;
            };

            return {
                ...state,
                parsedFormDrivers: {
                    ...state.parsedFormDrivers,
                    operational: getUpdatedAccountDrivers(state.parsedFormDrivers["operational"]),
                },
                pendingUpdates: {
                    ...state.pendingUpdates,
                    add: {
                        ...state.pendingUpdates.add,
                        operational: getPendingAdd(state.pendingUpdates.add.operational),
                    },
                    update: {
                        ...state.pendingUpdates.update,
                        operational: getPendingUpdate(state.pendingUpdates.update.operational),
                    },
                },
            };
        }

        case "REMOVE_LINE_ITEMS": {
            return {
                ...state,
                parsedFormDrivers: {
                    ...state.parsedFormDrivers,
                    worksheet: [],
                },
                pendingUpdates: {
                    add: {
                        ...state.pendingUpdates.add,
                        worksheet: [],
                    },
                    update: {
                        ...state.pendingUpdates.update,
                        worksheet: [],
                    },
                    remove: {
                        ...state.pendingUpdates.remove,
                        worksheet: state.parsedOriginalFormDrivers.worksheet.map((driver) => ({ description: driver.description })),
                    },
                },
            };
        }

        // Only mark a worksheet as deleted if it actually exists in the database
        case "DELETE_WORKSHEET": {
            const { isWorksheetDriven } = action;

            return {
                ...state,
                isUserRequestingWorksheetDeletion: isWorksheetDriven,
            };
        }

        case "UPDATE_LINE_ITEMS": {
            const { actionType, updatedValue, updatedValues } = action;

            // If a newly created line item was removed, then handle it here:
            const getNewLineItemObj = (description: string): TDriverWorksheetMetric => ({
                description,
                values: new Array(12).fill(null),
            });

            const getUpdatedWorksheetData = (prevWorksheetData: TDriverWorksheetMetric[]) => {
                if (updatedValues.length === 0) {
                    return [];
                }

                if (actionType === "deselect-option") {
                    return prevWorksheetData.filter((item) => item.description !== updatedValue);
                }

                if (actionType === "select-option") {
                    const itemInOriginalFxBar = state.parsedOriginalFormDrivers.worksheet.find((item) => item.description === updatedValue);

                    // This preserves the "values" array of the original line item
                    if (itemInOriginalFxBar !== undefined) {
                        return [ ...prevWorksheetData, itemInOriginalFxBar];
                    } else {
                        return [ ...prevWorksheetData, getNewLineItemObj(updatedValue)];
                    }
                }

                if (actionType === "create-option") {
                    return [ ...prevWorksheetData, getNewLineItemObj(updatedValue)];
                }
                return [];
            };

            const getPendingAdd = (prevWorksheetData: TDriverWorksheetMetric[]) => {
                const updatedParsedDrivers = getUpdatedWorksheetData(prevWorksheetData);
                const originalParsedDrivers = state.parsedOriginalFormDrivers.worksheet;

                return updatedParsedDrivers
                    .filter((updatedParsedDriver) => originalParsedDrivers.find((originalParsedDriver) => originalParsedDriver.description === updatedParsedDriver.description) === undefined)
                    .map((updatedParsedDriver) => ({ description: updatedParsedDriver.description }));
            };

            const getPendingRemove = (prevWorksheetData: TDriverWorksheetMetric[]) => {
                const updatedParsedDrivers = getUpdatedWorksheetData(prevWorksheetData);
                const originalParsedDrivers = state.parsedOriginalFormDrivers.worksheet;

                return originalParsedDrivers
                    .filter((originalParsedDriver) => updatedParsedDrivers.find((updatedParsedDriver) => updatedParsedDriver.description === originalParsedDriver.description) === undefined)
                    .map((originalParsedDriver) => ({ description: originalParsedDriver.description }));
            };

            return {
                ...state,
                isUserRequestingWorksheetDeletion: false,
                parsedFormDrivers: {
                    ...state.parsedFormDrivers,
                    worksheet: getUpdatedWorksheetData(state.parsedFormDrivers.worksheet),
                },
                pendingUpdates: {
                    ...state.pendingUpdates,
                    add: {
                        ...state.pendingUpdates.add,
                        worksheet: getPendingAdd(state.parsedFormDrivers.worksheet),
                    },
                    remove: {
                        ...state.pendingUpdates.remove,
                        worksheet: getPendingRemove(state.parsedFormDrivers.worksheet),
                    },
                },
            };
        }

        case "REMOVE_PAYROLL_DRIVER": {
            const { compId, itemType } = action;

            const getUpdatedDrivers = (payrollDrivers: TDriverPayrollMetric[]) =>
                payrollDrivers.filter((driver) => driver.itemType !== itemType);

            const getPendingAddAndUpdate = (payrollDrivers: TPendingDriverPayrollMetric[]) =>
            payrollDrivers.filter((driver) => driver.itemType !== itemType);

            const getPendingRemove = (payrollDrivers: TPendingDriverPayrollMetric[]) => {
                const matchingCompItemInOriginalState = state.parsedOriginalFormDrivers.payroll.find((driver) => driver.itemType === itemType);

                if (matchingCompItemInOriginalState) {
                    return [
                        ...payrollDrivers,
                        {
                            compId,
                            itemType,
                            positions: matchingCompItemInOriginalState.positions.map((position) => ({
                                id: position.id,
                                name: position.name,
                            })),
                        },
                    ];
                }

                return payrollDrivers;
            };

            return {
                ...state,
                parsedFormDrivers: {
                    ...state.parsedFormDrivers,
                    payroll: getUpdatedDrivers(state.parsedFormDrivers.payroll),
                },
                pendingUpdates: {
                    add: {
                        ...state.pendingUpdates.add,
                        payroll: getPendingAddAndUpdate(state.pendingUpdates.add.payroll),
                    },
                    remove: {
                        ...state.pendingUpdates.remove,
                        payroll: getPendingRemove(state.pendingUpdates.remove.payroll),
                    },
                    update: {
                        ...state.pendingUpdates.update,
                        payroll: getPendingAddAndUpdate(state.pendingUpdates.update.payroll),
                    },
                },
            };
        }

        case "UPDATE_PAYROLL_DRIVERS": {
            const { compId, itemType, positions } = action;

            const getPendingAdd = (prevPayrollDrivers: TPendingDriverPayrollMetric[]) => {
                if (positions.length === 0) {
                    return prevPayrollDrivers.filter((driver) => driver.itemType !== itemType);
                }

                const matchingCompItemInDirtyState = state.parsedFormDrivers.payroll.find((driver) => driver.itemType === itemType);
                const matchingCompItemInOriginalState = state.parsedOriginalFormDrivers.payroll.find((driver) => driver.itemType === itemType);

                if (matchingCompItemInOriginalState) {
                    return prevPayrollDrivers;
                }

                if (matchingCompItemInDirtyState) {
                    return prevPayrollDrivers.map((driver) => {
                        if (driver.itemType === itemType) {
                            return {
                                ...driver,
                                positions,
                            };
                        }
                        return driver;
                    });
                }

                return [
                    ...prevPayrollDrivers,
                    { compId, itemType, positions },
                ];
            };

            const getPendingRemove = (prevPayrollDrivers: TPendingDriverPayrollMetric[]) => {
                if (positions.length > 0) {
                    return prevPayrollDrivers.filter((driver) => driver.itemType !== itemType);
                }

                const matchingCompItemInOriginalState = state.parsedOriginalFormDrivers.payroll.find((driver) => driver.itemType === itemType);

                if (matchingCompItemInOriginalState) {
                    return [
                        ...prevPayrollDrivers,
                        {
                            compId,
                            itemType,
                            positions: matchingCompItemInOriginalState.positions.map((position) => ({
                                id: position.id,
                                name: position.name,
                            }))
                        },
                    ];
                }

                return prevPayrollDrivers;
            };

            const getPendingUpdate = (prevPayrollDrivers: TPendingDriverPayrollMetric[]) => {
                if (positions.length === 0) {
                    return prevPayrollDrivers.filter((driver) => driver.itemType !== itemType);
                }

                const matchingCompItemInOriginalState = state.parsedOriginalFormDrivers.payroll.find((driver) => driver.itemType === itemType);

                const isNoOp = () => {
                    return positions.filter((position) => matchingCompItemInOriginalState?.positions.find((originalPosition) => originalPosition.id === position.id) === undefined).length === 0;
                };

                if (matchingCompItemInOriginalState) {
                    if (positions.length === matchingCompItemInOriginalState.positions.length && isNoOp()) {
                        return prevPayrollDrivers.filter((driver) => driver.itemType !== itemType);
                    }

                    const updatedPayrollDriversByItemType = prevPayrollDrivers.toIdMap("itemType");
                    updatedPayrollDriversByItemType[itemType] = {compId, itemType, positions};
                    return Object.values(updatedPayrollDriversByItemType);
                }

                return prevPayrollDrivers;
            };

            const getUpdatedPayrollDrivers = (prevPayrollDrivers: TDriverPayrollMetric[]) => {
                if (positions.length === 0) {
                    return prevPayrollDrivers.filter((driver) => driver.itemType !== itemType);
                }

                const matchingCompItemInDirtyState = prevPayrollDrivers.find((driver) => driver.itemType === itemType);
                // Changes are being made to a dirty state comp item
                if (matchingCompItemInDirtyState) {
                    return prevPayrollDrivers.map((driver) => {
                        if (driver.itemType === itemType) {
                            return ({
                                ...driver,
                                positions,
                            });
                        }
                        return driver;
                    });
                }

                // A new comp item is being added to the dirty state
                return [
                    ...prevPayrollDrivers,
                    { compId, itemType, positions },
                ];
            };

            return {
                ...state,
                parsedFormDrivers: {
                    ...state.parsedFormDrivers,
                    payroll: getUpdatedPayrollDrivers(state.parsedFormDrivers.payroll),
                },
                pendingUpdates: {
                    add: {
                        ...state.pendingUpdates.add,
                        payroll: getPendingAdd(state.pendingUpdates.add.payroll),
                    },
                    remove: {
                        ...state.pendingUpdates.remove,
                        payroll: getPendingRemove(state.pendingUpdates.remove.payroll),
                    },
                    update: {
                        ...state.pendingUpdates.update,
                        payroll: getPendingUpdate(state.pendingUpdates.update.payroll),
                    },
                },
            };
        }

        case "RESET_PARSED_FORM_DRIVER_METRICS": {
            const { parsedOriginalFormDrivers } = action;
            return {
                ...state,
                parsedFormDrivers: parsedOriginalFormDrivers,
            };
        }

        case "RESET_PENDING_UPDATES": {
            return {
                ...state,
                isUserRequestingWorksheetDeletion: INITIAL_STATE.isUserRequestingWorksheetDeletion,
                pendingUpdates: INITIAL_STATE.pendingUpdates,
            };
        }

        default:
            return state;
    }
}
