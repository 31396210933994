import { FinancialEntity } from "../../../../../contexts/chartofaccounts/ChartOfAccountsContext";
import { Property } from "../../../../../contexts/properties/PropertiesContext";
import { Config } from "../../../../analyst/config/useConfig";
import {
    FinancialEntityType,
    FinancialValueO,
    GetFinancialValuesQuery
} from "../../../../../__generated__/generated_types";
import { FinancialsConfig as CFG } from "../../FinancialsConfig";
import { TwoYearsConfig as TWO_YR_CFG } from "../../../../../sjs/layout/two-years/helpers/TwoYearsConfig";
import { TwoYearsTableData } from "../../../../../sjs/layout/two-years/helpers/types";
import TwoYearsLayout, { AddedFinancialEntityTypes } from "../../../../../sjs/layout/two-years/TwoYearsLayout";
import { TwoYearsColId } from "../../../../../sjs/layout/two-years/helpers/enums";
import { DirectChildRow, FinancialEntityMetaData } from "./financialEntityMetaData";
import { addYearDataArrays } from "../../../../unit-level-modeling/helpers/utils";

export const buildInitialFinancialsData = (
    chartOfAccounts: FinancialEntity[],
    properties: Property[],
    config: Config,
    accountGatherDepthLimit = 1,
): {
    parsedFinancialsData: FinancialsData,
    gatheredCategoryIds: string[],
    gatheredComponentIds: string[],
    gatheredAccountIds: string[],
} => {
    const newFinancialsData: FinancialsData = {
        tableData: [],
        rowMetaData: [],
        idRowMap: {},
    };

    const gatheredCategoryIds: string[] = [];
    const gatheredComponentIds: string[] = [];
    const gatheredAccountIds: string[] = [];

    const processTree = (entityList: FinancialEntity[], depth = 0): void => {
        let gatheredCompOps: string;

        entityList.forEach((entity) => {
            gatheredCompOps = "";
            switch (entity.type) {
                case FinancialEntityType.Category: {
                    gatheredCategoryIds.push(entity.id);
                    break;
                }
                case FinancialEntityType.Component: {
                    gatheredComponentIds.push(entity.id);
                    if(entity.componentOps && entity.componentOps.length > 0){
                        gatheredCompOps = gatheredCompOps ?? '';
                        entity.componentOps.forEach(entry => {
                            if(entry.operation == 'ADD'){
                                gatheredCompOps += `+${entry.sourceComponentId},`;
                            }
                            else if(entry.operation == 'SUB'){
                                gatheredCompOps += `-${entry.sourceComponentId},`;
                            }
                        });
                    }
                    break;
                }
            }

            let newRowIdx = 0;
            let componentStartRow = -1;

            if(depth > 0){
                newRowIdx = newFinancialsData.tableData.push([
                    `${entity.name}`,
                    ...new Array(12).fill(null),
                    ...[null],
                    ...new Array(12).fill(null),
                    ...[null],
                ]);
                newFinancialsData.rowMetaData.push({
                    // loadState: depth <= CFG.STYLE_TO_DEPTH_ON_LOAD ? 'LOADED' : 'UNLOADED',
                    loadState: 'UNLOADED',
                    id: entity.id,
                    name: entity.name,
                    parentId: entity.parentId,
                    type: entity.type,
                    depth: depth,
                    totalOutlineGroupRows: 0,
                    isExpanded: CFG.ROWS_OPEN_ON_LOAD,
                    hasBeenExpanded: CFG.ROWS_OPEN_ON_LOAD,
                    isStyled: depth <= CFG.STYLE_TO_DEPTH_ON_LOAD,
                    directChildRows: [],
                    dataRow: newRowIdx - 1,
                    sheetRow: newRowIdx - 1 + TWO_YR_CFG.FIRST_DATA_ROW,
                });
                newFinancialsData.idRowMap[entity.id] = newRowIdx - 1;

            } else if(entity.type == FinancialEntityType.Component) {
                componentStartRow = newFinancialsData.tableData.length;
            }

            if(entity.children.length > 0){
                processTree(entity.children, depth + 1);
            }

            // Add a row for each property if this is an account
            if(entity.type == 'ACCOUNT'){
                if(depth <= accountGatherDepthLimit){
                    gatheredAccountIds.push(entity.id);
                }

                let propertyRowIdx = 0;
                // Insert rows that'll be populated by properties when/if their categories are opened
                properties.forEach((property) => {
                    propertyRowIdx = newFinancialsData.tableData.push([
                        property.name,
                        ...new Array(12).fill(null),
                        ...[null],
                        ...new Array(12).fill(null),
                        ...[null],
                    ]);

                    const propertyId = `${entity.id}#${property.id}`;
                    const propertyDataRow = propertyRowIdx - 1;
                    const propertySheetRow = propertyRowIdx - 1 + TWO_YR_CFG.FIRST_DATA_ROW;

                    newFinancialsData.rowMetaData.push({
                        loadState: 'UNLOADED',
                        id: propertyId,
                        name: property.name,
                        parentId: entity.id,
                        type: AddedFinancialEntityTypes.Property,
                        depth: depth + 1,
                        totalOutlineGroupRows: 0,
                        reforecastDriverTypes: config.drivers[entity.id + "#" + property.id]?.reforecastDrivers,
                        budgetDriverTypes: config.drivers[entity.id + "#" + property.id]?.budgetDrivers,
                        isExpanded: CFG.ROWS_OPEN_ON_LOAD,
                        isStyled: depth <= CFG.STYLE_TO_DEPTH_ON_LOAD,
                        directChildRows: [],
                        dataRow: propertyDataRow,
                        sheetRow: propertySheetRow,
                    });
                    newFinancialsData.idRowMap[entity.id + "#" + property.id] = propertyDataRow;

                    // Register property with parent account
                    const parentMeta = newFinancialsData.rowMetaData[newRowIdx - 1];
                    parentMeta?.directChildRows.push({
                        id: propertyId,
                        dataRow: propertyDataRow,
                        sheetRow: propertySheetRow,
                    });
                });
                // TODO: END Move to happen when an account is expanded _______________________
            }

            // Summary Row
            const label = depth > 0 ? `${entity.name} Total` : `${entity.name}`;
            const summaryRowIdx = newFinancialsData.tableData.push([
                label,
                ...new Array(12).fill(null),
                ...[null],
                ...new Array(12).fill(null),
                ...[null],
            ]);
            newFinancialsData.rowMetaData.push({
                loadState: depth <= CFG.STYLE_TO_DEPTH_ON_LOAD ? 'LOADED' : 'UNLOADED',
                id: entity.id,
                name: entity.name,
                // parentId: entity.parentId,
                type: entity.type == FinancialEntityType.Component
                    ? AddedFinancialEntityTypes.ComponentSummary
                    : AddedFinancialEntityTypes.CategorySummary,
                depth: depth,
                totalOutlineGroupRows: 0,
                componentOps: gatheredCompOps,
                isExpanded: CFG.ROWS_OPEN_ON_LOAD,
                isStyled: depth <= CFG.STYLE_TO_DEPTH_ON_LOAD,
                directChildRows: [],
                dataRow: newFinancialsData.tableData.length - 1,
                sheetRow: newFinancialsData.tableData.length - 1 + TWO_YR_CFG.FIRST_DATA_ROW,
            });
            if(depth == 0){
                newFinancialsData.idRowMap[entity.id] = newFinancialsData.tableData.length - 1;
            }

            if(depth > 0){
                const newRowMeta = newFinancialsData.rowMetaData[newRowIdx - 1];

                if(newRowMeta){
                    newFinancialsData.rowMetaData[newRowIdx - 1] = {
                        ...newRowMeta,
                        totalOutlineGroupRows: summaryRowIdx - newRowIdx,
                        summaryDataRow: summaryRowIdx - 1,
                        summarySheetRow: summaryRowIdx + TWO_YR_CFG.FIRST_DATA_ROW - 1
                    };
                }

                // Register the new row with its parent row (if it's got one)
                if(newRowMeta?.parentId){
                    const parentRow:number|undefined = newFinancialsData.idRowMap[newRowMeta?.parentId];
                    if(parentRow != undefined){
                        const parentRowMeta = newFinancialsData.rowMetaData[parentRow];

                        // Add the new entity row to the parent row's direct children array
                        // Note: This will only ever be an entity with a summary (e.g. no properties)
                        if(parentRowMeta){
                            const parentDirectChildRows = parentRowMeta.directChildRows ?? [];
                            newFinancialsData.rowMetaData[parentRow] = {
                                ...parentRowMeta,
                                directChildRows: [
                                    ...parentDirectChildRows,
                                    ...[{
                                        id: newRowMeta.id as string,
                                        dataRow: newRowMeta.dataRow,
                                        sheetRow: newRowMeta.sheetRow,
                                    }],
                                ],
                            };
                        }

                    }
                }
            }
            else if(entity.type == FinancialEntityType.Component){
                const componentDirectChildren = newFinancialsData.rowMetaData.slice(componentStartRow, summaryRowIdx).filter( row => row.parentId == entity.id);
                const summaryRowMeta = newFinancialsData.rowMetaData[summaryRowIdx - 1];
                if(summaryRowMeta){
                    newFinancialsData.rowMetaData[summaryRowIdx - 1] = {
                        ...summaryRowMeta,
                        directChildRows: componentDirectChildren.map(child => {
                            return {
                                id: child.id,
                                dataRow: child.dataRow,
                                sheetRow: child.sheetRow,
                            } as DirectChildRow;
                        }),
                    };
                }
            }
        });
    };

    processTree(chartOfAccounts);

    return {
        parsedFinancialsData: newFinancialsData,
        gatheredCategoryIds,
        gatheredComponentIds,
        gatheredAccountIds,
    };
};

/**
 * Returns a record of EntityYear objects (reforecast year values and total, budget year values and total) keyed
 * by entity ID.
 * @param financialValues
 * @param config
 */
export const buildEntitiesYearsData = (financialValues: GetFinancialValuesQuery, config: Config): Record<string, EntityYear>|undefined => {

    const entityYearsData: Record<string, EntityYear> = {};

    type TEntityData =
        { __typename?: 'FinancialValueO' }
        &Pick<FinancialValueO, 'entityId'|'entityType'|'versionId'|'propertyId'|'monthIndex'|'value'|'originalValue'>[];

    for (const [entityKey, entityData] of Object.entries(financialValues.financialValues.financialValues.groupBy(entry => `${entry.entityId}#${entry.versionId}#${entry.monthIndex}`))) {

        const [id, version, monthIdx] = entityKey.split('#');

        if(!id || !version || !monthIdx){
            console.warn('Missing entity information', entityKey);
            return;
        }

        const getEntityYearByKey = (key: string): EntityYear => {
            return entityYearsData[key] ??
                {
                    actual: {
                        monthValues: new Array(12).fill(null),
                        total: 0,
                    },
                    reforecast: {
                        monthValues: new Array(12).fill(null),
                        total: 0,
                    },
                    budget: {
                        monthValues: new Array(12).fill(null),
                        total: 0,
                    },
                    merged: {
                        reforecast: {
                            monthValues: new Array(12).fill(null),
                            total: 0,
                        }
                    }
                };
        };

        const getCurrentYearData = (entityData: TEntityData, entityYear: EntityYear): { actual: YearData, reforecast: YearData, budget: YearData } => {
            const actual = entityYear.actual ?? {
                monthValues: new Array(12).fill(null),
                total: 0,
            };
            const reforecast = entityYear.reforecast ?? {
                monthValues: new Array(12).fill(null),
                total: 0,
            };
            const budget = entityYear.budget ?? {
                monthValues: new Array(12).fill(null),
                total: 0,
            };

            // Put the month value for this entity/version/month in the correct location
            if(version == config.actualVersionId){
                const monthVal = entityData.reduce((prev, monthData) => prev + parseInt(monthData.value), 0);
                actual.monthValues[parseInt(monthIdx)] = monthVal;
            }
            else if(version == config.reforecastVersionId){
                reforecast.monthValues[parseInt(monthIdx)] = entityData.reduce((prev, monthData) => prev + parseInt(monthData.value), 0);
            }
            else if(version == config.nextYearBudgetVersionId){
                budget.monthValues[parseInt(monthIdx)] = entityData.reduce((prev, monthData) => prev + parseInt(monthData.value), 0);
            }

            return {
                actual,
                reforecast,
                budget
            };
        };

        const applyYearDataUpdate = (entityData: EntityYear, actual: YearData, reforecast: YearData, budget: YearData): EntityYear => {
            // Compile summary info for this entity
            return {
                ...entityData,
                actual: {
                    ...entityData.actual,
                    monthValues: actual.monthValues,
                    total: 0,
                },
                reforecast: {
                    ...entityData.reforecast,
                    monthValues: reforecast.monthValues,
                    total: 0,
                },
                budget: {
                    ...entityData.budget,
                    monthValues: budget.monthValues,
                    total: 0,
                },
                merged: {
                    reforecast: {
                        monthValues: [],
                        total: 0,
                    }
                },
            };
        };

        const thisEntityData = getEntityYearByKey(id);
        const { actual, reforecast, budget } = getCurrentYearData(entityData, thisEntityData);
        entityYearsData[id] = applyYearDataUpdate(thisEntityData, actual, reforecast, budget);

        const entityType = entityData[0]?.entityType;
        if(entityType == FinancialEntityType.Account){
            entityData.forEach(entity => {
                const accountPropertyId = `${id}#${entity.propertyId}`;
                const accountPropertyData = getEntityYearByKey(accountPropertyId);
                const filteredEntityDataArr = entityData.filter(thisEntity => thisEntity.propertyId == entity.propertyId);
                const {
                    actual,
                    reforecast,
                    budget
                } = getCurrentYearData(filteredEntityDataArr, accountPropertyData);
                entityYearsData[accountPropertyId] = applyYearDataUpdate(accountPropertyData, actual, reforecast, budget);
            });
        }
    }

    // All month values are now known, so calculate totals. This is an in-place update on the parsed object
    for (const [entityKey, entityData] of Object.entries(entityYearsData)) {
        // Build final value arrays and total column values
        entityData.actual.total = getArrayValuesSum(entityData.actual.monthValues) ?? 0;
        entityData.reforecast.total = getArrayValuesSum(entityData.reforecast.monthValues) ?? 0;
        entityData.budget.total = getArrayValuesSum(entityData.budget.monthValues) ?? 0;

        entityData.merged.reforecast.monthValues = entityData.actual.monthValues.map((entry, idx) => {
            if(idx < config.startReforecastMonthIndex){
                return entry;
            }
            else {
                return entityData.reforecast.monthValues[idx];
            }
        });
        entityData.merged.reforecast.total = getArrayValuesSum(entityData.merged.reforecast.monthValues) ?? 0;
    }

    return entityYearsData;
};

/**
 * Expects a TwoYearsTableData populated with Financial Entity data, as well as it's companion
 * @param tableData
 * @param rowMetaData
 * @param compOpList
 */
export const parseComponentOpList = (
    financialsData: FinancialsData,
    compOpList: string
): (number|null)[] => {
    const { tableData, rowMetaData } = financialsData;

    let composedReforecastYear: (number|null)[] = new Array(12).fill(0);
    let composedBudgetYear: (number|null)[] = new Array(12).fill(0);

    compOpList.split(',').forEach(thisOp => {
        const op = thisOp.slice(0, 1);
        const entityId: string = thisOp.slice(1);

        // Find the related entity row
        let entryRow = -1;
        rowMetaData.find((entry, idx) => {
            if(entry && entry.id == entityId){
                entryRow = idx;
            }
        });

        if(entryRow > -1){ // If the row for this related entry is found...
            const relatedReforecastYear = tableData[entryRow]?.slice(1, 13);
            if(relatedReforecastYear){
                if(op == '+'){
                    composedReforecastYear = addYearDataArrays(composedReforecastYear, relatedReforecastYear);
                }
                else if(op == '-'){
                    composedReforecastYear = addYearDataArrays(composedReforecastYear, relatedReforecastYear, -1);
                }
            }

            const relatedBudgetYear = tableData[entryRow]?.slice(14, 26);
            if(relatedBudgetYear){
                if(op == '+'){
                    composedBudgetYear = addYearDataArrays(composedBudgetYear, relatedBudgetYear);
                }
                else if(op == '-'){
                    composedBudgetYear = addYearDataArrays(composedBudgetYear, relatedBudgetYear, -1);
                }
            }
        }
    });

    // Get component summary totals for reforecast and budget years
    composedReforecastYear.push(
        composedReforecastYear.reduce((acc: number, curr) => {
            if(typeof curr == 'number'){
                return acc + curr;
            }
            else {
                return acc;
            }
        }, 0)
    );
    composedBudgetYear.push(
        composedBudgetYear.reduce((acc: number, curr) => {
            if(typeof curr == 'number'){
                return acc + curr;
            }
            else {
                return acc;
            }
        }, 0));

    return [...composedReforecastYear, ...composedBudgetYear];
};

export const buildTableDataRows = (
    entityData: Record<string, EntityYear>,
    financialsData: FinancialsData,
): {
    updatedTableData: TwoYearsTableData,
    updatedRowMetaData: FinancialEntityMetaData[],
    gatheredSummaryTotals: Record<string, EntityYearArrays>
} => {

    const { tableData, rowMetaData } = financialsData;

    const gatheredSummaryTotals: Record<string, EntityYearArrays> = {};
    const updatedRowMetaData = [...rowMetaData];

    const newData: TwoYearsTableData = tableData.map((rowData, idx) => {

        const rowMeta = rowMetaData[idx];

        const id = rowMetaData[idx]?.id;
        const updatedRow = rowData;

        if(rowMeta && id){
            const parentId = rowMetaData[idx]?.parentId;

            let isComponentSummary = false;

            if(rowMeta.type.includes('SUMMARY')){
                if(rowMeta.componentOps){
                    isComponentSummary = true;
                }
            }

            const thisData = entityData[id];
            if(thisData){
                const rowNumbers: (number|undefined)[] = [
                    ...thisData.merged.reforecast.monthValues,
                    ...[thisData.merged.reforecast.total],
                    ...thisData.budget.monthValues,
                    ...[thisData.budget.total]
                ];

                updatedRow.splice(TwoYearsLayout.TwoYearsColOffset(TwoYearsColId.REFO_MONTH_0), 26, ...rowNumbers);

                updatedRowMetaData[idx] = {
                    ...rowMeta,
                    loadState: 'LOADED',
                };

                // Capture values for parent entities for totals
                if(parentId){
                    const summaryParentData = gatheredSummaryTotals[parentId] ?? {
                        reforecast: [{
                            monthValues: [...thisData.merged.reforecast.monthValues],
                            total: 0,
                        }],
                        budget: [{
                            monthValues: [...thisData.budget.monthValues],
                            total: 0,
                        }],
                    };

                    gatheredSummaryTotals[parentId] = {
                        ...summaryParentData,
                        reforecast: [
                            ...summaryParentData.reforecast,
                            ...[{
                                monthValues: thisData.merged.reforecast.monthValues,
                                total: 0,
                            }]
                        ],
                        budget: [
                            ...summaryParentData.budget,
                            ...[{
                                monthValues: thisData.budget.monthValues,
                                total: 0,
                            }]
                        ],
                    };
                }

            }
            else if(isComponentSummary){
                updatedRow.splice(
                    TwoYearsLayout.TwoYearsColOffset(TwoYearsColId.REFO_MONTH_0),
                    26,
                    ...parseComponentOpList(financialsData, rowMeta.componentOps ?? '') as number[]
                );
            }
        }
        return updatedRow;
    });

    return {
        updatedTableData: newData,
        updatedRowMetaData,
        gatheredSummaryTotals: gatheredSummaryTotals,
    };
};

export const getArrayValuesSum = (thisArr: YearTableData) => {
    const returnArr = thisArr.reduce((prev:number, value) => {
        let numValue = 0;
        switch(typeof value){
            case 'string': {
                if(!isNaN(parseInt(value))){
                    numValue = parseInt(value);
                }
                break;
            }
            case 'number':{
                numValue = value;
            }
        }

        if(numValue){
            return prev + numValue;
        }
        else {
            return prev;
        }
    }, 0);
    return returnArr;
};

export type YearTableData = (string|number|undefined|null)[];

export type FinancialsData = {
    tableData: YearTableData[],
    rowMetaData: FinancialEntityMetaData[],
    idRowMap: Record<string, number>,
}

export type YearData = {
    monthValues: (number|undefined)[],
    total: number,
}

export type EntityYear = {
    actual: YearData,
    reforecast: YearData,
    budget: YearData,
    merged: {
        reforecast: YearData,
    },
}
export type EntityYearArrays = {
    reforecast: YearData[],
    budget: YearData[],
}

export type FinancialEntityLoadState = 'UNLOADED'|'LOADING'|'LOADED';

export type FinancialEntityRequestInfo = {
    type: FinancialEntityType,
    entityIds: string[],
}