import GC from "@grapecity/spread-sheets";

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace AcctRenovationsCfg{
    export const MAIN_SHEET_NAME = 'Sheet1';

    /**
     * First visible row and column, 0-based index
     */
    export const FIRST_VISIBLE_ROW = 2;
    export const FIRST_VISIBLE_COL = 2;

    export const FIRST_DATA_ROW = 4;
    export const FIRST_DATA_COL = 3;

    export const ROW_LABEL_COL_W = 191;
    export const MONTH_COL_W = 73;
    export const TOTALS_COL_W = 109;

    export const DATA_ROW_H = 48;

    export const ROW_LABEL_COLUMN = FIRST_VISIBLE_COL;
    export const TOTALS_COL = ROW_LABEL_COLUMN + 13;
}
