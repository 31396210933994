import "native-injects";
import {useState} from "react";
import {
    useCreateUnitEventMutation,
    CreateUnitEventMutationVariables,
    useUpdateUnitEventMutation,
    UpdateUnitEventMutationVariables,
    useDeleteUnitEventsMutation,
    DeleteUnitEventsMutationVariables
} from "../../../../../../__generated__/generated_types";

type ApiStatus = "INIT" | "IN_PROGRESS" | "COMPLETED"

export default function useCreateUpdateDeleteUnitEvent() {
    const [createApiStatus, setCreateApiStatus] = useState<ApiStatus>("INIT");
    const [updateApiStatus, setUpdateApiStatus] = useState<ApiStatus>("INIT");
    const [deleteApiStatus, setDeleteApiStatus] = useState<ApiStatus>("INIT");

    const [unitEventId, setUnitEventId] = useState<string>("");

    const [createMutation] = useCreateUnitEventMutation({
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        onCompleted(data) {
            // console.info(`create onComplete: data: ${Stringify(data)}`);
            if(data && data.createUnitEvent && data.createUnitEvent.id) {
                setCreateApiStatus("COMPLETED");
                setUnitEventId(data.createUnitEvent.id);
            }
        }
    });

    const createUnitEvent = (params: CreateUnitEventMutationVariables) => {
        // console.info(`createEmployee payload: ${Stringify(params)}`);
        const {
            propertyId, budgetYear, unitId,
            eventType, eventStart, eventEnd,
            concessionMonths,
            inPlaceRent,
            renewalAdjustmentPercentage,
            shortTermEffectiveAvgDailyRent, renovationCost, renovationPremium
        } = params;
        createMutation({
            variables: {
                propertyId,
                budgetYear,
                unitId,
                eventType,
                eventStart,
                eventEnd,
                concessionMonths,
                inPlaceRent,
                renewalAdjustmentPercentage,
                shortTermEffectiveAvgDailyRent,
                renovationCost,
                renovationPremium
            }
        });
        setCreateApiStatus("IN_PROGRESS");
    };

    const [updateMutation] = useUpdateUnitEventMutation({
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        onCompleted(data) {
            // console.info(`update onComplete: data: ${Stringify(data)}`);
            if(data && data.updateUnitEvent && data.updateUnitEvent.id) {
                setUpdateApiStatus("COMPLETED");
            }
        },
    });
    const updateUnitEvent = (params: UpdateUnitEventMutationVariables) => {
        // console.info(`updateEmployee payload: ${Stringify(params)}`);
        const {
            id,
            eventType, eventStart, eventEnd,
            concessionMonths,
            inPlaceRent,
            renewalAdjustmentPercentage,
            shortTermEffectiveAvgDailyRent, renovationCost, renovationPremium
        } = params;
        updateMutation({
            variables: {
                id,
                eventType,
                eventStart,
                eventEnd,
                concessionMonths,
                inPlaceRent,
                renewalAdjustmentPercentage,
                shortTermEffectiveAvgDailyRent,
                renovationCost,
                renovationPremium
            }
        });
        setUpdateApiStatus("IN_PROGRESS");
    };

    const [deleteMutation] = useDeleteUnitEventsMutation({
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        onCompleted(data) {
            // console.info(`delete onComplete: data: ${Stringify(data)}`);
            if(data) {
                setDeleteApiStatus("COMPLETED");
            }
        },
    });

    const deleteUnitEvent = (params: DeleteUnitEventsMutationVariables) => {
        // console.info(`deleteEmployee payload: ${Stringify(params)}`);
        const {id} = params;
        deleteMutation({
            variables: {
                id
            }
        });
        setDeleteApiStatus("IN_PROGRESS");
    };

    return {
        createUnitEvent,
        updateUnitEvent,
        deleteUnitEvent,
        unitEventId,
        createApiStatus,
        updateApiStatus,
        deleteApiStatus,
    };
}
