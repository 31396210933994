import { Body, Close, Footer, FooterItem, Header, Modal } from "@zendeskgarden/react-modals";
import { ReactElement, useEffect, useRef, useState } from "react";
import { Property } from "../../../contexts/properties/PropertiesContext";
import * as modalCss from "./shareModal.module.scss";
import { ThemeProvider } from "@zendeskgarden/react-theming";
import { ViziblyTheme } from "../../analyst/ViziblyZDGTheme";
import { Field, Input, Textarea } from "@zendeskgarden/react-forms";
import { Button } from "@zendeskgarden/react-buttons";
import {
    CreateSharedReportLinksMutationVariables,
    PersonEmailInput,
    useCreateSharedReportLinksMutation,
    useGetSharedReportLinksLazyQuery,
    useRemoveSharedReportLinkMutation,
    useResendSharedReportLinkMutation } from "../../../__generated__/generated_types";
import { toast } from "react-toastify";
import { ReactComponent as UserIcon } from '@zendeskgarden/svg-icons/src/16/user-circle-stroke.svg';
import { ReactComponent as TrashIcon } from '@zendeskgarden/svg-icons/src/16/trash-stroke.svg';
import { ReactComponent as LinkIcon } from '@zendeskgarden/svg-icons/src/16/link-stroke.svg';
import {
    BsThreeDotsVertical as ThreeDotsIcon,
    BsEnvelope  as EnvelopeIcon
 } from "react-icons/bs";
import { Dropdown, Item, Menu, Trigger } from "@zendeskgarden/react-dropdowns";


type IShareModalProps = {
    property: Property,
    onClose: () => void
}

type StagedUserData = {
    email: string | null;
    firstName: string | null;
    lastName: string | null;
}

type StagedUserDataForDisplay = StagedUserData & {
    uniq: number
}

export function ShareModal(props: IShareModalProps): ReactElement {
    const [getSharerReportLinks, {data: sharedReportLinksData, loading: sharedReportLinksLoading}] = useGetSharedReportLinksLazyQuery({fetchPolicy: "no-cache"});
    const [removeSharedReportLink] = useRemoveSharedReportLinkMutation({notifyOnNetworkStatusChange: true});
    const [resendSharedReportLink] = useResendSharedReportLinkMutation({fetchPolicy: "no-cache"});
    const [createSharedReportLinks, {loading: createLinksLoading}] = useCreateSharedReportLinksMutation({notifyOnNetworkStatusChange: true});

    // const [stagedUsers, setStagedUsers] = useState<StagedUserDataForDisplay[]>([{uniq: Date.now(), email: null, firstName: null, lastName: null}]);
    const [stagedUsers, setStagedUsers] = useState<StagedUserDataForDisplay[]>([]);
    const [inviteMessage, setInviteMessage] = useState<string>();
    const [stagedUsersValid, setStagedUsersValid] = useState(false);
    const [showInviteView, setShowInviteView] = useState(false);
    const stagedUsersDivRef = useRef<HTMLDivElement>(null);
    function handleAddBlankStagedUser() {
        setStagedUsers(prev => prev.concat({uniq: Date.now(), email: null, firstName: null, lastName: null}));
        setTimeout(() => {
            const div = stagedUsersDivRef.current;
            if (div) {
                div.scrollTop = div.scrollHeight;
            }
        }, 500);
    }

    function validateStagedUsers(stagedUsers: StagedUserDataForDisplay[]) {
        let stagedUsersValid = stagedUsers.length > 0;
        for (const stagedUser of stagedUsers) {
            stagedUsersValid = stagedUsersValid
            && stagedUser.email !== null
            && stagedUser.firstName !== null
            && stagedUser.lastName !== null;
            if (!stagedUsersValid) {
                break;
            }
        }
        setStagedUsersValid(stagedUsersValid);
    }

    function handleRemoveStagedUser(index: number) {
        if (index >= 0) {
            setStagedUsers(prev => {
                let updated = prev;
                if (prev.length > index) {
                    updated = [...prev];
                    updated.splice(index, 1);
                }
                return updated;
            });
        }
    }

    function handleUpdateStagedUser(
            index: number,
            email: string | null,
            firstName: string | null,
            lastName: string | null) {
        if (index >= 0 && index < stagedUsers.length) {
            const updated = stagedUsers.map((user, i) => {
                let updatedUser = user;
                if (i === index) {
                    updatedUser = {...user};
                    updatedUser.email = email;
                    updatedUser.firstName = firstName;
                    updatedUser.lastName = lastName;
                }
                return updatedUser;
            });
            setStagedUsers(updated);
        }
    }

    function handleInvite() {
        if (stagedUsers.length == 0) {
            return;
        }
        const persons:PersonEmailInput[] = []
        const vars:CreateSharedReportLinksMutationVariables = {
            budgetYear: props.property.budgetYear,
            propertyId: props.property.id,
            persons: persons
        };
        for (const user of stagedUsers) {
            if (user.email === null || user.firstName === null || user.lastName == null) {
                return;
            }
            persons.push({
                email: user.email,
                firstName: user.firstName,
                lastName: user.lastName
            });
        }
        if (inviteMessage && inviteMessage.trim().length != 0) {
            vars.message = inviteMessage;
        }
        createSharedReportLinks({variables: vars})
        .then((data) => {
            const emailsSentList = data.data?.createSharedReportLinks;
            if (emailsSentList) {
                props.onClose();
                toast.success(`Access email sent to ${emailsSentList.join(", ")}`);
            }
        });
    }

    function handleSharedLinkAction(id: string, email: string, link: string, action: SharedLinkAction) {
        if (action === "copy") {
            navigator.clipboard.writeText(link);
            toast.success(`Access link copied to clipboard`);
        }
        else if (action === "remove") {
            removeSharedReportLink({
                variables: {
                    id: id
                }
            })
            .then((data) => {
                const ret = data.data;
                if (ret) {
                    toast.success(`Access removed for ${email}`);
                }
                getSharerReportLinks({
                    variables: {
                        budgetYear: props.property.budgetYear,
                        propertyId: props.property.id
                    }
                });
            });
        }
        else if (action === "resend") {
            resendSharedReportLink({
                variables: {
                    id: id
                }
            })
            .then((data) => {
                const ret = data.data;
                if (ret) {
                    toast.success(`Access email sent to ${email}`);
                }
            });
        }
    }

    useEffect(() => {
        validateStagedUsers(stagedUsers);
    }, [stagedUsers]);

    useEffect(() => {
        getSharerReportLinks({
            variables: {
                budgetYear: props.property.budgetYear,
                propertyId: props.property.id
            }
        });
    },[]);

    return (
        <ThemeProvider theme={ViziblyTheme}>
            <Modal onClose={() => props.onClose()} isLarge style={{ overflow: "unset" }}>
                <Close aria-label="Close modal" />
                <Header>
                    Share Executive Summary ({props.property.name})
                </Header>
                <Body className={modalCss.modalBody}>
                    {!showInviteView &&
                    <>
                        <span className={modalCss.description}>Sharing access will send a login to the recipient using the email you provide. A one-time password will be sent to access Vizibly.</span>
                        <div className={modalCss.stagedUsersContainer} ref={stagedUsersDivRef}>
                            {stagedUsers.map((su, index) =>
                                <StagedUser
                                    key={su.uniq}
                                    data={su}
                                    handleRemove={() => handleRemoveStagedUser(index)}
                                    handleChange={(...args) => handleUpdateStagedUser(index, ...args)}/>
                            )}
                        </div>
                        <Button
                            className={modalCss.addGuestButton}
                            isBasic
                            onClick={() => handleAddBlankStagedUser()}
                        >
                            + Add guest
                        </Button>
                        <span>GUEST ACCESS</span>
                        <div className={modalCss.sharedLinksContainer}>
                            {sharedReportLinksData?.getSharedReportLinks.map(sharedReportLink =>
                                <SharedLink
                                    key={sharedReportLink.id}
                                    email={sharedReportLink.email}
                                    firstName={sharedReportLink.firstName}
                                    lastName={sharedReportLink.lastName}
                                    handleAction={(action) => handleSharedLinkAction(sharedReportLink.id, sharedReportLink.email, sharedReportLink.url, action)}
                                />
                            )}
                        </div>
                    </>
                    }
                    {showInviteView &&
                    <>
                        <div className={modalCss.inviteMailListContainer}>
                            {stagedUsers.map(u => <div className={modalCss.email}>{u.email}</div>)}
                        </div>
                        <Field>
                            <Textarea placeholder="Enter an optional message" value={inviteMessage} onChange={(evt) => setInviteMessage(evt.target.value)}/>
                        </Field>
                    </>
                    }
                </Body>
                <Footer className={modalCss.modalFooter}>
                    <FooterItem>
                        <Button isBasic isDanger onClick={() => props.onClose()}>
                            Cancel
                        </Button>
                    </FooterItem>
                    <FooterItem>
                        {!showInviteView &&
                        <Button isPrimary disabled={!stagedUsersValid} onClick={() => setShowInviteView(true)}>
                            Next
                        </Button>
                        }
                        {showInviteView &&
                        <div className={modalCss.modalFooterRightButtonContainer}>
                            <Button isBasic onClick={() => setShowInviteView(false)}>
                                Back
                            </Button>
                            <Button isPrimary onClick={() => handleInvite()} disabled={createLinksLoading}>
                                Invite
                            </Button>
                        </div>
                        }
                    </FooterItem>
                </Footer>
            </Modal>
        </ThemeProvider>
    );
}

function checkValid(checkValue:string|null, ...otherValues:(string|null)[]): "success"|"error" {
    let ret:"success"|"error" = "success";
    if (!(checkValue && checkValue.trim().length > 0)) {
        for (const otherValue of otherValues) {
            if (otherValue) {
                ret = "error";
                break;
            }
        }
    }
    return ret;
}

function StagedUser(
    props: {
        data: StagedUserData,
        handleChange: (email: string | null, firstName: string | null, lastName: string | null) => void,
        handleRemove: undefined | (() => void)}): ReactElement {
    const [email, setEmail] = useState<string | null>(props.data.email);
    const [firstName, setFirstName] = useState<string | null>(props.data.firstName);
    const [lastName, setLastName] = useState<string | null>(props.data.lastName);

    useEffect(() => {
        props.handleChange(
            (!email || email && email.trim(),length == 0 ? null : email),
            (!firstName || firstName && firstName.trim().length == 0 ? null : firstName),
            (!lastName || lastName && lastName.trim().length == 0 ? null : lastName)
        );
    }, [email, firstName, lastName]);
    return (
        <div className={modalCss.stagedUser}>
            <div className={modalCss.stagedUserHeader}>
                <span>GUEST USER</span>
                <Button isBasic isDanger disabled={!props.handleRemove} onClick={props.handleRemove}>Remove</Button>
            </div>
            <Field>
                <Input
                    placeholder="Invite by email*"
                    value={email ?? undefined}
                    onChange={(evt) => setEmail(evt.target.value)}
                    validation={checkValid(email, firstName, lastName)}
                />
            </Field>
            <div className={modalCss.nameInputs}>
                <Field>
                    <Input
                        placeholder="First Name*"
                        value={firstName ?? undefined}
                        onChange={(evt) => setFirstName(evt.target.value)}
                        validation={checkValid(firstName, email, lastName)}
                    />
                </Field>
                <Field>
                    <Input
                        placeholder="Last Name*"
                        value={lastName ?? undefined}
                        onChange={(evt) => setLastName(evt.target.value)}
                        validation={checkValid(lastName, firstName, email)}
                    />
                </Field>
            </div>
        </div>
    )
}

function SharedLink(props: {
        email: string,
        firstName: string,
        lastName: string,
        handleAction: (action: SharedLinkAction) => void
}): ReactElement {
    function handleAction(action: SharedLinkAction) {
        props.handleAction(action);
    }
    return (
        <div className={modalCss.sharedLink}>
            <UserIcon/>
            <div className={modalCss.person}>
                {(props.firstName.length > 0 || props.lastName.length > 0) && <div className={modalCss.personName}>{props.firstName} {props.lastName}</div>}
                <div className={modalCss.personEmail}>{props.email}</div>
            </div>
            <div className={modalCss.menuButton}>
                <Dropdown
                    onSelect={action => handleAction(action)}
                >
                    <Trigger>
                        <Button isBasic className={modalCss.sharedLinkMenuButton}>
                            <ThreeDotsIcon/>
                        </Button>
                    </Trigger>

                    <Menu>
                        <Item value="resend"><EnvelopeIcon/>&nbsp;Resend email</Item>
                        <Item value="copy"><LinkIcon/>&nbsp;Copy access link</Item>
                        <Item value="remove"><TrashIcon/>&nbsp;Remove access</Item>
                    </Menu>
                </Dropdown>
            </div>
        </div>
    );
}

type SharedLinkAction = "resend"|"copy"|"remove";