import React, {ReactElement} from "react";
import styles from "../AnalystPayrollDrawer.module.scss";
import {Field, Input, Label} from "@zendeskgarden/react-forms";
import {Col, Row} from "@zendeskgarden/react-grid";
import {
    All30Dates, All31Dates,
    AllFebDates,
    AllMonths,
    AllPayrollRaiseFrequencyAndType,
    LocalPayrollEmployeeModel,
    LocalPayrollEmployeeModelKeys,
    LocalPayrollEmployeeRaiseModel
} from "../helpers/types";
import {CustomZDDropdown} from "../../../atoms/custom-zd-dropdown/CustomZDDropdown";
import {PayrollCompensationType, PayrollRaiseFrequency, PayrollRaiseType} from "../../../__generated__/generated_types";
import {Datepicker} from "@zendeskgarden/react-datepickers";
import {DateFormatter} from "../helpers/utils";
import {Button} from "@zendeskgarden/react-buttons";
import {ensureDateInLocale, ensureDateInUtc} from "../date-helpers";

interface RaiseEntryProps {
    index: number,
    raise: LocalPayrollEmployeeRaiseModel,
    employeeData: LocalPayrollEmployeeModel,
    allowRemove: boolean,
    updateFormState: (key: LocalPayrollEmployeeModelKeys, value: any) => void
}

export default function RaiseEntry({index, raise, employeeData, allowRemove, updateFormState}: RaiseEntryProps): ReactElement {

    let allDates = null;
    if (["1", "01"].indexOf(raise.raiseMonth.toString()) !== -1) {
        allDates = AllFebDates;
    } else if(["3", "03", "5", "05", "8", "08", "10"].indexOf(raise.raiseMonth.toString()) !== -1) {
        allDates = All30Dates;
    } else {
        allDates = All31Dates;
    }

    return (
        <>
            <Row justifyContent="between">
                <Col>
                    <Label className={styles.earningsSubHeaders}>Raise {index + 1}</Label>
                </Col>
                {
                    allowRemove && <Button isBasic isDanger onClick={() => {
                        updateFormState(LocalPayrollEmployeeModelKeys.removeRaise, index);
                    }}>Remove</Button>
                }
            </Row>
            <Row justifyContent="between">
                <Col size={12}>
                    <Field className={styles.field}>
                        <Label className={styles.label}>Type</Label>
                        <CustomZDDropdown
                            widthSpec={"100%"}
                            applySelectedItems={(items) => {
                                updateFormState(LocalPayrollEmployeeModelKeys.raiseFrequencyAndType, Array.isArray(items) ? items[0] : items);
                            }}
                            initialSelectedItems={(raise.frequency && raise.raiseType) ? [JSON.stringify([raise.frequency, raise.raiseType])] : [""]}
                            openDropdownPlaceholder="Select Raise Type"
                            closedDropdownPlaceholder=""
                            options={AllPayrollRaiseFrequencyAndType.map(opt => ({
                                value: opt.value,
                                label: opt.label
                            }))}
                            isError={false}
                            onBlur={() => {
                                return undefined;
                            }}
                            noSearchIcon
                        />
                    </Field>
                </Col>
            </Row>
            {
                raise.frequency === PayrollRaiseFrequency.Annually ?
                    <>
                        <Row justifyContent="between">
                            <Col>
                                <Field className={styles.field}>
                                    <Label className={styles.label}>
                                        {
                                            raise.raiseType === PayrollRaiseType.PercentOfSalary ?
                                                <span>Raise <br /> Percentage</span> :
                                                employeeData.baseComp.compensationType == PayrollCompensationType.Hourly ?
                                                    <span>Raise Amount <br /> (per hour)</span> :
                                                    <span>Raise Amount <br /> &nbsp; </span>
                                        }
                                    </Label>
                                    <Input
                                        placeholder={raise.raiseType === PayrollRaiseType.FixedAmount ? "$" : "%"}
                                        value={raise.amount}
                                        type={"number"}
                                        onChange={(evt) => {
                                            updateFormState(
                                                LocalPayrollEmployeeModelKeys.raiseAmount,
                                                evt.target.value);
                                        }}/>
                                </Field>
                            </Col>
                            <Col>
                                <Field className={styles.field}>
                                    <Label className={styles.label}>
                                        <span>Effective <br /> Month</span>
                                    </Label>
                                    <div style={{marginTop: "8px"}}>
                                        <CustomZDDropdown
                                            widthSpec={"100%"}
                                            applySelectedItems={(items) => {
                                                updateFormState(LocalPayrollEmployeeModelKeys.raiseMonth, Array.isArray(items) ? items[0] : items);
                                            }}
                                            initialSelectedItems={raise.raiseMonth ? [raise.raiseMonth.toString()] : [""]}
                                            openDropdownPlaceholder="Select Raise Month"
                                            closedDropdownPlaceholder=""
                                            options={AllMonths.map(opt => ({
                                                value: opt.value,
                                                label: opt.label
                                            }))}
                                            isError={false}
                                            onBlur={() => {
                                                return undefined;
                                            }}
                                            noSearchIcon
                                        />
                                    </div>
                                </Field>
                            </Col>
                            <Col>
                                <Field className={styles.field}>
                                    <Label className={styles.label}>
                                        <span>Effective</span>
                                        <br/>
                                        <span>Day</span>
                                    </Label>
                                    <div style={{marginTop: "8px"}}>
                                        <CustomZDDropdown
                                            widthSpec={"100%"}
                                            applySelectedItems={(items) => {
                                                updateFormState(LocalPayrollEmployeeModelKeys.raiseDate, Array.isArray(items) ? items[0] : items);
                                            }}
                                            initialSelectedItems={raise.raiseDay ? [raise.raiseDay.toString()] : [""]}
                                            openDropdownPlaceholder="Select Raise Day"
                                            closedDropdownPlaceholder=""
                                            options={allDates.map(opt => ({
                                                value: opt.value,
                                                label: opt.label
                                            }))}
                                            isError={false}
                                            onBlur={() => {
                                                return undefined;
                                            }}
                                            noSearchIcon
                                        />
                                    </div>
                                </Field>
                            </Col>
                        </Row>
                    </> :
                    <>
                        <Row justifyContent="between">
                            <Col>
                                <Field className={styles.field}>
                                    <Label className={styles.label}>
                                        {
                                            raise.raiseType === PayrollRaiseType.PercentOfSalary ?
                                                <span>Raise <br /> Percentage</span> :
                                                employeeData.baseComp.compensationType == PayrollCompensationType.Hourly ?
                                                    <span>Raise Amount <br /> (per hour)</span> :
                                                    <span>Raise Amount <br /> &nbsp; </span>
                                    }
                                    </Label>
                                    <Input
                                        placeholder={raise.raiseType === PayrollRaiseType.FixedAmount ? "$" : "%"}
                                        value={raise.amount}
                                        type={"number"}
                                        onChange={(evt) => {
                                            updateFormState(
                                                LocalPayrollEmployeeModelKeys.raiseAmount,
                                                evt.target.value);
                                        }}/>
                                </Field>
                            </Col>
                            <Col>
                                <Field className={styles.field}>
                                    <Label className={styles.label}>
                                        <span>Effective <br /> Date</span>
                                    </Label>
                                    <Datepicker
                                        value={raise.onetimeRaiseDate ? ensureDateInLocale(raise.onetimeRaiseDate) : undefined}
                                        onChange={(selectedStartDate) => {
                                            updateFormState(LocalPayrollEmployeeModelKeys.onetimeRaiseDate, ensureDateInUtc(selectedStartDate)?.toISOString());
                                        }}
                                        formatDate={date => {
                                            return date ? DateFormatter.format(date) : "";
                                        }}
                                    >
                                        <Input/>
                                    </Datepicker>
                                </Field>
                            </Col>
                        </Row>
                    </>
            }
        </>
    );
}

