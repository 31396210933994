export enum DriverType {
    ACC_PERCENTAGE = "ACC_PERCENTAGE",
    WORKSHEET = "WORKSHEET",
    OPERATIONAL = "OPERATIONAL",
    MANUAL_ENTRY = "MANUAL_ENTRY",
    REVENUE = "REVENUE",
    PAYROLL = "PAYROLL"
}

export enum RevenueType {
    RENT = "RENT",
    RENT_SUBSIDY = "RENT_SUBSIDY",
    LOSS_TO_LEASE = "LOSS_TO_LEASE",
    VACANCY = "VACANCY",
    GPR = "GPR",
    GPR_AND_VACANCY = "GPR_AND_VACANCY"
}

export const REVENUE_TYPE_DESCRIPTIONS = {
    [RevenueType.RENT]: "Rent",
    [RevenueType.RENT_SUBSIDY]: "Rent Subsidy",
    [RevenueType.LOSS_TO_LEASE]: "Loss to Lease",
    [RevenueType.VACANCY]: "Vacancy",
    [RevenueType.GPR]: "Gross Potential Rent",
    [RevenueType.GPR_AND_VACANCY]: "Gpr with Vacancy",
};
