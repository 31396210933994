import { ReactElement } from "react";
import * as css from "./styles/styles.module.scss";
import { ReactComponent as LockIcon } from '@zendeskgarden/svg-icons/src/16/lock-locked-stroke.svg';

export interface ILockBannerProps {
    text: string,
    fixed?: boolean,
}

export default function LockBanner({text, fixed}: ILockBannerProps): ReactElement {
    return (
        <div className={`${css.lockBanner} ${fixed && css.lockBannerFixed}`}>
            <LockIcon className={css.lockBannerIcon} />
            <span>{text}</span>
        </div>
    );
}