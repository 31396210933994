import { ReactElement, useEffect, useState } from "react";
import { BulkReportType, useGetFinancialReportsLazyQuery } from "../../__generated__/generated_types";
import BulkExportPageHeader from "./BulkExportPageHeader";
import DownloadableExportsTable, { IDownloadableExportsTableRow } from "./DownloadableExportsTable";
import { getSortedReports, pageDescription } from "./utils";


export default function FinancialReportsBulkExport():ReactElement {
    const [rows, setRows] = useState<IDownloadableExportsTableRow[]>([]);

    const [getFinancialReports, {data: data, loading: loading}] = useGetFinancialReportsLazyQuery({
        fetchPolicy: "no-cache",
    });

    useEffect(() => {
        getFinancialReports();
    }, []);

    useEffect(() => {
        if (loading || !data || !data.financialReports) {
            return;
        }

        const sortedReports = getSortedReports(data.financialReports);
        setRows(sortedReports);
    }, [data, loading]);

    return (
        <div>
            <BulkExportPageHeader pageTitle="Financial Reports" pageDescription={pageDescription} />
            <DownloadableExportsTable reportType={BulkReportType.Financials} rows={rows} />
        </div>
    );
}
