import Handsontable from "handsontable";
import {TAccountTableRowData, TAccountTableSummaryFunction} from "../data/AccountTableData";
import * as css from "../styles/accountTable.module.scss";
import {FinancialValuesYearModel, VersionType} from "../../../__generated__/generated_types";
import {IAccountSummaryData, IAccountSummaryDataYears} from "../../../pages/workflows/account/AccountSummaryContext";

export const hotValidUserActions = ["CopyPaste.paste", "edit", "UndoRedo.undo", "UndoRedo.redo", "Autofill.fill"];

export type TSourceMetricDriverReturn = {
    unitCount?: number,
    percentage: number,
    fee: number,
    result: number,
}

export function numberOrZero(input: any): number {
    if (typeof input == "number") {
        return input;
    }
    let output = input;
    const parsed = Number.parseFloat(input);
    if (!Number.isNaN(parsed)) {
        output = parsed;
    }
    else {
        output = 0;
    }

    return output;
}

export function updateRowSummary(hotInstance: Handsontable, row: number, summaryFunction: TAccountTableSummaryFunction, rfcstStartMonth: number, versionType: VersionType): void {
    const rowValues: (number | null)[] = hotInstance.getDataAtRow(row);
    let summary: number | null = rowValues.slice(1, 13).reduce((x, y) => (numberOrZero(x) ?? 0) + (numberOrZero(y) ?? 0), 0);
    const startMonth = versionType == VersionType.Reforecast ? rfcstStartMonth : 0;

    switch(summaryFunction) {
        case "AVG": {
            if (summary != null) {
                summary = parseFloat((summary / (12 - startMonth)).toFixed(2));
            }
        }
    }
    hotInstance.setDataAtCell(row, 13, summary == null ? null : +(summary.toFixed(2)), "calculation");
}

export function recalculateCustomDriver(hotInstance: Handsontable, destinationRow: number, destinationCol: number

): TSourceMetricDriverReturn & {unitCount: number}{
    const fee = numberOrZero(hotInstance.getDataAtCell(destinationRow + 1, destinationCol));
    const percentage = numberOrZero(hotInstance.getDataAtCell(destinationRow + 2, destinationCol));
    const unitCount = numberOrZero(hotInstance.getDataAtCell(destinationRow + 3, destinationCol));
    const result = +(unitCount * fee == 0 ? 0 : percentage / 100 * unitCount * fee).toFixed();

    hotInstance.setDataAtCell(destinationRow, destinationCol, result == null ? null : Math.round(result), "computedDriver");

    return {
        unitCount,
        percentage,
        fee,
        result,
    };
}

export function recalculateOperationalDriver(hotInstance: Handsontable, destinationRow: number, destinationCol: number): TSourceMetricDriverReturn {
    const fee = numberOrZero(hotInstance.getDataAtCell(destinationRow + 1, destinationCol));
    const percentage = numberOrZero(hotInstance.getDataAtCell(destinationRow + 2, destinationCol));
    const unitCount = numberOrZero(hotInstance.getDataAtCell(destinationRow + 3, destinationCol));
    const result = +(unitCount * fee == 0 ? 0 : percentage / 100 * unitCount * fee).toFixed();

    hotInstance.setDataAtCell(destinationRow, destinationCol, result == null ? null : Math.round(result), "computedDriver");

    return {
        unitCount,
        percentage,
        fee,
        result,
    };
}

export function recalculateAcctPercentageDriver(
    hotInstance: Handsontable,
    destinationRow: number,
    destinationCol: number,
    augments: {minValue: number | null, maxValue: number | null}
): TSourceMetricDriverReturn {
    const percentage = numberOrZero(hotInstance.getDataAtCell(destinationRow + 1, destinationCol));
    const amount = numberOrZero(hotInstance.getDataAtCell(destinationRow + 2, destinationCol));
    let result = +(amount == 0 ? 0 : percentage / 100 * amount).toFixed();

    const minValue = augments.minValue;
    const maxValue = augments.maxValue;

    if(minValue !== null && result < minValue) {
        result = minValue;
    }
    if(maxValue !== null && result > maxValue) {
        result = maxValue;
    }

    hotInstance.setDataAtCell(destinationRow, destinationCol, result == null ? null : Math.round(result), "computedDriver");

    return {
        percentage,
        fee: amount,
        result,
    };
}

export function recalculateAcctPercentageDriverSimple(hotInstance: Handsontable, destinationRow: number, destinationCol: number, data: TAccountTableRowData[]): number {
    const firstRowIndex = data.findIndex(x => x.parentRowId == "multi_pct_of_account");
    const lastRowIndex = data.findIndexRight(x => x.parentRowId == "multi_pct_of_account");

    if (firstRowIndex >= 0 && lastRowIndex >= firstRowIndex) {
        let accum = 0;
        for (let index = firstRowIndex; index <= lastRowIndex; index++) {
            accum += numberOrZero(hotInstance.getDataAtCell(index, destinationCol));
        }
        hotInstance.setDataAtCell(firstRowIndex - 1, destinationCol, accum / (lastRowIndex - firstRowIndex + 1), "computedDriver");
    }

    const percentage = numberOrZero(hotInstance.getDataAtCell(destinationRow, destinationCol));

    return percentage;
}

export function setForceReadOnlyToMultiPctAccountRow(hotInstance: Handsontable, row: number, forceReadonly: boolean): void {
    for (let i = 1; i <= 12; i++) {
        hotInstance.setCellMeta(row, i, "forceReadonly", forceReadonly);
    }
}

export function toggleHiddenRows(hotInstance: Handsontable, data: TAccountTableRowData[], id: string, row: number): boolean {
    const hiddenRows = hotInstance.getPlugin("hiddenRows");
    const childRowIndexes: number[] = [];
    const cell = hotInstance.getCellMeta(row, 0);
    let isVisible;

    for (let i = 0; i < data.length; i++) {
        const rowData = data[i];

        if (rowData && rowData.parentRowId  && rowData.parentRowId == id) {
            childRowIndexes.push(i);
        }
    }

    const alreadyHiddenRows = hiddenRows.getHiddenRows();
    const currentClasses: string = cell.className as string;

    if (childRowIndexes[0] && alreadyHiddenRows.includes(childRowIndexes[0])) {
        hotInstance.setCellMeta(row, 0, "className", `${cell.className} ${css.parentRowVisible}`);
        hiddenRows.showRows(childRowIndexes);
        isVisible = true;
    } else {
        hotInstance.setCellMeta(row, 0, "className", currentClasses.replace(css.parentRowVisible, ""));
        hiddenRows.hideRows(childRowIndexes);
        isVisible = false;
    }

    return isVisible;
}

export function hideAllChildRows(hotInstance: Handsontable, data: TAccountTableRowData[]): void {
    const hiddenRows = hotInstance.getPlugin("hiddenRows");
    const childRowIndexes: number[] = [];
    const alreadyHiddenRows = hiddenRows.getHiddenRows();

    for (let i = 0; i < data.length; i++) {
        const rowData = data[i];

        if (rowData && rowData.parentRowId && !alreadyHiddenRows.includes(i)) {
            childRowIndexes.push(i);
            const cell = hotInstance.getCellMeta(i, 0);
            const currentClasses: string = cell.className as string;
            hotInstance.setCellMeta(i, 0, "className", currentClasses.replace(css.parentRowVisible, ""));
        }
    }

    hiddenRows.hideRows(childRowIndexes);
}

export function buildYearSummary(financialValues: FinancialValuesYearModel[], rfcstStartMonth: number): IAccountSummaryData {
    const ctxData: IAccountSummaryData = {years: {}};

    for (let i = 0; i < financialValues.length; i++) {
        const yearData = financialValues[i];

        if (!yearData) {
            continue;
        }

        const ctxDataForYear = ctxData.years[yearData.year] ?? {
            fcst: [] as number[],
            bdgt: [] as number[],
            act: [] as number[],
        };

        if (!ctxDataForYear) {
            continue;
        }

        for (let y = 0; y < 12; y++) {
            const monthValue = yearData.values[y];

            if (monthValue === undefined) {
                continue;
            }

            switch (yearData.versionType) {
                case VersionType.Reforecast: {
                    if (y >= rfcstStartMonth) {
                        ctxDataForYear.fcst[y] = monthValue;
                    } else {
                        ctxDataForYear.fcst[y] = 0;
                    }
                    break;
                }
                case VersionType.Actuals: {
                    ctxDataForYear.act[y] = monthValue;
                    break;
                }
                case VersionType.Budget: {
                    ctxDataForYear.bdgt[y] = monthValue;
                    break;
                }
            }

            ctxData.years[yearData.year] = ctxDataForYear;
        }
    }

    return ctxData;
}

export function buildGraphSummary(hotInstance: Handsontable, data: TAccountTableRowData[], versionType: VersionType, rfcstStartMonth: number): IAccountSummaryDataYears {
    const summaryRowIndexTest = data.findIndex(x => x.rowType == "versionSummary");

        const summaryRowValues = hotInstance.getDataAtRow(summaryRowIndexTest).slice(1, 13);

        const yearValues = {
            fcst: [] as number[],
            bdgt: [] as number[],
            act: [] as number[],
        };

        for (let i = 0; i < 12; i++) {
            const yearVal = numberOrZero(summaryRowValues[i]);

            if (versionType == VersionType.Budget) {
                yearValues.fcst.push(0);
                yearValues.bdgt.push(yearVal);
                yearValues.act.push(0);
            } else {
                if (i >= rfcstStartMonth) {
                    yearValues.act.push(0);
                    yearValues.fcst.push(yearVal);
                } else {
                    yearValues.act.push(yearVal);
                    yearValues.fcst.push(0);
                }
            }
        }

        return yearValues;
}

export function sanitizeTableInput(value: number | null | undefined | string): string | number {
    if (value == null || value == undefined || value == "") {
        return 0;
    } else if (typeof value === "string") {
        return value.replace(/[%$,]/g, "").trim();
    } else {
        return value;
    }
}
