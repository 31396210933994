import { useConfig } from "../../../../../hooks/useConfig";
import { useEffect, useRef, useState } from "react";
import { useProperties, Property } from "../../../../../contexts/properties/PropertiesContext";
import {
    FinancialEntityType,
    useGetMvrFinanciaValuesWithVarianceNotesLazyQuery,
    useGetMvrVarianceNotesLazyQuery,
    useSetAccountVarianceNoteMutation,
    VersionType
} from "../../../../../__generated__/generated_types";
import { FinancialEntity, useChartOfAccounts } from "../../../../../contexts/chartofaccounts/ChartOfAccountsContext";
import {
    applyVarianceNotesData,
    buildMvrEntityDataMap,
    buildMvrTableData,
    getMockEmptyFinancialEntityYear,
    IFinancialEntityYear,
    MvrTableRow,
} from "./logic/monthlyVarianceReportData";
import { HandledGQLErrors } from "../../../../../utils/errorHandling/HandledGQLErrors";
import { ApolloError } from "@apollo/client";

interface IUseMonthlyVarianceReportDataProps {
    year: number;
    month: number;
    onDataError: () => void;
}

export interface IVarianceNoteUpdateArgs {
    note: string,
    accountId: string,
    propertyId: string,
    year: number,
    monthIndex:number,
    versionType: VersionType,
}

export interface IUseMonthlyVarianceReportDataReturn {
    mvrDataServiceReady: boolean;
    mvrDataLoaded: boolean;
    mvrTableData: MvrTableRow[];
    mvrEntityDataMap?: Record<string, Partial<IFinancialEntityYear>>;
    mvrReportYear: number|undefined;
    mvrReportMonth: number|undefined;
    mvrAccountsReady: boolean,
    mvrAccounts: FinancialEntity[],
    mvrDataErrors: ApolloError[] | undefined;
    setVarianceNote: (updateArgs: IVarianceNoteUpdateArgs) => void,

    loading: boolean,

    setReportDate: (year: number, month: number) => void;
}

export default function useMonthlyVarianceReportData(props?: IUseMonthlyVarianceReportDataProps): IUseMonthlyVarianceReportDataReturn {

    const { chartOfAccounts, chartOfAccountsFlat, isReady: isCoaReady } = useChartOfAccounts();
    const config = useConfig();
    const properties = useProperties();

    const [dependenciesLoaded, setDependenciesLoaded] = useState<boolean>(false);

    const [mvrDataErrors, setMvrDataErrors] = useState<ApolloError[]>();

    const [mvrAccounts, setMvrAccounts] = useState<FinancialEntity[]>([]);
    useEffect(
        () => {
            if(isCoaReady && chartOfAccountsFlat){
                setMvrAccounts(chartOfAccountsFlat.filter(fe => fe.type === FinancialEntityType.Account));
            }
        },
        [isCoaReady]
    );

    /**
     * previousReportMonth is a point of comparison used to determine if the month has changed now that the month and
     * year are combined into an object that may be updated w/o a month change
     */
    const previousReportMonth = useRef<number>();
    const previousPropertyId = useRef<string>();

    const doFetchNotesOnMonthUpdate = useRef<boolean>(true);
    const deferredMonthUpdate = useRef<number|undefined>(undefined);

    useEffect(
        () => {
            const handledGQLErrors = HandledGQLErrors.getInstance();

            handledGQLErrors.registerHandledGQLError('FinancialValuesError', 'VERSION_TYPE_NOT_FOUND');

            return () => {
                handledGQLErrors.unregisterHandledGQLError('FinancialValuesError', 'VERSION_TYPE_NOT_FOUND');

                previousReportMonth.current = undefined;
                previousPropertyId.current = undefined;

                doFetchNotesOnMonthUpdate.current = true;
                deferredMonthUpdate.current = undefined;
            };
        },
        []
    );

    /**
     * fetchMrvData will request a year's worth of MVR values for Chart of Accounts entries
     */
    const [
        fetchMvrDataWithVarianceNotes,
        {
            loading: mvrRawDataLoading,
            data: mvrRawData,
            error: mvrRawDataError,
        }
    ] = useGetMvrFinanciaValuesWithVarianceNotesLazyQuery({
        fetchPolicy: "no-cache",
    });

    /**
     * fetchMvrVarianceNotes will request the monthly variance notes for one month of a year
     */
    const [
        fetchMvrVarianceNotes,
        {
            loading: varianceNotesLoading,
            data: mvrVarianceNotesData
        }
    ] = useGetMvrVarianceNotesLazyQuery({
        fetchPolicy: "network-only",
    });

    const [
        setAccountVarianceNote,
    ] = useSetAccountVarianceNoteMutation();

    useEffect(
        () => {
            if(!mvrRawDataError){
                return;
            }

            setMvrDataErrors( prev => {
                if(prev){
                    return [...prev, mvrRawDataError];
                } else {
                    return [mvrRawDataError];
                }
            });
            clearLoadedData();
        },
        [mvrRawDataError]
    );

    /**
     * mvrDataServiceReady - set to true when all data dependencies and initial MVR year data is loaded
     */
    const [mvrDataServiceReady, setMvrDataServiceReady] = useState<boolean>(false);
    const [mvrDataLoaded, setMvrDataLoaded] = useState<boolean>(false);

    const [reportYear, setReportYear] = useState<number|undefined>(props?.year);
    const [reportMonth, setReportMonth] = useState<number|undefined>(props?.month);

    const [reportProperty, setReportProperty] = useState<Property>();

    const [reportDate, _setReportDate] = useState<{year: number, month: number}>();
    const setReportDate = (year: number, month: number):void => {
        if(reportDate?.year !== undefined && year != reportDate.year){
            deferredMonthUpdate.current = month;
        }
        _setReportDate({ year, month });
    };
    useEffect(
            () => {
                if(reportDate === undefined
                    || !dependenciesLoaded
                    || !reportProperty
                    || !reportProperty.id
                ){
                    return;
                }

                const { year, month } = reportDate;
                if(
                    year != reportYear
                    || reportProperty.id !== previousPropertyId.current
                ){
                    // Reset stored data errors as we're making a new request
                    setMvrDataErrors([]);

                    previousPropertyId.current = reportProperty.id;

                    // Note: We will not need to make another call to get variance notes when this month is applied
                    doFetchNotesOnMonthUpdate.current = false;

                    // Don't change the month if we've got a deferred month update queued
                    const useMonth = deferredMonthUpdate.current != undefined
                        ? deferredMonthUpdate.current
                        : month;

                    fetchMvrDataWithVarianceNotes({
                        variables: {
                            propertyId: reportProperty.id,
                            year,
                            varianceNoteMonthIndex: useMonth,
                        }
                    });
                }
                else if(month != previousReportMonth.current){
                    doFetchNotesOnMonthUpdate.current = true;
                    setReportMonth(month);
                }
            },
            [reportDate, reportProperty]
    );

    useEffect(
            () => {
                if(
                    reportMonth === undefined
                    || reportYear === undefined
                    || reportProperty?.id === undefined
                ){
                    return;
                }

                if(doFetchNotesOnMonthUpdate.current){
                    fetchMvrVarianceNotes({
                        variables: {
                            propertyId: reportProperty.id,
                            year: reportYear,
                            varianceNoteMonthIndex: reportMonth,
                        }
                    });
                }
            },
            [reportMonth]
    );

    useEffect(
            () => {
                if(
                    !mvrVarianceNotesData
                    || !mvrEntityDataMap
                ){
                    return;
                }

                const rowDataMap = applyVarianceNotesData(mvrEntityDataMap, mvrVarianceNotesData);
                setMvrEntityDataMap(rowDataMap);
            },
            [mvrVarianceNotesData]
    );

    /**
     * Update the property that should be reported on when the currentProperty changes.
     */
    useEffect(
        () => {
            if(
                reportProperty?.id !== undefined
                && properties.currentProperty?.id == reportProperty?.id
            ){
                return;
            }
            setReportProperty(properties.currentProperty);
        },
        [properties.currentProperty]
    );

    /**
     * Once all required hooks and contexts are loaded, register dependencies loaded
     */
    useEffect(
        () => {
            if(!isCoaReady || !config.isReady || !properties.loaded || !reportProperty){
                return;
            }
            setDependenciesLoaded(true);
            setMvrDataServiceReady(true);
        },
        [isCoaReady, config.isReady, properties.loaded, reportProperty]
    );

    /**
     * New year data has been loaded, update the mvrEntityDataMap
     */
    useEffect(
        () => {
            if(
                mvrRawDataLoading
                || !mvrRawData
                || !chartOfAccounts
            ){
                return;
            }

            // Note: mvrRawData contains both variance data and variance notes data
            const rowDataMap = buildMvrEntityDataMap(mvrRawData);
            setMvrEntityDataMap(rowDataMap);
        },
        [mvrRawDataLoading, mvrRawData, chartOfAccounts]
    );

    /**
     * Row data map contains all metadata and values, and calculated values for an MVR year
     */
    const [mvrEntityDataMap, setMvrEntityDataMap] = useState<Record<string, Partial<IFinancialEntityYear>>>();
    useEffect(
        () => {
            if(
                !chartOfAccounts
                || chartOfAccounts.length == 0
                || !mvrEntityDataMap
                || !reportDate
                || reportDate.month == undefined
                || reportDate.year == undefined
            ){
                return;
            }

            const tableData = buildMvrTableData(chartOfAccounts, mvrEntityDataMap, reportDate.month);
            setMvrTableData(tableData);

            // Year data is loaded, clear any deferred month
            if(deferredMonthUpdate.current != undefined){
                deferredMonthUpdate.current = undefined;
            }

            // Table data is built, update the report year and month
            setReportYear(reportDate.year);
            setReportMonth(reportDate.month);

            setMvrDataLoaded(true);
        },
        [mvrEntityDataMap, chartOfAccounts]
    );

    const [mvrTableData, setMvrTableData] = useState<MvrTableRow[]>([]);

    /**
     * Clears the loaded data since it's not cleared when there's a data error.
     */
    const clearLoadedData = () => {
        setMvrTableData(new Array(10).fill(getMockEmptyFinancialEntityYear()));
        setMvrEntityDataMap({});
    };

    const _setAccountVarianceNote = (updateArgs: IVarianceNoteUpdateArgs):void => {
        if(reportProperty != undefined && reportYear != undefined){
            // noinspection JSIgnoredPromiseFromCall
            setAccountVarianceNote({
                variables: {
                    propertyId: reportProperty.id,
                    accountId: updateArgs.accountId,
                    year: reportYear,
                    monthIndex: updateArgs.monthIndex,
                    versionType: VersionType.Budget,
                    text: updateArgs.note,
                }
            });
        }
    };

    return {
        mvrDataServiceReady,
        mvrDataLoaded,
        mvrReportYear: reportYear,
        mvrReportMonth: reportMonth,
        mvrTableData,
        mvrEntityDataMap,
        mvrAccountsReady: mvrAccounts.length > 0,
        mvrAccounts,
        mvrDataErrors,
        setVarianceNote: _setAccountVarianceNote,

        loading: mvrRawDataLoading || varianceNotesLoading,

        setReportDate,
    };
}
