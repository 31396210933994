import {ReactElement, useEffect, useState} from "react";
import {
    UserRole,
    useGetUserLazyQuery,
    useUpdateUserMutation
} from "../../../__generated__/generated_types";
import {ViziblyTheme} from "../../analyst/ViziblyZDGTheme";
import {ThemeProvider} from "@zendeskgarden/react-theming";
import {Close, Modal} from "@zendeskgarden/react-modals";
import {toast} from "react-toastify";
import UserForm, {TUserRecord} from "./components/user-form/UserForm";

export interface IEditUser {
    userId: string | undefined,
    formVisible: boolean,
    onModalClose: () => void,
    onUpdate: () => void,
}

export function UserEditModal(props: IEditUser): ReactElement {
    const {
        userId,
        formVisible,
        onModalClose,
        onUpdate,
    } = props;

    const [loadUser, {data: userData, loading: userLoading}] = useGetUserLazyQuery({
        fetchPolicy: "network-only"
    });
    const [saveUser, {loading: updateUserLoading}] = useUpdateUserMutation({
        notifyOnNetworkStatusChange: true,
        onCompleted: data => {
            if (data.updateUser) {
                toast.success(`${data.updateUser.firstName} ${data.updateUser.lastName} updated`);
                onUpdate();
            }
            reset();
        },
    });

    const [user, setUser] = useState<TUserRecord | undefined>();

    useEffect(() => {
        if (!userId || !formVisible) {
            return;
        }

        loadUser({
            variables: {
                id: userId,
            }
        });
    }, [userId, formVisible]);

    useEffect(() => {
        if (!userLoading && userData?.getUser) {
            setUser(userData.getUser);
        }
    }, [userLoading, userData]);

    function updateUser(update: Partial<TUserRecord>): void {
        if (user) {
            let adminOverrides = {};
            if (user.role == UserRole.Admin) {
                adminOverrides = {
                    isReadOnly: false,
                    groups: [],
                    authorizations: [],
                    properties: [],
                };
            }

            setUser({
                ...user,
                ...update,
                ...adminOverrides,
            });
        }
    }

    function reset(): void {
        setUser(undefined);
        onModalClose();
    }

    function doSaveUser(): void {
        if (user && user.id != undefined) {
            if (user.role == UserRole.Admin) {
                user.isReadOnly = false;
                user.groups = [];
                user.authorizations = [];
                user.properties = [];
            }

            saveUser({
                variables: {
                    id: user.id,
                    firstName: user.firstName,
                    lastName: user.lastName,
                    role: user.role,
                    isReadOnly: user.isReadOnly,
                    authorizations: user.authorizations,
                    properties: user.properties.map(p => p.id),
                    groups: user.groups.map(g => g.id)
                }
            });
        }
    }

    if (!formVisible) {
        return <></>;
    }

    return (
        <ThemeProvider theme={ViziblyTheme}>
            <Modal onClose={reset}>
                {
                    user &&
                    <UserForm
                        user={user}
                        loading={updateUserLoading}
                        onChange={updateUser}
                        onSubmit={doSaveUser}
                        onClose={reset}
                    />
                }
                <Close aria-label="Close modal" />
            </Modal>
        </ThemeProvider>
    );
}
