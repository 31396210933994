import { ReactElement } from "react";
import "./Loading.css";

interface Props {
	modal?: boolean;
	withBackground?: boolean;
}
export default function Loading({ modal, withBackground }: Props): ReactElement {
	if(modal) withBackground = true;
	const Spinner = () => {
		return (
			<div className={`w-100 ${!withBackground ? 'my-5 py-5': ''} d-flex align-items-center justify-content-center`}>
				<div className="lds-roller">
					<div></div>
					<div></div>
					<div></div>
					<div></div>
					<div></div>
					<div></div>
					<div></div>
					<div></div>
				</div>
			</div>
		);
	};
	return (
		modal ?
			<div className="_modal _loader">
				<div className="loader-content"><Spinner /></div>
			</div>
			:
			<Spinner />
	);
}