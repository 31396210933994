import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const HIDE_FOQAL_PATHS = ["/hub", "/analytics"];
const FQ_CHAT_ROOT_HIDDEN = "fq-chat-root-hidden";

function hideFoqal(foqalChatRoot: Element) {
    if (!foqalChatRoot.classList.contains(FQ_CHAT_ROOT_HIDDEN)) {
        foqalChatRoot.classList.add(FQ_CHAT_ROOT_HIDDEN);
    }
}

function showFoqal(foqalChatRoot: Element) {
    if (foqalChatRoot.classList.contains(FQ_CHAT_ROOT_HIDDEN)) {
        foqalChatRoot.classList.remove(FQ_CHAT_ROOT_HIDDEN);
    }
}

function getFoqalChatRoot(): Element | undefined {
    const elements = document.getElementsByClassName("fq-chat-root");
    if (elements.length > 0) {
        return elements[0];
    }

    return undefined;
}

export function useFoqalAction() {
    const hide = () => {
        const foqalChatRoot = getFoqalChatRoot();

        if (foqalChatRoot) {
            hideFoqal(foqalChatRoot);
        }
    };

    const show = () => {
        const foqalChatRoow = getFoqalChatRoot();

        if (foqalChatRoow) {
            showFoqal(foqalChatRoow);
        }
    };

    return {
        show,
        hide
    };
}

export function useFoqalRouteTrack(): void {
    const location = useLocation();

    useEffect(
        () => {
            // console.log("location changed", location);
            const foqalChatRoot = getFoqalChatRoot();
            if (foqalChatRoot) {

                if (
                    HIDE_FOQAL_PATHS.includes(location.pathname)
                ) {
                    hideFoqal(foqalChatRoot);
                }
                else {
                    showFoqal(foqalChatRoot);
                }
            }
        },
        [location]
    );
}
