import React, {ReactElement, useEffect, useReducer, useState} from "react";
import {Modal as ReactModal} from "react-bootstrap";

import {Button} from '@zendeskgarden/react-buttons';
import {Body, Close, Footer, FooterItem, Header, Modal} from '@zendeskgarden/react-modals';

import "../analyst/AnalystDrawer.scss";
import styles from "./AnalystRenovationDrawer.module.scss";

import PropertiesAndUnitTypesFilter from "./PropertiesAndUnitTypesFilter";

import {GetAnalystRenovationDrawerRenovationPackagesQuery} from "../../__generated__/generated_types";
import {NewSpreadsheet} from "../spreadsheet/NewSpreadsheet";
import {RenovationPackageDrawerStyles} from "./helpers/RenovationPackageDrawerStyles";
import {initializeSheet} from "./helpers/RenovationPackageSpreadsheetLayout";
import {
    renovationSetupDrawerSheetStateReducer,
    RenovationSetupDrawerSheetUpdates
} from "./helpers/RenovationSetupDrawerSheetState";
import {useNewSpreadsheetAPI} from "../spreadsheet/NewSpreadsheetHooks";
import useGetClientCostCategories from "./data/UseGetClientCostCategories";
import {
    LocalRenovationPackage,
    RenovationCreatePackagePayload,
    RenovationDeletePackagePayload,
    RenovationSetupDrawerSheetEntityType,
    RenovationUpdatePackagePayload
} from "./helpers/types";
import {RenovationPackageDrawerConfigs} from "./helpers/RenovationPackageDrawerConfig";
import useAddUpdateDeleteRenovationPackage from "./data/UseAddUpdateDeleteRenovationPackage";
import {
    useGetRenovationsPackagesByPropertyIdAndUnitTypeId
} from "./data/UseGetRenovationsPackagesByPropertyIdAndUnitTypeId";

interface AnalystRenovationDrawerProps {
    show: boolean
    handleClose: (wasAnyUpdatesPersistedOnBE: boolean) => void
    propertyId: string
    unitTypeId: string
}

function safeParseInt(input: unknown | null | undefined, defaultValue: number): number {
    let result: number = defaultValue;

    if (input !== null && input !== undefined) {
        if (typeof input === "string") {

            const parsed = parseInt(input);

            if (!isNaN(parsed)) {
                result = parsed;
            }

        } else if (typeof input === "number") {
            result = input;
        }
    }

    return result;
}

export default function AnalystRenovationDrawer(props: AnalystRenovationDrawerProps): ReactElement {
    const [internalShow, setInternalShow] = useState<boolean>(props.show);
    const [showAlert, setShowAlert] = useState(false);
    const [selectedLocalPropertyId, setSelectedLocalPropertyId] = useState<string>("");
    const [selectedLocalUnitTypeId, setSelectedLocalUnitTypeId] = useState<string>("");
    const [propertyAndUnitTypeChangeResolver, setPropertyAndUnitTypeChangeResolver] = useState<({myResolve: (arg0: boolean) => void})|undefined>(undefined);
    const [selectedRenovationPackages, setSelectedRenovationPackages] = useState<GetAnalystRenovationDrawerRenovationPackagesQuery["queryRenovationPackages"]|undefined>(undefined);
    const [hasUnsavedData, setHasUnsavedData] = useState<boolean>(false);
    const [wasAnyUpdatesPersistedOnBE, setWasAnyUpdatesPersistedOnBE] = useState<boolean>(false);
    const [newPackageCounter, setNewPackageCounter] = useState<number>(1);
    const [localRenovationPackages, setLocalRenovationPackages] = useState<LocalRenovationPackage[]|undefined>(undefined);
    const [renovationSetupDrawerSheetUpdates, setRenovationSetupDrawerSheetUpdates] = useState<RenovationSetupDrawerSheetUpdates>({});
    const renovationSetupDrawerSheetData = useAddUpdateDeleteRenovationPackage();
    const [state, dispatch] = useReducer(renovationSetupDrawerSheetStateReducer, {rowColToEntityMap: {}});

    const {
        isDataReady: isRenovationCostCategoriesDataReady,
        data: renovationCostCategories
    } = useGetClientCostCategories();
    const {
        isDataReady: isRenovationPackagesDataReady,
        refreshRenovationPackagesForSelectedProperty,
        getData: getRenovationPackages
    } = useGetRenovationsPackagesByPropertyIdAndUnitTypeId();

    useEffect(() => {
        /*console.info(`useEffect
            selectedLocalPropertyId: ${selectedLocalPropertyId}
            selectedLocalUnitTypeId: ${selectedLocalUnitTypeId}`);*/
        if (!selectedLocalPropertyId || !selectedLocalUnitTypeId) {
            return;
        }
        if (selectedLocalPropertyId && selectedLocalUnitTypeId) {
            refreshRenovationPackagesForSelectedProperty(selectedLocalPropertyId, selectedLocalUnitTypeId);
        }
    }, [selectedLocalPropertyId, selectedLocalUnitTypeId]);

    useEffect(() => {
        /*console.info(`useEffect
            isRenovationPackagesDataReady: ${isRenovationPackagesDataReady}
            ===============
            selectedRenovationPackages: ${JSON.stringify(selectedRenovationPackages)}`);*/
        if (!isRenovationPackagesDataReady) {
            return;
        }
        const selectedPropertyAndUnitTypeRenovationPackages = getRenovationPackages();
        // console.info(`selectedPropertyAndUnitTypeRenovationPackages: ${JSON.stringify(selectedPropertyAndUnitTypeRenovationPackages)}`);
        setSelectedRenovationPackages(selectedPropertyAndUnitTypeRenovationPackages);
    }, [isRenovationPackagesDataReady]);

    useEffect(() => {
        /*console.info(`useEffect
            isRenovationPackagesDataReady: ${isRenovationPackagesDataReady}
            selectedRenovationPackages: ${JSON.stringify(selectedRenovationPackages)}`);*/
        if (!isRenovationPackagesDataReady || !selectedRenovationPackages) {
            return;
        }
        const nextLocalRenovationPackages: LocalRenovationPackage[] = [];
        selectedRenovationPackages.forEach(selectedRenovationPackage => {
            const nextLocalRenovationPackage: LocalRenovationPackage = {
                id: selectedRenovationPackage.id,
                propertyId: selectedRenovationPackage.propertyId,
                unitTypeId: selectedRenovationPackage.unitTypeId,
                name: selectedRenovationPackage.name,
                markedForDeletion: false,
                costCategoryMappings: [],
                premium: selectedRenovationPackage.premium,
                premiumDelayRecognition: selectedRenovationPackage.premiumDelayRecognition,
            };
            selectedRenovationPackage.costCategoryMappings.forEach(costCategory => {
                nextLocalRenovationPackage.costCategoryMappings.push({
                    costCategoryId: costCategory.renovationCostCategoryId,
                    amount: costCategory.amount,
                    delayRecognition: costCategory.delayRecognition
                });
            });
            nextLocalRenovationPackages.push(nextLocalRenovationPackage);
        });
        setLocalRenovationPackages(nextLocalRenovationPackages);
        // console.info(`=============== populated the localRenovation Packages: ${JSON.stringify(localRenovationPackages)} with the ${JSON.stringify(nextLocalRenovationPackages)}`);
    }, [isRenovationPackagesDataReady, selectedRenovationPackages]);

    const {
        handlers: sshandlers,
        isSpreadsheetReady,
        api: ssapi,
        cellsChanged,
        cellClicked,
        cellTargetClicked,
    } = useNewSpreadsheetAPI();

    useEffect(() => {
        if (props.show) {
            setTimeout(() => {
                setInternalShow(true);
            }, 600);
        } else {
            setTimeout(() => {
                setInternalShow(false);
            }, 600);
        }
    }, [props.show]);

    useEffect(() => {
        /*console.info(`useEffect
            isSpreadSheetReady: ${isSpreadsheetReady}
            isRenovationCostCategoriesDataReady: ${isRenovationCostCategoriesDataReady}
            isRenovationPackagesDataReady: ${isRenovationPackagesDataReady}
            renovationCostCategories: ${JSON.stringify(renovationCostCategories)}
            localRenovationPackages: ${JSON.stringify(localRenovationPackages)}`);*/
        if (!isSpreadsheetReady || !isRenovationCostCategoriesDataReady || !isRenovationPackagesDataReady || !localRenovationPackages) {
            return;
        }
        initializeSheet(ssapi, renovationCostCategories, localRenovationPackages, dispatch, false);
        ssapi.startCollectingCellUpdates();
    }, [isSpreadsheetReady, isRenovationPackagesDataReady, renovationCostCategories, localRenovationPackages]);

    useEffect(
        () => {
            if (!isSpreadsheetReady || !isRenovationCostCategoriesDataReady || !isRenovationPackagesDataReady || (cellsChanged && cellsChanged.length == 0)) {
                return;
            }
            cellsChanged.forEach(updatedCellMeta => {
                const currentState = state.rowColToEntityMap[`${updatedCellMeta.row}#${updatedCellMeta.col}`];
                // console.info(`Received change for ${updatedCellMeta.row}#${updatedCellMeta.col}`, currentState, updatedCellMeta);
                if (currentState) {
                    setHasUnsavedData(true);
                    setRenovationSetupDrawerSheetUpdates((prevState: RenovationSetupDrawerSheetUpdates) => {
                        return {
                            ...prevState,
                            [currentState.idx]: {
                                idx: currentState.idx,
                                packageId: currentState.packageId,
                                categoryId: currentState.categoryId,
                                entityType: currentState.entityType,
                                newValue: updatedCellMeta.value
                            }
                        };
                    });
                }
            });
        },
        [cellsChanged]);

    useEffect(
        () => {
            if (!isSpreadsheetReady || !isRenovationCostCategoriesDataReady || !isRenovationPackagesDataReady || !cellTargetClicked || !localRenovationPackages) {
                return;
            }

            const currentState = state.rowColToEntityMap[`${cellTargetClicked.row}#${cellTargetClicked.col}`];
            // console.info(`Received click for ${cellTargetClicked.row}#${cellTargetClicked.col}`, currentState, cellTargetClicked);
            if (currentState && currentState.entityType === RenovationSetupDrawerSheetEntityType.PACKAGE_NAME) {
                setHasUnsavedData(true);
                // console.info("marked for deletion");
                setRenovationSetupDrawerSheetUpdates((prevState: RenovationSetupDrawerSheetUpdates) => {
                    return {
                        ...prevState,
                        [currentState.idx]: {
                            idx: currentState.idx,
                            packageId: currentState.packageId,
                            categoryId: currentState.categoryId,
                            entityType: RenovationSetupDrawerSheetEntityType.PACKAGE_DELETE,
                            newValue: RenovationSetupDrawerSheetEntityType.CELL_NEW_VALUE_NA
                        }
                    };
                });
                // console.info(`cellTargetClicked, localRenovationPackages: ${JSON.stringify(localRenovationPackages)}`);
                setLocalRenovationPackages((prevState) => {
                    const newState: LocalRenovationPackage[] = [];
                    // console.info(`cellTargetClicked, prevState: ${JSON.stringify(prevState)}`);
                    prevState?.forEach((prevEntry: LocalRenovationPackage) => {
                        // console.info(`cellTargetClicked, prevEntry: ${JSON.stringify(prevEntry)}`);
                        newState.push({
                            id: prevEntry.id,
                            propertyId: prevEntry.propertyId,
                            unitTypeId: prevEntry.unitTypeId,
                            name: prevEntry.name,
                            markedForDeletion: prevEntry.markedForDeletion ? true : prevEntry.id === currentState.packageId,
                            costCategoryMappings: prevEntry.costCategoryMappings.map((prevCostCategory) => {
                                return {
                                    costCategoryId: prevCostCategory.costCategoryId,
                                    amount: prevCostCategory.amount,
                                    delayRecognition: prevCostCategory.delayRecognition
                                };
                            }),
                            premium: prevEntry.premium,
                            premiumDelayRecognition: prevEntry.premiumDelayRecognition
                        });
                    });
                    return newState;
                });
            }
        },
        [cellTargetClicked]);

    useEffect(() => {
        // console.info("in has unsaved data effect", hasUnsavedData, renovationSetupDrawerSheetData.updateStateMeta);
        const isAnyApiInProgress = Object.values(renovationSetupDrawerSheetData.updateStateMeta).some(state => state);
        if (!isAnyApiInProgress) {
            setHasUnsavedData(false);
            setSelectedRenovationPackages(undefined);
            setLocalRenovationPackages(undefined);
            setRenovationSetupDrawerSheetUpdates({});
            if (selectedLocalPropertyId && selectedLocalUnitTypeId) {
                refreshRenovationPackagesForSelectedProperty(selectedLocalPropertyId, selectedLocalUnitTypeId);
            }
        }
    }, [renovationSetupDrawerSheetData.updateStateMeta]);

    const clearStates = () => {
        setShowAlert(false);
        setSelectedLocalPropertyId("");
        setSelectedLocalUnitTypeId("");
        setPropertyAndUnitTypeChangeResolver(undefined);
        setSelectedRenovationPackages(undefined);
        setHasUnsavedData(false);
        setLocalRenovationPackages(undefined);
        setRenovationSetupDrawerSheetUpdates({});
    };

    const handleClose = (force?: boolean | undefined) => {
        // console.info("handleClose", force, hasUnsavedData, propertyAndUnitTypeChangeResolver);
        if (propertyAndUnitTypeChangeResolver) {
            // The case of being called from property change flow
            if (force === true) {
                propertyAndUnitTypeChangeResolver.myResolve(true);
                clearStates();
            } else {
                // This block should be unreachable from the property and unit type change and show discard unsaved changes flow
                console.warn("This console should not have come!!!");
            }
        } else {
            if (force === true) {
                setShowAlert(false);
                clearStates();
                props.handleClose(wasAnyUpdatesPersistedOnBE);
            } else {
                if (hasUnsavedData) {
                    setShowAlert(true);
                } else {
                    setShowAlert(false);
                    clearStates();
                    props.handleClose(wasAnyUpdatesPersistedOnBE);
                }
            }
        }
    };

    const checkPropertyAndUnitTypeChangeAllowed = ():Promise<boolean> => {
        // console.info("On filter dropdown change attempt", propertyId, unitTypeId, hasUnsavedData);
        if (hasUnsavedData) {
            // console.info("The property unit type change is not allowed as there are some unsaved changes. Please save them and then try again.");
            const propertyChangePromise = new Promise<boolean>((resolve: (arg0: boolean) => void) => {
                setPropertyAndUnitTypeChangeResolver({
                    myResolve: resolve
                });
            });
            setShowAlert(true);
            return propertyChangePromise;
        }
        return Promise.resolve(true);
    };

    const onPropertyAndUnitTypeFilterDropDownChange = (propertyId: string | undefined, unitTypeId: string | undefined):void => {
        // console.info("On filter dropdown change", propertyId, unitTypeId, hasUnsavedData);
        if (propertyId && unitTypeId && propertyId !== selectedLocalPropertyId && unitTypeId !== selectedLocalUnitTypeId) {
            setHasUnsavedData(false);
            setPropertyAndUnitTypeChangeResolver(undefined);
            setSelectedRenovationPackages(undefined);
            setLocalRenovationPackages(undefined);
            setRenovationSetupDrawerSheetUpdates({});
            setSelectedLocalPropertyId(propertyId);
            setSelectedLocalUnitTypeId(unitTypeId);
        }
    };

    const addDummyRenovationPackage = () => {
        const newPackageId = RenovationPackageDrawerConfigs.DEFAULT_RENOVATION_NEW_PACKAGE_ID + newPackageCounter;
        const newPackageName = RenovationPackageDrawerConfigs.DEFAULT_RENOVATION_NEW_PACKAGE_NAME + newPackageCounter;
        setNewPackageCounter(prevState => ++prevState);
        const dummyRenovationPackage: LocalRenovationPackage[] = [{
            id: newPackageId,
            propertyId: "propertyId",
            unitTypeId: "unitTypeId",
            name: newPackageName,
            markedForDeletion: false,
            costCategoryMappings: renovationCostCategories?.queryRenovationCostCategories.map(category => {
                return {
                    costCategoryId: category.id,
                    amount: RenovationPackageDrawerConfigs.DEFAULT_AMOUNT,
                    delayRecognition: RenovationPackageDrawerConfigs.DEFAULT_DELAY_RECOGNITION
                };
            }) ?? [],
            premium: RenovationPackageDrawerConfigs.DEFAULT_PREMIUM,
            premiumDelayRecognition: RenovationPackageDrawerConfigs.DEFAULT_PREMIUM_DELAY_RECOGNITION
        }];
        const currentAmountColumnIndex = 2 + (localRenovationPackages?.length || 0) * 2;
        const currentDelayRecognitionIndex = currentAmountColumnIndex + 1;
        const packageNameCellMeta = {
            idx: `${RenovationPackageDrawerConfigs.PACKAGE_NAME_VALUE_ROW_INDEX}#${currentAmountColumnIndex}`,
            packageId: newPackageId,
            categoryId: RenovationSetupDrawerSheetEntityType.CATEGORY_NA,
            entityType: RenovationSetupDrawerSheetEntityType.PACKAGE_NAME,
            newValue: newPackageName
        };
        let nextRowIndex = RenovationPackageDrawerConfigs.FIRST_COST_CATEGORY_VALUE_ROW_INDEX;
        const costCategoriesCellsMeta = renovationCostCategories?.queryRenovationCostCategories.reduce((prevVal: RenovationSetupDrawerSheetUpdates, category) => {
            prevVal[`${nextRowIndex}#${currentAmountColumnIndex}`] = {
                idx: `${nextRowIndex}#${currentAmountColumnIndex}`,
                packageId: newPackageId,
                categoryId: category.id,
                entityType: RenovationSetupDrawerSheetEntityType.AMOUNT,
                newValue: RenovationPackageDrawerConfigs.DEFAULT_AMOUNT
            };
            prevVal[`${nextRowIndex}#${currentDelayRecognitionIndex}`] = {
                idx: `${nextRowIndex}#${currentDelayRecognitionIndex}`,
                packageId: newPackageId,
                categoryId: category.id,
                entityType: RenovationSetupDrawerSheetEntityType.DELAY_RECOGNITION,
                newValue: RenovationPackageDrawerConfigs.DEFAULT_DELAY_RECOGNITION
            };
            nextRowIndex++;
            return prevVal;
        }, {}) ?? {};
        const premiumCellMeta = {
            idx: `${++nextRowIndex}#${currentAmountColumnIndex}`,
            packageId: newPackageId,
            categoryId: RenovationSetupDrawerSheetEntityType.CATEGORY_NA,
            entityType: RenovationSetupDrawerSheetEntityType.PREMIUM,
            newValue: RenovationPackageDrawerConfigs.DEFAULT_PREMIUM
        };
        const premiumDelayRecognitionCellMeta = {
            idx: `${nextRowIndex}#${currentDelayRecognitionIndex}`,
            packageId: newPackageId,
            categoryId: RenovationSetupDrawerSheetEntityType.CATEGORY_NA,
            entityType: RenovationSetupDrawerSheetEntityType.PREMIUM_DELAY_RECOGNITION,
            newValue: RenovationPackageDrawerConfigs.DEFAULT_PREMIUM_DELAY_RECOGNITION
        };
        setRenovationSetupDrawerSheetUpdates((prevState: RenovationSetupDrawerSheetUpdates) => {
            return {
                ...prevState,
                [packageNameCellMeta.idx]: packageNameCellMeta,
                ...costCategoriesCellsMeta,
                [premiumCellMeta.idx]: premiumCellMeta,
                [premiumDelayRecognitionCellMeta.idx]: premiumDelayRecognitionCellMeta
            };
        });
        setLocalRenovationPackages((prevState: LocalRenovationPackage[]|undefined) => {
            return prevState?.concat(dummyRenovationPackage) ?? dummyRenovationPackage;
        });
        setHasUnsavedData(true);
    };

    const addOrUpdateRenovationPackages = () => {
        const packagesToBeCreated: Record<string, RenovationCreatePackagePayload> = {};
        const packagesToBeUpdated: Record<string, RenovationUpdatePackagePayload> = {};
        const packagesToBeDeleted: Record<string, RenovationDeletePackagePayload> = {};
        // console.info("before api data processing", renovationSetupDrawerSheetUpdates);
        Object.keys(renovationSetupDrawerSheetUpdates).forEach(k => {
            const currCellMeta = renovationSetupDrawerSheetUpdates[k];
            if (currCellMeta?.entityType === RenovationSetupDrawerSheetEntityType.PACKAGE_DELETE) {
                if (!packagesToBeDeleted[currCellMeta.packageId]) {
                    packagesToBeDeleted[currCellMeta.packageId] = {
                        id: currCellMeta.packageId,
                        name: currCellMeta.newValue ? currCellMeta.newValue.toString() : "",
                        force: true
                    };
                }
            } else {
                let currPackageMeta;
                if (currCellMeta?.packageId.startsWith(RenovationPackageDrawerConfigs.DEFAULT_RENOVATION_NEW_PACKAGE_ID)) {
                    packagesToBeCreated[currCellMeta.packageId] = packagesToBeCreated[currCellMeta.packageId] || {
                        id: currCellMeta.packageId,
                        propertyId: selectedLocalPropertyId,
                        unitTypeId: selectedLocalUnitTypeId,
                        name: RenovationPackageDrawerConfigs.DEFAULT_RENOVATION_NEW_PACKAGE_NAME,
                        costCategoryMappings: [],
                        premium: RenovationPackageDrawerConfigs.DEFAULT_PREMIUM,
                        premiumDelayRecognition: RenovationPackageDrawerConfigs.DEFAULT_PREMIUM_DELAY_RECOGNITION
                    };
                    currPackageMeta = packagesToBeCreated[currCellMeta.packageId];
                } else if (currCellMeta?.packageId) {
                    const correspondingPackageMeta = localRenovationPackages?.find(localRenovationPackage => localRenovationPackage.id === currCellMeta.packageId);
                    if (!packagesToBeUpdated[currCellMeta.packageId]) {
                        packagesToBeUpdated[currCellMeta.packageId] = {
                            id: currCellMeta.packageId,
                            name: correspondingPackageMeta?.name ?? RenovationPackageDrawerConfigs.DEFAULT_RENOVATION_NEW_PACKAGE_NAME,
                            costCategoryMappings: correspondingPackageMeta?.costCategoryMappings.map(costCategory => {
                                return {
                                    costCategoryId: costCategory.costCategoryId,
                                    amount: costCategory.amount.toString(),
                                    delayRecognition: costCategory.delayRecognition
                                };
                            }) || [],
                            premium: correspondingPackageMeta?.premium ?? RenovationPackageDrawerConfigs.DEFAULT_PREMIUM,
                            premiumDelayRecognition: correspondingPackageMeta?.premiumDelayRecognition ?? RenovationPackageDrawerConfigs.DEFAULT_PREMIUM_DELAY_RECOGNITION
                        };
                    }
                    currPackageMeta = packagesToBeUpdated[currCellMeta.packageId];
                }
                if (currCellMeta && currPackageMeta) {
                    const currentNewValue = currCellMeta.newValue;
                    // console.info("currentNewValue", currentNewValue);
                    if (currCellMeta.entityType === RenovationSetupDrawerSheetEntityType.PACKAGE_NAME) {
                        currPackageMeta.name = currentNewValue?.toString() ?? RenovationPackageDrawerConfigs.DEFAULT_RENOVATION_NEW_PACKAGE_NAME;
                    } else if (currCellMeta.entityType === RenovationSetupDrawerSheetEntityType.AMOUNT || currCellMeta.entityType === RenovationSetupDrawerSheetEntityType.DELAY_RECOGNITION) {
                        if (currCellMeta.categoryId !== RenovationSetupDrawerSheetEntityType.CATEGORY_NA) {
                            const foundCorrespondingCategory = currPackageMeta.costCategoryMappings.find(category => category.costCategoryId === currCellMeta.categoryId);
                            if (!foundCorrespondingCategory) {
                                if (currCellMeta.entityType === RenovationSetupDrawerSheetEntityType.AMOUNT) {
                                    let amount: string = RenovationPackageDrawerConfigs.DEFAULT_AMOUNT.toString();
                                    if (currentNewValue !== null) {
                                        amount = currentNewValue.toString();
                                    }
                                    currPackageMeta.costCategoryMappings.push({
                                        costCategoryId: currCellMeta.categoryId,
                                        amount: amount,
                                        delayRecognition: RenovationPackageDrawerConfigs.DEFAULT_DELAY_RECOGNITION
                                    });
                                } else {
                                    let delayRecognition: number = RenovationPackageDrawerConfigs.DEFAULT_DELAY_RECOGNITION;
                                    if (typeof currentNewValue === "string") {
                                        delayRecognition = parseInt(currentNewValue);
                                    } else if (typeof currentNewValue === "number") {
                                        delayRecognition = currentNewValue;
                                    }
                                    currPackageMeta.costCategoryMappings.push({
                                        costCategoryId: currCellMeta.categoryId,
                                        amount: RenovationPackageDrawerConfigs.DEFAULT_AMOUNT.toString(),
                                        delayRecognition: delayRecognition
                                    });
                                }
                            } else {
                                if (currCellMeta.entityType === RenovationSetupDrawerSheetEntityType.AMOUNT) {
                                    let amount: string = RenovationPackageDrawerConfigs.DEFAULT_AMOUNT.toString();
                                    if (currentNewValue !== null) {
                                        amount = currentNewValue.toString();
                                    }
                                    foundCorrespondingCategory.amount = amount;
                                } else {
                                    let delayRecognition: number = RenovationPackageDrawerConfigs.DEFAULT_DELAY_RECOGNITION;
                                    if (typeof currentNewValue === "string") {
                                        delayRecognition = parseInt(currentNewValue);
                                    } else if (typeof currentNewValue === "number") {
                                        delayRecognition = currentNewValue;
                                    }
                                    foundCorrespondingCategory.delayRecognition = delayRecognition;
                                }
                            }
                        }
                    } else if (currCellMeta.entityType === RenovationSetupDrawerSheetEntityType.PREMIUM) {
                        currPackageMeta.premium = currentNewValue ? parseInt(currentNewValue.toString()) : RenovationPackageDrawerConfigs.DEFAULT_PREMIUM;
                    } else if (currCellMeta.entityType === RenovationSetupDrawerSheetEntityType.PREMIUM_DELAY_RECOGNITION) {
                        currPackageMeta.premiumDelayRecognition = safeParseInt(currentNewValue, RenovationPackageDrawerConfigs.DEFAULT_PREMIUM_DELAY_RECOGNITION);
                    } else {
                        console.info("Unhandled entity type. This console should not come", currCellMeta.entityType);
                    }
                }
            }
        });
        // console.info("packagesToBeCreated", packagesToBeCreated);
        // console.info("packagesToBeUpdated", packagesToBeUpdated);
        // console.info("packagesToBeDeleted", packagesToBeDeleted);
        setWasAnyUpdatesPersistedOnBE(true);
        const filteredPackageToBeCreated = Object.values(packagesToBeCreated).filter(v => Object.keys(packagesToBeDeleted).indexOf(v.id) === -1);
        console.info("filteredPackageToBeCreated", filteredPackageToBeCreated);
        filteredPackageToBeCreated.forEach(v => {
            console.info("Creating package: ", v.id, v.name);
            renovationSetupDrawerSheetData.createNewPackage({
                propertyId: selectedLocalPropertyId,
                unitTypeId: selectedLocalUnitTypeId,
                packageName: v.name ?? RenovationPackageDrawerConfigs.DEFAULT_RENOVATION_NEW_PACKAGE_NAME,
                costCategoryMappings: v.costCategoryMappings ?? [],
                premium: v.premium ?? RenovationPackageDrawerConfigs.DEFAULT_PREMIUM,
                premiumDelayRecognition: v.premiumDelayRecognition ?? RenovationPackageDrawerConfigs.DEFAULT_PREMIUM_DELAY_RECOGNITION
            });
        });
        const filteredPackageToBeUpdated = Object.values(packagesToBeUpdated).filter(v => Object.keys(packagesToBeDeleted).indexOf(v.id) === -1);
        console.info("filteredPackageToBeUpdated", filteredPackageToBeUpdated);
        filteredPackageToBeUpdated.forEach(v => {
            console.info("Updating package: ", v.id, v.name);
            renovationSetupDrawerSheetData.updatePackage({
                id: v.id,
                name: v.name,
                costCategoryMappings: v.costCategoryMappings ?? [],
                premium: v.premium ?? RenovationPackageDrawerConfigs.DEFAULT_PREMIUM,
                premiumDelayRecognition: v.premiumDelayRecognition ?? RenovationPackageDrawerConfigs.DEFAULT_PREMIUM_DELAY_RECOGNITION
            });
        });
        const filteredPackageToBeDeleted = Object.values(packagesToBeDeleted).filter(v => !v.id.startsWith(RenovationPackageDrawerConfigs.DEFAULT_RENOVATION_NEW_PACKAGE_ID));
        console.info("filteredPackageToBeDeleted", filteredPackageToBeDeleted);
        filteredPackageToBeDeleted.forEach(v => {
            console.info("Deleting package: ", v.id, v.name);
            renovationSetupDrawerSheetData.deletePackage({
                id: v.id,
                force: v.force,
            });
        });
    };

    const modalRootProbe = document.getElementById("modal-alert-root");
    const modalRoot = modalRootProbe == null ? undefined : modalRootProbe;
    const costCategoriesCount = renovationCostCategories?.queryRenovationCostCategories.length || 10;
    const totalRowsCount = RenovationPackageDrawerConfigs.FIRST_COST_CATEGORY_VALUE_ROW_INDEX +
        costCategoriesCount + 1 /*total_row*/ + 1 /*premium_row*/;
    const packagesCount = localRenovationPackages?.length || 1;
    const totalColumnsCount = RenovationPackageDrawerConfigs.FIRST_PACKAGE_NAME_VALUE_COL_INDEX + (packagesCount * 2) + 4; // Some buffer for 2 more packages and scrolling
    /*const sheetContainerHeight = RenovationPackageDrawerStyles.HEADER_ROW_HEIGHT +
        RenovationPackageDrawerStyles.SUB_HEADER_ROW_HEIGHT +
        costCategoriesCount * RenovationPackageDrawerStyles.DATA_ROW_HEIGHT +
        RenovationPackageDrawerStyles.TOTAL_LABEL_ROW_HEIGHT +
        RenovationPackageDrawerStyles.PREMIUM_LABEL_ROW_HEIGHT + 40/!* footer height *!/ + 15/!* some buffer *!/;*/
    let showAddPackageButton = false;
    const anyVisiblePackage = localRenovationPackages?.some(renovationPackage => !renovationPackage.markedForDeletion) || false;
    if (localRenovationPackages && (localRenovationPackages.length === 0 || !anyVisiblePackage)) {
        showAddPackageButton = true;
    }
    const addPackageButtonLeft = RenovationPackageDrawerStyles.COST_CATEGORY_COL_WIDTH + RenovationPackageDrawerStyles.GL_COL_WIDTH + 70;
    const addPackageButtonTop = RenovationPackageDrawerStyles.HEADER_ROW_HEIGHT + RenovationPackageDrawerStyles.SUB_HEADER_ROW_HEIGHT + 30;
    const addPackageButtonLeftPxStr = `${addPackageButtonLeft}px`;
    const addPackageButtonTopPxStr = `${addPackageButtonTop}px`;
    const isAnyApiInProgress = Object.values(renovationSetupDrawerSheetData.updateStateMeta).some(state => state);

    // console.info("Drawer before return JSX", anyVisiblePackage, showAddPackageButton);

    if (!internalShow) {
        return (
            <></>
        );
    }

    return (
        <>
            {showAlert && (
                <Modal
                    onClose={() => {
                        if(propertyAndUnitTypeChangeResolver) {
                            propertyAndUnitTypeChangeResolver.myResolve(false);
                            setPropertyAndUnitTypeChangeResolver(undefined);
                        }
                        setShowAlert(false);
                    }}
                    appendToNode={modalRoot}
                    backdropProps={{
                        style: {
                            backgroundColor: "#33384233",
                            marginTop: "75px"
                        }
                    }}
                    focusOnMount={true}
                    isAnimated={false}
                >
                    <Header isDanger>Discard unsaved changes?</Header>
                    <Body>
                        Your unsaved changes will be lost.
                    </Body>
                    <Footer>
                        <FooterItem>
                            <Button onClick={() => {
                                if(propertyAndUnitTypeChangeResolver) {
                                    propertyAndUnitTypeChangeResolver.myResolve(false);
                                    setPropertyAndUnitTypeChangeResolver(undefined);
                                }
                                setShowAlert(false);
                            }} isBasic>
                                Cancel
                            </Button>
                        </FooterItem>
                        <FooterItem>
                            <Button isPrimary isDanger onClick={() => handleClose(true)}>
                                Discard
                            </Button>
                        </FooterItem>
                    </Footer>
                    <Close aria-label="Close modal"/>
                </Modal>
            )}
            <ReactModal
                show={internalShow}
                animation={false}
                backdropClassName="vizibly-analyst-drawer-backdrop"
                dialogClassName={`vizibly-analyst-drawer-dialog ${props.show ? 'slide-in-right' : 'slide-out-right'}`}
                contentClassName="vizibly-analyst-drawer-content"
                onHide={() => handleClose()}
            >
                <ReactModal.Body>
                    <div id="modal-alert-root"/>
                    <div className="analyst-drawer">
                        <div className={styles.header}>
                            <div className={styles.headerText}>
                                <h4>Renovation Packages</h4>
                                <div className="small">Set up renovation packages by unit type per property.</div>
                                <button className={styles.closeButton + " btn btn-close"}
                                        onClick={() => handleClose()}></button>
                            </div>
                        </div>
                        <div className={styles.headerActionsBar}>
                            <PropertiesAndUnitTypesFilter
                                initialPropertyId={props.propertyId}
                                initialUnitTypeId={props.unitTypeId}
                                checkPropertyAndUnitTypeChangeAllowed={() => {
                                    return checkPropertyAndUnitTypeChangeAllowed();
                                }}
                                onSelectChange={(propertyId: string | undefined, unitTypeId: string | undefined) => {
                                    onPropertyAndUnitTypeFilterDropDownChange(propertyId, unitTypeId);
                                }}
                                disabled
                            />
                            <Button onClick={() => addDummyRenovationPackage()}>Add</Button>
                        </div>
                        {/*320px (75 for top header, 75 for drawer header, 72 for dropdown, 48 for footer, 50 for buffer to avoid another scroll)*/}
                        <div className={styles.sheetContainer} style={{maxHeight: `calc(100vh - 320px)`}}>
                            <NewSpreadsheet
                                startRow={0}
                                startCol={0}
                                rows={totalRowsCount}
                                cols={totalColumnsCount}
                                handlers={sshandlers}
                                allowVerticalScroll={true}
                                allowHorizontalScroll={true}
                                subscribeToMouse={true}
                                safeAreaFirstRow={5}
                                safeAreaFirstColumn={4}
                                style={{maxWidth: "100%", maxHeight: "calc(100vh - 320px)"}}
                            />
                            {showAddPackageButton && <div
                                className={styles.addPackageButtonContainer}
                                style={{left: addPackageButtonLeftPxStr, top: addPackageButtonTopPxStr}}>
                                <button
                                    type={"button"}
                                    onClick={() => addDummyRenovationPackage()}
                                    className={styles.addPackageButton}>
                                    Add Package
                                </button>
                            </div>}
                        </div>
                        <div className={styles.footerActionsBar}>
                            {isAnyApiInProgress &&
                                <div className={styles.uploadingMessageContainer}>
                                    Uploading Data
                                </div>
                            }
                            <Button isBasic className={styles.footerActionsBarCancelButton}
                                    onClick={() => handleClose()}>Cancel</Button>
                            <Button isPrimary className={styles.footerActionsBarUpdateButton}
                                    onClick={() => addOrUpdateRenovationPackages()}>Update</Button>
                        </div>
                    </div>
                </ReactModal.Body>
            </ReactModal>
        </>
    );
}