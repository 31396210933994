import { BudgetingType } from "../../../BudgetingType";
import { AppConfig } from "../../../hooks/useConfig";
import { ExportPropertyBudgetQuery, ExportPropertyBudgetT12ReportQuery, FinancialEntityType, VersionType } from "../../../__generated__/generated_types";
import { YearDefinition } from "../../annualsummary/YearDefinitions";
import ExcelJS, { Row } from "exceljs";
import { Property } from "../../../contexts/properties/PropertiesContext";

export async function formatForExcelFullNoZeros(
    { tab, referenceYear, data, config }:
        {
            tab: BudgetingType;
            referenceYear: YearDefinition;
            data: ExportPropertyBudgetQuery;
            config: AppConfig;
        }
): Promise<Blob> {

    const workbook = new ExcelJS.Workbook();
    workbook.creator = "Vizibly";
    workbook.lastModifiedBy = "Vizibly";
    workbook.created = new Date();
    workbook.modified = new Date();

    const reportYear = tab == BudgetingType.REFORECAST ? config.year : config.year + 1;

    const tabCaption = `${reportYear} ${tab == BudgetingType.REFORECAST ? "Reforecast" : "Budget"} - ${config.properties.currentProperty?.name}`;
    // create a sheet where the grid lines are hidden
    const sheet = workbook.addWorksheet(
        tabCaption,
        {
            views: [{
                showGridLines: false,
                zoomScale: 86,
                state: "frozen",
                xSplit: 2,
                ySplit: 5
            }]
        }
    );

    const glNumberColumnWidth = 15;
    const glNameColumnWidth = 42;
    const monthColumnWidth = 15;
    const yearTotalColumnWidth = 20;
    const referenceYearColumnWidth = 20;
    const var$ColumnWidth = 20;
    const varPctColumnWidth = 10;
    const notesColumnWidth = 80;

    sheet.columns = [
        {
            width: glNumberColumnWidth
        },
        {
            width: glNameColumnWidth
        },
        ...new Array(12).fill(0).map(_ => ({
            width: monthColumnWidth
        })),
        {
            width: yearTotalColumnWidth
        },
        {
            width: referenceYearColumnWidth
        },
        {
            width: var$ColumnWidth
        },
        {
            width: varPctColumnWidth
        },
        {
            width: notesColumnWidth
        }
    ];

    let addedRow = sheet.addRow([config.properties.currentProperty?.name]);
    addedRow.font = {
        name: "Helvetica",
        bold: true,
        size: 16
    };
    addedRow.alignment = {
        ...(addedRow.alignment ?? {}),
        vertical: "middle",
        horizontal: "center"
    };
    sheet.mergeCells("A1:R1");

    addedRow = sheet.addRow([`Operating ${tab == BudgetingType.REFORECAST ? "Reforecast" : "Budget"}`]);
    addedRow.font = {
        name: "Helvetica",
        bold: true,
        size: 16
    };
    addedRow.alignment = {
        ...(addedRow.alignment ?? {}),
        vertical: "middle",
        horizontal: "center"
    };
    sheet.mergeCells("A2:R2");

    addedRow = sheet.addRow([`January 1, ${reportYear} - December 31, ${reportYear}`]);
    addedRow.font = {
        name: "Helvetica",
        bold: true,
        size: 16
    };
    addedRow.alignment = {
        ...(addedRow.alignment ?? {}),
        vertical: "middle",
        horizontal: "center"
    };
    sheet.mergeCells("A3:R3");

    // empty row
    addedRow = sheet.addRow([]);
    addedRow.height = 16;
    sheet.mergeCells("A4:R4");

    const headerRow = [
        "GL Number",
        "GL Name",
        ...new Array(12).fill(0).map((_, month) => new Date(Date.UTC(reportYear, month, 1))),
        `${tab == BudgetingType.REFORECAST ? config.year + " Reforecast" : (config.year + 1) + " Budget"}`,
        `${referenceYear.year} ${referenceYear.type == VersionType.YearTotal ? "Reforecast" : referenceYear.type == VersionType.Budget ? "Budget" : "Actuals"}`,
        "Var $",
        "Var %",
        "Notes",
    ];

    addedRow = sheet.addRow(headerRow);
    addedRow.height = 30;
    addedRow.font = {
        name: "Helvetica",
        bold: true,
        size: 14
    };
    addedRow.alignment = {
        ...(addedRow.alignment ?? {}),
        vertical: "middle"
    };

    addedRow.border = {
        ...(addedRow.border ?? {}),
        bottom: {
            style: "thin",
            color: {
                argb: "45000000"
            }
        }
    };

    // col index is 1 based
    addedRow.eachCell((cell, col) => {
        if (col >= 3 && col <= 14) {
            cell.numFmt = "mmm yy";
        }
        if (col >= 3 && col <= 18) {
            cell.alignment = {
                ...(cell.alignment ?? {}),
                horizontal: "right"
            };
        }
        if (col == 19) {
            cell.alignment = {
                ...(cell.alignment ?? {}),
                horizontal: "center"
            };
        }
    });

    for (const record of data.getPropertyBudgetExportSingleYear ?? []) {
        const isIncome = config.chartOfAccountsConfig.getIsIncome(record.id);
        const variance$ = (record.yearTotal - record.referenceYearTotal) * (isIncome ? 1 : -1);
        const variancePct = record.referenceYearTotal ? variance$ / Math.abs(record.referenceYearTotal) : NaN;

        // skip zero rows
        let allZeroes = record.yearTotal == 0 && record.referenceYearTotal == 0;
        let monthIndex = 0;
        while (allZeroes && monthIndex < 12) {
            allZeroes = allZeroes && record.yearValues[monthIndex] == 0;
            monthIndex++;
        }

        if (allZeroes) {
            continue;
        }

        const addedDataRow = sheet.addRow([
            `${record.glNumber}` as string,
            `${record.glName}`,
            ...record.yearValues,
            record.yearTotal,
            record.referenceYearTotal,
            variance$,
            isNaN(variancePct) ? "" : variancePct,
            record.note ?? ""
        ]);
        addedDataRow.height = 30;
        addedDataRow.font = {
            name: "Helvetica",
            bold: record.type != FinancialEntityType.Account,
            size: 14
        };
        addedDataRow.alignment = {
            ...(addedDataRow.alignment ?? {}),
            vertical: "middle"
        };
        addedDataRow.border = {
            ...(addedRow.border ?? {}),
            bottom: {
                style: "thin",
                color: {
                    argb: "BFBFBF"
                }
            }
        };

        // col index is 1 based
        addedDataRow.eachCell((cell, col) => {
            if (col >= 3 && col <= 17) {
                cell.numFmt = "_(* #,##0_);_(* (#,##0);_(* -??_);_(@_)";
            }
            if (col >= 3) {
                cell.alignment = {
                    ...(cell.alignment ?? {}),
                    horizontal: "right"
                };
            }
            if (col == 1) {
                cell.alignment = {
                    ...(cell.alignment ?? {}),
                    horizontal: "center"
                };
            }
            if (col == 18) {
                cell.numFmt = "0.0%";
            }
            if (col == 19) {
                cell.alignment = {
                    ...(cell.alignment ?? {}),
                    wrapText: true,
                    horizontal: "left"
                }
            }
        });
    }

    const result = await workbook.xlsx.writeBuffer();

    return new Blob([result]);
}

export async function formatForExcelFullNoZerosWithThisYearBudget(
    { tab, referenceYear, data, config, extraReferenceYear }:
        {
            tab: BudgetingType;
            referenceYear: YearDefinition;
            extraReferenceYear: {
                referenceYear: number,
                referenceValueType: VersionType
            },
            data: ExportPropertyBudgetQuery;
            config: AppConfig;
        }
): Promise<Blob> {

    const workbook = new ExcelJS.Workbook();
    workbook.creator = "Vizibly";
    workbook.lastModifiedBy = "Vizibly";
    workbook.created = new Date();
    workbook.modified = new Date();

    const reportYear = tab == BudgetingType.REFORECAST ? config.year : config.year + 1;

    const tabCaption = `${reportYear} ${tab == BudgetingType.REFORECAST ? "Reforecast" : "Budget"} - ${config.properties.currentProperty?.name}`;
    // create a sheet where the grid lines are hidden
    const sheet = workbook.addWorksheet(
        tabCaption,
        {
            views: [{
                showGridLines: false,
                zoomScale: 86,
                state: "frozen",
                xSplit: 2,
                ySplit: 5
            }]
        }
    );

    const glNumberColumnWidth = 15;
    const glNameColumnWidth = 42;
    const monthColumnWidth = 15;
    const yearTotalColumnWidth = 20;
    const referenceYearColumnWidth = 20;
    const var$ColumnWidth = 20;
    const varPctColumnWidth = 10;
    const notesColumnWidth = 80;

    sheet.columns = [
        {
            width: glNumberColumnWidth
        },
        {
            width: glNameColumnWidth
        },
        ...new Array(12).fill(0).map(_ => ({
            width: monthColumnWidth
        })),
        {
            width: yearTotalColumnWidth
        },
        {
            width: referenceYearColumnWidth
        },
        {
            width: var$ColumnWidth
        },
        {
            width: varPctColumnWidth
        },
        {
            width: referenceYearColumnWidth
        },
        {
            width: notesColumnWidth
        }
    ];

    let addedRow = sheet.addRow([config.properties.currentProperty?.name]);
    addedRow.font = {
        name: "Helvetica",
        bold: true,
        size: 16
    };
    addedRow.alignment = {
        ...(addedRow.alignment ?? {}),
        vertical: "middle",
        horizontal: "center"
    };
    sheet.mergeCells("A1:R1");

    addedRow = sheet.addRow([`Operating ${tab == BudgetingType.REFORECAST ? "Reforecast" : "Budget"}`]);
    addedRow.font = {
        name: "Helvetica",
        bold: true,
        size: 16
    };
    addedRow.alignment = {
        ...(addedRow.alignment ?? {}),
        vertical: "middle",
        horizontal: "center"
    };
    sheet.mergeCells("A2:R2");

    addedRow = sheet.addRow([`January 1, ${reportYear} - December 31, ${reportYear}`]);
    addedRow.font = {
        name: "Helvetica",
        bold: true,
        size: 16
    };
    addedRow.alignment = {
        ...(addedRow.alignment ?? {}),
        vertical: "middle",
        horizontal: "center"
    };
    sheet.mergeCells("A3:R3");

    // empty row
    addedRow = sheet.addRow([]);
    addedRow.height = 16;
    sheet.mergeCells("A4:R4");

    const headerRow = [
        "GL Number",
        "GL Name",
        ...new Array(12).fill(0).map((_, month) => new Date(Date.UTC(reportYear, month, 1))),
        `${tab == BudgetingType.REFORECAST ? config.year + " Reforecast" : (config.year + 1) + " Budget"}`,
        `${referenceYear.year} ${referenceYear.type == VersionType.YearTotal ? "Reforecast" : referenceYear.type == VersionType.Budget ? "Budget" : "Actuals"}`,
        "Var $",
        "Var %",
        `${extraReferenceYear.referenceYear} ${extraReferenceYear.referenceValueType == VersionType.YearTotal ? "Reforecast" : extraReferenceYear.referenceValueType == VersionType.Budget ? "Budget" : "Actuals"}`,
        "Notes",
    ];

    addedRow = sheet.addRow(headerRow);
    addedRow.height = 30;
    addedRow.font = {
        name: "Helvetica",
        bold: true,
        size: 14
    };
    addedRow.alignment = {
        ...(addedRow.alignment ?? {}),
        vertical: "middle"
    };

    addedRow.border = {
        ...(addedRow.border ?? {}),
        bottom: {
            style: "thin",
            color: {
                argb: "45000000"
            }
        }
    };

    // col index is 1 based
    addedRow.eachCell((cell, col) => {
        if (col >= 3 && col <= 14) {
            cell.numFmt = "mmm yy";
        }
        if (col >= 3 && col <= 19) {
            cell.alignment = {
                ...(cell.alignment ?? {}),
                horizontal: "right"
            };
        }
        if (col == 20) {
            cell.alignment = {
                ...(cell.alignment ?? {}),
                horizontal: "center"
            };
        }
    });

    for (const record of data.getPropertyBudgetExportSingleYear ?? []) {
        const isIncome = config.chartOfAccountsConfig.getIsIncome(record.id);
        const variance$ = (record.yearTotal - record.referenceYearTotal) * (isIncome ? 1 : -1);
        const variancePct = record.referenceYearTotal ? variance$ / Math.abs(record.referenceYearTotal) : NaN;

        const extraReferenceYearTotal = record.extraReferenceYearTotal == undefined ? NaN : record.extraReferenceYearTotal;

        // skip zero rows
        let allZeroes = record.yearTotal == 0 && record.referenceYearTotal == 0;
        let monthIndex = 0;
        while (allZeroes && monthIndex < 12) {
            allZeroes = allZeroes && record.yearValues[monthIndex] == 0;
            monthIndex++;
        }

        if (allZeroes) {
            continue;
        }

        const addedDataRow = sheet.addRow([
            `${record.glNumber}` as string,
            `${record.glName}`,
            ...record.yearValues,
            record.yearTotal,
            record.referenceYearTotal,
            variance$,
            isNaN(variancePct) ? "" : variancePct,
            isNaN(extraReferenceYearTotal) ? "" : extraReferenceYearTotal,
            record.note ?? ""
        ]);
        addedDataRow.height = 30;
        addedDataRow.font = {
            name: "Helvetica",
            bold: record.type != FinancialEntityType.Account,
            size: 14
        };
        addedDataRow.alignment = {
            ...(addedDataRow.alignment ?? {}),
            vertical: "middle"
        };
        addedDataRow.border = {
            ...(addedRow.border ?? {}),
            bottom: {
                style: "thin",
                color: {
                    argb: "BFBFBF"
                }
            }
        };

        // col index is 1 based
        addedDataRow.eachCell((cell, col) => {
            if (col >= 3 && col <= 17 || col == 19) {
                cell.numFmt = "_(* #,##0_);_(* (#,##0);_(* -??_);_(@_)";
            }
            if (col >= 3) {
                cell.alignment = {
                    ...(cell.alignment ?? {}),
                    horizontal: "right"
                };
            }
            if (col == 1) {
                cell.alignment = {
                    ...(cell.alignment ?? {}),
                    horizontal: "center"
                };
            }
            if (col == 18) {
                cell.numFmt = "0.0%";
            }
            if (col == 20) {
                cell.alignment = {
                    ...(cell.alignment ?? {}),
                    wrapText: true,
                    horizontal: "left"
                }
            }
        });
    }

    const result = await workbook.xlsx.writeBuffer();

    return new Blob([result]);
}

export async function formatForExcelWithoutRawAccounts(
    { tab, compareToYear, referenceYear, data, config }:
        {
            tab: BudgetingType;
            compareToYear: YearDefinition;
            referenceYear: {
                referenceYear: number;
                referenceValueType: VersionType;
            };
            data: ExportPropertyBudgetQuery;
            config: AppConfig;
        }
): Promise<Blob> {

    const workbook = new ExcelJS.Workbook();
    workbook.creator = "Vizibly";
    workbook.lastModifiedBy = "Vizibly";
    workbook.created = new Date();
    workbook.modified = new Date();

    const reportYear = tab == BudgetingType.REFORECAST ? config.year : config.year + 1;

    const tabCaption = `${reportYear} ${tab == BudgetingType.REFORECAST ? "Reforecast" : "Budget"} - ${config.properties.currentProperty?.name}`;
    // create a sheet where the grid lines are hidden
    const sheet = workbook.addWorksheet(
        tabCaption,
        {
            views: [{
                showGridLines: false,
                zoomScale: 86
            }]
        }
    );

    const glNumberColumnWidth = 15;
    const glNameColumnWidth = 42;
    const yearTotalColumnWidth = 20;
    const compareToYearColumnWidth = 40;
    const compareToVar$ColumnWidth = 20;
    const compareToVarPctColumnWidth = 10;
    const referenceYearColumnWidth = 20;
    const referenceVar$ColumnWidth = 20;
    const referenceVarPctColumnWidth = 10;
    const notesColumnWidth = 85;

    sheet.columns = [
        {
            width: glNumberColumnWidth
        },
        {
            width: glNameColumnWidth
        },
        {
            width: yearTotalColumnWidth
        },
        {
            width: compareToYearColumnWidth
        },
        {
            width: compareToVar$ColumnWidth
        },
        {
            width: compareToVarPctColumnWidth
        },
        {
            width: referenceYearColumnWidth
        },
        {
            width: referenceVar$ColumnWidth
        },
        {
            width: referenceVarPctColumnWidth
        },
        {
            width: notesColumnWidth
        }
    ];

    let addedRow = sheet.addRow([config.properties.currentProperty?.name]);
    addedRow.font = {
        name: "Helvetica",
        bold: true,
        size: 22
    };
    addedRow.alignment = {
        ...(addedRow.alignment ?? {}),
        vertical: "middle",
        horizontal: "center"
    };
    sheet.mergeCells("A1:J1");

    let title = `${config.year} Reforecast`;

    if (tab == BudgetingType.BUDGET) {
        title = `${config.year + 1} Operating Budget`;
    }
    addedRow = sheet.addRow([title]);
    addedRow.font = {
        name: "Helvetica",
        bold: true,
        size: 22
    };
    addedRow.alignment = {
        ...(addedRow.alignment ?? {}),
        vertical: "middle",
        horizontal: "center"
    };
    sheet.mergeCells("A2:J2");

    // empty row
    addedRow = sheet.addRow([]);
    addedRow.height = 16;

    const headerRow = [
        "GL",
        "Account",
        `${tab == BudgetingType.REFORECAST ? config.year + " Reforecast" : (config.year + 1) + " Budget"}`,
        `Compare to ${compareToYear.year} ${compareToYear.type == VersionType.YearTotal ? "Reforecast" : compareToYear.type == VersionType.Budget ? "Budget" : "Actuals"}`,
        "Var $",
        "Var %",
        `${referenceYear.referenceYear} ${referenceYear.referenceValueType == VersionType.YearTotal ? "Reforecast" : "Budget"}`,
        "Var $",
        "Var %"
    ];

    addedRow = sheet.addRow(headerRow);
    addedRow.height = 30;
    addedRow.font = {
        name: "Helvetica",
        bold: true,
        size: 14
    };
    addedRow.alignment = {
        ...(addedRow.alignment ?? {}),
        vertical: "middle"
    };

    addedRow.eachCell((cell, col) => {
        if (col >= 3 && col < 10) {
            cell.alignment = {
                ...(cell.alignment ?? {}),
                horizontal: "right"
            };
        }
    });

    for (const record of data.getPropertyBudgetExportSingleYear ?? []) {
        const isRawAccount = record.type == FinancialEntityType.Account;

        if (isRawAccount) {
            continue;
        }

        const isIncome = config.chartOfAccountsConfig.getIsIncome(record.id);
        const compareToVariance$ = (record.yearTotal - record.referenceYearTotal) * (isIncome ? 1 : -1);
        const compareToVariancePct = record.referenceYearTotal ? compareToVariance$ / Math.abs(record.referenceYearTotal) : NaN;

        const extraReferenceYearTotal = record.extraReferenceYearTotal == undefined ? NaN : record.extraReferenceYearTotal;
        const extraReferenceVariance$ = (record.yearTotal -  extraReferenceYearTotal) * (isIncome ? 1 : -1);
        const extraReferenceVariancePct = record.referenceYearTotal ? compareToVariance$ / Math.abs(record.referenceYearTotal) : NaN;

        // skip zero rows
        let allZeroes = record.yearTotal == 0 && record.referenceYearTotal == 0;
        let monthIndex = 0;
        while (allZeroes && monthIndex < 12) {
            allZeroes = allZeroes && record.yearValues[monthIndex] == 0;
            monthIndex++;
        }

        if (allZeroes) {
            continue;
        }

        const addedDataRow = sheet.addRow([
            `${record.glNumber}` as string,
            `${record.glName}`,
            isNaN(record.yearTotal) ? 0 : record.yearTotal,
            isNaN(record.referenceYearTotal) ? 0 : record.referenceYearTotal,
            isNaN(compareToVariance$) ? 0 : compareToVariance$,
            isNaN(compareToVariancePct) ? 0 : compareToVariancePct,
            isNaN(extraReferenceYearTotal) ? 0 : extraReferenceYearTotal,
            isNaN(extraReferenceVariance$) ? 0 : extraReferenceVariance$,
            isNaN(extraReferenceVariancePct) ? 0 : extraReferenceVariancePct
        ]);
        addedDataRow.height = 30;
        addedDataRow.font = {
            name: "Helvetica",
            size: 14
        };
        addedDataRow.alignment = {
            ...(addedDataRow.alignment ?? {}),
            vertical: "middle"
        };

        // col index is 1 based
        addedDataRow.eachCell((cell, col) => {
            if (3 <= col && col <= 5 || col == 7 || col == 8) {
                cell.numFmt = "_(* #,##0_);_(* (#,##0);_(* -??_);_(@_)";
            }
            if (col == 6 || col == 9) {
                cell.numFmt = "0.0%";
            }
        });
    }

    const result = await workbook.xlsx.writeBuffer();

    return new Blob([result]);
}

export async function formatForExcelFullNoZerosWithMetrics(
    { tab, referenceYear, data, config }:
        {
            tab: BudgetingType;
            referenceYear: Pick<YearDefinition, "year" | "type">;
            data: ExportPropertyBudgetQuery;
            config: Pick<AppConfig, "year" | "chartOfAccountsConfig">
                    & {
                            properties: Pick<AppConfig["properties"], "unitsCount" | "unitsTotalSQFT">
                            & {
                                    currentProperty: Pick<Property, "name"> | undefined
                            }
                    };
        }
): Promise<Blob> {

    const workbook = new ExcelJS.Workbook();
    workbook.creator = "Vizibly";
    workbook.lastModifiedBy = "Vizibly";
    workbook.created = new Date();
    workbook.modified = new Date();

    const reportYear = tab == BudgetingType.REFORECAST ? config.year : config.year + 1;

    const units = config.properties.unitsCount ?? Number.MAX_SAFE_INTEGER;
    const sf = config.properties.unitsTotalSQFT ?? Number.MAX_VALUE;

    const tabCaption = `${reportYear} ${tab == BudgetingType.REFORECAST ? "Reforecast" : "Budget"} - ${config.properties.currentProperty?.name}`;
    // create a sheet where the grid lines are hidden

    function setLeftBorder(row: Row, cols: number[]) {
        for (const col of cols) {
            const cell = row.getCell(col);
            cell.border = {
                ...(cell.border ?? {}),
                left: {
                    style: "thin",
                    color: {
                        argb: "45000000"
                    }
                }
            };
        }
    }

    const sheet = workbook.addWorksheet(
        tabCaption,
        {
            views: [{
                showGridLines: false,
                zoomScale: 70,
                state: "frozen",
                xSplit: 2,
                ySplit: 6
            }]
        }
    );

    const glNumberColumnWidth = 15;
    const glNameColumnWidth = 42;
    const monthColumnWidth = 15;
    const yearTotalColumnWidth = 20;
    const budgetPerUnit = 20;
    const budgetPerSF = 20;
    const referenceYearColumnWidth = 20;
    const var$ColumnWidth = 20;
    const varPctColumnWidth = 10;
    const notesColumnWidth = 80;

    sheet.columns = [
        {
            width: glNumberColumnWidth
        },
        {
            width: glNameColumnWidth
        },
        ...new Array(12).fill(0).map(_ => ({
            width: monthColumnWidth
        })),
        {
            width: yearTotalColumnWidth
        },
        {
            width: budgetPerUnit
        },
        {
            width: budgetPerSF
        },
        {
            width: referenceYearColumnWidth
        },
        {
            width: var$ColumnWidth
        },
        {
            width: varPctColumnWidth
        },
        {
            width: notesColumnWidth
        }
    ];

    let addedRow = sheet.addRow([config.properties.currentProperty?.name]);
    addedRow.font = {
        name: "Helvetica",
        bold: true,
        size: 16
    };
    addedRow.alignment = {
        ...(addedRow.alignment ?? {}),
        vertical: "middle",
        horizontal: "center"
    };
    sheet.mergeCells("A1:T1");

    addedRow = sheet.addRow([`Operating ${tab == BudgetingType.REFORECAST ? "Reforecast" : "Budget"}`]);
    addedRow.font = {
        name: "Helvetica",
        bold: true,
        size: 16
    };
    addedRow.alignment = {
        ...(addedRow.alignment ?? {}),
        vertical: "middle",
        horizontal: "center"
    };
    sheet.mergeCells("A2:T2");

    addedRow = sheet.addRow([`January 1, ${reportYear} - December 31, ${reportYear}`]);
    addedRow.font = {
        name: "Helvetica",
        bold: true,
        size: 16
    };
    addedRow.alignment = {
        ...(addedRow.alignment ?? {}),
        vertical: "middle",
        horizontal: "center"
    };
    sheet.mergeCells("A3:T3");

    // Unit/Sqft row
    addedRow = sheet.addRow(["","","","","","","","","","","","","","","","Units", "Square Feet"]);
    addedRow.font = {
        name: "Helvetica",
        bold: true,
        size: 16
    };
    addedRow.alignment = {
        ...(addedRow.alignment ?? {}),
        vertical: "middle",
        horizontal: "center"
    };
    setLeftBorder(addedRow, [16, 17, 18]);
    // empty row
    addedRow = sheet.addRow(["","","","","","","","","","","","","","","",units, sf]);
    addedRow.font = {
        name: "Helvetica",
        size: 11
    };
    addedRow.alignment = {
        ...(addedRow.alignment ?? {}),
        vertical: "middle",
        horizontal: "center"
    };
    setLeftBorder(addedRow, [16, 17, 18]);

    const examineTitle = tab == BudgetingType.REFORECAST ? "Reforecast" : "Budget";
    const headerRow = [
        "GL Number",
        "GL Name",
        ...new Array(12).fill(0).map((_, month) => new Date(Date.UTC(reportYear, month, 1))),
        `${reportYear} ${examineTitle}`,
        `${examineTitle}/Unit`,
        `${examineTitle}/SF`,
        `${referenceYear.year} ${referenceYear.type == VersionType.YearTotal ? "Reforecast" : referenceYear.type == VersionType.Budget ? "Budget" : "Actuals"}`,
        "Var $",
        "Var %",
        "Notes",
    ];

    addedRow = sheet.addRow(headerRow);
    addedRow.height = 30;
    addedRow.font = {
        name: "Helvetica",
        bold: true,
        size: 14
    };
    addedRow.alignment = {
        ...(addedRow.alignment ?? {}),
        vertical: "middle"
    };

    addedRow.border = {
        ...(addedRow.border ?? {}),
        bottom: {
            style: "thin",
            color: {
                argb: "45000000"
            }
        }
    };

    // col index is 1 based
    addedRow.eachCell((cell, col) => {
        if (col >= 3 && col <= 14) {
            cell.numFmt = "mmm yy";
        }
        if (col >= 3 && col <= 18) {
            cell.alignment = {
                ...(cell.alignment ?? {}),
                horizontal: "right"
            };
        }
        if (col == 19) {
            cell.alignment = {
                ...(cell.alignment ?? {}),
                horizontal: "center"
            };
        }
    });
    setLeftBorder(addedRow, [16, 17, 18]);

    for (const record of data.getPropertyBudgetExportSingleYear ?? []) {
        const isIncome = config.chartOfAccountsConfig.getIsIncome(record.id);
        const variance$ = (record.yearTotal - record.referenceYearTotal) * (isIncome ? 1 : -1);
        const variancePct = record.referenceYearTotal ? variance$ / Math.abs(record.referenceYearTotal) : NaN;

        // skip zero rows
        let allZeroes = record.yearTotal == 0 && record.referenceYearTotal == 0;
        let monthIndex = 0;
        while (allZeroes && monthIndex < 12) {
            allZeroes = allZeroes && record.yearValues[monthIndex] == 0;
            monthIndex++;
        }

        if (allZeroes) {
            continue;
        }

        const addedDataRow = sheet.addRow([
            `${record.glNumber}` as string,
            `${record.glName}`,
            ...record.yearValues,
            record.yearTotal,
            record.yearTotal / units,
            record.yearTotal / sf,
            record.referenceYearTotal,
            variance$,
            isNaN(variancePct) ? "" : variancePct,
            record.note ?? ""
        ]);
        addedDataRow.height = 30;
        addedDataRow.font = {
            name: "Helvetica",
            bold: record.type != FinancialEntityType.Account,
            size: 14
        };
        addedDataRow.alignment = {
            ...(addedDataRow.alignment ?? {}),
            vertical: "middle"
        };
        addedDataRow.border = {
            ...(addedRow.border ?? {}),
            bottom: {
                style: "thin",
                color: {
                    argb: "BFBFBF"
                }
            }
        };

        // col index is 1 based
        addedDataRow.eachCell((cell, col) => {
            if (col >= 3 && col <= 16 || col == 18 || col == 19) {
                cell.numFmt = "_(* #,##0_);_(* (#,##0);_(* -??_);_(@_)";
            }
            if (col == 17) {
                cell.numFmt = "_(* #,##0.00_);_(* (#,##0.00);_(* -??_);_(@_)";
            }
            if (col >= 3) {
                cell.alignment = {
                    ...(cell.alignment ?? {}),
                    horizontal: "right"
                };
            }
            if (col == 1) {
                cell.alignment = {
                    ...(cell.alignment ?? {}),
                    horizontal: "center"
                };
            }
            if (col == 20) {
                cell.numFmt = "0.0%";
            }
            if (col == 21) {
                cell.alignment = {
                    ...(cell.alignment ?? {}),
                    wrapText: true,
                    horizontal: "left"
                };
            }
        });
        setLeftBorder(addedDataRow, [16, 17, 18]);
    }

    const result = await workbook.xlsx.writeBuffer();

    return new Blob([result]);
}

export async function formatForExcelT12Report(
    { data, config }:
        {
            data: ExportPropertyBudgetT12ReportQuery;
            config: AppConfig;
        }
): Promise<Blob> {

    const workbook = new ExcelJS.Workbook();
    workbook.creator = "Vizibly";
    workbook.lastModifiedBy = "Vizibly";
    workbook.created = new Date();
    workbook.modified = workbook.created;

    const units = data.getPropertyBudgetT12Report.unitCount;
    const sf = data.getPropertyBudgetT12Report.totalSqFoot;
    const reportYear = config.year + 1;

    const tabCaption = `${config.year + 1} Budget - ${config.properties.currentProperty?.name}`;
    // create a sheet where the grid lines are hidden

    function setLeftBorder(row: Row, cols: number[]) {
        for (const col of cols) {
            const cell = row.getCell(col);
            cell.border = {
                ...(cell.border ?? {}),
                left: {
                    style: "thin",
                    color: {
                        argb: "45000000"
                    }
                }
            };
        }
    }

    const sheet = workbook.addWorksheet(
        tabCaption,
        {
            views: [{
                showGridLines: false,
                zoomScale: 70,
                state: "frozen",
                xSplit: 2,
                ySplit: 6
            }]
        }
    );

    const glNumberColumnWidth = 15;
    const glNameColumnWidth = 42;
    const monthColumnWidth = 15;
    const yearTotalColumnWidth = 23;
    const budgetPerUnit = 20;
    const budgetPerSF = 20;
    const pctOfRevenueExpensesWidth = 37;
    const annualizedTotalWidth = 20;
    const annualizedGrowthPctWidth = 20;
    const pctOfAnnualizedRevenueExpensesWidth = 29;
    const noteColumnWidth = 50;

    sheet.columns = [
        {
            width: glNumberColumnWidth
        },
        {
            width: glNameColumnWidth
        },
        ...new Array(12).fill(0).map(_ => ({
            width: monthColumnWidth
        })),
        {
            width: yearTotalColumnWidth
        },
        {
            width: budgetPerUnit
        },
        {
            width: budgetPerSF
        },
        {
            width: pctOfRevenueExpensesWidth
        },
        {
            width: annualizedTotalWidth
        },
        {
            width: annualizedGrowthPctWidth
        },
        {
            width: pctOfAnnualizedRevenueExpensesWidth
        },
        {
            width: annualizedTotalWidth
        },
        {
            width: annualizedGrowthPctWidth
        },
        {
            width: pctOfAnnualizedRevenueExpensesWidth
        },
        {
            width: noteColumnWidth
        }
    ];

    let addedRow = sheet.addRow([config.properties.currentProperty?.name]);
    addedRow.font = {
        name: "Helvetica",
        bold: true,
        size: 16
    };
    addedRow.alignment = {
        ...(addedRow.alignment ?? {}),
        vertical: "middle",
        horizontal: "center"
    };
    sheet.mergeCells("A1:T1");

    addedRow = sheet.addRow(["Operating Budget"]);
    addedRow.font = {
        name: "Helvetica",
        bold: true,
        size: 16
    };
    addedRow.alignment = {
        ...(addedRow.alignment ?? {}),
        vertical: "middle",
        horizontal: "center"
    };
    sheet.mergeCells("A2:T2");

    addedRow = sheet.addRow([`January 1, ${reportYear} - December 31, ${reportYear}`]);
    addedRow.font = {
        name: "Helvetica",
        bold: true,
        size: 16
    };
    addedRow.alignment = {
        ...(addedRow.alignment ?? {}),
        vertical: "middle",
        horizontal: "center"
    };
    sheet.mergeCells("A3:T3");

    // Unit/Sqft row
    addedRow = sheet.addRow(["","","","","","","","","","","","","","","","Units", "Square Feet", "", `Annualized ${config.startReforecastMonthIndex} Months of Actual Data`, "","","T12"]);
    addedRow.font = {
        name: "Helvetica",
        bold: true,
        size: 16
    };
    addedRow.alignment = {
        ...(addedRow.alignment ?? {}),
        vertical: "middle",
        horizontal: "center"
    };
    sheet.mergeCells("S4:U4");
    sheet.mergeCells("V4:X4");
    setLeftBorder(addedRow, [16, 17, 18]);
    // empty row
    addedRow = sheet.addRow(["","","","","","","","","","","","","","","",units, sf]);
    addedRow.font = {
        name: "Helvetica",
        size: 11
    };
    addedRow.alignment = {
        ...(addedRow.alignment ?? {}),
        vertical: "middle",
        horizontal: "center"
    };
    setLeftBorder(addedRow, [16, 17, 18]);

    const headerRow = [
        "GL Number",
        "GL Name",
        ...new Array(12).fill(0).map((_, month) => new Date(Date.UTC(reportYear, month, 1))),
        `Total ${reportYear} Budget`,
        `Per Unit`,
        `Per Sq. Foot`,
        "% of Total Revenue/Expense",
        "Total",
        "Growth %",
        "% of Revenue/Expense",
        "Total",
        "Growth %",
        "% of Revenue/Expense",
        "Budget Note"
    ];

    addedRow = sheet.addRow(headerRow);
    addedRow.height = 30;
    addedRow.font = {
        name: "Helvetica",
        bold: true,
        size: 14
    };
    addedRow.alignment = {
        ...(addedRow.alignment ?? {}),
        vertical: "middle"
    };

    addedRow.border = {
        ...(addedRow.border ?? {}),
        bottom: {
            style: "thin",
            color: {
                argb: "45000000"
            }
        }
    };

    // col index is 1 based
    addedRow.eachCell((cell, col) => {
        if (col >= 3 && col <= 14) {
            cell.numFmt = "mmm yy";
        }
        if (col >= 3 && col <= 18) {
            cell.alignment = {
                ...(cell.alignment ?? {}),
                horizontal: "right"
            };
        }
        if (col == 19) {
            cell.alignment = {
                ...(cell.alignment ?? {}),
                horizontal: "center"
            };
        }
    });
    setLeftBorder(addedRow, [16, 17, 18]);

    for (const record of data.getPropertyBudgetT12Report.records ?? []) {
        const isIncome = config.chartOfAccountsConfig.getIsIncome(record.accountId);
        const recordBudgetTotal = record.budgetValues.sum();
        let pctOfTotalRevenueExpense = 0;
        let pctOfTotalRevenueExpenseAnnualized = 0;
        let pctOfTotalRevenueExpenseT12 = 0;
        if (isIncome) {
            if (data.getPropertyBudgetT12Report.totalRevenueBudget) {
                pctOfTotalRevenueExpense = recordBudgetTotal / data.getPropertyBudgetT12Report.totalRevenueBudget;
            }
            if (data.getPropertyBudgetT12Report.totalRevenueActualAnnualized) {
                pctOfTotalRevenueExpenseAnnualized = record.annualizedNMonthsActualsTotal / data.getPropertyBudgetT12Report.totalRevenueActualAnnualized;
            }
            if (data.getPropertyBudgetT12Report.totalRevenueActualT12) {
                pctOfTotalRevenueExpenseT12 = record.twelveMonthsActualsTotal / data.getPropertyBudgetT12Report.totalRevenueActualT12;
            }
        }
        else {
            if (data.getPropertyBudgetT12Report.totalExpensesBudget) {
                pctOfTotalRevenueExpense = recordBudgetTotal / data.getPropertyBudgetT12Report.totalExpensesBudget;
            }
            if (data.getPropertyBudgetT12Report.totalExpensesActualAnnualized) {
                pctOfTotalRevenueExpenseAnnualized = record.annualizedNMonthsActualsTotal / data.getPropertyBudgetT12Report.totalExpensesActualAnnualized;
            }
            if (data.getPropertyBudgetT12Report.totalExpensesActualT12) {
                pctOfTotalRevenueExpenseT12 = record.twelveMonthsActualsTotal / data.getPropertyBudgetT12Report.totalExpensesActualT12;
            }
        }
        let annualizedGrowthPct = 0;
        let t12GrowthPct = 0;
        if (record.annualizedNMonthsActualsTotal) {
            annualizedGrowthPct = (recordBudgetTotal - record.annualizedNMonthsActualsTotal) / Math.abs(record.annualizedNMonthsActualsTotal);
        }
        if (record.twelveMonthsActualsTotal) {
            t12GrowthPct = (recordBudgetTotal - record.twelveMonthsActualsTotal) / Math.abs(record.twelveMonthsActualsTotal);
        }

        // skip zero rows
        let allZeroes = Math.abs(record.annualizedNMonthsActualsTotal) < 0.01 && Math.abs(record.twelveMonthsActualsTotal) < 0.01;
        let monthIndex = 0;
        while (allZeroes && monthIndex < 12) {
            allZeroes = allZeroes && Math.abs(record.budgetValues[monthIndex] ?? 0) < 0.01;
            monthIndex++;
        }

        if (allZeroes) {
            continue;
        }

        const addedDataRow = sheet.addRow([
            `${record.glNumber}` as string,
            `${record.glName}`,
            ...record.budgetValues,
            recordBudgetTotal,
            recordBudgetTotal / units,
            recordBudgetTotal / sf,
            pctOfTotalRevenueExpense,
            record.annualizedNMonthsActualsTotal,
            annualizedGrowthPct,
            pctOfTotalRevenueExpenseAnnualized,
            record.twelveMonthsActualsTotal,
            t12GrowthPct,
            pctOfTotalRevenueExpenseT12,
            record.note ?? ""
        ]);
        addedDataRow.height = 30;
        addedDataRow.font = {
            name: "Helvetica",
            bold: record.type != FinancialEntityType.Account,
            size: 14
        };
        addedDataRow.alignment = {
            ...(addedDataRow.alignment ?? {}),
            vertical: "middle"
        };
        addedDataRow.border = {
            ...(addedRow.border ?? {}),
            bottom: {
                style: "thin",
                color: {
                    argb: "BFBFBF"
                }
            }
        };

        // col index is 1 based
        addedDataRow.eachCell((cell, col) => {
            if (col >= 3 && col <= 16 || col == 18 || col == 19 || col == 22) {
                cell.numFmt = "_(* #,##0_);_(* (#,##0);_(* -??_);_(@_)";
            }
            if (col == 17) {
                cell.numFmt = "_(* #,##0.00_);_(* (#,##0.00);_(* -??_);_(@_)";
            }
            if (col >= 3) {
                cell.alignment = {
                    ...(cell.alignment ?? {}),
                    horizontal: "right"
                };
            }
            if (col == 1) {
                cell.alignment = {
                    ...(cell.alignment ?? {}),
                    horizontal: "center"
                };
            }
            if (col == 18 || col == 20 || col == 21 || col == 23 || col == 24) {
                cell.numFmt = "0.0%";
            }
            if (col == 25) {
                cell.alignment = {
                    ...(cell.alignment ?? {}),
                    wrapText: true,
                    horizontal: "left"
                };
            }

        });
        setLeftBorder(addedDataRow, [16, 17, 18]);
    }

    const result = await workbook.xlsx.writeBuffer();

    return new Blob([result]);
}

