import { AssumptionRowModel } from '../models/AssumptionRowModel';
import { GetMvrBudgetAssumptionsForPropertyAndAccountQuery } from '../../../../../../../../../__generated__/generated_types';
import { AssumptionRowValueType } from '../enums/AssumptionRowValueTypeEnum';


/**
 * Maps WorksheetDriverModel.worksheetLines to
 * AssumptionRowModel[]: AssumptionRowModel(label = description, value=line value for the monthIndex)
 *
 * if isCreatePlainList === false then returns
 *      AssumptionRowModel(
 *          label = "Line Items"
 *          value = sum of all values across line items for the given month index
 *          children = AssumptionRowModel[] from above
 *      )
 * otherwise returns AssumptionRowModel[] from above
 * @param worksheetLinesData
 * @param monthIndex
 * @param isCreatePlainList
 * @returns
 */
type TWorksheetLinesData = GetMvrBudgetAssumptionsForPropertyAndAccountQuery["singlePropertyAccount"]["worksheetDriver"]

export function mapWorksheetLines(
    worksheetLinesData: TWorksheetLinesData,
    monthIndex: number,
    isCreatePlainList: boolean
): AssumptionRowModel[] {
    let resultRows = [] as AssumptionRowModel[];

    if (worksheetLinesData && worksheetLinesData != null && worksheetLinesData.worksheetLines.length > 0) {
        let totalValue = 0;
        const mappedLines = [] as AssumptionRowModel[];

        for(const worksheetLine of worksheetLinesData.worksheetLines) {
            const value = worksheetLine.values[monthIndex] ?? 0;
            totalValue += value;
            const worksheetLineMapped: AssumptionRowModel = {
                label: worksheetLine.description,
                value: value,
                valueType: AssumptionRowValueType.DOLLAR,
                children: []
            };
            mappedLines.push(worksheetLineMapped);
        }

        if (isCreatePlainList) {
            resultRows = mappedLines;
        }
        else {
            const parentRow: AssumptionRowModel = {
                label: "Line Items",
                value: totalValue,
                valueType: AssumptionRowValueType.DOLLAR,
                children: mappedLines
            };
            resultRows = [parentRow];
        }
    }

    return resultRows;
}
