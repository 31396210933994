import { ReactElement, useEffect, useMemo, useState } from "react";
import { Field as FormField, Label, Checkbox, MediaInput } from "@zendeskgarden/react-forms";
import { Button } from "@zendeskgarden/react-buttons";
import {
    FinancialEntityType,
    useAddAccountTagsMutation,
    useLoadChartOfAccountsQuery,
    useQueryAccountTagsLazyQuery,
    useRemoveAccountTagsMutation,
    useSetAccountTagsMutation,
} from "../../__generated__/generated_types";
import { FixedSizeList, ListChildComponentProps } from "react-window";
import { ReactComponent as SearchIcon } from '@zendeskgarden/svg-icons/src/16/search-stroke.svg';
import { ReactComponent as ChevronDownIcon } from '@zendeskgarden/svg-icons/src/16/chevron-down-stroke.svg';
import { ReactComponent as ChevronRightIcon } from '@zendeskgarden/svg-icons/src/16/chevron-right-stroke.svg';
import { ReactComponent as MaximizeIcon } from '@zendeskgarden/svg-icons/src/16/maximize-stroke.svg';
import { ReactComponent as MinimizeIcon } from '@zendeskgarden/svg-icons/src/16/minimize-stroke.svg';
import { ReactComponent as CheckboxIcon } from '@zendeskgarden/svg-icons/src/16/check-box-stroke.svg';
import cn from "classnames";
import * as css from "./styles/coaTags.module.scss";
import { Inline } from "@zendeskgarden/react-loaders";
import { TagSelector } from "./components/TagSelector";
import { toast } from "react-toastify";
import { ThemeProvider } from "@zendeskgarden/react-theming";
import AdminHeader from "../admin/AdminHeader";
import { ViziblyTheme } from "../analyst/ViziblyZDGTheme";
import Card from "../simplified-revenue/components/Card";
import useAppStore from "../../hooks/useAppStore";

export type ItemData = {
    id: string,
    glNumber?: string | null,
    glName: string,
    parentIds: string[],
    type: FinancialEntityType,

    selected: boolean,
    collapsed: boolean,
    tags: string[],
    tagsLoading: boolean
}

export function itemText(item: ItemData): string {
    return `${item.glNumber ? (item.glNumber + " - ") : ""} ${item.glName}`;
}

type ItemProps = {
    item: ItemData,
    allItems: ItemData[],
    allTags: string[],
    style?: React.CSSProperties,
    handleCollapse?: (item: ItemData, collapsed: boolean) => void,
    handleUpdateTags: (item: ItemData, tags: string[]) => void
    handleSelect: (item: ItemData, selected: boolean) => void,
}

function Item({
    item,
    style,
    allItems,
    allTags,
    handleCollapse,
    handleUpdateTags,
    handleSelect
}: ItemProps): ReactElement {
    const accountName = `${item.glNumber ? `${item.glNumber} - ` : ""}${item.glName}`;
    const [tagEditorVisible, setTagEditorVisible] = useState(false);
    const [indeterminate, checked] = useMemo(
        () => {
            let indeterminate = false;
            let checked = false;
            const children = allItems.filter(it => it.type == "ACCOUNT" && it.parentIds.includes(item.id));
            if (children.length > 0) {
                const selectedChildrenCount = children.count(child => child.selected);
                indeterminate = selectedChildrenCount > 0 && children.length > selectedChildrenCount;
                checked = selectedChildrenCount == children.length;
            }
            else {
                checked = item.selected;
            }
            return [indeterminate, checked];
        },
        [allItems]
    );

    function handleClose() {
        setTagEditorVisible(false);
    }

    function handleItemClick() {
        if (!item.tagsLoading && !tagEditorVisible) {
            setTagEditorVisible(true);
        }
    }
    return (
        <div
            style={style}
            className={css.item}
        >
            <FormField>
                <Checkbox
                    className={css.checkbox}
                    checked={checked}
                    onChange={() => {
                        handleSelect(item, !checked);
                    }}
                    indeterminate={indeterminate}
                >
                    <Label className={css.checkbox} />
                </Checkbox>
            </FormField>
            <div
                className={cn(
                    css.content,
                )}
                style={{ paddingLeft: `${item.parentIds.length * 48}px` }}
            >
                <div
                    className={css.chevron}
                >
                    {item.type != FinancialEntityType.Account &&
                        <Button
                            isBasic
                            onClick={() => handleCollapse?.(item, !item.collapsed)}
                        >
                            {item.collapsed ? <ChevronRightIcon /> : <ChevronDownIcon />}
                        </Button>
                    }
                </div>
                <div className={css.accountName}>
                    {accountName}
                </div>
            </div>
            {item.type == "ACCOUNT" &&
            <div
                className={css.lookbackContainer}
                onClick={handleItemClick}
            >
                    {item.tagsLoading ?
                    <Inline aria-label="loading"/>
                    :
                    <span>
                        {item.tags.join(", ")}
                    </span>
                    }
                    {tagEditorVisible &&
                    <TagSelector
                        key={item.id}
                        canAdd={true}
                        options={allTags.map(a => ({label: a, value: a}))}
                        selected={item.tags.map(a => ({label: a, value: a}))}
                        onConfirm={(tags) => {
                            handleUpdateTags(item, tags.map(tag => tag.value));
                            setTagEditorVisible(false);
                        }}
                        onClose={() => handleClose()}
                        title={`Edit tags for the account: ${accountName}`}
                    />
                    }
            </div>
            }
        </div>
    );
}

export function COATags(): ReactElement {
    const appStore = useAppStore();
    const { data: chartOfAccountsData, loading: chartOfAccountsLoading } = useLoadChartOfAccountsQuery({
        notifyOnNetworkStatusChange: true,
        fetchPolicy: "no-cache",
    });

    const [queryAccountTags, {data: accountTagsData, loading: accountTagsLoading}] = useQueryAccountTagsLazyQuery({
        fetchPolicy: "no-cache"
    });

    const [saveAccountTagsToDb] = useSetAccountTagsMutation({
        onCompleted: data => {
            if (data?.setAccountTags === true) {
                toast.success(`Tags Updated`);
            }
            else {
                toast.error(`Failed To Update Tags`);
            }
            queryAccountTags();
        },
    });

    const [addAccountTagsToDb] = useAddAccountTagsMutation({
        onCompleted: data => {
            if (data?.addAccountTags === true) {
                toast.success(`Tags Updated`);
            }
            else {
                toast.error(`Failed To Update Tags`);
            }
            queryAccountTags();
        },
    });

    const [removeAccountTagsToDb] = useRemoveAccountTagsMutation({
        onCompleted: data => {
            if (data?.removeAccountTags === true) {
                toast.success(`Tags Updated`);
            }
            else {
                toast.error(`Failed To Update Tags`);
            }
            queryAccountTags();
        },
    });

    const [items, setItems] = useState<ItemData[]>([]);
    const [displayItems, setDisplayItems] = useState<ItemData[]>([]);
    const [visibleItems, setVisibleItems] = useState<ItemData[]>([]);
    const [searchString, setSearchString] = useState<string>();
    const [searchTags, setSearchTags] = useState<string[]>([]);
    const [allTags, setAllTags] = useState<string[]>([]);
    const [selectedCount, setSelectedCount] = useState(0);
    const [selectionTags, setSelectionTags] = useState<string[]>([]);

    const [bulkAddTagsVisible, setBulkAddTagsVisible] = useState(false);
    const [bulkRemoveTagsVisible, setBulkRemoveTagsVisible] = useState(false);
    const [searchByTagsVisible, setSearchByTagsVisible] = useState(false);


    function updateDisplayItems(
        sourceItems: ItemData[],
        searchString: string | undefined,
        searchTags: string[]
    ) {
        let updated = [...sourceItems];
        if (searchString || searchTags.length > 0) {
            let temp = updated;
            if (searchString && searchString.length > 0) {
                temp = temp.filter(item => itemText(item).toLowerCase().includes(searchString.toLowerCase()));
            }
            if (searchTags.length > 0) {
                temp = temp.filter(item => item.tags.length > 0 && item.tags.find(tag => searchTags.find(t => t == tag)));
            }
            const ids = new Set(temp.map(item => item.id));
            const parentIds = new Set(temp.flatMap(item => item.parentIds).dedupe());
            updated = updated.filter(item =>
                ids.has(item.id)
                // I want to show user the path to the top level from the item filtered
                || parentIds.has(item.id)
            );
        }
        setVisibleItems(updated); // important to set visible items before hiding with collapsing
        const collapsedIds = updated.filter(item => item.collapsed).map(item => item.id);
        updated = updated.filter(item => !item.parentIds.some(id => collapsedIds.includes(id)));
        setDisplayItems(updated);
    }


    function handleCollapse(item: ItemData, collapsed: boolean) {
        const updated = [...items];
        const foundItem = updated.find(it => it.id == item.id);
        if (foundItem) {
            foundItem.collapsed = collapsed;
        }
        updateDisplayItems(updated, searchString, searchTags);
    }

    function handleCollapseAll(collapsed: boolean) {
        const visibleItemIds = new Set(visibleItems.map(it => it.id));
        for (const item of items) {
            if (item.type != FinancialEntityType.Account && visibleItemIds.has(item.id)) {
                item.collapsed = collapsed;
            }
        }
        updateDisplayItems(items, searchString, searchTags);
    }

    function handleSelect(item: ItemData, selected: boolean) {
        const updated = [...items];
        const visibleItemIds = new Set(visibleItems.map(it => it.id));
        let selectedCount = 0;
        const selectionTags = new Set<string>();

        for (const it of updated) {
            if (it.id == item.id || it.parentIds.includes(item.id) && visibleItemIds.has(it.id)) {
                it.selected = selected;
            }
            if (it.selected && it.type == "ACCOUNT") {
                selectedCount++;
                it.tags.forEach(tag => selectionTags.add(tag));
            }
        }
        updateDisplayItems(updated, searchString, searchTags);
        setSelectedCount(selectedCount);
        setSelectionTags(Array.from(selectionTags));
    }

    function handleSelectAll(selected: boolean) {
        const updated = [...items];
        const visibleItemIds = new Set(visibleItems.map(it => it.id));
        let selectedCount = 0;
        const selectionTags = new Set<string>();

        for (const it of updated) {
            if (visibleItemIds.has(it.id)) {
                it.selected = selected;
            }
            if (it.selected && it.type == "ACCOUNT") {
                selectedCount++;
                it.tags.forEach(tag => selectionTags.add(tag));
            }
        }
        updateDisplayItems(updated, searchString, searchTags);
        setSelectedCount(selectedCount);
        setSelectionTags(Array.from(selectionTags));
    }

    function handleUpdateSingleAccount(item: ItemData, tags: string[]) {
        const updated = [...items];
        for (const it of updated) {
            if (it.id == item.id) {
                it.tagsLoading = true;
                break;
            }
        }
        updateDisplayItems(updated, searchString, searchTags);
        saveAccountTagsToDb({
            variables: {
                accountIds: [item.id],
                tags: tags
            }
        });
    }


    function handleBulkAddTags(tags: string[]) {
        const updated = [...items];
        const accountIds = [];
        for (const it of updated) {
            if (it.type == "ACCOUNT" && it.selected) {
                accountIds.push(it.id);
                it.tagsLoading = true;
            }
        }
        updateDisplayItems(updated, searchString, searchTags);
        setBulkAddTagsVisible(false);
        addAccountTagsToDb({
            variables: {
                accountIds: accountIds,
                tags: tags
            }
        });
    }


    function handleBulkRemoveTags(tags: string[]) {
        const updated = [...items];
        const accountIds = [];
        for (const it of updated) {
            if (it.type == "ACCOUNT" && it.selected) {
                accountIds.push(it.id);
                it.tagsLoading = true;
            }
        }
        updateDisplayItems(updated, searchString, searchTags);
        setBulkRemoveTagsVisible(false);
        removeAccountTagsToDb({
            variables: {
                accountIds: accountIds,
                tags: tags
            }
        });
    }


    function COARow(rowProps: ListChildComponentProps<ItemData[]>) {
        const { data: displayItems, index, style } = rowProps;
        const item = displayItems[index];
        if (!item) return null;
        return (
            <Item
                item={item}
                allItems={visibleItems}
                allTags={allTags}
                handleCollapse={(item: ItemData, collapsed: boolean) => handleCollapse(item, collapsed)}
                handleUpdateTags={(item, tags) => handleUpdateSingleAccount(item, tags)}
                handleSelect={(item: ItemData, selected: boolean) => handleSelect(item, selected)}
                style={style}
            />
        );
    }

    useEffect(
        () => {
            appStore.set({ isLoading: false });
            return () => undefined;
        },
        []
    );

    useEffect(
        () => {
            if (chartOfAccountsData && !chartOfAccountsLoading) {
                const initialItems: ItemData[] = [];
                // const accountDriversById = props.fbUpdates.parsedFormDrivers.account.toIdMap("accountId");
                for (const row of chartOfAccountsData.listChartOfAccounts.records) {
                    // const accountDriverData = accountDriversById[row.id];
                    initialItems.push({
                        id: row.id,
                        glNumber: row.glNumber,
                        glName: row.glName,
                        parentIds: row.parentIds.copy(),
                        type: row.type,

                        selected: false,
                        collapsed: false,
                        tags: [],
                        tagsLoading: true
                    });
                }
                setItems(initialItems);
                updateDisplayItems(initialItems, searchString, searchTags);
                queryAccountTags(); // I want to load tags after was coa loaded
            }
            return () => undefined;
        },
        [chartOfAccountsData, chartOfAccountsLoading]
    );

    useEffect(() => {
        if (accountTagsData && !accountTagsLoading) {
            const allTags = new Set<string>();
            const selectionTags = new Set<string>();
            const tagsByAccountId = accountTagsData.queryAccountTags.toIdMap("accountId", "tags");
            const updated = [...items];
            for (const item of updated) {
                if (item.type == "ACCOUNT") {
                    item.tagsLoading = false;
                    item.tags = tagsByAccountId[item.id] ?? [];
                    for (const tag of item.tags) {
                        allTags.add(tag);
                        if (item.selected) {
                            selectionTags.add(tag);
                        }
                    }
                }
            }
            updateDisplayItems(updated, searchString, searchTags);
            setAllTags(Array.from(allTags));
            setSearchTags(prev => prev.filter(tag => allTags.has(tag)));
            setSelectionTags(Array.from(selectionTags));
        }
    }, [accountTagsData, accountTagsLoading]);


    useEffect(
        () => {
            updateDisplayItems(items, searchString, searchTags);
        },
        [searchString, searchTags]
    );

    return (
        <ThemeProvider theme={ViziblyTheme}>
            <div className={css.wrapper}>
                <AdminHeader
                    title={"Account Tags Management"}
                    subtitle={"Add/Remove account tags"}
                />
                <Card
                    title={"Chart Of Accounts"}
                    actions={
                        <>
                            <Button className={css.headerButton}
                                isBasic
                                onClick={() => handleCollapseAll(true)}
                            >
                                <MinimizeIcon />
                            </Button>
                            <Button className={css.headerButton}
                                isBasic
                                onClick={() => handleCollapseAll(false)}
                            >
                                <MaximizeIcon />
                            </Button>
                            <Button className={css.headerButton}
                                isBasic
                                onClick={() => handleSelectAll(true)}
                            >
                                <CheckboxIcon />&nbsp;All
                            </Button>
                            <Button className={css.headerButton}
                                isBasic
                                onClick={() => handleSelectAll(false)}
                            >
                                <CheckboxIcon />&nbsp;None
                            </Button>
                            <FormField className={css.inputBox}>
                                <MediaInput start={<SearchIcon />} value={searchString} placeholder="Search" onChange={e => setSearchString(e.target.value)} />
                            </FormField>
                            <FormField className={css.inputBox}>
                                <MediaInput
                                    start={<SearchIcon />}
                                    onClick={() => setSearchByTagsVisible(true)}
                                    value={searchTags.length > 0 ? searchTags.join(", ") : ""}
                                    placeholder="Filter By Tags"
                                />
                                {searchByTagsVisible &&
                                <TagSelector
                                    title="Filter By Tags"
                                    canAdd={false}
                                    options={allTags.map(t => ({label: t, value: t}))}
                                    selected={searchTags.map(t => ({label: t, value: t}))}
                                    onConfirm={(tags) => {setSearchTags(tags.map(t => t.value)); setSearchByTagsVisible(false);}}
                                    onClose={() => setSearchByTagsVisible(false)}
                                />
                                }
                            </FormField>
                        </>
                    }
                >
                    <FixedSizeList<ItemData[]>
                        height={430}
                        itemCount={displayItems.length}
                        itemSize={40}
                        width={"100%"}
                        itemData={displayItems}
                        className={css.tableContent}
                    >
                        {COARow}
                    </FixedSizeList>
                    <div className={css.footerWrapper}>
                        <div className={css.counterWrapper}>
                            <div className={css.counter}>{selectedCount}</div>
                            <div className={css.counterLabel}>GLs Selected</div>
                        </div>
                        <div className={css.bottomControlsWrapper}>
                            <Button
                                isPrimary
                                disabled={selectedCount==0}
                                onClick={() => setBulkAddTagsVisible(true)}
                            >
                                Add Tags
                            </Button>
                            <Button
                                isDanger
                                disabled={selectedCount==0 || selectionTags.length == 0}
                                onClick={() => setBulkRemoveTagsVisible(true)}
                            >
                                Remove Tags
                            </Button>
                        </div>
                    </div>
                </Card>
            </div>
            {bulkAddTagsVisible &&
            <TagSelector
                title="Bulk Add Tags to Selected Accounts"
                canAdd={true}
                options={allTags.map(t => ({label: t, value: t}))}
                selected={[]}
                onConfirm={(tags) => handleBulkAddTags(tags.map(t => t.value))}
                onClose={() => setBulkAddTagsVisible(false)}
            />
            }
            {bulkRemoveTagsVisible &&
            <TagSelector
                title="Bulk Remove Tags From Selected Accounts"
                canAdd={false}
                options={selectionTags.map(t => ({label: t, value: t}))}
                selected={[]}
                onConfirm={(tags) => handleBulkRemoveTags(tags.map(t => t.value))}
                onClose={() => setBulkRemoveTagsVisible(false)}
            />
            }
        </ThemeProvider>
    );
}