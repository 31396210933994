export const formatterDollarUSNoDecimal = Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    maximumFractionDigits: 0,
    minimumFractionDigits: 0
});

export const formatterDollarUSMax2Decimal = Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    maximumFractionDigits: 2,
    minimumFractionDigits: 0
});

export const formatterNumberUSMax2Decimal = Intl.NumberFormat("en-US", {
    maximumFractionDigits: 2,
    minimumFractionDigits: 0
});

export const formatterPercentUSMax2Decimal = Intl.NumberFormat("en-US", {
    style: "percent",
    maximumFractionDigits: 2,
    minimumFractionDigits: 0
});

export const formatterDecimalUSMax2Decimal = Intl.NumberFormat("en-US", {
    style: "decimal",
    maximumFractionDigits: 2,
    minimumFractionDigits: 0
});

export const formatterDateUSFull = Intl.DateTimeFormat("en-US", {
    dateStyle: "full"
});

export function getMonthUSFull(date: Date): string {
    const parts = formatterDateUSFull.formatToParts(date);
    let month = parts.find(part => part.type == "month")?.value;
    if (month == undefined) {
        month = `${date.getMonth() + 1}`;
    }

    return month;
}