import { ReactElement } from "react";
import * as css from "./styles/css.module.scss";

import { Spinner } from '@zendeskgarden/react-loaders';

export default function WidgetLoading():ReactElement {
    return (
            <div className={css.loadingContainer}>
                <Spinner size='48' />
            </div>
    );
}