import { useState } from "react";

export interface NavigatableArrayProps<TItem> {
    items: TItem[];
    initialPosition?: number;
    allowCircularNavigation?: boolean;
}

type NavigationState<TItem> = {
    items: TItem[];
    position: number;
}

export type NavigatableArrayReturnType<TItem> = {
    prev: () => void;
    next: () => void;
    select: (position: number) => void;
    selectedItem: TItem;
}

export function useNavigatableArray<TItem>(props: NavigatableArrayProps<TItem>): NavigatableArrayReturnType<TItem> {
    if (props.items.length === 0) {
        throw new Error("useNavigatableArray requires non-empty items array");
    }

    let initialPosition = props.initialPosition;

    if (initialPosition === undefined || initialPosition < 0) {
        initialPosition = 0;
    }

    if (initialPosition > props.items.length - 1) {
        initialPosition = props.items.length - 1;
    }

    const [navigationState, setNavigationState] = useState<NavigationState<TItem>>({
        items: props.items,
        position: initialPosition
    });

    const next = () => {
        setNavigationState(prevState => {
            let newPosition = prevState.position + 1;
            if (newPosition > prevState.items.length - 1) {
                if (props.allowCircularNavigation) {
                    newPosition = 0;
                }
                else {
                    newPosition = prevState.position;
                }
            }

            if (newPosition !== prevState.position) {
                return {
                    ...prevState,
                    position: newPosition
                };
            }

            return prevState;
        });
    };

    const prev = () => {
        setNavigationState(prevState => {
            let newPosition = prevState.position - 1;
            if (newPosition < 0) {
                if (props.allowCircularNavigation) {
                    newPosition = prevState.items.length - 1;
                }
                else {
                    newPosition = prevState.position;
                }
            }

            if (newPosition !== prevState.position) {
                return {
                    ...prevState,
                    position: newPosition
                };
            }

            return prevState;
        });
    };

    const select = (position: number) => {
        setNavigationState(prevState => {
            if (position === prevState.position) {
                return prevState;
            }

            let newPosition = position;
            if (newPosition < 0) {
                newPosition = 0;
            }

            if (newPosition > prevState.items.length - 1) {
                newPosition = prevState.items.length - 1;
            }

            if (newPosition !== prevState.position) {
                return {
                    ...prevState,
                    position: newPosition
                };
            }

            return prevState;
        });
    }

    const selectedItem = navigationState.items[navigationState.position];

    if (selectedItem == undefined) {
        throw new Error("No item for index " + navigationState.position);
    }

    return {
        prev: prev,
        next: next,
        select: select,
        selectedItem: selectedItem
    };
}