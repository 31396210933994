import { FinancialEntity } from '../../contexts/chartofaccounts/ChartOfAccountsContext';
import { VersionsState } from '../../contexts/versions/VersionsContext';
import { varianceNew } from '../../utils/math';
import { FinancialTotalValueQueryInput, FinancialValueQueryInput, GetFinancialValuesQueryVariables } from '../../__generated__/generated_types';
import { LineData, LineMonthData } from "./Line";


export function fillLineData(
    rawValues: LineMonthData[],
    total: number | undefined,
    referenceTotal: number | undefined,
    financialEntity: FinancialEntity,
) : LineData {
    // we always compare
    // lineTotal                vs lineReferenceTotal
    // 2022 budget              vs 2021 reforecast          --- isReforecast === false
    // 2021 reforecast          vs 2021 original budget     --- isReforecast === true
    // ------
    // 2021 === year
    //

    const values = [] as (LineMonthData)[];

    for (let month = 0; month < 12; month++) {
        values[month] = rawValues?.find(value => value.monthIndex === month) ?? {monthIndex: month, value: 0} ;
    }

    return {
        financialEntity,
        ...toDisplayTotals2(total, referenceTotal),
        monthNumbers: values,
    };
}

export function toDisplayTotals2(
    total: number | undefined,
    referenceTotal : number | undefined,
) : Pick<LineData, 'total' | 'referenceTotal' | 'variance' | 'variancePercent'> {
    const variance = (total ?? 0) - (referenceTotal ?? 0);
    const calculatedVariancePct = varianceNew(referenceTotal ?? 0, total ?? 0);

    const variancePercent = calculatedVariancePct === null ? calculatedVariancePct : Math.round(calculatedVariancePct * 1000) / 10;

    return {
        total           : total ?? 0,
        referenceTotal  : referenceTotal ?? 0,
        variance        : variance,
        variancePercent : variancePercent,
    };
}


