import { Children, ReactElement } from "react";

import * as styles from './styles/styles.module.scss';

export interface IStaticLoaderProps {
    loadProgress: number;
    children?: ReactElement[]|ReactElement;
}

function StaticLoader(props: IStaticLoaderProps): ReactElement {
    return (
            <div className={(
                    props.loadProgress < 100
                        ? `${styles.staticLoader} ${styles.staticLoaderLoading}`
                        : styles.staticLoader
            )}>
                <div className={(
                        props.loadProgress < 100
                                ? `${styles.loadbar} ${styles.loadbarLoading}`
                                : styles.loadbar
                )} style={{ width: `calc(100vw * ${props.loadProgress/100})` }}/>
            </div>
    );
}

export default StaticLoader;