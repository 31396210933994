import {ReactElement} from "react";
import {Body, Close, Footer, FooterItem, Header, Modal} from "@zendeskgarden/react-modals";
import {Button} from "@zendeskgarden/react-buttons";
import {Inline} from "@zendeskgarden/react-loaders";
import {PayrollCompensationItemType} from "../../../__generated__/generated_types";
import {titleCase} from "../../../global/string_functions";

export type BulkDeleteConfirmProps = {
    what: "raises" | "bonuses" | "overtimes" | string | PayrollCompensationItemType
    onClose: () => void;
    onConfirm: () => void;
    loading: boolean;
}

export function BulkDeleteConfirm(props: BulkDeleteConfirmProps): ReactElement {
    function handleConfirm() {
        props.onConfirm();
    }

    function toDefaultString(what: string | PayrollCompensationItemType): string {
        return what.toLowerCase().replace(/_+/g, " ");
    }

    function toTitle(what: string | PayrollCompensationItemType) {
        if(what === "raises") {
            return "Raises";
        } else if(what === "bonuses") {
            return "Bonuses";
        } else if(what === "overtimes") {
            return "Overtimes";
        } else if(what === PayrollCompensationItemType.Retirement_401KMatch) {
            return "401K";
        } else if(what === PayrollCompensationItemType.MedicareTax) {
            return "Medicare";
        } else {
            const withSpaces = toDefaultString(what);
            return titleCase(withSpaces);
        }
    }

    function toQuestion(what: string | PayrollCompensationItemType) {
        if(what === "raises") {
            return "raise";
        } else if(what === "bonuses") {
            return "bonus";
        } else if(what === "overtimes") {
            return "overtime";
        } else if(what === PayrollCompensationItemType.Retirement_401KMatch) {
            return "401K";
        } else if(what === PayrollCompensationItemType.MedicareTax) {
            return "medicare";
        } else {
            return toDefaultString(what);
        }
    }

    return (
    <Modal onClose={() => props.onClose()} isLarge style={{overflow: "unset"}}>
        <Header>
            Bulk Delete {toTitle(props.what)}
        </Header>
        <Body>
            Are you sure you want to delete the <b>{toQuestion(props.what)}</b> data?
        </Body>
        <Footer>
            <FooterItem>
                <Button onClick={() => props.onClose()} isBasic>
                    Cancel
                </Button>
            </FooterItem>
            <FooterItem>
                {
                    <Button
                        isPrimary
                        isDanger
                        onClick={() => handleConfirm()}
                        disabled={props.loading}
                    >
                        {
                            props.loading
                                ? <Inline size={24} aria-label="loading"/>
                                :
                                 <span>Confirm</span>
                        }
                    </Button>
                }
            </FooterItem>
        </Footer>
        <Close aria-label="Close modal" />
    </Modal>

    );
}