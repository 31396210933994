import { AcctRenosRowId } from "./enums";
import { AcctRenovationsRow } from "./types";
import { AcctRenovationsSheetStyles } from "./AcctRenovationsSheetStyles";

export const AcctRenoRowSpecs:Record<AcctRenosRowId, AcctRenovationsRow> = {
    [AcctRenosRowId.MONTHS]: {
        label: '',
        style: {
            labelCell: AcctRenovationsSheetStyles.MONTH_HEADER,
            dataCell: AcctRenovationsSheetStyles.MONTH_HEADER,
        }
    },
    [AcctRenosRowId.MOVE_OUTS_SUMMARY]: {
        label: 'Expiring Lease Move Outs',
        style: {
            labelCell: AcctRenovationsSheetStyles.RENO_SUMMARY_ROW,
            dataCell: AcctRenovationsSheetStyles.RENO_SUMMARY_ROW,
        }
    },
    [AcctRenosRowId.RENO_COUNTS_HDR]: {
        label: 'Renovation Count',
        style: {
            labelCell: AcctRenovationsSheetStyles.RENO_COUNT_HDR,
            dataCell: undefined,
        }
    },
    [AcctRenosRowId.PACKAGE_ROW]: {
        label: '',
        style: {
            labelCell: AcctRenovationsSheetStyles.RENO_DATA_LABEL,
            dataCell: AcctRenovationsSheetStyles.RENO_DATA,
        }
    },
    [AcctRenosRowId.TOTAL_RENOS_PERC_DEFAULT_ROW]: {
        label: 'Total Renovations',
        style: {
            labelCell: AcctRenovationsSheetStyles.TOTAL_RENOS_ROW,
            dataCell: AcctRenovationsSheetStyles.TOTAL_RENOS_DATA,
        }
    },
    [AcctRenosRowId.RENOS_MOVE_OUT_PERC_DEFAULT_ROW]: {
        label: 'Reno/Move Out %',
        style: {
            labelCell: AcctRenovationsSheetStyles.RENO_MOVEOUT_PERC_LABEL,
            dataCell: AcctRenovationsSheetStyles.RENO_MOVEOUT_PERC_DATA,
        }
    },
};
