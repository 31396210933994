import {HotTable} from "@handsontable/react";
import {ReactElement, useEffect, useRef, useState} from "react";
import {Property} from "../../../contexts/properties/PropertiesContext";
import * as tableCSS from "../../../components/account-table/styles/accountTable.module.scss";
import {LICENSES} from "../../../constants/Licenses";
import Handsontable from "handsontable";
import {GetRenewalRatioCountsActualsQuery, GetSimplifiedRevenueSummaryQuery, VersionType} from "../../../__generated__/generated_types";
import {MONTHS} from "../../../constants/Months";
import {TAccountTableRowData, TAccountTableSummaryFunction} from "../../../components/account-table/data/AccountTableData";
import {toggleHiddenRows, updateRowSummary} from "../../../components/account-table/logic/AccountTableHelpers";
import {averageOfArray} from "../logic/HelperFunctions";
import {getChildAverages, TAccountTableChildRowData} from "../logic/utils";

export interface IRenewalRatioCompareTableProps {
    property: Property,
    renewalRatioCountActualsData: GetRenewalRatioCountsActualsQuery,
    simplifiedRevenueSummaryData: GetSimplifiedRevenueSummaryQuery,
}


export default function RenewalRatioCompareTable({property, renewalRatioCountActualsData, simplifiedRevenueSummaryData}: IRenewalRatioCompareTableProps): ReactElement {
    const hotRef = useRef<HotTable>(null);
    const visibleParentRows = useRef<Set<number>>(new Set<number>());
    const [tableData, setTableData] = useState<TAccountTableRowData[]>([]);

    useEffect(() => {
        if (!renewalRatioCountActualsData.actuals || !simplifiedRevenueSummaryData.simplifiedRevenueModel?.unitTypeRenovations) {
            return;
        }

        let sortedActuals = [...renewalRatioCountActualsData.actuals];
        sortedActuals = sortedActuals.sort((a, b) => b.year - a.year);

        const actualsData = sortedActuals.map((eachActualYear) => {
            const parentRowId = `${eachActualYear.year} ${eachActualYear.type.toLowerCase().capitalize()}`;

            const childRows: TAccountTableChildRowData[] = [];

            const seen = new Set();
            const allUnitTypes = eachActualYear.unitTypeScheduleValues.filter(item => {
                const duplicate = seen.has(item.unitType);
                seen.add(item.unitType);
                return !duplicate;
            });

            allUnitTypes.forEach((eachUnitType) => {
                let defaultMonthlyValues = new Array(12).fill(null);
                const defaultMonthlyValuesMap: {[key: number]: number | null } = {};
                defaultMonthlyValues.forEach((e, i) => defaultMonthlyValuesMap[i] = e);
                const monthlyValueObjectsForUnitType = eachActualYear.unitTypeScheduleValues.filter((e) => e.unitType.id === eachUnitType.unitType.id);

                monthlyValueObjectsForUnitType.forEach((e) => defaultMonthlyValues[e.monthIndex] = e.renewalRatio*100);

                defaultMonthlyValues = defaultMonthlyValues.map((e) => Math.round(e));

                const childRowObject: TAccountTableChildRowData = {
                    label: eachUnitType.unitType.name,
                    rowType: "historical" as TAccountTableRowData["rowType"],
                    parentRowId: parentRowId.replace(" ", ""),
                    defaultMonthlyValues,
                };

                childRows.push(childRowObject);
            });

            const getParentMonthlyValues = () => {
                const defaultMonthlyValues = new Array(12).fill(null).map((_e, i) => childRows.reduce((acc, c) => {
                    const currentMonthlyValue = Number(c.defaultMonthlyValues[i] )|| 0;
                    return acc + currentMonthlyValue;
                }, 0));

                return defaultMonthlyValues.map((e) => Math.round(e/childRows.length));
            };

            const parentMonthlyValues = getParentMonthlyValues();
            const getChildRowAverage = (values: (number | null)[]) => {
                return Math.round(averageOfArray(values));
            };

            return [
                {
                    label: parentRowId,
                    rowType: "historical" as TAccountTableRowData["rowType"],
                    id: parentRowId.replace(" ", ""),
                    hideable: true,
                    ...parentMonthlyValues,
                    average: Math.round(averageOfArray(parentMonthlyValues)),
                },
                ...childRows.map((each) => ({
                    label: each.label,
                    rowType: each.rowType,
                    parentRowId: each.parentRowId,
                    ...each.defaultMonthlyValues,
                    average: getChildRowAverage(each.defaultMonthlyValues),
                })) as TAccountTableRowData[],
            ];
        });

        const rfrcstAndBdgtRenewalRatios = [
            ...simplifiedRevenueSummaryData.simplifiedRevenueModel.unitTypeRenovations.renovatedRenewalRatio,
            ...simplifiedRevenueSummaryData.simplifiedRevenueModel.unitTypeRenovations.unrenovatedRenewalRatio,
        ].sort((a, b) => {
            if(a.unitTypeName>b.unitTypeName) return 1;
            if(a.unitTypeName>b.unitTypeName) return -1;
            return 0;
        });

        const reforecastRenewalRatios = rfrcstAndBdgtRenewalRatios.map((each) => each.reforecast);
        const budgetRenewalRatios = rfrcstAndBdgtRenewalRatios.map((each) => each.budget);

        const getChildValues = (children: (number | null)[]) => {
            const columns = new Array(12).fill(0);
            const childValues: {[key: number]: number | null | undefined } = {};

            columns.forEach((_e, i) => {
                childValues[i] = children[i];
            });

            return childValues;
        };

        const getBudgetChildRows = (parentRowId: string) => {
            return rfrcstAndBdgtRenewalRatios.map((each) => ({
                label: each.unitTypeName,
                rowType: "historical" as TAccountTableRowData["rowType"],
                parentRowId,
                ...getChildValues(each.budget),
            }));
        };

        const getReforecastChildRows = (parentRowId: string) => {
            return rfrcstAndBdgtRenewalRatios.map((each) => ({
                label: each.unitTypeName,
                rowType: "historical" as TAccountTableRowData["rowType"],
                parentRowId,
                ...getChildValues(each.reforecast),
            }));
        };

        const budgetLabel = `${property.budgetYear} Budget`;
        const reforecastLabel = `${property.reforecastYear} Reforecast`;

        const budgetRenewalRatiosWithParent = [
            {
                label: budgetLabel,
                rowType: "historical" as TAccountTableRowData["rowType"],
                id: budgetLabel.replace(" ", ""),
                hideable: true,
                ...getChildAverages(budgetRenewalRatios),
            },
            ...getBudgetChildRows(budgetLabel.replace(" ", "")),
        ];

        const reforecastRenewalRatiosWithParent = [
            {
                label: reforecastLabel,
                rowType: "historical" as TAccountTableRowData["rowType"],
                id: reforecastLabel.replace(" ", ""),
                hideable: true,
                ...getChildAverages(reforecastRenewalRatios),
            },
            ...getReforecastChildRows(reforecastLabel.replace(" ", "")),
        ];

        setTableData([
            ...budgetRenewalRatiosWithParent,
            ...reforecastRenewalRatiosWithParent,
            ...actualsData.flat(),
        ]);
    }, [renewalRatioCountActualsData.actuals, simplifiedRevenueSummaryData.simplifiedRevenueModel]);

    const colHeaders: string[] = [
        "",
        ...MONTHS,
        "Average",
    ];

    const columns = [
        {data: "label", type: "text", disableVisualSelection: true},
        {data: 0, type: "numeric"},
        {data: 1, type: "numeric"},
        {data: 2, type: "numeric"},
        {data: 3, type: "numeric"},
        {data: 4, type: "numeric"},
        {data: 5, type: "numeric"},
        {data: 6, type: "numeric"},
        {data: 7, type: "numeric"},
        {data: 8, type: "numeric"},
        {data: 9, type: "numeric"},
        {data: 10, type: "numeric"},
        {data: 11, type: "numeric"},
        {data: "average", type: "numeric"},
    ];

    const settings: Handsontable.GridSettings = {
        data: tableData,
        licenseKey: LICENSES.HandsOnTable,
        columns,
        colHeaders,
        selectionMode: "range",
        rowHeaders: false,
        width: "auto",
        height: "auto",
        readOnly: true,
        fixedColumnsLeft: 1,
        manualRowResize: false,
        manualColumnResize: false,
        autoColumnSize: false,
        autoRowSize: false,
        disableVisualSelection: ["header"],
        hiddenRows: true,
        colWidths(index) {
            if (index == 0) {
                return 250;
            } else if (index == 13) {
                return 175;
            } else {
                return 100;
            }
        },
        afterGetColHeader: (_column, th) => {
            if (!hotRef.current?.hotInstance) {
                return;
            }

            th.className += ` ${tableCSS.cellBase} ${tableCSS.headerWithLabels} ${tableCSS.cellBgGrey}`;
            if (_column == 0) {
                th.innerHTML = `
                    <div class="${tableCSS.tableHeaderLightLabel}">
                        <span>&nbsp;</span>
                        <span>&nbsp;</span>
                        <span>&nbsp;</span>
                    </div>
                `;
            } else if (_column == 13) {
                th.innerHTML = `
                    <div class="${tableCSS.tableHeaderLightLabel}">
                        <span>&nbsp;</span>
                        <span>&nbsp;</span>
                        <span>Average</span>
                    </div>
                `;
            } else {
                th.innerHTML = `
                    <div class="${tableCSS.tableHeaderLightLabel}">
                        <span>&nbsp;</span>
                        <span>&nbsp;</span>
                        <span>${ MONTHS[_column - 1]}</span>
                    </div>
                `;
            }
        },
        cells(_row, _column) {
            this.className += ` ${tableCSS.cellBase}`;

            if (_column == 13) {
                this.className += ` ${tableCSS.cellBgGrey}`;
            }

            if ( _column > 0) {
                this.renderer = function (instance, td, row, col, prop, value, cellProperties) {
                    Handsontable.renderers.NumericRenderer.apply(this, [instance, td, row, col, prop, value, cellProperties]);

                    if (value !== null && value !== undefined) {
                        value = parseFloat(value);
                        td.innerText = value.toFixed(1) + '%';
                    }
                };
            }

            if (_column == 0) {
                this.disableVisualSelection = true;
                this.className += ` ${tableCSS.rowHeader}`;
            }

            return this;
        },
        afterRenderer(td, row, _column, prop, _value, _cellProperties) {
            const rowData = tableData[row];

            if (!rowData) {
                return;
            }

            if (prop == "label") {
                td.className += ` ${tableCSS.rowHeader}`;

                if (rowData.hideable) {
                    td.className += ` ${tableCSS.parentRow}`;

                    if (visibleParentRows.current.has(row)) {
                        td.className += ` ${tableCSS.parentRowVisible}`;
                    }
                } else if (rowData.parentRowId) {
                    td.className += ` ${tableCSS.childRow}`;
                }
            }
        },
        afterOnCellMouseUp: function(_event, {row, col}, _td) {
            if (!hotRef.current?.hotInstance) {
                return;
            }

            const rowData = tableData[row];

            if (rowData && rowData.id && col == 0) {
                const isVisible = toggleHiddenRows(hotRef.current.hotInstance, tableData, rowData.id, row);
                if (isVisible) {
                    visibleParentRows.current.add(row);
                } else {
                    visibleParentRows.current.delete(row);
                }
                hotRef.current.hotInstance.render();
            }
        }
    };

    const hiddenRows: number[] = [];

    tableData.forEach((row, index) => {
        if (row.parentRowId) {
            hiddenRows.push(index);
        }
    });

    useEffect(() => {
        if (!hotRef.current?.hotInstance) {
            return;
        }

        tableData.forEach((_rowData, row) => {
            if (hotRef.current?.hotInstance) {
                updateRowSummary(hotRef.current.hotInstance, row, TAccountTableSummaryFunction.AVG, 0, VersionType.Reforecast);
            }
        });
    }, [hotRef.current]);

    return (
        <div className={tableCSS.accountTableWrapper}>
            <HotTable
                id="renewal-ratio-compare"
                ref={hotRef}
                settings={settings}
                className={tableCSS.accountTable}
                undo={false}
                hiddenRows={{
                    rows: hiddenRows,
                }}
            />
        </div>
    );
}
