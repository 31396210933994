import { ReactElement, useEffect } from "react";

import useDriversAndWorksheetDataNoCalcEngine from "../../../contexts/account/data/useDriversAndWorksheetDataNoCalcEngine";
import FormulaBar from "../../workflows/account/formula-bar/FormulaBar";
import { IStashFxBarDirtyStateByAccountId, IUsePropertyDriversReturn } from "../logic/usePropertyDrivers";

import * as css from "./styles/css.module.scss";


interface IProps {
    accountId: string;
    isFxBarReadOnly: boolean;
    pd: IUsePropertyDriversReturn;
    handleStash?: (args: IStashFxBarDirtyStateByAccountId) => void;
    propertyId: string;
}

export default function PropertyDriversFormulaBar({
    accountId,
    isFxBarReadOnly,
    pd,
    handleStash,
    propertyId,
}: IProps): ReactElement {

    const driversAndWorksheetData = useDriversAndWorksheetDataNoCalcEngine({
        propertyId: propertyId,
        accountId: accountId,
    });

    /**
     * Sets isReady to true once all required dependencies have been loaded.
     */
    useEffect(
        () => {
            if (!propertyId || !accountId) {
                return;
            }

            // Initialize Drivers and Worksheet data
            driversAndWorksheetData.setAccountId(accountId);
            driversAndWorksheetData.setPropertyId(propertyId);
        },
        [accountId, propertyId]
    );

    useEffect(() => {
        if (driversAndWorksheetData.isReady && pd.versionType && pd.year) {
            driversAndWorksheetData.fetch(pd.year, pd.versionType);
        }
    }, [driversAndWorksheetData.isReady, pd.versionType, pd.year]);

    // Refetch DWD when property drivers are successfully saved
    useEffect(() => {
        if (pd.refetchDWD && pd.versionType && pd.year) {
            driversAndWorksheetData.fetch(pd.year, pd.versionType);
            pd.setRefetchDWD(false);
        }
    }, [pd.refetchDWD]);

    return (
        <div className={`${css.formulaBarWrapper} ${!isFxBarReadOnly && css.tallerFxBarWrapper}`}>
            <div className={css.labelColumn}>fx&nbsp;=</div>
            {pd.budgetYear && (
                <FormulaBar
                    year={pd.year}
                    versionType={pd.versionType}
                    currentPropertyId={propertyId}
                    accountId={accountId}
                    budgetYear={pd.budgetYear}
                    readOnly={isFxBarReadOnly}
                    driversAndWorksheetData={driversAndWorksheetData}
                    shouldFxBarStashChanges={pd.shouldFxBarStashChanges}
                    stashFxBarDirtyStateByAccountId={handleStash}
                    showLabelColumn={false}
                    isCommitDisabled
                    shouldReceiveBulkUpdate={pd.shouldApplyBulkUpdatesToFormulaBars}
                    bulkUpdateState={pd.bulkUpdateState}
                    isPropertyDriversUI
                    allAvailableDrivers={pd.allAvailableDrivers}
                    lineItemsMenuOptionsByAccountId={pd.lineItemsMenuOptionsByAccountId}
                    setLineItemsMenuOptionsByAccountId={pd.setLineItemsMenuOptionsByAccountId}
                    activeView={pd.activeView}
                    selectedAccountIds={pd.selectedAccounts.map((each) => each.id)}
                />
            )}
        </div>
    );
}
