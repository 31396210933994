import { Property, UnitEventType } from "../../../../__generated__/generated_types";

import { HCGanttSeries } from "../helpers/types";
import {
    getChartBackgroundClickInfo,
    getEventSubtitleString,
    getEventTitleString
} from "./helpers/utils";
import { UnitEventsList } from "./hooks/useUnitEventData";

import { DateTime, Duration } from "luxon";
import { format } from "highcharts";
import { COLORS } from "../../../../constants/Colors";

const buildTooltip = (point: any): string => {
    let tooltip = `
        <div style="
            background-color: white;
            border-radius: 4px;
            box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
            padding: 14px;">
            <div style="font-weight: 500; line-height: 1.7em;">
                ${point.custom.strings.eventTitle}
            </div>
            <div>
                ${point.custom.strings.eventSubtitle}
            </div>
    `;

    if(point.custom.prevEvent != null){
        const lastEventEnd = DateTime.fromISO(point.custom.prevEvent.eventEnd);
        const thisEventStart = DateTime.fromISO(point.custom.currentEvent.eventStart);

        const diff = thisEventStart.diff(lastEventEnd, ['days']);
        const dayLabel = diff.days == 1 ? 'days' : 'days';

        if(diff.days > 1){
            tooltip += `
            <div style="
                border-top: 1px solid ${COLORS.GREY_200};
                padding-top: 10px;
                margin-top: 10px;
                ">
                <strong>${Math.floor(diff.days) - 1} ${dayLabel} of vacancy</strong> since prior event
            </div>`;
        }
    }

    tooltip += '</div>';

    return tooltip;
};

/**
 * Translates a unit events list, which is a TraversablePaginatedUnitModel instance,
 * into the data schema expected by a Highcharts instance in Gantt chart mode.
 */
export const UnitEventDataToHCFormat = (
    unitEventsList: UnitEventsList,
    year: number,
    onUnitEventClick: (evt: any) => void,
    onEmptyAreaClick: (evt: any) => void,
    currentProperty?: Pick<Property, 'name'>,
): any => {

    return {
        title: {
            text: '',
            floating: true,
        },

        chart: {
            height: 1000,
            animation: false,
            events: {
                click: (e: any) => {
                    const unitIdx = Math.floor(e.yAxis[0].value + 0.5);
                    const timestamp = Math.floor(e.xAxis[0].value);

                    onEmptyAreaClick(
                        getChartBackgroundClickInfo(unitIdx, timestamp, year, unitEventsList)
                    );
                },
            },
        },

        tooltip: {
            enabled: true,
            useHTML: true,
            shadow: false,
            borderWidth: 0,
            backgroundColor: undefined,
            distance: 6,
            followPointer: true,
            formatter: function () {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                return buildTooltip(this.point);
            } as any,
        },

        plotOptions: {
            series: {
                pointWidth: 60,
            },
            gantt: {
                point: {
                    events: {
                        click: onUnitEventClick,
                    }
                }
            }
        },

        xAxis: [
            // _Month_ x-axis config
            {
                alternateGridColor: '#FBFBFB',
                min: Date.UTC(year, 0),
                max: Date.UTC(year + 1, 11, 31),
                showEmpty: true,
                visible: true,
                tickInterval: 2629800000,
                currentDateIndicator: {
                    color: '#A5C1EB',
                    format: '',
                    zIndex: 4,
                    width: 1,
                    label: {
                        text: '',
                        y: -5000,
                    },
                },
                dateTimeLabelFormats: {
                    millisecond: '%H:%M:%S.%L',
                    second: '%H:%M:%S',
                    minute: '%H:%M',
                    hour: '%H:%M',
                    day: '%e. %b',
                    week: '%e. %b',
                    month: '%b',
                    year: '%Y'
                }
            },
            // _Year_ x-axis config
            {
                min: Date.UTC(year, 0),
                max: Date.UTC(year + 1, 11, 31),
                showEmpty: true,
                visible: true,
            }
        ],

        yAxis: [{
            title: 'Unit',
            uniqueNames: true,
            categories: unitEventsList.items.map(entry => `Unit ${entry.number}`),
            staticScale: 10,
            max: unitEventsList.items.length - 1,
        }],

        accessibility: {
            point: {
                descriptionFormatter: function (point: any) {
                    var completedValue = point.completed ?
                            point.completed.amount || point.completed : null,
                        completed = completedValue ?
                            ' Task ' + Math.round(completedValue * 1000) / 10 + '% completed.' :
                            '';
                    return format(
                        '{point.yCategory}.{completed} Start {point.x:%Y-%m-%d}, end {point.x2:%Y-%m-%d}.',
                        { point, completed }
                    );
                }
            },
            series: {
                descriptionFormatter: function (series: any) {
                    return series.name;
                }
            }
        },

        lang: {
            accessibility: {
                axis: {
                    xAxisDescriptionPlural: 'The chart has a two-part X axis showing time in both week numbers and days.',
                    yAxisDescriptionPlural: 'The chart has one Y axis showing task categories.'
                }
            }
        },

        series: [{
            name: currentProperty?.name ?? '',
            type: 'gantt',
            animation: false,
            data: hcSeriesFromUnitModelEvents(unitEventsList, year),
            dataLabels: [{
                enabled: true,
                formatter: function () {
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    const point = this.point;
                    const pointWidth = point.shapeArgs.width - 30;

                    return `
                            <span style="
                              width: ${pointWidth}px;
                              white-space: nowrap;
                              display: block;
                              overflow: hidden;
                              color: #333842;
                              font-family: Inter, sans-serif;
                              font-weight: 300;
                            ">
                              <span style="font-weight: 500; line-height: 1.7em;">
                                 ${point.custom.strings.eventTitle}
                             </span><br/>
                             ${point.custom.strings.eventSubtitle}
                            </span>
                        `;
                } as any,
                useHTML: true,
                x: 10,
                overflow: 'crop',
                crop: false,
                align: 'left',
            }],
        }],
    };
};

export const colorIdxByEventType = (evtType: UnitEventType, isImportedEvent: boolean): number => {
    if(isImportedEvent){
        return 15;
    }

    switch (evtType) {
        case UnitEventType.MonthToMonth: {
            return 10;
        }
        case UnitEventType.Renewal: {
            return 11;
        }
        case UnitEventType.Renovation: {
            return 12;
        }
        case UnitEventType.ShortTermRental: {
            return 13;
        }
        case UnitEventType.TermLease: {
            return 14;
        }
    }
};

export const hcSeriesFromUnitModelEvents = (unitEventsList: UnitEventsList, year: number): HCGanttSeries[] => {
    const unitModelEventsReturn: HCGanttSeries[] = [];

    const minDate = Date.UTC(year, 0);
    const maxDate = Date.UTC(year + 1, 11, 31);

    unitEventsList.items.forEach((unitModel, unitIdx) => {
        const unitModelEvents = unitModel.events
            .filter(evt => Date.parse(evt.eventEnd) >= minDate)
            .sortBy(evt => evt.eventStart);

        unitModelEvents.forEach((evt, evtIdx) => {
            const evtStartDate = Date.parse(evt.eventStart);
            const evtEndDate = Date.parse(evt.eventEnd);

            // if(evtEndDate >= minDate){
            unitModelEventsReturn.push({
                start: evtStartDate >= minDate ? evtStartDate : minDate,
                end: evtEndDate <= maxDate ? evtEndDate : maxDate,
                name: evt.eventType,
                y: unitIdx,
                colorIndex: colorIdxByEventType(evt.eventType, evt.importedEvent),
                borderRadius: 20,
                custom: {
                    unit: unitModel,
                    currentEvent: evt,
                    prevEvent: unitModelEvents[evtIdx - 1] ?? null,
                    nextEvent: unitModelEvents[evtIdx + 1] ?? null,
                    eventId: evt.id,
                    propertyId: evt.propertyId,
                    unitId: evt.unitId,
                    eventType: evt.eventType,
                    importedEvent: evt.importedEvent,
                    inPlaceRent: evt.inPlaceRent,
                    startingCalculatedInPlaceRent: evt.startingCalculatedInPlaceRent,
                    endingCalculatedInPlaceRent: evt.endingCalculatedInPlaceRent,
                    startingMarketRent: evt.startingMarketRent,
                    endingMarketRent: evt.endingMarketRent,
                    startingLossToLease: evt.startingLossToLease,
                    endingLossToLease: evt.endingLossToLease,
                    concessionMonths: evt.concessionMonths,
                    renewalAdjustmentPercentage: evt.renewalAdjustmentPercentage,
                    renovationCost: evt.renovationCost,
                    renovationPremium: evt.renovationPremium,
                    shortTermEffectiveAvgDailyRent: evt.shortTermEffectiveAvgDailyRent,
                    eventStart: evt.eventStart,
                    eventEnd: evt.eventEnd,
                    strings: {
                        eventTitle: getEventTitleString(evt),
                        eventSubtitle: getEventSubtitleString(evt),
                    },
                }
            });
            // }
        });
    });
    return unitModelEventsReturn;
};
