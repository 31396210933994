import { FinancialEntity } from "../../../contexts/chartofaccounts/ChartOfAccountsContext";
import { ModelingMethodType } from "../../reports/monthly-variance-report/components/monthly-variance-modal/components/budget-assumptions/data/enums/ModelingMethodTypeEnum";

export interface IFinancialsCOATableRow extends FinancialEntity {
    isExpanded: boolean;
    isTopLevelAccount: boolean;
    modelingType: ModelingMethodType[];
    hasOverrides: boolean;
    budgetValues: (number | null)[];
    reforecastValues: (number | null)[];
    budgetTotal: number;
    reforecastTotal: number;
    varianceAmount: number | null;
    variancePercent: number | null;
    note: string | null;
}

export const getModelingMethodLabel = (type: ModelingMethodType | undefined): string => {
    if (type === ModelingMethodType.CUSTOM_DRIVER) {
        return "CUSTOM";
    } else if (type === ModelingMethodType.OP_DRIVER) {
        return "OP";
    } else if (type === ModelingMethodType.LINE_ITEMS) {
        return "LINE IT";
    } else if (type === ModelingMethodType.GROWTH) {
        return "TRGT";
    } else if (type === ModelingMethodType.PAYROLL) {
        return "PAYROLL";
    } else if (type === ModelingMethodType.REVENUE) {
        return "REV";
    } else if (type === ModelingMethodType.RENOVATION_COSTS) {
        return "RENO";
    } else if (type === ModelingMethodType.PCT_OF_ACCOUNT_MULTI) {
        return "%MACT";
    } else if (type === ModelingMethodType.PCT_OF_ACCOUNT) {
        return "%ACT";
    } else {
        return "";
    }
};

