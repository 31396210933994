import { ReactElement, useEffect, useState } from "react";
import { ISimplifiedRevenueModeProps } from "../SimplifiedRevAnalystRenovationDrawer";
import * as css from "../../../pages/simplified-revenue/styles/OpDriverModeling.module.scss";
import { BsArrowRight } from "react-icons/bs";
import { Input as FormInput, Field as FormField, Label as FormLabel } from "@zendeskgarden/react-forms";
import {Autocomplete, Dropdown, Field, Item, Menu, Trigger} from "@zendeskgarden/react-dropdowns";
import CloseIcon from '@material-ui/icons/Close';
import { ReactComponent as ChevronDownStroke } from '@zendeskgarden/svg-icons/src/16/chevron-down-stroke.svg';
import { Button } from "@zendeskgarden/react-buttons";
import { TSimplifiedUnitType } from "../../../pages/simplified-revenue/Renovations";
import { formatterDollarUSNoDecimal } from "../../../utils/formatters";

export default function SimplifiedRevenueUnitTypeControls({simplifiedRevenueModeProps}: {simplifiedRevenueModeProps: ISimplifiedRevenueModeProps}): ReactElement {
    const {
        unitTypes,
        prevSelectedRenoUnitType,
        setSelectedRenoUnitType,
        selectableAsRenovatedUnitTypes,
        selectedClassicUnitType,
        selectedRenovatedUnitType,
        setMarketRent,
        setFakeUnitName,
        marketRent,
        fakeUnitName,
    } = simplifiedRevenueModeProps;

    const [unitTypeSearch, setUnitTypeSearch] = useState<string>("");

    const unitTypeOptions: TSimplifiedUnitType[] = [
        {
            id: "fake",
            type: "Custom Unit Type",
            isCustom: true,
            count: 0,
        },
        ...unitTypes.filter(x => !x.isCustom),
    ];
    const classicUnitTypeRentOptions: {label: string, value: number}[] = unitTypes.filter(x => !x.isCustom).map(x => ({
        label: `Copy ${formatterDollarUSNoDecimal.format(x.startingMarketRent ?? 0)} from ${x.type}`,
        value: (x.startingMarketRent ?? 0)
    }));

    return (
        <div className={css.renovationDrawerTopControls}>
            <div className={css.classicField}>
                <span className={css.classicLabel}>Classic Unit Type</span>
                <h5 className={css.classicValue}>{selectedClassicUnitType.type}</h5>
            </div>

            <BsArrowRight className={css.mappingArrow} />

            <div>
                <FormField className={css.fakeUnitTypeField}>
                    <FormLabel className={css.renovatedUnitTypeLabel}>
                        Renovated Unit Type

                        {(selectedRenovatedUnitType != undefined && selectedRenovatedUnitType.id != "placeholder") &&
                            <Button
                                className={css.resetAssociation}
                                size="small"
                                isBasic
                                isDanger
                                disabled={selectedRenovatedUnitType == undefined || selectedRenovatedUnitType.id == "placeholder"}
                                onClick={() => setSelectedRenoUnitType(undefined)}
                            >
                                <Button.StartIcon>
                                    <CloseIcon />
                                </Button.StartIcon>
                                Clear
                            </Button>
                        }
                    </FormLabel>

                    <div className={css.fakeUnitTypeFieldInputs}>
                        <Dropdown
                            selectedItem={selectedRenovatedUnitType}
                            onSelect={setSelectedRenoUnitType}
                            downshiftProps={{itemToString: (item: TSimplifiedUnitType) => item.id}}
                        >
                            <Trigger>
                                <Button
                                    focusInset
                                    isNeutral
                                    className={`
                                        ${css.fakeUnitTypeFieldInputsLeft}
                                        ${selectedRenovatedUnitType?.isCustom ? css.fakeUnitSelected : ""}
                                    `}
                                >
                                    {selectedRenovatedUnitType?.isCustom
                                        ? "Custom Unit Type"
                                        : selectedRenovatedUnitType?.type
                                    }
                                    <Button.EndIcon>
                                        <ChevronDownStroke />
                                    </Button.EndIcon>
                                </Button>
                            </Trigger>

                            <Menu className={css.fakeUnitTypeFieldInputsLeftTrigger}>
                                {selectedRenovatedUnitType?.id == "placeholder" &&
                                    <Item
                                        key={"placeholder"}
                                        value={"placeholder"}
                                        disabled
                                    >
                                        Select Unit Type
                                    </Item>
                                }
                                {unitTypeOptions.map(u => (
                                    <Item
                                        className={u.isCustom ? css.fakeUnitMenuItem : ""}
                                        key={u.id}
                                        value={u}
                                        disabled={
                                            !u.isCustom &&
                                            (u.id == selectedClassicUnitType?.id || (u.id != prevSelectedRenoUnitType?.id && !selectableAsRenovatedUnitTypes.map(x => x.id).includes(u.id)))
                                        }
                                    >
                                        {u.isCustom
                                            ? "Custom Unit Type"
                                            : u.type
                                        }
                                    </Item>
                                ))}
                            </Menu>
                        </Dropdown>
                        {selectedRenovatedUnitType?.isCustom &&
                            <FormInput
                                className={css.fakeUnitTypeFieldInputsRight}
                                placeholder={"Enter Name"}
                                value={fakeUnitName}
                                onChange={e => {
                                    setFakeUnitName(e.target.value);
                                }}
                            />
                        }
                    </div>
                </FormField>

                <FormField className={`${css.fakeUnitTypeField} ${css.fakeUnitTypeFieldMarketRent}`}>
                    <FormLabel className={css.renovatedUnitTypeLabel}>
                        Starting Market Rent
                    </FormLabel>

                    <div className={css.fakeUnitTypeFieldInputs}>
                        <Dropdown
                            inputValue={unitTypeSearch}
                            onInputValueChange={value => {setUnitTypeSearch(value)}}
                            onSelect={item => {
                                let value = item?.value ?? marketRent;
                                if(typeof value === "string") {
                                    const parsed = parseFloat(value);
                                    if(!isNaN(parsed)) {
                                        value = parsed;
                                    }
                                }
                                if(typeof value === "number") {
                                    value = formatterDollarUSNoDecimal.format(value);
                                }

                                setMarketRent(value);
                            }}
                        >
                            <Field>
                                <Autocomplete
                                    disabled={!selectedRenovatedUnitType?.isCustom}
                                    className={`
                                        ${css.fakeUnitTypeFieldInputsLeft}
                                        ${selectedRenovatedUnitType?.isCustom ? css.fakeUnitSelected : ""}
                                    `}
                                >
                                    {selectedRenovatedUnitType?.isCustom
                                        ? "Custom Market Rent"
                                        : formatterDollarUSNoDecimal.format(selectedRenovatedUnitType?.startingMarketRent ?? 0)
                                    }
                                </Autocomplete>
                            </Field>

                            <Menu hasArrow={!selectedRenovatedUnitType?.isCustom} className={`
                                ${css.fakeUnitTypeFieldInputsLeftTrigger}
                                ${css.fakeUnitTypeFieldInputsLeftTriggerMarketRents}
                            `}>
                                {selectedRenovatedUnitType?.id == "placeholder" &&
                                    <Item
                                        key={"placeholder"}
                                        value={"placeholder"}
                                        disabled
                                    >
                                        Copy or Enter Amount
                                    </Item>
                                }
                                {classicUnitTypeRentOptions.filter(x => x.label.toLowerCase().includes(unitTypeSearch.toLowerCase())).map((u, i) => (
                                    <Item
                                        key={i}
                                        value={u}
                                    >
                                        {u.label}
                                    </Item>
                                ))}
                            </Menu>
                        </Dropdown>

                        {selectedRenovatedUnitType?.isCustom &&
                            <FormInput
                                className={css.fakeUnitTypeFieldInputsRight}
                                placeholder={"Enter Starting Market Rent"}
                                value={marketRent}
                                onChange={e => {
                                    const intVal = parseInt(e.target.value);
                                    setMarketRent(isNaN(intVal) ? 0 : intVal);
                                }}
                            />
                        }
                    </div>
                </FormField>
            </div>
       </div>
    );

}