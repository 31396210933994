// noinspection DuplicatedCode

import GC from "@grapecity/spread-sheets";

export class BlankDashCell extends GC.Spread.Sheets.CellTypes.Text {
    constructor() {
        super();
        this.typeName = "BlankDashCell";
    }

    override paint(ctx: CanvasRenderingContext2D, value: any, x: number, y: number, w: number, h: number, style: GC.Spread.Sheets.Style, context?: any): void {

        super.paint(ctx, value, x, y, w, h, style, context);
    }

    override paintContent(ctx: CanvasRenderingContext2D, value: any, x: number, y: number, w: number, h: number, style: GC.Spread.Sheets.Style, context?: any): void {

        if (value === "" || value == null) {
            value = "-";
            style.hAlign = GC.Spread.Sheets.HorizontalAlign.center;
        }

        super.paintContent(ctx, value === "" || value == null ? "-" : value, x, y, w, h, style, context);
    }

}
