import { ThemeProvider } from "@zendeskgarden/react-theming";
import { ReactElement, useEffect, useState } from "react";
import { ViziblyTheme } from "../analyst/ViziblyZDGTheme";
import AdminHeader from "../admin/AdminHeader";
import * as css from "./styles/circularReferences.module.scss";
import { VersionType, useQueryAccountPercentageDestinationAccountsWithCyclesLazyQuery, useQuerySourceAccountsLazyQuery, useSetAccPercentageDriversMutation } from "../../__generated__/generated_types";
import Card from "../simplified-revenue/components/Card";
import { Dropdown, Field, Item, Menu, Select } from "@zendeskgarden/react-dropdowns";
import useAppStore from "../../hooks/useAppStore";
import { FixedSizeList, ListChildComponentProps } from "react-window";
import { Button } from "@zendeskgarden/react-buttons";
import { AccountDriverFormulaManyModal } from "../workflows/account/formula-bar/components/formula-nodes/account-driver-formula-many-modal/AccountDriverFormulaManyModal";
import { useProperties } from "../../contexts/properties/PropertiesContext";
import { Inline } from "@zendeskgarden/react-loaders";
import { COLORS } from "../../constants/Colors";

export type ItemData = {
    id: string,
    glNumber?: string | null,
    glName: string,
    order: number
}

export function itemText(item: ItemData): string {
    return `${item.glNumber ? (item.glNumber + " - ") : ""} ${item.glName}`;
}

type ItemProps = {
    item: ItemData,
    style?: React.CSSProperties,
    openAccPercentageEditor: (accountId: string) => void,
}

function ListItem({
    item,
    style,
    openAccPercentageEditor
}: ItemProps): ReactElement {
    return (
        <div
            style={style}
            className={css.item}
        >
            <div
                className={css.accountName}
            >
                {item.glNumber ? `${item.glNumber} - ` : ""}{item.glName}
            </div>
            <Button
                isBasic
                onClick={() => {
                    openAccPercentageEditor(item.id);
                }}
            >
                Edit
            </Button>
        </div>
    );
}

export function CircularReferences(): ReactElement {
    const [versionType, setVersionType] = useState<VersionType.Reforecast | VersionType.Budget>(VersionType.Budget);
    const [queryAccounts, {data: accountsData, loading: accountsLoading}] = useQueryAccountPercentageDestinationAccountsWithCyclesLazyQuery({
        fetchPolicy: "no-cache"
    });
    const [querySourceAccounts, {data: sourceAccountsData, loading: sourceAccountsLoading}] = useQuerySourceAccountsLazyQuery({
        fetchPolicy: "no-cache"
    });

    const [setAccPercentageDrivers] = useSetAccPercentageDriversMutation({
        ignoreResults: true,
        onCompleted: () => {
            refetchCycles();
        }
    });

    const appStore = useAppStore();
    const [items, setItems] = useState<ItemData[]>([]);
    const [displayItems, setDisplayItems] = useState<ItemData[]>([]);
    const [searchString, setSearchString] = useState<string>();
    const [refetchingCycles, setRefetchingCycles] = useState(false);
    const properties = useProperties();

    const [accountPercentageModalAccountId, setAccountPercentageModalAccountId] = useState<string>();

    function updateDisplayItems(
        sourceItems: ItemData[],
        searchString: string | undefined
    ) {
        let updated = [...sourceItems];
        if (searchString && searchString.length > 0) {
            updated = updated.filter(item => itemText(item).toLowerCase().includes(searchString.toLowerCase()));
        }
        setDisplayItems(updated);
    }

    function AccountRow(rowProps: ListChildComponentProps<ItemData[]>) {
        const { data: displayItems, index, style } = rowProps;
        const item = displayItems[index];
        if (!item) return null;
        return (
            <ListItem
                item={item}
                openAccPercentageEditor={(id) => setAccountPercentageModalAccountId(id)}
                style={style}
            />
        );
    }

    function refetchCycles() {
        const propertyId = properties.currentProperty?.id;
        if (propertyId) {
            setRefetchingCycles(true);
            queryAccounts({
                variables: {
                    propertyId: propertyId,
                    versionType: versionType
                }
            });
        }
    }

    useEffect(
        () => {
            refetchCycles();
        },
        [properties.currentProperty?.id, versionType]
    );

    useEffect(
        () => {
            if (accountsData && !accountsLoading) {
                const initialItems: ItemData[] = [];
                for (const row of accountsData.queryAccountPercentageDestinationAccountsWithCycles) {
                    initialItems.push({
                        id: row.id,
                        glNumber: row.glNumber,
                        glName: row.glName,
                        order: row.order
                    });
                }
                setItems(initialItems);
                updateDisplayItems(initialItems, searchString);
                setRefetchingCycles(false);
            }
            return () => undefined;
        },
        [accountsLoading, accountsData]
    );

    useEffect(
        () => {
            const property = properties.currentProperty;
            if (accountPercentageModalAccountId && property) {
                let year = property.reforecastYear;
                if (versionType == VersionType.Budget) {
                    year = property.reforecastYear + 1;
                }
                querySourceAccounts({
                    variables: {
                        propertyId: property.id,
                        versionType: versionType,
                        year: year,
                        accountId: accountPercentageModalAccountId
                    }
                });
            }
        },
        [accountPercentageModalAccountId, properties.currentProperty]
    );

    function handleUpdateDrivers(
        propertyId: string,
        destinationAccountId: string,
        year: number,
        versionType: VersionType.Reforecast | VersionType.Budget,
        drivers: { sourceAccountId: string, monthsAgo: number }[]
    ) {
        setRefetchingCycles(true);
        setAccPercentageDrivers({
            variables: {
                destinationAccountId: destinationAccountId,
                propertyId: propertyId,
                year: year,
                versionType: versionType,
                drivers: drivers.map(driver => ({
                    sourceAccountId: driver.sourceAccountId,
                    monthsAgo: driver.monthsAgo
                }))
            }
        });
    }


    useEffect(
        () => {
            appStore.set({ isLoading: false });
            return () => undefined;
        },
        []
    );

    console.log("refetchingCycles", refetchingCycles);
    return (
        <ThemeProvider theme={ViziblyTheme}>
            <div
                className={css.wrapper}
            >
                <AdminHeader
                    title={"% Of Account Circular references"}
                    subtitle={"Review and resolve any existing circular references in the % of Account modeling methods"}
                />

                <Card
                    title={"Examine Period"}
                    actions={
                        <Dropdown
                            selectedItem={versionType}
                            onSelect={(v) => setVersionType(v)}
                        >
                            <Field>
                                <Select style={{width: "10rem"}}>
                                    {versionType.toPascalCase()}
                                </Select>
                            </Field>
                            <Menu>
                                {[VersionType.Reforecast, VersionType.Budget].map(option => (
                                    <Item key={option} value={option}>
                                        {option.toPascalCase()}
                                    </Item>
                                ))}
                            </Menu>
                        </Dropdown>
                    }
                >
                    {refetchingCycles ?
                    <div className={css.noCircularReferences}><Inline size={24} color={COLORS.PRIMARY_500} /></div>
                    :
                    accountsData && !accountsLoading && accountsData.queryAccountPercentageDestinationAccountsWithCycles.length > 0 ?
                    <FixedSizeList<ItemData[]>
                        height={600}
                        itemCount={displayItems.length}
                        itemSize={80}
                        width={"100%"}
                        itemData={displayItems}
                    >
                        {AccountRow}
                    </FixedSizeList>
                    :
                    <div className={css.noCircularReferences}>No Circular References</div>
                    }
                </Card>

            </div>
            {accountPercentageModalAccountId &&
            properties.currentProperty &&
            !sourceAccountsLoading &&
            sourceAccountsData &&
            <AccountDriverFormulaManyModal
                accountIds={[accountPercentageModalAccountId]}
                propertyId={properties.currentProperty.id}
                versionType={versionType}
                year={versionType == VersionType.Reforecast ?
                        properties.currentProperty.reforecastYear
                        : properties.currentProperty.reforecastYear + 1}
                drivers={sourceAccountsData
                         .singlePropertyAccount
                         .accountPercentageDriver
                         ?.sourceAccountsBare
                         ?.map(acc => ({
                            accountId: acc.accountId,
                            glName: acc.glName,
                            glNumber: acc.glNumber ?? "",
                            lookbackPeriod: acc.lookbackPeriod
                         })) ?? []}
                onClose={() => setAccountPercentageModalAccountId(undefined)}
                onConfirm={(updates, _augments) => {
                    if (properties.currentProperty) {
                        handleUpdateDrivers(
                            properties.currentProperty.id,
                            accountPercentageModalAccountId,
                            versionType == VersionType.Reforecast ?
                                properties.currentProperty.reforecastYear
                                : properties.currentProperty.reforecastYear + 1,
                            versionType,
                            updates.map(update => ({
                                sourceAccountId: update.accountId,
                                monthsAgo: update.lookbackPeriod
                            })));
                    }
                    setAccountPercentageModalAccountId(undefined);
                }}
            />
            }
        </ThemeProvider>
    );
}