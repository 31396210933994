import { GetMvrFinancialsSingleAccountQuery, VersionType } from "../../../../../../../__generated__/generated_types";

export type MapFinancialsSingleAccountQueryResult = {
    actuals: number[], // 12 values array. defaulted to zeroes
    budget: number[], // 12 values array. defaulted to zeroes
}

const $12zeroes = new Array<number>(12).fill(0);

export function mapFinancialsSingleAccountQueryResult(
    data: GetMvrFinancialsSingleAccountQuery
): MapFinancialsSingleAccountQueryResult {
    let actuals = $12zeroes.copy<number>();
    let budget = $12zeroes.copy<number>();

    const actualValues = data.financialValuesForYear.find(finModel => finModel.versionType == VersionType.Actuals)?.values;
    const budgetValues = data.financialValuesForYear.find(finModel => finModel.versionType == VersionType.Budget)?.values;

    if (actualValues) {
        actuals = copyArrayWithDefaults(actualValues, 12);
    }

    if (budgetValues) {
        budget = copyArrayWithDefaults(budgetValues, 12);
    }

    return {
        actuals: actuals,
        budget: budget
    };
}

function copyArrayWithDefaults(data: number[], length: number): number[] {
    const result = [] as number[];
    for (let i = 0; i < length; i++) {
        let val = 0;
        if (data.length > i) {
            val = data[i] ?? 0;
        }

        result.push(val);
    }

    return result;
}