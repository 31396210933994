// TODO - The followings are migrated to
//  @see ./vizibly-frontend/app/src/utils/helper-funcs.ts
// Once the new migrated file is merged to main, the following functions need to be deleted
// And usage of the following functions need to be refactored with the new file's funcs references

export function dateUtcNow(): Date {
    const date = new Date();
    return new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
}

export function ensureDateInLocale(d: Date | string | null | undefined): Date | undefined {
    if(!d) {
        return undefined;
    }

    if(typeof d === "string") {
        d = new Date(d);
    }

    // Javascript dates only support current locale or UTC.
    // So, we only worry about dealing with those.
    // We can't actually determine that a value was created in UTC,
    // so we just go under certain assumptions with hours, minutes, seconds
    // being 0 in UTC.
    if(d.getUTCHours() === 0 && d.getUTCMinutes() === 0 && d.getUTCSeconds() === 0) {
        return new Date(d.getUTCFullYear(), d.getUTCMonth(), d.getUTCDate());
    }
    return d;
}

export function ensureDateInUtc(d: Date | string | null | undefined): Date | undefined {
    if(!d) {
        return undefined;
    }

    if(typeof d === "string") {
        d = new Date(d);
    }

    if(d.getUTCHours() !== 0 || d.getUTCMinutes() !== 0 || d.getUTCSeconds() !== 0) {
        return new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate()));
    }
    return d;
}
