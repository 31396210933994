/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-empty-function */
import {ReactElement, useState, useEffect} from "react";
import {BudgetingType} from "../../BudgetingType";
import {AnnualSummary} from "../../components/annualsummary/AnnualSummary";
import ToggleCategory from "../../components/sidebar/ToggleCategory";
import {DashboardType} from "./DashboardType";
import {BsGraphUp} from "react-icons/bs"; //temp, graph not the same as figma.
import "./Dashboard.scss";
import {useSettings} from "../../contexts/settings/SettingsContext";
import {YearDefinitionsProvider} from "../../components/annualsummary/YearDefinitions";
import useAppStore from "../../hooks/useAppStore";
import useBroadcast from '../../hooks/useBroadcast';
import BudgetExportButton from "../../components/budget-export/BudgetExportButton";
import {Col, Grid, Row} from '@zendeskgarden/react-grid';
import {useConfig} from "../../hooks/useConfig";
import {VersionType} from "../../__generated__/generated_types";
import {Button} from "@zendeskgarden/react-buttons";

function Dashboard(): ReactElement {

    const appStore = useAppStore();
    const config = useConfig();

    const [tab, setTab] = useState<BudgetingType | undefined>();
    const [dashboardType, setDashboardType] = useState<DashboardType>(DashboardType.ANNUAL_SUMMARY);
    const {year: currentYear} = useSettings();
    const [refreshTriggered, setRefreshTriggered] = useState<boolean>(false);

    const bc = useBroadcast();

    useEffect(() => {
        bc.receiveMessage((event) => {
            const {rowName, col, value} = event?.data || {};
            const docNode = document.getElementById(rowName + "-" + (col - 4));
            const totalDocNode = document.getElementById(rowName + "-total");
            const allNodes = document.querySelectorAll("." + rowName);

            if (docNode) docNode.innerHTML = value;

            let accum = 0;
            for (let i = 0; i < allNodes.length; i++) {
                if (allNodes[i]) {
                    const l = allNodes[i]?.innerHTML;
                    accum += parseInt(l!);
                }
            }

            if (totalDocNode) totalDocNode.innerHTML = accum.toString() || "Refresh";
        });
        return () => {
        };
    }, [bc]);

    useEffect(() => {
        appStore.set({isLoading: false});
        return () => { };
    }, []);

    useEffect(() => {
        if (!config.isReady) {
            return;
        }

        setTab(config.versions.selectedVersionType == VersionType.Reforecast ? BudgetingType.REFORECAST : BudgetingType.BUDGET);
    }, [config.isReady]);

    useEffect(() => {
        if (tab === undefined) {
            return;
        }

        config.versions.setSelectedVersionType(tab == BudgetingType.REFORECAST ? VersionType.Reforecast : VersionType.Budget);
    }, [tab]);

    if (!config.isReady || tab == undefined) {
        return <></>;
    }

    return (
        <>
            <YearDefinitionsProvider tab={tab}>
                <div className="dashboard-title justify-content-center main-container">
                    <Grid>
                        <Row>
                            <Col size={4} />
                            <Col size={4}>
                                <div className="dashboard-text"> Overview </div>
                            </Col>
                            <Col size={4} className="d-flex justify-content-end pe-0">
                                <BudgetExportButton tab={tab} />
                            </Col>
                        </Row>
                    </Grid>
                </div>
                <div className={"dashboard-container d-flex justify-content-between pb-2 main-container "}>
                    <div className="dashboard-types d-flex">
                        <button
                            className={"annual-button " + (dashboardType === DashboardType.ANNUAL_SUMMARY ? "text-grey-20" : "text-philippine-grey")}
                            onClick={() => setDashboardType(DashboardType.ANNUAL_SUMMARY)}>
                            <BsGraphUp className="fs-4 me-1" /> Annual Summary
                        </button>
                    </div>


                    <div className="dashboard-toggle">
                        <Button size="small" className={"dashboard-toggle__fetch-button"} onClick={() => setRefreshTriggered(true)} disabled={refreshTriggered}>Refresh Data</Button>
                        <ToggleCategory
                            year={currentYear}
                            tabName={tab}
                            onToggle={setTab}
                        />
                    </div>
                </div>
                <div className="dashboard h-100 overflow-hidden">
                    {dashboardType === DashboardType.ANNUAL_SUMMARY &&
                        <AnnualSummary tab={tab} refreshTriggered={refreshTriggered} refreshCompletedCallback={() => setRefreshTriggered(false)} />
                    }
                </div>
            </YearDefinitionsProvider>
        </>
    );
}

export default Dashboard;


