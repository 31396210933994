import { DEFAULT_THEME, IGardenTheme } from "@zendeskgarden/react-theming";
import { css } from "styled-components";

export const ViziblyTheme:IGardenTheme = {
    ...DEFAULT_THEME,
    colors:{
        ...DEFAULT_THEME.colors,
        primaryHue: "#0D666D",
    },
    iconSizes:{
        sm: "16px",
        md: "16px",
        lg: "16px"
    },
    lineHeights:{
        sm: "16px",
        md: "16px",
        lg: "16px",
        xl: "16px",
        xxl: "16px",
        xxxl: "16px"
    },
    // TODO: put styling for checkbox here,
    //       component ids: forms.checkbox, forms.check_svg and something else that is responsible for indeterminate state
    // components:{
    //     "forms.check_svg":css`
    //         ${p => p.theme.components["forms.check_svg"]}
    //         background-color: red;
    //     `,
    // }
};
