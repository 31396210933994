import { ReactElement, useEffect, useRef, useState } from "react";

import { TDriverGrowthMetric } from "../../../../../../contexts/account/data/logic/driversAndWorksheetData";
import { DistributionMethod } from "../../../../../../__generated__/generated_types";
import { distributionMethodMap } from "../../../../../../contexts/account/data/utils";
import AnnualTargetValueMenu from "../formula-menu/AnnualTargetValueMenu";

import * as css from "./styles/css.module.scss";
import { deleteNodeKeys, TFormulaNodeProps } from "./logic/formulaNode";


type TAnnualTargetValueGrowthDriverFxNodeProps = TFormulaNodeProps&{
    drivers: TDriverGrowthMetric[],
}

export default function AnnualTargetValueGrowthDriverFxNode(props: TAnnualTargetValueGrowthDriverFxNodeProps): ReactElement {
    const [isFocused, setIsFocused] = useState<boolean>(false);
    const [childNodeHovered, setChildNodeHovered] = useState<boolean>(false);

    const wrapperRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (isFocused && wrapperRef.current) {
            wrapperRef.current.focus();
        }
    }, [isFocused]);

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (
                wrapperRef.current &&
                (wrapperRef.current === event.target ||
                wrapperRef.current.contains(event.target as Node))
            ) {
                return;
            }
            setIsFocused(false);
        };

        // Clicking outside the current node should remove focus
        document.addEventListener("mousedown", handleClickOutside);

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    const handleClick = () => {
        setIsFocused(!isFocused);
    };

    const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
        if (
            isFocused && deleteNodeKeys.includes(e.key)
        ) {
            props.fbUpdates.removeAnnualTargetValueDriver();
        }
    };

    const annualTargetValueManualEntry = props.drivers[0]?.annualTargetValueManualEntry || 0;
    const distributionMethod = props.drivers[0]?.distributionMethod || DistributionMethod.Flat;

    const annualTargetValueNode = <a style={{marginLeft: "6px"}}>${annualTargetValueManualEntry}</a>;
    const distributionMethodNode = <a style={{marginLeft: "6px"}}>{distributionMethodMap[distributionMethod]}</a>;

    const nodeClassName = props.locked ? css.fxNodeLocked : css.fxNode;
    const className = `${nodeClassName} ${props.className ? props.className : ''} ${css.deletableNode} ${isFocused ? css.focusedNode : ''} ${childNodeHovered ? css.disableHover : ''}`;

    const renderAnnualTargetValueNode = (): string | JSX.Element => {
        if (props.locked) {
            return <>&nbsp;${annualTargetValueManualEntry}</>;
        }
        return (
            <AnnualTargetValueMenu
                fbUpdates={props.fbUpdates}
                level={0}
                triggerNode={annualTargetValueNode}
            />
        );
    };

    const renderDistributionMethodNode = (): string | JSX.Element => {
        if (props.locked) {
            return <>&nbsp;{distributionMethodMap[distributionMethod]}</>;
        }
        return (
            <AnnualTargetValueMenu
                fbUpdates={props.fbUpdates}
                level={1}
                triggerNode={distributionMethodNode}
            />
        );
    };

    return (
        <div
            className={className}
            onClick={handleClick}
            onKeyDown={handleKeyDown}
            onMouseEnter={() => setChildNodeHovered(false)}
            ref={wrapperRef}
            tabIndex={0}
        >
            (Annual Target Amount:
            <div
                className={css.fxNode}
                onMouseEnter={() => setChildNodeHovered(true)}
                onMouseLeave={() => setChildNodeHovered(false)}
            >
                {renderAnnualTargetValueNode()}
            </div>
            &nbsp;with
            <div
                className={css.fxNode}
                onMouseEnter={() => setChildNodeHovered(true)}
                onMouseLeave={() => setChildNodeHovered(false)}
            >
                {renderDistributionMethodNode()}
            </div>
            )
        </div>
    );
}
