import { TFormulaNodeProps } from "./logic/formulaNode";
import { ReactElement } from "react";
import * as css from "./styles/css.module.scss";
import JunctionNode from "./JunctionNode";
import RenovationsDriverItemNode from "./RenovationsDriverItemNode";

export type TRenovationCost = {
    name: string,
    id: string,
}

type TRenovationsDriverFormulaNodeProps = TFormulaNodeProps&{
    renovationCosts: TRenovationCost[],
    budgetYear: number,
}

export default function RenovationsDriverFxNode(props:TRenovationsDriverFormulaNodeProps):ReactElement{
    const nodeClassName = props.locked ? css.fxNodeLocked : css.fxNode;
    return (
            <>
                <div className={props.className ? `${nodeClassName} ${props.className}` : nodeClassName}>
                    <span>(Renovation Costs:</span>
                    {props.renovationCosts.map((renoCost, idx, arr) =>
                            <span className={nodeClassName} key={`worksheet_line_item_${idx}`}>
                                <RenovationsDriverItemNode
                                        renoCostName={renoCost.name}
                                        locked={props.locked} // TODO: Replace w/ reno cost locked value (need to add to API response)
                                />
                                {idx < arr.length - 1
                                        ? <JunctionNode locked={props.locked} budgetYear={props.budgetYear} fbUpdates={props.fbUpdates} />
                                        : <></>
                                }
                            </span>
                    )}
                    <span>)</span>
                </div>
            </>
    );
}

// TODO: Move JunctionNode up and out of here https://github.com/vizibly-inc/vizibly/pull/1666#discussion_r1101791914
