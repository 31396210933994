// Custom Cell Configs ________________________________
import {
    FinancialEntityCellInfo
} from "../../../components/spreadsheet/spreadjs-custom-cell-types/FinancialEntityCell";
import { AnalystRenovationsSheetStyles as renoStyles } from "./AnalystRenovationsSheetStyles";
import * as Icons from "../../../components/spreadsheet/spreadjs-custom-cell-types/icons/IconResources";
import { CellDataStyle } from "../tab-op-drivers/types";

// TODO: DRY this up. This is a copy of the Op Drivers Metric config.
export const RENO_PROPERTY_CELL_INFO:FinancialEntityCellInfo = {
    level: 0,
    style: renoStyles.PROPERTY_LABEL,
    toggleIconSpecs: {
        isOpen: false,
        openIconImg: Icons.chevronOpen,
        openIconSpecs: {
            x: 256,
            y: 16,
            width: Icons.chevronOpenW,
            height: Icons.chevronOpenH,
        },
        closedIconImg: Icons.chevronClosed,
        closedIconSpecs: {
            x: 256,
            y: 16,
            width: Icons.chevronClosedW,
            height: Icons.chevronClosedH,
        },
    },
};// Property name cell config
export const UNIT_CELL_INFO:FinancialEntityCellInfo = {
    level: 1,
    style: renoStyles.UNIT_LABEL,
    buttonSpecs:{
        buttonImg: Icons.setupButton,
        buttonHoverImg: Icons.setupButtonHover,
        x: 180,
        y: 10,
        width: Icons.setupW,
        height: Icons.setupH,
    }
};

export const UNIT_CELL_INFO_W_PACKAGES:FinancialEntityCellInfo = {
    ...UNIT_CELL_INFO,
    ...{
        level: 1,
        style: renoStyles.UNIT_LABEL,
        toggleIconSpecs: {
            isOpen:false,
            openIconImg: Icons.chevronOpen,
            openIconSpecs: {
                x: 256,
                y: 16,
                width: Icons.chevronOpenW,
                height: Icons.chevronOpenH,
            },
            closedIconImg: Icons.chevronClosed,
            closedIconSpecs: {
                x: 256,
                y: 16,
                width: Icons.chevronClosedW,
                height: Icons.chevronClosedH,
            },
        },
    },
};

export const RENO_PACKAGE_INFO:FinancialEntityCellInfo = {
    level: 2,
    style: renoStyles.RENO_PACKAGE_LABEL,
};
