import React, { ReactElement, useState } from "react";

import { MarketRentsProps } from "./helpers/types";
import ShimmerRenderer, { ShimmerPageKey } from "../../../components/shimmer/ShimmerRenderer";
import MarketRentsSpread from "./sjs/MarketRentsSpread";

function MarketRents({ setLoadProgress }: MarketRentsProps): ReactElement {

    const [viewReady, setViewReady] = useState<boolean>(false);
    const [dataReady, setDataReady] = useState<boolean>(false);

    return (
        <>
            {(!viewReady || !dataReady) &&
                <div>
                    <ShimmerRenderer pageKey={ShimmerPageKey.ANALYST_VIEW}/>
                </div>}
            <div
                style={{
                    ...(
                        (!viewReady) ? { display: 'none' } : {}
                    )
                }}>
                <MarketRentsSpread
                    setViewReady={setViewReady}
                    setDataReady={setDataReady}
                    setLoadProgress={setLoadProgress}
                />
            </div>
        </>
    );
}

export default MarketRents;
