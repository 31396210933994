import React, {ReactElement, MouseEventHandler} from 'react';

export interface ModalWindowProps {
    isOpen: boolean;
    contentReady?:boolean;
    children?: ReactElement[] | ReactElement;
    primaryText?: string;
    onSave?: MouseEventHandler;
    onClose?: MouseEventHandler;
    title: string | ReactElement;
    canCancel?: boolean;
    canConfirm: boolean;
    hasBorders?: boolean;
    saveBtnVariant?: string;
    size?: "md" | "xl" | undefined;
    blur?: boolean;
    darkener?: boolean;
}

export default function ModalWindow(props: ModalWindowProps): ReactElement {
  if (!props.isOpen) {
    return (<></>);
  }

  return (
    <div className={`modal fade show d-block ${props.blur ? "blur" : ""} ${props.darkener ? "darkener" : ""} `} aria-labelledby="modalLabel" data-backdrop="static" aria-hidden="false">
      <div className={`modal-dialog modal-${props.size ?? "md"}`}>
        <div className="modal-content">
          <div className={"modal-header " + (!props.hasBorders && "border-bottom-0") }>
            <div className="modal-title fs-4" id="modalLabel"> {props.title} </div>
            <button onClick={props.onClose} type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div className="modal-body small">
            {props.children}
          </div>
            {(props.canCancel || props.canConfirm) && <div className={"modal-footer d-flex " + (props.hasBorders ? "justify-content-center" : "justify-content-end border-top-0")}>
            {props.canCancel &&
              <button type="button" className="btn border-primary text-primary" data-bs-dismiss="modal" onClick={props.onClose}> Cancel </button>
            }
            {props.canConfirm &&
              <button type="button" className={`btn btn-${props.saveBtnVariant ?? "primary"}`} onClick={props.onSave}> {props.primaryText ?? 'Save'} </button>
            }
          </div>}
        </div>
      </div>
    </div>
  );
}


ModalWindow.defaultProps = {
  canCancel: true,
  hasBorders: true
};
