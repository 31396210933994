import { useEffect, useState } from "react";
import { useGetTransactionsLazyQuery, SortOrder } from "../../../../../../../../__generated__/generated_types";
import { buildTransactionRows, TransactionRowViewModel } from "./logic/buildTransactionRows";
import * as MVRTransactionsTableConfig from "../MVRTransactionsTableConfig";

export interface UseMVRTransactionProps {
    propertyId: string;
    accountId: string;
    year: number;
    monthIndex: number;
}

export type PaginationState = {
    page: number;
    maxPage: number;
    totalItems: number;
}

interface IUseMVRTransactionsReturn {
    paginationState: PaginationState,
    setPage: (page: number) => void,
    transactions: TransactionRowViewModel[],
    isLoading: boolean,
}

export function useMVRTransactions(props: UseMVRTransactionProps):IUseMVRTransactionsReturn {
    const [fetch, { data, loading }] = useGetTransactionsLazyQuery({
        fetchPolicy: "network-only"
    });

    const [transactions, setTransactions] = useState<TransactionRowViewModel[]>([]);

    const [paginationState, setPaginationState] = useState<PaginationState>({
        page: 1,
        maxPage: 1,
        totalItems: 1
    });

    const setPage = (page: number) => {
        setPaginationState(prev => ({
          ...prev,
          page: page
        }));
    };

    useEffect(() => {
        setPaginationState({
            page: 1,
            maxPage: 1,
            totalItems: 1
        });
    },
    [props.propertyId, props.accountId, props.year, props.monthIndex]
    );

    useEffect(
        () => {
            const propertyId = props.propertyId;
            fetch({
                variables: {
                    propertyId: propertyId,
                    accountId: props.accountId,
                    year: props.year,
                    monthIndex: props.monthIndex,
                    pagination: {
                        page: paginationState.page,
                        take: MVRTransactionsTableConfig.TRANSACTIONS_PER_PAGE
                    },
                    sort: {
                        specs: [{
                            field: "postDate",
                            order: SortOrder.Desc
                        }]
                    },
                    filter: {
                        specs: []
                    }
                }
            });
        },
        [props.propertyId, props.accountId, props.year, props.monthIndex, paginationState.page]
    );

    useEffect(
        () => {
            if (data && !loading) {
                const transactions = buildTransactionRows(data.queryTransactions.items);
                setPaginationState(prev => ({
                    ...prev,
                    maxPage: data.queryTransactions.maxPage,
                    page: data.queryTransactions.page,
                    totalItems: data.queryTransactions.total
                }));
                setTransactions(transactions);
            }
        },
        [data, loading]
    );

    return {
        paginationState: paginationState,
        setPage: setPage,
        transactions: transactions,
        isLoading: loading,
    };
}