import {
    LocalPayrollCompensationItemModel,
    LocalPayrollEmployeeBonusModel,
    LocalPayrollEmployeeCompItemModel,
    LocalPayrollEmployeeOvertimeModel,
    LocalPayrollEmployeeRaiseModel
} from "./types";
import {
    PayrollCompensationItemCalculationType,
    PayrollCompensationItemModel,
    PayrollCompensationItemType
} from "../../../__generated__/generated_types";

export const DateFormatter = new Intl.DateTimeFormat('en-US', {
    month: '2-digit',
    day: '2-digit',
    year: 'numeric'
});

export const getDefaultLocalPayrollEmployeeRaiseModel: (() => LocalPayrollEmployeeRaiseModel) = () => {
    return {
        amount: "",
        frequency: null,
        raiseType: null,
        raiseDay: "",
        raiseMonth: "",
        effectiveStartDate: undefined,
        onetimeRaiseDate: undefined,
    };
};

export const getDefaultLocalPayrollEmployeeBonusModel: (() => LocalPayrollEmployeeBonusModel) = () => {
    return {
        amount: "",
        frequency: null,
        bonusType: null,
        bonusDay: "",
        bonusMonth: "",
        effectiveStartDate: undefined,
        onetimeBonusDate: undefined
    };
};

export const getDefaultLocalPayrollEmployeeOvertimeModel: (() => LocalPayrollEmployeeOvertimeModel) = () => {
    return {
        effectiveStartDate: undefined,
        effectiveEndDate: undefined,
        expectedHoursPerWeek: "",
        hourlyCompensation: ""
    };
};

export const getDefaultLocalPayrollEmployeeCompItemModel: (
    (compensationItem: Pick<PayrollCompensationItemModel, 'id' | 'type' | 'customName'> | LocalPayrollCompensationItemModel) =>
        LocalPayrollEmployeeCompItemModel|null) =
    (compensationItem) => {
    if (compensationItem) {
        const doesCompensationItemHaveAnnualCostCap =
            [PayrollCompensationItemType.Medical, PayrollCompensationItemType.Dental, PayrollCompensationItemType.Vision].indexOf(compensationItem.type) === -1;
        return {
            id: "",
            compensationItem: {
                id: compensationItem.id,
                customName: compensationItem.customName ?? "",
                type: compensationItem.type
            },
            annualCostCap: "",
            costPerPayPeriod: "",
            effectiveStartDate: undefined,
            calculationType: doesCompensationItemHaveAnnualCostCap ? PayrollCompensationItemCalculationType.PercentOfPrimaryCompensation : PayrollCompensationItemCalculationType.FixedAmount
        };
    }
    return null;
};

export const Stringify = (sample: any) => {
    return JSON.stringify(sample, (_, v) => v === undefined ? "#undefined#" : v, '\t');
};
