import "native-injects";
import React, {ReactElement, useContext, useEffect, useRef, useState} from 'react';
import {useParams} from 'react-router-dom';
import {BudgetingType} from '../../../../BudgetingType';
import {allowRightClickCopyPaste, NewSpreadsheet} from '../../../../components/spreadsheet/NewSpreadsheet';
import {useNewSpreadsheetAPI} from '../../../../components/spreadsheet/NewSpreadsheetHooks';
import {UnitType, useProperties} from '../../../../contexts/properties/PropertiesContext';
import {useSettings} from '../../../../contexts/settings/SettingsContext';
import useGetMonths from '../../../../hooks/UseGetMonths';
import {GetAverageRentQuery, useGetAverageRentQuery, useGetForecastLocksLazyQuery, VersionType} from '../../../../__generated__/generated_types';
import {HandleSaveValues, useSaveUnitTypeScheduleValues} from '../base';
import viewTemplate from "./average_rent_view.json";
import "../operational.scss";
import {AuthContext} from "../../../../contexts/AuthContext";
import {userIsReadOnly} from "../../../../authorization/AuthorizationCheckers";

import {
    WORKFLOW_YEAR_TAB_BUDGET
} from "../../../../components/workflows/workflow-year-tabs/WorkflowYearTabs";
import {shouldUpdateWorkflowTab} from "../../logic/workflows";

const REFORECAST_INPUT_ROW = 2, REFORECAST_INPUT_COL = 5;
const YEAR_INPUT_ROW = 3, YEAR_INPUT_COL = 5;
const HAS_VALUE_COL = 17;
const FIRST_VALUE_ROW = 6, FIRST_VALUE_COL = 4;


interface Data {
    data: GetAverageRentQuery | undefined;
    months: string[];
    year: number;
    startReforecastMonthIndex: number;
    propertyId: string;
    unitTypes: UnitType[];
    saveValues: HandleSaveValues;
}
interface DataReadyResult {
    isReady: boolean;
    updatesCount: number;
}
interface DataState {
    isDataReady: DataReadyResult;
    getData: () => Data;
}

function useAverageRentData(type: BudgetingType): DataState {
    const [isDataReady, setDataReady] = useState<DataReadyResult>({ isReady: false, updatesCount: 0 });
    const { propertyId, unitTypes: unitTypesUnsorted, loading: propertiesLoading, loaded: propertiesLoaded } = useProperties();
    const [unitTypes, setUnitTypes] = useState<UnitType[]>([]);
    const { year, startReforecastMonthIndex, isDefaults } = useSettings();
    const months = useGetMonths();
    const budgetYear = year + 1;
    const { loading, error, data } = useGetAverageRentQuery({
        variables: {
            propertyId,
            versionType: type == BudgetingType.BUDGET ? VersionType.Budget : VersionType.Reforecast,
            budgetYear: budgetYear,
            versionYear: type == BudgetingType.BUDGET ? year + 1 : year,
            year
        },
        fetchPolicy: "network-only"
    });

    useEffect(
        () => {
            if (unitTypesUnsorted && unitTypesUnsorted.length > 0) {
                const unitTypes = unitTypesUnsorted.sortBy(ut => ut.type);
                setUnitTypes(unitTypes);
            }
        },
        [propertiesLoading, unitTypesUnsorted, propertiesLoaded]
    );

    useEffect(
        () => {
            const dataReady = !isDefaults && !loading && data != undefined && !error && !propertiesLoading;

            setDataReady(oldVal => ({ isReady: dataReady, updatesCount: oldVal.updatesCount + 1 }));
        },
        [isDefaults, loading, data, propertiesLoading]
    );

    const saveValues = useSaveUnitTypeScheduleValues(propertyId, "averageMarketRent");

    const getData = () => {

        return {
            data,
            months,
            year,
            startReforecastMonthIndex,
            propertyId,
            unitTypes,
            saveValues
        };

    };

    return {
        isDataReady: isDataReady ?? false,
        getData: getData
    };
}

function getDataDetails(data: GetAverageRentQuery) {
    const rows = data.actuals.find(version => version.unitTypeScheduleValues.isNotEmpty) ?? data.actuals.firstElement;
    const groups = rows.unitTypeScheduleValues.groupBy(row => row.unitType.name);
    const currentVersion = data.currentVersion.find(version => version.unitTypeScheduleValues.isNotEmpty) ?? data.currentVersion.firstElement;
    const currentVersionGroups = currentVersion?.unitTypeScheduleValues?.toIdMap(unit => unit.unitType.name + "#" + unit.monthIndex) ?? {};

    return { actualRows: rows, currentVersion, currentVersionGroups, groups };
}

export default function AverageRentManual(): ReactElement {
    const { type } = useParams() as { type: BudgetingType };
    const { user } = useContext(AuthContext);

    const { year } = useSettings();
    const { isDataReady, getData: getRemoteData } = useAverageRentData(type);

    const unitsCount = useRef<number>(0);

    const spreadsheet = useNewSpreadsheetAPI();

    const [getForecastLocks, {data: dataForecastLocks, loading: lockDataLoading}] = useGetForecastLocksLazyQuery({
        fetchPolicy: "no-cache",
    });

    useEffect(() => {
        if (!isDataReady.isReady) {
            return;
        }

        const {propertyId} = getRemoteData();

        getForecastLocks({
            variables: {
                propertyId: propertyId,
                budgetYear: year + 1,
            }
        });
    }, [isDataReady.isReady]);

    useEffect(
        () => {
            if (lockDataLoading) {
                return;
            }

            const data = getRemoteData().data;

            if (isDataReady.isReady && spreadsheet.isSpreadsheetReady && data) {
                const { unitTypes, months, year, startReforecastMonthIndex } = getRemoteData();
                const { groups, currentVersionGroups } = getDataDetails(data);


                spreadsheet.api.setTemplate({ template: viewTemplate, rows: unitTypes.length + 2 });
                unitsCount.current = unitTypes.length;

                spreadsheet.api.suspend();
                spreadsheet.api.directAccess(spread => {
                    spread.options.allowUserDragDrop = false;
                    spread.options.allowContextMenu = false;
                    spread.options.showDragFillSmartTag = false;
                });

                spreadsheet.api.setValue({ row: YEAR_INPUT_ROW, col: YEAR_INPUT_COL, value: type == BudgetingType.REFORECAST ? year : year + 1 });
                spreadsheet.api.setValue({ row: REFORECAST_INPUT_ROW, col: REFORECAST_INPUT_COL, value: type == BudgetingType.REFORECAST ? startReforecastMonthIndex : 0 });
                spreadsheet.api.setArray({ row: FIRST_VALUE_ROW - 1, col: FIRST_VALUE_COL, array: [months] });
                const dataArray: (string | number | null)[][] = unitTypes.map(({ type: unitType }) => {
                    const values = groups[unitType] ?? [];
                    const months = values.toIdMap("monthIndex");

                    return [
                        unitType,
                        ...new Array(12).fill(null).map((_, monthIndex) => {
                            const isEditable = type == BudgetingType.BUDGET || monthIndex >= startReforecastMonthIndex;
                            const currentValue = currentVersionGroups[unitType + "#" + monthIndex];
                            const value = isEditable ? (parseInt(currentValue?.averageMarketRent ?? 0) ?? 0) : parseInt(months[monthIndex]?.averageMarketRent ?? 0);
                            return value;
                        })
                    ];
                });

                spreadsheet.api.directAccess(spread => {
                    const sheet = spread.getActiveSheet();
                    sheet.setRowCount(dataArray.length + FIRST_VALUE_ROW);
                    allowRightClickCopyPaste(spread);
                });

                const clearFormulaArray = dataArray.map(row => row.map(_ => null));
                spreadsheet.api.setArray({ row: FIRST_VALUE_ROW, col: FIRST_VALUE_COL - 1, array: clearFormulaArray, setFormula: true });
                spreadsheet.api.setArray({ row: FIRST_VALUE_ROW, col: FIRST_VALUE_COL - 1, array: dataArray });

                const lockedColumnsCount = type == BudgetingType.REFORECAST ? startReforecastMonthIndex : 0;
                if (lockedColumnsCount > 0) {
                    spreadsheet.api.setCellsLocked({ row: FIRST_VALUE_ROW, col: FIRST_VALUE_COL, rowCount: dataArray.length, colCount: lockedColumnsCount, locked: true });
                }
                const unLockedColumnsCount = 12 - lockedColumnsCount;
                if (unLockedColumnsCount > 0) {
                    spreadsheet.api.setCellsLocked({ row: FIRST_VALUE_ROW, col: FIRST_VALUE_COL + lockedColumnsCount, rowCount: dataArray.length, colCount: unLockedColumnsCount, locked: false });
                }
                // const unitMixArray = unitTypes.map(row => [row.type, row.count]);
                // spreadsheet.api.setArray({ row: UNIT_MIX_FIRST_ROW, col: UNIT_MIX_FIRST_COL, array: unitMixArray, sheetName: UNIT_MIX_TAB });

                spreadsheet.api.setArray({ row: FIRST_VALUE_ROW, col: HAS_VALUE_COL, array: new Array(unitTypes.length).fill(1) });

                if(userIsReadOnly(user)){
                    spreadsheet.api.lockAllCells();
                }

                spreadsheet.api.resume();

                setAccountDataLoading(false);
            }
        },
        [spreadsheet.isSpreadsheetReady, isDataReady.isReady, lockDataLoading]
    );

    useEffect(
        () => {
            const { data, unitTypes, startReforecastMonthIndex, saveValues } = getRemoteData();

            const lastRow = FIRST_VALUE_ROW + unitTypes.length - 1;
            const firstCol = FIRST_VALUE_COL + (type == BudgetingType.REFORECAST ? startReforecastMonthIndex : 0);
            const lastCol = FIRST_VALUE_COL + 11;
            const cellsToUpdate = spreadsheet.cellsChanged.filter(cell => cell.row >= FIRST_VALUE_ROW && cell.row <= lastRow && cell.col >= firstCol && cell.col <= lastCol);

            if (data && cellsToUpdate.length > 0) {
                const { currentVersion } = getDataDetails(data);
                const values = [];
                for (const cell of cellsToUpdate) {
                    const monthIndex = cell.col - FIRST_VALUE_COL;
                    const unitType = spreadsheet.api.getValue({ row: cell.row, col: FIRST_VALUE_COL - 1 }) as string;
                    values.push({ monthIndex, unitTypeName: unitType, value: parseFloat(cell.value?.toString() ?? "0").toString() });
                }
                saveValues(currentVersion.id, values);
            }
        },
        [spreadsheet.cellsChanged]
    );

    /**
     * Update selected tab, which updates display, on type change
     */
    useEffect(
            () => {
                const updateWorkflowTab = shouldUpdateWorkflowTab(type, selectedTab);
                if(updateWorkflowTab != false){
                    setSelectedTab(updateWorkflowTab as string);
                }
            },
            [type]
    );

    const [selectedTab, setSelectedTab] = useState(WORKFLOW_YEAR_TAB_BUDGET);
    const [accountDataLoading, setAccountDataLoading] = useState<boolean>(true);

    return (
        <>
            {dataForecastLocks?.forecastLocks?.property &&
            <NewSpreadsheet
                    startRow={FIRST_VALUE_ROW - 1}
                    startCol={FIRST_VALUE_COL - 1}
                    rows={2}
                    cols={14}
                    handlers={spreadsheet.handlers}
                    subscribeToMouse={false}
                    readOnly={
                        type === BudgetingType.REFORECAST ? dataForecastLocks.forecastLocks.property.reforecastLocked : dataForecastLocks.forecastLocks.property.budgetLocked
                    }
            />
            }
        </>
    );
}
