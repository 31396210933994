import Handsontable from "handsontable";

export type UseCsvExportAPI = {
    exportAsCsv: (hot: Handsontable) => void;
}

export function useCsvExport(): UseCsvExportAPI {
    const exportAsCsv = (hot: Handsontable) => {
        const exportPlugin = hot.getPlugin("exportFile");
        exportPlugin.downloadFile(
            "csv",
            {
                filename: "line_items",
                exportHiddenRows: true,
            }
        );
    };

    return {
        exportAsCsv: exportAsCsv
    };
}