import {dateOrNull, DropDownOption, LocalUnitEventModel, stringOrNull, UlmEventModalProps} from "../types";
import {UnitEventType} from "../../../../../../__generated__/generated_types";
import {DateTime} from "luxon";
import {LeaseTermCustomToken, LocalUnitEventModelChangeKeys} from "../structs";
import {
    setDaysVacant, setDefaultLeaseTermForNewEvents,
    setEndDateByLeaseTerm,
    setEndDateByRenovationDays, setEndDateDefault,
    setLeaseTerm,
    setMaxLeaseTerm,
    setMaxVacantDaysAndMaxStartDate,
    setRenovationDuration,
    setStartDate
} from "./fields-validations";
import {
    ensureDateInUtc,
    getPrecisedFloatFromString,
    getPrecisedStringFromString,
    UndefinedSafeStringify
} from "../../../../../../utils/helper-funcs";

import styles from "../../UnitEventModal.module.scss";

/*
This function produces the list of all possible lease term options to show in the dropdown on add/edit modal popup
These correspond to number of months and if the duration of lease does not correspond to exact number of months
It is the custom value in the dropdown which should appear selected
 */
const getAllLeaseTerms = () => {
    const _allLeaseTerms: Record<string, DropDownOption> = {};
    _allLeaseTerms["1"] = {
        value: "1",
        label: "1 month"
    };
    for (let i = 2; i <= 24; i++) {
        _allLeaseTerms[`${i.toString()}`] = {
            value: `${i.toString()}`,
            label: i.toString() + " months"
        };
    }
    _allLeaseTerms[`${LeaseTermCustomToken}`] = {
        value: `${LeaseTermCustomToken}`,
        label: LeaseTermCustomToken
    };
    return _allLeaseTerms;
};


export function getEventTypeTagClassName(eventType:UnitEventType):string {
    if(eventType === UnitEventType.TermLease) {
        return styles.termLeaseTag;
    } else if(eventType === UnitEventType.Renewal) {
        return styles.renewalTag;
    } else if(eventType === UnitEventType.MonthToMonth) {
        return styles.mtmTag;
    } else if(eventType === UnitEventType.ShortTermRental) {
        return styles.strTag;
    } else if(eventType === UnitEventType.Renovation) {
        return styles.renovationTag;
    } else {
        return "";
    }
}

export function getEventTypeThemeClassName(eventType:UnitEventType):string {
    if(eventType === UnitEventType.TermLease) {
        return styles.termLeaseTheme;
    } else if(eventType === UnitEventType.Renewal) {
        return styles.renewalTheme;
    } else if(eventType === UnitEventType.MonthToMonth) {
        return styles.mtmTheme;
    } else if(eventType === UnitEventType.ShortTermRental) {
        return styles.strTheme;
    } else if(eventType === UnitEventType.Renovation) {
        return styles.renovationTheme;
    } else {
        return "";
    }
}

export function initLocalState(props:UlmEventModalProps):LocalUnitEventModel {
    /*
        Initializing the different local values with defaults like Null
        And then later setting it up with proper contextualized value based on
        the current event type, user clicked in an empty space or on an existing event
         */
    let localPrevEndDate:dateOrNull = null;
    let localCurrEventTenantName:stringOrNull = null;
    let localCurrType:UnitEventType = UnitEventType.TermLease;
    let localCurrEventId:stringOrNull = null;

    let localCurrEventStartingCalculatedInPlaceRent = null;
    let localCurrEventEndingCalculatedInPlaceRent = null;
    let localCurrEventStartingMarketRent = null;
    let localCurrEventEndingMarketRent = null;
    let localCurrEventStartingLossToLease = null;
    let localCurrEventEndingLossToLease = null;

    let localCurrStartDate:dateOrNull = null;
    let localCurrEndDate:dateOrNull = null;
    let localNextStartDate:dateOrNull = null;

    let localCurrRentIncreaseDecreasePercentage:stringOrNull = null;
    let localCurrEffectiveAverageDailyRate:stringOrNull = null;
    let localCurrMtmRent:stringOrNull = null;
    let localCurrRenovationCost:stringOrNull = null;
    let localCurrRenovationPremium:stringOrNull = null;

    let localCurrConcessionMonths:stringOrNull = "0";

    if (props.prevEvent && props.prevEvent.eventEnd) {
        const utcPrevEndDate = ensureDateInUtc(props.prevEvent.eventEnd);
        if (utcPrevEndDate) {
            localPrevEndDate = DateTime.fromISO(
                utcPrevEndDate.toISOString(), {setZone: true});
        }
    }
    if('currentEvent' in props) {
        // Case of an existing event update
        localCurrEventId = props.currentEvent.id;
        localCurrEventTenantName = props.currentEvent.tenantName ?? null;
        if(props.currentEvent.eventStart) {
            const utcCurrStartDate = ensureDateInUtc(props.currentEvent.eventStart);
            if (utcCurrStartDate) {
                localCurrStartDate = DateTime.fromISO(
                    utcCurrStartDate.toISOString(), {setZone: true});
            }
        }
        if(props.currentEvent.eventEnd) {
            const utcCurrEndDate = ensureDateInUtc(props.currentEvent.eventEnd);
            if (utcCurrEndDate) {
                localCurrEndDate = DateTime.fromISO(
                    utcCurrEndDate.toISOString(), {setZone: true});
            }
        }
        localCurrType = props.currentEvent.eventType;

        localCurrEventStartingCalculatedInPlaceRent = getPrecisedFloatFromString(
            props.currentEvent.startingCalculatedInPlaceRent);
        localCurrEventEndingCalculatedInPlaceRent = getPrecisedFloatFromString(
            props.currentEvent.endingCalculatedInPlaceRent);
        localCurrEventStartingMarketRent = getPrecisedFloatFromString(
            props.currentEvent.startingMarketRent);
        localCurrEventEndingMarketRent = getPrecisedFloatFromString(
            props.currentEvent.endingMarketRent);
        localCurrEventStartingLossToLease = getPrecisedFloatFromString(
            props.currentEvent.startingLossToLease, 1);
        localCurrEventEndingLossToLease = getPrecisedFloatFromString(
            props.currentEvent.endingLossToLease, 1);

        localCurrConcessionMonths = props.currentEvent.concessionMonths ?
            props.currentEvent.concessionMonths.toString() : "0";

        localCurrRentIncreaseDecreasePercentage = getPrecisedStringFromString(
            props.currentEvent.renewalAdjustmentPercentage);

        localCurrEffectiveAverageDailyRate = getPrecisedStringFromString(
            props.currentEvent.shortTermEffectiveAvgDailyRent);

        localCurrMtmRent = getPrecisedStringFromString(
            props.currentEvent.inPlaceRent);

        localCurrRenovationCost = getPrecisedStringFromString(
            props.currentEvent.renovationCost);

        localCurrRenovationPremium = getPrecisedStringFromString(
            props.currentEvent.renovationPremium);

    } else if('clickedDate' in props) {
        // Case of adding a new event
        const utcDate = ensureDateInUtc(props.clickedDate);
        if(utcDate) {
            localCurrStartDate = DateTime.fromISO(utcDate.toISOString(), {setZone: true});
        } else {
            console.warn(`Error in converting the clickedDate prop to Date Object`);
        }
    }

    if (props.nextEvent && props.nextEvent.eventStart) {
        const utcNextStartDate = ensureDateInUtc(props.nextEvent.eventStart);
        if (utcNextStartDate) {
            localNextStartDate = DateTime.fromISO(
                utcNextStartDate.toISOString(), {setZone: true});
        }
    }

    /*
    The local model which drives the UI state of the modal pop up
    It not only contain the values with respect to the UI fields
    But also have the respective fields validation states like their max/min possible values
    And if these values as entered/intended by the user are correct
     It these values are invalid then there would be non-null error messages for the respective fields
     */
    const localUnitEventModel:LocalUnitEventModel = {
        prevEndDate: localPrevEndDate,
        daysVacant: null,
        daysVacantErrMsg: null,
        maxDaysVacant: null,
        currEventTenantName: localCurrEventTenantName,
        currEventType: localCurrType,
        currEventId: localCurrEventId,
        currEventStartingCalculatedInPlaceRent: localCurrEventStartingCalculatedInPlaceRent,
        currEventEndingCalculatedInPlaceRent: localCurrEventEndingCalculatedInPlaceRent,
        currEventStartingMarketRent: localCurrEventStartingMarketRent,
        currEventEndingMarketRent: localCurrEventEndingMarketRent,
        currEventStartingLossToLease: localCurrEventStartingLossToLease,
        currEventEndingLossToLease: localCurrEventEndingLossToLease,
        currStartDate: localCurrStartDate,
        currStartDateErrMsg: null,
        currMaxStartDate: null,
        currEndDate: localCurrEndDate,
        currEndDateErrMsg: null,
        nextStartDate: localNextStartDate,
        currLeaseTerm: null,
        currLeaseTermErrMsg: null,
        currAllLeaseTermDdOptions: getAllLeaseTerms(),
        currMaxLeaseTerm: null,
        currRenovationDuration: null,
        currRenovationDurationErrMsg: null,
        currLeaseConcession: localCurrConcessionMonths,
        currRentIncreaseDecreasePercentage: localCurrRentIncreaseDecreasePercentage,
        currRentIncreaseDecreasePercentageErrMsg: null,
        currEffectiveAverageDailyRate: localCurrEffectiveAverageDailyRate,
        currMtmRent: localCurrMtmRent,
        currRenovationPremium: localCurrRenovationPremium,
        currRenovationPremiumErrMsg: null,
        currRenovationCost: localCurrRenovationCost,
        currRenovationCostErrMsg: null
    };

    /*
    Calculating some non api related fields driven from the api served fields
     */
    setDaysVacant(localUnitEventModel);
    setLeaseTerm(localUnitEventModel);
    setRenovationDuration(localUnitEventModel);
    setMaxVacantDaysAndMaxStartDate(localUnitEventModel);
    setMaxLeaseTerm(localUnitEventModel);
    setDefaultLeaseTermForNewEvents(localUnitEventModel);

    return localUnitEventModel;
}

export function getNextLocalStateFromPrevLocalState(props:UlmEventModalProps,
                                                    prevState: LocalUnitEventModel | undefined,
                                                    key: LocalUnitEventModelChangeKeys,
                                                    value: any):LocalUnitEventModel {
    const nextState: LocalUnitEventModel = {
        ...prevState
    } as LocalUnitEventModel;
    switch (key) {
        case LocalUnitEventModelChangeKeys.eventId:
            nextState.currEventId = value;
            break;
        case LocalUnitEventModelChangeKeys.eventType:
            nextState.currEventType = value;
            /*
            Changing the event type should probably/ideally refresh the values. So that user can re-enter the new respective fields with respect to the new event type.
                It resets the followings fields only:
                    tenant name
                    starting and ending market rent
                    starting and ending in place rent
                    starting and ending loss to lease ratio
                    mtm rent (in place rent)

                Few considerations:
                    -   Some values like Renovation Premium, Renovation Cost, etc are not reset currently.
                        This is done just in case user comes back to the same event type again by changing the event type - he should see the same values as were received from the api or may be entered by him.
                        Example:
                            Changing from renovation to lease and then coming back to renovation again from lease type.
                            It seems a good experience for the user to see the same renovation cost and premium values as previous. (Which either the UI had received from the API initially or may be user had entered when he was first time sitting on the renovation type.)

                    -   There could be possible cases where we might want to restore/set some sensible defaults after the fields are reset, based on the new event type.
                        Case-1: Changing from lease to renewal we
                            Restore the tenant name from the previous event, if any
                            Set the start date as the next date after the previously ended event, if any

            Case-2: We always set the starting date as the clicked-date on the main chart for the new event type except it is not renewal where it is set to the next date after the previous event ended.
            ref: https://vizibly.atlassian.net/browse/APP-652
            */

            /*
            Resetting some values
             */
            // at least start date should not be reset, user might want to keep it
            nextState.currEventTenantName = null;
            nextState.currEndDate = null;
            nextState.currLeaseTerm = null;
            nextState.currRenovationDuration = null;
            nextState.currEventStartingMarketRent = null;
            nextState.currEventEndingMarketRent = null;
            nextState.currEventStartingCalculatedInPlaceRent = null;
            nextState.currEventEndingCalculatedInPlaceRent = null;
            nextState.currEventStartingLossToLease = null;
            nextState.currEventEndingLossToLease = null;
            nextState.currMtmRent = null;

            /*
            If the tenant is renewing the lease then
                - the tenant name should be restored from the previous event if any
                - the in place rent should be reset to null,
                    as the percentage increase/decrease will derive the inplacerent val in calculation
             */
            if (nextState.currEventType === UnitEventType.Renewal) {
                if (props.prevEvent && props.prevEvent.tenantName) {
                    nextState.currEventTenantName = props.prevEvent.tenantName;
                }
            }

            /*
            If the tenant is renewing the lease then the default days vacant would be 0
            Unless the user updates in the UI to set up a new start date or days vacant
             */
            if (nextState.currEventType === UnitEventType.Renewal &&
                nextState.prevEndDate) {
                nextState.daysVacant = 0;
                setStartDate(nextState);
                setLeaseTerm(nextState);
                setMaxLeaseTerm(nextState);
                setRenovationDuration(nextState);
            } else if('clickedDate' in props) {
                const utcDate = ensureDateInUtc(props.clickedDate);
                if(utcDate) {
                    nextState.currStartDate = DateTime.fromISO(
                        utcDate.toISOString(), {setZone: true});
                    setDaysVacant(nextState);
                    setLeaseTerm(nextState);
                    setMaxLeaseTerm(nextState);
                    setRenovationDuration(nextState);
                }
            }

            setDefaultLeaseTermForNewEvents(nextState);

            break;
        case LocalUnitEventModelChangeKeys.daysVacant:
            nextState.daysVacant = value ? parseInt(value, 10) : null;
            setStartDate(nextState);
            setLeaseTerm(nextState);
            setMaxLeaseTerm(nextState);
            setRenovationDuration(nextState);
            break;
        case LocalUnitEventModelChangeKeys.startDate:
            nextState.currStartDate = value;
            setDaysVacant(nextState);
            setLeaseTerm(nextState);
            setMaxLeaseTerm(nextState);
            setRenovationDuration(nextState);
            break;
        case LocalUnitEventModelChangeKeys.leaseTerm:
            nextState.currLeaseTerm = value;
            setEndDateByLeaseTerm(nextState);
            break;
        case LocalUnitEventModelChangeKeys.renovationDuration:
            nextState.currRenovationDuration = value ? parseInt(value, 10) : null;
            setEndDateByRenovationDays(nextState);
            break;
        case LocalUnitEventModelChangeKeys.endDate:
            nextState.currEndDate = value;
            /*
            Calculating lease term, max vacant-days/start-date/lease-term
            Which are dependent on the current event's end date
             */
            setLeaseTerm(nextState);
            setMaxVacantDaysAndMaxStartDate(nextState);
            setMaxLeaseTerm(nextState);
            setRenovationDuration(nextState);
            break;
        case LocalUnitEventModelChangeKeys.endDateClicked:
            setEndDateDefault(nextState);
            setLeaseTerm(nextState);
            setMaxVacantDaysAndMaxStartDate(nextState);
            setMaxLeaseTerm(nextState);
            setRenovationDuration(nextState);
            break;
        case LocalUnitEventModelChangeKeys.leaseConcession:
            nextState.currLeaseConcession = value ? value : null;
            break;
        case LocalUnitEventModelChangeKeys.rentIncreaseDecreasePercentage:
            nextState.currRentIncreaseDecreasePercentage = value ? value : null;
            break;
        case LocalUnitEventModelChangeKeys.effectiveAverageDailyRate:
            nextState.currEffectiveAverageDailyRate = value ? value : null;
            break;
        case LocalUnitEventModelChangeKeys.mtmRent:
            nextState.currMtmRent = value ? value : null;
            break;
        case LocalUnitEventModelChangeKeys.renovationPremium:
            nextState.currRenovationPremium = value ? value : null;
            break;
        case LocalUnitEventModelChangeKeys.renovationCost:
            nextState.currRenovationCost = value ? value : null;
            break;
        default:
            console.warn(`Unhandled key: ${key}, value: ${UndefinedSafeStringify(value)}`);
    }
    return nextState;
}
