import {useHistory} from 'react-router-dom';
import * as H from 'history';

/**
 * A utility function built around the useHistory of react-router-dom
 * Returns two helper functions to not type everytime history.push
 * and history.goBack()
 *
 * @return {Object} history object, goto function and goback function.
 */
export function useRoutingUtils():{
  history: H.History<H.LocationState>,
  goto: (_:string) => () => void,
  goback: () => void,
} {
  const history = useHistory();
  const goto = (path: string) => {
    return () => history.push(path);
  };
  const goback = () => history.goBack();

  return {history, goto, goback};
}
