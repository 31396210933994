import React from 'react';
import "./Line.scss";

interface ComponentHeaderProps {
    name: string,
}

export const ComponentHeader: React.VFC<ComponentHeaderProps> = (props) => {
    return (
        <td className="component-header-title">
            {props.name}
        </td>
    );
};