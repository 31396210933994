import React, {ReactElement} from "react";
import styles from "../AnalystPayrollDrawer.module.scss";
import {Field, Input, Label} from "@zendeskgarden/react-forms";
import {Col, Row} from "@zendeskgarden/react-grid";
import {
    All30Dates, All31Dates,
    AllFebDates,
    AllMonths,
    AllPayrollBonusFrequencyAndType,
    LocalPayrollEmployeeBonusModel,
    LocalPayrollEmployeeModelKeys,
} from "../helpers/types";
import {CustomZDDropdown} from "../../../atoms/custom-zd-dropdown/CustomZDDropdown";
import {PayrollBonusFrequency, PayrollBonusType} from "../../../__generated__/generated_types";
import {DateFormatter} from "../helpers/utils";
import {Datepicker} from "@zendeskgarden/react-datepickers";
import {Button} from "@zendeskgarden/react-buttons";
import {ensureDateInLocale, ensureDateInUtc} from "../date-helpers";

interface BonusEntryProps {
    index: number,
    bonus: LocalPayrollEmployeeBonusModel,
    allowRemove: boolean,
    updateFormState: (key: LocalPayrollEmployeeModelKeys, value: any) => void
}

export default function BonusEntry({index, bonus, allowRemove, updateFormState}: BonusEntryProps): ReactElement {
    let allDates = null;
    if (["1", "01"].indexOf(bonus.bonusMonth.toString()) !== -1) {
        allDates = AllFebDates;
    } else if(["3", "03", "5", "05", "8", "08", "10"].indexOf(bonus.bonusMonth.toString()) !== -1) {
        allDates = All30Dates;
    } else {
        allDates = All31Dates;
    }

    return (
        <div style={{display: "block"}}>
            <Row justifyContent="between">
                <Col>
                    <Label className={styles.earningsSubHeaders}>Bonus {index + 1}</Label>
                </Col>
                {
                    allowRemove && <Button isBasic isDanger onClick={() => {
                        updateFormState(LocalPayrollEmployeeModelKeys.removeBonus, index);
                    }}>Remove</Button>
                }
            </Row>
            <Row justifyContent="between">
                <Col size={12}>
                    <Field className={styles.field}>
                        <Label className={styles.label}>Type</Label>
                        <CustomZDDropdown
                            widthSpec={"100%"}
                            applySelectedItems={(items) => {
                                updateFormState(LocalPayrollEmployeeModelKeys.bonusFrequencyAndType, Array.isArray(items) ? items[0] : items);
                            }}
                            initialSelectedItems={(bonus.frequency && bonus.bonusType) ? [JSON.stringify([bonus.frequency, bonus.bonusType])] : [""]}
                            openDropdownPlaceholder="Select Bonus Type"
                            closedDropdownPlaceholder=""
                            options={AllPayrollBonusFrequencyAndType.map(opt => ({
                                value: opt.value,
                                label: opt.label
                            }))}
                            isError={false}
                            onBlur={() => {
                                return undefined;
                            }}
                            noSearchIcon
                        />
                    </Field>
                </Col>
            </Row>
            {
                bonus.frequency === PayrollBonusFrequency.Annually ?
                    <>
                        <Row justifyContent="between">
                            <Col>
                                <Field className={styles.field}>
                                    <Label className={styles.label}>
                                        {
                                            bonus.bonusType === PayrollBonusType.FixedAmount ?
                                                <span>Bonus Amount</span> : <span>Bonus Percentage</span>
                                        }
                                    </Label>
                                    <Input
                                        placeholder={bonus.bonusType === PayrollBonusType.FixedAmount ? "$" : "%"}
                                        value={bonus.amount}
                                        type={"number"}
                                        onChange={(evt) => {
                                            updateFormState(
                                                LocalPayrollEmployeeModelKeys.bonusAmount,
                                                evt.target.value);
                                        }}/>
                                </Field>
                            </Col>
                            <Col>
                                <Field className={styles.field}>
                                    <Label className={styles.label}>
                                        <span>Bonus Month</span>
                                    </Label>
                                    <div style={{marginTop: "8px"}}>
                                        <CustomZDDropdown
                                            widthSpec={"100%"}
                                            applySelectedItems={(items) => {
                                                updateFormState(LocalPayrollEmployeeModelKeys.bonusMonth, Array.isArray(items) ? items[0] : items);
                                            }}
                                            initialSelectedItems={bonus.bonusMonth ? [bonus.bonusMonth.toString()] : [""]}
                                            openDropdownPlaceholder="Select Bonus Month"
                                            closedDropdownPlaceholder=""
                                            options={AllMonths.map(opt => ({
                                                value: opt.value,
                                                label: opt.label
                                            }))}
                                            isError={false}
                                            onBlur={() => {
                                                return undefined;
                                            }}
                                            noSearchIcon
                                        />
                                    </div>
                                </Field>
                            </Col>
                            <Col>
                                <Field className={styles.field}>
                                    <Label className={styles.label}>
                                        <span>Bonus Day</span>
                                    </Label>
                                    <div style={{marginTop: "8px"}}>
                                        <CustomZDDropdown
                                            widthSpec={"100%"}
                                            applySelectedItems={(items) => {
                                                updateFormState(LocalPayrollEmployeeModelKeys.bonusDate, Array.isArray(items) ? items[0] : items);
                                            }}
                                            initialSelectedItems={bonus.bonusDay ? [bonus.bonusDay.toString()] : [""]}
                                            openDropdownPlaceholder="Select Bonus Day"
                                            closedDropdownPlaceholder=""
                                            options={allDates.map(opt => ({
                                                value: opt.value,
                                                label: opt.label
                                            }))}
                                            isError={false}
                                            onBlur={() => {
                                                return undefined;
                                            }}
                                            noSearchIcon
                                        />
                                    </div>
                                </Field>
                            </Col>
                        </Row>
                    </> :
                    <>
                        <Row justifyContent="between">
                            <Col>
                                <Field className={styles.field}>
                                    <Label className={styles.label}>
                                        {
                                            bonus.bonusType === PayrollBonusType.FixedAmount ?
                                                <span>Bonus Amount</span> : <span>Bonus Percentage</span>
                                        }
                                    </Label>
                                    <Input
                                        placeholder={bonus.bonusType === PayrollBonusType.FixedAmount ? "$" : "%"}
                                        value={bonus.amount}
                                        type={"number"}
                                        onChange={(evt) => {
                                            updateFormState(
                                                LocalPayrollEmployeeModelKeys.bonusAmount,
                                                evt.target.value);
                                        }}/>
                                </Field>
                            </Col>
                            <Col>
                                <Field className={styles.field}>
                                    <Label className={styles.label}>
                                        <span>Bonus Date</span>
                                    </Label>
                                    <Datepicker
                                        value={bonus.onetimeBonusDate ? ensureDateInLocale(bonus.onetimeBonusDate) : undefined}
                                        onChange={(selectedStartDate) => {
                                            updateFormState(LocalPayrollEmployeeModelKeys.onetimeBonusDate, ensureDateInUtc(selectedStartDate)?.toISOString());
                                        }}
                                        formatDate={date => {
                                            return date ? DateFormatter.format(date) : "";
                                        }}
                                    >
                                        <Input/>
                                    </Datepicker>
                                </Field>
                            </Col>
                        </Row>
                    </>
            }
        </div>
    );
}
