import { nanSafeParseFloat } from '../../../../../../../../../utils/excel';
import { AssumptionRowModel } from '../models/AssumptionRowModel';
import {
    SourceAccountModel,
    GetMvrBudgetAssumptionsForPropertyAndAccountQuery
} from '../../../../../../../../../__generated__/generated_types';
import { AssumptionRowValueType } from '../enums/AssumptionRowValueTypeEnum';
import { buildLookbackPeriodSubLabel } from './buildLookbackPeriodSubLabel';
import { Maybe } from 'graphql/jsutils/Maybe';
import { ModelingMethodType } from '../enums/ModelingMethodTypeEnum';

/**
 * Maps SourceAccountModel to
 * AssumptionRowModel(
 *      label = <built based on SourceAccountModel.glName>,
 *      subLabel = <built based on SourceAccountModel.lookbackPeriod>
 *      value = <calculated out of sourceAccount.assumptionsAndValues[monthIndex]>
 *      children = [
 *          AssumptionRowModel(label = "%", value = sourceAccount.assumptionsAndValues[monthIndex].percentage),
 *          AssumptionRowModel(label = SourceAccountModel.glName, value = sourceAccount.assumptionsAndValues[monthIndex].accountValue),
 *      ]
 * )
 *
 * NOTE: all undefined/null values are defaulted to 0
 *
 * @param sourceAccount SourceAccountModel
 * @param monthIndex 0-11
 * @returns AssumptionRowModel
 */
function mapAccPercentageSourceAccount(sourceAccount: SourceAccountModel, monthIndex: number): AssumptionRowModel {
    const assumption = sourceAccount.assumptionsAndValues[monthIndex];

    let percentage = 0;
    let accountValue = 0;
    let driverValue = 0;
    let lookbackPeriod = 0;

    if (assumption) {
        percentage = nanSafeParseFloat(`${assumption.percentage}`);
        accountValue = nanSafeParseFloat(`${assumption.accountValue}`);
        driverValue = percentage / 100 * accountValue;
        lookbackPeriod = nanSafeParseFloat(`${assumption.lookbackPeriod}`);
    }

    const accountRow: AssumptionRowModel = {
        id: sourceAccount.glNumber ?? undefined,
        name: sourceAccount.glName ?? undefined,
        label: `% of ${sourceAccount.glName}`,
        subLabel: buildLookbackPeriodSubLabel(lookbackPeriod),
        value: driverValue,
        valueType: AssumptionRowValueType.DOLLAR,
        modelingType: ModelingMethodType.PCT_OF_ACCOUNT,
        children: [
            {
                label: "%",
                subLabel: undefined,
                value: percentage,
                valueType: AssumptionRowValueType.PERCENTAGE,
                children: []
            },
            {
                label: sourceAccount.glName,
                subLabel: undefined,
                value: accountValue,
                valueType: AssumptionRowValueType.DOLLAR,
                children: []
            }
        ]
    };

    return accountRow;
}

type AccountPercentageModel = GetMvrBudgetAssumptionsForPropertyAndAccountQuery["singlePropertyAccount"]["accountPercentageDriver"];

export function mapAccPercentageAssumptions(
    accPercentageData: Maybe<AccountPercentageModel | undefined>,
    monthIndex: number
): AssumptionRowModel[] {
    const resultRows = [] as AssumptionRowModel[];

    if (accPercentageData && accPercentageData != null) {
        for(const sourceAccount of accPercentageData.sourceAccounts) {
            const sourceAccountMapped = mapAccPercentageSourceAccount(sourceAccount, monthIndex);
            resultRows.push(sourceAccountMapped);
        }
    }


    return resultRows;
}
