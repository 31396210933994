import React, {CSSProperties, ReactElement, useEffect, useState} from 'react';
import {Body, Cell, Head, HeaderCell, HeaderRow, Row, SortableCell, Table} from '@zendeskgarden/react-tables';
import {Anchor, Button} from '@zendeskgarden/react-buttons';
import {Textarea} from '@zendeskgarden/react-forms';
import {ReactComponent as NotesStroke} from '@zendeskgarden/svg-icons/src/16/notes-stroke.svg';
import {
    AccountsTableViewModel,
    EntityType,
    useUpsertNoteMutation,
    VersionType
} from "../../../__generated__/generated_types";

import styles from "./AccountsTableView.module.scss";
import {
    StarredAccountForAccountsTableView
} from "../../../components/starred-account/StarredAccountForAccoutsTableView";
import {calcVarianceNullSafe, varianceAmount, varianceValToString} from "../../../utils/math";
import {VarianceType} from "../../../VarianceType";

import AccountTableViewShimmer from "../../../assets/images/shimmers/account-table-view.svg";
import {useConfig} from "../../../hooks/useConfig";

export interface IRowData {
    accountId: string
    glNumber: string
    glName: string
    isIncome: boolean
    reforecastVersionId: string
    budgetVersionId: string
    isStarred: boolean
    reforecastValue: number
    budgetValue: number
    varPercentage: number
    varAmount: number
    reforecastNotes: string | null
    budgetNotes: string | null
    varPercentageClassName: string
}

type Direction = 'asc' | 'desc' | undefined;

type SortableFields = keyof Pick<IRowData, "glNumber" | "glName" | "varPercentage" | "varAmount">;

const sortData = (tableData: IRowData[], fieldName: SortableFields | undefined, sortDirection: Direction) => {
    if (!fieldName || !sortDirection) {
        return tableData;
    }
    return tableData.sort((a, b) => {
        const aValue = a[fieldName];
        const bValue = b[fieldName];

        // Keep NaNs always at the end in asc/desc sorting else the api returned order is good
        if (typeof aValue === "number" && isNaN(aValue)) {
            return 1;
        }
        if (typeof bValue === "number" && isNaN(bValue)) {
            return -1;
        }

        if (aValue > bValue) {
            return sortDirection === 'asc' ? 1 : -1;
        } else if (aValue < bValue) {
            return sortDirection === 'asc' ? -1 : 1;
        }
        return 0;
    });
};

interface AccountsTableViewProps {
    headerText: string;
    propertyId: string | undefined;
    reforecastYear: number;
    tableData: AccountsTableViewModel[] | undefined;
    reforecastYearShortForm: string;
    budgetYearShortForm: string;
    onStarringUpdate?: () => void;
    onNoteUpdate?: () => void;
    style?: CSSProperties;
}

export const AccountsTableView: React.VFC<AccountsTableViewProps> =
    ({
         headerText,
         propertyId,
         reforecastYear,
         tableData,
         reforecastYearShortForm,
         budgetYearShortForm,
         onStarringUpdate,
         onNoteUpdate,
         style
     }) => {
        const [data, setData] = useState<IRowData[] | undefined>(undefined);
        const [sortByField, setSortByField] = useState<(SortableFields) | undefined>(undefined);
        const [sortDirection, setSortDirection] = useState<Direction>();
        const [isNoteFocussedByAccountIdAndVersionIdRecord, setIsNoteFocussedByAccountIdAndVersionIdRecord] = useState<Record<string, boolean>>({});
        const [noteCurrentValueByAccountIdAndVersionIdRecord, setNoteCurrentValueByAccountIdAndVersionIdRecord] = useState<Record<string, (string|null)>>({});

        const config = useConfig();

        useEffect(
            () => {
                if (!tableData) {
                    return;
                }
                if (tableData.length > 0) {
                    const newRows: IRowData[] = [];
                    const newNoteCurrentValueByAccountIdAndVersionIdRecord:Record<string, (string|null)> = {};
                    for (const sad of tableData) {
                        const currentRowPartialData: Omit<IRowData, "varAmount" | "varPercentage" | "varPercentageClassName" | "isIncome"> = {
                            accountId: sad.accountId,
                            glNumber: sad.accountGlNumber,
                            glName: sad.accountGlName,
                            reforecastVersionId: sad.reforecastVersionId,
                            budgetVersionId: sad.budgetVersionId,
                            isStarred: sad.isBudgetVersionStarred,
                            reforecastValue: parseFloat(sad.reforecastValue ? sad.reforecastValue.toString() : ""),
                            budgetValue: parseFloat(sad.budgetValue ? sad.budgetValue.toString() : ""),
                            reforecastNotes: sad.reforecastVersionNote ?? null,
                            budgetNotes: sad.budgetVersionNote ?? null
                        };

                        let currentVarAmount = 0;
                        let currentVarPercentage = 0;
                        if (currentRowPartialData) {
                            currentVarAmount = varianceAmount(
                                sad.budgetValue ? currentRowPartialData.budgetValue : null,
                                sad.reforecastValue ? currentRowPartialData.reforecastValue : null) ?? NaN;
                            currentVarPercentage = calcVarianceNullSafe(
                                sad.reforecastValue ? currentRowPartialData.reforecastValue : 0,
                                sad.budgetValue ? currentRowPartialData.budgetValue : 0) ?? NaN;
                        }

                        const parentCategories = config.chartOfAccountsConfig.getParentCategories(sad.parentAccountId ?? "");
                        const currentIsIncome = parentCategories.some(agg => agg.budgetComponentType === "INCOME");

                        if (!currentIsIncome && currentVarAmount !== 0) {
                            currentVarAmount = currentVarAmount * -1;
                        }
                        let currentVarPercentageClassName = styles.green;
                        if ((currentIsIncome && currentVarPercentage < 0) || (!currentIsIncome && currentVarPercentage > 0)) {
                            currentVarPercentageClassName = styles.red;
                        }
                        const currentRowData: IRowData = {
                            ...currentRowPartialData,
                            varAmount: currentVarAmount,
                            varPercentage: currentVarPercentage,
                            isIncome: currentIsIncome,
                            varPercentageClassName: currentVarPercentageClassName
                        };
                        newRows.push(currentRowData);
                        newNoteCurrentValueByAccountIdAndVersionIdRecord[`${sad.accountId}#${sad.reforecastVersionId}`] = sad.reforecastVersionNote ?? null;
                        newNoteCurrentValueByAccountIdAndVersionIdRecord[`${sad.accountId}#${sad.budgetVersionId}`] = sad.budgetVersionNote ?? null;
                    }
                    setData(newRows);
                    setNoteCurrentValueByAccountIdAndVersionIdRecord(newNoteCurrentValueByAccountIdAndVersionIdRecord);
                } else {
                    setData([]);
                    setNoteCurrentValueByAccountIdAndVersionIdRecord({});
                }
            }, [tableData]
        );

        const sortClickHandler = (fieldName: SortableFields) => {
            if (sortByField === fieldName) {
                if (sortDirection === "asc") {
                    setSortDirection("desc");
                } else if (sortDirection === "desc") {
                    setSortDirection(undefined);
                } else {
                    setSortDirection("asc");
                }
            } else {
                setSortByField(fieldName);
                setSortDirection("asc");
            }
        };

        const getInlineEditableNote = (accountId: string, versionId: string, year: number, versionType: (VersionType.Reforecast | VersionType.Budget), noteText: string | null): ReactElement => {

            // The cell has never been in focussed or updated text.
            // And if it was originally null as per the api response then
            // show add note button
            // and on click reset it with blank value by discarding the button and it's text
            if (!(`${accountId}#${versionId}` in isNoteFocussedByAccountIdAndVersionIdRecord) &&
                noteText === null) {
                return (
                    <div className={styles.inlineNoteContainer}>
                        <Button isPrimary
                                size={"small"}
                                className={styles.inlineNoteAddNoteButton}
                                onClick={() => {
                                    setIsNoteFocussedByAccountIdAndVersionIdRecord((prevState) => {
                                        return {
                                            ...prevState,
                                            [`${accountId}#${versionId}`]: true
                                        };
                                    });
                                }}
                        >
                            <Button.StartIcon>
                                <NotesStroke/>
                            </Button.StartIcon>
                            Add Note
                        </Button>
                    </div>
                );
            }

            return <div className={styles.inlineNoteContainer}>
                <Textarea
                    isBare={!isNoteFocussedByAccountIdAndVersionIdRecord[`${accountId}#${versionId}`]}
                    minRows={2}
                    maxRows={isNoteFocussedByAccountIdAndVersionIdRecord[`${accountId}#${versionId}`] ? 5 : 2}
                    className={isNoteFocussedByAccountIdAndVersionIdRecord[`${accountId}#${versionId}`] ?
                        styles.inlineNoteEditMode : styles.inlineNoteViewMode
                    }
                    value={noteCurrentValueByAccountIdAndVersionIdRecord[`${accountId}#${versionId}`] ?? noteText ?? ""}
                    onChange={(evt) => {
                        setNoteCurrentValueByAccountIdAndVersionIdRecord((prevState) => {
                            return {
                                ...prevState,
                                [`${accountId}#${versionId}`]: evt.target.value
                            };
                        });
                    }}
                    onFocus={() => {
                        setIsNoteFocussedByAccountIdAndVersionIdRecord((prevState) => {
                            return {
                                ...prevState,
                                [`${accountId}#${versionId}`]: true
                            };
                        });
                    }}
                    onBlur={(event) => {
                        setIsNoteFocussedByAccountIdAndVersionIdRecord((prevState) => {
                            return {
                                ...prevState,
                                [`${accountId}#${versionId}`]: false
                            };
                        });
                        let noteText = event.currentTarget.value;
                        noteText = noteText.trim();
                        saveNote(noteText, accountId, year, versionType);
                    }}
                />
            </div>;
        };

        const [upsertNote] = useUpsertNoteMutation();

        const saveNote = async (text: string | null, accountId: string, year: number, versionType: (VersionType.Reforecast | VersionType.Budget)) => {
            if (propertyId && text) {
                await upsertNote({
                    variables: {
                        text: text,
                        entityId: accountId,
                        entityType: EntityType.Account,
                        versionType: versionType,
                        propertyId: propertyId,
                        // This will be plan id eventually.
                        year: year
                    },
                });
                if (typeof onNoteUpdate === "function") {
                    onNoteUpdate();
                }
            }
        };

        if (data === undefined || !propertyId || propertyId === "") {
            return <div className={styles.shimmerContainer}>
                <img width={"100%"} src={AccountTableViewShimmer} alt=""/>
            </div>;
        }

        return (
            <div style={style} className={styles.tableContainer}>
                <div className={styles.caption}>
                    {headerText} ({data.length})
                </div>
                <Table>
                    <Head>
                        <HeaderRow className={styles.headerRow}>
                            <SortableCell
                                width={110}
                                onClick={() => {
                                    sortClickHandler("glNumber");
                                }}
                                sort={sortByField === "glNumber" ? sortDirection : undefined}
                            >
                                GL #
                            </SortableCell>
                            <SortableCell
                                width={220}
                                onClick={() => {
                                    sortClickHandler("glName");
                                }}
                                sort={sortByField === "glName" ? sortDirection : undefined}
                            >
                                GL Account Name
                            </SortableCell>
                            <HeaderCell width={110}>
                                {reforecastYearShortForm} Reforecast
                            </HeaderCell>
                            <HeaderCell width={110}>
                                {budgetYearShortForm} Budget
                            </HeaderCell>
                            <SortableCell
                                width={110}
                                onClick={() => {
                                    sortClickHandler("varPercentage");
                                }}
                                sort={sortByField === "varPercentage" ? sortDirection : undefined}
                            >
                                Var %
                            </SortableCell>
                            <SortableCell
                                width={110}
                                onClick={() => {
                                    sortClickHandler("varAmount");
                                }}
                                sort={sortByField === "varAmount" ? sortDirection : undefined}
                            >
                                Var $
                            </SortableCell>
                            <HeaderCell>{reforecastYearShortForm} Reforecast Notes</HeaderCell>
                            <HeaderCell>{budgetYearShortForm} Budget Notes</HeaderCell>
                        </HeaderRow>
                    </Head>
                </Table>
                <div className={styles.tableRowsContainer}>
                    <Table>
                        <Body>
                            {sortData(data.slice(), sortByField, sortDirection).map(row => (
                                <Row key={row.accountId}>
                                    <Cell
                                        width={110}
                                    >
                                        <Anchor isExternal={false}
                                                className={styles.glDetailsText}
                                                href={`/account/${row.accountId}/budget`}
                                                target="_blank">
                                            {row.glNumber}
                                        </Anchor>
                                    </Cell>
                                    <Cell
                                        width={220}
                                    >
                                        <div className={styles.glNameContainer}>
                                            <Anchor isExternal={false}
                                                    className={styles.glDetailsText}
                                                    href={`/account/${row.accountId}/budget`}
                                                    target="_blank">
                                                {row.glName}
                                            </Anchor>
                                            <StarredAccountForAccountsTableView
                                                accountId={row.accountId}
                                                reforecastVersionId={row.reforecastVersionId}
                                                budgetVersionId={row.budgetVersionId}
                                                isStarred={row.isStarred}
                                                onUpdate={() => {
                                                    if (typeof onStarringUpdate === "function") {
                                                        onStarringUpdate();
                                                    }
                                                }}
                                            />
                                        </div>
                                    </Cell>
                                    <Cell width={110} className={styles.reforecastBudgetValue}>
                                        {varianceValToString(isNaN(row.reforecastValue) ? null : row.reforecastValue, VarianceType.VARIANCE_AMOUNT, true)}
                                    </Cell>
                                    <Cell width={110} className={styles.reforecastBudgetValue}>
                                        {varianceValToString(isNaN(row.budgetValue) ? null : row.budgetValue, VarianceType.VARIANCE_AMOUNT, true)}
                                    </Cell>
                                    <Cell width={110}>
                                        <div
                                            className={`${styles.varPercentageValContainer} ${row.varPercentageClassName}`}>
                                            {
                                                isNaN(row.varPercentage)
                                                        ? '∞%'
                                                        : varianceValToString(row.varPercentage, VarianceType.VARIANCE_PERCENT, false)
                                            }
                                        </div>
                                    </Cell>
                                    <Cell width={110} className={styles.varValue}>
                                        {varianceValToString(isNaN(row.varAmount) ? null : row.varAmount, VarianceType.VARIANCE_AMOUNT, true)}
                                    </Cell>
                                    <Cell>
                                        {getInlineEditableNote(row.accountId, row.reforecastVersionId, reforecastYear, VersionType.Reforecast, row.reforecastNotes)}
                                    </Cell>
                                    <Cell>
                                        {getInlineEditableNote(row.accountId, row.budgetVersionId, reforecastYear + 1, VersionType.Budget, row.budgetNotes)}
                                    </Cell>
                                </Row>
                            ))}
                        </Body>
                    </Table>
                </div>
            </div>
        );
    };
