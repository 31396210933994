import React, { useState } from "react";

import { useHistory } from "react-router-dom";
import { BudgetingType } from "../../../BudgetingType";

import { Tab, TabList } from "@zendeskgarden/react-tabs";

import { getAbbreviatedYear } from "../../../utils/helper-funcs";

import * as workflowCSS from "../../../styles/workflows/workflowCSS.module.scss";
import { yearTabsRow } from "../../../styles/workflows/workflowCSS.module.scss";
import { ForecastLocks, GetForecastLocksQuery } from "../../../__generated__/generated_types";

import { ReactComponent as LockIcon } from '@zendeskgarden/svg-icons/src/16/lock-locked-stroke.svg';
import LockBanner from "../../../pages/workflows/account/LockBanner";
import { TLockOverrides } from "../../../authorization/AuthorizationCheckers";

interface IWorkflowYearTabs {
    year: number;
    selectedTab?: string;
    rawForecastLocks?: GetForecastLocksQuery | undefined,
    forecastLocks?: ForecastLocks | undefined,
    lockOverrides?: TLockOverrides | undefined,
}

export const WORKFLOW_YEAR_TAB_REFORECAST = 'WORKFLOW_YEAR_TAB_REFORECAST';
export const WORKFLOW_YEAR_TAB_BUDGET = 'WORKFLOW_YEAR_TAB_BUDGET';
export const WORKFLOW_YEAR_TAB_COMBINED = 'WORKFLOW_YEAR_TAB_COMBINED';

export default function WorkflowYearTabs(props: IWorkflowYearTabs): ReturnType<typeof TabList> {
    const history = useHistory();

    const performNav = (activeTab: string) => {
        history.push(activeTab);
    };

    function showLockBanner(): boolean {
        if (!props.rawForecastLocks?.forecastLocks) {
            return false;
        }

        if (props.selectedTab == WORKFLOW_YEAR_TAB_REFORECAST) {
            return props.rawForecastLocks.forecastLocks.property.reforecastLocked ?? false;
        } else if (props.selectedTab == WORKFLOW_YEAR_TAB_BUDGET) {
            return props.rawForecastLocks.forecastLocks.property.budgetLocked ?? false;
        } else {
            return false;
        }
    }

    const period = `${props.selectedTab == WORKFLOW_YEAR_TAB_REFORECAST ? props.year : (props.year + 1)} ${props.selectedTab == WORKFLOW_YEAR_TAB_REFORECAST ? "Reforecast" : "Budget"}`
    const lockBannerText = `${period} has been finalized. No edits are permitted.`;

    return (
            <div className={yearTabsRow}>
                {showLockBanner() ?
                    <LockBanner fixed text={lockBannerText} />
                    : <></>
                }
                <TabList className={workflowCSS.accountYearTabNav}>
                    <Tab item={WORKFLOW_YEAR_TAB_REFORECAST}
                         className={workflowCSS.accountYearTab}
                         onClick={() => {
                             performNav(BudgetingType.REFORECAST);
                         }}
                    >
                        { props.forecastLocks?.reforecastLocked && !props.lockOverrides?.reforecast ?
                            <LockIcon className={workflowCSS.tabLockIcon} />
                            : <></>
                        }
                        Reforecast {getAbbreviatedYear(props.year)}
                    </Tab>
                    <Tab item={WORKFLOW_YEAR_TAB_BUDGET}
                         className={workflowCSS.accountYearTab}
                         onClick={() => {
                             performNav(BudgetingType.BUDGET);
                         }}
                    >
                        { props.forecastLocks?.budgetLocked && !props.lockOverrides?.budget ?
                            <LockIcon className={workflowCSS.tabLockIcon} />
                            : <></>
                        }
                        Budget {getAbbreviatedYear(props.year + 1)}
                    </Tab>
                </TabList>
            </div>
    );
}