import React, {ReactElement, useEffect, useRef, useState} from "react";
import {IconButton} from '@zendeskgarden/react-buttons';
import {DrawerModal} from '@zendeskgarden/react-modals';
import {Field, Label, Textarea} from '@zendeskgarden/react-forms';
import {
    GetClientCommentsForAccountQuery,
    useDeleteClientCommentMutation,
    useGetClientCommentsForAccountLazyQuery,
    useSetClientCommentMutation
} from '../../../__generated__/generated_types';
import css from "../styles/commentThread.module.scss";
import cn from "classnames";
import {formatterTimeUS} from "../../../utils/formatters";
import {Chat, Send} from "@material-ui/icons";
import {useUserOrGuestContext} from "../logic/useUserOrGuestContext";
import {ReactComponent as TrashStrokeIcon} from "@zendeskgarden/svg-icons/src/12/trash-stroke.svg";
import {Icon} from "@material-ui/core";


export interface ICommentAPICallPayload {
    accountId: string;
    budgetYear: number;
    propertyId: string;
    accountDescription: string;
}

export interface ICommentThreadDrawerProps {
    data: ICommentAPICallPayload | null;
    readOnly: boolean;
    onClose: () => void;
}

function buildAuthorName(data: {authorName: string, authorLastName: string, authorEmail: string}): string {
    const {authorName, authorLastName, authorEmail} = data;
    let ret = `${authorName} ${authorLastName}`;
    if (ret.trim().length === 0) {
        ret = ret = authorEmail;
    }

    return ret;
}

export default function CommentThreadDrawer({data, readOnly, onClose}: ICommentThreadDrawerProps): ReactElement {
    const user = useUserOrGuestContext();
    const [setClientComment, { loading }] = useSetClientCommentMutation({ fetchPolicy: "no-cache" });
    const [getClientComments, { data: rawApiComments, loading: commentsLoading }] = useGetClientCommentsForAccountLazyQuery({ fetchPolicy: "no-cache" });
    const [deleteClientComment] = useDeleteClientCommentMutation({
        fetchPolicy: "no-cache"
    });
    const [newCommentText, setNewCommentText] = useState<string>("");
    const [apiComments, setApiComments] = useState<GetClientCommentsForAccountQuery | undefined>(undefined);
    const bodyRef = useRef<HTMLDivElement>(null);

    const submitComment = async () => {
        if (data != null && newCommentText.trim() != "") {
            setClientComment({
                variables: {
                    budgetYear: data.budgetYear,
                    propertyId: data.propertyId,
                    accountId: data.accountId,
                    text: newCommentText
                }
            }).then(() => {
                getClientComments({
                    variables: {
                        budgetYear: data.budgetYear,
                        propertyId: data.propertyId,
                        accountId: data.accountId,
                    }
                });
            });
            setNewCommentText("");
        }
    };

    useEffect(() => {
        if (data != null) {
            getClientComments({
                variables: {
                    budgetYear: data.budgetYear,
                    propertyId: data.propertyId,
                    accountId: data.accountId,
                }
            });
            requestAnimationFrame(() => {
                if (bodyRef.current) {
                    bodyRef.current.scrollTop = bodyRef.current.scrollHeight - bodyRef.current.clientHeight;
                }
            });
        }
    }, [data]);

    useEffect(() => {
        if (!commentsLoading && rawApiComments) {
            if (bodyRef.current) {
                bodyRef.current.scrollTop = bodyRef.current.scrollHeight - bodyRef.current.clientHeight;
            }
            setApiComments(rawApiComments);
        }
    }, [rawApiComments, commentsLoading]);

    function deleteComment(commentId: string): void {
        if(apiComments === undefined) {
            return;
        }

        deleteClientComment({
            variables: {
                clientCommentId: commentId
            }
        }).then(result => {
            if(result.data?.deleteClientComment === true) {
                const filtered = apiComments.getClientCommentsForAccount.filter(c => c.id !== commentId);
                const cloned = {
                    ...apiComments,
                    getClientCommentsForAccount: filtered
                };
                setApiComments(cloned);
            }
        });
    }

    return (
        <DrawerModal
            isOpen={data != null}
            onClose={() => {
                setNewCommentText("");
                onClose();
            }}
            className={css.commentThreadDrawer}
            backdropProps={{
                // @bowman - this crazy z-index is to have it sit above the intercom button - better way???
                style: {zIndex: 9999999999}
            }}
        >
            <DrawerModal.Header className={css.header}>
                <div className={css.title}>
                    <h5>Comments</h5>
                    <h4>{data?.accountDescription}</h4>
                </div>
            </DrawerModal.Header>

            <DrawerModal.Body className={css.body} ref={bodyRef}>
                {
                    apiComments && apiComments.getClientCommentsForAccount.length == 0 &&
                        <div className={css.noCommentsWrapper}>
                            <Chat className={css.icon} />
                            <h5>No comments yet</h5>
                        </div>
                }

                {
                    apiComments && apiComments.getClientCommentsForAccount.map((x, i) => {
                        const previousComment = apiComments.getClientCommentsForAccount[i - 1];
                        let newDate = null;

                        if (!previousComment || new Date(previousComment.createdAt).toDateString() !== new Date(x.createdAt).toDateString()) {
                            const dateOptions: Intl.DateTimeFormatOptions = {
                                weekday: "long",
                                month: "2-digit",
                                day: "2-digit",
                                year: "numeric"
                            };
                            newDate = new Date(x.createdAt).toLocaleDateString("en-US", dateOptions).toUpperCase();
                        }

                        return (
                            <React.Fragment key={x.id}>
                                { newDate &&
                                    <div className={css.dateSeparator}>{newDate}</div>
                                }
                                <div className={cn(css.comment)}>
                                    <p className={css.commentMeta}>
                                        {buildAuthorName(x)} <span>{formatterTimeUS(x.createdAt)}</span>
                                        {user && user.id === x.authorId &&
                                            <>&nbsp;
                                            <IconButton
                                                    isDanger
                                                    isPill={false}
                                                    size="small"
                                                    aria-label="Delete Comment"
                                                    onClick={() => deleteComment(x.id)}
                                            >
                                                <TrashStrokeIcon />
                                            </IconButton></>
                                        }
                                    </p>
                                    <p className={css.commentText}>
                                        {x.text}
                                    </p>
                                </div>
                            </React.Fragment>
                        );
                    })
                }
            </DrawerModal.Body>

            <DrawerModal.Footer className={css.footer}>
                <DrawerModal.FooterItem className={css.inputFieldWrapper}>
                    <Field className={css.inputField}>
                        <Label hidden>Comment</Label>
                        <Textarea
                            disabled={readOnly}
                            minRows={4}
                            maxRows={12}
                            value={newCommentText}
                            onChange={e => setNewCommentText(e.target.value)}
                            placeholder="Add a comment..."
                        />
                    </Field>
                </DrawerModal.FooterItem>
                <DrawerModal.FooterItem>
                    <IconButton
                        isPrimary
                        onClick={submitComment}
                        disabled={newCommentText.trim() == "" || readOnly}
                    >
                        <Send />
                    </IconButton>
                </DrawerModal.FooterItem>
            </DrawerModal.Footer>

            <DrawerModal.Close />
        </DrawerModal>
    );
}
