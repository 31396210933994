import React, { useContext } from 'react';
import { useParams } from 'react-router-dom';
import { AuthContext } from '../../contexts/AuthContext';
import { useWelcomeUser } from '../../hooks/UseAuthHooks';
import { FormContainer } from './FormContainer';
import LoginDesign from './LoginDesign';
import PasswordFormPart from './PasswordFormPart';
import { ForgotPasswordProps, PasswordFlow } from './types';

const SetPassword: React.FC<ForgotPasswordProps> = (params: ForgotPasswordProps) => {
    const {userId: welcomeUserId} = useParams() as {userId: string};
    const {username, fullName} = useWelcomeUser(welcomeUserId);
    const authContext = useContext(AuthContext);

    const workflowUsername = params.flow === PasswordFlow.RESET ? authContext.username : username;

    if (!workflowUsername) {
        return <></>;
    }

    return (
        <>
            <FormContainer>
                {params.flow === PasswordFlow.RESET && <div className="light-blue-box"></div>}
                <div className="left-side">
                    <PasswordFormPart {...params} username={workflowUsername} fullName={fullName ?? ""}/>
                </div>
                <LoginDesign />
            </FormContainer>
        </>
    );
};

export default SetPassword;


