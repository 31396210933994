import "native-injects";
import {useState} from "react";
import {
    CostCategoryInput,
    useCreateRenovationPackageMutation,
    useUpdateRenovationPackageMutation,
    useDeleteRenovationPackageMutation,
    CreateRenovationPackageMutation
} from "../../../__generated__/generated_types";

type UpdateStateMeta = {
    [action: string]: boolean
}
export default function useAddUpdateDeleteRenovationPackage() {
    const [updateStateMeta, setUpdateStateMeta] =
        useState<UpdateStateMeta>({
            "CREATE_IN_PROGRESS": false,
            "UPDATE_IN_PROGRESS": false,
            "DELETE_IN_PROGRESS": false
        });
    const [addNewMutation] = useCreateRenovationPackageMutation({
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        onCompleted(_data) {
            setUpdateStateMeta((prevState) => {
                return {
                    ...prevState,
                    "CREATE_IN_PROGRESS": false
                };
            });
        },
    });
    const createNewPackage = async (params: {
        propertyId: string,
        unitTypeId: string,
        packageName: string,
        costCategoryMappings: CostCategoryInput[],
        premium: number,
        premiumDelayRecognition: number,
    }): Promise<CreateRenovationPackageMutation | null | undefined> => {
        const {propertyId, unitTypeId, packageName, costCategoryMappings, premium, premiumDelayRecognition} = params;
        setUpdateStateMeta((prevState) => {
            return {
                ...prevState,
                "CREATE_IN_PROGRESS": true
            };
        });
        const newPackage = await addNewMutation({
            variables: {
                name: packageName,
                propertyId: propertyId,
                unitTypeId: unitTypeId,
                costCategoryMappings: costCategoryMappings,
                premium: premium.toString(),
                premiumDelayRecognition: premiumDelayRecognition
            }
        }).then(({data}) => {
            return data;
        });

        return newPackage;
    };

    const [updateMutation] = useUpdateRenovationPackageMutation({
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        onCompleted(_data) {
            setUpdateStateMeta((prevState) => {
                return {
                    ...prevState,
                    "UPDATE_IN_PROGRESS": false
                };
            });
        },
    });
    const updatePackage = (params: {
        id: string,
        name: string,
        costCategoryMappings: CostCategoryInput[],
        premium: number,
        premiumDelayRecognition: number,
    }) => {
        const {id, name, costCategoryMappings, premium, premiumDelayRecognition} = params;
        updateMutation({
            variables: {
                id: id,
                name: name,
                costCategoryMappings: costCategoryMappings,
                premium: premium.toString(),
                premiumDelayRecognition: premiumDelayRecognition
            }
        });
        setUpdateStateMeta((prevState) => {
            return {
                ...prevState,
                "UPDATE_IN_PROGRESS": true
            };
        });
    };

    const [deleteMutation] = useDeleteRenovationPackageMutation({
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        onCompleted(_data) {
            setUpdateStateMeta((prevState) => {
                return {
                    ...prevState,
                    "DELETE_IN_PROGRESS": false
                };
            });
        },
    });
    const deletePackage = (params: {
        id: string,
        force: boolean
    }) => {
        const {id, force} = params;
        deleteMutation({
            variables: {
                id: id,
                force: force
            }
        });
        setUpdateStateMeta((prevState) => {
            return {
                ...prevState,
                "DELETE_IN_PROGRESS": true
            };
        });
    };

    return {
        createNewPackage,
        updatePackage,
        deletePackage,
        updateStateMeta
    };
}
