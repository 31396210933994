import { ReactElement, useEffect, useMemo, useRef, useState } from "react";

import { LineItemsMenu } from "../formula-menu/LineItemsMenu";

import * as css from "./styles/css.module.scss";
import { deleteNodeKeys, TFormulaNodeProps } from "./logic/formulaNode";


export type TWorksheetLineItem = {
    name: string,
    locked: boolean,
}

type TWorksheetDriverNodeProps = TFormulaNodeProps&{
    accountId: string | undefined,
    budgetYear: number;
    lineItems: TWorksheetLineItem[],
    isWorksheetDriven: boolean,
    worksheetTypeAhead?: string[] | undefined,
    isBulkUpdateBar?: boolean,
    isPropertyDriversUI?: boolean,
}

export default function WorksheetDriverFxNode(props: TWorksheetDriverNodeProps): ReactElement {
    const [isFocused, setIsFocused] = useState<boolean>(false);
    const [lineItemsHovered, setLineItemsHovered] = useState<boolean>(false);

    const wrapperRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (isFocused && wrapperRef.current) {
            wrapperRef.current.focus();
        }
    }, [isFocused]);

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (
                wrapperRef.current &&
                (wrapperRef.current === event.target ||
                wrapperRef.current.contains(event.target as Node))
            ) {
                return;
            }
            setIsFocused(false);
        };

        // Clicking outside the current node should remove focus
        document.addEventListener("mousedown", handleClickOutside);

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    const nodeClassName = props.locked ? css.fxNodeLocked : css.fxNode;

    const handleClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        const input = event.target as HTMLDivElement;
        let isClickOnInlineBlock = false;

        /**
         * We don't want the `onClose()` event of the `TooltipModal` in `LineItemsMenu` to bubble up
         * as an `onClick()` event on this div. Since `stopPropagation()` doesn't work here, we manually
         * check if this div was clicked on specifically before toggling `isFocused`
        */
        input.classList.forEach((classNameStr) => {
            if (classNameStr.includes(css.inlineBlock)) {
                isClickOnInlineBlock = true;
            }
        });

        if (isClickOnInlineBlock) {
            setIsFocused(!isFocused);
        }
    };

    const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
        if (
            isFocused && deleteNodeKeys.includes(e.key)
        ) {
            props.fbUpdates.deleteWorksheet(props.isWorksheetDriven);
        }
    };

    const worksheetWrapperClassName = useMemo(() => {
        const allClassNames = [nodeClassName, css.inlineBlock, css.deletableNode];

        if (isFocused) {
            allClassNames.push(css.focusedNode);
        }

        if (lineItemsHovered) {
            allClassNames.push(css.disableHover);
        } else {
            allClassNames.filter((each: string) => each !== css.disableHover);
        }

        return allClassNames.join(' ');
    }, [isFocused, lineItemsHovered, nodeClassName]);

    const getTriggerNode = () => {
        if (props.lineItems.length === 0) {
            const junctionNodeClassName = props.locked ? css.junctionNodeLocked : css.junctionNode;
            return (
                <div className={junctionNodeClassName}>+</div>
            );
        }

        return props.lineItems.map((lineItem, idx, arr) =>
            <span key={`worksheet_line_item_${idx}`}>
                {lineItem.name}
                {idx < arr.length - 1
                        ? <>&nbsp;+&nbsp;</>
                        : <></>
                }
            </span>
        );
    };

    const renderLineItemsMenu = (accountId: string, budgetYear: number) => (
        <div
            className={css.inlineBlock}
            onMouseEnter={() => setLineItemsHovered(true)}
            onMouseLeave={() => setLineItemsHovered(false)}
        >
            <LineItemsMenu
                accountId={accountId}
                budgetYear={budgetYear}
                fbUpdates={props.fbUpdates}
                triggerNode={
                    <a>
                        {getTriggerNode()}
                    </a>
                }
                worksheetTypeAhead={props.worksheetTypeAhead}
                isBulkUpdateBar={props.isBulkUpdateBar}
                isPropertyDriversUI={props.isPropertyDriversUI}
            />
        </div>
    );

    return (
        <div
            className={worksheetWrapperClassName}
            onClick={handleClick}
            onKeyDown={handleKeyDown}
            /**
             * onMouseLeave() isn't always triggered when the user selects a line item
             * and the line items menu closes, so we make sure by doing this:
            */
            onMouseEnter={() => setLineItemsHovered(false)}
            ref={wrapperRef}
            tabIndex={0}
        >
                <span
                    className={css.inlineBlock}
                >
                    (Line Items:&nbsp;
                </span>
                {props.accountId ? renderLineItemsMenu(props.accountId, props.budgetYear) : <></>}
                <span>)</span>
        </div>
    );
}
