import { ThemeProvider } from "@zendeskgarden/react-theming";
import { ReactElement, useEffect, useState } from "react";
import useAppStore from "../../../../hooks/useAppStore";
import { ViziblyTheme } from "../../../../styles/zendesk-garden/ViziblyZDGTheme";
import { useGetRevenueSummaryUnitTypeLazyQuery } from "../../../../__generated__/generated_types";
import { useProperties } from "../../../../contexts/properties/PropertiesContext";
import { headerRowNoBottomMargin } from "../../../../components/workflows/workflow-header/styles/css.module.scss";
import * as workflowHeaderCSS from "../../../../components/workflows/workflow-header/styles/css.module.scss";
import * as workflowCSS from "../../../../styles/workflows/workflowCSS.module.scss";
import "native-injects";
import WorkflowHeader from "../../../../components/workflows/workflow-header/WorkflowHeader";
import WorkflowNavToggle from "../../../../components/workflows/workflow-nav-toggle/WorkflowNavToggle";
import WorkflowPageLabel from "../../../../components/workflows/workflow-page-label/WorkflowPageLabel";
import WorkflowStepNav from "../../../../components/workflows/workflow-step-nav/WorkflowStepNav";
import { IWorkflowPageProps } from "../../logic/workflows";
import { RevenueSummaryTable, RevenueSummaryUnitType } from "./RevenueSummaryTable";

export function RevenueSummaryV2(props: IWorkflowPageProps): ReactElement {
    const { currentProperty: property } = useProperties();
    const [loadDataUnitType, { data: unitTypeData, loading: unitTypeLoading }] = useGetRevenueSummaryUnitTypeLazyQuery({ fetchPolicy: "no-cache" });
    const [unitTypes, setUnitTypes] = useState<RevenueSummaryUnitType[]>();
    const appStore = useAppStore();

    useEffect(() => {
        if (!property) {
            return;
        }

        setUnitTypes(undefined);
        loadDataUnitType({
            variables: {
                propertyId: property.id,
                budgetYear: property.budgetYear
            }
        });
    }, [property]);

    useEffect(() => {
        if (!unitTypeData || unitTypeLoading || !unitTypeData.originalRevenueModel) {
            return;
        }
        // assuming all metrics have same unit types list
        setUnitTypes(
            [{ unitTypeId: "all", unitTypeName: "All Unit Types" },
            ...Object.entries(unitTypeData
                .originalRevenueModel
                .unitTypeUnitCount
                .toIdMap("unitTypeId", "unitTypeName")
            )
                .map(([unitTypeId, unitTypeName]) => ({ unitTypeId, unitTypeName }))
                .sortBy("unitTypeName")
            ]
        );
    }, [unitTypeData, unitTypeLoading]);


    useEffect(() => {
        appStore.set({ isLoading: false });
    }, []);

    return (
        <ThemeProvider theme={ViziblyTheme}>
            <div className={workflowCSS.workflowContainer}>
                <WorkflowHeader className={headerRowNoBottomMargin}>
                    <WorkflowHeader.LeftCol>
                        <div className={workflowHeaderCSS.rowItemsContainer}>
                            <WorkflowNavToggle />
                            <WorkflowPageLabel label="Revenue Summary" />
                        </div>
                    </WorkflowHeader.LeftCol>
                    <WorkflowHeader.RightCol>
                        <div className={workflowHeaderCSS.columnItemsContainer}>
                            <WorkflowStepNav onPreviousClick={props.onPreviousClick} onNextClick={props.onNextClick} />
                        </div>
                    </WorkflowHeader.RightCol>
                </WorkflowHeader>
                <div className={`${workflowCSS.bodyContainer}`} style={{padding: "1rem"}}>
                    {unitTypeData && unitTypeData.originalRevenueModel && !unitTypeLoading && property && unitTypes &&
                    <RevenueSummaryTable
                        unitTypeData={unitTypeData.originalRevenueModel}
                        property={property}
                        unitTypes={unitTypes}
                    />
                    }
                </div>
            </div>
        </ThemeProvider>
    );
}