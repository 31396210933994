/* eslint-disable @typescript-eslint/no-empty-function */
import React, {ReactElement, useContext, useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import useAppStore from "../../hooks/useAppStore";

import AnalystSectionTabs, {AnalystSections} from "../../components/analyst/section-tabs/AnalystSectionTabs";
import * as styles from "./styles.module.scss";

import OpDrivers from "./tab-op-drivers/OpDrivers";
import ShimmerRenderer, {ShimmerPageKey} from "../../components/shimmer/ShimmerRenderer";



import {Payroll} from "../payroll/Payroll";
import AnalystRenovations from "./tab-renovations/AnalystRenovations";
import {useAnalystDashboardProperties} from "./AnalystDashboardProperties";

import { RenderIfAuthorized, RenderIfReadOnly } from "../../authorization/RenderIfAuthorized";
import {Authorizations} from "../../authorization/Authorizations";
import {AuthorizationContext} from "../../authorization/AuthorizationContext";
import { ThemeProvider } from "@zendeskgarden/react-theming";

import ReadOnlyHeader from "../../components/navbar/ReadOnlyHeader";
import {useProperties} from "../../contexts/properties/PropertiesContext";
import { ViziblyTheme } from "../../styles/zendesk-garden/ViziblyZDGTheme";
import StaticLoader from "../../components/static-loadbar/StaticLoader";
import { useGetUserPropertiesForecastLocksLazyQuery } from "../../__generated__/generated_types";
import { TLockedProperties } from "../bulk-update/BulkUpdate";
import FinancialsV2PlanningHubV1Shim from "../planning-hub/financials/FinancialsV2PlanningHubV1Shim";
import { AuthContext } from "../../contexts/AuthContext";

interface SectionTabData {
    section: AnalystSections;
    requiredAuthorization: Authorizations | null;
    defaultOrder: number
}
const SectionTabMap:Record<string, SectionTabData> = {
    'renovations': {
        section: AnalystSections.Renovations,
        requiredAuthorization: null,
        defaultOrder: 4
    },
    'payroll': {
        section: AnalystSections.Payroll,
        requiredAuthorization: Authorizations.PlanningHubPayroll,
        defaultOrder: 3
    },
    'financials': {
        section: AnalystSections.Financials,
        requiredAuthorization: null,
        defaultOrder: 5
    },
};

function getInitialSection(tab: string, hasAuthorization: (auth: Authorizations) => boolean): AnalystSections {
    const section = SectionTabMap[tab];
    if(section && (section.requiredAuthorization === null || hasAuthorization(section.requiredAuthorization))) {
        return section.section;
    }

    // Otherwise try to find the appropriate default for the user.
    const allowedSections = Object.values(SectionTabMap)
        .sortBy(st => st.defaultOrder)
        .filter(st => st.requiredAuthorization === null || hasAuthorization(st.requiredAuthorization));
    if(allowedSections.length > 0 && allowedSections[0]) {
        return allowedSections[0].section;
    }

    // We should never reach this point, but if we do
    // it will never load anything.
    return AnalystSections.Renovations;
}

export default function AnalystDashboard(): ReactElement {
    const appStore = useAppStore();
    const {properties} = useProperties();
    const propertiesFilter = useAnalystDashboardProperties();
    const {hasAuthorization} = useContext(AuthorizationContext);
    const {user} = useContext(AuthContext);

    const { tab } = useParams() as { tab: string };
    const initialSection = getInitialSection(tab, hasAuthorization);

    const [showShimmer, setShowShimmer] = useState<boolean>(true);
    const [showingSection, showSection] = useState(initialSection);
    const [sectionReady, setSectionReady] = useState<Record<AnalystSections, boolean>>({
        [AnalystSections.Payroll]: false,
        [AnalystSections.Renovations]: false,
        [AnalystSections.Financials]: false,
    });
    const initialProgress = 0;
    const [loadProgress, setLoadProgress] = useState<number>(initialProgress);
    const [budgetYear, setBudgetYear] = useState<number | undefined>(undefined);
    const [lockedProperties, setLockedProperties] = useState<TLockedProperties>({reforecastLocked: [], budgetLocked: []});

    const [getPropertyLocks, {data: propertyLocks, loading: propertyLocksLoading}] = useGetUserPropertiesForecastLocksLazyQuery({
        fetchPolicy: "no-cache",
    });

    /////////////////////////
    // Determine Budget Year
    /////////////////////////
    useEffect(() => {
        if(!properties || properties.length === 0) {
            setBudgetYear(undefined);
            return;
        }

        const budgetYears = properties.map(p => p.budgetYear).dedupe();
        const budgetYear = Math.max(...budgetYears);
        setBudgetYear(budgetYear);
    }, [properties]);

    useEffect(() => {
        if (budgetYear === undefined) {
            return;
        }

        getPropertyLocks({
            variables: {
                budgetYear,
            }
        });
    }, [budgetYear]);

    useEffect(() => {
        if (propertyLocksLoading || !propertyLocks?.userPropertiesForecastLocks) {
            return;
        }

        const {budgetLocked, reforecastLocked} = propertyLocks.userPropertiesForecastLocks;
        setLockedProperties({budgetLocked, reforecastLocked});
    }, [propertyLocksLoading, propertyLocks?.userPropertiesForecastLocks]);

    //////////////////////////////
    // BEGIN: Defer Load of sections until requested.
    // Notes:
    // 1. This is part of incremental migration towards Planning Hub v2
    // 2. Planning Hub v2 has a different, more consolidated, approach that's handled in a context
    /////////////////////////////
    const [sectionVisited, setSectionVisited] = useState<Record<AnalystSections, boolean>>({
        [AnalystSections.Payroll]: false,
        [AnalystSections.Renovations]: false,
        [AnalystSections.Financials]: false,
    });
    useEffect(
            ()=>{
                setSectionVisited( visitedState => {
                    return {
                        ...visitedState,
                        [showingSection]: true,
                    };
                });
            },
            [showingSection]);
    //////////////////////////////
    // END: Defer Load of sections until requested.
    //////////////////////////////

    //////////////////////////////
    // BEGIN: Unit-Level Modeling
    /////////////////////////////
    const [hasUnitLevelModeling, setHasUnitLevelModeling] = useState<boolean>(false);
    useEffect(() => {
        if(!properties || properties.length === 0) {
            setHasUnitLevelModeling(false);
            return;
        }

        setHasUnitLevelModeling(properties.some(p => p.unitLevelModelingEnabled));
    }, [properties]);
    /////////////////////////////
    // END: Unit-Level Modeling
    /////////////////////////////

    useEffect(
        () => {
            // Dispells the "Vizibly is starting" graphic
            appStore.set({ isLoading: false });
            return () => {
            };
        },
        []);

    // Updated when a section is loaded
    useEffect(
        () => {
            if(sectionReady[showingSection]){
                setShowShimmer(false);
            } else {
                setShowShimmer(true);
            }
        },
        [sectionReady, showingSection, sectionVisited]);

    function onSectionReadyReport(sectionId:AnalystSections, isReady:boolean):void{
        setSectionReady(previous => {
            return {
                ...previous,
                ...{[sectionId]: isReady }
            };
        });
    }

    return (
        <ThemeProvider theme={ViziblyTheme}>
            <section className={styles.dashboard}>
                <StaticLoader loadProgress={loadProgress}/>
                <RenderIfReadOnly>
                    <ReadOnlyHeader />
                </RenderIfReadOnly>
                <div className={styles.mainHeader}>
                    <div className={styles.viewTitle}>Planning Hub</div>
                    <div className={styles.betaTag}>Beta</div>
                </div>
                <AnalystSectionTabs
                    onTabSelect={showSection}
                    showingSection={showingSection}
                    propertiesFilter={propertiesFilter}
                    hasUnitLevelModeling={hasUnitLevelModeling}
                />
                {showShimmer &&
                    <div>
                        <ShimmerRenderer pageKey={ShimmerPageKey.ANALYST_VIEW}/>
                    </div>
                }
                <div>
                    {sectionVisited[AnalystSections.Renovations] && !hasUnitLevelModeling &&
                        <AnalystRenovations
                            setSectionReady={onSectionReadyReport}
                            setLoadProgress={setLoadProgress}
                            propertiesFilter={propertiesFilter}
                            lockedProperties={lockedProperties}
                            hideSpreadsheet={
                                showingSection != AnalystSections.Renovations ||
                                !sectionReady[AnalystSections.Renovations] ||
                                !propertiesFilter.isReady}/>
                    }
                    {sectionVisited[AnalystSections.Payroll] && showingSection == AnalystSections.Payroll && budgetYear != undefined
                            && <RenderIfAuthorized required={Authorizations.PlanningHubPayroll}>
                        <Payroll
                            setSectionReady={onSectionReadyReport}
                            budgetYear={budgetYear}
                            lockedProperties={lockedProperties}
                            filterPropertyIds={propertiesFilter.isReady ? propertiesFilter.selectedProperties.map(p => p.id) : undefined}
                        />
                    </RenderIfAuthorized>}
                    {sectionVisited[AnalystSections.Financials] && showingSection == AnalystSections.Financials && user?.clientId === "70279817-eaaa-462c-bbeb-6b8489028c6e" &&
                        <FinancialsV2PlanningHubV1Shim
                                reportLoadProgress={(progressAmt: number) => {
                                    setLoadProgress(progressAmt);
                                }}
                                style={{ ...(showingSection != AnalystSections.Financials || !propertiesFilter.isReady ? { display: 'none' } : {}) }}
                                setSectionReady={onSectionReadyReport}
                        />
                    }
                </div>
            </section>
        </ThemeProvider>
    );
}
