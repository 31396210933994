import { FinancialEntityType } from "../../__generated__/generated_types";

export type ItemData = {
    id: string,
    glNumber?: string | null,
    glName: string,
    parentIds: string[],
    type: FinancialEntityType,

    isNew: boolean,
    isDeleted: boolean,
    collapsed: boolean
}

export function itemText(item: ItemData): string {
    return `${item.glNumber ? (item.glNumber + " - ") : ""} ${item.glName}`;
}
