import { ReactElement } from "react";

import * as css from "./styles/css.module.scss";

interface IBulkExportPageHeaderProps {
    pageDescription: string;
    pageTitle: string;
}

export default function BulkExportPageHeader({
    pageDescription,
    pageTitle,
}: IBulkExportPageHeaderProps):ReactElement {
    return (
        <div className={`${css.column} ${css.pageHeader}`}>
            <div className={`${css.row} ${css.pageTitle}`}>
                Bulk Export - {pageTitle}
            </div>
            <div className={`${css.row} ${css.pageDescription}`}>
                {pageDescription}
            </div>
        </div>
    );
}
