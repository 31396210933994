import {ReactElement, useContext, useEffect} from "react";
import {useLocation} from "react-router-dom";
import mixpanel from "mixpanel-browser";
import { MixPanelContext } from "./MixPanelWrapper";

export function MixPanelRouteWatcher(props: {children?: ReactElement | ReactElement[]}): ReactElement {
    // This only functions when loaded under the Router element from react-router-dom, so this
    // component must be put under that.
    const location = useLocation();
    const mixPanelContext = useContext(MixPanelContext);

    useEffect(() => {
        if (!mixPanelContext.initialized) {
            return;
        }
        mixpanel.track_pageview();
    }, [location]);

    return <>{props.children ? props.children : <></>}</>;
}