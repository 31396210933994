import { IPaginationProps } from "@zendeskgarden/react-pagination";
/**
 * Used to apply Aria lables to the ZDG Pagination component's children. Many warnings are emitted if this is not
 * present. Not sure why this has to be added manually since the warnings simply tell that defaults are being used.
 * @param pageType
 * @param props
 * @param pageNumber
 */
export const zdgPaginationTransformPageProps: IPaginationProps['transformPageProps'] = (
    pageType,
    props,
    pageNumber
) => {
    let retVal;

    switch (pageType) {
        case 'previous':
            retVal = { ...props, 'aria-label': 'Previous page' };
            break;

        case 'next':
            retVal = { ...props, 'aria-label': 'Next page' };
            break;

        case 'page':
            retVal = {
                ...props,
                'aria-label': props['aria-current']
                    ? `Current page, page ${pageNumber}`
                    : `Page ${pageNumber}`
            };
            break;

        default:
            retVal = props;
            break;
    }

    return retVal;
};

