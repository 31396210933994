import {
    GetRenovationCostCategoriesQuery,
    useGetRenovationCostCategoriesQuery
} from "../../../__generated__/generated_types";
import {useEffect, useState} from "react";

interface DataState {
    isDataReady: boolean;
    data: GetRenovationCostCategoriesQuery | undefined;
}

export default function useGetClientCostCategories(): DataState {
    const [isDataReady, setDataReady] = useState<boolean>(false);

    const renovationCostCategoriesResponse = useGetRenovationCostCategoriesQuery({fetchPolicy: "network-only"});
    const renovationCostCategoriesData = renovationCostCategoriesResponse.data;
    const renovationCostCategoriesLoading = renovationCostCategoriesResponse.loading;
    const renovationCostCategoriesError = renovationCostCategoriesResponse.error;

    useEffect(
        () => {
            if (renovationCostCategoriesData === undefined || renovationCostCategoriesLoading) {
                return;
            }
            setDataReady(true);
        },
        [renovationCostCategoriesData,
            renovationCostCategoriesLoading,
            renovationCostCategoriesError]
    );

    return {
        isDataReady: isDataReady,
        data: renovationCostCategoriesData
    };
}
