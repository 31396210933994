import GC from "@grapecity/spread-sheets";
import Style = GC.Spread.Sheets.Style;
import { ICustomCellInfo, ICustomCellToggleHitInfo } from "./types";
import { DEFAULT_ICON_W, HotspotSpec, ToggleOpenIconSpec, ButtonSpec } from "./icons/CellDecoratorSpecs";
import { renderButton, renderToggleIcon } from "./icon-renderers/draw_expand_toggle";
import { FinancialEntityType } from "../../../__generated__/generated_types";

export interface IToggleIconCellHitInfo extends GC.Spread.Sheets.IHitTestCellTypeHitInfo {
    isOpen: boolean;
    highlightToggle: boolean;
    isTargetHit?: boolean;
    entityType?: string;
}

export interface ToggleIconCellInfo extends ICustomCellInfo {
    isOpen?: boolean,
    style?: Style,
    toggleIconSpecs?: ToggleOpenIconSpec,
    buttonSpecs?: ButtonSpec,
    showIcon?: boolean,
    showButton?: boolean,
    hotspotSpecs?: HotspotSpec,
    entityType?: string,
}

export class ToggleIconCell extends GC.Spread.Sheets.CellTypes.Text {
    private isIconHovered: boolean;
    private isButtonHovered: boolean;
    private isOpen: boolean;
    private entityType: string;

    constructor(private cellInfo: ToggleIconCellInfo) {
        super();
        this.typeName = 'ToggleIconCell';
        this.cellInfo = cellInfo;
        this.isIconHovered = false;
        this.isButtonHovered = false;

        if(cellInfo.isOpen == undefined){
            this.cellInfo.isOpen = false;
        }
        this.isOpen = this.cellInfo.isOpen as boolean;

        if(cellInfo.entityType == undefined){
            this.cellInfo.entityType = 'default';
        }
        this.entityType = this.cellInfo.entityType as string;

        // Initialize to false if no explicit instruction to show the icon is given
        if(cellInfo.showIcon != true){
            this.cellInfo.showIcon = false;
        }

        if(!this.cellInfo.showButton){
            this.cellInfo.showButton = false;
        }
    }

    showPointer = false;

    public updateCellInfo(cellInfo: ToggleIconCellInfo): ToggleIconCellInfo {
        this.cellInfo = cellInfo;

        this.isOpen = this.cellInfo.isOpen ?? false;

        return this.cellInfo;
    }

    public getCellInfo(): ToggleIconCellInfo {
        return this.cellInfo;
    }

    /**
     * Resets the isIconHovered evaluation flag. There are cases where this value isn't reset, and the cell believes
     * it's still in a hovered state, which causes the icon to be displayed incorrectly. We can know if an icon hover is
     * impossible, however, such as the case where another cell in the row is being hovered, and reset the state with
     * this method.
     */
    public resetHovered(): void {
        this.isIconHovered = false;
    }

    override paint(ctx: CanvasRenderingContext2D, value: any, x: number, y: number, w: number, h: number, style: GC.Spread.Sheets.Style, context?: any): void {
        if(this.cellInfo.style){
            style = {
                ...style,
                ...this.cellInfo.style,
            } as GC.Spread.Sheets.Style;
        }

        super.paint(ctx, value, x, y, w, h, style, context);
    }

    override paintContent(ctx: CanvasRenderingContext2D, value: any, x: number, y: number, w: number, h: number, style: GC.Spread.Sheets.Style, context?: any): void {
        if(this.cellInfo.style){
            style = {
                ...style,
                ...this.cellInfo.style,
            } as GC.Spread.Sheets.Style;
        }

        super.paintContent(ctx, value, x, y, w, h, style, context);

        if(this.cellInfo.hotspotSpecs && this.cellInfo.hotspotSpecs.visible){
            ctx.fillStyle = '#F7E6FF';
            ctx.fillRect(
                x + this.cellInfo.hotspotSpecs.x,
                y + this.cellInfo.hotspotSpecs.y,
                this.cellInfo.hotspotSpecs.width,
                this.cellInfo.hotspotSpecs.height,
            );
        }

        if(this.cellInfo.toggleIconSpecs && this.cellInfo.showIcon){
            renderToggleIcon(ctx, x, y, this.cellInfo.toggleIconSpecs, this.isOpen, this.isIconHovered);
        }

        if(this.cellInfo.buttonSpecs && this.cellInfo.showButton){
            renderButton(ctx, x, y, this.cellInfo.buttonSpecs, this.isButtonHovered);
        }
    }

    override getHitInfo(x: number, y: number, _cellStyle: GC.Spread.Sheets.Style, cellRect: GC.Spread.Sheets.Rect, context?: any): GC.Spread.Sheets.IHitTestCellTypeHitInfo {
        const c = cellRect; // alias
        const [row, col] = [context.row as number, context.col as number];

        if(this.cellInfo.hotspotSpecs){
            this.isIconHovered = this.getIsHotspotHovered(x, y, cellRect);
        }
        else {
            this.isIconHovered = this.getIsToggleIconHovered(x, y, cellRect);
        }

        if(this.cellInfo.buttonSpecs){
            this.isButtonHovered = this.getIsButtonHovered(x, y, cellRect);
        }

        return {
            x,
            y,
            row,
            col,
            cellRect,
            isOpen: this.isOpen,
            entityType: this.entityType,
            highlightToggle: this.isIconHovered,
            isTargetHit: this.cellInfo.buttonSpecs ? this.isButtonHovered : this.isIconHovered,
        } as ICustomCellToggleHitInfo;
    }

    getIsButtonHovered(x: number, _y: number, c: GC.Spread.Sheets.Rect): boolean {
        if(this.cellInfo.buttonSpecs){
            const { buttonSpecs } = this.cellInfo;
            if(
                x > c.x + buttonSpecs.x
                && x < c.x + buttonSpecs.x + (buttonSpecs.width ?? 0)
            ){
                return true;
            }
        }

        return false;
    }

    getIsHotspotHovered(x: number, _y: number, c: GC.Spread.Sheets.Rect): boolean {
        if(this.cellInfo.hotspotSpecs){
            const { hotspotSpecs } = this.cellInfo;
            if(x > c.x + hotspotSpecs.x && x < c.x + hotspotSpecs.x + hotspotSpecs.width){
                return true;
            }
        }

        return false;
    }

    getIsToggleIconHovered(x: number, _y: number, c: GC.Spread.Sheets.Rect): boolean {
        if(this.cellInfo.toggleIconSpecs){
            const { openIconSpecs, closedIconSpecs } = this.cellInfo.toggleIconSpecs as ToggleOpenIconSpec;
            if(this.isOpen){
                if(x > c.x + openIconSpecs.x && x < c.x + openIconSpecs.x + (openIconSpecs?.width ?? DEFAULT_ICON_W)){
                    return true;
                }
            }
            else {
                if(x > c.x + closedIconSpecs.x && x < c.x + closedIconSpecs.x + (closedIconSpecs?.width ?? DEFAULT_ICON_W)){
                    return true;
                }
            }
        }

        return false;
    }
}
