import React, { ReactElement, useState, useEffect, useRef } from "react";
import * as css from "../styles/executiveSummaryReportManagement.module.scss";
import { GetReportTablesQuery } from "../../../__generated__/generated_types";
import { Field, MediaInput, Label, Message } from '@zendeskgarden/react-forms';
import { Body, Close, Footer, FooterItem, Header, Modal } from "@zendeskgarden/react-modals";
import { Button } from "@zendeskgarden/react-buttons";
import { Inline } from "@zendeskgarden/react-loaders";

export type EditReportTableProps = {
    existingReportTables: GetReportTablesQuery["getReportTables"];
    initialName?: string;
    reportTableId?: string;
    title: string;
    onCancel: () => void;
    onSubmit: (
        name: string | undefined
    ) => void;
}

export function EditReportTable(props: EditReportTableProps): ReactElement {
    const [name, setName] = useState<string | undefined>(props.initialName);
    const [valid, setValid] = useState({valid: true, error: ""});
    const [applyClicked, setApplyClicked] = useState(false);
    const labelRef = useRef<HTMLLabelElement | null>(null);

    useEffect(() => {
        let valid = true;
        let error = "";

        if (name !== props.initialName && name !== undefined) {
            if (name.trim() === "") {
                valid = false;
                error = "Can't be empty";
            }

            const sameFound = props.existingReportTables.find(reportTable =>
                reportTable.id != props.reportTableId
                && reportTable.name == name
            );
            if (sameFound) {
                valid = false;
                error = "Table with same name exists";
            }
        }

        setValid({valid: valid, error: error});
    }, [name]);

    function handleEnter(e: React.KeyboardEvent<HTMLInputElement>) {
        if (e.code === "Enter") {
            e.preventDefault();
            e.stopPropagation();
            if (valid.valid) {
                handleSubmit();
            }
        }
    }

    function handleSubmit() {
        setApplyClicked(true);
        props.onSubmit(name);
    }

    function hasChanges(): boolean {
        const ret = (name !== props.initialName);
        return ret;
    }

    return (
        <Modal onClose={props.onCancel} isLarge style={{minWidth: "80rem", overflow: "unset"}}>
            <Header>
                {props.title}
            </Header>
            <Body>
                <form className={css.editForm}>
                    <Field>
                        <Label ref={labelRef}>Report Table Name</Label>
                        <MediaInput value={name} onChange={e => setName(e.target.value)} onKeyDown={handleEnter}/>
                    </Field>
                    {!valid.valid &&
                    <Message validation="error">{valid.error}</Message>
                    }
                </form>
            </Body>
            <Footer>
                <FooterItem>
                    <Button onClick={props.onCancel} isBasic>
                        Cancel
                    </Button>
                </FooterItem>
                <FooterItem>
                    <Button
                        disabled={!valid.valid || !hasChanges() || applyClicked}
                        onClick={() => {  handleSubmit(); }}
                    >
                        {
                            applyClicked
                                ? <Inline size={24} aria-label="loading"/>
                                : <span>Apply</span>
                        }
                    </Button>
                </FooterItem>
            </Footer>
            <Close aria-label="Close modal" />
        </Modal>
    );
}

