/* eslint-disable @typescript-eslint/no-empty-function */
import {ReactElement, useEffect, useRef} from 'react';
import {Col, Grid, Row} from '@zendeskgarden/react-grid';
import spStyles from './StartPlanning.module.scss';
import {useChartOfAccounts} from '../../contexts/chartofaccounts/ChartOfAccountsContext';
import Loading from '../../components/loading/Loadiing';
import useAppStore from '../../hooks/useAppStore';
import StarredAccountsTableView, {
    StarredAccountsTableViewRefObject
} from "./starred-accounts-table-view/StarredAccountsTableView";
import EmptyAccountsTableView, {
    EmptyAccountsTableViewRefObject
} from "./empty-accounts-table-view/EmptyAccountsTableView";
import OverriddenAccountsTableView, {
    OverriddenAccountsTableViewRefObject
} from "./overridden-accounts-table-view/OverriddenAccountsTableView";
import StartPlanningCards from './StartPlanningCards';

/**
 * Start planning page react component
 *
 * @return {ReactElement} Menu Cards populated
 */
export default function StartPlanning(): ReactElement {
    const {accounts, loading} = useChartOfAccounts();
    const starredAccountsTableViewRef = useRef<StarredAccountsTableViewRefObject>(null);
    const emptyAccountsTableViewRef = useRef<EmptyAccountsTableViewRefObject>(null);
    const overriddenAccountsTableViewRef = useRef<OverriddenAccountsTableViewRefObject>(null);

    const appStore = useAppStore();

    useEffect(() => {
        appStore.set({isLoading: loading});
        return () => { };
    }, [loading]);


    return (
        <>
            {loading && <Loading />}
            {accounts &&
                <>
                    <Grid className={`${spStyles.spContainer} ${spStyles.spContainerOldLandingPage}`}>
                        <Row justifyContent={"start"}
                             className={spStyles.spTitle}>
                            <Col size={12}>
                                Plan
                            </Col>
                        </Row>

                        <StartPlanningCards />

                        <Row justifyContent={"center"}>
                            <Col size={12}>
                                <StarredAccountsTableView
                                    ref={starredAccountsTableViewRef}
                                    onDataUpdate={() => {
                                        if( emptyAccountsTableViewRef && emptyAccountsTableViewRef.current &&
                                            typeof emptyAccountsTableViewRef.current.imperativeRefreshTableData === "function") {
                                                emptyAccountsTableViewRef?.current?.imperativeRefreshTableData();
                                        }
                                        if( overriddenAccountsTableViewRef && overriddenAccountsTableViewRef.current &&
                                                typeof overriddenAccountsTableViewRef.current.imperativeRefreshTableData === "function") {
                                            overriddenAccountsTableViewRef?.current?.imperativeRefreshTableData();
                                        }
                                    }}
                                />
                            </Col>
                        </Row>
                        <Row justifyContent={"center"}>
                            <Col size={12}>
                                <EmptyAccountsTableView
                                    ref={emptyAccountsTableViewRef}
                                    onDataUpdate={() => {
                                        if( starredAccountsTableViewRef && starredAccountsTableViewRef.current &&
                                            typeof starredAccountsTableViewRef.current.imperativeRefreshTableData === "function") {
                                            starredAccountsTableViewRef?.current?.imperativeRefreshTableData();
                                        }
                                        if( overriddenAccountsTableViewRef && overriddenAccountsTableViewRef.current &&
                                                typeof overriddenAccountsTableViewRef.current.imperativeRefreshTableData === "function") {
                                            overriddenAccountsTableViewRef?.current?.imperativeRefreshTableData();
                                        }
                                    }}
                                />
                            </Col>
                        </Row>
                        <Row justifyContent={"center"}>
                            <Col size={12}>
                                <OverriddenAccountsTableView
                                        ref={overriddenAccountsTableViewRef}
                                        onDataUpdate={() => {
                                            if( starredAccountsTableViewRef && starredAccountsTableViewRef.current &&
                                                    typeof starredAccountsTableViewRef.current.imperativeRefreshTableData === "function") {
                                                starredAccountsTableViewRef?.current?.imperativeRefreshTableData();
                                            }
                                            if( emptyAccountsTableViewRef && emptyAccountsTableViewRef.current &&
                                                    typeof emptyAccountsTableViewRef.current.imperativeRefreshTableData === "function") {
                                                emptyAccountsTableViewRef?.current?.imperativeRefreshTableData();
                                            }
                                        }}
                                />
                            </Col>
                        </Row>
                    </Grid>
                </>
            }
        </>
    );
}