import React, {ReactElement, useEffect, useState} from 'react';
import {useParams} from "react-router-dom";
import {BudgetingType} from "../../../../../BudgetingType";
import {useProperties} from "../../../../../contexts/properties/PropertiesContext";
import {useSettings} from "../../../../../contexts/settings/SettingsContext";
import {
    GetCappedRenewalIncreaseQuery,
    useGetCappedRenewalIncreaseLazyQuery,
} from "../../../../../__generated__/generated_types";
import CappedRenewalIncreaseSjs from "./sjs/CappedRenewalIncreaseSjs";

interface CappedRenewalIncreaseParams {
    accountDataLoading?: (isLoading:boolean) => void,
    readOnly?: boolean,
    // reloadTrigger - a way to tell the component that it needs to re-load data. whenever the value changes - re-load is triggered
    reloadTrigger: string, 
}

export default function CappedRenewalIncrease({ accountDataLoading, readOnly, reloadTrigger }: CappedRenewalIncreaseParams): ReactElement {
    const { type } = useParams() as { type: BudgetingType; propertyId: string };
    const { propertyId, loaded: propertyContextLoaded } = useProperties();
    const { startReforecastMonthIndex, year } = useSettings();
    const [data, setData] = useState<GetCappedRenewalIncreaseQuery["property"]>(undefined);
    const [canRender, setCanRender] = useState<boolean>(false);

    const [getCappedRenewalIncrease, {
        data: cappedRenewalIncreaseQueryData,
        loading: cappedRenewalIncreaseQueryLoading,
        error: cappedRenewalIncreaseQueryError
    }] = useGetCappedRenewalIncreaseLazyQuery({
        fetchPolicy: "no-cache"
    });

    useEffect(() => {
        setCanRender(false);
        getCappedRenewalIncrease({
            variables: {
                propertyId: propertyId,
                thisYearM3: year - 3,
                thisYearM2: year - 2,
                thisYearM1: year - 1,
                thisYear: year,
                nextYear: year + 1
            }
        });
    }, [reloadTrigger]);

    useEffect(() => {
        if(cappedRenewalIncreaseQueryData && cappedRenewalIncreaseQueryData.property && !cappedRenewalIncreaseQueryLoading && !cappedRenewalIncreaseQueryError && propertyContextLoaded) {
            setData({
                __typename: "Property",
                // TODO: move this crap to backend when getting rid of SJS
                unitTypes: cappedRenewalIncreaseQueryData.property.unitTypes.filter(row => row.budgetYear == year + 1),
                nextYearBudget: cappedRenewalIncreaseQueryData.property.nextYearBudget,
                thisYearBudget: cappedRenewalIncreaseQueryData.property.thisYearBudget,
                thisYearReforecast: cappedRenewalIncreaseQueryData.property.thisYearReforecast,
                thisYearActuals: cappedRenewalIncreaseQueryData.property.thisYearActuals,
                thisYearM1Actuals: cappedRenewalIncreaseQueryData.property.thisYearM1Actuals,
                thisYearM2Actuals: cappedRenewalIncreaseQueryData.property.thisYearM2Actuals,
                thisYearM3Actuals: cappedRenewalIncreaseQueryData.property.thisYearM3Actuals,
            });
            setCanRender(true);

            if(accountDataLoading){
                accountDataLoading(false);
            }
        }
    }, [cappedRenewalIncreaseQueryData, cappedRenewalIncreaseQueryLoading, cappedRenewalIncreaseQueryError, propertyContextLoaded]);

    return (
                <CappedRenewalIncreaseSjs
                        key="renewal-rate"
                        propertyId={propertyId}
                        budgetingType={type}
                        year={year}
                        startReforecastMonthIndex={startReforecastMonthIndex}
                        data={data}
                        canRender={canRender}
                        readOnly={readOnly}
                />
    );
}
