import React, { Children, ReactElement } from "react";
import { Route, BrowserRouter as Router, Switch } from "react-router-dom";
import { setUseGuestIdTokenForServerRequests } from "../libs/guest_token";

const GuestRoute: React.FC = (props) => {
    return (
        <>
            {props.children}
        </>
    );
};

const RegularRoute: React.FC = (props) => {
    return (
        <>
            {props.children}
        </>
    );
};

function AppDeciderInternal(props: {children: ReactElement | ReactElement[]}): ReactElement {
    let guestRoute:ReactElement = <></>;
    let regularRoute:ReactElement = <></>;

    Children.forEach<ReactElement>(props.children, child => {
        if(child.type === GuestRoute) {
            guestRoute = child;
        }
        if(child.type === RegularRoute) {
            regularRoute = child;
        }
    });

    return (
        <Switch>
            <Route path="/guest/" component={() => {setUseGuestIdTokenForServerRequests(true); return guestRoute;}} />
            <Route path="*" component={() => {setUseGuestIdTokenForServerRequests(false); return regularRoute;}}/>
        </Switch>
    );
}

export function AppDecider(props: {children: ReactElement | ReactElement[]}): ReactElement {
    return (
        <Router>
            <AppDeciderInternal>
                {props.children}
            </AppDeciderInternal>
        </Router>
    );
}


AppDecider.GuestRoute = GuestRoute;
AppDecider.RegularRoute = RegularRoute;