import {ReactElement, useState} from "react";
import {Body, Footer, FooterItem, Header, Modal} from "@zendeskgarden/react-modals";
import {Button} from "@zendeskgarden/react-buttons";
import {useProperties} from "../../../contexts/properties/PropertiesContext";
import {
    SetUsersBulkMutationVariables,
    useListGroupsQuery,
    useSetUsersBulkMutation
} from "../../../__generated__/generated_types";
import {Field, Label, Toggle} from "@zendeskgarden/react-forms";
import UserFormDropdown from "./components/user-form/UserFormDropdown";
import {COLORS} from "../../../constants/Colors";
import {Inline} from "@zendeskgarden/react-loaders";
import * as formCss from "./components/user-form/styles/userFormStyles.module.scss";
import {toast} from "react-toastify";

interface UserBulkEditModalProps {
    closed: (updated: boolean) => void;
    userIds: string[];
}

export function UserBulkEditModal(props: UserBulkEditModalProps): ReactElement {
    const {properties} = useProperties();
    const {loading: groupsLoading, data: groups} = useListGroupsQuery({
        fetchPolicy: "no-cache"
    });
    const [setUsersBulk, {loading}] = useSetUsersBulkMutation({
        fetchPolicy: "no-cache"
    });

    const [readOnly, setReadOnly] = useState<boolean>(false);
    const [propertyIds, setPropertyIds] = useState<string[]>([]);
    const [groupIds, setGroupIds] = useState<string[]>([]);

    const [editReadOnly, setEditReadOnly] = useState<boolean>(false);
    const [editProperties, setEditProperties] = useState<boolean>(false);
    const [editGroups, setEditGroups] = useState<boolean>(false);

    function doSetUsers(): void {
        const variables: Partial<SetUsersBulkMutationVariables> = {};
        if(editReadOnly) {
            variables["readOnly"] = readOnly;
        }
        if(editProperties) {
            variables["propertyIds"] = propertyIds;
        }
        if(editGroups) {
            variables["groupIds"] = groupIds;
        }

        setUsersBulk({
            variables: {
                ...variables,
                ids: props.userIds
            }
        }).then(data => {
            if(data.data) {
                toast.success(`Update ${props.userIds.length} users.`);
                props.closed(true);
            }
        }).catch(() => {/* Do Nothing */});
    }

    return <Modal>
        <Header>Bulk Edit {props.userIds.length} Users</Header>
        <Body style={{minHeight: "40vh"}}>
            <form className={formCss.bulkEditForm}>
                <div>
                    <h5 className={formCss.bulkEditHeader}>
                        Set Readonly
                        <Field className={formCss.bulkEditHeaderToggle}>
                            <Toggle checked={editReadOnly}
                                    aria-label="Enable Set Readonly"
                                    onChange={evt =>setEditReadOnly(evt.target.checked)}>
                                <Label />
                            </Toggle>
                        </Field>
                    </h5>
                    <div className={formCss.bulkEditField}>
                        {editReadOnly ?
                            <Field>
                                <Toggle checked={readOnly}
                                        onChange={evt => setReadOnly(evt.target.checked)}>
                                    <Label>Is Read Only?</Label>
                                </Toggle>
                            </Field> :
                            "No Change"
                        }
                    </div>
                </div>
                <div>
                    <h5 className={formCss.bulkEditHeader}>
                        Set Properties
                        <Field className={formCss.bulkEditHeaderToggle}>
                            <Toggle checked={editProperties}
                                    aria-label="Enable Set Properties"
                                    onChange={evt => setEditProperties(evt.target.checked)}>
                                <Label />
                            </Toggle>
                        </Field>
                    </h5>
                    <div className={formCss.bulkEditField}>
                        {editProperties ?
                            <UserFormDropdown
                                    label={"Properties"}
                                    required
                                    selection={properties?.filter(p => propertyIds.includes(p.id))}
                                    isMultiselect
                                    options={properties ?? []}
                                    error={undefined}
                                    onSelect={items => setPropertyIds(items.map((i: any) => i.id))}
                                    downshiftProps={{itemToString: (item: any) => item && item.id}}
                                    onBlur={() => {/* Do Nothing */}}
                            /> :
                            "No Change"
                        }
                    </div>
                </div>
                <div>
                    <h5 className={formCss.bulkEditHeader}>
                        Set Groups
                        <Field className={formCss.bulkEditHeaderToggle}>
                            <Toggle checked={editGroups}
                                    aria-label="Enable Set Groups"
                                    onChange={evt => setEditGroups(evt.target.checked)}>
                                <Label/>
                            </Toggle>
                        </Field>
                    </h5>
                    <div className={formCss.bulkEditField}>
                        {editGroups ? (groupsLoading ?
                                        <Inline size={24} color={COLORS.PRIMARY_500} aria-label="loading"/> :
                                        <UserFormDropdown
                                                label={"Groups"}
                                                required={false}
                                                selection={groups?.listUserGroups.filter(g => groupIds.includes(g.id)) ?? []}
                                                isMultiselect
                                                options={groups?.listUserGroups.map(g => ({
                                                    id: g.id,
                                                    name: g.name
                                                })) ?? []}
                                                error={undefined}
                                                onSelect={items => setGroupIds(items.map((i: any) => i.id))}
                                                downshiftProps={{itemToString: (item: any) => item && item.id}}
                                                onBlur={() => {/* Do Nothing */
                                                }}
                                        />) :
                                "No Change"
                        }
                    </div>
                </div>
            </form>
        </Body>
        <Footer>
            <FooterItem>
                <Button isBasic onClick={() => props.closed(false)}>Cancel</Button>
            </FooterItem>
            <FooterItem>
                <Button
                        isPrimary
                        disabled={!loading && !groupsLoading && (editProperties ? propertyIds.length === 0 : !editReadOnly && !editGroups)}
                        onClick={() => doSetUsers()}
                >Update</Button>
            </FooterItem>
        </Footer>
    </Modal>;
}