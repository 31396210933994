import { Tab, Tabs, TabList } from "@zendeskgarden/react-tabs";

import { ISetSelectedTab } from "../logic/useSummary";
import { TabNames } from "../logic/utils";

import { yearTabsRow } from "../../../styles/workflows/workflowCSS.module.scss";
import * as css from "../styles/OpDriverModeling.module.scss";


interface ISummaryTableTabs {
    selectedTab: TabNames;
    setSelectedTab: (args: ISetSelectedTab) => void;
}

const tabConfig = [
    { label: 'Rent', value: TabNames.rent },
    { label: 'Renewal Ratio', value: TabNames.renewalRatio },
    { label: 'Occupancy', value: TabNames.occupancy },
    { label: 'Renovations', value: TabNames.renovations },
];

export default function SummaryTableTabs({
    selectedTab,
    setSelectedTab,
}: ISummaryTableTabs): ReturnType<typeof TabList> {

    const handleChange = (tab: TabNames) => {
        setSelectedTab({ selectedTab: tab });
    };

    return (
        <Tabs className={yearTabsRow} selectedItem={selectedTab} onChange={handleChange}>
            <TabList className={css.summaryTabNav}>
                {tabConfig.map((each) => (
                    <Tab
                        key={each.value}
                        item={each.value}
                        className={css.summaryTab}
                    >
                    {each.label}
                    </Tab>
                ))}
            </TabList>
        </Tabs>
    );
}
