import { useState, useEffect } from "react";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
function useOperationLoadingState(data: any) {
    const [tableHasRendered, setTableHasRendered] = useState(false);

    useEffect(() => {
        if (!data?.data) return;
        if (data?.data?.actuals?.length > 0) setTableHasRendered(true);
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        return () => {};
    }, [data]);

    return {
        tableHasRendered
    };
}

export default useOperationLoadingState;
