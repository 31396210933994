import {VarianceType} from "../VarianceType";

export const formatter = new Intl.NumberFormat();

export function variance(newValue: number | null, oldValue: number | null): number | null {
    if (newValue === null && oldValue === null) {
        return 0;
    }

    if (newValue === null && oldValue !== null) {
        return -100;
    }

    if (newValue !== null && oldValue === null) {
        return 100;
    }

    if (newValue !== null && oldValue !== null) {
        return (newValue / oldValue - 1) * 100;
    }
    return 0;
}

export function varianceNew(oldValue: number, newValue: number): number | null {
    if (oldValue === 0 && newValue === 0) {
        return 0; // no change - 0 variance
    }
    if (oldValue === 0) {
        return null; // MVP-277
    }
    return (newValue - oldValue )/ Math.abs(oldValue);
}

export function growValue(value: number, variance: number): number {
    if (value < 0) {
        return value * (1 - variance);
    }

    return value * (1 + variance);
}

// sample
// F(AND(R[1]C[-2]=0,RC[-2]=0),0,IF(R[1]C[-2]=0,"n/a",(RC[-2]-R[1]C[-2])/ABS(R[1]C[-2])))
export function varianceSpreadJSFormula(oldValueCell: string, newValueCell: string): string {
    return `IF(AND(${oldValueCell}=0,${newValueCell}=0), 0, IF(${oldValueCell}=0,"n/a",(${newValueCell} - ${oldValueCell})/ABS(${oldValueCell})))`;
}

export function varianceAmount(first?: number | null, second?: number | null): number | null {
    if (first === null && second === null) {
        return null;
    }
    return (first ?? 0) - (second ?? 0);
}

export function between(low: number, high: number, val: number):boolean {
    return low <= val && val <= high;
}

export function calcVarianceNullSafe(oldValue: number, newValue: number): number | null {
    const calculated = varianceNew(oldValue, newValue);
    if (calculated !== null) {
        return calculated * 100;
    }
    return null;
}

export function varianceRoundVal(val: number, vt: VarianceType):number {
    return vt === VarianceType.VARIANCE_PERCENT ?
        Math.round(val * 10) / 10
        :
        Math.round(val);
}

export function varianceValToString(val: number | null, vt: VarianceType, negativeWParenthesis: boolean): string {
    if (val === null) {
        return 'n/a';
    }
    const pct = `${vt === VarianceType.VARIANCE_PERCENT && '%' || ''}`;
    return negativeWParenthesis ?
        `${val < 0 ? `(${formatter.format(varianceRoundVal(-val, vt))}${pct})` : `${formatter.format(varianceRoundVal(val, vt))}${pct}`}`
        :
        `${val < 0 ? `${formatter.format(varianceRoundVal(-val, vt))}${pct}` : `${formatter.format(varianceRoundVal(val, vt))}${pct}`}`
        ;
}

// A rounding function that works well for rounding money.
// Stackoverflow: https://stackoverflow.com/questions/11832914/how-to-round-to-at-most-2-decimal-places-if-necessary?page=1&tab=trending#answer-12830454
// round half away from zero
export function round(num: number, decimalPlaces:number): number {
    if(num < 0){
        return -round(-num, decimalPlaces);
    }
    const p = Math.pow(10, decimalPlaces);
    const n = num * p;
    const f = n - Math.floor(n);
    const e = Number.EPSILON * n;

    // Determine whether this fraction is a midpoint value.
    return (f >= .5 - e) ? Math.ceil(n) / p : Math.floor(n) / p;
}