import React, { ReactElement } from "react";

import useAppStore from "../../../hooks/useAppStore";

import { IconButton } from "@zendeskgarden/react-buttons";

import { ReactComponent as HamburgerMenu } from '@zendeskgarden/svg-icons/src/16/menu-stroke.svg';

export const toggleWorkflowsMenu = (appStore: ReturnType<typeof useAppStore>) => {
    appStore.set({ isSideBarVisible: !appStore.isSideBarVisible });
};

export default function WorkflowNavToggle():ReactElement {
    const appStore = useAppStore();
    return (
            <IconButton onClick={() => { toggleWorkflowsMenu(appStore); }}>
                <HamburgerMenu/>
            </IconButton>
    );
}
