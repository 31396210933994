/* eslint-disable @typescript-eslint/no-empty-function */
import React, { ReactElement, useEffect, useState } from "react";

import { Button } from "@zendeskgarden/react-buttons";

import { useHistory, useLocation } from "react-router-dom";

import * as styles from "../helpers/styles.module.scss";

export interface DashboardHeaderSection {
    sectionId: number,
    label: string,
    navLocation: string,
    icon?: React.FC<React.SVGProps<SVGSVGElement>>,
}

export interface DashboardHeaderProps {
    showingSection: number,
    sections: DashboardHeaderSection[],
    headerConfig?: DashboardHeaderConfig,
}

export interface DashboardHeaderConfig {
    prependNav?:ReactElement,
    headerRightSide?:ReactElement,
}

function RevenueDashboardHeader(props: DashboardHeaderProps): ReactElement {
    const history = useHistory();
    const location = useLocation();

    const headerRightSide:ReactElement = props.headerConfig?.headerRightSide ?? <></>;
    const prependNav:ReactElement = props.headerConfig?.prependNav ?? <></>;

    const performNavigation = (newLoc: string): void => {
        history.push(newLoc);
    };

    const getTabClassName = (sectionPath: string): string => {
        if(location.pathname == sectionPath){
            return styles.tabNavActiveButton;
        }
        return styles.tabNavButton;
    };

    return (
        <div className={styles.tabNavRow}>
            <span className={styles.tabNav}>
                {prependNav}
                {props.sections.map(section => {
                    return (<Button
                        isBasic
                        className={getTabClassName(section.navLocation)}
                        onClick={() => {
                            performNavigation(section.navLocation);
                        }}
                        disabled={location.pathname == section.navLocation}
                        key={section.sectionId}
                    >{section.icon && <Button.StartIcon>
                        <section.icon/>
                    </Button.StartIcon>}{`${section.label}`}</Button>);
                })}
            </span>
            <span className={`${styles.tabNav} ${styles.tabNavRight}`}>
                {headerRightSide}
            </span>
        </div>
    );
}

export default React.memo(RevenueDashboardHeader);
