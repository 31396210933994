import {HotTable} from "@handsontable/react";
import {ReactElement, useEffect, useRef, useState} from "react";
import * as tableCSS from "../../../components/account-table/styles/accountTable.module.scss";
import {LICENSES} from "../../../constants/Licenses";
import Handsontable from "handsontable";
import {toast} from "react-toastify";
import {hotValidUserActions, sanitizeTableInput} from "../../../components/account-table/logic/AccountTableHelpers";
import { TSimplifiedUnitType } from "../Renovations";
import { Property } from "../../../contexts/properties/PropertiesContext";
import { ForecastLocks, useUpdateFakeUnitStartingMarketRentMutation, useUpdateRealUnitStartingMarketRentMutation } from "../../../__generated__/generated_types";
import { TooltipModal } from "@zendeskgarden/react-modals";
import { formatterDollarUSNoDecimal } from "../../../utils/formatters";
import { ArrowRightAlt } from "@material-ui/icons";

export interface IMarketRentOverridesTableProps {
    property: Property,
    unitTypes: TSimplifiedUnitType[],
    forecastLocks: ForecastLocks,
}

type TSelectedOverride = {
    unitType: TSimplifiedUnitType | undefined,
    original: number | undefined,
    override: number | null,
    row: number,
    col: number,
}

export default function MarketRentOverridesTable({property, unitTypes, forecastLocks}: IMarketRentOverridesTableProps): ReactElement {
    const hotRef = useRef<HotTable>(null);
    const selectedOverride = useRef<TSelectedOverride>({
        unitType: undefined,
        original: undefined,
        override: null,
        row: 0,
        col: 0,
    });
    const [tooltipElement, setTooltipElement] = useState<HTMLTableCellElement | null>();
    const [updateFakeUnitMarketRents] = useUpdateFakeUnitStartingMarketRentMutation({
        ignoreResults: true,
        onCompleted: () => {
            toast.success("Market Rents Updated");
        }
    });
    const [updateRealUnitMarketRents] = useUpdateRealUnitStartingMarketRentMutation({
        ignoreResults: true,
        onCompleted: () => {
            toast.success("Market Rents Updated");

            if (hotRef.current?.hotInstance && selectedOverride.current.original != undefined) {
                hotRef.current.hotInstance.setDataAtCell(
                    selectedOverride.current.row,
                    selectedOverride.current.col,
                    selectedOverride.current.original,
                );
            }

            setTooltipElement(null);
            selectedOverride.current = {
                unitType: undefined,
                original: undefined,
                override: null,
                row: 0,
                col: 0,
            };
        }
    });
    const [rowData, setRowData] = useState<any[][]>([]);

    function updateMarketRent(unitType: TSimplifiedUnitType | undefined, newVal: number | undefined) {
        if (!unitType) {
            return;
        }

        if (unitType.isCustom) {
            updateFakeUnitMarketRents({
                variables: {
                    propertyId: property.id,
                    budgetYear: property.budgetYear,
                    marketRents: {
                        name: unitType.type,
                        startingMarketRent: String(newVal),
                    }
                }
            });
        } else {
            // since null is a valid input for overrides, sanitize to either string num representation or null
            const value = (newVal == undefined || newVal == null) ? null : String(newVal);

            updateRealUnitMarketRents({
                variables: {
                    propertyId: property.id,
                    budgetYear: property.budgetYear,
                    overrides: {
                        unitTypeId: unitType.id,
                        overrideStartingMarketRent: value == String(unitType.startingMarketRent) ? null : value,
                    }
                }
            });
        }
    }

    useEffect(() => {
        setRowData(unitTypes.map(x => [
            x.type,
            x.overrideMarketRent != undefined ? x.overrideMarketRent : x.startingMarketRent,
        ]));
    }, [unitTypes]);

    const columns: string[] = ["Unit Type", "Starting Market Rent"];

    const settings: Handsontable.GridSettings = {
        data: rowData,
        licenseKey: LICENSES.HandsOnTable,
        colHeaders: columns,
        selectionMode: "single",
        rowHeaders: false,
        width: "auto",
        height: "auto",
        readOnly: true,
        fixedColumnsLeft: 1,
        manualRowResize: false,
        manualColumnResize: false,
        autoColumnSize: false,
        autoRowSize: false,
        stretchH: "all",
        disableVisualSelection: ["header"],
        fillHandle: false,
        beforeOnCellMouseDown: function(event, coords) {
            if (coords.row < 0 || coords.col == 0) {
              event.stopImmediatePropagation();
            }
        },
        colWidths(index) {
            if (index == 0) {
                return 200;
            } else {
                return 100;
            }
        },
        afterGetColHeader: (_column, _th) => {
            if (!hotRef.current?.hotInstance) {
                return;
            }

            _th.className += ` ${tableCSS.cellBase} ${tableCSS.headerWithLabels} ${_column == 0 ? tableCSS.alignLeft : ""}`;
            _th.innerHTML = `
                <div class="${tableCSS.tableHeaderLightLabel}">
                    <span>${_column == 0 ? "&nbsp;" : "ACTUAL"}</span>
                    <span>&nbsp;</span>
                    <span>${_column == 0 ? "Unit Type" : "Starting Market Rent"}</span>
                </div>
            `;
        },
        cells(_row, _column) {
            this.className = "";
            this.className += ` ${tableCSS.cellBase}`;
            const unitType = unitTypes[_row];

            if (unitType) {
                if (_column == 1 && !(forecastLocks.reforecastLocked && forecastLocks.budgetLocked)) {
                    this.readOnly = false;
                    this.className += ` ${tableCSS.cellBorderBottom}`;

                    if (unitType.isCustom) {
                        this.className += ` ${tableCSS.cellColorBlue}`;
                    } else {
                        const cellValue = this.instance.getDataAtCell(_row, _column);

                        if (String(cellValue) == String(unitType.startingMarketRent)) {
                            this.className += ` ${tableCSS.cellColorGreen}`;
                        } else {
                            this.className += ` ${tableCSS.cellColorMagenta}`;
                        }
                    }
                }

                if (_column == 0) {
                    this.className += ` ${tableCSS.rowHeader}`;

                    if (unitType.isCustom) {
                        this.className += ` ${tableCSS.hasLabel} ${tableCSS.customUnitType}`;
                    }
                }

                // if row is the first one, with currency
                if (_column == 1) {
                    this.renderer = function (instance, td, row, col, prop, value, cellProperties) {
                        cellProperties.type = "numeric";
                        cellProperties.numericFormat = {
                            pattern: '$0,0.00',
                            culture: 'en-US'
                        };

                        if (value !== null && value !== undefined) {
                            td.innerText = String(Math.round(value));
                        }

                        Handsontable.renderers.NumericRenderer.apply(this, [instance, td, row, col, prop, value, cellProperties]);
                    };
                }
            }

            return this;
        },
        beforeChange: (changes) => {
            for (let i = 0; i < changes.length; i++) {
                const change = changes[i];

                if (!change) {
                    return;
                }

                change[3] = sanitizeTableInput(change[3]);
            }
        },
        afterChange: (changes, _source) => {
            const source = _source as string;

            if (!changes) {
                return;
            }

            const row = changes[0]?.[0];
            const col = changes[0]?.[1];
            const oldVal = changes[0]?.[2];
            const newVal = changes[0]?.[3];

            if (row == undefined || col == undefined || oldVal === undefined || newVal == undefined || String(oldVal) === String(newVal)) {
                return;
            }

            if (hotValidUserActions.includes(source)) {
                const unitType = unitTypes[row];

                if (!unitType) {
                    return;
                }

                updateMarketRent(unitType, newVal);
            }
        },
        afterOnCellContextMenu(_event, {row, col}, _td) {
            _event.preventDefault();

            if (!hotRef.current?.hotInstance || col != 1 || row < 0) {
                return;
            }

            const cellValue = hotRef.current.hotInstance.getDataAtCell(row, col);
            const unitType = unitTypes[row];

            if (!unitType || unitType.isCustom || cellValue == unitType.startingMarketRent) {
                return;
            }

            selectedOverride.current = {
                unitType,
                original: unitType.startingMarketRent,
                override: cellValue,
                row,
                col,
            };
            setTooltipElement(_td);
        },
        beforeUndoStackChange(_doneActions, source) {
            if (source && !hotValidUserActions.includes(source)) {
                return false;
            }
        },
    };

    return (
        <div className={tableCSS.accountTableWrapper}>
            <HotTable
                ref={hotRef}
                settings={settings}
                className={tableCSS.accountTable}
            />

            <TooltipModal
                referenceElement={tooltipElement}
                onClose={(event) => {
                    if ("button" in event && event.button != 2) {
                        setTooltipElement(null);

                        selectedOverride.current = {
                            unitType: undefined,
                            original: undefined,
                            override: null,
                            row: 0,
                            col: 0,
                        };
                    }
                }}
                hasArrow={false}
                popperModifiers={[
                    {
                        name: 'flip',
                        options: {
                        boundary: 'viewport'
                        }
                    },
                    {
                        name: 'preventOverflow',
                        options: {
                        boundary: 'viewport'
                        }
                    }
                ]}
            >
                <TooltipModal.Body>
                    <div onClick={() => updateMarketRent(selectedOverride.current.unitType, undefined)}>
                        Revert override (fx= {formatterDollarUSNoDecimal.format(selectedOverride.current.override ?? 0)} <ArrowRightAlt /> {formatterDollarUSNoDecimal.format(selectedOverride.current.original ?? 0)})
                    </div>
                </TooltipModal.Body>
            </TooltipModal>
        </div>
    );
}
