export enum OpDriverMetricSummaryType {
    AVERAGE,
    TOTAL,
    WEIGHTED_AVERAGE,
}

export enum OpDriverPropertyEditLevel {
    UNIT,
    PROPERTY_ONLY,
    PROPERTY_UNITS,
}

export enum OpDriverMetricType {
    AVG_MARKET_RENTS,
    EXPIRATIONS,
    RENEWAL_RATIO,
    RENEWAL_INCREASE,
    MOVE_OUT_RATES,
    EARLY_TERMINATIONS,
    MONTH_TO_MONTH_MOVE_OUTS,
    OCCUPANCY_RATES,
}

export const METRIC_TYPE_TO_METRIC_NAME: Record<OpDriverMetricType, string> = {
    [OpDriverMetricType.AVG_MARKET_RENTS]: "averageMarketRent",
    [OpDriverMetricType.EXPIRATIONS]: "leaseExpirationCount",
    [OpDriverMetricType.RENEWAL_RATIO]: "renewalRatio",
    [OpDriverMetricType.RENEWAL_INCREASE]: "renewalRate",
    [OpDriverMetricType.MOVE_OUT_RATES]: "moveOutRate",
    [OpDriverMetricType.EARLY_TERMINATIONS]: "earlyTerminationCount",
    [OpDriverMetricType.MONTH_TO_MONTH_MOVE_OUTS]: "monthToMonthMoveOutCount",
    [OpDriverMetricType.OCCUPANCY_RATES]: "occupancyRate"
}