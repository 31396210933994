import { formatterDollarUSNoDecimal } from "../../../../../../../../utils/fotmatters";

function padLeft(input: string|number, padWithChar: string, targetLength: number): string {
    let pad = padWithChar;
    for (let i = 0; i < targetLength; i++) {
        pad = `${padWithChar}${pad}`;
    }
    const inputStr = typeof input === "number" ? input.toString() : input;
    return (pad + inputStr).slice(-targetLength);
}

export function formatMoneySafeNoDecimal(money: number | null): string {
    if (money == null) {
        return "-";
    }

    return formatterDollarUSNoDecimal.format(money);
}