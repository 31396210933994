import { ReactElement } from "react";
import css from "../styles/css.module.scss";
import cn from "classnames";
import { IMetricBlock } from "../pdf/pdfExportHelpers";


export default function MetricBlock({
    title,
    isSmallTitle = false,
    values,
    text,
}: IMetricBlock): ReactElement {

    return (
        <div className={css.metricBlock}>
            <h2 className={cn(css.title, isSmallTitle && css.titleSmall)}>{title}</h2>
            <div className={css.metricBlockValues}>
                {values && values.map((v, i) => {
                    return (
                        <div key={i}>
                            <div className={css.metricBlockTopLabel}>{v.topLabel}</div>
                            <div className={css.metricBlockValue}>{v.value}</div>
                            <div className={css.metricBlockBottomLabel}>{v.bottomLabel}</div>
                        </div>
                    );
                })}
            </div>
            <div className={css.metricBlockText}>{text}</div>
        </div>
    );
}
