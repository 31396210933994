/**
 * Expects a 1-based month index (1 = January) and returns a 0-based equivalent. If the incoming string can't be
 * parsed to an int, or it doesn't land in the 0-11 range, 0 is returned;
 * @param monthParam
 */
export const parseMonthParam = (monthParam: string): [number, boolean] => {
    const parsedMonth = parseInt(monthParam);

    let useMonth:number;
    let parseOK = false;

    if(isNaN(parsedMonth)){
        useMonth = new Date().getMonth();
    } else if(parsedMonth - 1 > -1 && parsedMonth - 1 < 12){
        useMonth = parsedMonth - 1; // Month params are 1-based from the user perspective, but 0-based internally
        parseOK = true;
    } else {
        useMonth = 0; // January
    }

    return [useMonth, parseOK];
};

export const parseYearParam = (yearParam: string, bounds?: { minYear?: number, maxYear?: number }): number|undefined => {
    let parsedYear = parseInt(yearParam);

    if(!isNaN(parsedYear) && parsedYear > -1){
        if(bounds?.minYear !== undefined && parsedYear < bounds.minYear){
            parsedYear = bounds.minYear;
        }
        else if(bounds?.maxYear !== undefined && parsedYear > bounds.maxYear){
            parsedYear = bounds.maxYear;
        }

        return parsedYear;
    }
    return;
};