import { ReactElement } from "react";
import { BudgetComponentType } from "../../../../../../../../__generated__/generated_types";
import { formatterDollarUSNoSign, formatterPercent } from "../../../../../../../../utils/formatters";
import { MONTHS_LONG } from "../../../../../../../../constants/Months";
import { getVarianceColor } from "../../../../../../../../utils/variance";
import * as css from "../styles/insights.module.scss";
import { IVarianceMetric } from "../logic/accountVarianceInsights";

interface IMonthlyVarianceProps {
    varianceInfo: IVarianceMetric,
    accountType: BudgetComponentType,
}

export default function AccountVarianceMetric(props: IMonthlyVarianceProps): ReactElement {
    const accountTypeLabel = props.accountType === BudgetComponentType.Income ? 'Revenue' : 'Costs';
    const {
        year,
        monthIndex,
        varianceAmount: varianceAmountRaw,
        variancePercentage,
        value
    } = props.varianceInfo;

    const varianceAmount = formatterDollarUSNoSign.format(varianceAmountRaw);
    const percent = formatterPercent.format(variancePercentage);

    const comparedTo = <>compared to {MONTHS_LONG[monthIndex]} {year} ({formatterDollarUSNoSign.format(value)})</>;

    /**
     * Note: We pass a static `false` as the `negatedAtComponent` argument to getVarianceColor because the
     * account's negate status is applied on the server side for this view, therefore it should not be negated here
     */
    const changeColor = getVarianceColor(varianceAmountRaw, props.accountType, false);

    if(varianceAmountRaw > 0){
        return <>
            {accountTypeLabel} increased by <span style={{color: changeColor}} className={css.changeValue}>{varianceAmount} ({percent})</span> {comparedTo}
        </>;
    } else if(varianceAmountRaw < 0){
        return <>
            {accountTypeLabel} decreased by <span style={{color: changeColor}} className={css.changeValue}>{varianceAmount} ({percent})</span> {comparedTo}
        </>;
    } else {
        return <>{accountTypeLabel} did not change {comparedTo}</>;
    }
}