import { ReactElement, useEffect, useRef, useState } from "react";
import { StateChangeOptions } from "downshift";
import { Autocomplete, Dropdown, Field, Item, Menu, Separator, Trigger } from "@zendeskgarden/react-dropdowns";
import { TooltipModal } from "@zendeskgarden/react-modals";

import { TDriverMetricsYear } from "../../../../../../contexts/account/data/logic/driversAndWorksheetData";
import useFormulaBarMenu, { driverMetricsMap, DriverMetricsMapObject, DriverMetricsTypes } from "../../logic/useFormulaBarMenu";
import { TAccountFormulaNodeProps } from "../formula-nodes/AccountDriverFormulaNode";
import { IFormulaBarUpdates } from "../../../../../../contexts/account/data/useFormulaBarUpdates";
import { TAllAvailableDrivers } from "../../../../../../contexts/account/data/utils";
import { getPopperModifiers, getTooltipPlacementProps } from "../../logic/formulaBarMenu";

import { LineItemsMenu } from "./LineItemsMenu";
import { PayrollMenu } from "./PayrollMenu";
import PercentGrowthMenu from "./PercentGrowthMenu";
import MonthlyAverageMenu from "./MonthlyAverageMenu";
import AnnualTargetValueMenu from "./AnnualTargetValueMenu";
import * as css from "./styles/css.module.scss";
import { TOperationalFormulaNodeProps } from "../formula-nodes/OperationalDriverFxNode";
import { ModelingMethodName } from "../../logic/utils";
import { VersionType } from "../../../../../../__generated__/generated_types";
import { Button } from "@zendeskgarden/react-buttons";
import { AccountDriverFormulaManyModal } from "../formula-nodes/account-driver-formula-many-modal/AccountDriverFormulaManyModal";


interface MenuState {
    isOpen: boolean,
    selectedDriverMetric: keyof TDriverMetricsYear | undefined;
    selectedItem: string | undefined;
}

type TAccLookbackRefObj = {
    driver: "account",
    refObject: TAccountFormulaNodeProps["driverAccount"],
}

type TOpLookbackRefObj = {
    driver: "operational",
    refObject: TOperationalFormulaNodeProps["driverAccount"],
}

export type TLookbackRefObject = TAccLookbackRefObj | TOpLookbackRefObj;

const INITIAL_MENU_STATE = {
    isOpen: false,
    selectedDriverMetric: undefined,
    selectedItem: undefined,
};

// Whole formula bar needs rewrite and decomplication as it becomes hardly maintainable
type TFormulaBarMenuProps = {
    accountId?: string | undefined,
    budgetYear?: number | undefined;
    allAvailableDrivers?: TAllAvailableDrivers,
    className?: string,
    fbUpdates: IFormulaBarUpdates,
    isBulkUpdateBar?: boolean,
    isPropertyDriversUI?: boolean,
    isWorksheetDriven?: boolean,
    lookbackRefObject?: TLookbackRefObject,
    selectedAccountIds: string[],
    triggerNode: ReactElement | string,
    worksheetTypeAhead?: string[] | undefined,
    editableFxBarChecker: ((modelingMethod: ModelingMethodName) => boolean) | undefined,
    versionType?: VersionType.Budget | VersionType.Reforecast,
    currentPropertyId?: string | undefined,
    initialSelectedItem?: string | undefined
}


export default function FormulaBarMenu(props:TFormulaBarMenuProps):ReactElement {

    const fbMenu = useFormulaBarMenu({
        allDrivers: props.allAvailableDrivers,
        fbUpdates: props.fbUpdates,
        isBulkUpdateBar: props.isBulkUpdateBar,
        isWorksheetDriven: props.isWorksheetDriven,
        editableFxBarChecker: props.editableFxBarChecker,
        selectedAccountIds: props.selectedAccountIds,
    });

    const [menuState, setMenuState] = useState<MenuState>({
        ...INITIAL_MENU_STATE,
        ...(props.initialSelectedItem ?
            {
                selectedItem: props.initialSelectedItem
            }
            :
            {}
        )
    });
    const triggerRef = useRef<HTMLDivElement>(null);
    const [refElement, setRefElement] = useState<HTMLDivElement|null>(null);
    const [inputValue, setInputValue] = useState<string>("");
    const [menuItems, setMenuItems] = useState<DriverMetricsMapObject[]>([]);
    const [filteredMenuItems, setFilteredMenuItems] = useState<DriverMetricsMapObject[]>([]);

    /**
     * Watches props.fbUpdates.pendingUpdates because deleted drivers will change
     * the nature of the menu items
     * Ex: Deleting a revenue driver will need to set its disabled flag in the menu to false
     */
    useEffect(() => {
        if (driverMetricsMap !== undefined) {
            const menuItems = fbMenu.getMenuItems({
                selectedMenuItem: menuState.selectedItem,
                selectedDriverMetric: menuState.selectedDriverMetric,
                driverMetricsMap,
                lookbackRefObject: props.lookbackRefObject,
            });
            setMenuItems(menuItems);
            setFilteredMenuItems(menuItems);
        }
    }, [
        props.fbUpdates.pendingUpdates?.remove,
        driverMetricsMap,
        refElement,
        menuState.selectedItem,
    ]);

    useEffect(() => {
        if (menuState.selectedDriverMetric === DriverMetricsTypes.monthlyAverage) {
            props.fbUpdates.addMonthlyAverageGrowthDriver();
        } else if (menuState.selectedDriverMetric === DriverMetricsTypes.annualTargetValue) {
            props.fbUpdates.addAnnualTargetValueGrowthDriver();
        }
    }, [menuState.selectedDriverMetric]);

    // The header is a non-clickable, static text separated from the menu body
    const renderMenuHeader = () => (
        <>
            <div
                style={inlineStyles.header}
            >
                {menuState.selectedDriverMetric
                    ? fbMenu.getDisplayNameForMetric(menuState.selectedDriverMetric)
                    : "Add modeling method"
                }
            </div>
            <Separator style={inlineStyles.separator}/>
        </>
    );

    const renderMenuItems = () => {
        const renderMenuItemValue = (
            value: string,
            disabled: boolean,
            disabledMessage: string,
        ): ReactElement | string => {
            if (disabled) {
                return (
                    <div className={css.disabledItem}>
                        <div className={css.truncatedItem}>
                            {value}
                        </div>
                        <div className={css.alreadyAppliedText}>
                            {disabledMessage}
                        </div>
                    </div>
                );
            }
            return value;
        };

        return filteredMenuItems.map(
            (each) =>
                each.isStatic ? (
                    <Item
                        value={each.name}
                        key={each.name}
                        disabled
                        className={`${css.truncatedItem} ${css.staticItem}`}
                        style={inlineStyles.item(each.depth)}
                    >
                        {each.value}
                    </Item>
                ) : (
                    <Item
                        value={each.name}
                        key={each.name}
                        disabled={each.disabled}
                        className={`${css.truncatedItem} ${css.menuItem}`}
                        style={inlineStyles.item(each.depth)}
                    >
                        {renderMenuItemValue(each.value, each.disabled, each.disabledMessage)}
                    </Item>
                )
        );
    };

    const handleStateChange = (changes: StateChangeOptions<any>) => {
        const updatedState = {} as MenuState;
        const keysInChanges = Object.keys(changes);

        /**
         * Since this is a nested menu rendered inside a popup, the popup needs to be open if the user
         * clicked on a parent menu item so the UI can render its sub-menu.
         * The 'operational' check enforces just that - if the user clicked on the operational metric
         * menu item, then the menu popup shouldn't close - it should remain open to render the
         * operational metric drivers menu
         *
         * TODO: Move this check to a better place as we start supporting more nested menu items
        */
        if (keysInChanges.includes('isOpen')) {
            updatedState.isOpen = Object.keys(driverMetricsMap).includes(changes.selectedItem)
                || changes.isOpen
                || false;
        }

        if (keysInChanges.includes('selectedItem')) {
            // If no item was previously selected, then the user is in the root
            if (menuState.selectedItem === undefined) {
                updatedState.selectedDriverMetric = changes.selectedItem;
            } else {
                updatedState.selectedDriverMetric = menuState.selectedDriverMetric;
            }

            // If a menu item was selected, then handle that
            // This Zendesk component checks which menu item is currently selected, and renders
            // menu items based on that. So when the junction node is clicked, there's nothing selected,
            // and hence we render the plus menu
            updatedState.selectedItem = changes.selectedItem;

            if (props.lookbackRefObject) {
                fbMenu.handleLookbackMenuItemClick({
                    lookbackRefObject: props.lookbackRefObject,
                    selectedItem: updatedState.selectedItem,
                });
            } else if (updatedState.selectedDriverMetric) {
                fbMenu.handleMenuItemClick({
                    selectedMetric: updatedState.selectedDriverMetric,
                    prevSelectedItem: menuState.selectedItem,
                    selectedItem: updatedState.selectedItem,
                });
            }

            if (updatedState.selectedItem !== undefined && !Object.keys(driverMetricsMap).includes(updatedState.selectedItem)) {
                /**
                 * Once a driver was selected, close the menu
                 *
                 * Note:
                 *  This conditional logic will grow as we support more metrics
                */
                handleClose();

                return;
            }
        }

        if (Object.keys(updatedState).length > 0) {
            setMenuState(updatedState);
        }
    };

    const handleInputValueChange = (value: string) => {
        if (menuState.selectedDriverMetric) {
            const filteredMenuItems = value.length === 0 ? menuItems : fbMenu.getFilteredMenuItems(menuState.selectedDriverMetric, value);
            setInputValue(value);
            setFilteredMenuItems(filteredMenuItems);
        }
    };

    const handleClick = () => {
        setRefElement(triggerRef.current);
    };

    const handleClose = () => {
        setRefElement(null);
        setMenuState(INITIAL_MENU_STATE);
        setInputValue("");
    };

    const isMenuSearchable = menuState.selectedDriverMetric && driverMetricsMap[menuState.selectedDriverMetric]?.isSearchable || false;

    const getMenuStyles = () => {
        if (menuState.selectedDriverMetric) {
            return inlineStyles.menu;
        }
        if (props.lookbackRefObject) {
            return inlineStyles.rootMenu;
        }
        return inlineStyles.rootMenuWide;
    };

    const renderMenuByMetricType = () => {
        switch (menuState.selectedDriverMetric) {
            case "payroll": {
                return (
                    <PayrollMenu
                        allDrivers={props.allAvailableDrivers}
                        fbUpdates={props.fbUpdates}
                        level={0}
                    />
                );
            }
            case "worksheet": {
                return (props.accountId && props.budgetYear)
                    ? (
                        <LineItemsMenu
                            accountId={props.accountId}
                            budgetYear={props.budgetYear}
                            fbUpdates={props.fbUpdates}
                            worksheetTypeAhead={props.worksheetTypeAhead}
                        />
                    )
                    : <></>;
            }
            case "monthlyAverage": {
                return (
                    <MonthlyAverageMenu
                        fbUpdates={props.fbUpdates}
                        level={0}
                        handleCloseParent={handleClose}
                    />
                );
            }
            case "percentGrowth": {
                if (props.versionType) {
                    return (
                        <PercentGrowthMenu
                            fbUpdates={props.fbUpdates}
                            level={0}
                            handleCloseParent={handleClose}
                            versionType={props.versionType}
                        />
                    );
                }
                return <></>;
            }
            case "annualTargetValue": {
                return (
                    <AnnualTargetValueMenu
                        fbUpdates={props.fbUpdates}
                        level={0}
                        handleCloseParent={handleClose}
                    />
                );
            }
            default: {
                return (
                    <Dropdown
                        isOpen
                        onSelect={item => {
                            setMenuState({...menuState, selectedItem: item});
                        }}
                        onStateChange={(changes) => handleStateChange(changes)}
                        inputValue={inputValue}
                        onInputValueChange={handleInputValueChange}
                    >
                        {!isMenuSearchable && (
                            <Trigger>
                                <div></div>
                            </Trigger>
                        )}

                        <div className={css.menuWrapper}>
                            {/* Don't render the header if in lookback period menu */}
                            {!props.lookbackRefObject
                                ? renderMenuHeader()
                                : <></>
                            }
                            {isMenuSearchable && (
                                <Field>
                                    <Autocomplete className={css.autoComplete} style={inlineStyles.autocomplete}>
                                    </Autocomplete>
                                </Field>
                            )}
                            <Menu placement="bottom" className={css.menu} style={getMenuStyles()}>
                                {renderMenuItems()}
                            </Menu>
                        </div>
                    </Dropdown>
                );
            }
        }
    };

    return (
        menuState.selectedItem != "account" ?
        <>
            <div
                className={css.triggerNode}
                ref={triggerRef}
                onClick={handleClick}
            >
                {props.triggerNode}
            </div>
            <TooltipModal
                className={css.tooltipModal}
                hasArrow={false}
                referenceElement={refElement}
                onClose={handleClose}
                {...getTooltipPlacementProps(props.isPropertyDriversUI, props.isBulkUpdateBar)}
                popperModifiers={getPopperModifiers(props.isPropertyDriversUI, props.isBulkUpdateBar)}
                isAnimated
            >
                <TooltipModal.Body className={css.tooltipModalBody}>
                    {renderMenuByMetricType()}
                </TooltipModal.Body>
            </TooltipModal>
        </>
        :
        props.versionType != undefined && props.budgetYear != undefined && props.currentPropertyId != undefined ?
        <AccountDriverFormulaManyModal
            accountIds={props.isBulkUpdateBar ? props.selectedAccountIds : [props.accountId ?? ""]}
            propertyId={props.currentPropertyId}
            versionType={props.versionType}
            year={props.versionType == "REFORECAST" ? props.budgetYear - 1 : props.budgetYear}
            drivers={props.fbUpdates.parsedFormDrivers.account.map(a => ({
                accountId: a.accountId,
                glName: a.glName,
                glNumber: a.glNumber ?? "",
                lookbackPeriod: a.lookbackPeriod ?? 0
            }))}
            augments={props.fbUpdates.parsedFormDrivers.accountPercentageAugment}
            onClose={handleClose}
            onConfirm={(updates, augments) => {
                props.fbUpdates.setAccountManyDrivers({ payload: updates });
                props.fbUpdates.setAccountAugments(augments);
                handleClose();
            }}
        />
        :
        <></>
    );
}

const rootMenuStyles = {marginTop: "30px", position: "relative" as 'relative', transform: "none", width: "168px"};

const inlineStyles = ({
    autocomplete: {boxShadow: "none", margin: "12px", width: "calc(100% - 24px)"},
    header: {color: "#2f3941", fontWeight: 500, margin: "0", padding: "12px"},
    item: (depth: number) => ({paddingLeft: `${36 + depth * 24}px`}),
    menu: {marginTop: "30px", position: "relative" as 'relative', transform: "none", width: "auto"},
    rootMenu: rootMenuStyles,
    rootMenuWide: {...rootMenuStyles, width: "235px"},
    separator: {margin: "0"},
});
