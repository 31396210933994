import { Body, Cell, Head, HeaderCell, HeaderRow, Row, Table } from "@zendeskgarden/react-tables";
import { ReactElement, useEffect, useState } from "react";
import { GetLeaseExpirationCountsForRewriteQuery, useGetLeaseExpirationCountsForRewriteQuery, useMassUpdateLeaseExpirationCountsForPropertyAndPeriodMutation, VersionType } from "../../../../__generated__/generated_types";
import { Checkbox, Field, Input, Label } from "@zendeskgarden/react-forms";
import * as css from "./leaseExpirationsRewrite.module.scss";
import { Button } from "@zendeskgarden/react-buttons";
import { toast } from "react-toastify";
import { Inline } from "@zendeskgarden/react-loaders";
import { COLORS } from "../../../../constants/Colors";


interface LeaseExpirationsRewriteViewProps {
    propertyId: string,
    valuesStartFromIndex: number,
    versionType: VersionType,
    onCancel: () => void,
    onApply: () => void
}

type TUnitTypeData = {
    unitTypeId: string;
    unitTypeName: string;
    pmsValues: (number | null)[];
    currentForecastedValues: (number | null)[];
    newForecastedValues: (number | null)[];
    useNewForecasedValues: boolean;
}

function buildUnitTypesData(rawData: GetLeaseExpirationCountsForRewriteQuery, valuesStartFromIndex: number): TUnitTypeData[] {
    const forecastedDataByUnitTypeId = rawData.queryForecastedLeaseExpirationCountsForPropertyAndPeriod.toIdMap("unitTypeId");
    const ret = [] as TUnitTypeData[];
    for (const pmsDataRow of rawData.queryPMSLeaseExpirationCountsForPropertyAndPeriod) {
        const forecastedDataRow = forecastedDataByUnitTypeId[pmsDataRow.unitTypeId];
        const forecastedValues = forecastedDataRow ? forecastedDataRow.values : new Array(12).fill(null);
        ret.push({
            unitTypeId: pmsDataRow.unitTypeId,
            unitTypeName: pmsDataRow.unitTypeName,
            pmsValues: pmsDataRow.values.map((v, i) => i >= valuesStartFromIndex ? v : null),
            currentForecastedValues: forecastedValues.map((v, i) => i >= valuesStartFromIndex ? v : null),
            newForecastedValues: pmsDataRow.values.map((v, i) => i >= valuesStartFromIndex ? v : null),
            useNewForecasedValues: true
        });
    }

    return ret;
}

export function LeaseExpirationsRewriteView(props: LeaseExpirationsRewriteViewProps): ReactElement {
    const {data: expirationCountsRawData, loading: expirationCountsRawLoading} = useGetLeaseExpirationCountsForRewriteQuery({
        variables: {
            propertyId: props.propertyId,
            versionType: props.versionType
        },
        fetchPolicy: "no-cache"
    });
    const [saveChanges, {loading: isSavingChanges}] = useMassUpdateLeaseExpirationCountsForPropertyAndPeriodMutation({
        notifyOnNetworkStatusChange: true,
        onCompleted: data => {
            if (data?.massUpdateLeaseExpirationCountsForPropertyAndPeriod === true) {
                toast.success(`Updated Successfully`);
            }
            else {
                toast.error(`Failed To Update`);
            }
            props.onApply();
        },
    });

    const [unitTypesData, setUnitTypesData] = useState<TUnitTypeData[]>();

    useEffect(() => {
        if (!expirationCountsRawData || expirationCountsRawLoading) {
            return;
        }

        const unitTypesData = buildUnitTypesData(expirationCountsRawData, props.valuesStartFromIndex);
        setUnitTypesData(unitTypesData.sortBy("unitTypeName"));
    }, [expirationCountsRawData, expirationCountsRawLoading]);

    function onValueChange(unitTypeId: string, valueIndex: number, newVal: string) {
        if (!unitTypesData) {
            return;
        }
        const updated: TUnitTypeData[] = [];
        for (const row of unitTypesData) {
            if (row.unitTypeId != unitTypeId) {
                updated.push(row);
            }
            else {
                updated.push({
                    unitTypeId: row.unitTypeId,
                    unitTypeName: row.unitTypeName,
                    pmsValues: row.pmsValues,
                    currentForecastedValues: row.currentForecastedValues,
                    newForecastedValues: row.newForecastedValues.map((value, index) => {
                        if (index != valueIndex) {
                            return value;
                        }
                        let numVal: number | null = null;
                        if (newVal.trim().length > 0) {
                            numVal = +newVal;
                            if (Number.isNaN(numVal)) {
                                numVal = null;
                            }
                        }
                        return numVal;
                    }),
                    useNewForecasedValues: row.useNewForecasedValues,
                });
            }
        }
        setUnitTypesData(updated);
    }

    function handleSelect(unitTypeId: string, newVal: boolean) {
        if (!unitTypesData) {
            return;
        }
        const updated: TUnitTypeData[] = [];
        for (const row of unitTypesData) {
            if (row.unitTypeId != unitTypeId) {
                updated.push(row);
            }
            else {
                updated.push({
                    unitTypeId: row.unitTypeId,
                    unitTypeName: row.unitTypeName,
                    pmsValues: row.pmsValues,
                    currentForecastedValues: row.currentForecastedValues,
                    newForecastedValues: row.newForecastedValues,
                    useNewForecasedValues: newVal,
                });
            }
        }
        setUnitTypesData(updated);
    }

    function handleUsePMSValues(unitTypeId: string) {
        if (!unitTypesData) {
            return;
        }
        const updated: TUnitTypeData[] = [];
        for (const row of unitTypesData) {
            if (row.unitTypeId != unitTypeId) {
                updated.push(row);
            }
            else {
                updated.push({
                    unitTypeId: row.unitTypeId,
                    unitTypeName: row.unitTypeName,
                    pmsValues: row.pmsValues,
                    currentForecastedValues: row.currentForecastedValues,
                    newForecastedValues: [...row.pmsValues],
                    useNewForecasedValues: row.useNewForecasedValues,
                });
            }
        }
        setUnitTypesData(updated);
    }

    function handleUseCurrentValues(unitTypeId: string) {
        if (!unitTypesData) {
            return;
        }
        const updated: TUnitTypeData[] = [];
        for (const row of unitTypesData) {
            if (row.unitTypeId != unitTypeId) {
                updated.push(row);
            }
            else {
                updated.push({
                    unitTypeId: row.unitTypeId,
                    unitTypeName: row.unitTypeName,
                    pmsValues: row.pmsValues,
                    currentForecastedValues: row.currentForecastedValues,
                    newForecastedValues: [...row.currentForecastedValues],
                    useNewForecasedValues: row.useNewForecasedValues,
                });
            }
        }
        setUnitTypesData(updated);
    }

    function handleCancel() {
        props.onCancel();
    }

    function handleApply() {
        if (!unitTypesData) {
            props.onCancel();
            return;
        }
        const updates = unitTypesData.map(row => ({
            unitTypeId: row.unitTypeId,
            values: [...row.newForecastedValues]
        }));
        if (updates.length == 0) {
            props.onCancel();
        }
        saveChanges({
            variables: {
                propertyId: props.propertyId,
                versionType: props.versionType,
                updates: updates
            }
        });
    }

    return (
    <>
    <div className={css.tableWrapper}>
        <Table className={css.valuesTable} isReadOnly={true}>
            <Head isSticky={true}>
                <HeaderRow>
                    <HeaderCell className={css.valueColumn}></HeaderCell>
                    <HeaderCell className={css.valueColumn}>Jan</HeaderCell>
                    <HeaderCell className={css.valueColumn}>Feb</HeaderCell>
                    <HeaderCell className={css.valueColumn}>Mar</HeaderCell>
                    <HeaderCell className={css.valueColumn}>Apr</HeaderCell>
                    <HeaderCell className={css.valueColumn}>May</HeaderCell>
                    <HeaderCell className={css.valueColumn}>Jun</HeaderCell>
                    <HeaderCell className={css.valueColumn}>Jul</HeaderCell>
                    <HeaderCell className={css.valueColumn}>Aug</HeaderCell>
                    <HeaderCell className={css.valueColumn}>Sep</HeaderCell>
                    <HeaderCell className={css.valueColumn}>Oct</HeaderCell>
                    <HeaderCell className={css.valueColumn}>Nov</HeaderCell>
                    <HeaderCell className={css.valueColumn}>Dec</HeaderCell>
                    <HeaderCell className={css.valueColumn}>Total</HeaderCell>
                </HeaderRow>
            </Head>
            <Body>
                {expirationCountsRawLoading
                &&
                <Row>
                    <Cell colSpan={14}>
                        Loading
                    </Cell>
                </Row>
                }
                {!expirationCountsRawLoading
                && unitTypesData
                && unitTypesData.length == 0
                &&
                <Row>
                    <Cell colSpan={14}>
                        No PMS data found
                    </Cell>
                </Row>
                }
                {!expirationCountsRawLoading
                && unitTypesData
                && unitTypesData.length > 0
                &&
                unitTypesData.map(row => (
                    <>
                        <Row key={row.unitTypeId + "unit-type"}>
                            <Cell colSpan={13} className={css.unitTypeName}>{row.unitTypeName}</Cell>
                        </Row>
                        <Row key={row.unitTypeId + "pms"}>
                            {
                                <>
                                <Cell>
                                    <Button
                                        onClick={() => handleUsePMSValues(row.unitTypeId)}
                                        isBasic
                                        size="small"
                                        disabled={!row.useNewForecasedValues}
                                    >
                                        Use PMS
                                    </Button>
                                </Cell>
                                {
                                row.pmsValues.map((v, index) => (
                                    <Cell
                                        key={index}
                                        className={index >= props.valuesStartFromIndex ? undefined : css.notavailable}
                                    >
                                        {index >= props.valuesStartFromIndex ? v : ""}
                                    </Cell>
                                ))
                                }
                                <Cell key="total">
                                    {row.pmsValues.sum()}
                                </Cell>
                                </>
                            }
                        </Row>
                        <Row key={row.unitTypeId + "current-forecasted"}>
                            {
                                <>
                                <Cell>
                                    <Button
                                        onClick={() => handleUseCurrentValues(row.unitTypeId)}
                                        isBasic
                                        size="small"
                                        disabled={!row.useNewForecasedValues}
                                    >
                                        Use Existing
                                    </Button>
                                </Cell>
                                {
                                row.currentForecastedValues.map((v, index) => (
                                    <Cell
                                        key={index}
                                        className={index >= props.valuesStartFromIndex ? undefined : css.notavailable}
                                    >
                                        {index >= props.valuesStartFromIndex ? v : ""}
                                    </Cell>
                                ))
                                }
                                <Cell key="total">
                                    {row.currentForecastedValues.sum()}
                                </Cell>
                                </>
                            }
                        </Row>
                        {row.useNewForecasedValues ?
                        <Row key={row.unitTypeId + "new-forecasted"}>
                            {
                                <>
                                <Cell>
                                    <Field>
                                        <Checkbox
                                            className={css.checkbox}
                                            checked={row.useNewForecasedValues}
                                            onChange={() => {
                                                handleSelect(row.unitTypeId, !row.useNewForecasedValues);
                                            }}
                                        >
                                            <Label className={css.checkbox} />
                                        </Checkbox>
                                    </Field>
                                </Cell>
                                {
                                row.newForecastedValues.map((v, index) => (
                                    <Cell
                                        key={index}
                                        className={index >= props.valuesStartFromIndex ? undefined : css.notavailable}
                                    >
                                        {index >= props.valuesStartFromIndex ?
                                        <Input
                                            isBare
                                            className={css.valueInput}
                                            value={v == null ? "" : v.toString()}
                                            onChange={e => onValueChange(row.unitTypeId, index, e.target.value)}
                                        />
                                        :
                                        ""
                                        }
                                    </Cell>
                                ))
                                }
                                <Cell key="total">
                                    {row.newForecastedValues.sum()}
                                </Cell>
                                </>
                            }
                        </Row>
                        :
                        <Row key={row.unitTypeId + "new-forecasted-keep"}>
                                <Cell>
                                    <Field>
                                        <Checkbox
                                            className={css.checkbox}
                                            checked={row.useNewForecasedValues}
                                            onChange={() => {
                                                handleSelect(row.unitTypeId, !row.useNewForecasedValues);
                                            }}
                                        >
                                            <Label className={css.checkbox} />
                                        </Checkbox>
                                    </Field>
                                </Cell>
                                <Cell colSpan={13} className={css.keepCurrent}>Keep current assumptions</Cell>
                        </Row>
                        }
                    </>
                ))
                }
            </Body>
        </Table>
    </div>
    <div className={css.footerWrapper}>
        <div className={css.buttons}>
            <Button onClick={() => handleCancel()} isBasic>
                Cancel
            </Button>
            <Button
                onClick={() => handleApply()}
                isPrimary
                disabled={unitTypesData && unitTypesData.filter(row => row.useNewForecasedValues).length == 0 || isSavingChanges}
            >
                {isSavingChanges
                ? <Inline size={24} color={COLORS.POSITIVE_COLOR} aria-label="loading"/>
                :
                "Apply"
                }
            </Button>
        </div>
    </div>
    </>
    );
}