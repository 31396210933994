import { HotTable } from "@handsontable/react";
import Handsontable from "handsontable";

import {LICENSES} from "../../../constants/Licenses";
import {MONTHS} from "../../../constants/Months";
import {ColumnSettings} from "handsontable/settings";
import {Property} from "../../../contexts/properties/PropertiesContext";
import { ITableDataByTabAndUnitType } from "./utils";

import * as tableCSS from "../../../components/account-table/styles/accountTable.module.scss";


export function buildSummaryTableSettings(
    hotRef: React.RefObject<HotTable>,
    data: ITableDataByTabAndUnitType[],
    columns: ColumnSettings[],
    visibleParentRows: Set<number>,
    currentProperty: Property,
): Handsontable.GridSettings {

    const { budgetYear, reforecastStartMonthIndex, reforecastYear } = currentProperty;

    const bdgtColRange = new Array(12).fill(0).map((_e, i) => i + 14);
    const rfcstColRange = new Array(12 - reforecastStartMonthIndex).fill(0).map((_e, i) => reforecastStartMonthIndex + i + 1);

    return {
        data,
        licenseKey: LICENSES.HandsOnTable,
        columns,
        selectionMode: "range",
        className: "htCenter",
        rowHeaders: false,
        colWidths: [210, ...new Array(12).fill(75), 90, ...new Array(12).fill(75), 90, 90],
        width: "100%",
        height: "auto",
        manualRowResize: false,
        manualColumnResize: false,
        stretchH: "all",
        autoColumnSize: false,
        autoRowSize: false,
        disableVisualSelection: ["header"],
        fillHandle: false,
        fixedColumnsStart: 1,
        afterGetColHeader: (_column, th) => {
            if (!hotRef.current?.hotInstance) {
                return;
            }

            th.className += ` ${tableCSS.cellBase} ${tableCSS.headerWithLabels}`;

            if (_column == 13 || _column == 26 || _column == 27 || _column < reforecastStartMonthIndex + 1) {
                th.className += ` ${tableCSS.cellBgGrey}`;
            }

            if (_column == 13 || _column == 26 || _column == 27) {
                th.className += ` ${tableCSS.verticalThickBorderLeft}`;
            }

            if (_column == reforecastStartMonthIndex) {
                th.className += ` ${tableCSS.verticalThickBorderRight}`;
            }

            if (_column == 1) {
                th.innerHTML = `
                    <div class="${tableCSS.tableHeaderLightLabel}">
                        <span>ACTUAL</span>
                        <span>${reforecastYear}</span>
                        <span>${ MONTHS[_column - 1]}</span>
                    </div>
                `;
            } else if (_column > 1 && _column <= reforecastStartMonthIndex) {
                th.innerHTML = `
                    <div class="${tableCSS.tableHeaderLightLabel}">
                        <span>ACTUAL</span>
                        <span></span>
                        <span>${ MONTHS[_column - 1]}</span>
                    </div>
                `;
            } else if (rfcstColRange.includes(_column)) {
                const validMonths = MONTHS.slice(reforecastStartMonthIndex, 12);
                // -1 offset is to account for the first column - the label column
                th.innerHTML = `
                    <div class="${tableCSS.tableHeaderLightLabel}">
                        <span>RFCST</span>
                        <span>&nbsp;</span>
                        <span>${ validMonths[_column - reforecastStartMonthIndex - 1]}</span>
                    </div>
                `;
            } else if (bdgtColRange.includes(_column)) {
                const startingBdgtCol = rfcstColRange.length + reforecastStartMonthIndex + 2;
                th.innerHTML = `
                    <div class="${tableCSS.tableHeaderLightLabel}">
                        <span>BDGT</span>
                        <span>&nbsp;</span>
                        <span>${ MONTHS[_column - startingBdgtCol]}</span>
                    </div>
                `;
            } else if (_column == 13 || _column == 27) {
                th.innerHTML = `
                    <div class="${tableCSS.tableHeaderLightLabel}">
                        <span>RFCST</span>
                        <span>${reforecastYear}</span>
                        <span>Average</span>
                    </div>
                `;
            } else if (_column == 26) {
                th.innerHTML = `
                    <div class="${tableCSS.tableHeaderLightLabel}">
                        <span>BDGT</span>
                        <span>${budgetYear}</span>
                        <span>Average</span>
                    </div>
                `;
            } else {
                // sure there must be better ways to handle HoT col size calculations, but this will do it for now
                th.innerHTML = `
                    <div class="${tableCSS.tableHeaderLightLabel}">
                        <span> </span>
                        <span> </span>
                        <span> </span>
                    </div>
                `;
            }
        },
        cells(row, col, _prop) {
            const rowData = data[row];
            const classNames: string[] = [];

            if (rowData) {
                if (col == 0 && rowData.parentId.length === 0) {
                    classNames.push(tableCSS.bold);
                } else if (rowData.parentId.length > 0) {
                    classNames.push(tableCSS.medium);
                }
            }

            if (col == 13 || col == 26 || col == 27) {
                classNames.push(tableCSS.cellBgGrey);
                classNames.push(tableCSS.verticalThickBorderLeft);
            }

            if (col == reforecastStartMonthIndex) {
                classNames.push(tableCSS.verticalThickBorderRight);
            }

            this.renderer = "ViziblyBaseCell";

            if (classNames.length) {
                return {
                    ...this,
                    className: classNames.join(" "),
                };
            }

            return this;
        },
        // this logic used to be in their respective renderers, but the getSourceData built in method for HoT is too expensive to call per-renderer
        afterRenderer(td, row, col, prop, value, cellProperties) {
            const rowData = data[row];

            if (rowData) {

                if (col > 0) {
                    switch (rowData.dataFormat) {
                        case "currency": {
                            let decimalPoints = "";
                            if(rowData.decimalPoints > 0) {
                                for(let i = 0; i < rowData.decimalPoints; i++) {
                                    decimalPoints += "0";
                                }
                                decimalPoints = "." + decimalPoints;
                            }

                            cellProperties.numericFormat = {
                                pattern: "$0,0" + decimalPoints,
                                culture: "en-US"
                            };
                            Handsontable.renderers.NumericRenderer.apply(cellProperties, [cellProperties.instance, td, row, col, prop, value, cellProperties]);
                            break;
                        }
                        case "percentage": {
                            if (prop != "label" && value != null) {
                                let decimalPoints = "";
                                if(rowData.decimalPoints > 0) {
                                    for(let i = 0; i < rowData.decimalPoints; i++) {
                                        decimalPoints += "0";
                                    }
                                    decimalPoints = "." + decimalPoints;
                                }

                                cellProperties.numericFormat = {
                                    pattern: "0,0" + decimalPoints,
                                    culture: cellProperties.numericFormat?.culture ?? "en-US"
                                };
                                Handsontable.renderers.NumericRenderer.apply(cellProperties, [cellProperties.instance, td, row, col, prop, value, cellProperties]);
                                td.innerHTML += "%";
                            }
                            break;
                        }
                        case "count": {
                            if(prop != "label") {
                                let decimalPoints = "";
                                if(rowData.decimalPoints > 0) {
                                    for(let i = 0; i < rowData.decimalPoints; i++) {
                                        decimalPoints += "0";
                                    }
                                    decimalPoints = "." + decimalPoints;
                                }

                                cellProperties.numericFormat = {
                                    pattern: "0,0" + decimalPoints,
                                    culture: cellProperties.numericFormat?.culture ?? "en-US"
                                };
                                Handsontable.renderers.NumericRenderer.apply(cellProperties, [cellProperties.instance, td, row, col, prop, value, cellProperties]);
                            }
                            break;
                        }
                    }
                }

                if (prop == "col0") {
                    td.className += ` ${tableCSS.rowHeader}`;

                    if (rowData.parentId.length === 0) {
                        td.className += ` ${tableCSS.parentRow}`;

                        if (visibleParentRows.has(row)) {
                            td.className += ` ${tableCSS.parentRowVisible}`;
                        }
                    } else {
                        td.className += ` ${tableCSS.childRow}`;
                    }
                }
            }
        },
    };
}
