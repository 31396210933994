import { useMemo } from "react";
import { useLocation } from "react-router-dom";

/**
 * Builds on useLocation to provide access to url query string values.
 * Pulled from React Router's docs https://v5.reactrouter.com/web/example/query-parameters
 */
export default function useQuery() {
    const { search } = useLocation();
    return useMemo(() => new URLSearchParams(search), [search]);
}
