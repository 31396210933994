import { ReactElement, useEffect, useMemo, useRef, useState } from "react";

import { ModelingMethodName } from "../../logic/utils";

import { deleteNodeKeys, TFormulaNodeProps } from "./logic/formulaNode";
import * as css from "./styles/css.module.scss";


export type TCustomDriverFormulaNodeProps = TFormulaNodeProps&{
    id: string,
    itemName: string,
    isBulkUpdateBar?: boolean,
    isPropertyDriversUI?: boolean,
    editableFxBarChecker: ((modelingMethod: ModelingMethodName) => boolean) | undefined,
    selectedAccountIds: string[],
}

export function CustomDriverFxNode(props: TCustomDriverFormulaNodeProps): ReactElement {
    const [isFocused, setIsFocused] = useState<boolean>(false);
    const [lookbackHovered, setLookbackHovered] = useState<boolean>(false);

    const wrapperRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (isFocused && wrapperRef.current) {
            wrapperRef.current.focus();
        }
    }, [isFocused]);

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (
                wrapperRef.current &&
                (wrapperRef.current === event.target ||
                wrapperRef.current.contains(event.target as Node))
            ) {
                return;
            }
            setIsFocused(false);
        };

        // Clicking outside the current node should remove focus
        document.addEventListener("mousedown", handleClickOutside);

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    const handleClick = () => {
        setIsFocused(!isFocused);
    };

    const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
        if (
            isFocused && deleteNodeKeys.includes(e.key)
        ) {
            props.fbUpdates.removeCustomDriver({
                itemName: props.itemName,
            });
        }
    };

    const nodeClassName = props.locked ? css.fxNodeLocked : css.fxNode;

    const className = useMemo(() => {
        const allClassNames = [nodeClassName, css.deletableNode];

        if (props.className) {
            allClassNames.push(props.className);
        }

        if (isFocused) {
            allClassNames.push(css.focusedNode);
        }

        if (lookbackHovered) {
            allClassNames.push(css.disableHover);
        } else {
            allClassNames.filter((each: string) => each !== css.disableHover);
        }

        return allClassNames.join(' ');
    }, [isFocused, lookbackHovered, nodeClassName]);

    return (
        <div
            className={className}
            onClick={handleClick}
            onKeyDown={handleKeyDown}
            /**
             * onMouseLeave() isn't always triggered when the user selects a lookback period
             * and the lookback menu closes, so we make sure by doing this:
            */
            onMouseEnter={() => setLookbackHovered(false)}
            ref={wrapperRef}
            tabIndex={0}
        >
            (Custom Driver: Fee * % of {props.itemName}&nbsp;)
        </div>
    );
}
