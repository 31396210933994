import { BudgetComponentType } from "../__generated__/generated_types";
import { FinancialEntity } from "../contexts/chartofaccounts/ChartOfAccountsContext";

/**
 * Accounts have a BudgetComponentType property that indicates whether they're an Income of Expense account. Accounts
 * also have a `negate` property, which if true, means the account should be treated as its opposite type.
 * (e.g. a negated Income account is treated as an Expense account)
 * @param componentType     The account's registered BudgetComponentType
 * @param negateAtComponent The account's negate property value
 */
export const getAccountType = (
    componentType: BudgetComponentType,
    negateAtComponent: boolean
): BudgetComponentType => {
    if(componentType === BudgetComponentType.Expense){
        return negateAtComponent ? BudgetComponentType.Income : BudgetComponentType.Expense;
    }
    return negateAtComponent ? BudgetComponentType.Expense : BudgetComponentType.Income;
};

/**
 * Returns whether a value increase for a budget component type is considered good or bad. For example, an
 * increase in value is positive for an account that's of BudgetComponentType.Income, but bad for an account of
 * udgetComponentType.Expense
 * @param budgetComponentType
 * @param negatedAtComponent Indicates if the value is negated (i.e. multiplied by -1) when rolled up into
 *                           component.
 */
export const isPositiveGood = (budgetComponentType: BudgetComponentType, negatedAtComponent = false):boolean => {
    const val = budgetComponentType !== BudgetComponentType.Expense;
    return negatedAtComponent ? !val : val;
};

export const getChartOfAccountsWithDepths = (chartOfAccounts:FinancialEntity[]):IFinancialEntityWithDepth[] => {
    const result:IFinancialEntityWithDepth[] = [];

    let componentType = '';

    const processTree = (entityList: FinancialEntity[], depth = 0): void => {
        entityList.forEach(entity => {
            if(entity.type === 'COMPONENT'){
                // Note: Children of a Component will assume their parent budgetComponentType
                componentType = entity.budgetComponentType;
            }

            result.push({
                id: entity.id,
                name: entity.name,
                entityType: entity.type,
                parentId: entity.parentId,
                budgetComponentType: entity.budgetComponentType,
                budgetComponentNegate: entity.budgetComponentNegate ?? false,
                componentType,
                depth,
                number: entity.number,
            });

            if(entity.children.length > 0){
                processTree(entity.children, depth + 1);
            }
        });
    };

    processTree(chartOfAccounts);

    return result;
};

export interface IFinancialEntityWithDepth {
    id: string,
    name: string,
    entityType: string,
    budgetComponentType?: BudgetComponentType;
    budgetComponentNegate?: boolean;
    componentType?: string,
    parentId?: string,
    depth: number,
    number: string,
}
