import { makeVar } from "@apollo/client";
import { getStoreItem } from "../exports";
import { overviewSelector } from "../exports/contants";

export interface AppLocalStoreState {
    isLoading?: boolean;
    isSideBarVisible?: boolean;
    sideBarItem?: {
        /** The key ID/name of a parent side-bar navigation list */
        parentkeys?: Array<string>;
    };
    overviewSelectorItem?: {
        parentkeys?: Array<string>;
    };
    categoryType?: string;
}

const initialState: AppLocalStoreState = {
    isLoading: true,
    categoryType: "reforecast",
    overviewSelectorItem: {
        parentkeys: getStoreItem(overviewSelector.storekey),
    },
};

export const AppLocalStore = makeVar(initialState);
