import { ThemeProvider } from "@zendeskgarden/react-theming";
import { ReactElement, useContext, useEffect, useState } from "react";
import { ViziblyTheme } from "../../styles/zendesk-garden/ViziblyZDGTheme";
import { Col, Grid, Row } from "@zendeskgarden/react-grid";
import { Field, Input, InputGroup } from "@zendeskgarden/react-forms";
import { Button } from "@zendeskgarden/react-buttons";
import { GuestAuthenticationContext } from "../../contexts/guest-auth-context/GuestAuthContext";
import loginCartoon from "../../assets/icons/login/loginCartoon.svg";
import logo from "../../assets/images/logo/vizibly.svg";

import { useHistory } from "react-router-dom";
import * as css from "./styles/guestOtp.module.scss";

export function GuestOtp(): ReactElement {
    const guestAuthContext = useContext(GuestAuthenticationContext);
    const history = useHistory();
    const [otp, setOtp] = useState<string>();
    const [codeSent, setCodeSent] = useState(false);

    function handleSendCode() {
        setCodeSent(true);
        guestAuthContext.requestOTP();
    }

    useEffect(() => {
        if (guestAuthContext.user) {
            history.push("/guest");
        }
    }, [guestAuthContext.user]);

    return (
        <ThemeProvider theme={ViziblyTheme}>
            <Grid>
                <Row>
                    <Col className={css.otpWrapper} size={3} offset={3}>
                        <span className={css.welcome}>Welcome to</span>
                        <img src={logo} className={css.logo}/>
                        {!codeSent &&
                        <>
                            <span className={css.prompt}>We’ll send you a one-time password to your email to sign you in.</span>
                            <Button className={css.sendButton} isPrimary onClick={handleSendCode}>Send Code</Button>
                        </>
                        }
                        {codeSent &&
                        <>
                            <span className={css.prompt}>Please check your email and paste 6-digit code here</span>
                            <Field>
                                <InputGroup>
                                    <Input type="text" value={otp} onChange={evt => setOtp(evt.target.value)}></Input>
                                    <Button disabled={!otp} onClick={() => otp && guestAuthContext.signInWithOTP(otp)}>Sign In</Button>
                                </InputGroup>
                            </Field>
                        </>
                        }
                    </Col>
                    <Col className={css.otpRight} size={3}>
                        <img style={{width: "100%"}} src={loginCartoon} alt="assestsPerformance"/>
                        <div className="optimize-text">Optimize the performance of <br/> your assets.</div>
                </Col>
                </Row>
            </Grid>
        </ThemeProvider>
    );
}