import React, {ReactElement, useContext, useEffect, useRef, useState} from 'react';
import {useParams} from 'react-router-dom';
import {BudgetingType} from '../../../../BudgetingType';
import {allowRightClickCopyPaste, NewSpreadsheet} from '../../../../components/spreadsheet/NewSpreadsheet';
import {useNewSpreadsheetAPI} from '../../../../components/spreadsheet/NewSpreadsheetHooks';
import {UnitType, useProperties} from '../../../../contexts/properties/PropertiesContext';
import {useSettings} from '../../../../contexts/settings/SettingsContext';
import useGetMonths from '../../../../hooks/UseGetMonths';
import {
    GetLeaseExpirationCountQuery,
    useGetForecastLocksLazyQuery,
    useGetLeaseExpirationCountLazyQuery,
    VersionType
} from '../../../../__generated__/generated_types';
import {HandleSaveValues, useSaveUnitTypeScheduleValues} from '../base';
import viewTemplate from "./lease_expiration_view.json";
import {AuthContext} from "../../../../contexts/AuthContext";
import {userIsReadOnly} from "../../../../authorization/AuthorizationCheckers";

import {ThemeProvider} from "styled-components";
import {ViziblyTheme} from "../../../../styles/zendesk-garden/ViziblyZDGTheme";

import {Tabs} from "@zendeskgarden/react-tabs";

import * as workflowCSS from "../../../../styles/workflows/workflowCSS.module.scss";
import * as workflowHeaderCSS from "../../../../components/workflows/workflow-header/styles/css.module.scss";
import * as rewriteViewCSS from "./leaseExpirationsRewrite.module.scss"

import WorkflowHeader from "../../../../components/workflows/workflow-header/WorkflowHeader";
import WorkflowPageLabel from "../../../../components/workflows/workflow-page-label/WorkflowPageLabel";
import WorkflowStepNav from "../../../../components/workflows/workflow-step-nav/WorkflowStepNav";

import WorkflowYearTabs, {
    WORKFLOW_YEAR_TAB_BUDGET
} from "../../../../components/workflows/workflow-year-tabs/WorkflowYearTabs";
import {IWorkflowPageProps, shouldUpdateWorkflowTab} from "../../logic/workflows";
import WorkflowNavToggle from "../../../../components/workflows/workflow-nav-toggle/WorkflowNavToggle";
import ShimmerRenderer, {ShimmerPageKey} from "../../../../components/shimmer/ShimmerRenderer";
import {FinancialMetrics} from "../../../../components/metrics/FinancialMetrics";
import {spreadsheetContainer} from "../../account/styles/styles.module.scss";
import { Body, Close, Footer, FooterItem, Header, Modal } from "@zendeskgarden/react-modals";
import { Button } from "@zendeskgarden/react-buttons";
import { LeaseExpirationsRewriteView } from "./LeaseExpirationsRewriteView";
import {LeaseExpirationCurveModal} from "./curve/LeaseExpirationCurveModal";


const REFORECAST_INPUT_ROW = 2, REFORECAST_INPUT_COL = 5;
const HAS_VALUE_COL = 19;
const FIRST_VALUE_ROW = 6, FIRST_VALUE_COL = 4;
const UNIT_MIX_COL = 17;

interface Data {
    data: GetLeaseExpirationCountQuery | undefined;
    months: string[];
    year: number;
    startReforecastMonthIndex: number;
    propertyId: string;
    unitTypes: UnitType[];
    saveValues: HandleSaveValues;
}
interface DataReadyResult {
    isReady: boolean;
    updatesCount: number;
}
interface DataState {
    isDataReady: DataReadyResult;
    reloadLeaseExpirationCounts: () => void;
    getData: () => Data;
}

function useLeaseExpirationData(type: BudgetingType): DataState {
    const [isDataReady, setDataReady] = useState<DataReadyResult>({ isReady: false, updatesCount: 0 });
    const { propertyId, unitTypes: unitTypesUnsorted, loading: propertiesLoading, loaded: propertiesLoaded } = useProperties();
    const [unitTypes, setUnitTypes] = useState<UnitType[]>([]);
    const { year, startReforecastMonthIndex, isDefaults } = useSettings();
    const months = useGetMonths();
    const budgetYear = year + 1;
    const [loadLeaseExpirationCounts, { loading, error, data }] = useGetLeaseExpirationCountLazyQuery({
        fetchPolicy: "network-only"
    });

    useEffect(
        () => {
            reloadLeaseExpirationCounts();
        },
        [type]
    );

    useEffect(
        () => {
            if (unitTypesUnsorted && unitTypesUnsorted.length > 0) {
                const unitTypes = unitTypesUnsorted.sortBy(ut => ut.type);
                setUnitTypes(unitTypes);
            }
        },
        [propertiesLoading, unitTypesUnsorted, propertiesLoaded]
    );

    useEffect(
        () => {
            const dataReady = !isDefaults && !loading && data != undefined && !error && !propertiesLoading;

            setDataReady(oldVal => ({ isReady: dataReady, updatesCount: oldVal.updatesCount + 1 }));
        },
        [isDefaults, loading, data, propertiesLoading]
    );

    const saveValues = useSaveUnitTypeScheduleValues(propertyId, "leaseExpirationCount");

    const getData = () => {

        return {
            data,
            months,
            year,
            startReforecastMonthIndex,
            propertyId,
            unitTypes,
            saveValues
        };

    };

    function reloadLeaseExpirationCounts() {
        loadLeaseExpirationCounts({
            variables: {
                propertyId,
                versionType: type == BudgetingType.BUDGET ? VersionType.Budget : VersionType.Reforecast,
                budgetYear: budgetYear,
                versionYear: type == BudgetingType.BUDGET ? year + 1 : year,
                year
            }
        });
    }

    return {
        isDataReady: isDataReady ?? false,
        reloadLeaseExpirationCounts,
        getData: getData
    };
}

function getDataDetails(data: GetLeaseExpirationCountQuery) {
    const rows = data.actuals.find(version => version.unitTypeScheduleValues.isNotEmpty) ?? data.actuals.firstElement;
    const groups = rows.unitTypeScheduleValues.groupBy(row => row.unitType.name);
    const currentVersion = data.currentVersion.find(version => version.unitTypeScheduleValues.isNotEmpty) ?? data.currentVersion.firstElement;
    const currentVersionGroups = currentVersion?.unitTypeScheduleValues?.toIdMap(unit => unit.unitType.name + "#" + unit.monthIndex) ?? {};

    return { actualRows: rows, currentVersion, currentVersionGroups, groups };
}

export default function LeaseExpirations(props:IWorkflowPageProps): ReactElement {
    const { type } = useParams() as { type: BudgetingType; propertyId: string };
    const { user } = useContext(AuthContext);
    const { year } = useSettings();

    const [showRewriteModal, setShowRewriteModal] = useState(false);
    const [showExpirationCurveModal, setShowExpirationCurveModal] = useState(false);

    const { isDataReady, getData: getRemoteData, reloadLeaseExpirationCounts } = useLeaseExpirationData(type);
    const {currentProperty} = useProperties();

    const unitsCount = useRef<number>(0);

    const spreadsheet = useNewSpreadsheetAPI();

    const [getForecastLocks, {data: dataForecastLocks, loading: lockDataLoading}] = useGetForecastLocksLazyQuery({
        fetchPolicy: "no-cache",
    });

    useEffect(() => {
        if (!isDataReady.isReady) {
            return;
        }

        const {propertyId} = getRemoteData();

        getForecastLocks({
            variables: {
                propertyId: propertyId,
                budgetYear: year + 1,
            }
        });
    }, [isDataReady.isReady]);

    useEffect(
        () => {
            if (lockDataLoading) {
                return;
            }

            const data = getRemoteData().data;

            if (isDataReady.isReady && spreadsheet.isSpreadsheetReady && data) {
                const { unitTypes, months, startReforecastMonthIndex } = getRemoteData();
                const { groups, currentVersionGroups } = getDataDetails(data);

                spreadsheet.api.setTemplate({ template: viewTemplate, rows: unitTypes.length + 3 });
                unitsCount.current = unitTypes.length;

                spreadsheet.api.directAccess(spread => {
                    spread.suspendPaint();
                    spread.suspendEvent();
                    spread.options.allowUserDragDrop = false;
                    spread.options.showDragFillSmartTag = false;
                    allowRightClickCopyPaste(spread);
                });

                spreadsheet.api.setValue({ row: REFORECAST_INPUT_ROW, col: REFORECAST_INPUT_COL, value: type == BudgetingType.REFORECAST ? startReforecastMonthIndex : 0 });
                spreadsheet.api.setArray({ row: FIRST_VALUE_ROW - 1, col: FIRST_VALUE_COL, array: [months] });
                const dataArray: (string | number | null)[][] = unitTypes.map(({ type: unitType }) => {
                    const values = groups[unitType] ?? [];
                    const months = values.toIdMap("monthIndex");

                    return [
                        unitType,
                        ...new Array(12).fill(null).map((_, monthIndex) => {
                            const isEditable = type == BudgetingType.BUDGET || monthIndex >= startReforecastMonthIndex;
                            const currentValue = currentVersionGroups[unitType + "#" + monthIndex];
                            const value = isEditable ? (parseInt((currentValue?.leaseExpirationCount ?? 0).toString()) ?? 0) : parseInt((months[monthIndex]?.leaseExpirationCount ?? 0).toString());
                            return value;
                        })
                    ];
                });

                const clearFormulaArray = dataArray.map(row => row.map(_ => null));
                spreadsheet.api.setArray({ row: FIRST_VALUE_ROW, col: FIRST_VALUE_COL - 1, array: clearFormulaArray, setFormula: true });
                spreadsheet.api.setArray({ row: FIRST_VALUE_ROW, col: FIRST_VALUE_COL - 1, array: dataArray });

                const lockedColumnsCount = type == BudgetingType.REFORECAST ? startReforecastMonthIndex : 0;
                if (lockedColumnsCount > 0) {
                    spreadsheet.api.setCellsLocked({ row: FIRST_VALUE_ROW, col: FIRST_VALUE_COL, rowCount: dataArray.length, colCount: lockedColumnsCount, locked: true });
                }
                const unLockedColumnsCount = 12 - lockedColumnsCount;
                if (unLockedColumnsCount > 0) {
                    spreadsheet.api.setCellsLocked({ row: FIRST_VALUE_ROW, col: FIRST_VALUE_COL + lockedColumnsCount, rowCount: dataArray.length, colCount: unLockedColumnsCount, locked: false });
                }
                // const unitMixArray = unitTypes.map(row => row.count);
                // spreadsheet.api.setArray({ row: FIRST_VALUE_ROW, col: UNIT_MIX_COL, array: unitMixArray });

                spreadsheet.api.setArray({ row: FIRST_VALUE_ROW, col: HAS_VALUE_COL, array: new Array(unitTypes.length).fill(1) });

                if(userIsReadOnly(user)){
                    spreadsheet.api.lockAllCells();
                }

                spreadsheet.api.directAccess(spread => {
                    spread.resumeEvent();
                    spread.resumePaint();
                });

                setAccountDataLoading(false);
            }
        },
        [spreadsheet.isSpreadsheetReady, isDataReady.isReady, lockDataLoading]
    );


    useEffect(
        () => {
            const { data, unitTypes, startReforecastMonthIndex, saveValues } = getRemoteData();

            const lastRow = FIRST_VALUE_ROW + unitTypes.length - 1;
            const firstCol = FIRST_VALUE_COL + (type == BudgetingType.REFORECAST ? startReforecastMonthIndex : 0);
            const lastCol = FIRST_VALUE_COL + 11;
            const cellsToUpdate = spreadsheet.cellsChanged.filter(cell => cell.row >= FIRST_VALUE_ROW && cell.row <= lastRow && cell.col >= firstCol && cell.col <= lastCol);

            if (data && cellsToUpdate.length > 0) {
                const { currentVersion } = getDataDetails(data);
                const values = [];
                for (const cell of cellsToUpdate) {
                    const monthIndex = cell.col - FIRST_VALUE_COL;
                    const unitType = spreadsheet.api.getValue({ row: cell.row, col: FIRST_VALUE_COL - 1 }) as string;
                    values.push({ monthIndex, unitTypeName: unitType, value: parseFloat(cell.value?.toString() ?? "0").toString()});
                }
                saveValues(currentVersion.id, values);
            }
        },
        [spreadsheet.cellsChanged]
    );

    /**
     * Update selected tab, which updates display, on type change
     */
    useEffect(
            () => {
                const updateWorkflowTab = shouldUpdateWorkflowTab(type, selectedTab);
                if(updateWorkflowTab != false){
                    setSelectedTab(updateWorkflowTab as string);
                }
            },
            [type]
    );

    const [selectedTab, setSelectedTab] = useState(WORKFLOW_YEAR_TAB_BUDGET);
    const [accountDataLoading, setAccountDataLoading] = useState<boolean>(true);

    return (
            <ThemeProvider theme={ViziblyTheme}>
                {/* TODO: Update to <WorkflowLoadingOverlay loading={accountDataLoading}/> */}
                {accountDataLoading ? <ShimmerRenderer modal={true} pageKey={ShimmerPageKey.ACCOUNT_VIEW}  /> : null}
                <div className={workflowCSS.workflowContainer}>
                    <WorkflowHeader>
                        <WorkflowHeader.LeftCol>
                            <div className={workflowHeaderCSS.rowItemsContainer}>
                                <WorkflowNavToggle/>
                                <WorkflowPageLabel label="Lease Expiration Schedule"/>
                                { currentProperty?.id && type && dataForecastLocks && dataForecastLocks.forecastLocks &&
                                <Button
                                    onClick={() => setShowRewriteModal(true)}
                                    disabled={type === BudgetingType.REFORECAST ? dataForecastLocks.forecastLocks.property.reforecastLocked : dataForecastLocks.forecastLocks.property.budgetLocked}
                                >
                                    Sync With PMS
                                </Button>
                                }
                                {currentProperty?.id && type === BudgetingType.BUDGET &&
                                        <Button onClick={() => setShowExpirationCurveModal(true)}>Use Expiration Curve</Button>
                                }
                            </div>
                        </WorkflowHeader.LeftCol>
                        <WorkflowHeader.RightCol>
                            <WorkflowStepNav onPreviousClick={props.onPreviousClick} onNextClick={props.onNextClick}/>
                        </WorkflowHeader.RightCol>
                    </WorkflowHeader>
                    <div className={workflowCSS.bodyContainer}>
                        <Tabs selectedItem={selectedTab} onChange={setSelectedTab} className={workflowCSS.accountYearTabNav}>
                            <WorkflowYearTabs year={year}
                                selectedTab={selectedTab}
                                rawForecastLocks={dataForecastLocks}
                            />

                            {/*
                                TODO: Improve data service, load both tabs and financials at once.
                                Then, update content and replace w/ Tab panels (e.g. workflows/account/Account.tsx)
                                https://vizibly.atlassian.net/browse/APP-936
                            */}
                            <div className={workflowCSS.tabContent}>
                                <div className={workflowCSS.dataColumn}>
                                    <div className={`${workflowCSS.accountTableContainer} ${spreadsheetContainer}`}>
                                        {(!lockDataLoading && dataForecastLocks?.forecastLocks?.property) &&
                                            <NewSpreadsheet
                                                    startRow={FIRST_VALUE_ROW - 1}
                                                    startCol={FIRST_VALUE_COL - 1}
                                                    rows={2}
                                                    cols={14}
                                                    handlers={spreadsheet.handlers}
                                                    subscribeToMouse={false}
                                                    readOnly={
                                                        type === BudgetingType.REFORECAST ? dataForecastLocks.forecastLocks.property.reforecastLocked : dataForecastLocks.forecastLocks.property.budgetLocked
                                                    }
                                            />
                                        }
                                    </div>
                                    <div className={workflowCSS.accountFinancialsContainer}>
                                        <div className='financial-metrics-container'>
                                            <FinancialMetrics
                                                budgetComponentName="Gross potential rent"
                                                year={year}
                                                accountId='' // this will fail to subscribe
                                                showActuals={type === BudgetingType.REFORECAST}
                                                className={spreadsheetContainer}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Tabs>
                    </div>
                </div>
                {showRewriteModal && currentProperty && type &&
                <Modal onClose={() => setShowRewriteModal(false)} isLarge className={rewriteViewCSS.valuesModal}>
                    <Header>
                        Sync PMS values to your assumptions for {year} {type}
                    </Header>
                    <Body className={rewriteViewCSS.valuesModalBody}>
                        <LeaseExpirationsRewriteView
                            propertyId={currentProperty.id}
                            versionType={type == BudgetingType.BUDGET ? VersionType.Budget : VersionType.Reforecast}
                            valuesStartFromIndex={type == BudgetingType.BUDGET ? 0 : currentProperty.reforecastStartMonthIndex}
                            onCancel={() => setShowRewriteModal(false)}
                            onApply={() => {reloadLeaseExpirationCounts(); setShowRewriteModal(false);}}
                        />
                    </Body>
                    <Close aria-label="Close modal" />
                </Modal>

                }
                {showExpirationCurveModal && currentProperty &&
                        <LeaseExpirationCurveModal
                                propertyId={currentProperty.id}
                                budgetYear={currentProperty.reforecastYear + 1}
                                handleCancel={() => setShowExpirationCurveModal(false)}
                                handleConfirm={() => {
                                    reloadLeaseExpirationCounts();
                                    setShowExpirationCurveModal(false);
                                }} />
                }
            </ThemeProvider>
    );
}
