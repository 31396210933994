import {
    GetAnalystRenovationDrawerRenovationPackagesQuery,
    useGetAnalystRenovationDrawerRenovationPackagesLazyQuery
} from "../../../__generated__/generated_types";
import {useEffect, useState} from "react";

export interface RenovationsCostCategoriesState {
    isDataReady: boolean;
    refreshRenovationPackagesForSelectedProperty: (propertyId: string, unitTypeId: string) => void;
    getData: () => GetAnalystRenovationDrawerRenovationPackagesQuery["queryRenovationPackages"];
}

export function useGetRenovationsPackagesByPropertyIdAndUnitTypeId(): RenovationsCostCategoriesState {
    const [isDataReady, setDataReady] = useState<boolean>(false);
    const [unitTypeId, setUnitTypeId] = useState<string>("");
    const [data, setData] = useState<GetAnalystRenovationDrawerRenovationPackagesQuery["queryRenovationPackages"]>([]);

    const [
        getRenovationPackages,
        { data: renovationPackageData, loading: renovationPackagesLoading, error: renovationPackagesError }
    ] = useGetAnalystRenovationDrawerRenovationPackagesLazyQuery({ fetchPolicy: "network-only" });

    useEffect(
        () => {
            /*console.info(`useEffect
                isDataReady: ${isDataReady}
                unitTypeId: ${unitTypeId}
                renovationPackagesLoading: ${renovationPackagesLoading}
                renovationPackageData: ${JSON.stringify(renovationPackageData)}
                renovationPackagesError: ${renovationPackagesError}`);*/
            if(renovationPackageData == undefined || renovationPackagesLoading){
                return;
            }
            setData(renovationPackageData.queryRenovationPackages.filter(renovationPackage => renovationPackage.unitTypeId === unitTypeId));
            setDataReady(true);
        },
        [renovationPackageData, renovationPackagesLoading, renovationPackagesError]
    );

    const refreshRenovationPackagesForSelectedProperty = (propertyId: string, unitTypeId: string) => {
        // console.info(`refreshRenovationPackagesForSelectedProperty called for ${propertyId} and ${unitTypeId}`);
        if (propertyId && unitTypeId) {
            setUnitTypeId(unitTypeId);
            setDataReady(false);
            getRenovationPackages({
                variables: {
                    propertyIds: [propertyId]
                }
            });
        }
    };

    const getData = () => {
        return data;
    };

    return {
        isDataReady,
        refreshRenovationPackagesForSelectedProperty,
        getData,
    };
}
