import { createContext, ReactElement, ReactNode, useContext, useEffect, useState } from "react";

import useDriversAndWorksheetData, { IDriversAndWorksheetData } from "./data/useDriversAndWorksheetData";


interface IAccountContextState {
    isReady: boolean,

    setPropertyId: (propertyId: string | undefined) => void,
    propertyId: string | undefined,

    setAccountId: (accountId: string | undefined) => void,
    accountId: string | undefined,

    driversAndWorksheetData: IDriversAndWorksheetData,
}

const useAccountContextState = (): IAccountContextState => {
    const [isReady, setIsReady] = useState<boolean>(false);

    const [propertyId, _setPropertyId] = useState<string>();
    const [accountId, _setAccountId] = useState<string>();

    const dwData = useDriversAndWorksheetData({ propertyId, accountId });

    /**
     * Sets isReady to true once all required dependencies have been loaded.
     */
    useEffect(
        () => {
            if (!propertyId || !accountId) {
                return;
            }

            // Initialize Drivers and Worksheet data
            dwData.setAccountId(accountId);
            dwData.setPropertyId(propertyId);

            setIsReady(true);
        },
        [propertyId, accountId]
    );

    const setPropertyId = (propertyId: string | undefined): void => {
        _setPropertyId(propertyId);
    };

    const setAccountId = (accountId: string | undefined): void => {
        _setAccountId(accountId);
    };

    return {
        isReady,

        propertyId,
        setPropertyId,

        accountId,
        setAccountId,

        driversAndWorksheetData: dwData,
    };
};

export const AccountContext = createContext<IAccountContextState | undefined>(undefined);

/**
 * Provides services related to a particular account and property. In most cases, the propertyId that's registered will
 * be the same as the currentProperty that's provided by the Properties context, but a different property and account
 * id can be registered to fetch information relevant to those params.
 *
 * Important Note: In order to control when data is requested and loaded, this context intentionally does not fetch
 * data until it's requested. This consideration should be observed when adding functionality to the context.
 *
 * @param props
 * @constructor
 */
export function AccountContextProvider(props: { children: ReactNode | ReactNode[] }): ReactElement {
    return (
        <AccountContext.Provider value={{ ...useAccountContextState() }}>
            {props.children}
        </AccountContext.Provider>
    );
}

export const useAccountContext = (): IAccountContextState => {
    const ctx = useContext(AccountContext);

    if (ctx === undefined) {
        throw new Error('useAccountContext must be used within a <AccountContextProvider>');
    }

    return ctx;
};
