import { CellValue } from "handsontable/common";
import { useEffect, useState } from "react";
import { IMenuTitleOption } from "../../../components/menu-title/logic/menuTitle";
import { useProperties } from "../../../contexts/properties/PropertiesContext";
import { useSettings } from "../../../contexts/settings/SettingsContext";
import { useGetOperationalPropertyPerformanceLazyQuery, VersionType } from "../../../__generated__/generated_types";
import { OpDriverMetricType } from "../../analyst/tab-op-drivers/enums";
import { buildSummaryData, getApexSeriesFromParsedData, getHotTableDataFromParsedSeriesData, parseRawOperationalData } from "./logic/operationalData";

// model representing shape from backend resolver
export type TOperationalDataMetricDataRaw = {
    year: number,
    versionType: VersionType,
    values: (number | null)[],
    counts: number[],
}

// model representing shape from backend resolver
export type TOperationalDataRaw = {
    move_out_rates: TOperationalDataMetricDataRaw[],
    expirations: TOperationalDataMetricDataRaw[],
    occupancy_rates: TOperationalDataMetricDataRaw[],
    renewal_ratio: TOperationalDataMetricDataRaw[],
}

export type TOperationalDataMetricData = {
    year: number,
    versionType: VersionType,
    values: (number | null)[],
    counts: number[],
    name: string,
    color: string,
    type: string,
    averageValue: number,
    averageCount: number,
    totalValue: number,
    totalCount: number,
}

export type TOperationalDataArgs = {
    metricType: OpDriverMetricType,
    comparisonLeft: number,
    comparisonRight: number,
    property: {
        id: string | undefined,
        reforecastStartMonthIndex: number | undefined,
    },
}

export type TOperationalDataSummary = {
    comparisonItemOne: {
        label: string,
        value: number | string,
    },
    comparisonItemTwo: {
        label: string,
        value: number | string,
    },
    variancePercent: {
        label: string,
        value: number | string,
    },
    varianceAmount: {
        label: string,
        value: number | string,
    },
}

export type TOperationalParsedDataItem = {
    menuOptions: IMenuTitleOption[],
    seriesData: TOperationalDataMetricData[],
}

export type TOperationalParsedData = {
    [key: number]: TOperationalParsedDataItem,
}

export type TOperationalChartDataBundle = {
    varianceCounts: (number | null)[],
    chartSeriesData: TOperationalDataMetricData[],
}

export type TWidgetTableConfig = {
    colHeaders: string[],
    data: CellValue[][],
}

export type TOperationalData = {
    args: TOperationalDataArgs,
    comparisonOptions: IMenuTitleOption[],
    chartData: TOperationalChartDataBundle | undefined,
    summaryData: TOperationalDataSummary | undefined,
    tableData: TWidgetTableConfig | undefined,
    dataType: 'dollar' | 'percent' | 'count',
    loading: boolean,
    setArgs: (args: Partial<TOperationalDataArgs>) => void,
}

export default function useOperationalData(): TOperationalData {

    const settings = useSettings();
    const properties = useProperties();

    const [_fetch, { data, loading }] = useGetOperationalPropertyPerformanceLazyQuery({
        fetchPolicy: "network-only",
    });
    const [parsedData, setparsedData] = useState<TOperationalParsedData>({});
    const [comparisonOptions, setComparisonOptions] = useState<IMenuTitleOption[]>([]);
    const [chartData, setChartData] = useState<TOperationalChartDataBundle>();
    const [summaryData, setSummaryData] = useState<TOperationalDataSummary | undefined>();
    const [tableData, setTableData] = useState<TWidgetTableConfig | undefined>();
    const [dataType, setDataType] = useState<'dollar' | 'percent' | 'count'>('percent');
    const [args, _setArgs] = useState<TOperationalDataArgs>({
        metricType: OpDriverMetricType.OCCUPANCY_RATES,
        comparisonLeft: 0,
        comparisonRight: 0,
        property: {
            id: properties.currentProperty?.id,
            reforecastStartMonthIndex: properties.currentProperty?.reforecastStartMonthIndex,
        }
    });

    useEffect(
        () => {
            if (!properties.currentProperty?.id) {
                return;
            }

            setArgs({
                property: {
                    id: properties.currentProperty.id,
                    reforecastStartMonthIndex: properties.currentProperty.reforecastStartMonthIndex,
                }
            });

            _fetch({
                variables: {
                    propertyId: properties.currentProperty.id,
                    budgetYear: properties.currentProperty.reforecastYear + 1,
                }
            });
        },
        [properties.currentProperty]
    );

    useEffect(
        () => {
            if (!data) {
                return;
            }

            const parsed = parseRawOperationalData(data.getOperationalPropertyPerformance, settings.startReforecastMonthIndex, settings.year);
            setparsedData(parsed);
        },
        [data]
    );

    useEffect(
        () => {
            if (args.metricType == OpDriverMetricType.EXPIRATIONS) {
                setDataType('count');
            } else {
                setDataType('percent');
            }

            const parsedDataForMetricType = parsedData[args.metricType];

            if (!parsedDataForMetricType) {
                return;
            }

            setComparisonOptions(parsedDataForMetricType.menuOptions);

            const seriesDataForMetricType = parsedDataForMetricType.seriesData;
            const builtChartData = getApexSeriesFromParsedData(seriesDataForMetricType, args.comparisonLeft, settings.year);

            if (builtChartData) {
                setChartData(builtChartData);
                const summaryData = buildSummaryData(
                    parsedDataForMetricType,
                    args.comparisonLeft,
                    dataType,
                );
                setSummaryData(summaryData);

                const tableData = getHotTableDataFromParsedSeriesData(builtChartData, summaryData, dataType);

                if (tableData) {
                    setTableData(tableData);
                }
            }
        },
        [args, parsedData]
    );

    const setArgs = function(_args: Partial<TOperationalDataArgs>) {
        _setArgs((currentArgs) => {
            return {
                ...currentArgs,
                ..._args
            };
        });
    };

    return {
        comparisonOptions,
        dataType,
        args,
        summaryData,
        chartData,
        tableData,
        loading,
        setArgs,
    };
}