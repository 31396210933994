import { nanSafeParseFloat } from '../../../../../../../../../utils/excel';
import {
    SourceMetricModel,
    GetMvrBudgetAssumptionsForPropertyAndAccountQuery
} from '../../../../../../../../../__generated__/generated_types';
import { AssumptionRowModel } from '../models/AssumptionRowModel';
import { AssumptionRowValueType } from '../enums/AssumptionRowValueTypeEnum';
import { buildLookbackPeriodSubLabel } from './buildLookbackPeriodSubLabel';
// TODO: Don't use Maybe directly... add `|null` to the type Maybe might return
import { Maybe } from 'graphql/jsutils/Maybe';
import { OpDriverMetricDisplayName } from "../../../../../../../../../constants/Strings";
/**
 * Maps SourceAccountModel to
 * AssumptionRowModel(
 *      label = <built based on SourceAccountModel.glName>,
 *      subLabel = <built based on SourceAccountModel.lookbackPeriod>
 *      value = <calculated out of sourceAccount.assumptionsAndValues[monthIndex]>
 *      children = [
 *          AssumptionRowModel(label = "%", value = sourceAccount.assumptionsAndValues[monthIndex].percentage),
 *          AssumptionRowModel(label = SourceAccountModel.glName, value = sourceAccount.assumptionsAndValues[monthIndex].accountValue),
 *      ]
 * )
 *
 * NOTE: all undefined/null values are defaulted to 0
 *
 * @param sourceMetric SourceAccountModel
 * @param monthIndex 0-11
 * @returns AssumptionRowModel
 */
function mapOperationalMetricSourceMetric(sourceMetric: SourceMetricModel, monthIndex: number): AssumptionRowModel {
    const assumption = sourceMetric.assumptionsAndValues[monthIndex];

    let percentage = 0;
    let amount = 0;
    let metricValue = 0;
    let driverValue = 0;
    let lookbackPeriod = 0;

    if (assumption) {
        percentage = nanSafeParseFloat(`${assumption.percentage}`);
        amount = nanSafeParseFloat(`${assumption.amount}`);
        metricValue = nanSafeParseFloat(`${assumption.metricValue}`);
        driverValue = percentage / 100 * metricValue * amount;
        lookbackPeriod = nanSafeParseFloat(`${assumption.lookbackPeriod}`);
    }

    const metricDisplayNamePlural = OpDriverMetricDisplayName[sourceMetric.metricName]?.plural ?? sourceMetric.metricName;
    const metricDisplayNameSingle = OpDriverMetricDisplayName[sourceMetric.metricName]?.single ?? sourceMetric.metricName;
    const metricRow: AssumptionRowModel = {
        label: `${metricDisplayNamePlural} * Cost`,
        subLabel: buildLookbackPeriodSubLabel(lookbackPeriod),
        value: driverValue,
        valueType: AssumptionRowValueType.DOLLAR,
        children: [
            {
                label: `# of ${metricDisplayNamePlural}`,
                subLabel: undefined,
                value: metricValue,
                valueType: AssumptionRowValueType.NUMBER,
                children: []
            },
            {
                label: `% of ${metricDisplayNamePlural}`,
                subLabel: undefined,
                value: percentage,
                valueType: AssumptionRowValueType.PERCENTAGE,
                children: []
            },
            {
                label: `$ per ${metricDisplayNameSingle}`,
                subLabel: undefined,
                value: amount,
                valueType: AssumptionRowValueType.DOLLAR,
                children: []
            }
        ]
    };

    return metricRow;
}

type OperationalMetricModel = GetMvrBudgetAssumptionsForPropertyAndAccountQuery["singlePropertyAccount"]["operationalMetricDriver"];

export function mapOperationalMetricAssumptions(
    operationalMetricData: Maybe<OperationalMetricModel | undefined>,
    monthIndex: number
): AssumptionRowModel[] {
    const resultRows = [] as AssumptionRowModel[];

    if (operationalMetricData && operationalMetricData != null) {
        for(const sourceMetric of operationalMetricData.sourceMetrics) {
            const sourceMetricMapped = mapOperationalMetricSourceMetric(sourceMetric, monthIndex);
            resultRows.push(sourceMetricMapped);
        }
    }


    return resultRows;
}
