import React, {ReactElement, useEffect, useReducer, useState} from "react";
import { Body, Cell, Head, HeaderCell, HeaderRow, Row, Table } from "@zendeskgarden/react-tables";
import css from "../styles/table.module.scss";
import cn from "classnames";
import { useChartOfAccounts } from "../../../contexts/chartofaccounts/ChartOfAccountsContext";
import { formatterDollarUSNoDecimal, formatterPercentWithSign } from "../../../utils/formatters";
import { Button, IconButton, ToggleButton } from "@zendeskgarden/react-buttons";
import { Add, Forum, Notes, Remove, Settings, ToggleOffOutlined, ToggleOn } from "@material-ui/icons";
import { Dropdown, Menu, Trigger } from "@zendeskgarden/react-dropdowns";
import { ReactComponent as ChevronIcon } from '@zendeskgarden/svg-icons/src/16/chevron-down-stroke.svg';
import { ReactComponent as AccountDetailsIcon} from "../../../assets/icons/esr/account-details.svg";
import { Checkbox, Field, Label } from "@zendeskgarden/react-forms";
import { CommentPreviewData } from "../logic/logic";
import { Close, Header, Modal } from "@zendeskgarden/react-modals";
import CommentThreadDrawer, { ICommentAPICallPayload } from "./CommentThreadDrawer";
import { Property } from "../../../contexts/properties/PropertiesContext";
import {
    FinancialEntityType,
    useExportPropertyBudgetLazyQuery,
    useGetClientCommentsForAccountLazyQuery, useGetClientCommentSummaryForAccountLazyQuery,
    useGetUnitsCountLazyQuery,
    VersionType
} from "../../../__generated__/generated_types";
import FileSaver from "file-saver";
import { BudgetingType } from "../../../BudgetingType";
import { formatForExcelFullNoZerosWithMetrics } from "../../../components/budget-export/excel/formatters";
import AccountDetailsModal from "./AccountDetailsModal";
import { getModelingMethodLabel, IFinancialsCOATableRow } from "./helpers";
import { MONTHS } from "../../../constants/Months";
import { getVarianceColor } from "../../../utils/variance";

export interface IFinancialsCOATableProps {
    data: IFinancialsCOATableRow[];
    commentsMap: Map<string, CommentPreviewData>;
    property: Property;
    budgetApproved: boolean;
}

export default function FinancialsCOATable({ data, commentsMap: rawCommentsMap, property, budgetApproved }: IFinancialsCOATableProps): ReactElement {
    const [expandedRows, setExpandedRows] = useState<Set<string>>(new Set());
    const [isNotesOpen, setIsNotesOpen] = useState<boolean>(false);
    const [isCommentsOpen, setIsCommentsOpen] = useState<boolean>(true);
    const [isSettingsOpen, setIsSettingsOpen] = useState<boolean | undefined>(false);
    const [isFormulasOpen, setIsFormulasOpen] = useState<boolean>(true);
    const [isZeroSuppressed, setIsZeroSuppressed] = useState<boolean>(true);
    const [viewingNote, setViewingNote] = useState<string | null>(null);
    const [viewingNoteAccount, setViewingNoteAccount] = useState<string | null>(null);
    const [activeCommentThread, setActiveCommentThread] = useState<ICommentAPICallPayload | null>(null);
    const [showingAccountDetails, setShowingAccountDetails] = useState<string | null>(null);
    const chartOfAccountsConfig = useChartOfAccounts();
    const [commentsMap, updateCommentsMap] = useReducer((map: Map<string, CommentPreviewData>, action: {key: string, val: CommentPreviewData | null}) => {
        const newMap = new Map(map.entries());
        if(action.val === null) {
            newMap.delete(action.key);
        } else {
            newMap.set(action.key, action.val);
        }
        return newMap;
    }, rawCommentsMap);

    const [loadExportData, { data: exportData, loading: exportDataLoading }] = useExportPropertyBudgetLazyQuery({
        fetchPolicy: "no-cache"
    });
    const [getUnitsCount, { data: unitsCountData, loading: unitsCountLoading}] = useGetUnitsCountLazyQuery({
        fetchPolicy: "no-cache"
    });
    const [getAccountCommentSummary, {data: accountCommentSummaryData, loading: accountCommentSummaryLoading, variables: accountCommentSummaryVariables}] = useGetClientCommentSummaryForAccountLazyQuery({
        fetchPolicy: "no-cache",
        notifyOnNetworkStatusChange: true
    });

    useEffect(() => {
        if(!accountCommentSummaryLoading) {
            if(accountCommentSummaryData?.getClientCommentSummaryForAccount) {
                const accountCommentSummary = accountCommentSummaryData.getClientCommentSummaryForAccount;
                updateCommentsMap({
                    key: accountCommentSummary.accountId,
                    val: accountCommentSummary
                });
            } else if(accountCommentSummaryVariables) {
                updateCommentsMap({
                    key: accountCommentSummaryVariables.accountId,
                    val: null
                });
            }
        }
    }, [accountCommentSummaryData, accountCommentSummaryLoading, accountCommentSummaryVariables]);

    const toggleExpandCollapse = (id: string) => {
        setExpandedRows(prev => {
            const newSet = new Set(prev);
            if (newSet.has(id)) {
                newSet.delete(id);
            } else {
                newSet.add(id);
            }
            return newSet;
        });
    };

    const allZeroes = (data: IFinancialsCOATableRow): boolean => {
        const isAllZeroOrNull = (values: (number | null)[]) =>
            values.every(value => value === 0 || value === null);

        return isAllZeroOrNull(data.budgetValues) && isAllZeroOrNull(data.reforecastValues);
    };

    function exportBudget() {
        loadExportData({
            variables: {
                propertyId: property.id,
                requestValueType: VersionType.Budget,
                referenceYear: property.reforecastYear,
                referenceValueType: VersionType.Reforecast,
                includeExtraReferenceYear: false,
                extraReferenceYear: 0,
                extraReferenceValueType: VersionType.Reforecast,
            }
        });
        getUnitsCount({variables: {propertyId: property.id}});
    }

    useEffect(() => {
        if (exportData && !exportDataLoading && unitsCountData && !unitsCountLoading) {
            const formattedDataPromise = formatForExcelFullNoZerosWithMetrics({
                tab: BudgetingType.BUDGET,
                referenceYear: {
                        year: property.reforecastYear,
                        type: VersionType.YearTotal
                },
                data: exportData,
                config: {
                    year: property.reforecastYear,
                    properties: {
                        currentProperty: {
                            name: property.name,
                        },
                        unitsCount: unitsCountData.propertyUnitCounts.map(row => row.count).sum(),
                        unitsTotalSQFT: unitsCountData.propertyUnitCounts.map(row => row.sqft).sum()
                    },
                    chartOfAccountsConfig
                }
            });
            formattedDataPromise.then(saveData => {
                const fileName = `${property.budgetYear} Budget - ${property.name}`;
                FileSaver.saveAs(saveData, `${fileName}.xlsx`);
            });

        }
    }, [exportData, exportDataLoading, unitsCountData, unitsCountLoading]);

    const renderRow = (account: IFinancialsCOATableRow, depth: number, isTotal = false):ReactElement => {
        const commentData = commentsMap.get(account.id);
        const isExpanded = expandedRows.has(account.id);
        return (
                <Row>
                    <Cell
                        className={cn(css.fixedCol, css.firstCol, account.isTopLevelAccount || isTotal ? css.greyCell : "")}
                        style={{
                            paddingLeft: account.children.length > 0 ? `${depth * 20}px` : `${depth + 1 * 20}px`,
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                        }}
                    >
                        <div style={{ display: "flex", alignItems: "center" }}>
                            {!isTotal && account.children.length > 0 && (
                                <IconButton
                                    size="small"
                                    className={css.expandButton}
                                    onClick={() => toggleExpandCollapse(account.id)}
                                >
                                    {
                                        expandedRows.has(account.id) ?
                                        <Remove />
                                        : <Add />
                                    }
                                </IconButton>
                            )}
                            {isTotal ? "Total ":""}{account.name}{account.number ? ` (${account.number})` : ""}
                        </div>
                        {!isTotal && account.type === FinancialEntityType.Account && (
                            <IconButton
                                size="small"
                                onClick={() => setShowingAccountDetails(account.id)}
                            >
                                <AccountDetailsIcon fontSize="small" />
                            </IconButton>
                        )}
                    </Cell>
                    { isFormulasOpen && (
                        <Cell className={account.isTopLevelAccount || isTotal ? css.greyCell : ""}>
                            <div className={css.modelingMethodCell}>
                            {!isTotal && account.modelingType ?
                                account.modelingType.length === 0 ? "" :
                                account.modelingType.length === 1 ?
                                    <span className={cn(css.methodBadge, account.hasOverrides ? css.hasOverride : undefined)}>
                                        {getModelingMethodLabel(account.modelingType[0])}
                                    </span> :
                                <span className={css.methodBadge}>2+</span>
                            : ""}
                            </div>
                        </Cell>
                    )}

                    {account.budgetValues.map((val, index) => (
                        <Cell className={account.isTopLevelAccount || isTotal ? css.greyCell : ""} key={index}>
                            <div>{isExpanded && !isTotal ? "" : val != null ? formatterDollarUSNoDecimal.format(val) : "-"}</div>
                        </Cell>
                    ))}

                    <Cell className={account.isTopLevelAccount || isTotal ? css.greyCell : ""}>
                        <div>{isExpanded && !isTotal ? "" : formatterDollarUSNoDecimal.format(account.budgetTotal)}</div>
                    </Cell>
                    <Cell className={account.isTopLevelAccount || isTotal ? css.greyCell : ""}>
                        <div>{isExpanded && !isTotal ? "" : account.reforecastTotal != null ? formatterDollarUSNoDecimal.format(account.reforecastTotal) : "-"}</div>
                    </Cell>
                    <Cell className={account.isTopLevelAccount || isTotal ? css.greyCell : ""}>
                        <div
                            style={{color: account.varianceAmount === null ? "" : getVarianceColor(account.varianceAmount, account.budgetComponentType, account.budgetComponentNegate)}}
                        >{isExpanded && !isTotal ? "" : account.varianceAmount != null ? formatterDollarUSNoDecimal.format(account.varianceAmount) : "-"}</div>
                    </Cell>
                    <Cell className={account.isTopLevelAccount || isTotal ? css.greyCell : ""}>
                        <div
                            style={{color: account.variancePercent === null ? "" : getVarianceColor(account.variancePercent, account.budgetComponentType, account.budgetComponentNegate)}}
                        >{isExpanded && !isTotal ? "" : account.variancePercent != null ? formatterPercentWithSign.format(account.variancePercent) : "-"}</div>
                    </Cell>

                    {
                        isNotesOpen &&
                            <Cell className={cn(css.fixedCol, css.endCols, css.notesColumn, ((account.isTopLevelAccount || isTotal)&& css.greyCell))}>
                                <div>
                                    {
                                        !account.isTopLevelAccount && !isTotal &&
                                            <div className={css.noteAndCommentCells}>
                                                <div className={css.noteAndCommentCellsText}>{ account.note ? account.note : "" }</div>
                                                <IconButton
                                                    isBasic={false}
                                                    className={css.noteAndCommentCellsIcon}
                                                    size="small"
                                                    disabled={!account.note || account.note?.trim() == ""}
                                                    onClick={() => {
                                                        setViewingNote(account.note);
                                                        setViewingNoteAccount(`${account.name} ${account.number ? ` (${account.number})` : ""}`);
                                                    }}
                                                >
                                                    <Notes />
                                                </IconButton>
                                            </div>
                                    }
                                </div>
                            </Cell>
                    }

                    {
                        isCommentsOpen &&
                            <Cell className={cn(css.fixedCol, css.endCols, css.commentsColumn, ((account.isTopLevelAccount || isTotal) && css.greyCell))}>
                                <div>
                                    {
                                        !account.isTopLevelAccount && !isTotal &&
                                            <div className={css.noteAndCommentCells}>
                                                <div className={css.noteAndCommentCellsText}>{commentData ? `${commentData.count} comment${commentData.count == 1 ? '' : 's'}` : ""}</div>
                                                <IconButton
                                                    isBasic={false}
                                                    className={css.noteAndCommentCellsIcon}
                                                    size="small"
                                                    onClick={() =>
                                                        setActiveCommentThread({
                                                            accountId: account.id,
                                                            budgetYear: property.budgetYear,
                                                            propertyId: property.id,
                                                            accountDescription: account.name.concat(account.number ? ` (${account.number})` : ""),
                                                        })
                                                    }
                                                >
                                                    <Forum />
                                                </IconButton>
                                            </div>
                                    }
                                </div>
                            </Cell>
                    }
                </Row>
        );
    };

    const renderRows = (rows: IFinancialsCOATableRow[], parentId: string | undefined = undefined, depth = 0) => {
        return rows
            .filter(row => row.parentId === parentId && (!isZeroSuppressed || !allZeroes(row)))
            .map(account =>
                    <React.Fragment key={account.id}>
                        {renderRow(account, depth)}
                        {expandedRows.has(account.id) && renderRows(rows, account.id, depth + 1)}
                        {// Total row for expanded category
                         !account.isTopLevelAccount
                            && expandedRows.has(account.id)
                            && account.type !== FinancialEntityType.Account ?
                            renderRow(account, depth + 2, true)
                            : <></>
                        }
                    </React.Fragment>
            );
    };

    return (
        <div className={cn(css.wrapper, (isNotesOpen && css.notesOpen), (isCommentsOpen && css.commentsOpen))}>
            <div className={css.topHeader}>
                <h4>{property.name} | Financial Overview</h4>

                <div className={css.headerActions}>
                    <Dropdown
                        isOpen={isSettingsOpen}
                        onStateChange={options => setIsSettingsOpen(options.isOpen)}
                    >
                        <Trigger>
                            <Button isBasic>
                                <Button.StartIcon>
                                    <Settings />
                                </Button.StartIcon>
                                <label>Settings</label>
                                <Button.EndIcon isRotated={isSettingsOpen}>
                                    <ChevronIcon />
                                </Button.EndIcon>
                            </Button>
                        </Trigger>
                        <Menu className={css.settingsMenu} placement="bottom-end">
                            <div className={css.visibilityOptions}>
                                <h5 className={css.settingsMenuHeader}>Show/Hide Columns</h5>
                                <Field>
                                    <Checkbox checked={isFormulasOpen} onChange={() => setIsFormulasOpen(!isFormulasOpen)}>
                                        <Label>Formulas (fx)</Label>
                                    </Checkbox>
                                </Field>
                                <Field>
                                    <Checkbox checked={isNotesOpen} onChange={() => setIsNotesOpen(!isNotesOpen)}>
                                        <Label>Notes</Label>
                                    </Checkbox>
                                </Field>
                                <Field>
                                    <Checkbox checked={isCommentsOpen} onChange={() => setIsCommentsOpen(!isCommentsOpen)}>
                                        <Label>Comments</Label>
                                    </Checkbox>
                                </Field>
                            </div>
                            <div className={css.otherOptions}>
                                <ToggleButton
                                    isBasic
                                    onClick={() => setIsZeroSuppressed(!isZeroSuppressed)}
                                >
                                    { isZeroSuppressed ? <ToggleOn fontSize="large" /> : <ToggleOffOutlined fontSize="large" /> } <span style={{marginLeft: ".5rem"}}>Suppress zeroes</span>
                                </ToggleButton>
                            </div>
                        </Menu>
                    </Dropdown>
                    <Button onClick={() => exportBudget()}>Export</Button>
                </div>
            </div>

            <div className={css.tableWrapper}>
                <Table className={css.table}>
                    <Head className={css.headerRow}>
                        <HeaderRow>
                            <HeaderCell className={cn(css.fixedCol, css.firstCol)}>Description</HeaderCell>
                            { isFormulasOpen && <HeaderCell><div className={css.functionIcon}>fx</div></HeaderCell>}
                            {
                                MONTHS.map(month => <HeaderCell><div>{ month }</div></HeaderCell>)
                            }
                            <HeaderCell><div>Total</div></HeaderCell>
                            <HeaderCell><div>Reforecast</div></HeaderCell>
                            <HeaderCell><div>Variance $</div></HeaderCell>
                            <HeaderCell><div>Variance %</div></HeaderCell>
                            {
                                isNotesOpen &&
                                    <HeaderCell className={cn(css.fixedCol, css.endCols, css.notesColumn)}>Notes</HeaderCell>
                            }

                            {
                                isCommentsOpen &&
                                    <HeaderCell className={cn(css.fixedCol, css.endCols, css.commentsColumn)}>Comments</HeaderCell>
                            }
                        </HeaderRow>
                    </Head>
                    <Body>{renderRows(data)}</Body>
                </Table>
            </div>

            {(viewingNote != null) && (
                <Modal onClose={() => {
                    setViewingNote(null);
                    setViewingNoteAccount(null);
                }}>
                    <Header>Note - {viewingNoteAccount}</Header>
                    <Body className={css.noteModalBody}>
                        { viewingNote }
                    </Body>
                    <Close aria-label="Close modal" />
                </Modal>
            )}

            {
                <CommentThreadDrawer
                    data={activeCommentThread}
                    readOnly={budgetApproved}
                    onClose={() => {
                        if(activeCommentThread) {
                            getAccountCommentSummary({
                                variables: {
                                    propertyId: activeCommentThread.propertyId,
                                    budgetYear: activeCommentThread.budgetYear,
                                    accountId: activeCommentThread.accountId
                                }
                            });
                        }
                        setActiveCommentThread(null);
                    }}
                />
            }
            {showingAccountDetails && (
                <AccountDetailsModal
                    property={property}
                    accountId={showingAccountDetails}
                    accounts={data.filter(row => row.type === FinancialEntityType.Account && (!isZeroSuppressed || !allZeroes(row)))}
                    onClose={() => setShowingAccountDetails(null)}
                />
            )}
        </div>
    );
}
