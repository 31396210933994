import React, { ChangeEvent, ReactElement, useEffect, useRef, useState } from "react";
import { Dropdown, Field, Item, Menu, Select } from "@zendeskgarden/react-dropdowns";
import {Field as FormField, Input, Radio} from "@zendeskgarden/react-forms";
import { TooltipModal } from "@zendeskgarden/react-modals";

import downChevron from "../../../../../../assets/icons/chevron/down.svg";
import { MonthlyAdjustmentType, MonthlyAverageBasePeriod } from "../../../../../../__generated__/generated_types";
import { IFormulaBarUpdates } from "../../../../../../contexts/account/data/useFormulaBarUpdates";
import MonthPicker, { MonthPickerType } from "../month-picker/MonthPicker";
import { useProperties } from "../../../../../../contexts/properties/PropertiesContext";
import { MONTHS } from "../../../../../../constants/Months";
import { stringToFixedFloat } from "../../logic/utils";

import * as css from "./styles/css.module.scss";
import * as monthlyAvgCss from "./styles/monthly-average-menu/css.module.scss";
import { MonthlyAverageBasePeriodInverseMap } from "../formula-nodes/MonthlyAverageGrowthDriverFxNode";


interface ILookbackOption {
    label: number,
    value: MonthlyAverageBasePeriod,
}

enum BasePeriodInputType {
    MONTH_RANGE = "MONTH_RANGE",
    LOOKBACK = "LOOKBACK",
}

const lookbackOptions: ILookbackOption[] = [
    { label: 1, value: MonthlyAverageBasePeriod.LastMonth },
    { label: 3, value: MonthlyAverageBasePeriod.LastThreeMonthsActuals },
    { label: 6, value: MonthlyAverageBasePeriod.LastSixMonthsActuals },
    { label: 12, value: MonthlyAverageBasePeriod.LastTwelveMonthsActuals },
];

interface IGrowthMenuProps {
    fbUpdates: IFormulaBarUpdates,
    handleCloseParent?: () => void,
    level: number,
    triggerNode?: ReactElement,
}

export default function MonthlyAverageMenu({
    fbUpdates,
    // handleCloseParent,
    level,
    triggerNode,
}: IGrowthMenuProps): React.ReactElement {
    const { currentProperty } = useProperties();
    const [startMonth, setStartMonth] = useState<number>(0);
    const [endMonth, setEndMonth] = useState<number>(11);
    const [startYear, setStartYear] = useState<number>();
    const [endYear, setEndYear] = useState<number>();
    const [refElement, setRefElement] = useState<HTMLDivElement|null>(null);
    const [startPeriodRefElement, setStartPeriodRefElement] = useState<HTMLDivElement|null>(null);
    const [endPeriodRefElement, setEndPeriodRefElement] = useState<HTMLDivElement|null>(null);

    const [selectedLookbackOption, setSelectedLookbackOption] = useState<number>(3);
    const [selectedBasePeriodInputType, setSelectedBasePeriodInputType] = useState<BasePeriodInputType>(BasePeriodInputType.MONTH_RANGE);

    const monthlyAverageBasePeriodFromFxBar = fbUpdates.parsedFormDrivers.monthlyAverage[0]?.monthlyAverageBasePeriod;
    useEffect(() => {
        if (monthlyAverageBasePeriodFromFxBar) {
            setSelectedBasePeriodInputType(monthlyAverageBasePeriodFromFxBar === MonthlyAverageBasePeriod.CustomLookbackPeriod ? BasePeriodInputType.MONTH_RANGE : BasePeriodInputType.LOOKBACK);
            if (monthlyAverageBasePeriodFromFxBar !== MonthlyAverageBasePeriod.CustomLookbackPeriod) {
                setSelectedLookbackOption(MonthlyAverageBasePeriodInverseMap[monthlyAverageBasePeriodFromFxBar]);
            }
        }
    }, [monthlyAverageBasePeriodFromFxBar]);

    const monthlyAdjustmentType = fbUpdates.parsedFormDrivers.monthlyAverage[0]?.monthlyAdjustmentType;
    const monthlyAdjustmentValue = fbUpdates.parsedFormDrivers.monthlyAverage[0]?.monthlyAdjustmentValue;
    const [dollarMonthlyAdjustment, setDollarMonthlyAdjustment] = useState<string>(monthlyAdjustmentType === MonthlyAdjustmentType.Dollar ? (typeof monthlyAdjustmentValue === "number" ? monthlyAdjustmentValue.toString() : "0") : "0");
    const [percentMonthlyAdjustment, setPercentMonthlyAdjustment] = useState<string>(monthlyAdjustmentType === MonthlyAdjustmentType.Percent ? (typeof monthlyAdjustmentValue === "number" ? monthlyAdjustmentValue.toString() : "0") : "0");

    const triggerRef = useRef<HTMLDivElement>(null);
    const startPeriodRef = useRef<HTMLDivElement>(null);
    const endPeriodRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (currentProperty) {
            // Default end month should be the last month of actuals
            setEndMonth(currentProperty.reforecastStartMonthIndex - 1);
            setStartYear(currentProperty.reforecastYear);
            setEndYear(currentProperty.reforecastYear);
        }
    }, [currentProperty]);

    /**
     * Default the month-picker selections to what's coming from the backend
    */
    useEffect(() => {
        const lookbackPeriodStartMonth = fbUpdates.parsedFormDrivers.monthlyAverage[0]?.lookbackPeriodStart?.split("-")[1];
        const lookbackPeriodStartYear = fbUpdates.parsedFormDrivers.monthlyAverage[0]?.lookbackPeriodStart?.split("-")[0];
        const lookbackPeriodEndMonth = fbUpdates.parsedFormDrivers.monthlyAverage[0]?.lookbackPeriodEnd?.split("-")[1];
        const lookbackPeriodEndYear = fbUpdates.parsedFormDrivers.monthlyAverage[0]?.lookbackPeriodEnd?.split("-")[0];

        if (lookbackPeriodStartMonth && lookbackPeriodEndMonth && lookbackPeriodStartYear && lookbackPeriodEndYear) {
            setStartMonth(parseInt(lookbackPeriodStartMonth) - 1);
            setStartYear(parseInt(lookbackPeriodStartYear));
            setEndMonth(parseInt(lookbackPeriodEndMonth) - 1);
            setEndYear(parseInt(lookbackPeriodEndYear));
        }
    }, [fbUpdates.parsedFormDrivers.monthlyAverage[0]?.id]);

    useEffect(() => {
        if (selectedBasePeriodInputType === BasePeriodInputType.LOOKBACK) {
            setStartPeriodRefElement(null);
            setEndPeriodRefElement(null);
        }
    }, [selectedBasePeriodInputType]);

    const beforeCloseNode = (value: string, validate = false) => {
        if (value === '-' || value.length === 0) {
            fbUpdates.updateMonthlyAdjustmentValue(0);
        } else {
            if (validate && (parseFloat(value) < -100 || parseFloat(value) > 100)) {
                fbUpdates.updateMonthlyAdjustmentValue(0);
                setPercentMonthlyAdjustment("0");
            }  else {
                fbUpdates.updateMonthlyAdjustmentValue(stringToFixedFloat(value, 2, false));
            }
        }
    };

    useEffect(() => {
        if (monthlyAdjustmentType === MonthlyAdjustmentType.Dollar) {
            beforeCloseNode(dollarMonthlyAdjustment);
        } else if (monthlyAdjustmentType === MonthlyAdjustmentType.Percent) {
            beforeCloseNode(percentMonthlyAdjustment, true);
        }
    }, [monthlyAdjustmentType]);

    const handleSetStartMonth = (month: number, year: number) => {
        setStartMonth(month);
        fbUpdates.updateMonthlyAverageStartMonthYear(month, year);
        setStartPeriodRefElement(null);
    };

    const handleSetEndMonth = (month: number, year: number) => {
        setEndMonth(month);
        fbUpdates.updateMonthlyAverageEndMonthYear(month, year);
        setEndPeriodRefElement(null);
    };

    const handleRootClick = () => {
        setRefElement(triggerRef.current);
    };

    const handleStartPeriodClick = () => {
        setEndPeriodRefElement(null);
        // To remove the overlap period when both popovers are visible
        setTimeout(() => setStartPeriodRefElement(startPeriodRef.current), 200);
    };

    const handleEndPeriodClick = () => {
        setStartPeriodRefElement(null);
        // To remove the overlap period when both popovers are visible
        setTimeout(() => setEndPeriodRefElement(endPeriodRef.current), 200);
    };

    const handleCloseRoot = () => {
        setRefElement(null);
    };

    const handleCloseStartPeriodPopover = () => {
        setStartPeriodRefElement(null);
    };

    const handleCloseEndPeriodPopover = () => {
        setEndPeriodRefElement(null);
    };

    const handleCloseMonthlyAdjustmentNode = () => {
        if (monthlyAdjustmentType === MonthlyAdjustmentType.Dollar) {
            beforeCloseNode(dollarMonthlyAdjustment);
        } else if (monthlyAdjustmentType === MonthlyAdjustmentType.Percent) {
            beforeCloseNode(percentMonthlyAdjustment, true);
        }

        handleCloseRoot();
    };

    const handleSelectedLookbackOption = (label: number) => {
        setSelectedLookbackOption(label);
        const value = lookbackOptions.find((e) => e.label === label)?.value;
        if (value) {
            fbUpdates.updateMonthlyAverageBasePeriod(value);
        }
    };

    const handleSetSelectedLookbackOptionAsMonthRange = () => {
        setSelectedBasePeriodInputType(BasePeriodInputType.MONTH_RANGE);
        fbUpdates.updateMonthlyAverageBasePeriod(MonthlyAverageBasePeriod.CustomLookbackPeriod);
    };

    const handleSetSelectedLookbackOptionAsLookback = () => {
        setSelectedBasePeriodInputType(BasePeriodInputType.LOOKBACK);
        const value = lookbackOptions.find((e) => e.label === selectedLookbackOption)?.value;
        if (value) {
            fbUpdates.updateMonthlyAverageBasePeriod(value);
        }
    };

    const handleDollarMonthlyAdjustmentChange = (event: ChangeEvent<HTMLInputElement>) => {
        setDollarMonthlyAdjustment(event.target.value);
    };

    const handlePercentMonthlyAdjustmentChange = (event: ChangeEvent<HTMLInputElement>) => {
        setPercentMonthlyAdjustment(event.target.value);
    };

    /**
     * Close the monthly adjustment popup if the user presses enter while in the input field
     */
    const handleMonthlyAdjustmentInputKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === "Enter") {
            handleCloseMonthlyAdjustmentNode();
        }
    };

    if (!currentProperty || !startYear || !endYear) {
        return <></>;
    }

    const minMonth = 0;
    const maxMonth = 11;
    const minYear = currentProperty.reforecastYear - 2;
    const maxYear = currentProperty.reforecastYear;

    const chevronDownIcon = <img className={monthlyAvgCss.chevDown} src={downChevron} alt="down" width="12px" height="12px" />;

    const rootDropdown = (
        <Dropdown
            isOpen
            onSelect={(_item) => null}
            downshiftProps={{ defaultHighlightedIndex: 0 }}
        >
            <div className={css.menuWrapper}>
                <div className={monthlyAvgCss.menuHeader}>
                    Select months to average
                </div>
                <div className={`${monthlyAvgCss.row} ${monthlyAvgCss.radioRow} ${monthlyAvgCss.basePeriodRadioRow}`}>
                    <div className={`${monthlyAvgCss.col} ${monthlyAvgCss.radioCol}`}>
                        <FormField>
                            <Radio
                                key={BasePeriodInputType.MONTH_RANGE}
                                name={BasePeriodInputType.MONTH_RANGE}
                                value={BasePeriodInputType.MONTH_RANGE}
                                checked={selectedBasePeriodInputType === BasePeriodInputType.MONTH_RANGE}
                                onChange={handleSetSelectedLookbackOptionAsMonthRange}
                            />
                        </FormField>
                    </div>
                    <div className={monthlyAvgCss.dateRangeLabel}>
                        Average actuals from&nbsp;
                    </div>
                    <div className={monthlyAvgCss.monthPickerTarget}>
                        <div
                            className={css.triggerNode}
                            ref={startPeriodRef}
                            onClick={handleStartPeriodClick}
                            style={inlineStyles.triggerNode}
                        >
                            {`${MONTHS[startMonth]} ${startYear}`}
                            {chevronDownIcon}
                        </div>
                        <div className={monthlyAvgCss.monthPickerTargetChild}>
                            <TooltipModal
                                className={css.tooltipModal}
                                hasArrow={false}
                                referenceElement={startPeriodRefElement}
                                onClose={handleCloseStartPeriodPopover}
                                placement="bottom"
                                isAnimated
                            >
                                <TooltipModal.Body className={css.tooltipModalBody}>
                                    <div className={`${css.menuWrapper} ${monthlyAvgCss.menuWrapper}`}>
                                        <Dropdown
                                            isOpen
                                            onSelect={(_item) => null}
                                            downshiftProps={{ defaultHighlightedIndex: 0 }}
                                        >
                                            <div style={inlineStyles.rootMenu}>
                                                <MonthPicker
                                                    minMonth={minMonth}
                                                    maxMonth={endMonth}
                                                    minYear={minYear}
                                                    maxYear={endYear}
                                                    defaultMonth={startMonth}
                                                    defaultYear={startYear}
                                                    setMonth={handleSetStartMonth}
                                                    setYear={setStartYear}
                                                    type={MonthPickerType.FROM}
                                                />
                                            </div>
                                        </Dropdown>
                                    </div>
                                </TooltipModal.Body>
                            </TooltipModal>
                        </div>
                    </div>
                    <div className={monthlyAvgCss.dateRangeLabel}>
                        &nbsp;to&nbsp;
                    </div>
                    <div className={monthlyAvgCss.monthPickerTarget}>
                        <div
                            className={css.triggerNode}
                            ref={endPeriodRef}
                            onClick={handleEndPeriodClick}
                            style={inlineStyles.triggerNode}
                        >
                                {`${MONTHS[endMonth]} ${endYear}`}
                                {chevronDownIcon}
                        </div>
                        <div className={monthlyAvgCss.monthPickerTargetChild}>
                            <TooltipModal
                                className={css.tooltipModal}
                                hasArrow={false}
                                referenceElement={endPeriodRefElement}
                                onClose={handleCloseEndPeriodPopover}
                                placement="bottom"
                                isAnimated
                            >
                                <TooltipModal.Body className={css.tooltipModalBody}>
                                    <div className={`${css.menuWrapper} ${monthlyAvgCss.menuWrapper}`}>
                                        <Dropdown
                                            isOpen
                                            onSelect={(_item) => null}
                                            downshiftProps={{ defaultHighlightedIndex: 0 }}
                                        >
                                            <div style={inlineStyles.rootMenu}>
                                                <MonthPicker
                                                    minMonth={startMonth}
                                                    maxMonth={maxMonth}
                                                    minYear={startYear}
                                                    maxYear={maxYear}
                                                    defaultMonth={endMonth}
                                                    defaultYear={endYear}
                                                    setMonth={handleSetEndMonth}
                                                    setYear={setEndYear}
                                                    type={MonthPickerType.TO}
                                                />
                                            </div>
                                        </Dropdown>
                                    </div>
                                </TooltipModal.Body>
                            </TooltipModal>
                        </div>
                    </div>
                </div>
                <div className={`${monthlyAvgCss.row} ${monthlyAvgCss.radioRow} ${monthlyAvgCss.basePeriodRadioRow}`}>
                    <div className={`${monthlyAvgCss.col} ${monthlyAvgCss.radioCol}`}>
                        <FormField>
                            <Radio
                                key={BasePeriodInputType.LOOKBACK}
                                name={BasePeriodInputType.LOOKBACK}
                                value={BasePeriodInputType.LOOKBACK}
                                checked={selectedBasePeriodInputType === BasePeriodInputType.LOOKBACK}
                                onChange={handleSetSelectedLookbackOptionAsLookback}
                            />
                        </FormField>
                    </div>
                    <div className={`${monthlyAvgCss.col} ${monthlyAvgCss.dateRangeLabel}`}>
                        Average of last&nbsp;
                    </div>
                    <div className={monthlyAvgCss.col}>
                    <div className={`${css.menuWrapper} ${monthlyAvgCss.lookbackWrapper}`}>
                        <Dropdown
                            inputValue=""
                            selectedItem={selectedLookbackOption}
                            onSelect={item => handleSelectedLookbackOption(item)}
                            onInputValueChange={() => null}
                            downshiftProps={{ defaultHighlightedIndex: 0 }}
                        >
                            <Field className="field">
                                <Select disabled={selectedBasePeriodInputType !== BasePeriodInputType.LOOKBACK} style={inlineStyles.select}>{selectedLookbackOption}</Select>
                            </Field>
                            <Menu className={monthlyAvgCss.lookbackMenu}>
                                {lookbackOptions.map((option) => (
                                    <Item key={option.value} value={option.label} style={{display: "flex", flexDirection: "row"}}>
                                        <span
                                            className={selectedLookbackOption !== option.label ? monthlyAvgCss.ghostPadding : ''}
                                        >
                                            {option.label}
                                        </span>
                                    </Item>
                                ))}
                            </Menu>
                        </Dropdown>
                        </div>
                    </div>
                    <div className={`${monthlyAvgCss.col} ${monthlyAvgCss.dateRangeLabel}`}>
                        &nbsp; months of actuals
                    </div>
                </div>
            </div>
        </Dropdown>
    );

    const monthlyAdjustmentForm = (
        <div className={`${css.menuWrapper} ${monthlyAvgCss.adjustmentWrapper}`}>
            <div className={monthlyAvgCss.col}>
                <div className={`${monthlyAvgCss.row} ${monthlyAvgCss.menuHeader}`}>
                    Input monthly adjustment
                </div>
                <div className={`${monthlyAvgCss.row} ${monthlyAvgCss.radioRow}`}>
                    <div className={`${monthlyAvgCss.col} ${monthlyAvgCss.radioCol}`}>
                        <FormField>
                            <Radio
                                name={MonthlyAdjustmentType.Percent}
                                value={MonthlyAdjustmentType.Percent}
                                checked={fbUpdates.parsedFormDrivers.monthlyAverage[0]?.monthlyAdjustmentType === MonthlyAdjustmentType.Percent}
                                onChange={() => fbUpdates.updateMonthlyAdjustmentType(MonthlyAdjustmentType.Percent)}
                            />
                        </FormField>
                    </div>
                    <div className={`${monthlyAvgCss.col} ${monthlyAvgCss.inputCol} ${percentMonthlyAdjustment !== undefined && monthlyAvgCss.percentInput}`}>
                        <FormField>
                            <Input
                                placeholder="%"
                                value={percentMonthlyAdjustment}
                                onChange={handlePercentMonthlyAdjustmentChange}
                                type="number"
                                disabled={fbUpdates.parsedFormDrivers.monthlyAverage[0]?.monthlyAdjustmentType !== MonthlyAdjustmentType.Percent}
                                onKeyDown={handleMonthlyAdjustmentInputKeyDown}
                            />
                        </FormField>
                    </div>
                </div>
                <div className={`${monthlyAvgCss.row} ${monthlyAvgCss.radioRow}`}>
                    <div className={`${monthlyAvgCss.col} ${monthlyAvgCss.radioCol}`}>
                        <FormField>
                            <Radio
                                name={MonthlyAdjustmentType.Dollar}
                                value={MonthlyAdjustmentType.Dollar}
                                checked={fbUpdates.parsedFormDrivers.monthlyAverage[0]?.monthlyAdjustmentType === MonthlyAdjustmentType.Dollar}
                                onChange={() => fbUpdates.updateMonthlyAdjustmentType(MonthlyAdjustmentType.Dollar)}
                            />
                        </FormField>
                    </div>
                    <div className={`${monthlyAvgCss.col} ${monthlyAvgCss.inputCol} ${dollarMonthlyAdjustment !== undefined && monthlyAvgCss.dollarInput}`}>
                        <FormField>
                            <Input
                                placeholder="$"
                                value={dollarMonthlyAdjustment}
                                onChange={handleDollarMonthlyAdjustmentChange}
                                type="number"
                                disabled={fbUpdates.parsedFormDrivers.monthlyAverage[0]?.monthlyAdjustmentType !== MonthlyAdjustmentType.Dollar}
                                onKeyDown={handleMonthlyAdjustmentInputKeyDown}
                            />
                        </FormField>
                    </div>
                </div>
            </div>
        </div>
    );

    if (level === 0) {
        if (triggerNode) {
            return (
                <>
                    <div
                        className={css.triggerNode}
                        ref={triggerRef}
                        onClick={handleRootClick}
                    >
                        {triggerNode}
                    </div>
                    <TooltipModal
                        className={css.tooltipModal}
                        hasArrow={false}
                        referenceElement={refElement}
                        onClose={handleCloseRoot}
                        placement="bottom"
                        isAnimated
                    >
                        <TooltipModal.Body className={css.tooltipModalBody}>
                            {rootDropdown}
                        </TooltipModal.Body>
                    </TooltipModal>
                </>
            );
        }
        return rootDropdown;
    }

    if (level === 1) {
        if (triggerNode) {
            return (
                <>
                    <div
                        className={css.triggerNode}
                        ref={triggerRef}
                        onClick={handleRootClick}
                    >
                        {triggerNode}
                    </div>
                    <TooltipModal
                        className={css.tooltipModal}
                        hasArrow={false}
                        referenceElement={refElement}
                        onClose={handleCloseMonthlyAdjustmentNode}
                        placement="bottom"
                        isAnimated
                    >
                        <TooltipModal.Body className={css.tooltipModalBody}>
                            {monthlyAdjustmentForm}
                        </TooltipModal.Body>
                    </TooltipModal>
                </>
            );
        }
        return monthlyAdjustmentForm;
    }

    return <></>;
}

/**
 * To override ZDG styles and avoid using !important all the time
 */
const inlineStyles = ({
    rootMenu: {marginTop: "30px", position: "absolute" as 'absolute', transform: "none", width: "235px"},
    select: {backgroundColor: "transparent", border: "none", minWidth: "65px"},
    triggerNode: {cursor: "pointer"},
});
