import { ReactElement, Fragment } from 'react';
import './App.css';
import './styles.scss';
import './main.scss';
import './styles/handsontable/handsontable.vizibly.full.scss';

import AuthProvider, { AuthIsNotSignedIn, AuthIsSignedIn } from './contexts/AuthContext';
import { ChartOfAccountsProvider } from './contexts/chartofaccounts/ChartOfAccountsContext';
import { MainRoute } from './routes/main/MainRoute';
import { PropertiesProvider } from './contexts/properties/PropertiesContext';
import { SettingsProvider } from './contexts/settings/SettingsContext';
import { SignInRoute } from './routes/auth/SignInRoute';
import { VersionsProvider } from './contexts/versions/VersionsContext';
import { AuthorizationContextProvider } from "./authorization/AuthorizationContext";
import { AccountContextProvider } from "./contexts/account/AccountContext";
import { FeatureFlagContextProvider } from './feature-flag/FeatureFlagContextProvider';

import { withLDProvider } from 'launchdarkly-react-client-sdk';
import {IntercomWrapperRegular} from "./intercom/IntercomWrapperRegular";
import { AppDecider } from "./routes/AppDecider";
import { GuestRoute } from "./routes/guest/GuestRoute";
import { GuestAuthenticationContextProvider } from "./contexts/guest-auth-context/GuestAuthContext";
import { IntercomWrapperGuest } from "./intercom/IntercomWrapperGuest";
import { MixPanelWrapperGuest } from "./mixpanel/MixPanelWrapperGuest";
import { MixPanelWrapperRegular } from "./mixpanel/MixPanelWrapperRegular";

const LDClientKey = process.env['REACT_APP_LAUNCH_DARKLY_CLIENT_KEY'];

const App = ():ReactElement => (<Fragment>
    {/* <AppStarter /> */}
    <AppDecider>
        <AppDecider.GuestRoute>
            <GuestAuthenticationContextProvider>
                <IntercomWrapperGuest>
                    <MixPanelWrapperGuest>
                        <GuestRoute/>
                    </MixPanelWrapperGuest>
                </IntercomWrapperGuest>
            </GuestAuthenticationContextProvider>
        </AppDecider.GuestRoute>
        <AppDecider.RegularRoute>
            <AuthProvider>
                <IntercomWrapperRegular>
                    <MixPanelWrapperRegular>
                        <AuthIsSignedIn>
                            <AuthorizationContextProvider>
                                <FeatureFlagContextProvider>
                                    <VersionsProvider>
                                        <PropertiesProvider>
                                            <ChartOfAccountsProvider>
                                                <SettingsProvider>
                                                    <AccountContextProvider>
                                                        <MainRoute />
                                                    </AccountContextProvider>
                                                </SettingsProvider>
                                            </ChartOfAccountsProvider>
                                        </PropertiesProvider>
                                    </VersionsProvider>
                                </FeatureFlagContextProvider>
                            </AuthorizationContextProvider>
                        </AuthIsSignedIn>
                        <AuthIsNotSignedIn>
                            <SignInRoute />
                        </AuthIsNotSignedIn>
                    </MixPanelWrapperRegular>
                </IntercomWrapperRegular>
            </AuthProvider>
        </AppDecider.RegularRoute>
    </AppDecider>
</Fragment>);

export default withLDProvider({
    clientSideID: LDClientKey ?? 'LaunchDarkly client ID not found',
})(App);
