import { useEffect } from "react";
import { useNavigatableArray } from "../../../../../../utils/hooks/useNavigatableArray";

export type MVRNavigationAccount = {
    id: string,
    glName: string,
    glNumber: string | undefined
}
export interface IMonthlyVarianceModalDataProps {
    propertyId: string,
    accountId: string,
    accounts: MVRNavigationAccount[],
    year: number,
    month: number,
}

type IMonthlyVarianceModalDataReturn = {
    currentAccount: MVRNavigationAccount
    nextAccount: () => void
    prevAccount: () => void
    selectAccount: (accountId: string) => void
}

export default function useMonthlyVarianceModalData(props: IMonthlyVarianceModalDataProps): IMonthlyVarianceModalDataReturn {
    const initialAccountIndex = props.accounts.findIndex(account => account.id == props.accountId);
    const {
        prev: prevAccount,
        next: nextAccount,
        select: _selectAccount,
        selectedItem: currentAccount
    } = useNavigatableArray({
        items: props.accounts,
        initialPosition: initialAccountIndex,
        allowCircularNavigation: true
    });

    useEffect(
        () => {
            if (currentAccount.id !== props.accountId) {
                const accountIndex = props.accounts.findIndex(account => account.id == props.accountId);
                _selectAccount(accountIndex);
            }
        },
        [props.accountId]
    );

    const selectAccount = (accountId: string) => {
        const accountIndex = props.accounts.findIndex(account => account.id == accountId);
        _selectAccount(accountIndex);
    };

    return {
        currentAccount: currentAccount,
        prevAccount: prevAccount,
        nextAccount: nextAccount,
        selectAccount: selectAccount
    };
}