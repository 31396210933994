import FileSaver from "file-saver";
import { BudgetingType } from "../../../BudgetingType";
import { AppConfig } from "../../../hooks/useConfig";
import { ExportPropertyBudgetQuery } from "../../../__generated__/generated_types";
import { YearDefinition } from "../../annualsummary/YearDefinitions";
import { BudgetExportFormat } from "../BudgetExportData";
import { formatForAppfolio, formatAsReport, formatForYardi } from "./formatters";

export function useExportCsvHoook() {
    const exportCsv = (
        exportFormat: BudgetExportFormat.CSV_REPORT | BudgetExportFormat.CSV_APPFOLIO | BudgetExportFormat.CSV_YARDI | BudgetExportFormat.CSV_YARDI_1_cent,
        tab: BudgetingType,
        referenceYear: YearDefinition,
        data: ExportPropertyBudgetQuery,
        config: AppConfig
    ) => {
        let formattedData = "";

        if (exportFormat == BudgetExportFormat.CSV_REPORT) {
            formattedData = formatAsReport({
                tab: tab,
                referenceYear: referenceYear,
                data: data,
                config: config
            });
        }
        else if (exportFormat == BudgetExportFormat.CSV_APPFOLIO) {
            formattedData = formatForAppfolio({
                tab: tab,
                data: data,
                config: config
            });
        }
        else if (exportFormat == BudgetExportFormat.CSV_YARDI || exportFormat == BudgetExportFormat.CSV_YARDI_1_cent) {
            formattedData = formatForYardi({
                tab: tab,
                data: data,
                config: config,
                oneCentInsteadZero: exportFormat == BudgetExportFormat.CSV_YARDI_1_cent
            });
        }
        const saveData = new Blob([formattedData], { type: "text/csv;charset=utf-8" });
        let year = config.year;
        let periodName = "Reforecast";
        if (tab == BudgetingType.BUDGET) {
            year = config.year + 1;
            periodName = "Budget";
        }

        const fileName = `${year} ${periodName} - ${config.properties.currentProperty?.name ?? ""}`;
        FileSaver.saveAs(saveData, `${fileName}.csv`);
    };

    return exportCsv;
}