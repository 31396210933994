import React, {ReactElement} from "react";
import {
    AllPayrollCompensationType,
    LocalPayrollEmployeeBonusModel,
    LocalPayrollEmployeeModel,
    LocalPayrollEmployeeModelKeys,
    LocalPayrollEmployeeOvertimeModel,
    LocalPayrollEmployeeRaiseModel
} from "../helpers/types";
import styles from "../AnalystPayrollDrawer.module.scss";
import {Field, Input, Label} from "@zendeskgarden/react-forms";
import {Button} from '@zendeskgarden/react-buttons';
import {CustomZDDropdown} from "../../../atoms/custom-zd-dropdown/CustomZDDropdown";
import RaiseEntry from "../components/RaiseEntry";
import BonusEntry from "../components/BonusEntry";
import OvertimeEntry from "../components/OvertimeEntry";
import {
    PayrollBonusFrequency,
    PayrollBonusType,
    PayrollCompensationType,
    PayrollRaiseFrequency,
    PayrollRaiseType
} from "../../../__generated__/generated_types";
import {
    DateFormatter,
    getDefaultLocalPayrollEmployeeBonusModel,
    getDefaultLocalPayrollEmployeeOvertimeModel,
    getDefaultLocalPayrollEmployeeRaiseModel
} from "../helpers/utils";
import {Col, Row} from "@zendeskgarden/react-grid";
import {Datepicker} from "@zendeskgarden/react-datepickers";

interface EarningsProps {
    employeeData: LocalPayrollEmployeeModel,
    updateFormState: (key: LocalPayrollEmployeeModelKeys, value: any) => void
}

export default function TabEarnings({employeeData, updateFormState}: EarningsProps): ReactElement {

    return (
        <div className={styles.earningsContainer}>
            <div className={styles.earningsSections}>
                <div style={{width: "400px"}}>
                    <h4 className={styles.earningsHeaders}>Base Compensation</h4>
                    <Field className={styles.field}>
                        <Label className={styles.label}>Compensation Type</Label>
                        <CustomZDDropdown
                            widthSpec={"100%"}
                            applySelectedItems={(items) => {
                                updateFormState(LocalPayrollEmployeeModelKeys.baseCompensationType, Array.isArray(items) ? items[0] : items);
                            }}
                            initialSelectedItems={employeeData.baseComp.compensationType ? [employeeData.baseComp.compensationType] : [""]}
                            openDropdownPlaceholder="Salary"
                            closedDropdownPlaceholder=""
                            options={AllPayrollCompensationType.map(opt => ({value: opt.value, label: opt.label}))}
                            isError={false}
                            onBlur={() => {
                                return undefined;
                            }}
                            noSearchIcon
                        />
                    </Field>
                    {
                        employeeData.baseComp.compensationType === PayrollCompensationType.Annual ?
                            <>
                                <Row justifyContent="between">
                                    <Col>
                                        <Field className={styles.field}>
                                            <Label className={styles.label}>Annual Amount</Label>
                                            <Input placeholder="$"
                                                   type={"number"} value={employeeData.baseComp.annualCompensation}
                                                   onChange={(evt) => {
                                                       updateFormState(LocalPayrollEmployeeModelKeys.baseCompensationAnnualizedComponent, evt.target.value);
                                                   }}
                                            />
                                        </Field>
                                    </Col>
                                </Row>
                            </> :
                            <>
                                <Row justifyContent="between">
                                    <Col>
                                        <Field className={styles.field}>
                                            <Label className={styles.label}>Hourly Rate</Label>
                                            <Input placeholder="$"
                                                   type={"number"} value={employeeData.baseComp.hourlyCompensation}
                                                   onChange={(evt) => {
                                                       updateFormState(LocalPayrollEmployeeModelKeys.baseCompensationHourlyCompensation, evt.target.value);
                                                   }}
                                            />
                                        </Field>
                                    </Col>
                                    <Col>
                                        <Field className={styles.field}>
                                            <Label className={styles.label}>Hours/Week</Label>
                                            <Input placeholder="Hours"
                                                   type={"number"} value={employeeData.baseComp.expectedHoursPerWeek}
                                                   onChange={(evt) => {
                                                       updateFormState(LocalPayrollEmployeeModelKeys.baseCompensationExpectedHoursPerWeek, evt.target.value);
                                                   }}
                                            />
                                        </Field>
                                    </Col>
                                </Row>
                            </>
                    }
                </div>
            </div>
            <div className={styles.earningsSections}>
                <div style={{width: "400px"}}>
                    <h4 className={styles.earningsHeaders}>Raises</h4>
                    {
                        employeeData.raises.map((raise, index) => {
                            return (
                                <RaiseEntry
                                    key={index}
                                    index={index}
                                    raise={raise}
                                    employeeData={employeeData}
                                    allowRemove={employeeData.raises.length > 0}
                                    updateFormState={(key: LocalPayrollEmployeeModelKeys, value: any) => {
                                        const newRaises: Array<LocalPayrollEmployeeRaiseModel> =
                                            JSON.parse(JSON.stringify(employeeData.raises));
                                        const correspondingNewEntry: LocalPayrollEmployeeRaiseModel | undefined = newRaises[index];
                                        if (correspondingNewEntry) {
                                            let tokens:Array<string> = [];
                                            switch (key) {
                                                case LocalPayrollEmployeeModelKeys.raiseFrequencyAndType:
                                                    tokens = JSON.parse(value);
                                                    // console.info(`tokens: ${tokens}`, tokens);
                                                    if(tokens.length === 2) {
                                                        if(tokens[0] === PayrollRaiseFrequency.Annually) {
                                                            correspondingNewEntry.frequency = PayrollRaiseFrequency.Annually;
                                                        } else {
                                                            correspondingNewEntry.frequency = PayrollRaiseFrequency.Once;
                                                        }
                                                        if(tokens[1] === PayrollRaiseType.FixedAmount) {
                                                            correspondingNewEntry.raiseType = PayrollRaiseType.FixedAmount;
                                                        } else {
                                                            correspondingNewEntry.raiseType = PayrollRaiseType.PercentOfSalary;
                                                        }
                                                        correspondingNewEntry.amount = "";
                                                        correspondingNewEntry.raiseMonth = "";
                                                        correspondingNewEntry.raiseDay = "";
                                                        correspondingNewEntry.effectiveStartDate = undefined;
                                                    }
                                                    break;
                                                case LocalPayrollEmployeeModelKeys.raiseAmount:
                                                    correspondingNewEntry.amount = value;
                                                    break;
                                                case LocalPayrollEmployeeModelKeys.raiseMonth:
                                                    correspondingNewEntry.raiseMonth = value;
                                                    if (correspondingNewEntry.raiseDay.toString() === "30" &&
                                                        ["1", "01"].indexOf(correspondingNewEntry.raiseMonth.toString()) !== -1) {
                                                         correspondingNewEntry.raiseDay = "1";
                                                    }
                                                    if (correspondingNewEntry.raiseDay.toString() === "31" &&
                                                        ["1", "01", "3", "03", "5", "05", "8", "08", "10"].indexOf(correspondingNewEntry.raiseMonth.toString()) !== -1) {
                                                        correspondingNewEntry.raiseDay = "1";
                                                    }
                                                    break;
                                                case LocalPayrollEmployeeModelKeys.raiseDate:
                                                    correspondingNewEntry.raiseDay = value;
                                                    break;
                                                case LocalPayrollEmployeeModelKeys.raiseEffectiveStartDate:
                                                    correspondingNewEntry.effectiveStartDate = value;
                                                    break;
                                                case LocalPayrollEmployeeModelKeys.onetimeRaiseDate:
                                                    correspondingNewEntry.onetimeRaiseDate = value;
                                                    break;
                                                case LocalPayrollEmployeeModelKeys.removeRaise:
                                                    newRaises.splice(index, 1);
                                                    break;
                                                default:
                                                    console.info(`Unhandled key: ${key} with value: ${JSON.stringify(value)}`);
                                            }
                                        }
                                        updateFormState(LocalPayrollEmployeeModelKeys.raises, newRaises);
                                    }}
                                />
                            );
                        })
                    }
                    <Button isBasic className={styles.addButton} onClick={() => {
                        let newRaises: Array<LocalPayrollEmployeeRaiseModel> =
                            JSON.parse(JSON.stringify(employeeData.raises));
                        newRaises.push(getDefaultLocalPayrollEmployeeRaiseModel());
                        updateFormState(LocalPayrollEmployeeModelKeys.raises, newRaises);
                    }}>Add</Button>
                </div>
            </div>
            <div className={styles.earningsSections}>
                <div style={{width: "400px"}}>
                    <h4 className={styles.earningsHeaders}>Bonuses</h4>
                    {
                        employeeData.bonuses.map((bonus, index) => {
                            return (
                                <BonusEntry
                                    key={index}
                                    index={index}
                                    bonus={bonus}
                                    allowRemove={employeeData.bonuses.length > 0}
                                    updateFormState={(key: LocalPayrollEmployeeModelKeys, value: any) => {
                                        const newBonuses: Array<LocalPayrollEmployeeBonusModel> =
                                            JSON.parse(JSON.stringify(employeeData.bonuses));
                                        const correspondingNewEntry: LocalPayrollEmployeeBonusModel | undefined = newBonuses[index];
                                        if (correspondingNewEntry) {
                                            let tokens:Array<string> = [];
                                            switch (key) {
                                                case LocalPayrollEmployeeModelKeys.bonusFrequencyAndType:
                                                    tokens = JSON.parse(value);
                                                    console.info(`tokens: ${tokens}`, tokens);
                                                    if(tokens.length === 2) {
                                                        if(tokens[0] === PayrollBonusFrequency.Annually) {
                                                            correspondingNewEntry.frequency = PayrollBonusFrequency.Annually;
                                                        } else {
                                                            correspondingNewEntry.frequency = PayrollBonusFrequency.Once;
                                                        }
                                                        if(tokens[1] === PayrollBonusType.FixedAmount) {
                                                            correspondingNewEntry.bonusType = PayrollBonusType.FixedAmount;
                                                        } else {
                                                            correspondingNewEntry.bonusType = PayrollBonusType.PercentOfSalary;
                                                        }
                                                        correspondingNewEntry.amount = "";
                                                        correspondingNewEntry.bonusMonth = "";
                                                        correspondingNewEntry.bonusDay = "";
                                                        correspondingNewEntry.effectiveStartDate = undefined;
                                                        correspondingNewEntry.onetimeBonusDate = undefined;
                                                    }
                                                    break;
                                                case LocalPayrollEmployeeModelKeys.bonusAmount:
                                                    correspondingNewEntry.amount = value;
                                                    break;
                                                case LocalPayrollEmployeeModelKeys.bonusMonth:
                                                    correspondingNewEntry.bonusMonth = value;
                                                    if (correspondingNewEntry.bonusDay.toString() === "30" &&
                                                        ["1", "01"].indexOf(correspondingNewEntry.bonusMonth.toString()) !== -1) {
                                                        correspondingNewEntry.bonusDay = "1";
                                                    }
                                                    if (correspondingNewEntry.bonusDay.toString() === "31" &&
                                                        ["1", "01", "3", "03", "5", "05", "8", "08", "10"].indexOf(correspondingNewEntry.bonusMonth.toString()) !== -1) {
                                                        correspondingNewEntry.bonusDay = "1";
                                                    }
                                                    break;
                                                case LocalPayrollEmployeeModelKeys.bonusDate:
                                                    correspondingNewEntry.bonusDay = value;
                                                    break;
                                                case LocalPayrollEmployeeModelKeys.bonusEffectiveStartDate:
                                                    correspondingNewEntry.effectiveStartDate = value;
                                                    break;
                                                case LocalPayrollEmployeeModelKeys.onetimeBonusDate:
                                                    correspondingNewEntry.onetimeBonusDate = value;
                                                    break;
                                                case LocalPayrollEmployeeModelKeys.removeBonus:
                                                    newBonuses.splice(index, 1);
                                                    break;
                                                default:
                                                    console.info(`Unhandled key: ${key} with value: ${JSON.stringify(value)}`);
                                            }
                                        }
                                        updateFormState(LocalPayrollEmployeeModelKeys.bonuses, newBonuses);
                                    }}
                                />
                            );
                        })
                    }
                    <Button isBasic className={styles.addButton} onClick={() => {
                        let newBonuses: Array<LocalPayrollEmployeeBonusModel> =
                            JSON.parse(JSON.stringify(employeeData.bonuses));
                        newBonuses.push(getDefaultLocalPayrollEmployeeBonusModel());
                        updateFormState(LocalPayrollEmployeeModelKeys.bonuses, newBonuses);
                    }}>Add</Button>
                </div>
            </div>
            <div className={styles.earningsSections}>
                <div style={{width: "400px"}}>
                    <h4 className={styles.earningsHeaders}>Overtimes</h4>
                    {
                        employeeData.overtimes.map((overtime, index) => {
                            return (
                                <OvertimeEntry
                                    key={index}
                                    index={index}
                                    overtime={overtime}
                                    allowRemove={employeeData.overtimes.length > 0}
                                    updateFormState={(key: LocalPayrollEmployeeModelKeys, value: any) => {
                                        const newOvertimes: Array<LocalPayrollEmployeeOvertimeModel> =
                                            JSON.parse(JSON.stringify(employeeData.overtimes));
                                        const correspondingNewEntry: LocalPayrollEmployeeOvertimeModel | undefined = newOvertimes[index];
                                        if (correspondingNewEntry) {
                                            switch (key) {
                                                case LocalPayrollEmployeeModelKeys.overtimesHourlyRate:
                                                    correspondingNewEntry.hourlyCompensation = value;
                                                    break;
                                                case LocalPayrollEmployeeModelKeys.overtimeHoursPerWeek:
                                                    correspondingNewEntry.expectedHoursPerWeek = value;
                                                    break;
                                                case LocalPayrollEmployeeModelKeys.overtimeStartDate:
                                                    correspondingNewEntry.effectiveStartDate = value;
                                                    break;
                                                case LocalPayrollEmployeeModelKeys.overtimeEndDate:
                                                    correspondingNewEntry.effectiveEndDate = value;
                                                    break;
                                                case LocalPayrollEmployeeModelKeys.removeOvertime:
                                                    newOvertimes.splice(index, 1);
                                                    break;
                                                default:
                                                    console.info(`Unhandled key: ${key} with value: ${JSON.stringify(value)}`);
                                            }
                                        }
                                        updateFormState(LocalPayrollEmployeeModelKeys.overtimes, newOvertimes);
                                    }}
                                />
                            );
                        })
                    }
                    <Button isBasic className={styles.addButton} onClick={() => {
                        let newOvertimes: Array<LocalPayrollEmployeeOvertimeModel> =
                            JSON.parse(JSON.stringify(employeeData.overtimes));
                        newOvertimes.push(getDefaultLocalPayrollEmployeeOvertimeModel());
                        updateFormState(LocalPayrollEmployeeModelKeys.overtimes, newOvertimes);
                    }}>Add</Button>
                </div>
            </div>
        </div>
    );
}
