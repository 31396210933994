/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React from 'react';
import useAppStore from '../../hooks/useAppStore';
import { IconSideBar } from '../icons';
import ShimmerRenderer, { ShimmerPageKey } from '../shimmer/ShimmerRenderer';
import ToggleCategory, { SidebarTab } from '../sidebar/ToggleCategory';
import AddStaffButton, { AddStaffButtonProps } from "../payroll/AddStaffButton";
import {Grid, Col, Row} from "@zendeskgarden/react-grid";
import { useFoqalAction } from '../../hooks/useFoqal';
import StarredAccount from "../starred-account/StarredAccount";

interface Props {
    children?: any;
    title?: string | React.ReactNode;
    isLoading?: boolean;
    shimmerOptions?: {
        pageKey?: ShimmerPageKey;
    }
    toggleCategoryProps?: SidebarTab;
    className?: string;
    addStaffButtonProps?:AddStaffButtonProps;
    accountId?: string;
    versionIds?: string[];
    currentVersionId?: string;
}

function Content({ children, title, isLoading, className, shimmerOptions, toggleCategoryProps, addStaffButtonProps,
                     accountId, versionIds, currentVersionId }: Props) {
    const appStore = useAppStore();
    const {show: showFoqal, hide: hideFoqal} = useFoqalAction();

    const toggleSidebar = () => {
        if (appStore.isSideBarVisible) {
            showFoqal();
        }
        else {
            hideFoqal();
        }
        appStore.set({ isSideBarVisible: !appStore.isSideBarVisible });
    };

    return (
        <div className={`d-flex flex-column ${className} main-container`}>
            {isLoading ? <ShimmerRenderer modal={true} {...shimmerOptions} /> : null}
            <Grid>
                <Row className={"mt-5 mb-2"}>
                    <Col size={0.5}>
                        <div className={"display-modal-icon-container d-flex justify-content-center " + (appStore.isSideBarVisible ? "bg-light-azure" : "bg-white")}>
                            <IconSideBar
                                className={"display-modal-icon fs-4 text-primary"}
                                onClick={() => toggleSidebar()}
                            />
                        </div>
                    </Col>
                    <Col size={8.5} textAlign={"center"} style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center"
                    }}>
                        {
                            accountId && versionIds && versionIds.length > 0 && currentVersionId && <StarredAccount
                                accountId={accountId}
                                versionIds={versionIds}
                                currentVersionId={currentVersionId}
                                style={{marginRight: "7px"}}
                            />
                        }
                        <div className="account-title">
                            <h2>{title}</h2>
                        </div>
                    </Col>
                    <Col size={2} offset={1} style={{paddingTop: "5px"}}>
                        {
                            toggleCategoryProps ?
                                <div className="fs-5">
                                    <ToggleCategory  {...toggleCategoryProps} />
                                </div>
                                : addStaffButtonProps ?
                                    <AddStaffButton{...addStaffButtonProps} /> : <div></div>
                        }
                    </Col>
                </Row>
                {children}
            </Grid>
        </div>
    );
}

export default Content;
