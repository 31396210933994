import React from 'react';

export const FormContainer: React.FunctionComponent = (props) => {
    return (
        <div className="container vh-100 d-flex align-items-center grid-container">
            <div className="grid-container">
                {props.children}
            </div>
        </div>
    );
};