import {ListRenovationPackagesQuery} from "../../../../__generated__/generated_types";
// import {UnitType as TSimplifiedRevenueUnitType} from "../../../../contexts/properties/PropertiesContext";
import "native-injects";

export type TClassicToRenovatedAssociation = {
    unitTypeId: string,
    unitTypeName: string,
    renovatedUnitTypeId: string | undefined,
    renovatedUnitTypeName: string | undefined,
    packageId: string | undefined,
    packageName: string | undefined
}

export type TSimplifiedRevenueUnitType = {
    id: string,
    type: string,
    count: number
}


export type TClassicToRenovatedAssociationsData = {
    rows: TClassicToRenovatedAssociation[];
    unmappedUnitTypes: TSimplifiedRevenueUnitType[];
    unrenovatedUnitTypes: TSimplifiedRevenueUnitType[]; // includes mapped classic unit types and unmappedUnitTypes
}

// TODO: kill before merge
const MOCK = new Array(100).fill(0).map((_, i) => (
    {
        unitTypeId: `ut.id${i}`,
        unitTypeName: `ut.type${i}`,
        renovatedUnitTypeId: `renovatedUnitTypeId${i}`,
        renovatedUnitTypeName: `renovatedUnitTypeName${i}`,
        packageName: `packageName${i}`
    }
));

export function mapToClassicToRenovatedAssociations(
    data: ListRenovationPackagesQuery,
    unitTypes: TSimplifiedRevenueUnitType[]): TClassicToRenovatedAssociationsData {

    const renovatedUnitTypeIds = new Set(
        data.queryRenovationPackages.map(p =>
            p.simplifiedRevenueRenovationMapping?.renovatedUnitTypeId ?? null).filterNull()
    );
    // sortedUnrenovatedUnitTypes - all unit types that are not explicitly marked as renovated
    // those will be unit types marked as classic by being assigned to RenovationPackage.unitTypeId
    // and unit types not associated to any RenovationPackage
    const sortedUnrenovatedUnitTypes = unitTypes.filter(ut => !renovatedUnitTypeIds.has(ut.id)).sortBy("type");
    const unitTypeIdToPackageMap = data.queryRenovationPackages.toIdMap("unitTypeId");
    // unmappedUnitTypes is part of sortedUnrenovatedUnitTypes which is not associated to any RenovationPackage
    // and hence can become either assigned to RenovationPackage.unitTypeId or be used as renovatedUnitTypeId
    const unmappedUnitTypes: TSimplifiedRevenueUnitType[] = [];
    // classicMappedUnitTypes is part of sortedUnrenovatedUnitTypes which is assigned to RenovationPackage.unitTypeId
    const classicMappedUnitTypes: TSimplifiedRevenueUnitType[] = [];

    const mapped:TClassicToRenovatedAssociation[] = sortedUnrenovatedUnitTypes.map(ut => {
        let renovatedUnitTypeId = undefined;
        let renovatedUnitTypeName = undefined;
        let packageId = undefined;
        let packageName = undefined;
        const packageInfo = unitTypeIdToPackageMap[ut.id];
        if (packageInfo) {
            renovatedUnitTypeId = packageInfo.simplifiedRevenueRenovationMapping?.renovatedUnitTypeId;
            renovatedUnitTypeName = packageInfo.simplifiedRevenueRenovationMapping?.renovatedUnitTypeName;
            packageId = packageInfo.id;
            packageName = packageInfo.name;
            classicMappedUnitTypes.push(ut);
        }
        else {
            unmappedUnitTypes.push(ut);
        }
        const association:TClassicToRenovatedAssociation = {
            unitTypeId: ut.id,
            unitTypeName: ut.type,
            renovatedUnitTypeId: renovatedUnitTypeId,
            renovatedUnitTypeName: renovatedUnitTypeName,
            packageId: packageId,
            packageName: packageName
        };

        return association;
    });

    return {
        rows: mapped,
        unmappedUnitTypes: unmappedUnitTypes,
        unrenovatedUnitTypes: sortedUnrenovatedUnitTypes
    };
}