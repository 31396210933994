import { ReactElement, useEffect, useMemo, useState } from "react";
import { Body, Close, Footer, FooterItem, Header, Modal } from "@zendeskgarden/react-modals";
import { Field as FormField, Label, Checkbox, MediaInput, Message } from "@zendeskgarden/react-forms";
import { Button } from "@zendeskgarden/react-buttons";
import {
    FinancialEntityType,
    QueryAccountPercentageDriverCyclesQuery,
    VersionType,
    useLoadChartOfAccountsQuery,
    useQueryAccountPercentageDriverCyclesLazyQuery,
    useQueryAccountTagsLazyQuery
} from "../../../../../../../__generated__/generated_types";
import { FixedSizeList, ListChildComponentProps } from "react-window";
import { ReactComponent as SearchIcon } from '@zendeskgarden/svg-icons/src/16/search-stroke.svg';
import { ReactComponent as ChevronDownIcon } from '@zendeskgarden/svg-icons/src/16/chevron-down-stroke.svg';
import { ReactComponent as ChevronRightIcon } from '@zendeskgarden/svg-icons/src/16/chevron-right-stroke.svg';
import { ReactComponent as CycleIcon } from '@zendeskgarden/svg-icons/src/16/arrow-retweet-stroke.svg';
import { ReactComponent as MaximizeIcon } from '@zendeskgarden/svg-icons/src/16/maximize-stroke.svg';
import { ReactComponent as MinimizeIcon } from '@zendeskgarden/svg-icons/src/16/minimize-stroke.svg';
import { ReactComponent as CheckboxIcon } from '@zendeskgarden/svg-icons/src/16/check-box-stroke.svg';
import cn from "classnames";
import * as css from "./styles/accountDriverFormulaManyModal.module.scss";
import { LookbackPeriodSelector } from "./LookbackPeriodSelector";
import { v4 as uuid } from "uuid";
import { Inline } from "@zendeskgarden/react-loaders";
import { COLORS } from "../../../../../../../constants/Colors";
import { TagSelector } from "../../../../../../coa-tags/components/TagSelector";
import {Form} from "react-bootstrap";
import {FiDollarSign} from "react-icons/all";

export type TAccountDriverUpdate = {
    accountId: string;
    glName: string;
    glNumber: string;
    lookbackPeriod: number;
}

export type TAccountDriver = {
    accountId: string;
    glName: string;
    glNumber: string;
    lookbackPeriod: number;
}

export type TAccountDriverAugment = {
    // The values are decimal numbers, so we represent them as strings.
    minValue: string | null;
    maxValue: string | null;
}

export type AccountDriverFormulaManyModalProps = {
    onClose: () => void;
    onConfirm: (updates: TAccountDriverUpdate[], augments: TAccountDriverAugment) => void;
    drivers: TAccountDriver[];
    // This is allowed to be undefined to allow hiding
    // the form inputs when the model is used outside the
    // formula bar.
    augments?: TAccountDriverAugment;

    propertyId: string;
    versionType: VersionType;
    year: number;

    accountIds: string[];
}

export type ItemData = {
    id: string,
    glNumber?: string | null,
    glName: string,
    parentIds: string[],
    type: FinancialEntityType,

    selected: boolean,
    collapsed: boolean,
    lookbackPeriod: number,
    tags: string[]
}

export function itemText(item: ItemData): string {
    return `${item.glNumber ? (item.glNumber + " - ") : ""} ${item.glName}`;
}

type ItemProps = {
    item: ItemData,
    allItems: ItemData[],
    allTags: string[],
    destinationAccountIds: string[],
    cycles: QueryAccountPercentageDriverCyclesQuery | undefined,
    style?: React.CSSProperties,
    handleCollapse?: (item: ItemData, collapsed: boolean) => void,
    handleSelect: (item: ItemData, selected: boolean) => void,
    handleSetLookbackPeriod: (item: ItemData, lookbackPeriod: number) => void,
}

function Item({
    item,
    style,
    cycles,
    handleCollapse,
    handleSelect,
    handleSetLookbackPeriod,
    allItems
}: ItemProps): ReactElement {
    const [cyclesModalVisible, setCyclesModalVisible] = useState(false);
    const mergedCycles = useMemo(
        () => {
            if (!cycles) {
                return [];
            }
            return cycles.queryAccountPercentageDriverCycles
                .flatMap(cycle => cycle.accountIds).dedupe();
        },
        [cycles]
    );

    const [indeterminate, checked, lookbackPeriod] = useMemo(
        () => {
            let indeterminate = false;
            let checked = false;
            let lookbackPeriod = 0;
            const children = allItems.filter(it => it.type == "ACCOUNT" && it.parentIds.includes(item.id));
            if (children.length > 0) {
                const selectedChildrenCount = children.count(child => child.selected);
                indeterminate = selectedChildrenCount > 0 && children.length > selectedChildrenCount;
                checked = selectedChildrenCount == children.length;
                const childrenLookbackPeriods = children.map(child => child.lookbackPeriod).dedupe();
                if (childrenLookbackPeriods.length == 1 && childrenLookbackPeriods.firstElement) {
                    lookbackPeriod = childrenLookbackPeriods.firstElement;
                }
                else if (childrenLookbackPeriods.length > 1) {
                    lookbackPeriod = -1;
                }
            }
            else {
                checked = item.selected;
                lookbackPeriod = item.lookbackPeriod;
            }
            return [indeterminate, checked, lookbackPeriod];
        },
        [allItems]
    );

    const cyclesDescription = useMemo(
        () => {
            if (item.type == "ACCOUNT" && cyclesModalVisible && cycles) {
                return (
                    <ul>
                        {cycles.queryAccountPercentageDriverCycles
                            .filter(cycle => cycle.accountIds.includes(item.id))
                            .map(cycle => (
                                <li>
                                    {cycle.accountIds
                                        .map(id => allItems.find(it => it.id == id))
                                        .filterNull()
                                        .map(acc => (`${acc.glNumber ? `${acc.glNumber} - ` : ""}${acc.glName}`))
                                        .join("  ->  ")
                                    }
                                    <br />
                                    <br />
                                    <br />
                                </li>
                            ))}
                    </ul>
                );
            }
            return null;
        },
        [cyclesModalVisible, cycles]
    );

    const [hasCycle, childrenHaveCycle] = useMemo(
        () => [
            item.type == "ACCOUNT" && item.selected && mergedCycles.includes(item.id),
            item.type != "ACCOUNT" && allItems.find(it => it.type == "ACCOUNT" && it.selected && it.parentIds.includes(item.id) && mergedCycles.includes(it.id))
        ],
        [mergedCycles]
    );

    return (
        <div
            style={style}
            className={cn(
                css.item,
                item.type == FinancialEntityType.Account ? css.plain : undefined,
                hasCycle ? css.hasCycle : undefined,
                childrenHaveCycle ? css.childrenHaveCycle : undefined
            )}
        >
            <FormField>
                <Checkbox
                    className={css.checkbox}
                    checked={checked}
                    onChange={() => {
                        handleSelect(item, !checked);
                    }}
                    indeterminate={indeterminate}
                >
                    <Label className={css.checkbox} />
                </Checkbox>
            </FormField>
            <div
                className={cn(
                    css.content,
                )}
                style={{ paddingLeft: `${item.parentIds.length * 48}px` }}
            >
                <div
                    className={css.chevron}
                >
                    {item.type != FinancialEntityType.Account &&
                        <Button
                            isBasic
                            onClick={() => handleCollapse?.(item, !item.collapsed)}
                        >
                            {item.collapsed ? <ChevronRightIcon /> : <ChevronDownIcon />}
                        </Button>
                    }
                </div>
                <div className={css.accountName}>
                    {item.glNumber ? `${item.glNumber} - ` : ""}{item.glName}
                </div>
                <Button
                    isBasic
                    onClick={() => {
                        setCyclesModalVisible(true);
                    }}
                    hidden={!hasCycle}
                >
                    <div className={css.cycleIcon}>
                        <CycleIcon />
                    </div>
                </Button>
                {cyclesModalVisible &&
                    <Modal onClose={() => setCyclesModalVisible(false)}>
                        <Header>
                            Circular references identified
                        </Header>
                        <Body>
                            {cyclesDescription}
                        </Body>
                        <Close aria-label="Close modal" />
                    </Modal>
                }
            </div>
            <div
                className={css.lookbackContainer}
            >
                <LookbackPeriodSelector
                    initialValue={lookbackPeriod}
                    onSelect={(v) => handleSetLookbackPeriod(item, v)}
                />
            </div>
        </div>
    );
}

export function AccountDriverFormulaManyModal(props: AccountDriverFormulaManyModalProps): ReactElement {
    const { data: chartOfAccountsData, loading: chartOfAccountsLoading } = useLoadChartOfAccountsQuery({
        notifyOnNetworkStatusChange: true,
        fetchPolicy: "no-cache",
    });

    const [queryAccountPercentageDriverCycles, { data: accPercentageCyclesData, loading: accPercentageCyclesLoading }] = useQueryAccountPercentageDriverCyclesLazyQuery({
        fetchPolicy: "no-cache",
    });

    const [queryAccountTags, {data: accountTagsData, loading: accountTagsLoading}] = useQueryAccountTagsLazyQuery({
        fetchPolicy: "no-cache"
    });

    const [items, setItems] = useState<ItemData[]>([]);
    const [displayItems, setDisplayItems] = useState<ItemData[]>([]);
    const [visibleItems, setVisibleItems] = useState<ItemData[]>([]);
    const [searchString, setSearchString] = useState<string>();
    const [searchTags, setSearchTags] = useState<string[]>([]);
    const [searchByTagsVisible, setSearchByTagsVisible] = useState(false);
    const [allTags, setAllTags] = useState<string[]>([]);
    const [selectionVersion, setSelectionVersion] = useState<string>(); // just something that will change everytime the account selection changes
    const [isFindingCycles, setIsFindingCycles] = useState(false);
    const [showCyclesOnly, setShowCyclesOnly] = useState(false);
    const [minValue, setMinValue] = useState<string | undefined>(props.augments?.minValue || undefined);
    const [maxValue, setMaxValue] = useState<string | undefined>(props.augments?.maxValue || undefined);
    const [minValueInvalid, setMinValueInvalid] = useState<boolean>(false);
    const [maxValueInvalid, setMaxValueInvalid] = useState<boolean>(false);
    const [minGtMax, setMinGtMax] = useState<boolean>(false);

    const [hasCyclesWithSelectedAccounts, mergedCycles] = useMemo(
        () => {
            let hasCyclesWithSelectedAccounts = false;
            let mergedCycles: string[] = [];
            if (accPercentageCyclesData && !accPercentageCyclesLoading) {
                mergedCycles = accPercentageCyclesData.queryAccountPercentageDriverCycles
                        .flatMap(cycle => cycle.accountIds).dedupe();
            }
            for (const item of items) {
                if (item.selected && item.type == "ACCOUNT" && mergedCycles.includes(item.id)) {
                    hasCyclesWithSelectedAccounts = true;
                    break;
                }
            }
            return [hasCyclesWithSelectedAccounts, mergedCycles];
        },
        [accPercentageCyclesData, accPercentageCyclesLoading, items]
    );

    function updateDisplayItems(
        sourceItems: ItemData[],
        searchString: string | undefined,
        searchTags: string[]
    ) {
        let updated = [...sourceItems];
        if (searchString || searchTags.length > 0 || showCyclesOnly) {
            let temp = updated;
            if (searchString && searchString.length > 0) {
                temp = temp.filter(item => itemText(item).toLowerCase().includes(searchString.toLowerCase()));
            }
            if (searchTags.length > 0) {
                temp = temp.filter(item => item.tags.length > 0 && item.tags.find(tag => searchTags.find(t => t == tag)));
            }
            if (showCyclesOnly && mergedCycles.length > 0) {
                temp = temp.filter(item => item.selected && mergedCycles.includes(item.id));
            }
            const ids = new Set(temp.map(item => item.id));
            const parentIds = new Set(temp.flatMap(item => item.parentIds).dedupe());
            updated = updated.filter(item =>
                ids.has(item.id)
                // I want to show user the path to the top level from the item filtered
                || parentIds.has(item.id)
            );
        }
        setVisibleItems(updated); // important to set visible items before hiding with collapsing
        const collapsedIds = updated.filter(item => item.collapsed).map(item => item.id);
        updated = updated.filter(item => !item.parentIds.some(id => collapsedIds.includes(id)));
        setDisplayItems(updated);
    }


    function handleCollapse(item: ItemData, collapsed: boolean) {
        const updated = [...items];
        const foundItem = updated.find(it => it.id == item.id);
        if (foundItem) {
            foundItem.collapsed = collapsed;
        }
        updateDisplayItems(updated, searchString, searchTags);
    }

    function handleSelect(item: ItemData, selected: boolean) {
        const updated = [...items];
        const visibleItemIds = new Set(visibleItems.map(it => it.id));
        for (const it of updated) {
            if (it.id == item.id || it.parentIds.includes(item.id) && visibleItemIds.has(it.id)) {
                it.selected = selected;
            }
        }
        updateDisplayItems(updated, searchString, searchTags);
        setSelectionVersion(uuid());
        setIsFindingCycles(true);
    }

    function handleSelectAll(selected: boolean) {
        const updated = [...items];
        const visibleItemIds = new Set(visibleItems.map(it => it.id));
        for (const it of updated) {
            if (visibleItemIds.has(it.id)) {
                if (!(it.type == "ACCOUNT" && props.accountIds.includes(it.id))) {
                    it.selected = selected;
                }
            }
        }
        updateDisplayItems(updated, searchString, searchTags);
        setSelectionVersion(uuid());
        setIsFindingCycles(true);
    }

    function handleCollapseAll(collapsed: boolean) {
        const visibleItemIds = new Set(visibleItems.map(it => it.id));
        for (const item of items) {
            if (item.type != FinancialEntityType.Account && visibleItemIds.has(item.id)) {
                item.collapsed = collapsed;
            }
        }
        updateDisplayItems(items, searchString, searchTags);
    }

    function handleSetLookbackPeriod(item: ItemData, lookbackPeriod: number) {
        const updated = [...items];
        for (const it of updated) {
            if (it.id == item.id || it.parentIds.includes(item.id)) {
                it.lookbackPeriod = lookbackPeriod;
            }
        }
        updateDisplayItems(updated, searchString, searchTags);
    }

    function handleConfirm() {
        const update = items.filter(it => it.type == "ACCOUNT" && it.selected)
            .map(it => ({
                accountId: it.id,
                glName: it.glName,
                glNumber: it.glNumber ?? "",
                lookbackPeriod: it.lookbackPeriod
            }));

        props.onConfirm(update, {
            minValue: minValue === undefined ? null : minValue,
            maxValue: maxValue === undefined ? null : maxValue
        });
    }

    function reloadCycles(items: ItemData[]) {
        queryAccountPercentageDriverCycles({
            variables: {
                propertyId: props.propertyId,
                versionType: props.versionType,
                year: props.year,
                destinationAccountIds: props.accountIds,
                sourceAccountIds: items.filter(it => it.type == "ACCOUNT" && it.selected).map(it => it.id)
            }
        });
    }

    function COARow(rowProps: ListChildComponentProps<ItemData[]>) {
        const { data: displayItems, index, style } = rowProps;
        const item = displayItems[index];
        if (!item) return null;
        return (
            <Item
                item={item}
                allItems={visibleItems}
                allTags={allTags}
                destinationAccountIds={props.accountIds}
                cycles={accPercentageCyclesData}
                handleCollapse={(item: ItemData, collapsed: boolean) => handleCollapse(item, collapsed)}
                handleSelect={(item: ItemData, selected: boolean) => handleSelect(item, selected)}
                handleSetLookbackPeriod={((item: ItemData, lookbackPeriod: number) => handleSetLookbackPeriod(item, lookbackPeriod))}
                style={style}
            />
        );
    }

    useEffect(
        () => {
            if (chartOfAccountsData && !chartOfAccountsLoading) {
                const initialItems: ItemData[] = [];
                const accountDriversById = props.drivers.toIdMap("accountId");
                for (const row of chartOfAccountsData.listChartOfAccounts.records) {
                    const accountDriverData = accountDriversById[row.id];
                    initialItems.push({
                        id: row.id,
                        glNumber: row.glNumber,
                        glName: row.glName,
                        parentIds: row.parentIds.copy(),
                        type: row.type,

                        collapsed: false,
                        selected: accountDriverData != undefined,
                        tags: [],
                        lookbackPeriod: accountDriverData?.lookbackPeriod ?? 0
                    });
                }
                setItems(initialItems);
                updateDisplayItems(initialItems, searchString, searchTags);
                setIsFindingCycles(true);
                reloadCycles(initialItems);
                queryAccountTags(); // I want to load tags after was coa loaded
            }
            return () => undefined;
        },
        [chartOfAccountsData, chartOfAccountsLoading]
    );

    useEffect(() => {
        if (accountTagsData && !accountTagsLoading) {
            const allTags = new Set<string>();
            const selectionTags = new Set<string>();
            const tagsByAccountId = accountTagsData.queryAccountTags.toIdMap("accountId", "tags");
            const updated = [...items];
            for (const item of updated) {
                if (item.type == "ACCOUNT") {
                    item.tags = tagsByAccountId[item.id] ?? [];
                    for (const tag of item.tags) {
                        allTags.add(tag);
                        if (item.selected) {
                            selectionTags.add(tag);
                        }
                    }
                }
            }
            updateDisplayItems(updated, searchString, searchTags);
            setAllTags(Array.from(allTags));
            setSearchTags(prev => prev.filter(tag => allTags.has(tag)));
        }
    }, [accountTagsData, accountTagsLoading]);

    useEffect(
        () => {
            updateDisplayItems(items, searchString, searchTags);
        },
        [searchString, searchTags, showCyclesOnly]
    );

    useEffect(() => {
        let debounce: ReturnType<typeof setTimeout>;
        if (selectionVersion) {
            debounce = setTimeout(() => {
                reloadCycles(items);
            }, 1500);
        }

        return () => {
            clearTimeout(debounce);
        };
    }, [selectionVersion]);

    useEffect(
        () => {
            if (accPercentageCyclesData && !accPercentageCyclesLoading) {
                setIsFindingCycles(false);
            }
        },
        [accPercentageCyclesData, accPercentageCyclesLoading]
    );

    useEffect(() => {
        function validateValue(val: string): boolean {
            return !(/-?(?:\d+(?:\.\d+)?|\.\d+)/.test(val));
        }

        let minParsed: number | null = null;
        let maxParsed: number | null = null;

        if(minValue !== null && minValue !== undefined) {
            const minInvalid = validateValue(minValue);
            setMinValueInvalid(minInvalid);
            if(!minInvalid) {
                minParsed = parseFloat(minValue);
            }
        } else {
            setMinValueInvalid(false);
        }

        if(maxValue !== null && maxValue !== undefined) {
            const maxInvalid = validateValue(maxValue);
            setMaxValueInvalid(maxInvalid);
            if(!maxInvalid) {
                maxParsed = parseFloat(maxValue);
            }

        } else {
            setMaxValueInvalid(false);
        }

        setMinGtMax(minParsed !== null && maxParsed !== null && minParsed > maxParsed);
    }, [minValue, maxValue]);


    return (
        <Modal onClose={() => props.onClose()} isLarge style={{ overflow: "unset" }} className={css.modal}>
            <Header>
                Pick Accounts and lookback period
            </Header>
            <Body>
                <div className={css.listHeader}>
                    <div className={css.listHeaderContainer}>
                        <div className={css.listHeaderButtonContainer}>
                            <Button className={css.headerButton}
                                isBasic
                                onClick={() => handleCollapseAll(true)}
                            >
                                <MinimizeIcon />
                            </Button>
                            <Button className={css.headerButton}
                                isBasic
                                onClick={() => handleCollapseAll(false)}
                            >
                                <MaximizeIcon />
                            </Button>
                            <Button className={css.headerButton}
                                isBasic
                                onClick={() => handleSelectAll(true)}
                            >
                                <CheckboxIcon />&nbsp;All
                            </Button>
                            <Button className={css.headerButton}
                                isBasic
                                onClick={() => handleSelectAll(false)}
                            >
                                <CheckboxIcon />&nbsp;None
                            </Button>
                            <Button
                                isBasic={!showCyclesOnly}
                                isPrimary={showCyclesOnly}
                                onClick={() => setShowCyclesOnly(prev => !prev)}
                            >
                                <CycleIcon/>
                            </Button>
                        </div>
                        <div className={css.listHeaderInputBoxContainer}>
                            <FormField className={css.inputBox}>
                                <MediaInput start={<SearchIcon />} value={searchString} placeholder="Search" onChange={e => setSearchString(e.target.value)} />
                            </FormField>
                            <FormField className={css.inputBox}>
                                <MediaInput
                                    start={<SearchIcon />}
                                    onClick={() => setSearchByTagsVisible(true)}
                                    value={searchTags.length > 0 ? searchTags.join(", ") : ""}
                                    placeholder="Filter By Tags"
                                />
                                {searchByTagsVisible &&
                                <TagSelector
                                    title="Filter By Tags"
                                    canAdd={false}
                                    options={allTags.map(t => ({label: t, value: t}))}
                                    selected={searchTags.map(t => ({label: t, value: t}))}
                                    onConfirm={(tags) => {setSearchTags(tags.map(t => t.value)); setSearchByTagsVisible(false);}}
                                    onClose={() => setSearchByTagsVisible(false)}
                                />
                                }
                            </FormField>
                        </div>
                    </div>
                    {props.augments &&
                    <div className={css.listHeaderContainer}>

                        <div className={css.listHeaderButtonContainer}>
                            Set Min/Max Monthly Values
                        </div>
                        <div className={css.listHeaderInputBoxContainer}>
                            <FormField className={css.inputBox}>
                                <Label hidden>Minimum Monthly Value</Label>
                                <MediaInput
                                        validation={minValueInvalid || minGtMax ? "error" : undefined}
                                        start={<FiDollarSign />}
                                        value={minValue}
                                        onChange={evt => setMinValue(evt.currentTarget.value === "" ? undefined: evt.currentTarget.value)}
                                        placeholder="Min Value" />
                                {minGtMax &&
                                        <Message validation="error">Min cannot be greater than max.</Message>
                                }
                            </FormField>
                            <FormField className={css.inputBox}>
                                <Label hidden>Maximum Monthly Value</Label>
                                <MediaInput
                                        validation={maxValueInvalid ? "error" : undefined}
                                        start={<FiDollarSign />}
                                        inputMode="decimal"
                                        value={maxValue}
                                        onChange={evt => setMaxValue(evt.currentTarget.value === "" ? undefined: evt.currentTarget.value)}
                                        placeholder="Max Value" />
                            </FormField>
                        </div>
                    </div>
                    }
                </div>
                <FixedSizeList<ItemData[]>
                    height={430}
                    itemCount={displayItems.length}
                    itemSize={40}
                    width={"100%"}
                    itemData={displayItems}
                >
                    {COARow}
                </FixedSizeList>
            </Body>
            <Footer>
                <FooterItem>
                    {hasCyclesWithSelectedAccounts &&
                        <Message validation="error">Found circular references in % of accounts dependencies. Look for rows in red with <CycleIcon /> icon.</Message>
                    }
                </FooterItem>
                <FooterItem>
                    <Button onClick={() => props.onClose()} isBasic disabled={false}>
                        Cancel
                    </Button>
                </FooterItem>
                <FooterItem>
                    {
                        isFindingCycles ?
                            <Inline size={24} color={COLORS.PRIMARY_500} aria-label="loading"/>
                            :
                            <Button isPrimary onClick={() => handleConfirm()} disabled={hasCyclesWithSelectedAccounts || minValueInvalid || maxValueInvalid || minGtMax}>
                                Confirm
                            </Button>
                    }
                </FooterItem>
            </Footer>
            <Close aria-label="Close modal" />
        </Modal>

    );
}