import {VersionType} from "../__generated__/generated_types";
import {useChartOfAccounts} from "../contexts/chartofaccounts/ChartOfAccountsContext";
import {useProperties} from "../contexts/properties/PropertiesContext";
import {useSettings} from "../contexts/settings/SettingsContext";
import {useVersions} from "../contexts/versions/VersionsContext";
import useGetMonths from "./UseGetMonths";

export type AppConfig = ReturnType<typeof useConfig>;

export type localUserPrefs = {
    selectedVersionType: VersionType,
}

export function useConfig() {
    const {year, startReforecastMonthIndex, isDefaults: isDefaultSettings} = useSettings();
    const properties = useProperties();
    const months = useGetMonths();
    const chartOfAccountsConfig = useChartOfAccounts();
    const versions = useVersions();

    return  {
        isReady: !isDefaultSettings && properties.loaded && chartOfAccountsConfig.isReady,
        year,
        startReforecastMonthIndex,
        months,
        properties,
        chartOfAccountsConfig,
        versions
    };

}