import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useConfig } from "../../../analyst/config/useConfig";
import {
    FinancialEntityType,
    FinancialValueQueryInput,
    useGetFinancialValuesLazyQuery,
    useUpsertAccountValuesMutation
} from "../../../../__generated__/generated_types";
import { Property } from '../../../../contexts/properties/PropertiesContext';
import { YearTableData } from "../../../../sjs/layout/two-years/helpers/types";
import {
    buildEntitiesYearsData,
    buildInitialFinancialsData,
    buildTableDataRows,
    FinancialEntityRequestInfo,
    FinancialsData,
    getArrayValuesSum
} from "./logic/financialsData";
import { FinancialEntityMetaData } from "./logic/financialEntityMetaData";
import { FinancialsLoadPhases } from "../FinancialsV2PlanningHubV1Shim";

export type FinancialsDataReturn = {
    financialsData: FinancialsData,
    setFinancialsData: Dispatch<SetStateAction<FinancialsData>>,
    setCurrentProperties: Dispatch<SetStateAction<Property[]>>,
    fetchEntityYearValuesMixed: (entityRequestInfo: FinancialEntityRequestInfo[], properties: Property[], phase?:'INITIAL_LOAD'|'UPDATE') => void,
    updateEntityValue: (value:number|null, monthIdx:number, rowMeta:FinancialEntityMetaData) => void,
    getFinancialEntitiesMetaData: (dataRow: number, lastDataRow?: number) => FinancialEntityMetaData[],
    updateFinancialEntitiesMetaData: (entries: FinancialEntityMetaData[]) => void,
    applyRowMetaUpdatesRecord: (updateRecord:Record<string, Partial<FinancialEntityMetaData>>) => void,
    getRowDirectChildrenMeta: (row: number) => FinancialEntityMetaData[]|undefined,
    dataLoaded: boolean,
    networkStatus: number,
}

export default function useFinancialsData(properties: Property[], reportPhase: (phase:string) => void): FinancialsDataReturn {
    const config = useConfig();
    const [financialsData, setFinancialsData] = useState<FinancialsData>({
        tableData: [],
        rowMetaData: [],
        idRowMap: {},
    });

    const [dataLoaded, setDataLoaded] = useState<boolean>(false);

    const [getFinancialValuesQuery, {
        data: getFinancialValuesQueryResponse,
        loading: getFinancialValuesQueryLoading,
        networkStatus,
        refetch
    }] = useGetFinancialValuesLazyQuery({
        fetchPolicy: "no-cache",
        notifyOnNetworkStatusChange: true,
        // onCompleted:(data)=>{
        //     console.log('>>>> Financial values Loaded!', data);
        //     console.log('networkStatus', networkStatus);
        // },
    });

    const [currentProperties, setCurrentProperties] = useState<Property[]>(properties);

    const [upsertAccountValues] = useUpsertAccountValuesMutation();

    const fetchEntityYearValuesMixed = (entityRequestInfo: FinancialEntityRequestInfo[], properties: Property[], phase?:'INITIAL_LOAD'|'UPDATE'): void => {
        const propertyIds = properties.map(p => p.id);
        const financialValueSelectors: FinancialValueQueryInput[] = [];

        entityRequestInfo.forEach(info => {
            // actuals
            financialValueSelectors.push({
                propertyIds,
                entityIds: info.entityIds,
                entityType: info.type,
                versionIds: [config.actualVersionId],
                monthIndexes: new Array(config.startReforecastMonthIndex).fill(0).map((_, i) => i),
            });
            // reforecast
            financialValueSelectors.push({
                propertyIds,
                entityIds: info.entityIds,
                entityType: info.type,
                versionIds: [config.reforecastVersionId],
                monthIndexes: new Array(12 - config.startReforecastMonthIndex).fill(0).map((_, i) => i + config.startReforecastMonthIndex)
            });
            // reforecast
            financialValueSelectors.push({
                propertyIds,
                entityIds: info.entityIds,
                entityType: info.type,
                versionIds: [config.nextYearBudgetVersionId],
                monthIndexes: new Array(12).fill(0).map((_, i) => i),
            });
        });

        const vars = {
            financialValueSelectors: financialValueSelectors,
            financialTotalValueSelectorsProjections: [],
            financialTotalValueSelectorsHistorical: []
        };

        if(phase == 'UPDATE' && refetch){
            void refetch({
                ...vars,
                useCache: true
            });
        } else {
            getFinancialValuesQuery({
                variables: vars,
            });
        }
    };

    const updateParentTotals = (rowMeta:FinancialEntityMetaData, useTableData:YearTableData[], updatedCol?:number):YearTableData[] => {
        const parentId = rowMeta?.parentId;
        if(parentId){
            const parentDataRow:number|undefined = financialsData.idRowMap[parentId];
            if(parentDataRow != undefined){
                const parentRowMeta = financialsData.rowMetaData[parentDataRow];
                const parentTableData = financialsData.tableData[parentDataRow];
                const directChildRows = parentRowMeta?.directChildRows;
                if(directChildRows && directChildRows.length > 0){
                    let includeDataRows:(number|undefined)[];
                    if(parentRowMeta?.type == FinancialEntityType.Account){
                        includeDataRows = directChildRows.map( (_, idx) => {
                            return parentRowMeta.dataRow + 1 + idx;
                        });
                    } else {
                        includeDataRows = directChildRows.map( (childRowMeta) => {
                            return financialsData.idRowMap[childRowMeta.id];
                        });
                    }

                    const gatheredReforecastTotals:number[] = new Array(13).fill(0);
                    const gatheredBudgetTotals:number[] = new Array(13).fill(0);
                    includeDataRows.forEach( (rowNum) => {
                        if(rowNum != undefined){
                            const rowData = financialsData.tableData[rowNum] ?? [];
                            const startCol = updatedCol ? updatedCol : 0;
                            const maxCol = updatedCol ? updatedCol + 1 : 13;

                            for(let col = startCol; col < maxCol; col++){
                                const reforecastValue = rowData[col + 1];
                                if(typeof reforecastValue == 'number'){
                                    gatheredReforecastTotals[col] += reforecastValue;
                                }
                                const budgetValue = rowData[col + 14];
                                if(typeof budgetValue == 'number'){
                                    gatheredBudgetTotals[col] += budgetValue;
                                }
                            }
                        }
                    });

                    if(parentTableData && parentRowMeta?.dataRow != undefined){
                        // Parent Row
                        useTableData[parentRowMeta.dataRow]?.splice(1, 13, ...gatheredReforecastTotals);
                        useTableData[parentRowMeta.dataRow]?.splice(14, 13, ...gatheredBudgetTotals);

                        if(parentRowMeta.summaryDataRow){
                            useTableData[parentRowMeta.summaryDataRow]?.splice(1, 13, ...gatheredReforecastTotals);
                            useTableData[parentRowMeta.summaryDataRow]?.splice(14, 13, ...gatheredBudgetTotals);
                        }

                    }
                }

                if(parentRowMeta?.parentId){
                    updateParentTotals(parentRowMeta, useTableData);
                }
            }
        }

        return useTableData;
    };

    const updateEntityValue = (value:number|null, monthIdx:number, rowMeta:FinancialEntityMetaData): void => {

        const yearSet = Math.floor(monthIdx/12);
        const tableDataRow = financialsData.tableData[rowMeta.dataRow];
        const sliceStart = yearSet == 0 ? 1 : 14;

        const newYearData:YearTableData|undefined = tableDataRow?.slice(sliceStart, sliceStart + 12);

        if(tableDataRow && newYearData){
            newYearData[monthIdx % 12] = value;
            newYearData.push(getArrayValuesSum(newYearData));

            // Apply the update to the dataRow itself
            let updatedTableData = [...financialsData.tableData];
            updatedTableData[rowMeta.dataRow]?.splice(sliceStart, 13, ...newYearData);

            // Traverse parent rows making updates
            updatedTableData = updateParentTotals(rowMeta, updatedTableData);

            const versionId = monthIdx < 12 ? config.reforecastVersionId : config.nextYearBudgetVersionId;
            const sendValue = value == null ? 0 : value;
            const [accountId, propertyId] = rowMeta.id.split('#');

            if(accountId && propertyId){
                void upsertAccountValues({
                    variables: {
                        monthIndexes: [monthIdx % 12],
                        values: [sendValue.toString()],
                        versionId: versionId,
                        accountId: accountId,
                        propertyId: propertyId,
                    }
                });
            }

            setFinancialsData( current => {
                return {
                    ...current,
                    tableData: updatedTableData,
                };
            });
        }
    };

    const applyRowMetaUpdatesRecord = (updatesRecord:Record<string, Partial<FinancialEntityMetaData>>):void => {

        const updatedRowMeta = [...financialsData.rowMetaData];
        // Process each entity metadata update
        Object.keys(updatesRecord).forEach( updateId => {
            const entityRowIdx = financialsData.idRowMap[updateId];
            if(entityRowIdx != undefined){
                const rowUpdate = updatesRecord[updateId];

                if(entityRowIdx != -1){
                    const currentRowMeta = financialsData.rowMetaData[entityRowIdx];
                    if(currentRowMeta){
                        updatedRowMeta[entityRowIdx] = Object.assign(currentRowMeta, rowUpdate);
                    }
                }
            }
        });

        // All rowMeta updates applied, update state
        setFinancialsData( prev => {
            return {
                ...prev,
                rowMetaData: updatedRowMeta,
            };
        });
    };

    const getFinancialEntitiesMetaData = (dataRow: number, rowCount = 1): FinancialEntityMetaData[] => {
        return financialsData.rowMetaData.slice(dataRow, dataRow + rowCount);
    };

    const updateFinancialEntitiesMetaData = (entries: FinancialEntityMetaData[]): void => {
        const updatedRowMetaData = [...financialsData.rowMetaData];
        entries.forEach(entryUpdate => {
            updatedRowMetaData[entryUpdate.dataRow] = entryUpdate;
        });

        setFinancialsData(state => {
            return {
                ...state,
                rowMetaData: updatedRowMetaData,
            };
        });
    };

    const getRowDirectChildrenMeta = (row: number): FinancialEntityMetaData[] => {
        const directChildRows = financialsData.rowMetaData[row]?.directChildRows;
        const directChildRowMeta: FinancialEntityMetaData[] = [];
        if(directChildRows && directChildRows.length > 0){
            directChildRows.forEach(rowMeta => {
                const thisRowMeta = getFinancialEntitiesMetaData(rowMeta.dataRow)[0];
                if(thisRowMeta){
                    directChildRowMeta.push(thisRowMeta);
                }
            });
        }
        return directChildRowMeta;
    };

    useEffect(
        () => {
            if(!config.chartOfAccounts || !config.isReady){
                return;
            }

            if(currentProperties.length > 0){

                const {
                    parsedFinancialsData,
                    gatheredCategoryIds,
                    gatheredAccountIds,
                } = buildInitialFinancialsData(config.chartOfAccounts, currentProperties, config);

                setFinancialsData(parsedFinancialsData);

                const foundEntities: FinancialEntityRequestInfo[] = [];
                if(gatheredCategoryIds.length > 0){
                    foundEntities.push({
                        type: FinancialEntityType.Category,
                        entityIds: gatheredCategoryIds
                    });
                }
                if(gatheredAccountIds.length > 0){
                    foundEntities.push({
                        type: FinancialEntityType.Account,
                        entityIds: gatheredAccountIds
                    });
                }

                reportPhase(FinancialsLoadPhases.FETCH_ENTITY_YEAR_VALUES);
                fetchEntityYearValuesMixed(foundEntities, currentProperties);
            }
        },
        [config.chartOfAccounts, config.isReady, currentProperties],
    );
    /**
     * Triggered once financial values for categories have been loaded. Only Categories and summaries are visible on
     * initial load.
     */
    useEffect(
        () => {
            if(!getFinancialValuesQueryResponse || getFinancialValuesQueryLoading){
                return;
            }

            reportPhase(FinancialsLoadPhases.BUILD_FINANCIALS_DATA);
            const entitiesYearData = buildEntitiesYearsData(getFinancialValuesQueryResponse, config);
            if(entitiesYearData){
                const {
                    updatedTableData,
                    updatedRowMetaData,
                } = buildTableDataRows(entitiesYearData, financialsData);

                setFinancialsData(current => {
                    return {
                        ...current,
                        tableData: updatedTableData,
                        rowMetaData: updatedRowMetaData,
                    };
                });

                // Final data loaded signal. All internal data loaded.
                setDataLoaded(true);
            }
        },
        [getFinancialValuesQueryResponse, getFinancialValuesQueryLoading]
    );

    return {
        financialsData,
        setFinancialsData,
        setCurrentProperties,
        fetchEntityYearValuesMixed,
        updateEntityValue,
        getFinancialEntitiesMetaData,
        updateFinancialEntitiesMetaData,
        applyRowMetaUpdatesRecord,
        getRowDirectChildrenMeta,
        dataLoaded,
        networkStatus,
    };
}
