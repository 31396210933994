import { MarketRentColSpec as colSpec } from "./types";

import { SJSSheetStyles as SJSS } from "../../../../../sjs/layout/shared/SJSSheetStyles";
import { MarketRentSheetStyles as sheetStyle } from "../MarketRentSheetStyles";

export enum MarketRentColId{
    ITEM_TITLE,
    THIS_YEAR_MONTH_0 = 2,
    THIS_YEAR_MONTH_1,
    THIS_YEAR_MONTH_2,
    THIS_YEAR_MONTH_3,
    THIS_YEAR_MONTH_4,
    THIS_YEAR_MONTH_5,
    THIS_YEAR_MONTH_6,
    THIS_YEAR_MONTH_7,
    THIS_YEAR_MONTH_8,
    THIS_YEAR_MONTH_9,
    THIS_YEAR_MONTH_10,
    THIS_YEAR_MONTH_11,
    THIS_YEAR_AVERAGES,
    NEXT_YEAR_MONTH_0,
    NEXT_YEAR_MONTH_1,
    NEXT_YEAR_MONTH_2,
    NEXT_YEAR_MONTH_3,
    NEXT_YEAR_MONTH_4,
    NEXT_YEAR_MONTH_5,
    NEXT_YEAR_MONTH_6,
    NEXT_YEAR_MONTH_7,
    NEXT_YEAR_MONTH_8,
    NEXT_YEAR_MONTH_9,
    NEXT_YEAR_MONTH_10,
    NEXT_YEAR_MONTH_11,
    NEXT_YEAR_AVERAGES,
    UNIT_ID,
    RENT_METRIC_TYPE,
    NO_RENO_ADJUST,
}

const MONTH_SPEC:colSpec = {
    width: 74,
    actualHeader: SJSS.ACTUAL_DATA_HEADER,
    lastActualHeader: SJSS.LAST_ACTUAL_DATA_HEADER,
    reforecastHeader: SJSS.REFORECAST_DATA_HEADER,
    lastReforecastHeader: SJSS.LAST_REFORECAST_DATA_HEADER,
    content: SJSS.EDITABLE_CELL,
    contentActual: sheetStyle.ACTUAL_CELL,
    contentActualLast: sheetStyle.LAST_ACTUAL_CELL,
};

export const WorksheetLayoutColSpecs: Record<number, colSpec> = {
    [MarketRentColId.ITEM_TITLE]: {
        width: 191,
        actualHeader: sheetStyle.ITEM_HEADER,
        content: sheetStyle.ITEM_LABEL,
        contentActual: sheetStyle.ITEM_LABEL,
        isLabelCell: true,
    },
    [MarketRentColId.THIS_YEAR_MONTH_0]: MONTH_SPEC,
    [MarketRentColId.THIS_YEAR_MONTH_1]: MONTH_SPEC,
    [MarketRentColId.THIS_YEAR_MONTH_2]: MONTH_SPEC,
    [MarketRentColId.THIS_YEAR_MONTH_3]: MONTH_SPEC,
    [MarketRentColId.THIS_YEAR_MONTH_4]: MONTH_SPEC,
    [MarketRentColId.THIS_YEAR_MONTH_5]: MONTH_SPEC,
    [MarketRentColId.THIS_YEAR_MONTH_6]: MONTH_SPEC,
    [MarketRentColId.THIS_YEAR_MONTH_7]: MONTH_SPEC,
    [MarketRentColId.THIS_YEAR_MONTH_8]: MONTH_SPEC,
    [MarketRentColId.THIS_YEAR_MONTH_9]: MONTH_SPEC,
    [MarketRentColId.THIS_YEAR_MONTH_10]: MONTH_SPEC,
    [MarketRentColId.THIS_YEAR_MONTH_11]: {
        ...MONTH_SPEC,
        ...{
            header: SJSS.LAST_DATA_HEADER,
            content: SJSS.LAST_DATA_CELL,
        }
    },
    [MarketRentColId.THIS_YEAR_AVERAGES]: {
        width: 94,
        actualHeader: sheetStyle.TOTALS_COLUMN_HDR,
        content: sheetStyle.TOTALS_COLUMN_DATA_CELL,
        contentActual: sheetStyle.TOTALS_COLUMN_DATA_CELL,
    },
};
