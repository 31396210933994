import { useEffect, useState } from "react";
import { Property, useProperties } from "../../contexts/properties/PropertiesContext";

export type AnalystDashboardProperties = ReturnType<typeof useAnalystDashboardProperties>;

export function useAnalystDashboardProperties() {
    const {properties, loaded} = useProperties();

    const [propertyOptions, setPropertyOptions] = useState<{value: string, label: string}[]>([]);
    const [selectedProperties, setSelectedProperties] = useState<Property[]>([]);
    const [initialSelectedProperties, setInitialSelectedItems] = useState<string[]>([]);

    const applyPropertiesSelection = (selected: string[]) => {
        setSelectedProperties(properties?.filter(p => selected.find(s => s == p.id) != undefined) ?? []);
    };

    useEffect(
        () => {
            if (loaded && properties) {
                setPropertyOptions(_prev => properties.map(p => ({value: p.id, label: p.name})));
                setInitialSelectedItems(_prev => properties.map(p => p.id));
                setSelectedProperties(_prev => properties);
            }
        },
        [properties, loaded]
    );

    return {
        isReady: loaded,
        propertyOptions: propertyOptions,
        selectedProperties: selectedProperties,
        initialSelectedProperties: initialSelectedProperties,
        applyPropertiesSelection: applyPropertiesSelection
    };
}