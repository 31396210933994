import { ReactElement, useEffect, useState } from "react";
import { MetricCard } from "../../metric-card/MetricCard";
import Chart from "react-apexcharts";
import MenuTitle from "../../menu-title/MenuTitle";
import { IMenuTitleOption } from "../../menu-title/logic/menuTitle";
import * as css from "../styles/styles.module.scss";
import { Col, Grid, Row } from "@zendeskgarden/react-grid";
import { ApexOptions } from "apexcharts";
// import { leftComparisonOptions, rightComparisonOptions } from "../data/useAccountSummary";
import { useAccountSummaryContext } from "../../../pages/workflows/account/AccountSummaryContext";
import { AccountChangeColorMap, AccountChangeDirection, getAccountChangeDirection, getMonthlyAvgsForYear, getVarianceForSet } from "../logic/AccountSummaryLogic";
import { formatterDollarUSNoDecimal } from "../../../utils/formatters";
import { BudgetingType } from "../../../BudgetingType";
import { COLORS } from "../../../constants/Colors";
import { buildApexChartConfig } from "../../../styles/apex-charts/ViziblyChartDefaultConfig";

interface IVarianceCardProps {
    wide?: boolean
}

export function MonthlyCard(props: IVarianceCardProps): ReactElement {
    const ctxAcctSummary = useAccountSummaryContext();

    const [chartSeries, setChartSeries] = useState<ApexAxisChartSeries>([]);
    const [chartCategories, setChartCategories] = useState<string[] | number[]>([]);
    const [leftComparator, setLeftComparator] = useState<IMenuTitleOption>({ label: '', value: 0 });
    const [rightComparator, setRightComparator] = useState<IMenuTitleOption>({ label: '', value: 0 });
    const [rfcstAvg, setRfcstAvg] = useState<number>(0);
    const [bdgtAvg, setBdgtAvg] = useState<number>(0);
    const [difference, setDifference] = useState<number>(0);
    const [accountChangeDirection, setAccountChangeDirection] = useState<AccountChangeDirection>(AccountChangeDirection.NEITHER);

    const options: IMenuTitleOption[] = [
        {
            label: 'Monthly Avg',
            value: 0, //'avg'
        },
        // TODO enable selecting custom comparison metrics - bowman APP-1043
        // {
        //     label: 'Monthly Avg/Sqft',
        //     value: 1, //'avg_sqft'
        // },
        // {
        //     label: 'Monthly Avg/Unit',
        //     value: 2, //'avg_unit'
        // },
    ];

    const chartOptions: ApexOptions = {
        chart: {
            stacked: true,
        },
        xaxis: {
            categories: chartCategories,
        },
    };

    useEffect(
        () => {
            if (!ctxAcctSummary.data) return;

            const currentYearAverages = getMonthlyAvgsForYear(ctxAcctSummary.currentYear, ctxAcctSummary);
            const nextYearAverages = getMonthlyAvgsForYear(ctxAcctSummary.currentYear + 1, ctxAcctSummary);
            const localCurrentYearTruncated = ctxAcctSummary.currentYear % 100;

            if (ctxAcctSummary.budgetType == BudgetingType.REFORECAST) {
                const localAccountChangeDirection = getAccountChangeDirection(currentYearAverages.rfcst, currentYearAverages.bdgt, ctxAcctSummary.isIncome);
                const { varValue } = getVarianceForSet(currentYearAverages.rfcst, currentYearAverages.bdgt);

                setChartCategories([
                    `${localCurrentYearTruncated} RFCST`,
                    `${localCurrentYearTruncated} BDGT`,
                ]);
                setRfcstAvg(currentYearAverages.rfcst);
                setBdgtAvg(currentYearAverages.bdgt);
                setDifference(varValue);
                setLeftComparator({ label: `${localCurrentYearTruncated} RFCST`, value: 0 });
                setRightComparator({ label: `${localCurrentYearTruncated} BDGT`, value: 0 });
                setAccountChangeDirection(localAccountChangeDirection);
                setChartSeries([
                    {
                        name: 'Actual',
                        data: [currentYearAverages.act, null],
                        color: COLORS.LAVENDER_300,
                    },
                    {
                        name: 'Forecast',
                        data: [currentYearAverages.fcst, null],
                        color: COLORS.PRIMARY_200,
                    },
                    {
                        name: 'Budget',
                        data: [null, currentYearAverages.bdgt],
                        color: COLORS.PRIMARY_200,
                    },
                ]);
            } else {
                const localAccountChangeDirection = getAccountChangeDirection(nextYearAverages.bdgt, currentYearAverages.rfcst, ctxAcctSummary.isIncome);
                const { varValue } = getVarianceForSet(nextYearAverages.bdgt, currentYearAverages.rfcst);

                setChartCategories([
                    `${localCurrentYearTruncated + 1} BDGT`,
                    `${localCurrentYearTruncated} RFCST`,
                ]);
                setRfcstAvg(currentYearAverages.rfcst);
                setBdgtAvg(nextYearAverages.bdgt);
                setDifference(varValue);
                setLeftComparator({ label: `${localCurrentYearTruncated + 1} BDGT`, value: 0 });
                setRightComparator({ label: `${localCurrentYearTruncated} RFCST`, value: 0 });
                setAccountChangeDirection(localAccountChangeDirection);
                setChartSeries([
                    {
                        name: 'Budget',
                        data: [nextYearAverages.bdgt, null],
                        color: COLORS.LAVENDER_300,
                    },
                    {
                        name: 'Actual',
                        data: [null, currentYearAverages.act],
                        color: COLORS.LAVENDER_300,
                    },
                    {
                        name: 'Forecast',
                        data: [null, currentYearAverages.fcst],
                        color: COLORS.PRIMARY_200,
                    },
                ]);
            }
        },
        [ctxAcctSummary.data]
    );

    const chartConfig = buildApexChartConfig({ options: chartOptions, series: chartSeries });

    return (
        <MetricCard wide={props.wide}>
            <MetricCard.Title>
                <div className={css.accountSummaryTitle}>
                    <MenuTitle options={options} />
                </div>

                <div className={css.accountSummarySubtitle}>
                    <MenuTitle options={[leftComparator]} plain />
                    <span className={css.accountSummarySubtitleSeparator}>vs</span>
                    <MenuTitle options={[rightComparator]} plain />
                </div>
            </MetricCard.Title>

            <MetricCard.Body className={props.wide ? css.accountSummaryBodyWide : ''}>
                <Grid className={props.wide ? css.accountSummaryBodyWideLeftWrapper : ''}>
                    <Row justifyContent="center" className={props.wide ? css.accountSummaryBodyWideLeft : ''}>
                        <Col size={props.wide ? 12 : 4} textAlign="center">
                            <h5 className={css.statLabel}>{leftComparator.label}</h5>
                            <h5 className={`${css.statValue} ${!props.wide && css.statValueMedium}`}>
                                {formatterDollarUSNoDecimal.format(ctxAcctSummary.budgetType == 'reforecast' ? rfcstAvg : bdgtAvg)}
                                {/* TODO enable selecting custom comparison metrics - bowman APP-1043 */}
                                {/* <span className={`${css.statLabel} ${css.statLabelLight}`}></span> */}
                            </h5>
                        </Col>

                        <Col size={props.wide ? 12 : 4} textAlign="center">
                            <h5 className={css.statLabel}>{rightComparator.label}</h5>
                            <h5 className={`${css.statValue} ${!props.wide && css.statValueMedium}`}>
                                {formatterDollarUSNoDecimal.format(ctxAcctSummary.budgetType == 'reforecast' ? bdgtAvg : rfcstAvg)}
                                {/* TODO enable selecting custom comparison metrics - bowman APP-1043 */}
                                {/* <span className={`${css.statLabel} ${css.statLabelLight}`}></span> */}
                            </h5>
                        </Col>

                        <Col size={props.wide ? 12 : 4} textAlign="center">
                            <h5 className={css.statLabel}>DIFFERENCE</h5>
                            <h5 className={`${css.statValue} ${!props.wide && css.statValueMedium} ${AccountChangeColorMap[accountChangeDirection]}`}>
                                {formatterDollarUSNoDecimal.format(difference)}
                                {/* TODO enable selecting custom comparison metrics - bowman APP-1043 */}
                                {/* <span className={`${css.statLabel} ${css.statLabelLight}`}></span> */}
                            </h5>
                        </Col>
                    </Row>
                </Grid>

                <div className={props.wide ? css.accountSummaryBodyWideRight : ''}>
                    {chartSeries.length > 0 &&
                        <Chart
                            type="bar"
                            options={chartConfig.options}
                            series={chartConfig.series}
                            height={props.wide ? 240 : 185}
                        />
                    }
                </div>
            </MetricCard.Body>
        </MetricCard>
    );
}