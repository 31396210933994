import { ReactElement, useEffect, useRef, useState } from "react";
import { ThemeProvider } from "@zendeskgarden/react-theming";

import { ViziblyTheme } from "../../../../../../pages/analyst/ViziblyZDGTheme";
import leftChevron from "../../../../../../assets/icons/chevron/left.svg";
import rightChevron from "../../../../../../assets/icons/chevron/right.svg";

import * as css from "./css.module.scss";
import { Button } from "@zendeskgarden/react-buttons";
import { MONTHS } from "../../../../../../constants/Months";


export enum MonthPickerType {
    FROM = "FROM",
    TO = "TO",
}

interface IMonthPickerProps {
    minYear: number;
    maxYear: number;
    minMonth?: number;
    maxMonth?: number;
    defaultYear: number;
    defaultMonth: number;
    setYear: (year: number) => void;
    setMonth: (month: number, year: number) => void;
    type: MonthPickerType;
}

export default function MonthPicker({
    minYear,
    maxYear,
    minMonth=0,
    maxMonth=11,
    defaultYear,
    defaultMonth,
    setYear,
    setMonth,
    type,
}: IMonthPickerProps): ReactElement {
    const [currentYear, setCurrentYear] = useState<number>(defaultYear);
    const [localMinMonth, setLocalMinMonth] = useState<number>(minMonth);
    const [localMaxMonth, setLocalMaxMonth] = useState<number>(maxMonth);
    const [selectedMonth, setSelectedMonth] = useState<number | null>(defaultMonth);
    const selectedMonthRef = useRef<number | null>(defaultMonth);

    useEffect(() => {
        if (currentYear !== defaultYear) {
            if (selectedMonth !== null) {
                selectedMonthRef.current = selectedMonth;
            }
            setSelectedMonth(null);
        } else {
            setSelectedMonth(selectedMonthRef.current);
        }
        if (type === "FROM") {
            if (currentYear < maxYear) {
                setLocalMinMonth(0);
                setLocalMaxMonth(11);
            } else {
                setLocalMinMonth(0);
                setLocalMaxMonth(maxMonth);
            }
        } else if (type === "TO") {
            if (currentYear > minYear) {
                setLocalMinMonth(0);
                setLocalMaxMonth(11);
            } else {
                setLocalMinMonth(minMonth);
                setLocalMaxMonth(11);
            }
        }
    }, [currentYear, minYear, maxYear, type]);

    /**
     * Selecting a month finalizes the flow and sets the month and year
     */
    const handleSelectMonth = (month: number) => {
        setYear(currentYear);
        setSelectedMonth(month);
        setMonth(month, currentYear);
    };

    return (
        <ThemeProvider theme={ViziblyTheme}>
            <div className={`${css.col} ${css.wrapper}`}>
                <div className={`${css.row} ${css.yearPicker}`}>
                    <Button className={`${css.col} ${css.navBtn}`} isBasic onClick={() => setCurrentYear(currentYear - 1)} disabled={currentYear === minYear}>
                        <img src={leftChevron} alt="left" />
                    </Button>
                    <div className={css.col}>
                        {currentYear}
                    </div>
                    <Button className={`${css.col} ${css.navBtn}`} isBasic onClick={() => setCurrentYear(currentYear + 1)} disabled={currentYear === maxYear}>
                        <img src={rightChevron} alt="right" />
                    </Button>
                </div>
                <div className={`${css.row} ${css.monthPicker}`}>
                    {MONTHS.map((month, i) => (
                        <Button
                            key={month}
                            disabled={i < localMinMonth || i > localMaxMonth}
                            className={`${css.month} ${i === selectedMonth && css.selectedMonth}`}
                            onClick={() => handleSelectMonth(i)}
                        >
                            {month}
                        </Button>
                    ))}
                </div>
            </div>
        </ThemeProvider>
    );
}
