import {Button} from "@zendeskgarden/react-buttons";
import {Dropdown, HeaderIcon, HeaderItem, Item, Menu, Separator, Trigger} from "@zendeskgarden/react-dropdowns";
import {ThemeProvider} from "@zendeskgarden/react-theming";
import {ReactElement, useContext, useState} from "react";
import {ViziblyTheme} from "../../../../styles/zendesk-garden/ViziblyZDGTheme";
import * as css from "./styles/css.module.scss";
import {BsGearFill, BsGearWideConnected} from "react-icons/bs";
import {useHistory} from "react-router-dom";
import {AiOutlineLogout} from "react-icons/ai";
import {COLORS} from "../../../../constants/Colors";
import Alert from "../../../alert";
import {AuthContext} from "../../../../contexts/AuthContext";
import {PropertyManagementSystem, UserRole} from "../../../../__generated__/generated_types";
import {useProperties} from "../../../../contexts/properties/PropertiesContext";

export function SettingsNavMenu(): ReactElement {
    const history = useHistory();
    const {user} = useContext(AuthContext);
    const {properties} = useProperties();

    const buttonClass = (history.location.pathname.startsWith("/settings/") || history.location.pathname.startsWith("/admin/")) ? css.focusedButton : undefined;

    const [showAlert, setShowAlert] = useState(false);
    const {signOut} = useContext(AuthContext);
    const logoutClicked = () => {
        signOut?.();
    };

    let highlightedIndex = 9999999;
    if (history.location.pathname.includes("financial-metrics-management")) {
        highlightedIndex = 1;
    } else if (history.location.pathname.includes("property-plan-management")) {
        highlightedIndex = 3;
    } else if (history.location.pathname.includes("lock-management")) {
        highlightedIndex = 5;
    } else if (history.location.pathname.includes("budget-season-management")) {
        highlightedIndex = 7;
    } else if (history.location.pathname.includes("coa-management")) {
        highlightedIndex = 9;
    } else if (history.location.pathname.includes("account-tags-management")) {
        highlightedIndex = 11;
    } else if (history.location.pathname.includes("custom-item-management")) {
        highlightedIndex = 13;
    } else if (history.location.pathname.includes("unit-type-management")) {
        highlightedIndex = 15;
    } else if (history.location.pathname.includes("users")) {
        highlightedIndex = 17;
    }

    return (
        <ThemeProvider theme={ViziblyTheme}>
            <Dropdown
                onSelect={url => history.push(url)}
                highlightedIndex={highlightedIndex}
            >
                <Trigger>
                    <Button isBasic className={`${buttonClass} ${css.navIconButton}`}>
                        <BsGearFill size={24} />
                    </Button>
                </Trigger>

                <Menu className={css.navMenuWrapper} maxHeight="fit-content">
                    {
                        (user && user.role === UserRole.Admin) ?
                            <>
                                <HeaderItem hasIcon>
                                    <HeaderIcon>
                                        <BsGearWideConnected color={COLORS.GREY_300} />
                                    </HeaderIcon>
                                    <span className={css.menuHeaderItem}>Settings</span>
                                </HeaderItem>
                                <Separator />
                                <Item value="/settings/financial-metrics-management">Financial Metrics Management</Item>
                                <Item value="/settings/property-plan-management">Property Plan Management</Item>
                                <Item value="/settings/lock-management">Lock Management</Item>
                                <Item value="/settings/budget-season-management">Budget Season Management</Item>
                                <Separator />
                                <Item value="/settings/coa-management">Chart of Accounts Management</Item>
                                <Item value="/settings/account-tags-management">Account Tags Management</Item>
                                <Separator />
                                <Item value="/settings/custom-item-management">Custom Item Management</Item>
                                <Separator />
                                <Item value="/settings/unit-type-management">Unit Type Management</Item>
                                <Separator />
                                <Item value="/settings/executive-summary-report-management">Executive Summary Report Management</Item>
                                <Separator />
                                <Item value="/admin/users">User Management</Item>
                                <Separator />
                                {properties && properties.findIndex(p => p.propertyManagementSystem === PropertyManagementSystem.Entrata) !== -1 &&
                                    <>
                                        <Item value="/admin/send_budget">Send Budget to PMS</Item>
                                        <Separator />
                                    </>
                                }
                            </>
                            : <></>
                    }
                    <Button isStretched className={css.logoutButton} onClick={() => history.push("/budget-completion")}>
                        Budget Status
                    </Button>
                    <Button isStretched className={css.logoutButton} onClick={() => setShowAlert(true)}>
                        <AiOutlineLogout size={22} className={css.logoutButtonIcon} />

                        Logout
                    </Button>
                </Menu>
            </Dropdown>

            {
                showAlert ?
                    <Alert onCancelClick={() => setShowAlert(false)} onConfirmClick={logoutClicked}
                        message="Do you want to logout from your account?" />
                    : null
            }
        </ThemeProvider>
    );
}
