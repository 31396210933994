import { EmployeeTotals } from "./usePropertyExecutiveSummaryData";
import * as ExcelJS from "exceljs";
import { Property } from "../../../contexts/properties/PropertiesContext";
import FileSaver from "file-saver";

const columns: Partial<ExcelJS.Column>[] = [
    {
        header: "Employee",
        key: "employee",
        width: 50
    },
    {
        header: "Base Pay",
        key: "basePay",
        alignment: {
            horizontal: "right"
        }
    },
    {
        header: "Raise(s)",
        key: "raises",
        alignment: {
            horizontal: "right"
        }
    },
    {
        header: "Total",
        key: "total",
        alignment: {
            horizontal: "right"
        }
    }
];

async function generateXlsx(employees: EmployeeTotals[]): Promise<ArrayBuffer> {
    const workbook = new ExcelJS.Workbook();
    const sheet = workbook.addWorksheet("Employees");
    sheet.columns = columns;
    sheet.addRows(employees.map(e => ({
        employee: e.positionName,
        basePay: parseFloat(e.basePayTotal.toFixed(2)),
        raises: parseFloat(e.raiseTotal.toFixed(2)),
        total: parseFloat((e.basePayTotal + e.raiseTotal).toFixed(2))
    })));

    for(let i = 1; i <= (employees.length + 1); i++) {
        const row = sheet.getRow(i);
        for(let j = 2; j <= columns.length; j++) {
            const cell = row.getCell(j);
            cell.alignment = {
                horizontal: "right"
            };
            cell.numFmt = "0.00";
        }
    }

    return await workbook.xlsx.writeBuffer();
}

export async function exportEmployeeTableToExcel(property: Property, employees: EmployeeTotals[]): Promise<void> {
    const filename = `${property.name}_${property.budgetYear}-budget_employees.xlsx`;
    const xlsx = await generateXlsx(employees);
    const blob = new Blob([xlsx], {type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"});
    FileSaver.saveAs(blob, filename);
}