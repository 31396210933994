import { Button } from "@zendeskgarden/react-buttons";
import { Dots } from "@zendeskgarden/react-loaders";
import { Textarea } from "@zendeskgarden/react-forms";
import React, { Children, ReactElement, ReactNode, useEffect, useRef } from "react";
import * as css from "./styles/css.module.scss";
import { ReactComponent as Check } from '@zendeskgarden/svg-icons/src/16/check-circle-stroke.svg';
import { SavePhase, useVarianceNoteState } from "./logic/useVarianceNoteState";
import { getMonthUSFull } from "../../../../../../../utils/fotmatters";

export interface VarianceNoteProps {
    propertyId: string;
    accountId: string;
    year: number;
    monthIndex: number;
    onDirtyChange?: (isDirty: boolean) => void;
    className?: string;
    children?: ReactElement[]|ReactElement;
}

interface HeaderProps {
    children: ReactNode[]|ReactNode;
    className?: {
        header?:string,
        varianceNoteLabel?: string,
    };
}

function Header({ children, className }: HeaderProps): ReactElement {
    return (
            <div className={className ? `${css.varianceNoteHeader} ${className}` : css.varianceNoteHeader}>
                <span className={className ? `${css.varianceNoteLabel} ${className}` : css.varianceNoteLabel}>{children}</span>
            </div>
    );
}

export function VarianceNote(props: VarianceNoteProps): ReactElement {
    const varianceNoteState = useVarianceNoteState({
        propertyId: props.propertyId,
        accountId: props.accountId,
        year: props.year,
        monthIndex: props.monthIndex
    });
    const buttonRef = useRef<HTMLButtonElement>(null);
    const saveClicked = () => {
        if (!varianceNoteState.canSave) {
            return;
        }

        const buttonElement = buttonRef.current;

        if (buttonElement) {
            // see https://garden.zendesk.com/patterns/buttons#loading
            // this way we preserve button width which is aligned with the button
            // contents in non-animated state
            buttonElement.style.width = `${buttonElement.offsetWidth}px`;
        }

        varianceNoteState.save();
    };

    useEffect(
        () => {
            const buttonElement = buttonRef.current;

            if (varianceNoteState.savePhase === SavePhase.READY) {
                if (buttonElement) {
                    buttonElement.style.width = "";
                }
            }
        },
        [varianceNoteState.savePhase]
    );

    useEffect(
        () => {
            if (props.onDirtyChange) {
                props.onDirtyChange(varianceNoteState.isDirty);
            }
        },
        [varianceNoteState.isDirty]
    );

    let saveButtonContent: ReactNode = "Save";

    if (varianceNoteState.savePhase == SavePhase.SAVING) {
        saveButtonContent = <Dots delayMS={0} area-label="Save" size={16} />;
    }
    else if (varianceNoteState.savePhase == SavePhase.SAVED) {
        saveButtonContent = <Check />;
    }

    // Note: according to designs - when saving is in progress (i.e. savePhase is not READY)
    // we want to display the save button as enabled (for the sake of it's color treatment)
    const showSaveButtonAsDisabled = !varianceNoteState.canSave && varianceNoteState.savePhase == SavePhase.READY;

    const monthString = getMonthUSFull(new Date(props.year, props.monthIndex));

    let header = <></>;

    if(props.children != undefined){
        Children.forEach(props.children, child => {
            if(child.type === Header){
                header = child;
            }
        });
    }

    return (
        <div className={css.varianceNoteContainer}>
            {header}
            <div className={css.varianceNoteTextContainer}>
                <Textarea
                    isBare
                    focusInset={false}
                    className={css.varianceNoteText}
                    value={varianceNoteState.text}
                    placeholder={`Write a Variance Note for ${monthString}...`}
                    onChange={(event) => {
                        varianceNoteState.setText(event.target.value);
                    }}
                />
            </div>
            <div className={css.varianceNoteFooter}>
                <Button
                    disabled={showSaveButtonAsDisabled}
                    size="small"
                    isPrimary
                    className={css.saveButton}
                    onClick={() => saveClicked()}
                    ref={buttonRef}
                >
                    {saveButtonContent}
                </Button>
            </div>
        </div>
    );
}

VarianceNote.Header = Header;