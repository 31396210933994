import { ReactElement, useState } from "react";
import { Property } from "../../../contexts/properties/PropertiesContext";
import { Field as FormField, Label as FormLabel, Checkbox, Radio } from '@zendeskgarden/react-forms';
import * as css from "../styles/bulkUpdate.module.scss";
import { Button } from "@zendeskgarden/react-buttons";
import { FinancialEntityType, useSetMultiAccountAndPropertyLocksMutation } from "../../../__generated__/generated_types";
import PropertyDropdown from "./PropertyDropdown";
import AccountDropdown from "./AccountDropdown";
import { FinancialEntity } from "../../../contexts/chartofaccounts/ChartOfAccountsContext";
import { IFinancialEntityWithDepth } from "../../../utils/account";

export interface IAccountLockBulkUpdaterProps {
    budgetYear: number;
    properties: Property[];
    accounts: FinancialEntity[];
    flatAccounts: IFinancialEntityWithDepth[];
    lockedProperties: {
        reforecastLocked: string[],
        budgetLocked: string[],
    };
    onUpdateCompleted: () => void;
}

export type TAccountDropdownItem = {
    id: string;
    name: string;
    parentId: string | undefined;
    type: FinancialEntityType;
    children: TAccountDropdownItem[];
    depth: number;
}

export default function AccountLockBulkUpdater({ budgetYear, properties, accounts, flatAccounts, lockedProperties, onUpdateCompleted }: IAccountLockBulkUpdaterProps): ReactElement {
    const [shouldLock, setShouldLock] = useState<boolean>(true);
    const [rfcstPeriodSelected, setRfcstPeriodSelected] = useState<boolean>(false);
    const [bdgtPeriodSelected, setBdgtPeriodSelected] = useState<boolean>(false);
    const [selectedProperties, setSelectedProperties] = useState<string[]>([]);
    const [selectedAccounts, setSelectedAccounts] = useState<string[]>([]);
    const [saving, setSaving] = useState<boolean>(false);

    const [updateMultiAccountAndPropertyLocks] = useSetMultiAccountAndPropertyLocksMutation({
        onCompleted: () => {
            setSaving(false);
            resetForm();
            onUpdateCompleted();
        }
    });

    function resetForm() {
        setSelectedAccounts([]);
        setSelectedProperties([]);
        setShouldLock(true);
        setRfcstPeriodSelected(false);
        setBdgtPeriodSelected(false);
    }

    const childIds: Set<string> = new Set();
    accounts.forEach(account => {
        const ids = account.children.map(x => x.id);
        for (const id of ids) {
            childIds.add(id);
        }

        if (account.componentOps) {
            const compIds = account.componentOps.map(x => x.sourceComponentId);
            for (const id of compIds) {
                childIds.add(id);
            }
        }
    });
    const roots = accounts.filter(x => !childIds.has(x.id));
    const accountsForDropdown: TAccountDropdownItem[] = [];

    function findEntity(root: FinancialEntity | undefined, id: string): FinancialEntity | null {
        if (!root) {
            return null;
        }

        if (root.id === id) {
            return root;
        }

        for (const child of root.children) {
            const foundItem = findEntity(child, id);

            if (foundItem !== null) {
                return foundItem;
            }
        }

        return null;
    }

    function buildDropdownTree(entity: FinancialEntity, depth: number): TAccountDropdownItem {
        let childIds = entity.children.map(x => x.id);

        if (entity.componentOps) {
            const compIds = entity.componentOps.map(x => x.sourceComponentId);
            for (const id of compIds) {
                childIds.push(id);
            }
        }

        childIds = childIds.dedupe();

        const childEntities: FinancialEntity[] = [];

        for (const id of childIds) {
            let result;

            for (const item of accounts) {
                const res = findEntity(item, id);

                if (res) {
                    result = res;
                }
            }

            if (result) {
                result.parentId = entity.id;
                childEntities.push(result);
            }
        }

        return {
            id: entity.id,
            name: entity.name,
            parentId: entity.parentId,
            type: entity.type,
            children: childEntities.sort((a, b) => a.order > b.order ? 1 : -1).map(x => buildDropdownTree(x, depth + 1)),
            depth,
        }
    }

    roots.forEach(root => {
        accountsForDropdown.push(buildDropdownTree(root, 0));
    });

    return (
        <>
            <ol className={css.updaterControlsSteps}>
                <li>
                    Select GL Accounts
                    <AccountDropdown
                        selectedAccounts={selectedAccounts}
                        accounts={accountsForDropdown}
                        onAccountSelect={selected => {
                            setSelectedAccounts(selected);
                        }}
                    />
                </li>

                <li>
                    Select Forecast Period(s)
                    <FormField>
                        <Checkbox checked={rfcstPeriodSelected} onChange={event => setRfcstPeriodSelected(event.target.checked)}>
                            <FormLabel isRegular>{budgetYear - 1} Reforecast</FormLabel>
                        </Checkbox>
                    </FormField>
                    <FormField>
                        <Checkbox checked={bdgtPeriodSelected} onChange={event => setBdgtPeriodSelected(event.target.checked)}>
                            <FormLabel isRegular>{budgetYear} Budget</FormLabel>
                        </Checkbox>
                    </FormField>
                </li>

                <li>
                    Select Properties
                    <PropertyDropdown
                        selectedProperties={selectedProperties}
                        properties={properties}
                        lockedProperties={lockedProperties}
                        onPropertySelect={selected => {
                            setSelectedProperties(selected);
                        }}
                    />
                </li>

                <li>
                    Select Status
                    <FormField>
                        <Radio
                            value={"lock"}
                            checked={shouldLock}
                            onChange={() => setShouldLock(true)}
                        >
                            <FormLabel>Lock</FormLabel>
                        </Radio>
                    </FormField>
                    <FormField>
                        <Radio
                            value={"unlock"}
                            checked={!shouldLock}
                            onChange={() => setShouldLock(false)}
                        >
                            <FormLabel>Unlock</FormLabel>
                        </Radio>
                    </FormField>
                </li>
            </ol>

            <div className={css.updaterControlsButtons}>
                {(selectedAccounts.length > 0 || selectedProperties.length > 0 || rfcstPeriodSelected || bdgtPeriodSelected) &&
                    <Button
                        className={css.button}
                        isBasic
                        isDanger
                        onClick={resetForm}
                    >
                        Cancel
                    </Button>
                }

                <Button
                    className={css.button}
                    isPrimary
                    disabled={saving || selectedAccounts.length == 0 || selectedProperties.length == 0 || (!rfcstPeriodSelected && !bdgtPeriodSelected)}
                    onClick={() => {
                        const locks: { lockRfcst?: boolean, lockBdgt?: boolean } = {};

                        if (rfcstPeriodSelected === true) {
                            locks.lockRfcst = shouldLock;
                        }

                        if (bdgtPeriodSelected === true) {
                            locks.lockBdgt = shouldLock;
                        }

                        const validAccounts: Set<string> = new Set(flatAccounts.filter(a => a.entityType == "ACCOUNT").map(a => a.id));
                        const accountIds = selectedAccounts.filter(a => validAccounts.has(a));

                        setSaving(true);
                        updateMultiAccountAndPropertyLocks({
                            variables: {
                                propertyIds: selectedProperties,
                                accountIds: accountIds,
                                budgetYear,
                                ...locks,
                            }
                        })
                    }}
                >
                    Save
                </Button>
            </div>
        </>
    );
}
