import { IMenuTitleOption } from "../../../../components/menu-title/logic/menuTitle";
import { COLORS } from "../../../../constants/Colors";
import { IFinancialEntityWithDepth } from "../../../../utils/account";
import { getVarianceColor } from "../../../../utils/variance";
import { flatAverageValues, sumYearValues } from "../../../../utils/yearValues";
import { EntityType, FinancialValuesYearModel, VersionType } from "../../../../__generated__/generated_types";
import { TPlanDashChartData, TPlanDashChartSeriesData } from "./commonWidgetData";
import { buildSummaryData, getApexSeriesFromParsedData } from "./operationalData";

export function buildAccountMenuOptions(data: IFinancialEntityWithDepth[]): IMenuTitleOption[] {
    return data.map((account, index) => ({
        label: account.number ? `${account.name} (${account.number})` : account.name,
        value: index,
        depth: account.depth,
        bold: account.entityType != EntityType.Account,
    }));
}

export function buildComparisonMenuOptions(financialValues: FinancialValuesYearModel[]): IMenuTitleOption[] {
    const options: IMenuTitleOption[] = [];

    const sortedData = financialValues.filter(x => x.versionType != VersionType.Budget).sort((a, b) => {
        if (a.year > b.year) return -1;
        if (a.year < b.year) return 1;
        return 0;
    });

    let rfcstYear = 0;
    // trying to make sure to keep the indexes for the options matching up with the data properly
    let indexIterator = sortedData.filter((value, index, array) => array.indexOf(value) === index).length - 2;
    sortedData.forEach(item => {
        if (item.versionType == VersionType.Reforecast) {
            rfcstYear = item.year;
        } else if (rfcstYear == item.year) {
            return;
        }

        options.push({
            label: `${item.year % 100} ${item.versionType == VersionType.Reforecast ? 'RFCST' : 'ACT'}`,
            value: indexIterator,
        });

        indexIterator--;
    });

    return options;
}

export function getVarianceColorForAccount(account: IFinancialEntityWithDepth | undefined, value: string | number | undefined): string {
    if (!account || value == undefined) {
        return '';
    }

    const valueAsNumber = parseInt(String(value));

    if (account && account.budgetComponentType && valueAsNumber != undefined) {
        if (valueAsNumber != 0) {
            return getVarianceColor(valueAsNumber, account.budgetComponentType, account.budgetComponentNegate);
        }
    }

    return '';
}

export function parseGLAccountData(
    rawData: FinancialValuesYearModel[],
    startMonthIndex: number,
): TPlanDashChartSeriesData[] {
    const seriesData: TPlanDashChartSeriesData[] = [];

    let years: number[] = [];
    rawData.map(x => x.year).forEach(year => {
        if (!years.includes(year)) {
            years.push(year);
        }
    });

    years = years.sort();
    years.forEach(year => {
        const dataForYear = rawData.filter(x => x.year == year);
        const budgetData = dataForYear.find(x => x.versionType == VersionType.Budget);
        const reforecastData = dataForYear.find(x => x.versionType == VersionType.Reforecast);
        const actualsData = dataForYear.find(x => x.versionType == VersionType.Actuals);

        if (budgetData) {
            seriesData.push({
                ...budgetData,
                counts: [],
                name: `${budgetData.year % 100} BDGT`,
                color: COLORS.PRIMARY_400,
                type: 'line',
                averageValue: flatAverageValues(budgetData.values),
                averageCount: flatAverageValues(budgetData.values),
                totalValue: sumYearValues(budgetData.values),
                totalCount: sumYearValues(budgetData.values),
            });
        }

        if (reforecastData && actualsData) {
            const actualValues = actualsData.values.slice(0, startMonthIndex);
            const forecastValues = reforecastData.values.slice(startMonthIndex);
            const reforecastValues = actualsData ? actualValues.concat(forecastValues) : reforecastData.values;
            const actualValuesWithNulls = actualValues.concat(new Array(12 - startMonthIndex).fill(null));
            const forecastValuesWithNulls = new Array(startMonthIndex).fill(null).concat(reforecastData.values.slice(startMonthIndex));

            seriesData.push({
                ...actualsData,
                name: `${actualsData.year % 100} REFORECAST - ACTUAL`,
                color: COLORS.LAVENDER_400,
                type: 'bar',
                values: actualValuesWithNulls,
                counts: [],
                averageValue: flatAverageValues(reforecastValues),
                averageCount: flatAverageValues(reforecastValues),
                totalValue: sumYearValues(reforecastValues),
                totalCount: sumYearValues(reforecastValues),
            });

            seriesData.push({
                ...reforecastData,
                name: `${reforecastData.year % 100} REFORECAST - FORECAST`,
                color: COLORS.LAVENDER_200,
                type: 'bar',
                values: forecastValuesWithNulls,
                counts: [],
                averageValue: flatAverageValues(reforecastValues),
                averageCount: flatAverageValues(reforecastValues),
                totalValue: sumYearValues(reforecastValues),
                totalCount: sumYearValues(reforecastValues),
            });
        } else if (actualsData) {
            seriesData.push({
                ...actualsData,
                name: `${actualsData.year % 100} ACTUAL`,
                color: COLORS.LAVENDER_400,
                type: 'bar',
                counts: [],
                averageValue: flatAverageValues(actualsData.values),
                averageCount: flatAverageValues(actualsData.values),
                totalValue: sumYearValues(actualsData.values),
                totalCount: sumYearValues(actualsData.values),
            });
        } else if (reforecastData) {
            seriesData.push({
                ...reforecastData,
                name: `${reforecastData.year % 100} REFORECAST`,
                color: COLORS.LAVENDER_200,
                type: 'bar',
                counts: [],
                averageValue: flatAverageValues(reforecastData.values),
                averageCount: flatAverageValues(reforecastData.values),
                totalValue: sumYearValues(reforecastData.values),
                totalCount: sumYearValues(reforecastData.values),
            });
        }
    });

    return seriesData;
}

export function getGLAccountChartData(
    seriesData: TPlanDashChartSeriesData[],
    menuOptions: IMenuTitleOption[],
    comparisonIndex: number,
    focusYear: number
): TPlanDashChartData {
    const chartData = getApexSeriesFromParsedData(seriesData, comparisonIndex, focusYear);
    const summaryData = buildSummaryData(
        {
            seriesData,
            menuOptions,
        },
        comparisonIndex,
        'dollar',
    );

    return {
        varianceCounts: chartData?.varianceCounts ?? [],
        chartSeriesData: chartData?.chartSeriesData ?? [],
        summaryData: summaryData,
    };
}