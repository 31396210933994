import { ReactElement } from "react";
import { BudgetingType } from "../../../BudgetingType";
import { FinancialMetrics } from "../../../components/metrics/FinancialMetrics";
import { useSettings } from "../../../contexts/settings/SettingsContext";

import { spreadsheetContainer } from "../account/styles/styles.module.scss";

export function OperationalFinancialMetrics({type}: {type: BudgetingType}): ReactElement {
	const {year} = useSettings();
	return (
            <div className='financial-metrics-container' style={{marginTop: "1em"}}>
                <FinancialMetrics
                    budgetComponentName="Gross potential rent"
                    year={year}
                    accountId='' // this will fail to subscribe
                    showActuals={type === BudgetingType.REFORECAST}
                    className={spreadsheetContainer}
                />
            </div>
	);
}
