import { ReactElement, Children } from "react";
import * as css from '../styles/insights.module.scss';

interface ISectionTitleProps {
    children: ReactElement | ReactElement[] | string,
    className?: string,
}

function SectionTitle(props: ISectionTitleProps):ReactElement {
    return (
            <div className={props.className ? `${css.insightsHeading} ${props.className}` : css.insightsHeading}>
                {props.children}
            </div>
    );
}

interface IInsightsSectionProps {
    children: ReactElement | ReactElement[],
    className?: string,
}

function SectionBody(props: IInsightsSectionProps):ReactElement {
    return (
            <div className={props.className ? `${css.insightsBody} ${props.className}` : css.insightsBody}>
                {props.children}
            </div>
    );
}

export default function InsightsSection(props: IInsightsSectionProps):ReactElement {
    let title = <></>;
    let sectionBody = <></>;

    Children.forEach(props.children, child => {
        if(child.type === SectionTitle) {
            title = child;
        }
        if(child.type === SectionBody) {
            sectionBody = child;
        }
    });

    return (
            <section>
                {title}
                {sectionBody}
            </section>
    );
}

InsightsSection.Title = SectionTitle;
InsightsSection.Body = SectionBody;
