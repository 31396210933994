import { useEffect, useState } from "react";
import { useConfig } from "../../../../../../hooks/useConfig";

type PropertyOption = {
    value: string;
    label: string;
}

export type LineItemsReportState = {
    selectedProperties: PropertyOption[];
    availablePropeties: PropertyOption[];
}

export type UseItemsReport = LineItemsReportState & {
    selectedPropertyIds: string[];
    setSelectedProperties: (ids: string[]) => void;
}

export function useLineItemsReportState(): UseItemsReport {
    const config = useConfig();
    const [state, setState] = useState<LineItemsReportState>(
        {
            selectedProperties: [],
            availablePropeties: []
        }
    );

    useEffect(
        () => {
            if (config.isReady && config.properties?.properties) {
                setState(prev => {
                    if (config.properties.properties) {
                        const availableProperties = config.properties.properties.map(p => ({ value: p.id, label: p.name }));
                        const selectedProperties = availableProperties.map(p => p);
                        const newState: LineItemsReportState = {
                            selectedProperties: selectedProperties,
                            availablePropeties: availableProperties
                        };

                        return newState;
                    }
                    else {
                        return prev;
                    }
                });
            }
        },
        [config.isReady, config.properties?.properties]
    );

    const setSelectedProperties = (ids: string[]) => {
        setState(prev => {
            const selectedProperties = prev.availablePropeties.filter(p => ids.includes(p.value));
            return {
                ...prev,
                selectedProperties: selectedProperties
            };
        });
    };

    return {
        ...state,
        selectedPropertyIds: state.selectedProperties.map(p => p.value),
        setSelectedProperties: setSelectedProperties
    }
}