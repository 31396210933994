import * as css from "./styles/userFormStyles.module.scss";

import { Message } from "@zendeskgarden/react-forms";
import { ReactElement } from "react";
import { Dropdown, Field, Item, Label, Menu, Multiselect, Select } from "@zendeskgarden/react-dropdowns";
import Downshift from "downshift";
import { formatterEnumToTitle } from "../../../../../utils/formatters";
import { Tag } from "@zendeskgarden/react-tags";

export interface IUserFormDropdown {
    label: string,
    required: boolean,
    selection: any | any[],
    itemKey?: string,
    itemValue?: string,
    options: any[],
    error: string | undefined,
    isMultiselect?: boolean,
    downshiftProps?: Record<string, any>,
    onSelect: (evt: any) => void,
    onBlur: () => void,
}

export default function UserFormDropdown(props: IUserFormDropdown): ReactElement {
    const {
        label,
        required,
        selection,
        itemKey = "id",
        itemValue = "name",
        options,
        error,
        isMultiselect,
        downshiftProps,
        onSelect,
        onBlur
    } = props;

    const selectProp = isMultiselect ? { selectedItems: selection } : { selectedItem: selection };


    const stateReducer = (state: any, changes: any) => {
        switch (changes.type) {
            case Downshift.stateChangeTypes.keyDownEnter:
            case Downshift.stateChangeTypes.clickItem:
                return {
                    ...changes,
                    highlightedIndex: state.highlightedIndex,
                    isOpen: true,
                    inputValue: '',
                };
            default:
                return changes;
        }
    };

    const computedDownshiftProps = {
        defaultHighlightedIndex: 0,
        stateReducer,
        ...downshiftProps,
    };

    return (
        <Dropdown
            inputValue={""}
            onSelect={onSelect}
            downshiftProps={computedDownshiftProps}
            {...selectProp}
        >
            <Field>
                <Label className={css.userFormLabel}>
                    <span>{label}</span>
                    {
                        required && <span className={css.required}>REQUIRED</span>
                    }
                </Label>
                {
                    isMultiselect
                        ? <Multiselect onBlur={onBlur} renderItem={({ value, removeValue }) => (
                            <Tag>
                                <span>{value[itemValue] ?? formatterEnumToTitle(value)}</span>
                                <Tag.Close onClick={removeValue} />
                            </Tag>
                        )} />
                        : <Select onBlur={onBlur} validation={error ? "error" : undefined}>{selection}</Select>
                }
                {
                    error &&
                    <Message validation="error">{error}</Message>
                }
            </Field>
            <Menu>
                {options.map(option => (
                    <Item key={option[itemKey] ?? option} value={option}>{formatterEnumToTitle(option[itemValue] ?? formatterEnumToTitle(option))}</Item>
                ))}
            </Menu>
        </Dropdown>
    );
}