import { ReactElement } from "react";
import { Cell, Row, Body, Head, HeaderCell, HeaderRow, Table } from '@zendeskgarden/react-tables';
import { Pagination } from '@zendeskgarden/react-pagination';
import { MVRTransactionRow } from "./components/MVRTransactionRow";
import { useMVRTransactions } from "./data/useMVRTransactions";
import * as css from "./styles/css.module.scss";
import { formatMoneySafeNoDecimal } from "./logic/formatting";
import { buildPaginationParameters } from "./logic/buildPaginationParameters";
import { buildItemsStatsString } from "./logic/buildItemsStats";
import * as MVRTransactionsTableConfig from "./MVRTransactionsTableConfig";
import { zdgPaginationTransformPageProps } from "../../../../../../../styles/zendesk-garden/ZDPaginationTransformPageProps";

export interface MVRTransactionsTableProps {
    propertyId: string;
    accountId: string;
    year: number;
    monthIndex: number;
    totalValue: number;
}

export function MVRTransactionsTable(props: MVRTransactionsTableProps): ReactElement {
    const transactionsData = useMVRTransactions({
        propertyId: props.propertyId,
        accountId: props.accountId,
        year: props.year,
        monthIndex: props.monthIndex
    });

    const totalDisplay = transactionsData.paginationState.totalItems > 0 ? formatMoneySafeNoDecimal(props.totalValue) : "-";
    const paginationParams = buildPaginationParameters(
        transactionsData.paginationState.maxPage,
        transactionsData.paginationState.page
    );
    const itemsStatsString = buildItemsStatsString(
        transactionsData.paginationState.page,
        transactionsData.paginationState.totalItems,
        MVRTransactionsTableConfig.TRANSACTIONS_PER_PAGE
    );

    let rows = null;

    if (transactionsData.transactions.length > 0) {
        rows = transactionsData.transactions.map((row, index) =>
            <MVRTransactionRow
                key={index}
                description={row.description}
                memo={row.memo}
                date={row.date}
                amount={row.amount}
                // @Alexei: We'll need all this info in the details panel, also this is interface will grow.
                // If we pass the entire TransactionRowViewModel, anything that gets added will automatically become
                // available to consumers
                transaction={row}
            />
        );
    }
    else {
        rows = <Row>
            <Cell colSpan={4} className={css.noDataAvailableRow}>
                No data available
            </Cell>
        </Row>;
    }

    return (
        <div className={css.tableAndFooterContainer}>
            <div className={css.tableContainer}>
                <Table isReadOnly className={css.table}>
                    <Head isSticky>
                        <HeaderRow className={css.headerRow}>
                            <HeaderCell className={css.descriptionHeaderCell}>
                                Description
                            </HeaderCell>
                            <HeaderCell className={css.headerCell} width={144}>
                                Memo
                            </HeaderCell>
                            <HeaderCell className={css.dateHeaderCell} width={76}>
                                Date
                            </HeaderCell>
                            <HeaderCell className={css.amountHeaderCell} width={91}>
                                Amount
                            </HeaderCell>
                        </HeaderRow>
                    </Head>
                    <Body>
                        {rows}
                    </Body>
                </Table>
            </div>
            <div className={css.tableFooterContainer}>
                <div className={css.totalRow}>
                    <span>Total</span>
                    <span>{totalDisplay}</span>
                </div>
                <div className={css.paginationRow}>
                    <span className={css.paginationRowItemStats}>
                        {itemsStatsString}
                    </span>
                    <div className={css.paginationRowPaginationContainer}>
                        <Pagination
                            totalPages={paginationParams.totalPages}
                            currentPage={paginationParams.currentPage}
                            onChange={currentPage => { transactionsData.setPage(currentPage); }}
                            pageGap={2}
                            pagePadding={1}
                            transformPageProps={zdgPaginationTransformPageProps}
                        />
                    </div>
                    <div className={css.paginationRowColumn}></div>
                </div>
            </div>
        </div>
    );
}