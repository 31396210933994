import {ReactElement} from "react";
import {Body, Footer, FooterItem, Header, Modal} from "@zendeskgarden/react-modals";
import {Button} from "@zendeskgarden/react-buttons";
import {toast} from "react-toastify";
import {useDeleteGroupsMutation, useFindGroupUserCountsQuery} from "../../../../__generated__/generated_types";
import {COLORS} from "../../../../constants/Colors";
import {Inline} from "@zendeskgarden/react-loaders";

interface GroupDeleteModalProps {
    close: (groupsDeleted: boolean) => void;
    groupIds: string[];
}

export function GroupDeleteModal(props: GroupDeleteModalProps): ReactElement {
    const [deleteGroups, {loading: deleteLoading}] = useDeleteGroupsMutation({
        fetchPolicy: "no-cache"
    });
    const {data, loading} = useFindGroupUserCountsQuery({
        fetchPolicy: "no-cache",
        variables: {
            ids: props.groupIds
        }
    });

    function doDelete(ids: string[]): void {
        if(ids.length === 0) {
            return;
        }

        deleteGroups({
            variables: {
                ids: ids
            }
        }).then(() => {
            toast.success(`Deleted ${ids.length} Group${ids.length > 1 ? "s" : ""}.`);
            props.close(true);
        });
    }

    return <Modal>
        <Header>Delete Group{props.groupIds.length > 1 ? "s" : ""}</Header>
        <Body>
            {loading ?
                    <Inline size={24} color={COLORS.PRIMARY_500} aria-label="loading"/> :
                    <>
                        Are you sure you want to delete {props.groupIds.length} group{props.groupIds.length > 1 ? "s" : ""}?
                        {data?.findUserGroups && data.findUserGroups.length > 0 && data.findUserGroups.some(g => g.userCount > 0) &&
                            <ul style={{marginTop: "0.5em"}}>
                                {data.findUserGroups
                                        .filter(g => g.userCount > 0)
                                        .map(g => <li key={g.id}>{g.name} is in use by {g.userCount} user{g.userCount > 1 ? "s" : ""}</li>)
                                }
                            </ul>
                        }
                    </>
            }
        </Body>
        <Footer>
            <FooterItem>
                <Button isBasic onClick={() => props.close(false)}>Cancel</Button>
            </FooterItem>
            <FooterItem>
                <Button isDanger disabled={deleteLoading || loading} onClick={() => doDelete(props.groupIds)}>Delete</Button>
            </FooterItem>
        </Footer>
    </Modal>;
}