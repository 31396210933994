// Payroll Driver Calculation Types
// TODO: Convert PAYROLL_DRIVER_CALC_TYPES to singluar/plural form (see below)
export const PAYROLL_DRIVER_CALC_TYPES: Record<string, string> = {
    'CUSTOM': 'Custom',
    'DENTAL': 'Dental',
    'FEDERAL_UNEMPLOYMENT_TAX': 'Federal Unemployment Tax',
    'MEDICAL': 'Medical',
    'MEDICARE_TAX': 'Medicare Tax',
    'RETIREMENT_401K_MATCH': 'Retirement 401k Match',
    'STATE_UNEMPLOYMENT_TAX': 'State Unemployment Tax',
    'VISION': 'Vision',
    'WORKERS_COMPENSATION': 'Workers Compensation',
};

export const OpDriverMetricDisplayName: Record<string, {
    plural: string,
    single: string
}> = {
    ["Total Unit"]: { plural: "Total Units", single: "Unit" },
    ["Occupied"]: { plural: "Occupied Units", single: "Occupied Unit" },
    ["Renewal"]: { plural: "Renewals", single: "Renewal" },
    ["Move In"]: { plural: "Move Ins", single: "Move In" },
    ["Move Out"]: { plural: "Move Outs", single: "Move Out" },
    ["Expiration"]: { plural: "Expirations", single: "Expiration" }
};
