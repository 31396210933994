import TwoYearsLayout, { TwoYearsLayoutConfig } from "../../../../sjs/layout/two-years/TwoYearsLayout";
import { TwoYearsColId } from "../../../../sjs/layout/two-years/helpers/enums";
import {
    TwoYearsConfig as TWO_YR_CFG,
    TwoYearsConfig as CFG
} from "../../../../sjs/layout/two-years/helpers/TwoYearsConfig";
import { TwoYearsTableData } from "../../../../sjs/layout/two-years/helpers/types";
import { SJSCfg } from "../../../../sjs/layout/shared/SJSConfig";
import { SJSSheetStyles as SJSS } from "../../../../sjs/layout/shared/SJSSheetStyles";
import { MarketRentSheetStyles as SheetStyles } from "./MarketRentSheetStyles";

export default class MarketRentLayout extends TwoYearsLayout {
    constructor({ name, ssapi, template, year, firstReforecastMonth, reportLoadProgress }: TwoYearsLayoutConfig) {
        super({
            name,
            ssapi,
            template,
            year,
            firstReforecastMonth,
            reportLoadProgress,
            monthColOffset: 1,
        });

        this._ssapi.directAccess(spread => {
            const sheet = spread.getSheetFromName(CFG.MAIN_TAB_NAME);
            sheet.frozenTrailingRowCount(2, false);

            sheet.setColumnWidth(TWO_YR_CFG.LABEL_COL, 120);
            sheet.setColumnVisible(CFG.BUDGET_TOTALS_COL + 1, true);
        });
    }

    override dataColOffset = (colId: TwoYearsColId): number => {
        return colId + 1;
    };

    public isCellInReforecastYear(cellCol: number): boolean {
        return cellCol > CFG.FIRST_MONTH_COL + this._firstReforecastMonth
            && cellCol < CFG.TOTALS_COL + 1;
    }

    public isCellInBudgetYear(cellCol: number): boolean {
        return cellCol > CFG.TOTALS_COL + 1 && cellCol < CFG.BUDGET_TOTALS_COL + 1;
    }

    override renderTwoYearsTableData(data: TwoYearsTableData, setDataReady: React.Dispatch<React.SetStateAction<boolean>>) {
        this._ssapi.directAccess(spread => {
            const sheet = spread.getSheetFromName(SJSCfg.MAIN_SHEET_NAME);
            const rowCount = data.length + SJSCfg.FIRST_DATA_ROW;

            sheet.setRowCount(rowCount);
            sheet.setColumnCount(Object.keys(TwoYearsColId).filter((v) => isNaN(Number(v))).length + SJSCfg.FIRST_VISIBLE_COL);

            sheet.setArray(
                CFG.FIRST_DATA_ROW,
                CFG.LABEL_COL,
                data
            );

            const labelCells = sheet.getRange(CFG.FIRST_DATA_ROW, CFG.LABEL_COL, rowCount, 2);

            const actualCells = sheet.getRange(
                CFG.FIRST_DATA_ROW,
                CFG.LABEL_COL + 1 + this._monthColOffset,
                sheet.getRowCount(),
                CFG.LABEL_COL + this._firstReforecastMonth - 1
            );

            const lastActualCol = sheet.getRange(
                CFG.FIRST_DATA_ROW,
                CFG.FIRST_MONTH_COL + this._firstReforecastMonth,
                sheet.getRowCount(),
                1
            );

            const reforecastCells = sheet.getRange(
                CFG.FIRST_DATA_ROW,
                CFG.FIRST_MONTH_COL + this._firstReforecastMonth + 1,
                sheet.getRowCount(),
                12 - this._firstReforecastMonth,
            );

            const reforecastYearTotals = sheet.getRange(
                CFG.FIRST_DATA_ROW,
                CFG.TOTALS_COL + 1,
                sheet.getRowCount(),
                1,
            );

            const budgetYearDataCells = sheet.getRange(
                CFG.FIRST_DATA_ROW,
                CFG.FIRST_MONTH_COL + 14, // 12 months, 1 totals, 1 extra label column
                sheet.getRowCount(),
                12
            );

            const budgetYearTotals = sheet.getRange(
                CFG.FIRST_DATA_ROW,
                CFG.BUDGET_TOTALS_COL + 1,
                sheet.getRowCount(),
                1,
            );

            labelCells.setStyle(SheetStyles.MARKET_RENTS_LABEL);
            actualCells.setStyle(SJSS.ACTUAL_CELL);
            lastActualCol.setStyle(SJSS.LAST_ACTUAL_CELL);
            reforecastCells.setStyle(SJSS.EDITABLE_CELL_BLUE);
            reforecastYearTotals.setStyle(SJSS.TOTALS_DATA);
            budgetYearDataCells.setStyle(SJSS.EDITABLE_CELL_BLUE);
            budgetYearTotals.setStyle(SJSS.TOTALS_DATA);

            // Apply styles
            data.forEach((row, rowIdx) => {
                if(row[0] == 'span'){
                    sheet.setValue(CFG.FIRST_DATA_ROW + rowIdx, CFG.LABEL_COL, 'boop');
                    sheet.addSpan(CFG.FIRST_DATA_ROW + rowIdx - 2, CFG.LABEL_COL, 3, 1);

                    sheet.getRange(
                        CFG.FIRST_DATA_ROW + rowIdx - 1,
                        CFG.FIRST_MONTH_COL + this._firstReforecastMonth + 1,
                        2,
                        12 - this._firstReforecastMonth
                    ).setStyle(SJSS.ACTUAL_CELL);

                    sheet.getRange(
                        CFG.FIRST_DATA_ROW + rowIdx - 1,
                        CFG.FIRST_MONTH_COL + 14, // 12 months, 1 totals, 1 extra label column
                        2,
                        12
                    ).setStyle(SJSS.ACTUAL_CELL);

                }

                sheet.autoFitRow(rowIdx + CFG.FIRST_DATA_ROW);
            });

            sheet.getRange(rowCount - 2, CFG.LABEL_COL, 2, 28).setStyle(SheetStyles.MKT_RENT_SUMMARY);

            setDataReady(true);
        });
    }
}
