import { ReactElement, useEffect, useState } from "react";
import { ListBudgetYearsQuery, useCountPayrollEmployeesForBudgetYearGroupByPropertyLazyQuery, PayrollPropertyEmployeeCountModel, useCopyPayrollEmployeesClientLevelMutation } from "../../../__generated__/generated_types";
import Card from "../../simplified-revenue/components/Card";
import * as css from "../styles/budgetSeasonManagement.module.scss";
import { Field as FormField, MediaInput } from '@zendeskgarden/react-forms';
import { Dropdown, Field, Item, Menu, Select } from "@zendeskgarden/react-dropdowns";
import { yearToSeason } from "../util";
import { Button } from "@zendeskgarden/react-buttons";
import { toast } from "react-toastify";
import { ReactComponent as SearchIcon } from '@zendeskgarden/svg-icons/src/16/search-stroke.svg';
import { Body, Close, Footer, FooterItem, Header, Modal } from "@zendeskgarden/react-modals";
import { Inline } from "@zendeskgarden/react-loaders";

export type IModelingMethodsTab = {
    budgetYearsListData: ListBudgetYearsQuery | undefined,
}

type TPropertyEmployeeCount =
    Pick<PayrollPropertyEmployeeCountModel,
        "propertyId" |
        "propertyName" |
        "employeeCount"
    >

export default function PayrollEmployeesTab({budgetYearsListData}: IModelingMethodsTab): ReactElement {
    const [sourceBudgetYear, setSourceBudgetYear] = useState<number>();
    const [destBudgetYear, setDestBudgetYear] = useState<number>();
    const [saving, setSaving] = useState(false);
    const [showConfirmCopy, setShowConfirmCopy] = useState(false);

    const [employeeCounts, setEmployeeCounts] = useState<TPropertyEmployeeCount[]>([]);
    const [employeeCountsBudgetYear, setEmployeeCountsBudgetYear] = useState<number>();
    const [filteredEmployeeCounts, setFilteredEmployeeCounts] = useState<TPropertyEmployeeCount[]>([]);

    const [copyEmployeesClientLevel] = useCopyPayrollEmployeesClientLevelMutation();
    const [getEmployeeCounts, {data: employeeCountsData}] = useCountPayrollEmployeesForBudgetYearGroupByPropertyLazyQuery({
        fetchPolicy: "no-cache"
    });

    function resetForm() {
        setSourceBudgetYear(undefined);
        setDestBudgetYear(undefined);
    }

    function reloadEmployeeCounts() {
        if (!employeeCountsBudgetYear) {
            return;
        }
        getEmployeeCounts({
            variables: {
                budgetYear: employeeCountsBudgetYear
            }
        });
    }

    function handleCopy(sourceBudgetYear: number,
                        destBudgetYear: number) {
        setSaving(true);
        copyEmployeesClientLevel({
            variables: {
                sourceBudgetYear: sourceBudgetYear,
                destBudgetYear: destBudgetYear
            }
        })
        .then(
            (ret) => {
                resetForm();
                if (ret.data?.copyPayrollEmployees) {
                    toast.success(`Employees copied successfully`);
                }
                else {
                    toast.error("Employees copy failed");
                }
                reloadEmployeeCounts();
                setSaving(false);
            }
        )
        .finally(() => {
            setShowConfirmCopy(false);
        });
    }

    useEffect(
        () => {
            reloadEmployeeCounts();
        },
        [employeeCountsBudgetYear]
    );

    useEffect(
        () => {
            if (budgetYearsListData?.listBudgetYears?.activeBudgetYear != undefined) {
                setEmployeeCountsBudgetYear(budgetYearsListData?.listBudgetYears?.activeBudgetYear);
            }
        },
        [budgetYearsListData?.listBudgetYears?.activeBudgetYear]
    );

    useEffect(
        () => {
            if (!employeeCountsData) {
                return;
            }
            const employeeCounts: TPropertyEmployeeCount[] = [];
            for (const row of employeeCountsData.countEmployeesForBudgetYearGroupByProperty) {
                employeeCounts.push({
                    propertyId: row.propertyId,
                    propertyName: row.propertyName,
                    employeeCount: row.employeeCount
                });
            }
            setEmployeeCounts(employeeCounts);
            setFilteredEmployeeCounts(employeeCounts);
        },
        [employeeCountsData]
    );

    const possibleSourceBudgetYears = Array.from(budgetYearsListData?.listBudgetYears?.budgetYears?.map(y => y.year) ?? ([] as number[])).sort();
    const possibleDestBudgetYears = budgetYearsListData?.listBudgetYears?.budgetYears?.filter(y => y.year != sourceBudgetYear && !y.protected)?.map(y => y.year)?.sort() ?? [];

    return (
        <>
            <Card
                className={css.updaterControlsCard}
                titleClassName={css.updaterControlsTitle}
                title={"Carry Over Modeling Methods Across Budgeting Seasons"}
                actions={
                    <div className={css.updaterControlsButtons}>
                        {(sourceBudgetYear || destBudgetYear) &&
                            <Button
                                className={css.button}
                                isBasic
                                isDanger
                                onClick={resetForm}
                            >
                                Cancel
                            </Button>
                        }

                        <Button
                            className={css.button}
                            isPrimary
                            disabled={saving
                                || sourceBudgetYear == undefined
                                || destBudgetYear == undefined
                            }
                            onClick={() => setShowConfirmCopy(true)}
                        >
                            Copy
                        </Button>
                    </div>
                }
            >
                <ol className={css.updaterControlsSteps}>
                    <li>
                        Select Originating Season
                        <Dropdown
                            selectedItem={sourceBudgetYear?.toString()}
                            onSelect={(v) => {setSourceBudgetYear(v); setDestBudgetYear(undefined);}}
                        >
                            <Field>
                                <Select>
                                    {sourceBudgetYear ? yearToSeason(sourceBudgetYear): ""}
                                </Select>
                            </Field>
                            <Menu>
                                {possibleSourceBudgetYears.map(option => (
                                    <Item key={option} value={option}>
                                        {yearToSeason(option)}
                                    </Item>
                                ))}
                            </Menu>
                        </Dropdown>
                    </li>
                    <li>
                        Select Destination Season
                        <Dropdown
                            selectedItem={destBudgetYear?.toString()}
                            onSelect={(v) => setDestBudgetYear(v)}
                        >
                            <Field>
                                <Select>
                                    {destBudgetYear ? yearToSeason(destBudgetYear): ""}
                                </Select>
                            </Field>
                            <Menu>
                                {possibleDestBudgetYears.map(option => (
                                    <Item key={option} value={option}>
                                        {yearToSeason(option)}
                                    </Item>
                                ))}
                            </Menu>
                        </Dropdown>
                    </li>
                </ol>

            </Card>
            <Card
                title={"Employees Per Property"}
                actions={
                    <>
                        <Dropdown
                            selectedItem={employeeCountsBudgetYear?.toString()}
                            onSelect={(v) => setEmployeeCountsBudgetYear(v)}
                        >
                            <Field>
                                <Select style={{width: "15rem"}}>
                                    {employeeCountsBudgetYear ? yearToSeason(employeeCountsBudgetYear): ""}
                                </Select>
                            </Field>
                            <Menu>
                                {
                                    budgetYearsListData?.listBudgetYears?.budgetYears ?
                                        budgetYearsListData?.listBudgetYears?.budgetYears?.map(option => (
                                            <Item key={option.year} value={option.year}>
                                                {yearToSeason(option.year)}
                                            </Item>
                                        ))
                                        :
                                        <></>
                                }
                            </Menu>
                        </Dropdown>
                        <FormField className={css.propertySearch}>
                            <MediaInput start={<SearchIcon />} placeholder="Search" onChange={e => {
                                setFilteredEmployeeCounts(
                                    employeeCounts
                                    .filter(p => p.propertyName.toLowerCase().includes(e.target.value.trim().toLowerCase())));
                            }} />
                        </FormField>
                    </>
                }
            >
                <div className={css.propertyTableWrapper}>
                    <table className={css.propertyTable}>
                        <thead>
                            <tr className={css.headerCell}>
                                <th className={`${css.cell} ${css.nameCell}`}>Property</th>
                                <th className={`${css.cell} ${css.periodCell}`}>Employees Count</th>
                            </tr>
                        </thead>

                        <tbody>
                            {filteredEmployeeCounts.map(row => (
                                    <tr key={row.propertyId}>
                                        <td className={`${css.cell}`}>{ row.propertyName }</td>
                                        <td className={`${css.cell} ${css.periodCell}`}>{row.employeeCount}</td>
                                    </tr>
                                ))}
                        </tbody>
                    </table>
                </div>
            </Card>
            {showConfirmCopy && sourceBudgetYear && destBudgetYear &&
            <Modal onClose={() => setShowConfirmCopy(false)}>
                <Header isDanger>
                    Confirm copy employees
                </Header>
                <Body>
                    Are you sure you want to copy employees to {yearToSeason(destBudgetYear)}?
                    This will erase employees in {yearToSeason(destBudgetYear)} if they exist.
                </Body>
                <Footer>
                    <FooterItem>
                        <Button onClick={() => setShowConfirmCopy(false)} isBasic disabled={saving}>
                            Cancel
                        </Button>
                    </FooterItem>
                    <FooterItem>
                        {
                            <Button isPrimary onClick={() => handleCopy(sourceBudgetYear, destBudgetYear)} disabled={saving}>
                                {
                                    saving
                                        ? <Inline size={24} aria-label="loading"/>
                                        : <span>Confirm</span>
                                }
                            </Button>
                        }
                    </FooterItem>
                </Footer>
                <Close aria-label="Close modal" />
            </Modal>}
        </>
    );
}