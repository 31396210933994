import { formatterDollarUSMax2Decimal } from "../../../../../../../../utils/fotmatters";

export type BuildTotalAndOverrideData = {
    total: string;
    override: string | null; // there can be no override. designating this by the null value
}

/**
 * Implements the following logic:
 * if no original account value (fetchedOriginalAccountValue==null) then there is no override
 *      thus we return total=fetchedAccountValue and override=null
 * if there is original account value (fetchedOriginalAccountValue != null)  then there is override
 *      thus we return total=fetchedOriginalAccountValue and override=fetchedAccountValue
 * 
 * the values returns are string (formatting applied)
 * @param fetchedAccountValue
 * @param fetchedOriginalAccountValue
 */
export function buildTotalAndOverride(
    fetchedAccountValue: number,
    fetchedOriginalAccountValue: number | null
): BuildTotalAndOverrideData {
    // by default assume for no override
    let total = fetchedAccountValue;
    let override: number | null = null;

    if (fetchedOriginalAccountValue != null) {
        total = fetchedOriginalAccountValue;
        override = fetchedAccountValue;
    }

    return {
        total: formatterDollarUSMax2Decimal.format(total),
        override: override == null ? null : formatterDollarUSMax2Decimal.format(override)
    };
}