export enum RenovationSetupDrawerSheetEntityType {
    PACKAGE_NAME = "PACKAGE_NAME",
    AMOUNT = 'AMOUNT',
    DELAY_RECOGNITION = 'DELAY_RECOGNITION',
    PREMIUM = 'PREMIUM',
    PREMIUM_DELAY_RECOGNITION = "PREMIUM_DELAY_RECOGNITION",
    PACKAGE_DELETE = "PACKAGE_DELETE",
    CATEGORY_NA = "CATEGORY_NA",
    CELL_NEW_VALUE_NA = "CELL_NEW_VALUE_NA"
}

export interface LocalCostCategoryInput {
    costCategoryId: string,
    amount: number,
    delayRecognition: number
}

export interface LocalRenovationPackage {
    id: string,
    propertyId: string,
    unitTypeId: string,
    name: string,
    markedForDeletion: boolean,
    costCategoryMappings: LocalCostCategoryInput[],
    premium: number,
    premiumDelayRecognition: number
}

export interface costCategoryPayload {
    costCategoryId: string,
    amount: string,
    delayRecognition: number
}

export interface RenovationCreatePackagePayload {
    id: string,
    propertyId: string,
    unitTypeId: string,
    name: string,
    costCategoryMappings: costCategoryPayload[],
    premium: number,
    premiumDelayRecognition: number
}

export interface RenovationUpdatePackagePayload {
    id: string,
    name: string,
    costCategoryMappings: costCategoryPayload[],
    premium: number,
    premiumDelayRecognition: number
}

export interface RenovationDeletePackagePayload {
    id: string,
    name: string,
    force: boolean
}

export type PropertiesAndUnitTypesFilterDataType = {
    key: string,
    propertyId: string,
    unitTypeId: string,
    propertyName: string,
    unitTypeName: string
}

