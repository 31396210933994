import {Button} from "@zendeskgarden/react-buttons";
import {Body, Close, Footer, FooterItem, Header, Modal} from "@zendeskgarden/react-modals";
import {ReactElement} from "react";

import * as css from "./styles/copy-drivers-btn/css.module.scss";

export interface IDriversCopyConfirmation {
    canConfirm: boolean,
    count: number,
    onClose: () => void,
    onConfirm: () => void,
}

export default function CopyDriversConfirmationModal(props: IDriversCopyConfirmation): ReactElement {

    return (
        <Modal onClose={props.onClose}>
            <Header isDanger className={css.confirmationHeader}>
                Confirm Copy
            </Header>
            <Body>
                Are you sure you want to copy {props.count} modeling methods?
                <br /><br />
                Copying modeling methods will erase all existing values for the retrieving forecast period. This data will no longer be retrievable.
            </Body>
            <Footer>
                <FooterItem>
                    <Button onClick={props.onClose} isBasic>
                        Cancel
                    </Button>
                </FooterItem>
                <FooterItem>
                    {
                        <Button isPrimary isDanger onClick={props.onConfirm} disabled={!props.canConfirm}>
                            <span>Confirm</span>
                        </Button>
                    }
                </FooterItem>
            </Footer>
            <Close aria-label="Close modal" />
        </Modal>
    );
}
