import { dataColumn, entityColumn, utilColumn, varianceNotesColumn } from "../styles/css.module.scss";
import { MONTHS_LONG } from "../../../../../constants/Months";
import { ColumnSettings, GridSettings } from "handsontable/settings";
import { Settings } from "handsontable/plugins/nestedHeaders";

import * as headerCss from "../styles/spreadsheetHeader.module.scss";
import { LICENSES } from "../../../../../constants/Licenses";

export enum MvrTableCols {
    entityLabel,
    monthlyActual,
    monthlyBudget,
    monthlyValueVariance,
    monthlyPercentVariance,
    ytdActual,
    ytdBudget,
    ytdValueVariance,
    ytdPercentVariance,
    varianceNotes,
    util_entityId,
    util_entityType,
    util_parentId,
    util_depth,
    util_componentType,
    util_negatedAtComponent
}

export const buildMvrSheetSettings = (month?: number, year?: number): Partial<GridSettings> => {
    return {
        width: '100%',
        height: '100%',
        columns: buildMvrColumnsConfig(),
        fixedColumnsLeft: 1,
        hiddenColumns: { columns: [10, 11, 12, 13, 14, 15, 16] },
        stretchH: 'last',
        nestedHeaders: buildMvrNestedHeader(month, year),
        afterGetColHeader: (column: number, th: HTMLTableHeaderCellElement, headerLevel: number): void => {
            afterGetColumnHeader(column, th, headerLevel);
        },
        rowHeights: 48,
        // TODO: Determine what context menu options should be available https://handsontable.com/docs/javascript-data-grid/context-menu/#context-menu-with-specific-options
        contextMenu: ['copy'],
        licenseKey: LICENSES.HandsOnTable,
    };
};

export const buildMvrNestedHeader = (month?: number, year?: number): Settings => {
    let useMonth = MONTHS_LONG[month ?? new Date().getMonth()];
    const useYear = year ?? new Date().getFullYear();

    if(useMonth != undefined){
        useMonth = useMonth.toUpperCase();
    }

    return [
        [
            { // Entity label
                label: '',
                colspan: 1,
            },
            { // Monthly Variance Fields
                label: `${useMonth} ${useYear}`,
                colspan: 4,
            },
            { // YTD Variance Fields
                label: `YTD ${useYear}`,
                colspan: 4,
            },
        ],
        [
            'Accounts',                             // Entity label
            'Actual', 'Budget', 'Var $', 'Var %',   // Monthly Variance Fields
            'Actual', 'Budget', 'Var $', 'Var %',    // YTD Variance Fields
            'Variance Note',
            'util: entityId',
            'util: entityType',
            'util: parentId',
            'util: depth',
            'util: componentType',
            'util: negatedAtComponent',
            'util: rowIdx',
        ],
    ];
};

export const afterGetColumnHeader = (column: number, th: HTMLTableHeaderCellElement, headerLevel:number):void => {

    if(headerLevel === 0){
        if(column === 0 && !th.classList.contains(headerCss.headerRow0EmptyCell)){
            th.classList.add(headerCss.headerRow0EmptyCell);
        }
        else if(column === 1 && !th.classList.contains(headerCss.headerGroupLabel)){
            th.classList.add(headerCss.headerGroupLabel);
        }
        else if(column === 5 && !th.classList.contains(headerCss.headerGroupLabel)){
            th.classList.add(headerCss.headerGroupLabel);
        }
        else if(column === 9 && !th.classList.contains(headerCss.headerRow0EmptyCell)){
            th.classList.add(headerCss.headerRow0EmptyCell);
        }
    }
    else if(headerLevel === 1){
        if(column === 0 && !th.classList.contains(headerCss.headerRow1Label)){
            th.classList.add(headerCss.headerRow1Label);
        }
        else if(column < MvrTableCols.monthlyPercentVariance && !th.classList.contains(headerCss.headerRow1ValueColumn)){
            th.classList.add(headerCss.headerRow1ValueColumn);
        }
        else if(column === MvrTableCols.monthlyPercentVariance && !th.classList.contains(headerCss.headerRow1ValueColumnLast)){
            th.classList.add(headerCss.headerRow1ValueColumnLast);
        }
        else if(column < MvrTableCols.ytdPercentVariance && !th.classList.contains(headerCss.headerRow1ValueColumn)){
            th.classList.add(headerCss.headerRow1ValueColumn);
        }
        else if(column === MvrTableCols.ytdPercentVariance && !th.classList.contains(headerCss.headerRow1ValueColumnLast)){
            th.classList.add(headerCss.headerRow1ValueColumnLast);
        }
        else if(column === MvrTableCols.varianceNotes && !th.classList.contains(headerCss.headerRow1Label)){
            th.classList.add(headerCss.headerRow1Label);
        }
    }
};

const currencyCell: ColumnSettings = {
    type: 'numeric',
    readOnly: true,
    numericFormat: {
        pattern: '0,0.00',
        culture: 'en-US', // this is the default culture, set up for USD
    },
    width: '110px',
};

const percentCell: ColumnSettings = {
    type: 'numeric',
    readOnly: true,
    numericFormat: {
        pattern: '0,0.00%',
    },
    width: '110px',
};

export const buildMvrColumnsConfig = ():  ColumnSettings[] | ((index: number) => ColumnSettings) => {
    return [
        {
            data: 'entityName',
            renderer: 'financialEntityCellRenderer',
            readOnlyCellClassName: entityColumn,
            readOnly: true,
            width: '285px',
        },
        {
            ...currencyCell,
            className: dataColumn,
            data: 'monthActual',
            renderer: 'valueCellRenderer',
        },
        {
            ...currencyCell,
            className: dataColumn,
            data: 'monthBudget',
            renderer: 'valueCellRenderer',
        },
        {
            ...currencyCell,
            className: dataColumn,
            data: 'monthValueVariance',
            renderer: 'varianceCellRenderer',
        },
        {
            ...percentCell,
            className: dataColumn,
            data: 'monthPercentVariance',
            renderer: 'varianceCellRenderer',
        },
        {
            ...currencyCell,
            className: dataColumn,
            data: 'ytdActual',
            renderer: 'valueCellRenderer',
        },
        {
            ...currencyCell,
            className: dataColumn,
            data: 'ytdBudget',
            renderer: 'valueCellRenderer',
        },
        {
            ...currencyCell,
            className: dataColumn,
            data: 'ytdValueVariance',
            renderer: 'varianceCellRenderer',
        },
        {
            ...percentCell,
            className: dataColumn,
            data: 'ytdPercentVariance',
            renderer: 'varianceCellRenderer',
        },
        {
            className: varianceNotesColumn,
            data: 'varianceNote',
            renderer: 'varianceNotesCellRenderer',
        },
        {
            className: utilColumn,
            data: 'entityId',
        },
        {
            className: utilColumn,
            data: 'entityType',
        },
        {
            className: utilColumn,
            data: 'parentId',
        },
        {
            className: utilColumn,
            data: 'depth',
        },
        {
            className: utilColumn,
            data: 'componentType',
        },
        {
            className: utilColumn,
            data: 'negatedAtComponent'
        },
        {
            className: utilColumn,
            data: 'rowIdx'
        },
    ];
};
