/**
 * Returns an appropriate connector string for a list of strings in a proper english narrative style.
 * Parameters are assumed to match array index perspectives, so idx is 0-based, and listLength is 1-based.
 * @param idx
 * @param listLength
 */
export function getListItemJunction(idx:number, listLength:number):string {
    if(idx < listLength - 2){
        return ', ';
    }
    else if(idx < listLength - 1){
        return ', and ';
    }
    return '';
}
