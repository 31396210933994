import { MONTHS } from "../../../../../../constants/Months";

export function buildHeaderRowsData(reforecastYear: number): string[][] {
    const currYearSuffix = `0000${reforecastYear.toString()}`.slice(-2);
    const nextYearSuffix = `0000${(reforecastYear + 1).toString()}`.slice(-2);
    const hearderRowsData = [
        ["Item Name", "Property", "GL Account", ...MONTHS.map(mo => `${mo} ${currYearSuffix}`), `TOTAL ${currYearSuffix}`, ...MONTHS.map(mo => `${mo} ${nextYearSuffix}`), `TOTAL ${nextYearSuffix}` ]
    ];

    return hearderRowsData;
}