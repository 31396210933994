import { ReactElement, useMemo, useState } from "react";
import { ReportTableData, ReportTableDataRow } from "../logic/reportTablesLogic";
import { Body, Head, HeaderCell, HeaderRow, Row, Cell, Table, SortableCell } from "@zendeskgarden/react-tables";
import { formatterDollarUSNoDecimal, formatterPercentWithSign } from "../../../utils/formatters";
import css from "../styles/table.module.scss";
import { getVarianceColor } from "../../../utils/variance";

type SortColumn = "LINE" | "TOTAL_RFCST" | "TOTAL_BUDGET" | "GROWTH_$" | "GROWTH_%" | "PER_UNIT";
type SortType = "asc" | "desc";
type SortField = keyof Pick<ReportTableDataRow, "reforecastTotal"|"budgetTotal"|"varianceAmount"|"variancePercent"|"order"|"budgetPerUnit">;

function nextSortType(sortType: SortType | undefined): SortType | undefined {
    if (sortType === undefined) {
        return "asc";
    }
    if (sortType === "asc") {
        return "desc";
    }
    if (sortType === "desc") {
        return undefined;
    }
}

export function FinancialReportTable(props: {reforecastYear: number, budgetYear: number, data: ReportTableData}): ReactElement {
    const {reforecastYear, budgetYear, data} = props;
    const [sortColumn, setSortColumn] = useState<SortColumn>();
    const [sortType, setSortType] = useState<SortType>();

    function handleSortUpdate(column: SortColumn | undefined) {
        if (column !== sortColumn) {
            setSortColumn(column);
            setSortType(nextSortType(undefined));
        }
        else {
            setSortType(nextSortType(sortType));
        }
    }

    function currentSortForColumn(column: SortColumn | undefined): SortType | undefined {
        let type: SortType | undefined = undefined;
        if (!column) {
            type = undefined;
        }
        else if (sortColumn === column) {
            type = sortType;
        }

        return type;
    }

    const processedData = useMemo(() => {
        let ret = data.rows;
        let sortField:SortField = "order";
        let sortAscending = true;
        if (sortColumn && sortType) {
             if (sortColumn === "TOTAL_RFCST") {
                sortField = "reforecastTotal";
             }
             else if (sortColumn === "TOTAL_BUDGET") {
                sortField = "budgetTotal";
             }
             else if (sortColumn === "GROWTH_$") {
                sortField = "varianceAmount";
             }
             else if (sortColumn === "GROWTH_%") {
                sortField = "variancePercent";
             }
             else if (sortColumn === "PER_UNIT") {
                sortField = "budgetPerUnit";
             }
             if (sortType === "asc") {
                sortAscending = true;
             }
             else if (sortType === "desc") {
                sortAscending = false;
             }
        }
        if (sortAscending) {
            ret = data.rows.sortBy(sortField)
        }
        else {
            ret = data.rows.sortByDescending(sortField);
        }
        return ret;
    }, [sortColumn, sortType, data.rows]);

    return (
        <div className={css.wrapper}>
            <div className={css.topHeader}>
                <div>
                    <h4>{data.name}</h4>
                    <h5>{budgetYear % 100} BDGT</h5>
                </div>
            </div>
            <Table className={css.table}>
                <Head>
                    <HeaderRow>
                        <HeaderCell>Line</HeaderCell>
                        <SortableCell
                            onClick={() => handleSortUpdate("TOTAL_RFCST")}
                            sort={currentSortForColumn("TOTAL_RFCST")}
                        >
                            {reforecastYear} Total
                        </SortableCell>
                        <SortableCell
                            onClick={() => handleSortUpdate("TOTAL_BUDGET")}
                            sort={currentSortForColumn("TOTAL_BUDGET")}
                        >
                            {budgetYear} Total
                        </SortableCell>
                        <SortableCell
                            onClick={() => handleSortUpdate("GROWTH_$")}
                            sort={currentSortForColumn("GROWTH_$")}
                        >
                            Growth $
                        </SortableCell>
                        <SortableCell
                            onClick={() => handleSortUpdate("GROWTH_%")}
                            sort={currentSortForColumn("GROWTH_%")}
                        >
                            Growth %
                        </SortableCell>
                        <SortableCell
                            onClick={() => handleSortUpdate("PER_UNIT")}
                            sort={currentSortForColumn("PER_UNIT")}
                        >
                            {budgetYear} Per Unit
                        </SortableCell>
                    </HeaderRow>
                </Head>
                <Body>
                    {processedData.map(row => (
                        <Row key={row.accountId}>
                            <Cell>{row.name}</Cell>
                            <Cell>{row.reforecastTotal === null ? "-" : formatterDollarUSNoDecimal.format(row.reforecastTotal)}</Cell>
                            <Cell>{row.budgetTotal === null ? "-" : formatterDollarUSNoDecimal.format(row.budgetTotal)}</Cell>
                            <Cell style={{
                                color: row.varianceAmount === null ? "" : getVarianceColor(row.varianceAmount, row.componentType, row.negateAtComponent)
                            }}>
                                {row.varianceAmount === null ? "-" : formatterDollarUSNoDecimal.format(row.varianceAmount)}
                            </Cell>
                            <Cell style={{
                                color: row.varianceAmount === null ? "" : getVarianceColor(row.varianceAmount, row.componentType, row.negateAtComponent)
                            }}>
                                {row.variancePercent === null ? "-" : formatterPercentWithSign.format(row.variancePercent)}
                            </Cell>
                            <Cell>{row.budgetPerUnit === null ? "-" : formatterDollarUSNoDecimal.format(row.budgetPerUnit)}</Cell>
                        </Row>
                    ))}
                </Body>
            </Table>
        </div>
    );
}