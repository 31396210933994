import { Dispatch, DOMElement, ReactElement, SetStateAction, useEffect, useRef, useState } from "react";

import { MONTHS } from "../../../constants/Months";

import { Button, IconButton } from "@zendeskgarden/react-buttons";
import { Well } from "@zendeskgarden/react-notifications";

import { ReactComponent as ChevronLeft } from '@zendeskgarden/svg-icons/src/16/chevron-left-fill.svg';
import { ReactComponent as ChevronRight } from '@zendeskgarden/svg-icons/src/16/chevron-right-fill.svg';

import { getButtonState } from "../logic/yearMonthSelector";
import * as css from "../styles/css.module.scss";

export interface ICalendarPaneProps {
    year: number|undefined,
    month: number|undefined
    displayedYear: number|undefined
    setDisplayedYear: Dispatch<SetStateAction<number|undefined>>,
    pickerOpen: boolean,
    selectionMade: (year: number, month: number) => void
    id?: string
    maxYear?: number,
    minYear?: number,
    disabledBackLabel?: string;
    disabledForwardLabel?: string;
    className?: string;
    onClickOutside: () => void;
}

export default function CalendarPane(props: ICalendarPaneProps): ReactElement {

    const ref = useRef(null);

    const renderMonths = (): ReactElement[] => {
        return MONTHS.map((month, idx) => {
            const btnId = `calendar_panel_${idx}`;
            return (
                    <Button
                            data-testid={props.id ? `${btnId}_${props.id}` : btnId}
                            key={props.id ? `${btnId}_${props.id}` : btnId}
                            className={css.monthsButton}
                            size="small"
                            onClick={() => {
                                if(props.displayedYear != undefined){
                                    props.selectionMade(props.displayedYear, idx);
                                }
                            }}
                            {...getButtonState(
                                    idx,
                                    props.displayedYear,
                                    props.year,
                                    props.month ?? 0,
                                    props.maxYear,
                                    props.minYear,
                            )}
                    >
                        {month}
                    </Button>
            );
        });
    };

    useEffect(
            () => {
                if(ref === undefined || ref.current === undefined){
                    return;
                }

                const handleClickOutside = (event: MouseEvent) => {
                    if(!(ref.current as unknown as HTMLElement).contains(event.target as Node)){
                        props.onClickOutside && props.onClickOutside();
                    }
                };
                document.addEventListener('click', handleClickOutside, true);
                return () => {
                    document.removeEventListener('click', handleClickOutside, true);
                };
            },
            [props.onClickOutside, ref.current]
    );

    const [goLastYearDisabled, setGoLastYearDisabled] = useState<boolean>(false);
    useEffect(
            () => {
                setGoLastYearDisabled(
                        props.displayedYear === undefined
                        || (props.minYear !== undefined && props.displayedYear < props.minYear + 1)
                );
            },
            [props.displayedYear, props.minYear]
    );

    const [goNextYearDisabled, setGoNextYearDisabled] = useState<boolean>(false);
    useEffect(
            () => {
                setGoNextYearDisabled(
                        props.displayedYear === undefined
                        || (props.maxYear !== undefined && props.displayedYear > props.maxYear - 1)
                );
            },
            [props.displayedYear, props.maxYear]
    );

    return (
            <div ref={ref}>
                <Well
                        data-testid={props.id ? `calendar_panel_${props.id}` : 'calendar_panel'}
                        isFloating
                        className={props.className ? `${css.yearContainer} ${props.className}` : `${css.yearContainer}`}
                        style={!props.pickerOpen ? { display: 'none' } : {}}
                >
                    <div className={css.yearNavigator}>
                        <IconButton
                                size="small"
                                onClick={() => {
                                    if(props.displayedYear != undefined){
                                        props.setDisplayedYear(props.displayedYear - 1);
                                    }
                                }}
                                disabled={goLastYearDisabled}
                        >
                            <ChevronLeft/>
                        </IconButton>
                        <div>{props.displayedYear}</div>
                        <IconButton
                                size="small"
                                onClick={() => {
                                    if(props.displayedYear != undefined){
                                        props.setDisplayedYear(props.displayedYear + 1);
                                    }
                                }}
                                disabled={goNextYearDisabled}
                        >
                            <ChevronRight/>
                        </IconButton>
                    </div>
                    <div className={css.monthsContainer}>
                        {renderMonths()}
                    </div>
                    {
                        goLastYearDisabled && props.disabledBackLabel != undefined
                                ? <div className={css.calendarLabel}>{props.disabledBackLabel}</div>
                                : <></>
                    }
                    {
                        goNextYearDisabled && props.disabledForwardLabel != undefined
                                ? <div className={css.calendarLabel}>{props.disabledForwardLabel}</div>
                                : <></>
                    }
                </Well>
            </div>
    );
}