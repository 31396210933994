import React, {ReactElement} from "react";

import {Button} from '@zendeskgarden/react-buttons';

import {ReactComponent as ChevronLeft} from '@zendeskgarden/svg-icons/src/16/chevron-left-stroke.svg';
import {ReactComponent as ChevronRight} from '@zendeskgarden/svg-icons/src/16/chevron-right-stroke.svg';

import * as css from "./styles/css.module.scss";

export interface WorkflowStepNavProps {
    onPreviousClick?: () => void;
    onNextClick?: () => void;
}

export default function WorkflowStepNav(props:WorkflowStepNavProps):ReactElement{
    return (
        <div className={css.stepNavContainer}>
            {
                props.onPreviousClick != undefined &&
                <Button onClick={props.onPreviousClick} data-testid={`btn_previous_workflow`}>
                    <Button.StartIcon><ChevronLeft/></Button.StartIcon> Previous
                </Button>
            }

            {
                props.onNextClick != undefined &&
                <Button onClick={props.onNextClick} data-testid={`btn_next_workflow`}>
                    Next <Button.EndIcon><ChevronRight/></Button.EndIcon>
                </Button>
            }
        </div>
    );
}