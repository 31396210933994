/**
 * TODO: COMPONENT IS NOT USED and possibly obsolete
 */

import React, { useState, useContext, FormEvent } from 'react';

import { useValidUsername, useValidCode } from '../../hooks/UseAuthHooks';

import { AuthContext } from '../../contexts/AuthContext';
import { Form, Button } from 'react-bootstrap';
import { FormContainer } from './FormContainer';
import email from "../../assets/icons/email/email.svg";
import LoginDesign from './LoginDesign';
import PasswordFormPart from './PasswordFormPart';
import { PasswordFlow } from './types';

export interface RequestCodeProps {
    needResetPassword?: () => void;
    cancelled?: () => void;
}

const RequestCode: React.FC<RequestCodeProps> = (params: RequestCodeProps) => {
    const { username, setUsername, usernameIsValid } = useValidUsername('');
    // const { code, setCode, codeIsValid } = useValidCode('');

    const [error, setError] = useState('');
    // const [resetSent, setResetSent] = useState(false);

    const isInValid = !usernameIsValid || username.length === 0;

    const authContext = useContext(AuthContext);

    const sendCodeClicked = async (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        try {
            await authContext.sendCodeForgotPassword(username);
            authContext.setUsername(username);
            params.needResetPassword?.();
        } catch (err) {
            setError('Unknown user');
        }
    };


    // const sendClicked = async (event: FormEvent<HTMLFormElement>) => {
    //     event.preventDefault();
    //     try {
    //         if (codeIsValid) {
    //             await authContext.saveVerificationCode(username, code);
    //             // params.needResetPassword?.();
    //         }
    //     } catch (err) {
    //         setError('Invalid Code');
    //     }
    //     setCode("");
    // };

    // const emailSent = () => (
    //     <Form onSubmit={sendClicked}>
    //         <Form.Label className="forgot-password mb-3">
    //             We sent a code to your email
    //         </Form.Label>

    //         <Form.Group className="mb-3">
    //             <Form.Label className="mb-2 text-darkliver">
    //                 <div className="d-flex align-items-center">
    //                     <img className="email-icon" src={email} alt="email" />
    //                     <div> Enter the 6-digit verification code sent to <span className="text-blueberry"> {username} </span> </div>
    //                 </div>
    //             </Form.Label>
    //         </Form.Group>
    //         <div className="login-wrapper forgot-password-wrapper mt-4">
    //             <div className={"login-box " + (error ? "error-border" : code.length > 0 ? "focused-border" : "default-border")}>
    //                 <label className={"login-email " + (error ? "error-text" : code.length > 0 ? "focused-text" : "default-text")}> Code </label>
    //                 <span className="login-input-wrapper">
    //                     <input
    //                         className="login-input"
    //                         type="text"
    //                         required
    //                         minLength={6}
    //                         value={code}
    //                         onChange={(value) => setCode(value.target.value)}
    //                     />
    //                 </span>
    //             </div>
    //         </div>
    //         {error &&
    //         <p className="error-message code-error-message"> That's not the right code. Please try again. </p>
    //         }
    //         <div className={"resend-code-buttons " + (!error && "button-padding")}>
    //             <div className="resend-code"> Resend code </div>
    //             <Button type="submit" className="reset-code-button" variant="primary" > Submit </Button>
    //         </div>
    //         <p className="reset-code-text"> If you don't see the email in your inbox, check your spam folder and check that you entered
    //             the email associated with your Vizibly account.
    //         </p>
    //     </Form>

    // );

    const sendCode = () => (
        <Form onSubmit={sendCodeClicked}>
            <Form.Label className="forgot-password mb-3">
                Forgot your password?
            </Form.Label>
            <p className="forgot-password-text"> Don't worry, we'll send you a code to reset your password.</p>

            <div className="login-wrapper forgot-password-wrapper mt-4">
                <div className={"login-box " + (username ? "focused-border" : error ? "error-border" : "default-border")}>
                    <label className={"login-email " + (username ? "focused-text" : error ? "error-text" : "default-text")}> Email </label>
                    <span className="login-input-wrapper">
                        <input
                            className="login-input"
                            type="email"
                            value={username}
                            onChange={(value) => setUsername(value.target.value)}
                        />
                    </span>
                </div>
            </div>
            <div className="forgot-password-buttons request-code-buttons flex-row justify-content-end">
                <Button type="submit" className="send-code-button" variant="primary" disabled={isInValid}> Send </Button>
            </div>
        </Form>
    );

    return (
        <FormContainer>
            <div className="light-blue-box"></div>
            <div className="left-side">
                <div className="left-side-container d-flex flex-column">
                    {
                        // resetSent ? emailSent() :
                        sendCode()
                    }
                    <div className="back-to-login mt-auto mb-0" onClick={params.cancelled}> &lt; Back to log in </div>
                </div>
            </div>
            <LoginDesign />
        </FormContainer>
    );
};

export default RequestCode;

