import React, {ReactElement, useContext, useEffect, useState} from "react";
import {Col, Grid, Row} from "@zendeskgarden/react-grid";
import {PasswordError, PasswordValidationResult, validatePassword} from "../../libs/password";
import {AuthContext} from "../../contexts/AuthContext";
import {Field, Input, InputGroup, Label} from "@zendeskgarden/react-forms";
import {Button} from "@zendeskgarden/react-buttons";
import {ThemeProvider} from "@zendeskgarden/react-theming";
import {ViziblyTheme} from "../../styles/zendesk-garden/ViziblyZDGTheme";
import logo from "../../assets/icons/brand/logo.svg";
import loginCartoon from "../../assets/icons/login/loginCartoon.svg";
import * as css from "./styles/signInChangePasswords.module.scss";
import {ReactComponent as CheckCircleStrokeIcon} from "@zendeskgarden/svg-icons/src/16/check-circle-stroke.svg";
import {ReactComponent as XCircleStrokeIcon} from "@zendeskgarden/svg-icons/src/16/x-circle-stroke.svg";
import {ReactComponent as EyeStrokeIcon} from "@zendeskgarden/svg-icons/src/12/eye-stroke.svg";
import {useRoutingUtils} from "../../routes/RoutingUtils";

const UNKNOWN_ERR_MESSAGE = "An unknown error occurred. Please try again.";

interface SignInChangePasswordProps {
    email: string;
    oldPassword: string;
}

export function SignInChangePassword(props: SignInChangePasswordProps): ReactElement {
    const {goto} = useRoutingUtils();
    const authContext = useContext(AuthContext);
    const [newPassword, setNewPassword] = useState<string>();
    const [passwordValidationResult, setPasswordValidationResult] = useState<PasswordValidationResult | null>(null)
    const [viewingPassword, setViewingPassword] = useState<boolean>(false);
    const [resetError, setResetError] = useState<string | null>(null);

    useEffect(() => {
        // Timeout is set at 5 minutes (300,000 ms or 300 seconds)
        const timeoutId = setTimeout(() => {
            // Reload the location after a specified timeout to ensure that someone
            // doesn't come along and login as a user if they forget to set their password.
            window.location.reload();
        }, 300000);

        return () => clearTimeout(timeoutId);
    }, [newPassword]);

    function doValidation(newValue: string): void {
        setNewPassword(newValue);
        const validationResult = validatePassword(newValue);
        setPasswordValidationResult(validationResult);
    }

    function changePassword(): void {
        if(!newPassword || !passwordValidationResult?.valid) {
            return;
        }

        authContext.signInWithPasswordReset?.(props.email, props.oldPassword, newPassword)
                .then(() => {
                    goto("/");
                }).catch(err => {
                    if(typeof err === "object") {
                        if("message" in err) {
                            setResetError(err.message);
                        } else if(typeof err.toString === "function") {
                            setResetError(err.toString());
                        }
                        setResetError(UNKNOWN_ERR_MESSAGE);
                    } else if(typeof err === "string") {
                        setResetError(err);
                    } else {
                        setResetError(UNKNOWN_ERR_MESSAGE);
                    }
                });
    }

    return <ThemeProvider theme={ViziblyTheme}>
        <Grid>
            <Row>
                <Col className={css.passwordWrapper} size={3} offset={3}>
                    <img className="mt-2" src={logo} alt="vizibly-logo"/>
                    <h3 className={css.passwordWrapperHeader}>Please Update Your Password</h3>
                    {resetError && <div className={css.passwordSetError}>{resetError}</div>}
                    <Field>
                        <Label>New Password</Label>
                        <InputGroup>
                            <Input aria-describedby="new-password-requirements" type={viewingPassword ? "text" : "password"} value={newPassword} onChange={evt => doValidation(evt.target.value)}/>
                            <Button focusInset isNeutral onClick={() => setViewingPassword(!viewingPassword)}><EyeStrokeIcon aria-label={viewingPassword ? "Hide Password" : "View Password"} /></Button>
                        </InputGroup>
                    </Field>
                    <div className={css.buttonContainer}>
                        <Button
                                className={css.setPasswordButton}
                                isPrimary
                                disabled={!passwordValidationResult?.valid}
                                onClick={changePassword}
                        >Set Password</Button>
                    </div>
                    <ul id="new-password-requirements" className={css.passwordRequirementsList}>
                        <li>
                            <span className={css.passwordRequirementIcon}>{(!passwordValidationResult || passwordValidationResult.errors.includes(PasswordError.PASSWORD_LENGTH)) ?
                                    <span className={css.bad}><XCircleStrokeIcon aria-label="bad" /></span> :
                                    <span className={css.good}><CheckCircleStrokeIcon aria-label="good" /></span>
                            }</span>
                            Password is 8 characters is more.
                        </li>
                        <li>
                            <span className={css.passwordRequirementIcon}>{(!passwordValidationResult || passwordValidationResult.errors.includes(PasswordError.MISSING_LOWER)) ?
                                    <span className={css.bad}><XCircleStrokeIcon aria-label="bad"/></span> :
                                    <span className={css.good}><CheckCircleStrokeIcon aria-label="good"/></span>
                            }</span>
                            Password includes lower case letter.
                        </li>
                        <li>
                            <span className={css.passwordRequirementIcon}>{(!passwordValidationResult || passwordValidationResult.errors.includes(PasswordError.MISSING_UPPER)) ?
                                    <span className={css.bad}><XCircleStrokeIcon aria-label="bad"/></span> :
                                    <span className={css.good}><CheckCircleStrokeIcon aria-label="good"/></span>
                            }</span>
                            Password includes upper case letter.
                        </li>
                        <li>
                            <span className={css.passwordRequirementIcon}>{(!passwordValidationResult || passwordValidationResult.errors.includes(PasswordError.MISSING_DIGIT)) ?
                                    <span className={css.bad}><XCircleStrokeIcon aria-label="bad"/></span> :
                                    <span className={css.good}><CheckCircleStrokeIcon aria-label="good"/></span>
                            }</span>
                            Password includes a number.
                        </li>
                        <li>
                            <span className={css.passwordRequirementIcon}>{(!passwordValidationResult || passwordValidationResult.errors.includes(PasswordError.MISSING_SPECIAL)) ?
                                    <span className={css.bad}><XCircleStrokeIcon aria-label="bad"/></span> :
                                    <span className={css.good}><CheckCircleStrokeIcon aria-label="good"/></span>
                            }</span>
                            Password includes a special character (e.g. #$^&*!).
                        </li>
                    </ul>
                </Col>
                <Col className={css.passwordRight} size={3}>
                    <img style={{width: "100%"}} src={loginCartoon} alt="assestsPerformance"/>
                    <div className="optimize-text">Optimize the performance of <br/> your assets.</div>
                </Col>
            </Row>
        </Grid>
    </ThemeProvider>;
}