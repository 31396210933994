import { ReactElement, useRef, useState } from "react";
import { Property } from "../../../contexts/properties/PropertiesContext";
import { Field as FormField, Label as FormLabel, Checkbox, Radio } from '@zendeskgarden/react-forms';
import * as css from "../styles/bulkUpdate.module.scss";
import { Button } from "@zendeskgarden/react-buttons";
import { useSetMultiForecastPeriodLocksMutation } from "../../../__generated__/generated_types";
import PropertyDropdown from "./PropertyDropdown";

export interface IPropertyLockBulkUpdaterProps {
    budgetYear: number;
    properties: Property[];
    lockedProperties: {
        reforecastLocked: string[],
        budgetLocked: string[],
    };
    onUpdateCompleted: (numberUpdated: number) => void;
}

export default function PropertyLockBulkUpdater({ budgetYear, properties, lockedProperties, onUpdateCompleted }: IPropertyLockBulkUpdaterProps): ReactElement {
    const [shouldFinalize, setShouldFinalize] = useState<boolean>(true);
    const [rfcstPeriodSelected, setRfcstPeriodSelected] = useState<boolean>(false);
    const [bdgtPeriodSelected, setBdgtPeriodSelected] = useState<boolean>(false);
    const [selectedProperties, setSelectedProperties] = useState<string[]>([]);
    const [saving, setSaving] = useState<boolean>(false);

    const [updateMultiForecastPeriodLocks] = useSetMultiForecastPeriodLocksMutation({
        onCompleted: data => {
            setSaving(false);
            resetForm();
            onUpdateCompleted(data.setMultiPropertyForecastPeriodLocks.length);
        }
    });

    function resetForm() {
        setSelectedProperties([]);
        setShouldFinalize(true);
        setRfcstPeriodSelected(false);
        setBdgtPeriodSelected(false);
    }

    return (
        <>
            <ol className={css.updaterControlsSteps}>
                <li>
                    Select Forecast Period(s)
                    <FormField>
                        <Checkbox checked={rfcstPeriodSelected} onChange={event => setRfcstPeriodSelected(event.target.checked)}>
                            <FormLabel isRegular>{budgetYear - 1} Reforecast</FormLabel>
                        </Checkbox>
                    </FormField>
                    <FormField>
                        <Checkbox checked={bdgtPeriodSelected} onChange={event => setBdgtPeriodSelected(event.target.checked)}>
                            <FormLabel isRegular>{budgetYear} Budget</FormLabel>
                        </Checkbox>
                    </FormField>
                </li>

                <li>
                    Select Properties
                    <PropertyDropdown
                        selectedProperties={selectedProperties}
                        properties={properties}
                        lockedProperties={lockedProperties}
                        showLockTags
                        onPropertySelect={selected => {
                            setSelectedProperties(selected);
                        }}
                    />
                </li>

                <li>
                    Select Status
                    <FormField>
                        <Radio
                            value={"finalize"}
                            checked={shouldFinalize}
                            onChange={() => setShouldFinalize(true)}
                        >
                            <FormLabel>Finalize</FormLabel>
                        </Radio>
                    </FormField>
                    <FormField>
                        <Radio
                            value={"unfinalize"}
                            checked={!shouldFinalize}
                            onChange={() => setShouldFinalize(false)}
                        >
                            <FormLabel>Unfinalize</FormLabel>
                        </Radio>
                    </FormField>
                </li>
            </ol>

            <div className={css.updaterControlsButtons}>
                {(selectedProperties.length > 0 || rfcstPeriodSelected || bdgtPeriodSelected) &&
                    <Button
                        className={css.button}
                        isBasic
                        isDanger
                        onClick={resetForm}
                    >
                        Cancel
                    </Button>
                }

                <Button
                    className={css.button}
                    isPrimary
                    disabled={saving || selectedProperties.length == 0 || (!rfcstPeriodSelected && !bdgtPeriodSelected)}
                    onClick={() => {
                        const locks: {isBudgetLocked?: boolean, isReforecastLocked?: boolean} = {};

                        if (rfcstPeriodSelected === true) {
                            locks.isReforecastLocked = shouldFinalize;
                        }

                        if (bdgtPeriodSelected === true) {
                            locks.isBudgetLocked = shouldFinalize;
                        }

                        setSaving(true);
                        updateMultiForecastPeriodLocks({
                            variables: {
                                propertyIds: selectedProperties,
                                budgetYear,
                                locks,
                            }
                        })
                    }}
                >
                    Save
                </Button>
            </div>
        </>
    );
}
