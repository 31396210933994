export enum Authorizations {
    Analytics = "ANALYTICS",

    // Planning Hub
    PlanningHubPayroll = "PLANNING_HUB_PAYROLL",

    // Modeling Methods
    EditModelingMethods = "EDIT_MODELING_METHODS",

    // Forecast Locking
    LockUnlockForecastPeriods = "LOCK_UNLOCK_FORECAST_PERIODS",

    // Lock/Unlock and Edit for Locked Accounts
    EditLockUnlockAccounts = "EDIT_LOCK_UNLOCK_ACCOUNTS"
}
