/* eslint-disable */
import React, { ReactElement, useCallback, useContext, useEffect, useState } from "react";

import { ViziblyTheme } from "../../../styles/zendesk-garden/ViziblyZDGTheme";
import { ThemeProvider } from "styled-components";
import { Progress } from '@zendeskgarden/react-loaders';

import DashboardHeader, { DashboardHeaderConfig, DashboardHeaderSection } from "./components/DashboardHeader";

import { COLORS } from "../../../constants/Colors";

import * as styles from './helpers/styles.module.scss';

export interface IDashboardLayout {
    title: string,
    sections: DashboardHeaderSection[],
    showingSection: number,
    children: ReactElement[]|ReactElement|string,
    loadProgress: number,
    headerConfig?: DashboardHeaderConfig,
}

function DashboardLayout({ title, children, sections, showingSection, loadProgress }: IDashboardLayout): ReactElement {
    return (
        <ThemeProvider theme={ViziblyTheme}>
            <div className={`${styles.dashboard}`}>
                {loadProgress < 100 &&
                    <Progress size="medium" color={COLORS.PRIMARY_400} value={loadProgress}/>}
                <div className={styles.mainHeader}>
                    <div className={styles.viewTitle}>{`${title}`}</div>
                    <div className={styles.betaTag}>Beta</div>
                </div>
                <DashboardHeader
                    sections={sections}
                    showingSection={showingSection}
                />
                <div className={`${styles.section}`}>
                    {children}
                </div>
            </div>
        </ThemeProvider>
    );
}

export default React.memo(DashboardLayout);
