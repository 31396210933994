/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, {ReactElement, useEffect, useState} from "react";
import Tree, {TreeNode} from "../tree/Tree";
import "./SideBar.css";
import {REVENUE_WORKFLOW} from "../../constants/RevenueWorkflow";
import {FinancialEntity, useChartOfAccounts} from "../../contexts/chartofaccounts/ChartOfAccountsContext";
import {FinancialEntityType, RevenueModelType,} from "../../__generated__/generated_types";
import useAppStore from '../../hooks/useAppStore';
import {isEmpty} from '../../utils/exports';
import {useParams} from "react-router-dom";
import {useFoqalAction} from "../../hooks/useFoqal";
import {propertyHasRevenueModel, useProperties} from "../../contexts/properties/PropertiesContext";
import {SIMPLIFIED_REVENUE_CHILD_TREE_NODES} from "../../routes/main/account/AccountRouteConstants";

export interface SideBarProps {
    renovationsWorkflow: Array<TreeNode>;
    isPayrollAvailableForCurrentUser: boolean;
}

const grabKeys = (node: TreeNode[], ancestorNode: TreeNode, ancesstorChild: TreeNode, currentAccountId: string) => {
    const keys: Array<string> = [];

    if (node.length > 0) {
        for (let j = 0; j < node.length; j++) {
            const child2 = node[j];
            if (child2?.accountId === currentAccountId) {
                keys.push(ancestorNode?.title, ancesstorChild?.title);
            }
            if (child2?.children) {
                const linkFound = child2?.children.map(a => a.accountId).includes(currentAccountId);
                if (linkFound) keys.push(ancestorNode?.title, ancesstorChild?.title, child2.title);
            }
        }
    }
    return keys;
};

const grabActiveSideBarItem = (arr: TreeNode[], currentAccountId: string) => {
    const parentKeys: Array<string> = [];

    for (let index = 0; index < arr.length; index++) {
        const node: any = arr[index];

        if (isEmpty(node?.children)) break;

        const children: TreeNode[] | undefined = node?.children;
        children?.forEach((child) => {
            if (child.accountId === currentAccountId) {
                parentKeys.push(node?.title);
            }
            const node2 = child.children;
            if (node2) {
                parentKeys.push(...grabKeys(node2, node, child, currentAccountId));
            }
        });
    }
    return parentKeys;
};

export default function SideBar({renovationsWorkflow, isPayrollAvailableForCurrentUser}:SideBarProps): ReactElement {

    const appStore = useAppStore();
    const { chartOfAccounts, isReady } = useChartOfAccounts();
    const {currentProperty} = useProperties();
    const isVisible = appStore.isSideBarVisible;

    const params: any = useParams();
    const routeName = params?.id;
    const categoryType = params?.type || appStore.categoryType;

    const [treeNodes, setTreeNodes] = useState<TreeNode[]>([]);

    const {show: showFoqal} = useFoqalAction();

    useEffect(
        () => {
            if(
                !isReady
                || !chartOfAccounts
                || (chartOfAccounts && chartOfAccounts.length == 0)
            ){
                return;
            }

            let menuTreeNodes:TreeNode[] = [];

            if(propertyHasRevenueModel(currentProperty, RevenueModelType.Original)) {
                menuTreeNodes.push(...REVENUE_WORKFLOW.map(revWorkflow => {
                    return {
                        title: revWorkflow.title,
                        children: revWorkflow.children.map((workflow) => {
                            return {
                                title: workflow.title,
                                url: workflow.url
                            };
                        })
                    };
                }));

                if(renovationsWorkflow){
                    const renovationsWorkflowChildren = renovationsWorkflow[0]?.children;
                    if(renovationsWorkflowChildren && renovationsWorkflowChildren.length > 0){
                        menuTreeNodes.push(...renovationsWorkflow);
                    }
                }
            } else if(propertyHasRevenueModel(currentProperty, RevenueModelType.Simplified)) {
                menuTreeNodes.push({
                    title: "Revenue",
                    children: SIMPLIFIED_REVENUE_CHILD_TREE_NODES
                });
            } else {
                menuTreeNodes.push(
                    {
                        title: 'Revenue',
                        children: [
                            {
                                title: 'Market Rents',
                                url: '/revenue/market-rents',
                                useRawUrl: true,
                            },
                            {
                                title: 'Leasing Activity',
                                url: '/revenue/leasing-activity',
                                useRawUrl: true,
                            },
                            {
                                title: 'Summary',
                                url: '/revenue/summary',
                                useRawUrl: true,
                            },
                        ]
                    }
                );
            }

            if(isPayrollAvailableForCurrentUser) {
                menuTreeNodes = menuTreeNodes.concat([
                    {
                        title: "Payroll",
                        url:'/payroll',
                    }
                ]);
            }

            chartOfAccounts.forEach(component => {
                menuTreeNodes = menuTreeNodes.concat(component.children.map(child => {
                    return {
                        title: child.name,
                        children: getChildren(child)
                    };

                    function getChildren(node: FinancialEntity): TreeNode[] {
                        const children = node.children?.map(childNode => {
                            if (childNode.type == FinancialEntityType.Account) {
                                return {
                                    title: childNode.name,
                                    accountId: childNode.id
                                };
                            }
                            else {
                                return {
                                    title: childNode.name,
                                    children: getChildren(childNode)
                                };
                            }
                        }) ?? [];
                        return children;
                    }
                }));
            });

            setTreeNodes( menuTreeNodes);

            appStore.set({ sideBarItem: { parentkeys: grabActiveSideBarItem(menuTreeNodes, routeName) } });
        },
        [isReady, currentProperty, chartOfAccounts, renovationsWorkflow]
    );

    useEffect(() => {
        appStore.set({ sideBarItem: { parentkeys: grabActiveSideBarItem(treeNodes, routeName) } });
        // appStore.set({ isSideBarVisible: !isEmpty(appStore.sideBarItem?.parentkeys) });
        return () => { };
    }, [params]);

    return (
        <React.Fragment>
            <div id="sidebarx" onClick={(e) => {
                const t = e.target as any;
                if (t.id === "sidebarx") {
                    appStore.set({ isSideBarVisible: false });
                    showFoqal();
                }
            }} className={isVisible ? "sd-container _modal transparent" : ""} style={{ zIndex: 9 }}>
            </div>
            <div className={`bg-white overflow-auto ${(isVisible && "sidebar")} ${isVisible ? " sidebar-opened" : ""} h-100`}>
                {isVisible && (
                    <div className="sidebar-components-data text-decoration-none text-darkliver pt-5">
                        <Tree
                            type={categoryType}
                            nodes={treeNodes}
                            depth={0}
                        />
                    </div>
                )}
            </div>
        </React.Fragment>
    );
}

