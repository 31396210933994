import { ReactElement, useEffect, useMemo, useRef, useState } from "react";

import { TDriverPayrollMetric } from "../../../../../../contexts/account/data/logic/driversAndWorksheetData";
import { PayrollMenu } from "../formula-menu/PayrollMenu";

import * as css from "./styles/css.module.scss";
import { deleteNodeKeys, TFormulaNodeProps } from "./logic/formulaNode";
import { compItemsCustomNamesMap, TAllAvailableDrivers } from "../../../../../../contexts/account/data/utils";


export type TPayrollCalculationType = {
    name: string,
}

type TPayrollCalculationNodeProps = TFormulaNodeProps&{
    allDrivers: TAllAvailableDrivers,
    driver: TDriverPayrollMetric,
    className?: {
        calculationNode?: string,
        calculationItemNode?: string,
    },
    isLastDriver: boolean,
    isLastDriverType?: boolean,
    isWorksheetDriven?: boolean,
    isBulkUpdateBar?: boolean,
    isPropertyDriversUI?: boolean,
}

export default function PayrollCalculationFxNode(props: TPayrollCalculationNodeProps): ReactElement {
    const [isFocused, setIsFocused] = useState<boolean>(false);
    const [positionsHovered, setPositionsHovered] = useState<boolean>(false);

    const wrapperRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (isFocused && wrapperRef.current) {
            wrapperRef.current.focus();
        }
    }, [isFocused]);

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (
                wrapperRef.current &&
                (wrapperRef.current === event.target ||
                wrapperRef.current.contains(event.target as Node))
            ) {
                return;
            }
            setIsFocused(false);
        };

        // Clicking outside the current node should remove focus
        document.addEventListener("mousedown", handleClickOutside);

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    const nodeClassName = props.locked ? css.fxNodeLocked : css.fxNode;

    const staticJunctionNode = <>&nbsp;+&nbsp;</>;

    const renderStaticJunctionNode = () => {
        if (props.isLastDriver) {
            /**
             * The worksheet driver will follow the payroll driver in the fx bar
             * Since we render the `(Line items +)` node if the account is worksheet driven,
             * we'll need to account for that to conditionally append '+' to this driver
             */
            if (!props.isLastDriverType || props.isWorksheetDriven) {
                return staticJunctionNode;
            }
            return <></>;
        }
        return staticJunctionNode;
    };

    const handleClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        const input = event.target as HTMLDivElement;
        let isClickOnPayrollCompItem = false;

        /**
         * We don't want the `onClose()` event of the `TooltipModal` in `PayrollMenu` to bubble up
         * as an `onClick()` event on this div. Since `stopPropagation()` doesn't work here, we manually
         * check if this div was clicked on specifically before toggling `isFocused`
        */
        input.classList.forEach((classNameStr) => {
            if (classNameStr.includes(css.payrollCompItem)) {
                isClickOnPayrollCompItem = true;
            }
        });

        if (isClickOnPayrollCompItem) {
            setIsFocused(!isFocused);
        }
    };

    const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
        if (
            isFocused && deleteNodeKeys.includes(e.key) && props.allDrivers.payroll
        ) {
            props.fbUpdates.removePayrollDriver({
                allPayrollDrivers: props.allDrivers.payroll,
                itemType: props.driver.itemType,
            });
        }
    };

    const payrollWrapperClassNames = useMemo(() => {
        const allClassNames = [css.deletableNode];

        if (isFocused) {
            allClassNames.push(css.focusedNode);
        }

        if (positionsHovered) {
            allClassNames.push(css.disableHover);
        } else {
            allClassNames.filter((each: string) => each !== css.disableHover);
        }

        return allClassNames.join(' ');
    }, [isFocused, positionsHovered, nodeClassName]);

    const payrollDriversNode = () => {
        return (
            <div className={css.payrollWrapper}>
                <div
                    className={payrollWrapperClassNames}
                    onClick={handleClick}
                    onKeyDown={handleKeyDown}
                    /**
                     * onMouseLeave() isn't always triggered when the user selects a position
                     * and the positions menu closes, so we make sure by doing this:
                    */
                    onMouseEnter={() => setPositionsHovered(false)}
                    ref={wrapperRef}
                    tabIndex={0}
                >
                    <div className={css.payrollCompItem}>({compItemsCustomNamesMap[props.driver.itemType]}: </div>
                    <div
                        className={css.payrollMenuContainer}
                        onMouseEnter={() => setPositionsHovered(true)}
                        onMouseLeave={() => setPositionsHovered(false)}
                    >
                        <PayrollMenu
                            allDrivers={props.allDrivers}
                            triggerNode={
                                <a>
                                    {props.driver.positions.map((position, j, arrB) =>
                                        <span key={position.id}>
                                            {position.name}
                                            {j< arrB.length - 1
                                                ? staticJunctionNode
                                                : <></>
                                            }
                                        </span>
                                    )}
                                </a>
                            }
                            level={1}
                            selectedCompItemType={props.driver.itemType}
                            fbUpdates={props.fbUpdates}
                            isBulkUpdateBar={props.isBulkUpdateBar}
                            isPropertyDriversUI={props.isPropertyDriversUI}
                        />
                    </div>)
                </div>
                {renderStaticJunctionNode()}
            </div>
        );
    };

    return (
        <div className={props.className ? `${nodeClassName} ${props.className}` : nodeClassName}>
            {payrollDriversNode()}
        </div>
    );
}
