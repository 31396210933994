import { ReactElement, useEffect, useState } from "react";
import {
    ListBudgetYearsQuery,
    PropertyRevenueAssumptionsStatusViewModel,
    VersionType,
    useCopyRevenueAssumptionsClientLevelMutation,
    useRevenueAssumptionsStatusLazyQuery
} from "../../../__generated__/generated_types";
import Card from "../../simplified-revenue/components/Card";
import * as css from "../styles/budgetSeasonManagement.module.scss";
import { Field as FormField, Label as FormLabel, Radio, MediaInput, Checkbox } from '@zendeskgarden/react-forms';
import { Dropdown, Field, Item, Menu, Select } from "@zendeskgarden/react-dropdowns";
import { yearToSeason } from "../util";
import { Button } from "@zendeskgarden/react-buttons";
import { toast } from "react-toastify";
import { ReactComponent as SearchIcon } from '@zendeskgarden/svg-icons/src/16/search-stroke.svg';
import { Body, Close, Footer, FooterItem, Header, Modal } from "@zendeskgarden/react-modals";
import { Inline } from "@zendeskgarden/react-loaders";
import { ReactComponent as CheckIcon } from '@zendeskgarden/svg-icons/src/16/check-lg-fill.svg';

export type IModelingMethodsTab = {
    budgetYearsListData: ListBudgetYearsQuery | undefined,
}

type TPropertyRevenueAssumptionsStatus =
    Pick<PropertyRevenueAssumptionsStatusViewModel,
        "propertyId" |
        "propertyName" |
        "hasAssumptions" |
        "year" |
        "versionType"
    >

export default function RevenueAssumptionsTab({budgetYearsListData}: IModelingMethodsTab): ReactElement {

    const [sourcePeriod, setSourcePeriod] = useState<VersionType.Reforecast | VersionType.Budget>(VersionType.Budget);
    const [destPeriod, setDestPeriod] = useState<VersionType.Reforecast | VersionType.Budget>(VersionType.Reforecast);
    const [sourceBudgetYear, setSourceBudgetYear] = useState<number>();
    const [destBudgetYear, setDestBudgetYear] = useState<number>();
    const [renovationsCopyBothPeriods, setRenovationsCopyBothPeriods] = useState(false);
    const [saving, setSaving] = useState(false);
    const [showConfirmCopy, setShowConfirmCopy] = useState(false);

    const [propertyRevenueAssumptionsStatus, setPropertyRevenueAssumptionsStatus] = useState<TPropertyRevenueAssumptionsStatus[]>([]);
    const [propertyRevenueAssumptionsStatusBudgetYear, setPropertyRevenueAssumptionsStatusBudgetYear] = useState<number>();
    const [propertyRevenueAssumptionsStatusPeriod, setPropertyRevenueAssumptionsStatusPeriod] = useState<VersionType.Reforecast | VersionType.Budget>(VersionType.Budget);
    const [filteredPropertyRevenueAssumptionsStatus, setFilteredPropertyRevenueAssumptionsStatus] = useState<TPropertyRevenueAssumptionsStatus[]>([]);

    const [copyRevenueAssumptionsClientLevel] = useCopyRevenueAssumptionsClientLevelMutation();
    const [getRevenueAssumptionsStatus, {data: propertyRevenueAssumptionsStatusData}] = useRevenueAssumptionsStatusLazyQuery({
        fetchPolicy: "no-cache"
    });

    function resetForm() {
        setSourceBudgetYear(undefined);
        setDestBudgetYear(undefined);
        setSourcePeriod(VersionType.Budget);
        setDestPeriod(VersionType.Reforecast);
    }

    function reloadRevenueAssumptionsStatus() {
        if (!propertyRevenueAssumptionsStatusBudgetYear || !propertyRevenueAssumptionsStatusPeriod) {
            return;
        }
        getRevenueAssumptionsStatus({
            variables: {
                budgetYear: propertyRevenueAssumptionsStatusBudgetYear,
                versionType: propertyRevenueAssumptionsStatusPeriod
            }
        });
    }

    function handleCopy(sourceBudgetYear: number,
                        sourcePeriod: VersionType.Reforecast | VersionType.Budget,
                        destBudgetYear: number,
                        destPeriod: VersionType.Reforecast | VersionType.Budget) {
        setSaving(true);
        copyRevenueAssumptionsClientLevel({
            variables: {
                sourceBudgetYear: sourceBudgetYear,
                sourceVersionType: sourcePeriod,
                destinationBudgetYear: destBudgetYear,
                destinationVersionType: destPeriod,
                renovationCopyBothPeriods: renovationsCopyBothPeriods
            }
        })
        .then(
            (ret) => {
                resetForm();
                if (ret.data?.copyRevenueAssumptionsClientLevel.result) {
                    toast.success(`Revenue Assumptions copied successfully`);
                }
                else {
                    toast.error(ret.data?.copyRevenueAssumptionsClientLevel.resultString ?? "Revenue Assumptions copy failed");
                }
                reloadRevenueAssumptionsStatus();
                setSaving(false);
            }
        )
        .finally(() => {
            setShowConfirmCopy(false);
        });
    }

    useEffect(
        () => {
            reloadRevenueAssumptionsStatus();
        },
        [propertyRevenueAssumptionsStatusBudgetYear, propertyRevenueAssumptionsStatusPeriod]
    );

    useEffect(
        () => {
            if (budgetYearsListData?.listBudgetYears?.activeBudgetYear != undefined) {
                setPropertyRevenueAssumptionsStatusBudgetYear(budgetYearsListData?.listBudgetYears?.activeBudgetYear);
            }
        },
        [budgetYearsListData?.listBudgetYears?.activeBudgetYear]
    );

    useEffect(
        () => {
            if (!propertyRevenueAssumptionsStatusData) {
                return;
            }
            const propertyRevenueAssumptionsStatus: TPropertyRevenueAssumptionsStatus[] = [];
            for (const row of propertyRevenueAssumptionsStatusData.queryRevenueAssumptionsStatus) {
                propertyRevenueAssumptionsStatus.push({
                    propertyId: row.propertyId,
                    propertyName: row.propertyName,
                    hasAssumptions: row.hasAssumptions,
                    year: row.year,
                    versionType: row.versionType
                });
            }
            setPropertyRevenueAssumptionsStatus(propertyRevenueAssumptionsStatus);
            setFilteredPropertyRevenueAssumptionsStatus(propertyRevenueAssumptionsStatus);
        },
        [propertyRevenueAssumptionsStatusData]
    );

    const possibleSourceBudgetYears = Array.from(budgetYearsListData?.listBudgetYears?.budgetYears?.map(y => y.year) ?? ([] as number[])).sort();
    const possibleDestBudgetYears = budgetYearsListData?.listBudgetYears?.budgetYears?.filter(y => y.year != sourceBudgetYear && !y.protected)?.map(y => y.year)?.sort() ?? [];

    return (
        <>
            <Card
                className={css.updaterControlsCard}
                titleClassName={css.updaterControlsTitle}
                title={"Carry Over Revenue Assumptions Across Budgeting Seasons (including renovations setup)"}
                actions={
                    <div className={css.updaterControlsButtons}>
                        {(sourceBudgetYear || destBudgetYear) &&
                            <Button
                                className={css.button}
                                isBasic
                                isDanger
                                onClick={resetForm}
                            >
                                Cancel
                            </Button>
                        }

                        <Button
                            className={css.button}
                            isPrimary
                            disabled={saving 
                                || sourcePeriod == undefined
                                || sourceBudgetYear == undefined
                                || destBudgetYear == undefined
                                || destPeriod == undefined
                            }
                            onClick={() => {
                                setShowConfirmCopy(true);
                            }}
                        >
                            Copy
                        </Button>
                    </div>
                }
            >
                <ol className={css.updaterControlsSteps}>
                    <li>
                        Select Originating Season
                        <Dropdown
                            selectedItem={sourceBudgetYear?.toString()}
                            onSelect={(v) => {setSourceBudgetYear(v); setDestBudgetYear(undefined);}}
                        >
                            <Field>
                                <Select>
                                    {sourceBudgetYear ? yearToSeason(sourceBudgetYear): ""}
                                </Select>
                            </Field>
                            <Menu>
                                {possibleSourceBudgetYears.map(option => (
                                    <Item key={option} value={option}>
                                        {yearToSeason(option)}
                                    </Item>
                                ))}
                            </Menu>
                        </Dropdown>
                    </li>
                    <li>
                        Select Originating Forecast Period
                        <FormField>
                            <Radio
                                disabled={sourceBudgetYear == undefined}
                                value={"sourcePeriodReforecast"}
                                checked={sourcePeriod == VersionType.Reforecast}
                                onChange={() => setSourcePeriod(VersionType.Reforecast)}
                            >
                                <FormLabel>{sourceBudgetYear ? sourceBudgetYear - 1 : ""} Reforecast</FormLabel>
                            </Radio>
                        </FormField>
                        <FormField>
                            <Radio
                                disabled={sourceBudgetYear == undefined}
                                value={"sourcePeriodBudget"}
                                checked={sourcePeriod == VersionType.Budget}
                                onChange={() => setSourcePeriod(VersionType.Budget)}
                            >
                                <FormLabel>{sourceBudgetYear ?? ""} Budget</FormLabel>
                            </Radio>
                        </FormField>
                    </li>
                    <li>
                        Select Destination Season
                        <Dropdown
                            selectedItem={destBudgetYear?.toString()}
                            onSelect={(v) => setDestBudgetYear(v)}
                        >
                            <Field>
                                <Select>
                                    {destBudgetYear ? yearToSeason(destBudgetYear): ""}
                                </Select>
                            </Field>
                            <Menu>
                                {possibleDestBudgetYears.map(option => (
                                    <Item key={option} value={option}>
                                        {yearToSeason(option)}
                                    </Item>
                                ))}
                            </Menu>
                        </Dropdown>
                    </li>
                    <li>
                        Select Destination Forecast Period
                        <FormField>
                            <Radio
                                disabled={destBudgetYear == undefined}
                                value={"destPeriodReforecast"}
                                checked={destPeriod == VersionType.Reforecast}
                                onChange={() => setDestPeriod(VersionType.Reforecast)}
                            >
                                <FormLabel>{destBudgetYear ? destBudgetYear - 1 : ""} Reforecast</FormLabel>
                            </Radio>
                        </FormField>
                        <FormField>
                            <Radio
                                disabled={destBudgetYear == undefined}
                                value={"destPeriodBudget"}
                                checked={destPeriod == VersionType.Budget}
                                onChange={() => setDestPeriod(VersionType.Budget)}
                            >
                                <FormLabel>{destBudgetYear ?? ""} Budget</FormLabel>
                            </Radio>
                        </FormField>
                    </li>
                    <li>
                        Renovations - should copy both periods?
                        <FormField>
                            <Checkbox
                                disabled={destBudgetYear == undefined}
                                checked={renovationsCopyBothPeriods}
                                onChange={(v) => setRenovationsCopyBothPeriods(v.target.checked)}
                            >
                                <FormLabel>{
                                    renovationsCopyBothPeriods ? "Both"
                                    :
                                    destPeriod && sourcePeriod ?
                                    `
                                     ${destBudgetYear ? (destPeriod == VersionType.Reforecast ? (destBudgetYear - 1) : destBudgetYear) : ""}
                                     ${destPeriod.toPascalCase()} will be copied from
                                     ${sourceBudgetYear ? (sourcePeriod == VersionType.Reforecast ? (sourceBudgetYear - 1) : sourceBudgetYear) : ""}
                                     ${sourcePeriod.toPascalCase()}.
                                     ${destBudgetYear ? (destPeriod == VersionType.Budget ? (destBudgetYear - 1) : destBudgetYear) : ""}
                                     ${destPeriod == VersionType.Budget ? "Reforecast" : "Budget"} will be cleared`
                                    :
                                    ""
                                }</FormLabel>
                            </Checkbox>
                        </FormField>
                    </li>
                </ol>

            </Card>
            <Card
                title={"Number of Accounts Assigned a Modelling Method"}
                actions={
                    <>
                        <Dropdown
                            selectedItem={propertyRevenueAssumptionsStatusBudgetYear?.toString()}
                            onSelect={(v) => setPropertyRevenueAssumptionsStatusBudgetYear(v)}
                        >
                            <Field>
                                <Select style={{width: "15rem"}}>
                                    {propertyRevenueAssumptionsStatusBudgetYear ? yearToSeason(propertyRevenueAssumptionsStatusBudgetYear): ""}
                                </Select>
                            </Field>
                            <Menu>
                                {
                                    budgetYearsListData?.listBudgetYears?.budgetYears ?
                                        budgetYearsListData?.listBudgetYears?.budgetYears?.map(option => (
                                            <Item key={option.year} value={option.year}>
                                                {yearToSeason(option.year)}
                                            </Item>
                                        ))
                                        :
                                        <></>
                                }
                            </Menu>
                        </Dropdown>
                        <Dropdown
                            selectedItem={propertyRevenueAssumptionsStatusPeriod}
                            onSelect={(v) => setPropertyRevenueAssumptionsStatusPeriod(v)}
                        >
                            <Field>
                                <Select style={{width: "10rem"}}>
                                    {propertyRevenueAssumptionsStatusPeriod ? propertyRevenueAssumptionsStatusPeriod.toPascalCase(): ""}
                                </Select>
                            </Field>
                            <Menu>
                                {[VersionType.Reforecast, VersionType.Budget].map(option => (
                                    <Item key={option} value={option}>
                                        {option.toPascalCase()}
                                    </Item>
                                ))}
                            </Menu>
                        </Dropdown>
                        <FormField className={css.propertySearch}>
                            <MediaInput start={<SearchIcon />} placeholder="Search" onChange={e => {
                                setFilteredPropertyRevenueAssumptionsStatus(
                                    propertyRevenueAssumptionsStatus
                                    .filter(p => p.propertyName.toLowerCase().includes(e.target.value.trim().toLowerCase())));
                            }} />
                        </FormField>
                    </>
                }
            >
                <div className={css.propertyTableWrapper}>
                    <table className={css.propertyTable}>
                        <thead>
                            <tr className={css.headerCell}>
                                <th className={`${css.cell} ${css.nameCell}`}>Property</th>
                                <th className={`${css.cell} ${css.periodCell}`}>Revenue Setup</th>
                            </tr>
                        </thead>

                        <tbody>
                            {filteredPropertyRevenueAssumptionsStatus.map(property => (
                                    <tr key={property.propertyId}>
                                        <td className={`${css.cell}`}>{ property.propertyName }</td>
                                        <td className={`${css.cell} ${css.periodCell}`}>{property.hasAssumptions ? <CheckIcon /> : <></>}</td>
                                    </tr>
                                ))}
                        </tbody>
                    </table>
                </div>
            </Card>
            {showConfirmCopy && sourceBudgetYear && destBudgetYear &&
            <Modal onClose={() => setShowConfirmCopy(false)}>
                <Header isDanger>
                    Confirm copy revenue assumptions
                </Header>
                <Body>
                    Are you sure you want to copy revenue assumptions to {yearToSeason(destBudgetYear)} {destPeriod.toPascalCase()}?
                    This will erase revenue assumptions in {yearToSeason(destBudgetYear)} {destPeriod.toPascalCase()} if they exist.
                </Body>
                <Footer>
                    <FooterItem>
                        <Button
                            onClick={() => setShowConfirmCopy(false)}
                            isBasic
                            disabled={saving
                                || sourcePeriod == undefined
                                || sourceBudgetYear == undefined
                                || destBudgetYear == undefined
                                || destPeriod == undefined
                            }
                        >
                            Cancel
                        </Button>
                    </FooterItem>
                    <FooterItem>
                        {
                            <Button
                                isPrimary
                                onClick={() => handleCopy(sourceBudgetYear, sourcePeriod, destBudgetYear, destPeriod)}
                                disabled={saving
                                    || sourcePeriod == undefined
                                    || sourceBudgetYear == undefined
                                    || destBudgetYear == undefined
                                    || destPeriod == undefined
                                }
                            >
                                {
                                    saving
                                        ? <Inline size={24} aria-label="loading"/>
                                        : <span>Confirm</span>
                                }
                            </Button>
                        }
                    </FooterItem>
                </Footer>
                <Close aria-label="Close modal" />
            </Modal>}
         </>
    );
}