import React, {ReactElement} from "react";
import {Field, Label, Input, Message} from '@zendeskgarden/react-forms';
import {Button} from '@zendeskgarden/react-buttons';
import styles from '../AnalystPayrollDrawer.module.scss';
import {DEFAULT_NEW_EMPLOYEE_ID, LocalPayrollEmployeeModel, LocalPayrollEmployeeModelKeys} from "../helpers/types";

interface StaffDetailsProps {
    employeeData: LocalPayrollEmployeeModel,
    handleDelete: () => void,
    updateFormState: (key: LocalPayrollEmployeeModelKeys, value: any) => void
}

export default function StaffDetails({employeeData, handleDelete, updateFormState}: StaffDetailsProps): ReactElement {

    if (!employeeData) {
        return <></>;
    }

    return (
        <div className={styles.staffDetailsContainer}>
            <div>
                <h4 className={styles.staffDetailsHeader}>Staff Details</h4>
                <Field className={styles.field}>
                    <Label className={styles.label}>First Name</Label>
                    <Input placeholder="First Name" value={employeeData.firstName}
                           validation={employeeData.firstNameFieldTouched && employeeData.firstName === "" ? "error" : undefined}
                           onChange={(evt) => {
                               updateFormState(LocalPayrollEmployeeModelKeys.firstName, evt.target.value);
                           }}/>
                    <Message validation="error"
                             hidden={!employeeData.firstNameFieldTouched || employeeData.firstName !== ""}>
                        Staff member must have a first name.
                    </Message>
                </Field>
                <Field className={styles.field}>
                    <Label className={styles.label}>Last Name</Label>
                    <Input placeholder="Last Name" value={employeeData.lastName} onChange={(evt) => {
                        updateFormState(LocalPayrollEmployeeModelKeys.lastName, evt.target.value);
                    }}/>
                </Field>
                <Field className={styles.field}>
                    <Label className={styles.label}>Employee ID</Label>
                    <Input placeholder="Employee ID"
                           value={employeeData.externalSystemEmployeeId ?? ""} type={"number"}
                           onChange={(evt) => {
                               updateFormState(LocalPayrollEmployeeModelKeys.externalSystemEmployeeId, evt.target.value);
                           }}/>
                </Field>
            </div>
            {
                employeeData.id && !employeeData.id.startsWith(DEFAULT_NEW_EMPLOYEE_ID) &&
                <div style={{justifyContent: "center", textAlign: "center"}}>
                    <Button
                        isDanger
                        size={"medium"}
                        style={{width: "150px"}}
                        onClick={() => {
                            handleDelete();
                        }}
                    >
                        Delete
                    </Button>
                </div>
            }
        </div>
    );
}
