import Handsontable from "handsontable";
import * as css from "./styles/accountTable.module.scss";
import {BaseRenderer} from "handsontable/renderers";

// TODO investigate actual need for this many cell renderers, since without data context it's hard to do much customization
// so, a lot of the dynamic styling in response to changes happens in the AccountTableSettings cell callback - jonathan

// anything done within this baseCell renderer will be applied to every cell, but may be overriden
export const ViziblyBaseCell: BaseRenderer = function(this: Handsontable, instance, td, row, col, prop, value, cellProperties): void {
    Handsontable.renderers.BaseRenderer.apply(this, [instance, td, row, col, prop, value, cellProperties]);
    Handsontable.renderers.NumericRenderer.apply(this, [instance, td, row, col, prop, value, cellProperties]);

    td.className += ` ${css.cellBase}`;
    cellProperties.readOnly = true;
};

export const Divider: BaseRenderer = function(this: Handsontable, instance, td, row, col, prop, value, cellProperties): void {
    ViziblyBaseCell.apply(this, [instance, td, row, col, prop, value, cellProperties]);
    td.className += ` ${css.dividerLine}`;
    cellProperties.disableVisualSelection = true;
};

export const ForecastMonthStandardCell: BaseRenderer = function(this: Handsontable, instance, td, row, col, prop, value, cellProperties): void {
    ViziblyBaseCell.apply(this, [instance, td, row, col, prop, value, cellProperties]);
};

export const Actuals: BaseRenderer = function(this: Handsontable, instance, td, row, col, prop, value, cellProperties): void {
    ViziblyBaseCell.apply(this, [instance, td, row, col, prop, value, cellProperties]);

    cellProperties.readOnly = true;
};

export const Totals: BaseRenderer = function(this: Handsontable, instance, td, row, col, prop, value, cellProperties): void {
    ViziblyBaseCell.apply(this, [instance, td, row, col, prop, value, cellProperties]);

    td.className += ` ${css.cellBgGrey}`;
    cellProperties.readOnly = true;
};

export const ForecastMonthStandard: BaseRenderer = function(this: Handsontable, instance, td, row, col, prop, value, cellProperties): void {
    ViziblyBaseCell.apply(this, [instance, td, row, col, prop, value, cellProperties]);

    td.className += ` ${css.cellBorderBottom}`;
    cellProperties.readOnly = false;
};

export const ForecastMonthDriverCalculation: BaseRenderer = function(this: Handsontable, instance, td, row, col, prop, value, cellProperties): void {
    ViziblyBaseCell.apply(this, [instance, td, row, col, prop, value, cellProperties]);

    td.className += ` ${css.cellBorderBottom}`;
    cellProperties.readOnly = true;
};

export const ForecastMonthCalculationOverrideable: BaseRenderer = function(this: Handsontable, instance, td, row, col, prop, value, cellProperties): void {
    ViziblyBaseCell.apply(this, [instance, td, row, col, prop, value, cellProperties]);

    td.className += ` ${css.cellBorderBottom}`;
    cellProperties.readOnly = false;
};

export const ForecastMonthEditableDriver: BaseRenderer = function(this: Handsontable, instance, td, row, col, prop, value, cellProperties): void {
    ViziblyBaseCell.apply(this, [instance, td, row, col, prop, value, cellProperties]);

    td.className += ` ${css.cellBorderBottom}`;
    cellProperties.readOnly = false;
};