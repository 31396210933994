import {
    GetPayrollEmployeeDataQuery,
    useGetPayrollEmployeeDataLazyQuery
} from "../../__generated__/generated_types";
import { ApolloError } from "@apollo/client";
import {useEffect, useState} from "react";

export type PayrollQueryData = GetPayrollEmployeeDataQuery["listEmployees"]["items"];

export interface PayrollEmployeeDataState {
    payrollEmployeeData: PayrollQueryData | undefined;
    payrollEmployeeDataLoading: boolean;
    payrollEmployeeDataError:ApolloError | undefined;
    payrollEmployeeDataRefetch: () => void;
}

export function usePayrollEmployeeData(props: {budgetYear: number | undefined}): PayrollEmployeeDataState {
    const [data, setData] = useState<PayrollQueryData | undefined>(undefined);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<ApolloError | undefined>(undefined);
    const [partialData, setPartialData] = useState<PayrollQueryData>([]);

    const [getEmployeeData, {data: employeeData, loading: employeeLoading, error: employeeError}] = useGetPayrollEmployeeDataLazyQuery({
        fetchPolicy: "no-cache"
    });

    useEffect(() => {
        if(!employeeLoading && employeeData) {
            if(employeeData.listEmployees.hasMore && employeeData.listEmployees.cursor && props.budgetYear) {
                setPartialData([...partialData, ...employeeData.listEmployees.items]);
                getEmployeeData({
                    variables: {
                        cursor: employeeData.listEmployees.cursor,
                        budgetYear: props.budgetYear
                    }
                });
            } else {
                setData([...partialData, ...employeeData.listEmployees.items]);
                setLoading(false);
                setPartialData([]);
            }
        }
    }, [employeeLoading, employeeData]);

    useEffect(() => {
        if(!employeeLoading && employeeError) {
            setLoading(false);
            setError(employeeError);
        }
    }, [employeeLoading, employeeError]);

    useEffect(() => {
        if(props.budgetYear){
            getEmployeeData({
                variables: {
                    budgetYear: props.budgetYear
                }
            });
        }
    }, [props.budgetYear]);

    const refetch = () => {
        if(props.budgetYear){
            setLoading(true);
            setData(undefined);
            setError(undefined);
            setPartialData([]);
            getEmployeeData({
                variables: {
                    budgetYear: props.budgetYear
                }
            });
        }
    };

    return {
        payrollEmployeeData: data,
        payrollEmployeeDataLoading: loading,
        payrollEmployeeDataError: error,
        payrollEmployeeDataRefetch: refetch
    };
}
