import GC from "@grapecity/spread-sheets";
import { COLORS } from "../../constants/Colors";

import driverLeftCap from "../../assets/images/spreadjs/driver_cell_left_cap.png";
import driverCenter from "../../assets/images/spreadjs/driver_cell_center.png";
import driverRightCap from "../../assets/images/spreadjs/driver_cell_right_cap.png";
import VerticalAlign = GC.Spread.Sheets.VerticalAlign;
import HorizontalAlign = GC.Spread.Sheets.HorizontalAlign;

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace AnalystStyles {

    export const CELL_BORDER_COLOR = "#E9EBED";

    export const ACTUAL_TOP_BORDER_COLOR = COLORS.PRIMARY_150;
    export const REFORECAST_TOP_BORDER_COLOR = "#107F88";

    export const TOTALS_RIGHT_BORDER_COLOR = "#D2D5DB";
    export const TOTALS_BG_COLOR = "#FAFAFA";

    const ROLLUP_BG_COLOR = "#F7F7F7";

    export const DATA_FONT_COLOR = "#667085";
    export const PROPERTY_LABEL_COLOR = "#09474C";

    export const STATIC_DATA_COLOR = "#667085";
    export const EDITABLE_DATA_COLOR = COLORS.PRIMARY_400;

    export const CELL_BORDER = new GC.Spread.Sheets.LineBorder(CELL_BORDER_COLOR, GC.Spread.Sheets.LineStyle.thin);
    export const TOTALS_RT_BORDER = new GC.Spread.Sheets.LineBorder(TOTALS_RIGHT_BORDER_COLOR, GC.Spread.Sheets.LineStyle.medium);

    export const MEDIUM_VERTICAL_DIVIDER = new GC.Spread.Sheets.LineBorder(TOTALS_RIGHT_BORDER_COLOR, GC.Spread.Sheets.LineStyle.medium);

    export const SECTION_LABEL_FONT = '400 12px Inter';
    export const DATA_LABEL_FONT = '400 12px Inter';

    export const LEVEL_0_TEXT_INDENT = 2;
    export const LEVEL_1_TEXT_INDENT = 4;
    export const LEVEL_2_TEXT_INDENT = 6;

    // Financial Entity Cell Header Styles ________________________________________________________
    const FINANCIAL_ENTITY_CELL = new GC.Spread.Sheets.Style();
    FINANCIAL_ENTITY_CELL.foreColor = '#2F3941';
    FINANCIAL_ENTITY_CELL.font = '500 12px Inter';
    FINANCIAL_ENTITY_CELL.vAlign = VerticalAlign.center;
    FINANCIAL_ENTITY_CELL.cellPadding = '8 26 8 8';
    FINANCIAL_ENTITY_CELL.borderBottom = CELL_BORDER;
    FINANCIAL_ENTITY_CELL.wordWrap = true;

    export const LEVEL_0_ROW_LABEL = FINANCIAL_ENTITY_CELL.clone();
    LEVEL_0_ROW_LABEL.foreColor = COLORS.GREY_300;
    LEVEL_0_ROW_LABEL.textIndent = 3.5;

    // Apply to Level 0 rows when their data is loaded
    export const LEVEL_0_ROW_LABEL_LOADED = LEVEL_0_ROW_LABEL.clone();
    LEVEL_0_ROW_LABEL_LOADED.foreColor = '#2F3941';

    // TODO: Migrate LEVEL_0_ROW_LABEL_LOADED uses to LEVEL_0_ROW_LABEL_LOADED_V2; Remove "V2" once migration is done
    export const LEVEL_0_ROW_LABEL_LOADED_V2 = FINANCIAL_ENTITY_CELL.clone();
    LEVEL_0_ROW_LABEL_LOADED_V2.foreColor = '#2F3941';
    LEVEL_0_ROW_LABEL_LOADED_V2.textIndent = LEVEL_0_TEXT_INDENT;

    export const LEVEL_1_ROW_LABEL = FINANCIAL_ENTITY_CELL.clone();
    LEVEL_1_ROW_LABEL.foreColor = COLORS.GREY_300;
    LEVEL_1_ROW_LABEL.textIndent = 5;

    export const LEVEL_1_ROW_LABEL_LOADED = LEVEL_1_ROW_LABEL.clone();
    LEVEL_1_ROW_LABEL_LOADED.foreColor = '#2F3941';

    export const LEVEL_2_ROW_LABEL = FINANCIAL_ENTITY_CELL.clone();
    LEVEL_2_ROW_LABEL.textIndent = 6.5;

    export const LEVEL_3_ROW_LABEL = FINANCIAL_ENTITY_CELL.clone();
    LEVEL_3_ROW_LABEL.foreColor = '#0D66D';
    LEVEL_3_ROW_LABEL.textIndent = 8;

    export const LEVEL_4_ROW_LABEL = FINANCIAL_ENTITY_CELL.clone();
    LEVEL_4_ROW_LABEL.foreColor = '#0D66D';
    LEVEL_4_ROW_LABEL.textIndent = 9.5;

    // Analyst Op Driver Styles __________________________________________________________________

    // TODO: Migrate LEVEL_1_ROW_LABEL_LOADED uses to LEVEL_1_ROW_LABEL_LOADED_V2; Remove "V2" once migration is done
    export const ANALYST_OP_DRIVER_METRIC_STYLE = FINANCIAL_ENTITY_CELL.clone();
    ANALYST_OP_DRIVER_METRIC_STYLE.textIndent = LEVEL_0_TEXT_INDENT;

    export const ANALYST_OP_DRIVER_PROPERTY_LABEL_STYLE = FINANCIAL_ENTITY_CELL.clone();
    ANALYST_OP_DRIVER_PROPERTY_LABEL_STYLE.textIndent = LEVEL_1_TEXT_INDENT;
    ANALYST_OP_DRIVER_PROPERTY_LABEL_STYLE.foreColor = '#0D666D';

    export const ANALYST_OP_DRIVER_UNIT_LABEL_STYLE = FINANCIAL_ENTITY_CELL.clone();
    ANALYST_OP_DRIVER_UNIT_LABEL_STYLE.textIndent = LEVEL_2_TEXT_INDENT;


    // Data Column Header Styles __________________________________________________________________
    const DATA_HEADER_BASE = new GC.Spread.Sheets.Style();
    DATA_HEADER_BASE.foreColor = DATA_FONT_COLOR;
    DATA_HEADER_BASE.font = '600 10px Inter';
    DATA_HEADER_BASE.hAlign = HorizontalAlign.right;

    export const ACTUAL_HEADER = DATA_HEADER_BASE.clone();
    ACTUAL_HEADER.vAlign = VerticalAlign.bottom;

    export const ACTUAL_HEADER_LAST_CELL = ACTUAL_HEADER.clone();
    ACTUAL_HEADER_LAST_CELL.borderRight = TOTALS_RT_BORDER;

    export const REFORECAST_HEADER = DATA_HEADER_BASE.clone();
    REFORECAST_HEADER.vAlign = VerticalAlign.bottom;
    REFORECAST_HEADER.backColor = "#F1F6F7";

    export const ACTUAL_HEADER_YEAR = DATA_HEADER_BASE.clone();
    ACTUAL_HEADER_YEAR.font = '500 12px Inter';

    export const ACTUAL_HEADER_YEAR_LAST = ACTUAL_HEADER_YEAR.clone();
    ACTUAL_HEADER_YEAR_LAST.borderRight = TOTALS_RT_BORDER;

    export const REFORECAST_YEAR_HEADER = ACTUAL_HEADER_YEAR.clone();
    REFORECAST_YEAR_HEADER.backColor = "#F1F6F7";

    export const ACTUAL_HEADER_MONTH = DATA_HEADER_BASE.clone();
    ACTUAL_HEADER_MONTH.foreColor = '#333842';
    ACTUAL_HEADER_MONTH.font = '800 12.5px Inter';
    ACTUAL_HEADER_MONTH.vAlign = VerticalAlign.top;

    export const ACTUAL_HEADER_MONTH_LAST = ACTUAL_HEADER_MONTH.clone();
    ACTUAL_HEADER_MONTH_LAST.borderRight = TOTALS_RT_BORDER;

    export const REFORECAST_MONTH_HEADER = ACTUAL_HEADER_MONTH.clone();
    REFORECAST_MONTH_HEADER.backColor = "#F1F6F7";


    // Totals Column Styles _______________________________________________________________________
    const TOTALS_COLUMN_BASE = new GC.Spread.Sheets.Style();
    TOTALS_COLUMN_BASE.vAlign = VerticalAlign.center;
    TOTALS_COLUMN_BASE.hAlign = HorizontalAlign.right;
    TOTALS_COLUMN_BASE.backColor = TOTALS_BG_COLOR;
    TOTALS_COLUMN_BASE.foreColor = DATA_FONT_COLOR;
    TOTALS_COLUMN_BASE.borderRight = TOTALS_RT_BORDER;

    export const TOTALS_COLUMN_HDR_CELL1 = TOTALS_COLUMN_BASE.clone();
    TOTALS_COLUMN_HDR_CELL1.font = '500 10px Inter';
    TOTALS_COLUMN_HDR_CELL1.vAlign = VerticalAlign.bottom;

    export const TOTALS_COLUMN_HDR_CELL2 = TOTALS_COLUMN_BASE.clone();
    TOTALS_COLUMN_HDR_CELL2.font = '500 12px Inter';

    export const TOTALS_COLUMN_HDR_CELL3 = TOTALS_COLUMN_BASE.clone();
    TOTALS_COLUMN_HDR_CELL3.foreColor = '#333842';
    TOTALS_COLUMN_HDR_CELL3.font = '600 12.5px Inter';
    TOTALS_COLUMN_HDR_CELL3.vAlign = VerticalAlign.top;

    export const TOTALS_COLUMN_DATA_CELL = TOTALS_COLUMN_BASE.clone();
    TOTALS_COLUMN_DATA_CELL.font = '500 12px Inter';
    TOTALS_COLUMN_DATA_CELL.cellPadding = '6px';
    TOTALS_COLUMN_DATA_CELL.borderTop = CELL_BORDER;
    TOTALS_COLUMN_DATA_CELL.borderBottom = CELL_BORDER;
    TOTALS_COLUMN_DATA_CELL.formatter = "#,#";

    export const TOTALS_COLUMN_PERCENT_DATA_CELL = TOTALS_COLUMN_BASE.clone();
    TOTALS_COLUMN_PERCENT_DATA_CELL.formatter = "0.0%";

    export const TOTALS_COLUMN_DATA_CELL_OPEN = TOTALS_COLUMN_DATA_CELL.clone();
    TOTALS_COLUMN_DATA_CELL_OPEN.foreColor = TOTALS_BG_COLOR;

    // Rollup Row Styles __________________________________________________________________________
    const ROLLUP_ROW_BASE = new GC.Spread.Sheets.Style();
    ROLLUP_ROW_BASE.foreColor = DATA_FONT_COLOR;
    ROLLUP_ROW_BASE.backColor = ROLLUP_BG_COLOR;
    ROLLUP_ROW_BASE.font = '400 12px Inter';
    ROLLUP_ROW_BASE.vAlign = VerticalAlign.center;
    ROLLUP_ROW_BASE.cellPadding = '6px';
    ROLLUP_ROW_BASE.borderRight = CELL_BORDER;
    ROLLUP_ROW_BASE.borderBottom = CELL_BORDER;

    export const ROLLUP_DATA_CELL = ROLLUP_ROW_BASE.clone();
    ROLLUP_DATA_CELL.formatter = "#,#";

    export const ROLLUP_DATA_LAST_CELL = ROLLUP_DATA_CELL.clone();
    ROLLUP_DATA_LAST_CELL.borderRight = TOTALS_RT_BORDER;

    export const ROLLUP_TOTAL_CELL = ROLLUP_ROW_BASE.clone();
    ROLLUP_TOTAL_CELL.borderRight = TOTALS_RT_BORDER;
    ROLLUP_TOTAL_CELL.font = '500 12px Inter';
    ROLLUP_TOTAL_CELL.formatter = "#,#";

    export const ROLLUP_ROW_LABEL = ROLLUP_ROW_BASE.clone();
    ROLLUP_ROW_LABEL.foreColor = '#2F3941';
    ROLLUP_ROW_LABEL.backColor = '#FAFAFA';
    ROLLUP_ROW_LABEL.font = '500 12px Inter';
    ROLLUP_ROW_LABEL.wordWrap = true;

    export const LEVEL_0_ROLLUP_ROW_LABEL = ROLLUP_ROW_LABEL.clone();
    LEVEL_0_ROLLUP_ROW_LABEL.textIndent = LEVEL_0_TEXT_INDENT;
    LEVEL_0_ROLLUP_ROW_LABEL.backColor = '#F0F1F3';

    export const LEVEL_0_ROLLUP_ROW_LABEL_V1 = ROLLUP_ROW_LABEL.clone();
    LEVEL_0_ROLLUP_ROW_LABEL_V1.textIndent = 1.2;

    export const LEVEL_0_ROLLUP_DATA_CELL = ROLLUP_ROW_BASE.clone();
    LEVEL_0_ROLLUP_DATA_CELL.formatter = "#,#";
    LEVEL_0_ROLLUP_DATA_CELL.backColor = '#F0F1F3';

    export const LEVEL_0_LAST_ROLLUP_DATA_CELL = ROLLUP_ROW_BASE.clone();
    LEVEL_0_LAST_ROLLUP_DATA_CELL.formatter = "#,#";
    LEVEL_0_LAST_ROLLUP_DATA_CELL.backColor = '#F0F1F3';
    LEVEL_0_LAST_ROLLUP_DATA_CELL.borderRight = MEDIUM_VERTICAL_DIVIDER;

    export const LEVEL_1_ROLLUP_ROW_LABEL = ROLLUP_ROW_LABEL.clone();
    LEVEL_1_ROLLUP_ROW_LABEL.textIndent = LEVEL_1_TEXT_INDENT;

    export const LEVEL_1_ROLLUP_ROW_LABEL_V1 = LEVEL_1_ROLLUP_ROW_LABEL.clone();
    LEVEL_1_ROLLUP_ROW_LABEL_V1.textIndent = 2.6;

    export const LEVEL_2_ROLLUP_ROW_LABEL = ROLLUP_ROW_LABEL.clone();
    LEVEL_2_ROLLUP_ROW_LABEL.textIndent = LEVEL_2_TEXT_INDENT;

    export const LEVEL_2_ROLLUP_ROW_LABEL_V1 = LEVEL_2_ROLLUP_ROW_LABEL.clone();
    LEVEL_2_ROLLUP_ROW_LABEL_V1.textIndent = 3.5;

    // Section Values cell styles ____________________________________
    // Cells in the same row as a section title. Supports open & closed states
    const SECTION_VALUE_CELL_LABEL_BASE = new GC.Spread.Sheets.Style();
    SECTION_VALUE_CELL_LABEL_BASE.foreColor = DATA_FONT_COLOR;
    SECTION_VALUE_CELL_LABEL_BASE.font = '400 12px Inter';
    SECTION_VALUE_CELL_LABEL_BASE.vAlign = VerticalAlign.center;
    SECTION_VALUE_CELL_LABEL_BASE.cellPadding = '6px';
    SECTION_VALUE_CELL_LABEL_BASE.locked = true;

    export const SECTION_VALUE_CLOSED_CELL_LABEL = SECTION_VALUE_CELL_LABEL_BASE.clone();
    SECTION_VALUE_CLOSED_CELL_LABEL.borderTop = CELL_BORDER;
    SECTION_VALUE_CLOSED_CELL_LABEL.borderRight = CELL_BORDER;
    SECTION_VALUE_CLOSED_CELL_LABEL.borderBottom = CELL_BORDER;
    SECTION_VALUE_CLOSED_CELL_LABEL.borderLeft = CELL_BORDER;
    SECTION_VALUE_CLOSED_CELL_LABEL.formatter = "#,#";

    export const SECTION_VALUE_CLOSED_PERCENT_CELL_LABEL = SECTION_VALUE_CLOSED_CELL_LABEL.clone();
    SECTION_VALUE_CLOSED_PERCENT_CELL_LABEL.formatter = "0.0%";

    export const SECTION_VALUE_LAST_CLOSED_CELL_LABEL = SECTION_VALUE_CLOSED_CELL_LABEL.clone();
    SECTION_VALUE_LAST_CLOSED_CELL_LABEL.borderRight = TOTALS_RT_BORDER;

    export const SECTION_VALUE_LAST_CLOSED_PERCENT_CELL_LABEL = SECTION_VALUE_CLOSED_CELL_LABEL.clone();
    SECTION_VALUE_LAST_CLOSED_PERCENT_CELL_LABEL.borderRight = new GC.Spread.Sheets.LineBorder(TOTALS_RIGHT_BORDER_COLOR, GC.Spread.Sheets.LineStyle.medium);
    SECTION_VALUE_LAST_CLOSED_PERCENT_CELL_LABEL.formatter = "0.0%";

    const SECTION_VALUE_OPEN_CELL_LABEL_BASE = SECTION_VALUE_CELL_LABEL_BASE.clone();
    SECTION_VALUE_OPEN_CELL_LABEL_BASE.foreColor = 'white';
    SECTION_VALUE_OPEN_CELL_LABEL_BASE.borderTop = CELL_BORDER;
    SECTION_VALUE_OPEN_CELL_LABEL_BASE.borderBottom = CELL_BORDER;

    export const SECTION_VALUE_OPEN_FIRST_CELL_LABEL = SECTION_VALUE_OPEN_CELL_LABEL_BASE.clone();
    SECTION_VALUE_OPEN_FIRST_CELL_LABEL.borderLeft = CELL_BORDER;

    export const SECTION_VALUE_OPEN_MID_CELL_LABEL = SECTION_VALUE_OPEN_CELL_LABEL_BASE.clone();

    export const SECTION_VALUE_OPEN_LAST_CELL_LABEL = SECTION_VALUE_OPEN_CELL_LABEL_BASE.clone();
    SECTION_VALUE_OPEN_LAST_CELL_LABEL.borderRight = CELL_BORDER;

    export const SECTION_VALUE_OPEN_LAST_ACTUAL_CELL = SECTION_VALUE_OPEN_MID_CELL_LABEL.clone();
    SECTION_VALUE_OPEN_LAST_ACTUAL_CELL.borderRight = TOTALS_RT_BORDER;

    // Manual Entry cell styles ____________________________________
    export const MANUAL_EDITABLE_FONT_COLOR = "#0A4C52";
    export const MANUAL_ENTRY_HOVER_BORDER_COLOR = "#107F88";
    export const MANUAL_STATIC_CELL_BORDER_COLOR = CELL_BORDER_COLOR;
    export const MANUAL_EDITABLE_CELL_BORDER_COLOR = COLORS.PRIMARY_150;

    export const MANUAL_STATIC_CELL_BORDER = new GC.Spread.Sheets.LineBorder(CELL_BORDER_COLOR, GC.Spread.Sheets.LineStyle.thin);
    export const MANUAL_STATIC_CELL_HOVER_BORDER = new GC.Spread.Sheets.LineBorder("red", GC.Spread.Sheets.LineStyle.thin);

    export const MANUAL_EDITABLE_CELL_BORDER = new GC.Spread.Sheets.LineBorder(MANUAL_EDITABLE_CELL_BORDER_COLOR, GC.Spread.Sheets.LineStyle.medium);
    export const MANUAL_EDITABLE_CELL_HOVER_BORDER = new GC.Spread.Sheets.LineBorder(COLORS.PRIMARY_200, GC.Spread.Sheets.LineStyle.medium);

    const MANUAL_EDIT_CELL_BASE = new GC.Spread.Sheets.Style();
    MANUAL_EDIT_CELL_BASE.foreColor = STATIC_DATA_COLOR;
    MANUAL_EDIT_CELL_BASE.font = '500 12px Inter';
    MANUAL_EDIT_CELL_BASE.vAlign = VerticalAlign.center;
    MANUAL_EDIT_CELL_BASE.cellPadding = '6px';
    MANUAL_EDIT_CELL_BASE.locked = false;

    // Default (unhovered) style for NON-EDITABLE cells
    export const MANUAL_STATIC_CELL_UNHOVERED = MANUAL_EDIT_CELL_BASE.clone();
    MANUAL_STATIC_CELL_UNHOVERED.borderTop = MANUAL_STATIC_CELL_BORDER;
    MANUAL_STATIC_CELL_UNHOVERED.borderRight = MANUAL_STATIC_CELL_BORDER;
    MANUAL_STATIC_CELL_UNHOVERED.borderBottom = MANUAL_STATIC_CELL_BORDER;
    MANUAL_STATIC_CELL_UNHOVERED.borderLeft = MANUAL_STATIC_CELL_BORDER;
    MANUAL_STATIC_CELL_UNHOVERED.locked = true;
    MANUAL_STATIC_CELL_UNHOVERED.formatter = "#,#";

    export const MANUAL_STATIC_PERCENT_CELL_UNHOVERED = MANUAL_STATIC_CELL_UNHOVERED.clone();
    MANUAL_STATIC_PERCENT_CELL_UNHOVERED.formatter = "0.0%";

    export const MANUAL_LAST_STATIC_CELL_UNHOVERED = MANUAL_STATIC_CELL_UNHOVERED.clone();
    MANUAL_LAST_STATIC_CELL_UNHOVERED.borderRight = TOTALS_RT_BORDER;

    export const MANUAL_LAST_STATIC_PERCENT_CELL_UNHOVERED = MANUAL_LAST_STATIC_CELL_UNHOVERED.clone();
    MANUAL_LAST_STATIC_PERCENT_CELL_UNHOVERED.formatter = "0.0%";

    // Default style (hovered) for EDITABLE cells
    export const MANUAL_EDITABLE_CELL_UNHOVERED = MANUAL_EDIT_CELL_BASE.clone();
    MANUAL_EDITABLE_CELL_UNHOVERED.borderTop = MANUAL_STATIC_CELL_BORDER;
    MANUAL_EDITABLE_CELL_UNHOVERED.borderRight = MANUAL_STATIC_CELL_BORDER;
    MANUAL_EDITABLE_CELL_UNHOVERED.borderBottom = MANUAL_STATIC_CELL_BORDER;
    MANUAL_EDITABLE_CELL_UNHOVERED.borderLeft = MANUAL_STATIC_CELL_BORDER;
    MANUAL_EDITABLE_CELL_UNHOVERED.foreColor = '#0D666D';
    MANUAL_EDITABLE_CELL_UNHOVERED.font = '500 12px Inter';
    MANUAL_EDITABLE_CELL_UNHOVERED.formatter = "#,#";

    export const MANUAL_EDITABLE_PERCENT_CELL_UNHOVERED = MANUAL_EDITABLE_CELL_UNHOVERED.clone();
    MANUAL_EDITABLE_PERCENT_CELL_UNHOVERED.formatter = "#.#%";

    // Hovered style for NON-EDITABLE cells
    export const MANUAL_STATIC_CELL_HOVERED = MANUAL_STATIC_CELL_UNHOVERED.clone();
    MANUAL_STATIC_CELL_HOVERED.borderTop = MANUAL_STATIC_CELL_BORDER;
    MANUAL_STATIC_CELL_HOVERED.borderRight = MANUAL_STATIC_CELL_BORDER;
    MANUAL_STATIC_CELL_HOVERED.borderBottom = MANUAL_STATIC_CELL_BORDER;
    MANUAL_STATIC_CELL_HOVERED.borderLeft = MANUAL_STATIC_CELL_BORDER;

    // Hovered style for EDITABLE cells
    export const MANUAL_EDITABLE_CELL_HOVERED = MANUAL_EDIT_CELL_BASE.clone();
    MANUAL_EDITABLE_CELL_HOVERED.foreColor = '#0D666D';
    MANUAL_EDITABLE_CELL_HOVERED.borderTop = MANUAL_EDITABLE_CELL_HOVER_BORDER;
    MANUAL_EDITABLE_CELL_HOVERED.borderRight = MANUAL_EDITABLE_CELL_HOVER_BORDER;
    MANUAL_EDITABLE_CELL_HOVERED.borderBottom = MANUAL_EDITABLE_CELL_HOVER_BORDER;
    MANUAL_EDITABLE_CELL_HOVERED.borderLeft = MANUAL_EDITABLE_CELL_HOVER_BORDER;
    MANUAL_EDITABLE_CELL_HOVERED.formatter = "#,#";


    const DRIVER_CELL_BASE = new GC.Spread.Sheets.Style();
    DRIVER_CELL_BASE.foreColor = '#0D666D';
    DRIVER_CELL_BASE.font = '500 12px Inter';
    DRIVER_CELL_BASE.vAlign = VerticalAlign.center;
    DRIVER_CELL_BASE.hAlign = HorizontalAlign.right;
    DRIVER_CELL_BASE.cellPadding = '6px';
    DRIVER_CELL_BASE.borderTop = MANUAL_STATIC_CELL_BORDER;
    DRIVER_CELL_BASE.borderRight = MANUAL_STATIC_CELL_BORDER;
    DRIVER_CELL_BASE.borderBottom = MANUAL_STATIC_CELL_BORDER;
    DRIVER_CELL_BASE.borderLeft = MANUAL_STATIC_CELL_BORDER;
    DRIVER_CELL_BASE.locked = true;
    DRIVER_CELL_BASE.formatter = "#,#";


    export const DRIVER_CELL_LEFT_CAP = DRIVER_CELL_BASE.clone();
    DRIVER_CELL_LEFT_CAP.backgroundImage = driverLeftCap;

    export const DRIVER_CELL_CENTER = DRIVER_CELL_BASE.clone();
    DRIVER_CELL_CENTER.backgroundImage = driverCenter;

    export const DRIVER_CELL_RIGHT_CAP = DRIVER_CELL_BASE.clone();
    DRIVER_CELL_RIGHT_CAP.backgroundImage = driverRightCap;



    // Test/Dev cell styles _______________________________________________________________________
    export const TEST_STYLE = new GC.Spread.Sheets.Style();
    TEST_STYLE.foreColor = 'blue';
    TEST_STYLE.backColor = 'yellow';
    TEST_STYLE.font = '400 12px Inter';
    TEST_STYLE.vAlign = VerticalAlign.center;
    TEST_STYLE.cellPadding = '6px';
    TEST_STYLE.locked = false;

    export const TEST_STYLE_2 = TEST_STYLE.clone();
    TEST_STYLE_2.foreColor = 'yellow';
    TEST_STYLE_2.backColor = 'blue';

    export const TEST_STYLE_3 = TEST_STYLE.clone();
    TEST_STYLE_3.foreColor = 'white';
    TEST_STYLE_3.backColor = '#FF00FF';




}


