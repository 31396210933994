import { ReactElement } from "react";
import { Button } from "@zendeskgarden/react-buttons";
import { Tooltip } from "@zendeskgarden/react-tooltips";

import { ActiveView, IUsePropertyDriversReturn } from "../logic/usePropertyDrivers";
import { VersionType } from "../../../__generated__/generated_types";


interface IConfigureModelingMethodsBtnProps {
    pd: IUsePropertyDriversReturn,
    isLocked: boolean;
}

export default function ConfigureModelingMethodsBtn({
    pd,
    isLocked,
}: IConfigureModelingMethodsBtnProps): ReactElement {

    const noop = () => null;

    const disabledBtnStyles = {
        backgroundColor: "#e9ebed",
        borderColor: "transparent",
        color: "#c2c8cc",
        cursor: "not-allowed",
    };

    const handleConfigureModelingMethodsClick = () => {
        pd.setIsConfigureModelingMethodsModalOpen(true);
        pd.setActiveView(ActiveView.editFormulas);
    };

    const isCurrentForecastPeriodLockedForProperty = (pd.versionType === VersionType.Budget && pd.propertyForecastLocks.isBudgetLocked)
        || (pd.versionType === VersionType.Reforecast && pd.propertyForecastLocks.isReforecastLocked);

    const renderBtnBySelectAccountsState = () => {
        switch(true) {
            // Precedes all other cases
            case isLocked:
            case isCurrentForecastPeriodLockedForProperty:
                return (
                    <Tooltip content={`Modeling methods cannot be configured. The ${pd.versionType.toLowerCase()} period is locked for this property`} size="large">
                    <Button
                        onClick={noop}
                        // Overriding default ZDG styles. Using inline to avoid !important declarations
                        style={disabledBtnStyles}
                    >
                        Configure Modeling Methods
                    </Button>
                </Tooltip>
                );
            case pd.selectedAccounts.length === 0:
                return (
                    <Button
                        disabled
                        onClick={noop}
                    >
                        Configure Modeling Methods
                    </Button>
                );
            case pd.selectedAccounts.length > 20:
                return (
                    <Tooltip content="You cannot configure more than 20 accounts at once." size="large">
                        <Button
                            onClick={noop}
                            // Overriding default ZDG styles. Using inline to avoid !important declarations
                            style={disabledBtnStyles}
                        >
                            Configure Modeling Methods
                        </Button>
                    </Tooltip>
                );
            default:
                return (
                    <Button
                        onClick={handleConfigureModelingMethodsClick}
                    >
                        Configure Modeling Methods
                    </Button>
                );
        }
    };

    return renderBtnBySelectAccountsState();
}
