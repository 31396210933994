import React, { useEffect, useRef, useState } from "react";
import { MultiValue, SelectInstance } from "react-select";

import CreatableSelect from "react-select/creatable";
import * as styles from './styles.module.scss';
import { SelectNotifiable } from "./TypeaheadDropdownCell";
import { COLORS } from "../../../../constants/Colors";

interface OptionsType {
    label: string;
    value: string;
}
interface Props {
    holder: SelectNotifiable;
    options: string[];
    placeholder?: string;
    initialValue?: string;
    width: number;
}


export const TypeaheadDropdown: React.FC<Props> = ({ holder, options, placeholder, initialValue, width }) => {

    const [value, setValue] = useState<OptionsType | undefined>(initialValue ? {label: initialValue, value: initialValue.trim().toLowerCase()} : undefined);

    useEffect(
        () => {
            if (value) {
                holder.callback(value.label.trim(), "something");
            }
        },
        [value]
    );

    const selectRef = useRef<SelectInstance>(null);

    useEffect(
        () => {
            if (selectRef.current) {
                holder.setRef(selectRef.current);
            }
        },
        [selectRef.current]
    );

    const customStyles = {
        control: (base:any, _state:any) => ({
            ...base,
            boxShadow: `0 0 0 0 ${ COLORS.PRIMARY_400 }`,
            border: `1px solid ${ COLORS.PRIMARY_400 }`,
            fontSize: "12px",
            fontWeight: 500,
            fontFamily: "Inter",
            hover:{
                border: `1px solid ${ COLORS.PRIMARY_400 }`,
            }
        }),
        menu: (base:any, _state:any) => ({
            ...base,
            font: `500 12px Inter`,
        }),
        container: (base:any, _state:any) => ({
            ...base,
            backgroundColor: 'none',
        }),
        group: (base:any, _state:any) => ({
            ...base,
            backgroundColor: 'red',
        }),
        option: (base:any, state:any) => ({
            ...base,
            color: state.isSelected ? `white` : `${ COLORS.PRIMARY_400 }`,
            backgroundColor: state.isSelected ? `${ COLORS.PRIMARY_400 }` : 'none',
        }),
    };

    return <React.StrictMode>
        <div style={{ minWidth: `${width}px`, height: "300px" }}>
            <CreatableSelect
                ref={selectRef}
                escapeClearsValue={true}
                placeholder={placeholder}
                options={options.map(o => ({label: o, value: o.trim().toLowerCase()})) as MultiValue<OptionsType>}
                className={`${styles.typeaheadDropdown}`}
                components={{ DropdownIndicator: null }}
                onChange={
                    (newValue: any, _meta) => {
                        setValue(newValue);
                    }
                }
                onCreateOption={
                    (newValue: string) => {
                        setValue({ value: newValue, label: newValue });
                    }
                }

                isMulti={false}
                value={value}
                autoFocus={true}
                styles={customStyles}
            />
        </div>
    </React.StrictMode>;
}
