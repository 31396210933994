// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
import Core from "handsontable/core";
import { CellProperties } from "handsontable/settings";
import Handsontable from "handsontable";
import * as css from "../styles/css.module.scss";
import { MonthlyVarianceReportConfig } from "../../MonthlyVarianceReportConfig";
import { FinancialEntityType } from "../../../../../__generated__/generated_types";
import { Events } from "handsontable/pluginHooks";
import { MvrTableCols } from "./mvrSpreadsheet";
import { COLORS } from "../../../../../constants/Colors";
import { getVarianceColor } from "../../../../../utils/variance";
import { AutoRowSize, getPlugin } from "handsontable/plugins";

const setDetailsButtonVisible = (hotInstance: Core, row: number, isVisible: boolean): void => {
    const labelCell = hotInstance.getCell(row, MvrTableCols.entityLabel, true);
    if(labelCell){
        if(isVisible){
            labelCell.classList.add(css.showDetailsButton);
        } else {
            labelCell.classList.remove(css.showDetailsButton);
        }
    }
};

/**
 * It's possible for reported row indices to become incorrect, after rapid scrubbing up and down across rows with
 * mouse event handlers. The issue occurs when mousing over rows with no 'Details' button, e.g. summary rows. This fix
 * rolls over visible rows (there are < 10 at any given time) and makes sure they do not have the css class that
 * makes the Details button visible.
 * @param hotInstance
 */
const onNonAccountHover = (hotInstance: Core):void => {
    const plugRef = hotInstance.getPlugin('AutoRowSize');
    if(plugRef !== undefined){
        const autoRowSize = plugRef as AutoRowSize;
        const firstRow = autoRowSize.getFirstVisibleRow();
        const lastRow = autoRowSize.getLastVisibleRow();

        let row = firstRow;
        while (row++ < lastRow){
            setDetailsButtonVisible(hotInstance, row, false);
        }
    }
};

export const valueCellRenderer = (hotInstance: Core, td: HTMLTableCellElement, row: number, column: number, prop: string|number, value: any, cellProperties: CellProperties): void => {
    Handsontable.renderers.NumericRenderer.apply(this, [hotInstance, td, row, column, prop, value, cellProperties]);

    const rowData = hotInstance.getDataAtRow(row);
    const entityType = rowData[MvrTableCols.util_entityType];

    if(entityType === FinancialEntityType.Account){
        td.onmouseenter = () => {
            setDetailsButtonVisible(hotInstance, row, true);
        };

        td.onmouseout = () => {
            setDetailsButtonVisible(hotInstance, row, false);
        };
    }
    else {
        td.style.backgroundColor = COLORS.GREY_100;
        td.style.color = COLORS.GREY_600;

        if(entityType == 'COMPONENT_SUMMARY'){
            td.style.backgroundColor = COLORS.GREY_200;
            td.style.borderBottomWidth = '2px';
            td.style.borderBottomColor = COLORS.GREY_300;
        } else {
            td.style.backgroundColor = COLORS.GREY_100;
        }

        td.onmouseenter = () => {
            onNonAccountHover(hotInstance);
        };
    }
};

export const varianceCellRenderer = (hotInstance: Core, td: HTMLTableCellElement, row: number, column: number, prop: string|number, value: any, cellProperties: CellProperties): void => {
    const cellValue = !isNaN(Math.abs(value)) ? Math.abs(value) : '';
    Handsontable.renderers.NumericRenderer.apply(this, [hotInstance, td, row, column, prop, cellValue, cellProperties]);

    const rowData = hotInstance.getDataAtRow(row);
    const componentType = rowData[MvrTableCols.util_componentType];
    const negatedAtComponent = rowData[MvrTableCols.util_negatedAtComponent];
    const entityType = rowData[MvrTableCols.util_entityType];

    if(value != 0){
        td.style.color = getVarianceColor(value, componentType, negatedAtComponent);
    }

    if(entityType === FinancialEntityType.Account){
        td.onmouseenter = () => {
            setDetailsButtonVisible(hotInstance, row, true);
        };

        td.onmouseout = () => {
            setDetailsButtonVisible(hotInstance, row, false);
        };
    }
    else {
        td.style.backgroundColor = COLORS.GREY_100;

        if(entityType == 'COMPONENT_SUMMARY'){
            td.style.backgroundColor = COLORS.GREY_200;
            td.style.borderBottomWidth = '2px';
            td.style.borderBottomColor = COLORS.GREY_300;
        } else {
            td.style.backgroundColor = COLORS.GREY_100;
        }

        td.onmouseenter = () => {
            onNonAccountHover(hotInstance);
        };
    }
};

export const varianceNotesCellRenderer = (hotInstance: Core, td: HTMLTableCellElement, row: number, column: number, prop: string|number, value: string|number|null|undefined, cellProperties: CellProperties): void => {
    const rowData = hotInstance.getDataAtRow(row);
    const entityType = rowData[MvrTableCols.util_entityType];

    if(entityType === FinancialEntityType.Account){
        Handsontable.renderers.TextRenderer.apply(this, [hotInstance, td, row, column, prop, value, cellProperties]);

        td.onmouseenter = () => {
            setDetailsButtonVisible(hotInstance, row, true);
        };

        td.onmouseout = () => {
            setDetailsButtonVisible(hotInstance, row, false);
        };
    }
    else {
        hotInstance.setCellMeta(row, column, 'readOnly', true);
        hotInstance.setCellMeta(row, column, 'disableVisualSelection', true);

        if(entityType == 'COMPONENT_SUMMARY'){
            td.style.backgroundColor = COLORS.GREY_200;
            td.style.borderBottomWidth = '2px';
            td.style.borderBottomColor = COLORS.GREY_300;
        } else {
            td.style.backgroundColor = COLORS.GREY_100;
        }

        Handsontable.renderers.TextRenderer.apply(this, [hotInstance, td, row, column, prop, null, cellProperties]);

        td.onmouseenter = () => {
            onNonAccountHover(hotInstance);
        };
    }
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const financialEntityCellRenderer = (hotInstance: Core, td: HTMLTableCellElement, row: number, column: number, prop: string|number, value: any, cellProperties: CellProperties): void => {
    Handsontable.renderers.TextRenderer.apply(this, [hotInstance, td, row, column, prop, value, cellProperties]);

    hotInstance.setCellMeta(row, column, 'readOnly', true);
    hotInstance.setCellMeta(row, column, 'disableVisualSelection', true);

    const rowData = hotInstance.getDataAtRow(row);
    const entityDepth = rowData[MvrTableCols.util_depth];
    const entityType = rowData[MvrTableCols.util_entityType];

    const leftPadAmt = MonthlyVarianceReportConfig.ENTITY_LEVEL_LEFT_PAD * (entityDepth + 1);
    const maxEntityCellWidth = MonthlyVarianceReportConfig.ENTITY_LABEL_CELL_WIDTH;

    td.style.paddingLeft = `${leftPadAmt}px`;
    td.style.width = `${maxEntityCellWidth - leftPadAmt}px`;

    if(rowData[MvrTableCols.util_entityType] === FinancialEntityType.Account){

        const entityDetailsButton = document.createElement('button');
        entityDetailsButton.innerText = 'Details';
        entityDetailsButton.className = css.financialEntityDetails;
        td.appendChild(entityDetailsButton);

        td.onclick = () => {
            if(rowData[MvrTableCols.util_entityType] === FinancialEntityType.Account){
                hotInstance.runHooks('financialEntityDetails' as keyof Events, rowData[MvrTableCols.util_entityId]);
            }
            hotInstance.deselectCell();
        };

        td.style.position = 'relative';
        td.style.fontWeight = '400';

        td.onmouseover = () => {
            td.classList.add(css.showDetailsButton);
        };

        td.onmouseout = () => {
            td.classList.remove(css.showDetailsButton);
        };
    }
    else if(entityType !== FinancialEntityType.Account){
        td.style.color = COLORS.GREY_600;

        if(entityType == 'COMPONENT_SUMMARY'){
            td.style.backgroundColor = COLORS.GREY_200;
            td.style.fontWeight = '600';
            td.style.borderBottomWidth = '2px';
            td.style.borderBottomColor = COLORS.GREY_300;
        }
        else if(entityType == 'CATEGORY'){
            td.style.backgroundColor = COLORS.GREY_100;
            td.style.fontWeight = '500';
        }
    }
};
