import { Button } from "@zendeskgarden/react-buttons";
import { Body, Close, Footer, FooterItem, Header, Modal } from "@zendeskgarden/react-modals";
import { ReactElement } from "react";
import { Inline } from "@zendeskgarden/react-loaders";
import {Dropdown, Field, Item, Menu, Select} from "@zendeskgarden/react-dropdowns";

export interface IAddBudgetYearModal {
    selectedOptionValue?: string,
    selectedOptionLabel?: string,
    onSelectOption?: (option: string) => void,
    options?: {value: string, label: string}[],
    loading: boolean,
    onClose: () => void,
    onConfirm: () => void,
}

export default function AddBudgetYearModal(props: IAddBudgetYearModal): ReactElement {

    return (
        <Modal onClose={props.onClose}>
            <Header>
                Select Budget Season to add
            </Header>
            <Body>
                <Dropdown
                    selectedItem={props.selectedOptionValue}
                    onSelect={props.onSelectOption}
                >
                    <Field>
                        <Select>
                            {props.selectedOptionLabel ?? ""}
                        </Select>
                    </Field>
                    <Menu>
                        {props.options?.map(option => (
                            <Item key={option.value} value={option.value}>
                                {option.label}
                            </Item>
                        ))}
                    </Menu>
                </Dropdown>
            </Body>
            <Footer>
                <FooterItem>
                    <Button onClick={props.onClose} isBasic disabled={props.loading}>
                        Cancel
                    </Button>
                </FooterItem>
                <FooterItem>
                    {
                        <Button isPrimary onClick={props.onConfirm} disabled={props.loading || !props.selectedOptionValue}>
                            {
                                props.loading
                                    ? <Inline size={24} aria-label="loading"/>
                                    : <span>Add</span>
                            }
                        </Button>
                    }
                </FooterItem>
            </Footer>
            <Close aria-label="Close modal" />
        </Modal>
    );
}