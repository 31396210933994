import {HotColumn, HotTable} from "@handsontable/react";
import {CellMeta, CellProperties} from "handsontable/settings";
import React, {useEffect, useMemo, useState} from "react";
import {Dropdown, Field, Item, Menu, Select} from "@zendeskgarden/react-dropdowns";
import {ThemeProvider} from "@zendeskgarden/react-theming";

import {ViziblyTheme} from "../../../styles/zendesk-garden/ViziblyZDGTheme";
import {LICENSES} from "../../../constants/Licenses";
import {IUsePropertyDriversReturn} from "../logic/usePropertyDrivers";

import * as css from './styles/css.module.scss';
import {Col, Grid, Row} from "@zendeskgarden/react-grid";
import Handsontable from "handsontable";
import {Button, ToggleButton} from "@zendeskgarden/react-buttons";
import useEditAssumptions, {CustomDriverUnit, GROWTH_DRIVER_LABELS, IUpdateGLAccountData, OpDriverUnit, SELECTED_DRIVER_LABELS} from "../logic/useEditAssumptions";
import {Body, Close, Header, Modal} from "@zendeskgarden/react-modals";
import {VersionType} from "../../../__generated__/generated_types";
import {hotValidUserActions} from "../../../components/account-table/logic/AccountTableHelpers";
import { Inline } from "@zendeskgarden/react-loaders";


interface IBulkUpdateColumnsConfig {
    readOnly: boolean;
    value?: number | string;
}[];

interface IEditAssumptionsProps {
    pd: IUsePropertyDriversReturn;
}


export default function EditAssumptions({
    pd,
}: IEditAssumptionsProps): React.ReactElement {
    const ea = useEditAssumptions({pd});

    const [selectedDriver, setSelectedDriver] = useState<string>();
    const [tableKey, setTableKey] = useState<string>('');

    useEffect(() => {
        setTableKey(selectedDriver || '');
    }, [selectedDriver]);

    const valueColumnConfig = useMemo(() => {
        if ((selectedDriver?.includes("OpDriver") && ea.selectedOpDriverUnit === OpDriverUnit.DOLLAR)
         || (selectedDriver?.includes("Custom Driver") && ea.selectedCustomDriverUnit === CustomDriverUnit.DOLLAR)
         || selectedDriver === "Line Items") {
            return ({
                type: 'numeric',
                numericFormat: {
                    pattern: '$0,0.00',
                    culture: 'en-US'
                }
            });
        }

        // % of account drivers are percent values
        return ({
            type: 'numeric',
            numericFormat: {
                pattern: '0,0.00',
                culture: 'en-US'
            }
        });
    }, [selectedDriver, ea.selectedOpDriverUnit, ea.selectedCustomDriverUnit]);

    useEffect(() => {
        setSelectedDriver(ea.dropdownItems[0]?.dropdownTitle);
    }, [ea.dropdownItems.length]);

    const lineItemsDropdownOptions = useMemo(() => {
        interface IResultItem {
            dropdownTitle: string,
            rows: {
                displayName: string,
                values: Array<string | number | null>,
            }[],
        }
        const result: IResultItem[] = [];

        if (ea.dataByDriverTypeDraft) {
            for (const lineItemObj of ea.dataByDriverTypeDraft.worksheet) {
                result.push({
                    dropdownTitle: lineItemObj.lineItem,
                    rows: lineItemObj.drivers.map((each) => ({
                        displayName: each.accountName,
                        values: each.values,
                    })),
                });
            }
        }

        return result;
    }, [ea.dataByDriverTypeDraft]);

    useEffect(() => {
        if (selectedDriver === "Line Items" && lineItemsDropdownOptions) {
            ea.setSelectedLineItem(lineItemsDropdownOptions[0]?.dropdownTitle);
        }
    }, [lineItemsDropdownOptions.length, selectedDriver]);

    const tableData = useMemo(() => {
        if (selectedDriver) {
            if (selectedDriver === "Line Items") {
                const dataToRender = lineItemsDropdownOptions.find((each) => each.dropdownTitle === ea.selectedLineItem);
                if (dataToRender) {
                    return dataToRender.rows.map((each) => [each.displayName, ...each.values, '']);
                }
            } else {
                const dataToRender = ea.dropdownItems.find((each) => each.dropdownTitle === selectedDriver);
                if (dataToRender) {
                    return dataToRender.rows.map((each) => [each.displayName, ...each.values, '']);
                }
            }
        }
        return [];
    }, [ea.dropdownItems, lineItemsDropdownOptions, selectedDriver, ea.selectedLineItem]);

    const [bulkUpdateColumnConfig, setBulkUpdateColumnConfig] = useState<IBulkUpdateColumnsConfig[]>([]);

    useEffect(() => {
        if (!selectedDriver) {
            return;
        }

        const numberOfCells = GROWTH_DRIVER_LABELS.includes(selectedDriver) ? 1 : 12;
        const result = new Array(numberOfCells).fill(0).map((_each, index) => ({
            readOnly: pd.versionType === VersionType.Reforecast && (index < pd.reforecastStartMonth) && !GROWTH_DRIVER_LABELS.includes(selectedDriver),
            value: ea.bulkUpdateData[index] || 0,
        }));
        setBulkUpdateColumnConfig(result);
    }, [ea.bulkUpdateData, pd.reforecastStartMonth, pd.versionType, selectedDriver, tableData]);

    const handleSelect = (item: string) => {
        setSelectedDriver(item);
    };

    const onGLAccountCellChange = (changes: Handsontable.CellChange[]|null, _source: Handsontable.ChangeSource) => {
        if (changes) {
            const updateGLAccountDataInp: IUpdateGLAccountData[] = [];

            for (const change of changes) {
                const [row, column, oldValue, newValue] = change;

                let parsedNewValue = newValue;

                /**
                 * Fail-safe for invalid inputs
                 * The reason this defaults to the old value and not 0 is to avoid making a setPropertyDrivers
                 * API call if the user enters something invalid and clicks 'Save'
                 * Defaulting to the old value won't make the API call
                */
                if (typeof newValue !== "number") {
                    parsedNewValue = oldValue;
                }

                const index = typeof column === "number" ? column : parseInt(column);

                updateGLAccountDataInp.push({
                    row,
                    column: index,
                    newValue: parsedNewValue,
                });
            }

            ea.updateGLAccountData(updateGLAccountDataInp, selectedDriver);
        }
    };

    const onBulkUpdateCellChange = (changes: Handsontable.CellChange[]|null, _source: Handsontable.ChangeSource) => {
        if (changes) {
            const tempBulkUpdateData = ea.bulkUpdateData.slice();
            for (const change of changes) {
                const [_row, column, _oldValue, newValue] = change;

                const index = typeof column === "number" ? column : parseInt(column);

                tempBulkUpdateData[index] = newValue;
            }

            ea.setBulkUpdateData(tempBulkUpdateData);
        }
    };

    const handleClickOnCancel = () => {
        pd.setIsConfigureModelingMethodsModalOpen(false);
    };

    const handleClickOnSave = () => {
        if (ea.dataByDriverType && ea.dataByDriverTypeDraft) {
            pd.saveFxBarsInBulk(ea.dataByDriverType, ea.dataByDriverTypeDraft);

        }
    };

    const handleApplyBulkUpdate = () => {
        if (selectedDriver) {
            ea.applyBulkUpdate({
                selectedDriver,
                selectedLineItem: ea.selectedLineItem,
                selectedOpDriverUnit: ea.selectedOpDriverUnit,
                selectedCustomDriverUnit: ea.selectedCustomDriverUnit,
                tableData,
            });
        }
    };

    return (
        <ThemeProvider theme={ViziblyTheme}>
            <Grid className={`${css.assumptionsWrapper} ${css.noPadding}`}>
                <Row className={`${css.tableTitleWrapper} ${css.noMargin}`}>
                    <Col className={css.tableTitle}>
                        Modeling Methods
                    </Col>
                    <Col className={css.forecastHeaderCol}>
                        <Row className={css.forecastPeriod}>
                            Forecast Period
                        </Row>
                        <Row className={css.year}>
                            {pd.versionType.toLowerCase()} {pd.year}
                        </Row>
                    </Col>
                </Row>
                {ea.dropdownItems.length === 0
                    ? (
                        <Row className={css.noAssumptionsText}>No assumptions data to edit</Row>
                    )
                    : (selectedDriver ? (
                        <>
                            <Row className={css.dropdownWrapper}>
                                <Dropdown
                                    selectedItem={selectedDriver}
                                    onSelect={item => handleSelect(item)}
                                >
                                    <Field className={css.dropdownField}>
                                        <Select className={css.dropdownSelect}>
                                            <div className={css.dummyFlexCol}>
                                                <div className={css.dropdownLabel}>
                                                    Modeling method
                                                </div>
                                                <div className={css.selectedItem}>
                                                    {selectedDriver}
                                                </div>
                                            </div>
                                        </Select>
                                    </Field>
                                    <Menu>
                                        {ea.dropdownItems.map((dropdownItem) => (
                                            <Item
                                                key={dropdownItem.dropdownTitle}
                                                value={dropdownItem.dropdownTitle}
                                            >
                                                {dropdownItem.dropdownTitle}
                                            </Item>
                                        ))}
                                    </Menu>
                                </Dropdown>
                                {selectedDriver.includes("OpDriver") && (
                                    <div className={css.opDriverUnitBtnWrapper}>
                                        <ToggleButton
                                            isPressed={ea.selectedOpDriverUnit === OpDriverUnit.DOLLAR}
                                            onClick={() => ea.setSelectedOpDriverUnit(OpDriverUnit.DOLLAR)}
                                        >
                                            $ per {selectedDriver.match(/\((.*?)\)/)?.[1]}
                                        </ToggleButton>
                                        <ToggleButton
                                            isPressed={ea.selectedOpDriverUnit === OpDriverUnit.PERCENTAGE}
                                            onClick={() => ea.setSelectedOpDriverUnit(OpDriverUnit.PERCENTAGE)}
                                        >
                                            % of {selectedDriver.match(/\((.*?)\)/)?.[1]}
                                        </ToggleButton>
                                    </div>
                                )}
                                {selectedDriver.includes("Custom Driver") && (
                                    <div className={css.opDriverUnitBtnWrapper}>
                                        <ToggleButton
                                            isPressed={ea.selectedCustomDriverUnit === CustomDriverUnit.DOLLAR}
                                            onClick={() => ea.setSelectedCustomDriverUnit(CustomDriverUnit.DOLLAR)}
                                        >
                                            $ per {selectedDriver.match(/\((.*?)\)/)?.[1]}
                                        </ToggleButton>
                                        <ToggleButton
                                            isPressed={ea.selectedCustomDriverUnit === CustomDriverUnit.PERCENTAGE}
                                            onClick={() => ea.setSelectedCustomDriverUnit(CustomDriverUnit.PERCENTAGE)}
                                        >
                                            % of {selectedDriver.match(/\((.*?)\)/)?.[1]}
                                        </ToggleButton>
                                        <ToggleButton
                                            isPressed={ea.selectedCustomDriverUnit === CustomDriverUnit.COUNT}
                                            onClick={() => ea.setSelectedCustomDriverUnit(CustomDriverUnit.COUNT)}
                                        >
                                            # of {selectedDriver.match(/\((.*?)\)/)?.[1]}
                                        </ToggleButton>
                                    </div>
                                )}
                                {selectedDriver === "Line Items" && (
                                    <Dropdown
                                        selectedItem={ea.selectedLineItem}
                                        onSelect={item => ea.setSelectedLineItem(item)}
                                    >
                                        <Field className={css.dropdownField}>
                                            <Select className={css.dropdownSelect}>
                                                <div className={css.dropdownLabel}>
                                                    Line Items
                                                </div>
                                                <div className={css.selectedItem}>
                                                    {ea.selectedLineItem}
                                                </div>
                                            </Select>
                                        </Field>
                                        <Menu>
                                            {lineItemsDropdownOptions.map((each) => (
                                                <Item
                                                    key={each.dropdownTitle}
                                                    value={each.dropdownTitle}
                                                >
                                                    {each.dropdownTitle}
                                                </Item>
                                            ))}
                                        </Menu>
                                    </Dropdown>
                                )}
                            </Row>
                            {selectedDriver && (
                                <div className={css.updateDriverHoTWrapper}>
                                <HotTable
                                    id="pd-assumptions"
                                    data={tableData}
                                    licenseKey={LICENSES.HandsOnTable}
                                    afterGetColHeader={(_column: number, th: HTMLTableHeaderCellElement): void => {
                                        th.classList.add(css.headerRowCell);
                                        if (_column === 0) {
                                            th.classList.add(css.noBorderLeft);
                                        }
                                        if (_column === 13) {
                                            th.classList.add(css.noBorderRight);
                                        }
                                        if (_column > 0) {
                                            th.classList.add(css.headerRowRightAlign);
                                        }
                                    }}
                                    cells={function (this: CellProperties, _row: number, col: number): CellMeta {
                                        const classNames = [];
                                        if (col === 0) {
                                            classNames.push(css.accountNameCell);
                                        }
                                        if (pd.versionType === VersionType.Reforecast) {
                                            if ((col > 0 && col <= pd.reforecastStartMonth && !GROWTH_DRIVER_LABELS.includes(selectedDriver)) || col === 13) {
                                                classNames.push(css.staticCell);
                                            }
                                            if ((col > pd.reforecastStartMonth || col > 0 && GROWTH_DRIVER_LABELS.includes(selectedDriver)) && col < 13) {
                                                classNames.push(css.editableAssumptionsCell);
                                            }
                                        } else {
                                            if (col === 0 || col === 13) {
                                                classNames.push(css.staticCell);
                                            } else {
                                                classNames.push(css.editableAssumptionsCell);
                                            }
                                        }
                                        if (col > 0 && col < 13) {
                                            classNames.push(css.dataCell);
                                        }
                                        if (col === 13) {
                                            classNames.push(css.averageCell);
                                        }

                                        if (classNames.length) {
                                            return {
                                                className: classNames.join(" "),
                                            };
                                        }

                                        return this;
                                    }}
                                    afterChange={(changes: Handsontable.CellChange[]|null, source: Handsontable.ChangeSource) => {
                                        if(!changes || source == 'loadData'){
                                            return;
                                        }
                                        onGLAccountCellChange(changes, source);
                                    }}
                                    beforeUndoStackChange={(_doneActions, source) => {
                                        if (source && !hotValidUserActions.includes(source)) {
                                            return false;
                                        }
                                    }}
                                    width="100%"
                                    key={tableKey}
                                >
                                    {ea.getColumnsConfig(selectedDriver).map((column) => (
                                        <HotColumn
                                            key={column.title}
                                            width="100%"
                                            title={column.title}
                                            readOnly={column.readOnly}
                                            {...valueColumnConfig}
                                            className={css.headerRowCell}
                                        />
                                    ))}
                                </HotTable>
                            </div>
                            )}
                            <div className={css.bulkUpdateHoTWrapper}>
                                <Row
                                    className={`${css.footer} ${css.assumptnBulkUpdateWrapper} ${GROWTH_DRIVER_LABELS.includes(selectedDriver) && css.growthFooter}`}
                                >
                                    <div className={css.assumptnBulkUpdateText}>
                                        Bulk Update {tableData.length} GL Account{tableData.length === 1 ? '' : 's'}
                                    </div>
                                    <HotTable
                                        id="pd-assumptions-bulk-update"
                                        data={[ea.bulkUpdateData]}
                                        licenseKey={LICENSES.HandsOnTable}
                                        afterGetColHeader={(_column: number, th: HTMLTableHeaderCellElement): void => {
                                            th.classList.add(css.headerRowCell);
                                            if (_column === 0) {
                                                th.classList.add(css.noBorderLeft);
                                            }
                                            if (_column === 11) {
                                                th.classList.add(css.noBorderRight);
                                            }
                                            if (_column > 0) {
                                                th.classList.add(css.headerRowRightAlign);
                                            }
                                        }}
                                        cells={function (this: CellProperties, _row: number, col: number): CellMeta {
                                            const classNames = [css.dataCell];
                                            if (GROWTH_DRIVER_LABELS.includes(selectedDriver)) {
                                                classNames.push(css.wideDataCell);
                                            }
                                            if ((pd.versionType === VersionType.Reforecast) && col < pd.reforecastStartMonth && !GROWTH_DRIVER_LABELS.includes(selectedDriver)) {
                                                classNames.push(css.staticCell);
                                            }
                                            if ((pd.versionType === VersionType.Budget) || col >= pd.reforecastStartMonth) {
                                                classNames.push(css.editableAssumptionsCell);
                                            }
                                            return {
                                                className: classNames.join(" "),
                                            };
                                        }}
                                        afterChange={(changes: Handsontable.CellChange[]|null, source: Handsontable.ChangeSource) => {
                                            if(!changes || source == 'loadData'){
                                                return;
                                            }
                                            onBulkUpdateCellChange(changes, source);
                                        }}
                                        beforeUndoStackChange={(_doneActions, source) => {
                                            if (source && !hotValidUserActions.includes(source)) {
                                                return false;
                                            }
                                        }}
                                        width={888}
                                        height={55}
                                        key={`${tableKey}-bulk`}
                                    >
                                        {bulkUpdateColumnConfig.map((column, index) => (
                                            <HotColumn
                                                key={`${index}-${column.value}`}
                                                title="" readOnly={column.readOnly} {...valueColumnConfig}
                                            />
                                        ))}
                                    </HotTable>
                                    <div className={css.applyAssumptnBlkUpdateBtnWrapper}>
                                        <Button onClick={handleApplyBulkUpdate} className={css.applyAssumptnBlkUpdateBtn}>
                                            Apply
                                        </Button>
                                    </div>
                                </Row>
                            </div>
                        </>
                    ): <></>)}
                <Row justifyContent="end" className={css.actionsRow}>
                    <Col md="auto">
                        <Button isBasic onClick={handleClickOnCancel}>
                            Cancel
                        </Button>
                    </Col>
                    <Col md="auto">
                        <Button isPrimary onClick={handleClickOnSave} disabled={pd.isSaving}>
                            {
                                pd.isSaving
                                ? <Inline size={24} aria-label="loading"/>
                                : "Save"
                            }
                        </Button>
                    </Col>
                </Row>
            </Grid>
            {/*
              * Much easier to show a popup when the user tries to click "save" when there's no dirty state
              * than live-evaluating if the 'save' btn should be enabled
            */}
            {pd.isNoChangesModalOpen && (
                <Modal onClose={() => pd.setIsNoChangesModalOpen(false)}>
                    <Header>
                        No changes to save
                    </Header>
                    <Body>
                        There are no changes to save to the selected GL accounts
                    </Body>
                    <Close aria-label="Close modal"/>
                </Modal>
            )}
        </ThemeProvider>
    );
}
