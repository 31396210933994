import * as css from "./styles/css.module.scss";
import React, { Children, ReactElement, ReactNode, forwardRef, createRef, cloneElement } from "react";

export interface INotePaneProps {
    children: ReactElement[]|ReactElement;
    className?: string,
    isVisible?:boolean,
}

interface HeaderProps {
    children: ReactElement[]|ReactElement;
    onCopyClick?: () => void;
    className?: string;
}

const HeaderTest = forwardRef<HTMLDivElement, HeaderProps>((props, ref) => {
    let copyNoteTrigger = <></>;
    let label = <></>;

    const copyTriggerRef = createRef();

    if(props.children){
        Children.forEach(props.children, (child: React.ReactElement<any, string | React.JSXElementConstructor<any>>) => {
            switch(child.type){
                case Label: {
                    label = child;
                    break;
                }
                case CopyNoteTrigger: {
                    copyNoteTrigger = child;
                    break;
                }
                case CopyNoteTriggerTest: {
                    copyNoteTrigger = cloneElement(child, {ref: copyTriggerRef});
                    break;
                }
            }
        });
    }

    return (
            <div ref={ref} className={props.className ? `${css.header} ${props.className}` : css.header}>
                {label}
                {copyNoteTrigger}
            </div>
    );
});

function Header({ children, className }: HeaderProps): ReactElement {
    let copyNoteTrigger = <></>;
    let label = <></>;

    const copyTriggerRef = createRef();

    if(children){
        Children.forEach(children, child => {
            switch(child.type){
                case Label: {
                    label = child;
                    break;
                }
                case CopyNoteTrigger: {
                    copyNoteTrigger = child;
                    break;
                }
                case CopyNoteTriggerTest: {
                    copyNoteTrigger = cloneElement(child, {ref: copyTriggerRef});
                    break;
                }
            }
        });
    }

    return (
            <div className={className ? `${css.header} ${className}` : css.header}>
                {label}
                {copyNoteTrigger}
            </div>
    );
}

interface ILabelProps {
    children: ReactNode[]|ReactNode;
    className?: string;
}

function Label({ children, className }: ILabelProps): ReactElement {
    return (
            <span className={className ? `${css.headerText} ${className}` : css.headerText}>
                {children}
            </span>
    );
}

interface CopyNoteTriggerTestProps {
    children: ReactNode[]|ReactNode;
    className?: string;
}

const CopyNoteTriggerTest = forwardRef<HTMLDivElement, CopyNoteTriggerTestProps>((props, ref) => {
    return (
            <div
                    ref={ref}
                    className={props.className ? `${css.headerCopyTrigger} ${props.className}` : css.header}
            >
                {props.children}
            </div>
    );
});

interface CopyNoteTriggerProps {
    children: ReactNode[]|ReactNode;
    className?: string;
}

function CopyNoteTrigger({ children, className }: CopyNoteTriggerProps): ReactElement {
    return (
            <div
                    className={className ? `${css.headerCopyTrigger} ${className}` : css.header}
                    onClick={() => {
                        console.log('CopyNoteTrigger clicked');
                    }}
            >
                {children}
            </div>
    );
}

interface ContentProps {
    children: ReactElement[]|ReactElement|string|undefined;
    className?: {
        body?: string,
        content?: string,
        contentText?: string,
    };
}

function Note({ children, className }: ContentProps): ReactElement {
    return (
            <div className={className?.body ? `${css.body} ${className?.body}` : css.body}>
                <div className={className?.content ? `${css.content} ${className?.content}` : css.content}>
                    <span className={className?.contentText ? `${css.contentText} ${className?.contentText}` : css.contentText}>
                        {children}
                    </span>
                </div>
            </div>
    );
}

export function NotePane(props: INotePaneProps): ReactElement {

    let header = <></>;
    let noteBody = <></>;

    const copyClicked = ():void => {
        alert('copy clicked!');
    };

    Children.forEach(props.children, child => {
        switch(child.type){
            case Header: {
                header = child;
                break;
            }
            case HeaderTest: {
                header = cloneElement(child, {onCopyClick: copyClicked});
                break;
            }
            case Note: {
                // noteBody = child;
                noteBody = child;
                break;
            }
        }
    });

    return (
            <div className={css.container} style={props.isVisible === false ? {display: 'none'} : {}}>
                {header}
                {noteBody}
            </div>
    );
}


NotePane.Label = Label;
NotePane.CopyNoteTrigger = CopyNoteTrigger;
NotePane.CopyNoteTriggerTest = CopyNoteTriggerTest;
NotePane.Header = Header;
NotePane.Note = Note;
