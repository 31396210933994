const TRIANGLE_LEG = 12;
const TRIANGLE_CLICKABLE_SIZE = 16;

export function drawTriangle(ctx: CanvasRenderingContext2D, x: number, y: number, w: number, _h: number) {
    ctx.beginPath();
    ctx.strokeStyle = "#832161";
    ctx.fillStyle = "#832161";
    ctx.moveTo(x + w, y);
    ctx.lineTo(x + w, y + TRIANGLE_LEG);
    ctx.lineTo(x + w - TRIANGLE_LEG, y);
    ctx.closePath();
    ctx.stroke();
    ctx.fill();
}

export function isInsideSquare(tx: number, ty: number, x: number, y: number, w: number, _h: number) {
    return x + w - TRIANGLE_CLICKABLE_SIZE <= tx && tx <= x + w &&
           y <= ty && ty <= y + TRIANGLE_CLICKABLE_SIZE;
}
