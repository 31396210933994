import GC from "@grapecity/spread-sheets";
import { SJSLayoutHelper, SJSLayoutHelperConfig } from "../shared/SJSLayoutHelper";
import { SJSCfg } from "../shared/SJSConfig";
import { SJSSheetStyles as SJSS } from "../shared/SJSSheetStyles";
import { AnalystStyles } from "../../../pages/analyst/AnalystStyles";
import { TwoYearsConfig as TWO_YR_CFG } from "./helpers/TwoYearsConfig";
import { TwoYearsTableData } from "./helpers/types";
import { TwoYearsColId } from "./helpers/enums";
import { AccountIconSpec, ExpandableIconSpec } from "./helpers/constants";
import { ToggleIconCell } from "../../../components/spreadsheet/spreadjs-custom-cell-types/ToggleIconCell";
import React from "react";
import { addYearDataArrays } from "../../../pages/unit-level-modeling/helpers/utils";
import { FinancialEntityType } from "../../../__generated__/generated_types";

export interface TwoYearsLayoutConfig extends SJSLayoutHelperConfig {
    year: number,
    firstReforecastMonth: number,
    reportLoadProgress?: (progressAmt: number) => void,
    monthColOffset?: number,
}

export enum AddedFinancialEntityTypes {
    Property = 'PROPERTY',
    CategorySummary = 'CATEGORY_SUMMARY',
    ComponentSummary = 'COMPONENT_SUMMARY',
    AccountSummary = 'ACCOUNT_SUMMARY',
}

export default class TwoYearsLayout extends SJSLayoutHelper {
    protected readonly _firstReforecastMonth;

    private _year: number;
    private _entityIdRows: string[] = [];

    protected _monthColOffset = 0;

    protected _reportLoadProgress?: (progressAmt: number) => void;

    protected readonly _categoryBaseStyle = SJSS.FINANCIAL_ENTITY_LABEL.clone();
    protected _categoryLevelStyles: GC.Spread.Sheets.Style[] = [];

    protected readonly _accountBaseStyle = SJSS.FINANCIAL_ENTITY_LABEL.clone();
    protected _accountLevelStyles: GC.Spread.Sheets.Style[] = [];

    protected readonly _categorySummaryBaseStyle = SJSS.CTGY_SUMMARY_LABEL.clone();
    protected _categorySummaryLevelStyles: GC.Spread.Sheets.Style[] = [];

    protected readonly _propertyBaseStyle = SJSS.FINANCIAL_ENTITY_LABEL.clone();
    protected _propertyLevelStyles: GC.Spread.Sheets.Style[] = [];

    constructor({
                    name,
                    ssapi,
                    template,
                    year,
                    firstReforecastMonth,
                    reportLoadProgress,
                    monthColOffset,
                }: TwoYearsLayoutConfig) {
        super({ name, ssapi, template });

        this._year = year;
        this._firstReforecastMonth = firstReforecastMonth;

        this._monthColOffset = monthColOffset ?? 0;

        if(reportLoadProgress){
            this._reportLoadProgress = reportLoadProgress;
        }

        const { spread, sheet } = this.initSheet();
        ssapi.suspend();

        sheet.options.frozenlineColor = AnalystStyles.CELL_BORDER_COLOR;
        sheet.frozenRowCount(TWO_YR_CFG.FIRST_DATA_ROW);
        sheet.frozenColumnCount(TWO_YR_CFG.LABEL_COL + this._monthColOffset + 1);

        // sheet.showRowOutline(true);
        // sheet.options.rowHeaderVisible = true;

        sheet.setColumnWidth(TWO_YR_CFG.LABEL_COL, SJSCfg.FINANCIAL_ENTITY_COL_W);

        // Setup Named Styles
        sheet.addNamedStyle(SJSS.ENTITY_COL_LABEL);
        sheet.addNamedStyle(SJSS.ACTUAL_CELL);
        sheet.addNamedStyle(SJSS.LAST_ACTUAL_CELL);
        sheet.addNamedStyle(SJSS.EDITABLE_CELL_BLUE);
        sheet.addNamedStyle(SJSS.REFORECAST_DRIVER_CELL);
        sheet.addNamedStyle(SJSS.TOTALS_DATA);
        sheet.addNamedStyle(SJSS.BUDGET_DRIVER_CELL);
        sheet.addNamedStyle(SJSS.SECTION_VALUE_OPEN_LAST_ACTUAL_CELL);
        sheet.addNamedStyle(SJSS.TOTALS_COLUMN_DATA_CELL_OPEN);
        sheet.addNamedStyle(SJSS.YEAR_REFORECAST_HEADER_ROW_1);
        sheet.addNamedStyle(SJSS.YEAR_REFORECAST_HEADER_ROW_2);
        sheet.addNamedStyle(SJSS.YEAR_REFORECAST_HEADER_ROW_3);
        sheet.addNamedStyle(SJSS.YEAR_ACTUAL_HEADER_ROW_1);
        sheet.addNamedStyle(SJSS.YEAR_LAST_ACTUAL_HEADER_ROW_1);
        sheet.addNamedStyle(SJSS.YEAR_ACTUAL_HEADER_ROW_2);
        sheet.addNamedStyle(SJSS.YEAR_LAST_ACTUAL_HEADER_ROW_2);
        sheet.addNamedStyle(SJSS.YEAR_ACTUAL_HEADER_ROW_3);
        sheet.addNamedStyle(SJSS.YEAR_LAST_ACTUAL_HEADER_ROW_3);
        sheet.addNamedStyle(SJSS.YEAR_TOTALS_HEADER_ROW_1);
        sheet.addNamedStyle(SJSS.YEAR_TOTALS_HEADER_ROW_2);
        sheet.addNamedStyle(SJSS.YEAR_TOTALS_HEADER_ROW_3);
        sheet.addNamedStyle(SJSS.COMP_SUMMARY_LABEL);
        sheet.addNamedStyle(SJSS.COMP_SUMMARY_ACTUAL);
        sheet.addNamedStyle(SJSS.COMP_SUMMARY_LAST_ACTUAL);
        sheet.addNamedStyle(SJSS.COMP_SUMMARY_REFORECAST_TOTAL);
        sheet.addNamedStyle(SJSS.CTGY_SUMMARY_ACTUAL);
        sheet.addNamedStyle(SJSS.CTGY_SUMMARY_LAST_ACTUAL);
        sheet.addNamedStyle(SJSS.CTGY_SUMMARY_REFORECAST_TOTAL);

        this.applyYearHeaderTreatment(
            [year, year + 1],
            ['TOTAL', 'TOTAL'],
            { row: TWO_YR_CFG.HEADER_ROW, col: TWO_YR_CFG.LABEL_COL + this._monthColOffset },
            TWO_YR_CFG.MAIN_TAB_NAME,
            firstReforecastMonth,
        );

        this.setupCellHighlightBorders();

        // Hide rows and columns outside the visible area
        let thisRow = -1;
        while (++thisRow < SJSCfg.FIRST_VISIBLE_ROW) {
            sheet.setRowVisible(thisRow, false);
        }

        let thisCol = -1;
        while (++thisCol < SJSCfg.FIRST_VISIBLE_COL) {
            sheet.setColumnVisible(thisCol, false);
        }

        // Hide utility columns
        const colCount = sheet.getColumnCount();
        for (let col = TWO_YR_CFG.BUDGET_TOTALS_COL + 1; col < colCount; col++) {
            sheet.setColumnVisible(col, false);
        }

        ssapi.resume();
    }

    private setupCellHighlightBorders(): void {
        this.setCellHighlightBorderSpec({
            startCol: this.dataColOffset(TwoYearsColId.ITEM_TITLE),
            endCol: this.dataColOffset(TwoYearsColId.ITEM_TITLE),
            color: 'transparent',
        });
        this.setCellHighlightBorderSpec({
            startCol: this.dataColOffset(TwoYearsColId.REFO_MONTH_0),
            endCol: this.dataColOffset(TwoYearsColId.REFO_MONTH_0 + this._firstReforecastMonth - 1),
            color: SJSS.SELECTED_STATIC_BORDER_COLOR,
        });
        this.setCellHighlightBorderSpec({
            startCol: this.dataColOffset(TwoYearsColId.REFO_MONTH_0 + this._firstReforecastMonth),
            endCol: this.dataColOffset(TwoYearsColId.REFO_MONTH_11),
            color: SJSS.SELECTED_EDITABLE_BORDER_COLOR,
        });
        this.setCellHighlightBorderSpec({
            startCol: this.dataColOffset(TwoYearsColId.REFO_TOTALS),
            endCol: this.dataColOffset(TwoYearsColId.REFO_TOTALS),
            color: SJSS.SELECTED_STATIC_BORDER_COLOR,
        });
        this.setCellHighlightBorderSpec({
            startCol: this.dataColOffset(TwoYearsColId.BUDGET_MONTH_0),
            endCol: this.dataColOffset(TwoYearsColId.BUDGET_MONTH_11),
            color: SJSS.SELECTED_EDITABLE_BORDER_COLOR,
        });
        this.setCellHighlightBorderSpec({
            startCol: this.dataColOffset(TwoYearsColId.BUDGET_TOTALS),
            endCol: this.dataColOffset(TwoYearsColId.BUDGET_TOTALS),
            color: SJSS.SELECTED_STATIC_BORDER_COLOR,
        });
    }

    static TwoYearsColOffset = (col: number): number => col - TwoYearsColId.ITEM_TITLE;
    static TwoYearsRowOffset = (row: number): number => row + TWO_YR_CFG.FIRST_DATA_ROW;

    protected reportLoadProgress(progressAmt: number): void {
        if(this._reportLoadProgress){
            this._reportLoadProgress(progressAmt);
        }
    }

    protected applyDataCellStylesToRow = (row: number, sheet: GC.Spread.Sheets.Worksheet, rowCount = 1): void => {
        // console.log(`applyDefaultCellStyleToRow(${row})`);
        // Actual Datacells
        sheet.getRange(
            row,
            TWO_YR_CFG.FIRST_MONTH_COL,
            rowCount,
            this._firstReforecastMonth - 1, // all data columns
        ).setStyleName(
            'SJSS.ACTUAL_CELL'
        );

        // Last Actual Datacell
        sheet.getRange(
            row,
            TWO_YR_CFG.FIRST_MONTH_COL + this._firstReforecastMonth - 1,
            rowCount,
            1
        ).setStyleName(
            'SJSS.LAST_ACTUAL_CELL'
        );

        // Budget Datacells
        sheet.getRange(
            row,
            TWO_YR_CFG.FIRST_MONTH_COL + this._firstReforecastMonth,
            rowCount,
            25 - this._firstReforecastMonth, // all data columns
        ).setStyleName(
            'SJSS.ACTUAL_CELL'
        );

        // Reforecast Year Totals Cells
        sheet.getRange(
            row,
            TWO_YR_CFG.TOTALS_COL,
            rowCount,
            1
        ).setStyleName(
            'SJSS.TOTALS_DATA'
        );

        // Budget Year Totals Cells
        sheet.getRange(
            row,
            TWO_YR_CFG.BUDGET_TOTALS_COL,
            rowCount,
            1
        ).setStyle(
            SJSS.TOTALS_DATA
        );
    };
    protected applyOpenedStateStylesToRow = (row: number, sheet: GC.Spread.Sheets.Worksheet): void => {
        console.log(row, sheet);
    };

    protected applyDefaultCellStyles = (rowCount: number, sheet: GC.Spread.Sheets.Worksheet): void => {
        // Actual Datacells
        sheet.getRange(
            TWO_YR_CFG.FIRST_DATA_ROW,
            TWO_YR_CFG.FIRST_MONTH_COL,
            rowCount - TWO_YR_CFG.FIRST_DATA_ROW,
            this._firstReforecastMonth - 1, // all data columns
        ).setStyle(SJSS.ACTUAL_CELL);

        // Last Actual Datacell
        sheet.getRange(
            TWO_YR_CFG.FIRST_DATA_ROW,
            TWO_YR_CFG.FIRST_MONTH_COL + this._firstReforecastMonth - 1,
            rowCount - TWO_YR_CFG.FIRST_DATA_ROW,
            1, // all data columns
        ).setStyle(SJSS.LAST_ACTUAL_CELL);

        // Budget Datacells
        sheet.getRange(
            TWO_YR_CFG.FIRST_DATA_ROW,
            TWO_YR_CFG.FIRST_MONTH_COL + this._firstReforecastMonth,
            rowCount - TWO_YR_CFG.FIRST_DATA_ROW,
            25 - this._firstReforecastMonth, // all data columns
        ).setStyle(SJSS.ACTUAL_CELL);

        // Reforecast Year Totals Cells
        sheet.getRange(
            TWO_YR_CFG.FIRST_DATA_ROW,
            TWO_YR_CFG.TOTALS_COL,
            rowCount - TWO_YR_CFG.FIRST_DATA_ROW,
            1,
        ).setStyle(SJSS.TOTALS_DATA);

        // Budget Year Totals Cells
        sheet.getRange(
            TWO_YR_CFG.FIRST_DATA_ROW,
            TWO_YR_CFG.BUDGET_TOTALS_COL,
            rowCount - TWO_YR_CFG.FIRST_DATA_ROW,
            1,
        ).setStyle(SJSS.TOTALS_DATA);
    };

    public applyComponentSummaryRowStyle = (row: number, sheet: GC.Spread.Sheets.Worksheet): void => {

        sheet.setStyle(row, TWO_YR_CFG.LABEL_COL, SJSS.COMP_SUMMARY_LABEL);
        sheet.getRange(
            row,
            TWO_YR_CFG.FIRST_MONTH_COL,
            1,
            this._firstReforecastMonth - 1, // all data columns
        ).setStyle(SJSS.COMP_SUMMARY_ACTUAL);

        // Last Actual Datacell
        sheet.getCell(
            row,
            TWO_YR_CFG.FIRST_MONTH_COL + this._firstReforecastMonth - 1
        ).setStyle(SJSS.COMP_SUMMARY_LAST_ACTUAL);

        // Reforecast Datacells and Totals Cell
        sheet.getRange(
            row,
            TWO_YR_CFG.FIRST_MONTH_COL + this._firstReforecastMonth,
            1,
            TWO_YR_CFG.TOTALS_COL - this._firstReforecastMonth - TWO_YR_CFG.FIRST_MONTH_COL, // all data columns
        ).setStyle(SJSS.COMP_SUMMARY_ACTUAL);
        sheet.setStyle(row, TWO_YR_CFG.TOTALS_COL, SJSS.COMP_SUMMARY_REFORECAST_TOTAL);

        sheet.getRange(
            row,
            TWO_YR_CFG.TOTALS_COL + 1,
            1,
            TWO_YR_CFG.TOTALS_COL + 12, // all data columns
        ).setStyle(SJSS.COMP_SUMMARY_ACTUAL);
        sheet.setStyle(row, TWO_YR_CFG.BUDGET_TOTALS_COL, SJSS.COMP_SUMMARY_REFORECAST_TOTAL);
    };

    protected applyCategorySummaryRowStyle = (row: number, sheet: GC.Spread.Sheets.Worksheet, depth = 0): void => {

        const ctgyLabelStyle = this.getEntityLabelStyle(AddedFinancialEntityTypes.CategorySummary, depth);

        sheet.setStyle(row, TWO_YR_CFG.LABEL_COL, ctgyLabelStyle);
        sheet.getRange(
            row,
            TWO_YR_CFG.FIRST_MONTH_COL,
            1,
            this._firstReforecastMonth - 1, // all data columns
        ).setStyle(SJSS.CTGY_SUMMARY_ACTUAL);

        // Last Actual Datacell
        sheet.getRange(
            row,
            TWO_YR_CFG.FIRST_MONTH_COL + this._firstReforecastMonth - 1,
            1,
            1, // all data columns
        ).setStyle(SJSS.CTGY_SUMMARY_LAST_ACTUAL);

        // Reforecast Datacells
        sheet.getRange(
            row,
            TWO_YR_CFG.FIRST_MONTH_COL + this._firstReforecastMonth,
            1,
            TWO_YR_CFG.TOTALS_COL - this._firstReforecastMonth - TWO_YR_CFG.FIRST_MONTH_COL, // all data columns
        ).setStyle(SJSS.CTGY_SUMMARY_ACTUAL);

        // Reforecast Total cell
        sheet.setStyle(row, TWO_YR_CFG.TOTALS_COL, SJSS.CTGY_SUMMARY_REFORECAST_TOTAL);

        sheet.getRange(
            row,
            TWO_YR_CFG.TOTALS_COL + 1,
            1,
            TWO_YR_CFG.TOTALS_COL + 12, // all data columns
        ).setStyle(SJSS.CTGY_SUMMARY_ACTUAL);
        sheet.setStyle(row, TWO_YR_CFG.BUDGET_TOTALS_COL, SJSS.CTGY_SUMMARY_REFORECAST_TOTAL);
    };

    protected getEntityLabelStyle(type: FinancialEntityType|AddedFinancialEntityTypes, depth: number): GC.Spread.Sheets.Style {
        let returnStyle = SJSS.FINANCIAL_ENTITY_LABEL;

        // TODO: DRY this up
        switch (type) {
            case FinancialEntityType.Category: {
                let entityStyle = this._categoryLevelStyles[depth];
                if(!entityStyle){
                    const newStyle = this._categoryBaseStyle.clone();
                    newStyle.cellPadding = `0 8 0 ${TWO_YR_CFG.LABEL_BASE_INDENT + (TWO_YR_CFG.LABEL_LEVEL_INDENT * depth)}`;
                    this._categoryLevelStyles[depth] = newStyle;

                    entityStyle = this._categoryLevelStyles[depth];
                }
                returnStyle = entityStyle as GC.Spread.Sheets.Style;
                break;
            }

            case AddedFinancialEntityTypes.CategorySummary: {
                let entityStyle = this._categorySummaryLevelStyles[depth];
                if(!entityStyle){
                    const newStyle = this._categorySummaryBaseStyle.clone();
                    newStyle.cellPadding = `0 8 0 ${TWO_YR_CFG.LABEL_BASE_INDENT + (TWO_YR_CFG.LABEL_LEVEL_INDENT * depth)}`;
                    this._categorySummaryLevelStyles[depth] = newStyle;

                    entityStyle = this._categorySummaryLevelStyles[depth];
                }
                returnStyle = entityStyle as GC.Spread.Sheets.Style;
                break;
            }

            case FinancialEntityType.Account: {
                let entityStyle = this._accountLevelStyles[depth];
                if(!entityStyle){
                    const newStyle = this._accountBaseStyle.clone();
                    newStyle.cellPadding = `0 8 0 ${TWO_YR_CFG.LABEL_BASE_INDENT + (TWO_YR_CFG.LABEL_LEVEL_INDENT * depth)}`;
                    this._accountLevelStyles[depth] = newStyle;
                    entityStyle = this._accountLevelStyles[depth];
                }
                returnStyle = entityStyle as GC.Spread.Sheets.Style;
                break;
            }

            case AddedFinancialEntityTypes.Property: {
                let entityStyle = this._propertyLevelStyles[depth];
                if(!entityStyle){
                    const newStyle = this._propertyBaseStyle.clone();
                    newStyle.cellPadding = `0 8 0 ${TWO_YR_CFG.LABEL_BASE_INDENT + (TWO_YR_CFG.LABEL_LEVEL_INDENT * depth)}`;
                    this._propertyLevelStyles[depth] = newStyle;
                    entityStyle = this._propertyLevelStyles[depth];
                }
                returnStyle = entityStyle as GC.Spread.Sheets.Style;
                break;
            }
        }

        return returnStyle;
    }

    public renderTwoYearsTableData(data: TwoYearsTableData, setDataReady: React.Dispatch<React.SetStateAction<boolean>>): void {

        this._ssapi.directAccess(spread => {
            const sheet = spread.getSheetFromName(SJSCfg.MAIN_SHEET_NAME);
            const rowCount = data.length + SJSCfg.FIRST_DATA_ROW;
            const groupRows: number[] = [];

            sheet.setRowCount(rowCount);
            sheet.setColumnCount(Object.keys(TwoYearsColId).filter((v) => isNaN(Number(v))).length + SJSCfg.FIRST_VISIBLE_COL);

            sheet.setArray(
                TWO_YR_CFG.FIRST_DATA_ROW,
                TWO_YR_CFG.LABEL_COL,
                data
            );

            const parseCompOpList = (compOpList: string): (number|null)[] => {
                let composedReforecastYear: (number|null)[] = new Array(12).fill(0);
                let composedBudgetYear: (number|null)[] = new Array(12).fill(0);

                compOpList.split(',').forEach(thisOp => {
                    const op = thisOp.slice(0, 1);
                    const entityId: string = thisOp.slice(1);

                    // Find the related entity row
                    let entryRow = -1;
                    this._entityIdRows.find((entry, idx) => {
                        if(entry == entityId){
                            entryRow = idx;
                        }
                    });

                    if(entryRow > -1){ // If the row for this related entry is found...
                        const relatedReforecastYear = data[entryRow]?.slice(1, 13);
                        if(relatedReforecastYear){
                            if(op == '+'){
                                composedReforecastYear = addYearDataArrays(composedReforecastYear, relatedReforecastYear);
                            }
                            else if(op == '-'){
                                composedReforecastYear = addYearDataArrays(composedReforecastYear, relatedReforecastYear, -1);
                            }
                        }

                        const relatedBudgetYear = data[entryRow]?.slice(14, 26);
                        if(relatedBudgetYear){
                            if(op == '+'){
                                composedBudgetYear = addYearDataArrays(composedBudgetYear, relatedBudgetYear);
                            }
                            else if(op == '-'){
                                composedBudgetYear = addYearDataArrays(composedBudgetYear, relatedBudgetYear, -1);
                            }
                        }
                    }
                });

                // Get component summary totals for reforecast and budget years
                composedReforecastYear.push(
                    composedReforecastYear.reduce((acc: number, curr) => {
                        if(typeof curr == 'number'){
                            return acc + curr;
                        }
                        else {
                            return acc;
                        }
                    }, 0)
                );
                composedBudgetYear.push(
                    composedBudgetYear.reduce((acc: number, curr) => {
                        if(typeof curr == 'number'){
                            return acc + curr;
                        }
                        else {
                            return acc;
                        }
                    }, 0));

                return [...composedReforecastYear, ...composedBudgetYear];
            };

            // FIXME: Improve by processing rowData in chunks rather than in one shot
            data.forEach((rowData, rowIdx) => {

                const type = rowData[TwoYearsLayout.TwoYearsColOffset(TwoYearsColId.ENTITY_TYPE)];

                const entityId = rowData[TwoYearsLayout.TwoYearsColOffset(TwoYearsColId.ENTITY_ID)] as string;
                const numChildren = rowData[TwoYearsLayout.TwoYearsColOffset(TwoYearsColId.NUM_CHILDREN)] as number ?? 0;
                const depth = rowData[TwoYearsLayout.TwoYearsColOffset(TwoYearsColId.DEPTH)] as number ?? 0;
                const row = TwoYearsLayout.TwoYearsRowOffset(rowIdx);
                const col = TWO_YR_CFG.LABEL_COL;

                // Update this entity's row position
                this._entityIdRows.push(entityId as string);

                if(numChildren && numChildren > 0){
                    const groupRow = rowIdx + SJSCfg.FIRST_DATA_ROW + 1;
                    sheet.rowOutlines.group(groupRow, numChildren);
                    groupRows.push(groupRow);
                }

                if((type == FinancialEntityType.Category || type == FinancialEntityType.Account)){

                    // Build the layer's style if it hasn't been built yet
                    const entityStyle = this.getEntityLabelStyle(type, depth);

                    if(rowData[TwoYearsLayout.TwoYearsColOffset(TwoYearsColId.DEPTH)] == 1){
                        sheet.getCell(row, col).setStyle(entityStyle as GC.Spread.Sheets.Style);
                    }
                    sheet.getCell(
                        row,
                        col
                    ).cellType(
                        new ToggleIconCell({
                            isOpen: false,
                            showIcon: true,
                            toggleIconSpecs: type == FinancialEntityType.Category ? ExpandableIconSpec : AccountIconSpec,
                            hotspotSpecs: {
                                visible: false,
                                x: 0,
                                y: 0,
                                width: 20,
                                height: 20,
                            }
                        })
                    );
                }

                // Only Component summary rows are present, so data doesn't need to be saved to apply to two rows
                if(type == 'COMPONENT_SUMMARY'){
                    const compOpList = rowData[TwoYearsLayout.TwoYearsColOffset(TwoYearsColId.COMPONENT_OPS)];
                    if(compOpList && typeof compOpList == 'string'){
                        const compOpsData = parseCompOpList(compOpList) as number[];
                        sheet.setArray(row, TwoYearsColId.REFO_MONTH_0, [compOpsData]);
                    }

                    this.applyComponentSummaryRowStyle(row, sheet);
                }

                if(type == 'CATEGORY_SUMMARY'){
                    this.applyCategorySummaryRowStyle(row, sheet);
                }

                // Apply default data cell styles to all visible rows
                if(depth == 1){
                    this.applyDataCellStylesToRow(row, sheet);
                }

                // Apply autoFirRow to all visible rows
                if(depth == 1 || type == 'COMPONENT_SUMMARY'){
                    sheet.autoFitRow(row);
                    if(sheet.getRowHeight(row) < SJSCfg.MINUMUM_ROW_HEIGHT){
                        sheet.setRowHeight(row, SJSCfg.MINUMUM_ROW_HEIGHT);
                    }
                }
            });

            groupRows.forEach(groupRow => sheet.rowOutlines.setCollapsed(groupRow, true));

            // this.reportLoadProgress(100);
            setDataReady(true);
        });
    }

    public setRowOpenState(row: number, openState: boolean): void {
        this._ssapi.directAccess(spread => {
            const sheet = spread.getSheetFromName(TWO_YR_CFG.MAIN_TAB_NAME);

            this.setGroupOpenState(row, openState);
        });
    }

    public getRowType(row: number): string|undefined {
        return this.getRowInfo(row, TwoYearsColId.ENTITY_TYPE) as string|undefined;
    }
}
