import { ReactElement, useRef } from "react";
import * as css from "../styles/css.module.scss";
import {
    IUseTreeviewProps,
    IUseTreeviewReturnValue,
    TreeviewContainer,
} from '@zendeskgarden/container-treeview';

// This whole component mostly borrowed from Zenddesk Garden
// see:
// 1. https://zendeskgarden.github.io/react-containers/?path=/story/packages-treeview-readme--page
// 2. https://github.com/zendeskgarden/react-containers/blob/main/packages/treeview/demo/stories/TreeviewStory.tsx
interface INodeProps {
    treeNode: ITreeNode;
    uniqueKey: string;
    getNodeProps: IUseTreeviewReturnValue<string>['getNodeProps'];
    getGroupProps: IUseTreeviewReturnValue<string>['getGroupProps'];
    level?: number;
}

const Node = ({
    treeNode,
    uniqueKey,
    getNodeProps,
    getGroupProps,
    level = 0
}: INodeProps) => {
    const focusRef = useRef<HTMLLIElement>(null);
    const nodeProps = getNodeProps({
        focusRef: focusRef,
        item: uniqueKey,
        nodeType: treeNode.children ? 'parent' : 'end'
    });

    return (
        <li
            {...nodeProps}
            ref={focusRef}
            style={{ "--level": level } as unknown as React.CSSProperties}
        >
            {treeNode.children ? (
                <details open={nodeProps['aria-expanded'] as boolean}>
                    <summary>
                        <div>
                            {treeNode.content}
                        </div>
                    </summary>
                    <ul {...getGroupProps()}>
                        {treeNode.children.map((node, index) => (
                            <Node
                                key={`${level}${index}`}
                                uniqueKey={`${level}${index}`}
                                treeNode={node}
                                getNodeProps={getNodeProps}
                                getGroupProps={getGroupProps}
                                level={level + 1}
                            />
                        ))}
                    </ul>
                </details>
            ) : (
                <summary>
                    <div>{treeNode.content}</div>
                </summary>
            )}
        </li>
    );
};

interface IComponentProps extends IUseTreeviewReturnValue<string> {
    tree: ITreeNode[];
    'aria-label': string;
}

const Component = ({
    getTreeProps,
    tree,
    'aria-label': ariaLabel,
    ...props
}: IComponentProps) => (
    <div className={css.container}>
        <ul {...getTreeProps({ 'aria-label': ariaLabel })}>
            {tree.map((node, index) => (
                <Node key={index} uniqueKey={index.toString()} treeNode={node} {...props} />
            ))}
        </ul>
    </div>
);

export interface IProps extends IUseTreeviewProps<string> {
    tree: ITreeNode[];
    'aria-label': string;
}

export interface ITreeNode {
    content: ReactElement;
    children?: ITreeNode[];
}

export const ZDTreeContainer = ({ tree, 'aria-label': ariaLabel, ...props }: IProps) => (
    <TreeviewContainer {...props}>
        {containerProps => (
            <Component
                tree={tree}
                aria-label={ariaLabel}
                {...containerProps}
            />
        )}
    </TreeviewContainer>
);
