import checkedYesIndeterminateYesStateHover from "../icons/checkbox_28x28/checked_yes_indeterminate_yes_state_hover.svg";
import checkedYesIndeterminateYesStateDisabled from "../icons/checkbox_28x28/checked_yes_indeterminate_yes_state_disabled.svg";
import checkedYesIndeterminateYesStateFocused from "../icons/checkbox_28x28/checked_yes_indeterminate_yes_state_focused.svg";
import checkedYesIndeterminateNoStateHover from "../icons/checkbox_28x28/checked_yes_indeterminate_no_state_hover.svg";
import checkedYesIndeterminateYesStateDefault from "../icons/checkbox_28x28/checked_yes_indeterminate_yes_state_default.svg";
import checkedYesIndeterminateNoStateDisabled from "../icons/checkbox_28x28/checked_yes_indeterminate_no_state_disabled.svg";
import checkedYesIndeterminateNoStateFocused from "../icons/checkbox_28x28/checked_yes_indeterminate_no_state_focused.svg";
import checkedYesIndeterminateNoStateDefault from "../icons/checkbox_28x28/checked_yes_indeterminate_no_state_default.svg";
import checkedNoIndeterminateNoStateHover from "../icons/checkbox_28x28/checked_no_indeterminate_no_state_hover.svg";
import checkedNoIndeterminateNoStateFocused from "../icons/checkbox_28x28/checked_no_indeterminate_no_state_focused.svg";
import checkedNoIndeterminateNoStateDisabled from "../icons/checkbox_28x28/checked_no_indeterminate_no_state_disabled.svg";
import checkedNoIndeterminateNoStateDefault from "../icons/checkbox_28x28/checked_no_indeterminate_no_state_default.svg";

const images: Record<string, HTMLImageElement> = {};

const srcs: Record<string, any> = {
    checkedYesIndeterminateYesStateHover: checkedYesIndeterminateYesStateHover,
    checkedYesIndeterminateYesStateDisabled: checkedYesIndeterminateYesStateDisabled,
    checkedYesIndeterminateYesStateFocused: checkedYesIndeterminateYesStateFocused,
    checkedYesIndeterminateNoStateHover: checkedYesIndeterminateNoStateHover,
    checkedYesIndeterminateYesStateDefault: checkedYesIndeterminateYesStateDefault,
    checkedYesIndeterminateNoStateDisabled: checkedYesIndeterminateNoStateDisabled,
    checkedYesIndeterminateNoStateFocused: checkedYesIndeterminateNoStateFocused,
    checkedYesIndeterminateNoStateDefault: checkedYesIndeterminateNoStateDefault,
    checkedNoIndeterminateNoStateHover: checkedNoIndeterminateNoStateHover,
    checkedNoIndeterminateNoStateFocused: checkedNoIndeterminateNoStateFocused,
    checkedNoIndeterminateNoStateDisabled: checkedNoIndeterminateNoStateDisabled,
    checkedNoIndeterminateNoStateDefault: checkedNoIndeterminateNoStateDefault,
};

function getImage(srcName: string): HTMLImageElement {
    const src = srcs[srcName];

    if (!src) {
        console.log("No image for ", srcName);
    }

    let image = images[src];

    if (!image) {
        image = new Image();
        image.src = src;
        images[src] = image;
    }
    return image;
}

// preload images
for (const name of Object.keys(srcs)) getImage(name);

export enum CheckboxState {
    DEFAULT,
    FOCUSED,
    DISABLED,
    HOVER,
}

const SIZE = 28;
const DISTANCE = 12;
const INNER_ADJUST = 8; // to consider size of 20 as design "thinks" it is 20

const yn = (x: boolean) => x ? "Yes" : "No";
const st = (x: CheckboxState) => {
    switch (x) {
        case CheckboxState.FOCUSED:
            return "Focused";
        case CheckboxState.DISABLED:
            return "Disabled";
        case CheckboxState.HOVER:
            return "Hover";
        default:
            return "Default";
    }
}

export function drawCheckbox(ctx: CanvasRenderingContext2D, contentX: number, y: number, _w: number, h: number, state: CheckboxState, checked: boolean, indeterminate: boolean): void {

    const srcName = `checked${yn(checked)}Indeterminate${yn(indeterminate)}State${st(state)}`;

    const image = getImage(srcName);
    ctx.drawImage(image, contentX - DISTANCE - SIZE + INNER_ADJUST, y + h / 2 - SIZE / 2, SIZE, SIZE);
}
