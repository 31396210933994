import GC from '@grapecity/spread-sheets';
import VerticalAlign = GC.Spread.Sheets.VerticalAlign;
import HorizontalAlign = GC.Spread.Sheets.HorizontalAlign;
import { AnalystStyles } from '../AnalystStyles';
// import { COLORS } from '../../../constants/Colors';

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace AnalystRenovationsSheetStyles {
    /**
     * The basic style for an entry in the Financial Entity column.
     * e.g. A property, unit type, metric, etc.
     * @private
     */
    const FINANCIAL_ENTITY = new GC.Spread.Sheets.Style();
    FINANCIAL_ENTITY.foreColor = '#333842';
    FINANCIAL_ENTITY.font = '500 12px Inter';
    FINANCIAL_ENTITY.wordWrap = true;
    FINANCIAL_ENTITY.vAlign = VerticalAlign.center;

    export const PROPERTY_LABEL = FINANCIAL_ENTITY.clone();
    PROPERTY_LABEL.textIndent = AnalystStyles.LEVEL_0_TEXT_INDENT;

    export const UNIT_LABEL = FINANCIAL_ENTITY.clone();
    UNIT_LABEL.textIndent = AnalystStyles.LEVEL_1_TEXT_INDENT;
    UNIT_LABEL.foreColor = '#0D666D';

    export const RENO_PACKAGE_LABEL = FINANCIAL_ENTITY.clone();
    RENO_PACKAGE_LABEL.textIndent = AnalystStyles.LEVEL_2_TEXT_INDENT;

    export const VALUE_BASE = new GC.Spread.Sheets.Style();
    VALUE_BASE.font = '400 12px Inter';
    VALUE_BASE.vAlign = VerticalAlign.center;
    VALUE_BASE.cellPadding = '6px';
    VALUE_BASE.locked = true;
    VALUE_BASE.formatter = '#,#';
    VALUE_BASE.foreColor = '#525A6A';

    /**
     * Non-editable actual values in a closed row.
     */
    export const ACTUAL_UNHOVER_CLOSED = VALUE_BASE.clone();
    ACTUAL_UNHOVER_CLOSED.borderRight = AnalystStyles.CELL_BORDER;
    ACTUAL_UNHOVER_CLOSED.borderBottom = AnalystStyles.CELL_BORDER;

    /**
     * Open row style
     */
    export const OPEN_ROW_ACTUALS = VALUE_BASE.clone();
    OPEN_ROW_ACTUALS.borderTop = AnalystStyles.CELL_BORDER;
    OPEN_ROW_ACTUALS.borderRight = AnalystStyles.MEDIUM_VERTICAL_DIVIDER;
    OPEN_ROW_ACTUALS.borderBottom = AnalystStyles.CELL_BORDER;
    OPEN_ROW_ACTUALS.borderLeft = AnalystStyles.CELL_BORDER;

    /**
     * The last cell in a span of non-editable cells. This cell gets
     * a special treatment as compared to the others in the span, so
     * it needs its own style ID. Default formatting is a decimal string.
     */
    export const LAST_ACTUAL_UNHOVER_CLOSED = VALUE_BASE.clone();
    LAST_ACTUAL_UNHOVER_CLOSED.borderRight = AnalystStyles.MEDIUM_VERTICAL_DIVIDER;
    LAST_ACTUAL_UNHOVER_CLOSED.borderBottom = AnalystStyles.CELL_BORDER;

    export const MANUAL_ENTRY_UNHOVERED = VALUE_BASE.clone();
    MANUAL_ENTRY_UNHOVERED.borderRight = AnalystStyles.CELL_BORDER;
    MANUAL_ENTRY_UNHOVERED.borderBottom = AnalystStyles.CELL_BORDER;

    export const LAST_MANUAL_ENTRY_UNHOVERED = MANUAL_ENTRY_UNHOVERED.clone();
    LAST_MANUAL_ENTRY_UNHOVERED.borderRight = AnalystStyles.MEDIUM_VERTICAL_DIVIDER;

    /**
     * Cells in a span of manual entry cells.
     * Default formatting is a decimal string.
     */
    export const MANUAL_ENTRY = VALUE_BASE.clone();
    MANUAL_ENTRY.borderRight = AnalystStyles.CELL_BORDER;
    MANUAL_ENTRY.borderBottom = AnalystStyles.CELL_BORDER;
    MANUAL_ENTRY.borderBottom = AnalystStyles.MANUAL_EDITABLE_CELL_HOVER_BORDER;
    MANUAL_ENTRY.locked = false;


    /**
     * The last cell in a span of manual entry cells. This cell gets
     * a special treatment as compared to the others in the span, so
     * it needs its own style ID. Default formatting is a decimal string.
     */
    export const LAST_MANUAL_ENTRY = MANUAL_ENTRY.clone();
    LAST_MANUAL_ENTRY.borderRight = AnalystStyles.MEDIUM_VERTICAL_DIVIDER;

    /**
     * The cell containing the total for a financial entity. Used for both
     * the current year or a budget year.
     */
    export const TOTAL = VALUE_BASE.clone();
    TOTAL.backColor = AnalystStyles.TOTALS_BG_COLOR;
    TOTAL.borderRight = AnalystStyles.MEDIUM_VERTICAL_DIVIDER;
    TOTAL.borderBottom = AnalystStyles.CELL_BORDER;

    /**
     * The rollup row for a property.
     */
    export const PROPERTY_ROLLUP = VALUE_BASE.clone();
    PROPERTY_ROLLUP.backColor = '#F0F1F3';
    PROPERTY_ROLLUP.borderRight = AnalystStyles.CELL_BORDER;
    PROPERTY_ROLLUP.borderBottom = AnalystStyles.CELL_BORDER;

    /**
     * The last cell for a property rollup before the reforecast
     * columns begin.
     */
    export const LAST_PROPERTY_ROLLUP = PROPERTY_ROLLUP.clone();
    LAST_PROPERTY_ROLLUP.borderRight = AnalystStyles.MEDIUM_VERTICAL_DIVIDER;
    LAST_PROPERTY_ROLLUP.borderBottom = AnalystStyles.CELL_BORDER;

    /**
     * The rollup row for a metric.
     */
    export const UNIT_ROLLUP = VALUE_BASE.clone();
    UNIT_ROLLUP.backColor = '#FAFAFA';
    UNIT_ROLLUP.borderRight = AnalystStyles.CELL_BORDER;
    UNIT_ROLLUP.borderBottom = AnalystStyles.CELL_BORDER;

    /**
     * The last cell for a property rollup before the reforecast
     * columns begin.
     */
    export const LAST_UNIT_ROLLUP = UNIT_ROLLUP.clone();
    LAST_UNIT_ROLLUP.borderRight = AnalystStyles.MEDIUM_VERTICAL_DIVIDER;
    LAST_UNIT_ROLLUP.borderBottom = AnalystStyles.CELL_BORDER;

    /**
     * The cell containing the total for a financial entity. Used for both
     * the current year or a budget year.
     */
    export const UNIT_TOTAL = TOTAL.clone();
    UNIT_TOTAL.backColor = '#FAFAFA';

    /**
     * The rollup row for a metric.
     */
    export const EXPIRED_MOVEOUTS_ROLLUP = UNIT_ROLLUP.clone();
    EXPIRED_MOVEOUTS_ROLLUP.formatter = '0.0';

    /**
     * The last cell for a property rollup before the reforecast
     * columns begin.
     */
    export const LAST_EXPIRED_MOVEOUTS_ROLLUP = LAST_UNIT_ROLLUP.clone();
    LAST_EXPIRED_MOVEOUTS_ROLLUP.formatter = '0.0';

    /**
     * The cell containing the total for a financial entity. Used for both
     * the current year or a budget year.
     */
    export const EXPIRED_MOVEOUTS_TOTAL = UNIT_TOTAL.clone();
    EXPIRED_MOVEOUTS_TOTAL.formatter = '0.0';

}
