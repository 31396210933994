import {useEffect, useState} from "react";
import {useConfig} from "../../../../hooks/useConfig";
import { Property } from "../../../../contexts/properties/PropertiesContext";

interface UseAccountsTableViewDataReturnType {
    isReady: boolean,
    propertyId: string | undefined,
    property: Property | undefined,
    startReforecastMonthIndex: number | undefined,
    year: number | undefined,
    reforecastYearShortForm: string | undefined,
    budgetYearShortForm: string | undefined,
    starringReforcastVersionId: string | undefined,
    starringBudgetVersionId: string | undefined,
    finTotalReforcastVersionId: string | undefined,
    finTotalBudgetVersionId: string | undefined
}

export function useAccountsTableViewData():UseAccountsTableViewDataReturnType {
    const [isReady, setIsReady] = useState<boolean>(false);
    const [propertyId, setPropertyId] = useState<string | undefined>(undefined);
    const [property, setProperty] = useState<Property | undefined>(undefined);
    const [startReforecastMonthIndex, setStartReforecastMonthIndex] = useState<number | undefined>(undefined);
    const [year, setYear] = useState<number | undefined>(undefined);
    const [reforecastYearShortForm, setReforecastYearShortForm] = useState<string | undefined>(undefined);
    const [budgetYearShortForm, setBudgetYearShortForm] = useState<string | undefined>(undefined);
    const [starringReforcastVersionId, setStarringReforcastVersionId] = useState<string | undefined>(undefined);
    const [starringBudgetVersionId, setStarringBudgetVersionId] = useState<string | undefined>(undefined);
    const [finTotalReforcastVersionId, setFinTotalReforcastVersionId] = useState<string | undefined>(undefined);
    const [finTotalBudgetVersionId, setFinTotalBudgetVersionId] = useState<string | undefined>(undefined);

    const config = useConfig();

    useEffect(() => {
        if (!config.isReady) {
            setIsReady(false);
            return;
        }
        setPropertyId(config.properties.currentProperty?.id ?? "");
        setProperty(config.properties.currentProperty);
        setStartReforecastMonthIndex(config.startReforecastMonthIndex ?? "");
        setYear(config.year);
        const modelVersions = config.versions.getModelVersions(config.year);
        setReforecastYearShortForm(`'${config.year.toString(10).substring(2)}`);
        setBudgetYearShortForm(`'${(config.year + 1).toString(10).substring(2)}`);
        setStarringReforcastVersionId(modelVersions.reforecastVersionId);
        setStarringBudgetVersionId(modelVersions.nextYearBudgetVersionId);
        setFinTotalReforcastVersionId(modelVersions.currentYearTotalVersionId);
        setFinTotalBudgetVersionId(modelVersions.nextYearBudgetVersionId);
        setIsReady(true);
    }, [config.properties.currentProperty, config.versions, config.year, config.isReady]);

    return  {
        isReady,
        propertyId,
        property,
        startReforecastMonthIndex,
        year,
        reforecastYearShortForm,
        budgetYearShortForm,
        starringReforcastVersionId,
        starringBudgetVersionId,
        finTotalReforcastVersionId,
        finTotalBudgetVersionId
    };
}
