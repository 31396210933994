import { ReactElement } from "react";
import * as css from "../styles/insights.module.scss";

import { IGlContributions, IVarianceAccount } from "../logic/accountVarianceInsights";
import { formatterDollarUSNoDecimal, formatterPercent } from "../../../../../../../../utils/formatters";
import { getListItemJunction } from "../../../../../../../../utils/string-helpers";

interface IGlContributionsProps {
    account: IVarianceAccount|undefined,
    contributions: IGlContributions|undefined;
}

export default function GlContributions(props: IGlContributionsProps): ReactElement {

    const account = props.contributions?.[0];
    const lineage = props.contributions?.slice(1) ?? [];

    const contribs: ReactElement[] = [
        <span key="ref_contrib_acct"
              className={css.contribution}>({account?.glNumber}) {account?.name} contributed to </span>
    ];

    lineage.forEach((cont, idx, arr) => {
        const parentTotal = formatterDollarUSNoDecimal.format(cont.parentTotal);
        const percent = formatterPercent.format(cont.percentContributed);

        const connector = <>{getListItemJunction(idx, arr.length)}</>;

        contribs.push(
                <span key={`contrib_piece_${idx}`} className={css.contribution}>
                    <span className={css.hilightValue}>${percent}</span> of {cont.name} ({parentTotal}){connector}
                </span>
        );
    });

    return <div>
        {
            !account
                    ? <>Loading...</>
                    : contribs.map(contrib => contrib)
        }
    </div>;
}
