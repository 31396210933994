import React from 'react';
import Select from "react-select";
import { useProperties } from '../../contexts/properties/PropertiesContext';
import './Properties.scss';
import PropertyChangeE2eTestWrapper from "./e2e-utils/PropertyChangeE2eTestWrapper";

export const Properties: React.VFC = () => {
    const { properties, currentProperty, chooseProperty, loading } = useProperties();
    const mockLoading = [
        {
            value: 'noid',
            label: 'Loading...'
        }
    ];
    return (
        <>
            {loading &&
                <Select
                    className="fs-5 properties-select"
                    classNamePrefix="properties-select"
                    placeholder={"Select property"}
                    options={mockLoading}
                    defaultValue={mockLoading[0]}
                    value={mockLoading[0]}
                />
            }
            {!loading &&
                <>
                    <Select
                        className="fs-5 properties-select"
                        classNamePrefix="properties-select"
                        placeholder={"Select property"}
                        options={properties?.map(property => ({ value: property.id, label: property.name }))}
                        onChange={property => {
                            if (property && property.value) {
                                chooseProperty(property.value);
                            }
                        }}
                        defaultValue={{ value: currentProperty?.id, label: currentProperty?.name }}
                        value={{ value: currentProperty?.id, label: currentProperty?.name }}
                        isDisabled={(properties?.length ?? 1) < 2}
                    />
                    <PropertyChangeE2eTestWrapper
                        properties={properties}
                        chooseProperty={chooseProperty}
                    />
                </>
            }
        </>
    );
};
