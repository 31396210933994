import { BudgetingType } from "../../../BudgetingType";
import { AppConfig } from "../../../hooks/useConfig";
import { ExportPropertyBudgetQuery, VersionType, FinancialEntityType } from "../../../__generated__/generated_types";
import { YearDefinition } from "../../annualsummary/YearDefinitions";

export function formatAsReport(
    { tab, referenceYear, data, config }:
        {
            tab: BudgetingType;
            referenceYear: YearDefinition;
            data: ExportPropertyBudgetQuery;
            config: AppConfig;
        }): string {
    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    const tabularData = [
        [
            "GL Number",
            "GL Name",
            ...new Array(12).fill(0).map((_, i) => `${months[i]}.${tab == BudgetingType.REFORECAST ? config.year : config.year + 1}`),
            `${tab == BudgetingType.REFORECAST ? config.year : config.year + 1} ${tab}`,
            `${referenceYear.year} ${referenceYear.type == VersionType.YearTotal ? "Reforecast" : referenceYear.type == VersionType.Budget ? "Budget" : "Actuals"}`,
            "Note",
            "Variance %",
            "Variance $"
        ].join(",")];


    for (const record of data.getPropertyBudgetExportSingleYear ?? []) {
        const isIncome = config.chartOfAccountsConfig.getIsIncome(record.id);
        const variance$ = (record.yearTotal - record.referenceYearTotal) * (isIncome ? 1 : -1);
        const variancePct = record.referenceYearTotal ? variance$ / Math.abs(record.referenceYearTotal) : NaN;



        tabularData.push([
            `"${record.glNumber}"`,
            `"${record.glName}"`,
            ...record.yearValues.map(val => val.toFixed(2).toString()),
            record.yearTotal.toFixed(2).toString(),
            record.referenceYearTotal.toFixed(2).toString(),
            `"${record.note ?? ""}"`,
            isNaN(variancePct) ? "n/a" : (variancePct * 100).toFixed(2).toString(),
            variance$.toFixed(2).toString(),
        ].join(","));
    }
    const formattedData = tabularData.join("\n");
    return formattedData;
}

export function formatForAppfolio(
    { tab, data, config }:
        {
            tab: BudgetingType;
            data: ExportPropertyBudgetQuery;
            config: AppConfig;
        }): string {

    const $15EmptyCells = ",,,,,,,,,,,,,,";
    const $14EmptyCells = ",,,,,,,,,,,,,";
    const $13EmptyCells = ",,,,,,,,,,,,";
    const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
    const property = config.properties.currentProperty;
    const year = tab == BudgetingType.REFORECAST ? config.year : config.year + 1;
    const tabularData = [
        `"Budget for ${property?.name} - ${property?.address}"${$14EmptyCells}`,
        $15EmptyCells,
        `Fiscal Year,${year}${$13EmptyCells}`,
        $15EmptyCells,
        `Description,${$14EmptyCells}`,
        $15EmptyCells,
        [
            "Account Number",
            "Account Name",
            ...months,
            "Notes"
        ].join(",")
    ];

    const accountsOnly = (data.getPropertyBudgetExportSingleYear ?? []).filter(row => row.type == FinancialEntityType.Account);

    const accountsGroupedByClassification = accountsOnly.groupBy(row => row.accountClassification ?? "");

    for (const [classification, accountsGroup] of Object.entries(accountsGroupedByClassification)) {
        if (classification != null || classification != undefined) {
            tabularData.push($15EmptyCells);
            tabularData.push(classification + $14EmptyCells);
        }
        const accountsSorted = accountsGroup.sortBy("glNumber");
        for (const record of accountsSorted ?? []) {
            tabularData.push([
                `"${record.glNumber}"`,
                `"${record.glName}"`,
                ...record.yearValues.map(val => val.toFixed(2).toString()),
                `"${record.note ?? ""}"`,
            ].join(","));
        }
    }
    const formattedData = tabularData.join("\n");
    return formattedData;
}

export function formatForYardi(
    { tab, data, config, oneCentInsteadZero }:
        {
            tab: BudgetingType;
            data: ExportPropertyBudgetQuery;
            config: AppConfig;
            oneCentInsteadZero: boolean;
        }): string {

    const bookNum = config.chartOfAccountsConfig.rawData?.findFirstChartOfAccounts?.yardiChartOfAccountsMetadata?.firstElement?.bookNum ?? "unknown";
    const $28EmptyCells = ",,,,,,,,,,,,,,,,,,,,,,,,,,,";
    const $12EmptyCells = ",,,,,,,,,,,";
    const property = config.properties.currentProperty;
    const year = tab == BudgetingType.REFORECAST ? config.year : config.year + 1;
    const tabularData = [["FinBudgets",$28EmptyCells].join(",")];
    const tranNum = Date.now();
    tabularData.push([
        "TranNum",
        "Property",
        "BookNum",
        "Year",
        "Account",
        ...(new Array(12).fill(null).map((_,index) => `Segment${index + 1}`)),
        ...(new Array(12).fill(null).map((_,index) => `MtdBudget${index + 1}`))
    ].join(","));

    const accountsOnly = (data.getPropertyBudgetExportSingleYear ?? []).filter(row => row.type == FinancialEntityType.Account);

    for (const record of accountsOnly ?? []) {
        const yearValues = record.yearValues.copy<number>();

        if (yearValues.every(val => val == 0) && oneCentInsteadZero) {
            yearValues[0] = 0.01;
        }
        tabularData.push([
            `${tranNum}`,
            `${property?.code}`,
            `${bookNum}`,
            `${year}`,
            `${record.yardiExternalAccountId ?? record.glNumber}`,
            $12EmptyCells,
            ...yearValues.map(val => val.toFixed(2).toString()),
        ].join(","));
    }
    const formattedData = tabularData.join("\n");
    return formattedData;
}
