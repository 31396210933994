import { ReactElement } from "react";
import { HotColumn, HotTable } from "@handsontable/react";
import { registerAllModules } from 'handsontable/registry';
import { LICENSES } from "../../../../constants/Licenses";
import { CellMeta, CellProperties } from "handsontable/settings";
import { TWidgetTableConfig } from "../../data/useOperationalData";

import * as css from '../../styles/css.module.scss';
import * as widgetCSS from '../graph-table-widget/styles/css.module.scss';

import { IFinancialEntityWithDepth, isPositiveGood } from "../../../../utils/account";

registerAllModules();

interface IGLAccountsTableProps {
    tableData: TWidgetTableConfig,
    account: IFinancialEntityWithDepth | undefined,
}

export default function GLAccountsTable(props: IGLAccountsTableProps): ReactElement {
    const valueColumnConfig = {
        type: 'numeric',
        readOnly: true,
        numericFormat: {
            pattern: '$0,0.00',
            culture: 'en-US'
          }
    };

    const getChangeColor = (value: number | string): string => {
        if (!props.account || !props.account.budgetComponentType || value == 0 || value == '-') {
            return '';
        }

        const posGood = isPositiveGood(props.account.budgetComponentType, props.account.budgetComponentNegate);
        const valueAsNumber = parseFloat(String(value));

        if (valueAsNumber > 0) {
            return posGood ? css.positiveChange : css.negativeChange;
        } else {
            return posGood ? css.negativeChange : css.positiveChange;
        }
    };

    return (
        <div style={{ 'height': '100%' }}>
            <HotTable
                id="glaccount"
                width="100%"
                height="100%"
                data={props.tableData.data}
                afterGetColHeader={(_column: number, th: HTMLTableHeaderCellElement): void => {
                    if (_column == 0) {
                        th.classList.add(widgetCSS.widgetTableHeader);
                    } else {
                        th.classList.add(widgetCSS.widgetTableHeaderAlignRight);
                    }
                }}
                stretchH="all"
                className={css.widgetTable}
                licenseKey={LICENSES.HandsOnTable}
                fixedRowsBottom={1}
                cells={function (this: CellProperties, row: number, col: number): CellMeta {
                    const data = this.instance.getData();

                    if (row == props.tableData.data.length - 1) {
                        if (col == 0) {
                            return {
                                className: `${css.summaryRow} ${css.leftAlignCell}`,
                            };
                        } else {
                            return {
                                className: `${css.summaryRow} ${col > props.tableData.colHeaders.length - 3 ? getChangeColor(data[row][col]) : ''}`,
                            };
                        }
                    } else if (col > props.tableData.colHeaders.length - 3) {
                        return {
                            className: col > props.tableData.colHeaders.length - 3 ? getChangeColor(data[row][col]) : '',
                        };
                    }

                    return this;
                }}
            >
                {
                    props.tableData.colHeaders.map((column, index) => {
                        if (index == 0) {
                            return <HotColumn key={index} className={`${css.leftAlignCell} ${css.valueColumnNarrow}`} title={column} {...valueColumnConfig} />;
                        } else {
                            return <HotColumn key={index} className={css.valueColumnNarrow} title={column} {...valueColumnConfig} />;
                        }
                    })
                }
            </HotTable>
        </div>
    );
}