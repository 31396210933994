import GC from "@grapecity/spread-sheets";
import { drawExpandCollapse, isInsideExpandCollapse } from "./icon-renderers/draw-expand-collapse";
import { ICustomCellTargetHitInfo } from "./types";

export class ExpandCollapseRowCell extends GC.Spread.Sheets.CellTypes.Text {
    constructor(private isExpand: boolean) {
        super();
        this.typeName = "ExpandCollapseRowCell";
    }

    showPointer = false;

    override paint(ctx: CanvasRenderingContext2D, value: any, x: number, y: number, w: number, h: number, style: GC.Spread.Sheets.Style, context?: any): void {

        super.paint(ctx, value, x, y, w, h, style, context);

        if (this.showPointer) {
            const spread = context.sheet.getParent();
            let canvasEl: any = spread
                .getHost()
                .querySelector('[gcuielement="gcWorksheetCanvas"]');
            if (canvasEl && canvasEl.style.cursor === "default") {
                canvasEl.style.cursor = "pointer";
            }
        }
    }

    override paintContent(ctx: CanvasRenderingContext2D, value: any, x: number, y: number, w: number, h: number, style: GC.Spread.Sheets.Style, context?: any): void {
        super.paintContent(ctx, value, x, y, w, h, style, context);
        drawExpandCollapse(ctx, x, y, w, h, this.isExpand);
    }

    override getHitInfo(x: number, y: number, _cellStyle: GC.Spread.Sheets.Style, cellRect: GC.Spread.Sheets.Rect, context?: any): GC.Spread.Sheets.IHitTestCellTypeHitInfo {
        const c = cellRect; // alias
        const isTargetHit = isInsideExpandCollapse(x, y, c.x, c.y, c.width, c.height);
        const [row, col] = [context.row as number, context.col as number];
        const info: ICustomCellTargetHitInfo = {
            x,
            y,
            row,
            col,
            cellRect,
            sheetArea: context.sheetArea,
            isReservedLocation: true, // KEEP IT TRUE: see APP-328
            isTargetHit: isTargetHit
        };
        return info;
    }

    override processMouseMove(hitInfo: GC.Spread.Sheets.IHitTestCellTypeHitInfo): boolean {
        const hi = hitInfo as ICustomCellTargetHitInfo;
        this.showPointer = hi.isTargetHit;
        return false;
    }

    override processMouseLeave(_hitInfo: GC.Spread.Sheets.IHitTestCellTypeHitInfo): boolean {
        this.showPointer = false;
        return false;
    }

    override isReservedKey(e: KeyboardEvent, context?: any): boolean {
        const ret = super.isReservedKey(e, context);
        return ret;
    }

}
