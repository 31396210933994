import { ApolloError } from '@apollo/client';
import { BudgetingType } from '../../../BudgetingType';
import { allowRightClickCopyPaste, NewSpreadsheet } from '../../../components/spreadsheet/NewSpreadsheet';
import { OperationalFinancialMetrics } from './operational-metrics';
import { UnitTypeScheduleValue, VersionType } from '../../../__generated__/generated_types';
import { ReactElement, useContext, useEffect, useRef, useState } from 'react';
import { useNewSpreadsheetAPI } from '../../../components/spreadsheet/NewSpreadsheetHooks';
import { useSettings } from '../../../contexts/settings/SettingsContext';
import useGetMonths from '../../../hooks/UseGetMonths';
import viewTemplate from "./operational_metric_with_unit_types.json";
import { useProperties } from '../../../contexts/properties/PropertiesContext';
import { CustomCellType } from '../../../components/spreadsheet/NewSpreadsheetTypes';
import { average, TColumnAggregator, UnitTypeScheduleValuePick, useSaveUnitTypeScheduleValues, WeightFn } from './base';
import { useVersions } from '../../../contexts/versions/VersionsContext';
import { Body, Close, Footer, FooterItem, Header, Modal } from '@zendeskgarden/react-modals';
import { Button } from '@zendeskgarden/react-buttons';
import { AuthContext } from "../../../contexts/AuthContext";
import { userIsReadOnly } from "../../../authorization/AuthorizationCheckers";
import { spreadsheetContainer } from "../account/styles/styles.module.scss";
import GC from '@grapecity/spread-sheets';

const FIRST_VALUE_COLUMN = 5;
const FIRST_VALUE_ROW = 5;
const REFORECAST_INPUT_ROW = 2;
const REFORECAST_INPUT_COL = 6;
const FORMAT_AS_PCT_ROW = 2;
const FORMAT_AS_PCT_COL = 9;
const AGGREGATOR_ROW = 2;
const AGGREGATOR_COL = 12;

const UNIT_REFORECAST_INPUT_ROW = 2, UNIT_REFORECAST_INPUT_COL = 5;
const UNIT_YEAR_INPUT_ROW = 3, UNIT_YEAR_INPUT_COL = 5;
const UNIT_TYPE_INPUT_ROW = 3, UNIT_TYPE_INPUT_COL = 7;
const UNIT_HAS_VALUE_COL = 17;
const UNIT_COLUMN_AGGREGATOR_ROW = 2, UNIT_COLUMN_AGGREGATOR_COL = 17;
const UNIT_FIRST_VALUE_ROW = 6, UNIT_FIRST_VALUE_COL = 4;
const UNIT_FORMAT_AS_PCT_ROW = 3, UNIT_FORMAT_AS_PCT_COL = 10;
const UNIT_AGGREGATOR_ROW = 3, UNIT_AGGREGATOR_COL = 13;

const WEIGHTS_TAB = "Weights";
const WEIGHTS_TAB_FIRST_ROW = 1, WEIGHTS_TAB_FIRST_COL = 0;


const AGGREGATOR = {
    "SUM": 0,
    "AVERAGE": 1
};

const COLUMN_AGGREGATOR = {
    "AVERAGE": 0,
    "WEIGHTED_AVERAGE": 1
};


type UnitTypeScheduleValueSub<TKey extends keyof UnitTypeScheduleValue> = {
    id: string;
    year: number;
    type: VersionType;
    unitTypeScheduleValues: UnitTypeScheduleValuePick<TKey>[];
};

type QueryResults<TKey extends keyof UnitTypeScheduleValue> = {
    actuals: UnitTypeScheduleValueSub<TKey>[],
    budget?: UnitTypeScheduleValueSub<TKey>[],
    currentVersion: UnitTypeScheduleValueSub<TKey>[],
    reforecastVersion: UnitTypeScheduleValueSub<TKey>[],
}


export interface Data<TKey extends keyof UnitTypeScheduleValue> {
    data?: QueryResults<TKey>;
    loading: boolean;
    error?: ApolloError;
}

type Value = { monthIndex: number, unitTypeName: string, value: string };

interface Props<TKey extends keyof UnitTypeScheduleValue = keyof UnitTypeScheduleValue> {
    currentVersionYear: number;
    type: BudgetingType;
    propertyId: string;
    field: TKey;
    data: Data<TKey>;
    canRender: boolean;
    parseValue: (data: string) => string | number;
    parseFromRemote?: (data: string | number) => string | number | undefined;

    formatPercentage?: boolean;

    aggregator: "SUM" | "AVERAGE";
    columnAggregator: TColumnAggregator;

    readOnly?: boolean;

    weightFn?: WeightFn<TKey>; // required if columnAggregator is WEIGHTED_AVERAGE

    key: string;

}

function getUnitLevelDataDetails<TKey extends keyof UnitTypeScheduleValue>(
    data: QueryResults<TKey>,
    reforecastYear: number,
    startReforecastMonthIndex: number,
    field: TKey, weightFn?: WeightFn<TKey>,
    columnAggregator?: TColumnAggregator,
) {
    // mind suffixes below
    // UTL - Unit Type Level
    // PL - Property Level

    // ## unitCount monthly value
    // we have unit Count actual values received from Property Management System up until last month before reforecast start
    // yet there is no data as to the months coming forward (in reality we just do not pull this data but let's not overcomplicate)
    // unit Count is needed for calculating weighted average for certain metrics aggregating unit type level values to the property level
    // Hence we make an assumption that unit Count does not change and use last known (pre reforecast month) unit count as value for all the remaining
    // months in the budgeting season
    let unitCountYear = reforecastYear;
    let unitCountMonth = startReforecastMonthIndex - 1;
    if (unitCountMonth < 0) {
        unitCountYear = reforecastYear - 1;
        unitCountMonth = 11;
    }
    const unitCountLastKnownActualPerUnitType = data.actuals
        .find(row => row.year == unitCountYear)
        ?.unitTypeScheduleValues
        ?.filter(row => row.monthIndex == unitCountMonth)
        ?.toIdMap(row => row.unitType.id, row => row.unitCount);
    const reforecastVersionUpdated = data.reforecastVersion
        .map(row => ({
            ...row,
            unitTypeScheduleValues: row.unitTypeScheduleValues.map(utsv => {
                const utsvCopy = {
                    ...utsv,
                    unitType: {
                        ...utsv.unitType
                    }
                };
                const unitCountUnitTypeValue = unitCountLastKnownActualPerUnitType?.[utsv.unitType.id];
                if (row.type == VersionType.Reforecast || row.type == VersionType.Budget) {
                    if (unitCountUnitTypeValue != undefined) {
                        utsvCopy.unitCount = unitCountUnitTypeValue;
                    }
                }

                return utsvCopy;
            })
        }));

    const currentVersionUpdated = data.currentVersion
        .map(row => ({
            ...row,
            unitTypeScheduleValues: row.unitTypeScheduleValues.map(utsv => {
                const utsvCopy = {
                    ...utsv,
                    unitType: {
                        ...utsv.unitType
                    }
                };
                const unitCountUnitTypeValue = unitCountLastKnownActualPerUnitType?.[utsv.unitType.id];
                if (row.type == VersionType.Reforecast || row.type == VersionType.Budget) {
                    if (unitCountUnitTypeValue != undefined) {
                        utsvCopy.unitCount = unitCountUnitTypeValue;
                    }
                }
                return utsvCopy;
            })
        }));
    const actualsUTL = data.actuals.toIdMap("year", row => row.unitTypeScheduleValues.toIdMap(value => value.unitType.name + "#" + value.monthIndex));
    const actualsPL = data.actuals.toIdMap("year", row => Object.entries(row.unitTypeScheduleValues.groupBy("monthIndex")).toIdMap(([key]) => key, ([, values]) => average(values, field, weightFn, columnAggregator)));
    const reforecastPL = Object.entries(reforecastVersionUpdated?.firstElement?.unitTypeScheduleValues.groupBy("monthIndex"))?.toIdMap(([key]) => key, ([, values]) => average(values, field, weightFn, columnAggregator));
    const currentVersion = currentVersionUpdated.find(version => version.unitTypeScheduleValues.isNotEmpty) ?? currentVersionUpdated.firstElement;
    const currentVersionUTL = currentVersion?.unitTypeScheduleValues?.toIdMap(unit => unit.unitType.name + "#" + unit.monthIndex) ?? {};

    return { actualsUTL, actualsPL, reforecastPL, currentVersionUTL };
}

export default function UnitTypeLevelMetricTable<T extends keyof UnitTypeScheduleValue>(props: Props<T>): ReactElement {

    const {
        readOnly,

        type,

        field,

        propertyId,

        canRender

    } = props;

    const { loading, error, data } = props.data;
    const { user } = useContext(AuthContext);

    const spreadsheet = useNewSpreadsheetAPI();

    const { startReforecastMonthIndex, year } = useSettings();
    const months = useGetMonths();
    const { unitTypes: unitTypesUnsorted } = useProperties();
    const unitTypes = unitTypesUnsorted.sortBy("type");
    const { getModelVersions } = useVersions();

    const unitsCount = useRef<number>(0);

    const saveValues = useSaveUnitTypeScheduleValues(propertyId, field);
    const [preparedUpdates, setPreparedUpdates] = useState<{ values: Value[], cellsForUndo: Record<string, { row: number, col: number, value: number | string }> } | undefined>();

    const hasColumnDiffs = (rowFrom: number, colFrom: number, unitsCount: number, colCount: number) => {
        let ret = false;
        spreadsheet.api.directAccess(spread => {
            const sheet = spread.getSheetFromName("UnitTypeLevel");
            for (let col = colFrom; col < colFrom + colCount; col++) {
                const firstRowValue = sheet.getValue(rowFrom, col) as number;
                for (let row = rowFrom; row < rowFrom + unitsCount; row++) {
                    const currentRowValue = sheet.getValue(row, col);
                    if (firstRowValue != currentRowValue) {
                        ret = true;
                        return;
                    }
                }
            }
        });

        return ret;
    };

    useEffect(() => {
        // Load the unitLevel Data into the second tab.
        if (spreadsheet.isSpreadsheetReady) {
            if (data && !loading) {
                const { actualsUTL, actualsPL, reforecastPL, currentVersionUTL } = getUnitLevelDataDetails(data, year, startReforecastMonthIndex, field, props.weightFn, props.columnAggregator);

                if (unitsCount.current != unitTypes.length) {
                    unitsCount.current = unitTypes.length;
                }

                spreadsheet.api.directAccess(spread => {
                    spread.options.allowUserDragDrop = false;
                    spread.options.showDragFillSmartTag = false;
                    allowRightClickCopyPaste(spread);
                });

                ////////////////////////////////////////////////
                // Unit Type Level tab configuration and data
                ////////////////////////////////////////////////

                spreadsheet.api.setValue({ row: UNIT_YEAR_INPUT_ROW, col: UNIT_YEAR_INPUT_COL, value: type == BudgetingType.REFORECAST ? year : year + 1, sheetName: "UnitTypeLevel" });
                spreadsheet.api.setArray({ row: UNIT_TYPE_INPUT_ROW, col: UNIT_TYPE_INPUT_COL, array: [type == BudgetingType.REFORECAST ? "Reforecast" : "Budget"], sheetName: "UnitTypeLevel" });
                spreadsheet.api.setValue({ row: UNIT_REFORECAST_INPUT_ROW, col: UNIT_REFORECAST_INPUT_COL, value: type == BudgetingType.REFORECAST ? startReforecastMonthIndex : 0, sheetName: "UnitTypeLevel" });
                spreadsheet.api.setArray({ row: UNIT_FIRST_VALUE_ROW - 1, col: UNIT_FIRST_VALUE_COL, array: [months], sheetName: "UnitTypeLevel" });
                const actualsRowReforecastYear = actualsUTL[year] ?? {}; // relevant for type == BudgetingType.REFORECAST
                const dataArray: (string | number | null)[][] = unitTypes.map(({ type: unitType }) => {
                    return [
                        unitType,
                        ...new Array(12).fill(null).map((_, monthIndex) => {
                            const isEditable = type == BudgetingType.BUDGET || monthIndex >= startReforecastMonthIndex;
                            const currentValue = currentVersionUTL[unitType + "#" + monthIndex];
                            const actualValue = actualsRowReforecastYear[unitType + "#" + monthIndex];
                            const value = isEditable ? (parseFloat(currentValue?.[field] ?? "0") ?? 0) : (parseFloat(actualValue?.[field] ?? "0") ?? 0);
                            return value;
                        })
                    ];
                });

                spreadsheet.api.setArray({ row: UNIT_FIRST_VALUE_ROW, col: UNIT_FIRST_VALUE_COL - 1, array: dataArray, sheetName: "UnitTypeLevel" });

                const lockedColumnsCount = type == BudgetingType.REFORECAST ? startReforecastMonthIndex : 0;
                if (lockedColumnsCount > 0) {
                    spreadsheet.api.setCellsLocked({ row: UNIT_FIRST_VALUE_ROW, col: UNIT_FIRST_VALUE_COL, rowCount: dataArray.length, colCount: lockedColumnsCount, locked: true, sheetName: "UnitTypeLevel" });
                }
                const unLockedColumnsCount = 12 - lockedColumnsCount;
                if (unLockedColumnsCount > 0) {
                    spreadsheet.api.setCellsLocked({ row: UNIT_FIRST_VALUE_ROW, col: UNIT_FIRST_VALUE_COL + lockedColumnsCount, rowCount: dataArray.length, colCount: unLockedColumnsCount, locked: false, sheetName: "UnitTypeLevel" });
                }

                spreadsheet.api.setArray({ row: UNIT_FIRST_VALUE_ROW, col: UNIT_HAS_VALUE_COL, array: new Array(unitTypes.length).fill(1), sheetName: "UnitTypeLevel" });
                spreadsheet.api.applyCustomCellType({ row: UNIT_FIRST_VALUE_ROW + unitTypes.length, col: UNIT_FIRST_VALUE_COL - 1, cellType: CustomCellType.COLLAPSE_ROW_CELL, sheetName: "UnitTypeLevel" });
                spreadsheet.api.setValue({ row: UNIT_FORMAT_AS_PCT_ROW, col: UNIT_FORMAT_AS_PCT_COL, value: props.formatPercentage ? 1 : 0, sheetName: "UnitTypeLevel" });
                spreadsheet.api.setValue({ row: UNIT_AGGREGATOR_ROW, col: UNIT_AGGREGATOR_COL, value: AGGREGATOR[props.aggregator], sheetName: "UnitTypeLevel" });
                spreadsheet.api.setValue({ row: UNIT_COLUMN_AGGREGATOR_ROW, col: UNIT_COLUMN_AGGREGATOR_COL, value: COLUMN_AGGREGATOR[props.columnAggregator], sheetName: "UnitTypeLevel" });

                if (hasColumnDiffs(UNIT_FIRST_VALUE_ROW, UNIT_FIRST_VALUE_COL + lockedColumnsCount, unitTypes.length, 12 - lockedColumnsCount)) {
                    spreadsheet.api.setActiveSheetIndex(spreadsheet.api.getSheetIndex("UnitTypeLevel"));
                    spreadsheet.api.updateSpreadsheetViewPort({ startRow: UNIT_FIRST_VALUE_ROW - 1, startCol: UNIT_FIRST_VALUE_COL - 1, rows: unitsCount.current + 2, cols: 14, sheetName: "UnitTypeLevel" });
                }
                ////////////////////////////////////////////////
                // Weights tab configuration and data
                ////////////////////////////////////////////////

                // required for weighted average roll ups from unit type level to property level
                if (props.columnAggregator === "WEIGHTED_AVERAGE" && props.weightFn) {
                    const fn = props.weightFn;
                    const dataArray: (string | number | null)[][] = unitTypes.map(({ type: unitType }) => {
                        return [
                            unitType,
                            ...new Array(12).fill(null).map((_, monthIndex) => {
                                const isEditable = type == BudgetingType.BUDGET || monthIndex >= startReforecastMonthIndex;
                                const currentValue = currentVersionUTL[unitType + "#" + monthIndex];
                                const actualValue = actualsRowReforecastYear[unitType + "#" + monthIndex];
                                const value = isEditable ? (currentValue ? fn(currentValue) : 0) : (actualValue ? fn(actualValue) : 0);
                                return value;
                            })
                        ];
                    });

                    spreadsheet.api.setArray({ row: WEIGHTS_TAB_FIRST_ROW, col: WEIGHTS_TAB_FIRST_COL, array: dataArray, sheetName: WEIGHTS_TAB });
                }

                if(userIsReadOnly(user) || readOnly === true){
                    const lockStyle = new GC.Spread.Sheets.Style;

                    lockStyle.foreColor = "Black";
                    spreadsheet.api.setStyle({row: -1, col: -1, style: lockStyle});

                    spreadsheet.api.lockAllCells({sheetName: "UnitTypeLevel"});
                }

                ////////////////////////////////////////////////
                // Property Level tab configuration and data
                ////////////////////////////////////////////////

                // grab values into property level tab
                for (let monthIndex = 0; monthIndex < 12; monthIndex++) {
                    const value = spreadsheet.api.getValue({
                        row: UNIT_FIRST_VALUE_ROW + unitTypes.length,
                        col: UNIT_FIRST_VALUE_COL + monthIndex,
                        sheetName: "UnitTypeLevel"
                    }) as number;
                    spreadsheet.api.setValue({ row: FIRST_VALUE_ROW, col: FIRST_VALUE_COLUMN + monthIndex, value: value, sheetName: "PropertyLevel" });
                }

                const otherYears: (string | number | null)[][] = [];

                if (type == BudgetingType.BUDGET) { // add reforecast year as reference under the budget row
                    const yearRow = actualsPL[year] ?? {};
                    const yearRowArray: (string | number | null)[] = [];
                    for (let monthIndex = 0; monthIndex < 12; monthIndex++) {
                        if (monthIndex < startReforecastMonthIndex) {
                            yearRowArray.push(yearRow[monthIndex.toString()] ?? null);
                        }
                        else {
                            yearRowArray.push(reforecastPL[monthIndex.toString()] ?? null);
                        }
                    }
                    otherYears.push(yearRowArray);
                }

                for (let rowYear = year - 1; rowYear >= (type == BudgetingType.REFORECAST ? year - 3 : year - 2); rowYear--) {
                    const yearRow = actualsPL[rowYear] ?? {};
                    const yearRowArray: (string | number | null)[] = new Array(12).fill(0).map((_, monthIndex) => yearRow[monthIndex.toString()] ?? null);
                    otherYears.push(yearRowArray);
                }

                spreadsheet.api.setArray({ row: FIRST_VALUE_ROW + 1, col: FIRST_VALUE_COLUMN, array: otherYears, sheetName: "PropertyLevel" });
            }
        }
    },
        [spreadsheet.isSpreadsheetReady, data, loading]

    );

    useEffect(
        () => {
            if (spreadsheet.isSpreadsheetReady) {
                spreadsheet.api.setTemplate({ template: viewTemplate });
            }
        },
        [spreadsheet.isSpreadsheetReady]
    );

    useEffect(
        () => {
            if (data && !error && !loading && spreadsheet.isSpreadsheetReady && canRender) {

                spreadsheet.api.setValue({ row: AGGREGATOR_ROW, col: AGGREGATOR_COL, value: AGGREGATOR[props.aggregator], sheetName: "PropertyLevel" });
                spreadsheet.api.setValue({ row: FORMAT_AS_PCT_ROW, col: FORMAT_AS_PCT_COL, value: props?.formatPercentage ? 1 : 0, sheetName: "PropertyLevel" });
                spreadsheet.api.setValue({ row: REFORECAST_INPUT_ROW, col: REFORECAST_INPUT_COL, value: type == BudgetingType.REFORECAST ? startReforecastMonthIndex : 0, sheetName: "PropertyLevel" });

                for (let col = type == BudgetingType.REFORECAST ? FIRST_VALUE_COLUMN + startReforecastMonthIndex : FIRST_VALUE_COLUMN; col < FIRST_VALUE_COLUMN + 12; col++) {
                    spreadsheet.api.setCellLocked({ row: FIRST_VALUE_ROW, col, locked: false, sheetName: "PropertyLevel" });
                }
                const lockedColumnsCount = type == BudgetingType.REFORECAST ? startReforecastMonthIndex : 0;
                if (lockedColumnsCount > 0) {
                    spreadsheet.api.setCellsLocked({ row: FIRST_VALUE_ROW, col: FIRST_VALUE_COLUMN, rowCount: 1, colCount: lockedColumnsCount, locked: true, sheetName: "PropertyLevel" });
                }
                const unLockedColumnsCount = 12 - lockedColumnsCount;
                if (unLockedColumnsCount > 0) {
                    spreadsheet.api.setCellsLocked({ row: FIRST_VALUE_ROW, col: FIRST_VALUE_COLUMN + lockedColumnsCount, rowCount: 1, colCount: unLockedColumnsCount, locked: false, sheetName: "PropertyLevel" });
                }

                spreadsheet.api.applyCustomCellType({ row: FIRST_VALUE_ROW, col: FIRST_VALUE_COLUMN - 1, cellType: CustomCellType.EXPAND_ROW_CELL, sheetName: "PropertyLevel" });

                const yearLabels =
                    type == BudgetingType.REFORECAST ?
                        [
                            `${year} Reforecast`,
                            `${year - 1}`,
                            `${year - 2}`,
                            `${year - 3}`,
                        ]
                        :
                        [
                            `${year + 1} Budget`,
                            `${year} Reforecast`,
                            `${year - 1}`,
                            `${year - 2}`
                        ];

                spreadsheet.api.setArray({ row: FIRST_VALUE_ROW, col: FIRST_VALUE_COLUMN - 1, array: yearLabels, sheetName: "PropertyLevel" });

                if(userIsReadOnly(user) || readOnly === true){
                    const lockStyle = new GC.Spread.Sheets.Style;

                    lockStyle.foreColor = "Black";
                    spreadsheet.api.setStyle({row: -1, col: -1, style: lockStyle});

                    spreadsheet.api.lockAllCells({sheetName: "PropertyLevel"});
                }
            }
        },
        [spreadsheet.isSpreadsheetReady, data, canRender]
    );

    useEffect(
        () => {

            if (!data || loading || !canRender) {
                return;
            }


            const { reforecastVersionId, nextYearBudgetVersionId } = getModelVersions(year);
            const currentVersionId = props.type == BudgetingType.REFORECAST ? reforecastVersionId : nextYearBudgetVersionId;

            if (spreadsheet.api.getActiveSheetIndex() == spreadsheet.api.getSheetIndex("PropertyLevel")) {
                // grab updates from property level worksheet
                const cells = spreadsheet.cellsChanged.filter(cell => cell.row == FIRST_VALUE_ROW && cell.col >= FIRST_VALUE_COLUMN && cell.col <= FIRST_VALUE_COLUMN + 11).sortBy("col");

                if (cells.length == 0) {
                    return;
                }

                let hasUnittypeLevelDiffs = false;
                const cellsForUndo = {} as Record<string, { row: number, col: number, value: number | string }>;

                const values = [] as Value[];
                for (const cell of cells) {

                    if (!preparedUpdates) {

                        const monthIndex = cell.col - FIRST_VALUE_COLUMN;
                        const unitTypeLevelCol = UNIT_FIRST_VALUE_COL + monthIndex;
                        hasUnittypeLevelDiffs = hasUnittypeLevelDiffs || hasColumnDiffs(UNIT_FIRST_VALUE_ROW, unitTypeLevelCol, unitTypes.length, 1);

                        // distribute for all unit types for saving in backend
                        for (const unitType of unitTypes.map(v => v.type)) {
                            values.push({
                                monthIndex: monthIndex,
                                unitTypeName: unitType,
                                value: parseFloat(cell.value?.toString() ?? "0").toString(),
                            });
                        }

                        // distribute for all unit types for saving in unit type level worksheet
                        for (let row = UNIT_FIRST_VALUE_ROW; row < UNIT_FIRST_VALUE_ROW + unitsCount.current; row++) {
                            cellsForUndo[row.toString() + "#" + unitTypeLevelCol.toString()] = {
                                row: row,
                                col: unitTypeLevelCol,
                                value: spreadsheet.api.getValue({ row: row, col: unitTypeLevelCol, sheetName: "UnitTypeLevel" })
                            };
                            spreadsheet.api.setValue({
                                row: row,
                                col: unitTypeLevelCol,
                                value: parseFloat(props.parseValue(cell.value == null || isNaN(cell.value) ? "0" : cell.value.toString()) as string),
                                sheetName: "UnitTypeLevel",
                            });
                        }
                    }
                }


                if (hasUnittypeLevelDiffs) {
                    setPreparedUpdates({ values, cellsForUndo });

                }
                else {
                    if (preparedUpdates) {
                        for (const restoreValue of Object.values(preparedUpdates.cellsForUndo)) {
                            spreadsheet.api.setValue({ row: restoreValue.row, col: restoreValue.col, sheetName: "UnitTypeLevel", value: restoreValue.value })
                        }
                        setPreparedUpdates(undefined);
                    }
                    if (values.length > 0) {
                        // persist values
                        saveValues(currentVersionId, values);
                    }
                }


            }
            else if (spreadsheet.api.getActiveSheetIndex() == spreadsheet.api.getSheetIndex("UnitTypeLevel")) {

                // grab updates from unit type level worksheet
                const lastRow = UNIT_FIRST_VALUE_ROW + unitTypes.length - 1;
                const firstCol = UNIT_FIRST_VALUE_COL + (type == BudgetingType.REFORECAST ? startReforecastMonthIndex : 0);
                const lastCol = UNIT_FIRST_VALUE_COL + 11;
                const cellsToUpdate = spreadsheet.cellsChanged.filter(cell => cell.sheetName == "UnitTypeLevel" && cell.row >= UNIT_FIRST_VALUE_ROW && cell.row <= lastRow && cell.col >= firstCol && cell.col <= lastCol);

                if (cellsToUpdate.length == 0) {
                    return;
                }

                const cols = cellsToUpdate.map(cell => cell.col - UNIT_FIRST_VALUE_COL).sort().dedupe();


                // sync updated aggregate from unit type level worksheet into property level worksheet
                for (const col of cols) {
                    const value = spreadsheet.api.getValue({ row: UNIT_FIRST_VALUE_ROW + unitTypes.length, col: UNIT_FIRST_VALUE_COL + col, sheetName: "UnitTypeLevel" }) as number;
                    spreadsheet.api.setValue({ row: FIRST_VALUE_ROW, col: FIRST_VALUE_COLUMN + col, value: value, sheetName: "PropertyLevel" });
                }

                // persist updated
                const values = [];

                for (const cell of cellsToUpdate) {
                    const monthIndex = cell.col - UNIT_FIRST_VALUE_COL;
                    const unitType = spreadsheet.api.getValue({ row: cell.row, col: UNIT_FIRST_VALUE_COL - 1, sheetName: "UnitTypeLevel" }) as string;
                    values.push({
                        monthIndex: monthIndex,
                        unitTypeName: unitType,
                        value: parseFloat(cell.value?.toString() ?? "0").toString(),
                    });

                }
                saveValues(currentVersionId, values);
            }

        },
        [spreadsheet.cellsChanged]
    );

    useEffect(
        () => {
            if (spreadsheet.cellTargetClicked) {
                const activeSheetIndex = spreadsheet.api.getActiveSheetIndex();
                const propertySheetIndex = spreadsheet.api.getSheetIndex("PropertyLevel");

                if (activeSheetIndex == propertySheetIndex) {
                    spreadsheet.api.setActiveSheetIndex(spreadsheet.api.getSheetIndex("UnitTypeLevel"));
                    spreadsheet.api.updateSpreadsheetViewPort({ startRow: UNIT_FIRST_VALUE_ROW - 1, startCol: UNIT_FIRST_VALUE_COL - 1, rows: unitsCount.current + 2, cols: 14, sheetName: "UnitTypeLevel" });
                }
                else {
                    spreadsheet.api.setActiveSheetIndex(spreadsheet.api.getSheetIndex("PropertyLevel"));
                    spreadsheet.api.updateSpreadsheetViewPort({ startRow: FIRST_VALUE_ROW - 1, startCol: FIRST_VALUE_COLUMN - 1, rows: 5, cols: 14, sheetName: "PropertyLevel" });
                }
            }
        },
        [spreadsheet.cellTargetClicked]
    );

    const cancelUnitlevelOverride = () => {
        spreadsheet.api.directAccess(spread => {
            spread.undoManager().undo();
        });
    };

    const confirmUnitlevelOverride = async () => {
        if (preparedUpdates) {
            const { reforecastVersionId, nextYearBudgetVersionId } = getModelVersions(year);
            const currentVersionId = props.type == BudgetingType.REFORECAST ? reforecastVersionId : nextYearBudgetVersionId;
            await saveValues(currentVersionId, preparedUpdates.values);
            setPreparedUpdates(undefined);
            spreadsheet.api.directAccess(spread => {
                spread.undoManager().clear();
            });
        }
    };

    return (

        <div className="mt-3">
            {preparedUpdates && (
                <Modal
                    onClose={() => cancelUnitlevelOverride()}
                    backdropProps={{
                        style: {
                            backgroundColor: "#33384233",
                            marginTop: "75px"
                        }
                    }}
                    focusOnMount={true}
                    isAnimated={false}
                >
                    <Header isDanger>Confirm overwrite</Header>
                    <Body>
                        Are you sure you want to enter values at the property level? Doing so will overwrite the values entered at the unit type level. This cannot be undone.
                    </Body>
                    <Footer>
                        <FooterItem>
                            <Button onClick={() => cancelUnitlevelOverride()} isBasic>
                                Cancel
                            </Button>
                        </FooterItem>
                        <FooterItem>
                            <Button isPrimary isDanger onClick={() => {
                                confirmUnitlevelOverride();
                            }}>
                                Confirm
                            </Button>
                        </FooterItem>
                    </Footer>
                    <Close aria-label="Close modal" />
                </Modal>
            )}

            <div className={`operational-table-container table-average-rent bg-white p-0 ${spreadsheetContainer}`}>
                <NewSpreadsheet startRow={4} startCol={4} rows={5} cols={14} handlers={spreadsheet.handlers} subscribeToMouse={true} readOnly={readOnly} />
            </div>
            <OperationalFinancialMetrics type={type} />

        </div>

    );
}
