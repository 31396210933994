import { Tag } from "@zendeskgarden/react-tags";
import { ReactElement } from "react";
import * as css from "../styles/OpDriverModeling.module.scss";
import { ReactComponent as LockIcon } from '@zendeskgarden/svg-icons/src/16/lock-locked-stroke.svg';

export interface ILockStatusProps {
    budgetYear: number,
    reforecastLocked: boolean,
    budgetLocked: boolean,
}

export default function LockStatus({budgetYear, reforecastLocked, budgetLocked}: ILockStatusProps): ReactElement {
    return (
        <div className={css.lockStatusTags}>
            {reforecastLocked &&
                <Tag className={css.lockStatusTag}>
                    <LockIcon className={css.lockStatusTagIcon} /> {budgetYear - 1} Reforecast
                </Tag>
            }

            {budgetLocked &&
                <Tag className={css.lockStatusTag}>
                    <LockIcon className={css.lockStatusTagIcon} /> {budgetYear} Budget
                </Tag>
            }
        </div>
    );
}
