import { ReactElement, useState, useEffect } from "react";
import * as css from "../styles/coaManagement.module.scss";
import { FinancialEntityType } from "../../../__generated__/generated_types";
import { Field, MediaInput, Radio, Label, Fieldset, Message } from '@zendeskgarden/react-forms';
import { Body, Close, Footer, FooterItem, Header, Modal } from "@zendeskgarden/react-modals";
import { Button } from "@zendeskgarden/react-buttons";
import { ItemData } from "../types";

export type EditRecordProps = {
    existingItems: ItemData[];
    initialGlNumber?: string;
    initialGlName?: string;
    itemId?: string;
    initialType: FinancialEntityType;
    allowChooseType: boolean;
    title: string;
    onCancel: () => void;
    onSubmit: (glNumber: string | undefined, glName: string, type: FinancialEntityType) => void;
}
export function EditRecord(props: EditRecordProps): ReactElement {
    const [glNumber, setGlNumber] = useState<string | undefined>(props.initialGlNumber);
    const [glName, setGlName] = useState<string | undefined>(props.initialGlName);
    const [valid, setValid] = useState({valid: true, error: ""});
    const [type, setType] = useState<FinancialEntityType>(props.initialType);

    useEffect(() => {
        let valid = true;
        let error = "";

        if (glNumber !== props.initialGlNumber || glName !== props.initialGlName) {
            if (type == "ACCOUNT" && (!glNumber || glNumber.trim() == "")) {
                valid = false;
                error = "Account must have number";
            }
            else {
                const sameFound = props.existingItems.filter(item => !item.isDeleted).find(item =>
                    item.id != props.itemId
                    &&
                    (
                        item.type == type
                        && item.glName == glName
                        && (
                            !item.glNumber && (!glNumber || glNumber == "")
                            || item.glNumber == glNumber
                        )
                        ||
                        item.glNumber == glNumber && item.glNumber && item.glNumber != ""
                    )
                );
                valid = sameFound == undefined;
                if (!valid) {
                    error = "Same Record Exists";
                }
            }
        }

        setValid({valid: valid, error: error});
    }, [glName, glNumber, type]);

    return (
        <Modal onClose={props.onCancel} isLarge>
            <Header>
                {props.title}
            </Header>
            <Body>
                <form className={css.editForm}>
                    <Field>
                        <Label>Account Number</Label>
                        <MediaInput value={glNumber} onChange={e => setGlNumber(e.target.value)} />
                    </Field>
                    <Field>
                        <Label>Account Name</Label>
                        <MediaInput value={glName} onChange={e => setGlName(e.target.value)} />
                    </Field>
                    {props.allowChooseType &&
                    <Fieldset>
                        <Label isRegular={false}>Choose Type</Label>
                        <Field>
                            <Radio
                                value={FinancialEntityType.Account}
                                checked={type == FinancialEntityType.Account}
                                onChange={() => setType(FinancialEntityType.Account)}
                                disabled={!props.allowChooseType}
                            >
                                <Label isRegular>{FinancialEntityType.Account.toPascalCase()}</Label>
                            </Radio>
                        </Field>
                        <Field>
                            <Radio
                                value={FinancialEntityType.Category}
                                checked={type == FinancialEntityType.Category}
                                onChange={() => setType(FinancialEntityType.Category)}
                                disabled={!props.allowChooseType}
                            >
                                <Label isRegular>{FinancialEntityType.Category.toPascalCase()}</Label>
                            </Radio>
                        </Field>
                    </Fieldset>
                    }
                    {!valid.valid &&
                    <Message validation="error">{valid.error}</Message>
                    }
                </form>
            </Body>
            <Footer>
                <FooterItem>
                    <Button onClick={props.onCancel} isBasic>
                        Cancel
                    </Button>
                </FooterItem>
                <FooterItem>
                    <Button
                        disabled={!valid || (glNumber == props.initialGlNumber && glName == props.initialGlName && type == props.initialType)}
                        onClick={() => { if (glName) props.onSubmit(glNumber, glName, type) }}
                    >
                        Apply
                    </Button>
                </FooterItem>
            </Footer>
            <Close aria-label="Close modal" />
        </Modal>
    );
}

