import React from "react";
import { Col, Grid, Row } from "@zendeskgarden/react-grid";

import * as css from "./styles/css.module.scss";
import { ActiveView, IUsePropertyDriversReturn } from "../logic/usePropertyDrivers";


interface IProgressIndicatorProps {
    pd: IUsePropertyDriversReturn;
}

export default function ProgressIndicator({
    pd,
}: IProgressIndicatorProps): React.ReactElement {
    const renderIcon = (step: number) => {
        let className = '';
        switch (step) {
            case 1: {
                const conditionalClassName = pd.activeView === ActiveView.editFormulas
                    ? css.activeViewIcon
                    : css.inactiveViewIcon;
                className = `${css.icon} ${conditionalClassName}`;
                break;
            }
            case 2: {
                const conditionalClassName = pd.activeView === ActiveView.editAssumptions
                    ? css.activeViewIcon
                    : css.inactiveViewIcon;
                className = `${css.icon} ${conditionalClassName}`;
                break;
            }
            default:
                // Unlikely
                break;
        }

        return (
            <div className={className}>
                {step}
            </div>
        );
    };

    return (
        <div className={css.progressIndicator}>
            <div className={css.progressIndicatorRow}>
                <div className={css.stepContainer}>
                    <div className={css.iconContainer}>
                        {renderIcon(1)}
                    </div>
                    <div className={css.currentStepTitle}>
                        Edit Formulas
                    </div>
                </div>
                <div className={css.horizontalLine}></div>
                <div className={css.stepContainer}>
                    <div className={css.iconContainer}>
                        {renderIcon(2)}
                    </div>
                    <div className={css.currentStepTitle}>
                        Edit Assumptions
                    </div>
                </div>
            </div>
        </div>
    );
}
