// noinspection DuplicatedCode
import {
    renderChevronToggle,
    renderPlusMinusLoading,
    renderPlusMinusToggle,
    renderSetupBtn
} from "./icon-renderers/draw_expand_toggle";

import GC from "@grapecity/spread-sheets";
import { ICustomCellInfo, ICustomCellToggleHitInfo } from "./types";
import Style = GC.Spread.Sheets.Style;
import { CellDecoratorSpec } from "./icons/CellDecoratorSpecs";

export interface ExpandableCategoryCellInfo extends ICustomCellInfo {
    level: number,
    isOpen: boolean,
    canOpen: boolean,
    renderChevron: boolean,
    renderSetupBtn: boolean,
    baseToggleSpaceWidth: number,
    subLevelXOffset: number,
    style?: Style,
    padding?: number,
    hideToggle?:boolean,
    leftIconSpecs?: CellDecoratorSpec,
    rightIconSpecs?: CellDecoratorSpec,
    isLoaded?: boolean,
    row?: number,
}

export class ExpandableCategoryCell extends GC.Spread.Sheets.CellTypes.Text {
    constructor(private cellInfo: ExpandableCategoryCellInfo) {
        super();
        this.typeName = "ExpandableCategoryCell";
        this.cellInfo = cellInfo;
    }

    showPointer = false;

    getCellInfo():ExpandableCategoryCellInfo{
        return this.cellInfo;
    }

    updateCellInfo(cellInfo: ExpandableCategoryCellInfo):void {
        this.cellInfo = cellInfo;
    }

    setStyle(newStyle:Style):void{
        this.cellInfo.style = newStyle;
    }

    override paint(ctx: CanvasRenderingContext2D, value: any, x: number, y: number, w: number, h: number, style: GC.Spread.Sheets.Style, context?: any): void {

        if(this.cellInfo.style){
            style = {
                ...style,
                ...this.cellInfo.style,
            } as GC.Spread.Sheets.Style;
        }

        super.paint(ctx, value, x, y, w, h, style, context);

        if (this.showPointer) {
            const spread = context.sheet.getParent();
            const canvasEl: HTMLCanvasElement = spread
                .getHost()
                .querySelector('[gcuielement="gcWorksheetCanvas"]');
            if (canvasEl && canvasEl.style.cursor === "default") {
                canvasEl.style.cursor = "pointer";
            }
        }
    }

    override paintContent(ctx: CanvasRenderingContext2D, value: any, x: number, y: number, w: number, h: number, style: GC.Spread.Sheets.Style, context?: any): void {
        // const togglePadW = this.cellInfo.baseToggleSpaceWidth + (this.cellInfo.subLevelXOffset * this.cellInfo.level);
        const leftIconXOffset = (this.cellInfo.leftIconSpecs?.x || 0) + (this.cellInfo.subLevelXOffset * this.cellInfo.level);

        if(this.cellInfo.style){
            style = {
                ...style,
                ...this.cellInfo.style,
            } as GC.Spread.Sheets.Style;
        }

        super.paintContent(ctx, value, x, y, w, h, style, context);

        if( !this.cellInfo.hideToggle ){

            if( !this.cellInfo.isLoaded && !this.cellInfo.renderChevron ){

                renderPlusMinusLoading(ctx, x + leftIconXOffset, y + (this.cellInfo.leftIconSpecs?.y || 0));

            } else if( this.cellInfo.canOpen ){

                if (this.cellInfo.renderChevron) {
                    renderChevronToggle(ctx, x + (this.cellInfo.rightIconSpecs?.x || 0), y + (this.cellInfo.rightIconSpecs?.y || 0), this.cellInfo.isOpen);
                } else {
                    renderPlusMinusToggle(ctx, x + leftIconXOffset, y + (this.cellInfo.leftIconSpecs?.y || 0), this.cellInfo.isOpen);
                }
            }
        }

        if (this.cellInfo.renderSetupBtn) {
            renderSetupBtn(ctx, x + w - 31 - 46, y, 75, 24, this.showPointer);
        }
}

    override getHitInfo(x: number, y: number, _cellStyle: GC.Spread.Sheets.Style, cellRect: GC.Spread.Sheets.Rect, context?: any): GC.Spread.Sheets.IHitTestCellTypeHitInfo {
        const c = cellRect; // alias
        const [row, col] = [context.row as number, context.col as number];

        let iconClicked;
        if (this.cellInfo.renderSetupBtn) {
            iconClicked = c.x + c.width - 31 - 75 < x && x < c.x + c.width - 31;
        }

        return {
            x,
            y,
            row,
            col,
            cellRect,
            isOpen: this.cellInfo.isOpen,
            highlightToggle: iconClicked,
            isTargetHit: iconClicked,
        } as ICustomCellToggleHitInfo;
    }

    override processMouseMove(hitInfo: GC.Spread.Sheets.IHitTestCellTypeHitInfo): boolean {
        const hi = hitInfo as ICustomCellToggleHitInfo;
        this.showPointer = hi.isReservedLocation || hi.isTargetHit || false;
        return true;
    }

    override processMouseLeave(_hitInfo: GC.Spread.Sheets.IHitTestCellTypeHitInfo): boolean {
        this.showPointer = false;
        return true;
    }
}
