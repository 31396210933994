import { ReactElement, useContext } from "react";
import { PropertyExecutiveSummary } from "./PropertyExecutiveSummary";
import { useProperties } from "../../contexts/properties/PropertiesContext";
import { AuthContext } from "../../contexts/AuthContext";

export function PropertyExecutiveSummaryRegularWrapper(): ReactElement {
    const {currentProperty} = useProperties();
    const {user} = useContext(AuthContext);
    return (
        user && currentProperty ?
        <PropertyExecutiveSummary user={{...user, isGuest: false}} property={currentProperty}/>
        :
        <></>
    );
}