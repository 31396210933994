import { ReactElement, useEffect, useState } from "react";

import { GetSinglePropertyDriversAndWorksheetItemsNoCalcEngineQuery, GetSinglePropertyDriversAndWorksheetItemsQuery, VersionType } from "../../../../../__generated__/generated_types";
import { TDriverMetricsYear } from "../../../../../contexts/account/data/logic/driversAndWorksheetData";
import { OperationalDriverFxNode } from "../components/formula-nodes/OperationalDriverFxNode";
import { RevenueDriverFxNode } from "../components/formula-nodes/RevenueDriverFxNode";
import WorksheetDriverFxNode, { TWorksheetLineItem } from "../components/formula-nodes/WorksheetDriverFxNode";
import PayrollCalculationFxNode from "../components/formula-nodes/PayrollCalculationFxNode";
import RenovationsDriverFxNode, { TRenovationCost } from "../components/formula-nodes/RenovationsDriverFxNode";
import { IFormulaBarUpdates } from "../../../../../contexts/account/data/useFormulaBarUpdates";
import { TAllAvailableDrivers } from "../../../../../contexts/account/data/utils";
import PercentGrowthDriverFxNode from "../components/formula-nodes/PercentGrowthDriverFxNode";
import MonthlyAverageGrowthDriverFxNode from "../components/formula-nodes/MonthlyAverageGrowthDriverFxNode";
import AnnualTargetValueGrowthDriverFxNode from "../components/formula-nodes/AnnualTargetValueGrowthDriverFxNode";

import { ModelingMethodName } from "./utils";
import { AccountDriverFormulaManyNode } from "../components/formula-nodes/AccountDriverFormulaManyNode";
import { CustomDriverFxNode } from "../components/formula-nodes/CustomDriverFxNode";


interface IUseFormulaBarBuilderProps {
    accountId: string | undefined,
    // This is added for multi-year. For plan based budgeting, it will get replaced
    // by the global plan id.
    budgetYear: number,
    allAvailableDrivers: TAllAvailableDrivers,
    drivers: TDriverMetricsYear,
    isWorksheetDriven: boolean,
    year: number|undefined,
    version: VersionType.Reforecast|VersionType.Budget|undefined,
    rawData: GetSinglePropertyDriversAndWorksheetItemsQuery | GetSinglePropertyDriversAndWorksheetItemsNoCalcEngineQuery | undefined,
    readOnly: boolean,
    fbUpdates: IFormulaBarUpdates,
    worksheetTypeAhead?: string[] | undefined;
    isBulkUpdateBar?: boolean;
    isPropertyDriversUI?: boolean;
    editableFxBarChecker?: (modelingMethod: ModelingMethodName) => boolean;
    selectedAccountIds: string[];
    currentPropertyId?: string | undefined;
}

export interface IUseFormulaBarBuilderReturn {
    nodes: ReactElement[],
}

export default function useFormulaBarBuilder(props: IUseFormulaBarBuilderProps): IUseFormulaBarBuilderReturn {

    const [nodes, setNodes] = useState<ReactElement[]>([]);

    const getIsLocked = (modelingMethod: ModelingMethodName): boolean => {
        if (modelingMethod == "account" && props.isPropertyDriversUI && props.selectedAccountIds.length > 1 && !props.isBulkUpdateBar) {
            return true;
        }
        // If editableFxBarChecker was passed down, then evaluate against it
        if (props.editableFxBarChecker) {
            return props.readOnly || !(props.editableFxBarChecker(modelingMethod));
        }

        return props.readOnly;
    };

    useEffect(
            () => {
                if(!props.version || !props.year || !props.drivers || !props.fbUpdates.isReady){
                    return;
                }

                const builtNodes: ReactElement[] = [];

                const { drivers } = props;
                if(drivers){

                    const lastDriverType = getLastDriverType(drivers);

                    let nodeIdx = 0;

                    const accounts: {
                        name: string,
                        id: string,
                        lookbackPeriod: number|null|undefined,
                    }[] = drivers.account.map(driver => ({
                        name: driver.glName,
                        id: driver.accountId,
                        lookbackPeriod: driver.lookbackPeriod,
                    }));

                    if (accounts.length > 0) {
                        builtNodes.push(
                                <AccountDriverFormulaManyNode
                                        driverAccounts={accounts}
                                        driverAugments={drivers.accountPercentageAugment}
                                        locked={getIsLocked("account")}
                                        nodeId={`driver_node`}
                                        key={nodeIdx++}
                                        isLastDriverType={lastDriverType === 'account'}
                                        isBulkUpdateBar={props.isBulkUpdateBar}
                                        isPropertyDriversUI={props.isPropertyDriversUI}
                                        fbUpdates={props.fbUpdates}
                                        editableFxBarChecker={props.editableFxBarChecker}
                                        selectedAccountIds={props.selectedAccountIds}
                                        accountId={props.accountId ?? ""}
                                        budgetYear={props.budgetYear}
                                        versionType={props.version}
                                        currentPropertyId={props.currentPropertyId ?? ""}
                                />
                        );
                    }

                    // Operational drivers
                    drivers.operational.forEach((driver, idx, arr) => {
                        builtNodes.push(
                                <OperationalDriverFxNode
                                        driverAccount={{
                                            name: driver.type,
                                            id: driver.driverMetricTypeId,
                                            lookbackPeriod: driver.lookbackPeriod,
                                        }}
                                        locked={getIsLocked("operational")}
                                        nodeId={`operational_node_${idx}`}
                                        key={nodeIdx++}
                                        isLastDriverType={lastDriverType === 'operational' && idx == arr.length - 1}
                                        isBulkUpdateBar={props.isBulkUpdateBar}
                                        isPropertyDriversUI={props.isPropertyDriversUI}
                                        fbUpdates={props.fbUpdates}
                                        editableFxBarChecker={props.editableFxBarChecker}
                                        selectedAccountIds={props.selectedAccountIds}
                                />
                        );
                    });

                    // Revenue drivers
                    drivers.revenue.forEach((driver, idx, arr) => {
                        builtNodes.push(
                                <RevenueDriverFxNode
                                        revenueType={
                                            { revenueTypeKey: driver.revenueType, id: driver.sourceId }
                                        }
                                        nodeId={`revenue_node_${idx}`}
                                        locked={getIsLocked("revenue")}
                                        key={nodeIdx++}
                                        isLastDriverType={lastDriverType === 'revenue' && idx == arr.length - 1}
                                        fbUpdates={props.fbUpdates}
                                />
                        );
                    });

                    // Payroll drivers
                    // DEV: Haven > Hidden Acres > Payroll- Maintenance Technician(6352)
                    // DEV: http://localhost:3000/account/43a2b697-81d9-464f-9092-86df90a29a92/budget
                    if(drivers.payroll.length > 0){
                        drivers.payroll.forEach((each, i, arr) => {
                                builtNodes.push(
                                    <PayrollCalculationFxNode
                                            allDrivers={props.allAvailableDrivers}
                                            driver={each}
                                            nodeId={`payroll_calcs_node`}
                                            locked={getIsLocked("payroll")}
                                            isLastDriverType={lastDriverType === 'payroll'}
                                            isLastDriver={i === arr.length - 1}
                                            isWorksheetDriven={props.isWorksheetDriven}
                                            isBulkUpdateBar={props.isBulkUpdateBar}
                                            isPropertyDriversUI={props.isPropertyDriversUI}
                                            key={`payroll_driver_${each.itemType}`}
                                            fbUpdates={props.fbUpdates}
                                    />
                                );
                            }
                        );
                    }

                    // Renovation drivers
                    if(drivers.renovations.length > 0){
                        const renoCosts: TRenovationCost[] = drivers.renovations.map(renoCost => {
                            return {
                                name: renoCost.name,
                                id: renoCost.id,
                            };
                        });
                        builtNodes.push(
                                <RenovationsDriverFxNode
                                        renovationCosts={renoCosts}
                                        nodeId={`renovations_node`}
                                        locked={getIsLocked("renovations")}
                                        key={nodeIdx++}
                                        isLastDriverType={lastDriverType === 'renovations'}
                                        fbUpdates={props.fbUpdates}
                                        budgetYear={props.budgetYear}
                                />
                        );
                    }

                    // Monthly Average growth drivers
                    if (drivers.monthlyAverage.length > 0) {
                        const driver = drivers.monthlyAverage[0];
                        if (driver) {
                            builtNodes.push(
                                <MonthlyAverageGrowthDriverFxNode
                                    driver={driver}
                                    nodeId="monthly_average_growth_node"
                                    locked={getIsLocked("monthlyAverage")}
                                    key={nodeIdx++}
                                    isLastDriverType={lastDriverType === 'monthlyAverage'}
                                    fbUpdates={props.fbUpdates}
                                />
                            );
                        }
                    }

                    // % Growth drivers
                    if (drivers.percentGrowth.length > 0) {
                        builtNodes.push(
                            <PercentGrowthDriverFxNode
                                drivers={drivers.percentGrowth}
                                nodeId="percent_growth_node"
                                locked={getIsLocked("percentGrowth")}
                                key={nodeIdx++}
                                isLastDriverType={lastDriverType === 'percentGrowth'}
                                fbUpdates={props.fbUpdates}
                                versionType={props.version}
                            />
                        );
                    }

                    // Annual Target Value growth drivers
                    if (drivers.annualTargetValue.length > 0) {
                        builtNodes.push(
                            <AnnualTargetValueGrowthDriverFxNode
                                drivers={drivers.annualTargetValue}
                                nodeId="annual_target_value_growth_node"
                                locked={getIsLocked("annualTargetValue")}
                                key={nodeIdx++}
                                isLastDriverType={lastDriverType === 'annualTargetValue'}
                                fbUpdates={props.fbUpdates}
                            />
                        );
                    }

                    // Worksheet drivers
                    /**
                     * In a dirty state where the user has deleted a worksheet, we don't want an empty (Line items: ) node to show
                     */
                    if(props.fbUpdates.isUserRequestingWorksheetDeletion === false && (drivers.worksheet.length > 0 || props.isWorksheetDriven)){
                        const lineItems: TWorksheetLineItem[] = drivers.worksheet.map(lineItem => {
                            return {
                                name: lineItem.description,
                                locked: props.readOnly, // TODO: Replace w/ line item locked value (need to add to API response)
                            };
                        });
                        builtNodes.push(
                                <WorksheetDriverFxNode
                                        accountId={props.accountId}
                                        budgetYear={props.budgetYear}
                                        lineItems={lineItems}
                                        nodeId={'worksheet_node'}
                                        locked={getIsLocked("worksheet")}
                                        key={nodeIdx++}
                                        isLastDriverType={lastDriverType === 'worksheet'}
                                        isWorksheetDriven={props.isWorksheetDriven}
                                        isBulkUpdateBar={props.isBulkUpdateBar}
                                        isPropertyDriversUI={props.isPropertyDriversUI}
                                        fbUpdates={props.fbUpdates}
                                        worksheetTypeAhead={props.worksheetTypeAhead}
                                />
                        );
                    }

                    // Custom Drivers
                    drivers.customDriver.forEach((driver, idx, arr) => {
                        builtNodes.push(
                                <CustomDriverFxNode
                                        id={driver.id}
                                        itemName={driver.itemName}
                                        locked={getIsLocked("customDriver")}
                                        nodeId={`custom_driver_node_${idx}`}
                                        key={nodeIdx++}
                                        isLastDriverType={lastDriverType === 'customDriver' && idx == arr.length - 1}
                                        isBulkUpdateBar={props.isBulkUpdateBar}
                                        isPropertyDriversUI={props.isPropertyDriversUI}
                                        fbUpdates={props.fbUpdates}
                                        editableFxBarChecker={props.editableFxBarChecker}
                                        selectedAccountIds={props.selectedAccountIds}
                                />
                        );
                    });
                }

                setNodes(builtNodes);
            },
            [props.fbUpdates.isReady, props.fbUpdates.options, props.version, props.year, props.drivers, props.budgetYear, props.worksheetTypeAhead]
    );

    return { nodes };
}

type TLastDriverType = 'account'|'operational'|'revenue'|'payroll'|'renovations'|'worksheet'|'monthlyAverage'|'percentGrowth'|'annualTargetValue'|'customDriver'|undefined;

const getLastDriverType = (drivers:TDriverMetricsYear):TLastDriverType => {
    let isLast:TLastDriverType;
    if(drivers.account.length > 0){
        isLast = 'account';
    }
    if(drivers.operational.length > 0){
        isLast = 'operational';
    }
    if(drivers.revenue.length > 0){
        isLast = 'revenue';
    }
    if(drivers.payroll.length > 0){
        isLast = 'payroll';
    }
    if(drivers.renovations.length > 0){
        isLast = 'renovations';
    }
    if(drivers.worksheet.length > 0){
        isLast = 'worksheet';
    }
    if(drivers.monthlyAverage.length > 0){
        isLast = 'monthlyAverage';
    }
    if(drivers.percentGrowth.length > 0){
        isLast = 'percentGrowth';
    }
    if(drivers.annualTargetValue.length > 0){
        isLast = 'annualTargetValue';
    }
    if(drivers.customDriver.length > 0){
        isLast = 'customDriver';
    }
    return isLast;
};
