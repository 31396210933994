import Handsontable from "handsontable";
import { IMvrContextState } from "../../context/MonthlyVarianceReportContext";
import { MvrTableCols } from "./mvrSpreadsheet";
import { VersionType } from "../../../../../__generated__/generated_types";

type hotCellChange = {
    row: number,
    prop: string,
    oldVal: string|number|null|undefined,
    newVal: string|number|null|undefined,
}

export const onCellChange = (
    changes: Handsontable.CellChange[]|null,
    _source: Handsontable.ChangeSource,
    hotRef:Handsontable,
    mvrContext:IMvrContextState
):void => {
    if(hotRef && changes && mvrContext){
        changes.forEach( change => {
            const [row, colName, oldValue, newValue] = change;

            if(oldValue === newValue){
                return;
            }

            switch(colName){
                case 'varianceNote': {
                    const rowData = hotRef.getDataAtRow(row);
                    const accountId = rowData[MvrTableCols.util_entityId];
                    if(
                        rowData
                        && accountId
                        && mvrContext.mvrReportYear != undefined
                        && mvrContext.mvrReportMonth != undefined
                        && mvrContext.reportPropertyId != undefined
                    ){
                        mvrContext.setVarianceNote({
                            note: newValue,
                            accountId: accountId,
                            propertyId: mvrContext.reportPropertyId,
                            year: mvrContext.mvrReportYear,
                            monthIndex:mvrContext.mvrReportMonth,
                            versionType: VersionType.Budget,
                        });
                    }
                    break;
                }
            }
        })
    }
};