import GC from "@grapecity/spread-sheets";
import { NewSpreadsheetAPI } from "../../../../../components/spreadsheet/NewSpreadsheetTypes";
import { TwoYearsConfig as TWO_YR_CFG } from "../../../../../sjs/layout/two-years/helpers/TwoYearsConfig";
// TODO: Promote ../../../../analyst/config/useConfig to a more global location (not Analyst)
import { Config } from "../../../../analyst/config/useConfig";

export const restoreCellValue = (cell:AccurateValueTypesChangedCell, ssapi:NewSpreadsheetAPI):void => {
    ssapi.directAccess( spread => {
        const sheet = spread.getSheetFromName(TWO_YR_CFG.MAIN_TAB_NAME);
        sheet.setValue(cell.row, cell.col, cell.oldValue);
    });
};

export const getMonthIdx = (cell:AccurateValueTypesChangedCell, config:Config):number|undefined => {
    if(cell.col > config.startReforecastMonthIndex && cell.col < TWO_YR_CFG.TOTALS_COL){
        return cell.col - TWO_YR_CFG.FIRST_MONTH_COL;
    } else if(cell.col >= TWO_YR_CFG.FIRST_BUDGET_MONTH_COL && cell.col < TWO_YR_CFG.BUDGET_TOTALS_COL){
        return cell.col - TWO_YR_CFG.FIRST_BUDGET_MONTH_COL + 12;
    }
    return;
};

// TODO: Update NewSpreadsheetAPI > ChangedCell to accept the possible runtime type of string.
export type AccurateValueTypesChangedCell = {
    row: number;
    col: number;
    value: number | string | null;
    sheetName: string;
    sheetArea?: GC.Spread.Sheets.SheetArea;
    propertyName?: string,
    oldValue?:any,
    isUndo?: boolean;
};