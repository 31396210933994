import { ReactElement, useMemo, useState } from "react";
import { Body, Close, Footer, FooterItem, Header, Modal } from "@zendeskgarden/react-modals";
import { Field, Dropdown, Item, Menu, Select } from "@zendeskgarden/react-dropdowns";
import { Field as FormField, Label, Input } from "@zendeskgarden/react-forms";
import { Datepicker } from "@zendeskgarden/react-datepickers";
import { Button } from "@zendeskgarden/react-buttons";
import { Inline } from "@zendeskgarden/react-loaders";
import { DateFormatter } from "../../../components/analyst-payroll-drawer/helpers/utils";
import { ensureDateInLocale, ensureDateInUtc } from "../../../utils/helper-funcs";

export type BulkUpdateBonusRaiseProps = {
    onClose: () => void;
    onConfirmOnceFixed: (
        onetimeDate: Date,
        amount: number
    ) => void;
    onConfirmOncePercent: (
        onetimeDate: Date,
        percent: number
    ) => void;
    onConfirmRecurringFixed: (
        recurringMonth: number,
        recurringDay: number,
        amount: number
    ) => void;
    onConfirmRecurringPercent: (
        recurringMonth: number,
        recurringDay: number,
        amount: number
    ) => void;
    loading: boolean;
    isCreate?: boolean;
    updateWhat: "raises" | "bonuses";
}

export enum Type {
    "once_fixed" = "once_fixed",
    "once_percent" = "once_percent",
    "recurring_fixed" = "recurring_fixed",
    "recurring_percent" = "recurring_percent"
}

const typeLabel = (type: Type | undefined) =>
    type == "once_fixed" ? "One-Time ($)"
    : type == "once_percent" ? "One-Time (%)"
    : type == "recurring_fixed" ? "Annually Recurring ($)"
    : type == "recurring_percent" ? "Annually Recurring  (%)"
    : "";

const whatSingular = (what: "raises" | "bonuses") =>
    what == "raises" ? "raise"
    : "bonus";

const D28 = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28];
const D30 = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30];
const D31 = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31];
const DAYS = new Map<number, number[]>([
    [1, D31],
    [2, D28],
    [3, D31],
    [4, D30],
    [5, D31],
    [6, D30],
    [7, D31],
    [8, D31],
    [9, D30],
    [10, D31],
    [11, D30],
    [12, D31]
]);

export function BulkUpdateBonusRaise(props: BulkUpdateBonusRaiseProps): ReactElement {
    const [type, setType] = useState<Type>();
    const [onetimeDate, setOnetimeDate] = useState<Date>();
    const [recurringMonth, setRecurringMonth] = useState<number>();
    const [recurringDay, setRecurringDay] = useState<number>();
    const [percent, setPercent] = useState<number>();
    const [amount, setAmount] = useState<number>();

    function handleSelectType(type: Type) {
        setType(type);
        setOnetimeDate(undefined);
        setRecurringMonth(undefined);
        setRecurringDay(undefined);
        setPercent(undefined);
        setAmount(undefined);
    }

    function handleConfirm() {
        if (type == Type.once_fixed && onetimeDate != undefined && amount != undefined) {
            props.onConfirmOnceFixed(onetimeDate, amount);
        }
        else if (type == Type.once_percent && onetimeDate != undefined && percent != undefined) {
            props.onConfirmOncePercent(onetimeDate, percent);
        }
        else if (type == Type.recurring_fixed && recurringMonth != undefined && recurringDay != undefined && amount != undefined) {
            props.onConfirmRecurringFixed(recurringMonth, recurringDay, amount);
        }
        else if (type == Type.recurring_percent && recurringMonth != undefined && recurringDay != undefined && percent != undefined) {
            props.onConfirmRecurringPercent(recurringMonth, recurringDay, percent);
        }

    }

    const canConfirm = useMemo(
        () =>
            type == Type.once_fixed && onetimeDate != undefined && amount != undefined
            || type == Type.once_percent && onetimeDate != undefined && percent != undefined
            || type == Type.recurring_fixed && recurringMonth != undefined && recurringDay != undefined && amount != undefined
            || type == Type.recurring_percent && recurringMonth != undefined && recurringDay != undefined && percent != undefined
        ,
        [type, onetimeDate, recurringMonth, recurringDay, percent, amount]
    );

    return (
    <Modal onClose={() => props.onClose()} isLarge style={{overflow: "unset"}}>
        <Header>
            Bulk {props.isCreate == undefined || !props.isCreate? "Update" : "Create"} {props.updateWhat.capitalize()}
        </Header>
        <Body>
            <div style={{flexDirection: "column", display: "flex", height: "500px"}}>
                <Dropdown
                    selectedItem={type}
                    onSelect={(v) => handleSelectType(v)}
                >
                    <Label>
                        Type
                    </Label>
                    <Field>
                        <Select>
                            {typeLabel(type)}
                        </Select>
                    </Field>
                    <Menu>
                        {Object.keys(Type).map(option => (
                            <Item key={option} value={option}>
                                {typeLabel(Type[option as keyof typeof Type])}
                            </Item>
                        ))}
                    </Menu>
                </Dropdown>
                {(type == Type.once_fixed || type == Type.once_percent) &&
                <div style={{display: "flex", flexDirection: "row", marginTop: "1rem"}}>
                    {(type == Type.once_fixed) &&
                    <FormField>
                        <Label>
                            {`${whatSingular(props.updateWhat).capitalize()} Amount${props.updateWhat == "raises" ? " (per hour)" : ""}`}
                        </Label>
                        <Input
                            placeholder="$"
                            value={amount}
                            type={"number"}
                            onChange={(evt) => {
                                const val = Number.parseFloat(evt.target.value);
                                if (Number.isNaN(val)) {
                                    setAmount(undefined);
                                }
                                else {
                                    setAmount(val);
                                }
                            }}
                        />
                    </FormField>
                    }
                    {(type == Type.once_percent) &&
                    <FormField>
                        <Label>
                            {`${whatSingular(props.updateWhat).capitalize()} Percentage`}
                        </Label>
                        <Input
                            placeholder="%"
                            value={percent}
                            type={"number"}
                            onChange={(evt) => {
                                const val = Number.parseFloat(evt.target.value);
                                if (Number.isNaN(val)) {
                                    setPercent(undefined);
                                }
                                else {
                                    setPercent(val);
                                }
                            }}
                        />
                    </FormField>
                    }
                    <FormField style={{marginLeft: "auto"}}>
                        <Label>Effective Date</Label>
                        <Datepicker
                            value={ensureDateInLocale(onetimeDate)}
                            onChange={(date) => setOnetimeDate(ensureDateInUtc(date))}
                            formatDate={date => {
                                return date ? DateFormatter.format(ensureDateInLocale(date)) : "";
                            }}
                        >

                            <Input placeholder="Select Date"/>
                        </Datepicker>
                    </FormField>
                </div>
                }
                {(type == Type.recurring_fixed || type == Type.recurring_percent) &&
                <div style={{display: "flex", flexDirection: "row", marginTop: "1rem"}}>
                    {(type == Type.recurring_fixed) &&
                    <FormField>
                        <Label>
                            {`${whatSingular(props.updateWhat).capitalize()} Amount${props.updateWhat == "raises" ? " (per hour)" : ""}`}
                        </Label>
                        <Input
                            placeholder="$"
                            value={amount}
                            type={"number"}
                            onChange={(evt) => {
                                const val = Number.parseFloat(evt.target.value);
                                if (Number.isNaN(val)) {
                                    setAmount(undefined);
                                }
                                else {
                                    setAmount(val);
                                }
                            }}
                        />
                    </FormField>
                    }
                    {(type == Type.recurring_percent) &&
                    <FormField>
                        <Label>
                            {`${whatSingular(props.updateWhat).capitalize()} Percentage`}
                        </Label>
                        <Input
                            placeholder="%"
                            value={percent}
                            type={"number"}
                            onChange={(evt) => {
                                const val = Number.parseFloat(evt.target.value);
                                if (Number.isNaN(val)) {
                                    setPercent(undefined);
                                }
                                else {
                                    setPercent(val);
                                }
                            }}
                        />
                    </FormField>
                    }
                    <div style={{marginLeft: "auto", display: "flex", flexDirection: "row"}}>
                        <div>
                            <Dropdown
                                selectedItem={type}
                                onSelect={(v) => {setRecurringMonth(Number(v)); setRecurringDay(undefined)}}
                            >
                                <Label style={{marginBottom: "8px"}}>
                                    Effective Month
                                </Label>
                                <Field>
                                    <Select>
                                        {recurringMonth ?? ""}
                                    </Select>
                                </Field>
                                <Menu>
                                    {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map(option => (
                                        <Item key={option} value={option}>
                                            {option}
                                        </Item>
                                    ))}
                                </Menu>
                            </Dropdown>
                        </div>
                        <div>
                            <Dropdown
                                selectedItem={type}
                                onSelect={(v) => setRecurringDay(Number(v))}
                            >
                                <Label style={{marginBottom: "8px"}}>
                                    Effective Day
                                </Label>
                                <Field>
                                    <Select>
                                        {recurringDay ?? ""}
                                    </Select>
                                </Field>
                                <Menu>
                                    {recurringMonth == undefined ?
                                    null
                                    :
                                    DAYS.get(recurringMonth)?.map(option => (
                                        <Item key={option} value={option}>
                                            {option}
                                        </Item>
                                    )) ?? []
                                    }
                                </Menu>
                            </Dropdown>
                        </div>
                    </div>
                </div>
                }
            </div>
        </Body>
        <Footer>
            <FooterItem>
                <Button onClick={() => props.onClose()} isBasic disabled={false}>
                    Cancel
                </Button>
            </FooterItem>
            <FooterItem>
                {
                    <Button
                        isPrimary
                        onClick={() => handleConfirm()}
                        disabled={props.loading || !canConfirm}
                    >
                        {
                            props.loading
                                ? <Inline size={24} aria-label="loading"/>
                                :
                                 <span>Confirm</span>
                        }
                    </Button>
                }
            </FooterItem>
        </Footer>
        <Close aria-label="Close modal" />
    </Modal>

    );
}