import { RowEntityType } from './../enums';
import { FinancialEntityCellInfo } from './../../../components/spreadsheet/spreadjs-custom-cell-types/FinancialEntityCell';
import GC from "@grapecity/spread-sheets";
import React from "react";
import { AnalystCfg } from "../AnalystCfg";
import { AnalystEntityType, MouseoverRow } from "../types";
import { AnalystStyles } from "../AnalystStyles";
import { CellHitInfo, NewSpreadsheetAPI } from "../../../components/spreadsheet/NewSpreadsheetTypes";
import { CloseRowAction, OpenRowAction, RowMapAction, RowMapEntry } from "../AnalystState";
import {
    DriverType,
} from "../../../__generated__/generated_types";
import {
    ExpandableCategoryCell,
    ExpandableCategoryCellInfo
} from "../../../components/spreadsheet/spreadjs-custom-cell-types/ExpandableCategoryCell";
import { CellDecoratorSpec } from "../../../components/spreadsheet/spreadjs-custom-cell-types/icons/CellDecoratorSpecs";
import { WorksheetStyles } from "../../../components/analyst/worksheet/WorksheetStyles";
import { v4 as uuid } from "uuid";
import { OpDriverMetric, OpDriverPropertyUnit } from "../tab-op-drivers/types";
import { OpDriverMetricType } from "../tab-op-drivers/enums";
import {
    FinancialEntityCell,
    FinancialEntityCellVars
} from "../../../components/spreadsheet/spreadjs-custom-cell-types/FinancialEntityCell";
import { isFinancialEntityCell } from "./CustomCellHelpers";

enum SheetArea {
    YEAR,
    BUDGET,
    ACTUALS,
    REFORECAST,
}

type CellSpanStyles = {
    leftCap: GC.Spread.Sheets.Style,
    centerSpan: GC.Spread.Sheets.Style,
    rightCap: GC.Spread.Sheets.Style,
}

export interface AnalystTimeBasedLayoutConfig {
    name: string,
    ssapi: NewSpreadsheetAPI,
    firstReforecastMonth: number,
    months: string[],
    year: number,
    financialEntityColLabel: string,
    hideDriversCol?: boolean,
    hideColumns?: number[],
    canRenderSetup: boolean,
    lockedProperties: {
        reforecastLocked: string[],
        budgetLocked: string[],
    },
}

export class AnalystTimeBasedLayout {
    protected _ssapi: NewSpreadsheetAPI;
    protected _months: string[];

    protected _firstReforecastMonth: number;
    protected _year: number;

    protected _financialEntityLabel: string;
    public financialEntityColumnLabelOffset = "     ";

    protected _hideDriversCol: boolean | undefined;
    protected _hideColumns: number[] | undefined = [];

    protected _name: string;
    protected readonly _uuid: string;

    protected _initialized: boolean;

    protected _canRenderSetup: boolean;

    protected readonly _lockedProperties: { reforecastLocked: string[], budgetLocked: string[] };

    public metricRowData: Record<OpDriverMetricType, OpDriverMetric | undefined> = {
        [OpDriverMetricType.AVG_MARKET_RENTS]: { row: 0, summaryRow: 0, isOpen: true },
        [OpDriverMetricType.EXPIRATIONS]: { row: 0, summaryRow: 0, isOpen: true },
        [OpDriverMetricType.RENEWAL_RATIO]: { row: 0, summaryRow: 0, isOpen: true },
        [OpDriverMetricType.RENEWAL_INCREASE]: { row: 0, summaryRow: 0, isOpen: true },
        [OpDriverMetricType.MOVE_OUT_RATES]: { row: 0, summaryRow: 0, isOpen: true },
        [OpDriverMetricType.EARLY_TERMINATIONS]: { row: 0, summaryRow: 0, isOpen: true },
        [OpDriverMetricType.MONTH_TO_MONTH_MOVE_OUTS]: { row: 0, summaryRow: 0, isOpen: true },
        [OpDriverMetricType.OCCUPANCY_RATES]: { row: 0, summaryRow: 0, isOpen: true },
    };
    public unitTypeData: Record<string, OpDriverPropertyUnit | undefined> = {};

    get initialized(): boolean {
        return this._initialized;
    }

    get ssapi(): NewSpreadsheetAPI {
        return this._ssapi;
    }

    // eslint-disable-next-line @typescript-eslint/no-empty-function
    public constructor(config: AnalystTimeBasedLayoutConfig) {

        config.financialEntityColLabel = config.financialEntityColLabel ?? "Accounts";

        const {
            name,
            ssapi,
            firstReforecastMonth,
            months,
            year,
            financialEntityColLabel,
            hideDriversCol,
            hideColumns,
            canRenderSetup,
            lockedProperties,
        } = config;

        this._name = name;
        this._ssapi = ssapi;
        this._firstReforecastMonth = firstReforecastMonth;
        this._months = months;
        this._year = year;
        this._financialEntityLabel = financialEntityColLabel;
        this._hideDriversCol = hideDriversCol;
        this._initialized = false;
        if (this._hideColumns) {
            this._hideColumns = hideColumns;
        }

        this._uuid = uuid();
        this._canRenderSetup = canRenderSetup;
        this._lockedProperties = lockedProperties;
    }

    /**
     * Applies default (non-hovered) styles and formatting to rows that display data
     * @param row number
     * @param isPropertyRow boolean
     */
    public initializeDataRow(row: number, isPropertyRow?: boolean): void {

        // Direct access settings
        this._ssapi.directAccess(spread => {
            const sheet = spread.getActiveSheet();

            // Confirm borders for Financial Entity and Modeling Method columns are in place
            sheet.getRange(
                row,
                AnalystCfg.FINANCIAL_ENTITY_COL,
                1, 2
            ).setBorder(AnalystStyles.CELL_BORDER, { all: true });

            // Actuals and Reforecast ranges
            // Note: Actuals column count doesn't include last column to enable final cell right border
            const actualsRange = sheet.getRange(
                row,
                AnalystCfg.FIRST_DATA_COL,
                1, this._firstReforecastMonth - 1
            );

            const refoRange = sheet.getRange(
                row,
                AnalystCfg.FIRST_DATA_COL + this._firstReforecastMonth,
                1, 12 - this._firstReforecastMonth
            );

            // Unhovered style for This Year area data cells
            if (!isPropertyRow) {
                actualsRange.setStyle(AnalystStyles.SECTION_VALUE_CLOSED_CELL_LABEL);

                // Last Actuals cell needs a border, so must have its own style
                sheet.setStyle(
                    row,
                    AnalystCfg.FIRST_DATA_COL + this._firstReforecastMonth - 1,
                    AnalystStyles.SECTION_VALUE_LAST_CLOSED_CELL_LABEL
                );

                refoRange.setStyle(AnalystStyles.SECTION_VALUE_CLOSED_CELL_LABEL);
            } else {
                actualsRange.setStyle(AnalystStyles.MANUAL_STATIC_CELL_UNHOVERED);

                sheet.setStyle(
                    row,
                    AnalystCfg.FIRST_DATA_COL + this._firstReforecastMonth - 1,
                    AnalystStyles.MANUAL_LAST_STATIC_CELL_UNHOVERED
                );

                refoRange.setStyle(AnalystStyles.MANUAL_EDITABLE_CELL_UNHOVERED);
            }

            // This Year Total
            sheet.getCell(
                row,
                AnalystCfg.THIS_YEAR_TOTALS_COL
            ).setStyle(AnalystStyles.TOTALS_COLUMN_DATA_CELL);

            // Unhovered style for Budget area
            if (!isPropertyRow) {
                sheet.getRange(
                    row,
                    AnalystCfg.FIRST_BUDGET_DATA_COL,
                    1, 12
                ).setStyle(AnalystStyles.SECTION_VALUE_CLOSED_CELL_LABEL);
            } else {
                sheet.getRange(
                    row,
                    AnalystCfg.FIRST_BUDGET_DATA_COL,
                    1, 12
                ).setStyle(AnalystStyles.MANUAL_EDITABLE_CELL_UNHOVERED);
            }

            // Budget Total
            sheet.getCell(
                row,
                AnalystCfg.BUDGET_TOTALS_COL
            ).setStyle(AnalystStyles.TOTALS_COLUMN_DATA_CELL);

            sheet.getCell(row, AnalystCfg.FINANCIAL_ENTITY_COL).wordWrap(true);
            sheet.autoFitRow(row);

            if(sheet.getRowHeight(row) < AnalystCfg.DATA_CELL_HEIGHT){
                sheet.setRowHeight(row, AnalystCfg.DATA_CELL_HEIGHT);
            }
        });
    }

    /**
     * Styles the "Totals Row" for a section, which is the "closed" state for a section
     * @param row
     * @param level
     */
    public initializeTotalsRow(row: number, level: number): void {

        this._ssapi.directAccess(spread => {
            const sheet = spread.getActiveSheet();

            // Configure the Financial Entity cell
            sheet.getCell(
                row, AnalystCfg.FINANCIAL_ENTITY_COL
            ).cellType(
                new ExpandableCategoryCell({
                    level: level,
                    isOpen: false,
                    canOpen: false,
                    renderChevron: false,
                    baseToggleSpaceWidth: AnalystCfg.BASE_TOGGLE_SPACE_WIDTH,
                    subLevelXOffset: AnalystCfg.SUBLEVEL_X_OFFSET,
                    padding: AnalystCfg.FINANCIAL_ENTITY_CELL_PADDING,
                    style: AnalystCfg.rollupLevelStyles[level],
                    hideToggle: true,
                } as ExpandableCategoryCellInfo)
            );

            // Totals Modeling Method cell
            sheet.getCell(row, AnalystCfg.DRIVER_COLUMN).setStyle(AnalystStyles.ROLLUP_DATA_CELL);

            // Apply borders to Financial Entity and Driver Columns
            sheet.getRange(
                row,
                AnalystCfg.FINANCIAL_ENTITY_COL,
                1, 2
            ).setBorder(AnalystStyles.CELL_BORDER, { right: true, bottom: true });

            // Actuals range for Totals row (minus 1 cell for the right border style)
            sheet.getRange(
                row,
                AnalystCfg.FIRST_DATA_COL,
                1,
                this._firstReforecastMonth - 1,
            ).setStyle(AnalystStyles.ROLLUP_DATA_CELL);

            // Last Actuals column; Requires right border
            sheet.setStyle(
                row,
                AnalystCfg.FIRST_DATA_COL + this._firstReforecastMonth - 1,
                AnalystStyles.ROLLUP_DATA_LAST_CELL
            );

            sheet.getRange(
                row,
                AnalystCfg.FIRST_DATA_COL + this._firstReforecastMonth,
                1, 12 - this._firstReforecastMonth
            ).setStyle(AnalystStyles.ROLLUP_DATA_CELL);

            sheet.getCell(
                row,
                AnalystCfg.THIS_YEAR_TOTALS_COL
            ).setStyle(AnalystStyles.ROLLUP_TOTAL_CELL);

            sheet.getRange(
                row,
                AnalystCfg.FIRST_BUDGET_DATA_COL,
                1,
                12,
            ).setStyle(AnalystStyles.ROLLUP_DATA_CELL);

            sheet.getCell(
                row,
                AnalystCfg.BUDGET_TOTALS_COL,
            ).setStyle(AnalystStyles.ROLLUP_TOTAL_CELL);

            sheet.getCell(row, AnalystCfg.FINANCIAL_ENTITY_COL).wordWrap(true);
            sheet.autoFitRow(row);

            if(sheet.getRowHeight(row) < AnalystCfg.DATA_CELL_HEIGHT){
                sheet.setRowHeight(row, AnalystCfg.DATA_CELL_HEIGHT);
            }
        });
    }

    /**
     * Performs initialization of the Analyst View sheet.
     * @public
     */

    public initSheet(totalsColTitle?:string): void {

        // Direct access settings
        this._ssapi.directAccess(spread => {
            const sheet = spread.getActiveSheet();

            sheet.setSelection(0, 0, 1, 1); // instead of clearSelection as clearSelection breaks further selecting cells

            spread.options.showVerticalScrollbar = true;
            spread.options.showHorizontalScrollbar = true;
            spread.options.allowUserZoom = false;
            // spread.options.tabStripVisible = false;
            // prevent scrolling past the last row or column
            spread.options.scrollbarMaxAlign = true;
            spread.options.scrollIgnoreHidden = true;

            sheet.options.frozenlineColor = WorksheetStyles.CELL_BORDER_COLOR;

            sheet.setValue(
                AnalystCfg.FINANCIAL_ENTITY_LABEL_ROW,
                AnalystCfg.FINANCIAL_ENTITY_COL,
                this.financialEntityColumnLabelOffset + this._financialEntityLabel
            );

            // Hide any columns that were identified
            this._hideColumns?.map(col => {
                sheet.setColumnVisible(
                    col,
                    false,
                );
            });
        });

        // Apply width to the frozen left columns
        this._ssapi.setColumnWidth({ col: AnalystCfg.FINANCIAL_ENTITY_COL, width: AnalystCfg.FINANCIAL_ENTITY_COL_W });
        this._ssapi.setColumnWidth({ col: AnalystCfg.DRIVER_COLUMN, width: AnalystCfg.DRIVER_COLUMN_W });

        // Freeze the header rows, and the Financial Entities columns
        this._ssapi.frozenRowCount({ rowCount: 5 });
        this._ssapi.frozenColumnCount({ colCount: 4 });

        // Hide any rows or columns outside the visible sheet area
        this._ssapi.setColumnVisible({ colIdx: 0, isVisible: false });
        this._ssapi.setColumnVisible({ colIdx: 1, isVisible: false });

        // Hide row 0
        this._ssapi.setRowVisible({ rowIdx: 0, isVisible: false });
        // Set row 1 to 2 px tall, so we can see the thick line above the row headers
        this._ssapi.setRowHeight({ row: 1, height: 2 });

        // Apply default cell borders
        // Set border for top left anchor cells
        this._ssapi.setBorder({
            row: 2,
            col: 1,
            rowCount: 3,
            colCount: 2,
            border: AnalystStyles.CELL_BORDER,
            options: { right: true, bottom: true }
        });

        this.initializeDataColumns(totalsColTitle);
    }

    /**
     * Initializes data columns, called once on sheet initialization.
     * @protected
     */
    protected initializeDataColumns(totalsColTitle?: string): void {
        let currentColumn = AnalystCfg.FIRST_DATA_COL;

        this._ssapi.directAccess(spread => {
            const sheet = spread.getActiveSheet();

            sheet.setRowHeight(AnalystCfg.FIRST_COL_HEADER_ROW, 25);
            sheet.setRowHeight(AnalystCfg.FIRST_COL_HEADER_ROW + 1, 20);
            sheet.setRowHeight(AnalystCfg.FIRST_COL_HEADER_ROW + 2, 35);

            const applyHeaderStyles = (sheetArea: SheetArea): void => {

                const colOffset = sheetArea == SheetArea.YEAR ? 0 : 13;

                // HEADER ACTUALS/BUDGET: labels, Styles, & Border __________________________________
                sheet.setArray(
                    AnalystCfg.FIRST_COL_HEADER_ROW,
                    AnalystCfg.FIRST_DATA_COL + colOffset,
                    [new Array(this._firstReforecastMonth).fill(
                        sheetArea == SheetArea.YEAR ? 'ACTUAL ' : 'BUDGET '
                    )],
                );

                if (sheetArea == SheetArea.YEAR) {
                    const actualsHdrRange = sheet.getRange(
                        AnalystCfg.FIRST_COL_HEADER_ROW,
                        AnalystCfg.FIRST_DATA_COL,
                        1,
                        this._firstReforecastMonth,
                    );
                    actualsHdrRange.setStyle(AnalystStyles.ACTUAL_HEADER);
                    // HEADER ACTUALS Last Cell
                    sheet.setStyle(
                        AnalystCfg.FIRST_COL_HEADER_ROW,
                        AnalystCfg.FIRST_DATA_COL + this._firstReforecastMonth - 1,
                        AnalystStyles.ACTUAL_HEADER_LAST_CELL
                    );

                    // Apply top border to ALL Actuals top header row cells
                    actualsHdrRange.setBorder(
                        new GC.Spread.Sheets.LineBorder(AnalystStyles.ACTUAL_TOP_BORDER_COLOR, GC.Spread.Sheets.LineStyle.thick),
                        { top: true }
                    );

                    const reforecastHdrRange = sheet.getRange(
                        AnalystCfg.FIRST_COL_HEADER_ROW,
                        AnalystCfg.FIRST_DATA_COL + this._firstReforecastMonth,
                        1,
                        12 - this._firstReforecastMonth,
                    );

                    reforecastHdrRange.setStyle(AnalystStyles.REFORECAST_HEADER);
                    reforecastHdrRange.setBorder(
                        new GC.Spread.Sheets.LineBorder(AnalystStyles.REFORECAST_TOP_BORDER_COLOR, GC.Spread.Sheets.LineStyle.thick),
                        { top: true }
                    );

                    sheet.setStyle(
                        AnalystCfg.FIRST_COL_HEADER_ROW + 1,
                        AnalystCfg.FIRST_DATA_COL,
                        AnalystStyles.ACTUAL_HEADER_YEAR,
                    );

                    // Last Actuals Year cell (to add right border)
                    sheet.setStyle(
                        AnalystCfg.FIRST_COL_HEADER_ROW + 1,
                        AnalystCfg.FIRST_DATA_COL + this._firstReforecastMonth - 1,
                        AnalystStyles.ACTUAL_HEADER_YEAR_LAST,
                    );

                    sheet.getRange(
                        AnalystCfg.FIRST_COL_HEADER_ROW + 2,
                        AnalystCfg.FIRST_DATA_COL,
                        1, this._firstReforecastMonth - 1
                    ).setStyle(AnalystStyles.ACTUAL_HEADER_MONTH);

                    sheet.getCell(
                        AnalystCfg.FIRST_COL_HEADER_ROW + 2,
                        AnalystCfg.FIRST_DATA_COL + this._firstReforecastMonth - 1
                    ).setStyle(AnalystStyles.ACTUAL_HEADER_MONTH_LAST);

                    // Reforecast Header Year cell
                    sheet.getRange(
                        AnalystCfg.FIRST_COL_HEADER_ROW + 1,
                        AnalystCfg.FIRST_DATA_COL + this._firstReforecastMonth,
                        1, 12 - this._firstReforecastMonth
                    ).setStyle(AnalystStyles.REFORECAST_YEAR_HEADER);

                    sheet.getRange(
                        AnalystCfg.FIRST_COL_HEADER_ROW + 2,
                        AnalystCfg.FIRST_DATA_COL + this._firstReforecastMonth + colOffset,
                        1, 12 - this._firstReforecastMonth
                    ).setStyle(AnalystStyles.REFORECAST_MONTH_HEADER);

                } else {
                    const budgetHdrTopRow = sheet.getRange(
                        AnalystCfg.FIRST_COL_HEADER_ROW,
                        AnalystCfg.FIRST_BUDGET_DATA_COL,
                        1, 12
                    );
                    budgetHdrTopRow.setStyle(AnalystStyles.REFORECAST_HEADER);
                    budgetHdrTopRow.setBorder(
                        new GC.Spread.Sheets.LineBorder(AnalystStyles.REFORECAST_TOP_BORDER_COLOR, GC.Spread.Sheets.LineStyle.thick),
                        { top: true }
                    );

                    sheet.getRange(
                        AnalystCfg.FIRST_COL_HEADER_ROW + 1,
                        AnalystCfg.FIRST_BUDGET_DATA_COL,
                        1, 12
                    ).setStyle(AnalystStyles.REFORECAST_YEAR_HEADER);

                    sheet.getRange(
                        AnalystCfg.FIRST_COL_HEADER_ROW + 2,
                        AnalystCfg.FIRST_BUDGET_DATA_COL,
                        1, 12
                    ).setStyle(AnalystStyles.REFORECAST_MONTH_HEADER);
                }

                // HEADER: REFORECAST labels, Styles, & Border ____________________________________
                sheet.setArray(
                    AnalystCfg.FIRST_COL_HEADER_ROW,
                    AnalystCfg.FIRST_DATA_COL + this._firstReforecastMonth + colOffset,
                    [new Array(12 - this._firstReforecastMonth).fill(
                        sheetArea == SheetArea.YEAR ? 'REFORECAST ' : 'BUDGET ')],
                );

                for (let i = 0; i < 12; i++) {
                    currentColumn = AnalystCfg.FIRST_DATA_COL + i;
                    sheet.setColumnWidth(currentColumn + colOffset, AnalystCfg.DATA_CELL_WIDTH);
                }

                // HEADER: YEAR label & Style _____________________________________________________
                const displayYear = sheetArea == SheetArea.YEAR ? this._year : this._year + 1;
                sheet.setValue(
                    AnalystCfg.FIRST_COL_HEADER_ROW + 1,
                    AnalystCfg.FIRST_DATA_COL + colOffset,
                    displayYear + ' ');

                // HEADER: MONTHS label & Style ___________________________________________________
                sheet.setArray(
                    AnalystCfg.FIRST_COL_HEADER_ROW + 2,
                    AnalystCfg.FIRST_DATA_COL + colOffset,
                    [this._months.map(entry => entry + '  ')],
                );


                // HEADER: TOTALS label & Style
                sheet.setColumnWidth(AnalystCfg.THIS_YEAR_TOTALS_COL + colOffset, AnalystCfg.TOTALS_COL_W);

                // Totals row 1 of 3
                sheet.setValue(
                    AnalystCfg.FIRST_COL_HEADER_ROW,
                    AnalystCfg.THIS_YEAR_TOTALS_COL + colOffset,
                    sheetArea == SheetArea.YEAR ? 'REFORECAST  ' : 'BUDGET  '
                );
                sheet.setStyle(
                    AnalystCfg.FIRST_COL_HEADER_ROW,
                    AnalystCfg.THIS_YEAR_TOTALS_COL + colOffset,
                    AnalystStyles.TOTALS_COLUMN_HDR_CELL1);

                // Totals row 2 of 3
                sheet.setValue(
                    AnalystCfg.FIRST_COL_HEADER_ROW + 1,
                    AnalystCfg.THIS_YEAR_TOTALS_COL + colOffset,
                    sheetArea == SheetArea.YEAR ? this._year + '  ' : (this._year + 1) + '  '
                );
                sheet.setStyle(
                    AnalystCfg.FIRST_COL_HEADER_ROW + 1,
                    AnalystCfg.THIS_YEAR_TOTALS_COL + colOffset,
                    AnalystStyles.TOTALS_COLUMN_HDR_CELL1);

                // Totals row 2 of 3
                sheet.setValue(
                    AnalystCfg.FIRST_COL_HEADER_ROW + 2,
                    AnalystCfg.THIS_YEAR_TOTALS_COL + colOffset,
                    `${totalsColTitle}  `,
                );
                sheet.setStyle(AnalystCfg.FIRST_COL_HEADER_ROW + 2, AnalystCfg.THIS_YEAR_TOTALS_COL + colOffset, AnalystStyles.TOTALS_COLUMN_HDR_CELL3);

                // Set right border for all Total header cells
                sheet.getRange(
                    AnalystCfg.FIRST_COL_HEADER_ROW,
                    AnalystCfg.THIS_YEAR_TOTALS_COL + colOffset,
                    3, 1
                ).setBorder(AnalystStyles.TOTALS_RT_BORDER, { right: true });
            };

            applyHeaderStyles(SheetArea.YEAR);
            applyHeaderStyles(SheetArea.BUDGET);

        });

    }

    /**
     * Called by the useEffect from analyst views that's called in response to cellHovered?.row changing
     * @param mouseOverRow
     * @param refoDrivers DriverType[] any Reforecast Drivers associated with the entity on the row
     * @param budgetDrivers DriverType[] any Budget Drivers associated with the entity on the row
     */
    public onMouseoverPropertyRow(mouseOverRow: MouseoverRow, refoDrivers: DriverType[], budgetDrivers: DriverType[]): void {

        this._ssapi.directAccess(spread => {
            const sheet = spread.getActiveSheet();

            if (mouseOverRow.entityType == "ACCOUNT_PROPERTY") {

                const reforecastRange = sheet.getRange(
                    mouseOverRow.rowIdx,
                    AnalystCfg.FIRST_DATA_COL + this._firstReforecastMonth,
                    1, 12 - this._firstReforecastMonth
                );

                if (refoDrivers.length == 0) {
                    reforecastRange.setStyle(AnalystStyles.MANUAL_EDITABLE_CELL_HOVERED);
                } else {
                    sheet.autoFitRow(reforecastRange.row);
                    if(sheet.getRowHeight(reforecastRange.row) < AnalystCfg.DATA_CELL_HEIGHT){
                        sheet.setRowHeight(reforecastRange.row, AnalystCfg.DATA_CELL_HEIGHT);
                    }

                    this.applyCappedRowSpanStyle(
                        reforecastRange.row,
                        reforecastRange.col,
                        reforecastRange.col + reforecastRange.colCount - 1,
                        {
                            leftCap: AnalystStyles.DRIVER_CELL_LEFT_CAP,
                            centerSpan: AnalystStyles.DRIVER_CELL_CENTER,
                            rightCap: AnalystStyles.DRIVER_CELL_RIGHT_CAP,
                        },
                    );
                }

                const budgetRange = sheet.getRange(
                    mouseOverRow.rowIdx,
                    AnalystCfg.FIRST_BUDGET_DATA_COL,
                    1, 12
                );

                if (budgetDrivers.length == 0) {
                    budgetRange.setStyle(AnalystStyles.MANUAL_EDITABLE_CELL_HOVERED);
                } else {
                    this.applyCappedRowSpanStyle(
                        budgetRange.row,
                        budgetRange.col,
                        budgetRange.col + budgetRange.colCount - 1,
                        {
                            leftCap: AnalystStyles.DRIVER_CELL_LEFT_CAP,
                            centerSpan: AnalystStyles.DRIVER_CELL_CENTER,
                            rightCap: AnalystStyles.DRIVER_CELL_RIGHT_CAP,
                        },
                    );
                }
            }

            if (mouseOverRow.oldRowEntityType == "ACCOUNT_PROPERTY") {
                this.resetPropertyRowStyles(mouseOverRow.oldRow);
            }
        });
    }

    /**
     * Called by the useEffect from analyst views that's called in response to cellHovered?.row changing
     * @param mouseOverRow
     */
    public onMouseoverAccountRow(mouseOverRow: MouseoverRow, thisRow: RowMapEntry | undefined, oldRow: RowMapEntry | undefined): void {

        this._ssapi.directAccess(spread => {
            const sheet = spread.getActiveSheet();

            if (mouseOverRow.entityType == "ACCOUNT" && thisRow) {

                const ctgyLabelCell = sheet.getCell(mouseOverRow.rowIdx, AnalystCfg.FINANCIAL_ENTITY_COL);
                const cellType = ctgyLabelCell.cellType();

                if(cellType && cellType.typeName == 'ExpandableCategoryCell'){
                    const cellRef = (cellType as ExpandableCategoryCell);
                    cellRef.updateCellInfo({
                        ...cellRef.getCellInfo(),
                        ...{ renderSetupBtn: this._canRenderSetup }
                    });
                }
            }

            if (mouseOverRow.oldRowEntityType == "ACCOUNT" && oldRow) {
                const ctgyLabelCell = sheet.getCell(mouseOverRow.oldRow, AnalystCfg.FINANCIAL_ENTITY_COL);
                const cellType = ctgyLabelCell.cellType();

                if(cellType && cellType.typeName == 'ExpandableCategoryCell'){
                    const cellRef = (cellType as ExpandableCategoryCell);
                    cellRef.updateCellInfo({
                        ...cellRef.getCellInfo(),
                        ...{ renderSetupBtn: false }
                    });
                }
            }
        });
    }

    protected resetPropertyRowStyles(row: number) {
        this._ssapi.directAccess(spread => {
            const sheet = spread.getActiveSheet();

            const refoRange = sheet.getRange(row, AnalystCfg.FIRST_DATA_COL + this._firstReforecastMonth, 1, 12 - this._firstReforecastMonth);
            const budgetRange = sheet.getRange(row, AnalystCfg.FIRST_BUDGET_DATA_COL, 1, 12);


            refoRange.setStyle(AnalystStyles.MANUAL_EDITABLE_CELL_UNHOVERED);
            budgetRange.setStyle(AnalystStyles.MANUAL_EDITABLE_CELL_UNHOVERED);

        });
    }

    public setCategoryExpanded(dispatch: React.Dispatch<RowMapAction>, cellClicked: CellHitInfo, thisRow: RowMapEntry | undefined): void {

        if (!thisRow || !thisRow.canOpen || !thisRow.isFormulaPopulated) {
            return;
        }

        this._ssapi.directAccess(spread => {
            const sheet = spread.getActiveSheet();

            // Get a reference to the Row Group index (rgi)
            const rgi = sheet.rowOutlines.find(cellClicked.row + 1, thisRow.level);
            const ctgyLabelCell = sheet.getCell(cellClicked.row, AnalystCfg.FINANCIAL_ENTITY_COL);
            const cellType = ctgyLabelCell.cellType();

            if(cellType && cellType.typeName == 'ExpandableCategoryCell'){

                // TODO: Migrate to managed suspend/resume vs. direct access
                sheet.suspendPaint();

                if (thisRow.isOpen) {

                    // CLOSE the row
                    dispatch({ kind: "CloseRowAction", rowID: cellClicked.row } as CloseRowAction);

                    if (rgi != null) {
                        sheet.rowOutlines.expandGroup(rgi, false);
                    }

                    const cellRef = (cellType as ExpandableCategoryCell);

                    let cellInfo = cellRef.getCellInfo();
                    cellInfo.isOpen = !thisRow.isOpen;
                    cellRef.updateCellInfo(cellInfo);

                    this.applyCategoryRowDisplayState(thisRow.idx, false);

                } else {

                    // OPEN the row
                    dispatch({ kind: "OpenRowAction", rowID: cellClicked.row } as OpenRowAction);

                    if (rgi != null) {
                        sheet.rowOutlines.expandGroup(rgi, true);
                    }

                    const cellRef = (cellType as ExpandableCategoryCell);

                    let cellInfo = cellRef.getCellInfo();
                    cellInfo.isOpen = !thisRow.isOpen;
                    cellRef.updateCellInfo(cellInfo);

                    this.applyCategoryRowDisplayState(thisRow.idx, true);
                }

                // TODO: Migrate to managed suspend/resume vs. direct access
                sheet.resumePaint();
            }
        });
    }

    protected applyCategoryRowDisplayState(row: number, isOpen: boolean): void {

        this._ssapi.directAccess(spread => {
            const sheet = spread.getActiveSheet();

            if (isOpen) { // Display the "opened" state for the category

                // Apply standard styles to the Actual/Reforecast months
                // 3 styles - left end w/ 3 sides, middle span w/ top and bottom, right end w/ 3 sides
                this.applyCappedRowSpanStyle(
                    row,
                    AnalystCfg.FIRST_DATA_COL,
                    AnalystCfg.FIRST_DATA_COL + this._firstReforecastMonth - 1,
                    {
                        leftCap: AnalystStyles.SECTION_VALUE_OPEN_FIRST_CELL_LABEL,
                        centerSpan: AnalystStyles.SECTION_VALUE_OPEN_MID_CELL_LABEL,
                        rightCap: AnalystStyles.SECTION_VALUE_OPEN_MID_CELL_LABEL,
                    },
                );

                sheet.setStyle(
                    row,
                    AnalystCfg.FIRST_DATA_COL + this._firstReforecastMonth - 1,
                    AnalystStyles.SECTION_VALUE_OPEN_LAST_ACTUAL_CELL
                );

                // TODO: Add support for 1 reforecast month (December only)
                this.applyCappedRowSpanStyle(
                    row,
                    AnalystCfg.FIRST_DATA_COL + this._firstReforecastMonth,
                    AnalystCfg.FIRST_DATA_COL + this._firstReforecastMonth + (11 - this._firstReforecastMonth),
                    {
                        leftCap: AnalystStyles.SECTION_VALUE_OPEN_FIRST_CELL_LABEL,
                        centerSpan: AnalystStyles.SECTION_VALUE_OPEN_MID_CELL_LABEL,
                        rightCap: AnalystStyles.SECTION_VALUE_OPEN_MID_CELL_LABEL,
                    }
                );

                // This Year totals column
                sheet.getCell(
                    row, AnalystCfg.THIS_YEAR_TOTALS_COL
                ).setStyle(AnalystStyles.TOTALS_COLUMN_DATA_CELL_OPEN);

                // Apply standard styles to the Budget months
                // 3 styles - left end w/ 3 sides, middle span w/ top and bottom, right end w/ 3 sides
                this.applyCappedRowSpanStyle(
                    row,
                    AnalystCfg.FIRST_BUDGET_DATA_COL,
                    AnalystCfg.FIRST_BUDGET_DATA_COL + 11,
                    {
                        leftCap: AnalystStyles.SECTION_VALUE_OPEN_FIRST_CELL_LABEL,
                        centerSpan: AnalystStyles.SECTION_VALUE_OPEN_MID_CELL_LABEL,
                        rightCap: AnalystStyles.SECTION_VALUE_OPEN_LAST_CELL_LABEL,
                    }
                );

                // Budget totals column
                sheet.getCell(
                    row, AnalystCfg.BUDGET_TOTALS_COL
                ).setStyle(AnalystStyles.TOTALS_COLUMN_DATA_CELL_OPEN);

            } else { // Display the closed, or default, state for the category

                this.initializeDataRow(row, false);

            }
        });
    }

    /**
     * Applies a "bridged" set of styles to a span of row cells, including a left cap style to the leftmost cell,
     * a right cap style to the rightmost cell, and a style that visually bridges the two to any inbetween.
     * @param row
     * @param firstCol
     * @param lastCol
     * @param styles
     * @protected
     */
    protected applyCappedRowSpanStyle(
        row: number,
        firstCol: number,
        lastCol: number,
        styles: CellSpanStyles,
    ) {

        this._ssapi.directAccess(spread => {
            const sheet = spread.getActiveSheet();

            sheet.getCell(
                row, firstCol
            ).setStyle(styles.leftCap);

            sheet.getRange(
                row,
                firstCol + 1,
                1,
                lastCol - firstCol,
            ).setStyle(styles.centerSpan);

            sheet.getCell(
                row, lastCol
            ).setStyle(styles.rightCap);
        });
    }

    public static getCategoryCellInfo(
        level: number,
        type: AnalystEntityType,
        showSetup: boolean,
        isOpen: boolean,
        hideToggle: boolean,
        isLoaded?: boolean,
    ): ExpandableCategoryCellInfo {

        return {
            level: level,
            isOpen,
            canOpen: ["ACCOUNT", "CATEGORY"].includes(type),
            renderChevron: type == "ACCOUNT",
            renderSetupBtn: type == "ACCOUNT" && showSetup,
            baseToggleSpaceWidth: AnalystCfg.BASE_TOGGLE_SPACE_WIDTH,
            subLevelXOffset: AnalystCfg.SUBLEVEL_X_OFFSET,
            leftIconSpecs: AnalystCfg.leftIconSpecs,
            rightIconSpecs: AnalystCfg.rightIconSpecs,
            hideToggle: hideToggle,
            isLoaded: isLoaded,
        };
    }

    /**
     * Updated version of setCategoryExpanded() designed to work with the
     * new FinancialEntityCell mechanism
     * @param row
     * @param forceState
     */
    public toggleSectionOpenState(row: number, forceState?: boolean): void {
        this._ssapi.directAccess(spread => {
            const sheet = spread.getActiveSheet();

            sheet.suspendPaint();

            // Bail if this cell isn't a FinancialEntityCell (as continuing will dump the app)
            if (!isFinancialEntityCell(row, AnalystCfg.FINANCIAL_ENTITY_COL, sheet)) {
                sheet.resumePaint();
                return;
            }
            const thisCell = sheet.getCell(row, AnalystCfg.FINANCIAL_ENTITY_COL);
            const cellRef: FinancialEntityCell = (thisCell.cellType() as FinancialEntityCell);

            const { isOpen, level } = cellRef.getCellVars();

            cellRef.setIsOpen(!isOpen);

            const rgi = sheet.rowOutlines.find(row + 1, level ?? 0);

            if (rgi != null) {
                if (forceState === undefined) {
                    sheet.rowOutlines.expandGroup(rgi, !isOpen);
                } else {
                    sheet.rowOutlines.expandGroup(rgi, forceState);
                }
            }

            this.applyCategoryRowDisplayState(row, !isOpen);

            sheet.resumePaint();
        });
    }

    public isOpen(row: number): boolean {
        let ret = false;

        this.ssapi.directAccess(spread => {
            const sheet = spread.getSheetFromName(AnalystCfg.MAIN_TAB_NAME);

            // Bail if this cell isn't a FinancialEntityCell (as continuing will dump the app)
            if (isFinancialEntityCell(row, AnalystCfg.FINANCIAL_ENTITY_COL, sheet)) {
                const thisCell = sheet.getCell(row, AnalystCfg.FINANCIAL_ENTITY_COL);
                const cellRef: FinancialEntityCell = (thisCell.cellType() as FinancialEntityCell);

                const { isOpen } = cellRef.getCellVars();

                ret = isOpen;
            }
        });

        return ret;
    }

    public getFinancialCellInfoAndVars(row: number) {
        let cellInfo: FinancialEntityCellInfo = {};
        let cellVars: FinancialEntityCellVars = {
            row: 0,
            canOpen: false,
            isOpen: false,
            level: undefined,
            isButtonHovered: false,
            isToggleHovered: false,
            rowEntityType: RowEntityType.METRIC
        };

        this.ssapi.directAccess(spread => {
            const sheet = spread.getSheetFromName(AnalystCfg.MAIN_TAB_NAME);

            const thisCell = sheet.getCell(row, AnalystCfg.FINANCIAL_ENTITY_COL);

            const cellType = thisCell.cellType()
            // Bail if this cell isn't a FinancialEntityCell (as continuing will dump the app)
            if (cellType && cellType.typeName === "FinancialEntityCell") {
                const cellRef: FinancialEntityCell = (cellType as FinancialEntityCell);
                cellInfo = cellRef.getCellInfo();
                cellVars = cellRef.getCellVars();
            }
        });

        return {
            cellInfo, cellVars
        };
    }

    protected getActualsRange(sheet: GC.Spread.Sheets.Worksheet, row: number): GC.Spread.Sheets.CellRange {
        return sheet.getRange(
            row,
            AnalystCfg.FIRST_DATA_COL,
            1, this._firstReforecastMonth - 1
        );
    }

    protected getRefoRange(sheet: GC.Spread.Sheets.Worksheet, row: number): GC.Spread.Sheets.CellRange {
        return sheet.getRange(
            row,
            AnalystCfg.FIRST_DATA_COL + this._firstReforecastMonth,
            1, 12 - this._firstReforecastMonth
        );
    }

    protected getBudgetRange(sheet: GC.Spread.Sheets.Worksheet, row: number): GC.Spread.Sheets.CellRange {
        return sheet.getRange(
            row,
            AnalystCfg.FIRST_BUDGET_DATA_COL,
            1, 12
        );
    }
}
