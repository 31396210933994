import { ReactElement, useEffect, useState } from "react";

import { BulkReportType, useGetOperationalReportsLazyQuery } from "../../__generated__/generated_types";
import BulkExportPageHeader from "./BulkExportPageHeader";
import DownloadableExportsTable, { IDownloadableExportsTableRow } from "./DownloadableExportsTable";
import { getSortedReports, pageDescription } from "./utils";


export default function OperationalReportsBulkExport():ReactElement {
    const [rows, setRows] = useState<IDownloadableExportsTableRow[]>([]);

    const [getOperationalReports, {data: data, loading: loading}] = useGetOperationalReportsLazyQuery({
        fetchPolicy: "no-cache",
    });

    useEffect(() => {
        getOperationalReports();
    }, []);

    useEffect(() => {
        if (loading || !data || !data.operationalReports) {
            return;
        }

        const sortedReports = getSortedReports(data.operationalReports);
        setRows(sortedReports);
    }, [data, loading]);

    return (
        <div>
            <BulkExportPageHeader pageTitle="Operational Reports" pageDescription={pageDescription} />
            <DownloadableExportsTable reportType={BulkReportType.OperationalMetrics} rows={rows} />
        </div>
    );
}
