import { useEffect, useReducer } from "react";
import { useProperties } from "../../../contexts/properties/PropertiesContext";
import { useGetSimplifiedRevenueSummaryLazyQuery } from "../../../__generated__/generated_types";
import {
    ALL_UNIT_TYPES,
    buildSummaryTableData,
    ITableDataByTabAndUnitType,
    SimplifiedRevenueModel,
    SummaryTableData,
    SummaryTableGenericRowValues,
    SummaryTableParentRow,
    TabNames,
} from "./utils";


type State = {
    rawData: SimplifiedRevenueModel | undefined;
    selectedTab: TabNames;
    selectedUnitType: string | undefined;
    tableData: SummaryTableData | undefined;
    tableDataByTabAndUnitType: ITableDataByTabAndUnitType[];
    unitTypes: string[];
}

const INITIAL_STATE: State = {
    rawData: undefined,
    selectedTab: TabNames.rent,
    selectedUnitType: undefined,
    tableData: undefined,
    tableDataByTabAndUnitType: [],
    unitTypes: [],
};

/**
 * Reducer Initialization
*/

interface SetRawData {
    type: "SET_RAW_DATA",
    rawData: SimplifiedRevenueModel,
}

interface SetSelectedTab {
    type: "SET_SELECTED_TAB",
    selectedTab: State["selectedTab"],
}

interface SetSelectedUnitType {
    type: "SET_SELECTED_UNIT_TYPE",
    selectedUnitType: State["selectedUnitType"],
}

interface SetTableData {
    type: "SET_TABLE_DATA",
    tableData: SummaryTableData,
}

interface SetTableDataByTabAndUnitType {
    type: "SET_TABLE_DATA_BY_TAB_AND_UNIT_TYPE",
    tableDataByTabAndUnitType: State["tableDataByTabAndUnitType"],
}

interface SetUnitTypes {
    type: "SET_UNIT_TYPES",
    unitTypes: State["unitTypes"]
}

type Action = SetRawData
    | SetSelectedTab
    | SetSelectedUnitType
    | SetTableData
    | SetTableDataByTabAndUnitType
    | SetUnitTypes;

/**
 * END -- Reducer Initialization
*/

/**
 * Return Types
*/
export interface ISetRawData {
    rawData: SimplifiedRevenueModel,
}

export interface ISetSelectedTab {
    selectedTab: State["selectedTab"],
}

export interface ISetSelectedUnitType {
    selectedUnitType: State["selectedUnitType"],
}

export interface ISetTableData {
    tableData: SummaryTableData,
}

export interface ISetTableDataByTabAndUnitType {
    tableDataByTabAndUnitType: State["tableDataByTabAndUnitType"],
}

export interface ISetUnitTypes {
    unitTypes: State["unitTypes"]
}

export interface IUseSummaryReturn {
    rawData: State["rawData"];
    setRawData: (args: ISetRawData) => void;

    selectedTab: State["selectedTab"];
    setSelectedTab: (args: ISetSelectedTab) => void;

    selectedUnitType: State["selectedUnitType"];
    setSelectedUnitType: (args: ISetSelectedUnitType) => void;

    tableData: State["tableData"];
    setTableData: (args: ISetTableData) => void;

    tableDataByTabAndUnitType: State["tableDataByTabAndUnitType"];
    setTableDataByTabAndUnitType: (args: ISetTableDataByTabAndUnitType) => void;

    unitTypes: State["unitTypes"];
    setUnitTypes: (args: ISetUnitTypes) => void;
}

/**
 * END -- Return Types
*/


export default function useSummary(): IUseSummaryReturn {
    const property = useProperties();
    const [state, dispatch] = useReducer(summaryReducer, INITIAL_STATE);

    const [getSimplifiedRevenueSummary, { data, loading }] = useGetSimplifiedRevenueSummaryLazyQuery({
        fetchPolicy: "network-only",
    });

    const reforecastStartMonthIndex = property.currentProperty?.reforecastStartMonthIndex || 0;

    useEffect(() => {
        if (!property.currentProperty) {
            return;
        }

        getSimplifiedRevenueSummary({
            variables: {
                propertyId: property.currentProperty.id,
                budgetYear: property.currentProperty.budgetYear,
            }
        });
    }, [property.currentProperty]);

    useEffect(() => {
        if (data?.simplifiedRevenueModel) {
            dispatch({
                type: "SET_RAW_DATA",
                rawData: data?.simplifiedRevenueModel,
            });
        }
    }, [data]);

    useEffect(() => {
        const rawData = state.rawData;
        if (rawData) {
            const tableData = buildSummaryTableData(rawData, reforecastStartMonthIndex);
            setTableData({ tableData });
        }
    }, [state.rawData !== undefined]);

    useEffect(() => {
        if (state.tableData !== undefined) {
            const unitTypes: string[] = [ALL_UNIT_TYPES];

            const tableDataForSelectedTab = state.tableData[state.selectedTab];

            tableDataForSelectedTab.forEach((each) => {
                each.rows.forEach((eachRow) => {
                    unitTypes.push(eachRow.label);
                });
            });

            setUnitTypes({ unitTypes: unitTypes.dedupe() });
        }
    }, [state.selectedTab, state.tableData !== undefined]);

    useEffect(() => {
        if (state.unitTypes.length > 0) {
            dispatch({
                type: "SET_SELECTED_UNIT_TYPE",
                selectedUnitType: state.unitTypes[0],
            });
        }
    }, [state.unitTypes]);

    useEffect(() => {
        if (state.tableData !== undefined) {
            const tableDataByTab = state.tableData[state.selectedTab];
            let tableDataByTabAndUnitType: ITableDataByTabAndUnitType[] = [];

            const customReducer = (values: (string | number | null)[]) => {
                return values.reduce((acc: number, num) => {
                    if (typeof num === "string") {
                        return acc + (parseFloat(num));
                    }
                    return acc + (num ?? 0);
                }, 0);
            };

            const getReforecastAverage = (cellValues: (string | number | null)[]) => {
                const reforecastValues = cellValues.slice(reforecastStartMonthIndex, 12);

                const reforecastValuesSum = customReducer(reforecastValues);

                return Math.round(reforecastValuesSum/(reforecastValues.length));
            };

            const getBudgetAverage = (cellValues: (string | number | null)[]) => {
                const budgetValues = cellValues.slice(12);

                const budgetValuesSum = customReducer(budgetValues);

                return Math.round(budgetValuesSum/(budgetValues.length));
            };

            const getRowGroupValues = (rows: SummaryTableGenericRowValues[], dataFormat: string, rowGroupUnitCounts: (number | null)[][]) => {
                let aggregateRow: (number | null)[] = new Array(24).fill(null);
                const unitCountTotals = Array(24).fill(0);

                rows.forEach((row, rowIndex) => {
                    const unitCounts = rowGroupUnitCounts[rowIndex];
                    if (!unitCounts) {
                        return;
                    }

                    row.cellValues.forEach((each, cellIndex) => {
                        if (each == null) {
                            return;
                        }

                        if (dataFormat === "count") {
                            return (aggregateRow[cellIndex] as number) += each;
                        }

                        const unitCount = unitCounts[cellIndex] ?? 0;
                        unitCountTotals[cellIndex] += unitCount;


                        if (aggregateRow[cellIndex] == null) {
                            aggregateRow[cellIndex] = each * unitCount;
                        } else {
                            (aggregateRow[cellIndex] as number) += (each * unitCount);
                        }
                    });
                });

                if (dataFormat === "count") {
                    return aggregateRow;
                }

                aggregateRow = aggregateRow.map((each, index) => {
                    const total = unitCountTotals[index];

                    if (each == null) {
                        return null;
                    }

                    if (total == 0) {
                        return 0;
                    }

                    return Math.round(each/total);
                });

                return aggregateRow;
            };

            const buildChildRows = (eachRowGroup: SummaryTableParentRow) => {
                const filteredRows = state.selectedUnitType === ALL_UNIT_TYPES ? eachRowGroup.rows : eachRowGroup.rows.filter((eachRow) => eachRow.label === state.selectedUnitType);
                return filteredRows.map((each) => ({
                    ...each,
                    id: each.value,
                    parentId: eachRowGroup.label,
                }));
            };

            const getRowGroupUnitCounts = (rows: any[]) => {
                const counts = rows.map(row => {
                    if (!row.unitCounts) {
                        return Array(24).fill(null);
                    }

                    const actualsCounts = row.unitCounts.actuals.slice(0, reforecastStartMonthIndex);
                    const reforecastCounts = row.unitCounts.reforecast.slice(reforecastStartMonthIndex, 12);
                    const budgetCounts = row.unitCounts.budget;

                    return [...actualsCounts, ...reforecastCounts, ...budgetCounts];
                });

                return counts;
            };

            tableDataByTabAndUnitType = tableDataByTab
                // Filter out the child rows by unit type
                .map((eachRowGroup) => ({
                    dataFormat: eachRowGroup.dataFormat,
                    decimalPoints: eachRowGroup.decimalPoints,
                    name: eachRowGroup.label,
                    rows: buildChildRows(eachRowGroup),
                    id: eachRowGroup.value,
                    parentId: '',
                }))

                // Filter out parent rows with 0 child rows with the selected unit type
                .filter((eachRowGroup) => eachRowGroup.rows.length > 0)

                .map((eachRowGroup) => {
                    const getRenderableParentColumns = () => {
                        const rowGroupUnitCounts = getRowGroupUnitCounts(eachRowGroup.rows);
                        const renderableParentColumns: { [key: string]: string | number | null } = {};
                        const rowGroupValues = getRowGroupValues(eachRowGroup.rows, eachRowGroup.dataFormat, rowGroupUnitCounts);


                        const allColumns = [
                            eachRowGroup.name,
                            ...rowGroupValues.slice(0, 12),
                            getReforecastAverage(rowGroupValues),
                            ...rowGroupValues.slice(12),
                            getBudgetAverage(rowGroupValues),
                            getReforecastAverage(rowGroupValues),
                        ];

                        allColumns.forEach((each, index) => {
                            renderableParentColumns[`col${index}`] = each;
                        });

                        return renderableParentColumns as Omit<ITableDataByTabAndUnitType, 'id' | 'parentId' | 'decimalPoints'>;
                    };

                    const getRenderableChildColumns = (childRows: (string | number | null)[]): Omit<ITableDataByTabAndUnitType, 'id' | 'parentId' | 'decimalPoints'> => {
                        const renderableChildColumns: { [key: string]: string | number | null } = {};
                        const tempChildRows = [
                            ...childRows.slice(0, 13),
                            getReforecastAverage(childRows.slice(1)),
                            ...childRows.slice(13),
                            getBudgetAverage(childRows.slice(1)),
                            getReforecastAverage(childRows.slice(1)),
                        ];
                        tempChildRows.forEach((each, index) => {
                            renderableChildColumns[`col${index}`] = each;
                        });

                        return renderableChildColumns as Omit<ITableDataByTabAndUnitType, 'id' | 'parentId'>;
                    };
                    return ([
                        {
                            id: eachRowGroup.id,
                            parentId: eachRowGroup.parentId,
                            decimalPoints: eachRowGroup.decimalPoints,
                            ...getRenderableParentColumns(),
                            dataFormat: eachRowGroup.dataFormat,
                        },
                        ...eachRowGroup.rows.map((each) => ({
                            id: each.id,
                            parentId: each.parentId,
                            decimalPoints: each.decimalPoints,
                            ...getRenderableChildColumns([each.label, ...each.cellValues]),
                            dataFormat: each.dataFormat,
                        }))
                    ]);
                })
                // Flatten to get a 2D list for the HoT instance
                .flat() as ITableDataByTabAndUnitType[];

            dispatch({
                type: "SET_TABLE_DATA_BY_TAB_AND_UNIT_TYPE",
                tableDataByTabAndUnitType: tableDataByTabAndUnitType,
            });
        }
    }, [state.selectedTab, state.selectedUnitType, state.tableData !== undefined]);

    const setRawData = ({ rawData }: ISetRawData): void => {
        dispatch({
            type: "SET_RAW_DATA",
            rawData,
        });
    };

    const setSelectedTab = ({ selectedTab }: ISetSelectedTab): void => {
        dispatch({
            type: "SET_SELECTED_TAB",
            selectedTab,
        });
    };

    const setSelectedUnitType = ({ selectedUnitType }: ISetSelectedUnitType): void => {
        dispatch({
            type: "SET_SELECTED_UNIT_TYPE",
            selectedUnitType,
        });
    };

    const setTableData = ({ tableData }: ISetTableData): void => {
        dispatch({
            type: "SET_TABLE_DATA",
            tableData,
        });
    };

    const setTableDataByTabAndUnitType = ({ tableDataByTabAndUnitType }: ISetTableDataByTabAndUnitType): void => {
        dispatch({
            type: "SET_TABLE_DATA_BY_TAB_AND_UNIT_TYPE",
            tableDataByTabAndUnitType,
        });
    };

    const setUnitTypes = ({ unitTypes }: ISetUnitTypes): void => {
        dispatch({
            type: "SET_UNIT_TYPES",
            unitTypes,
        });
    };

    return {
        rawData: state.rawData,
        setRawData,

        selectedTab: state.selectedTab,
        setSelectedTab,

        selectedUnitType: state.selectedUnitType,
        setSelectedUnitType,

        tableData: state.tableData,
        setTableData,

        tableDataByTabAndUnitType: state.tableDataByTabAndUnitType,
        setTableDataByTabAndUnitType,

        unitTypes: state.unitTypes,
        setUnitTypes,
    };
}

function summaryReducer(state: State, action: Action): State {
    switch (action.type) {
        case "SET_RAW_DATA": {
            const { rawData } = action;

            return {
                ...state,
                rawData,
            };
        }

        case "SET_SELECTED_TAB": {
            const { selectedTab } = action;

            return {
                ...state,
                selectedTab,
            };
        }

        case "SET_SELECTED_UNIT_TYPE": {
            const { selectedUnitType } = action;

            return {
                ...state,
                selectedUnitType,
            };
        }

        case "SET_TABLE_DATA": {
            const { tableData } = action;

            return {
                ...state,
                tableData,
            };
        }

        case "SET_TABLE_DATA_BY_TAB_AND_UNIT_TYPE": {
            const { tableDataByTabAndUnitType } = action;

            return {
                ...state,
                tableDataByTabAndUnitType,
            };
        }

        case "SET_UNIT_TYPES": {
            const { unitTypes } = action;

            return {
                ...state,
                unitTypes,
            };
        }

        default:
            return state;
    }
}
