import {
    GetPropertiesAndUnitTypesQuery,
    useGetPropertiesAndUnitTypesQuery
} from "../../../__generated__/generated_types";
import {useEffect, useState} from "react";

export type PropertiesAndUnitTypes = GetPropertiesAndUnitTypesQuery["myProperties"];

export interface PropertiesAndUnitTypesDataState {
    isDataReady: boolean;
    data: PropertiesAndUnitTypes
}

export function useGetPropertiesAndUnitTypes(): PropertiesAndUnitTypesDataState {
    const [isDataReady, setDataReady] = useState<boolean>(false);
    const [data, setData] =
        useState<PropertiesAndUnitTypes>([]);

    const {
        data: propertiesAndUnitTypesData,
        loading: propertiesAndUnitTypesLoading,
        error: propertiesAndUnitTypesError
    } = useGetPropertiesAndUnitTypesQuery({fetchPolicy: "no-cache"});

    useEffect(
        () => {
            if(propertiesAndUnitTypesData == undefined || propertiesAndUnitTypesLoading){
                return;
            }
            setDataReady(true);
            setData(propertiesAndUnitTypesData.myProperties);
        },
        [propertiesAndUnitTypesData, propertiesAndUnitTypesLoading, propertiesAndUnitTypesError]
    );

    return {
        isDataReady,
        data
    };
}
