/* eslint-disable @typescript-eslint/no-empty-function */
import React, { ReactElement, useEffect, useState } from 'react';

import FinancialsSpread from "./sjs/FinancialsSpread";

import ShimmerRenderer, { ShimmerPageKey } from "../../../components/shimmer/ShimmerRenderer";
import { AnalystSections } from "../../../components/analyst/section-tabs/AnalystSectionTabs";
import { FinancialsLoadPhases } from "./FinancialsV2PlanningHubV1Shim";

export type FinancialsProps = {
    reportLoadProgress: (progressAmt: number|string) => void;
    style: React.CSSProperties;
}

function Financials({ reportLoadProgress, style }: FinancialsProps): ReactElement {

    const [viewReady, setViewReady] = useState<boolean>(false);
    const [dataReady, setDataReady] = useState<boolean>(false);

    return (
            <div style={style}>
                {(!viewReady || !dataReady) &&
                        <div>
                            <ShimmerRenderer pageKey={ShimmerPageKey.ANALYST_VIEW}/>
                        </div>}
                <div
                        style={{
                            ...(
                                    (!viewReady) ? { display: 'none' } : {}
                            )
                        }}>
                    <FinancialsSpread
                            setViewReady={setViewReady}
                            setDataReady={setDataReady}
                            reportLoadProgress={reportLoadProgress}
                    />
                </div>
            </div>
    );
}

export default Financials;
