/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React from 'react';
import Al from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';

interface Props {
    title?: string;
    message?: string | React.ReactNode;
    visible?: boolean;
    confirmText?: string;
    cancelText?: string;
    stripButton?: boolean;
    overlayStyle?: "blur" | "see-through";
    overlayColor?: string;
    onCancelClick?: () => void;
    onConfirmClick?: () => void;
    onClose?: () => void;
}

function Alert({ title = "Alert", confirmText = "Confirm", overlayStyle = "blur", overlayColor, cancelText = "Cancel", message, onCancelClick, onConfirmClick, onClose, stripButton }: Props) {


    const confirmPressed = () => {
        onConfirmClick?.();
        onClose?.();
    };

    const cancelPressed = () => {
        onCancelClick?.();
        onClose?.();
    };


    return (
        <div className={`d-flex _modal ${overlayStyle} justify-content-center align-items-center`} style={{ zIndex: 2000, background: overlayColor }}>
            <Al className='vz-alert' show={true} variant="light">
                <Al.Heading>{title}</Al.Heading>
                <p>{message}</p>
                <hr />
                <div className="d-flex justify-content-end">
                    <Button className={`${stripButton ? "strip-btn" : ""}`} variant={stripButton ? "secondary" : "primary"} style={{ marginRight: 15 }} onClick={confirmPressed}>{confirmText}</Button>
                    <Button className={`${stripButton ? "strip-btn" : ""}`} variant={stripButton ? "primary" : "secondary"} onClick={cancelPressed}>{cancelText}</Button>
                </div>
            </Al>
        </div>
    );
}

export default Alert;