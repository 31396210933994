import {ReactElement, useState} from "react";
import {Body, Footer, FooterItem, Header, Modal} from "@zendeskgarden/react-modals";
import {Button} from "@zendeskgarden/react-buttons";
import UserFormInput from "../user-form/UserFormInput";
import * as css from "../user-form/styles/userFormStyles.module.scss";
import UserFormDropdown from "../user-form/UserFormDropdown";
import {formatterEnumToTitle} from "../../../../../utils/formatters";
import {authorizationTitleRemapping} from "../../logic/authorizationRenamer";
import {useCreateGroupMutation, useUpdateGroupMutation} from "../../../../../__generated__/generated_types";
import {toast} from "react-toastify";

interface GroupFormProps {
    closed: (saved: boolean) => void;
    availableAuthorizations: string[];
    existingGroup?: {
        id: string;
        name: string;
        authorizations: string[];
    }
}

export function GroupForm(props: GroupFormProps): ReactElement {
    const [createGroup, {loading: createLoading}] = useCreateGroupMutation({});
    const [updateGroup, {loading: updateLoading}] = useUpdateGroupMutation({});
    const [name, setName] = useState<string>(props.existingGroup?.name ?? "");
    const [nameTouched, setNameTouched] = useState<boolean>(false);
    const [authorizations, setAuthorizations] = useState<string[]>(props.existingGroup?.authorizations ?? []);
    const [authorizationsTouched, setAuthorizationsTouched] = useState<boolean>(false);

    function doSave(): void {
        let promise:Promise<unknown>;
        if(props.existingGroup) {
            promise = updateGroup({
                variables: {
                    id: props.existingGroup.id,
                    name: name,
                    authorizations: authorizations
                }
            });
        } else {
            promise = createGroup({
                variables: {
                    name: name,
                    authorizations: authorizations
                }
            });
        }

        promise.then(() => {
            toast.success("Group Saved!");
            props.closed(true);
        }).catch(() => {/* Do Nothing */});
    }

    return <Modal>
        <Header>
            {props.existingGroup ? "Edit Group" : "Create Group"}
        </Header>
        <Body>
            <form className={css.userForm}>
                <UserFormInput
                        label="Name"
                        placeholder="Group Name"
                        required
                        value={name}
                        error={nameTouched && name === "" ? "Name cannot be empty." : undefined}
                        onChange={(evt) => {setName(evt.target.value); setNameTouched(true);}}
                        onBlur={() => { /* Do Nothing */}} />

                <UserFormDropdown
                        label="Authorizations"
                        required
                        isMultiselect
                        selection={!authorizations ? undefined : authorizations.map(a => ({
                            id: a,
                            name: formatterEnumToTitle(authorizationTitleRemapping(a))
                        }))}
                        options={props.availableAuthorizations.map(a => ({
                            id: a,
                            name: formatterEnumToTitle(authorizationTitleRemapping(a))
                        }))}
                        error={authorizationsTouched && authorizations.length === 0 ? "You must have at least one authorization." : undefined}
                        downshiftProps={{itemToString: (item: any) => item && item.id}}
                        onSelect={authorizations => {
                            setAuthorizations(authorizations.map((a: any) => a.id));
                            setAuthorizationsTouched(true);
                        }}
                        onBlur={() => {/* Do Nothing */}} />
            </form>
        </Body>
        <Footer>
            <FooterItem>
                <Button onClick={() => props.closed(false)}>Cancel</Button>
            </FooterItem>
            <FooterItem>
                <Button
                        isPrimary
                        disabled={createLoading || updateLoading || !(name && name !== "") || !(authorizations && authorizations.length > 0) }
                        onClick={() => doSave()}
                >Save</Button>
            </FooterItem>
        </Footer>
    </Modal>;
}