import { TwoYearsColSpec } from "./types";
import { TwoYearsSheetStyles as sheetStyle } from './TwoYearsSheetStyles';
import { SJSSheetStyles as SJSS } from "../../../layout/shared/SJSSheetStyles";
import { TwoYearsColId as colID } from "./enums";
import { ToggleOpenIconSpec } from "../../../../components/spreadsheet/spreadjs-custom-cell-types/icons/CellDecoratorSpecs";
import * as Icons from "../../../../components/spreadsheet/spreadjs-custom-cell-types/icons/IconResources";

const MONTH_SPEC: TwoYearsColSpec = {
    width: 74,
    actualHeader: SJSS.ACTUAL_DATA_HEADER,
    lastActualHeader: SJSS.LAST_ACTUAL_DATA_HEADER,
    reforecastHeader: SJSS.REFORECAST_DATA_HEADER,
    lastReforecastHeader: SJSS.LAST_REFORECAST_DATA_HEADER,
    content: SJSS.EDITABLE_CELL,
    contentRowHover: sheetStyle.DATA_CELL_HOVER,
    contentActual: sheetStyle.ACTUAL_CELL,
    contentActualLast: sheetStyle.LAST_ACTUAL_CELL,
    contentActualRowHover: sheetStyle.LOCKED_DATA_CELL_HOVER,
    adminLocked: sheetStyle.ACTUAL_CELL,
};

export const TwoYearsLayoutColSpecs: Record<number, TwoYearsColSpec> = {
    [colID.ITEM_TITLE]: {
        width: 191,
        actualHeader: sheetStyle.ITEM_HEADER,
        content: sheetStyle.ITEM_LABEL,
        contentRowHover: sheetStyle.ITEM_LABEL_HOVER,
        contentActual: sheetStyle.ITEM_LABEL,
        contentActualRowHover: sheetStyle.ITEM_LABEL_HOVER,
        adminLocked: sheetStyle.ITEM_LABEL,
        isLabelCell: true,
    },
    [colID.REFO_MONTH_0]: MONTH_SPEC,
    [colID.REFO_MONTH_1]: MONTH_SPEC,
    [colID.REFO_MONTH_2]: MONTH_SPEC,
    [colID.REFO_MONTH_3]: MONTH_SPEC,
    [colID.REFO_MONTH_4]: MONTH_SPEC,
    [colID.REFO_MONTH_5]: MONTH_SPEC,
    [colID.REFO_MONTH_6]: MONTH_SPEC,
    [colID.REFO_MONTH_7]: MONTH_SPEC,
    [colID.REFO_MONTH_8]: MONTH_SPEC,
    [colID.REFO_MONTH_9]: MONTH_SPEC,
    [colID.REFO_MONTH_10]: MONTH_SPEC,
    [colID.REFO_MONTH_11]: {
        ...MONTH_SPEC,
        ...{
            header: SJSS.LAST_DATA_HEADER,
            content: SJSS.LAST_DATA_CELL,
            contentRowHover: sheetStyle.LAST_DATA_CELL_HOVER,
        }
    },
    [colID.REFO_TOTALS]: {
        width: 94,
        actualHeader: sheetStyle.TOTALS_COLUMN_HDR,
        content: sheetStyle.TOTALS_COLUMN_DATA_CELL,
        contentRowHover: sheetStyle.TOTALS_COLUMN_DATA_CELL,
        contentActual: sheetStyle.TOTALS_COLUMN_DATA_CELL,
        contentActualRowHover: sheetStyle.TOTALS_COLUMN_DATA_CELL,
        adminLocked: sheetStyle.TOTALS_COLUMN_DATA_CELL_LOCKED,
    },
    [colID.BUDGET_MONTH_0]: MONTH_SPEC,
    [colID.BUDGET_MONTH_1]: MONTH_SPEC,
    [colID.BUDGET_MONTH_2]: MONTH_SPEC,
    [colID.BUDGET_MONTH_3]: MONTH_SPEC,
    [colID.BUDGET_MONTH_4]: MONTH_SPEC,
    [colID.BUDGET_MONTH_5]: MONTH_SPEC,
    [colID.BUDGET_MONTH_6]: MONTH_SPEC,
    [colID.BUDGET_MONTH_7]: MONTH_SPEC,
    [colID.BUDGET_MONTH_8]: MONTH_SPEC,
    [colID.BUDGET_MONTH_9]: MONTH_SPEC,
    [colID.BUDGET_MONTH_10]: MONTH_SPEC,
    [colID.BUDGET_MONTH_11]: {
        ...MONTH_SPEC,
        ...{
            header: SJSS.LAST_DATA_HEADER,
            content: SJSS.LAST_DATA_CELL,
            contentRowHover: sheetStyle.LAST_DATA_CELL_HOVER,
        }
    },
    [colID.BUDGET_TOTALS]: {
        width: 94,
        actualHeader: sheetStyle.TOTALS_COLUMN_HDR,
        content: sheetStyle.TOTALS_COLUMN_DATA_CELL,
        contentRowHover: sheetStyle.TOTALS_COLUMN_DATA_CELL,
        contentActual: sheetStyle.TOTALS_COLUMN_DATA_CELL,
        contentActualRowHover: sheetStyle.TOTALS_COLUMN_DATA_CELL,
        adminLocked: sheetStyle.TOTALS_COLUMN_DATA_CELL_LOCKED,
    },
};

export const ExpandableIconSpec: ToggleOpenIconSpec = {
    isOpen: false,
    openIconImg: Icons.plusMinusOpen,
    openIconSpecs: {
        x: -18,
        y: 8,
        width: 8,
        height: 10,
    },
    openIconHoverImg: Icons.plusMinusOpen,
    openIconHoverSpecs: {
        x: -18,
        y: 8,
        width: 8,
        height: 10,
    },
    closedIconImg: Icons.plusMinusClosed,
    closedIconSpecs: {
        x: -19,
        y: 8,
        width: 10,
        height: 10,
    },
    closedIconHoverImg: Icons.plusMinusClosed,
    closedIconHoverSpecs: {
        x: -19,
        y: 8,
        width: 10,
        height: 10,
    },
};

export const AccountIconSpec: ToggleOpenIconSpec = {
    isOpen: false,
    openIconImg: Icons.chevronOpen,
    openIconSpecs: {
        x: 178,
        y: 6,
        width: 12,
        height: 12,
    },
    openIconHoverImg: Icons.chevronOpen,
    openIconHoverSpecs: {
        x: 178,
        y: 6,
        width: 12,
        height: 12,
    },
    closedIconImg: Icons.chevronClosed,
    closedIconSpecs: {
        x: 178,
        y: 6,
        width: 12,
        height: 12,
    },
    closedIconHoverImg: Icons.chevronClosed,
    closedIconHoverSpecs: {
        x: 178,
        y: 6,
        width: 12,
        height: 12,
    },
};
