import { ReactElement, useEffect, useState } from "react";
import useAppStore from "../../hooks/useAppStore";
import { ViziblyTheme } from "../analyst/ViziblyZDGTheme";
import { ThemeProvider } from "@zendeskgarden/react-theming";
import * as css from "./styles/bulkUpdate.module.scss";
import AdminHeader from "../admin/AdminHeader";
import { Tab, TabList, TabPanel, Tabs } from "@zendeskgarden/react-tabs";
import Card from "../simplified-revenue/components/Card";
import { Field, MediaInput } from "@zendeskgarden/react-forms";
import { ReactComponent as SearchIcon } from '@zendeskgarden/svg-icons/src/16/search-stroke.svg';
import { useGetUserPropertiesForecastLocksLazyQuery } from "../../__generated__/generated_types";
import { Property, useProperties } from "../../contexts/properties/PropertiesContext";
import { useConfig } from "../../hooks/useConfig";
import { ReactComponent as CheckIcon } from '@zendeskgarden/svg-icons/src/16/check-lg-fill.svg';
import PropertyLockBulkUpdater from "./components/PropertyLockBulkUpdater";
import { toast } from "react-toastify";
import AccountLockBulkUpdater from "./components/AccountLockBulkUpdater";
import { FinancialEntity, useChartOfAccounts } from "../../contexts/chartofaccounts/ChartOfAccountsContext";
import { IFinancialEntityWithDepth, getChartOfAccountsWithDepths } from "../../utils/account";

export type TLockedProperties = {
    reforecastLocked: string[],
    budgetLocked: string[],
}

export default function BulkUpdate(): ReactElement {
    const appStore = useAppStore();
    const {isReady: coaReady, chartOfAccounts} = useChartOfAccounts();
    const { year, isReady: configReady } = useConfig();
    const { properties } = useProperties();
    const [filteredProperties, setFilteredProperties] = useState<Property[]>([]);
    const [selectedTab, setSelectedTab] = useState<string>("property-plans");
    const [lockedProperties, setLockedProperties] = useState<TLockedProperties>({reforecastLocked: [], budgetLocked: []});
    const [accounts, setAccounts] = useState<FinancialEntity[]>([]);
    const [flatAccounts, setFlatAccounts] = useState<IFinancialEntityWithDepth[]>([]);

    const [getPropertyLocks, {data: propertyLocks, loading: propertyLocksLoading}] = useGetUserPropertiesForecastLocksLazyQuery({
        fetchPolicy: "no-cache",
    });

    useEffect(() => {
        appStore.set({isLoading: false});

        return () => undefined;
    }, []);

    useEffect(() => {
        if (!configReady) {
            return;
        }

        getPropertyLocks({
            variables: {
                budgetYear: year + 1,
            }
        });
    }, [configReady]);

    useEffect(() => {
        if (!properties || !propertyLocks || propertyLocksLoading || !propertyLocks.userPropertiesForecastLocks) {
            return;
        }

        setLockedProperties(propertyLocks.userPropertiesForecastLocks);

        setFilteredProperties(properties);
    }, [properties, propertyLocks, propertyLocksLoading]);

    useEffect(() => {
        if (coaReady && chartOfAccounts) {
            const acctsWithDepths = getChartOfAccountsWithDepths(chartOfAccounts);
            setAccounts(chartOfAccounts);
            setFlatAccounts(acctsWithDepths);
        }
    }, [coaReady, chartOfAccounts]);

    return (
        <ThemeProvider theme={ViziblyTheme}>
            <div className={css.wrapper}>
                <AdminHeader
                    title={"Lock Management"}
                    subtitle={"Make bulk update changes to your property plans."}
                />

                <Tabs selectedItem={selectedTab} onChange={setSelectedTab}>
                    <TabList>
                        <Tab item="property-plans">Property Plans</Tab>
                        <Tab item="gl-accounts">GL Accounts</Tab>
                    </TabList>

                    <TabPanel item="property-plans">
                        <Card
                            className={css.updaterControlsCard}
                            titleClassName={css.updaterControlsTitle}
                            title={"Bulk Update Property Plans"}
                        >
                            {properties &&
                                <PropertyLockBulkUpdater
                                    budgetYear={year + 1}
                                    properties={properties}
                                    lockedProperties={lockedProperties}
                                    onUpdateCompleted={(numberUpdated: number) => {
                                        getPropertyLocks({
                                            variables: {
                                                budgetYear: year + 1,
                                            }
                                        });
                                        toast.success(`${numberUpdated} ${numberUpdated == 1 ? "property" : "properties"} ${numberUpdated == 1 ? "has" : "have"} been updated.`);
                                    }}
                                />
                            }
                        </Card>

                        <Card
                            title={"Propery Plans Finalize Status"}
                            actions={
                                <Field className={css.propertySearch}>
                                    <MediaInput start={<SearchIcon />} placeholder="Search" onChange={e => {
                                        if (!properties) {
                                            return;
                                        }
                                        setFilteredProperties(properties.filter(p => p.name.toLowerCase().includes(e.target.value.trim().toLowerCase())));
                                    }} />
                                </Field>
                            }
                        >
                            <div className={css.propertyTableWrapper}>
                                <table className={css.propertyTable}>
                                    <thead>
                                        <tr className={css.headerCell}>
                                            <th className={`${css.cell} ${css.nameCell}`}>Property</th>
                                            <th className={`${css.cell} ${css.periodCell}`}>{year} Reforecast</th>
                                            <th className={`${css.cell} ${css.periodCell}`}>{year + 1} Budget</th>
                                            <th className={`${css.cell}`}></th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        {filteredProperties.map(property => (
                                                <tr key={property.id}>
                                                    <td className={`${css.cell}`}>{ property.name }</td>
                                                    <td className={`${css.cell} ${css.periodCell}`}>{ lockedProperties.reforecastLocked.includes(property.id) && <CheckIcon /> }</td>
                                                    <td className={`${css.cell} ${css.periodCell}`}>{ lockedProperties.budgetLocked.includes(property.id) && <CheckIcon /> }</td>
                                                    <td className={`${css.cell}`}></td>
                                                </tr>
                                            ))}
                                    </tbody>
                                </table>
                            </div>
                        </Card>
                    </TabPanel>

                    <TabPanel item="gl-accounts">
                        <Card
                            className={css.updaterControlsCard}
                            titleClassName={css.updaterControlsTitle}
                            title={"Bulk Update GL Accounts"}
                        >
                            {properties &&
                                <AccountLockBulkUpdater
                                    budgetYear={year + 1}
                                    properties={properties}
                                    accounts={accounts}
                                    flatAccounts={flatAccounts}
                                    lockedProperties={lockedProperties}
                                    onUpdateCompleted={() => {
                                        toast.success("GL Account updates completed.");
                                    }}
                                />
                            }
                        </Card>
                    </TabPanel>
                </Tabs>
            </div>
        </ThemeProvider>
    );
}
