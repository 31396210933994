import { Destination } from "../navigation/useNavigation";

export function parseNavigationDestination(destination: any): Destination {
    let parsedDestination = Destination.Overview; // default

    if (typeof destination === "string") {
        switch(destination){
            case Destination.Overview:
            case Destination.LineItems:
            case Destination.PropertyExecutiveSummary:
            case Destination.MVR:
            case Destination.FinancialReports:
            case Destination.OperationalReports: {
                parsedDestination = destination;
            }
        }
    }

    return parsedDestination;
}
