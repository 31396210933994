import { IMenuTitleOption } from "../../components/menu-title/logic/menuTitle";
import { SERIES_DATA_OPTION } from "./logic/usePropertyExecutiveSummaryData";
import assetLivingLogo from "../../assets/images/asset_living/pdf_logo.png";

export interface IClientBrandingAsset {
    colorPrimary: string,
    colorSecondary: string,
    banner: string | null,
    logo: string | null,
    logoMini: string | null,
}

export const OPERATIONAL_MENU_OPTIONS: IMenuTitleOption[] = [
    { label: 'Occupancy', value: SERIES_DATA_OPTION.OCCUPANCY },
    { label: 'Expirations', value: SERIES_DATA_OPTION.EXPIRATIONS },
    { label: 'Renewals', value: SERIES_DATA_OPTION.RENEWALS },
    { label: 'Renewal Ratios', value: SERIES_DATA_OPTION.RENEWAL_RATIOS },
    // { label: 'Renewal Trade Outs', value: SERIES_DATA_OPTION.RENEWAL_TRADEOUTS },
    { label: 'Move Outs', value: SERIES_DATA_OPTION.MOVE_OUTS },
    { label: 'Move Out Ratios', value: SERIES_DATA_OPTION.MOVE_OUTS_RATIOS },
    // { label: 'New Lease Trade Outs', value: SERIES_DATA_OPTION.NEW_LEASE_TRADEOUTS }
    // { label: 'Rents', value: SERIES_DATA_OPTION.MOVE_OUTS_RATIOS },
    { label: 'Net Operating Income', value: SERIES_DATA_OPTION.NOI },
    { label: 'Rental Income', value: SERIES_DATA_OPTION.RENTAL_INCOME },
    { label: 'Total Expenses', value: SERIES_DATA_OPTION.TOTAL_EXPENSE },
    { label: 'Budgeted Market Rents', value: SERIES_DATA_OPTION.BUDGETED_MARKET_RENT },
];

export const CLIENT_LOGO_ASSET_MAP: {[id: string]: IClientBrandingAsset} = {
    "6de1678f-59af-49b1-accd-4b76b612a160": {
        colorPrimary: "#003C71",
        colorSecondary: "#00A1E4",
        banner: "asset_living/asset_living_bg_img.png",
        logo: assetLivingLogo,
        logoMini: "asset_living/asset_living_logo_mini.jpg",
    },
    "0b1f3821-0616-4fed-9ca6-bfa9ecfd8f3c": {
        colorPrimary: "#003C71",
        colorSecondary: "#00A1E4",
        banner: "asset_living/asset_living_bg_img.png",
        logo: "asset_living/asset_living_logo.svg",
        logoMini: "asset_living/asset_living_logo_mini.jpg",
    }
}

export function loadClientBrandAsset(id?: string): IClientBrandingAsset {
    const defaultAsset: IClientBrandingAsset = {
        colorPrimary: "#083D41",
        colorSecondary: "#888888",
        banner: "executive_summary/executive_summary_default_banner_bg.jpg",
        logo: null,
        logoMini: null,
    }

    if (!id) {
        return defaultAsset;
    }

    let asset = CLIENT_LOGO_ASSET_MAP[id];

    if (!asset) {
        return defaultAsset;
    }

    return asset;
}
