import { ReactElement } from "react";

import * as css from "./styles/transactionDetails.module.scss";
import { TransactionRowViewModel, TransactionType } from "../../data/logic/buildTransactionRows";
import PayableDetails from "./components/PayableDetails";

interface ITransactionDetailsProps {
    transaction: Partial<TransactionRowViewModel>;
}

export default function TransactionDetails(props: ITransactionDetailsProps): ReactElement {

    // TODO: Replace w/ payload prop once available
    const transactionType = TransactionType.Payable;

    const getDetailsBody = (transactionType: TransactionType): ReactElement => {
        switch (transactionType) {
            case TransactionType.Payable: {
                return <PayableDetails transaction={props.transaction}/>;
            }
            default: {
                return <PayableDetails transaction={props.transaction}/>;
            }
        }
    };

    return (
            <div className={css.detailsBody}>
                <div className={css.leftCellBorder}></div>
                {getDetailsBody(transactionType)}
            </div>
    );
}
