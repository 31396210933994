import TwoYearsLayout, { TwoYearsLayoutConfig } from "../../../../sjs/layout/two-years/TwoYearsLayout";
import { TwoYearsTableData } from "../../../../sjs/layout/two-years/helpers/types";
import React from "react";
import { SJSCfg } from "../../../../sjs/layout/shared/SJSConfig";
import { TwoYearsColId } from "../../../../sjs/layout/two-years/helpers/enums";
import { isNaN } from "lodash";
import { TwoYearsConfig as CFG } from "../../../../sjs/layout/two-years/helpers/TwoYearsConfig";
import { SJSSheetStyles as SJSS } from "../../../../sjs/layout/shared/SJSSheetStyles";
import { SummarySJSStyles } from "./SummarySheetStyles";

export interface SummaryLayoutData {
    opMetricHeader: TwoYearsTableData[0];
    opMetrics: TwoYearsTableData;
    financialsHeader: TwoYearsTableData[0];
    financials: TwoYearsTableData;
    setDataReady: React.Dispatch<React.SetStateAction<boolean>>;
}

export default class SummaryLayout extends TwoYearsLayout {
    private static readonly DOLLAR_METRIC_CELLS = [
        "Avg Market Rent",
        "Renewal Increase"
    ];

    private static readonly PERCENTAGE_METRIC_CELLS = [
        "Renewal Ratio",
        "Move Out %",
        "Occupancy"
    ];

    constructor({ name, ssapi, template, year, firstReforecastMonth, reportLoadProgress }: TwoYearsLayoutConfig) {
        super({
            name,
            ssapi,
            template,
            year,
            firstReforecastMonth,
            reportLoadProgress
        });

        ssapi.directAccess(spread => {
            spread.options.showHorizontalScrollbar = true;
        });
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    public override renderTwoYearsTableData(_data: TwoYearsTableData, _setDataReady: React.Dispatch<React.SetStateAction<boolean>>): void {
        // Do Nothing
        throw new Error("DO NOT CALL THIS METHOD.");
    }

    public renderDataCustom(data: SummaryLayoutData): void {
        this._ssapi.directAccess(spread => {
            const sheet = spread.getSheetFromName(SJSCfg.MAIN_SHEET_NAME);
            const rowCount = data.financials.length + data.opMetrics.length + 2 + SJSCfg.FIRST_DATA_ROW;

            sheet.setColumnVisible(CFG.TOTALS_COL, false);
            sheet.setColumnVisible(CFG.BUDGET_TOTALS_COL, false);

            sheet.setRowCount(rowCount);
            sheet.setColumnCount(Object.keys(TwoYearsColId).filter((v) => isNaN(Number(v))).length + SJSCfg.FIRST_VISIBLE_COL);

            sheet.getCell(SJSCfg.FIRST_VISIBLE_ROW, SJSCfg.FIRST_VISIBLE_COL)
                .value('Description')
                .setStyle(SJSS.ENTITY_COL_LABEL);

            sheet.setArray(SJSCfg.FIRST_DATA_ROW, CFG.LABEL_COL, [
                data.opMetricHeader,
                ...data.opMetrics,
                data.financialsHeader,
                ...data.financials
            ]);

            sheet.getCell(SJSCfg.FIRST_DATA_ROW, CFG.LABEL_COL).setStyle(SummarySJSStyles.TOP_LEVEL_LABEL);
            sheet.getRange(SJSCfg.FIRST_DATA_ROW, CFG.LABEL_COL + 1, 1, 26).setStyle(SummarySJSStyles.TOP_LEVEL_LABEL_CELL);
            sheet.getRange(SJSCfg.FIRST_DATA_ROW + 1, CFG.LABEL_COL, data.opMetrics.length, 1).setStyle(SummarySJSStyles.NESTED_METRIC_LABEL);
            //sheet.getRange(SJSCfg.FIRST_DATA_ROW + 1, CFG.LABEL_COL + 1, data.opMetrics.length, 26).setStyle(SummarySJSStyles.BASIC_CELL);
            sheet.getCell(SJSCfg.FIRST_DATA_ROW + 1 + data.opMetrics.length, CFG.LABEL_COL).setStyle(SummarySJSStyles.TOP_LEVEL_LABEL);
            sheet.getRange(SJSCfg.FIRST_DATA_ROW + 1 + data.opMetrics.length, CFG.LABEL_COL + 1, 1, 26).setStyle(SummarySJSStyles.TOP_LEVEL_LABEL_CELL);
            sheet.getRange(SJSCfg.FIRST_DATA_ROW + 2 + data.opMetrics.length, CFG.LABEL_COL, data.financials.length, 1).setStyle(SummarySJSStyles.NESTED_FINANCIAL_LABEL);
            sheet.getRange(SJSCfg.FIRST_DATA_ROW + 2 + data.opMetrics.length, CFG.LABEL_COL + 1, data.financials.length, 26).setStyle(SummarySJSStyles.BASIC_DOLLAR_CELL);

            for (let i = 0, row = SJSCfg.FIRST_DATA_ROW + 1; i < data.opMetrics.length; i++, row++) {
                let style = SummarySJSStyles.BASIC_CELL;
                const rowData: TwoYearsTableData[0] = data.opMetrics[i] ?? [];
                if(rowData.length > 1 && rowData[0]){
                    if(SummaryLayout.DOLLAR_METRIC_CELLS.includes(rowData[0].toString())){
                        style = SummarySJSStyles.BASIC_DOLLAR_CELL;
                    }
                    else if(SummaryLayout.PERCENTAGE_METRIC_CELLS.includes(rowData[0].toString())){
                        style = SummarySJSStyles.BASIC_PERCENT_CELL;
                    }
                }
                sheet.getRange(row, CFG.LABEL_COL + 1, 1, 26).setStyle(style);
            }

            for (let i = SJSCfg.FIRST_DATA_ROW; i < rowCount; i++) {
                sheet.autoFitRow(i);
            }

            this.reportLoadProgress(100);
            data.setDataReady(true);
        });
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    override applyRowHoverStyle(_row: number): void {
        // Do Nothing
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    override applyRowDefaultStyle(_row: number): void {
        // Do Nothing
    }
}
