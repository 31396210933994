import {ReactElement} from "react";
import * as css from "../styles/OpDriverModeling.module.scss";
import WorkflowHeader from "../../../components/workflows/workflow-header/WorkflowHeader";
import WorkflowNavToggle from "../../../components/workflows/workflow-nav-toggle/WorkflowNavToggle";
import WorkflowPageLabel from "../../../components/workflows/workflow-page-label/WorkflowPageLabel";
import WorkflowStepNav from "../../../components/workflows/workflow-step-nav/WorkflowStepNav";
import {Dropdown, Field, Item, Label, Menu, Select} from "@zendeskgarden/react-dropdowns";

export enum DropdownPlacement {
    RIGHT,
    BOTTOM,
}

export interface IHeaderProps {
    title: string,
    subtitle?: string,
    selectedOption?: string,
    onSelectOption?: (option: string) => void,
    options?: string[],
    dropdownPlacement?: DropdownPlacement,
    prevAction?: () => void,
    nextAction?: () => void,
}

export default function Header({
    title,
    subtitle,
    selectedOption,
    onSelectOption,
    options,
    dropdownPlacement=DropdownPlacement.BOTTOM,
    prevAction,
    nextAction,
}: IHeaderProps): ReactElement {

    const renderDropdown = () => options && (
        <Dropdown
            selectedItem={selectedOption}
            onSelect={onSelectOption}
        >
            <Field className={css.headerDropdown}>
                <Select className={css.headerSelect}>
                    <Label className={css.dropdownLabel}>Rent Roll</Label>
                    {selectedOption}
                </Select>
            </Field>
            <Menu>
                {options.map(option => (
                    <Item key={option} value={option}>
                        {option}
                    </Item>
                ))}
            </Menu>
        </Dropdown>
    );

    return (
        <WorkflowHeader>
            <WorkflowHeader.LeftCol>
                <div>
                    <WorkflowNavToggle />
                    <WorkflowPageLabel label={`Operational Drivers // ${title}`} />

                    <br/>

                    {dropdownPlacement === DropdownPlacement.BOTTOM && renderDropdown()}

                    {subtitle &&
                        <h5 className={css.headerSubtitle}>{subtitle}</h5>
                    }
                </div>
            </WorkflowHeader.LeftCol>

            <WorkflowHeader.RightCol>
                {dropdownPlacement === DropdownPlacement.RIGHT && renderDropdown()}
                <WorkflowStepNav onPreviousClick={prevAction} onNextClick={nextAction} />
            </WorkflowHeader.RightCol>
        </WorkflowHeader>
    );
}
