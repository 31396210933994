import {ReactElement, useContext, useEffect, useRef, useState} from "react";
import {Well} from "@zendeskgarden/react-notifications";
import {Tab, TabList, TabPanel, Tabs} from "@zendeskgarden/react-tabs";
import * as css from './styles/styles.module.scss';
import * as accountsCss from "../../pages/workflows/account/styles/styles.module.scss";
import * as workflowCSS from "../../styles/workflows/workflowCSS.module.scss";
import {Col, Grid, Row} from "@zendeskgarden/react-grid";
import {AccountSummaryTabOptions} from "./logic/AccountSummaryLogic";
import {VarianceCard} from "./components/VarianceCard";
import {MonthlyCard} from "./components/MonthlyCard";
import {GrowthCard} from "./components/GrowthCard";
import {BudgetRemainingCard} from "./components/BudgetRemainingCard";
import {CategoryDescriptor, ChartOfAccountsState} from "../../contexts/chartofaccounts/ChartOfAccountsContext";
import {IAccountSummaryData, useAccountSummaryContext} from "../../pages/workflows/account/AccountSummaryContext";
import {FinancialMetrics, FinancialMetricsProps} from "../metrics/FinancialMetrics";
import {BudgetingType} from "../../BudgetingType";
import {Skeleton} from "@zendeskgarden/react-loaders";
import {VersionType} from "../../__generated__/generated_types";

interface IAccountSummaryProps {
    chartOfAccounts: ChartOfAccountsState,
    accountId: string,
    parentCategories: CategoryDescriptor[],
    configYear: number,
    rfcstStartMonth: number,
    financialYearValues: IAccountSummaryData,
    versionType: VersionType,
}

export function AccountSummary(props: IAccountSummaryProps): ReactElement {
    const ctxAcctSummary = useAccountSummaryContext();
    const [activeSummaryTab, setActiveSummaryTab] = useState<AccountSummaryTabOptions>(AccountSummaryTabOptions.summary);

    useEffect(() => {
        if (props.financialYearValues === undefined || props.versionType === undefined || props.rfcstStartMonth === undefined || props.configYear === undefined) {
            return;
        }

        ctxAcctSummary.setData(props.financialYearValues);
        ctxAcctSummary.setBudgetType(props.versionType == VersionType.Reforecast ? BudgetingType.REFORECAST : BudgetingType.BUDGET);
        ctxAcctSummary.setStartReforecastMonthIndex(props.rfcstStartMonth);
        ctxAcctSummary.setCurrentYear(props.configYear);
    }, [props.financialYearValues, props.versionType, props.rfcstStartMonth, props.configYear]);

    useEffect(
        () => {
            if (!props.chartOfAccounts.isReady || !props.accountId) return;

            const isIncome = props.chartOfAccounts.isIncome[props.accountId];

            if (isIncome != undefined) {
                ctxAcctSummary.setIsIncome(isIncome);
            }
        },
        [props.chartOfAccounts.isReady, props.accountId]
    );

    return (
        <div className={css.accountSummaryWrapper}>
            <Well className={css.accountSummary}>
                <Tabs selectedItem={activeSummaryTab} onChange={setActiveSummaryTab}>
                    <TabList className={activeSummaryTab == AccountSummaryTabOptions.financialImpact ? css.tabListNoMargin : ''}>
                        <Tab item={AccountSummaryTabOptions.summary}>Summary</Tab>
                        <Tab item={AccountSummaryTabOptions.financialImpact}>Financial Impact</Tab>
                    </TabList>

                    <TabPanel item={AccountSummaryTabOptions.summary}>
                        <Grid>
                            <Row>
                                {ctxAcctSummary.budgetType == BudgetingType.REFORECAST
                                    ? <>
                                        <Col className={css.accountSummaryColumn}>
                                            {ctxAcctSummary.isReady
                                                ? <VarianceCard />
                                                : <Skeleton />
                                            }
                                        </Col>

                                        <Col className={css.accountSummaryColumn}>
                                            {ctxAcctSummary.isReady
                                                ? <MonthlyCard />
                                                : <Skeleton />
                                            }
                                        </Col>

                                        <Col className={css.accountSummaryColumn}>
                                            {ctxAcctSummary.isReady
                                                ? <GrowthCard />
                                                : <Skeleton />
                                            }
                                        </Col>

                                        <Col className={css.accountSummaryColumn}>
                                            {ctxAcctSummary.isReady
                                                ? <BudgetRemainingCard />
                                                : <Skeleton />
                                            }
                                        </Col>
                                    </>
                                    : <>
                                        <Col className={css.accountSummaryColumn} style={{'width': '50%'}}>
                                            {ctxAcctSummary.isReady
                                                ? <MonthlyCard wide />
                                                : <Skeleton />
                                            }
                                        </Col>

                                        <Col className={css.accountSummaryColumn} style={{'width': '50%'}}>
                                            {ctxAcctSummary.isReady
                                                ? <GrowthCard wide />
                                                : <Skeleton />
                                            }
                                        </Col>
                                    </>
                                }
                            </Row>
                        </Grid>
                    </TabPanel>

                    <TabPanel item={AccountSummaryTabOptions.financialImpact}>
                        <div className={workflowCSS.accountFinancialsContainer}>
                            <div className='financial-metrics-container' key='fin-metrics'>
                                <FinancialMetrics
                                    parentCategories={props.parentCategories}
                                    accountId={props.accountId}
                                    year={props.configYear}
                                    showActuals={ctxAcctSummary.budgetType == BudgetingType.REFORECAST}
                                    className={`${accountsCss.spreadsheetContainer} ${accountsCss.spreadsheetContainerNested}`}
                                    financialYearValues={props.financialYearValues}
                                />
                            </div>
                        </div>
                    </TabPanel>
                </Tabs>
            </Well>
        </div>
    );
}