import {AuthUser} from "../contexts/AuthContext";
import {Authorizations} from "./Authorizations";
import {ForecastLockModel, UserRole} from "../__generated__/generated_types";
import { accountViewFxBarUserPermissionsMap, ModelingMethodName } from "../pages/workflows/account/formula-bar/logic/utils";

export type TLockOverrides = {
    reforecast: boolean,
    budget: boolean,
}

export function checkAuthorization(user: AuthUser | null, authorization: Authorizations): boolean {
    if(!user) {
        return false;
    }

    if(user.role === UserRole.Admin) {
        return true;
    }

    return user.authorizations.includes(authorization);
}

export function userIsReadOnly(user: AuthUser | null):boolean {
    if(!user || user.role === UserRole.Admin) {
        return false;
    }

    return user.isReadOnly;
}

export function checkOnlyAuthorization(user: AuthUser | null, authorization: Authorizations): boolean {
    if(!user) {
        return false;
    }

    return user.authorizations.length == 0 && user.authorizations.firstElement == authorization;
}

export function canOverrideAssumptionLock(user: AuthUser, forecastLocks: ForecastLockModel): TLockOverrides {
    const userCanEditLockedAccounts = checkAuthorization(user, Authorizations.EditLockUnlockAccounts);
    const lockOverrides: TLockOverrides = {
        reforecast: true,
        budget: true,
    };

    if (forecastLocks.property.reforecastLocked || (forecastLocks.account?.reforecastLocked && !userCanEditLockedAccounts)) {
        lockOverrides.reforecast = false;
    }

    if (forecastLocks.property.budgetLocked || (forecastLocks.account?.budgetLocked && !userCanEditLockedAccounts)) {
        lockOverrides.budget = false;
    }

    return lockOverrides;
}

export function buildEditableFxBarChecker(user: AuthUser, accountLockedForVersionType: boolean): (modelingMethod: ModelingMethodName) => boolean {
    const canUserEditModelingMethod = (modelingMethod: ModelingMethodName): boolean => {
        if (user && user.role) {
            const editableModelingMethods = accountViewFxBarUserPermissionsMap[user.role];

            /**
             * As long as the forecast period for the property is not locked,
             *  a user can edit any modeling method if they have both:
             *  1. The EditLockUnlockAccounts permission
             *  2. Authorization to edit the current modeling method (Admins can edit all, Managers can edit some)
             */
            if (accountLockedForVersionType) {
                return checkAuthorization(user, Authorizations.EditLockUnlockAccounts)
                    && (checkAuthorization(user, Authorizations.EditModelingMethods) || editableModelingMethods.includes(modelingMethod));
            }

            /**
             * If the account is unlocked for the current version type, then
             *  - A user with the EditLockUnlockAccounts permission can edit any modeling method
             *  - A user without the EditLockUnlockAccounts permission can only edit the modeling methods that are allowed for their role
             */
            return checkAuthorization(user, Authorizations.EditModelingMethods) || editableModelingMethods.includes(modelingMethod);
        }
        return false;
    };

    return canUserEditModelingMethod;
}
