/* eslint-disable no-console */
import React, {useEffect, useState} from "react";
import {Button, Form, Nav, Navbar} from 'react-bootstrap';
import Select from "react-select";
import './NavBar.scss';
import {useRoutingUtils} from "../../routes/RoutingUtils";
import {BudgetingType} from "../../BudgetingType";
import {Properties} from "../properties/Properties";
import brand from "../../assets/icons/brand/brand.png";
import {NavLink} from "react-router-dom";
import {useSettings} from "../../contexts/settings/SettingsContext";
import {useChartOfAccounts} from "../../contexts/chartofaccounts/ChartOfAccountsContext";
import * as styles from "./styles.module.scss";
import {RenderIfAuthorized} from "../../authorization/RenderIfAuthorized";
import {Authorizations} from "../../authorization/Authorizations";
import {ReportsNavMenu} from "./components/reports-nav-menu/ReportsNavMenu";
import {SettingsNavMenu} from "./components/settings-nav-menu/SettingsNavMenu";
import {useConfig} from "../../hooks/useConfig";
import {VersionType} from "../../__generated__/generated_types";
import { CircularReferenceIndicator } from "../../pages/circular-references/CircularReferenceIndicator";

interface OptionsType {
    label: string;
    value: string;
}

export interface NavBarProps {
    needSettings?: () => void;
    needPlan?: () => void;
    needDashboard?: () => void;
}

const NavBar: React.FC<NavBarProps> = (params: NavBarProps) => {
    const {goto} = useRoutingUtils();
    const config = useConfig();

    const {hasReports} = useSettings();

    const planClicked = () => {
        params.needPlan?.();
    };

    const {isReady, accounts: accountsData} = useChartOfAccounts();
    const [options, setOptions] = useState<OptionsType[]>([]);

    useEffect(() => {
        const accounts: OptionsType[] = [];

        if (isReady && accountsData && config.isReady) {
            accountsData.forEach(acc => {
                if (config.versions.selectedVersionType == VersionType.Budget) {
                    accounts.push({
                        label: `${acc.accountNumber} ${acc.name}`,
                        value: `${acc.id}/${BudgetingType.BUDGET}`
                    });
                } else {
                    accounts.push({
                        label: `${acc.accountNumber} ${acc.name}`,
                        value: `${acc.id}/${BudgetingType.REFORECAST}`
                    });
                }
            });
        }
        setOptions(accounts);

    }, [isReady, accountsData, config.isReady, config.versions.selectedVersionType]);


    const handleSearchChange = (value: OptionsType | null) => {

        goto(`/account/${value?.value}`)();
    };


    return (
        <React.Fragment>
            <Navbar className="vz-header" bg="light" expand="lg">
                <Navbar.Brand href="/"><img src={brand} alt="vizibly-brand" className="vizibly-brand" /></Navbar.Brand>
                <div className="me-auto" />
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav" className="justify-content-end">
                    <Nav>
                        <CircularReferenceIndicator/>
                        <NavLink
                            className={`${styles.topNav} ${styles.topNavBtnPlanningHub}`}
                            activeClassName={`${styles.topNav} ${styles.topNavBtnPlanningHubOn}`}
                            to={"/hub"}>
                            Planning Hub
                        </NavLink>
                        <ReportsNavMenu />
                        <RenderIfAuthorized required={Authorizations.Analytics}>
                            {hasReports &&
                                <NavLink
                                    className={`${styles.topNav} ${styles.topNavBtnAnalytics}`}
                                    activeClassName={`${styles.topNav} ${styles.topNavBtnAnalyticsOn}`}
                                    to={'/analytics'}>
                                    Analytics
                                </NavLink>
                            }
                        </RenderIfAuthorized>
                    </Nav>
                    <Form className="d-flex nav-bar__form">
                        <Select
                            className="nb-search-box"
                            classNamePrefix="react-select-theme"
                            options={options}
                            placeholder={"Account search"}
                            components={{DropdownIndicator: () => null, IndicatorSeparator: () => null}}
                            onChange={handleSearchChange}
                            isClearable={true}
                        />
                        <Properties />
                    </Form>
                    <Button variant="primary" onClick={planClicked}>Plan</Button>
                    <Nav className='nav-bar__settings-nav'>
                        <SettingsNavMenu />
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
        </React.Fragment>
    );
};

export default NavBar;
