export type YearArray = (string|number|undefined|null)[];

export const isYearArrayPopulated = (arr:(string|number|null|undefined)[]|undefined):boolean => {
    if(arr){
        return arr.reduce((acc:boolean, entry) => {
            if(acc === true){ // we only require 1 non-null, non-undefined value to be present
                return acc;
            } else {
                acc = entry != null && entry != undefined;
            }
            return acc;
        }, false);
    }
    return false;
};

/**
 * Expects an array representing values for 12 months, which may be of type (string|number|undefined|null).
 * If no array is provided to parse, a YearArr array is returned that's populated with null values.
 * @param yearArr
 */
export const parseYearArrayValues = (yearArr?:YearArray):YearArray => {
    return yearArr?.map(
        monthVal => {
            if(monthVal != undefined){ // also captures null
                if(typeof monthVal == 'string' && Number.isNaN(parseFloat(monthVal))){
                    return Math.round(parseFloat(monthVal));
                }
                else {
                    return Math.round(monthVal as number);
                }
            }
            return monthVal;
        }
    ) ?? new Array(12).fill(null);
};

/**
 * Expects an array of actual values for a year, an array of reforecast values for a year, and an index for the month
 * that reforecast values begin. Returns a YearArray with actual values up to the point reforecast values begin, and
 * reforecast values to the end of the year.
 * @param actualsArr
 * @param reforecastArr
 * @param reforecastMonthStart
 */
export const composeReforecastYearArray = (
    actualsArr:YearArray = new Array(12).fill(null),
    reforecastArr:YearArray = new Array(12).fill(null),
    reforecastMonthStart:number
):YearArray => {
    return parseYearArrayValues([
        ...actualsArr.slice(0, reforecastMonthStart),
        ...reforecastArr.slice(reforecastMonthStart),
    ]);
};

/**
 * Expects a YearArray, and returns the average of all non-null, numeric, values.
 * @param yearArr
 * @param procFunc
 */
export const getYearFlatAverage = (yearArr:YearArray, procFunc?:(x:number) => number):number => {
    let valueCount = 0;
    const total = yearArr.reduce((acc:number, monthVal):number => {
        if(monthVal != undefined){
            let val = 0;
            if(typeof monthVal == 'string' && Number.isNaN(parseFloat(monthVal))){
                val = parseFloat(monthVal);
                valueCount++;
            } else if(typeof monthVal == 'number'){
                val = monthVal;
                valueCount++;
            }

            return acc + val;
        }
        return acc;
    }, 0);

    const avg = valueCount > 0 ? total/valueCount : 0;

    return procFunc ? procFunc(avg) : avg;
};

export const addYearDataArrays = (
    arr1:(string|number|null|undefined)[],
    arr2:(string|number|null|undefined)[],
    arr2Mod = 1
):number[] => {
    const arrReturn:number[] = [];
    arr1.forEach((item, idx) => {
        let val1 = item ?? 0;
        let val2 = arr2[idx] ?? 0;

        if(typeof val1 == 'string' && !isNaN(parseInt(val1))){
            val1 = parseInt(val1);
        } else if(typeof val1 == 'string'){
            val1 = 0;
        }

        if(typeof val2 == 'string' && !isNaN(parseInt(val2))){
            val2 = parseInt(val2);
        } else if(typeof val2 == 'string'){
            val2 = 0;
        }

        arrReturn[idx] = (val1 as number) + ((val2 as number) * arr2Mod);
    });
    return arrReturn;
};

export const averageTwoYearsArrays = (twoYearsArrays:YearArray[]):YearArray => {
    const addedArrays = new Array(26).fill(0);
    const valueCounts = new Array(26).fill(0);

    twoYearsArrays.forEach(
        (thisYear) => {
            thisYear.forEach( (thisMonthVal, monthIdx) => {
                if(typeof thisMonthVal == 'number'){
                    addedArrays[monthIdx] += thisMonthVal;
                    valueCounts[monthIdx] += 1;
                }
            });
        }
    );
    const averaged = addedArrays.map((entry, idx) => {
        if(valueCounts[idx] > 0){
            return Math.round(entry/valueCounts[idx]);
        } else {
            return null;
        }
    });

    return averaged ?? [];
};
