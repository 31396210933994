/**
 * We've migrated to LaunchDarkly to provide feature flags.
 * See https://app.launchdarkly.com/default/development/features for flags configured for development environments
 *
 * Note: The client-side LaunchDarkly Client parses received keys to camel case, so 'MVR'
 * becomes 'mvr', 'MVRInsights' becomes 'mvrInsights', etc. Be sure to take this into account
 * when registering new flags.
 */
export enum Feature {
    MVR = 'MVR',
    AccountFormulaBar = 'AccountFormulaBar',
    EditableFormulaBar = 'EditableFormulaBar',
    MVRInsights = 'MVRInsights',
    PlanningDashboard = 'PlanningDashboard',
}
