/**
 * TwoYearsColId
 * Column IDs are 0 based for direct access
 */
export enum TwoYearsColId{
    ITEM_TITLE = 2,
    REFO_MONTH_0,
    REFO_MONTH_1,
    REFO_MONTH_2,
    REFO_MONTH_3,
    REFO_MONTH_4,
    REFO_MONTH_5,
    REFO_MONTH_6,
    REFO_MONTH_7,
    REFO_MONTH_8,
    REFO_MONTH_9,
    REFO_MONTH_10,
    REFO_MONTH_11,
    REFO_TOTALS,
    BUDGET_MONTH_0,
    BUDGET_MONTH_1,
    BUDGET_MONTH_2,
    BUDGET_MONTH_3,
    BUDGET_MONTH_4,
    BUDGET_MONTH_5,
    BUDGET_MONTH_6,
    BUDGET_MONTH_7,
    BUDGET_MONTH_8,
    BUDGET_MONTH_9,
    BUDGET_MONTH_10,
    BUDGET_MONTH_11,
    BUDGET_TOTALS,
    ENTITY_ID,
    PARENT_ID,
    ENTITY_TYPE,
    DEPTH,
    NUM_CHILDREN,
    HAS_REFORECAST_DRIVERS,
    HAS_BUDGET_DRIVERS,
    COMPONENT_OPS,
}

export enum WorksheetRowTypes{
    HEADER,
    ITEM,
}
