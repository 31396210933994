import {ThemeProvider} from "@zendeskgarden/react-theming";
import {ReactElement, useEffect, useState} from "react";
import {ViziblyTheme} from "../analyst/ViziblyZDGTheme";
import useAppStore from "../../hooks/useAppStore";
import * as css from "./styles/OpDriverModeling.module.scss";
import * as workflowCSS from "../../styles/workflows/workflowCSS.module.scss";
import Header from "./components/Header";
import {Col, Grid, Row} from "@zendeskgarden/react-grid";
import {useProperties} from "../../contexts/properties/PropertiesContext";
import Card from "./components/Card";
import {ForecastLocks, useGetForecastLocksLazyQuery, useGetSimplifiedRevenueLazyQuery} from "../../__generated__/generated_types";
import {IconButton} from "@zendeskgarden/react-buttons";
import {RiDownloadLine} from "react-icons/ri";
import RenovationsDefaultsTable from "./components/RenovationsDefaultsTable";
import {WorkflowStepNavProps} from "../../components/workflows/workflow-step-nav/WorkflowStepNav";
import ClassicToRenovatedAssociations from "./components/classic-to-renovated-associations/ClassicToRenovatedAssociations";
import { buildSimplifiedUnitTypes } from "./logic/HelperFunctions";
import LockStatus from "./components/LockStatus";

export type TSimplifiedUnitType = {
    id: string,
    type: string,
    startingMarketRent?: number,
    overrideMarketRent?: number,
    count: number,
    isCustom?: boolean,
}

export default function Renovations({onPreviousClick, onNextClick}: WorkflowStepNavProps): ReactElement {
    const appStore = useAppStore();
    const property = useProperties();

    const [triggerDownload, setTriggerDownload] = useState<boolean>(false);
    const [unitTypes, setUnitTypes] = useState<TSimplifiedUnitType[]>([]);
    const [simplifiedRevenueVars, setSimplifiedRevenueVars] = useState<{propertyId: string, budgetYear: number} | undefined>();
    const [forecastLocks, setForecastLocks] = useState<ForecastLocks>();
    const [getSimplifiedRevenue, {data, loading}] = useGetSimplifiedRevenueLazyQuery({
        fetchPolicy: "no-cache",
    });
    const [getForecastLocks, {data: dataForecastLocks, loading: lockDataLoading}] = useGetForecastLocksLazyQuery({
        fetchPolicy: "no-cache",
    });

    function fetchSimplifiedRevenue() {
        if (!simplifiedRevenueVars || loading) {
            return;
        }

        getSimplifiedRevenue({
            variables: simplifiedRevenueVars,
        });
    }

    useEffect(() => {
        appStore.set({isLoading: false});
        return () => undefined;
    }, []);

    useEffect(() => {
        if (!property || !property.currentProperty) {
            return;
        }

        setSimplifiedRevenueVars({
            propertyId: property.currentProperty.id,
            budgetYear: property.currentProperty.budgetYear,
        });

        getForecastLocks({
            variables: {
                propertyId: property.currentProperty.id,
                budgetYear: property.currentProperty.budgetYear,
            }
        });
    }, [property]);

    useEffect(() => {
        if (!simplifiedRevenueVars) {
            return;
        }

        fetchSimplifiedRevenue();
    }, [simplifiedRevenueVars]);

    useEffect(() => {
        if (!data || loading) {
            return;
        }

        const unitTypes = buildSimplifiedUnitTypes(data);

        setUnitTypes(unitTypes);
    }, [data, loading]);

    useEffect(() => {
        if (lockDataLoading || !dataForecastLocks?.forecastLocks) {
            setForecastLocks(undefined);
            return;
        }

        setForecastLocks(dataForecastLocks.forecastLocks.property);
    }, [dataForecastLocks, lockDataLoading]);

    return (
        <ThemeProvider theme={ViziblyTheme}>
            <div className={`${css.opDriverModelingWrapper} ${workflowCSS.workflowContainer}`}>
                <div className={workflowCSS.workflowContainerInner}>
                    <Header
                        title={"Renovations"}
                        prevAction={onPreviousClick}
                        nextAction={onNextClick}
                    />

                    <Grid className={css.bodyContent} gutters={"lg"}>
                        <Row>
                            <Col>
                                {forecastLocks && property.currentProperty && data && fetchSimplifiedRevenue &&
                                    <Card
                                        title="Defaults"
                                        actions={
                                            <>
                                                <LockStatus
                                                    budgetYear={property.currentProperty.budgetYear}
                                                    reforecastLocked={forecastLocks.reforecastLocked}
                                                    budgetLocked={forecastLocks.budgetLocked}
                                                />
                                                <IconButton onClick={() => {
                                                    setTriggerDownload(true);
                                                }}>
                                                    <RiDownloadLine />
                                                </IconButton>
                                            </>
                                        }
                                    >
                                            <RenovationsDefaultsTable
                                                property={property.currentProperty}
                                                data={data}
                                                triggerDownload={triggerDownload}
                                                forecastLocks={forecastLocks}
                                                onDataUpdate={fetchSimplifiedRevenue}
                                                onDownloadComplete={() => setTriggerDownload(false)}
                                            />
                                    </Card>
                                }
                            </Col>
                        </Row>

                        <Row>
                            <Col>
                                <Card
                                    title="Renovations"
                                >
                                    {
                                        property.currentProperty && unitTypes.length > 0 ?
                                            <ClassicToRenovatedAssociations
                                                property={property.currentProperty}
                                                unitTypes={unitTypes}
                                                refetchSimplifiedData={fetchSimplifiedRevenue}
                                            />
                                        : <></>
                                    }
                                </Card>
                            </Col>
                        </Row>
                    </Grid>
                </div>
            </div>
        </ThemeProvider>
    );
}