import GC from '@grapecity/spread-sheets';

import { AnalystStyles } from "../../../../pages/analyst/AnalystStyles";
import { SJSSheetStyles as SJSS } from "../../../layout/shared/SJSSheetStyles";
import { COLORS } from "../../../../constants/Colors";

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace TwoYearsSheetStyles {

    const HorizontalAlign = GC.Spread.Sheets.HorizontalAlign;
    const VerticalAlign = GC.Spread.Sheets.VerticalAlign;

    const NO_DATA_CELL_BORDER = new GC.Spread.Sheets.LineBorder(
        COLORS.GREY_75,
        GC.Spread.Sheets.LineStyle.thin
    );

    // Item Column __________________________________________________________________________
    export const ITEM_BASE = SJSS.DATA_LABEL.clone();
    ITEM_BASE.borderBottom = SJSS.CELL_BORDER;
    ITEM_BASE.borderRight = SJSS.CELL_BORDER;
    ITEM_BASE.foreColor = '#0D666D';

    export const ITEM_HEADER = ITEM_BASE.clone();
    ITEM_HEADER.textIndent = 2.7;
    ITEM_HEADER.hAlign = HorizontalAlign.left;
    ITEM_HEADER.foreColor = COLORS.GREY_800;
    ITEM_HEADER.font = '550 12px Inter';

    export const ITEM_LABEL = ITEM_BASE.clone();
    ITEM_LABEL.cellPadding = '8 26 8 13';
    ITEM_HEADER.textIndent = 2.7;
    ITEM_LABEL.locked = false;
    ITEM_LABEL.hAlign = HorizontalAlign.left;

    export const ITEM_LABEL_MARKED = ITEM_LABEL.clone();
    ITEM_LABEL_MARKED.foreColor = 'red';

    export const ITEM_LABEL_HOVER = ITEM_LABEL.clone();
    ITEM_LABEL_HOVER.borderBottom = SJSS.HOVER_CELL_BORDER;
    ITEM_LABEL_HOVER.cellPadding = '10 26 8 13';

    // Data Column __________________________________________________________________________
    export const ACTUAL_CELL        = SJSS.ACTUAL_CELL_GREY;
    ACTUAL_CELL.backColor           = COLORS.GREY_75;
    ACTUAL_CELL.foreColor           = COLORS.GREY_300;
    ACTUAL_CELL.locked              = true;

    export const LAST_ACTUAL_CELL   = ACTUAL_CELL.clone();
    LAST_ACTUAL_CELL.borderRight    = SJSS.MEDIUM_VERTICAL_DIVIDER;

    export const LOCKED_DATA_LABEL = SJSS.ADMIN_LOCKED_DATA_LABEL;

    export const DATA_CELL_HOVER = SJSS.EDITABLE_CELL.clone();
    DATA_CELL_HOVER.borderTop = SJSS.HOVER_CELL_BORDER;
    DATA_CELL_HOVER.borderRight = SJSS.HOVER_CELL_BORDER;
    DATA_CELL_HOVER.borderBottom = SJSS.HOVER_CELL_BORDER;
    DATA_CELL_HOVER.borderLeft = SJSS.HOVER_CELL_BORDER;

    export const LAST_DATA_CELL_HOVER = DATA_CELL_HOVER.clone();
    LAST_DATA_CELL_HOVER.borderBottom = AnalystStyles.MANUAL_EDITABLE_CELL_HOVER_BORDER;
    LAST_DATA_CELL_HOVER.borderRight = SJSS.MEDIUM_VERTICAL_DIVIDER;

    export const LOCKED_DATA_CELL_HOVER = DATA_CELL_HOVER.clone();
    LOCKED_DATA_LABEL.foreColor = '#858D9D';
    LOCKED_DATA_LABEL.locked = true;

    export const TOTALS_COLUMN_HDR = SJSS.TOTALS_COLUMN_BASE.clone();
    TOTALS_COLUMN_HDR.vAlign = VerticalAlign.bottom;
    TOTALS_COLUMN_HDR.hAlign = HorizontalAlign.right;
    TOTALS_COLUMN_HDR.font = '500 12px Inter';

    export const TOTALS_COLUMN_DATA_CELL = TOTALS_COLUMN_HDR.clone();
    TOTALS_COLUMN_DATA_CELL.font = '500 12px Inter';
    TOTALS_COLUMN_DATA_CELL.cellPadding = '6px';
    TOTALS_COLUMN_DATA_CELL.borderTop = AnalystStyles.CELL_BORDER;
    TOTALS_COLUMN_DATA_CELL.borderBottom = AnalystStyles.CELL_BORDER;
    TOTALS_COLUMN_DATA_CELL.vAlign = VerticalAlign.center;
    TOTALS_COLUMN_DATA_CELL.formatter = "#,#";
    TOTALS_COLUMN_DATA_CELL.locked = true;

    export const TOTALS_COLUMN_DATA_CELL_LOCKED = TOTALS_COLUMN_HDR.clone();
    TOTALS_COLUMN_DATA_CELL_LOCKED.font = '500 12px Inter';
}
