import { IconButton } from "@zendeskgarden/react-buttons";
import { Dropdown, Item, Menu, Trigger } from "@zendeskgarden/react-dropdowns";
import { ReactElement } from "react";

import lockOn from "../../../assets/icons/lock/locked-disabled.svg";
import chevronDown from "../../../assets/icons/chevron/down.svg";


interface IBulkLockButtonProps {
    bulkUpdateAccountLocks: (isLocked: boolean) => void,
}

export default function BulkLockButton({
    bulkUpdateAccountLocks,
}: IBulkLockButtonProps): ReactElement {

    const handleSelect = (value: string) => {
        const locked = value === "lockAll";

        bulkUpdateAccountLocks(locked);
    };

    return (
        <div style={inlineStyles.wrapper}>
            <img src={lockOn} alt="lock" width="15px" height="15px" />
            <Dropdown onSelect={handleSelect}>
                <Trigger>
                    <IconButton aria-label="plant" style={inlineStyles.chevronIcon}>
                        <img src={chevronDown} alt="arrow" width="15px" height="15px" />
                    </IconButton>
                </Trigger>
                <Menu>
                    <Item value="lockAll">Lock all GLs</Item>
                    <Item value="unlockAll">Unlock all GLs</Item>
                </Menu>
            </Dropdown>
        </div>
    );
}

const inlineStyles = ({
    wrapper: {
        alignItems: "center",
        display: "flex",
        flexDirection: "row",
        gap: "8px",
        height: "40px",
    } as  React.CSSProperties, // TS didn't like "row" being passed as a string
    chevronIcon: {
        display: "flex",
        height: "28px",
        minWidth: "28px",
        width: "28px",
    },
});
