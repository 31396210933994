import {HotTable} from "@handsontable/react";
import {ReactElement, useEffect, useRef, useState} from "react";
import {Property} from "../../../contexts/properties/PropertiesContext";
import * as css from "../styles/OpDriverModeling.module.scss";
import * as tableCSS from "../../../components/account-table/styles/accountTable.module.scss";
import {LICENSES} from "../../../constants/Licenses";
import Handsontable from "handsontable";
import {ForecastLockModel, ForecastLocks, GetSimplifiedRevenueQuery, useUpdateMarketRentGrowthMutation} from "../../../__generated__/generated_types";
import {MONTHS} from "../../../constants/Months";
import {Body, Close, Footer, FooterItem, Header, Modal} from "@zendeskgarden/react-modals";
import {Button} from "@zendeskgarden/react-buttons";
import {Field, Input, Label} from "@zendeskgarden/react-forms";
import {toast} from "react-toastify";
import {hotValidUserActions, sanitizeTableInput} from "../../../components/account-table/logic/AccountTableHelpers";
import {buildManualEntryIncreases, averageOfArray, numRange} from "../logic/HelperFunctions";

export interface IMarketRentDefaultsTableProps {
    property: Property,
    data: GetSimplifiedRevenueQuery,
    configModalVisible: boolean,
    triggerDownload: boolean,
    forecastLocks: ForecastLocks,
    onDataUpdate: () => void,
    onDownloadComplete: () => void,
    onModalClose: () => void,
}

export default function MarketRentDefaultsTable({property, data, configModalVisible, triggerDownload, forecastLocks, onDownloadComplete, onDataUpdate, onModalClose}: IMarketRentDefaultsTableProps): ReactElement {
    const hotRef = useRef<HotTable>(null);
    const marketRents = data.simplifiedRevenueModel?.defaultMarketRents;
    const manualEntrySaveDebounce = useRef<(ReturnType<typeof setTimeout> | null)>(null);

    const [rfcstAnnualGrowth, setRfcstAnnualGrowth] = useState<string>(String(marketRents?.reforecastAnnualIncreasePercentage) ?? "");
    const [bdgtAnnualGrowth, setBdgtAnnualGrowth] = useState<string>(String(marketRents?.budgetAnnualIncreasePercentage) ?? "");
    const [isRfcstConfigured, setIsRfcstConfigured] = useState<boolean>(marketRents?.reforecastAnnualIncreasePercentage != undefined);
    const [isBdgtConfigured, setIsBdgtConfigured] = useState<boolean>(marketRents?.budgetAnnualIncreasePercentage != null);
    const [updateGrowth] = useUpdateMarketRentGrowthMutation({
        onCompleted: data => {
            if (data.updateSimplifiedRevenueDefaultMarketRentIncrease) {
                toast.success("Saved", {autoClose: 2500, hideProgressBar: true});
                onModalClose();
                onDataUpdate();
            }
        },
        onError: () => {
            toast.error("Error Saving", {autoClose: 2500, hideProgressBar: true});
        },
    });

    useEffect(() => {
        if (!data.simplifiedRevenueModel) {
            return;
        }

        setIsRfcstConfigured(data.simplifiedRevenueModel.defaultMarketRents.reforecastAnnualIncreasePercentage != undefined);
        setIsBdgtConfigured(data.simplifiedRevenueModel.defaultMarketRents.budgetAnnualIncreasePercentage != undefined);
    }, [data]);

    useEffect(() => {
        if (triggerDownload && hotRef.current?.hotInstance) {
            const exportPlugin = hotRef.current.hotInstance.getPlugin("exportFile");
            exportPlugin.downloadFile("csv", {filename: `${property.name}_${property.budgetYear} Market Rent_${new Date().toLocaleDateString('en-US')}`, columnHeaders: true,});
            onDownloadComplete();
        }
    }, [triggerDownload]);

    if (marketRents == undefined) {
        return <></>;
    }

    const averageMarketAmounts: (number | null)[] = [];
    const averageMarketGrowths: (number | null)[] = [];
    const rfcstMonthlyAmounts: (number | null)[] = [];
    const rfcstMonthlyGrowths: (number | null)[] = [];
    const bdgtMonthlyAmounts: (number | null)[] = [];
    const bdgtMonthlyGrowths: (number | null)[] = [];
    const rfcstAverageColIndex = 13 - property.reforecastStartMonthIndex;
    const bdgtAverageColIndex = 13 - property.reforecastStartMonthIndex + 13;
    const rfcstColRange =  numRange(1, rfcstAverageColIndex);
    const bdgtColRange = numRange(rfcstAverageColIndex + 1, bdgtAverageColIndex);

    for (let i = 0; i < 12; i++) {
        if (i >= property.reforecastStartMonthIndex) {
            let rfcstMonthlyAmount;

            if (i == property.reforecastStartMonthIndex) {
                rfcstMonthlyAmount = marketRents.reforecast[i + 1]?.startingAverageMarketRent ?? null;
            } else {
                rfcstMonthlyAmount = marketRents.reforecast[i]?.averageMarketRent ?? null;
            }

            const rfcstMonthlyGrowth = marketRents.reforecast[i]?.growthPercentage ?? null;

            rfcstMonthlyAmounts.push(rfcstMonthlyAmount == null ? null : Math.round(rfcstMonthlyAmount));
            rfcstMonthlyGrowths.push(rfcstMonthlyGrowth == null ? null : parseFloat(rfcstMonthlyGrowth.toFixed(1)));
        }

        const bdgtMonthlyAmount = marketRents.budget[i]?.averageMarketRent ?? null;
        const bdgtMonthlyGrowth = marketRents.budget[i]?.growthPercentage ?? null;

        bdgtMonthlyAmounts.push(bdgtMonthlyAmount == null ? null : Math.round(bdgtMonthlyAmount));
        bdgtMonthlyGrowths.push(bdgtMonthlyGrowth == null ? null : parseFloat(bdgtMonthlyGrowth.toFixed(1)));
    }

    averageMarketAmounts.push(
        ...rfcstMonthlyAmounts,
        Math.round(averageOfArray(rfcstMonthlyAmounts)),
        ...bdgtMonthlyAmounts,
        Math.round(averageOfArray(bdgtMonthlyAmounts)),
    );

    averageMarketGrowths.push(
        ...rfcstMonthlyGrowths,
        averageOfArray(rfcstMonthlyGrowths),
        ...bdgtMonthlyGrowths,
        averageOfArray(bdgtMonthlyGrowths),
    );

    const columns: string[] = [
        "",
        ...MONTHS.slice(property.reforecastStartMonthIndex, 12),
        "Average",
        ...MONTHS,
        "Average",
    ];

    const rowData = [
        [
            "Average market rent",
            ...averageMarketAmounts,
        ],
        [
            "Market rent growth %",
            ...averageMarketGrowths,
        ],
    ];

    const settings: Handsontable.GridSettings = {
        data: rowData,
        licenseKey: LICENSES.HandsOnTable,
        colHeaders: columns,
        selectionMode: "range",
        rowHeaders: false,
        width: "auto",
        height: "auto",
        readOnly: true,
        fixedColumnsLeft: 1,
        manualRowResize: false,
        manualColumnResize: false,
        autoColumnSize: false,
        autoRowSize: false,
        disableVisualSelection: ["header"],
        colWidths(index) {
            if (index == 0) {
                return 200;
            } else {
                return 100;
            }
        },
        afterGetColHeader: (_column, th) => {
            if (!hotRef.current?.hotInstance) {
                return;
            }

            th.className += ` ${tableCSS.cellBase} ${tableCSS.headerWithLabels}`;

            if (_column == 0 || _column == rfcstAverageColIndex || _column == bdgtAverageColIndex) {
                th.className += ` ${tableCSS.cellBgGrey}`;
            }

            if (_column == rfcstAverageColIndex || _column == bdgtAverageColIndex) {
                th.className += ` ${tableCSS.verticalThickBorderLeft}`;
            }

            if (rfcstColRange.includes(_column)) {
                const validMonths = MONTHS.slice(property.reforecastStartMonthIndex, 12);
                // -1 offset is to account for the first column - the label column
                th.innerHTML = `
                    <div class="${tableCSS.tableHeaderLightLabel}">
                        <span>RFCST</span>
                        <span>&nbsp;</span>
                        <span>${ validMonths[_column - 1]}</span>
                    </div>
                `;
            } else if (bdgtColRange.includes(_column)) {
                const startingBdgtCol = rfcstColRange.length + 2;
                th.innerHTML = `
                    <div class="${tableCSS.tableHeaderLightLabel}">
                        <span>BDGT</span>
                        <span>&nbsp;</span>
                        <span>${ MONTHS[_column - startingBdgtCol]}</span>
                    </div>
                `;
            } else if (rfcstAverageColIndex == _column) {
                th.innerHTML = `
                    <div class="${tableCSS.tableHeaderLightLabel}">
                        <span>RFCST</span>
                        <span>${property.reforecastYear}</span>
                        <span>Average</span>
                    </div>
                `;
            } else if (bdgtAverageColIndex == _column) {
                th.innerHTML = `
                    <div class="${tableCSS.tableHeaderLightLabel}">
                        <span>BDGT</span>
                        <span>${property.budgetYear}</span>
                        <span>Average</span>
                    </div>
                `;
            } else {
                // sure there must be better ways to handle HoT col size calculations, but this will do it for now
                th.innerHTML = `
                    <div class="${tableCSS.tableHeaderLightLabel}">
                        <span> </span>
                        <span> </span>
                        <span> </span>
                    </div>
                `;
            }
        },
        cells(_row, _column) {
            this.className += ` ${tableCSS.cellBase}`;

            if (_column == 0) {
                this.className += ` ${tableCSS.rowHeader}`;
            }

            if (_column == 1) {
                this.className += ` ${tableCSS.cellBgGrey}`;
            }

            // if row is the first one, with currency
            if (_row == 0) {
                this.renderer = function (instance, td, row, col, prop, value, cellProperties) {
                    cellProperties.type = "numeric";
                    cellProperties.numericFormat = {
                        pattern: '$0,0',
                        culture: 'en-US'
                    };

                    if (value !== null && value !== undefined) {
                        td.innerText = String(Math.round(value));
                    }

                    Handsontable.renderers.NumericRenderer.apply(this, [instance, td, row, col, prop, value, cellProperties]);
                };

                if (_column == 1) {
                    this.className += ` ${tableCSS.lightText}`;
                }
            }

            // if row is second one, with growth pct, and it's a non-label column
            if (_row == 1 && _column > 1) {
                this.renderer = function (instance, td, row, col, prop, value, cellProperties) {
                    Handsontable.renderers.NumericRenderer.apply(this, [instance, td, row, col, prop, value, cellProperties]);

                    if (value !== null && value !== undefined) {
                        value = parseFloat(value);
                        td.innerText = value.toFixed(1) + '%';
                    }
                };

                if (rfcstColRange.includes(_column) && !isRfcstConfigured && !forecastLocks.reforecastLocked) {
                    this.className += ` ${tableCSS.cellColorBlue} ${tableCSS.cellBorderBottom}`;
                    this.readOnly = false;
                }

                if (bdgtColRange.includes(_column) && !isBdgtConfigured && !forecastLocks.budgetLocked) {
                    this.className += ` ${tableCSS.cellColorBlue} ${tableCSS.cellBorderBottom}`;
                    this.readOnly = false;
                }
            }

            // if the column is one of the average columns
            if (_column == rfcstAverageColIndex || _column == bdgtAverageColIndex) {
                this.className += ` ${tableCSS.verticalThickBorderLeft}`;
                this.className += ` ${tableCSS.cellBgGrey}`;
                this.readOnly = true;
            }

            return this;
        },
        beforeChange: (changes) => {
            for (let i = 0; i < changes.length; i++) {
                const change = changes[i];

                if (!change) {
                    return;
                }

                change[3] = sanitizeTableInput(change[3]);
            }
        },
        afterChange: (changes, _source) => {
            const source = _source as string;

            if (!changes) {
                return;
            }

            for (let i = 0; i < changes.length; i++) {
                const change = changes[i];

                if (!change) {
                    continue;
                }

                const row = change[0];
                const col = change[1];
                const oldVal = change[2];
                const newVal = change[3];

                if (row == undefined || col == undefined || oldVal === undefined || newVal == undefined || String(oldVal) === String(newVal)) {
                    continue;
                }

                if (hotValidUserActions.includes(source)) {
                    if (manualEntrySaveDebounce.current) {
                        clearTimeout(manualEntrySaveDebounce.current);
                    }

                    manualEntrySaveDebounce.current = setTimeout(() => {
                        if (!hotRef.current?.hotInstance) {
                            return;
                        }

                        const increases = buildManualEntryIncreases(
                            rfcstColRange,
                            bdgtColRange,
                            rfcstAverageColIndex,
                            bdgtAverageColIndex,
                            rfcstAnnualGrowth,
                            bdgtAnnualGrowth,
                            isRfcstConfigured,
                            isBdgtConfigured,
                            hotRef.current.hotInstance,
                        );

                        updateGrowth({
                            variables: {
                                propertyId: property.id,
                                budgetYear: property.budgetYear,
                                increases,
                            }
                        });
                    }, 1400);
                }
            }
        },
        beforeUndoStackChange(_doneActions, source) {
            if (source && !hotValidUserActions.includes(source)) {
                return false;
            }
        },
    };

    return (
        <div className={tableCSS.accountTableWrapper}>
            <HotTable
                ref={hotRef}
                settings={settings}
                className={tableCSS.accountTable}
            />

            {configModalVisible && (
                <Modal
                    onClose={() => {
                        onModalClose();
                        setRfcstAnnualGrowth(String(marketRents?.reforecastAnnualIncreasePercentage) ?? "");
                        setBdgtAnnualGrowth(String(marketRents?.budgetAnnualIncreasePercentage) ?? "");
                    }}>
                    <Header>Configure Rules</Header>
                    <Body>
                        <div className={css.configRuleHeader}>
                            <span className={css.configRuleHeaderLeft}>{property.reforecastYear} Reforecast</span>
                            <Button className={css.configRuleHeaderRight} disabled={!isRfcstConfigured || forecastLocks.reforecastLocked} isBasic isDanger onClick={() => setRfcstAnnualGrowth("")}>
                                Clear Rule
                            </Button>
                        </div>

                        <Field className={css.configRuleField}>
                            <Label className={css.configRuleFieldLabel}>% Growth (Annualized)</Label>
                            <Input disabled={forecastLocks.reforecastLocked} value={rfcstAnnualGrowth} type="number" placeholder={isRfcstConfigured ? "Rule will be cleared upon Apply!" : "Enter amount"} onChange={event => {
                                setRfcstAnnualGrowth(event.target.value);
                            }} />
                        </Field>

                        <hr className={css.configRuleDivider} />

                        <div className={css.configRuleHeader}>
                            <span className={css.configRuleHeaderLeft}>{property.reforecastYear + 1} Budget</span>
                            <Button className={css.configRuleHeaderRight} disabled={!isBdgtConfigured || forecastLocks.budgetLocked} isBasic isDanger onClick={() => setBdgtAnnualGrowth("")}>
                                Clear Rule
                            </Button>
                        </div>

                        <Field className={css.configRuleField}>
                            <Label className={css.configRuleFieldLabel}>% Growth (Annualized)</Label>
                            <Input disabled={forecastLocks.budgetLocked} value={bdgtAnnualGrowth} type="number" placeholder={isBdgtConfigured ? "Rule will be cleared upon Apply!" : "Enter amount"} onChange={event => {
                                setBdgtAnnualGrowth(event.target.value);
                            }} />
                        </Field>
                    </Body>
                    <Footer>
                        <FooterItem>
                            <Button
                                isBasic
                                onClick={() => {
                                    onModalClose();
                                    setRfcstAnnualGrowth(String(marketRents?.reforecastAnnualIncreasePercentage) ?? "");
                                    setBdgtAnnualGrowth(String(marketRents?.budgetAnnualIncreasePercentage) ?? "");
                            }}>
                                Cancel
                            </Button>
                        </FooterItem>
                        <FooterItem>
                            <Button isPrimary onClick={() => {
                                updateGrowth({
                                    variables: {
                                        propertyId: property.id,
                                        budgetYear: property.budgetYear,
                                        increases: {
                                            reforecastYearlyIncrease: rfcstAnnualGrowth == "" ? null : parseFloat(rfcstAnnualGrowth),
                                            reforecastMonthlyValues: [],
                                            budgetYearlyIncrease: bdgtAnnualGrowth == "" ? null : parseFloat(bdgtAnnualGrowth),
                                            budgetMonthlyValues: [],
                                        }
                                    }
                                });
                            }}>
                                Apply
                            </Button>
                        </FooterItem>
                    </Footer>
                    <Close aria-label="Close modal" />
                </Modal>
            )}
        </div>
    );
}
