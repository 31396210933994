import { ReactElement } from "react";
import { MONTHS } from "../../constants/Months";

interface IYearMonthRangeProps {
    startYear: number,
    startMonthIdx?: number,
    endYear: number,
    endMonthIdx?: number,
    className?: string,
}

export default function YearMonthRange(props: IYearMonthRangeProps):ReactElement {
    const startMonth = props.startMonthIdx !== undefined ? MONTHS[props.startMonthIdx] : '';
    const endMonth = props.endMonthIdx !== undefined ? MONTHS[props.endMonthIdx] : '';

    return (
            <div className={props.className ? props.className : ''}>
                {startMonth} {props.startYear} - {endMonth} {props.endYear}
            </div>
    );
}