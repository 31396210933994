import { Field, Input, Label, Message } from "@zendeskgarden/react-forms";
import { ReactElement } from "react";

import * as css from "./styles/userFormStyles.module.scss";

export interface IUserFormInput {
    label: string,
    placeholder: string | undefined,
    required: boolean,
    value: string | number | readonly string[] | undefined,
    error: string | undefined,
    onChange: (evt: any) => void,
    onBlur: () => void,
}

export default function UserFormInput(props: IUserFormInput): ReactElement {
    const { label, placeholder, required, value, error, onChange, onBlur } = props;

    return (
        <Field>
            <Label className={css.userFormLabel}>
                <span>{label}</span>
                {
                    required && <span className={css.required}>REQUIRED</span>
                }
            </Label>
            <Input
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                placeholder={placeholder}
                validation={error ? "error" : undefined}
            />
            {
                error &&
                <Message validation="error">{error}</Message>
            }
        </Field>
    );
}