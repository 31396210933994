import { GetTransactionsQuery } from "../../../../../../../../../__generated__/generated_types";

export type TransactionRowViewModel = {
    id: string;
    description: string;
    memo: string;
    date: Date;
    amount: number;
}

// TODO: Replace enum values with is present in the transaction data return when available
export enum TransactionType {
    Payable = 'Payable',
    Charge = 'Charge',
    JournalEntry = 'JournalEntry',
    Receipt = 'Receipt',
    Checks = 'Checks',
    Other = 'Other',
}

export function buildTransactionRows(apiRows: GetTransactionsQuery["queryTransactions"]["items"]): TransactionRowViewModel[] {
    const result = [] as TransactionRowViewModel[];

    for(const apiRow of apiRows) {
        const parsedDate = new Date(apiRow.postDate);
        const vmRow: TransactionRowViewModel = {
            id: apiRow.id,
            description: apiRow.description,
            memo: apiRow.memo,
            date: parsedDate,
            amount: apiRow.amount
        };
        result.push(vmRow);
    }
    return result;
}