import { GetPropertyExecutiveSummaryDataQuery, Maybe, TopCardTagType, VersionType } from "../../../__generated__/generated_types";
import {Property} from "../../../contexts/properties/PropertiesContext";
import "native-injects";

const EPSILON = 0.01;

export type CommentPreviewData = {
    text: string,
    count: number
}

function addToArrays(property: Property,
                     sourceArray: {actuals: (number | null)[], reforecast: (number | null)[], budget: (number | null)[]}[],
                     destReforecast: number[],
                     destBudget: number[]
) {
    if (destReforecast.length != 12 || destBudget.length !== 12) {
        return;
    }
    for (const source of sourceArray){
        for (let i = 0; i < 12; i++) {
            const reforecastVal = i < property.reforecastStartMonthIndex ? source.actuals[i] : source.reforecast[i];
            if (reforecastVal !== null && reforecastVal !== undefined) {
                destReforecast[i] = (destReforecast[i] ?? 0) + reforecastVal;
            }
            const budgetVal = source.budget[i];
            if (budgetVal !== null && budgetVal !== undefined) {
                destBudget[i] = (destBudget[i] ?? 0) + budgetVal;
            }
        }
    }
}

export function buildSummaryDataAverageMarketRent(rawData: GetPropertyExecutiveSummaryDataQuery) {
    if (!rawData.originalRevenueModel) {
        return {
            reforecastValue: null,
            budgetValue: null,
            deltaPercent: null
        };
    }
    let reforecastValue = 0;
    let budgetValue = 0;
    let reforecastUnitCountValue = 0;
    let budgetUnitCountValue = 0;
    const unitCountByUnitTypeId = rawData.originalRevenueModel.unitTypeUnitCountMonthly.toIdMap("unitTypeId");
    for (const averageMaretRent of rawData.originalRevenueModel.unitTypeAvgMarketRent) {
        const utReforecastValue = averageMaretRent.reforecast[11];
        const utBudgetValue = averageMaretRent.budget[11];
        const utUnitCount = unitCountByUnitTypeId[averageMaretRent.unitTypeId];
        if (!utUnitCount) {
            continue;
        }
        const utUnitCountReforecastValue = utUnitCount.reforecast[11];
        const utUnitCountBudgetValue = utUnitCount.budget[11];
        if (utReforecastValue !== null && utReforecastValue !== undefined
            && utUnitCountReforecastValue !== null && utUnitCountReforecastValue !== undefined) {
            reforecastValue += (utReforecastValue * utUnitCountReforecastValue);
            reforecastUnitCountValue += utUnitCountReforecastValue;
        }
        if (utBudgetValue !== null && utBudgetValue !== undefined
            && utUnitCountBudgetValue !== null && utUnitCountBudgetValue !== undefined) {
            budgetValue += (utBudgetValue * utUnitCountBudgetValue);
            budgetUnitCountValue += utUnitCountBudgetValue;
        }
    }

    if (Math.abs(reforecastUnitCountValue) < EPSILON || Math.abs(budgetUnitCountValue) < EPSILON) {
        return {
            reforecastValue: null,
            budgetValue: null,
            deltaPercent: null
        };
    }

    reforecastValue = reforecastValue / reforecastUnitCountValue;
    budgetValue = budgetValue / budgetUnitCountValue;

    if (Math.abs(reforecastValue) < EPSILON) {
        return {
            reforecastValue,
            budgetValue,
            deltaPercent: null
        };
    }
    return {
        reforecastValue,
        budgetValue,
        deltaPercent: budgetValue / reforecastValue - 1
    };
}

export type TSummaryDataAverageOccupancy = ReturnType<typeof buildSummaryDataAverageOccupancy>;

export function buildSummaryDataAverageOccupancy(property: Property, rawData: GetPropertyExecutiveSummaryDataQuery) {
    let budgetAvgOccupancy: number | null = null;
    let reforecastAvgOccupancy: number | null = null;
    let reforecastDecember: number | null = null;
    const reforecastUnitCountMonthly = new Array(12).fill(0);
    const reforecastOccupiedCountMonthly = new Array(12).fill(0);
    const reforecastOccupancy = new Array(12).fill(0);
    const budgetUnitCountMonthly = new Array(12).fill(0);
    const budgetOccupiedCountMonthly = new Array(12).fill(0);
    const budgetOccupancy = new Array(12).fill(0);
    if (rawData.originalRevenueModel) {
        addToArrays(property, rawData.originalRevenueModel.unitTypeUnitCountMonthly, reforecastUnitCountMonthly, budgetUnitCountMonthly);
        addToArrays(property, rawData.originalRevenueModel.unitTypeOccupiedCount, reforecastOccupiedCountMonthly, budgetOccupiedCountMonthly);
        for (let i = 0; i < 12; i++) {
            if (budgetUnitCountMonthly[i] > 0) { // assuming unit count can never be negative and superfractional
                budgetOccupancy[i] = budgetOccupiedCountMonthly[i] / budgetUnitCountMonthly[i];
            }
            if (reforecastUnitCountMonthly[i] > 0) { // assuming unit count can never be negative and superfractional
                reforecastOccupancy[i] = reforecastOccupiedCountMonthly[i] / reforecastUnitCountMonthly[i];
            }
        }
        reforecastAvgOccupancy = reforecastOccupancy.average();
        budgetAvgOccupancy = budgetOccupancy.average();
        reforecastDecember = reforecastOccupancy[11];
    }
    return {
        reforecastAvgOccupancy,
        budgetAvgOccupancy,
        reforecastUnitCountMonthly,
        reforecastOccupiedCountMonthly,
        reforecastOccupancy,
        budgetUnitCountMonthly,
        budgetOccupiedCountMonthly,
        budgetOccupancy,
        reforecastDecember
    };
}

export type TSummaryDataFinancialMetric = ReturnType<typeof buildSummaryDataFinancialMetric>;

export function buildSummaryDataFinancialMetric(
        property: Property,
        rawData: GetPropertyExecutiveSummaryDataQuery,
        tag: TopCardTagType
) {
    const accountIds = rawData.getAccountsTopCardTags.filter(row => row.topCardTagType == tag).map(row => row.accountId);
    let reforecastTotal = 0;
    let budgetTotal = 0;
    const reforecastMonthly = new Array(12).fill(0);
    const budgetMonthly = new Array(12).fill(0);
    for (const accountValues of rawData.financialValuesPropertyBudgetSeason) {
        if (accountIds.includes(accountValues.accountId)) {
            if (accountValues.year == property.reforecastYear && accountValues.versionType == VersionType.Reforecast) {
                reforecastTotal += accountValues.values.sum();
                for (let i = 0; i < 12; i++) {
                    reforecastMonthly[i] += accountValues.values[i];
                }
            }
            else if (accountValues.year == property.budgetYear && accountValues.versionType == VersionType.Budget) {
                budgetTotal += accountValues.values.sum();
                for (let i = 0; i < 12; i++) {
                    budgetMonthly[i] += accountValues.values[i];
                }
            }
        }
    }
    let varianceAmount = 0;
    let variancePercent = 0;

    if (Math.abs(reforecastTotal) < EPSILON) {
        varianceAmount = Math.abs(budgetTotal) < EPSILON ? 0 : budgetTotal;
        variancePercent = 0;
    }
    else {
        varianceAmount = budgetTotal - reforecastTotal;
        variancePercent = budgetTotal / reforecastTotal - 1;
    }
    return {
        reforecastTotal,
        budgetTotal,
        reforecastMonthly,
        budgetMonthly,
        varianceAmount,
        variancePercent
    };
}

export type TSummaryDataExpiringCounts = ReturnType<typeof buildSummaryDataExpiringCounts>;

export function buildSummaryDataExpiringCounts(property: Property, rawData: GetPropertyExecutiveSummaryDataQuery) {
    let reforecastExpirations:number|null = null;
    let reforecastExpiringRenewals:number|null = null;
    let reforecastExpiringMoveOuts:number|null = null;
    let reforecastAvgExpiringRenewalRatio:number|null = null;
    let reforecastAvgExpiringMoveOutRatio:number|null = null;
    let budgetExpirations:number|null = null;
    let budgetExpiringRenewals:number|null = null;
    let budgetExpiringMoveOuts:number|null = null;
    let budgetExpiringRenewalRatio:number|null = null;
    let budgetExpiringMoveOutRatio:number|null = null;
    let budgetAvgExpiringRenewalRatio:number|null = null;
    let budgetAvgExpiringMoveOutRatio:number|null = null;
    let expitationsVariancePercent:number|null = null;
    let expirationsVarianceAmount:number|null = null;
    let expiringRenewalsVariancePercent:number|null = null;
    let expiringRenewalsVarianceAmount:number|null = null;
    let expiringMoveOutsVariancePercent:number|null = null;
    let expiringMoveOutsVarianceAmount:number|null = null;

    const reforecastExpirationsMonthly = new Array(12).fill(0);
    const reforecastExpiringRenewalsMonthly = new Array(12).fill(0);
    const reforecastExpiringRenewalRatiosMonthly = new Array(12).fill(0);
    const reforecastExpiringMoveOutRatiosMonthly = new Array(12).fill(0);
    const reforecastExpiringMoveOutsMonthly = new Array(12).fill(0);
    const budgetExpirationsMonthly = new Array(12).fill(0);
    const budgetExpiringRenewalsMonthly = new Array(12).fill(0);
    const budgetExpiringRenewalRatiosMonthly = new Array(12).fill(0);
    const budgetExpiringMoveOutRatiosMonthly = new Array(12).fill(0);
    const budgetExpiringMoveOutsMonthly = new Array(12).fill(0);

    if (rawData.originalRevenueModel) {
        addToArrays(property, rawData.originalRevenueModel.unitTypeExpirationCount, reforecastExpirationsMonthly, budgetExpirationsMonthly);
        addToArrays(property, rawData.originalRevenueModel.unitTypeMoveOutCount, reforecastExpiringMoveOutsMonthly, budgetExpiringMoveOutsMonthly);
        addToArrays(property, rawData.originalRevenueModel.unitTypeRenewalCount, reforecastExpiringRenewalsMonthly, budgetExpiringRenewalsMonthly);
        for (let i = 0; i < 12; i++) {
            if (Math.abs(reforecastExpirationsMonthly[i]) > EPSILON) {
                reforecastExpiringMoveOutRatiosMonthly[i] = reforecastExpiringMoveOutsMonthly[i] / reforecastExpirationsMonthly[i];
                reforecastExpiringRenewalRatiosMonthly[i] = reforecastExpiringRenewalsMonthly[i] / reforecastExpirationsMonthly[i];
            }
            if (Math.abs(budgetExpirationsMonthly[i]) > EPSILON) {
                budgetExpiringMoveOutRatiosMonthly[i] = budgetExpiringMoveOutsMonthly[i] / budgetExpirationsMonthly[i];
                budgetExpiringRenewalRatiosMonthly[i] = budgetExpiringRenewalsMonthly[i] / budgetExpirationsMonthly[i];
            }
        }

        reforecastExpirations = reforecastExpirationsMonthly.sum();
        reforecastExpiringRenewals = reforecastExpiringRenewalsMonthly.sum();
        reforecastExpiringMoveOuts = reforecastExpiringMoveOutsMonthly.sum();
        reforecastAvgExpiringRenewalRatio = reforecastExpiringRenewalRatiosMonthly.average();
        reforecastAvgExpiringMoveOutRatio = reforecastExpiringMoveOutRatiosMonthly.average();
        budgetExpirations = budgetExpirationsMonthly.sum();
        budgetExpiringRenewals = budgetExpiringRenewalsMonthly.sum();
        budgetExpiringMoveOuts = budgetExpiringMoveOutsMonthly.sum();
        budgetAvgExpiringRenewalRatio = budgetExpiringRenewalRatiosMonthly.average();
        budgetAvgExpiringMoveOutRatio = budgetExpiringMoveOutRatiosMonthly.average();
        expirationsVarianceAmount = budgetExpirations - reforecastExpirations;
        expiringRenewalsVarianceAmount = budgetExpiringRenewals - reforecastExpiringRenewals;
        expiringMoveOutsVarianceAmount = budgetExpiringMoveOuts - reforecastExpiringMoveOuts;
        if (Math.abs(budgetExpirations) > EPSILON) {
            budgetExpiringMoveOutRatio = budgetExpiringMoveOuts / budgetExpirations;
            budgetExpiringRenewalRatio = budgetExpiringRenewals / budgetExpirations;
        }
        if (Math.abs(reforecastExpirations) > EPSILON) {
            expitationsVariancePercent = budgetExpirations / reforecastExpirations - 1;
        }
        if (Math.abs(reforecastExpiringRenewals) > EPSILON) {
            expiringRenewalsVariancePercent = budgetExpiringRenewals / reforecastExpiringRenewals - 1;
        }
        if (Math.abs(reforecastExpiringMoveOuts) > EPSILON) {
            expiringMoveOutsVariancePercent = budgetExpiringMoveOuts / reforecastExpiringMoveOuts - 1;
        }
    }
    return {
        expirationsVarianceAmount,
        expitationsVariancePercent,
        expiringRenewalsVarianceAmount,
        expiringRenewalsVariancePercent,
        expiringMoveOutsVarianceAmount,
        expiringMoveOutsVariancePercent,
        reforecastExpirationsMonthly,
        reforecastExpiringMoveOutsMonthly,
        reforecastExpiringRenewalsMonthly,
        reforecastExpiringMoveOutRatiosMonthly,
        reforecastExpiringRenewalRatiosMonthly,
        reforecastAvgExpiringRenewalRatio,
        reforecastAvgExpiringMoveOutRatio,
        reforecastExpirations,
        reforecastExpiringRenewals,
        reforecastExpiringMoveOuts,
        budgetExpirationsMonthly,
        budgetExpiringMoveOutsMonthly,
        budgetExpiringRenewalsMonthly,
        budgetExpiringMoveOutRatiosMonthly,
        budgetExpiringRenewalRatiosMonthly,
        budgetAvgExpiringRenewalRatio,
        budgetAvgExpiringMoveOutRatio,
        budgetExpirations,
        budgetExpiringRenewals,
        budgetExpiringMoveOuts,
        budgetExpiringMoveOutRatio,
        budgetExpiringRenewalRatio,
    };
}

export function buildSummaryDataTradeOuts(rawData: GetPropertyExecutiveSummaryDataQuery) {

    if (!rawData.originalRevenueModel) {
        return {
            newLeaseTradeOut: null,
            newLeaseTradeOutPercent: null,
            renewalTradeOut: null,
            renewalTradeOutPercent: null
        };
    }

    const budgetExpiringMoveInsMonthly = new Array(12).fill(0);
    const budgetExpiringRenewalsMonthly = new Array(12).fill(0);
    const budgetInPlaceRentsMonthlyForRenewalTO = new Array(12).fill(0);
    const budgetInPlaceRentsMonthlyForNewLeaseTO = new Array(12).fill(0);
    const budgetNewLeaseTradeOutsMonthly = new Array(12).fill(0);
    const budgetRenewalTradeOutsMonthly = new Array(12).fill(0);
    const inPlaceRentsByUnitTypeId = rawData.originalRevenueModel.unitTypeInPlaceRent.toIdMap("unitTypeId");
    const newLeaseTradeOutsByUnitTypeId = rawData.originalRevenueModel.unitTypeNewLeaseTradeOut.toIdMap("unitTypeId");
    const renewalTradeOutsByUnitTypeId = rawData.originalRevenueModel.unitTypeRenewalTradeOut.toIdMap("unitTypeId");

    // SUM PRODUCT of renewals and unit type in place rents
    // SUM PRODUCT of renewals and unit type renewal trade outs
    for (const utCounts of rawData.originalRevenueModel.unitTypeRenewalCount) {
        const utInPlaceRent = inPlaceRentsByUnitTypeId[utCounts.unitTypeId];
        const utTradeOut = renewalTradeOutsByUnitTypeId[utCounts.unitTypeId];
        if (!utInPlaceRent || !utTradeOut) {
            continue;
        }
        for (let i = 0; i < 12; i++) {
            const iprVal = utInPlaceRent.budget[i];
            const tradeOutVal = utTradeOut.budget[i];
            const countVal = utCounts.budget[i];
            if (iprVal !== null && iprVal !== undefined
                && tradeOutVal !== null && tradeOutVal !== undefined
                && countVal !== null && countVal !== undefined) {

                    budgetInPlaceRentsMonthlyForRenewalTO[i] += iprVal * countVal;
                    budgetRenewalTradeOutsMonthly[i] += tradeOutVal * countVal;
                    budgetExpiringRenewalsMonthly[i] += countVal;
            }
        }
    }

    // SUM PRODUCT of move ins and unit type in place rents
    // SUM PRODUCT of move ins and unit type new lease trade outs
    for (const utCounts of rawData.originalRevenueModel.unitTypeMoveInCount) {
        const utInPlaceRent = inPlaceRentsByUnitTypeId[utCounts.unitTypeId];
        const utTradeOut = newLeaseTradeOutsByUnitTypeId[utCounts.unitTypeId];
        if (!utInPlaceRent || !utTradeOut) {
            continue;
        }
        for (let i = 0; i < 12; i++) {
            const iprVal = utInPlaceRent.budget[i];
            const tradeOutVal = utTradeOut.budget[i];
            const countVal = utCounts.budget[i];
            if (iprVal !== null && iprVal !== undefined
                && tradeOutVal !== null && tradeOutVal !== undefined
                && countVal !== null && countVal !== undefined) {

                    budgetInPlaceRentsMonthlyForNewLeaseTO[i] += iprVal * countVal;
                    budgetNewLeaseTradeOutsMonthly[i] += tradeOutVal * countVal;
                    budgetExpiringMoveInsMonthly[i] += countVal;
            }
        }
    }

    const totalExpiringMoveInCount = budgetExpiringMoveInsMonthly.sum();
    const totalExpiringRenewalCount = budgetExpiringRenewalsMonthly.sum();

    let inPlaceRentForNewLeaseTradeOut: number | null = null;
    let inPlaceRentForRenewalTradeOut: number | null = null;
    let newLeaseTradeOut: number | null = null;
    let newLeaseTradeOutPercent: number | null = null;
    let renewalTradeOut: number | null = null;
    let renewalTradeOutPercent: number | null = null;

    if (Math.abs(totalExpiringMoveInCount) > EPSILON) {
        inPlaceRentForNewLeaseTradeOut = budgetInPlaceRentsMonthlyForNewLeaseTO.sum() / totalExpiringMoveInCount;
        newLeaseTradeOut = budgetNewLeaseTradeOutsMonthly.sum() / totalExpiringMoveInCount;
        if (Math.abs(inPlaceRentForNewLeaseTradeOut) > EPSILON) {
            newLeaseTradeOutPercent = newLeaseTradeOut / inPlaceRentForNewLeaseTradeOut;
        }
    }

    if (Math.abs(totalExpiringRenewalCount) > EPSILON) {
        inPlaceRentForRenewalTradeOut = budgetInPlaceRentsMonthlyForRenewalTO.sum() / totalExpiringRenewalCount;
        renewalTradeOut = budgetRenewalTradeOutsMonthly.sum() / totalExpiringRenewalCount;
        if (Math.abs(inPlaceRentForRenewalTradeOut) > EPSILON) {
            renewalTradeOutPercent = renewalTradeOut / inPlaceRentForRenewalTradeOut;
        }
    }

    return {
        newLeaseTradeOut,
        newLeaseTradeOutPercent,
        renewalTradeOut,
        renewalTradeOutPercent
    };
}

export type TBudgetedAvgMarketRentStat = ReturnType<typeof buildBudgetedAvgMarketRentStat>;

export function buildBudgetedAvgMarketRentStat(budgetedMarketRentStat: TSummaryDataFinancialMetric, occupancyStat: TSummaryDataAverageOccupancy) {
    let budgetedAvgBudgetMarketRent = null;
    let budgetedAvgReforecastMarketRent = null;
    let varianceAmount = null;
    let variancePercent = null;

    const budgetedMathAvgBudgetMarketRent = budgetedMarketRentStat.budgetMonthly.average();
    const budgetedMathAvgBudgetOccupiedCount = occupancyStat.budgetUnitCountMonthly.average();

    const budgetedMathAvgRefofecastMarketRent = budgetedMarketRentStat.reforecastMonthly.average();
    const budgetedMathAvgReforecastOccupiedCount = occupancyStat.reforecastUnitCountMonthly.average();

    if (Math.abs(budgetedMathAvgBudgetOccupiedCount) > EPSILON) {
        budgetedAvgBudgetMarketRent = budgetedMathAvgBudgetMarketRent / budgetedMathAvgBudgetOccupiedCount;
    }
    if (Math.abs(budgetedMathAvgReforecastOccupiedCount) > EPSILON) {
        budgetedAvgReforecastMarketRent = budgetedMathAvgRefofecastMarketRent / budgetedMathAvgReforecastOccupiedCount;
    }
    if (budgetedAvgBudgetMarketRent !== null && budgetedAvgReforecastMarketRent) {
        varianceAmount = budgetedAvgBudgetMarketRent - budgetedAvgReforecastMarketRent;
        if (Math.abs(budgetedAvgReforecastMarketRent) > EPSILON) {
            variancePercent = budgetedAvgBudgetMarketRent / budgetedAvgReforecastMarketRent - 1.0;
        }
    }

    return {
        budgetedAvgBudgetMarketRent,
        budgetedAvgReforecastMarketRent,
        varianceAmount,
        variancePercent
    };
}

export function buildCommentsPreviewData(rawData: GetPropertyExecutiveSummaryDataQuery): Map<string, CommentPreviewData> {
    const ret:Map<string, CommentPreviewData> = new Map();
    for (const commentPreview of rawData.getClientFirstComments) {
        ret.set(commentPreview.accountId, {text: commentPreview.text, count: commentPreview.count});
    }

    return ret;
}