import { NewSpreadsheetAPI } from "../../../../../../components/spreadsheet/NewSpreadsheetTypes";
import {
    FIRST_VALUE_COLUMN,
    FIRST_VALUE_ROW,
    PROPERTY_LEVEL_TOTAL_ROWS_COUNT,
    SheetNames,
    SheetsIndexMap,
    SJS_TEXT_BUDGET, SJS_TEXT_IN_PLACE_RENT, SJS_TEXT_PERCENT,
    SJS_TEXT_REFORECAST,
    SJS_TEXT_TRADEOUT_DOLLAR,
    SJS_TEXT_TRADEOUT_PERCENTAGE,
    TOTAL_COLS_COUNT
} from "./cappedRenewalIncreaseSjsConfig";
import { GetCappedRenewalIncreaseQuery, RenewalIncreaseAmountAndPercent } from "../../../../../../__generated__/generated_types";

type RenewalIncreaseAmountAndPercentNumbersOnly = Pick<RenewalIncreaseAmountAndPercent, "amounts" | "inPlaceRents" | "percents">;

function mergeActualsAndReforecast(
    actuals: RenewalIncreaseAmountAndPercentNumbersOnly,
    reforecast: RenewalIncreaseAmountAndPercentNumbersOnly,
    startReforecastMonthIndex: number
): RenewalIncreaseAmountAndPercentNumbersOnly {
    const result: RenewalIncreaseAmountAndPercentNumbersOnly = {
        amounts: actuals.amounts.slice(0, startReforecastMonthIndex).concat(reforecast.amounts.slice(startReforecastMonthIndex)),
        inPlaceRents: actuals.inPlaceRents.slice(0, startReforecastMonthIndex).concat(reforecast.inPlaceRents.slice(startReforecastMonthIndex)),
        percents: actuals.percents.slice(0, startReforecastMonthIndex).concat(reforecast.percents.slice(startReforecastMonthIndex))
    };

    return result;
}

function createSection(rowTitle: string, data: RenewalIncreaseAmountAndPercentNumbersOnly): string[][] {
    const lastHeaderRowRenewalIncreases: string[] = [rowTitle, SJS_TEXT_TRADEOUT_DOLLAR];
    const lastHeaderRowRenewalPercentages: string[] = [``, SJS_TEXT_TRADEOUT_PERCENTAGE];
    const lastHeaderRowInPlaceRents: string[] = [``, SJS_TEXT_IN_PLACE_RENT];

    let lastDataRowRenewalIncreases: string[] = [];
    let lastDataRowRenewalPercentages: string[] = [];
    let lastDataRowInPlaceRents: string[] = [];

    lastDataRowRenewalIncreases = lastDataRowRenewalIncreases.concat(data.amounts);
    lastDataRowRenewalPercentages = lastDataRowRenewalPercentages.concat(data.percents);
    lastDataRowInPlaceRents = lastDataRowInPlaceRents.concat(data.inPlaceRents);

    lastDataRowRenewalIncreases = formatEntries(lastDataRowRenewalIncreases, "", 0);
    lastDataRowRenewalPercentages = formatEntries(lastDataRowRenewalPercentages, SJS_TEXT_PERCENT, 2);
    lastDataRowInPlaceRents = formatEntries(lastDataRowInPlaceRents, "", 0);

    const result = [] as string[][];
    result.push(lastHeaderRowRenewalIncreases.concat(lastDataRowRenewalIncreases));
    result.push(lastHeaderRowRenewalPercentages.concat(lastDataRowRenewalPercentages));
    result.push(lastHeaderRowInPlaceRents.concat(lastDataRowInPlaceRents));
    return result;
}

function createPropertyHistoricals(
    data: GetCappedRenewalIncreaseQuery["property"],
    year: number
): string[][] {
    let result = [] as string[][];

    if (data && data != null) {
        let section = [] as string[][];

        section = createSection(`${year - 1}`, data.thisYearM1Actuals);
        result = result.concat(section);

        section = createSection(`${year - 2}`, data.thisYearM2Actuals);
        result = result.concat(section);

        section = createSection(`${year - 3}`, data.thisYearM3Actuals);
        result = result.concat(section);
    }

    return result;
}

export const getPropertyLevelReforecastDataArray = (
    data: GetCappedRenewalIncreaseQuery["property"],
    year: number,
    startReforecastMonthIndex: number
): string[][] => {
    let plDataArray: string[][] = [];
    if(data){
        const firstRowData = mergeActualsAndReforecast(data.thisYearActuals, data.thisYearReforecast, startReforecastMonthIndex);
        const firstRow = createSection(`${year} ${SJS_TEXT_REFORECAST}`, firstRowData);
        plDataArray = plDataArray.concat(firstRow);

        const secondRow = createSection(`${year} ${SJS_TEXT_BUDGET}`, data.thisYearBudget);
        plDataArray = plDataArray.concat(secondRow);

        const historicalSections = createPropertyHistoricals(data, year);
        plDataArray = plDataArray.concat(historicalSections);
    }
    // console.info(`getPropertyLevelReforecastDataArray, ${JSON.stringify(plDataArray)}`);
    return plDataArray;
};

export const getPropertyLevelBudgetDataArray = (
    data: GetCappedRenewalIncreaseQuery["property"],
    year: number,
    startReforecastMonthIndex: number
): string[][] => {
    let plDataArray: string[][] = [];
    if(data){
        // First Row Processing
        const firstRow = createSection(`${year + 1} ${SJS_TEXT_BUDGET}`, data.nextYearBudget);
        plDataArray = plDataArray.concat(firstRow);

        // Second Row Processing
        const secondRowData = mergeActualsAndReforecast(data.thisYearActuals, data.thisYearReforecast, startReforecastMonthIndex);
        const secondRow = createSection(`${year} ${SJS_TEXT_REFORECAST}`, secondRowData);
        plDataArray = plDataArray.concat(secondRow);

        const historicalSections = createPropertyHistoricals(data, year);
        plDataArray = plDataArray.concat(historicalSections);
    }

    return plDataArray;
};

export const getUnitTypeLevelReforecastDataArray = (
    data: GetCappedRenewalIncreaseQuery["property"],
    year: number,
    startReforecastMonthIndex: number
): string[][] => {
    const utlDataArray: string[][] = [];

    if(data){
        data.unitTypes.sortBy("name").forEach((ut) => {
            const currentHeaderRowRenewalIncreases: string[] = [ut.name, SJS_TEXT_TRADEOUT_DOLLAR];
            const currentHeaderRowRenewalPercentages: string[] = ["", SJS_TEXT_TRADEOUT_PERCENTAGE];
            const currentHeaderRowInPlaceRents: string[] = ["", SJS_TEXT_IN_PLACE_RENT];
            let currentDataRenewalIncreases: string[] = [];
            let currentDataRenewalPercentages: string[] = [];
            let currentDataInPlaceRents: string[] = [];
            if(ut.thisYearActuals){
                currentDataRenewalIncreases = currentDataRenewalIncreases.concat(ut.thisYearActuals.amounts.slice(0, startReforecastMonthIndex));
                currentDataRenewalPercentages = currentDataRenewalPercentages.concat(ut.thisYearActuals.percents.slice(0, startReforecastMonthIndex));
                currentDataInPlaceRents = currentDataInPlaceRents.concat(ut.thisYearActuals.inPlaceRents.slice(0, startReforecastMonthIndex));
            }
            if(ut.thisYearReforecast){
                currentDataRenewalIncreases = currentDataRenewalIncreases.concat(ut.thisYearReforecast.amounts.slice(startReforecastMonthIndex));
                currentDataRenewalPercentages = currentDataRenewalPercentages.concat(ut.thisYearReforecast.percents.slice(startReforecastMonthIndex));
                currentDataInPlaceRents = currentDataInPlaceRents.concat(ut.thisYearReforecast.inPlaceRents.slice(startReforecastMonthIndex));
            }
            currentDataRenewalIncreases = formatEntries(currentDataRenewalIncreases, "", 0);
            currentDataRenewalPercentages = formatEntries(currentDataRenewalPercentages, SJS_TEXT_PERCENT, 2);
            currentDataInPlaceRents = formatEntries(currentDataInPlaceRents, "", 0);
            utlDataArray.push(currentHeaderRowRenewalIncreases.concat(currentDataRenewalIncreases));
            utlDataArray.push(currentHeaderRowRenewalPercentages.concat(currentDataRenewalPercentages));
            utlDataArray.push(currentHeaderRowInPlaceRents.concat(currentDataInPlaceRents));
        });

        const lastHeaderRowRenewalIncreases: string[] = [`${year} Reforecast`, SJS_TEXT_TRADEOUT_DOLLAR];
        const lastHeaderRowRenewalPercentages: string[] = [``, SJS_TEXT_TRADEOUT_PERCENTAGE];
        const lastHeaderRowInPlaceRents: string[] = [``, SJS_TEXT_IN_PLACE_RENT];
        let lastDataRowRenewalIncreases: string[] = [];
        let lastDataRowRenewalPercentages: string[] = [];
        let lastDataRowInPlaceRents: string[] = [];
        if(data.thisYearActuals){
            lastDataRowRenewalIncreases = lastDataRowRenewalIncreases.concat(data.thisYearActuals.amounts.slice(0, startReforecastMonthIndex));
            lastDataRowRenewalPercentages = lastDataRowRenewalPercentages.concat(data.thisYearActuals.percents.slice(0, startReforecastMonthIndex));
            lastDataRowInPlaceRents = lastDataRowInPlaceRents.concat(data.thisYearActuals.inPlaceRents.slice(0, startReforecastMonthIndex));
        }
        if(data.thisYearReforecast){
            lastDataRowRenewalIncreases = lastDataRowRenewalIncreases.concat(data.thisYearReforecast.amounts.slice(startReforecastMonthIndex));
            lastDataRowRenewalPercentages = lastDataRowRenewalPercentages.concat(data.thisYearReforecast.percents.slice(startReforecastMonthIndex));
            lastDataRowInPlaceRents = lastDataRowInPlaceRents.concat(data.thisYearReforecast.inPlaceRents.slice(startReforecastMonthIndex));
        }
        lastDataRowRenewalIncreases = formatEntries(lastDataRowRenewalIncreases, "", 0);
        lastDataRowRenewalPercentages = formatEntries(lastDataRowRenewalPercentages, SJS_TEXT_PERCENT, 2);
        lastDataRowInPlaceRents = formatEntries(lastDataRowInPlaceRents, "", 0);

        utlDataArray.push(lastHeaderRowRenewalIncreases.concat(lastDataRowRenewalIncreases));
        utlDataArray.push(lastHeaderRowRenewalPercentages.concat(lastDataRowRenewalPercentages));
        utlDataArray.push(lastHeaderRowInPlaceRents.concat(lastDataRowInPlaceRents));
    }

    return utlDataArray;
};

export const getUnitTypeLevelBudgetDataArray = (
    data: GetCappedRenewalIncreaseQuery["property"],
    year: number
): string[][] => {
    const utlDataArray: string[][] = [];

    if(data){
        data.unitTypes.sortBy("name").forEach((ut) => {
            const currentHeaderRowRenewalIncreases: string[] = [ut.name, SJS_TEXT_TRADEOUT_DOLLAR];
            const currentHeaderRowRenewalPercentages: string[] = ["", SJS_TEXT_TRADEOUT_PERCENTAGE];
            const currentHeaderRowInPlaceRents: string[] = ["", SJS_TEXT_IN_PLACE_RENT];
            let currentDataRowRenewalIncreases: string[] = [];
            let currentDataRowRenewalPercentages: string[] = [];
            let currentDataRowInPlaceRents: string[] = [];
            if(ut.nextYearBudget){
                currentDataRowRenewalIncreases = ut.nextYearBudget.amounts;
                currentDataRowRenewalPercentages = ut.nextYearBudget.percents;
                currentDataRowInPlaceRents = ut.nextYearBudget.inPlaceRents;
            }
            currentDataRowRenewalIncreases = formatEntries(currentDataRowRenewalIncreases, "", 0);
            currentDataRowRenewalPercentages = formatEntries(currentDataRowRenewalPercentages, SJS_TEXT_PERCENT, 2);
            currentDataRowInPlaceRents = formatEntries(currentDataRowInPlaceRents, "", 0);
            utlDataArray.push(currentHeaderRowRenewalIncreases.concat(currentDataRowRenewalIncreases));
            utlDataArray.push(currentHeaderRowRenewalPercentages.concat(currentDataRowRenewalPercentages));
            utlDataArray.push(currentHeaderRowInPlaceRents.concat(currentDataRowInPlaceRents));
        });

        const lastHeaderRowRenewalIncreases: string[] = [`${year + 1} ${SJS_TEXT_BUDGET}`, SJS_TEXT_TRADEOUT_DOLLAR];
        const lastHeaderRowRenewalPercentages: string[] = [``, SJS_TEXT_TRADEOUT_PERCENTAGE];
        const lastHeaderRowInPlaceRents: string[] = [``, SJS_TEXT_IN_PLACE_RENT];
        let lastDataRowRenewalIncreases: string[] = [];
        let lastDataRowRenewalPercentages: string[] = [];
        let lastDataRowInPlaceRents: string[] = [];
        if(data.nextYearBudget){
            lastDataRowRenewalIncreases = data.nextYearBudget.amounts;
            lastDataRowRenewalPercentages = data.nextYearBudget.percents;
            lastDataRowInPlaceRents = data.nextYearBudget.inPlaceRents;
        }
        lastDataRowRenewalIncreases = formatEntries(lastDataRowRenewalIncreases, "", 0);
        lastDataRowRenewalPercentages = formatEntries(lastDataRowRenewalPercentages, SJS_TEXT_PERCENT, 2);
        lastDataRowInPlaceRents = formatEntries(lastDataRowInPlaceRents, "", 0);

        utlDataArray.push(lastHeaderRowRenewalIncreases.concat(lastDataRowRenewalIncreases));
        utlDataArray.push(lastHeaderRowRenewalPercentages.concat(lastDataRowRenewalPercentages));
        utlDataArray.push(lastHeaderRowInPlaceRents.concat(lastDataRowInPlaceRents));
    }
    // console.info(`getUnitTypeLevelBudgetDataArray, ${JSON.stringify(utlDataArray)}`);
    return utlDataArray;
};

const formatEntries = (input: (string|number)[], suffix: string, decimalPlaces: number): string[] => {
    let sum = 0;
    let count = 0;
    const output: string[] = input.map(e => {
        if(e){
            const parsed = parseFloat(e.toString());
            if (!isNaN(parsed)) {
                sum += parsed;
            }
            count++;
            return `${parsed.toFixed(decimalPlaces)}${suffix}`;
        }
        return '';
    });
    const avg: number = sum / count;
    let avgStr = "";
    if (!isNaN(avg)) {
        avgStr = `${avg.toFixed(decimalPlaces)}${suffix}`;
    }
    output.push(avgStr);
    return output;
};

export const updatePropertyLevelSheetViewPort = (ssapi: NewSpreadsheetAPI): void => {
    ssapi.setActiveSheetIndex(SheetsIndexMap[SheetNames.PROPERTY_LEVEL]);
    ssapi.updateSpreadsheetViewPort({
        startRow: FIRST_VALUE_ROW,
        startCol: FIRST_VALUE_COLUMN,
        rows: PROPERTY_LEVEL_TOTAL_ROWS_COUNT,
        cols: TOTAL_COLS_COUNT,
        sheetName: SheetNames.PROPERTY_LEVEL
    });
};

export const updateUnitTypeLevelSheetViewPort = (ssapi: NewSpreadsheetAPI, unitTypeCounts: number): void => {
    ssapi.setActiveSheetIndex(SheetsIndexMap[SheetNames.UNIT_TYPE_LEVEL]);
    ssapi.updateSpreadsheetViewPort({
        startRow: FIRST_VALUE_ROW,
        startCol: FIRST_VALUE_COLUMN,
        rows: 1 + unitTypeCounts * 3 + 3, // header, unit_count * 3, average_row * 2
        cols: TOTAL_COLS_COUNT,
        sheetName: SheetNames.UNIT_TYPE_LEVEL
    });
};