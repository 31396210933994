import GC from "@grapecity/spread-sheets";
import { drawExpandCollapse, isInsideExpandCollapse } from "./icon-renderers/draw-expand-collapse";
import { renderLightning } from "./icon-renderers/draw_lightning";
import { ICustomCellLightningHitInfo, ICustomCellTargetHitInfo } from "./types";

export class LightningCell extends GC.Spread.Sheets.CellTypes.Text {
    constructor() {
        super();
        this.typeName = "LightningCell";
    }

    showLightning = false;
    highlightLighning = false;

    override paint(ctx: CanvasRenderingContext2D, value: any, x: number, y: number, w: number, h: number, style: GC.Spread.Sheets.Style, context?: any): void {
        super.paint(ctx, value, x, y, w, h, style, context);
        const spread = context.sheet.getParent();
        let canvasEl: any = spread
            .getHost()
            .querySelector('[gcuielement="gcWorksheetCanvas"]');

        if (this.highlightLighning) {
            canvasEl.style.cursor = "pointer";
        }
    }

    override paintContent(ctx: CanvasRenderingContext2D, value: any, x: number, y: number, w: number, h: number, style: GC.Spread.Sheets.Style, context?: any): void {
        super.paintContent(ctx, value, x, y, w, h, style, context);
        if (this.showLightning) {
            renderLightning(ctx, x, y, w, h, this.highlightLighning);
        }
    }

    override getHitInfo(x: number, y: number, _cellStyle: GC.Spread.Sheets.Style, cellRect: GC.Spread.Sheets.Rect, context?: any): GC.Spread.Sheets.IHitTestCellTypeHitInfo {
        const c = cellRect; // alias
        const highlightLightning = c.x + c.width - 32 < x && x < c.x + c.width - 6;
        // console.log(highlightLightning, x, x + c.width - 32, c.x, c.y, c.width, c.height);
        const [row, col] = [context.row as number, context.col as number];
        const info: ICustomCellLightningHitInfo = {
            x,
            y,
            row,
            col,
            cellRect,
            highlightLightning,
            isTargetHit: highlightLightning
        };
        return info;
    }

    override processMouseEnter(_hitInfo: GC.Spread.Sheets.IHitTestCellTypeHitInfo){
        this.showLightning = true;
        return false;
    }

    override processMouseLeave(_hitInfo: GC.Spread.Sheets.IHitTestCellTypeHitInfo): boolean {
        this.showLightning = false;
        return false;
    }

    override isReservedKey(e: KeyboardEvent, context?: any): boolean {
        const ret = super.isReservedKey(e, context);
        return ret;
    }

}
