import { ReactElement } from "react";
import { TransactionRowViewModel } from "../../../data/logic/buildTransactionRows";
import * as css from "../styles/transactionDetails.module.scss";
import InfoBlock from "./info-block/InfoBlock";
import Title from "./info-block/Title";
import Content from "./info-block/Content";
import AttachmentAccess from "./attachment-access/AttachmentAccess";
import {formatDateInLocale} from "../../../../../../../../../../utils/date-helpers";

interface IPayableProps {
    transaction: Partial<TransactionRowViewModel>
}

export default function PayableDetails(props:IPayableProps): ReactElement {
    return (
            <>
                <div className={css.detailsColumnLeft}>
                    <InfoBlock>
                        <Title>
                            DESCRIPTION
                        </Title>
                        <Content>
                            {props.transaction.description}
                        </Content>
                    </InfoBlock>
                    <InfoBlock>
                        <Title>
                            MEMO
                        </Title>
                        <Content>
                            {props.transaction.memo}
                        </Content>
                    </InfoBlock>
                </div>
                <div className={css.detailsColumnRight}>
                    <InfoBlock>
                        <Title>
                            TRANSACTION NUMBER
                        </Title>
                        <Content>
                            {props.transaction.id}
                        </Content>
                    </InfoBlock>
                    <InfoBlock>
                        <Title>
                            INVOICE DATE
                        </Title>
                        <Content>
                            {
                                props.transaction.date
                                        ? formatDateInLocale(props.transaction.date)
                                        : undefined
                            }
                        </Content>
                    </InfoBlock>
                </div>
            </>
    );
}