import React, { useCallback, useEffect, useReducer, useState } from "react";
import GC from '@grapecity/spread-sheets';
import * as ExcelIO from "@grapecity/spread-excelio";
import { SpreadSheets, Worksheet } from "@grapecity/spread-sheets-react";
import '@grapecity/spread-sheets/styles/gc.spread.sheets.excel2013white.css';
import { ChangedCell, CustomCellType, NewSpreadsheetHandlers } from "./NewSpreadsheetTypes";
import { CustomLink } from "./spreadjs-custom-cell-types/CustomLink";
import { OverrideModeCell } from "./spreadjs-custom-cell-types/OverrideModeCell";
import {
    ICustomCellInfo,
    ICustomCellLightningHitInfo,
    ICustomCellTargetHitInfo,
    ICustomCellToggleHitInfo
} from "./spreadjs-custom-cell-types/types";
import {
    ExpandableCategoryCell,
    ExpandableCategoryCellInfo
} from "./spreadjs-custom-cell-types/ExpandableCategoryCell";
import { ExpandCollapseRowCell } from "./spreadjs-custom-cell-types/ExpandCollapseRowCell";
import { LightningCell } from "./spreadjs-custom-cell-types/LigtningCell";
import { DriverPillsCell, DriverPillsCellInfo } from "./spreadjs-custom-cell-types/DriverPillsCell";
import {
    ICustomCellTypeaheadDropdown,
    TypeaheadDropdownCell
} from "./spreadjs-custom-cell-types/typeahead-dropdown/TypeaheadDropdownCell";
// import { ExpandRowHeadingCell } from "./spreadjs-custom-cell-types/ExpandRowHeadingCell";

import { SJSCfg } from "../../sjs/layout/shared/SJSConfig";


import { CheckboxCell, CheckboxCellInfo } from "./spreadjs-custom-cell-types/CheckboxCell";
import { BlankDashCell } from "./spreadjs-custom-cell-types/BlankDashCell";

const SpreadJSKey = process.env['REACT_APP_SPREADJS_LICENSE'] ?? 'no license found in ENV';
GC.Spread.Sheets.LicenseKey = SpreadJSKey;

// console.log("Using spreadjs license", SpreadJSKey);

(ExcelIO as any).LicenseKey = GC.Spread.Sheets.LicenseKey;

// console.log(GC.Spread.Sheets.LicenseKey);

const hostStyle: Partial<CSSStyleDeclaration> =
        {
            width: '1100px',
            height: '300px',
            padding: '0'
        };

const MANUAL_VALUE_FONT = "500 12px Inter";
const DRIVER_VALUE_FONT = "500 12px Inter";

const FONTS: Record<string, string> = {
    "MANUAL_VALUE_FONT": MANUAL_VALUE_FONT,
    "DRIVER_VALUE_FONT": DRIVER_VALUE_FONT
};

export interface SpreadsheetProps {
    startRow: number;
    startCol: number;
    rows: number;
    cols: number;
    subscribeToMouse: boolean;
    hasDriver?: boolean;
    handlers: NewSpreadsheetHandlers;
    heightAdjustment?: number;
    allowHorizontalScroll?: boolean;
    allowVerticalScroll?: boolean;
    safeAreaFirstRow?: number;
    safeAreaFirstColumn?: number;
    style?: Partial<CSSStyleDeclaration>;
    allowZoom?: boolean;
    readOnly?: boolean;
}

export interface SingleRowSelectionColorAdvisor {
    row: number;
    colors: Record<number, string>;
}

export function allowRightClickCopyPaste(spread: GC.Spread.Sheets.Workbook): void {
    spread.options.allowContextMenu = true;
    const reducedContextMenuOptions = spread.contextMenu.menuData
            .filter(opt => opt.text == "Copy" || opt.text == "Paste")
            .map(opt => {
                const updated: GC.Spread.Sheets.ContextMenu.IMenuItemData = {
                    name: opt.name,
                    text: opt.text,
                    command: opt.command,
                    disable: opt.disable,
                    iconClass: opt.iconClass,
                    group: opt.group,
                    subMenu: opt.subMenu,
                    type: opt.type,
                    workArea: opt.workArea,
                    visible: opt.visible,
                    menuContent: opt.menuContent,
                    status: opt.status,
                    placeholder: opt.placeholder,
                    commandOptions: opt.commandOptions,
                    itemClass: opt.itemClass,
                    title: opt.title
                };
                if (updated.text == "Paste") {
                    updated.name = "gc.spread.pasteValues";
                }
                return updated;
            });
    spread.contextMenu.menuData = reducedContextMenuOptions;
}

export function allowRightClickCopy(spread: GC.Spread.Sheets.Workbook): void {
    spread.options.allowContextMenu = true;
    const reducedContextMenuOptions = spread.contextMenu.menuData.filter(opt => opt.text == "Copy");
    spread.contextMenu.menuData = reducedContextMenuOptions;
}

function getSheet(spread: GC.Spread.Sheets.Workbook, sheetName: string|undefined) {
    if(sheetName){
        return spread.getSheetFromName(sheetName);
    }

    return spread.getActiveSheet();
}

type SheetDimensions = {
    startRow: number;
    startCol: number;
    rows: number;
    cols: number;
}

type SheetDimensionsPerSheet = Record<string, SheetDimensions>;
type SheetDimensionsUpdate = { sheetName: string; dimensions: SheetDimensions };

export const NewSpreadsheet: React.FC<SpreadsheetProps> = ({
                                                               startRow,
                                                               startCol,
                                                               rows,
                                                               cols,
                                                               handlers,
                                                               subscribeToMouse,
                                                               heightAdjustment,
                                                               allowHorizontalScroll,
                                                               allowVerticalScroll,
                                                               style,
                                                               allowZoom,
                                                               readOnly,
                                                           }) => {
    const [spread, setSpread] = useState<GC.Spread.Sheets.Workbook>();
    const [singleRowSelectionColorAdvisor, setSingleRowSelectionColorAdvisor] = useState<SingleRowSelectionColorAdvisor|undefined>(undefined);
    const [sheetDimensions, setSheetDimensions] = useReducer((state: SheetDimensionsPerSheet, action: SheetDimensionsUpdate) => {
        state[action.sheetName] = action.dimensions;
        return state;
    }, {});

    const _baseGetAutoFitHeight = GC.Spread.Sheets.CellTypes.Base.prototype.getAutoFitHeight;
    GC.Spread.Sheets.CellTypes.Base.prototype.getAutoFitHeight = function (value: any, text: string, cellStyle: GC.Spread.Sheets.Style, zoomFactor: number, context?: any) {
        const height = _baseGetAutoFitHeight.call(this, value, text, cellStyle, zoomFactor, context);

        let padOffset = 0;
        if(cellStyle.cellPadding){
            const padValues = cellStyle.cellPadding.split(' ');
            if(padValues.length == 1){
                padOffset = parseInt(padValues[0] as string) * 2;
            }
            if(padValues.length == 4){
                padOffset = parseInt(padValues[0] as string) + parseInt(padValues[2] as string);
            }
        }

        return Math.max(SJSCfg.MINUMUM_ROW_HEIGHT - padOffset, height);
    };

    const workbookInit = (spread: GC.Spread.Sheets.Workbook) => {
        setSpread(spread);
        updateSpreadsheetViewport({ spread, startRow, startCol, rows, cols });
    };

    const setArrayFormula = (params: { row: number, col: number, rows: number, cols: number, formula: string, sheetName?: string }) => {
        const { row, col, rows, cols, formula, sheetName } = params;

        if(!spread){
            return;
        }
        const sheet = getSheet(spread, sheetName);

        if(!sheet){
            return;
        }

        sheet.setArrayFormula(row, col, rows, cols, formula);
    };

    const setArray = (params: { row: number, col: number, array: any[], setFormula?: boolean|undefined, sheetName?: string, notify?: boolean }) => {
        const { row, col, array, setFormula, sheetName, notify } = params;
        if(!spread){
            return;
        }
        const sheet = getSheet(spread, sheetName);

        if(!sheet){
            return;
        }
        if(!setFormula){
            // clean formulas
            if(Array.isArray(array.firstElement)){
                const emptyRow = new Array(array.firstElement.length).fill("");
                const emptyRows = new Array(array.length).fill(emptyRow);
                sheet.setArray(row, col, emptyRows, true);
            } else {
                sheet.setArray(row, col, new Array(array.length).fill(""), true);
            }
        }
        sheet.setArray(row, col, array, setFormula);
        const obtainedSheetName = sheet.name();
        if(notify && !setFormula){
            const changes: ChangedCell[] = Array.isArray(array.firstElement) ?
                    array.flatMap((aRow: any[], rowIndex: number) =>
                            aRow.map((aCell, colIndex) => ({
                                row: rowIndex + row,
                                col: colIndex + col,
                                value: aCell,
                                sheetName: obtainedSheetName
                            }))
                    )
                    :
                    array.map((aCell, colIndex: number) => ({
                        row,
                        col: colIndex + col,
                        value: aCell,
                        sheetName: obtainedSheetName
                    }));

            handlers.cellsChanged(changes);
        }
    };

    const setValue = (params: { row: number, col: number, value: number|string|undefined, sheetName?: string }) => {
        const { row, col, value, sheetName } = params;
        if(spread){
            getSheet(spread, sheetName).setValue(row, col, value);
        }
    };

    const showRows = (params: { startRow: number, rows: number, sheetName?: string }) => {
        const { startRow, rows, sheetName } = params;

        if(spread){

            const sheet = getSheet(spread, sheetName);
            const totalRows = sheet.getRowCount();

            sheet.suspendPaint();

            sheet.showRow(startRow, GC.Spread.Sheets.VerticalPosition.top);
            let height = 0;
            for (let row = 0; row < totalRows; row++) {
                const visible = row >= startRow && row < startRow + rows;
                sheet.setRowVisible(row, visible);
                height += visible ? sheet.getRowHeight(row) : 0;
            }

            spread.getHost().style.height = height.toString() + "px";
            spread.refresh();
            sheet.resumePaint();
        }
    };

    const updateCustomCellType = (params: { row: number, col: number, cellType: CustomCellType, sheetName?: string, customCellInfo?: ICustomCellInfo }) => {

        const { row, col, cellType, sheetName, customCellInfo } = params;

        if(!spread){
            return;
        }

        const cell = getSheet(spread, sheetName).getCell(row, col);

        switch (cellType) {
            case CustomCellType.CHECKBOX_CELL: {
                const tp = cell.cellType();
                if(tp && tp.typeName == "CheckboxCell"){
                    (tp as CheckboxCell).updateCellInfo(customCellInfo as CheckboxCellInfo);
                }
            }
        }

    };

    const applyCustomCellType = (params: { row: number, col: number, cellType: CustomCellType, sheetName?: string, customCellInfo?: ICustomCellInfo }) => {

        const { row, col, cellType, sheetName, customCellInfo } = params;

        if(!spread){
            return;
        }

        const cell = getSheet(spread, sheetName).getCell(row, col);

        switch (cellType) {
            case CustomCellType.CUSTOM_LINK:
                cell.cellType(new CustomLink());
                break;
            case CustomCellType.CUSTOM_LINK_WITH_TRIANGLE:
                cell.cellType(new CustomLink(true));
                break;
            case CustomCellType.OVERRIDE_MODE_CELL:
                cell.cellType(new OverrideModeCell(false));
                break;
            case CustomCellType.OVERRIDE_MODE_CELL_WITH_TRIANGLE:
                cell.cellType(new OverrideModeCell(true));
                break;
            case CustomCellType.EXPANDABLE_CATEGORY_CELL:
                cell.cellType(new ExpandableCategoryCell(customCellInfo as ExpandableCategoryCellInfo));
                break;
            case CustomCellType.EXPAND_ROW_CELL:
                cell.cellType(new ExpandCollapseRowCell(true));
                break;
            case CustomCellType.COLLAPSE_ROW_CELL:
                cell.cellType(new ExpandCollapseRowCell(false));
                break;
            case CustomCellType.LIGHTNING_CELL:
                cell.cellType(new LightningCell());
                break;
            case CustomCellType.DRIVER_PILLS_CELL:
                cell.cellType(new DriverPillsCell(customCellInfo as DriverPillsCellInfo));
                break;
            case CustomCellType.TYPEAHEAD_DROPDOWN_CELL:
                cell.cellType(new TypeaheadDropdownCell(customCellInfo as ICustomCellTypeaheadDropdown));
                break;
            case CustomCellType.CHECKBOX_CELL:
                cell.cellType(new CheckboxCell(customCellInfo as CheckboxCellInfo));
                break;
            case CustomCellType.BLANK_DASH:
                cell.cellType(new BlankDashCell());
                break;
        }

    };

    const removeCustomCellType = (params: { row: number, col: number, sheetName?: string }) => {

        const { row, col, sheetName } = params;

        if(!spread){
            return;
        }

        getSheet(spread, sheetName).getCell(row, col).cellType(undefined);

    };

    const getCell = (params: { row: number, col: number, sheetName?: string, value?: string }) => {
        const { row, col, sheetName, value } = params;
        if(!spread) return;

        const cell = getSheet(spread, sheetName).getCell(row, col);
        if(value) cell.text(value);
        return cell;
    };

    const recalculate = () => {
        if(spread){
            spread.resumeCalcService(true);
        }
    };

    const setCellSelection = (params: { row: number, col: number, editMode: boolean }) => {
        const { row, col, editMode } = params;

        if(!spread) return;

        const sheet = getSheet(spread, undefined);

        sheet.setActiveCell(row, col);

        if(editMode){
            sheet.startEdit(true);
        }
    };

    const setSingleRowColorAdvisor = (advisor: SingleRowSelectionColorAdvisor|undefined) => {
        setSingleRowSelectionColorAdvisor(advisor);
    };

    // eslint-disable-next-line @typescript-eslint/ban-types
    const setTemplate = (params: { template: Object, sheetName?: string, rows?: number }) => {

        const { template, sheetName, rows: rowsParam } = params;

        if(spread){
            spread.fromJSON(template);

            const conditionalFormats = spread.getActiveSheet()?.conditionalFormats;
            if(conditionalFormats){
                const getRulesFunc = conditionalFormats.getRules as unknown as (() => GC.Spread.Sheets.ConditionalFormatting.ConditionRuleBase[]);
                const formattingRules = getRulesFunc.apply(conditionalFormats);
                for (const rule of formattingRules ?? []) {
                    if(rule.ruleType() == GC.Spread.Sheets.ConditionalFormatting.RuleType.formulaRule){
                        const formulaVal = (rule as GC.Spread.Sheets.ConditionalFormatting.NormalConditionRule).formula();
                        if(typeof formulaVal == "string" && (formulaVal as string).includes("STYLE_NAME")){
                            const formula = formulaVal as string;
                            formula.indexOf("STYLE_NAME");
                            const remainder = formula.substring(formula.indexOf("STYLE_NAME") + "STYLE_NAME\"<>\"".length);
                            const styleName = remainder.substring(0, remainder.indexOf("\""));
                            const font = FONTS[styleName];

                            if(font){
                                const style = rule.style() as GC.Spread.Sheets.Style;
                                // console.log(rule.ruleType());

                                if (readOnly) {
                                    style.locked = true;
                                    style.foreColor ="Black";
                                }

                                style.font = font;
                            }
                        }
                    }
                }
            }

            spread.options.scrollbarMaxAlign = true;
            spread.sheets.forEach(sheet => {
                sheet.options.clipBoardOptions = GC.Spread.Sheets.ClipboardPasteOptions.values;

                if (readOnly) {
                    sheet.options.isProtected = true;
                }
            });


            updateSpreadsheetViewport({ spread, startRow, startCol, rows: rowsParam ?? rows, cols, sheetName });
            subscribe();

        }
    };

    const subscribe = (): void => {

        if(!spread){
            return;
        }

        const sheet = getSheet(spread, undefined);

        if(!sheet){
            return;
        }

        if(!allowVerticalScroll){
            sheet.bind(GC.Spread.Sheets.Events.TopRowChanged,
                    function (
                            _sender: never,
                            _args: never
                    ) {
                        sheet.showRow(startRow, GC.Spread.Sheets.VerticalPosition.top);
                    }
            );
        } else {
            spread.options.showVerticalScrollbar = true;
            spread.options.scrollIgnoreHidden = true;
        }

        if(!allowHorizontalScroll){
            sheet.bind(GC.Spread.Sheets.Events.LeftColumnChanged,
                    function (
                            _sender: never,
                            _args: never
                    ) {
                        sheet.showColumn(startCol, GC.Spread.Sheets.HorizontalPosition.left);
                    }
            );
        } else {
            spread.options.showHorizontalScrollbar = true;
            spread.options.scrollIgnoreHidden = true;
        }

        if(!allowZoom){
            sheet.bind(GC.Spread.Sheets.Events.ViewZooming,
                    function (
                            _e: never,
                            args: any
                    ) {
                        if(args.newZoomFactor >= 1){
                            args.newZoomFactor = 1;
                        }
                        if(args.newZoomFactor <= 1){
                            args.newZoomFactor = 1;
                        }
                    }
            );
        }


        startCollectingCellUpdates();
        sheet.bind(GC.Spread.Sheets.Events.EditEnded, (_e: never, info: GC.Spread.Sheets.IEditEndedEventArgs) => {
            const cell = sheet.getCell(info.row, info.col);
            const cellType = cell.cellType() as OverrideModeCell;

            if(cellType?.typeName == "OverrideModeCell" && cellType.isSaveTriggered){
                handlers.cellsChanged([{
                    row: info.row,
                    col: info.col,
                    value: cell.value(),
                    sheetName: info.sheetName
                }]);
            }
        });
    };

    const setActiveSheetIndex = (index: number): void => {
        if(!spread){
            return;
        }

        spread.setActiveSheetIndex(index);
    };

    const getActiveSheetIndex = (): number => {
        const activeTab = spread?.getActiveSheetIndex() || -1;
        if(activeTab < 0){
            spread?.setActiveSheetIndex(0);
        }
        return spread?.getActiveSheetIndex() || 0;
    };

    const getSheetIndex = (name: string): number => {
        return spread?.getSheetIndex(name) || 0;
    };

    const updateSelectorBorder = (info: GC.Spread.Sheets.ISelectionChangedEventArgs) => {
        const sheet = spread?.getActiveSheet();
        if(sheet && singleRowSelectionColorAdvisor && info?.newSelections?.length > 0 && singleRowSelectionColorAdvisor.row == info.newSelections.firstElement.row){
            const color = singleRowSelectionColorAdvisor.colors[info.newSelections.firstElement.col];
            sheet.options.selectionBorderColor = color ?? "";
        }
    };

    const startCollectingCellUpdates = () => {
        if(!spread){
            return;
        }

        spread.bind(GC.Spread.Sheets.Events.RangeChanged,
                function (
                        _sender: never,
                        args: GC.Spread.Sheets.IRangeChangedEventArgs
                ) {
                    const actionFilter = [
                        GC.Spread.Sheets.RangeChangedAction.dragFill,
                        GC.Spread.Sheets.RangeChangedAction.clear,
                        GC.Spread.Sheets.RangeChangedAction.paste,
                        GC.Spread.Sheets.RangeChangedAction.evaluateFormula,
                    ];

                    if(actionFilter.includes(args.action)){
                        const cellsChanged = [] as ChangedCell[];
                        for (const cellPosition of args.changedCells) {
                            const cell = args.sheet.getCell(cellPosition.row, cellPosition.col);
                            cellsChanged.push({
                                row: cellPosition.row,
                                col: cellPosition.col,
                                value: cell.value(),
                                sheetName: args.sheetName
                            });
                        }
                        handlers.cellsChanged(cellsChanged);
                    }
                }
        );
        spread.bind(GC.Spread.Sheets.Events.CellChanged, (_e: never, info: GC.Spread.Sheets.ICellChangedEventArgs) => {
            const VALUE = info.newValue;
            if(info.propertyName === 'value' &&
                    (
                            info.oldValue != null && VALUE != null && info.oldValue.toString() != VALUE.toString()
                            ||
                            info.oldValue == null && VALUE != null
                            ||
                            info.oldValue != null && VALUE == null
                    )){
                handlers.cellsChanged([{
                    row: info.row,
                    col: info.col,
                    value: VALUE,
                    sheetName: info.sheetName,
                    sheetArea: info.sheetArea,
                    propertyName: info.propertyName,
                    oldValue: info.oldValue,
                }]);
            }
        });
    };

    useEffect(
            () => {
                if(spread && singleRowSelectionColorAdvisor){
                    const sheet = spread.getActiveSheet();
                    if(sheet){
                        sheet.bind(GC.Spread.Sheets.Events.SelectionChanged, (_e: never, info: GC.Spread.Sheets.ISelectionChangedEventArgs) => {
                            updateSelectorBorder(info);
                        });
                    }
                }
                return () => {
                    if(spread){
                        const sheet = spread.getActiveSheet();
                        if(sheet){
                            sheet.unbind(GC.Spread.Sheets.Events.SelectionChanged);
                        }
                    }
                };
            },
            [singleRowSelectionColorAdvisor]
    );

    useEffect(
            () => {
                if(spread){
                    updateSpreadsheetViewport({ spread, startRow, startCol, rows, cols });
                }
            },
            [startRow, startCol, rows, cols]
    );

    const updateSpreadSheetViewPortExposed = (params: { startRow: number, startCol: number, rows: number, cols: number, sheetName?: string }) => {
        if(spread){
            updateSpreadsheetViewport({
                spread,
                startRow: params.startRow,
                startCol: params.startCol,
                rows: params.rows,
                cols: params.cols,
                sheetName: params.sheetName
            });
        }
    };

    const updateSpreadsheetViewport = useCallback((params: { spread: GC.Spread.Sheets.Workbook, startRow: number, startCol: number, rows: number, cols: number, sheetName?: string }) => {

        const { spread, startRow, startCol, rows, cols, sheetName } = params;

        const sheet = getSheet(spread, sheetName);
        const totalRows = sheet.getRowCount();
        const totalCols = sheet.getColumnCount();

        sheet.suspendPaint();

        sheet.showRow(startRow, GC.Spread.Sheets.VerticalPosition.top);
        sheet.showColumn(startCol, GC.Spread.Sheets.HorizontalPosition.left);

        let height = 0;
        for (let row = 0; row < totalRows; row++) {
            const visible = row >= startRow && row < startRow + rows;
            if(!allowVerticalScroll){
                sheet.setRowVisible(row, visible);
            }
            height += visible ? sheet.getRowHeight(row) : 0;
        }

        let width = 0;
        for (let col = 0; col < totalCols; col++) {
            const visible = col >= startCol && col < startCol + cols;
            if(!allowHorizontalScroll){
                sheet.setColumnVisible(col, visible);
            }
            width += visible ? sheet.getColumnWidth(col) : 0;
        }

        spread.getHost().style.width = style?.width ?? style?.maxWidth ?? ((width + 1).toString() + "px");
        spread.getHost().style.height = style?.height ?? style?.maxHeight ?? (height + (heightAdjustment ?? 0)).toString() + "px";
        spread.refresh();
        setSheetDimensions({ sheetName: sheet.name(), dimensions: { startRow, startCol, rows, cols } });
        sheet.resumePaint();
    }, [rows]);


    function mouseMoveHandler(this: HTMLElement, event: MouseEvent) {
        event.preventDefault();
        if(!spread){
            return;
        }

        const y = event.offsetY;
        const x = event.offsetX;

        // Determine if the hover is over a gcuielelemnt, or the spreadsheet itself
        let isSpreadsheetHover = 'gcuielement' in (event.target as HTMLElement).attributes;

        const result = spread.hitTest(x, y);
        if(!isSpreadsheetHover || !result || !result.worksheetHitInfo || result.worksheetHitInfo.row == undefined || result.worksheetHitInfo.col == undefined){
            return;
        }

        const sheet = spread.getActiveSheet();
        const cellRect = sheet.getCellRect(result.worksheetHitInfo.row, result.worksheetHitInfo.col);

        handlers.cellHovered({
            row: result.worksheetHitInfo.row,
            col: result.worksheetHitInfo.col,
            cellRect: {
                x: cellRect.x,
                y: cellRect.y,
                height: cellRect.height,
                width: cellRect.width
            },
            offsetLeft: this.offsetLeft,
            offsetTop: this.offsetTop,
        });

        let resultContent: GC.Spread.Sheets.IHitTestInformation|undefined = undefined;
        try {
            resultContent = sheet.hitTest(x, y);
        }
        catch(_) {
            // swallow it. This soon will die.
        }

        if(resultContent &&
                resultContent.cellTypeHitInfo &&
                resultContent.cellTypeHitInfo.isReservedLocation &&
                resultContent.cellTypeHitInfo.cellRect &&
                resultContent.row != undefined &&
                resultContent.col != undefined
        ){
            // console.log("mouseMoveHandler - hover over content");
            const cellRect = resultContent.cellTypeHitInfo.cellRect;
            handlers.cellContentHovered({
                row: resultContent.row,
                col: resultContent.col,
                cellRect: {
                    x: cellRect.x,
                    y: cellRect.y,
                    height: cellRect.height,
                    width: cellRect.width
                },
                offsetLeft: this.offsetLeft,
                offsetTop: this.offsetTop,
            });
        }
    }

    function mouseUpHandler(this: HTMLElement, event: MouseEvent) {
        event.preventDefault();
        if(!spread){
            return;
        }

        const y = event.offsetY;
        const x = event.offsetX;

        const result = spread.hitTest(x, y);
        if(!result || !result.worksheetHitInfo || result.worksheetHitInfo.row == undefined || result.worksheetHitInfo.col == undefined){
            return;
        }

        const sheet = spread.getActiveSheet();
        const cellRect = sheet.getCellRect(result.worksheetHitInfo.row, result.worksheetHitInfo.col);

        const resultContent = sheet.hitTest(x, y);

        let granularContentClicked = false;

        if(resultContent &&
                resultContent.cellTypeHitInfo &&
                resultContent.cellTypeHitInfo.cellRect &&
                resultContent.row != undefined &&
                resultContent.col != undefined
        ){
            const cellRect = resultContent.cellTypeHitInfo.cellRect;
            if(resultContent.cellTypeHitInfo.isReservedLocation){
                granularContentClicked = true;
                handlers.cellContentClicked({
                    row: resultContent.row,
                    col: resultContent.col,
                    cellRect: {
                        x: cellRect.x,
                        y: cellRect.y,
                        height: cellRect.height,
                        width: cellRect.width
                    },
                    offsetLeft: this.offsetLeft,
                    offsetTop: this.offsetTop,
                });
            }

            if((resultContent.cellTypeHitInfo as ICustomCellTargetHitInfo)?.isTargetHit
                    || (resultContent.cellTypeHitInfo as ICustomCellLightningHitInfo)?.isTargetHit
                    || (resultContent.cellTypeHitInfo as ICustomCellToggleHitInfo)?.isTargetHit){

                granularContentClicked = true;
                handlers.cellTargetClicked({
                    row: resultContent.row,
                    col: resultContent.col,
                    cellRect: {
                        x: cellRect.x,
                        y: cellRect.y,
                        height: cellRect.height,
                        width: cellRect.width
                    },
                    offsetLeft: this.offsetLeft,
                    offsetTop: this.offsetTop
                });
            }
        } else {
            handlers.cellContentClicked(undefined);
            handlers.cellTargetClicked(undefined);
        }
        if(!granularContentClicked){
            handlers.cellClicked({
                row: result.worksheetHitInfo.row,
                col: result.worksheetHitInfo.col,
                cellRect: {
                    x: cellRect.x,
                    y: cellRect.y,
                    height: cellRect.height,
                    width: cellRect.width
                },
                offsetLeft: this.offsetLeft,
                offsetTop: this.offsetTop
            });
        }
    }

    const setCellLocked = (params: { row: number, col: number, locked: boolean, sheetName?: string }) => {

        const { row, col, locked, sheetName } = params;

        if(!spread){
            return;
        }
        getSheet(spread, sheetName).getCell(row, col).locked(locked);
    };

    const setCellsLocked = (params: { row: number, col: number, rowCount: number, colCount: number, locked: boolean, sheetName?: string }) => {

        const { row, col, rowCount, colCount, locked, sheetName } = params;

        if(!spread){
            return;
        }

        getSheet(spread, sheetName).getRange(row, col, rowCount, colCount).locked(locked);
    };

    const getColumnLabel = (params: { col: number, sheetName?: string }): string => {

        const { col, sheetName } = params;

        if(!spread){
            return "A";
        }

        return getSheet(spread, sheetName).getValue(0, col, GC.Spread.Sheets.SheetArea.colHeader);
    };

    const getValue = (params: { row: number, col: number, sheetName?: string }): number|string => {

        const { row, col, sheetName } = params;

        if(!spread){
            return "";
        }

        return getSheet(spread, sheetName).getCell(row, col).value();
    };

    const setColumnWidth = (params: { col: number, width: number }) => {
        const { col, width } = params;

        if(!spread){
            return;
        }

        const sheet = getSheet(spread, undefined);

        sheet.setColumnWidth(col, width);
    };

    const setRowHeight = (params: { row: number, height: number }) => {
        const { row, height } = params;

        if(!spread){
            return;
        }

        const sheet = getSheet(spread, undefined);

        sheet.setRowHeight(row, height);
    };

    const setStyle = (params: { row: number, col: number, style: GC.Spread.Sheets.Style }) => {
        const { row, col, style } = params;

        if(!spread){
            return;
        }

        const sheet = getSheet(spread, undefined);

        sheet.setStyle(row, col, style);
    };

    const addSpan = (params: { row: number, col: number, rowCount: number, colCount: number, sheetName?: string }) => {
        if(!spread){
            return;
        }

        const { row, col, rowCount, colCount, sheetName } = params;

        const sheet = getSheet(spread, sheetName);

        sheet.addSpan(row, col, rowCount, colCount);

    };

    const suspend = (): void => {
        if(!spread){
            return;
        }

        spread.suspendEvent();
        spread.suspendPaint();
        spread.suspendCalcService(false);

    };

    const resume = (): void => {
        if(!spread){
            return;
        }

        spread.resumeCalcService(false);
        spread.resumePaint();
        spread.resumeEvent();

        // const sheet = getSheet(spread, undefined);

        // if (sheet) {
        //     sheet.resumeCalcService();
        //     sheet.resumePaint();
        // }
    };

    const toJSON = (): string => {
        if(!spread){
            return "";
        }

        return JSON.stringify(spread.toJSON(), null, 2);
    };

    const setRowCount = (params: { rows: number, sheetName?: string }) => {
        if(!spread){
            return;
        }

        const { rows, sheetName } = params;

        getSheet(spread, sheetName)?.setRowCount(rows);
    };

    const setColumnCount = (params: { cols: number, sheetName?: string }) => {
        if(!spread){
            return;
        }

        const { cols, sheetName } = params;

        getSheet(spread, sheetName)?.setColumnCount(cols);
    };

    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // TODO: METHOD DOES NOT WORK. FIX IT BEFORE USING.
    // Error: Uncaught TypeError: Cannot read properties of undefined (reading 'cellContent')
    // When: Occurs when scrolling down analyst view.
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    const createOutlineColumn = (params: { columnIdx: number, sheetName?: string }) => {
        if(!spread){
            return;
        }

        const { columnIdx, sheetName } = params;
        const sheet = getSheet(spread, sheetName);

        // sheet?.outlineColumn.options({
        //     columnIndex: columnIdx,
        //     showCheckBox: false,
        //     showIndicator: false,
        //     images: [],
        //     expandIndicator: undefined,
        //     collapseIndicator: undefined,
        //     maxLevel: 5,
        // });
        sheet?.showRowOutline(false);
        // sheet?.showColumnOutline(false);
        // sheet?.outlineColumn.refresh();
    };

    const showRowOutline = (params: { show: boolean, sheetName?: string }) => {
        if(!spread){
            return;
        }

        const { show, sheetName } = params;
        const sheet = getSheet(spread, sheetName);

        sheet?.showRowOutline(show);
    };


    const frozenRowCount = (params: { rowCount: number, sheetName?: string }) => {
        if(!spread){
            return;
        }

        const { rowCount, sheetName } = params;
        const sheet = getSheet(spread, sheetName);

        sheet?.frozenRowCount(rowCount);
    };
    const frozenColumnCount = (params: { colCount: number, sheetName?: string }) => {
        if(!spread){
            return;
        }

        const { colCount, sheetName } = params;
        const sheet = getSheet(spread, sheetName);

        sheet?.frozenColumnCount(colCount);
    };
    const setFrozenLineColor = (params: { color: string, sheetName?: string }): void => {
        if(!spread){
            return;
        }

        const { color, sheetName } = params;
        const sheet = getSheet(spread, sheetName);

        sheet.options.frozenlineColor = color;
    };

    const setRowVisible = (params: { rowIdx: number, isVisible: boolean, sheetName?: string }): void => {
        if(!spread){
            return;
        }

        const { rowIdx, isVisible, sheetName } = params;
        const sheet = getSheet(spread, sheetName);

        sheet.setRowVisible(rowIdx, isVisible);
    };

    const setColumnVisible = (params: { colIdx: number, isVisible: boolean, sheetName?: string }): void => {
        if(!spread){
            return;
        }

        const { colIdx, isVisible, sheetName } = params;
        const sheet = getSheet(spread, sheetName);

        sheet.setColumnVisible(colIdx, isVisible);
    };

    const getColumnCount = (params: { sheetName?: string }): number|undefined => {
        if(!spread){
            return;
        }

        const { sheetName } = params;
        const sheet = getSheet(spread, sheetName);

        return sheet.getColumnCount();
    };

    const getRowCount = (params: { sheetName?: string }): number|undefined => {
        if(!spread){
            return;
        }

        const { sheetName } = params;
        const sheet = getSheet(spread, sheetName);

        return sheet.getRowCount();
    };

    const setBorder = (params: { row: number, col: number, border: GC.Spread.Sheets.LineBorder, options: GC.Spread.Sheets.ISetBorderOptions, rowCount?: number, colCount?: number, sheetName?: string }) => {
        if(!spread){
            return;
        }

        const { row, col, rowCount, colCount, border, options, sheetName } = params;
        const sheet = getSheet(spread, sheetName);

        if((rowCount == 1 || !rowCount) && (colCount == 1 || !colCount)){
            sheet.getCell(row, col).setBorder(border, options);
        } else {
            sheet.getRange(row, col, rowCount, colCount).setBorder(border, options);
        }
    };

    const setRowOutlineGroups = (params: { firstRow: number, rowCount: number, sheetName?: string }) => {
        if(!spread){
            return;
        }

        const { firstRow, rowCount, sheetName } = params;
        const sheet = getSheet(spread, sheetName);

        try {
            sheet.rowOutlines.group(firstRow, rowCount);
        } catch (e) {
            console.error(e);
        }
    };

    const setOutlineGroupExpand = (params: { groupRow: number, isExpanded: boolean, level: number, sheetName?: string }) => {
        if(!spread){
            return;
        }

        const { groupRow, isExpanded, sheetName, level } = params;
        const sheet = getSheet(spread, sheetName);

        const rgi = sheet.rowOutlines.find(groupRow, level);
        if(rgi != null && rgi != undefined){
            sheet.rowOutlines.expandGroup(rgi, isExpanded);
        }
    };

    const setOutlineExpand = (params: { level: number, isExpanded: boolean, sheetName?: string }) => {
        if(!spread){
            return;
        }

        const { isExpanded, sheetName, level } = params;
        const sheet = getSheet(spread, sheetName);

        if(!sheet){
            return;
        }

        sheet.rowOutlines.expand(level, isExpanded);
    };

    const getOutlineMaxLevel = (params: { sheetName?: string }): number|undefined => {
        if(!spread){
            return;
        }

        const { sheetName } = params;
        const sheet = getSheet(spread, sheetName);

        return sheet.rowOutlines.getMaxLevel();
    };

    const suspendPaint = () => {
        if(!spread){
            return;
        }
        const sheet = getSheet(spread, undefined);

        sheet.suspendPaint();
    };

    const resumePaint = () => {
        if(!spread){
            return;
        }
        const sheet = getSheet(spread, undefined);

        sheet.resumePaint();
    };

    const setFormat = (params: {
        row: number,
        col: number,
        format: string,
        rowCount?: number, colCount?: number, sheetName?: string
    }) => {
        if(!spread){
            return;
        }

        const { row, col, format, rowCount, colCount, sheetName } = params;
        const sheet = getSheet(spread, sheetName);

        sheet.getRange(row, col, rowCount || 1, colCount || 1).formatter(format);
    };

    const addRows = (params: { row: number, count: number, sheetName?: string }) => {
        if(!spread){
            return;
        }

        const { row, count, sheetName } = params;
        const sheet = getSheet(spread, sheetName);

        sheet.addRows(row, count);
    };

    const setCellBgImageBase64 = (params: { row: number, col: number, b64Image: string, sheetName?: string }) => {
        if(!spread){
            return;
        }

        const { row, col, b64Image, sheetName } = params;
        const sheet = getSheet(spread, sheetName);

        sheet.getCell(row, col).backgroundImage(b64Image);
    };

    const directAccess = (cb: (spread: GC.Spread.Sheets.Workbook) => void) => {
        if(!spread){
            return;
        }
        suspend();
        cb(spread);
        resume();
    };

    const getSpread = ():GC.Spread.Sheets.Workbook|undefined => {
        if(!spread){
            return undefined;
        }
        return spread;
    };

    const download = (): string => {
        if(!spread){
            return '';
        }

        const sheet = getSheet(spread, undefined);

        return JSON.stringify(sheet.toJSON());
    };

    const addCellStateRange = (params: { row: number, col: number, rows: number, cols: number, state: string, style: GC.Spread.Sheets.Style, sheetName?: string }) => {
        if(!spread){
            return '';
        }

        const { row, col, rows, cols, state, style, sheetName } = params;
        const sheet = getSheet(spread, undefined);

        const range = new GC.Spread.Sheets.Range(row, col, rows, cols);

        const cellStates: Record<string, GC.Spread.Sheets.CellStatesType> = {
            hover: GC.Spread.Sheets.CellStatesType.hover,
            active: GC.Spread.Sheets.CellStatesType.active,
            selected: GC.Spread.Sheets.CellStatesType.selected,
            edit: GC.Spread.Sheets.CellStatesType.edit,
        };

        sheet.cellStates.add(range, cellStates[state] as GC.Spread.Sheets.CellStatesType, style);
    };

    const clearSelection = () => {
        if(!spread){
            return;
        }
        const sheet = getSheet(spread, undefined);

        sheet.clearSelection();

    };

    const lockAllCells = (params: { sheetName?: string } = {}) => {
        if(!spread){
            return;
        }

        const sheet = getSheet(spread, params.sheetName);
        sheet.getRange(0, 0, sheet.getRowCount(), sheet.getColumnCount()).locked(true);
    };

    useEffect(
            () => {
                if(spread){
                    handlers.onInit({
                        download,
                        setArrayFormula,
                        setArray,
                        setTemplate,
                        subscribe,
                        showRows,
                        setValue,
                        getColumnLabel,
                        setCellLocked,
                        setCellsLocked,
                        getValue,
                        setColumnWidth,
                        setRowHeight,
                        setStyle,
                        addSpan,
                        recalculate,
                        suspend: suspend,
                        resume: resume,
                        updateCustomCellType,
                        applyCustomCellType,
                        removeCustomCellType,
                        getCell,
                        toJSON,
                        setCellSelection,
                        setSingleRowColorAdvisor,
                        startCollectingCellUpdates,
                        setRowCount,
                        setColumnCount,
                        createOutlineColumn,
                        showRowOutline,
                        frozenRowCount,
                        frozenColumnCount,
                        setFrozenLineColor,
                        setRowVisible,
                        setColumnVisible,
                        getColumnCount,
                        getRowCount,
                        setBorder,
                        setRowOutlineGroups,
                        setOutlineGroupExpand,
                        setOutlineExpand,
                        getOutlineMaxLevel,
                        suspendPaint,
                        resumePaint,
                        setFormat,
                        addRows,
                        setActiveSheetIndex,
                        getActiveSheetIndex,
                        getSheetIndex,
                        updateSpreadsheetViewPort: updateSpreadSheetViewPortExposed,
                        setCellBgImageBase64,
                        directAccess,
                        getSpread,
                        addCellStateRange,
                        clearSelection,
                        lockAllCells,
                    });
                    if(subscribeToMouse){
                        spread.getHost().addEventListener("mousemove", mouseMoveHandler);
                        spread.getHost().addEventListener("mouseup", mouseUpHandler);
                    }
                }

                return () => {
                    handlers.onDeinit();
                    if(!spread){
                        return;
                    }

                    if(subscribeToMouse){
                        spread.getHost().removeEventListener("mousemove", mouseMoveHandler);
                        spread.getHost().removeEventListener("mouseup", mouseUpHandler);
                    }
                };
            },
            [spread]
    );

    const combinedHostStyle = {
        ...hostStyle,
        ...style
    };

    return (
            <SpreadSheets key="newspreadhseet" tabNavigationVisible={false} newTabVisible={false}
                          tabStripVisible={false}
                          backColor={"#FFFFFF"} hostStyle={combinedHostStyle as unknown as ElementCSSInlineStyle}
                          workbookInitialized={workbookInit}>
                <Worksheet autoGenerateColumns={false}>
                </Worksheet>
            </SpreadSheets>
    );
};
