import * as css from "./styles/css.module.scss";
import { ReactElement } from "react";
import { useBudgetNoteData } from "./data/useBudgetNoteData";

export interface BudgetNoteProps {
    propertyId: string;
    accountId: string;
    year: number;
}

export function BudgetNote(props: BudgetNoteProps): ReactElement {
    const content = useBudgetNoteData({
        propertyId: props.propertyId,
        accountId: props.accountId,
        year: props.year
    });
    return (
        <div className={css.container}>
            <div className={css.header}>
                <span className={css.headerText}>
                    Budget Note
                </span>
            </div>
            <div className={css.body}>
                <div className={css.content}>
                    <span className={css.contentText}>
                        {content.text}
                    </span>
                </div>
            </div>
        </div>
    );
}