import * as css from "./styles/css.module.scss";
import { ReactElement } from "react";

export enum PillType {
    LINE_ITEMS,
    MANUAL_ENTRY,
    MIXED_METHODS,
    OP_DRIVER,
    OVERRIDE,
    PAYROLL,
    PCT_OF_ACCOUNT,
    RENOVATION_COSTS,
    REVENUE
}

export interface ModelingMethodPillProps {
    pillType: PillType;
}

export function ModelingMethodPill(props: ModelingMethodPillProps): ReactElement {
    let pillContent = <>unknown</>;
    if (props.pillType == PillType.OP_DRIVER) {
        pillContent = <div className={css.opDriver}>Op Driver</div>;
    }
    else if (props.pillType == PillType.PCT_OF_ACCOUNT) {
        pillContent = <div className={css.pctOfAccount}>% of Account</div>;
    }
    else if (props.pillType == PillType.LINE_ITEMS) {
        pillContent = <div className={css.lineItems}>Line Items</div>;
    }
    else if (props.pillType == PillType.MANUAL_ENTRY) {
        pillContent = <div className={css.manualEntry}>Manual Entry</div>;
    }
    else if (props.pillType == PillType.OVERRIDE) {
        pillContent = <div className={css.override}>Override</div>;
    }
    else if (props.pillType == PillType.PAYROLL) {
        pillContent = <div className={css.payroll}>Payroll</div>;
    }
    else if (props.pillType == PillType.RENOVATION_COSTS) {
        pillContent = <div className={css.renovationCosts}>Renovation Costs</div>;
    }
    else if (props.pillType == PillType.MIXED_METHODS) {
        pillContent = <div className={css.mixedMethods}>Mixed Methods</div>;
    }
    else if (props.pillType == PillType.REVENUE) {
        pillContent = <div className={css.revenue}>Revenue</div>;
    }
    return pillContent;
}