import lockLocked from "../icons/lock.svg";
import lockUnlocked from "../icons/lock_unlocked.svg";

const imageLocked = new Image();
imageLocked.src = lockLocked;

const imageUnlocked = new Image();
imageUnlocked.src = lockUnlocked;

const ICON_SIZE = 12;
const ICON_PADDING = -8;

export function drawLock(ctx: CanvasRenderingContext2D, x: number, y: number, w: number, h: number, locked = true): void {
    if (locked) {
        ctx.drawImage(imageLocked, x + w - ICON_PADDING - ICON_SIZE, y + h / 2 - ICON_SIZE / 2, ICON_SIZE, ICON_SIZE);
    }
    else {
        ctx.drawImage(imageUnlocked, x + w - ICON_PADDING - ICON_SIZE, y + h / 2 - ICON_SIZE / 2, ICON_SIZE, ICON_SIZE);
    }
}
