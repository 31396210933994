import React, { useEffect, useState } from 'react';
import {
    useGetInstructionQuery,
} from '../../__generated__/generated_types';

import { Button, IconButton } from "@zendeskgarden/react-buttons";
import {ReactComponent as InfoStroke} from '@zendeskgarden/svg-icons/src/16/info-stroke.svg';

import styles from "./AccountInstruction.module.scss";

import {Modal as ReactModal} from "react-bootstrap";
import { Tooltip } from "@zendeskgarden/react-tooltips";

interface AccountInstructionProps {
    accountId: string
    versionId: string
}

export const AccountInstruction: React.VFC<AccountInstructionProps> = (props) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isModalCloseStart, setIsModalCloseStart] = useState(false);

    const { data: instruction } = useGetInstructionQuery({
        variables: {
            accountId: props.accountId,
            versionId: props.versionId
        },
        fetchPolicy: "cache-first" // first fetch the note from cache, if it doesn't exist, fetch from server
    });

    const [instructionTextValue, setInstructionTextValue] = useState<string>("");

    useEffect(
        () => {
            if (instruction && instruction.getInstructionByAccountIdAndValueVersionId?.text) {
                setInstructionTextValue(instruction.getInstructionByAccountIdAndValueVersionId.text);
            }
            else {
                // APP2-198
                setInstructionTextValue("");
            }
        }
        ,[instruction]
    );

    const handleOpenModal = () => {
        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        setIsModalCloseStart(true);
        setTimeout(() => {
            setIsModalOpen(false);
            setIsModalCloseStart(false);
        }, 400);
    };

    if (instructionTextValue === "") {
        return <></>;
    }

    return (
        <div>
            <Tooltip content="Instructions">
                <IconButton
                        aria-label="Instructions"
                        onClick={() => {
                            handleOpenModal();
                        }}
                >
                    <InfoStroke />
                </IconButton>
            </Tooltip>

            <ReactModal
                show={isModalOpen}
                animation={false}
                backdropClassName={styles.viziblyAccountInstructionsDrawerBackdrop}
                dialogClassName={`${styles.viziblyAccountInstructionsDrawerDialog} ${isModalCloseStart ? 'slide-out-right' : 'slide-in-right'}`}
                contentClassName={styles.viziblyAccountInstructionsDrawerContent}
                onHide={() => handleCloseModal()}
            >
                <ReactModal.Body>
                    <div className={styles.contentBody}>
                        <div className={styles.header}>
                            <div className={styles.headerTextContainer}>
                                <InfoStroke className={styles.headerTextIcon} />
                                <div className={styles.headerText}>
                                    Instructions
                                </div>
                            </div>
                            <button className={styles.closeButton + " btn btn-close"}
                                    onClick={() => handleCloseModal()}></button>
                        </div>
                        <div className={styles.instructionText}>
                            {instructionTextValue}
                        </div>
                    </div>
                </ReactModal.Body>
            </ReactModal>
        </div>
    );
};
