/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React from 'react';
import Footer from '../footer/Footer';
import SideBar from '../sidebar/SideBar';
import {TreeNode} from "../tree/Tree";
import ReadOnlyHeader from "../navbar/ReadOnlyHeader";
import { RenderIfReadOnly } from "../../authorization/RenderIfAuthorized";

interface ISkeletonProps {
    children?: any;
    accounts: any;
    renovationsWorkflow: Array<TreeNode>|undefined;
    isPayrollAvailableForCurrentUser: boolean | undefined;
    /**
     * @description This function expects an `onNextClick` funtion to move through the accounts and operations pages on the footer.
     */
    onNextClick: () => void;

    /**
    * @description This function expects an `onPreviousClick` funtion to move through the accounts and operations pages on the footer.
    */
    onPreviousClick: () => void;
    hideFooter?: boolean;
}

function Skeleton(props: ISkeletonProps) {
    const {
        accounts,
        renovationsWorkflow,
        isPayrollAvailableForCurrentUser,
        children,
        onNextClick,
        onPreviousClick,
        hideFooter,
    } = props;

    return (
        <React.Fragment>
            <div className='vz-wrapper'>
                {
                    accounts && renovationsWorkflow && isPayrollAvailableForCurrentUser !== undefined &&
                    <SideBar
                        renovationsWorkflow={renovationsWorkflow}
                        isPayrollAvailableForCurrentUser={isPayrollAvailableForCurrentUser}
                    />
                }
                <div className='vz-container'>
                    <div className='vz-content'>
                        <div className="d-flex flex-column w-100" style={{ height: '100%', minHeight: `calc(100vh - 70px)`}}>
                            {children}
                        </div>
                    </div>
                </div>
                { !hideFooter &&
                        <Footer backText='Previous' nextText='Next' needGoBack={onPreviousClick} needGoNext={onNextClick} />
                }
            </div>
        </React.Fragment>
    );
}

export default Skeleton;
