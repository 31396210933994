import {
    GetAccountVarianceInsightsQuery,
    useGetAccountVarianceInsightsLazyQuery
} from "../../../../../__generated__/generated_types";

export interface IUseAccountVarianceInsightsReturn {
    fetch: (accountId: string, propertyId: string, year: number, month: number) => void;
    loading: boolean;
    data: GetAccountVarianceInsightsQuery|undefined;
}

export default function useAccountVarianceInsights(): IUseAccountVarianceInsightsReturn {
    const [
        _fetch,
        {
            loading,
            data,
        }
    ] = useGetAccountVarianceInsightsLazyQuery({
        fetchPolicy: 'network-only',
    });

    const fetch = (accountId: string, propertyId: string, year: number, month: number): void => {
        _fetch({
            variables: {
                accountId,
                propertyId,
                year,
                month,
            }
        });
    };

    return {
        fetch,
        loading,
        data,
    };
}
