import React, { CSSProperties, ReactElement, useEffect, useState } from 'react';
import {
    StarredAccountMutationArgs, useGetStarredAccountsLazyQuery,
    useUpsertStarredAccountsMutation
} from '../../__generated__/generated_types';
import {ReactComponent as StarStroke} from '@zendeskgarden/svg-icons/src/16/star-stroke.svg';
import {ReactComponent as StarFill} from '@zendeskgarden/svg-icons/src/16/star-fill.svg';
import styles from "./StarredAccount.module.scss";
import {useConfig} from "../../hooks/useConfig";

interface StarredAccountProps {
    accountId: string
    versionIds: string[]
    currentVersionId: string
    style?: CSSProperties
}

export default function StarredAccount(props: StarredAccountProps):ReactElement {

    const config = useConfig();

    const [
        getStarredAccounts,
        {data: starredAccountsData, loading: starredAccountsDataLoading, error: starredAccountsDataError}
    ] = useGetStarredAccountsLazyQuery({fetchPolicy: "no-cache"});

    const [upsertStarredAccounts] = useUpsertStarredAccountsMutation({
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        onCompleted: () => {
            getStarredAccounts({
                variables: {
                    propertyId: config.properties.currentProperty?.id ?? "",
                    accountId: props.accountId,
                    versionIds: props.versionIds
                }
            });
        },
    });

    const [isStarred, setIsStarred] = useState<boolean>(false);

    useEffect(
        () => {
            if (starredAccountsDataLoading || starredAccountsDataError || !starredAccountsData) {
                return;
            }
            if (starredAccountsData.getStarredAccountByPropertyIdAndAccountIdAndVersionIds &&
                starredAccountsData.getStarredAccountByPropertyIdAndAccountIdAndVersionIds.length > 0) {
                const allVersionedAccounts = starredAccountsData.getStarredAccountByPropertyIdAndAccountIdAndVersionIds;
                const currentVersionedAccount = allVersionedAccounts.find(v => v.version.id === props.currentVersionId);
                setIsStarred(currentVersionedAccount?.isStarred ?? false);
            } else {
                setIsStarred(false);
            }
        }, [starredAccountsData, starredAccountsDataLoading, starredAccountsDataError]
    );

    useEffect(() => {
        if(!props.accountId || !props.versionIds || !config.isReady){
            return;
        }
        const propertyId = config.properties.currentProperty?.id ?? "";
        if (props.accountId && props.versionIds && props.versionIds.length > 0 && propertyId && !starredAccountsDataLoading) { // the latter is attempt to reduce picking of backend with query
            getStarredAccounts({
                variables: {
                    propertyId,
                    accountId: props.accountId,
                    versionIds: props.versionIds
                }
            });
        }
    }, [props.accountId, props.versionIds, config.isReady]);

    const onStarAccountToggle = (toStar: boolean) => {
        const payload: StarredAccountMutationArgs[] = props.versionIds.map(versionId => {
            const payload: StarredAccountMutationArgs = {
                propertyId: config.properties.currentProperty?.id ?? "",
                accountId: props.accountId,
                versionId: versionId,
                isStarred: toStar
            };
            return payload;
        });
        upsertStarredAccounts({
            variables: {
                data: payload
            }
        });
    };

    return (
        <div style={props.style} className={styles.starIconContainer}>
            {
                <>
                    <StarFill
                            style={isStarred ? {} : {display: 'none'}}
                            onClick={() => {
                                onStarAccountToggle(false);
                            }}
                    />
                    <StarStroke
                            style={isStarred ? {display: 'none'} : {}}
                            onClick={() => {
                                onStarAccountToggle(true);
                            }}
                    />
                </>
            }
        </div>
    );
}
