import React, { Children, ReactElement, cloneElement } from "react";

import * as css from "./styles/css.module.scss";

interface IActionsArrayProps {
    children: ReactElement | ReactElement[],
    disabled: boolean,
    className?: string,
}

export default function ActionsArray(props: IActionsArrayProps):ReactElement {
    const modifiedChildren = Children.map( props.children, child => {
        return cloneElement(child, {disabled: props.disabled}, child.props.children);
    });

    return (
            <div className={props.className ? `${css.actionsArray} ${props.className}` : css.actionsArray}>
                {modifiedChildren}
            </div>
    );
}
