/* eslint-disable @typescript-eslint/no-empty-function */
import React, { ReactElement, useState } from "react";
import ShimmerRenderer, {ShimmerPageKey} from "../../../components/shimmer/ShimmerRenderer";
import SummarySpread from "./sjs/SummarySpread";

function Summary({setLoadProgress}: {setLoadProgress: React.Dispatch<React.SetStateAction<number>>}): ReactElement {
    const [viewReady, setViewReady] = useState<boolean>(false);
    const [dataReady, setDataReady] = useState<boolean>(false);

    return (
        <>
            {(!viewReady || !dataReady) &&
                <div>
                    <ShimmerRenderer pageKey={ShimmerPageKey.ANALYST_VIEW}/>
                </div>}
            <div
                style={{
                    ...(
                        (!viewReady) ? { display: 'none' } : {}
                    )
                }}>
                <SummarySpread
                    setViewReady={setViewReady}
                    setDataReady={setDataReady}
                    setLoadProgress={setLoadProgress}
                />
            </div>
        </>
    );
}

export default Summary;
