import { ReactElement, ReactNode, useContext } from "react";
import { Authorizations } from "./Authorizations";
import { AuthContext } from "../contexts/AuthContext";
import { checkAuthorization, userIsReadOnly } from "./AuthorizationCheckers";
import { UserRole } from "../__generated__/generated_types";

export function RenderIfAuthorized(props: { required: Authorizations, children: ReactNode | ReactNode[] }): ReactElement | null {
    const { user } = useContext(AuthContext);

    if (!checkAuthorization(user, props.required)) {
        return null;
    }

    return (
        <>
            {props.children}
        </>
    );
}

export function RenderIfReadOnly(props: { children: ReactNode | ReactNode[] }): ReactElement | null {
    const { user } = useContext(AuthContext);

    if (!user) {
        return null;
    }

    if (userIsReadOnly(user)) {
        return (<>
            {props.children}
        </>);
    }

    return null;
}

export function RenderIfNotReadOnly(props: { children: ReactNode | ReactNode[] }): ReactElement | null {
    const { user } = useContext(AuthContext);

    if (!user) {
        return null;
    }

    if (user.role === UserRole.Admin || !user.isReadOnly) {
        return (<>
            {props.children}
        </>);
    }

    return null;
}
