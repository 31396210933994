export function buildItemsStatsString(page: number, totalItems: number, itemsPerPage: number): string {
    if (totalItems == 0) {
        return "0 OF 0";
    }

    const firstReturnedItemNumber = (page - 1)  * itemsPerPage + 1;
    let lastReturnedItemNumber = page * itemsPerPage;
    if (lastReturnedItemNumber > totalItems) {
        lastReturnedItemNumber = totalItems;
    }

    return `${firstReturnedItemNumber} - ${lastReturnedItemNumber} OF ${totalItems}`;
}