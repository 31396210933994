import {ReactElement, useState} from "react";
import {useClassicToRenovatedAssociationsData} from "./useClassicToRenovatedAssociationsData";
import {Property} from "../../../../contexts/properties/PropertiesContext";
import * as tableCSS from "../../../../components/account-table/styles/accountTable.module.scss";
import * as css from "../../styles/OpDriverModeling.module.scss";
import {useRemoveUnitTypeRenovationMapMutation, useUpdateUnitTypeRenovationMapMutation} from "../../../../__generated__/generated_types";
import SimplifiedRevAnalystRenovationDrawer from "../../../../components/analyst-renovations-drawers/SimplifiedRevAnalystRenovationDrawer";
import { Tag } from "@zendeskgarden/react-tags";
import { TSimplifiedUnitType } from "../../Renovations";
import { toast } from "react-toastify";
import { Button } from "@zendeskgarden/react-buttons";

export interface IClassicToRenovatedAssociationsProps {
    property: Property,
    unitTypes: TSimplifiedUnitType[],
    refetchSimplifiedData?: () => void,
}

interface IDrawerProps {
    show: boolean,
    propertyId: string,
    unitTypeId: string,
}

export default function ClassicToRenovatedAssociations({
    property, unitTypes, refetchSimplifiedData,
}: IClassicToRenovatedAssociationsProps): ReactElement {
    const data = useClassicToRenovatedAssociationsData({property, unitTypes: unitTypes.filter(x => !x.isCustom)});
    const [drawerProps, setDrawerProps] = useState<IDrawerProps>({
        show: false,
        propertyId: "", // just following data contract of the drawer
        unitTypeId: "",
    });

    const [updateUnitMapping] = useUpdateUnitTypeRenovationMapMutation({
        notifyOnNetworkStatusChange: true,
    });
    const [removeUnitMapping] = useRemoveUnitTypeRenovationMapMutation({
        notifyOnNetworkStatusChange: true,
    });

    const openRenovationDrawer = (propertyId: string, unitTypeId: string) => {
        setDrawerProps({
            show: true,
            propertyId: propertyId,
            unitTypeId: unitTypeId
        });
    };

    function updateMaps(unitType: TSimplifiedUnitType, renovatedUnitTypeId: string, renovationPackageId: string) {
        let setData;

        if (unitType.isCustom && marketRent != undefined) {
            setData = {
                fakeUnitTypeName: fakeUnitName,
                renovationPackageId,
                fakeUnitTypeStartingInPlaceRent: parseInt(String(marketRent)),
            };
        } else {
            setData = {
                renovatedUnitTypeId,
                renovationPackageId,
            };
        }

        updateUnitMapping({
            variables: {
                propertyId: property.id,
                budgetYear: property.budgetYear,
                setData,
            }
        }).then(() => {
            toast.success("Unit Type Mapping Updated");
            data.refetch();

            if (refetchSimplifiedData) {
                refetchSimplifiedData();
            }

            if (unitType.isCustom) {
                setFakeUnitName(fakeUnitName);
                setMarketRent(marketRent ?? 0);
                setPrevFakeUnitName(fakeUnitName);
                setPrevMarketRent(marketRent ?? 0);
            } else {
                setSelectedRenoUnitType(selectedRenoUnitType);
                setPrevSelectedSelectedRenoUnitType(selectedRenoUnitType);
            }
        });
    }

    function removeMaps(renovationPackageId: string) {
        removeUnitMapping({
            variables: {
                propertyId: property.id,
                budgetYear: property.budgetYear,
                renovationPackageId: renovationPackageId
            }
        }).then(() => {
            data.refetch();

            if (refetchSimplifiedData) {
                refetchSimplifiedData();
            }

            setSelectedClassicUnitType(undefined);
            setFakeUnitName("");
            setMarketRent(0);
            setPrevFakeUnitName("");
            setPrevMarketRent(0);
            setSelectedRenoUnitType(undefined);
            setPrevSelectedSelectedRenoUnitType(undefined);
        });
    }

    const closeRenovationDrawer = () => {
        setSelectedClassicUnitType(undefined);
        setFakeUnitName("");
        setMarketRent(0);
        setPrevFakeUnitName("");
        setPrevMarketRent(0);
        setSelectedRenoUnitType(undefined);
        setPrevSelectedSelectedRenoUnitType(undefined);

        setDrawerProps(prev => ({
            ...prev,
            show: false
        }));
    };

    const [selectedClassicUnitType, setSelectedClassicUnitType] = useState<TSimplifiedUnitType | undefined>(unitTypes[0]);
    const [prevSelectedRenoUnitType, setPrevSelectedSelectedRenoUnitType] = useState<TSimplifiedUnitType | undefined>(unitTypes[0]);
    const [selectedRenoUnitType, setSelectedRenoUnitType] = useState<TSimplifiedUnitType | undefined>(unitTypes[0]);
    const [fakeUnitName, setFakeUnitName] = useState<string>("");
    const [marketRent, setMarketRent] = useState<number>(0);
    const [prevFakeUnitName, setPrevFakeUnitName] = useState<string>("");
    const [prevMarketRent, setPrevMarketRent] = useState<number>(0);
    const thClass = `${tableCSS.cellBase} ${tableCSS.cellBgGrey} ${tableCSS.tallCell} ${tableCSS.alignLeft}`;
    const tdClass = `${tableCSS.cellBase} ${tableCSS.alignLeft}`;

    return (
        <div style={{maxHeight: 500, overflow: 'auto'}}>
            <table className={tableCSS.accountTable} style={{borderCollapse: "collapse", tableLayout: "fixed"}}>
                <thead>
                <tr style={{"position": "sticky", top: 0, zIndex: 10, boxShadow: "0 0 1px #dddddd"}}>
                        <th className={thClass}>Classic Unit Type</th>
                        <th className={thClass}>Renovated Unit Type</th>
                        <th className={thClass}>Package Name</th>
                    </tr>
                </thead>

                <tbody>
                    {data.rows.map(d => (
                        <tr key={d.unitTypeId}>
                            <td className={`${tdClass} ${css.renovationsClassicColumn}`} onClick={() => {
                                const unit = unitTypes.find(x => x.type == d.renovatedUnitTypeName);

                                setSelectedClassicUnitType({id: d.unitTypeId, type: d.unitTypeName, count: 0});

                                if (unit && unit.isCustom) {
                                    setFakeUnitName(unit.type);
                                    setMarketRent(unit.startingMarketRent ?? 0);
                                    setPrevFakeUnitName(unit.type);
                                    setPrevMarketRent(unit.startingMarketRent ?? 0);
                                }

                                setSelectedRenoUnitType(unit);
                                setPrevSelectedSelectedRenoUnitType(unit);
                                openRenovationDrawer(property.id, d.unitTypeId);
                            }}>
                                {d.unitTypeName}
                                <Button className={css.renovationColumnEditButton} isBasic size={"small"}>Set Up</Button>
                            </td>
                            <td className={tdClass}>
                                {d.renovatedUnitTypeName ?? ""}
                                {unitTypes.find(x => x.isCustom && x.type == d.renovatedUnitTypeName) &&
                                    <Tag size="small" style={{"float": "right"}}>Custom Unit Type</Tag>
                                }
                            </td>
                            <td className={tdClass}>
                                {d.packageName ?? ""}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>

            {selectedClassicUnitType &&
                <SimplifiedRevAnalystRenovationDrawer
                    show={drawerProps.show}
                    propertyId={drawerProps.propertyId}
                    unitTypeId={selectedClassicUnitType.id}
                    handleClose={() => {
                        closeRenovationDrawer();
                    }}
                    simplifiedRevenueModeProps={{
                        setRenovationAssociation: updateMaps,
                        removeRenovationAssociation: removeMaps,
                        setSelectedRenoUnitType: setSelectedRenoUnitType,
                        setMarketRent,
                        setFakeUnitName,
                        marketRent,
                        prevMarketRent,
                        fakeUnitName,
                        prevFakeUnitName,
                        budgetYear: property.budgetYear,
                        unitTypes: unitTypes,
                        prevSelectedRenoUnitType: prevSelectedRenoUnitType,
                        selectableAsRenovatedUnitTypes: data.unmappedUnitTypes,
                        selectedClassicUnitType: selectedClassicUnitType,
                        selectedRenovatedUnitType: selectedRenoUnitType ? selectedRenoUnitType : {id: "placeholder", type: "Select Unit Type", count: 0},
                    }}
                />
            }
        </div>
    );
}