import {createContext, ReactElement, useEffect, useState} from "react";
import mixpanel from "mixpanel-browser";
import {MIX_PANEL_PROJECT_ID} from "../constants/MixPanel";

type MixPanelUser = {
    id: string,
    clientId: string,
    clientName: string,
    firstName: string,
    lastName: string,
    email: string,
}

type TMixPanelContext = {
    initialized: boolean
}

export const MixPanelContext = createContext<TMixPanelContext>({initialized:false})

export function MixPanelWrapper(props: {children: ReactElement | ReactElement[], user: MixPanelUser | null}): ReactElement {
    const [initialized, setInitialized] = useState<boolean>(false);

    useEffect(() => {
        mixpanel.init(MIX_PANEL_PROJECT_ID);
        setInitialized(true);
    }, []);

    useEffect(() => {
        if(!initialized) {
            return;
        }

        const user = props.user;
        if (user !== null) {
            mixpanel.identify(user.id);
            mixpanel.people.set({
                "$name": `${user.firstName} ${user.lastName}`,
                "$email": user.email,
                "companyId": user.clientId,
                "company": user.clientName
            });
            return () => {
                mixpanel.reset();
            };
        }
    }, [initialized, props.user]);


    return <MixPanelContext.Provider value={{initialized}}>{props.children}</MixPanelContext.Provider>;
}