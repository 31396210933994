/* eslint-disable @typescript-eslint/no-namespace */
import GC from "@grapecity/spread-sheets";
import { COLORS } from "../../../constants/Colors";
import {AnalystStyles} from "../../../pages/analyst/AnalystStyles";

import dummyBgPackageNameValueImg from "../../../assets/images/analyst/renovations/renovation_drawer_dummy_bg_pacakge_name_value.png";
import dummyBgAmountLabelImg from "../../../assets/images/analyst/renovations/renovation_drawer_dummy_bg_amount_label.png";
import dummyBgDelayRecognitionLabelImg from "../../../assets/images/analyst/renovations/renovation_drawer_dummy_bg_delay_recognitions_label.png";
import dummyBgAmountValueImg from "../../../assets/images/analyst/renovations/renovation_drawer_dummy_bg_amount_value.png";
import dummyBgTotalAmountValueImg from "../../../assets/images/analyst/renovations/renovation_drawer_dummy_bg_total_amount_value.png";
import dummyBgDelayRecognitionValueImg from "../../../assets/images/analyst/renovations/renovation_drawer_dummy_bg_delay_recognitions_value.png";

const VerticalAlign = GC.Spread.Sheets.VerticalAlign;
const CELL_BORDER = new GC.Spread.Sheets.LineBorder(AnalystStyles.CELL_BORDER_COLOR, GC.Spread.Sheets.LineStyle.thin);
const CELL_BG_COLOR = "#F7F8F9";
const CELL_EDITABLE_VALUE_COLOR = "#0D666D";
const AMOUNT_FORMAT = "$0,0.00";
const COMMON_CELL_STYLE = new GC.Spread.Sheets.Style();
COMMON_CELL_STYLE.font = "600 12px Inter";
COMMON_CELL_STYLE.locked = true;
COMMON_CELL_STYLE.foreColor = COLORS.GREY_600;
COMMON_CELL_STYLE.vAlign = VerticalAlign.center;
COMMON_CELL_STYLE.hAlign = GC.Spread.Sheets.HorizontalAlign.left;
COMMON_CELL_STYLE.cellPadding = "0 0 0 10";

const COMMON_WITH_BG_CELL_STYLE = COMMON_CELL_STYLE.clone();
COMMON_WITH_BG_CELL_STYLE.backColor = CELL_BG_COLOR;

export namespace RenovationPackageDrawerStyles {
    export const CELL_BORDER_COLOR = AnalystStyles.CELL_BORDER_COLOR;
    export const HEADER_ROW_HEIGHT = 44;
    export const SUB_HEADER_ROW_HEIGHT = 44;
    export const DATA_ROW_HEIGHT = 48;
    export const COST_CATEGORY_COL_WIDTH = 186;
    export const GL_COL_WIDTH = 178;
    export const AMOUNT_COL_WIDTH = 87 + 25; // Adding 25 extra as the figma did not have the decimal amounts
    export const DELAY_RECOGNITION_COL_WIDTH = 181;
    export const TOTAL_LABEL_ROW_HEIGHT = 44;
    export const PREMIUM_LABEL_ROW_HEIGHT = 48;

    export const RENOVATION_PACKAGE_LABEL_CELL_STYLE = COMMON_CELL_STYLE.clone();

    export const COST_CATEGORY_LABEL_CELL_STYLE = COMMON_WITH_BG_CELL_STYLE.clone();

    export const GL_LABEL_CELL_STYLE = COMMON_WITH_BG_CELL_STYLE.clone();

    export const COST_CATEGORY_VALUE_CELL_STYLE = COMMON_CELL_STYLE.clone();
    COST_CATEGORY_VALUE_CELL_STYLE.font = "500 12px Inter"; // Applying this from figma does not produce the same effect as in figma
    COST_CATEGORY_VALUE_CELL_STYLE.foreColor = COLORS.GREY_800;

    export const GL_VALUE_CELL_STYLE = COMMON_CELL_STYLE.clone();
    GL_VALUE_CELL_STYLE.font = "500 12px Inter";
    GL_VALUE_CELL_STYLE.foreColor = "#858D9D";
    GL_VALUE_CELL_STYLE.wordWrap = true;
    GL_VALUE_CELL_STYLE.cellPadding = "4px";

    export const TOTAL_LABEL_CELL_STYLE = COMMON_WITH_BG_CELL_STYLE.clone();

    export const PREMIUM_LABEL_CELL_STYLE = COMMON_CELL_STYLE.clone();
    PREMIUM_LABEL_CELL_STYLE.borderBottom = CELL_BORDER;

    export const PACKAGE_NAME_VALUE_DUMMY_CELL_STYLE = COMMON_CELL_STYLE.clone();
    PACKAGE_NAME_VALUE_DUMMY_CELL_STYLE.backgroundImage = dummyBgPackageNameValueImg;
    PACKAGE_NAME_VALUE_DUMMY_CELL_STYLE.backgroundImageLayout = GC.Spread.Sheets.ImageLayout.center;

    export const PACKAGE_REMOVE_ACTION_DUMMY_CELL_STYLE = COMMON_CELL_STYLE.clone();
    PACKAGE_REMOVE_ACTION_DUMMY_CELL_STYLE.borderRight = CELL_BORDER;

    export const AMOUNT_LABEL_DUMMY_CELL_STYLE = COMMON_WITH_BG_CELL_STYLE.clone();
    AMOUNT_LABEL_DUMMY_CELL_STYLE.backgroundImage = dummyBgAmountLabelImg;
    AMOUNT_LABEL_DUMMY_CELL_STYLE.backgroundImageLayout = GC.Spread.Sheets.ImageLayout.center;

    export const DELAY_RECOGNITION_LABEL_DUMMY_CELL_STYLE = COMMON_WITH_BG_CELL_STYLE.clone();
    DELAY_RECOGNITION_LABEL_DUMMY_CELL_STYLE.backgroundImage = dummyBgDelayRecognitionLabelImg;
    DELAY_RECOGNITION_LABEL_DUMMY_CELL_STYLE.backgroundImageLayout = GC.Spread.Sheets.ImageLayout.center;
    DELAY_RECOGNITION_LABEL_DUMMY_CELL_STYLE.borderRight = CELL_BORDER;

    export const AMOUNT_VALUE_DUMMY_CELL_STYLE = COMMON_CELL_STYLE.clone();
    AMOUNT_VALUE_DUMMY_CELL_STYLE.backgroundImage = dummyBgAmountValueImg;
    AMOUNT_VALUE_DUMMY_CELL_STYLE.backgroundImageLayout = GC.Spread.Sheets.ImageLayout.center;
    AMOUNT_VALUE_DUMMY_CELL_STYLE.formatter = undefined;

    export const DELAY_RECOGNITION_VALUE_DUMMY_CELL_STYLE = COMMON_CELL_STYLE.clone();
    DELAY_RECOGNITION_VALUE_DUMMY_CELL_STYLE.backgroundImage = dummyBgDelayRecognitionValueImg;
    DELAY_RECOGNITION_VALUE_DUMMY_CELL_STYLE.backgroundImageLayout = GC.Spread.Sheets.ImageLayout.center;
    DELAY_RECOGNITION_VALUE_DUMMY_CELL_STYLE.formatter = undefined;
    DELAY_RECOGNITION_VALUE_DUMMY_CELL_STYLE.borderRight = CELL_BORDER;

    export const TOTAL_AMOUNT_VALUE_DUMMY_CELL_STYLE = AMOUNT_VALUE_DUMMY_CELL_STYLE.clone();
    TOTAL_AMOUNT_VALUE_DUMMY_CELL_STYLE.backColor = CELL_BG_COLOR;
    TOTAL_AMOUNT_VALUE_DUMMY_CELL_STYLE.backgroundImage = dummyBgTotalAmountValueImg;

    export const TOTAL_DELAY_RECOGNITION_VALUE_DUMMY_CELL_STYLE = COMMON_WITH_BG_CELL_STYLE.clone();
    TOTAL_DELAY_RECOGNITION_VALUE_DUMMY_CELL_STYLE.borderRight = CELL_BORDER;

    export const PREMIUM_VALUE_DUMMY_CELL_STYLE = AMOUNT_VALUE_DUMMY_CELL_STYLE.clone();
    PREMIUM_VALUE_DUMMY_CELL_STYLE.borderBottom = CELL_BORDER;

    export const PREMIUM_DELAY_RECOGNITION_VALUE_DUMMY_CELL_STYLE = DELAY_RECOGNITION_VALUE_DUMMY_CELL_STYLE.clone();
    PREMIUM_DELAY_RECOGNITION_VALUE_DUMMY_CELL_STYLE.borderBottom = CELL_BORDER;

    export const PACKAGE_NAME_VALUE_CELL_STYLE = COMMON_CELL_STYLE.clone();
    PACKAGE_NAME_VALUE_CELL_STYLE.locked = false;
    PACKAGE_NAME_VALUE_CELL_STYLE.foreColor = CELL_EDITABLE_VALUE_COLOR;

    export const PACKAGE_REMOVE_ACTION_CELL_STYLE = COMMON_CELL_STYLE.clone();
    PACKAGE_REMOVE_ACTION_CELL_STYLE.borderRight = CELL_BORDER;

    export const AMOUNT_LABEL_CELL_STYLE = COMMON_WITH_BG_CELL_STYLE.clone();

    export const DELAY_RECOGNITION_LABEL_CELL_STYLE = COMMON_WITH_BG_CELL_STYLE.clone();
    DELAY_RECOGNITION_LABEL_CELL_STYLE.borderRight = CELL_BORDER;

    export const AMOUNT_VALUE_CELL_STYLE = COMMON_CELL_STYLE.clone();
    AMOUNT_VALUE_CELL_STYLE.locked = false;
    AMOUNT_VALUE_CELL_STYLE.font = "500 12px Inter";
    AMOUNT_VALUE_CELL_STYLE.foreColor = CELL_EDITABLE_VALUE_COLOR;
    AMOUNT_VALUE_CELL_STYLE.formatter = AMOUNT_FORMAT;

    export const DELAY_RECOGNITION_VALUE_CELL_STYLE = COMMON_CELL_STYLE.clone();
    DELAY_RECOGNITION_VALUE_CELL_STYLE.locked = false;
    DELAY_RECOGNITION_VALUE_CELL_STYLE.font = "500 12px Inter";
    DELAY_RECOGNITION_VALUE_CELL_STYLE.foreColor = CELL_EDITABLE_VALUE_COLOR;
    DELAY_RECOGNITION_VALUE_CELL_STYLE.borderRight = CELL_BORDER;

    export const TOTAL_AMOUNT_VALUE_CELL_STYLE = COMMON_WITH_BG_CELL_STYLE.clone();
    TOTAL_AMOUNT_VALUE_CELL_STYLE.formatter = AMOUNT_FORMAT;

    export const TOTAL_DELAY_RECOGNITION_VALUE_CELL_STYLE = COMMON_WITH_BG_CELL_STYLE.clone();
    TOTAL_DELAY_RECOGNITION_VALUE_CELL_STYLE.borderRight = CELL_BORDER;

    export const PREMIUM_VALUE_CELL_STYLE = PREMIUM_LABEL_CELL_STYLE.clone();
    PREMIUM_VALUE_CELL_STYLE.locked = false;
    PREMIUM_VALUE_CELL_STYLE.formatter = AMOUNT_FORMAT;
    PREMIUM_VALUE_CELL_STYLE.foreColor = CELL_EDITABLE_VALUE_COLOR;

    export const PREMIUM_DELAY_RECOGNITION_VALUE_CELL_STYLE = PREMIUM_LABEL_CELL_STYLE.clone();
    PREMIUM_DELAY_RECOGNITION_VALUE_CELL_STYLE.locked = false;
    PREMIUM_DELAY_RECOGNITION_VALUE_CELL_STYLE.borderRight = CELL_BORDER;
    PREMIUM_DELAY_RECOGNITION_VALUE_CELL_STYLE.foreColor = CELL_EDITABLE_VALUE_COLOR;

    export const TEST_STYLE = COMMON_CELL_STYLE.clone();
    TEST_STYLE.backColor = `#FF00FF`;
}


