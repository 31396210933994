import { useCallback } from "react";
import { useSettings } from "../contexts/settings/SettingsContext";

export default function useGetMonths(): string[] {
    const { fiscalYearStartMonthIndex } = useSettings();
    const getMonths = useCallback(
        () => {
            const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

            for (let i = 0; i < fiscalYearStartMonthIndex; i++) {
                months.push(months.shift() ?? "Jan"); // ?? - never going to happen
            }

            return months;
        },
        [fiscalYearStartMonthIndex],
    );

    return getMonths();
}
