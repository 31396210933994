import { ReactElement, useEffect, useMemo, useRef, useState } from "react";
import { ModelingMethodName } from "../../logic/utils";

import { TFormulaNodeProps } from "./logic/formulaNode";
import * as css from "./styles/css.module.scss";
import { VersionType } from "../../../../../../__generated__/generated_types";
import { AccountDriverFormulaManyModal } from "./account-driver-formula-many-modal/AccountDriverFormulaManyModal";
import {min} from "lodash";


export type TAccountFormulaManyNodeProps = TFormulaNodeProps&{
    driverAccounts: {
        name: string,
        id: string,
        lookbackPeriod: number|null|undefined,
    }[],
    driverAugments: {
        minValue: string | null,
        maxValue: string | null
    },
    isBulkUpdateBar?: boolean,
    isPropertyDriversUI?: boolean,
    editableFxBarChecker: ((modelingMethod: ModelingMethodName) => boolean) | undefined,
    selectedAccountIds: string[],
    versionType: VersionType.Reforecast | VersionType.Budget,
    currentPropertyId: string,
    budgetYear: number,
    accountId: string
}

export function AccountDriverFormulaManyNode(props: TAccountFormulaManyNodeProps): ReactElement {
    const [isFocused, setIsFocused] = useState<boolean>(false);

    const wrapperRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (isFocused && wrapperRef.current) {
            wrapperRef.current.focus();
        }
    }, [isFocused]);

    const handleClick = () => {
        if (!isFocused) {
            setIsFocused(true);
        }
    };

    let driverNameRender: string|ReactElement = "Choose Account(s)";
    if (props.driverAccounts.length == 1 && props.driverAccounts[0]?.name) {
        driverNameRender = props.driverAccounts[0].name;
    }
    else if (props.driverAccounts.length > 1) {
        driverNameRender = "Multiple Accounts";
    }

    let minMaxRender: string | ReactElement = "";
    if(props.driverAugments.minValue !== null || props.driverAugments.maxValue !== null) {
        let minRender = "";
        if(props.driverAugments.minValue !== null) {
            minRender = "$" + props.driverAugments.minValue;
        }

        let maxRender = "";
        if(props.driverAugments.maxValue !== null) {
            maxRender = "$" + props.driverAugments.maxValue;
        }
        minMaxRender = ` range [${minRender},${maxRender}]`;
    }

    const nodeClassName = props.locked ? css.fxNodeLocked : css.fxNode;

    const className = useMemo(() => {
        const allClassNames = [nodeClassName, css.deletableNode];

        if (props.className) {
            allClassNames.push(props.className);
        }

        if (isFocused) {
            allClassNames.push(css.focusedNode);
        }

        return allClassNames.join(' ');
    }, [isFocused, nodeClassName]);

    return (
        <div
            className={className}
            onClick={handleClick}
            ref={wrapperRef}
            tabIndex={0}
        >
            (% of Account: {driverNameRender}{minMaxRender}
            {isFocused &&
            <AccountDriverFormulaManyModal
                accountIds={props.isBulkUpdateBar ? props.selectedAccountIds : [props.accountId ?? ""]}
                propertyId={props.currentPropertyId}
                versionType={props.versionType}
                year={props.versionType == "REFORECAST" ? props.budgetYear - 1 : props.budgetYear}
                drivers={props.fbUpdates.parsedFormDrivers.account.map(a => ({
                    accountId: a.accountId,
                    glName: a.glName,
                    glNumber: a.glNumber ?? "",
                    lookbackPeriod: a.lookbackPeriod ?? 0
                }))}
                augments={props.driverAugments}
                onClose={() => setIsFocused(false)}
                onConfirm={(updates, augments) => {
                    props.fbUpdates.setAccountManyDrivers({ payload: updates });
                    props.fbUpdates.setAccountAugments(augments);
                    setIsFocused(false);
                }}
            />})
        </div>
    );
}
