import { createContext, ReactElement, ReactNode, useContext, useEffect, useState } from "react";
import { Feature } from "./Feature";
import { AuthContext, AuthUser } from "../contexts/AuthContext";

// import { useFlags, useLDClient } from 'launchdarkly-react-client-sdk';

export interface IFeatureFlagContext {
    hasFeature(feature: Feature): boolean;
}

const defaultFeatureFlagContext: IFeatureFlagContext = {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    hasFeature(_feature: Feature): boolean {
        return false;
    }
};

const hardcodedFeatureFlagContext: IFeatureFlagContext = {
    hasFeature(feature: Feature): boolean {
        const ret = (
            Feature.EditableFormulaBar == feature
            || Feature.AccountFormulaBar == feature
            || Feature.PlanningDashboard == feature
        );
        return ret;
    }
};

export const FeatureFlagContext = createContext(defaultFeatureFlagContext);

export function FeatureFlagContextProvider(props: {children: ReactNode | ReactNode[]}): ReactElement {

    const {user} = useContext(AuthContext);

    const [context, setContext] = useState<IFeatureFlagContext>(defaultFeatureFlagContext);

    // LaunchDarkly Feature Flag Client
    const ldClient: any = undefined; //useLDClient();

    useEffect(
            () => {
                /**
                 * Keep the default context if no LaunchDarkly client or user is available.
                 * Identified limitation: This means no "user agnostic" feature flags. We don't have this use case now,
                 * but it's worth noting.
                 */
                // if(!ldClient){
                //     return;
                // }

                if(!user) {
                    // If the user logouts, we unset the LaunchDarkly context and reset it to
                    // the default, do nothing context.
                    setContext(defaultFeatureFlagContext);
                    return;
                }
                setContext(hardcodedFeatureFlagContext);

                // LaunchDarkly initialization
                // const userContext = {
                //     kind: 'user',
                //     key: user.id,
                //     email: user.email,
                //     clientId: user.clientId,
                //     env: "react-www"
                // };

                // If we have a user,
                //   1. Ask the LaunchDarkly client to fetch the flags for the new user, and
                //   2. Add a callback to the LaunchDarkly client for when flags are updated.
                // Order is important in these tasks, as the change handler needs to be registered,
                // before we get new flags.
                //
                // The destructor for this effect, must unregister the callback. This uses the old
                // JQuery style of passing in the same function to `off` and `on`.
                // const cb = () => {
                //     setContext(() => {
                //         return {
                //             hasFeature(feature: Feature): boolean {
                //                 return ldClient.variation(feature, false);
                //             }
                //         };
                //     });
                // };

                // ldClient.identify(userContext).then(() => {
                //     cb();
                //     ldClient.on("change", cb);
                // });
                // return () => { ldClient.off("change", cb); };
            },
        [user, ldClient]
    );

    return (
        <FeatureFlagContext.Provider value={context}>
            {props.children}
        </FeatureFlagContext.Provider>
    );
}
