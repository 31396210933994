import { ReactElement, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Button } from "@zendeskgarden/react-buttons";
import { Field, Label, Radio } from "@zendeskgarden/react-forms";
import { Body, Close, Footer, Header, Modal } from "@zendeskgarden/react-modals";
import { ThemeProvider } from "@zendeskgarden/react-theming";

import { ViziblyTheme } from "../../../styles/zendesk-garden/ViziblyZDGTheme";
import * as css from "./styles/copy-drivers-btn/css.module.scss";
import alertIcon from "../../../assets/icons/alert.svg";
import { useProperties } from "../../../contexts/properties/PropertiesContext";
import { DriversCopyPeriodInput, GetUserPropertiesForecastLocksQuery, useCopyPropertyDriversMutation, VersionType } from "../../../__generated__/generated_types";
import { SelectableFinancialEntity } from "../PropertyDrivers";
import PropertiesSelector from "./PropertiesSelector";
import CopyDriversConfirmationModal from "./CopyDriversConfirmationModal";

export interface Option {
    label: string;
    value: string;
}

export enum CopyType {
    MM_ONLY = "MM_ONLY",
    MM_ASSUMPTNS = "MM_ASSUMPTNS"
}

enum RequestStatus {
    PASS = "PASS",
    FAIL = "FAIL"
}

interface IProcessedRequest {
    propertyId: string;
    status: RequestStatus
}

interface ICopyDriversBtn {
    allAccountsCount: number;
    selectedAccounts: SelectableFinancialEntity[];
    versionType: VersionType;
    year: number;
    lockedProperties: GetUserPropertiesForecastLocksQuery | undefined;
}

export default function CopyDriversToProperties({
    allAccountsCount,
    selectedAccounts,
    versionType,
    year,
    lockedProperties,
}: ICopyDriversBtn): ReactElement {
    const [isConfirmModalOpen, setIsConfirmModalOpen] = useState<boolean>(false);
    const [isCopyPDModalOpen, setIsCopyPDModalOpen] = useState<boolean>(false);
    const [isCopyDriversExecuting, setIsCopyDriversExecuting] = useState<boolean>(false);
    const [isFailedRequestsModalOpen, setIsFailedRequestsModalOpen] = useState<boolean>(false);
    const [selectedPropertyOptions, setSelectedPropertyOptions] = useState<Option[]>([]);
    const [copyType, setCopyType] = useState<CopyType>(CopyType.MM_ONLY);
    const [processedRequests, setProcessedRequests] = useState<IProcessedRequest[]>([]);

    const { currentProperty, properties } = useProperties();
    const [copyDrivers] = useCopyPropertyDriversMutation();

    const handleOpenCopyPDModal = () => setIsCopyPDModalOpen(true);

    const resetState = () => {
        setIsCopyDriversExecuting(false);
        setIsConfirmModalOpen(false);
        setIsFailedRequestsModalOpen(false);
        setSelectedPropertyOptions([]);
        setProcessedRequests([]);
        setCopyType(CopyType.MM_ONLY);
        setIsCopyPDModalOpen(false);
    };

    const doCopyDrivers = (
        accountIds: string[],
        budgetYear: number,
        copyAssumptions: boolean,
        currentPropertyId: string,
        destinationPropertyId: string,
        period: DriversCopyPeriodInput,
    ): Promise<any> => {
        return copyDrivers({
            variables: {
                ...(accountIds.length > 0 ? {accountIds} : {}),
                budgetYear: budgetYear,
                sourcePropertyId: currentPropertyId,
                destinationPropertyId: destinationPropertyId,
                period: period,
                copyAssumptions,
            }
        });
    };

    const handleCopyDrivers = () => {
        const budgetYear = currentProperty?.budgetYear;

        if (!budgetYear || !currentProperty) {
            return;
        }

        const accountIds = allAccountsCount === selectedAccounts.length ? [] : selectedAccounts.map((e) => e.id);
        const period: DriversCopyPeriodInput = {
            destinationVersionType: versionType,
            sourceVersionType: versionType,
        };
        const destinationPropertyIds = selectedPropertyOptions.map((e) => e.value);

        const copyPromises = destinationPropertyIds.map((destinationPropertyId: string) =>
            doCopyDrivers(accountIds, budgetYear, copyType === CopyType.MM_ASSUMPTNS, currentProperty.id, destinationPropertyId, period)
        );

        setIsCopyDriversExecuting(true);

        Promise.all(copyPromises)
            .then((responses) => {
                const processedRequestsWithUndefined: IProcessedRequest[] = [];
                responses.forEach((response, index) => {
                    const propertyId = destinationPropertyIds[index];
                    if (propertyId) {
                        processedRequestsWithUndefined.push({
                            propertyId,
                            status: response.data === null ? RequestStatus.FAIL : RequestStatus.PASS,
                        });
                    }
                });

                setProcessedRequests(processedRequestsWithUndefined);
            })
            .catch(() => {
                /* Nothing to do */
            });
    };

    useEffect(() => {
        if (processedRequests.length > 0 && (processedRequests.length === selectedPropertyOptions.length)) {
            const failedRequestIds = processedRequests.filter((e) => e.status === RequestStatus.FAIL).map((e) => e.propertyId);

            if (failedRequestIds.length && properties) {
                toast.error("Modeling methods could not be copied to all properties");

                const pendingPropertyOptions: Option[] = [];
                failedRequestIds.forEach((e) => {
                    const matchingProperty = properties.find((p) => p.id === e);
                    if (matchingProperty) {
                        pendingPropertyOptions.push({
                            label: matchingProperty.name,
                            value: matchingProperty.id,
                        });
                    }
                });

                setIsConfirmModalOpen(false);
                setProcessedRequests([]);
                setSelectedPropertyOptions(pendingPropertyOptions);
                setIsFailedRequestsModalOpen(true);
            } else {
                resetState();
                toast.success("Modeling methods copied successfully");
            }
        }
    }, [processedRequests, properties, selectedPropertyOptions]);

    if (!properties || !currentProperty) {
        return <></>;
    }

    return (
        <ThemeProvider theme={ViziblyTheme}>
        <div className={css.copyDriversBtnWrapper}>
            <Button
                disabled={selectedAccounts.length === 0}
                onClick={handleOpenCopyPDModal}
            >
                Copy Modeling Methods to another property
            </Button>
            {isCopyPDModalOpen && (
                <Modal onClose={resetState} className={css.modal}>
                    <Header>
                        Copy Modeling Methods to another property
                    </Header>
                    <Body className={css.copyDriversBody}>
                        <div className={`${css.row} ${css.title}`}>
                            You're about to copy {selectedAccounts.length} modeling methods from your {year} {versionType.toLowerCase().capitalize()} to another property.
                        </div>
                        <div className={`${css.row} ${css.sectionHeader}`}>
                            <div className={`${css.column} ${css.sectionTitle}`}>
                                Which properties would you like to copy to?
                            </div>
                            <div className={`${css.column} ${css.sectionLabel}`}>
                                REQUIRED
                            </div>
                        </div>
                        <div className={`${css.row} ${css.lightText}`}>
                            Select up to 10 properties
                        </div>
                        {isFailedRequestsModalOpen && (
                            <div className={`${css.row} ${css.error}`}>
                                <img src={alertIcon} alt="alert" />
                                Modeling methods could not be copied to some properties. Those properties are already selected in the dropdown below.
                            </div>
                        )}
                        <div className={`${css.row} ${css.dropdownWrapper}`}>
                            <PropertiesSelector
                                properties={properties.filter((p) => p.id !== currentProperty.id)}
                                year={year}
                                versionType={versionType}
                                selectedOptions={selectedPropertyOptions}
                                lockedProperties={lockedProperties}
                                setSelectedOptions={setSelectedPropertyOptions}
                            />
                        </div>
                        <div className={`${css.row} ${css.sectionHeader} ${css.radioHeader}`}>
                            <div className={`${css.column} ${css.sectionTitle}`}>
                                What should be copied?
                            </div>
                            <div className={`${css.column} ${css.sectionLabel}`}>
                                REQUIRED
                            </div>
                        </div>
                        <div className={css.row}>
                            <Field className={css.radioField}>
                                <Radio
                                    name={CopyType.MM_ONLY}
                                    value={CopyType.MM_ONLY}
                                    checked={copyType === CopyType.MM_ONLY}
                                    onChange={() => setCopyType(CopyType.MM_ONLY)}
                                >
                                    <Label className={css.radioLabel}>Copy modeling methods only (recommended)</Label>
                                </Radio>
                            </Field>
                        </div>
                        <div className={css.row}>
                            <Field className={css.radioField}>
                                <Radio
                                    name={CopyType.MM_ASSUMPTNS}
                                    value={CopyType.MM_ASSUMPTNS}
                                    checked={copyType === CopyType.MM_ASSUMPTNS}
                                    onChange={() => setCopyType(CopyType.MM_ASSUMPTNS)}
                                >
                                    <Label className={css.radioLabel}>Copy modeling methods and assumptions</Label>
                                </Radio>
                            </Field>
                        </div>
                    </Body>
                    <Footer>
                        <div className={`${css.row} ${css.actionsRow}`}>
                            <div className={css.column}>
                                <Button
                                    isBasic
                                    onClick={resetState}
                                >
                                    Cancel
                                </Button>
                            </div>
                            <div className={css.column}>
                                <Button
                                    isPrimary
                                    disabled={selectedPropertyOptions.length === 0 || selectedPropertyOptions.length > 10}
                                    onClick={() => setIsConfirmModalOpen(true)}
                                >
                                    Copy
                                </Button>
                            </div>
                        </div>
                    </Footer>
                    <Close aria-label="Close modal" />
                </Modal>
            )}
        </div>
        {isConfirmModalOpen && (
            <CopyDriversConfirmationModal
                canConfirm={!isCopyDriversExecuting}
                count={selectedAccounts.length}
                onClose={() => setIsConfirmModalOpen(false)}
                onConfirm={handleCopyDrivers}
            />
        )}
        </ThemeProvider>
    );
}
