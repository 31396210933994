import { ReactElement, useEffect, useMemo, useState } from "react";
import useAppStore from "../../hooks/useAppStore";
import {ThemeProvider} from "styled-components";
import { ViziblyTheme } from "../../styles/zendesk-garden/ViziblyZDGTheme";
import * as adminCSS from "../admin/styles/adminStyles.module.scss";
import {Col, Grid, Row} from "@zendeskgarden/react-grid";
import AdminHeader from "../admin/AdminHeader";
import { useGetPropertyBudgetCompletionsLazyQuery, useSetBudgetApprovalMutation, useSetPropertyBudgetCompletionMutation } from "../../__generated__/generated_types";
import * as css from "./budgetCompletion.module.scss"
import Card from "../simplified-revenue/components/Card";
import { Field, MediaInput, Toggle, Label } from "@zendeskgarden/react-forms";
import { ReactComponent as SearchIcon } from '@zendeskgarden/svg-icons/src/16/search-stroke.svg';
import { Dropdown, Item, Menu, Select, Field as DropdownField } from "@zendeskgarden/react-dropdowns";
import { formatterPercent } from "../../utils/formatters";
import { useProperties } from "../../contexts/properties/PropertiesContext";

type PropertyBudgetStatus = {
    propertyId: string,
    propertyName: string,
    budgetYear: number,
    completed: boolean,
    approved: boolean
}

enum CompletionFilterValue {
    ANY = "All",
    IN_PROGRESS = "In Progress",
    COMPLETED = "Completed"
}

enum ApprovalFilterValue {
    ANY = "All",
    AWAITING = "Pending",
    APPROVED = "Approved"
}

export function PropertyBudgetCompletion(): ReactElement {
    const appStore = useAppStore();
    const properties = useProperties();
    const [fetchBudgetStatus, {data, loading}] = useGetPropertyBudgetCompletionsLazyQuery({
        fetchPolicy: "no-cache"
    });
    const [setBudgetStatus, {loading: completionUpdating}] = useSetPropertyBudgetCompletionMutation({
        onCompleted: () => {
            if (properties.currentProperty?.budgetYear) {
                fetchBudgetStatus({
                    variables: {
                        budgetYear: properties.currentProperty.budgetYear
                    }
                });
            }
        }
    });
    const [parsedData, setParsedData] = useState<PropertyBudgetStatus[]>();
    const [filteredData, setFilteredData] = useState<PropertyBudgetStatus[]>([]);
    const [searchString, setSearchString] = useState<string>();
    const [completionFilter, setCompletionFilter] = useState<CompletionFilterValue.ANY | CompletionFilterValue.IN_PROGRESS | CompletionFilterValue.COMPLETED>(CompletionFilterValue.ANY);
    const [approvalFilter, setApprovalFilter] = useState<ApprovalFilterValue.ANY | ApprovalFilterValue.AWAITING | ApprovalFilterValue.APPROVED>(ApprovalFilterValue.ANY);

    useEffect(
        () => {
            appStore.set({isLoading: false});
        },
        []
    );

    useEffect(() => {
        if (properties.currentProperty?.budgetYear) {
            fetchBudgetStatus({
                variables: {
                    budgetYear: properties.currentProperty.budgetYear
                }
            });
        }
    }, [properties.currentProperty?.budgetYear]);

    useEffect(() => {
        if (data && !loading) {
            const parsed = data
                .queryPropertyBudgetCompletions
                .sortBy("propertyName")
                .map(r => ({
                    propertyId: r.propertyId,
                    propertyName: r.propertyName,
                    budgetYear: r.budgetYear,
                    completed: r.completed,
                    approved: false
                }));

            const approvalMap = data.getBudgetApprovalStatusForProperties.toIdMap("propertyId", "approved");
            for (const row of parsed) {
                row.approved = approvalMap[row.propertyId] ?? false;
            }
            setParsedData(parsed);
            updateFilteredData(parsed);
        }
    }, [data, loading]);

    useEffect(() => {
        updateFilteredData(parsedData ?? []);
    }, [searchString, completionFilter, approvalFilter]);

    function updateFilteredData(parsedData: PropertyBudgetStatus[]) {
        let filtered = parsedData.filter(
                p => completionFilter === CompletionFilterValue.ANY ||
                completionFilter === CompletionFilterValue.COMPLETED && p.completed ||
                completionFilter === CompletionFilterValue.IN_PROGRESS && !p.completed);
        filtered = filtered.filter(
                p => approvalFilter === ApprovalFilterValue.ANY ||
                approvalFilter === ApprovalFilterValue.APPROVED && p.approved ||
                approvalFilter === ApprovalFilterValue.AWAITING && !p.approved
        );
        if (searchString && searchString.trim() !== "") {
            filtered = filtered.filter(p => p.propertyName.toLowerCase().includes(searchString));
        }
        setFilteredData(filtered);
    }

    function handleCompletion(propertyId: string, completed: boolean) {
        if (properties.currentProperty?.budgetYear !== undefined) {
            setBudgetStatus({
                variables: {
                    propertyId: propertyId,
                    budgetYear: properties.currentProperty?.budgetYear,
                    completed: completed
                }
            });
        }
    }

    const doneRatioMsg = useMemo(() => {
        let ret = undefined;
        if (parsedData && parsedData.length > 1) {
            const completedCount = parsedData.filter(d => d.completed).length;
            const approvedCount = parsedData.filter(d => d.approved).length;
            ret = `Completed ${completedCount} out of ${parsedData.length} (${formatterPercent.format(completedCount / parsedData.length)}).`;
            ret += ` Approved ${approvedCount} out of ${parsedData.length} (${formatterPercent.format(approvedCount / parsedData.length)}).`;
        }
        return ret;
    }, parsedData);

    return (
        <ThemeProvider theme={ViziblyTheme}>
            <Grid className={adminCSS.adminWrapper}>
                <Row>
                    <Col>
                        <AdminHeader
                            title={"Budget Completion"}
                            subtitle={""}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div style={{marginBottom: "1rem"}}>
                            This tells others in the company that budgeting for the property has been completed or is still in progress
                        </div>
                        <Card
                            title={`Property Budget Status. ${doneRatioMsg ?? ""}`}
                            actions={
                                <Field className={css.propertySearch}>
                                    <MediaInput start={<SearchIcon />} placeholder="Search" onChange={e => setSearchString(e.target.value.trim().toLowerCase())}/>
                                </Field>
                            }
                        >
                            <div className={css.propertyTableWrapper}>
                                <table className={css.propertyTable}>
                                    <thead>
                                        <tr className={css.headerCell}>
                                            <th className={`${css.cell} ${css.nameCell}`}>Property</th>
                                            <th className={`${css.cell} ${css.periodCell}`}>
                                                <div className={css.verticalBlock}>
                                                    <span>Completion Status</span>
                                                    <Dropdown
                                                        selectedItem={completionFilter ?? CompletionFilterValue.ANY}
                                                        onSelect={setCompletionFilter}
                                                    >
                                                        <DropdownField className={css.statusFilter}>
                                                            <Select>
                                                                {completionFilter ?? CompletionFilterValue.ANY}
                                                            </Select>
                                                        </DropdownField>
                                                        <Menu>
                                                            <Item key={CompletionFilterValue.ANY} value={CompletionFilterValue.ANY}>
                                                                {CompletionFilterValue.ANY}
                                                            </Item>
                                                            <Item key={CompletionFilterValue.IN_PROGRESS} value={CompletionFilterValue.IN_PROGRESS}>
                                                                {CompletionFilterValue.IN_PROGRESS}
                                                            </Item>
                                                            <Item key={CompletionFilterValue.COMPLETED} value={CompletionFilterValue.COMPLETED}>
                                                                {CompletionFilterValue.COMPLETED}
                                                            </Item>
                                                        </Menu>
                                                    </Dropdown>
                                                </div>
                                            </th>
                                            <th className={`${css.cell} ${css.periodCell}`}>
                                                <div className={css.verticalBlock}>
                                                    <span>Approval Status</span>
                                                    <Dropdown
                                                        selectedItem={approvalFilter ?? ApprovalFilterValue.ANY}
                                                        onSelect={setApprovalFilter}
                                                    >
                                                        <DropdownField className={css.statusFilter}>
                                                            <Select>
                                                                {approvalFilter ?? ApprovalFilterValue.ANY}
                                                            </Select>
                                                        </DropdownField>
                                                        <Menu>
                                                            <Item key={ApprovalFilterValue.ANY} value={ApprovalFilterValue.ANY}>
                                                                {ApprovalFilterValue.ANY}
                                                            </Item>
                                                            <Item key={ApprovalFilterValue.AWAITING} value={ApprovalFilterValue.AWAITING}>
                                                                {ApprovalFilterValue.AWAITING}
                                                            </Item>
                                                            <Item key={ApprovalFilterValue.APPROVED} value={ApprovalFilterValue.APPROVED}>
                                                                {ApprovalFilterValue.APPROVED}
                                                            </Item>
                                                        </Menu>
                                                    </Dropdown>
                                                </div>
                                            </th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        {filteredData.map(property => (
                                                <tr key={property.propertyId}>
                                                    <td className={`${css.cell}`}>{ property.propertyName }</td>
                                                    <td className={`${css.cell} ${css.periodCell}`}>
                                                        <Field>
                                                            <Toggle checked={property.completed} onChange={() => !(loading || completionUpdating) && handleCompletion(property.propertyId, !property.completed)}>
                                                            <Label>{property.completed ? "Completed" : "In Progress"}</Label>
                                                            </Toggle>
                                                        </Field>
                                                    </td>
                                                    <td className={`${css.cell} ${css.periodCell}`}>
                                                        {property.approved ? "Approved" : "Pending"}
                                                    </td>
                                                    </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </Card>
                    </Col>
                </Row>
            </Grid>
        </ThemeProvider>
    );
}