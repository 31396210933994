import { ReactElement } from "react";

import { IFormulaBarUpdates } from "../../../../../../contexts/account/data/useFormulaBarUpdates";
import { TAllAvailableDrivers } from "../../../../../../contexts/account/data/utils";
import { ModelingMethodName } from "../../logic/utils";
import { VersionType } from "../../../../../../__generated__/generated_types";
import FormulaBarMenu from "../formula-menu/FormulaBarMenu";
import * as css from "../formula-nodes/styles/css.module.scss";


type TJunctionNodeProps = {
    accountId?: string | undefined,
    budgetYear: number;
    allAvailableDrivers?: TAllAvailableDrivers,
    isWorksheetDriven?: boolean,
    locked:boolean,
    className?: string,
    fbUpdates: IFormulaBarUpdates,
    isBulkUpdateBar?: boolean,
    selectedAccountIds?: string[],
    isPropertyDriversUI?: boolean,
    worksheetTypeAhead?: string[] | undefined,
    editableFxBarChecker?: (modelingMethod: ModelingMethodName) => boolean,
    versionType?: VersionType.Budget | VersionType.Reforecast,
    currentPropertyId?: string | undefined // Hate props drilling but this is how the whole formula bar is built at this point :(
}

export default function JunctionNode(props:TJunctionNodeProps):ReactElement {
    const nodeClassName = props.locked ? css.junctionNodeLocked : css.junctionNode;

    const staticJunctionNode = '+';

    const clickableJunctionNode = (
        <div className={css.clickableJunctionNode}>
            {staticJunctionNode}
        </div>
    );

    return (
            <div className={props.className ? `${nodeClassName} ${props.className}` : nodeClassName}>
                {props.locked
                    ? staticJunctionNode
                    : (
                        <FormulaBarMenu
                            accountId={props.accountId}
                            budgetYear={props.budgetYear}
                            allAvailableDrivers={props.allAvailableDrivers}
                            isWorksheetDriven={props.isWorksheetDriven}
                            triggerNode={clickableJunctionNode}
                            fbUpdates={props.fbUpdates}
                            isBulkUpdateBar={props.isBulkUpdateBar}
                            selectedAccountIds={props.selectedAccountIds ?? []}
                            isPropertyDriversUI={props.isPropertyDriversUI}
                            worksheetTypeAhead={props.worksheetTypeAhead}
                            editableFxBarChecker={props.editableFxBarChecker}
                            versionType={props.versionType}
                            currentPropertyId={props.currentPropertyId}
                        />
                    )
                }
            </div>
    );
}
