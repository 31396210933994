import { ReactElement, useEffect, useMemo } from "react";
import { useProperties } from "../../contexts/properties/PropertiesContext";
import { useQueryAccountPercentageDriverCyclesIndicatorsLazyQuery } from "../../__generated__/generated_types";
import { ReactComponent as CycleIcon } from '@zendeskgarden/svg-icons/src/16/arrow-retweet-stroke.svg';
import { Button } from "@zendeskgarden/react-buttons";
import {Link, useLocation} from 'react-router-dom';

export function CircularReferenceIndicator(): ReactElement | null{
    const [queryAccountPercentageDriverCyclesIndicators, { data: accPercentageCyclesData, loading: accPercentageCyclesLoading }] = useQueryAccountPercentageDriverCyclesIndicatorsLazyQuery({
        fetchPolicy: "no-cache"
    });

    const properties = useProperties();
    // I need to periodically check for cycles.
    // To not overload system with polling - I am checking on location change
    // This is meaningful for scenarios where user does not switch a property being on
    const location = useLocation();

    useEffect(() => {
        // Let's be protective to not overwelm our backend with requests
        const debounce = setTimeout(() => {
            const propertyId = properties.currentProperty?.id;
            if (propertyId) {
                queryAccountPercentageDriverCyclesIndicators({
                    variables: {
                        propertyId: propertyId
                    }
                });
            }
        }, 2000);

        return () => clearTimeout(debounce);
    },
    [properties.currentProperty?.id, location.pathname]);

    const hasCycles = useMemo(
        () => {
            let result = false;
            if (accPercentageCyclesData && !accPercentageCyclesLoading) {
                result = accPercentageCyclesData.queryAccountPercentageDriverCyclesIndicators.reforecast
                    || accPercentageCyclesData.queryAccountPercentageDriverCyclesIndicators.budget;
            }

            return result;
        },
        [accPercentageCyclesData, accPercentageCyclesLoading]
    );


    return !hasCycles || location.pathname == "/circular-references" ? null : (
        <Link to="/circular-references">
            <Button isDanger isPrimary>
                <CycleIcon />
            </Button>
        </Link>
    );
}