import { ReactElement } from "react";
import { formatterDollarUSNoDecimal, formatterPercent } from "../../../../../../../../utils/formatters";
import * as css from '../styles/insights.module.scss';

interface IAnnualBudgetRemainingProps {
    budgetRemaining: IAnnualBudgetRemaining
}

export default function AnnualBudgetRemaining(props:IAnnualBudgetRemainingProps):ReactElement {

    const percentVal = !isNaN(props.budgetRemaining.percentRemaining)
            ? formatterPercent.format(props.budgetRemaining.percentRemaining)
            : formatterPercent.format(0);
    const totalRemaining = formatterDollarUSNoDecimal.format(props.budgetRemaining.totalRemaining);
    const overUnder = props.budgetRemaining.totalRemaining > 0 ? 'under' : 'over';
    const year = props.budgetRemaining.year;

    return <><span className={css.changeValue}>{percentVal} ({totalRemaining})</span> {overUnder} {year} annual budget target</>;
}

export interface IAnnualBudgetRemaining {
    year: number,
    budgetTotal: number,
    percentRemaining: number,
    totalRemaining: number,
}
