// noinspection DuplicatedCode

import GC from "@grapecity/spread-sheets";
import { ICustomCellInfo, ICustomCellToggleHitInfo } from "./types";
import { CheckboxState, drawCheckbox } from "./icon-renderers/draw_checkbox";
import { drawLock } from "./icon-renderers/draw-lock";

export interface CheckboxCellInfo extends ICustomCellInfo {
    show: boolean;
    checked: boolean;
    indeterminate: boolean;
    style: GC.Spread.Sheets.Style;
    showLockLocked: boolean;
    showLockUnlocked: boolean;
    checkboxDisabled: boolean;
}

export class CheckboxCell extends GC.Spread.Sheets.CellTypes.Text {
    constructor(private cellInfo: CheckboxCellInfo) {
        super();
        this.typeName = "CheckboxCell";
        this.cellInfo = cellInfo;
    }

    state = CheckboxState.DEFAULT;

    updateCellInfo(cellInfo: CheckboxCellInfo) {
        this.cellInfo = cellInfo;
    }

    override paint(ctx: CanvasRenderingContext2D, value: any, x: number, y: number, w: number, h: number, style: GC.Spread.Sheets.Style, context?: any): void {

        if (this.cellInfo.style) {
            style = this.cellInfo.style;
        }
        super.paint(ctx, value, x, y, w, h /*+ (this.cellInfo.padding || 0)*/, style, context);
    }

    override paintContent(ctx: CanvasRenderingContext2D, value: any, x: number, y: number, w: number, h: number, style: GC.Spread.Sheets.Style, context?: any): void {

        super.paintContent(ctx, value, x, y, w, h, style, context);

        if (this.cellInfo.show) {
            drawCheckbox(ctx, x + (style?.textIndent ?? 0) * 8, y, w, h,
                this.cellInfo.checkboxDisabled ? CheckboxState.DISABLED : this.state,
                this.cellInfo?.checked ?? false,
                this.cellInfo?.indeterminate ?? false
            );
        }

        if (this.cellInfo.showLockLocked) {
            drawLock(ctx, x - 16, y, w, h);
        }
        else if (this.cellInfo.showLockUnlocked) {
            drawLock(ctx, x - 16, y, w, h, false);
        }
    }

    override getHitInfo(x: number, y: number, _cellStyle: GC.Spread.Sheets.Style, cellRect: GC.Spread.Sheets.Rect, context?: any): GC.Spread.Sheets.IHitTestCellTypeHitInfo {
        const [row, col] = [context.row as number, context.col as number];

        return {
            x,
            y,
            row,
            col,
            cellRect,
        } as ICustomCellToggleHitInfo;
    }

    override processMouseEnter(_hitInfo: GC.Spread.Sheets.IHitTestCellTypeHitInfo): boolean {
        this.state = CheckboxState.HOVER;
        return true;
    }

    override processMouseLeave(_hitInfo: GC.Spread.Sheets.IHitTestCellTypeHitInfo): boolean {
        this.state = CheckboxState.DEFAULT;
        return true;
    }

    override processMouseDown(_hitInfo: GC.Spread.Sheets.IHitTestCellTypeHitInfo): boolean {
        this.state = CheckboxState.FOCUSED;
        return true;
    }

    override processMouseUp(_hitInfo: GC.Spread.Sheets.IHitTestCellTypeHitInfo): boolean {
        this.state = CheckboxState.HOVER;
        return true;
    }

}
