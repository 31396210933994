import { Button } from "@zendeskgarden/react-buttons";
import { ThemeProvider } from "@zendeskgarden/react-theming";
import { ReactElement, useEffect, useRef } from "react";
import useAppStore from "../../../hooks/useAppStore";
import { ViziblyTheme } from "../../../styles/zendesk-garden/ViziblyZDGTheme";
import LineItemsSpreadsheet, { LineItemsSpreadsheetAPI } from "./components/line-items-spreadsheet/LineItemsSpreadsheet";
import { ReactComponent as Download } from '@zendeskgarden/svg-icons/src/16/download-stroke.svg';
import * as css from "./styles/css.module.scss";
import { CustomZDDropdown } from "../../../atoms/custom-zd-dropdown/CustomZDDropdown";
import { useLineItemsReportState } from "./components/line-items-spreadsheet/state/useLineItemsReportState";

export function LineItemsReport(): ReactElement {
    const appStore = useAppStore();
    const spreadsheetRef = useRef<LineItemsSpreadsheetAPI>(null);
    const state = useLineItemsReportState();

    useEffect(
        () => {
            appStore.set({ isLoading: false });
            // eslint-disable-next-line @typescript-eslint/no-empty-function
            return () => { };
        },
        []
    );

    const onExportClicked = () => {
        if (spreadsheetRef.current != null) {
            spreadsheetRef.current.exportAsCsv();
        }
    };

    return (
        <div className={css.container}>
            <div className={css.pageTitle}>
                Line Items
            </div>
            <div className={css.pageContent}>
                <div className={css.pageContentHeader}>
                    <div className={css.headerLabel}>
                        Line Items
                    </div>
                    <div className={css.headerPropertyFilter}>
                        <ThemeProvider theme={ViziblyTheme}>
                            <div className={css.propertiesSelector}>
                                <CustomZDDropdown
                                    initialSelectedItems={state.selectedProperties.map(p => p.value)}
                                    applySelectedItems={function (items: any): void {
                                        state.setSelectedProperties(items);
                                    }}
                                    openDropdownPlaceholder={"Properties"}
                                    closedDropdownPlaceholder={"Properties"}
                                    options={state.availablePropeties}
                                    isError={undefined}
                                    isMulti
                                    allOption
                                />
                            </div>
                        </ThemeProvider>
                    </div>
                    <div className={css.headerSettingsButton}>
                        <ThemeProvider theme={ViziblyTheme}>
                            <Button onClick={() => onExportClicked()}>
                                <Button.StartIcon>
                                    <Download />
                                </Button.StartIcon>
                                Export
                            </Button>
                        </ThemeProvider>
                    </div>
                </div>
                <div className={css.pageContentBody}>
                    <LineItemsSpreadsheet ref={spreadsheetRef} propertyIds={state.selectedPropertyIds} />
                </div>
            </div>
        </div>
    );
}