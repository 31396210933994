import { MONTHS } from "../../../../../../constants/Months";
import { VersionType } from "../../../../../../__generated__/generated_types";
import * as css from "../styles/css.module.scss";
import { ColumnSettings } from "handsontable/settings";

enum lineItemCols {
    lineItemName,
    property,
    glAccount,
    reforecastJan = 3,
    reforecastTotal = 15,
    budgetJan = 16,
    budgetTotal = 28,
}

export const columnConfig:ColumnSettings[] = [
        {
            width: '240px',
        },
        {
            width: '150px',
        },
        {
            width: '240px',
        },
        ...new Array(12).fill({
            width: '96px',
            type: 'numeric',
            numericFormat: {
                pattern: '0,0.00',
                culture: 'en-US', // this is the default culture, set up for USD
            },
            headerAction: false,
        }),
        {
            className: css.totalColumn,
            width: '96px',
            type: 'numeric',
            numericFormat: {
                pattern: '0,0.00',
                culture: 'en-US', // this is the default culture, set up for USD
            },
            headerAction: false,
        },
        ...new Array(12).fill({
            width: '96px',
            type: 'numeric',
            numericFormat: {
                pattern: '0,0.00',
                culture: 'en-US', // this is the default culture, set up for USD
            },
            headerAction: false,
        }),
        {
            className: css.totalColumn,
            width: '96px',
            type: 'numeric',
            numericFormat: {
                pattern: '0,0.00',
                culture: 'en-US', // this is the default culture, set up for USD
            },
            headerAction: false,
        },
];

const getColumnHeaderBody = (monthIdx:number, year: number, versionType:VersionType, isTotal?:boolean):string => {
    let colHeaderBody:string = versionType === VersionType.Reforecast
        ? `<div class="${css.budgetType}">REFORECAST</div>`
        : `<div class="${css.budgetType}">BUDGET</div>`;

    colHeaderBody += (monthIdx == 0 && year != undefined) || (isTotal === true && year != undefined)
        ? `<div class="${css.year}">${year}</div>`
        : '<div class="${css.year}"><br/></div>';

    if(isTotal === true){
        colHeaderBody += `<div class="${css.month}">TOTAL</div>`;
    }
    else{
        colHeaderBody += `<div class="${css.month}">${MONTHS[monthIdx]}</div>`;
    }

    return `<div class="${css.columnHeaderBody}">${colHeaderBody}</div>`;
};

export const getColHeaders = (index: number, year:number):string => {

    if (index === lineItemCols.lineItemName){
        return 'Line Items';
    }
    if (index === lineItemCols.property){
        return 'Property';
    }
    if (index === lineItemCols.glAccount){
        return 'GL Account';
    }
    else if(index < lineItemCols.reforecastTotal){
        return getColumnHeaderBody(index - 3, year, VersionType.Reforecast);
    }
    else if (index === lineItemCols.reforecastTotal){
        return getColumnHeaderBody(index - 3, year, VersionType.Reforecast, true);
    }
    else if(index < lineItemCols.budgetTotal){
        return getColumnHeaderBody(index - lineItemCols.budgetJan, year + 1, VersionType.Budget);
    }
    else if (index === lineItemCols.budgetTotal) {
        return getColumnHeaderBody(index - lineItemCols.budgetJan, year + 1, VersionType.Budget, true);
    }
    // Past user facing columns, return empty string
    return '';
};

export const afterGetColumnHeader = (column: number, th: HTMLTableHeaderCellElement):void => {
    if(column >= 0 && column <= 2) {
        /*
         * This is a stupid nasty hack because we decided to change
         * HandsOnTable's global styling. Why we did that I will never
         * know.
         */
        th.classList.add(css.sortableHeader);
    }
    if(column < lineItemCols.reforecastJan && !th.classList.contains(css.columnHeaderBase)){
        th.classList.add(css.columnHeaderBase);
    }
    else if(column < lineItemCols.reforecastTotal && !th.classList.contains(css.reforecastValueColumnHeader)){
        th.classList.add(css.reforecastValueColumnHeader);
    }
    else if((column === lineItemCols.reforecastTotal || column === lineItemCols.budgetTotal) && !th.classList.contains(css.totalsColumnHeader)){
        th.classList.add(css.totalsColumnHeader);
    }
    else if(column < lineItemCols.budgetTotal && !th.classList.contains(css.budgetValueColumnHeader)){
        th.classList.add(css.budgetValueColumnHeader);
    }
};
