import GC from "@grapecity/spread-sheets";
import { WHITE_BORDER } from "../../../vztable-styles/Styles";
import {
    OperationalMetric,
    UnitType,
    UnitTypeScheduleValue,
    UnitTypeScheduleValueUpsertInput,
    useUpsertOpMetricValuesMutation,
    useUpsertUnitTypeScheduleValuesMutation,
} from "../../../__generated__/generated_types";

export const BORDER = new GC.Spread.Sheets.LineBorder("#f2f2f2", GC.Spread.Sheets.LineStyle.thin);
export const GRAY_BORDER = new GC.Spread.Sheets.LineBorder("#9C9C9C", GC.Spread.Sheets.LineStyle.medium);

export const DEFAULT_STYLE = new GC.Spread.Sheets.Style();
DEFAULT_STYLE.font = '400 12px Inter';
DEFAULT_STYLE.foreColor = "#333";
DEFAULT_STYLE.formatter = "###,###,##0";
DEFAULT_STYLE.locked = true;
DEFAULT_STYLE.cellPadding = "12px";
DEFAULT_STYLE.borderBottom = BORDER;
DEFAULT_STYLE.borderLeft = BORDER;
DEFAULT_STYLE.borderRight = undefined;
DEFAULT_STYLE.borderTop = BORDER;
DEFAULT_STYLE.vAlign = GC.Spread.Sheets.VerticalAlign.center;
DEFAULT_STYLE.hAlign = GC.Spread.Sheets.HorizontalAlign.center;

export const DEFAULT_STYLE_LEFT = DEFAULT_STYLE.clone();
DEFAULT_STYLE_LEFT.hAlign = GC.Spread.Sheets.HorizontalAlign.left;

export const DEFAULT_BOLD_STYLE_LEFT = DEFAULT_STYLE.clone();
DEFAULT_BOLD_STYLE_LEFT.hAlign = GC.Spread.Sheets.HorizontalAlign.left;
DEFAULT_BOLD_STYLE_LEFT.font = '500 12px Inter';

export const DEFAULT_BOLD_STYLE = DEFAULT_STYLE.clone();
DEFAULT_BOLD_STYLE.font = '500 12px Inter';
DEFAULT_BOLD_STYLE.borderBottom = BORDER;
DEFAULT_BOLD_STYLE.borderLeft = BORDER;
DEFAULT_BOLD_STYLE.borderRight = BORDER;
DEFAULT_BOLD_STYLE.borderTop = BORDER;

export const DEFAULT_BOLD_STYLE_CENTERED = DEFAULT_STYLE.clone();
DEFAULT_BOLD_STYLE_CENTERED.font = '500 12px Inter';
DEFAULT_BOLD_STYLE_CENTERED.vAlign = GC.Spread.Sheets.VerticalAlign.center;
DEFAULT_BOLD_STYLE_CENTERED.hAlign = GC.Spread.Sheets.HorizontalAlign.center;
DEFAULT_BOLD_STYLE_CENTERED.borderTop = undefined;
DEFAULT_BOLD_STYLE_CENTERED.borderBottom = BORDER;
DEFAULT_BOLD_STYLE_CENTERED.borderRight = BORDER;

export const DEFAULT_STYLE_CENTERED = DEFAULT_STYLE.clone();
DEFAULT_STYLE_CENTERED.font = '400 12px Inter';
DEFAULT_STYLE_CENTERED.vAlign = GC.Spread.Sheets.VerticalAlign.center;
DEFAULT_STYLE_CENTERED.hAlign = GC.Spread.Sheets.HorizontalAlign.center;
DEFAULT_STYLE_CENTERED.borderTop = undefined;
DEFAULT_STYLE_CENTERED.borderBottom = undefined;

const EDITABLE_BORDER = new GC.Spread.Sheets.LineBorder("#78BCBC", GC.Spread.Sheets.LineStyle.thin);

export const EDITABLE_STYLE = DEFAULT_STYLE.clone();
EDITABLE_STYLE.formatter = "_(#,##0_);_((#,e##0);";
EDITABLE_STYLE.locked = false;
EDITABLE_STYLE.borderBottom = EDITABLE_BORDER;
EDITABLE_STYLE.borderLeft = EDITABLE_BORDER;
EDITABLE_STYLE.borderRight = EDITABLE_BORDER;
EDITABLE_STYLE.borderTop = EDITABLE_BORDER;
EDITABLE_STYLE.hAlign = GC.Spread.Sheets.HorizontalAlign.center;
EDITABLE_STYLE.vAlign = GC.Spread.Sheets.VerticalAlign.center;
EDITABLE_STYLE.foreColor = "#107F88";
EDITABLE_STYLE.font = '500 12px Inter';

export const HEADER_STYLE = DEFAULT_STYLE.clone();
HEADER_STYLE.font = '400 12px Inter';
HEADER_STYLE.borderLeft = WHITE_BORDER;
HEADER_STYLE.borderRight = WHITE_BORDER;
HEADER_STYLE.cellPadding = "12px";
HEADER_STYLE.borderBottom = undefined;
HEADER_STYLE.hAlign = GC.Spread.Sheets.HorizontalAlign.center;
HEADER_STYLE.vAlign = GC.Spread.Sheets.VerticalAlign.center;


export const TOTALS_STYLE = DEFAULT_STYLE.clone();
TOTALS_STYLE.borderTop = new GC.Spread.Sheets.LineBorder("#9C9C9C", GC.Spread.Sheets.LineStyle.thin);
TOTALS_STYLE.hAlign = GC.Spread.Sheets.HorizontalAlign.center;
TOTALS_STYLE.vAlign = GC.Spread.Sheets.VerticalAlign.center;
TOTALS_STYLE.font = '500 12px Inter';

export const BUDGET_STYLE = DEFAULT_STYLE.clone();
BUDGET_STYLE.foreColor = '#9C9C9C';

export const BUDGET_STYLE_HEADER = BUDGET_STYLE.clone();
BUDGET_STYLE_HEADER.formatter = '';

export const DEFAULT_PERCENT = DEFAULT_STYLE.clone();
//DEFAULT_PERCENT.formatter = "_(#,##0.0%_);_((#,e##0.0%);";
DEFAULT_PERCENT.formatter = "0.0%";
DEFAULT_PERCENT.hAlign = GC.Spread.Sheets.HorizontalAlign.left;
DEFAULT_PERCENT.vAlign = GC.Spread.Sheets.VerticalAlign.center;


export const PERCENTAGE_BOLD_STYLE_CENTERED = DEFAULT_PERCENT.clone();
PERCENTAGE_BOLD_STYLE_CENTERED.formatter = "0.0%";
PERCENTAGE_BOLD_STYLE_CENTERED.font = '500 12px Inter';
PERCENTAGE_BOLD_STYLE_CENTERED.vAlign = GC.Spread.Sheets.VerticalAlign.center;
PERCENTAGE_BOLD_STYLE_CENTERED.hAlign = GC.Spread.Sheets.HorizontalAlign.center;
PERCENTAGE_BOLD_STYLE_CENTERED.borderRight = BORDER;

export const PERCENTAGE_STYLE_CENTERED = DEFAULT_PERCENT.clone();
PERCENTAGE_STYLE_CENTERED.formatter = "0.0%";
PERCENTAGE_STYLE_CENTERED.font = '400 12px Inter';
PERCENTAGE_STYLE_CENTERED.vAlign = GC.Spread.Sheets.VerticalAlign.center;
PERCENTAGE_STYLE_CENTERED.hAlign = GC.Spread.Sheets.HorizontalAlign.center;

export const EDITABLE_PERCENT = DEFAULT_PERCENT.clone();
EDITABLE_PERCENT.formatter = "0.0%";
EDITABLE_PERCENT.locked = false;
EDITABLE_PERCENT.borderBottom = EDITABLE_BORDER;
EDITABLE_PERCENT.borderLeft = EDITABLE_BORDER;
EDITABLE_PERCENT.borderRight = EDITABLE_BORDER;
EDITABLE_PERCENT.borderTop = EDITABLE_BORDER;
EDITABLE_PERCENT.hAlign = GC.Spread.Sheets.HorizontalAlign.center;
EDITABLE_PERCENT.vAlign = GC.Spread.Sheets.VerticalAlign.center;


export const EDITABLE_PERCENT_WITH_DATA = EDITABLE_PERCENT.clone();
EDITABLE_PERCENT_WITH_DATA.borderBottom = BORDER;
EDITABLE_PERCENT_WITH_DATA.borderLeft = BORDER;
EDITABLE_PERCENT_WITH_DATA.borderRight = BORDER;
EDITABLE_PERCENT_WITH_DATA.borderTop = BORDER;
EDITABLE_PERCENT_WITH_DATA.foreColor = "#107F88";
EDITABLE_PERCENT_WITH_DATA.font = "500 12px Inter";

export const DEFAULT_GOOD = DEFAULT_STYLE.clone();
DEFAULT_GOOD.foreColor = '#00B1A7';
DEFAULT_GOOD.hAlign = GC.Spread.Sheets.HorizontalAlign.center;
DEFAULT_GOOD.vAlign = GC.Spread.Sheets.VerticalAlign.center;

export const DEFAULT_BAD = DEFAULT_STYLE.clone();
DEFAULT_BAD.foreColor = '#FF5C00';
DEFAULT_BAD.hAlign = GC.Spread.Sheets.HorizontalAlign.center;
DEFAULT_BAD.vAlign = GC.Spread.Sheets.VerticalAlign.center;

export const DEFAULT_DARK_OLIVE = DEFAULT_STYLE.clone();
DEFAULT_DARK_OLIVE.backColor = 'rgba(3, 54, 61, 0.3)';


export const DEFAULT_DARK_OLIVE_EDITABLE = DEFAULT_DARK_OLIVE.clone();
DEFAULT_DARK_OLIVE_EDITABLE.locked = false;
DEFAULT_DARK_OLIVE_EDITABLE.borderBottom = EDITABLE_BORDER;
DEFAULT_DARK_OLIVE_EDITABLE.borderLeft = EDITABLE_BORDER;
DEFAULT_DARK_OLIVE_EDITABLE.borderRight = EDITABLE_BORDER;
DEFAULT_DARK_OLIVE_EDITABLE.borderTop = EDITABLE_BORDER;
DEFAULT_DARK_OLIVE_EDITABLE.hAlign = GC.Spread.Sheets.HorizontalAlign.center;
DEFAULT_DARK_OLIVE_EDITABLE.vAlign = GC.Spread.Sheets.VerticalAlign.center;


export const DEFAULT_DARK_OLIVE_EDITABLE_PERCENT = DEFAULT_DARK_OLIVE_EDITABLE.clone();
DEFAULT_DARK_OLIVE_EDITABLE_PERCENT.formatter = "_(#,##0.0%_);_((#,e##0.0%);";



export const DEFAULT_OLIVE = DEFAULT_STYLE.clone();
DEFAULT_OLIVE.backColor = 'rgba(196, 196, 196, 0.3)';

export const DEFAULT_OLIVE_EDITABLE = DEFAULT_OLIVE.clone();
DEFAULT_OLIVE_EDITABLE.locked = false;
DEFAULT_OLIVE_EDITABLE.borderBottom = EDITABLE_BORDER;
DEFAULT_OLIVE_EDITABLE.borderLeft = EDITABLE_BORDER;
DEFAULT_OLIVE_EDITABLE.borderRight = EDITABLE_BORDER;
DEFAULT_OLIVE_EDITABLE.borderTop = EDITABLE_BORDER;
DEFAULT_OLIVE_EDITABLE.hAlign = GC.Spread.Sheets.HorizontalAlign.center;
DEFAULT_OLIVE_EDITABLE.vAlign = GC.Spread.Sheets.VerticalAlign.center;


//LEASE EXPIRATION BORDER DIVISION
export const DEFAULT_BOLD_STYLE_LEFT_DIVIDER = DEFAULT_STYLE.clone();
DEFAULT_BOLD_STYLE_LEFT_DIVIDER.hAlign = GC.Spread.Sheets.HorizontalAlign.left;
DEFAULT_BOLD_STYLE_LEFT_DIVIDER.font = '500 12px Inter';
DEFAULT_BOLD_STYLE_LEFT_DIVIDER.borderTop = GRAY_BORDER;

export const DEFAULT_STYLE_CENTERED_DIVIDER = DEFAULT_STYLE.clone();
DEFAULT_STYLE_CENTERED_DIVIDER.font = '400 12px Inter';
DEFAULT_STYLE_CENTERED_DIVIDER.vAlign = GC.Spread.Sheets.VerticalAlign.center;
DEFAULT_STYLE_CENTERED_DIVIDER.hAlign = GC.Spread.Sheets.HorizontalAlign.center;
DEFAULT_STYLE_CENTERED_DIVIDER.borderTop = GRAY_BORDER;
DEFAULT_STYLE_CENTERED_DIVIDER.borderBottom = undefined;

export const TOTALS_STYLE_DIVIDER = DEFAULT_STYLE.clone();
TOTALS_STYLE_DIVIDER.borderTop = GRAY_BORDER;
TOTALS_STYLE_DIVIDER.hAlign = GC.Spread.Sheets.HorizontalAlign.center;
TOTALS_STYLE_DIVIDER.vAlign = GC.Spread.Sheets.VerticalAlign.center;
TOTALS_STYLE_DIVIDER.font = '500 12px Inter';

export const PERCENTAGE_BOLD_STYLE_CENTERED_DIVIDER = DEFAULT_PERCENT.clone();
PERCENTAGE_BOLD_STYLE_CENTERED_DIVIDER.formatter = "0.0%";
PERCENTAGE_BOLD_STYLE_CENTERED_DIVIDER.font = '500 12px Inter';
PERCENTAGE_BOLD_STYLE_CENTERED_DIVIDER.vAlign = GC.Spread.Sheets.VerticalAlign.center;
PERCENTAGE_BOLD_STYLE_CENTERED_DIVIDER.hAlign = GC.Spread.Sheets.HorizontalAlign.center;
PERCENTAGE_BOLD_STYLE_CENTERED_DIVIDER.borderRight = BORDER;
PERCENTAGE_BOLD_STYLE_CENTERED_DIVIDER.borderTop = GRAY_BORDER;

export const DEFAULT_BOLD_STYLE_DIVIDER = DEFAULT_STYLE.clone();
DEFAULT_BOLD_STYLE_DIVIDER.font = '500 12px Inter';
DEFAULT_BOLD_STYLE_DIVIDER.borderBottom = BORDER;
DEFAULT_BOLD_STYLE_DIVIDER.borderLeft = BORDER;
DEFAULT_BOLD_STYLE_DIVIDER.borderRight = BORDER;
DEFAULT_BOLD_STYLE_DIVIDER.borderTop = GRAY_BORDER;



export const DEFAULT_BOLD_STYLE_BORDER = DEFAULT_STYLE.clone();
DEFAULT_BOLD_STYLE_BORDER.font = '500 12px Inter';
DEFAULT_BOLD_STYLE_BORDER.borderBottom = undefined;
DEFAULT_BOLD_STYLE_BORDER.borderLeft = BORDER;
DEFAULT_BOLD_STYLE_BORDER.borderRight = BORDER;
DEFAULT_BOLD_STYLE_BORDER.borderTop = BORDER;

export type UnitTypeScheduleValuePick<TKey extends keyof UnitTypeScheduleValue> = Pick<UnitTypeScheduleValue, TKey | "monthIndex" | "id" | "unitCount"> & {unitType: Pick<UnitType, "id" | "name">};

export type WeightFn<TKey extends keyof UnitTypeScheduleValue> = (value: UnitTypeScheduleValuePick<TKey> | UnitTypeScheduleValue) => number;
export type WeightFnV2<TKey extends keyof UnitTypeScheduleValue> = {
    fn: (value: UnitTypeScheduleValuePick<TKey> | UnitTypeScheduleValue) => number;
    fields: TKey | TKey[];
}

export type TColumnAggregator = "AVERAGE" | "WEIGHTED_AVERAGE";

export function average<TKey extends keyof UnitTypeScheduleValue>(values: UnitTypeScheduleValuePick<TKey>[], field: TKey, weightFn?: WeightFn<TKey>, columnAggregator?: TColumnAggregator): number {
    if (columnAggregator === "AVERAGE") {
        return values.map(value => parseFloat(value?.[field] ?? "0") ?? 0)?.average() ?? [];
    }
    if (columnAggregator === "WEIGHTED_AVERAGE" && weightFn) {
        const weights = values.map(value => weightFn(value));
        const sumWeights = weights.sum();
        const product = values.map(value => (parseFloat(value?.[field] ?? "0") ?? 0) * weightFn(value)).sum();
        return (product / sumWeights) ?? 0;
    }
    return 0;
}


export type HandleSaveValues = (versionId: string, values: UnitTypeScheduleValueUpsertInput[]) => Promise<void>
export function useSaveUnitTypeScheduleValues(propertyId: string, field: keyof UnitTypeScheduleValue): HandleSaveValues {
    const [upsertUnitTypeScheduleValues] = useUpsertUnitTypeScheduleValuesMutation();

    return async (versionId: string, values: UnitTypeScheduleValueUpsertInput[]): Promise<void> => {
        await upsertUnitTypeScheduleValues({
            variables: {
                propertyId,
                versionId,
                fieldName: field,
                values
            }
        });
    };
}


type HandleSaveOperationalMetric = (versionId: string, monthIndexes: number[], values: string[]) => void
export function useSaveOperationalMetric<TKey extends keyof OperationalMetric>(propertyId: string, field: TKey): HandleSaveOperationalMetric {

    const [save] = useUpsertOpMetricValuesMutation();

    return async (versionId: string, monthIndexes: number[], values: string[]) => {
        await save({
            variables: {
                propertyId,
                versionId,
                fieldName: field,
                monthIndexes,
                values
            }
        });
    };


}
