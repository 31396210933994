/*
A common helper functions collection which can be used across the UI application
 */

export function dateUtcNow(): Date {
    const date = new Date();
    return new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
}

export function ensureDateInLocale(d: Date | string | null | undefined): Date | undefined {
    if(!d) {
        return undefined;
    }

    if(typeof d === "string") {
        d = new Date(d);
    }

    // Javascript dates only support current locale or UTC.
    // So, we only worry about dealing with those.
    // We can't actually determine that a value was created in UTC,
    // so we just go under certain assumptions with hours, minutes, seconds
    // being 0 in UTC.
    if(d.getUTCHours() === 0 && d.getUTCMinutes() === 0 && d.getUTCSeconds() === 0) {
        return new Date(d.getUTCFullYear(), d.getUTCMonth(), d.getUTCDate());
    }
    return d;
}

export function ensureDateInUtc(d: Date | string | null | undefined): Date | undefined {
    if(!d) {
        return undefined;
    }

    if(typeof d === "string") {
        d = new Date(d);
    }

    if(d.getUTCHours() !== 0 || d.getUTCMinutes() !== 0 || d.getUTCSeconds() !== 0) {
        return new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate()));
    }
    return d;
}

export function getPrecisedFloatFromString(sample:(string|undefined|null), precision = 0):(number|null) {
    if(
        !sample
        || isNaN(parseFloat(sample))
    ) {
        return null;
    }
    return parseFloat(parseFloat(sample).toFixed(precision));
}

export function getPrecisedFloatFromNumber(sample:(number|undefined|null), precision = 0):(number|null) {
    if(!sample && sample != 0) {
        return null;
    }
    return parseFloat(sample.toFixed(precision));
}

export function getPrecisedStringFromString(sample:(string|undefined|null), precision = 0):(string|null) {
    if(
        !sample
        || isNaN(parseFloat(sample))
    ) {
        return null;
    }
    return parseFloat(sample).toFixed(precision);
}

export const UndefinedSafeStringify = (sample: any, spacing = '\t') => {
    return JSON.stringify(
        sample,
        (_, v) => v === undefined ? "#undefined#" : v,
        spacing
    );
};

/**
 * Expects a number value representing a year, returns an abbreviated string representation. (e.g. 2023 => "'23")
 * @param thisYear
 */
export const getAbbreviatedYear = (thisYear:number):string => {
    const yearStr = thisYear.toString().slice(-2);
    return `'${yearStr}`;
};

/**
 * Expects a year and a zero-indexed month value, returns a combined number with the month as a decimal.
 * (e.g. (2023, 4) => 2023.04)
 * @param year
 * @param month
 */
export const getYearMonthKey = (year:number, month: number):number => {
    return year + (month/100);
};
