// TODO: Remove this eslint-disable + console calls
/* eslint-disable @typescript-eslint/no-empty-function,no-console */
import {
    Dispatch,
    ReactElement,
    SetStateAction,
    useEffect,
    useLayoutEffect,
    useRef,
    useState
} from "react";

import {
    FinancialEntityType,
} from "../../../../__generated__/generated_types";

import { useConfig } from "../../../analyst/config/useConfig";

import { NewSpreadsheet } from "../../../../components/spreadsheet/NewSpreadsheet";
import { useNewSpreadsheetAPI } from "../../../../components/spreadsheet/NewSpreadsheetHooks";
import viewTemplate from "../../../../sjs/templates/two_years_sjs_view.json";

import * as styles from "../styles/styles.module.scss";

import { TwoYearsConfig as TWO_YR_CFG } from "../../../../sjs/layout/two-years/helpers/TwoYearsConfig";
import useFinancialsData from "../data/useFinancialsData";

import FinancialsLayout from "./FinancialsLayout";

import { FinancialsConfig as CFG } from "../FinancialsConfig";

import { AccurateValueTypesChangedCell, getMonthIdx, restoreCellValue } from "./logic/financialsSpread";
import { FinancialsLoadPhases } from "../FinancialsV2PlanningHubV1Shim";
import useTimingEventReporter from "../../../../utils/hooks/useTimingEventReporter";
import { CellHitInfo } from "../../../../components/spreadsheet/NewSpreadsheetTypes";

export type FinancialsSJSSpreadProps = {
    setViewReady: Dispatch<SetStateAction<boolean>>,
    setDataReady: Dispatch<SetStateAction<boolean>>,
    reportLoadProgress: (progressAmt: number|string) => void,
}

const FinancialsSpread = ({
                              setViewReady,
                              setDataReady,
                              reportLoadProgress,
                          }: FinancialsSJSSpreadProps): ReactElement => {
    const ref = useRef<HTMLDivElement>(null);

    const config = useConfig();

    const {
        financialsData,
        setCurrentProperties,
        fetchEntityYearValuesMixed,
        updateEntityValue,
        getFinancialEntitiesMetaData,
        updateFinancialEntitiesMetaData,
        applyRowMetaUpdatesRecord,
        getRowDirectChildrenMeta,
        dataLoaded: financialTableDataLoaded,
        networkStatus: financialsDataNetworkStatus
    } = useFinancialsData(
            config.properties,
            (progressAmt: number|string) => {
                if(!initialRenderComplete){
                    reportLoadProgress(progressAmt);
                }
            },
    );

    const [layout, setLayout] = useState<FinancialsLayout|undefined>();

    const [initialRenderComplete, setInitialRenderComplete] = useState<boolean>(false);

    const {
        handlers: sshandlers,
        api: ssapi,
        isSpreadsheetReady,
        cellsChanged,
        cellClicked,
        cellTargetClicked,
    } = useNewSpreadsheetAPI();

    const {
        registerTimingEvent,
        registerTimingEventDone,
    } = useTimingEventReporter({
        timingEventRegistered: (evt: string): void => {
            console.log(evt);
        },
        isEnabled: false,
    });

    useEffect(
            () => {
                registerTimingEvent('FincialsSpread mount', 'FinancialsSpread');
            },
            []);

    // INTERNAL LOAD PROGRESS HANDLING _______________________________________________________________________________________________
    // We want to be sure that load progress is registered before performing certain operations
    const [internalLoadProgress, setInternalLoadProgress] = useState<number|string>();
    useEffect(
            () => {
                if(!internalLoadProgress){
                    return;
                }
                reportLoadProgress(internalLoadProgress);
            },
            [internalLoadProgress]
    );

    // COMPONENT EFFECTS _______________________________________________________________________________________________
    /**
     * Called when the div the spreadsheet is in changes height.
     * Tells spreadsheet layout helper to update the host height to the new container height.
     */
    useLayoutEffect(
            () => {
                if(layout && ref.current && ref.current.id == CFG.SJS_CELL_ID){
                    const containerHeight = ref.current.offsetHeight;
                    const containerWidth = ref.current.offsetWidth;
                    if(containerHeight && containerWidth){
                        layout.setHostDimensions(containerHeight, containerWidth);
                    }
                }
            });

    // DATA EFFECTS ----------------------------------------------------------------------------------------------------
    const hasUnloadedChildren = (sheetRow: number): boolean => {
        const checkChildrenEvt = registerTimingEvent('hasUnloadedChildren', 'FinancialsSpread');
        let foundUnloadedChildren = false;
        const rowMeta = financialsData.rowMetaData[sheetRow - TWO_YR_CFG.FIRST_DATA_ROW];
        if(rowMeta){
            const directChildRows = rowMeta.directChildRows.map(childRow => {
                return financialsData.rowMetaData[childRow.dataRow];
            });

            const unloadedChildren = directChildRows.filter(child => child && child.loadState != 'LOADED') ?? [];
            foundUnloadedChildren = unloadedChildren.length != 0;
        }
        registerTimingEventDone(checkChildrenEvt, `foundUnloadedChildren? ${foundUnloadedChildren.toString()}`);
        return foundUnloadedChildren;
    };

    function handleCellClick(layout: FinancialsLayout, cellClicked: CellHitInfo) {
        const { row, col } = cellClicked;

        registerTimingEvent('cellClicked; calling onCellSelect()...', 'FinancialsSpread');
        layout.onCellSelect(row, col, TWO_YR_CFG.MAIN_TAB_NAME);

        // If the clicked cell was a row label...
        if(col == TWO_YR_CFG.LABEL_COL){
            const rowMeta = getFinancialEntitiesMetaData(row - TWO_YR_CFG.FIRST_DATA_ROW)[0];
            if(
                !rowMeta
                || (rowMeta.type != FinancialEntityType.Component
                    && rowMeta.type != FinancialEntityType.Category
                    && rowMeta.type != FinancialEntityType.Account)
                    ){
                        return;
                    }

                    if(rowMeta.hasBeenExpanded){
                        // If the row has already been expanded (and loaded and styled) JUST toggle open state (no other checks)
                        const newIsExpanded = !rowMeta.isExpanded;
                        applyRowMetaUpdatesRecord({[rowMeta.id]: {isExpanded: newIsExpanded}});
                        layout.setCellOpenState(row, newIsExpanded);
                        layout.setGroupOpenState(row, newIsExpanded);
                        if(rowMeta.type === FinancialEntityType.Account){
                            layout.applyAccountRowStyle(row, {
                                ...rowMeta,
                    isExpanded: newIsExpanded
                });
            } else {
                layout.applyCategoryRowStyle(row, rowMeta.depth, newIsExpanded);
            }
        } else {
            if(rowMeta.isExpanded){
                const closeStartEvt = registerTimingEvent('Closing row...', 'FinancialsSpread');
                const openRows = getFinancialEntitiesMetaData(
                    rowMeta.dataRow,
                    (rowMeta.summarySheetRow ?? 0) - TWO_YR_CFG.FIRST_DATA_ROW
                    ).filter(rowMeta => rowMeta.isExpanded);

                    const updatedRowMeta = layout.closeSheetRowByMeta(openRows);

                    updateFinancialEntitiesMetaData(updatedRowMeta);
                    registerTimingEventDone(closeStartEvt);
                } else {
                    if(openingRow == -1){

                        if(!rowMeta.hasBeenExpanded && rowMeta.directChildRows.length > 0){
                            setInternalLoadProgress(FinancialsLoadPhases.OPENING_ROW);
                        }
                        setTimeout(
                            () => {
                                setOpeningRow(row as number);
                            },
                            0);
                        }
                    }

                }
            }
    }

    useEffect(
            () => {
                if(
                        !financialTableDataLoaded
                        || !layout
                        || financialsData.tableData.length == 0
                        || financialsData.rowMetaData.length == 0
                ){
                    return;
                }
                if(!initialRenderComplete){
                    setInitialRenderComplete(true);
                    layout.renderInitialViewTableRows(financialsData, setDataReady);
                } else {
                    layout.applyFinancialsDataUpdate(financialsData);

                    // Check children rows loaded
                    if(!hasUnloadedChildren(openingRow)){
                        registerTimingEvent('Child rows loaded; Opening row DONE', 'FinancialsSpread');
                        reportLoadProgress(100);
                        setOpeningRow(-1);
                    }
                }
            },
            [financialTableDataLoaded, financialsData, financialsDataNetworkStatus],
    );

    // SPREADSHEET EFFECTS _____________________________________________________________________________________________
    /**
     * Initializes the spreadsheet layout helper when the spreadsheet and environment data is ready
     */
    useEffect(
            () => {
                if(!isSpreadsheetReady || !config.isReady){
                    return;
                }

                setLayout(new FinancialsLayout({
                    name: 'Financials',
                    template: viewTemplate,
                    ssapi,
                    config,
                    reportLoadProgress: (progressAmt: number): void => {
                        // TODO: Think about ways to eliminate this chicanery (50% is about where this phase happens in the Financials loading process)
                        setInternalLoadProgress(50 + (50 * (progressAmt / 100)));
                    },
                    onRenderDone: () => {
                        setViewReady(true);
                        setDataReady(true);
                    },
                    registerTimingEvent,
                }));
            },
            [isSpreadsheetReady, config.isReady]
    );

    useEffect(
            () => {
                if(!layout || !config.isReady){
                    return;
                }

                // console.log('config', config);

                // TODO: Switch to whatever is enabled in the property filter. For now, pull all.
                setCurrentProperties(config.properties);

                // DEMO: single property selected in property filter
                // if(config.currentProperty){
                //     setCurrentProperties([config.currentProperty]);
                // }
            },
            [layout, config.isReady]
    );

    const [openingRow, setOpeningRow] = useState<number>(-1);
    useEffect(
            () => {
                if(openingRow < 0 || !layout){
                    return;
                }

                const rowMeta = getFinancialEntitiesMetaData(openingRow - TWO_YR_CFG.FIRST_DATA_ROW);
                if(!rowMeta[0]?.hasBeenExpanded){
                    setInternalLoadProgress(75);
                }

                registerTimingEvent('Opening row...', 'FinancialsSpread');
                const {
                    metaRowUpdatesRecord,
                    unloadedEntities
                } = layout.requestRowOpenByMeta(
                        rowMeta,
                        getRowDirectChildrenMeta(openingRow - TWO_YR_CFG.FIRST_DATA_ROW)
                );

                if(metaRowUpdatesRecord){
                    applyRowMetaUpdatesRecord(metaRowUpdatesRecord);
                }

                if(Object.keys(unloadedEntities).length > 0){
                    setInternalLoadProgress(75);

                    // TODO: Update to use properties filter when present
                    fetchEntityYearValuesMixed(unloadedEntities, config.properties, 'UPDATE');
                } else {
                    registerTimingEvent('Child rows loaded; Opening row DONE', 'FinancialsSpread openingRow useEffect');
                    // Check children rows loaded
                    if(!hasUnloadedChildren(openingRow)){
                        reportLoadProgress(100);
                        setOpeningRow(-1);
                    }
                }
            },
            [openingRow, layout],
    );

    useEffect(() => {
        if (layout && cellClicked){
            handleCellClick(layout, cellClicked);
        }
    }, [cellClicked]);

    useEffect(() => {
        if (layout && cellTargetClicked){
            handleCellClick(layout, cellTargetClicked);
        }
    }, [cellTargetClicked]);

    useEffect(
            () => {
                if(!config.isReady || !isSpreadsheetReady || !initialRenderComplete || cellsChanged.length == 0){
                    return;
                }

                const validCellsChanged = cellsChanged.filter((cell: AccurateValueTypesChangedCell) => {
                    if(typeof cell.value == 'string' && isNaN(parseInt(cell.value))){
                        restoreCellValue(cell, ssapi);
                        return false;
                    }
                    return true;
                });

                if(validCellsChanged.length == 0){
                    return;
                }

                validCellsChanged.forEach(cell => {
                    const rowMeta = getFinancialEntitiesMetaData(cell.row - TWO_YR_CFG.FIRST_DATA_ROW)[0];
                    const monthIdx = getMonthIdx(cell, config);

                    if(rowMeta && monthIdx){
                        updateEntityValue(cell.value, monthIdx, rowMeta);
                    }
                });
            },
            [config.isReady, isSpreadsheetReady, initialRenderComplete, cellsChanged],
    );

    return (
        <div
            id={CFG.SJS_CELL_ID}
            ref={ref}
            className={styles.sheetContainer}
        >
            <NewSpreadsheet
                startRow={0}
                startCol={0}
                rows={9}
                cols={16}
                handlers={sshandlers}
                allowVerticalScroll={true}
                allowHorizontalScroll={true}
                subscribeToMouse={true}
                safeAreaFirstRow={3}
                safeAreaFirstColumn={2}
                style={{
                    maxWidth: "calc(100vw - 80px)",
                    maxHeight: "calc(100vh - 208px - 26px)", // 208: header, 26: spreadsheet horiz. scrollbar
                    padding: "0 0",
                }}
                allowZoom={false}
            />
        </div>
    );
};

export default FinancialsSpread;
