import { Destination } from "../navigation/useNavigation";
import { ReportsNavMenuConfig } from "../ReportsNavMenuConfig";

export function parseCurrentDestination(path: string): Destination | undefined {
    let currentDestination: Destination | undefined = undefined;
    if (path.endsWith(ReportsNavMenuConfig.OVERVIEW_PATH)) {
        currentDestination = Destination.Overview;
    }
    else if (path.endsWith(ReportsNavMenuConfig.LINE_ITEMS_PATH)) {
        currentDestination = Destination.LineItems;
    }
    else if(path.endsWith(ReportsNavMenuConfig.MVR_PATH)){
        currentDestination = Destination.MVR;
    }

    return currentDestination;
}