import { ReactElement } from "react";
import { formatterDollarUSNoDecimal } from "../../../../../../../../utils/formatters";
import { AssumptionRowModel } from "../../budget-assumptions/data/models/AssumptionRowModel";

export function buildReferenceAccountHTML(rows: AssumptionRowModel[]): ReactElement[] {
    const driverCount = rows.length;

    if (driverCount > 4) {
        const amount = rows.map(x => x.value).reduce((a, b) => a + b, 0);
        const budget = rows.map(x => x.children[1]?.value ?? 0).reduce((a, b) => a + b, 0);
        const rowData = buildRowData(amount, budget);

        // if we have more than 4 drivers, consolidate them all down into a single line
        return ([
            <li key={1}>
                {driverCount} referenced GL accounts amounted to {rowData.formattedAmount}, which is {rowData.formattedDifference} {rowData.directionText} budget <strong>{rowData.formattedBudget}</strong>
            </li>
        ]);
    } else {
        // if we have 4 or less drivers, return an array with each line formatted individually
        return rows.map(row => {
            const amount = row.value;
            const budget = row?.children[1]?.value ?? 0;
            const rowData = buildRowData(amount, budget);

            return (
                <li key={row.id}>
                    {row.id} - {row.name} amounted to {rowData.formattedAmount}, which is {rowData.difference == 0 ? '' : rowData.formattedDifference} {rowData.directionText} budget <strong>{rowData.formattedBudget}</strong>
                </li>
            );
        });
    }
}

type TRowData = {
    formattedAmount: string,
    formattedBudget: string,
    difference: number,
    formattedDifference: string,
    directionText: string,
};

// internal helper method that returns the formatted strings for a referenceAccount row item
function buildRowData(amount: number, budget: number): TRowData {
    let formattedAmount = formatterDollarUSNoDecimal.format(Math.abs(amount));
    let formattedBudget = formatterDollarUSNoDecimal.format(Math.abs(budget));

    if (amount < 0) {
        formattedAmount = '(' + formattedAmount + ')';
    }

    if (budget < 0) {
        formattedBudget = '(' + formattedBudget + ')';
    }

    const difference = Math.abs(amount - budget);
    const formattedDifference = formatterDollarUSNoDecimal.format(difference);

    let directionText = amount > budget ? 'more than' : 'less than';
    if (amount == budget) {
        directionText = 'even with';
    }

    return {
        formattedAmount,
        formattedBudget,
        difference,
        formattedDifference,
        directionText,
    };
}