import { IAccountSummary } from "../../../pages/workflows/account/AccountSummaryContext";
import * as css from "../styles/styles.module.scss";

export enum AccountSummaryTabOptions {
    summary = 'summary',
    financialImpact = 'financialImpact'
}

export enum AccountChangeDirection {
    GOOD,
    BAD,
    NEITHER,
}

export const AccountChangeColorMap: Record<number, string> = {
    [AccountChangeDirection.GOOD]: css.statGreen,
    [AccountChangeDirection.BAD]: css.statRed,
    [AccountChangeDirection.NEITHER]: '',
};

export interface IAccountSummaryYearResults {
    act: number,
    fcst: number,
    rfcst: number,
    bdgt: number,
}

export interface IAccountSummaryVariance {
    varValue: number,
    varPercentage: number | undefined,
}

export const getTotalsForYear = (year: number, ctx: IAccountSummary): IAccountSummaryYearResults => {
    const ctxYear = ctx.data.years[year] ?? {
        act: [] as number[],
        fcst: [] as number[],
        bdgt: [] as number[],
    };

    const act = ctxYear.act.reduce((total, a) => total + a, 0);
    const fcst = ctxYear.fcst.reduce((total, a) => total + a, 0);
    const bdgt = ctxYear.bdgt.reduce((total, a) => total + a, 0);

    return {
        act: act,
        fcst: fcst,
        rfcst: act + fcst,
        bdgt: bdgt,
    };
};

export const getMonthlyAvgsForYear = (year: number, ctx: IAccountSummary): IAccountSummaryYearResults => {
    const totals = getTotalsForYear(year, ctx);

    return {
        act: totals.act / 12,
        fcst: totals.fcst / 12,
        rfcst: totals.rfcst / 12,
        bdgt: totals.bdgt / 12,
    };
};

export const getVarianceForSet = (newVal: number, oldVal: number): IAccountSummaryVariance => {
    if (newVal == oldVal) {
        return {
            varValue: 0,
            varPercentage: 0,
        };
    }

    const varValue = newVal - oldVal;
    let varPercentage = undefined;

    // avoid dividing by zero
    if (oldVal !== 0) {
        varPercentage = parseFloat((varValue / Math.abs(oldVal) * 100).toFixed(1));
    }

    return {
        varValue,
        varPercentage,
    };
};

export const getAccountChangeDirection = (newVal: number, oldVal: number, isIncomeAccount: boolean | undefined): AccountChangeDirection => {
    if (newVal == oldVal) return AccountChangeDirection.NEITHER;

    if (isIncomeAccount) {
        return newVal > oldVal ? AccountChangeDirection.GOOD : AccountChangeDirection.BAD;
    } else {
        return newVal > oldVal ? AccountChangeDirection.BAD : AccountChangeDirection.GOOD;
    }
};