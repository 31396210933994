import React, {
    useEffect, useState,
    useImperativeHandle, Ref, ForwardRefRenderFunction, forwardRef
} from 'react';
import {
    AccountsTableViewModel,
    useGetTableViewForOverriddenAccountsByPropertyIdAndVersionIdsLazyQuery,
    VersionType
} from "../../../__generated__/generated_types";

import {AccountsTableView} from "../accounts-table-view/AccountsTableView";
import {useAccountsTableViewData} from "../accounts-table-view/hooks/useAccountsTableViewData";

interface OverriddenAccountsTableViewProps {
    onDataUpdate: () => void
}

export interface OverriddenAccountsTableViewRefObject {
    imperativeRefreshTableData: () => void
}

const OverriddenAccountsTableViewRenderer:
    ForwardRefRenderFunction<OverriddenAccountsTableViewRefObject, OverriddenAccountsTableViewProps> = (
    {onDataUpdate}: OverriddenAccountsTableViewProps,
    ref: Ref<OverriddenAccountsTableViewRefObject>
) => {
    const {
        isReady, propertyId, property, startReforecastMonthIndex, year,
        reforecastYearShortForm, budgetYearShortForm,
        starringReforcastVersionId, starringBudgetVersionId,
        finTotalReforcastVersionId, finTotalBudgetVersionId
    } = useAccountsTableViewData();
    const [data, setData] = useState<AccountsTableViewModel[] | undefined>(undefined);

    const [
        fetchTableData,
        {data: tableData, loading: tableDataLoading, error: tableDataLoadingError}
    ] = useGetTableViewForOverriddenAccountsByPropertyIdAndVersionIdsLazyQuery({fetchPolicy: "network-only"});

    const refreshTableData = () => {
        if (isReady && propertyId && startReforecastMonthIndex !== undefined && year &&
            starringReforcastVersionId && starringBudgetVersionId &&
            finTotalReforcastVersionId && finTotalBudgetVersionId) {
            fetchTableData({
                variables: {
                    propertyId: propertyId,
                    reforecastStartMonthIndex: startReforecastMonthIndex,
                    starringMetaArgInput: [
                        {
                            versionId: starringReforcastVersionId,
                            type: VersionType.Reforecast
                        },
                        {
                            versionId: starringBudgetVersionId,
                            type: VersionType.Budget
                        }
                    ],
                    financialTotalValueArgInput: [
                        {
                            versionId: finTotalReforcastVersionId,
                            year: year,
                            type: VersionType.YearTotal
                        },
                        {
                            versionId: finTotalBudgetVersionId,
                            year: year + 1,
                            type: VersionType.Budget
                        }
                    ]
                }
            });
        }
    };

    useImperativeHandle(
        ref,
        () => ({
            imperativeRefreshTableData() {
                refreshTableData();
            }
        }),
        [isReady, propertyId, startReforecastMonthIndex, year,
            starringReforcastVersionId, starringBudgetVersionId,
            finTotalReforcastVersionId, finTotalBudgetVersionId],
    );

    useEffect(() => {
        refreshTableData();
    }, [isReady, propertyId, startReforecastMonthIndex, year,
        starringReforcastVersionId, starringBudgetVersionId,
        finTotalReforcastVersionId, finTotalBudgetVersionId]);

    useEffect(
        () => {
            if (tableDataLoading || tableDataLoadingError || !tableData) {
                return;
            }
            if (tableData.getTableViewForOverriddenAccountsByPropertyIdAndVersionIds) {
                setData(tableData.getTableViewForOverriddenAccountsByPropertyIdAndVersionIds);
            } else {
                setData([]);
            }
        }, [tableData, tableDataLoading, tableDataLoadingError]
    );

    return (
        <AccountsTableView
            headerText={"Overridden Accounts"}
            style={{marginBottom: "35px"}}
            propertyId={propertyId}
            reforecastYear={property?.reforecastYear ?? 0}
            tableData={data}
            reforecastYearShortForm={reforecastYearShortForm ?? ""}
            budgetYearShortForm={budgetYearShortForm ?? ""}
            onStarringUpdate={() => {
                refreshTableData();
                onDataUpdate();
            }}
            onNoteUpdate={() => {
                // refreshTableData();
                onDataUpdate();
            }}
        />
    );
};

const OverriddenAccountsTableView = forwardRef(OverriddenAccountsTableViewRenderer);
export default OverriddenAccountsTableView;
