import { ReactElement } from "react";
import * as css from "./styles/css.module.scss";

interface IWorksheetDriverItemNodeProps {
    locked: boolean,
    renoCostName: string,
    className?: string,
}

export default function RenovationsDriverItemNode(props: IWorksheetDriverItemNodeProps):ReactElement {

    let driverNameRender: string|ReactElement = props.renoCostName;
    let nodeClassName = css.fxNodeLocked;

    if(!props.locked){
        driverNameRender = <a>{props.renoCostName}</a>;
        nodeClassName = css.fxNode;
    }

    return (
            <span className={props.className ? `${nodeClassName} ${props.className}` : nodeClassName}>
                {driverNameRender}
            </span>
    );
}
