/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
export const isEmpty = (data: any): boolean => {
    return data == null || typeof data === "undefined" || data === "";
};

export const isEmailValid = (email: string) => {
    if (isEmpty(email)) return false;
    const re =
        // eslint-disable-next-line no-useless-escape
        /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    return email.match(re);
};

export const stringify = (item: any) => {
    if (isEmpty(item)) return "";
    return JSON.stringify(item);
};

export const parseJSON = (data: any) => {
    if (isEmpty(data)) return null;
    if (typeof data !== "string") return data;
    return JSON.parse(data);
};

export const saveStoreItem = (key: string, data: any) => {
    if (isEmpty(data)) return false;
    localStorage.setItem(key, stringify(data));
    return true;
};

export const getStoreItem = (key: string) => {
    if (isEmpty(key)) return null;
    return parseJSON(localStorage.getItem(key));
};


export const fromLocaleStringToNumber = (str: string)=> {
    if(isEmpty(str)) return 0;
    return parseInt(str.replace(/\D/g,''));
};