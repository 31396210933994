import {
        BudgetComponentType,
        FinancialValuesYearBareModel,
        GetPropertyAccountDriverStatusQuery,
        GetPropertyExecutiveSummaryDataQuery,
        ReportTableViewModel,
        VersionType
} from "../../../__generated__/generated_types";
import { FinancialEntity } from "../../../contexts/chartofaccounts/ChartOfAccountsContext";
import { Property } from "../../../contexts/properties/PropertiesContext";
import "native-injects";
import { IFinancialsCOATableRow } from "../components/helpers";
import { ModelingMethodType } from "../../reports/monthly-variance-report/components/monthly-variance-modal/components/budget-assumptions/data/enums/ModelingMethodTypeEnum";

const EPSILON = 0.01;

export type ReportTableDataRow = {
    accountId: string;
    name: string;
    order: number;
    reforecastTotal: number | null;
    budgetTotal: number | null;
    varianceAmount: number | null;
    variancePercent: number | null;
    budgetPerUnit: number | null;
    componentType: BudgetComponentType;
    negateAtComponent: boolean;
}

export type ReportTableData = {
    id: string;
    name: string;
    order: number;
    rows: ReportTableDataRow[]
}

export function buildReportTablesData(property: Property,
                                      rawData: GetPropertyExecutiveSummaryDataQuery,
                                      chartOfAccountsFlat: FinancialEntity[],
                                      unitCount: number): ReportTableData[] {
    const ret:ReportTableData[] = [];
    const chartOfAccountsFlatById = chartOfAccountsFlat.toIdMap("id");
    for (const rawTableData of rawData.getReportTables) {
        const tableData = _buildReportTableData(property,
                                                rawData,
                                                rawTableData,
                                                chartOfAccountsFlatById,
                                                unitCount);
        ret.push(tableData);
    }
    // ret = ret.sortBy("order");
    return ret;
}

export type TAccountDriverStatus = GetPropertyAccountDriverStatusQuery["queryPropertyAccountDriverStatus"][number];

export function buildFinancialsTableData(property: Property,
                                        rawData: GetPropertyExecutiveSummaryDataQuery,
                                        chartOfAccountsFlat: FinancialEntity[],
                                        accountDriverStatusData: TAccountDriverStatus[]): IFinancialsCOATableRow[] {
    const idOf = (x: FinancialValuesYearBareModel) => `${x.accountId}${x.year}${x.versionType}`;
    const idOfABudget = (accountId: string) => `${accountId}${property.budgetYear}${VersionType.Budget}`;
    const idOfARfrcst = (accountId: string) => `${accountId}${property.reforecastYear}${VersionType.Reforecast}`;
    const ret: IFinancialsCOATableRow[] = [];
    const notesMap = new Map<string, string>();
    for (const note of rawData.queryNotesForProperty) {
        if (note.budgetNote) {
            notesMap.set(note.accountId, note.budgetNote);
        }
    }
    const accountModelingMethodMap = new Map<string, {methods: ModelingMethodType[], hasOverrides: boolean}>();
    for (const accountDriverStatus of accountDriverStatusData) {
        const methods: ModelingMethodType[] = [];
        if (accountDriverStatus.customDriver) {
            methods.push(ModelingMethodType.CUSTOM_DRIVER);
        }
        if (accountDriverStatus.operational) {
            methods.push(ModelingMethodType.OP_DRIVER);
        }
        if (accountDriverStatus.worksheet) {
            methods.push(ModelingMethodType.LINE_ITEMS);
        }
        if (accountDriverStatus.growth) {
            methods.push(ModelingMethodType.GROWTH);
        }
        if (accountDriverStatus.payroll) {
            methods.push(ModelingMethodType.PAYROLL);
        }
        if (accountDriverStatus.revenue) {
            methods.push(ModelingMethodType.REVENUE);
        }
        if (accountDriverStatus.renovation) {
            methods.push(ModelingMethodType.RENOVATION_COSTS);
        }
        if (accountDriverStatus.accPercentage > 1) {
            methods.push(ModelingMethodType.PCT_OF_ACCOUNT_MULTI);
        }
        else if (accountDriverStatus.accPercentage > 0) {
            methods.push(ModelingMethodType.PCT_OF_ACCOUNT);
        }
        accountModelingMethodMap.set(accountDriverStatus.accountId, {methods, hasOverrides: accountDriverStatus.hasOverrides});
    }
    const coaCopy: FinancialEntity[] = JSON.parse(JSON.stringify(chartOfAccountsFlat));

    const topLevelAccounts = coaCopy.filter(a => a.parentId == null).map(a => a.id);
    const accountValuesById = rawData.financialValuesPropertyBudgetSeason.toIdMap(x => idOf(x));

    for (const account of coaCopy) {
        if (account.parentId && topLevelAccounts.includes(account.parentId)) {
            account.parentId = undefined;
        }
        if (topLevelAccounts.includes(account.id)) {
            account.children = [];
        }

        const budgetValues = accountValuesById[idOfABudget(account.id)]?.values ?? new Array(12).fill(0);
        const reforecastValues = accountValuesById[idOfARfrcst(account.id)]?.values ?? new Array(12).fill(0);
        const budgetTotal = budgetValues.sum();
        const reforecastTotal = reforecastValues.sum();
        let varianceAmount = null;
        let variancePercent = null;
        if (Math.abs(reforecastTotal) < EPSILON) {
            varianceAmount = Math.abs(budgetTotal) < EPSILON ? 0 : budgetTotal;
            variancePercent = 0;
        } else {
            varianceAmount = budgetTotal - reforecastTotal;
            variancePercent = budgetTotal / reforecastTotal - 1;
        }
        const rowData: IFinancialsCOATableRow = {
            ...account,
            budgetValues,
            isExpanded: false,
            modelingType: accountModelingMethodMap.get(account.id)?.methods ?? [],
            hasOverrides: accountModelingMethodMap.get(account.id)?.hasOverrides ?? false,
            note: notesMap.get(account.id) ?? null,
            isTopLevelAccount: topLevelAccounts.includes(account.id),
            budgetTotal,
            reforecastTotal,
            reforecastValues,
            varianceAmount,
            variancePercent,
        };

        ret.push(rowData);
    }

    return ret;
}

function _buildReportTableData(property: Property,
                               rawData: GetPropertyExecutiveSummaryDataQuery,
                               rawTableData: ReportTableViewModel,
                               chartOfAccountsFlatById: Record<string, FinancialEntity>,
                               unitCount: number): ReportTableData {
    const resultRowsMap = new Map<string, ReportTableDataRow>();
    for (const accountIn of rawTableData.accounts) {
        const coaAccount = chartOfAccountsFlatById[accountIn.accountId];
        if (!coaAccount) {
            continue;
        }
        resultRowsMap.set(coaAccount.id, {
            accountId: coaAccount.id,
            name: coaAccount.name + (coaAccount.number ? ` (${coaAccount.number})` : ""),
            order: coaAccount.order,
            reforecastTotal: null,
            budgetTotal: null,
            varianceAmount: null,
            variancePercent: null,
            budgetPerUnit: null,
            componentType: coaAccount.budgetComponentType,
            negateAtComponent: coaAccount.budgetComponentNegate ?? false,
        });
    }

    for (const accountValues of rawData.financialValuesPropertyBudgetSeason) {
        const row = resultRowsMap.get(accountValues.accountId);
        if (!row) {
            continue;
        }
        if (accountValues.year == property.reforecastYear && accountValues.versionType == VersionType.Reforecast) {
            row.reforecastTotal = accountValues.values.sum();
        }
        else if (accountValues.year == property.budgetYear && accountValues.versionType == VersionType.Budget) {
            row.budgetTotal = accountValues.values.sum();
        }
    }

    for (const row of Array.from(resultRowsMap.values())) {
        if (row.reforecastTotal !== null && row.budgetTotal !== null) {
            if (Math.abs(row.reforecastTotal) < EPSILON) {
                row.varianceAmount = Math.abs(row.budgetTotal) < EPSILON ? 0 : row.budgetTotal;
                row.variancePercent = 0;
            }
            else {
                row.varianceAmount = row.budgetTotal - row.reforecastTotal;
                row.variancePercent = row.budgetTotal / row.reforecastTotal - 1;
            }
        }
        if (row.budgetTotal !== null && Math.abs(unitCount) > EPSILON) {
            row.budgetPerUnit = row.budgetTotal / unitCount;
        }
    }
    const resultRows = Array.from(resultRowsMap.values()).sortBy("order");
    return {
        id: rawTableData.id,
        name: rawTableData.name,
        order: rawTableData.order,
        rows: resultRows
    };

}