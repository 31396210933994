import { PayrollCompensationItemType } from "../../__generated__/generated_types";

export function PayrollCompensationItemTypeLabel(type: PayrollCompensationItemType): string {
    if (type == PayrollCompensationItemType.Medical) {
        return "Medical";
    }
    else if (type == PayrollCompensationItemType.Dental) {
        return "Dental";
    }
    else if (type == PayrollCompensationItemType.Vision) {
        return "Vision";
    }
    else if (type == PayrollCompensationItemType.Retirement_401KMatch) {
        return "401K";
    }
    else if (type == PayrollCompensationItemType.MedicareTax) {
        return "Medicare";
    }
    else if (type == PayrollCompensationItemType.SocialSecurityTax) {
        return "Social Security Tax";
    }
    else if (type == PayrollCompensationItemType.FederalUnemploymentTax) {
        return "Federal Unemployment Tax";
    }
    else if (type == PayrollCompensationItemType.StateUnemploymentTax) {
        return "State Unemployment Tax";
    }
    else if (type == PayrollCompensationItemType.WorkersCompensation) {
        return "Workers Compensation";
    }

    return "";
}