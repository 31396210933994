import "native-injects";
import {useState} from "react";
import {
    useDeleteEmployeesMutation,
    DeleteEmployeesMutationVariables,
    useUpdatePayrollEmployeeDataMutation,
    UpdatePayrollEmployeeDataMutationVariables, useCreateEmployeeMutation, CreateEmployeeMutationVariables
} from "../../../__generated__/generated_types";

type ApiStatus = "INIT" | "IN_PROGRESS" | "COMPLETED"

export default function useCreateUpdateDeleteEmployee() {
    const [createApiStatus, setCreateApiStatus] = useState<ApiStatus>("INIT");
    const [updateApiStatus, setUpdateApiStatus] = useState<ApiStatus>("INIT");
    const [deleteApiStatus, setDeleteApiStatus] = useState<ApiStatus>("INIT");

    const [employeeId, setEmployeeId] = useState<string>("");

    const [createMutation] = useCreateEmployeeMutation({
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        onCompleted(data) {
            // console.info(`create onComplete: data: ${Stringify(data)}`);
            if(data && data.createPayrollEmployeeForBudgetYear && data.createPayrollEmployeeForBudgetYear.id) {
                setCreateApiStatus("COMPLETED");
                setEmployeeId(data.createPayrollEmployeeForBudgetYear.id);
            }
        }
    });

    const createEmployee = (params: CreateEmployeeMutationVariables) => {
        const {
            budgetYear,
            firstName,
            lastName,
            externalSystemEmployeeId,
            type,
            positionId,
            classification,
            startDate,
            terminationDate,
            properties,
            baseComp,
            raises,
            bonuses,
            overtime,
            items
        } = params;
        createMutation({
            variables: {
                budgetYear: budgetYear,
                firstName: firstName,
                lastName: lastName || undefined,
                externalSystemEmployeeId: externalSystemEmployeeId,
                type: type,
                positionId: positionId,
                classification: classification,
                startDate: startDate,
                terminationDate: terminationDate,
                properties: properties,
                baseComp: baseComp,
                raises: raises,
                bonuses: bonuses,
                overtime: overtime,
                items: items
            }
        });
        setCreateApiStatus("IN_PROGRESS");
    };

    const [updateMutation] = useUpdatePayrollEmployeeDataMutation({
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        onCompleted(data) {
            // console.info(`update onComplete: data: ${Stringify(data)}`);
            if(data && data.setPayrollEmployeeData && data.setPayrollEmployeeData.id) {
                setUpdateApiStatus("COMPLETED");
            }
        },
    });
    const updateEmployee = (params: UpdatePayrollEmployeeDataMutationVariables) => {
        // console.info(`updateEmployee payload: ${Stringify(params)}`);
        const {
            id,
            firstName,
            lastName,
            externalSystemEmployeeId,
            type,
            positionId,
            classification,
            startDate,
            terminationDate,
            properties,
            baseComp,
            raises,
            bonuses,
            overtime,
            items
        } = params;
        updateMutation({
            variables: {
                id: id,
                firstName: firstName,
                lastName: lastName || undefined,
                externalSystemEmployeeId: externalSystemEmployeeId,
                type: type,
                positionId: positionId,
                classification: classification,
                startDate: startDate,
                terminationDate: terminationDate,
                properties: properties,
                baseComp: baseComp,
                raises: raises,
                bonuses: bonuses,
                overtime: overtime,
                items: items
            }
        });
        setUpdateApiStatus("IN_PROGRESS");
    };

    const [deleteMutation] = useDeleteEmployeesMutation({
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        onCompleted(data) {
            // console.info(`delete onComplete: data: ${Stringify(data)}`);
            if(data) {
                setDeleteApiStatus("COMPLETED");
            }
        },
    });

    const deleteEmployee = (params: DeleteEmployeesMutationVariables) => {
        // console.info(`deleteEmployee payload: ${Stringify(params)}`);
        const {employeeIds} = params;
        deleteMutation({
            variables: {
                employeeIds: employeeIds
            }
        });
        setDeleteApiStatus("IN_PROGRESS");
    };

    return {
        createEmployee,
        updateEmployee,
        deleteEmployee,
        employeeId,
        createApiStatus,
        updateApiStatus,
        deleteApiStatus,
    };
}
