import {
    EnrichedUnitEventModel,
    UnitEventType, UnitModel
} from "../../../../../__generated__/generated_types";
import { DateTime } from "luxon";
import { ChartBackgroundClickInfo, HCGanttSeries } from "../../helpers/types";
import {
    colorIdxByEventType,
} from "../highchartsGanttDataXform";

import {getPrecisedFloatFromString} from "../../../../../utils/helper-funcs";

export const eventTypeToString = (type: UnitEventType): string => {
    const evtTypeStrMap = {
        [UnitEventType.MonthToMonth]: 'Month to Month',
        [UnitEventType.Renewal]: 'Renewal',
        [UnitEventType.Renovation]: 'Renovation',
        [UnitEventType.ShortTermRental]: 'Short Term Rental',
        [UnitEventType.TermLease]: 'Term Lease',
    };
    return evtTypeStrMap[type] ?? '';
};

export const getEventTitleString = (evt: EnrichedUnitEventModel): string => {
    let labelString = eventTypeToString(evt.eventType);

    if(evt.tenantName){
        labelString += ` - ${evt.tenantName}`;
    }

    return labelString;
};

export const getEventSubtitleString = (evt: EnrichedUnitEventModel): string => {
    const { eventStart, eventEnd, eventType, startingLossToLease, endingLossToLease, inPlaceRent } = evt;

    const evtStartString = DateTime.fromISO(eventStart, {setZone: true}).toFormat('MM/dd/yyyy');
    const evtEndString = DateTime.fromISO(eventEnd, {setZone: true}).toFormat('MM/dd/yyyy');

    const startingLTL = startingLossToLease ? `${getPrecisedFloatFromString(startingLossToLease, 1)}%` : `n/a`;
    const endingLTL = endingLossToLease ? `${getPrecisedFloatFromString(endingLossToLease, 1)}%` : `n/a`;

    let labelString = `${evtStartString} &#8594 ${evtEndString}`;

    if(eventType !== UnitEventType.Renovation){
        labelString += ` &bull; LTL Ratio: ${startingLTL} &rarr; ${endingLTL}`;
    }

    if(inPlaceRent){
        const formattedInPlaceRent = getPrecisedFloatFromString(inPlaceRent);
        labelString += ` &bull; In Place Rent: $${formattedInPlaceRent}`;
    }

    return labelString;
};

/*
TODO
1.  thisUnit.events.forEach should break and return if the timestamp is found within the event range
    So for n number of events on the unit, if the clicked event is first one,
        then there is no need to loop over remaining n-1 events
2.  Now, If the hcGanttSeries is not found from step 1 then it means it is empty space click
    So, we can just loop over the events and then find out the previous and next event
        So, overall time complexity should come to `n` in stead of current `n^2`
3. If the events are sorted by starDate, we can do the binary search and find the correspoding
    clickedEvent, prevEvent and nextEvent in `logn` :)
 */
export const getChartBackgroundClickInfo = (unitIdx:number, timestamp:number, year:number, data:any):(HCGanttSeries|ChartBackgroundClickInfo|undefined) => {
    const thisUnit:UnitModel = data.items[unitIdx];

    let hcGanttSeries:HCGanttSeries|undefined;
    let chartBackgroundInfo:ChartBackgroundClickInfo|undefined;

    const minDate = Date.UTC(year, 0);

    if(thisUnit){
        const unitModelEvents = thisUnit.events
            .filter(evt => Date.parse(evt.eventEnd) >= minDate)
            .sortBy( evt => evt.eventStart);

        unitModelEvents.forEach((evt, evtIdx) => {
            const evtStartDate = Date.parse(evt.eventStart);
            const evtEndDate = Date.parse(evt.eventEnd);

            if(timestamp >= evtStartDate && timestamp <= evtEndDate){
                hcGanttSeries = {
                    start: evtStartDate,
                    end: evtEndDate,
                    name: evt.eventType,
                    y: unitIdx,
                    colorIndex: colorIdxByEventType(evt.eventType, !!evt.importedEvent),
                    borderRadius: 20,
                    custom: {
                        unit: thisUnit,
                        currentEvent: evt,
                        prevEvent: unitModelEvents[evtIdx - 1] ?? null,
                        nextEvent: unitModelEvents[evtIdx + 1] ?? null,
                        eventId: evt.id,
                        propertyId: evt.propertyId,
                        unitId: evt.unitId,
                        eventType: evt.eventType,
                        importedEvent: evt.importedEvent,
                        inPlaceRent: evt.inPlaceRent,
                        startingCalculatedInPlaceRent: evt.startingCalculatedInPlaceRent,
                        endingCalculatedInPlaceRent: evt.endingCalculatedInPlaceRent,
                        startingMarketRent: evt.startingMarketRent,
                        endingMarketRent: evt.endingMarketRent,
                        startingLossToLease: evt.startingLossToLease,
                        endingLossToLease: evt.endingLossToLease,
                        concessionMonths: evt.concessionMonths,
                        renewalAdjustmentPercentage: evt.renewalAdjustmentPercentage,
                        renovationCost: evt.renovationCost,
                        renovationPremium: evt.renovationPremium,
                        shortTermEffectiveAvgDailyRent: evt.shortTermEffectiveAvgDailyRent,
                        eventStart: evt.eventStart,
                        eventEnd: evt.eventEnd,
                        strings:{
                            eventTitle: getEventTitleString(evt),
                            eventSubtitle: getEventSubtitleString(evt),
                        },
                    },
                };
            } else {
                const unitModelSelected = {
                    id: thisUnit.id,
                    number: thisUnit.number,
                    unitTypeId: thisUnit.unitTypeId,
                };
                if(thisUnit.events.length > 0){
                    let lastEvent:EnrichedUnitEventModel|null = null;
                    let nextEvent:EnrichedUnitEventModel|null = null;

                    thisUnit.events
                        .filter(evt => Date.parse(evt.eventEnd) >= minDate)
                        .sortBy(evt => evt.eventStart)
                        .forEach((evt)=>{
                            const evtStart = Date.parse(evt.eventStart);
                            const evtEnd = Date.parse(evt.eventEnd);

                            if(evtStart > timestamp && nextEvent === null){
                                nextEvent = evt;
                            }
                            if(evtEnd < timestamp){
                                lastEvent = evt;
                            }
                        });
                    chartBackgroundInfo = {
                        unit: unitModelSelected,
                        clickedTs: timestamp,
                        prevEvent: lastEvent,
                        nextEvent: nextEvent,
                    };
                } else{
                    chartBackgroundInfo = {
                        unit: unitModelSelected,
                        clickedTs: timestamp,
                        prevEvent: null,
                        nextEvent: null,
                    };
                }
            }
        });

        /*
        This is a temporary fix so as not to cause any regression
        We would get back to this as per the function top comment
        and refactor it :pray:
         */
        if(unitModelEvents.length < 1) {
            const unitModelSelected = {
                id: thisUnit.id,
                number: thisUnit.number,
                unitTypeId: thisUnit.unitTypeId,
            };
            chartBackgroundInfo = {
                unit: unitModelSelected,
                clickedTs: timestamp,
                prevEvent: null,
                nextEvent: null,
            };
        }
    }

    if(hcGanttSeries !== undefined){
        return hcGanttSeries;
    } else {
        return chartBackgroundInfo;
    }
};
