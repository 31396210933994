import { DistributionMethod, GetPayrollCompensationItemsQuery, GetPayRolPositionsQuery, PayrollPrimaryCompensationItem, PercentGrowthBasePeriod } from "../../../__generated__/generated_types";
import { TDriverGrowthMetric } from "./logic/driversAndWorksheetData";


export type CompensationItemType = GetPayrollCompensationItemsQuery["listCompensationItems"][0]["type"]
  | PayrollPrimaryCompensationItem;

export interface IPayrollCompensationItem {
    customName?: string | null;
    id: string;
    type: CompensationItemType;
}

export type TListPayrollPositions = GetPayRolPositionsQuery["listPayrollPositions"];

export interface IPayrollDriver extends IPayrollCompensationItem {
    positions: TListPayrollPositions | undefined,
}

/**
 * [key in string] instead of [key in CompensationItemType] since the `itemType`
 * that comes from the backend is a string, and also Zendesk menu item values are strings
 */
export type CompItemsCustomNamesMap = {
  [key in string]: string;
};

export const compItemsCustomNamesMap: CompItemsCustomNamesMap = {
    COMPENSATION: 'Base Pay',
    BONUS: 'Bonuses',
    OVERTIME: 'Overtime',
    CUSTOM: 'Custom',
    FEDERAL_UNEMPLOYMENT_TAX: 'Federal Unemployment Tax',
    MEDICARE_TAX: 'Medicare Tax',
    STATE_UNEMPLOYMENT_TAX: 'State Unemployment Tax',
    WORKERS_COMPENSATION: 'Workers Compensation',
    SOCIAL_SECURITY_TAX: 'Social Security Tax',
    RETIREMENT_401K_MATCH: '401K',
    VISION: 'Vision',
    DENTAL: 'Dental Benefits',
    MEDICAL: 'Medical',
};

export type TAllAvailableDrivers = {
    payroll: IPayrollDriver[] | undefined,
    customItems: string[]
}

export const distributionMethodMap = {
  [DistributionMethod.Flat]: "even distribution",
  [DistributionMethod.Seasonal]: "seasonal distribution",
};

export const invertedDistributionMethodMap: { [key: string]: DistributionMethod } = {
  "even distribution": DistributionMethod.Flat,
  "seasonal distribution": DistributionMethod.Seasonal,
};
