import {SJSSheetStyles as SJSS} from "../../../../sjs/layout/shared/SJSSheetStyles";
import {COLORS} from "../../../../constants/Colors";

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace SummarySJSStyles {
    export const BASIC_CELL = SJSS.BASE.clone();
    BASIC_CELL.cellPadding = '8';
    BASIC_CELL.font = '500 12px Inter';
    BASIC_CELL.foreColor = "#0000000";
    BASIC_CELL.formatter = '#,0.00';
    BASIC_CELL.locked = true;

    export const BASIC_DOLLAR_CELL = BASIC_CELL.clone();
    BASIC_DOLLAR_CELL.formatter = '$#,0.00';

    export const BASIC_PERCENT_CELL = BASIC_CELL.clone();
    BASIC_PERCENT_CELL.formatter = '#,0.00%';

    export const TOP_LEVEL_LABEL = SJSS.FINANCIAL_ENTITY_LABEL.clone();
    TOP_LEVEL_LABEL.foreColor = "#000000";
    TOP_LEVEL_LABEL.locked = true;
    TOP_LEVEL_LABEL.cellPadding = '8 8 8 16';

    export const TOP_LEVEL_LABEL_CELL = SJSS.BASE.clone();
    TOP_LEVEL_LABEL_CELL.borderRight = undefined;

    export const NESTED_METRIC_LABEL = SJSS.FINANCIAL_ENTITY_LABEL_LVL1.clone();
    NESTED_METRIC_LABEL.foreColor = "#000000";
    NESTED_METRIC_LABEL.locked = true;

    export const NESTED_FINANCIAL_LABEL = SJSS.FINANCIAL_ENTITY_LABEL_LVL1.clone();
    NESTED_FINANCIAL_LABEL.foreColor = COLORS.PRIMARY_500;
    NESTED_FINANCIAL_LABEL.locked = true;
}
