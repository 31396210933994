/* eslint-disable @typescript-eslint/no-empty-function */
import { ReactElement, useEffect, useState } from "react";
import { Button } from "@zendeskgarden/react-buttons";
import { Body, Cell, Head, Row, Table } from "@zendeskgarden/react-tables";
import { ThemeProvider } from "@zendeskgarden/react-theming";

import downloadBtn from "../../assets/icons/icon_download.svg";
import { ViziblyTheme } from "../../pages/analyst/ViziblyZDGTheme";
import * as css from "./styles/css.module.scss";
import { BulkReportType, useGetBulkExportDownloadLinkFromS3LazyQuery } from "../../__generated__/generated_types";
import useAppStore from "../../hooks/useAppStore";
import { formatDate } from "./utils";
import Loading from "../../components/loading/Loadiing";


export interface IDownloadableExportsTableRow {
    dateNoTimeString: string;
    fileName: string;
    path: string;
    lastModified: Date;
}

interface IDownloadableExportsTableProps {
    reportType: BulkReportType;
    rows: IDownloadableExportsTableRow[];
}

export default function DownloadableExportsTable({
    reportType,
    rows,
}: IDownloadableExportsTableProps):ReactElement {
    const appStore = useAppStore();
    const [downloadLink, setDownloadLink] = useState<string>();

    const [getDownloadLink, {data: data, loading: loading}] = useGetBulkExportDownloadLinkFromS3LazyQuery({
        fetchPolicy: "no-cache",
    });

    useEffect(
        () => {
            appStore.set({ isLoading: false });
            return () => {};
        },
    []);

    const handleDownloadClick = (row: IDownloadableExportsTableRow) => {
        getDownloadLink({
            variables: {
                date: row.dateNoTimeString,
                reportType,
            },
        });
    };

    useEffect(() => {
        if (loading || !data || !data.downloadLink) {
            return;
        }

        setDownloadLink(data.downloadLink);
    },[data, loading]);

    useEffect(() => {
        if (downloadLink) {
            /**
             * Create a link and synthetically click it to download the file
            */
            const link = document.createElement('a');
            link.href = downloadLink;
            document.body.appendChild(link);

            link.click();

            document.body.removeChild(link);
            setDownloadLink(undefined);
        }
    }, [downloadLink]);

    return (
        <ThemeProvider theme={ViziblyTheme}>
            {rows.length === 0 ? (
                <Loading />
            ) : (
                <div className={css.tableWrapper}>
                    <Table className={css.table} isReadOnly>
                        <Head className={css.header} isSticky>
                            <Row>
                                <Cell className={css.headerCell}>Report Name</Cell>
                                <Cell className={css.headerCell}>Generation Timestamp</Cell>
                                <Cell className={css.headerCell}></Cell>
                            </Row>
                        </Head>
                        <Body>
                            {rows.map((row, index) => (
                                <Row key={`${row.fileName}-${index}`} className={css.bodyRow}>
                                    <Cell className={css.bodyCell}>{row.fileName}</Cell>
                                    <Cell className={css.bodyCell}>{formatDate(row.lastModified)}</Cell>
                                    <Cell className={css.bodyCell}>
                                        <Button
                                            disabled={loading}
                                            isBasic
                                            className={css.downloadButton}
                                            onClick={() => handleDownloadClick(row)}
                                        >
                                            <img src={downloadBtn} alt="download" width="12px" height="12px" />
                                        </Button>
                                    </Cell>
                                </Row>
                            ))}
                        </Body>
                    </Table>
                </div>
            )}
        </ThemeProvider>
    );
}
