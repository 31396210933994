import { ReactElement, useEffect, useMemo, useRef, useState } from "react";

import { OpDriverMetricDisplayName } from "../../../../../../constants/Strings";
import { ModelingMethodName } from "../../logic/utils";
import FormulaBarMenu from "../formula-menu/FormulaBarMenu";

import { deleteNodeKeys, TFormulaNodeProps } from "./logic/formulaNode";
import * as css from "./styles/css.module.scss";


export type TOperationalFormulaNodeProps = TFormulaNodeProps&{
    driverAccount: {
        name: string,
        id: string,
        lookbackPeriod: number|null|undefined,
    },
    isBulkUpdateBar?: boolean,
    isPropertyDriversUI?: boolean,
    editableFxBarChecker: ((modelingMethod: ModelingMethodName) => boolean) | undefined,
    selectedAccountIds: string[],
}

export function OperationalDriverFxNode(props: TOperationalFormulaNodeProps): ReactElement {
    const [isFocused, setIsFocused] = useState<boolean>(false);
    const [lookbackHovered, setLookbackHovered] = useState<boolean>(false);

    const wrapperRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (isFocused && wrapperRef.current) {
            wrapperRef.current.focus();
        }
    }, [isFocused]);

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (
                wrapperRef.current &&
                (wrapperRef.current === event.target ||
                wrapperRef.current.contains(event.target as Node))
            ) {
                return;
            }
            setIsFocused(false);
        };

        // Clicking outside the current node should remove focus
        document.addEventListener("mousedown", handleClickOutside);

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    let lookback = '';

    if(props.driverAccount.lookbackPeriod != undefined){
        if(props.driverAccount.lookbackPeriod < 1){
            lookback = ' from same month';
        } else if(props.driverAccount.lookbackPeriod < 2){
            lookback = ' from 1 month ago';
        } else {
            lookback = ` from ${props.driverAccount.lookbackPeriod} months ago`;
        }
    }

    const handleClick = () => {
        setIsFocused(!isFocused);
    };

    const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
        if (
            isFocused && deleteNodeKeys.includes(e.key)
        ) {
            props.fbUpdates.removeOperationalDriver({
                operationalDriverName: props.driverAccount.name,
            });
        }
    };

    const opDriverName = OpDriverMetricDisplayName[props.driverAccount.name]?.plural ?? props.driverAccount.name;

    const driverNameRender: string|ReactElement = opDriverName;

    const nodeClassName = props.locked ? css.fxNodeLocked : css.fxNode;

    const className = useMemo(() => {
        const allClassNames = [nodeClassName, css.deletableNode];

        if (props.className) {
            allClassNames.push(props.className);
        }

        if (isFocused) {
            allClassNames.push(css.focusedNode);
        }

        if (lookbackHovered) {
            allClassNames.push(css.disableHover);
        } else {
            allClassNames.filter((each: string) => each !== css.disableHover);
        }

        return allClassNames.join(' ');
    }, [isFocused, lookbackHovered, nodeClassName]);

    const lookbackNode = <a>{lookback}</a>;

    const renderLookbackElement = (): string | JSX.Element => {
        if (lookback.length > 0) {
            // Check if the formula bar is locked
            if (props.locked) {
                return lookback;
            } else {
                return (
                    <FormulaBarMenu
                        triggerNode={lookbackNode}
                        lookbackRefObject={{driver: "operational", refObject: props.driverAccount}}
                        fbUpdates={props.fbUpdates}
                        isBulkUpdateBar={props.isBulkUpdateBar}
                        isPropertyDriversUI={props.isPropertyDriversUI}
                        editableFxBarChecker={props.editableFxBarChecker}
                        selectedAccountIds={props.selectedAccountIds}
                    />
                );
            }
        }
        return <></>;
    };

    return (
        <div
            className={className}
            onClick={handleClick}
            onKeyDown={handleKeyDown}
            /**
             * onMouseLeave() isn't always triggered when the user selects a lookback period
             * and the lookback menu closes, so we make sure by doing this:
            */
            onMouseEnter={() => setLookbackHovered(false)}
            ref={wrapperRef}
            tabIndex={0}
        >
            (Op Driver: Fee * % of {driverNameRender}&nbsp;
            <div
                className={css.fxNode}
                onMouseEnter={() => setLookbackHovered(true)}
                onMouseLeave={() => setLookbackHovered(false)}
            >
                {renderLookbackElement()}
            </div>)
        </div>
    );
}
