import React, { ReactElement, useEffect, useState } from "react";
import { Col as GridCol, Grid, Row as GridRow, Col } from "@zendeskgarden/react-grid";
import { ThemeProvider } from "@zendeskgarden/react-theming";

import { ViziblyTheme } from "../analyst/ViziblyZDGTheme";
import { FinancialEntity, useChartOfAccounts } from "../../contexts/chartofaccounts/ChartOfAccountsContext";
import { FinancialEntityType, VersionType, useGetUserPropertiesForecastLocksLazyQuery, useSetForecastPeriodLocksMutation } from "../../__generated__/generated_types";

import * as css from "./styles/propertyDrivers.module.scss";
import ConfigureModelingMethodsBtn from "./components/ConfigureModelingMethodsBtn";
import usePropertyDrivers, { ActiveView } from "./logic/usePropertyDrivers";
import SelectAccounts from "./components/SelectAccounts";
import EditFormulas from "./components/EditFormulas";
import EditAssumptions from "./components/EditAssumptions";
import CopyDriversBtn from "./components/CopyDriversToProperties";
import { Body, Close, Header, Modal } from "@zendeskgarden/react-modals";
import ProgressIndicator from "./components/ProgressIndicator";
import CopyDriversToPeriod from "./components/CopyDriversToPeriod";
import { ReactComponent as LockIcon } from '@zendeskgarden/svg-icons/src/16/lock-locked-stroke.svg';
import { Field, Label, Toggle } from "@zendeskgarden/react-forms";

export interface SelectableFinancialEntity extends FinancialEntity {
    isSelected: boolean;
}

export type TVersionTypeDropdownItem = VersionType.Budget | VersionType.Reforecast;


export default function PropertyDrivers(): ReactElement {
    const pd = usePropertyDrivers();
    const coa = useChartOfAccounts();

    const [getLockedProperties, {data: lockedProperties, loading: lockedPropertiesLoading}] = useGetUserPropertiesForecastLocksLazyQuery({
        fetchPolicy: "no-cache",
    });

    const [isLocked, setIsLocked] = useState<boolean>(false);
    const [isCopyPeriodLocked, setIsCopyPeriodLocked] = useState<boolean>(false);
    const [updatePeriodLock] = useSetForecastPeriodLocksMutation({
        onCompleted: () => {
            getLockedProperties({
                variables: {
                    budgetYear: pd.versionType == VersionType.Reforecast ? pd.year + 1 : pd.year,
                }
            });
        }
    });

    useEffect(() => {
        if (!pd.year) {
            return;
        }

        getLockedProperties({
            variables: {
                budgetYear: pd.versionType == VersionType.Reforecast ? pd.year + 1 : pd.year,
            }
        });
    }, [pd.year]);

    useEffect(() => {
        if (!pd.currentProperty || !lockedProperties?.userPropertiesForecastLocks || lockedPropertiesLoading) {
            return;
        }

        const rfcstLocked = lockedProperties.userPropertiesForecastLocks.reforecastLocked.includes(pd.currentProperty.id) ?? false;
        const bdgtLocked = lockedProperties.userPropertiesForecastLocks.budgetLocked.includes(pd.currentProperty.id) ?? false;

        setIsLocked(pd.versionType == VersionType.Reforecast ? rfcstLocked : bdgtLocked);
        setIsCopyPeriodLocked(pd.versionType == VersionType.Reforecast ? bdgtLocked : rfcstLocked);

    }, [pd.currentProperty, lockedProperties?.userPropertiesForecastLocks, lockedPropertiesLoading]);

    useEffect(
        () => {
            if (!coa.isReady || coa.chartOfAccounts == undefined || !coa.chartOfAccountsFlat) {
                return;
            }

            /**
             * We only need the "ACCOUNT" type objects from the CoA
             * Sorting them by their order keeps the relevant accounts together
             * Initialize all accounts with `isSelected: false`
             */
            const accountsToRender = coa.chartOfAccountsFlat
                .filter((each) => each.type === FinancialEntityType.Account)
                .sort((a, b) => a.order - b.order)
                .map((each) => ({ ...each, isSelected: false }));

            pd.setAccounts(accountsToRender);
        },
        [coa.isReady]
    );

    const renderConfigureModelingMethodsViews = () => {
        const renderViewByActiveViewType = () => {
            switch (pd.activeView) {
                case ActiveView.editFormulas: {
                    return <EditFormulas pd={pd} />;
                }
                case ActiveView.editAssumptions: {
                    return <EditAssumptions pd={pd} />;
                }
                // Unlikely
                default: {
                    return <></>;
                }
            }
        };

        return (
            <Modal onClose={() => pd.setIsConfigureModelingMethodsModalOpen(false)} isLarge className={css.configureMmModal}>
                <Header>
                    Configure Modeling Methods
                </Header>
                <Body className={css.modalBody}>
                    <Grid>
                        <ProgressIndicator pd={pd} />
                        {renderViewByActiveViewType()}
                    </Grid>
                </Body>
                <Close aria-label="Close modal" />
            </Modal>
        );
    };

    return (
        <ThemeProvider theme={ViziblyTheme}>
            <Grid className={`${css.sectionWrapper} ${css.pageHeader}`}>
                <GridRow>
                    <Col>
                        <h3 className={css.pageTitle}>
                            {pd.currentProperty?.name} | {pd.year} {pd.versionType.toLowerCase().capitalize()} {isLocked && <LockIcon className={css.pageTitleLockIcon} />}
                        </h3>
                    </Col>

                    <Col className={css.finalizeToggleWrapper}>
                        <Field className={css.finalizeToggleWrapper}>
                            <Toggle checked={isLocked} onChange={event => {
                                const periodToUpdate = pd.versionType == VersionType.Reforecast ? "isReforecastLocked" : "isBudgetLocked";
                                updatePeriodLock({
                                    variables: {
                                        propertyId: pd.propertyId,
                                        budgetYear: pd.versionType == VersionType.Reforecast ? pd.year + 1 : pd.year,
                                        locks: {
                                            [periodToUpdate]: event.target.checked,
                                        }
                                    }
                                })
                            }}>
                                <Label>Finalize</Label>
                            </Toggle>
                        </Field>
                    </Col>
                </GridRow>
                <SelectAccounts pd={pd} />
                {pd.isConfigureModelingMethodsModalOpen && renderConfigureModelingMethodsViews()}
                <GridRow className={css.footerWrapper}>
                    <GridCol className={css.counterWrapper}>
                        <div className={css.dummyFlexWrapper}>
                            <div className={css.counter}>
                                {pd.selectedAccounts.length}
                            </div>
                            <div className={css.counterLabel}>
                                GL accounts selected
                            </div>
                        </div>
                    </GridCol>
                    <GridCol className={css.copyDriversBtnCol}>
                        <ConfigureModelingMethodsBtn isLocked={isLocked} pd={pd} />
                    </GridCol>
                    <GridCol className={css.copyDriversBtnCol}>
                        <CopyDriversToPeriod
                            allAccountsCount={pd.accounts.length}
                            selectedAccounts={pd.selectedAccounts}
                            versionType={pd.versionType}
                            isCopyPeriodLocked={isCopyPeriodLocked}
                        />
                    </GridCol>
                    <GridCol className={css.copyDriversBtnCol}>
                        <CopyDriversBtn
                            allAccountsCount={pd.accounts.length}
                            selectedAccounts={pd.selectedAccounts}
                            versionType={pd.versionType}
                            year={pd.year}
                            lockedProperties={lockedProperties}
                        />
                    </GridCol>
                </GridRow>
            </Grid>
        </ThemeProvider>
    );
}
