import { ReactElement, useEffect, useState } from "react";
import { Button } from "@zendeskgarden/react-buttons";

import { useGetIsAccountLockedLazyQuery, useSetAccountLockMutation, VersionType } from "../../../__generated__/generated_types";
import lockOn from "../../../assets/icons/lock/locked.svg";
import lockOff from "../../../assets/icons/lock/unlocked.svg";
import lockOnDisabled from "../../../assets/icons/lock/locked-disabled.svg";
import lockOffDisabled from "../../../assets/icons/lock/unlocked-disabled.svg";
import { IUpdateAccountLocksByAccountId } from "../logic/usePropertyDrivers";

import * as css from "./styles/css.module.scss";


interface ILockButtonProps {
    propertyId: string,
    accountId: string,
    budgetYear: number,
    versionType: VersionType,
    isConfigureModalOpen: boolean,
    updateAccountLocksByAccountId: (args: IUpdateAccountLocksByAccountId) => void,
    updateAccountLocksDraftByAccountId: (args: IUpdateAccountLocksByAccountId) => void,
    isLocked: boolean | undefined,
    disabled?: boolean,
}

export default function LockButton({
    propertyId,
    accountId,
    budgetYear,
    versionType,
    isConfigureModalOpen,
    updateAccountLocksByAccountId,
    updateAccountLocksDraftByAccountId,
    isLocked,
    disabled=false,
}: ILockButtonProps): ReactElement {
    const [lockHovered, setLockHovered] = useState<boolean>(false);
    const [disableMouseEnter, setDisableMouseEnter] = useState<boolean>(false);

    const [getIsAccountLocked, {data: getActionData, loading: getActionLoading}] = useGetIsAccountLockedLazyQuery({
        fetchPolicy: "no-cache"
    });

    /**
     * Fetch locks whenever property or version type changes or when the configure modal is toggled
     */
    useEffect(() => {
        getIsAccountLocked({
            variables: {
                propertyId: propertyId,
                accountId: accountId,
                budgetYear: budgetYear,
                versionType: versionType,
            },
        });
    }, [isConfigureModalOpen, propertyId, versionType]);

    useEffect(() => {
        if (getActionLoading || !getActionData || getActionData.getIsAccountLocked === null || getActionData.getIsAccountLocked === undefined) {
            return;
        }

        updateAccountLocksByAccountId({
            accountId,
            isLocked: getActionData.getIsAccountLocked,
        });
    }, [getActionData, getActionLoading]);

    const handleClick = () => {
        updateAccountLocksDraftByAccountId({
            accountId,
            isLocked: !isLocked,
        });
        setDisableMouseEnter(true);
    };

    const handleMouseLeave = () => {
        setLockHovered(false);
        setDisableMouseEnter(false);
    };

    const renderLockType = () => {
        if (disabled) {
            return <img src={lockOnDisabled} alt="locked" width="15px" height="15px" />;
        }
        if (lockHovered && !disableMouseEnter) {
            return <img src={lockOff} alt="unlock" width="15px" height="15px" />;
        }
        return <img src={lockOn} alt="locked" width="15px" height="15px" />;
    };

    const renderUnlockType = () => {
        if (disabled) {
            return <img src={lockOffDisabled} alt="unlocked" width="15px" height="15px" />;
        }
        if (lockHovered && !disableMouseEnter) {
            return <img src={lockOn} alt="lock" width="15px" height="15px" />;
        }
        return <img src={lockOff} alt="unlocked" width="15px" height="15px" />;
    };

    const renderLock = () => {
        if (isLocked) {
            return renderLockType();
        }
        return renderUnlockType();
    };

    if (isLocked === undefined) {
        return <></>;
    }

    return (
        <Button
            isBasic
            disabled={disabled}
            onClick={handleClick}
            onMouseEnter={() => setLockHovered(true)}
            onMouseLeave={handleMouseLeave}
            className={`${css.lockButton} ${disabled ? '' : css.editableLockButton}`}
        >
            {renderLock()}
        </Button>
    );
}
