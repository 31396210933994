import { ReactElement, useEffect, useState } from "react";
import { Cell, Row } from '@zendeskgarden/react-tables';

import * as css from "./../styles/css.module.scss";
import * as detailsCss from "../components/transaction-details/styles/transactionDetails.module.scss";

import { formatterDollarUSNoDecimal } from "../../../../../../../../utils/fotmatters";

import TransactionDetails from "./transaction-details/TransactionDetails";
import ExpandDetailsToggle from "./ExpandDetailsToggle";
import { TransactionRowViewModel } from "../data/logic/buildTransactionRows";
import {formatDateInLocale} from "../../../../../../../../utils/date-helpers";

export interface MVRTransactionRowProps {
    description: string;
    memo: string;
    date: Date;
    amount: number;
    transaction: TransactionRowViewModel;
}

export function MVRTransactionRow(props: MVRTransactionRowProps): ReactElement {
    const [detailsExpanded, setDetailsExpanded] = useState<boolean>(false);

    const onExpandDetailsClick = (isExpanded: boolean) => {
        setDetailsExpanded(isExpanded);
    };

    /**
     * Close the details panel on description update.
     */
    useEffect(
            () => {
                if(detailsExpanded){
                    setDetailsExpanded(false);
                }
            },
            [props.description]
    );

    const formattedDate = formatDateInLocale(props.date);
    const formattedAmount = formatterDollarUSNoDecimal.format(props.amount);
    return (<>
                <Row>
                    <Cell isTruncated className={css.descriptionTableCell} title={props.description} width={103}>
                        <div className={css.descriptionContainer}>
                            <div>
                                <ExpandDetailsToggle
                                        onClick={onExpandDetailsClick}
                                        isExpanded={detailsExpanded}
                                        size={'small'}
                                />
                            </div>
                            <div className={css.description}>{props.description}</div>
                        </div>
                    </Cell>
                    <Cell isTruncated className={css.tableCell} title={props.memo} width={144}>
                        {props.memo}
                    </Cell>
                    <Cell className={css.tableCell} width={76}>
                        {formattedDate}
                    </Cell>
                    <Cell className={css.amountTableCell} width={91}>
                        {formattedAmount}
                    </Cell>
                </Row>
                <Row className={detailsCss.detailsRow} style={detailsExpanded ? {} : { display: "none" }}>
                    <Cell className={detailsCss.detailsCell} colSpan={4}>
                        <TransactionDetails transaction={props.transaction}/>
                    </Cell>
                </Row>
            </>
    );
}
