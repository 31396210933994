import { ReactElement, useContext } from "react";
import { PropertyExecutiveSummary } from "./PropertyExecutiveSummary";
import { GuestAuthenticationContext } from "../../contexts/guest-auth-context/GuestAuthContext";

export function PropertyExecutiveSummaryGuestWrapper(): ReactElement {
    const authContext = useContext(GuestAuthenticationContext);
    return (
        authContext.user ?
        <PropertyExecutiveSummary user={{...authContext.user, isGuest: true}} property={authContext.user.property}/>
        :
        <></>
    );
}