import React, {useEffect, useState} from 'react';
import {ReactComponent as NotesStroke} from '@zendeskgarden/svg-icons/src/16/notes-stroke.svg';
import {ReactComponent as NotesFill} from '@zendeskgarden/svg-icons/src/12/notes-fill.svg';
import {ReactComponent as ArrowExpand} from '../spreadsheet/spreadjs-custom-cell-types/icons/arrow-expand.svg';
import {
    EntityType,
    GetNoteDocument,
    useGetNoteQuery,
    useUpsertNoteMutation,
    VersionType,
} from '../../__generated__/generated_types';
import ModalWindow from '../modal/ModalWindow';
import './ReportNote.scss';
import {Button} from "@zendeskgarden/react-buttons";

interface ReportNoteProps {
    propertyId: string | undefined | null;
    versionType: VersionType;
    year: number;
    entityId: string;
    entityType: EntityType;
}

export const ReportNote: React.VFC<ReportNoteProps> = (props) => {
    const [isModalOpen, setIsModalOpen] = useState(false);

    const { data: note } = useGetNoteQuery({
        variables: {
            propertyId: props.propertyId ?? "",
            versionType: props.versionType,
            year: props.year,
            entityId: props.entityId,
            entityType: props.entityType
        },
        fetchPolicy: "cache-first" // first fetch the note from cache, if it doesnt exist, fetch from server
    });

    const [defaultNoteTextValue, setDefaultNoteTextValue] = useState<string>(note?.queryNote?.text ?? "");
    const [noteTextValue, setNoteTextValue] = useState<string>();

    const handleOpenModal = () => {
        if(noteTextValue) setNoteTextValue("");
        if(defaultNoteTextValue) setDefaultNoteTextValue("");
        setIsModalOpen(!isModalOpen);
    };

    const [upsertNote] = useUpsertNoteMutation({
        awaitRefetchQueries: true,
        refetchQueries: [{
            query: GetNoteDocument, variables: {
                propertyId: props.propertyId ?? "",
                versionType: props.versionType,
                year: props.year,
                entityId: props.entityId,
                entityType: props.entityType
            }
        }]
    });

    useEffect(
        () => {
            if (note && note.queryNote) {
                setDefaultNoteTextValue(note.queryNote.text ?? "");
                setNoteTextValue(note.queryNote.text ?? "");
            }
        }
        ,[note]
    );

    const handleCloseModal = () => {
        setIsModalOpen(false);
        setNoteTextValue(defaultNoteTextValue);
    };

    const handleSaveNote = async () => {
        upsertNote({
            variables: {
                text: noteTextValue ?? "",
                entityId: props.entityId,
                entityType: props.entityType,
                versionType: props.versionType,
                propertyId: props.propertyId ?? "",
                year: props.year
            },
        });
        setDefaultNoteTextValue(noteTextValue ?? "");
        setIsModalOpen(false);
    };

    return (
        <div className="report-note-container d-flex justify-content-center pe-2">
            {
                note?.queryNote?.text ?
                    <div className={"report-view-note-container"}>
                        <div className={"report-view-note-fill-icon"}>
                            <NotesFill />
                        </div>
                        <div className={"report-view-note-text-container"}>
                            <div className={"report-view-note-text"}>
                                {note.queryNote.text}
                            </div>
                        </div>
                        <div className={"report-view-note-expand-icon-container"}>
                            <div className={"report-view-note-expand-icon"}
                                onClick={() => {
                                    handleOpenModal();
                                }}>
                                <ArrowExpand />
                            </div>
                        </div>
                    </div> :
                    <Button isPrimary
                        size={"small"}
                        className={"report-add-note-button"}
                        onClick={() => {
                            handleOpenModal();
                        }}>
                    <Button.StartIcon>
                        <NotesStroke />
                    </Button.StartIcon>
                    Add Note
                </Button>
            }
            <ModalWindow
                isOpen={isModalOpen}
                primaryText="Update"
                title="Note"
                onClose={handleCloseModal}
                onSave={handleSaveNote}
                canCancel={false}
                canConfirm={true}
                hasBorders={false}
            >
                <textarea
                    className="report-note-text-area ps-2 pt-2"
                    cols={30}
                    rows={7}
                    defaultValue={defaultNoteTextValue || (note?.queryNote?.text ?? "")}
                    placeholder="Add a note..."
                    onChange={event => setNoteTextValue(event.target.value)}
                >
                </textarea>
            </ModalWindow>
        </div>

    );
};
