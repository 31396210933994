import React, {ReactElement} from "react";
import styles from "../AnalystPayrollDrawer.module.scss";
import {Field, Input, Label} from "@zendeskgarden/react-forms";
import {Col, Row} from "@zendeskgarden/react-grid";
import {
    LocalPayrollEmployeeCompItemModel,
    LocalPayrollEmployeeModelKeys
} from "../helpers/types";
import {Datepicker} from "@zendeskgarden/react-datepickers";
import {Button} from "@zendeskgarden/react-buttons";
import {DateFormatter} from "../helpers/utils";
import {ensureDateInLocale, ensureDateInUtc} from "../date-helpers";

interface BenefitEntryProps {
    index: number,
    compItem: LocalPayrollEmployeeCompItemModel,
    allowRemove: boolean,
    updateFormState: (key: LocalPayrollEmployeeModelKeys, value: any) => void
}

export default function BenefitEntry({index, compItem, allowRemove, updateFormState}: BenefitEntryProps): ReactElement {
    return (
        <div>
            <Field className={styles.field}>
                <Row justifyContent="between">
                    <Col>
                        <Label className={styles.benefitSubHeaders}>Payment {index + 1}</Label>
                    </Col>
                    {
                        allowRemove && <Button isBasic isDanger onClick={() => {
                            updateFormState(LocalPayrollEmployeeModelKeys.removeCompItem, index);
                        }}>Remove</Button>
                    }
                </Row>
                <Row justifyContent="between">
                    <Col>
                        <Field className={styles.field}>
                            <Label className={styles.label}>Amount</Label>
                            <Input
                                placeholder="$"
                                value={compItem.costPerPayPeriod ?? ""}
                                type={"number"}
                                onChange={(evt) => {
                                    updateFormState(
                                        LocalPayrollEmployeeModelKeys.compItemsAmount,
                                        evt.target.value);
                                }}/>
                        </Field>
                    </Col>
                    <Col>
                        <Field className={styles.field}>
                            <Label className={styles.label}>Effective Date</Label>
                            <Datepicker
                                value={compItem.effectiveStartDate ? ensureDateInLocale(compItem.effectiveStartDate) : undefined}
                                onChange={(effectiveStartDate) => {
                                    updateFormState(
                                        LocalPayrollEmployeeModelKeys.compItemsEffectiveDate,
                                        ensureDateInUtc(effectiveStartDate)?.toISOString());
                                }}
                                formatDate={date => {
                                    return date ? DateFormatter.format(date) : "";
                                }}
                            >
                                <Input/>
                            </Datepicker>
                        </Field>
                    </Col>
                </Row>
            </Field>
        </div>
    );
}

