import React, { Children, ReactElement } from "react";
import * as css from "./styles/css.module.scss";
import CurrentFocusedYear from "../current-focused-year/CurrentFocusedYear";

interface IDashboardHeaderProps {
    children: ReactElement[] | ReactElement,
    className?: string,
}

function PropertyInfo(props: IDashboardHeaderProps): ReactElement{
    return (
            <span className={props.className ? `${css.propertyRow} ${props.className}` : css.propertyRow}>
                {props.children}
            </span>
    );
}

function Timeframe(props: IDashboardHeaderProps): ReactElement{
    return (
            <span className={props.className ? `${css.timeframeRow} ${props.className}` : css.timeframeRow}>
                {props.children}
            </span>
    );
}

export default function DashboardHeader(props: IDashboardHeaderProps):ReactElement{
    let propertyInfo = <></>;
    let timeframeInfo = <></>;

    Children.forEach(props.children, child => {
        if(child.type === PropertyInfo) {
            propertyInfo = child;
        }
        if(child.type === Timeframe) {
            timeframeInfo = child;
        }
    });

    return (
            <div className={props.className ? `${css.dashHeader} ${props.className}` : css.dashHeader}>
                <div>{propertyInfo}</div>
                <div>{timeframeInfo}</div>
            </div>
    );
}

DashboardHeader.PropertyInfo = PropertyInfo;
DashboardHeader.Timeframe = Timeframe;

// TODO: Add DashboardHeader.PropertyInfo to the dashboard once we're through the interim phase
// <DashboardHeader>
//     <DashboardHeader.PropertyInfo>
//         <><BuildingIcon/> <span>{dashboardData.property.name}</span></>
//     </DashboardHeader.PropertyInfo>
//     <DashboardHeader.Timeframe>
//         <span>Dashboard</span><span>//</span><CurrentFocusedYear
//             year={
//                 dashboardData.focusYear?.year
//             }
//             yearType={dashboardData.focusYear?.type}/>
//     </DashboardHeader.Timeframe>
// </DashboardHeader>
