import AdminHeader from "../AdminHeader";
import {ReactElement, useEffect, useState} from "react";
import * as css from "./styles/userStyles.module.scss";
import {Inline} from "@zendeskgarden/react-loaders";
import {COLORS} from "../../../constants/Colors";
import {Checkbox, Field, Label, MediaInput} from "@zendeskgarden/react-forms";
import {SearchRounded} from "@material-ui/icons";
import {Button} from "@zendeskgarden/react-buttons";
import {ListGroupsQuery, useListGroupsLazyQuery} from "../../../__generated__/generated_types";
import {Body, Cell, Head, HeaderCell, HeaderRow, Row, Table} from "@zendeskgarden/react-tables";
import {GroupForm} from "./components/group-form/GroupForm";
import SelectionBar from "./components/SelectionBar";
import {authorizationTitleRemapping} from "./logic/authorizationRenamer";
import {formatterEnumToTitle} from "../../../utils/formatters";
import {GroupDeleteModal} from "./components/GroupDeleteModal";

export function GroupList(props: {authData: string[]}): ReactElement {
    const [listGroups, {data, loading}] = useListGroupsLazyQuery({
        fetchPolicy: "no-cache"
    });

    const [search, setSearch] = useState<string | null>(null);
    const [deleteVisible, setDeleteVisible] = useState<boolean>(false);
    const [createFormVisible, setCreateFormVisible] = useState<boolean>(false);
    const [editingGroup, setEditingGroup] = useState<ListGroupsQuery["listUserGroups"][0] | null>(null);
    const [filteredData, setFilteredData] = useState<ListGroupsQuery["listUserGroups"]>([]);
    const [selectedGroups, setSelectedGroups] = useState<string[]>([]);

    useEffect(() => {
        listGroups();
    }, []);

    useEffect(() => {
        if(loading) {
            return;
        }

        if(!data) {
            setFilteredData([]);
            return;
        }

        let filtered = data.listUserGroups;
        if(search !== null && search !== "") {
            filtered = filtered.filter(g => g.name.toLowerCase().includes(search.toLowerCase()));
        }
        setFilteredData(filtered);
    }, [data, loading, search]);

    function clearSelected() {
        setSelectedGroups([]);
    }

    function closeForm(saved: boolean): void {
        setCreateFormVisible(false);
        setEditingGroup(null);
        if(saved) {
            listGroups();
        }
    }

    return <>
        <div className={css.userPageInner}>
            <AdminHeader
                    title={"Group Management"}
                    subtitle={"Create and modify groups holding common permissions for users."}
            />
            <div className={css.tableHeaderActions}>
                <h5 className={css.tableHeaderActionsLeft}>
                    Groups
                    {
                            loading && <Inline size={24} color={COLORS.PRIMARY_500} aria-label="loading"/>
                    }
                </h5>
                <div className={css.tableHeaderActionsRight}>
                    <Field>
                        <MediaInput
                                className={css.tableHeaderActionsSearch}
                                placeholder="Search"
                                isCompact
                                onChange={evt => setSearch(evt.target.value.toLowerCase())}
                                start={
                                    <SearchRounded/>
                                }
                        />
                    </Field>
                    <Button isPrimary className={css.tableHeaderActionsButton} onClick={() => setCreateFormVisible(true)}>Add
                        Group</Button>
                </div>
            </div>
            <div className={css.userTableWrapper}>
                <Table className={css.userTable} size={"large"} >
                    <Head isSticky>
                        <HeaderRow className={css.headerRow}>
                            <HeaderCell isMinimum className={css.checkboxCell} />
                            <HeaderCell style={{borderLeft: "none"}}>Name</HeaderCell>
                            <HeaderCell>Permissions</HeaderCell>
                        </HeaderRow>
                    </Head>
                    <Body>
                        {
                            filteredData.map(g => {
                                return <Row key={g.id} className={css.bodyRow}>
                                    <Cell className={css.checkboxCell}>
                                        <Field>
                                            <Checkbox
                                                    className={css.userTableCheckbox}
                                                    checked={selectedGroups.includes(g.id)}
                                                    onChange={() => {
                                                        const idx = selectedGroups.indexOf(g.id);
                                                        const copy = [...selectedGroups];
                                                        if(idx > -1) {
                                                            copy.splice(idx, 1);
                                                        } else {
                                                            copy.push(g.id);
                                                        }
                                                        setSelectedGroups(copy);
                                                    }}
                                            >
                                                <Label className={css.userTableCheckbox} />
                                            </Checkbox>
                                        </Field>
                                    </Cell>
                                    <Cell style={{borderLeft: "none"}}>{g.name}</Cell>
                                    <Cell>
                                        {g.authorizations.map(a => formatterEnumToTitle(authorizationTitleRemapping(a))).join(", ")}
                                    </Cell>
                                </Row>;
                            })
                        }
                    </Body>
                </Table>
            </div>
        </div>
        <SelectionBar
                selected={selectedGroups}
                onEdit={() => {
                    if(selectedGroups.length !== 1) {
                        return;
                    }

                    const id = selectedGroups[0];
                    const toEdit = data?.listUserGroups.find(g => g.id === id);
                    if(!toEdit) {
                        setEditingGroup(null);
                    } else {
                        setEditingGroup(toEdit);
                    }
                }}
                onDelete={() => setDeleteVisible(true)}
                name="Group"
                namePlural="Groups"
        />
        {createFormVisible &&
                <GroupForm closed={closeForm} availableAuthorizations={props.authData} existingGroup={undefined} />
        }
        {editingGroup !== null &&
                <GroupForm closed={closeForm} availableAuthorizations={props.authData} existingGroup={editingGroup} />
        }
        {deleteVisible &&
                <GroupDeleteModal
                        close={deleted => {
                            setDeleteVisible(false);
                            if(deleted) {
                                clearSelected();
                                listGroups();
                            }
                        }}
                        groupIds={selectedGroups} />
        }
    </>;
}