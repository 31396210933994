
// BEGIN: Simplified Revenue Routing Constants
import {TreeNode} from "../../../components/tree/Tree";

export const SIMPLIFIED_REVENUE_PREFIX = "/simplified-revenue";

export const SIMPLIFIED_REVENUE_ROUTES = [
    "lease-expiration-schedule",
    "market-rent",
    "renewal-trade-out",
    "renewal-ratios",
    "renovations",
    "vacancy-rates",
    "summary"
];

export const SIMPLIFIED_REVENUE_CHILD_TREE_NODES: TreeNode[] = [
    {
        title: 'Lease Expiration Schedule',
        url: `${SIMPLIFIED_REVENUE_PREFIX}/lease-expiration-schedule`,
        useRawUrl: true,
    },
    {
        title: 'Market Rent',
        url: `${SIMPLIFIED_REVENUE_PREFIX}/market-rent`,
        useRawUrl: true,
    },
    {
        title: 'Renewal Trade Out',
        url: `${SIMPLIFIED_REVENUE_PREFIX}/renewal-trade-out`,
        useRawUrl: true,
    },
    {
        title: 'Renewal Ratio',
        url: `${SIMPLIFIED_REVENUE_PREFIX}/renewal-ratios`,
        useRawUrl: true,
    },
    {
        title: 'Renovations',
        url: `${SIMPLIFIED_REVENUE_PREFIX}/renovations`,
        useRawUrl: true,
    },
    {
        title: 'Vacancy Rates',
        url: `${SIMPLIFIED_REVENUE_PREFIX}/vacancy-rates`,
        useRawUrl: true,
    },
    {
        title: 'Summary',
        url: `${SIMPLIFIED_REVENUE_PREFIX}/summary`,
        useRawUrl: true,
    },
];
// END: Simplified Revenue Routing Constants