import { ReactElement } from "react";

import * as css from "./styles/adminStyles.module.scss";

export interface IAdminHeader {
    title: string,
    subtitle: string,
}

export default function AdminHeader(props: IAdminHeader): ReactElement {
    return (
        <div>
            <h2 className={css.adminTitle}>{props.title}</h2>
            <h6 className={css.adminSubtitle}>{props.subtitle}</h6>
        </div>
    )
}