import { FinancialEntity } from "../../../../../../contexts/chartofaccounts/ChartOfAccountsContext";
import {
    FinancialEntityType,
    FinancialValuesYearModel,
    GetMvrFinanciaValuesWithVarianceNotesQuery,
    GetMvrVarianceNotesQuery,
    VersionType
} from "../../../../../../__generated__/generated_types";
import { MonthlyVarianceReportConfig } from "../../../MonthlyVarianceReportConfig";
import { calculateVariancePercent } from "../../../../../../utils/variance";

export type MvrTableRow = {
    entityName: string
    entityType: string,
    depth: number,
    monthActual?: number
    monthBudget?: number,
    monthValueVariance?: number,
    monthPercentVariance?: number,
    monthPercentVarianceString?: string,
    ytdActual?: number
    ytdBudget?: number,
    ytdValueVariance?: number,
    ytdPercentVariance?: number,
    ytdPercentVarianceString?: string,
    varianceNote?: string,
    entityId?: string, // Summary rows don't have Ids
    parentId?: string, // Components don't have parent Ids
    componentType?: string,
    negatedAtComponent?: boolean,
    rowIdx?: number,
}

export interface IFinancialEntityYear {
    rowIdx: number,
    entityType: string,
    year: number,
    values: {
        actual: (number|null)[],
        budget: (number|null)[],
    },
    variance: {
        value: (number|null)[],
        percent: (number|null)[],
        percentStrings: (string|null)[],
    }
    ytd: {
        values: {
            actual: (number|null)[],
            budget: (number|null)[],
        },
        variance: {
            value: (number|null)[],
            percent: (number|null)[],
            percentStrings: (string|null)[],
        }
    },
    varianceNote?: string,
}

type TFinancialValuesYear = ({ __typename?: "FinancialValuesYearModel"|undefined }&Pick<FinancialValuesYearModel, "entityId"|"entityType"|"year"|"versionType"|"propertyId"|"values">);

export const getMockEmptyFinancialEntityYear = ():IFinancialEntityYear => {
    return {
        rowIdx: 0,
        entityType: '',
        year: new Date().getFullYear(),
        values: {
            actual: new Array(12).fill(null),
            budget: new Array(12).fill(null),
        },
        variance: {
            value: new Array(12).fill(null),
            percent: new Array(12).fill(null),
            percentStrings: new Array(12).fill('0.00%'),
        },
        ytd: {
            values: {
                actual: new Array(12).fill(null),
                budget: new Array(12).fill(null),
            },
            variance: {
                value: new Array(12).fill(null),
                percent: new Array(12).fill(null),
                percentStrings: new Array(12).fill('0.00%'),
            }
        }
    };
};

export const calculateMonthlyVarianceValues = (actuals: (number|null)[], budget: (number|null)[]): (number|null)[] => {
    return actuals.map((actual, idx) => {
        const budgetValue = budget[idx];
        if(actual != null && budgetValue != undefined){
            return actual - budgetValue;
        }
        else {
            return null;
        }
    });
};

export const calculateMonthlyVariancePercent = (actuals: (number|null)[], budget: (number|null)[]): (number|null)[] => {
    return actuals.map((actual, idx) => {
        const budgetValue = budget[idx];
        if(actual === budgetValue){
            return 0;
        }
        else if(actual != undefined && budgetValue){
            return calculateVariancePercent(actual, budgetValue);
        }
        else {
            return null;
        }
    });
};

export const calculateYTDArray = (yearValues: (number|null)[]): (number|null)[] => {
    const YTDArray: number[] = new Array(12).fill(0);

    yearValues.reduce(
        (acc: number, curr: number|null, idx) => {
            if(curr != null){
                YTDArray[idx] = curr + acc;
                return curr + acc;
            }
            else {
                YTDArray[idx] = acc;
                return acc;
            }
        }, 0);

    return YTDArray;
};

export const percentArrayStrings = (arr: (number|null)[]): (string|null)[] => {
    return arr.map(entry => {
        if(entry != null){
            return (entry * 100).toFixed(1).toString() + '%';
        }
        else {
            return null;
        }
    });
};

/**
 * buildmvrEntityDataMap() produces a record, keyed by entityId, with all data necessary to render the monthly variance
 * report, aside from Variance Notes. This includes calculated arrays for variance (percent and value) and percent
 * strings.
 */
export const buildMvrEntityDataMap = (mvrRawData: GetMvrFinanciaValuesWithVarianceNotesQuery ): Record<string, Partial<IFinancialEntityYear>> => {
    /**
     * mvrEntityDataMap produces a record, keyed by entityId, with all data necessary to render the monthly variance
     * report, aside from Variance Notes. This includes calculated arrays for variance (percent and value) and percent
     * strings.
     */
    const varianceNotesById = mvrRawData?.queryAccountVarianceNotes.toIdMap('accountId', entry => entry.text);

    return mvrRawData.financialValuesForYear.reduce(
        (acc: Record<string, Partial<IFinancialEntityYear>>, entry: TFinancialValuesYear, idx): Record<string, Partial<IFinancialEntityYear>> => {
            const newEntry: Partial<IFinancialEntityYear> = {
                rowIdx: idx + 1,
                entityType: entry.entityType,
                year: entry.year,
            };

            const varianceNote = varianceNotesById[entry.entityId];

            if(entry.values){
                switch (entry.versionType) {
                    case VersionType.Budget: {
                        newEntry.values = {
                            ...newEntry.values ?? {
                                actual: []
                            },
                            budget: [...entry.values]
                        };
                        break;
                    }
                    case VersionType.Actuals: {
                        newEntry.values = {
                            ...newEntry.values ?? {
                                budget: [],
                            },
                            actual: [...entry.values]
                        };
                        break;
                    }
                }
            }

            if(entry.entityId in acc){
                const thisEntry = acc[entry.entityId];
                if(thisEntry){
                    acc[entry.entityId] = {
                        ...thisEntry,
                        ...{
                            values: {
                                actual: [
                                    ...thisEntry.values?.actual ?? [],
                                    ...newEntry.values?.actual ?? [],
                                ],
                                budget: [
                                    ...thisEntry.values?.budget ?? [],
                                    ...newEntry.values?.budget ?? [],
                                ],
                            }
                        }
                    };
                }
            }
            else {
                acc[entry.entityId] = { ...newEntry };
            }

            const actualValues = acc[entry.entityId]?.values?.actual;
            const budgetValues = acc[entry.entityId]?.values?.budget;

            if(actualValues != undefined && budgetValues != undefined){
                const monthlyValueVariance = calculateMonthlyVarianceValues(actualValues, budgetValues);
                const monthlyPercentVariance = calculateMonthlyVariancePercent(actualValues, budgetValues);
                const monthlyPercentVarianceStrings = percentArrayStrings(monthlyPercentVariance);

                const ytdActualValues = calculateYTDArray(actualValues);
                const ytdBudgetValues = calculateYTDArray(budgetValues);
                const ytdValueVariance = calculateMonthlyVarianceValues(ytdActualValues, ytdBudgetValues);
                const ytdPercentVariance = calculateMonthlyVariancePercent(ytdActualValues, ytdBudgetValues);
                const ytdPercentVarianceStrings = percentArrayStrings(ytdPercentVariance);

                acc[entry.entityId] = {
                    ...acc[entry.entityId],
                    variance: {
                        value: monthlyValueVariance,
                        percent: monthlyPercentVariance,
                        percentStrings: monthlyPercentVarianceStrings,
                    },
                    ytd: {
                        values: {
                            actual: ytdActualValues,
                            budget: ytdBudgetValues,
                        },
                        variance: {
                            value: ytdValueVariance,
                            percent: ytdPercentVariance,
                            percentStrings: ytdPercentVarianceStrings,
                        }
                    },
                    varianceNote,
                };
            }

            return acc;
        }, {});
};

export const buildMvrTableData = (chartOfAccounts: FinancialEntity[], mvrEntityDataMap: Record<string, Partial<IFinancialEntityYear>>, monthIdx = 0): MvrTableRow[] => {
    const mvrTableData: MvrTableRow[] = [];

    if(!chartOfAccounts){
        // eslint-disable-next-line no-console
        console.warn('No Chart of Accounts data provided.');
        return mvrTableData;
    }

    let componentType = '';

    const processTree = (entityList: FinancialEntity[], depth = 0): void => {
        entityList.forEach(entity => {

            if(entity.type === 'COMPONENT'){
                componentType = entity.budgetComponentType;
            }

            const rowDataMap = mvrEntityDataMap[entity.id];

            // If we're not a COMPONENT...
            if(depth > 0 && rowDataMap){
                mvrTableData.push({
                    rowIdx: rowDataMap.rowIdx ?? undefined,
                    entityName: entity.name,
                    entityId: entity.id,
                    entityType: entity.type,
                    parentId: entity.parentId,
                    monthActual: rowDataMap.values?.actual[monthIdx] ?? undefined,
                    monthBudget: rowDataMap.values?.budget[monthIdx] ?? undefined,
                    monthValueVariance: rowDataMap.variance?.value[monthIdx] ?? undefined,
                    monthPercentVariance: rowDataMap.variance?.percent[monthIdx] ?? undefined,
                    monthPercentVarianceString: rowDataMap.variance?.percentStrings[monthIdx] ?? undefined,
                    ytdActual: rowDataMap.ytd?.values.actual[monthIdx] ?? undefined,
                    ytdBudget: rowDataMap.ytd?.values.budget[monthIdx] ?? undefined,
                    ytdValueVariance: rowDataMap.ytd?.variance.value[monthIdx] ?? undefined,
                    ytdPercentVarianceString: rowDataMap.ytd?.variance.percentStrings[monthIdx] ?? undefined,
                    ytdPercentVariance: rowDataMap.ytd?.variance.percent[monthIdx] ?? undefined,
                    varianceNote: rowDataMap.varianceNote,
                    componentType,
                    negatedAtComponent: entity.budgetComponentNegate ?? false,
                    depth
                });
            }

            if(entity.children.length > 0){
                processTree(entity.children, depth + 1);
            }

            // Summary Row for non-accounts
            if(
                rowDataMap
                && ((
                    entity.type !== FinancialEntityType.Account
                    && MonthlyVarianceReportConfig.ENABLE_ROW_FOLDING
                    )
                    || entity.type === FinancialEntityType.Component
                )
            ){
                const label = depth > 0 ? `${entity.name} Total` : `${entity.name}`;
                mvrTableData.push({
                    rowIdx: rowDataMap.rowIdx,
                    entityName: label,
                    entityType: `${entity.type}_SUMMARY`,
                    parentId: entity.parentId,
                    monthActual: rowDataMap.values?.actual[monthIdx] ?? undefined,
                    monthBudget: rowDataMap.values?.budget[monthIdx] ?? undefined,
                    monthValueVariance: rowDataMap.variance?.value[monthIdx] ?? undefined,
                    monthPercentVariance: rowDataMap.variance?.percent[monthIdx] ?? undefined,
                    monthPercentVarianceString: rowDataMap.variance?.percentStrings[monthIdx] ?? undefined,
                    ytdActual: rowDataMap.ytd?.values.actual[monthIdx] ?? undefined,
                    ytdBudget: rowDataMap.ytd?.values.budget[monthIdx] ?? undefined,
                    ytdValueVariance: rowDataMap.ytd?.variance.value[monthIdx] ?? undefined,
                    ytdPercentVarianceString: rowDataMap.ytd?.variance.percentStrings[monthIdx] ?? undefined,
                    ytdPercentVariance: rowDataMap.ytd?.variance.percent[monthIdx] ?? undefined,
                    componentType,
                    negatedAtComponent: entity.budgetComponentNegate ?? false,
                    depth
                });
            }
        });
    };

    processTree(chartOfAccounts);

    return mvrTableData;
};

export const applyVarianceNotesData = (mvrEntityDataMap: Record<string, Partial<IFinancialEntityYear>>, varianceNoteData: GetMvrVarianceNotesQuery): Record<string, Partial<IFinancialEntityYear>> => {
    const varianceNotesById = varianceNoteData?.queryAccountVarianceNotes.toIdMap('accountId', entry => entry.text);
    const mvrDataKeys = Object.keys(mvrEntityDataMap);

    const updatedEntityMap:Record<string, Partial<IFinancialEntityYear>> = {};

    mvrDataKeys.map( key => {
        updatedEntityMap[key] = {
            ...mvrEntityDataMap[key],
            varianceNote: varianceNotesById[key] ?? undefined,
        };
    });

    return updatedEntityMap;
};
