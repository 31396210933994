import { ReactElement, useContext, useEffect, useState } from "react";
import { IWorkflowPageProps } from "../../logic/workflows";
import { ViziblyTheme } from "../../../../styles/zendesk-garden/ViziblyZDGTheme";
import { ThemeProvider } from "@zendeskgarden/react-theming";
import * as workflowCSS from "../../../../styles/workflows/workflowCSS.module.scss";
import * as workflowHeaderCSS from "../../../../components/workflows/workflow-header/styles/css.module.scss";
import { spreadsheetContainer } from "../../account/styles/styles.module.scss";
import WorkflowHeader from "../../../../components/workflows/workflow-header/WorkflowHeader";
import WorkflowNavToggle from "../../../../components/workflows/workflow-nav-toggle/WorkflowNavToggle";
import WorkflowPageLabel from "../../../../components/workflows/workflow-page-label/WorkflowPageLabel";
import WorkflowStepNav from "../../../../components/workflows/workflow-step-nav/WorkflowStepNav";
import { Tabs } from "@zendeskgarden/react-tabs";
import WorkflowYearTabs, { WORKFLOW_YEAR_TAB_BUDGET, WORKFLOW_YEAR_TAB_REFORECAST } from "../../../../components/workflows/workflow-year-tabs/WorkflowYearTabs";
import { OriginalRevenueMarketRentModeType, useGetForecastLocksLazyQuery, useUpdateOriginalRevenueMarketRentModeMutation, VersionType } from "../../../../__generated__/generated_types";
import { useProperties } from "../../../../contexts/properties/PropertiesContext";
import { AverageRentGrowth } from "./average-rent-growth/AverageRentGrowth";
import { FinancialMetrics } from "../../../../components/metrics/FinancialMetrics";
import { Dropdown, Field, Item, Menu, Select } from "@zendeskgarden/react-dropdowns";
import {AuthContext} from "../../../../contexts/AuthContext";
import { toast } from "react-toastify";
import AverageRentManual from "./AverageRentManual";

export default function AverageRent(props: IWorkflowPageProps): ReactElement {
    const [selectedTab, setSelectedTab] = useState(WORKFLOW_YEAR_TAB_BUDGET);
    const auth = useContext(AuthContext);
    const { currentProperty } = useProperties();
    const [getForecastLocks, { data: dataForecastLocks }] = useGetForecastLocksLazyQuery({
        fetchPolicy: "no-cache",
    });
    const [saveRevenueMarketRentMode] = useUpdateOriginalRevenueMarketRentModeMutation({
        notifyOnNetworkStatusChange: true,
        onCompleted: data => {
            if (data?.setOriginalRevenueMarketRentMode === true) {
                toast.success(`Updated Successfully`);
            }
            else {
                toast.error(`Failed To Update`);
            }
            auth.reloadUserWithProperties();
        },
    });

    useEffect(() => {
        if (!currentProperty) {
            return;
        }

        getForecastLocks({
            variables: {
                propertyId: currentProperty.id,
                budgetYear: currentProperty.budgetYear
            }
        });
    }, [currentProperty]);

    function handleMarketRentModeSelect(val: OriginalRevenueMarketRentModeType) {
        if (!currentProperty) {
            return;
        }
        saveRevenueMarketRentMode({
            variables: {
                propertyId: currentProperty.id,
                budgetYear: currentProperty.budgetYear,
                originalRevenueMarketRentModeType: val
            }
        });
    }

    return (
        <ThemeProvider theme={ViziblyTheme}>
            <div className={workflowCSS.workflowContainer}>
                <WorkflowHeader>
                    <WorkflowHeader.LeftCol>
                        <div className={workflowHeaderCSS.rowItemsContainer}>
                            <WorkflowNavToggle />
                            <WorkflowPageLabel label="Average Rent" />
                            {currentProperty &&
                            <Dropdown
                                selectedItem={currentProperty.originalRevenueMarketRentModeType}
                                onSelect={handleMarketRentModeSelect}
                            >
                                <Field>
                                    <Select>
                                        {currentProperty.originalRevenueMarketRentModeType == OriginalRevenueMarketRentModeType.Growth
                                        ? "Growth" : "Manual Entry"}
                                    </Select>
                                </Field>
                                <Menu>
                                    <Item key={OriginalRevenueMarketRentModeType.Growth} value={OriginalRevenueMarketRentModeType.Growth}>
                                        Growth
                                    </Item>
                                    <Item key={OriginalRevenueMarketRentModeType.Manual} value={OriginalRevenueMarketRentModeType.Manual}>
                                        Manual Entry
                                    </Item>
                                </Menu>
                            </Dropdown>
                            }
                        </div>
                    </WorkflowHeader.LeftCol>
                    <WorkflowHeader.RightCol>
                        <WorkflowStepNav onPreviousClick={props.onPreviousClick} onNextClick={props.onNextClick} />
                    </WorkflowHeader.RightCol>
                </WorkflowHeader>
                {currentProperty &&
                    <div className={workflowCSS.bodyContainer}>
                        <Tabs selectedItem={selectedTab} onChange={setSelectedTab} className={workflowCSS.accountYearTabNav}>
                            <WorkflowYearTabs year={currentProperty.reforecastYear}
                                selectedTab={selectedTab}
                                rawForecastLocks={dataForecastLocks}
                            />
                            <div className={workflowCSS.tabContent}>
                                <div className={workflowCSS.dataColumn}>
                                    {currentProperty.originalRevenueMarketRentModeType == OriginalRevenueMarketRentModeType.Growth &&
                                    <div className={`${workflowCSS.accountTableContainer} ${spreadsheetContainer}`}>
                                        <AverageRentGrowth property={currentProperty} versionType={selectedTab == WORKFLOW_YEAR_TAB_BUDGET ? VersionType.Budget : VersionType.Reforecast} />
                                    </div>
                                    }
                                    {currentProperty.originalRevenueMarketRentModeType == OriginalRevenueMarketRentModeType.Manual &&
                                    <div className={`${workflowCSS.accountTableContainer} ${spreadsheetContainer}`}>
                                        <AverageRentManual/>
                                    </div>
                                    }
                                    <div className={workflowCSS.accountFinancialsContainer}>
                                        <div className='financial-metrics-container'>
                                            <FinancialMetrics
                                                budgetComponentName="Gross potential rent"
                                                year={currentProperty.reforecastYear}
                                                accountId='' // this will fail to subscribe
                                                showActuals={selectedTab == WORKFLOW_YEAR_TAB_REFORECAST}
                                                className={spreadsheetContainer}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Tabs>
                    </div>
                }
            </div>
        </ThemeProvider>
    );
}