import React, { ReactElement, useContext, useEffect, useLayoutEffect, useRef, useState } from "react";

import { useConfig } from "../../../analyst/config/useConfig";

import { NewSpreadsheet } from "../../../../components/spreadsheet/NewSpreadsheet";
import { useNewSpreadsheetAPI } from "../../../../components/spreadsheet/NewSpreadsheetHooks";

import viewTemplate from "./two_years_market_rent_sjs_view.json";

import * as styles from "../helpers/styles.module.scss";

import { TwoYearsConfig as CFG } from "../../../../sjs/layout/two-years/helpers/TwoYearsConfig";
import { MarketRentsSJSSpreadProps } from "../helpers/types";
import MarketRentLayout from "./MarketRentLayout";

import { FinancialEntityType, VersionType } from "../../../../__generated__/generated_types";
import { userIsReadOnly } from "../../../../authorization/AuthorizationCheckers";
import { AuthContext } from "../../../../contexts/AuthContext";
import useMarketRentsData from "../data/useMarketRentsData";
import { MarketRentColId } from "./helpers/structs";

const MarketRentsSpread = ({
                                  setViewReady,
                                  setDataReady,
                                  setLoadProgress
                              }: MarketRentsSJSSpreadProps): ReactElement => {
    const ref = useRef<HTMLDivElement>(null);

    const config = useConfig();
    const { user } = useContext(AuthContext);

    const {
        unitAndMarketRentsTableData,
        // unitsAndMarketRentsLoading,
        dataReady: marketRentsTableDataLoaded,
        setMarketRent,
    } = useMarketRentsData({setLoadProgress});

    const [layout, setLayout] = useState<MarketRentLayout|undefined>();

    const {
        handlers: sshandlers,
        api: ssapi,
        isSpreadsheetReady,
        cellHovered,
        cellsChanged,
        cellClicked,
    } = useNewSpreadsheetAPI();

    // GENERAL EFFECTS _______________________________________________________________________________________________
    useEffect(
        () => {
            setLoadProgress(1);
        },
        []);

    // COMPONENT EFFECTS _______________________________________________________________________________________________
    /**
     * Called when the div the spreadsheet is in changes height.
     * Tells spreadsheet layout helper to update the host height to the new container height.
     */
    useLayoutEffect(
        () => {
            if(layout && ref.current && ref.current.id == 'MarketRentsSJSSpreadCell'){
                const containerHeight = ref.current.offsetHeight;
                const containerWidth = ref.current.offsetWidth;
                if(containerHeight && containerWidth){
                    layout.setHostDimensions(containerHeight, containerWidth);
                }
            }
        });

    // DATA EFFECTS ----------------------------------------------------------------------------------------------------
    useEffect(
        () => {
            if(!unitAndMarketRentsTableData || !marketRentsTableDataLoaded || !layout){
                return;
            }
            layout.renderTwoYearsTableData(unitAndMarketRentsTableData, setDataReady);
            setLoadProgress(100);
        },
        [unitAndMarketRentsTableData, marketRentsTableDataLoaded],
    );

// SPREADSHEET EFFECTS _____________________________________________________________________________________________
    /**
     * Initializes the spreadsheet layout helper when the spreadsheet and environment data is ready
     */
    useEffect(
        () => {
            if(!isSpreadsheetReady || !config.isReady){
                return;
            }

            setLayout(new MarketRentLayout({
                name: 'Unit',
                ssapi,
                template: viewTemplate,
                year: config.year,
                firstReforecastMonth: config.startReforecastMonthIndex,
            }));

            setViewReady(true);
        },
        [isSpreadsheetReady, config.isReady]
    );

    /**
     * Called when the selection is changing
     */
    useEffect(
        () => {
            if(!cellClicked || !layout){
                return;
            }

            layout.onCellSelect(
                cellClicked.row,
                cellClicked.col,
                CFG.MAIN_TAB_NAME
            );
        },
        [cellClicked]
    );

    useEffect(
        () => {
            if(!cellHovered || !layout || userIsReadOnly(user)){
                return;
            }
            const rowType = layout.getRowType(cellHovered.row);
            const lastRowType = cellHovered.prev?.row ? layout.getRowType(cellHovered.prev?.row) : undefined;

            if(rowType == FinancialEntityType.Account || lastRowType == FinancialEntityType.Account){
                layout.applyRowHoverStyle(cellHovered.row);
            }

            if(rowType == FinancialEntityType.Account || lastRowType == FinancialEntityType.Account){
                if(cellHovered.prev?.row){
                    layout.applyRowDefaultStyle(cellHovered.prev?.row);
                }
            }
        },
        [cellHovered?.row]);

    useEffect(
        () => {
            if(cellsChanged.length == 0 || !layout){
                return;
            }

            console.log(cellsChanged, `first reforecast cell: ${CFG.FIRST_MONTH_COL + 1 + config.startReforecastMonthIndex}, last reforecast cell: ${CFG.TOTALS_COL}`);

            cellsChanged.forEach( (cell, cellIdx ) => {
                if(typeof cell.value != 'number' && cell.value != null){
                    ssapi.directAccess( spread => {
                        spread.getSheetFromName(CFG.MAIN_TAB_NAME).setValue(cell.row, cell.col, cell.oldValue);
                    });
                    cellsChanged.splice(cellIdx, 1);
                } else {
                    console.log('value was a number or null');
                }
            });

            const changedReforecastCells = cellsChanged.filter(cell => {
                return layout.isCellInReforecastYear(cell.col);
            });

            const changedBudgetCells = cellsChanged.filter(cell => {
                return layout.isCellInBudgetYear(cell.col);
            });

            if(changedReforecastCells.length > 0){
                changedReforecastCells.forEach( (cell) => {
                    const dataRow = unitAndMarketRentsTableData[cell.row - CFG.FIRST_DATA_ROW];
                    if(dataRow){
                        setMarketRent(
                            dataRow[MarketRentColId.UNIT_ID] as string,
                            config.year,
                            cell.col - CFG.FIRST_MONTH_COL - 1,
                            VersionType.Reforecast,
                            Math.floor(cell.value as number),
                        );
                    }
                });
            }

            if(changedBudgetCells.length > 0){
                changedBudgetCells.forEach( (cell) => {
                    const dataRow = unitAndMarketRentsTableData[cell.row - CFG.FIRST_DATA_ROW];
                    if(dataRow){
                        setMarketRent(
                            dataRow[MarketRentColId.UNIT_ID] as string,
                            config.year + 1,
                            cell.col - CFG.FIRST_BUDGET_MONTH_COL - 1,
                            VersionType.Budget,
                            Math.floor(cell.value as number),
                        );
                    }
                });
            }
        },
        [cellsChanged]
    );

    return (
        <div
            id={'MarketRentsSJSSpreadCell'}
            ref={ref}
            className={styles.sheetContainer}>
            <NewSpreadsheet
                startRow={0}
                startCol={0}
                rows={9}
                cols={16}
                handlers={sshandlers}
                allowVerticalScroll={true}
                allowHorizontalScroll={true}
                subscribeToMouse={true}
                safeAreaFirstRow={3}
                safeAreaFirstColumn={2}
                style={{
                    maxWidth: "100%",
                    padding: "0 0",
                }}
                allowZoom={false}
            />
        </div>
    );
};

export default MarketRentsSpread;
