import {ReactElement, useEffect} from 'react';
import {useParams} from 'react-router-dom';
import {BudgetingType} from '../../../../../BudgetingType';
import {useProperties} from '../../../../../contexts/properties/PropertiesContext';
import {useSettings} from '../../../../../contexts/settings/SettingsContext';
import {
    UnitTypeScheduleValue,
    useGetRenewalRateCountsQuery,
    VersionType
} from '../../../../../__generated__/generated_types';
import UnitTypeLevelMetricTable from '../../UnitTypeLevelMetricTable';
import useOperationLoadingState from '../../hooks/useOperationLoadingState';
import {WeightFn} from '../../base';

export const weightFnRenewalRate: WeightFn<"renewalRatio"> = (value) => {
    const val = value as UnitTypeScheduleValue;
    return (val["leaseExpirationCount"] || 0) * (val["renewalRatio"] || 0);
};

export default function RenewalIncrease({ accountDataLoading, readOnly }:{ accountDataLoading?: (isLoading:boolean) => void, readOnly?: boolean }): ReactElement {
	const { type } = useParams() as { type: BudgetingType; propertyId: string };
    const { propertyId, loaded: propertyContextLoaded } = useProperties();
	const { year } = useSettings();

	const currentVersionYear = type == BudgetingType.BUDGET ? year + 1 : year;

	const data = useGetRenewalRateCountsQuery({
		variables: {
			propertyId: propertyId,
			versionType: type == BudgetingType.BUDGET ? VersionType.Budget : VersionType.Reforecast,
			budgetYear: year + 1,
			versionYear: currentVersionYear,
			year: year,
		},
		fetchPolicy: "network-only"
	});

	const { tableHasRendered } = useOperationLoadingState(data);
    useEffect(
            () => {
                if(data.loading || !tableHasRendered || !propertyContextLoaded){
                    return;
                }
                if(accountDataLoading){
                    accountDataLoading(false);
                }
            },
            [data.loading, tableHasRendered, propertyContextLoaded]
    );

    const canRender = tableHasRendered && propertyContextLoaded;

	return (
			<UnitTypeLevelMetricTable
				key="renewal-rate"
				currentVersionYear={currentVersionYear}
				field="renewalRate"
				propertyId={propertyId}
				type={type}
				data={data}
                canRender={canRender}

				parseFromRemote={(value: string | number) => typeof value === "string" ? parseFloat(value) : value}

				parseValue={value => parseFloat(value).toString()}
				aggregator="AVERAGE"
                columnAggregator="WEIGHTED_AVERAGE"
                weightFn={weightFnRenewalRate}

                readOnly={readOnly}
			/>
	);
}
