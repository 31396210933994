import { createContext, ReactElement, ReactNode, useEffect, useState } from "react";
import { getGuestAccessTokenFromStore, getGuestAccessTokenFromURL, getGuestIdTokenFromStore, removeGuestIdTokenFromStore, saveGuestAccessTokenToStore, saveGuestIdTokenToStore } from "../../libs/guest_token";
import { useAuthenticateGuestTokenLazyQuery, useMyUserLazyQuery, useRequestGuestOtpLazyQuery } from "../../__generated__/generated_types";
import { Property } from "../properties/PropertiesContext";

export type GuestUser = {
    id: string,
    clientId: string,
    clientName: string,
    clientDisplayName: string,
    firstName: string,
    lastName: string,
    email: string,
    property: Property
}

export type GuestAuthContext = {
    user: GuestUser|null,
    accessToken: string|null,
    idToken: string|null,
    requestOTP: () => void,
    signInWithOTP: (otp: string) => void
}

const defaultContext:GuestAuthContext = {
    user:null,
    accessToken: null,
    idToken: null,
    requestOTP: () => { /* no-op */},
    signInWithOTP: () => { /* no-op */},
};
export const GuestAuthenticationContext = createContext<GuestAuthContext>(defaultContext);

export function GuestAuthenticationContextProvider(props: {children: ReactNode|ReactNode[]}): ReactElement {
    const [context, setContext] = useState<GuestAuthContext>(defaultContext);
    const [mounted, setMounted] = useState(false);
    const [loadGuestUser, {data: guestUserData, loading: guestUserLoading}] = useMyUserLazyQuery({
        fetchPolicy: "no-cache"
    });

    const [sendRequestOTP] = useRequestGuestOtpLazyQuery({
        fetchPolicy: "no-cache"
    });
    const [sendAuthenticateToken, {data: idTokenData, loading: idTokenLoading}] = useAuthenticateGuestTokenLazyQuery({
        fetchPolicy: "no-cache"
    });
    useEffect(() => {
        let accessToken = getGuestAccessTokenFromURL();
        if (!accessToken) {
            accessToken = getGuestAccessTokenFromStore();
        }
        else {
            const accessTokenFromStore = getGuestAccessTokenFromStore();
            saveGuestAccessTokenToStore(accessToken);
            if (accessToken != accessTokenFromStore) {
                removeGuestIdTokenFromStore();
            }
        }
        const idToken = getGuestIdTokenFromStore();
        setContext({
            ...context,
            accessToken: accessToken,
            idToken: idToken
        });
        if (idToken) {
            loadGuestUser();
        }
        setMounted(true);
    }, []);

    useEffect(() => {
        if (!idTokenData || idTokenLoading) {
            return;
        }
        if (idTokenData.authenticateToken) {
            saveGuestIdTokenToStore(idTokenData.authenticateToken);
            setContext({
                ...context,
                idToken: idTokenData.authenticateToken
            });
            loadGuestUser();
        }
    }, [idTokenData, idTokenLoading]);

    useEffect(() => {
        if (!guestUserData || guestUserLoading) {
            return;
        }
        const myGuestUser = guestUserData.myUser;
        if (!myGuestUser) {
            return;
        }
        const property = myGuestUser.properties[0];
        if (!property) {
            return;
        }
        const user:GuestUser = {
            id: myGuestUser.id,
            clientId: myGuestUser.clientId,
            clientName: myGuestUser.clientName,
            clientDisplayName: myGuestUser.clientDisplayName,
            email: myGuestUser.email,
            firstName: myGuestUser.firstName,
            lastName: myGuestUser.lastName,
            property: {
                ...property,
                budgetYear: property.reforecastYear + 1
            }
        };
        setContext({
            ...context,
            user: user
        });
    }, [guestUserData, guestUserLoading]);


    function requestOTP() {
        if (context.accessToken) {
            removeGuestIdTokenFromStore();
            sendRequestOTP({
                variables: {
                    accessToken: context.accessToken
                }
            });
        }
    }

    function signInWithOTP(otp: string) {
        removeGuestIdTokenFromStore();
        if (context.accessToken) {
            sendAuthenticateToken({
                variables: {
                    accessToken: context.accessToken,
                    otp: otp
                }
            });
        }
    }

    const finalContext = {
        ...context,
        requestOTP: requestOTP,
        signInWithOTP: signInWithOTP
    };

    return (
        <GuestAuthenticationContext.Provider value={finalContext}>
            {mounted ? props.children : <></>}
        </GuestAuthenticationContext.Provider>
    );
}