/* eslint-disable @typescript-eslint/no-namespace */
export namespace RenovationPackageDrawerConfigs {
    export const RENOVATION_PACKAGE_LABEL = "RENOVATION PACKAGE";
    export const BLANK_CELL_VALUE = "";
    export const COST_CATEGORY_LABEL = "COST CATEGORY";
    export const GL_LABEL = "GL";
    export const AMOUNT_LABEL = "AMOUNT";
    export const DELAY_RECOGNITION_LABEL = "DELAY RECOGNITION";
    export const TOTAL_LABEL = "TOTAL";
    export const PREMIUM_LABEL = "PREMIUM";
    export const PREMIUM_LABEL_COL_INDEX = 0;
    export const DEFAULT_RENOVATION_NEW_PACKAGE_ID = "New#Package#UUID#";
    export const DEFAULT_RENOVATION_NEW_PACKAGE_NAME = "New Package";
    export const RENOVATION_PACKAGE_LABEL_ROW_INDEX = 0;
    export const RENOVATION_PACKAGE_LABEL_COL_INDEX = 0;
    export const PACKAGE_NAME_VALUE_ROW_INDEX = 0;
    export const FIRST_PACKAGE_NAME_VALUE_COL_INDEX = 2;
    export const COST_CATEGORY_LABEL_ROW_INDEX = 1;
    export const COST_CATEGORY_LABEL_COL_INDEX = 0;
    export const GL_LABEL_ROW_INDEX = 1;
    export const GL_LABEL_COL_INDEX = 1;
    export const AMOUNT_LABEL_ROW_INDEX = 1;
    export const FIRST_AMOUNT_VALUE_COL_INDEX = 2;
    export const DELAY_RECOGNITION_LABEL_ROW_INDEX = 1;
    export const FIRST_DELAY_RECOGNITION_VALUE_COL_INDEX = 3;
    export const FIRST_COST_CATEGORY_VALUE_ROW_INDEX = 2;
    export const TOTAL_LABEL_COL_INDEX = 0;
    export const DEFAULT_AMOUNT = 0;
    export const DEFAULT_DELAY_RECOGNITION = 1;
    export const DEFAULT_PREMIUM = 0;
    export const DEFAULT_PREMIUM_DELAY_RECOGNITION = 1;
}
