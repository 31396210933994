import { useState } from "react";

// eslint-disable-next-line no-var
var $lastUseTimingInfoTimestamp = Date.now();

export type TimingInfoEntry = {
    timestamp: number,
    event: string,
    timeDiff?:number,
    eventSource?: string,
}

interface ITimingInfoProps {
    timingEventRegistered: (evt: string) => void,
    isEnabled?: boolean,
}

export interface IUseTimingInfoReturn {
    timingInfo: TimingInfoEntry[],
    registerTimingEvent: (event: string, eventSource?: string) => number,
    registerTimingEventDone: (evtId:number, notes?:string) => void,
}

export default function useTimingEventReporter({ timingEventRegistered, isEnabled }: ITimingInfoProps): IUseTimingInfoReturn {
    const [timingInfo, setTimingInfo] = useState<TimingInfoEntry[]>([]);

    const registerTimingEvent = (event: string, eventSource?: string): number => {
        if(!isEnabled){
            return -1;
        }
        const updatedTimingInfo = timingInfo;

        const timestamp = Date.now();
        const timeDiff = timestamp - $lastUseTimingInfoTimestamp;

        timingEventRegistered(`${timestamp}: ${event} (${timeDiff} ms since last event)`);

        $lastUseTimingInfoTimestamp = timestamp;

        const newEvtId = updatedTimingInfo.push({ timestamp, event, eventSource, timeDiff });
        setTimingInfo(updatedTimingInfo);

        return newEvtId - 1;
    };

    const registerTimingEventDone = (evtId:number, notes?:string):void => {
        if(!isEnabled){
            return;
        }

        const updatedTimingInfo = timingInfo;
        const startEvent = updatedTimingInfo[evtId];

        if(startEvent != undefined){
            const timestamp = Date.now();
            const timeDiff = timestamp - startEvent.timestamp;

            let msg = `${startEvent.event} [DONE]`;
            if(notes){
                msg = `${msg} - ${notes}`;
            }

            timingEventRegistered(`${timestamp}: ${msg}; Event took ${timeDiff}ms`);

            updatedTimingInfo.push({ timestamp, event: `${msg}`, eventSource: startEvent.eventSource, timeDiff });
            setTimingInfo(updatedTimingInfo);
        }
    };

    return {
        timingInfo,
        registerTimingEvent,
        registerTimingEventDone,
    };
}