import {ReactElement, useEffect, useRef, useState} from "react";
import Handsontable from "handsontable";
import {HotTable} from "@handsontable/react";

import DownloadIcon from "../../../assets/icons/icon_download.svg";
import {LICENSES} from "../../../constants/Licenses";
import {hideAllChildRows, ITableDataByTabAndUnitType, TabNames, toggleHiddenRows} from "../logic/utils";
import * as CellRenderers from "../../../components/account-table/CellRenderers";
import * as css from "../styles/OpDriverModeling.module.scss";
import * as tableCSS from "../../../components/account-table/styles/accountTable.module.scss";
import {buildSummaryTableSettings} from "../logic/buildSummaryTableSettings";
import {Property} from "../../../contexts/properties/PropertiesContext";
import {MONTHS} from "../../../constants/Months";
import Card from "./Card";
import {Col} from "react-bootstrap";
import {Grid, Row} from "@zendeskgarden/react-grid";


interface ISummaryTableProps {
    currentProperty: Property,
    selectedTab: TabNames,
    selectedUnitType: string | undefined,
    tableDataByTabAndUnitType: ITableDataByTabAndUnitType[],
}

export default function SummaryTable({
    currentProperty,
    selectedTab,
    selectedUnitType,
    tableDataByTabAndUnitType
}: ISummaryTableProps): ReactElement {
     // register our custom renderers with HoT
     const cellRenderers = CellRenderers;
     Object.entries(cellRenderers).forEach(([key, value]) => {
         Handsontable.renderers.registerRenderer(key, value);
     });
    const hotRef = useRef<HotTable>(null);
    const visibleParentRows = useRef<Set<number>>(new Set<number>());
    const hiddenRows = useRef<number[]>([]);
    const [tableSettings, setTablesettings] = useState<Handsontable.GridSettings | undefined>();
    const [tableKey, setTableKey] = useState<string>('');

    useEffect(() => {
        hiddenRows.current = [];
        visibleParentRows.current = new Set<number>();
        hotRef.current?.hotInstance?.removeHook("afterOnCellMouseUp", () => undefined);

        setTableKey(`${selectedTab}=${selectedUnitType}`);

        return () => {
            hotRef.current?.hotInstance?.removeHook("afterOnCellMouseUp", () => undefined);
            hiddenRows.current = [];
            visibleParentRows.current = new Set<number>();
        };
    }, [selectedTab, selectedUnitType]);

    useEffect(() => {
        const settings = buildSummaryTableSettings(
            hotRef,
            tableDataByTabAndUnitType,
            columnsConfig,
            visibleParentRows.current,
            currentProperty,
        );
        setTablesettings(settings);
    }, [tableDataByTabAndUnitType]);

    const columns: string[] = [
        "",
        ...MONTHS.slice(0, currentProperty.reforecastStartMonthIndex).map((m) => `Actual - ${m}`),
        ...MONTHS.slice(currentProperty.reforecastStartMonthIndex).map((m) => `Reforecast - ${m}`),
        "Reforecast Average",
        ...MONTHS.map((m) => `Budget - ${m}`),
        "Budget Average",
        "Reforecast Average",
    ];

    const columnsConfig = new Array(28).fill(0).map((_each, index) => ({
        title: columns[index] || '',
        data: `col${index}`,
    }));

    const hiddenColumns: number[] = [];
    if(selectedTab === TabNames.renovations) {
        for(let i = 1; i <= currentProperty.reforecastStartMonthIndex; i++) {
            hiddenColumns.push(i);
        }
    }


    const handleExportClick = () => {
        const hot = hotRef.current?.hotInstance;

        if (hot && currentProperty) {
            hot.getPlugin('exportFile').downloadFile('csv', {
                bom: false,
                columnDelimiter: ',',
                columnHeaders: true,
                exportHiddenColumns: false,
                exportHiddenRows: true,
                fileExtension: 'csv',
                filename: `${currentProperty.name}_${currentProperty.budgetYear} Revenue Summary_${new Date().toLocaleDateString('en-US')}`,
                mimeType: 'text/csv',
                rowDelimiter: '\r\n',
                rowHeaders: true,
            });
        }
    };

    useEffect(() => {
        if (!hotRef.current?.hotInstance || !tableSettings || tableDataByTabAndUnitType.length === 0) {
            return;
        }

        hideAllChildRows(hotRef.current.hotInstance, tableDataByTabAndUnitType);
        hiddenRows.current = hotRef.current.hotInstance.getPlugin("hiddenRows").getHiddenRows();

        hotRef.current.hotInstance.removeHook("afterOnCellMouseUp", () => undefined);
        hotRef.current.hotInstance.addHook("afterOnCellMouseUp", function(_event, {row, col}, _td) {
            if (!hotRef.current?.hotInstance) {
                return;
            }

            const rowData = tableDataByTabAndUnitType[row];

            if (rowData && rowData.id && col == 0) {
                const isVisible = toggleHiddenRows(hotRef.current.hotInstance, tableDataByTabAndUnitType, rowData.id, row, selectedTab);


                if (isVisible) {
                    visibleParentRows.current.add(row);
                } else {
                    visibleParentRows.current.delete(row);
                }
                hiddenRows.current = hotRef.current.hotInstance.getPlugin('hiddenRows').getHiddenRows();
                hotRef.current.hotInstance.render();
            }
        });
    }, [tableSettings]);

    return (
        <Grid className={`${css.bodyContent} ${css.summaryWrapper}`} gutters={"lg"}>
            <Row>
                <Col>
                    <Card
                        title="Summary"
                        actions={(
                            <button id="export-file" onClick={handleExportClick} className={css.downloadBtn}>
                                <img src={DownloadIcon} alt="download" />
                            </button>
                        )}
                        children={(
                            <div className={`${tableCSS.accountTableWrapper} ${css.summaryTableWrapper}`}>
                                <HotTable
                                    id="simplified-revenue-summary"
                                    className={tableCSS.summaryTable}
                                    data={tableDataByTabAndUnitType.concat()}
                                    licenseKey={LICENSES.HandsOnTable}
                                    ref={hotRef}
                                    style={{width: "100%", overflowX: "scroll", overflowY: "auto", height: "auto"}}
                                    settings={tableSettings}
                                    columns={columnsConfig}
                                    undo={false}
                                    hiddenRows={{
                                        rows: hiddenRows.current
                                    }}
                                    hiddenColumns={{
                                        columns: hiddenColumns
                                    }}
                                    key={tableKey}
                                />
                            </div>
                        )}
                    />
                </Col>
            </Row>
        </Grid>
    );
}
