/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { useReactiveVar } from '@apollo/client';
import { AppLocalStore, AppLocalStoreState } from '../../utils/local-store/AppLocalStore';

const _app = AppLocalStore;

function useAppStore() {
    const state = useReactiveVar(AppLocalStore);

    const set = (data: AppLocalStoreState) => {
        _app({ ..._app(), ...data });
    };

    return {
        ...state,
        set,
    };
}

export default useAppStore;