import React, {ReactElement} from "react";
import styles from "../AnalystPayrollDrawer.module.scss";
import {Field, Input, Label} from "@zendeskgarden/react-forms";
import {Col, Row} from "@zendeskgarden/react-grid";
import {
    LocalPayrollEmployeeModelKeys, LocalPayrollEmployeeOvertimeModel
} from "../helpers/types";
import {Datepicker} from "@zendeskgarden/react-datepickers";
import {Button} from "@zendeskgarden/react-buttons";
import {DateFormatter} from "../helpers/utils";
import {ensureDateInLocale, ensureDateInUtc} from "../date-helpers";

interface OvertimeEntryProps {
    index: number,
    overtime: LocalPayrollEmployeeOvertimeModel,
    allowRemove: boolean,
    updateFormState: (key: LocalPayrollEmployeeModelKeys, value: any) => void
}

export default function OvertimeEntry({index, overtime, allowRemove, updateFormState}: OvertimeEntryProps): ReactElement {
    return (
        <div style={{display: "block"}}>
            <Row justifyContent="between">
                <Col>
                    <Label className={styles.earningsSubHeaders}>Overtime {index + 1}</Label>
                </Col>
                {
                    allowRemove && <Button isBasic isDanger onClick={() => {
                        updateFormState(LocalPayrollEmployeeModelKeys.removeOvertime, index);
                    }}>Remove</Button>
                }
            </Row>
            <Row justifyContent="between">
                <Col>
                    <Field className={styles.field}>
                        <Label className={styles.label}>Hourly Rate</Label>
                        <Input placeholder="$" value={overtime.hourlyCompensation}
                               type={"number"}
                               onChange={(evt) => {
                                   updateFormState(LocalPayrollEmployeeModelKeys.overtimesHourlyRate, evt.target.value);
                               }}
                        />
                    </Field>
                </Col>
                <Col>
                    <Field className={styles.field}>
                        <Label className={styles.label}>Hours/Week</Label>
                        <Input placeholder="Hours" value={overtime.expectedHoursPerWeek}
                               type={"number"}
                               onChange={(evt) => {
                                   updateFormState(LocalPayrollEmployeeModelKeys.overtimeHoursPerWeek, evt.target.value);
                               }}
                        />
                    </Field>
                </Col>
            </Row>
            <Row justifyContent="between">
                <Col>
                    <Field className={styles.field}>
                        <Label className={styles.label}>Start Date</Label>
                        <Datepicker
                            value={overtime.effectiveStartDate ? ensureDateInLocale(overtime.effectiveStartDate) : undefined}
                            onChange={(effectiveStartDate) => {
                                updateFormState(
                                    LocalPayrollEmployeeModelKeys.overtimeStartDate,
                                    ensureDateInUtc(effectiveStartDate)?.toISOString());
                            }}
                            formatDate={date => {
                                return date ? DateFormatter.format(date) : "";
                            }}
                        >
                            <Input/>
                        </Datepicker>
                    </Field>
                </Col>
                <Col>
                    <Field className={styles.field}>
                        <Label className={styles.label}>End Date</Label>
                        <Datepicker
                            minValue={overtime.effectiveStartDate ? ensureDateInLocale(overtime.effectiveStartDate) : undefined}
                            value={overtime.effectiveEndDate ? ensureDateInLocale(overtime.effectiveEndDate) : undefined}
                            onChange={(effectiveEndDate) => {
                                updateFormState(
                                    LocalPayrollEmployeeModelKeys.overtimeEndDate,
                                    ensureDateInUtc(effectiveEndDate)?.toISOString());
                            }}
                            formatDate={date => {
                                return date ? DateFormatter.format(date) : "";
                            }}
                        >
                            <Input disabled={overtime.effectiveStartDate === undefined}/>
                        </Datepicker>
                    </Field>
                </Col>
            </Row>
        </div>
    );
}

