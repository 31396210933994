/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
// import { useState } from "react";

export const broadcast = new BroadcastChannel("user_cell_edits");

function useBroadcast() {
    const receiveMessage = (callback: (event: MessageEvent<any>) => void) => {
        broadcast.onmessage = (event) => {
            callback(event);
        };
    };
    const broadcastMessage = (message: any) => {
        broadcast.postMessage(message);
    };
    return {
        broadcast,
        receiveMessage,
        broadcastMessage,
    };
}

export default useBroadcast;
