import plusMinusOpenIcon from "../icons/minus_icon_10x10.svg";
import plusMinusClosedIcon from "../icons/plus_icon_10x10.svg";

import plusMinusLoadingIcon from "../icons/plus_icon_loading_10x10.svg";

import chevronOpenIcon from "../icons/chevron01_open_12x12.svg";
import chevronClosedIcon from "../icons/chevron01_closed_12x12.svg";

import setupButtonImage from "../icons/setup.svg";
import setupButtonHoverImage from "../icons/setup_hover.svg";

export const plusMinusOpen = new Image();
plusMinusOpen.src = plusMinusOpenIcon;
export const plusMinusOpenW = 10;
export const plusMinusOpenH = 10;

export const plusMinusClosed = new Image();
plusMinusClosed.src = plusMinusClosedIcon;
export const plusMinusClosedW = 10;
export const plusMinusClosedH = 10;

export const plusMinusLoading = new Image();
plusMinusLoading.src = plusMinusLoadingIcon;
export const plusMinusLoadingW = 10;
export const plusMinusLoadingH = 10;

export const chevronOpen = new Image();
chevronOpen.src = chevronOpenIcon;
export const chevronOpenW = 12;
export const chevronOpenH = 12;

export const chevronClosed = new Image();
chevronClosed.src = chevronClosedIcon;
export const chevronClosedW = 12;
export const chevronClosedH = 12;

export const setupButton = new Image();
setupButton.src = setupButtonImage;
export const setupW = 73;
export const setupH = 24;

export const setupButtonHover = new Image();
setupButtonHover.src = setupButtonHoverImage;
export const setupHoverW = 73;
export const setupHoverH = 24;

import fourArrowOpenIcon from "../icons/four_arrow_open_24x24.png";
import fourArrowOpenHoverIcon from "../icons/four_arrow_open_hover_24x24.png";
import fourArrowClosedIcon from "../icons/four_arrow_closed_24x24.png";
import fourArrowClosedHoverIcon from "../icons/four_arrow_closed_hover_24x24.png";

export const fourArrowOpen = new Image();
fourArrowOpen.src = fourArrowOpenIcon;
export const fourArrowOpenW = 24;
export const fourArrowOpenH = 24;

export const fourArrowOpenHover = new Image();
fourArrowOpenHover.src = fourArrowOpenHoverIcon;
export const fourArrowOpenHoverW = 24;
export const fourArrowOpenHoverH = 24;

export const fourArrowClosed = new Image();
fourArrowClosed.src = fourArrowClosedIcon;
export const fourArrowClosedW = 24;
export const fourArrowClosedH = 24;

export const fourArrowClosedHover = new Image();
fourArrowClosedHover.src = fourArrowClosedHoverIcon;
export const fourArrowClosedHoverW = 24;
export const fourArrowClosedHoverH = 24;

import twoArrowOpenIcon from "../icons/two_arrow_open_24x24.svg";
import twoArrowClosedIcon from "../icons/two_arrow_closed_24x24.svg";
import twoArrowClosedHoverIcon from "../icons/two_arrow_closed_hover_24x24.svg";

export const twoArrowOpen = new Image();
twoArrowOpen.src = twoArrowOpenIcon;
export const twoArrowOpenW = 24;
export const twoArrowOpenH = 24;

export const twoArrowClosed = new Image();
twoArrowClosed.src = twoArrowClosedIcon;
export const twoArrowClosedW = 24;
export const twoArrowClosedH = 24;

export const twoArrowClosedHover = new Image();
twoArrowClosedHover.src = twoArrowClosedHoverIcon;
export const twoArrowClosedHoverW = 24;
export const twoArrowClosedHoverH = 24;

import simpleXIcon from "../icons/simple_X_12x12.svg";

export const simpleX = new Image();
simpleX.src = simpleXIcon;
export const simpleXW = 12;
export const simpleXH = 12;
