import { Button } from "@zendeskgarden/react-buttons";
import { Body, Close, Footer, FooterItem, Header, Modal } from "@zendeskgarden/react-modals";
import { ReactElement, useContext, useEffect, useState } from "react";
import { Inline } from "@zendeskgarden/react-loaders";
import { COLORS } from "../../../../constants/Colors";
import { User } from "../../types";
import { AuthContext } from "../../../../contexts/AuthContext";

export interface IUserDeleteConfirmation {
    users: User[],
    selectedUsers: string[],
    loading: boolean,
    onClose: () => void,
    onConfirm: () => void,
}

export default function UserDeleteConfirmation(props: IUserDeleteConfirmation): ReactElement {

    const authContext = useContext(AuthContext);
    const [currentUserSelected, setCurrentUserSelected] = useState<boolean>(false);

    function getSelectedUsersName(userId: string | undefined): string {
        const user = props.users.find(x => x.id == userId);

        if (!user?.firstName && !user?.lastName) {
            return "this user";
        }

        return `${user.firstName} ${user.lastName}`;
    }

    function getMessageText(): string {
        if (currentUserSelected) {
            return "You are not able to delete yourself. Please deselect your user before trying again.";
        } else if (props.selectedUsers.length == 1) {
            return `Are you sure you want to delete ${getSelectedUsersName(props.selectedUsers[0])}? They will no longer be able to access Vizibly.`;
        } else {
            return `Are you sure you want to delete ${props.selectedUsers.length} users? They will no longer be able to access Vizibly.`;
        }
    }

    useEffect(
        () => {
            if (!authContext || authContext == undefined || !authContext.user) {
                return;
            }

            setCurrentUserSelected(props.selectedUsers.includes(authContext.user.id));
        },
        [authContext]
    );

    return (
        <Modal onClose={props.onClose}>
            <Header isDanger>
                Confirm Delete
            </Header>
            <Body>{getMessageText()}</Body>
            <Footer>
                <FooterItem>
                    <Button onClick={props.onClose} isBasic disabled={props.loading}>
                        Cancel
                    </Button>
                </FooterItem>
                <FooterItem>
                    {
                        !currentUserSelected &&
                        <Button isPrimary isDanger onClick={props.onConfirm} disabled={props.loading}>
                            {
                                props.loading
                                    ? <Inline size={24} color={COLORS.NEGATIVE_COLOR} aria-label="loading"/>
                                    : <span>Delete</span>
                            }
                        </Button>
                    }
                </FooterItem>
            </Footer>
            <Close aria-label="Close modal" />
        </Modal>
    );
}