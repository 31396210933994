import { COLORS } from "../../../../../../../../constants/Colors";
import { formatterDollarUSNoDecimal } from "../../../../../../../../utils/fotmatters";
import { IOverviewDataChart } from "../data/useOverviewData";
import * as css from "./../styles/css.module.scss";

export const getChartOptions = (viewMode = 'month', overviewData: IOverviewDataChart): (Highcharts.Options) => {
    let series: Highcharts.SeriesOptionsType[] | undefined;
    const chartDataYTD = overviewData.ytd;
    const chartDataMonth = overviewData.month;


    if (viewMode == "ytd") {
        series = [
            {
                type: 'column',
                name: 'Actual',
                color: COLORS.LAVENDER_200,
                data: chartDataYTD.chartData.actual,
            },
            {
                type: 'line',
                name: 'Budget',
                color: COLORS.PRIMARY_300,
                data: chartDataYTD.chartData.budget,
            }
        ];
    } else {
        series = [
            {
                type: 'column',
                colors: [COLORS.LAVENDER_200, COLORS.PRIMARY_300],
                data: chartDataMonth.chartData,
                pointWidth: 87,
                colorByPoint: true,
            },
        ];
    }

    return {
        title: {
            text: ""
        },
        credits: {
            enabled: false
        },
        chart: {
            type: 'column',
            height: 148,
            spacingBottom: 2
        },
        legend: {
            enabled: viewMode == 'ytd',
        },
        tooltip: {
            useHTML: true,
            formatter: function (): string {
                const pIndex = this.point.index;

                if (viewMode == 'ytd') {
                    const actualValue = chartDataYTD.chartData.actual[pIndex];
                    const budgetValue = chartDataYTD.chartData.budget[pIndex];

                    // chart should not even display without presence of the values
                    if (
                        actualValue == undefined
                        || budgetValue == undefined
                        || typeof actualValue != 'number'
                        || typeof budgetValue != 'number'
                    ) {
                        return "No Data";
                    }

                    return `
                        <div class="${css.overviewChartTooltip}">
                            <div>Actual: <strong>${formatterDollarUSNoDecimal.format(actualValue)}</strong></div>
                            <div>Budget: <strong>${formatterDollarUSNoDecimal.format(budgetValue)}</strong></div>
                        </div>
                    `;
                }

                return `${this.x}: <span style='font-weight: 800;'>${formatterDollarUSNoDecimal.format(this.y ?? 0)}</span>`;
            }
        },
        xAxis: {
            lineWidth: 1,
            lineColor: COLORS.WHITE_SMOKE,
            categories: viewMode == 'ytd' ? chartDataYTD.categories : chartDataMonth.categories,
        },
        yAxis: [{
            title: {
                text: ""
            },
            tickAmount: 4,
            maxPadding: 0,
            minPadding: 0,
            lineWidth: 1,
            lineColor: COLORS.WHITE_SMOKE,
            labels: {
                formatter: function (): string {
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    const value: number = parseInt(this.value);

                    if (value == 0) return "0";

                    let label = value < 0 ? '-$' : '$';
                    label += Math.abs(value) >= 1000 ? (Math.abs(value) / 1000) + 'k' : Math.abs(value);

                    return label;
                }
            }
        }],
        series: series
    };
};

export type OverviewOption = {
    label: string,
    value: string
};

export const getVarianceColor = (variance: number, isPositiveGood: boolean): string => {
    if (variance == 0) return '';

    return (isPositiveGood && variance >= 0) ? css.valueGreen : css.valueRed;
};