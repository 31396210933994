import { ReactElement, useEffect, useState } from "react";
import { Dropdown, Menu, Item, Trigger, Autocomplete, Field, Label } from '@zendeskgarden/react-dropdowns';
import { IMenuTitleOption } from "./logic/menuTitle";
import * as css from "./styles/styles.module.scss";
import { ReactComponent as ChevronDownIcon } from '@zendeskgarden/svg-icons/src/16/chevron-down-stroke.svg';

interface MenuTitleProps {
    options: IMenuTitleOption[],
    small?: boolean,
    align?: string,
    plain?: boolean,
    noUnderline?: boolean,
    className?: string,
    titleTextClassName?: string,
    autocomplete?: boolean,
    onChange?: (activeSelection: number) => void,
}

export default function MenuTitle(props: MenuTitleProps): ReactElement {
    const [activeSelection, setActiveSelection] = useState<IMenuTitleOption | undefined>(props.options[0]);
    const [inputValue, setInputValue] = useState('');
    const [matchingOptions, setMatchingOptions] = useState<IMenuTitleOption[]>(props.options);

    useEffect(
        () => {
            setInputValue('');

            if (props.onChange == undefined || !activeSelection) {
                return;
            }

            props.onChange(activeSelection.value);
        },
        [activeSelection]
    );

    useEffect(
        () => {
            if (!props.options) {
                return;
            }

            setMatchingOptions(props.options);

            // Backfill the activeSelection if none was available on mount
            if (!activeSelection || !props.options.includes(activeSelection)) {
                setActiveSelection(props.options[0]);
            }
        },
        [props.options]
    );

    useEffect(
        () => {
            if (props.autocomplete) {
                // filterMatchingOptionsRef.current(inputValue);
                const matchedOptions = props.options.filter(
                        option => option.label.trim().toLowerCase().indexOf(inputValue.trim().toLowerCase()) !== -1
                );
                setMatchingOptions(matchedOptions);
            } else {
                setMatchingOptions(props.options);
            }
        }, [inputValue]
    );

    let alignmentClass;
    switch (props.align) {
        case 'center':
            alignmentClass = css.alignCenter;
            break;
        case 'right':
            alignmentClass = css.alignRight;
            break;
    }

    // If only one option, disregard the dropdown menu functionality
    if (props.options.length == 1) {
        return (
            <div className={`
                ${css.menuTitle}
                ${props.className ? props.className : ''}
                ${props.small ? css.menuTitleSmall : ''}
                ${props.plain ? css.menuTitlePlain : ''}
                ${props.noUnderline ? css.menuTitleNoUnderline : ''}
                ${alignmentClass ?? ''}
            `}>
                <h4 className={props.titleTextClassName ? props.titleTextClassName : css.menuTitleText}>{props.options[0]?.label}</h4>
            </div>
        );
    }

    // with multiple options, turn it into a dropdown select
    return (
        <div className={`
                ${css.menuTitle}
                ${css.menuTitleHasOptions}
                ${props.className ? props.className : ''}
                ${props.small ? css.menuTitleSmall : ''}
                ${props.plain ? css.menuTitlePlain : ''}
                ${props.noUnderline ? css.menuTitleNoUnderline : ''}
                ${props.autocomplete ? css.menuTitleTextAutocomplete : ''}
                ${alignmentClass ?? ''}
            `}
        >
            <Dropdown
                selectedItem={activeSelection}
                onSelect={setActiveSelection}
                onInputValueChange={value => {
                    if (props.autocomplete) {
                        setInputValue(value);
                    }
                }}
                downshiftProps={{ itemToString: (item: IMenuTitleOption) => item && item.label }}
            >
                    {/* if using an autocomplete  */}
                    {
                        props.autocomplete ?
                        <Field>
                            <Label hidden>Search Items</Label>
                            <Autocomplete isCompact className={props.titleTextClassName ? props.titleTextClassName : css.menuTitleText}>{activeSelection?.label}</Autocomplete>
                        </Field>
                        : <Trigger>
                            <div>
                                <span className={props.titleTextClassName ? props.titleTextClassName : css.menuTitleText}>{activeSelection?.label}</span>
                                {!props.plain && <ChevronDownIcon />}
                            </div>
                        </Trigger>
                    }
                <Menu className={css.menuTitleOptionList}>
                    {
                        matchingOptions && matchingOptions.length > 0 ?
                            matchingOptions.map(option => (
                            <Item
                                key={option.value}
                                value={option}
                                style={{'paddingLeft': `calc(${option.depth ?? 0} * 1rem + 2.5rem)`}}
                                className={option.bold != undefined && option.bold ? css.menuTitleOptionBold : ''}
                            >
                                {option.label}
                            </Item>
                        ))
                        :  <Item disabled>
                            No Results Found
                        </Item>
                    }
                </Menu>
            </Dropdown>
        </div>
    );
}