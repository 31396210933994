export enum RevenueSource {
    AVG_MARKET_RENTS = "AVG_MARKET_RENTS",
    EXPIRATIONS = "EXPIRATIONS",
    RENEWALS_RATE = "RENEWALS_RATE",
    RENEWALS_RATIO = "RENEWALS_RATIO",
    MOVE_OUTS = "MOVE_OUTS",
    EARLY_TERMS = "EARLY_TERMS",
    MONTH_TO_MONTH = "MONTH_TO_MONTH",
    OCCUPANCY = "OCCUPANCY",
    SUMMARY = "SUMMARY",
}


export const REVENUE_SOURCE_DESCRIPTIONS = {
    [RevenueSource.AVG_MARKET_RENTS]: {
        title: "Avg Market Rents",
        path: "average-rent",
        next: RevenueSource.EXPIRATIONS,
        previous: null
    },
    [RevenueSource.EXPIRATIONS]: {
        title: "Expirations",
        path: "lease-expirations",
        previous: RevenueSource.AVG_MARKET_RENTS,
        next: RevenueSource.RENEWALS_RATIO

    },
    [RevenueSource.RENEWALS_RATIO]: {
        title: "Renewal Ratio",
        path: "renewal-ratios",
        previous: RevenueSource.EXPIRATIONS,
        next: RevenueSource.RENEWALS_RATE
    },
    [RevenueSource.RENEWALS_RATE]: {
        title: "Renewal Increase",
        path: "renewal-rates",
        previous: RevenueSource.RENEWALS_RATIO,
        next: RevenueSource.MOVE_OUTS
    },
    [RevenueSource.MOVE_OUTS]: {
        title: "Move Outs",
        path: "move-outs",
        previous: RevenueSource.RENEWALS_RATE,
        next: RevenueSource.EARLY_TERMS
    },
    [RevenueSource.EARLY_TERMS]: {
        title: "Early Terms",
        path: "early-terminations",
        previous: RevenueSource.MOVE_OUTS,
        next: RevenueSource.MONTH_TO_MONTH
    },
    [RevenueSource.MONTH_TO_MONTH]: {
        title: "Month to Month Move Outs",
        path: "month-to-month-move-outs",
        previous: RevenueSource.EARLY_TERMS,
        next: RevenueSource.OCCUPANCY
    },
    [RevenueSource.OCCUPANCY]: {
        title: "Occupancy",
        path: "occupancy-rates",
        previous: RevenueSource.MONTH_TO_MONTH,
        next: RevenueSource.SUMMARY
    },
    [RevenueSource.SUMMARY]: {
        title: "Summary",
        path: "summary",
        previous: RevenueSource.OCCUPANCY,
        next: null
    },
};

export const REVENUE_WORKFLOW = [
    {
        title: "Revenue Workflow",
        children: Object.entries(REVENUE_SOURCE_DESCRIPTIONS).map(([id, entity]) => ({
            title: entity.title,
            id,
            url: "/operational/" + entity.path,
        }))
    }
];