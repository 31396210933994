import {ReactElement, useEffect, useState} from "react";
import * as css from "./styles/css.module.scss";
import * as widgetCss from "./components/graph-table-widget/styles/css.module.scss";

import useAppStore from "../../hooks/useAppStore";

import CurrentFocusedYear from "./components/current-focused-year/CurrentFocusedYear";
import DashboardHeader from "./components/dashboard-header/DashboardHeader";

import usePlanningDashboardData from "./data/usePlanningDashboardData";
import OverviewCard, {TOverviewCardProps} from "./components/overview-card/OverviewCard";
import {parseDashboardData} from "./data/logic/planningDashboardData";
import GraphTableWidget from "./components/graph-table-widget/GraphTableWidget";
import {ThemeProvider} from "styled-components";
import {ViziblyTheme} from "../../styles/zendesk-garden/ViziblyZDGTheme";
import RentsTable from "./components/rents-table/RentsTable";
import {OperationalGraph} from "./components/operational-graph/OperationalGraph";
import {Col, Grid, Row} from "@zendeskgarden/react-grid";
import RentsGraph from "./components/rents-graph/RentsGraph";
import WidgetLoading from "./components/widget-loading/WidgetLoading";
import {IRentsDataParsed} from "./data/logic/rentsData";
import MenuTitle from "../../components/menu-title/MenuTitle";
import {formatValueForDataType, getHotTableDataFromParsedSeriesData, operationalMenuOptions} from "./data/logic/operationalData";
import RentsRange from "./components/rents-range/RentsRange";
import AccountOverviewTables from "./components/account-overview-tables/AccountOverviewTables";
import useOperationalData, {TWidgetTableConfig} from "./data/useOperationalData";
import {OpDriverMetricType} from "../analyst/tab-op-drivers/enums";
import * as statCSS from "../../components/account-summary/styles/styles.module.scss";
import OperationalTable from "./components/operational-table/OperationalTable";
import {Skeleton} from "@zendeskgarden/react-loaders";
import {GLAccountsGraph} from "./components/gl-accounts/GLAccountsGraph";
import {buildAccountMenuOptions, buildComparisonMenuOptions, getGLAccountChartData, getVarianceColorForAccount, parseGLAccountData} from "./data/logic/glAccountData";
import useGLAccountData from "./data/useGLAccountData";
import {IMenuTitleOption} from "../../components/menu-title/logic/menuTitle";
import GLAccountsTable from "./components/gl-accounts/GLAccountsTable";
import {TPlanDashChartData, TPlanDashChartSeriesData} from "./data/logic/commonWidgetData";
import {IFinancialEntityWithDepth} from "../../utils/account";
import useTopCardTagSettings from "../admin/top-card-tag-settings/data/useTopCardTagSettings";
import {TopCardTagType} from "../../__generated__/generated_types";
import StartPlanningCards from "../start-planning/StartPlanningCards";

export default function PlanningDashboard(): ReactElement {

    const appStore = useAppStore();
    const dashboardData = usePlanningDashboardData();
    const operationalData = useOperationalData();
    const glAccountData = useGLAccountData();
    const topCardSettings = useTopCardTagSettings();

    const [overviewCards, setOverviewCards] = useState<TOverviewCardProps[]>([]);
    const [availableTopCardTags, setAvailableTopCardTags] = useState<TopCardTagType[] | undefined>();

    // Rents Widget Support
    // const [rentsData, setRentsData] = useState<IRentsDataParsed>();
    // const [rentsFirstYear, setRentsFirstYear] = useState<number>(0);

    useEffect(
        () => {
            if (dashboardData.loading
                || !dashboardData.data
                || !dashboardData.focusYear
                || availableTopCardTags == undefined
            ) {
                return;
            }

            const parsed = parseDashboardData(dashboardData.data, dashboardData.focusYear, availableTopCardTags);
            setOverviewCards(parsed.overviewCards);
            // setRentsData(parsed.rents);
        },
        [dashboardData.loading, dashboardData.data, dashboardData.focusYear, availableTopCardTags]
    );

    useEffect(
        () => {
            appStore.set({isLoading: false});
            topCardSettings.fetch([], []);
            // eslint-disable-next-line @typescript-eslint/no-empty-function
            return () => {
            };
        },
        []
    );

    useEffect(() => {
        if (topCardSettings.data && !topCardSettings.loading && topCardSettings.initialized) {
            setAvailableTopCardTags(topCardSettings.data.map(x => x.topCardTagType));
        }
    }, [topCardSettings.data, topCardSettings.loading, topCardSettings.initialized]);

    // GL Account Widget Support
    const [parsedGLData, setParsedGLData] = useState<TPlanDashChartSeriesData[]>([]);
    const [glAccountChartData, setGLAccountChartData] = useState<TPlanDashChartData>();
    const [glAccountInfo, setGLAccountInfo] = useState<IFinancialEntityWithDepth | undefined>();
    const [glAccountOptions, setGLAccountOptions] = useState<IMenuTitleOption[]>([]);
    const [glSelectedAccount, setGLSelectedAccount] = useState<number>(0);
    const [glComparisonOptions, setGLComparisonOptions] = useState<IMenuTitleOption[]>([]);
    const [glSelectedComparison, setGLSelectedComparison] = useState<number>(0);
    const [glAccountsTableConfig, setGLAccountsTableConfig] = useState<TWidgetTableConfig | undefined>();
    const [glChangeVarianceColor, setGLChangeVarianceColor] = useState<string>('');

    useEffect(
        () => {
            if (!glAccountData.data || glAccountData.data.length == 0 || glAccountData.startMonthIndex == undefined || !glAccountData.coaWithDepths) {
                return;
            }

            const parsedData = parseGLAccountData(
                glAccountData.data,
                glAccountData.startMonthIndex,
            );
            setParsedGLData(parsedData);

            const _comparisonOptions = buildComparisonMenuOptions(glAccountData.data);
            setGLComparisonOptions(_comparisonOptions);

            const builtChartData = getGLAccountChartData(parsedData, _comparisonOptions, glSelectedComparison, glAccountData.focusYear);
            setGLAccountChartData(builtChartData);

            const tableData = getHotTableDataFromParsedSeriesData({
                varianceCounts: builtChartData.varianceCounts,
                chartSeriesData: builtChartData.chartSeriesData,
            }, builtChartData.summaryData, 'dollar');
            setGLAccountsTableConfig(tableData);

            const varianceColor = getVarianceColorForAccount(glAccountData.coaWithDepths[glSelectedComparison], builtChartData.summaryData?.varianceAmount?.value);
            setGLChangeVarianceColor(varianceColor);

            setGLAccountInfo(glAccountData.coaWithDepths[glSelectedComparison]);
        },
        [glAccountData.isReady]
    );

    useEffect(
        () => {
            if (glSelectedAccount == undefined || !glAccountData.coaWithDepths) {
                return;
            }

            const account = glAccountData.coaWithDepths[glSelectedAccount];

            if (account) {
                glAccountData.fetch(account.id);
            }
        },
        [glSelectedAccount]
    );

    useEffect(
        () => {
            if (glSelectedAccount == undefined || glSelectedComparison == undefined || !glAccountData.coaWithDepths || !glAccountData.data || glAccountData.data.length == 0) {
                return;
            }

            const builtChartData = getGLAccountChartData(parsedGLData, glComparisonOptions, glSelectedComparison, glAccountData.focusYear);
            setGLAccountChartData(builtChartData);

            const tableData = getHotTableDataFromParsedSeriesData({
                varianceCounts: builtChartData.varianceCounts,
                chartSeriesData: builtChartData.chartSeriesData,
            }, builtChartData.summaryData, 'dollar');

            setGLAccountsTableConfig(tableData);

            const varianceColor = getVarianceColorForAccount(glAccountData.coaWithDepths[glSelectedComparison], builtChartData.summaryData?.varianceAmount?.value);
            setGLChangeVarianceColor(varianceColor);
        },
        [glSelectedComparison]
    );

    useEffect(
        () => {
            if (!glAccountData.coaWithDepths) {
                return;
            }

            const _accountOptions = buildAccountMenuOptions(glAccountData.coaWithDepths);
            setGLAccountOptions(_accountOptions);
        },
        [glAccountData.coaWithDepths]
    );

    return (
        <div className={css.perfDashContainer}>
            <ThemeProvider theme={ViziblyTheme}>
                <DashboardHeader>
                    <DashboardHeader.Timeframe>
                        <span>Dashboard</span><span>//</span><CurrentFocusedYear
                            year={
                                dashboardData.focusYear?.year
                            }
                            yearType={dashboardData.focusYear?.type} />
                    </DashboardHeader.Timeframe>
                </DashboardHeader>

                <StartPlanningCards />

                <Row className={css.dashboardRow}>
                    {
                        overviewCards.length > 0 ?
                        overviewCards.map((cardInfo, idx) =>
                                <Col key={`overviewCard_${idx}`}>
                                    <OverviewCard
                                            {...cardInfo}
                                    />
                                </Col>
                        )
                        : [1, 2, 3, 4, 5].map(x =>
                            <Col key={x}>
                                <Skeleton height={'118px'} />
                            </Col>
                        )
                    }
                </Row>

                <Row className={css.dashboardRow}>
                    <Col md={12} lg={6}>
                        <GraphTableWidget>
                            <GraphTableWidget.Label>
                                <div className={widgetCss.widgetLabel}>
                                    <MenuTitle
                                        options={glAccountOptions}
                                        onChange={(newVal) => {
                                            setGLSelectedAccount(newVal);
                                        }}
                                        autocomplete
                                        noUnderline
                                    />
                                </div>

                                <div className={widgetCss.widgetSublabel}>
                                        {
                                            dashboardData.focusYear ?
                                                <MenuTitle
                                                    options={glComparisonOptions}
                                                    onChange={(newVal) => {
                                                        setGLSelectedComparison(newVal);
                                                    }}
                                                    small
                                                    plain
                                                />
                                                : <WidgetLoading />
                                        }
                                        <span className={widgetCss.widgetSublabelMetricDivider}>vs</span>
                                        <MenuTitle
                                            options={[
                                                {
                                                    label: `${(glAccountData.focusYear + 1) % 100} BDGT`,
                                                    value: glAccountData.focusYear,
                                                }
                                            ]}
                                            small
                                            plain
                                        />
                                    </div>
                            </GraphTableWidget.Label>

                                <GraphTableWidget.Graph>
                                    {
                                        glAccountData.focusYear && glAccountChartData?.summaryData ?
                                        <Grid>
                                            <Row justifyContent="center" className={css.dashboardRow}>
                                                <Col textAlign="center">
                                                    <h5 className={statCSS.statLabel}>{glAccountChartData.summaryData.comparisonItemOne.label}</h5>
                                                    <h5 className={statCSS.statValue}>{glAccountChartData.summaryData.comparisonItemOne.value}</h5>
                                                </Col>

                                                <Col textAlign="center">
                                                    <h5 className={statCSS.statLabel}>{glAccountData.focusYear + 1} BDGT</h5>
                                                    <h5 className={statCSS.statValue}>{glAccountChartData.summaryData.comparisonItemTwo.value}</h5>
                                                </Col>

                                                <Col textAlign="center">
                                                    <h5 className={statCSS.statLabel}>% Change</h5>
                                                    <h5
                                                        className={statCSS.statValue}
                                                        style={{'color': glChangeVarianceColor}}
                                                    >
                                                        {glAccountChartData.summaryData.variancePercent.value}
                                                    </h5>
                                                </Col>

                                                <Col textAlign="center">
                                                    <h5 className={statCSS.statLabel}>$ Change</h5>
                                                    <h5
                                                        className={statCSS.statValue}
                                                        style={{'color': glChangeVarianceColor}}
                                                    >
                                                        {formatValueForDataType(glAccountChartData.summaryData.varianceAmount.value, 'dollar')}
                                                    </h5>
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col>
                                                {
                                                    parsedGLData ?
                                                        <GLAccountsGraph chartData={glAccountChartData} />
                                                        : <WidgetLoading />
                                                }
                                                </Col>
                                            </Row>
                                        </Grid>
                                        : <WidgetLoading />
                                    }
                                </GraphTableWidget.Graph>

                            <GraphTableWidget.Table>
                                {
                                    glAccountsTableConfig ?
                                        <GLAccountsTable tableData={glAccountsTableConfig} account={glAccountInfo} />
                                        : <WidgetLoading />
                                }
                            </GraphTableWidget.Table>
                        </GraphTableWidget>
                    </Col>

                    <Col md={12} lg={6}>
                        {
                            dashboardData.focusYear != undefined ?
                            <GraphTableWidget>
                                <GraphTableWidget.Label>
                                    <div className={widgetCss.widgetLabel}>
                                        <MenuTitle
                                            options={operationalMenuOptions}
                                            onChange={(newVal) => {
                                                operationalData.setArgs({
                                                    metricType: newVal as OpDriverMetricType,
                                                });
                                            }}
                                            noUnderline
                                        />
                                    </div>
                                    <div className={widgetCss.widgetSublabel}>
                                        <MenuTitle
                                            options={operationalData.comparisonOptions}
                                            onChange={(newVal) => {
                                                operationalData.setArgs({
                                                    comparisonLeft: newVal,
                                                });
                                            }}
                                            small
                                            plain
                                        />
                                        <span className={widgetCss.widgetSublabelMetricDivider}>vs</span>
                                        <MenuTitle
                                            options={[
                                                {
                                                    label: `${dashboardData.focusYear.year % 100} BDGT`,
                                                    value: 0,
                                                }
                                            ]}
                                            small
                                            plain
                                        />
                                    </div>
                                </GraphTableWidget.Label>
                                <GraphTableWidget.Graph>
                                    <Grid>
                                        <Row justifyContent="center" className={css.dashboardRow}>
                                            <Col textAlign="center">
                                                <h5 className={statCSS.statLabel}>{operationalData.summaryData?.comparisonItemOne.label}</h5>
                                                <h5 className={statCSS.statValue}>{operationalData.summaryData?.comparisonItemOne.value}</h5>
                                            </Col>

                                            <Col textAlign="center">
                                                <h5 className={statCSS.statLabel}>{operationalData.summaryData?.comparisonItemTwo.label}</h5>
                                                <h5 className={statCSS.statValue}>{operationalData.summaryData?.comparisonItemTwo.value}</h5>
                                            </Col>

                                            <Col textAlign="center">
                                                <h5 className={statCSS.statLabel}>{operationalData.summaryData?.variancePercent.label}</h5>
                                                <h5 className={statCSS.statValue}>{operationalData.summaryData?.variancePercent.value}</h5>
                                            </Col>

                                            <Col textAlign="center">
                                                <h5 className={statCSS.statLabel}>{operationalData.summaryData?.varianceAmount.label}</h5>
                                                <h5 className={statCSS.statValue}>{operationalData.summaryData?.varianceAmount.value}</h5>
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col>
                                                {
                                                    operationalData.chartData ?
                                                    <OperationalGraph
                                                        chartData={operationalData.chartData}
                                                        dataType={operationalData.dataType}
                                                    />
                                                    : <WidgetLoading />
                                                }
                                            </Col>
                                        </Row>
                                    </Grid>
                                </GraphTableWidget.Graph>
                                <GraphTableWidget.Table>
                                    {
                                        operationalData.tableData !== undefined ?
                                            <OperationalTable tableData={operationalData.tableData} />
                                            : <WidgetLoading />
                                    }
                                </GraphTableWidget.Table>
                            </GraphTableWidget>
                            : <WidgetLoading />
                        }
                    </Col>

                    {/* TODO after rents widget enabled, move operational widget to new row below this one and uncomment rents code */}
                    {/* <Col size="5">
                        <GraphTableWidget>
                            <GraphTableWidget.Label>
                                <div className={widgetCss.widgetLabel}>
                                    Rents
                                </div>
                                <div className={widgetCss.widgetSublabel}>
                                    {
                                        rentsData && dashboardData.focusYear?.year
                                            ? <RentsRange
                                                data={rentsData}
                                                prefs={dashboardData.rentsPrefs}
                                                year={dashboardData.focusYear?.year}
                                                onChange={(value: number) => {
                                                    setRentsFirstYear(value);
                                                }}
                                            />
                                            : <WidgetLoading />
                                    }
                                </div>
                            </GraphTableWidget.Label>
                            <GraphTableWidget.Graph>
                                {
                                    rentsData !== undefined
                                        ? <RentsGraph
                                            data={rentsData}
                                            firstYear={rentsFirstYear}
                                        />
                                        : <WidgetLoading />
                                }
                            </GraphTableWidget.Graph>
                            <GraphTableWidget.Table>
                                {
                                    rentsData !== undefined
                                        ? <RentsTable
                                            data={rentsData}
                                            firstYear={rentsFirstYear}
                                        />
                                        : <WidgetLoading />
                                }
                            </GraphTableWidget.Table>
                        </GraphTableWidget>
                    </Col> */}
                </Row>

                <Row className={css.dashboardRow}>
                    <Col>
                        <AccountOverviewTables className={css.widgetLabel}/>
                    </Col>
                </Row>
            </ThemeProvider>
        </div>
    );
}