import { ReactElement, useEffect, useState } from "react";
import { MetricCard } from "../../metric-card/MetricCard";
import Chart from "react-apexcharts";
import MenuTitle from "../../menu-title/MenuTitle";
import { IMenuTitleOption } from "../../menu-title/logic/menuTitle";
import { Col, Grid, Row } from "@zendeskgarden/react-grid";
import * as css from "../styles/styles.module.scss";
import { ApexOptions } from "apexcharts";
import { useAccountSummaryContext } from "../../../pages/workflows/account/AccountSummaryContext";
import { AccountChangeColorMap, AccountChangeDirection, getAccountChangeDirection, getTotalsForYear, getVarianceForSet } from "../logic/AccountSummaryLogic";
import { formatterDollarUSNoDecimal } from "../../../utils/formatters";
import { buildApexChartConfig } from "../../../styles/apex-charts/ViziblyChartDefaultConfig";

export function VarianceCard(): ReactElement {
    const ctxAcctSummary = useAccountSummaryContext();

    const [chartSeries, setChartSeries] = useState<ApexAxisChartSeries>([]);
    const [varianceValue, setVarianceValue] = useState<number>(0);
    const [variancePercentage, setVariancePercentage] = useState<number | string | undefined>(0);
    const [currentYearTruncated, setCurrentYearTruncated] = useState<number>(0);
    const [accountChangeDirection, setAccountChangeDirection] = useState<AccountChangeDirection>(AccountChangeDirection.NEITHER);

    const leftOptions: IMenuTitleOption[] = [
        { label: 'Variance', value: 0 },
    ];

    // TODO enable annual vs ytd view modes - bowman APP-1043
    // const rightOptions: IMenuTitleOption[] = [
    //     { label: 'Annual', value: 'annual' },
    //     { label: 'YTD', value: 'ytd' },
    // ];

    const chartOptions: ApexOptions = {
        chart: {
            stacked: true,
        },
        xaxis: {
            categories: [
                `${currentYearTruncated} RFCST`,
                `${currentYearTruncated} BDGT`,
            ],
        },
    };

    useEffect(
        () => {
            if (!ctxAcctSummary.data) return;

            setCurrentYearTruncated(ctxAcctSummary.currentYear % 100);

            const {
                act,
                fcst,
                rfcst,
                bdgt,
            } = getTotalsForYear(ctxAcctSummary.currentYear, ctxAcctSummary);
            const {
                varValue,
                varPercentage,
            } = getVarianceForSet(rfcst, bdgt);

            setChartSeries([
                { data: [act, null], name: 'Actual' },
                { data: [fcst, null], name: 'Forecast' },
                { data: [null, bdgt], name: 'Budget' },
            ]);
            setVarianceValue(varValue);

            if (varPercentage == undefined) {
                setVariancePercentage('-');
            } else {
                setVariancePercentage(parseFloat(Math.abs(varPercentage).toFixed(1)) + '%');
            }

            setAccountChangeDirection(getAccountChangeDirection(rfcst, bdgt, ctxAcctSummary.isIncome));
        },
        [ctxAcctSummary.data]
    );

    const chartConfig = buildApexChartConfig({ options: chartOptions, series: chartSeries });

    return (
        <MetricCard>
            <MetricCard.Title>
                <div className={css.accountSummaryTitle}>
                    <MenuTitle options={leftOptions} />
                    {/* TODO enable annual vs ytd view modes - bowman APP-1043 */}
                    {/* <MenuTitle options={rightOptions} small={true} align={'right'} /> */}
                </div>

                <div className={css.accountSummarySubtitle}>
                    <MenuTitle options={[{ label: `${currentYearTruncated} RFCST`, value: 0 }]} plain />
                    <span className={css.accountSummarySubtitleSeparator}>vs</span>
                    <MenuTitle options={[{ label: `${currentYearTruncated} BDGT`, value: 0 }]} plain />
                </div>
            </MetricCard.Title>

            <MetricCard.Body>
                <Grid>
                    <Row justifyContent="center">
                        <Col size={5} textAlign="center">
                            <h5 className={css.statLabel}>% VAR</h5>
                            <h5 className={`${css.statValue} ${AccountChangeColorMap[accountChangeDirection]}`}>
                                {variancePercentage}
                            </h5>
                        </Col>

                        <Col size={5} textAlign="center">
                            <h5 className={css.statLabel}>$ VAR</h5>
                            <h5 className={`${css.statValue} ${AccountChangeColorMap[accountChangeDirection]}`}>
                                {formatterDollarUSNoDecimal.format(varianceValue)}
                            </h5>
                        </Col>
                    </Row>
                </Grid>

                <Chart
                    type="bar"
                    options={chartConfig.options}
                    series={chartConfig.series}
                    height={190}
                />
            </MetricCard.Body>
        </MetricCard>
    );
}